import { FormControl, FormGroup, FormArray, AbstractControl, Validators } from '@angular/forms';
import { ComCodForm } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm-form.model';
import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { NctsPreAdmRefForm } from '../preadmrefar2/preadmrefar2-form.model';
import { NctsPreAdmRefModel } from '../preadmrefar2/preadmrefar2.model';
import { NctsProDocForm } from '../prodocdc2/prodocdc2-form.model';
import { NctsProDocModel } from '../prodocdc2/prodocdc2.model';
import { NctsSpeMenForm } from '../spemenmt2/spemenmt2-form.model';
import { NctsSpeMenModel } from '../spemenmt2/spemenmt2.model';
import { T2LGooIteGdsModel } from './gooitegds.model';

export class T2LGooIteGdsForm {
    [key: string]: AbstractControl;

    IteNumGDS7: FormControl;
    ComCodTarCodGDS10: FormControl;
    DecTypGDS15: FormControl;
    GooDesGDS23: FormControl;
    GooDesGDS23LNG: FormControl;
    GroMasGDS46: FormControl;
    NetMasGDS48: FormControl;
    CouOfDisGDS58: FormControl;
    PREADMREFAR2: FormArray;
    PRODOCDC2: FormArray;
    SPEMENMT2: FormArray;

    COMCODGODITM: FormArray;

    constructor(goods: T2LGooIteGdsModel) {
        //console.trace("Who creates GooItegds");
        // console.log("___GooIteGdsFormModel constructor", goods);

        this.IteNumGDS7 = new FormControl(goods.IteNumGDS7, {
            updateOn: 'blur'
        });
        this.ComCodTarCodGDS10 = new FormControl(goods.ComCodTarCodGDS10, {
            updateOn: 'blur'
        });
        this.DecTypGDS15 = new FormControl(goods.DecTypGDS15);
        this.GooDesGDS23 = new FormControl(goods.GooDesGDS23, {
            updateOn: 'blur'
        });
        this.GooDesGDS23LNG = new FormControl(goods.GooDesGDS23LNG);
        this.GroMasGDS46 = new FormControl(goods.GroMasGDS46, {
            updateOn: 'blur'
        });
        this.NetMasGDS48 = new FormControl(goods.NetMasGDS48, {
            updateOn: 'blur'
        });
        this.CouOfDisGDS58 = new FormControl(goods.CouOfDisGDS58);

        this.PREADMREFAR2 = new FormArray([]);
        this.PRODOCDC2 = new FormArray([]);
        this.SPEMENMT2 = new FormArray([]);
        this.COMCODGODITM = new FormArray([]);

        if (goods.PREADMREFAR2) {
            goods.PREADMREFAR2.forEach((prevdocs: NctsPreAdmRefModel) => {
                this.PREADMREFAR2.push(new FormGroup(new NctsPreAdmRefForm(new NctsPreAdmRefModel(prevdocs))));
            });
        } else {
            this.PREADMREFAR2.push(new FormGroup(new NctsPreAdmRefForm(new NctsPreAdmRefModel())));
        }

        if (goods.PRODOCDC2) {
            goods.PRODOCDC2.forEach((prodocs: NctsProDocModel) => {
                this.PRODOCDC2.push(new FormGroup(new NctsProDocForm(new NctsProDocModel(prodocs))));
            });
        } else {
            this.PRODOCDC2.push(new FormGroup(new NctsProDocForm(new NctsProDocModel())));
        }

        if (goods.SPEMENMT2) {
            goods.SPEMENMT2.forEach((spemen: NctsSpeMenModel) => {
                this.SPEMENMT2.push(new FormGroup(new NctsSpeMenForm(new NctsSpeMenModel(spemen))));
            });
        } else {
            this.SPEMENMT2.push(new FormGroup(new NctsSpeMenForm(new NctsSpeMenModel())));
        }

        if (goods.COMCODGODITM) {
            goods.COMCODGODITM.forEach((comcode: ComCodModel) => {
                this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel(comcode))));
            });
        } else {
            this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel())));
        }


        this.GooDesGDS23.setValidators(Validators.required)
        this.GooDesGDS23.updateValueAndValidity()
    }

}
