import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MainBroadcasterService } from './broadcaster.service';
import { T2LDeclarationForm } from 'app/main/apps/transit/t2l/_models/declaration/declaration-form.model';

@Injectable({
    providedIn: 'root'
})
export class DisableFormService {

    constructor(private mainBroadcaster: MainBroadcasterService) { }


    checkFormState(declarationForm: FormGroup) {

        //  console.log("DeclarationForm on checkFormState", declarationForm.value)
        const currentState = declarationForm.get('_PRIVATE').get('stateCode').value;
        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value;



        console.log('EDIT declForm CheckState before enable', declarationForm.getRawValue());

        if (currentState !== 'Pre-Submitted' && currentState !== 'Edit') {
            console.log('CHECKSTATE DISABLING FORM');

            this.disableForm(declarationForm);
        } else {
            console.log('CHECKSTATE ENABLING FORM');

            this.enableForm(declarationForm);

        }
        declarationForm.updateValueAndValidity();


        if (currentState === 'Edit') {
            console.log('CHECKSTATE ENABLING FORM, DISABLING SPECIFIC');

            switch (subdomain) {

                case 'EXP_DECL': {
                    this.exp_decl(declarationForm);
                    break;
                }
                case 'IMP_DECL': {
                    this.imp_decl(declarationForm);
                    break;
                }
                case 'T2L': {
                    this.t2l(declarationForm);
                    break;
                }
                case 'ICS_DETE': {
                    this.ics_dete(declarationForm);
                    break;
                }

            }
        }
    }


    enableForm(declarationForm: FormGroup) {
        const currentGoods = declarationForm.get('GOOITEGDS') as FormArray;

        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value;

        this.mainBroadcaster.updateFormDisabled({ [subdomain]: false });

        //THIS IS VERY IMPORTANT AS IF WE DONT ENABLE THEM FIRST
        //THE GoodsItems Array resets
        setTimeout(() => {
            currentGoods?.enable();

            declarationForm.enable();

            //disable national code on comcod
            if (currentGoods && subdomain !== 'IMP_DECL') {
                const items = currentGoods.length;

                for (let i = 0; i < items; i++) {
                    const comCodGodItm = currentGoods.at(i).get('COMCODGODITM') as FormArray;


                    const nationalAddCod = comCodGodItm.at(0).get('NAtAddCodCMD1');
                    nationalAddCod.disable();
                }
            }
        }, 100);
    }

    disableForm(declarationForm: FormGroup) {

        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value;


        declarationForm.disable();
        this.mainBroadcaster.updateFormDisabled({ [subdomain]: true });

        console.log('XXXformDisabled', declarationForm.value);
    }

    exp_decl(declarationForm) {
        const tradec = declarationForm.get('TRADEC') as FormGroup;
        const statrep385 = declarationForm.get('STATREP385') as FormGroup;
        const cusoffexpert = declarationForm.get('CUSOFFEXPERT') as FormGroup;
        const heahea = declarationForm.get('HEAHEA') as FormGroup;
        const privateCtrl = declarationForm.get('_PRIVATE') as FormGroup;
        const gooitegds = declarationForm.get('GOOITEGDS') as FormArray;

        const pos1a = heahea?.get('TypOfDecHEA24');
        const pos1b = heahea?.get('TypOfDecBx12HEA651');
        const lrn = heahea?.get('RefNumHEA4');
        const mrn = heahea?.get('DocNumHEA5');
        const refNumber = privateCtrl.get('refNumber')


        // CHANGE THiS TO DOCUMENT SPECIFIC
        setTimeout(() => {
            tradec?.disable();
            statrep385?.disable();
            cusoffexpert?.disable();
            pos1a?.disable();
            pos1b?.disable();
            lrn?.disable();
            mrn?.disable();
            refNumber?.disable();

            for (let i = 0; i < gooitegds.length; i++) {

                gooitegds.at(i).get('ProReqGDI1').disable()
                gooitegds.at(i).get('PreProGDI1').disable()
                gooitegds.at(i).get('ComNatProGIM1').disable()

                gooitegds.at(i).get('ProReqGDI1').updateValueAndValidity()
                gooitegds.at(i).get('PreProGDI1').updateValueAndValidity()
                gooitegds.at(i).get('ComNatProGIM1').updateValueAndValidity()

            }

            tradec?.updateValueAndValidity();
            statrep385?.updateValueAndValidity();
            cusoffexpert?.updateValueAndValidity();
            pos1a?.updateValueAndValidity();
            pos1b?.updateValueAndValidity();
            lrn?.updateValueAndValidity();
            mrn?.updateValueAndValidity();
            refNumber?.updateValueAndValidity();
            declarationForm.updateValueAndValidity();
        }, 100);
    }

    imp_decl(declarationForm) {

    }

    t2l(declarationForm: FormGroup<T2LDeclarationForm>) {
        const tradec = declarationForm.get('TRADEC') as FormGroup;
        const heahea = declarationForm.get('HEAHEA') as FormGroup;
        const cusoffdepept = declarationForm.get('CUSOFFDEPEPT') as FormGroup;
        const privateCtrl = declarationForm.get('_PRIVATE') as FormGroup;

        const pos1a = heahea?.get('TypOfDecHEA24');
        const pos1b = heahea?.get('TypOfDecBx12HEA651');
        const lrn = heahea?.get('RefNumHEA4');
        const mrn = heahea?.get('DocNumHEA5');
        const refNumber = privateCtrl.get('refNumber')


        // CHANGE THiS TO DOCUMENT SPECIFIC
        setTimeout(() => {
            tradec?.disable();
            cusoffdepept?.disable();
            pos1a?.disable();
            pos1b?.disable();
            lrn?.disable();
            mrn?.disable();
            refNumber?.disable();


            tradec?.updateValueAndValidity();
            cusoffdepept?.updateValueAndValidity();
            pos1a?.updateValueAndValidity();
            pos1b?.updateValueAndValidity();
            lrn?.updateValueAndValidity();
            mrn?.updateValueAndValidity();
            refNumber?.updateValueAndValidity();
            declarationForm.updateValueAndValidity();
        }, 100);


    }

    ics_dete(declarationForm) {

        const cusoff = declarationForm.get('CUSOFF') as FormGroup;
        const heahea = declarationForm.get('HEAHEA') as FormGroup;
        const mrn = heahea?.get('DocNumHEA5');
        const lrn = heahea?.get('RefNumHEA4');

        setTimeout(() => {
            cusoff.disable();
            mrn?.disable();
            lrn?.disable();

            cusoff.updateValueAndValidity();
            mrn?.updateValueAndValidity();
            lrn?.updateValueAndValidity();
            declarationForm.updateValueAndValidity();
        }, 100);
    }

}