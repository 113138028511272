import { LoadingModule } from 'app/main/main-building-blocks/loading/loading.module';
import { NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { FormFieldsModule } from 'app/main/main-building-blocks/form-fields/form-fields.module';
import { AddDelButtonsModule } from 'app/main/main-building-blocks/_scams/add-del-buttons/add-del-buttons.module';
import { EPaymentComponent } from './e-payment.component';
import { RemittanceErrorPipeModule } from 'app/services/pipes/remittanceError.pipe';
import { TestDigitOnlyPipeModule } from 'app/services/pipes/testDigitOnly.pipe';



@NgModule({
    declarations: [EPaymentComponent],
    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        AddDelButtonsModule,
        LoadingModule,
        TestDigitOnlyPipeModule,
        RemittanceErrorPipeModule
    ],
    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { panelClass: 'mat-dialog-override' }
        },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ]

})
export class EPaymentModule { }
