import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import {
    customsItem,
    international_customersItem,
    _pelatesItem
} from 'app/model/api-model';
import { Observable, Subject } from 'rxjs';
import { debounceTime, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ArrivalAtExitService } from './arrAtExit.service';
import { DeclarationStates, MainBroadcasterService } from 'app/services/broadcaster.service';
import { ArchiveSaveDataCommon } from '../../archive/archive.model';
import { DeclarationStateService, StateResponse } from 'app/services/declaration-state.service';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { ArrAtExitForm } from 'app/main/apps/export/arrival-at-exit/_models/arrivaAtExit-form.model';

@Component({
    selector: 'app-arrival-at-exit',
    templateUrl: './arrival-at-exit.component.html',
    styleUrls: ['./arrival-at-exit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ArrivalAtExitService]
})
export class ArrivalAtExitComponent implements OnInit {

    @ViewChild('ArrNotPlace') _arrNotPlace: ElementRef;
    @ViewChild('ArrAgrLoc') _arrAgrLoc: ElementRef;
    @ViewChild('ArrAgrLocCode') _arrAgrLocCod: ElementRef;
    subdomain = 'ARR_AT_EXIT';
    arrAtExitForm: FormGroup;
    heahea: FormGroup;
    traexicar: FormGroup;
    aceace: FormGroup;

    color1 = '';
    color2 = '';

    eori: string;
    street: string;
    address2: string;
    country: string;
    specialMention: string;
    ArrivalAtExitService;

    dateType = 'yyyyMMdd';
    defaultDate: Date;
    carriers: any[] = [];
    carrier: FormControl;

    stateCode: string;
    showButtons: boolean;
    formDisabled: boolean;

    consConfig: AutoCompConfigValues = {
        display: { values: ['lastname'] },
        menuOptions: { options: ['lastname', 'eori'] }
    };

    customs: customsItem[] = [];

    declarationSettings: DeclarationSettingsModel;

    config = {
        output: 'ReferenceNumber',
        menuOptions: {
            options: ['CustomsOfficeName', 'ReferenceNumber']
        }
    };

    parentSubdomain: string;

    get customsPlace(): FormControl {
        return this.heahea.get('CusSubPlaHEA66') as FormControl
    }

    get agreedLocCode(): FormControl {
        return this.heahea.get('ArrAgrLocCodHEA62') as FormControl
    }

    get agreedLoc(): FormControl {
        return this.heahea.get('ArrAgrLocOfGooHEA63') as FormControl
    }



    private _destroy: Subject<void>;

    constructor(
        public dialogRef: MatDialogRef<ArrivalAtExitComponent>,
        private arrAtExitService: ArrivalAtExitService,
        private cf: ExtCustomFunctions,
        private _mainBroadcaster: MainBroadcasterService,
        private cd: ChangeDetectorRef,
        private declarationStateService: DeclarationStateService,
        @Inject(MAT_DIALOG_DATA) public data: { declaration: ArchiveSaveDataCommon, referrerDeclarationId: string }
    ) {
        console.log('ARRATEXIT COMP INIT');

        this.carrier = new FormControl(
            { value: null, disabled: false },
            { validators: [Validators.required] }
        );

        this._destroy = new Subject<void>();

        this.defaultDate = new Date();

        this._mainBroadcaster.declarationSettings$.pipe(take(1)).subscribe(settings => {

            this.declarationSettings = settings;
        }
        )
    }

    ngOnInit(): void {
        this.arrAtExitService
            .fillForm(this.data.referrerDeclarationId)
            .pipe(take(1))
            .subscribe(([subdomain, declarationForms]) => {
                this.parentSubdomain = subdomain;
                //after filling the form set activeSubdomain

            });

        this.arrAtExitService
            .onRefusedEdit()
            .pipe(takeUntil(this._destroy))
            .subscribe();


        this.arrAtExitService.arrAtExitForm$
            .pipe(
                tap(form => {

                    console.trace("ARRATEXIT FORM", form.value)
                    this.declareProperties(form)
                    this.getCustoms()
                    this.arrAtExitService.getCarriers().subscribe(carriers => { this.carriers = carriers; });
                }),
                tap(() => this.valueChangesSubs()),
                switchMap((value, index) => {

                    console.log("ARRATEXIT ON DECL FORM VALUE CHANGES this.arrAtExitForm", this.arrAtExitForm.value, index)
                    console.log("ARRATEXIT ON DECL FORM VALUE CHANGES subdomain", this.subdomain)
                    return this.arrAtExitService.onDeclFormValuechanges(this.arrAtExitForm, this.subdomain)
                }),

                switchMap(() => this.declarationState()),


                takeUntil(this._destroy),
            ).subscribe(value => {
                console.log("ARRATEXIT ON DECL FORM VALUE CHANGES", value)
            });

        this._mainBroadcaster.updateActiveSubdomain(this.subdomain)

        if (this.data.declaration) {
            this.loadSavedDeclaration();
        }


        //this.loadSavedDeclaration();
    }

    getCustoms() {

        const countryCode = this.aceace.get('_countryCode');

        this.arrAtExitService.getCustoms(countryCode.value).subscribe((data: customsItem[]) => {
            this.customs = data;
        });
    }
    valueChangesSubs() {

        const countryCode = this.aceace.get('_countryCode');

        console.log("TRAEXICAR VALUE BEFORE SUBSCRIBE TO VALUE CHANGE", this.traexicar.get('CarrierObject').value)

        this.heahea
            .get('ArrNotPlaHEA60')
            .valueChanges.pipe(
                startWith(this.heahea.get('ArrNotPlaHEA60').value),
                takeUntil(this._destroy)
            )
            .subscribe(value => {
                if (!this.formDisabled) {
                    if (value && value !== null && value !== '') {
                        this.heahea.get('ArrNotPlaHEA60LNG').setValue(this.declarationSettings.language);
                    } else {
                        this.heahea.get('ArrNotPlaHEA60LNG').setValue(null);
                    }
                }
            });

        this.customsPlace
            .valueChanges.pipe(takeUntil(this._destroy))
            .subscribe(value => {

                if (!this.formDisabled) {
                    console.log("ARRVALUECHANGE CUSTOMS", value)
                    if (value && value !== null && value !== '') {

                        this.controlDisable(this.agreedLoc)
                        this.controlDisable(this.agreedLocCode)
                        this._arrNotPlace.nativeElement.focus()
                    } else {
                        this.controlEnable(this.agreedLoc)
                        this.controlEnable(this.agreedLocCode)
                        setTimeout(() => {
                            this._arrAgrLocCod.nativeElement.focus()

                        }, 100)
                    }
                }
            });

        this.agreedLocCode
            .valueChanges.pipe(takeUntil(this._destroy))
            .subscribe(value => {
                if (!this.formDisabled) {

                    console.log("ARRVALUECHANGE AGREEDLOCCODE", value)

                    if (value && value !== null && value !== '') {

                        this.controlDisable(this.customsPlace)
                        this.controlDisable(this.agreedLoc)
                        this._arrNotPlace.nativeElement.focus()


                    } else {
                        this.controlEnable(this.customsPlace)
                        this.controlEnable(this.agreedLoc)
                        setTimeout(() => {
                            this._arrAgrLoc.nativeElement.focus()

                        }, 100)
                    }

                }
            });

        this.agreedLoc
            .valueChanges.pipe(takeUntil(this._destroy))
            .subscribe(value => {

                console.log("ARRVALUECHANGE AGREEDLOC", value)

                if (!this.formDisabled) {

                    if (value && value !== null && value !== '') {

                        this.controlDisable(this.customsPlace)
                        this.controlDisable(this.agreedLocCode)
                        this._arrNotPlace.nativeElement.focus()
                    } else {
                        this.controlEnable(this.customsPlace)
                        this.controlEnable(this.agreedLocCode)
                    }
                }

            });


        this.traexicar.get('CarrierObject')
            .valueChanges.pipe(
                startWith(this.traexicar.get('CarrierObject').value),
                takeUntil(this._destroy))
            .subscribe(value => {
                if (!this.formDisabled) {
                    console.log("CarrierObject value", value)
                    if (!!value && value !== '') {
                        if (value.lastname) {
                            this.traexicar.patchValue({
                                NamTEC1: value.lastname,
                                StrAndNumTEC2: value.street,
                                PosCodTEC1: value.postal_code,
                                CitTEC1: value.city,
                                CouCodTEC1: value.country,
                                TINTEC2: value.eori,
                                TRAEXICARLNG: this.declarationSettings.language
                            }, { emitEvent: false, onlySelf: true });
                        }

                        this.checkTIN(value);
                    } else {
                        this.traexicar.patchValue({
                            NamTEC1: null,
                            StrAndNumTEC2: null,
                            PosCodTEC1: null,
                            CitTEC1: null,
                            CouCodTEC1: null,
                            TINTEC2: null,
                            TRAEXICARLNG: null
                        }, { emitEvent: false, onlySelf: true });
                    }
                }
            });

        countryCode.valueChanges
            .pipe(
                debounceTime(300),
                switchMap((country: string) => {
                    console.log(country);
                    if (country.length === 2) {

                        return this.arrAtExitService.getCustoms(country);
                    }
                })
            )
            .subscribe(customs => {
                if (!this.formDisabled) {
                    if (customs === null) {
                        this.aceace.get('RefNumACE1').setValue(null);
                        this.aceace.get('RefNumACE1').disable();
                    } else {
                        if (countryCode.disabled) {
                            this.aceace.get('RefNumACE1').disable();
                        } else {
                            this.aceace.get('RefNumACE1').enable();
                        }
                        this.customs = customs;
                    }
                }
            });



        this.cd.detectChanges()


    }
    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        console.log('ARRATEXIT COMP DESTROYED');

        this.reset_arrAtExit()

        this._destroy.next();
        this._destroy.complete();
    }

    declareProperties(form: FormGroup<ArrAtExitForm>) {
        console.log('Arr form on comp', form);
        this.arrAtExitForm = form;

        this.heahea = form.get('HEAHEA') as FormGroup;
        this.traexicar = form.get('TRAEXICAR') as FormGroup;
        this.aceace = form.get('ACEACE') as FormGroup;

        this.stateCode = form.value._PRIVATE.stateCode;
    }
    loadSavedDeclaration() {

        this.arrAtExitService.loadSavedForm(this.data.declaration);

        if (
            this.data.declaration.state !== 'Pre-Submitted' &&
            this.data.declaration.state !== 'Edit'
        ) {
            this.arrAtExitForm.disable();
            this.arrAtExitService.updateFormDisabled(true)
        }

        this.stateCode = this.arrAtExitForm.get('_PRIVATE').get('stateCode').value;
        this.arrAtExitService.updateDeclarationState(this.stateCode)


        this.cd.detectChanges()
    }

    declarationState(): Observable<[DeclarationStates, boolean]> {

        console.log("ARRATEXIT DECLARATION STATE")
        return this.arrAtExitService.declarationState(this.arrAtExitForm).pipe(
            tap(
                ([declarationStates, disabled]) => {
                    console.log('arrAtExit subdomain', this.subdomain);
                    console.log('arrAtExit form', this.arrAtExitForm);
                    console.log('arrAtExit state', declarationStates);
                    console.log('arrAtExit stateCode', declarationStates[this.subdomain].current);
                    console.log('arrAtExit formDisabled ', disabled);

                    this.formDisabled = disabled;
                    this.showButtons = !disabled;

                    //Disable controls that are contitionally set
                    if (this.formDisabled) {
                        this.controlDisable(this.customsPlace)
                        this.controlDisable(this.agreedLoc)
                        this.controlDisable(this.agreedLocCode)

                    }
                    const state: StateResponse = declarationStates[this.subdomain].current;

                    if (state?.stateCode) {
                        this.stateCode = state.stateCode;
                    }
                    //without this, 'Expression has changed' when we have modify error after a modify success
                    this.cd.detectChanges()
                },

            ),
            takeUntil(this._destroy))

    }
    editDeclaration() {
        this.cf.editDeclaration(this.arrAtExitForm);
    }
    onCloseConfirm() {
        this.dialogRef.close();
    }
    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event);
    }
    changeStyle2(event) {
        if (this.arrAtExitForm.valid) {
            this.color2 = this.cf.changeStyle2(this.arrAtExitForm, event);
        }
    }
    onSubmit() {
        this.arrAtExitService.submit();

    }

    checkState() {
        console.log("ARRATEXIT CHECK STATE", this.arrAtExitForm)
        this.declarationStateService.checkState(this.arrAtExitForm);
    }
    expandPanel(matExpansionPanel, event) {
        this.cf.expandPanel(matExpansionPanel, event);
    }

    checkTIN(value: international_customersItem) {
        this.eori = value.eori;

        if (this.eori !== null && this.eori !== '') {
            this.street = '';
            this.address2 = '';
            this.country = '';
        } else {
            this.street = value.street;
            this.address2 = value.city + ', ' + value.postal_code;
            this.country = value.country;
        }
    }
    controlEnable(control: AbstractControl) {
        control.enable({ emitEvent: false });
        control.setValidators(Validators.required)
        control.updateValueAndValidity({ emitEvent: false });
    }
    controlDisable(control: AbstractControl) {
        control.disable({ emitEvent: false });
        control.setValidators(null);
        control.updateValueAndValidity({ emitEvent: false });

    }
    reset_arrAtExit() {

        this._mainBroadcaster.updateActiveSubdomain(this.parentSubdomain);

        //if form has been submitted delete it from everywhere
        if (this.stateCode !== 'Pre-Submitted') {
            this._mainBroadcaster.updateDeclarationForms({
                [this.subdomain]: null
            });
        }
        console.log("Subdomain on reset arrAtExit", this.subdomain)
        this._mainBroadcaster.updateDeclarationStates({
            [this.subdomain]: { stateCode: 'Pre-Submitted' }
        })

        this._mainBroadcaster.updateFormDisabled({ [this.subdomain]: null });



    }

}
