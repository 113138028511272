import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { BeerForm } from 'app/main/apps/excise-helper-tables/beer/_models/beer-form.model';
import { BeerModel } from 'app/main/apps/excise-helper-tables/beer/_models/beer.model';
import { BeerService } from 'app/main/apps/excise-helper-tables/beer/beer.service';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
    selector: 'beer-header',
    templateUrl: './beer-header.component.html',
    styleUrls: ['./beer-header.component.scss']
})
export class BeerHeaderComponent implements OnInit {

    beerForm: FormGroup<BeerForm>
    pelates: Contact[] = []
    pelConfig: AutoCompConfigValues = {
        display: { values: ['fullname'] },
        menuOptions: { options: ['fullname', 'afm'] },
        menuTitles: [null, 'ΑΦΜ: ']
    };

    private _destroy = new Subject<void>();


    constructor(private beerService: BeerService, private mainBroadcaster: MainBroadcasterService
    ) { }

    ngOnInit() {
        this.mainBroadcaster.domecticCustomers$.pipe(take(1)).subscribe((pelates) => {
            this.pelates = pelates
        })

        this.beerService.beerForm$.pipe(takeUntil(this._destroy)).subscribe((form: FormGroup) => {

            this.beerForm = form
            // this.calculateTotalDays(form.value)
        })

        this.beerForm.valueChanges.pipe(takeUntil(this._destroy)).subscribe((value: BeerModel) => {
            console.log('BeerHeaderComponent valueChanges', value)
            //this.calculateTotalDays(value)

        })
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }


    createContact() {
        this.beerService.createContact()
            .subscribe(result => {
                if (!result) {
                    return;
                }
                const newContact = result.createdContact;
                console.log('CREATED CONTACT', result);

                this.beerForm.patchValue({
                    customer: newContact
                });
                //this.cd.detectChanges();
                //this.onCloseConfirm();
            });
    }

}
