import { FormGroup, FormControl, FormArray, AbstractControl, Validators } from '@angular/forms';
import { GoodsItemsForT2LT2LF, Packaging, ProofRequest, TransportEquipments } from 'app/main/apps/transit/t2l-pous/_models/pous/pous.model';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';

export class AddressForm {
    [key: string]: AbstractControl;

    Country: FormControl;
    City: FormControl;
    Postcode: FormControl;
    StreetAndNumber: FormControl;

    constructor(value?) {
        this.Country = new FormControl(value?.Country || null);
        this.City = new FormControl(value?.City || null);
        this.Postcode = new FormControl(value?.Postcode || null);
        this.StreetAndNumber = new FormControl(value?.StreetAndNumber || null);
    }
}

export class EoNameAndAddressForm {
    [key: string]: AbstractControl;

    Address: FormGroup;
    Name: FormControl;

    constructor(value?) {
        this.Address = new FormGroup(new AddressForm(value?.Address));
        this.Name = new FormControl(value?.Name || null);
    }
}

export class PersonPresentingGoodsToCustomsForm {
    [key: string]: AbstractControl;

    IdentificationNumber: FormControl;
    IdentificationType: FormControl;
    EoNameAndAddress: FormGroup;

    constructor(value?) {
        this.IdentificationNumber = new FormControl(value?.IdentificationNumber || null);
        this.IdentificationType = new FormControl(value?.IdentificationType || null);
        this.EoNameAndAddress = new FormGroup(new EoNameAndAddressForm(value?.EoNameAndAddress));
    }
}

export class JustificationForm {
    [key: string]: AbstractControl;

    FreeText: FormControl;

    constructor(value?) {
        this.FreeText = new FormControl(value?.FreeText || null);
    }
}

export class RequestedValidityOfTheProofForm {
    [key: string]: AbstractControl;

    NumberOfDays: FormControl;
    Justification: FormGroup;

    constructor(value?) {
        this.NumberOfDays = new FormControl(value?.NumberOfDays || null);
        this.Justification = new FormGroup(new JustificationForm(value?.Justification));
    }
}

export class AdditionalInformationForm {
    [key: string]: AbstractControl;

    Text: FormControl;
    Code: FormControl;

    constructor(value?) {
        this.Text = new FormControl(value?.Text || null);
        this.Code = new FormControl(value?.Code || null);
    }
}

export class CommodityForm {
    [key: string]: AbstractControl;

    HarmonizedSystemSubHeadingCode: FormControl;
    CombinedNomenclatureCode: FormControl;

    constructor(value?) {
        this.HarmonizedSystemSubHeadingCode = new FormControl(value?.HarmonizedSystemSubHeadingCode || null, { validators: [Validators.required], updateOn: 'blur' });
        this.CombinedNomenclatureCode = new FormControl(value?.CombinedNomenclatureCode || null, { updateOn: 'blur' });
    }
}

export class GoodsMeasureForm {
    [key: string]: AbstractControl;

    GrossMass: FormControl;
    NetMass: FormControl;

    constructor(value?) {
        this.GrossMass = new FormControl(value?.GrossMass || null, [Validators.required]);
        this.NetMass = new FormControl(value?.NetMass || null, [Validators.required]);
    }
}

export class PackagingForm {
    [key: string]: AbstractControl;

    ShippingMarks: FormControl;
    NumberOfPackages: FormControl;
    TypeOfPackages: FormControl;

    constructor(value?) {
        this.ShippingMarks = new FormControl(value?.ShippingMarks || null);
        this.NumberOfPackages = new FormControl(value?.NumberOfPackages || null);
        this.TypeOfPackages = new FormControl(value?.TypeOfPackages || null);
    }
}

export class SupportingDocumentsForm {
    [key: string]: AbstractControl;

    Type: FormControl;
    ReferenceNumber: FormControl;
    Date: FormControl;

    constructor(value?) {
        this.Type = new FormControl(value?.Type || null);
        this.ReferenceNumber = new FormControl(value?.ReferenceNumber || null);
        this.Date = new FormControl(value?.Date || null);
    }
}

export class AdditionalReferencesForm {
    [key: string]: AbstractControl;

    Type: FormControl;
    ReferenceNumber: FormControl;

    constructor(value?) {
        this.Type = new FormControl(value?.Type || null);
        this.ReferenceNumber = new FormControl(value?.ReferenceNumber || null);
    }
}

export class PreviousDocumentsForm {
    [key: string]: AbstractControl;

    Type: FormControl;
    ReferenceNumber: FormControl;
    Date: FormControl;

    constructor(value?) {
        this.Type = new FormControl(value?.Type || null);
        this.ReferenceNumber = new FormControl(value?.ReferenceNumber || null);
        this.Date = new FormControl(value?.Date || null);
    }
}

export class GoodsItemsForT2LT2LFForm {
    [key: string]: AbstractControl;

    AdditionalInformation: FormGroup;
    Commodity: FormGroup;
    CusCode: FormControl;
    GoodsItemNumber: FormControl;
    DescriptionOfGoods: FormControl;
    GoodsMeasure: FormGroup;
    Packaging: FormArray;
    SupportingDocuments: FormArray;
    AdditionalReferences: FormArray;
    PreviousDocuments: FormArray;

    constructor(value?) {
        this.AdditionalInformation = new FormGroup(new AdditionalInformationForm(value?.AdditionalInformation));
        this.Commodity = new FormGroup(new CommodityForm(value?.Commodity));
        this.CusCode = new FormControl(value?.CusCode || null);
        this.GoodsItemNumber = new FormControl(value?.GoodsItemNumber || null);
        this.DescriptionOfGoods = new FormControl(value?.DescriptionOfGoods || null, { updateOn: 'blur' });
        this.GoodsMeasure = new FormGroup(new GoodsMeasureForm(value?.GoodsMeasure), [Validators.required]);
        this.Packaging = new FormArray([]);
        this.SupportingDocuments = new FormArray((value?.SupportingDocuments || []).map(sd => new FormGroup(new SupportingDocumentsForm(sd))));
        this.AdditionalReferences = new FormArray((value?.AdditionalReferences || []).map(ar => new FormGroup(new AdditionalReferencesForm(ar))));
        this.PreviousDocuments = new FormArray((value?.PreviousDocuments || []).map(pd => new FormGroup(new PreviousDocumentsForm(pd))));

        if (value.Packaging) {
            value.Packaging.forEach((packaging: Packaging) => {
                this.Packaging.push(new FormGroup(new PackagingForm(new Packaging(packaging))));
            });
        } else {
            this.Packaging.push(new FormGroup(new PackagingForm(new Packaging())));
        }

    }
}

export class LocationOfGoodsForm {
    [key: string]: AbstractControl;

    TypeOfLocation: FormControl;
    QualifierOfIdentification: FormControl;
    CustomsOffice: FormControl;

    constructor(value?) {
        this.TypeOfLocation = new FormControl(value?.TypeOfLocation || null);
        this.QualifierOfIdentification = new FormControl(value?.QualifierOfIdentification || null);
        this.CustomsOffice = new FormControl(value?.CustomsOffice || null);
    }
}

export class TransportDocumentsForm {
    [key: string]: AbstractControl;

    Type: FormControl;
    ReferenceNumber: FormControl;
    Date: FormControl;

    constructor(value?) {
        this.Type = new FormControl(value?.Type || null);
        this.ReferenceNumber = new FormControl(value?.ReferenceNumber || null);
        this.Date = new FormControl(value?.Date || null);
    }
}

export class TransportEquipmentsForm {
    [key: string]: AbstractControl;

    ContainerIdentificationNumber: FormControl;
    GoodsReferences: FormArray;

    constructor(value?) {
        this.ContainerIdentificationNumber = new FormControl(value?.ContainerIdentificationNumber || null);
        this.GoodsReferences = new FormArray([]);

        if (value.GoodsReferences) {
            value.GoodsReferences.forEach((goodRef: number) => {
                this.GoodsReferences.push(new FormControl(goodRef));
            });
        } else {
            this.GoodsReferences.push(new FormControl());


        }
    }
}

export class GoodsShipmentForT2LT2LFForm {
    [key: string]: AbstractControl;

    ContainerIndication: FormControl;
    LocationOfGoods: FormGroup;
    GoodsItemsForT2LT2LF: FormArray;
    TransportDocuments: FormArray;
    SupportingDocuments: FormArray;
    TransportEquipments: FormArray;
    PreviousDocuments: FormArray;

    constructor(value?) {
        this.ContainerIndication = new FormControl(value?.ContainerIndication || null, [Validators.required]);
        this.LocationOfGoods = new FormGroup(new LocationOfGoodsForm(value?.LocationOfGoods));
        this.GoodsItemsForT2LT2LF = new FormArray([]);
        this.TransportDocuments = new FormArray((value?.TransportDocuments || []).map(td => new FormGroup(new TransportDocumentsForm(td))));
        this.SupportingDocuments = new FormArray((value?.SupportingDocuments || []).map(sd => new FormGroup(new SupportingDocumentsForm(sd))));
        this.TransportEquipments = new FormArray([]);
        this.PreviousDocuments = new FormArray((value?.PreviousDocuments || []).map(pd => new FormGroup(new PreviousDocumentsForm(pd))));

        if (value.GoodsItemsForT2LT2LF) {
            value.GoodsItemsForT2LT2LF.forEach((goodsItem: GoodsItemsForT2LT2LF) => {
                this.GoodsItemsForT2LT2LF.push(new FormGroup(new GoodsItemsForT2LT2LFForm(new GoodsItemsForT2LT2LF(goodsItem))));
            });
        } else {
            this.GoodsItemsForT2LT2LF.push(new FormGroup(new GoodsItemsForT2LT2LFForm(new GoodsItemsForT2LT2LF())));
        }

        if (value.TransportEquipments) {
            value.TransportEquipments.forEach((transEquip: TransportEquipments) => {
                this.TransportEquipments.push(new FormGroup(new TransportEquipmentsForm(new TransportEquipments(transEquip))));
            });
        } else {
            this.TransportEquipments.push(new FormGroup(new TransportEquipmentsForm(new TransportEquipments())));
        }
    }
}

export class ProofInformationT2LT2LFForm {
    [key: string]: AbstractControl;

    CompetentCustomsOffice: FormControl;
    DeclarationType: FormControl;
    DeclarationDate: FormControl;
    PersonPresentingGoodsToCustoms: FormGroup;
    TotalGrossMassKg: FormControl;
    RequestedValidityOfTheProof: FormGroup;
    GoodsShipmentForT2LT2LF: FormGroup;
    RequestType: FormControl;

    constructor(value?) {
        this.CompetentCustomsOffice = new FormControl(value?.CompetentCustomsOffice || null, [Validators.required]);
        this.DeclarationType = new FormControl(value?.DeclarationType || null, [Validators.required]);
        this.DeclarationDate = new FormControl(value?.DeclarationDate || null);
        this.PersonPresentingGoodsToCustoms = new FormGroup(new PersonPresentingGoodsToCustomsForm(value?.PersonPresentingGoodsToCustoms));
        this.TotalGrossMassKg = new FormControl(value?.TotalGrossMassKg || null);
        this.RequestedValidityOfTheProof = new FormGroup(new RequestedValidityOfTheProofForm(value?.RequestedValidityOfTheProof));
        this.GoodsShipmentForT2LT2LF = new FormGroup(new GoodsShipmentForT2LT2LFForm(value?.GoodsShipmentForT2LT2LF));
        this.RequestType = new FormControl(value?.RequestType || null);
    }
}

export class ProofRequestForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;
    DelegateIdentificationType: FormControl;
    TraderIdentificationType: FormControl;
    LRN: FormControl;
    TraderIdentificationNumber: FormControl;
    Country: FormControl;
    ProofInformationT2LT2LF: FormGroup;
    DelegateIdentificationNumber: FormControl;

    constructor(declaration: ProofRequest, subdomain?: string, messageType = 'TF015A') {

        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));
        this.DelegateIdentificationType = new FormControl(declaration?.DelegateIdentificationType || null);
        this.TraderIdentificationType = new FormControl(declaration?.TraderIdentificationType || null);
        this.LRN = new FormControl(declaration?.LRN || null, [Validators.required]);
        this.TraderIdentificationNumber = new FormControl(declaration?.TraderIdentificationNumber || null);
        this.Country = new FormControl(declaration?.Country || null, [Validators.required]);
        this.ProofInformationT2LT2LF = new FormGroup(new ProofInformationT2LT2LFForm(declaration?.ProofInformationT2LT2LF));
        this.DelegateIdentificationNumber = new FormControl(declaration?.DelegateIdentificationNumber || null);
    }
}
