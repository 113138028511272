<ng-container *ngIf="dilosiForm$ | async as dilosiForm">
    <div [formGroup]="dilosiForm" class="app_Grid">
        <div class="app_img">
            <topos-tirisis-eggrafon-svg></topos-tirisis-eggrafon-svg>
        </div>


        <div id="customsName" class="declDiv">
            <app-customs-control [customsGroup]="dilosiForm" classes="s-full" [setDefault]="true" [trapFocus]="true"
                controlName="customsName" [showAlterName]="true" [outputName]="true" (keyDown.Escape)="$event.stopPropagation();
                $event.preventDefault();"></app-customs-control>
        </div>

        <div id="customsCode" class="declDiv">
            <app-customs-control [customsGroup]="dilosiForm" [setDefault]="true" controlName="customsCode"
                (keyDown.Escape)="$event.stopPropagation();
                $event.preventDefault();"></app-customs-control>
        </div>

        <div id="customer-eori" class="declDiv">

            {{ customer.get('customerObject').value?.eori }}
        </div>
        <div id="customer-afm" class="declDiv">
            {{ customer.get('customerObject').value?.afm }}


        </div>
        <div id="customer" class="declDiv" [formGroup]="customer">
            <mat-form-field class="s-l s-full">
                <autocomplete-control formControlName="customerObject" [options]="pelates$ | async" [config]="pelConfig"
                    id="exporter-control" (keyDown.Escape)="$event.stopPropagation();
                    $event.preventDefault();">
                </autocomplete-control>
                <!--   <mat-error *ngIf="pelatis.hasError('required') && pelatis.touched"
                >Required</mat-error
            > -->
            </mat-form-field>
        </div>

        <div id="customer-name" class="declDiv">
            <app-generic-input [form]="customer.get('contactDetails')" controlName="firstname" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="customer-phone" class="declDiv">
            <app-generic-input [form]="customer.get('contactDetails')" controlName="phone" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full"></app-generic-input>
        </div>
        <div id="customer-fax" class="declDiv">
            <app-generic-input [form]="customer.get('contactDetails')" controlName="fax" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full"></app-generic-input>
        </div>
        <div id="customer-email" class="declDiv">
            <app-generic-input [form]="customer.get('contactDetails')" controlName="email" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full"></app-generic-input>
        </div>






        <div id="declarant-eori" class="declDiv">

            {{ declarant.get('declarantObject').value?.eori }}
        </div>
        <div id="declarant-afm" class="declDiv">
            {{ declarant.get('declarantObject').value?.afm }}


        </div>
        <div id="declarant" class="declDiv" [formGroup]="declarant">
            <mat-form-field class="s-l s-full">
                <autocomplete-control formControlName="declarantObject" [options]="declarants$ | async"
                    [config]="pelConfig" id="declarant-control" (keyDown.Escape)="$event.stopPropagation();
                    $event.preventDefault();">
                </autocomplete-control>
                <!--   <mat-error *ngIf="pelatis.hasError('required') && pelatis.touched"
                >Required</mat-error
            > -->
            </mat-form-field>
        </div>

        <div id="declarant-name" class="declDiv">
            <app-generic-input [form]="declarant.get('contactDetails')" controlName="firstname" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="declarant-phone" class="declDiv">
            <app-generic-input [form]="declarant.get('contactDetails')" controlName="phone" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full"></app-generic-input>
        </div>
        <div id="declarant-fax" class="declDiv">
            <app-generic-input [form]="declarant.get('contactDetails')" controlName="fax" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full"></app-generic-input>
        </div>
        <div id="declarant-email" class="declDiv">
            <app-generic-input [form]="declarant.get('contactDetails')" controlName="email" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full"></app-generic-input>
        </div>








        <ng-container [formGroup]="documentsPlace">


            <mat-radio-group class="example-radio-group" formControlName="place" (focusin)="onFocus($event)">

                <div id="PlaceOfTheCustomer" class="declDiv"
                    (click)="documentsPlace.get('place').setValue(0); PlaceOfTheCustomer.checked=true">
                    <mat-radio-button #PlaceOfTheCustomer class="example-radio-button" [value]="0"
                        (focus)="onFocus($event)">
                    </mat-radio-button>
                </div>

                <div id="OtherPlace" class="declDiv"
                    (click)="documentsPlace.get('place').setValue(1);OtherPlace.checked=true">
                    <mat-radio-button #OtherPlace class="example-radio-button" [value]="1" (focus)="onFocus($event)">
                    </mat-radio-button>

                </div>

                <div id="PlaceOfTheDeclarant" class="declDiv"
                    (click)="documentsPlace.get('place').setValue(2); PlaceOfTheDeclarant.checked=true">
                    <mat-radio-button #PlaceOfTheDeclarant class="example-radio-button" [value]="2"
                        (focus)="onFocus($event)">
                    </mat-radio-button>

                </div>
            </mat-radio-group>




            <div id="documentsPlace-street" class="declDiv">
                <app-generic-input [form]="documentsPlace" controlName="street" svgDocFocus="svgFocus"
                    inputField="input" classes="s-l s-full"></app-generic-input>
            </div>
            <div id="documentsPlace-streetNumber" class="declDiv">
                <app-generic-input [form]="documentsPlace" controlName="streetNumber" svgDocFocus="svgFocus"
                    inputField="input" classes="s-l s-full"></app-generic-input>
            </div>
            <div id="documentsPlace-postalCode" class="declDiv">
                <app-generic-input [form]="documentsPlace" controlName="postalCode" svgDocFocus="svgFocus"
                    inputField="input" classes="s-l s-full"></app-generic-input>
            </div>
            <div id="documentsPlace-city" class="declDiv">
                <app-generic-input [form]="documentsPlace" controlName="city" svgDocFocus="svgFocus" inputField="input"
                    classes="s-l s-full"></app-generic-input>
            </div>
            <div id="documentsPlace-county" class="declDiv">
                <app-generic-input [form]="documentsPlace" controlName="county" svgDocFocus="svgFocus"
                    inputField="input" classes="s-l s-full"></app-generic-input>
            </div>
            <div id="documentsPlace-prefecture" class="declDiv">
                <app-generic-input [form]="documentsPlace" controlName="prefecture" svgDocFocus="svgFocus"
                    inputField="input" classes="s-l s-full"></app-generic-input>
            </div>
            <div id="documentsPlace-workingHours" class="declDiv">
                <app-generic-input [form]="documentsPlace" controlName="workingHours" svgDocFocus="svgFocus"
                    inputField="input" classes="s-l s-full"></app-generic-input>
            </div>





        </ng-container>

        <ng-container [formGroup]="contactDetails">


            <div id="contactDetails-lastname" class="declDiv">
                <app-generic-input [form]="contactDetails" controlName="lastname" svgDocFocus="svgFocus"
                    inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
            </div>
            <div id="contactDetails-firstname" class="declDiv">
                <app-generic-input [form]="contactDetails" controlName="firstname" svgDocFocus="svgFocus"
                    inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
            </div>

            <div id="contactDetails-phone" class="declDiv">
                <app-generic-input [form]="contactDetails" controlName="phone" svgDocFocus="svgFocus" inputField="input"
                    classes="s-l s-full"></app-generic-input>
            </div>
            <div id="contactDetails-fax" class="declDiv">
                <app-generic-input [form]="contactDetails" controlName="fax" svgDocFocus="svgFocus" inputField="input"
                    classes="s-l s-full"></app-generic-input>
            </div>
            <div id="contactDetails-email" class="declDiv">
                <app-generic-input [form]="contactDetails" controlName="email" svgDocFocus="svgFocus" inputField="input"
                    classes="s-l s-full"></app-generic-input>
            </div>


        </ng-container>


        <div id="date" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="date" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full"></app-generic-input>
        </div>



    </div>


    <div style="display:none">

        <app-topos-tirisis-eggrafon-print></app-topos-tirisis-eggrafon-print>

    </div>
</ng-container>