import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ToastrService } from 'ngx-toastr';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Filters, ProductNode, TaricService } from '../taric.service';
import { TreeDataSource } from './taric-tree.datasource';

@Component({
    selector: 'taric-tree',
    templateUrl: './taric-tree.component.html',
    styleUrls: ['./taric-tree.component.scss']
})
export class TaricTreeComponent {
    @Input() treeData: ProductNode[];
    @Input() requested: string;
    @Input() filters: Filters;
    @Input() formDisabled$: Observable<boolean>;
    @Input() dataLoading: boolean;

    // filters: Filters;

    private _destroy: Subject<void>;
    showActionButtons: boolean;
    treeControl: NestedTreeControl<ProductNode>;
    treeDataSource: TreeDataSource;
    directMatches: any;

    constructor(private taricService: TaricService, private toastrService: ToastrService, private mainBroadcaster: MainBroadcasterService) {
        this._destroy = new Subject<void>();
        console.log('TARIX Taric Tree Started');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.treeData) {
            const data = changes.treeData.currentValue;
            this.treeControl = new NestedTreeControl<ProductNode>(node => node.children);
            //this.taricService.treeControl.next(this.treeControl);

            this.treeDataSource = new TreeDataSource(this.treeControl, data);

            this.taricService.treeDataSource.next(this.treeDataSource);

            if (this.treeDataSource.data && this.treeDataSource.data.length > 0) {
                this.taricService.expandAll(this.treeDataSource.data);
            }
        }
    }

    ngOnInit() {
        this.taricService.directMatches$.pipe(takeUntil(this._destroy)).subscribe(value => {
            this.directMatches = value;
        });

        this.mainBroadcaster.activeSubdomain$.pipe(takeUntil(this._destroy)).subscribe(subdomain => {
            if (subdomain) {
                this.showActionButtons = true
            }
            else { this.showActionButtons = false; }
        })
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        console.log('TARIX Taric Tree Destroyed');

        this._destroy.next();
        this._destroy.complete();
    }

    hasChildren = (_: number, node: ProductNode) => node.hasChildren;

    expand(parentNode: ProductNode) {
        console.log('expand', parentNode);
        console.log('expandNode function', this.taricService.expandNode(parentNode));

        this.taricService.expandNode(parentNode).subscribe();
    }

    collapse(parentNode: ProductNode) {
        this.taricService.collapseNode(parentNode);
    }

    requestedProduct(node) {
        if (this.isOdd(+this.requested)) {
            this.requested = this.requested.substr(0, -1);
        }
        if (node.code && node.code === this.requested) {
            return true;
        } else {
            return false;
        }
    }

    isOdd(n) {
        return Math.abs(n % 2) === 1;
    }

    private scrollToSectionHook() {
        /*  this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            const tree = this.router.parseUrl(this.router.url);
            if (tree.fragment) {
                const element = document.querySelector('#' + tree.fragment);
                if (element) {
                    setTimeout(() => {
                        element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                    }, 500 );
                }
            }
         }
    });
   */

        const element = document.querySelector('.product-requested');

        if (element) {
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
            }, 500);
        }
    }


    selectProduct(product: ProductNode) {
        if (this.filters.destinationCountry && this.filters.exportCountry) {
            // this.taricService.updateTariffReqParams('taricTree', product, this.filters);

            product.isLoading = true;
            //  this.taricService.updateTariffLoading(true)

            this.taricService.getTariff({
                'applicant': 'taricTree',
                product,
                'filters': this.filters
            }).subscribe(() => {
                //     this.taricService.updateTariffLoading(false)

                product.isLoading = false
            })


        }
        else {

            this.toastrService.warning('Παρακαλώ επιλέξτε χώρα αποστολής/προορισμού.')
        }
    }



    setCodeDesc(event) {
        this.taricService.setCodeDesc(event).subscribe();
    }
}
