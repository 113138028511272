import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { BaseBuildingComponent } from './../../_baseComponent/base.component';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';

@Component({
    selector: 'app-generic-date',
    templateUrl: './generic-date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class GenericDateComponent extends BaseBuildingComponent implements OnInit {

    @Input() formGroup: FormGroup;
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Input() formDisabled: boolean;
    @Input() tabindex: number;
    @Input() hideCal: boolean;
    @Input() controlName: string;
    @Input() showDefaultDate: boolean;



    //choose dateType based on Angular DatePipe format
    dateType = 'yyyy-MM-dd';

    //len is used for minLength validation does not work well for len < 8
    // I have to find smth else
    len: number = this.dateType.length;

    // uncomment below if you want a default date
    //custom
    //defaultDate: Date = new Date('12/01/2019');
    //today
    defaultDate: Date;

    get control(): FormControl {
        return this.formGroup.get(this.controlName) as FormControl;
    }

    constructor(
        protected hostElement: ElementRef,
        protected cf: ExtCustomFunctions,
        private DateTimeConvert: DateTransformPipe
    ) {
        super(hostElement, cf)


    }

    ngOnInit() {



        const date = this.formGroup.get(this.controlName);
        const status = date?.status;

        //IF there is a value but form is disabled (submitted)
        // show saved Date

        if (date?.value && status === 'DISABLED') {
            this.defaultDate = this.DateTimeConvert.getDate(date.value);
        }
        else if (this.showDefaultDate && !this.formDisabled) {
            this.defaultDate = new Date()
        }
        else {
            this.defaultDate = null
        }
        //if form is not disabled, use a new date
        //even if it has a value(meaning it was saved as draft from a differrent day)
        //and when it doesn't have a date meaning form is new

    }
}
