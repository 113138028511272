import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { environment } from 'environments/environment';
import { BusinessModel } from 'app/main/businesses/invoices/_models/business/business.model';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class BusinessService {
    private businesses: BehaviorSubject<BusinessModel[]> = new BehaviorSubject<BusinessModel[]>(null);
    private currentBusiness: BehaviorSubject<BusinessModel> = new BehaviorSubject<BusinessModel>(null);

    public businessesFetched = new BehaviorSubject<boolean>(false);

    businesses$: Observable<BusinessModel[] | null>;
    currentBusiness$: Observable<BusinessModel>;



    PHP_API_SERVER = environment.API_URL;


    constructor(private http: HttpClient, private injector: Injector) {

        console.log("BUSINESS SERVICE CONSTRUCTOR")

        this.businesses$ = this.businesses.asObservable();
        this.currentBusiness$ = this.currentBusiness.asObservable();

    }

    fetchBusinesses(overrideCache = false): Observable<BusinessModel[]> {

        console.log("businessG fetchBusiness overrideCache", overrideCache)

        if (this.businesses.value && !overrideCache) {
            return of(this.businesses.value)
        }
        else {
            console.trace("businessG fetchBusiness")

            return this.http.get<BusinessModel[]>(`${this.PHP_API_SERVER}/businesses/`).pipe(
                switchMap(businesses => {
                    console.log("businessG fetchBusiness", businesses)

                    this.businesses.next(businesses);
                    this.businessesFetched.next(true)
                    console.log("businessG currentBusiness", this.currentBusiness.value)


                    if (this.currentBusiness.value === null) {
                        return this.setDefaultBusiness().pipe(map(() => businesses))
                    }

                    return of(businesses)


                    // Set the default business
                }), take(1));
        }
    }

    setDefaultBusiness(currentId?: string): Observable<BusinessModel> {

        const businesses = this.businesses.value
        let defaultBusiness: BusinessModel;

        const urlBusiness = businesses.find(business => business.business_id === currentId);

        console.log("businessG fetchBusines currentId", currentId)
        console.log("businessG fetchBusines urlBusiness", urlBusiness?.business_name)

        if (urlBusiness) {
            defaultBusiness = urlBusiness;
        }
        else {
            // Find the default business or get the first business
            defaultBusiness = businesses.find(business => business.is_default_business) || businesses[0];
        }

        console.log("businessG fetchBusinessModels defaultBusiness", defaultBusiness.business_name)

        return this.injector.get(AuthService).businessAuthenticate(defaultBusiness).pipe(map(() => defaultBusiness))

    }

    getBusinessById(businessId: string): BusinessModel {
        const businesses = this.businesses.getValue();
        return businesses.find(business => business.business_id === businessId) || null;
    }

    setCurrentBusiness(business: BusinessModel): void {
        this.currentBusiness.next(business);
        console.log("businessG setCurrentBusinessModel", business?.business_name)

    }


    hasAccess(requiredApps: string[]): boolean {
        const currentBusiness = this.currentBusiness.getValue();
        const currentApps = currentBusiness?.apps || [];


        if (!currentBusiness || !currentApps) {
            return false;
        }
        const hasAccess = requiredApps.every(requiredApp => currentApps.includes(requiredApp));

        return hasAccess;
    }

    setBusinesses(businesses: BusinessModel[]): void {
        this.businesses.next(businesses);
    }


}
