import { CountryPipeModule } from 'app/services/pipes/countryName.pipe';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { FuseConfirmDialogModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { CountrySelectModule } from 'app/main/main-building-blocks/form-fields/country-select/country-select.module';
import { ContactsContactFormDialogComponent } from './contact-form.component';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { AddDelButtonsModule } from 'app/main/main-building-blocks/_scams/add-del-buttons/add-del-buttons.module';

@NgModule({
    declarations: [ContactsContactFormDialogComponent],
    imports: [CountrySelectModule, FuseSharedModule, FuseConfirmDialogModule, MaterialModule, CountryPipeModule, AddDelButtonsModule],
    providers: [

        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { panelClass: 'mat-dialog-override' }
        },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ]
})
export class ContactFormModule { }
