<div class="tsipoura-header">
    <div [formGroup]="tsipouraForm" class="noPrint" style="display:flex;justify-content:space-between;width:100%">
        <table style="width:50%">
            <colgroup>
                <col style="width:20%">
                <col style="width:11%">
                <col style="width:12%">
                <col style="width:17%">
                <col style="width:7%">
                <col style="width:25%">

            </colgroup>
            <tbody>
                <tr>
                    <td>ΥΠΟΧΡΕΟΣ ΥΠΟΒΟΛΗΣ</td>
                    <td colspan="4" style="line-height:2.3">
                        <mat-form-field class="excel-form-field sc-ul s-32">
                            <autocomplete-control formControlName="obligee" class="autocomplete-excel-input"
                                [options]="pelates" [config]="pelConfig" (keyDown.Escape)="$event.stopPropagation();
                                $event.preventDefault();">
                            </autocomplete-control>

                        </mat-form-field>
                    </td>
                    <td> 
                        <button mat-raised-button class=" accent" style="font-size:12px; line-height: 2.4;"
                            (click)="createContact()">
                            Νέος Πελάτης
                        </button>
                    </td>
                 
                </tr>
                <tr>
                    <td>ΚΑΤΟΧΟΣ ΑΜΒΥΚΑ</td>
                    <td colspan="4" style="line-height:2.3">
                        <mat-form-field class="excel-form-field sc-ul s-32">
                            <autocomplete-control formControlName="boilerOwner" class="autocomplete-excel-input"
                                [options]="pelates" [config]="pelConfig" (keyDown.Escape)="$event.stopPropagation();
                                $event.preventDefault();">
                            </autocomplete-control>

                        </mat-form-field>
                    </td>
                   
                    <td>

                    </td>
                </tr>
                <tr>
                    <td>Α.Γ.Μ.</td>
                    <td>
                        <input style="width:80px" class="excel-input text-bold" formControlName="agm">
                    </td>
                    <td>ΑΡΙΘ. ΑΔΕΙΑΣ</td>
                    <td colspan="2">
                        <input style="width:195px" class="excel-input text-bold" formControlName="boilerRegistrationNumber">
                    </td>
                </tr>
                <tr>
                    <td>ΧΩΡΗΤΙΚΟΤΗΤΑ ΑΜΒΥΚΑ (lt)</td>
                    <td>
                        <input style="width:80px" class="excel-input text-bold" digitOnly
                            formControlName="boilerCapacity">
                    </td>
                    <td>ΓΕΩΓ. ΣΤΙΓΜΑ</td>
                    <td colspan="2">
                        <input style="width:195px" class="excel-input text-bold" formControlName="boilerGeoLocation">
                    </td>
                </tr>


            </tbody>
        </table>
        <table style="width:35%; margin-left:24px;">
            <colgroup>
                <col style="width:25%">
                <col style="width:60%">

            </colgroup>
            <tbody>

                <tr>

                    <td>Έναρξη Παραγωγής</td>

                    <td>
                        <input tabindex="-1" style="width:160px" class="excel-input"
                            [matDatetimepicker]="startDatetimePicker" autocomplete="false"
                            (dateChange)="changeDateTimeEvent($event, 'startDate')" formControlName="startDate" />

                        <mat-datetimepicker-toggle [for]="startDatetimePicker" matSuffix
                            class="noPrint"></mat-datetimepicker-toggle>
                        <mat-datetimepicker tabindex="-1" #startDatetimePicker type="datetime" [openOnFocus]="false"
                            timeInterval="30" class="noPrint">
                        </mat-datetimepicker>
                    </td>
                </tr>
                <tr>

                    <td>Λήξη Παραγωγής</td>
                    <td>
                        <input tabindex="-1" style="width:160px"  autocomplete="false" formControlName="endDate"
                            [matDatetimepicker]="endDatetimePicker"
                            (dateChange)="changeDateTimeEvent($event, 'endDate')" />

                        <mat-datetimepicker-toggle tabindex="-1" [for]="endDatetimePicker" class="noPrint" matSuffix
                            ></mat-datetimepicker-toggle>
                        <mat-datetimepicker tabindex="-1" #endDatetimePicker class="noPrint" type="datetime"
                            timeInterval="30" >
                        </mat-datetimepicker>
                    </td>
                </tr>
                <tr>
                    <td>Ημέρες Εργασίας</td>
                    <td>
                        <input tabindex="-1" style="width:160px" class="text-bold" readonly autocomplete="false"
                            [value]="totalDays" />
                    </td>
                </tr>

            </tbody>
        </table>
    </div>


    <div class="printOnly">
        <div style="display:flex;justify-content:space-between;width:100%">
            <table style="width:45%">
                <colgroup>
                    <col style="width:45%">
                    <col style="width:55%">

                </colgroup>
                <tbody>
                    <tr>
                        <td>ΚΑΤΟΧΟΣ ΑΜΒΥΚΑ</td>
                        <td class="text-bold">
                            {{tsipouraForm.get('boilerOwner')?.value?.fullname}}
                        </td>
                    </tr>
                    <tr>
                        <td>Α.Γ.Μ.</td>
                        <td class="text-bold">
                            {{tsipouraForm.get('agm').value}}
                        </td>
                    </tr>
                    <tr>
                        <td>ΧΩΡΗΤΙΚΟΤΗΤΑ ΑΜΒΥΚΑ (lt)</td>
                        <td class="text-bold">
                            {{tsipouraForm.get('boilerCapacity').value}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table style="width:30%; margin-left:24px;">
                <colgroup>
                    <col style="width:50%">
                    <col style="width:50%">
                </colgroup>
                <tbody>
                    <tr>
                        <td>Έναρξη Παραγωγής</td>
                        <td class="text-center">
                            {{tsipouraForm.get('startDate').value | date:'dd/MM/yyyy HH:mm'}}
                        </td>
                    </tr>
                    <tr>
                        <td>Λήξη Παραγωγής</td>
                        <td class="text-center">
                            {{tsipouraForm.get('endDate').value | date:'dd/MM/yyyy HH:mm'}}
                        </td>
                    </tr>
                    <tr>
                        <td>Ημέρες Εργασίας</td>
                        <td class="text-center text-bold">
                            {{totalDays}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>