import { ConfirmDialogConfig, OpenDialogsService } from 'app/services/openDialogs.service';
import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { genericItem } from 'app/model/api-model';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { Subject, takeUntil } from 'rxjs';
import { DaoDeclarationFormService } from '../dao-declaration/dao-declaration-form.service';

export interface VehicleDetailsData {

    VehicleDetailsFormGroup: FormGroup;
    VehicleDetailsIndex: number;
    formDisabled: boolean

}


@Component({
    selector: 'app-vehicle-details-dialog',
    templateUrl: './vehicle-details-dialog.component.html',
    styleUrls: ['./vehicle-details-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DaoVehicleDetailsDialogComponent {
    VehicleDetailsFormGroup: FormGroup;
    VehicleDetailsIndex: number;
    vehicleInventory: FormArray;


    color1: string;
    color2: string;
    showButtons: boolean;

    carKindOptions: genericItem[] = [];
    carBrandOptions: genericItem[] = [];
    stoixeioApografikouOpt: genericItem[] = [];

    master_control_carKind = new FormControl();
    master_control_carBrand = new FormControl();
    master_control_carUsed = new FormControl();

    dateType = 'yyyy-MM-dd';
    defaultDate: Date;

    checkboxChecked: boolean;
    formDisabled: boolean;




    config: AutoCompConfigValues = {
        output: 'code',
        menuOptions: {
            options: ['code', 'desc']
        }
    };



    private _destroy: Subject<void> = new Subject();

    get carKindControl(): FormControl {
        return this.VehicleDetailsFormGroup.get('CarKind') as FormControl;
    }
    get carBrandControl(): FormControl {
        return this.VehicleDetailsFormGroup.get('ImporterCodeOrBrandName') as FormControl;
    }
    get carUsedControl(): FormControl {
        return this.VehicleDetailsFormGroup.get('VehicleUsedFlag') as FormControl;
    }

    constructor(
        public dialogRef: MatDialogRef<DaoVehicleDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VehicleDetailsData,
        private daoDeclarationFormService: DaoDeclarationFormService,
        private cf: ExtCustomFunctions,
        private DateTimeConvert: DateTransformPipe,
        private dbQuery: ApiService,
        private openDialogsService: OpenDialogsService

    ) {

        console.log("VehicleArrivalConsignee on dialogRef", data)
        this.VehicleDetailsFormGroup = data.VehicleDetailsFormGroup
        this.VehicleDetailsIndex = data.VehicleDetailsIndex
        this.showButtons = !data.formDisabled;
        this.formDisabled = data.formDisabled;


    }


    ngOnInit() {

        this.vehicleInventory = this.VehicleDetailsFormGroup.get('VehicleInventory') as FormArray;

        //=------------------------------------------------------------------
        this.dbQuery.get_options(ApiEndPoints.eidi_oximaton)
            .subscribe((data: genericItem[]) => {
                this.carKindOptions = data;
            });


        //-----------------------------------------------

        this.dbQuery.get_options(ApiEndPoints.car_brands)
            .subscribe((data: genericItem[]) => {
                this.carBrandOptions = data;

            });

        //=========================================================
        this.dbQuery.get_options(ApiEndPoints.stoixeio_apografikou)
            .subscribe((data: genericItem[]) => {
                this.stoixeioApografikouOpt = data;

            });



        //--------------------------------
        const date = this.VehicleDetailsFormGroup.get('FirstAllowanceDate');
        //IF there is a value but form is disabled (submitted)
        // show saved Date
        if (date?.value) {
            this.defaultDate = this.DateTimeConvert.getDate(date.value);
        }

        //========================================================================



        if (this.carUsedControl.value === 1) {
            this.checkboxChecked = true
        }
        else { this.checkboxChecked = false }


        this.carUsedControl.valueChanges.pipe(takeUntil(this._destroy)).subscribe(value => {

            this.setUsedFlagValidators(value)
        })



    }

    ngOnDestroy() {
        this.deleteEmpty()
        this._destroy.next()
        this._destroy.complete()
    }

    deleteEmpty() {
        const emptyArray = this.cf.deleteEmpty(this.vehicleInventory);
        //start deleting empty entries
        emptyArray.forEach(value => {
            this.delete(value);
        });
    }

    onChange(event) {

        if (event.checked === true) {
            this.carUsedControl.setValue(1)
        }
        else {
            this.carUsedControl.setValue(0)

        }


    }


    setUsedFlagValidators(value: 0 | 1) {
        if (value === 1) {
            this.VehicleDetailsFormGroup.get('Mileage').setValidators(Validators.required)
        }
        else {
            this.VehicleDetailsFormGroup.get('Mileage').removeValidators(Validators.required)
        }
        this.VehicleDetailsFormGroup.get('Mileage').updateValueAndValidity()

    }

    add() {
        this.daoDeclarationFormService.addVehicleInventoryArray(this.VehicleDetailsIndex);

        /*      if (formArrayName === 'GOOITEGDS') {
                 setTimeout(() => {
                     this._goodsPanel.last.open();
                 }, 100);
                 setTimeout(() => {
                     this._combinedCode.last.nativeElement.focus();
                 }, 500);
             } */
    }

    delete(i) {
        this.daoDeclarationFormService.deleteFormArray('VehicleInventory', i, this.VehicleDetailsFormGroup);

        if (this.vehicleInventory.length === 0) {
            this.add();
        }
    }

    hideAdd(formArray: FormArray) {
        return this.cf.hideAdd(formArray, 99);
    }
    hideDelete(formArray: FormArray) {
        return this.cf.hideDelete(formArray);
    }


    onCloseConfirm() {
        if (this.VehicleDetailsFormGroup.valid && !this.VehicleDetailsFormGroup.get('VehicleUsedFlag').value) {
            this.VehicleDetailsFormGroup.get('VehicleUsedFlag').setValue(0);
        }
        this.dialogRef.close();
    }
    cancel() {
        //   this.VehicleArrivalConsignee.get('VehicleTaxWarehouseReference').reset()
        //   this.VehicleArrivalConsignee.get('DeliveryPlace').reset()

        if (!this.formDisabled) {

            const config: ConfirmDialogConfig = {
                message: 'Τα στοιχεία που έχουν εισαχθεί πρόκειται να διαγραφούν. Θέλετε να συνεχίσετε;',
                title: 'Διαγραφή Στοιχείων',
                confirmButton: 'Ναι',
                cancelButton: 'Όχι',
                confirmClass: 'warn',
                width: '40%',
                height: '35%'
            }
            this.openDialogsService.openConfirmDialog(config).subscribe((result) => {


                if (result) {
                    this.VehicleDetailsFormGroup.reset();
                    this.dialogRef.close();

                }


            })
        }
        else {
            this.dialogRef.close();
        }

    }
    changeStyle1(event) {
        this.color1 = event.type === 'mouseenter' ? 'accent' : '';
    }
    changeStyle2(event) {
        if (this.VehicleDetailsFormGroup.valid) {
            this.color2 = event.type === 'mouseenter' ? 'accent' : 'primary';
        }
    }









}
