import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
//import { MyGroupForm } from "../../my-group/_models/mygroup-form.model";
//import { MyGroup } from "../../my-group/_models/mygroup.model";
//import { PlayerForm, Player } from "../../player";
import { ArrAtExitModel } from './arrivaAtExit.model';

import { TraexicarForm } from './traexicar/traexicar-form.model';
import { TraexicarModel } from './traexicar/traexicar.model';
import { AceAceForm } from './aceace/aceace-form.model';
import { AceAceModel } from './aceace/aceace.model';
import { ArrAtExitHeaheaForm } from './heahea/heahea-form.model';
import { ArrAtExitHeaheaModel } from './heahea/heahea.model';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';

export class ArrAtExitForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    SynIdeMES1: FormControl;
    SynVerNumMES2: FormControl;
    MesSenMES3: FormControl;
    MesRecMES6: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    IntConRefMES11: FormControl;
    TesIndMES18: FormControl;
    // PriMES15: FormControl;
    // ComAgrIdMES17: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    // ComAccRefMES21: FormControl;
    HEAHEA: FormGroup;
    ACEACE: FormGroup;
    TRAEXICAR: FormGroup;
    /*
  
   */

    constructor(declaration: ArrAtExitModel, subdomain?: string, messageType?: string) {

        console.log('DECLARATION-FORM-MODEL CONSTRUCTOR', declaration);
        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));


        this.SynIdeMES1 = new FormControl(declaration.SynIdeMES1);
        this.SynVerNumMES2 = new FormControl(declaration.SynVerNumMES2);
        this.MesSenMES3 = new FormControl(declaration.MesSenMES3);
        this.MesRecMES6 = new FormControl(declaration.MesRecMES6);
        this.DatOfPreMES9 = new FormControl(declaration.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(declaration.TimOfPreMES10);
        this.IntConRefMES11 = new FormControl(declaration.IntConRefMES11);
        this.TesIndMES18 = new FormControl(declaration.TesIndMES18);
        this.MesIdeMES19 = new FormControl(declaration.MesIdeMES19);
        this.MesTypMES20 = new FormControl(declaration.MesTypMES20);

        //     this.PriMES15 = new FormControl(declaration.PriMES15);
        //     this.ComAgrIdMES17 = new FormControl(declaration.ComAgrIdMES17);
        //     this.ComAccRefMES21 = new FormControl(declaration.ComAccRefMES21);

        this.HEAHEA = new FormGroup(new ArrAtExitHeaheaForm(new ArrAtExitHeaheaModel(declaration.HEAHEA)));

        this.ACEACE = new FormGroup(new AceAceForm(new AceAceModel(declaration.ACEACE)));

        this.TRAEXICAR = new FormGroup(new TraexicarForm(new TraexicarModel(declaration.TRAEXICAR)));
    }
}
