import { DEFKContactDetailsModel } from "../contactDetails/contactDetails.model";

export class TaxesObligeeModel {
    ObligeeType?: string;
    ObligeeIdentificationType?: string;
    ObligeeIdentification?: string;
    ContactDetails?: DEFKContactDetailsModel;
    ObligeeObject?: {};


    constructor(value?) {
        this.ObligeeType = value?.ObligeeType || null;
        this.ObligeeIdentificationType = value?.ObligeeIdentificationType || null;
        this.ObligeeIdentification = value?.ObligeeIdentification || null;
        this.ContactDetails = value?.ContactDetails || null;
        this.ObligeeObject = value?.ObligeeObject || null;

    }
}
