import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Sort } from 'app/services/datasource/page';
import { PaginatedDataSource } from 'app/services/datasource/paginated-datasource';
import { CustomQuery } from './customs-list/customs-list.component';
import { Custom } from './customs.model';
import { CustomsService } from './customs.service';

@Injectable()
export class CustomsResolver implements Resolve<PaginatedDataSource<Custom, CustomQuery>[]> {
    initialSort: Sort<Custom> = {
        property: 'CountryCode',
        order: 'desc'
    };

    constructor(private _customsService: CustomsService) {
        console.log('CUSTMS resolver hit');
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const allCustoms = new PaginatedDataSource<Custom, CustomQuery>(
            (request, query) => this._customsService.getCustoms(request, query),
            this.initialSort,
            { search: '' },
            10
        );

        console.log('CUSTMS dataSource:', allCustoms);
        return allCustoms;
    }
}
