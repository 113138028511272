<div fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div>Ρυθμίσεις Εκτύπωσης Εγγράφων</div>

        <div>
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="printSettingsForm" fxLayout="row" fxLayoutAlign="space-around start" mat-dialog-content
        [formGroup]="printSettingsForm">
        <!-- LEFT SIDE -->
        <div class="slider-container" fxLayout="row" style="width: 95%; flex-wrap: wrap">
            <ng-container formArrayName="settings" *ngFor="let item of settings?.controls; let i = index">
                <mat-card [formGroupName]="i" class="print-setting">
                    <app-print-settings-slider [DocumentSettings]="item"></app-print-settings-slider>
                </mat-card>
            </ng-container>
        </div>
    </div>
    <!--     <div>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button
                style="width: auto !important"
                mat-raised-button
                [ngClass]="color1"
                (mouseenter)="changeStyle1($event)"
                (mouseleave)="changeStyle1($event)"
                (click)="onCloseConfirm()"
            >
                Ακυρο
            </button>
            <button
                style="width: auto !important"
                mat-raised-button
                [ngClass]="color2"
                (mouseenter)="changeStyle2($event)"
                (mouseleave)="changeStyle2($event)"
                (click)="onSubmit()"
            >
                Υποβολή
            </button>
        </mat-dialog-actions>
    </div>
 -->
</div>