import { ArchiveSaveDataCommon } from "app/main/apps/archive/archive.model";
import { ePaymentResponseModel } from "app/main/apps/e-payment/_models/e-payment-response.model";
import { AgrCertModel } from "app/main/apps/export/agrcert/_models/agr.model";
import { CmrModel } from "app/main/apps/export/cmr/_models/cmr.model";
import { OriginCertModel } from "app/main/apps/export/origin-cert/_models/origin-cert.model";
import { DeclarationModels } from "app/services/submitServices/submitForm.service";

export class SaveDataModel implements ArchiveSaveDataCommon {
    domain: string;
    subdomain: string;
    messageType: string;
    messageIn?: string;
    userId: number;
    lrn: string;
    state: string;
    documentType: string;
    declarationForm: DeclarationModels; //Declartion | ImportDeclarationModel
    id?: string;
    eur1Form?: OriginCertModel;
    inf4Form?: OriginCertModel;
    agrCertForm?: AgrCertModel;
    cmrForm?: CmrModel;
    remittance?: ePaymentResponseModel;
    declarantId?: string;
    traderId?: string;
    customerId?: number;
    mrn?: string;
    description?: string;
    stateDate?: string;
    submissionDate?: string;
    noNotification?: boolean;
    originalDocType?: string;
    originalDocId?: string;
    referrerDeclarationId?: string;
    last_modified?: string;

    constructor(value?) {
        if (value) {
            this.id = value.id; //
            this.domain = value.domain; //
            this.subdomain = value.subdomain; //
            this.messageType = value.messageType; //
            this.messageIn = value.messageIn; //

            this.userId = value.userId; //
            this.lrn = value.lrn; //
            this.state = value.state; //
            this.documentType = value.documentType; //
            this.declarationForm = value.declarationForm; //
            this.eur1Form = value.eur1Form; //
            this.agrCertForm = value.agrCertForm; //
            this.cmrForm = value.cmrForm; //
            this.remittance = value.remittance; //
            this.declarantId = value.declarantId; //
            this.traderId = value.traderId; //
            this.customerId = value.customerId; //
            this.mrn = value.mrn; ///
            this.description = value.description; //
            this.submissionDate = value.submissionDate; //
            this.stateDate = value.stateDate; //
            this.noNotification = value.noNotification; //
            this.originalDocType = value.originalDocType; //
            this.originalDocId = value.originalDocId; //
            this.referrerDeclarationId = value.referrerDeclarationId; //
            this.last_modified = value.last_modified;
        }
    }
}
