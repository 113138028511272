import { FormControl, FormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, Component, ElementRef, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { takeUntil } from 'rxjs';
import { AutoCompConfigValues } from '../../form-fields/custom-autocomplete/config.model';
import { BaseBuildingComponent } from '../../_baseComponent/base.component';

@Component({
    selector: 'app-generic-flag',
    templateUrl: './generic-flag.component.html',
    styleUrls: ['./generic-flag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class GenericFlagComponent extends BaseBuildingComponent {

    @Input() formGroup: FormGroup;
    @Input() options: { code: string, desc: string }[];
    @Input() controlName: string;
    @Input() formDisabled: boolean;
    @Input() classes: string;
    @Input() required: boolean;
    @Input() appearance: string;
    @Input() trapFocus: boolean;

    config: AutoCompConfigValues = {
        display: { values: ['desc'] },
        menuOptions: {
            options: ['code', 'desc']
        }
    };

    defaultOptions = [{ code: "0", desc: "ΟΧΙ" }, { code: "1", desc: "ΝΑΙ" }]

    master_control = new FormControl()

    get control(): FormControl {
        return this.formGroup.get(this.controlName) as FormControl;
    }

    //===============================================================

    constructor(protected hostElement: ElementRef, protected cf: ExtCustomFunctions) {
        super(hostElement, cf)
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("GenericFlag changes", changes)
        setTimeout(() => {
            const fdChanged: SimpleChange = changes.formDisabled;
            const formDisabled = fdChanged?.currentValue

            const formGroupChanged: SimpleChange = changes.formGroup;
            const currformGroupStatus = formGroupChanged?.currentValue?.status

            if (formDisabled || currformGroupStatus === 'DISABLED') {
                this.master_control.disable()
            } else if (formDisabled === false && currformGroupStatus !== 'DISABLED') {
                this.master_control.enable()
            }


        })


    }

    ngOnInit() {
        if (!this.options) {
            this.options = this.defaultOptions
        }
        const initialValue = this.options.filter(e => e.code === this.control?.value)[0]

        console.log("MASTER_CONTROL INIT VALUE", initialValue)
        this.master_control.setValue(initialValue)


        this.master_control.valueChanges.pipe(takeUntil(this._destroy)).subscribe(value => {

            console.log("MASTER_CONTROL VALUE", value)
            this.control.setValue(value?.code)
        });
    }



}
