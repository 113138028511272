export class TraConce2Model {
    NamCE27?: string;
    StrAndNumCE222?: string;
    PosCodCE223?: string;
    CitCE224?: string;
    CouCE225?: string;
    NADLNGGICE?: string;
    TINCE259?: string;
    TINTypCE260?: string; //NCTS ONLY
    ConsigneeObject?: {};

    constructor(value?) {
        this.NamCE27 = value?.NamCE27 || null;
        this.StrAndNumCE222 = value?.StrAndNumCE222 || null;
        this.PosCodCE223 = value?.PosCodCE223 || null;
        this.CitCE224 = value?.CitCE224 || null;
        this.CouCE225 = value?.CouCE225 || null;
        this.NADLNGGICE = value?.NADLNGGICE || null;
        this.TINCE259 = value?.TINCE259 || null;
        this.TINTypCE260 = value?.TINCE259 || null;
        this.ConsigneeObject = value?.ConsigneeObject || null;
    }
}
