import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'distillation-licence-app-svg',
    templateUrl: './distillation-licence-app-svg.component.svg',
    styleUrls: ['./distillation-licence-app-svg.component.scss']
})
export class DistillationLicenceAppSvgComponent {

    constructor() { }


}
