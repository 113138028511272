export class WarIDGI700Model {
    WartypWARIDGI10?: string;
    AutCouWARIDGI20?: string;
    WarIdeWARIDEGI19?: string;

    constructor(value?) {
        this.WartypWARIDGI10 = value?.WartypWARIDGI10 || null;
        this.AutCouWARIDGI20 = value?.AutCouWARIDGI20 || null;
        this.WarIdeWARIDEGI19 = value?.WarIdeWARIDEGI19 || null;
    }
}
