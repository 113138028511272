import { _pelatesItem } from "app/model/api-model";
import { PrivateControlsModel } from "app/model/private-controls.model";
import { EMCSHeaderModel } from "../../../_models/emcs-header.model";
import { DaoBodyModel } from "./Body/body.model";

export class DaoDeclarationModel {
    _PRIVATE: PrivateControlsModel;
    Header: EMCSHeaderModel;
    Body: DaoBodyModel;

    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);
        this._PRIVATE = value?._PRIVATE || null;
        this.Header = value?.Header;
        this.Body = value?.Body;
    }
}
