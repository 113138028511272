import { Injectable } from "@angular/core";
import { CanLoad, Router, Route, UrlSegment } from "@angular/router";
import { BusinessService } from "app/main/businesses/services/business.service";
import { Observable, filter, of, switchMap, take } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class BusinessGuard implements CanLoad {
    constructor(
        private businessService: BusinessService,
        private router: Router
    ) {

    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {

        const businessId = segments?.length > 0 ? segments?.[1]?.path : null;
        console.log("businessGuard businessId", businessId)

        return this.businessService.businessesFetched.pipe(

            filter(fetched => fetched === true),
            take(1),
            switchMap(_ => {

                //Check that the business exists
                const business = this.businessService.getBusinessById(businessId);
                console.log("businessGuard business", business?.business_name)


                if (business) {
                    this.businessService.setCurrentBusiness(business);
                    return of(true);

                } else {
                    this.router.navigate(['/']); // Navigate to an error page if the office does not exist
                    return of(false);
                }

            })
        )

    }
}
