import { FormControl, AbstractControl } from '@angular/forms';
import { RefDeclModel } from './refDecl.model';

export class RefDeclForm {
    [key: string]: AbstractControl;

    DocRefNumREFDEC = new FormControl();

    constructor(value: RefDeclModel) {
        this.DocRefNumREFDEC.setValue(value?.DocRefNumREFDEC);
    }
}
