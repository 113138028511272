<div [formGroup]="customsGroup"
    [matTooltip]="showTooltip ? (customsGroup.get(controlName).value | customsName : { customs, name:officeName})  : null"
    matTooltipClass="tooltip" fxLayout="row" fxLayoutAlign="space-between start">
    <div fxLayout="row" style="width:85%;">
        <mat-form-field class="s-c s-2" [ngClass]="{'sc-ul reset': resetStyle}" [appearance]='appearance'
            *ngIf="showCountry">
            <country-select #countryInput formControlName="_countryCode" [customsCountries]="true" (focus)="onFocus()">
            </country-select>
        </mat-form-field>

        <mat-form-field class="s-c s-8 ml-2" [class]="classes"
            [ngClass]="{'sc-ul reset': resetStyle, 's-12': appearance==='outline'}" [appearance]='appearance'>
            <autocomplete-control #firstInput [formControlName]="controlName" [trapFocus]="trapFocus"
                [options]="customs" [config]="config" placeholder="" (focus)="onFocus()" (keydown.Enter)="
                $event.stopImmediatePropagation()
            " (keyup.Enter)="onEnter()" (blur)="onError(hasError(control))" [tabindex]="tabindex"
                [required]="required">
            </autocomplete-control>

            <!--   <mat-error *ngIf="control1.hasError('required') && control1.touched"
            >Required</mat-error
        > -->
        </mat-form-field>
        <div *ngIf="showCustomsName" class="customsOfficeName" [style]="OfficeNameStyle"
            [ngClass]="{customsOfficeNameMargin: appearance==='outline', disabled:formDisabled}"> {{CustomsOfficeName}}
        </div>

    </div>
    <div *ngIf="!formDisabled && showCountry" style="margin-top: -4px" class="ScreenMedia"
        [ngClass]="{'reset': resetStyle}">
        <button mat-icon-button (click)="openDialog()" tabindex="-1">
            <dots></dots>
        </button>
    </div>
</div>