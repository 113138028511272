import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    encryptKey = 'AKJHGDlkhkjdhsl;kjq098w92ujkkawlikh2KJH2;l;Jk';

    /**
     * this function is used for decrypting the data using cryptoJS
     *
     * @params data
     * @params key
     */

    decrypt(data: string, key: string = this.encryptKey): string {
        return AES.decrypt(data, key).toString(enc.Utf8);
    }

    /**
     * this function is used to encrypt the data using cryptoJS
     *
     * @param data
     * @param key
     */
    encrypt(data: string, key: string = this.encryptKey): string {
        return AES.encrypt(data, key).toString();
    }

    /**
     * @param digit
     * @return number
     */
    randomNumber(digit = 60000) {
        return Math.floor(100000 + Math.random() * digit);
    }
}
