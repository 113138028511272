export class SpeMenModel {
    AddInfCodMT23?: string;
    TexSPM1?: string;
    TexSPM1LNG?: string;

    constructor(value?) {
        this.AddInfCodMT23 = value?.AddInfCodMT23 || null;
        this.TexSPM1 = value?.TexSPM1 || null;
        this.TexSPM1LNG = value?.TexSPM1LNG || null;
    }
}
