<div class="general-applications">
    <div mat-dialog-title class="primary">
        <div fxLayout="column">
            <span>{{dialogTitle}}</span>

        </div>
        <div>
            <button *ngIf="allApplications" mat-icon-button [matMenuTriggerFor]="loadMenu" (menuOpened)="onMenuOpen()"
                matTooltip="Άνοιγμα" matTooltipClass="tooltip">
                <mat-icon> archive</mat-icon>
            </button>

            <button mat-icon-button (click)="onSave()" matTooltip="Αποθήκευση" matTooltipClass="tooltip">
                <mat-icon> save</mat-icon>
            </button>

            <button mat-icon-button (click)="onPrint()" matTooltip="Εκτύπωση" matTooltipClass="tooltip">
                <mat-icon> print</mat-icon>
            </button>
            <button mat-icon-button (click)="onCloseConfirm()" matTooltip="Κλείσιμο" matTooltipClass="tooltip">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div mat-dialog-content style="background-color: white;">

        <ng-template content-container></ng-template>
    </div>




    <mat-menu #loadMenu="matMenu">
        <mat-form-field class="px-6 w-100-p" (click)="$event.stopPropagation()" floatLabel="never">
            <input #searchInput matInput placeholder="Αναζήτηση δήλωσης..." [(ngModel)]="searchText"
                (keydown)="$event.stopPropagation()" />
        </mat-form-field>

        <mat-divider></mat-divider>

        <mat-nav-list *ngIf="!searching" style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
            <mat-list-item *ngFor="let application of allApplications$ | async | filterNames:searchText:'description'"
                (click)="onLoad($event, application)">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">



                    <p matLine fxFlex>{{ application?.description }}</p>

                    <div class="ml-8 app-delete-div" (click)="$event.stopPropagation();delete(application)">
                        <mat-icon class="red-fg">delete</mat-icon>
                    </div>


                </div>
            </mat-list-item>

            <mat-list-item *ngIf="allApplications.length === 0">
                <p>
                    <small>Δεν υπάρχουν αποθηκευμένες δηλώσεις!</small>
                </p>
            </mat-list-item>
        </mat-nav-list>


    </mat-menu>

</div>