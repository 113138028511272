import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import moment from 'moment';
import { tap, take, Observable, map, first, of, filter, combineLatest } from 'rxjs';
import { MainBroadcasterService } from 'app/services/broadcaster.service';

@Injectable({
    providedIn: 'root'
})
export class LrnService {

    constructor(
        private mainBroadcaster: MainBroadcasterService
    ) { }

    public setRefNumber(declarationForm: FormGroup): Observable<{ lrn: string }> {
        console.log("lrnService declarationForm on setRefNumber", declarationForm)

        const stateCode = declarationForm.get('_PRIVATE').get('stateCode').value;
        console.log('lrnService stateCode: ', stateCode);
        const lrn = declarationForm.get('_PRIVATE').get('lrn').value;

        console.log('lrnService RefNumber: ', lrn);

        //if it is an already saved form
        if (stateCode !== 'Pre-Submitted') {

            this.setLrn(declarationForm, lrn);
            return of({ lrn })
        }

        //if is draft or template get new RefNumber
        else {

            console.log('lrnService setting new LRN')
            return this.setNewLrn(declarationForm);
        }
    }

    public setNewLrn(declarationForm: FormGroup): Observable<{ lrn: string }> {
        console.trace('setNewLrn')
        //get RefNumber and afm
        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value;

        return this.formatLRN(subdomain).pipe(
            tap((value: { lrn }) => {

                console.log('lrnService DECLFORMSERVICE: setting LRN', value.lrn);

                const lrn_control = this.getLRNControl(declarationForm);

                if (lrn_control) {
                    lrn_control.setValue(value.lrn);
                }

                declarationForm.get('_PRIVATE').get('refNumber')?.setValue(value.lrn);
                declarationForm.get('_PRIVATE').get('lrn').setValue(value.lrn)

            }),
            take(1)
        );
    }

    private setLrn(declarationForm: FormGroup, lrn: string) {

        //refNUmber and LRN are the same
        console.log('lrnService setting LRN', lrn);

        const lrn_control = this.getLRNControl(declarationForm);

        lrn_control.setValue(lrn);

        declarationForm.get('_PRIVATE').get('refNumber').setValue(lrn);
        declarationForm.get('_PRIVATE').get('lrn').setValue(lrn)

    }

    private getLRNControl(declarationForm: FormGroup): FormControl {

        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value

        if (subdomain === 'DAO') {

            return declarationForm.get('Body').get('VehicleArrivalDeclarationRegistration').get('VehicleArrival').get('LocalReferenceNumber') as FormControl;
        }
        else if (subdomain === 'DEFK') {

            return declarationForm.get('Body').get('DeclarationOfExciseTaxesRegistration').get('ExciseTaxesDeclaration').get('LocalReferenceNumber') as FormControl;
        }
        else if (subdomain === 'POUS') {

            return declarationForm.get('LRN') as FormControl;
        }
        else {
            return declarationForm.get('HEAHEA').get('RefNumHEA4') as FormControl;
        }
    }


    private formatLRN(subdomain?: string): Observable<{ lrn: string }> {
        const currentDeclarant$ = this.mainBroadcaster.currentDeclarant$;
        const lrns$ = this.mainBroadcaster.lrns$

        return combineLatest([currentDeclarant$, lrns$]).pipe(
            filter(([currentDeclarant, lrns]) => Boolean(currentDeclarant?.declarant && lrns)),
            map(([currentDeclarant, lrns]) => {
                const declarant = currentDeclarant?.declarant
                console.log('lrnService formatLRN currentDeclarant', currentDeclarant)

                let lrn = '';
                if (declarant?.is_client || subdomain === 'POUS') {
                    const timestampInMilliseconds = new Date().getTime().toString();

                    lrn = timestampInMilliseconds;

                    return { lrn };

                }

                const afm = declarant.afm;
                const year = moment(new Date()).format('YY');


                console.log('lrnService declarant.id', declarant.id)
                console.log('lrnService lrns', lrns)
                //find initial LRN on lrns array where declarant id is the same as the current declarant id
                const initialLRN = lrns.find((lrn) => lrn.declarantId === declarant.id)?.initialLRN.toString();

                console.log('lrnService formatLRN initialLRN', initialLRN);

                const refNumber = initialLRN.length < 7 ? ('000000' + initialLRN).substr(-6) : initialLRN;

                lrn = afm + '/' + year + '/' + refNumber;
                return { lrn };


            }),
            first()
        );
    }
}
