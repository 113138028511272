<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
            <!-- SHORTCUTS -->
            <div class="px-10 px-md-16">
                <!-- <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts> -->
                <ext-shortcuts></ext-shortcuts>
            </div>
            <!-- /SHORTCUTS -->
            <!-- /SEPARATOR -->
            <!-- 

            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && !rightNavbar"
            ></div>
 -->

            <!-- /SEPARATOR -->

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg" />
                </div>
            </div>
        </div>
        <div style="width:26%" class="px-6 px-md-64" *ngIf="testing">
            <img src="assets/images/general/test.png" width="190" height="64" alt="test" />
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button *ngIf="loggedin" mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">

                    <span class="username mr-12">{{ office?.office_name }}</span>
                    <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <button *ngIf="!loggedin" mat-button class="user-button" (click)="login()">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="username mr-12">Login</span>
                </div>
            </button>

            <mat-menu class="wv-business-switcher__panel" #userMenu="matMenu" [overlapTrigger]="true">
                <toolbar-profile-menu></toolbar-profile-menu>
            </mat-menu>

            <div class="toolbar-separator"></div>
            <fuse-fullscreen></fuse-fullscreen>
            <div class="toolbar-separator"></div>
            <activity-leds></activity-leds>
            <div class="toolbar-separator"></div>

            <!--   <fuse-search-bar (input)="search($event)"></fuse-search-bar>
 -->
            <div class="toolbar-separator"></div>

            <!--             <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
 -->
            <button mat-button class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="
                            'assets/icons/flags/' +
                            selectedLanguage.flag +
                            '.png'
                        " />
                    <span class="iso text-uppercase">{{
                        selectedLanguage.id
                        }}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/' + lang.flag + '.png'" />
                        <span class="iso">{{ lang.title }}</span>
                    </span>
                </button>

                <button mat-menu-item [routerLink]="'/components/multi-language'">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>Learn more</span>
                    </div>
                </button>
            </mat-menu>

            <div class="toolbar-separator"></div>

            <!--  <button
                mat-icon-button
                fxHide.gt-md
                class="chat-panel-toggle-button"
                (click)="toggleSidebarOpen('chatPanel')"
                aria-label="Toggle chat panel"
            >
                <mat-icon class="secondary-text">chat</mat-icon>
            </button> -->

            <div class="toolbar-separator"></div>

            <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar"></div>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')">
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>