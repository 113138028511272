import { Injectable } from '@angular/core';
import { countriesItem } from 'app/model/api-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ApiEndPoints, ApiService } from './api.service';
import { MainBroadcasterService } from './broadcaster.service';

@Injectable({
    providedIn: 'root'
})
export class CountriesService {
    countries: countriesItem[];


    constructor(private dbQuery: ApiService, private mainBroadcaster: MainBroadcasterService) {

    }

    getCountry(countryCode) {
        if (countryCode && countryCode !== null) {
            return this.countries.find(country => country.country_code === countryCode);
        }
    }
    getCountries(): Observable<countriesItem[]> {

        return this.dbQuery
            .get_options(ApiEndPoints.countries)
            .pipe(tap(countries => {
                this.mainBroadcaster.updateCountries(countries)
                this.countries = countries;
            }),
                take(1))
    }
}
