<div id="eAitisi" fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 20%">
            <button mat-icon-button [matMenuTriggerFor]="moreMenu" matTooltip="Επιλογές" class="sidebar-toggle mr-12">
                <mat-icon>menu</mat-icon>
            </button>

            <span>Αίτηση Δ.Ε.Τ.Ε.</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" style="margin-left: 70px">
            <h3>
                <span *ngIf="stateCode">{{
                    stateCode | declarationState
                    }}</span>
            </h3>

            <div *ngIf="
            (eAitisiForm.valid || eAitisiForm.status === 'DISABLED') &&
                    stateCode !== '???ics_paid???' &&
                    stateCode !== 'ics_paid' &&
                    stateCode !== 'Cancelled' &&
                    stateCode !== 'Pre-Submitted' &&
                    stateCode !== 'Edit'
                " class="m-0" id="b-circle">
                <div class="outer-circle"></div>

                <div class="circle" [ngClass]="stateCode | headerClass"></div>

                <!-- EDIT REJECTED FORM -->

                <button *ngIf="stateCode === 'Rejected'" class="sendButton" style="top: -3px" (click)="edit()"
                    matTooltip="Διόρθωση & Επανάληψη Αποστολής" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
                <!-- //EDIT REJECTED FORM -->
                <!-- FORM SUBMITTED CHECK STATE -->

                <button *ngIf="
                        stateCode === 'Submitted' ||
                        stateCode === 'finalized' ||
                        stateCode === '???finalized???' ||
                        stateCode === 'Reversed' ||
                        stateCode === 'Sent' ||
                        stateCode === 'Under Amendment Request' ||
                        stateCode === 'Cancellation Submitted' ||
                        stateCode === 'Accepted' ||
                        stateCode === 'To be Accepted'
                    " class="sendButton" style="top: -3px" (click)="checkState()" mat-icon-button
                    matTooltip="Έλεγχος Κατάστασης">
                    <mat-icon>autorenew</mat-icon>
                </button>
                <!-- ///FORM SUBMITTED CHECK STATE -->
            </div>

            <!-- If state is Paid -->
            <div *ngIf="
                    stateCode === '???ics_paid???' ||
                    stateCode === 'ics_paid' ||
                    stateCode === 'Cancelled' ||
                    stateCode === 'Pre-Submitted' ||
                    stateCode === 'Edit' ||
                    stateCode === 'Sent'
                " class="m-0" id="b-circle"></div>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="flex-start center" mat-dialog-content style="padding: 0 16px;"
        [formGroup]="eAitisiForm">
        <div *ngIf="heahea.get('DocNumHEA5').value" fxLayout="column" style="width: 100%">
            <div fxLayout="column" class="group">
                <!-- FIRST ROW -->
                <table class="eAitisiHea twoColumns-results">
                    <tbody>
                        <tr [formGroup]="heahea">
                            <td style="width: 35%">
                        <tr>
                            <td style="width: 35%"><b>MRN</b></td>
                            <td>
                                <mat-form-field class="s-c sc-ul s-22" appearance="outline">
                                    <input matInput style="font-size:16px;" formControlName="DocNumHEA5" maxlength="21"
                                        readonly />
                                </mat-form-field>
                            </td>
                        </tr>
                        </td>
                        <td>
                            <tr *ngIf="private.get('taxAmount').value">
                                <td style="width: 38%">
                                    <b>Ποσό Επιβάρυνσης</b>
                                </td>
                                <td [formGroup]="private">
                                    <mat-form-field class="s-c sc-ul s-22" appearance="outline">
                                        <input matInput formControlName="taxAmount" maxlength="21" readonly />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </td>
                        </tr>

                        <tr [formGroup]="heahea">
                            <td>
                        <tr>
                            <td style="width: 35%">
                                <b>Ημ/νία Αποδοχής</b>
                            </td>
                            <td>
                                <mat-form-field appearance="outline" class="s-c sc-ul s-22">
                                    <input matInput [formControl]="acceptanceDate" maxlength="10" readonly />
                                </mat-form-field>
                            </td>
                        </tr>
                        </td>
                        <td>
                            <tr *ngIf="heahea.get('AmeAccDatHEA602').value">
                                <td style="width: 18.5%">
                                    <b>Ημ/νία Αποδοχής Διόρθωσης</b>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline" class="s-c sc-ul s-22">
                                        <input matInput [formControl]="
                                                    amendAcceptanceDate
                                                " maxlength="10" readonly />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%">
            <!-- LEFT SIDE -->
            <div fxLayout="column" fxLayoutAlign="center start" style="width: 48%">
                <div fxLayout="column" class="group">
                    <h2>Βασικά Στοιχεία</h2>

                    <!-- FIRST ROW -->
                    <table class="eAitisiHea fourColumns">
                        <tbody>
                            <tr [formGroup]="heahea">
                                <td>Αριθμός Αναφοράς</td>
                                <td colspan="3">
                                    <mat-form-field class="s-c sc-ul s-22" appearance="outline" required>
                                        <input matInput formControlName="RefNumHEA4" maxlength="21" required />
                                    </mat-form-field>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr [formGroup]="cusoff">
                                <td>Τελωνείο</td>
                                <td>
                                    <mat-form-field appearance="outline" class="s-c sc-ul s-4">
                                        <country-select formControlName="_countryCode">
                                        </country-select>
                                    </mat-form-field>
                                </td>

                                <td colspan="2">
                                    <mat-form-field class="s-c sc-ul s-12" appearance="outline" required>
                                        <autocomplete-control formControlName="RefNumIMPCUSOFF" [options]="customs"
                                            [config]="customsConfig" (keyDown.Escape)="$event.stopPropagation();
                                            $event.preventDefault();">
                                        </autocomplete-control>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr [formGroup]="heahea">
                                <td>Τρόπος Πληρωμής</td>
                                <td>
                                    <mat-form-field class="s-c sc-ul s-4" appearance="outline" required>
                                        <autocomplete-control formControlName="MetOfPayHEA590" [options]="payments">
                                        </autocomplete-control>
                                    </mat-form-field>
                                </td>
                                <td colspan="2"> {{heahea.get('MetOfPayHEA590').value | codeToDesc:payments}}</td>
                            </tr>
                            <tr formArrayName="REFDECL" *ngFor="
                                    let item of refdecl?.controls;
                                    let i = index
                                ">
                                <td *ngIf="i < 1">Αναφερόμενα Παραστατικά</td>
                                <td *ngIf="i >= 1"></td>
                                <td colspan="2" [formGroupName]="i">
                                    <mat-form-field class="s-c sc-ul s-22" appearance="outline">
                                        <input matInput formControlName="DocRefNumREFDEC" maxlength="21" required />
                                    </mat-form-field>
                                </td>
                                <td class="add_del_buttons">
                                    <add-del-buttons *ngIf="showButtons" [show]="showButtons"
                                        [hideAdd]="hideAdd(refdecl)" [hideDelete]="hideDelete(refdecl)"
                                        (addButton)="add('REFDECL')" (deleteButton)="delete('REFDECL', i)">
                                    </add-del-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div fxLayout="column" class="group">
                    <h2>Είδη</h2>

                    <!-- FIRST ROW -->
                    <table class="eAitisiHea twoColumns-goods">
                        <tbody formArrayName="GOOITEGDS" *ngFor="
                                let item of gooitegds?.controls;
                                let i = index
                            ">
                            <tr [formGroupName]="i">
                                <td style="padding: 5px 0">
                                    <mat-expansion-panel #goodsPanel>
                                        <mat-expansion-panel-header class="specific-class" (click)="
                                                expandPanel(goodsPanel, $event)
                                            ">
                                            <mat-panel-title style="margin: 0 0.25em" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                <span> Είδος {{ i + 1 }} </span>
                                                <span *ngIf="
                                                        item
                                                            .get('COMCODGODITM')
                                                            .at(0)
                                                            .get(
                                                                '_combinedComCode'
                                                            ).value
                                                    "><b>
                                                        :
                                                        {{
                                                        item
                                                        .get(
                                                        "COMCODGODITM"
                                                        )
                                                        .at(0)
                                                        .get(
                                                        "_combinedComCode"
                                                        ).value
                                                        }}
                                                    </b>
                                                </span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <ng-template matExpansionPanelContent>
                                            <div id="pd-additional-fields" fxLayout="column">
                                                <div id="pd-add-fields-1" fxLayout="row" fxLayoutAlign="space-between">
                                                    <table>
                                                        <!--                                  COMCODE                              -->
                                                        <tr formArrayName="COMCODGODITM" *ngFor="
                                                                let comcode of item.get(
                                                                    'COMCODGODITM'
                                                                ).controls
                                                            ">
                                                            <td>Κωδικός</td>
                                                            <td formGroupName="0">
                                                                <mat-form-field class="sc-ul s-c s-12"
                                                                    appearance="outline">

                                                                    <input #combinedCode matInput
                                                                        formControlName="_combinedComCode"
                                                                        maxlength="10" minlength="10"
                                                                        (blur)="decomposeComCode(comcode)" />


                                                                </mat-form-field>
                                                            </td>

                                                            <td formGroupName="0">
                                                                <mat-form-field class="sc-ul s-c s-6"
                                                                    appearance="outline">
                                                                    <input matInput formControlName="TARFirAddCodCMD1"
                                                                        maxlength="4" />
                                                                </mat-form-field>
                                                            </td>
                                                            <td formGroupName="0">
                                                                <mat-form-field class="sc-ul s-c s-6"
                                                                    appearance="outline">
                                                                    <input matInput formControlName="TARSecAddCodCMD1"
                                                                        maxlength="4" />
                                                                </mat-form-field>
                                                            </td>
                                                            <td formGroupName="0">
                                                                <mat-form-field class="sc-ul s-c s-6"
                                                                    appearance="outline">
                                                                    <input matInput formControlName="NAtAddCodCMD1"
                                                                        maxlength="4" />
                                                                </mat-form-field>
                                                            </td>
                                                        </tr>
                                                        <!--                                  /COMCODE                              -->
                                                        <!--                                  DESCRIPTION                              -->
                                                        <tr>
                                                            <td style="
                                                                    vertical-align: middle;
                                                                ">
                                                                Περιγραφή
                                                            </td>
                                                            <td colspan="5" style="
                                                                    padding-bottom: 16px;
                                                                ">
                                                                <mat-form-field class="s-30" style="
                                                                        position: relative;
                                                                        top: 14px;
                                                                    " appearance="outline">
                                                                    <textarea #autosize="cdkTextareaAutosize" style="
                                                                            overflow: hidden;
                                                                            line-height: 1.3vw;
                                                                        " matInput cdkTextareaAutosize
                                                                        cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                                                                        matInput formControlName="GooDesGDS23"
                                                                        maxlength="280"></textarea>
                                                                </mat-form-field>
                                                            </td>
                                                        </tr>
                                                        <!--                                 /DESCRIPTION                              -->
                                                        <!--                                  STATVALUE                              -->
                                                        <tr>
                                                            <td>Αξία</td>
                                                            <td colspan="5">
                                                                <mat-form-field appearance="outline"
                                                                    class="s-l sc-ul s-14">
                                                                    <input matInput maxlength="14" digitOnly
                                                                        decimal="true"
                                                                        formControlName="StaValAmoGDI1" />
                                                                </mat-form-field>
                                                            </td>
                                                        </tr>
                                                        <!--                                  /STATVALUE                              -->
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </mat-expansion-panel>
                                </td>

                                <td class="add_del_buttons">
                                    <add-del-buttons *ngIf="showButtons" [show]="showButtons"
                                        [hideAdd]="hideAdd(gooitegds)" [hideDelete]="hideDelete(gooitegds)"
                                        (addButton)="add('GOOITEGDS')" (deleteButton)="delete('GOOITEGDS', i)">
                                    </add-del-buttons>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- RIGHT SIDE -->
            <div fxLayout="column" fxLayoutAlign="center start" style="width: 48%; margin-bottom: 16px">
                <div fxLayout="column" fxLayoutAlign="center start" class="group">
                    <h2>Υποβάλλων</h2>
                    <table class="eAitisiHea twoColumns" [formGroup]="tradec">
                        <tbody>
                            <tr>
                                <td>Επωνυμία</td>
                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <autocomplete-control formControlName="DeclarantObject" [options]="traders"
                                            [config]="consConfig" (keyDown.Escape)="$event.stopPropagation();
                                            $event.preventDefault();">
                                        </autocomplete-control>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td *ngIf="tradec.get('IDTypDECL').value === 10">
                                    EORI
                                </td>
                                <td *ngIf="tradec.get('IDTypDECL').value === 20">
                                    ΑΦΜ
                                </td>
                                <td *ngIf="!tradec.get('IDTypDECL').value"></td>

                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34" required>
                                        <input matInput formControlName="IDNumDECL" readonly tabindex="-1" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <mat-expansion-panel #tradecPanel>
                                        <mat-expansion-panel-header class="specific-class" (click)="
                                                expandPanel(tradecPanel, $event)
                                            ">
                                            <mat-panel-title style="margin: 0 0.25em">
                                                <b>Διεύθυνση</b>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <ng-template matExpansionPanelContent>
                                            <div id="pd-additional-fields" fxLayout="column">
                                                <div id="pd-add-fields-1" fxLayout="row" fxLayoutAlign="space-between">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    Διεύθυνση
                                                                </td>
                                                                <td>
                                                                    <mat-form-field appearance="outline"
                                                                        class="s-l sc-ul">
                                                                        <input matInput
                                                                            formControlName="StrAndNumTDE1" />
                                                                    </mat-form-field>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Τ.Κ.</td>
                                                                <td>
                                                                    <mat-form-field appearance="outline"
                                                                        class="s-l sc-ul">
                                                                        <input matInput formControlName="PosCodTDE1" />
                                                                    </mat-form-field>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Πόλη</td>
                                                                <td>
                                                                    <mat-form-field appearance="outline"
                                                                        class="s-l sc-ul">
                                                                        <input matInput formControlName="CiTDE1" />
                                                                    </mat-form-field>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Χώρα</td>
                                                                <td>
                                                                    <mat-form-field appearance="outline"
                                                                        class="s-l sc-ul">
                                                                        <country-select formControlName="CouCodTDE1"
                                                                            placeholder="Χώρα">
                                                                        </country-select>
                                                                    </mat-form-field>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </mat-expansion-panel>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start" class="group">
                    <h2>Εκτελωνιστής</h2>
                    <table class="eAitisiHea twoColumns" [formGroup]="trarep">
                        <tbody>
                            <tr>
                                <td>Ονοματεπώνυμο</td>
                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <autocomplete-control formControlName="DeclarantObject" [options]="declarants"
                                            [config]="declConfig" (keyDown.Escape)="$event.stopPropagation();
                                            $event.preventDefault();">
                                        </autocomplete-control>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>ΑΦΜ</td>

                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <input matInput formControlName="VatNumTRE" />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start" class="group">
                    <h2>Είδη Εργασιών</h2>
                    <table class="eAitisiHea fourColumns-tasks">
                        <tbody>
                            <tr [formGroup]="heahea">
                                <td>Εργασίες</td>
                                <td colspan="2">
                                    <mat-form-field class="s-l sc-ul dont-float s-34" floatLabel="never"
                                        appearance="outline" required>
                                        <mat-label>Επιλογή</mat-label>
                                        <mat-select name="CustomsTasks" formControlName="CustomsTasks">
                                            <mat-option value="0">
                                                Περιγραφή Εργασιών Γενικού
                                                Σκοπού
                                            </mat-option>
                                            <mat-option value="1">
                                                Τελωνειακές εργασίες
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr [formGroup]="heahea" *ngIf="heahea.get('CustomsTasks').value==='0'">
                                <td style="vertical-align: middle">
                                    Περιγραφή Εργασιών Γενικού Σκοπού
                                </td>
                                <td colspan="2">
                                    <mat-form-field class="s-34" style="position: relative; top: 14px"
                                        appearance="outline">
                                        <textarea #autosize="cdkTextareaAutosize" style="
                                                overflow: hidden;
                                                line-height: 1.3vw;
                                            " matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
                                            cdkAutosizeMaxRows="8" matInput formControlName="GenrlPurpsTsksDescr"
                                            maxlength="280"></textarea>
                                    </mat-form-field>
                                </td>
                            </tr>

                            <ng-container *ngIf="heahea.get('CustomsTasks').value==='1'">
                                <tr formArrayName="TASKS" *ngFor="
                                        let task of tasks?.controls;
                                        let i = index
                                    ">
                                    <ng-container [formGroupName]="i">
                                        <td *ngIf="i < 1">
                                            Ενδεικτικές Εργασίες
                                        </td>
                                        <td *ngIf="i >= 1"></td>
                                        <td>
                                            <mat-form-field class="s-c sc-ul s-4" appearance="outline">
                                                <autocomplete-control #customsTaskType formControlName="CustomsTaskType"
                                                    [options]="codes">
                                                </autocomplete-control>
                                            </mat-form-field>
                                        </td>
                                        <td> {{task.get('CustomsTaskType').value | codeToDesc:codes}}
                                        </td>
                                        <td class="add_del_buttons">
                                            <add-del-buttons *ngIf="showButtons" [show]="showButtons"
                                                [hideAdd]="hideAdd(tasks)" [hideDelete]="hideDelete(tasks)" (addButton)="
                                                    add('TASKS')
                                                " (deleteButton)="
                                                    delete('TASKS', i)
                                                "></add-del-buttons>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showButtons">
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="onCloseConfirm()">
                Ακυρο
            </button>
            <button style="width: auto !important" mat-raised-button
                [disabled]="!eAitisiForm.valid || eAitisiForm.disabled" [ngClass]="color2"
                (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onSubmit()">
                Υποβολή
            </button>
        </mat-dialog-actions>
    </div>
</div>

<mat-menu #moreMenu="matMenu">
    <mat-nav-list style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
        <mat-list-item [ngClass]="{
            buttonDisabled: heahea.get('MetOfPayHEA590').value!=='H' || (stateCode !== 'finalized' && stateCode !== '???finalized???') || 
                (icisOnline$ | async)===false || (serverOnline$ | async)===false
        }" (click)="ePayment()">
            <!--  <mat-list-item (click)="ePayment('remittance')">
-->
            <div class="w-100-p" fxLayout="row">
                <p matLine fxLayout="row" fxLayoutAlign="center center">
                    Ηλεκτρονικές Πληρωμές
                </p>
            </div>
        </mat-list-item>
        <mat-list-item [ngClass]="{
                buttonDisabled:
                    stateCode !== 'To be Accepted' && stateCode !== 'Accepted'
            }">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="modifyDeclaration()">
                <p matLine fxLayout="row" fxLayoutAlign="start center">
                    Τροποποίηση
                </p>
            </div>
        </mat-list-item>
        <mat-list-item [ngClass]="{
                buttonDisabled:
                    stateCode !== 'To be Accepted' &&
                    stateCode !== 'Accepted' &&
                    stateCode !== 'Rejected' &&
                    stateCode !== '???ics_paid???'
            }">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="start" (click)="cancelDeclaration()">
                <p matLine fxLayout="row" fxLayoutAlign="start center">
                    Ακύρωση
                </p>
            </div>
        </mat-list-item>

        <mat-list-item *ngIf="stateCode === 'Edit'" (click)="cancelModify()">
            <div class="w-100-p" fxLayout="row">
                <p matLine fxLayout="row" fxLayoutAlign="center center">
                    Ακύρωση Τροποποίησης
                </p>
            </div>
        </mat-list-item>

        <mat-list-item *ngIf="stateCode === 'Pre-Submitted'" [ngClass]="{
            buttonDisabled: stateCode !== 'Pre-Submitted'}" (click)="getXML()">
            <div class="w-100-p" fxLayout="row">
                <p matLine fxLayout="row" fxLayoutAlign="center center">
                    Δημιουργία αρχείου XML
                </p>
            </div>
        </mat-list-item>
    </mat-nav-list>
</mat-menu>