import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintSettingsComponent } from './print-settings/print-settings.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { PrintSettingsSliderComponent } from './print-settings/print-settings-slider/print-settings-slider.component';

@NgModule({
    declarations: [PrintSettingsComponent, PrintSettingsSliderComponent],
    imports: [FuseSharedModule, MaterialModule]
})
export class AppSettingsModule {}
