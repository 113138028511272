import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomsDialogContentService {
    roles: any[];
    trafficType: any[];
    notes: any[];
    geoInfo: any[];

    constructor(private dbQuery: ApiService) {

    }

    init() {


        this.dbQuery
            .get_customs_extras('Role')
            .pipe(take(1))
            .subscribe(value => (this.roles = value));

        this.dbQuery
            .get_customs_extras('SpecificNotesCode')
            .pipe(take(1))
            .subscribe(value => (this.notes = value));

        this.dbQuery
            .get_customs_extras('TrafficType')
            .pipe(take(1))
            .subscribe(value => (this.trafficType = value));

        this.dbQuery
            .get_customs_extras('GeoInfoCode')
            .pipe(take(1))
            .subscribe(value => (this.geoInfo = value));
    }

    getRole(code) {
        if (code && this.roles) {
            const note = this.roles.find(e => e.Role === code);

            if (note.el !== null) {
                return note.el;
            } else {
                return note.en;
            }
        }
        return null;
    }
    getNote(code) {
        if (code && this.notes) {
            const note = this.notes.find(e => e.SpecificNotesCode === code);

            if (note.el !== null) {
                return note.el;
            } else {
                return note.en;
            }
        }
        return null;
    }

    getGeoInfo(code) {
        if (code && this.geoInfo) {
            const note = this.geoInfo.find(e => e.GeoInfoCode === code);

            if (note.el !== null) {
                return note.el;
            } else {
                return note.en;
            }
        }
        return null;
    }

    getTrafficType(code) {
        if (code && this.trafficType) {
            const note = this.trafficType.find(e => e.TrafficType === code);

            if (note.el !== null) {
                return note.el;
            } else {
                return note.en;
            }
        }
        return null;
    }
}
