import { DatePipe } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateTransform',
    pure: true
})
export class DateTransformPipe implements PipeTransform {

    constructor(private datepipe: DatePipe) { }

    transform(value: any, format?: string): any {

        console.log("dateTransform value", value)
        console.log("dateTransform format", format)

        if (/\d{8}/.test(value) || /\d{4}-\d{2}-\d{2}/.test(value)) {
            return this.formatDate(value, format)

        }




        return value;
    }


    /**
 * This function takes a date input and outputs a string of the format we provide (e.g. dd/MM/yyyy, yyyMMmdd)
 *
 * @param date
 * @param format
 */
    convertDate(date, format: string): string {
        const d = new Date(date);
        const day = this.datepipe.transform(d, format);

        return day;
    }

    convertDateTime(format: string, date?: string | number | Date): string {
        let d;
        if (date) {
            d = new Date(date);
        } else {
            d = new Date();
        }

        const day = this.datepipe.transform(d, format);

        return day;
    }
    /**
     * @param value Value is string of type yyyyMMddHHmmss
     * Output format is of type yyyy-mm-dd HH:mm:ss
     */
    formatDate3(value: string) {
        const year = value.substring(0, 4);
        const month = value.substring(4, 6);
        const day = value.substring(6, 8);
        const hours = value.substring(8, 10);
        const mins = value.substring(10, 12);
        const secs = value.substring(12, 14);

        const date: string = year + '-' + month + '-' + day + ' ' + hours + ':' + mins + ':' + secs;

        return date;
    }

    /**
     * This function takes a string date of type dd/MM/yyyy and returns a string of type yyyy-mm-dd or mm/dd/yyyy
     *
     * @param dateString
     * @param format valid formats 'yyyy-mm-dd' and 'mm/dd/yyyy'
     */
    formatDate2(dateString: string, format: string) {
        // dd/mm/yyyythis.agrForm.get("dc12_date"
        const c = dateString.split('/');
        let date;
        if (format === 'yyyy-mm-dd') {
            //months start from 0=Jan, we substract 1
            date = c[2] + '-' + c[1] + '-' + c[0];
        } else if (format === 'mm/dd/yyyy') {
            date = c[1] + '/' + c[0] + '/' + c[2];
        }

        return date;
    }

    /**
     *
     * @param dateString Value is string of type 'yyyyMMdd' or type 'yyyy-MM-dd'
     * @param format  Format can be of type dd/mm/yyyy or mm/dd/yyyy or dd/mm
     */
    formatDate(dateString: string, format: string) {
        let year: string;
        let month: string;
        let day: string;
        let date: string;
        let time: string;

        if (!dateString) return null;


        if (/^\d{8}$/.test(dateString)) {
            year = dateString.substring(0, 4);
            month = dateString.substring(4, 6);
            day = dateString.substring(6, 8);
        }

        else if (/^\d{6}$/.test(dateString)) {

            year = '20' + dateString.substring(0, 2);
            month = dateString.substring(2, 4);
            day = dateString.substring(4, 6);
        }
        else if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{2}/.test(dateString)) {
            const dt = dateString.split('T');
            const d = dt[0].split('-');
            const t = dt[1].split('.');

            year = d[0];
            month = d[1];
            day = d[2];

            time = t[0];


        }
        else if (/\d{4}-\d{2}-\d{2}/.test(dateString)) {
            const c = dateString.split('-');

            year = c[0];
            month = c[1];
            day = c[2];
        }

        if (format === 'dd/mm/yyyy') {
            date = day + '/' + month + '/' + year;
            if (time) {
                date += ' ' + time;
            }
        } else if (format === 'mm/dd/yyyy') {
            date = month + '/' + day + '/' + year;
        } else if (format === 'dd/mm') {
            date = day + '/' + month;
        }

        return date;
    }

    /**
     * This function takes a string of type "dd/MM/yyyy" or type "yyyyMMdd" or type "yyMMdd" and returns js Date object
     *
     * @param dateString
     * @returns Date
     */
    getDate(dateString: string): Date {
        let date: Date;
        // dd/mm/yyyy
        if (/\d{2}\/\d{2}\/\d{4}/.test(dateString)) {
            const c = dateString.split('/');
            //months start from 0=Jan, we substract 1
            date = new Date(+c[2], +c[1] - 1, +c[0]);
        } else if (/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(dateString)) {

            const c = dateString.split(' ')[0].split('-')
            //months start from 0=Jan, we substract 1
            date = new Date(+c[0], +c[1] - 1, +c[2]);

        } else if (/\d{4}-\d{2}-\d{2}/.test(dateString)) {
            const c = dateString.split('-');
            //months start from 0=Jan, we substract 1
            date = new Date(+c[0], +c[1] - 1, +c[2]);
        } else if (/^\d{8}$/.test(dateString)) {
            const y = dateString.substring(0, 4);
            const m = dateString.substring(4, 6);
            const d = dateString.substring(6, 8);

            //months start from 0=Jan, we substract 1
            date = new Date(+y, +m - 1, +d);
        }

        else if (/^\d{6}$/.test(dateString)) {

            const y = '20' + dateString.substring(0, 2);
            const m = dateString.substring(2, 4);
            const d = dateString.substring(4, 6);

            //months start from 0=Jan, we substract 1
            date = new Date(+y, +m - 1, +d);

        }

        return date;
    }

    isFormattedDate(str) {
        if (!/\d{2}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(str)) {
            return false;
        }

        return true;
    }

}

@NgModule({
    declarations: [DateTransformPipe],
    exports: [DateTransformPipe],
})
export class DateTransformPipeModule { }