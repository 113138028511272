import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiclesComponent } from './vehicles.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { FormFieldsModule } from 'app/main/main-building-blocks/form-fields/form-fields.module';
import { CarsModule } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars.module';
import { TrucksModule } from 'app/main/apps/excise-helper-tables/vehicles/trucks/trucks.module';
import { VehiclesQueriesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicle-queries.service';
import { VehiclesGeneralCalculationDataComponent } from './vehicles-general-calculation-data/vehicles-general-calculation-data.component';

@NgModule({
    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        CarsModule,
        TrucksModule
    ],
    declarations: [VehiclesComponent, VehiclesGeneralCalculationDataComponent],
    providers: [VehiclesQueriesService]
})
export class VehiclesModule { }
