import { AccountingModel } from 'app/main/apps/export/declaration/_models/gooitegds/accounting/accounting.model';
import { CalTaxModel } from 'app/main/apps/export/declaration/_models/gooitegds/caltaxgod/caltaxgod.model';
import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { Connr2Model } from 'app/main/apps/export/declaration/_models/gooitegds/connr2/connr2.model';
import { PacgsModel } from 'app/main/apps/export/declaration/_models/gooitegds/pacgs2/pacgs2.model';
import { PreAdmRefModel } from 'app/main/apps/export/declaration/_models/gooitegds/preadmrefar2/preadmrefar2.model';
import { ProDocModel } from 'app/main/apps/export/declaration/_models/gooitegds/prodocdc2/prodocdc2.model';
import { SpeMenModel } from 'app/main/apps/export/declaration/_models/gooitegds/spemenmt2/spemenmt2.model';
import { TraConce2Model } from 'app/main/apps/export/declaration/_models/gooitegds/traconce2/traconce2.model';
import { PosPayGI400Model } from './pospaygi400/pospaygi400.model';
import { Rel800Model } from './rel800/rel800.model';
import { TaxAddEle100Model } from './taxaddele100/taxaddele100.model';
import { Traconco2Model } from './traconco2/traconco2.model';
import { ImpVehDetModel } from './vehdet/vehdet.model';
import { WarIDGI700Model } from './waridgi700/waridgi700.model';
import { GrnImpModel } from 'app/main/apps/import/_models/grn_imp/grn-imp.model';

export class ImpGooIteGdsModel {
    IteNumGDS7?: number;
    GooDesGDS23?: string;
    GooDesGDS23LNG?: string;
    GroMasGDS46?: number;
    NetMasGDS48?: number;
    MetOfPayGDI12?: string;
    ProReqGDI1?: string;
    PreProGDI1?: string;
    ComNatProGIM1?: string;
    StaValCurGDI1?: string;
    CouOfOriGDI1?: string;
    SupUniGDI1?: string;
    UNDanGooCodGDI1?: string;
    PlaLoaGOOITE333?: string;
    PlaUnlGOOITE333?: string;
    IdeMeaTraCroBorGDS1031?: string;
    IdeMeaTraCroBorGDS1031LNG?: string;
    NatMeaTraCroBorGDS1032?: string;
    Pre4046?: string;
    Quo4013?: string;
    ProPri4002?: string;
    ProReqEndDat4003?: string;
    IdeOfMeaOfTraArrGDI3003?: string;
    EstOfValGDS14?: string;
    ReconcilGDS15?: string;
    ContacPersonGI65?: string;
    UcrGI56?: string;
    ArrDatGDS150?: string;
    StaValAmoGDI1?: string;
    NatfmeatraatarrGIMES?: string;
    IsSorCerGIMES?: string;
    PREADMREFAR2?: PreAdmRefModel[];
    PRODOCDC2?: ProDocModel[];
    SPEMENMT2?: SpeMenModel[];
    TRACONCO2?: Traconco2Model[];
    COMCODGODITM?: ComCodModel[];
    CALTAXGOD?: CalTaxModel[];
    TRACONCE2?: TraConce2Model[];
    CONNR2?: Connr2Model[];
    PACGS2?: PacgsModel[];
    GRN?: GrnImpModel[];
    VEHDET?: ImpVehDetModel[];
    TAXADDELE100?: TaxAddEle100Model[];
    POSPAYGI400?: PosPayGI400Model[];
    REL800?: Rel800Model[];
    WARIDGI700?: WarIDGI700Model[];

    //For CalculationPurposes ONLY
    ACCOUNTING?: AccountingModel[];

    constructor(value?) {
        // console.log("___GOOITEGDSModel", value);
        if (value) {
            this.IteNumGDS7 = value.IteNumGDS7;
            this.GooDesGDS23 = value.GooDesGDS23;
            this.GooDesGDS23LNG = value.GooDesGDS23LNG;
            this.GroMasGDS46 = value.GroMasGDS46;
            this.NetMasGDS48 = value.NetMasGDS48;
            this.MetOfPayGDI12 = value.MetOfPayGDI12;
            this.ProReqGDI1 = value.ProReqGDI1;
            this.PreProGDI1 = value.PreProGDI1;
            this.ComNatProGIM1 = value.ComNatProGIM1;
            this.StaValCurGDI1 = value.StaValCurGDI1;
            this.CouOfOriGDI1 = value.CouOfOriGDI1;
            this.SupUniGDI1 = value.SupUniGDI1;
            this.UNDanGooCodGDI1 = value.UNDanGooCodGDI1;
            this.PlaLoaGOOITE333 = value.PlaLoaGOOITE333;
            this.PlaUnlGOOITE333 = value.PlaUnlGOOITE333;
            this.IdeMeaTraCroBorGDS1031 = value.IdeMeaTraCroBorGDS1031;
            this.IdeMeaTraCroBorGDS1031LNG = value.IdeMeaTraCroBorGDS1031LNG;
            this.NatMeaTraCroBorGDS1032 = value.NatMeaTraCroBorGDS1032;
            this.Pre4046 = value.Pre4046;
            this.Quo4013 = value.Quo4013;
            this.ProPri4002 = value.ProPri4002;
            this.ProReqEndDat4003 = value.ProReqEndDat4003;
            this.IdeOfMeaOfTraArrGDI3003 = value.IdeOfMeaOfTraArrGDI3003;
            this.EstOfValGDS14 = value.EstOfValGDS14;
            this.ReconcilGDS15 = value.ReconcilGDS15;
            this.ContacPersonGI65 = value.ContacPersonGI65;
            this.UcrGI56 = value.UcrGI56;
            this.ArrDatGDS150 = value.ArrDatGDS150;
            this.StaValAmoGDI1 = value.StaValAmoGDI1;
            this.NatfmeatraatarrGIMES = value.NatfmeatraatarrGIMES;
            this.IsSorCerGIMES = value.IsSorCerGIMES;
            this.PREADMREFAR2 = value.PREADMREFAR2;
            this.PRODOCDC2 = value.PRODOCDC2;
            this.SPEMENMT2 = value.SPEMENMT2;
            this.TRACONCO2 = value.TRACONCO2;
            this.COMCODGODITM = value.COMCODGODITM;
            this.CALTAXGOD = value.CALTAXGOD;
            this.TRACONCE2 = value.TRACONCE2;
            this.CONNR2 = value.CONNR2;
            this.PACGS2 = value.PACGS2;
            this.GRN = value.GRN;
            this.VEHDET = value.VEHDET;
            this.TAXADDELE100 = value.TAXADDELE100;
            this.POSPAYGI400 = value.POSPAYGI400;
            this.REL800 = value.REL800;
            this.WARIDGI700 = value.WARIDGI700;

            this.ACCOUNTING = value.ACCOUNTING;
        }
    }
}
