import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateSelectComponent } from './date-select.component';
import { DatePipe } from '@angular/common';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [DateSelectComponent],
    exports: [DateSelectComponent],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, MaterialModule],
    providers: [DatePipe]
})
export class DateSelectModule {}
