import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ApiService } from 'app/services/api.service';
import { Observable, take, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { Declarant } from 'app/core/user/user.model';


@Injectable({
    providedIn: 'root'
})
export class CustomersService {


    constructor(
        private dbQuery: ApiService,
        private _mainBroadcaster: MainBroadcasterService
    ) { }



    getCarriers(withCache?: boolean): Observable<Contact[]> {
        return this.dbQuery
            .get_carriers(withCache)
            .pipe(
                tap((value: any) => {
                    this._mainBroadcaster.updateCarriers(value);
                    console.log('carriers', value);
                }),
                take(1))
    }
    getDomesticCustomers(withCache?: boolean): Observable<Contact[]> {
        return this.dbQuery
            .get_pelates(withCache)
            .pipe(
                tap((value: any) => {
                    this._mainBroadcaster.updateDomesticCustomers(value);
                    //  console.log(this.domestic);
                }),
                take(1))

    }

    getInternationalCustomers(withCache?: boolean): Observable<Contact[]> {
        return this.dbQuery
            .get_international_customers(withCache)
            .pipe(
                tap((value: any) => {
                    this._mainBroadcaster.updateInternationalCustomers(value);
                }),
                take(1))
    }

    getDeclarants(withCache?: boolean): Observable<Declarant[]> {

        return this.dbQuery
            .get_declarants(withCache)
            .pipe(
                tap((value: any) => {
                    this._mainBroadcaster.updateDeclarants(value);
                    this._mainBroadcaster.updateDefaultDeclarant(value.find((d: Declarant) => d.is_owner === true));
                }),
                take(1)
            )

    }

}