import { Declaration } from 'app/main/apps/export/declaration/_models/declaration.model';


export interface _pelatesItem {
    id?: string;
    type?: string // domenstic, international, carrier
    lastname?: string;
    firstname?: string;
    fullname?: string;
    afm?: number;
    eori?: string;
    passport?: string;
    id_number?: string;
    doy?: string;
    occupation?: string;
    street?: string;
    address2?: string;
    city?: string;
    country?: string;
    phone?: string;
    email?: string;
    postal_code?: string;
    trademark?: string;
    trademarkEn?: string;
    icisCreds?: boolean;
}



export interface countriesItem {
    country_id: number;
    country_code: string;
    country_name: string;
    country_name_en: string;
    customsCountry: number;
    euCountry: number;
}


export interface customsItem {
    customs_id: number;
    customs_int_code: string;
    customs_local_code: number;
    customs_name: string;
    customs_address: string;
    customs_city: string;
}

export interface customsAllItem {
    state?: string;
    activeFrom?: string;
    ReferenceNumber?: string;
    ReferenceNumberHigherAuthority?: string;
    ReferenceNumberTakeOver?: string;
    ReferenceNumberMainOffice?: string;
    ReferenceNumberCompetentAuthorityOfEnquiry?: string;
    ReferenceNumberCompetentAuthorityOfRecovery?: string;
    CountryCode?: string;
    RegionCode?: string;
    UnLocodeId?: string;
    NctsEntryDate?: string;
    NearestOffice?: string;
    PostalCode?: string;
    PhoneNumber?: string;
    TelexNumber?: string;
    FaxNumber?: string;
    EMailAddress?: string;
    GeoInfoCode?: string;
    TraderDedicated?: string;
    CustomsOfficeSpecificNotes?: any[];
    CustomsOfficeLsd?: any[];
    CustomsOfficeTimetable?: any[];
}

export interface exchangeRateItem {
    rate: number;
    currency: string;
}

export interface international_customersItem {
    id: number;
    firstname: string;
    lastname: string;
    fullname: string;
    eori: string;
    passport: string;
    occupation: string;
    street: string;
    city: string;
    country: string;
    phone: string;
    email: string;
    postal_code: string;
    active: number;
}

export interface User {
    id: string;
    firstname: string;
    lastname: string;
    fullname: string;

    afm: string;
    doy: string;
    eori: string;
    occupation: string;
    street: string;
    city: string;
    country: string;
    phone: string;
    email: string;
    postal_code: string;
    decl_status: string;
    business_name: string;
    business_object: string;
    business_afm: string;
    business_doy: string;
    business_address_1: string;
    business_address_2: string;
    business_city: string;
    business_postal_code: string;
    business_phone: string;
    business_fax: string;
    business_email: string;
    business_mobile: string;
    business_toll_free: string;
    business_website: string;
}

export interface kathestosItem {
    id: number;
    code: string;
    desc: string;
    decl_type: string[];
    prev_procedure: string[];
    measures: string[];
}


export interface measuresItem {
    id: number;
    code: string;
    desc: string;
    natCode: string;
    taxFreeCode: string;
    taxPaidCode: string;
}


export interface packagingItem {
    id: number;
    code: string;
    desc: string;
    desc_en: string;
}


export interface genericItem {
    id?: string;
    code: string;
    desc: string;
    title?: string;
    type?: string;
}




export class AttachmentItem {
    public app: 'itrader' | 'taric' | 'remittance';
    public fileToUpload: File;
    public username: string;
    public password: string;
    public domain: string;
    public type: string;
    public key_type: string;
    public key: string;
    public role: string;
    public remarks: string;
    public creds: any;
}

export interface SupUnitDesc {
    short: string;
    long: string;
}
export interface SupUnits {
    index: number;
    supUnit: SupUnitDesc;
}

export interface CalculateTariffRequired {

    commodityCode: boolean;
    country: boolean;
    customsValue: boolean;
    netWeight: boolean;
    preference: boolean;
    supUnits?: boolean;

}
export enum TariffRequired {

    commodityCode = 'Κωδικός TARIC',
    country = 'Χώρα Αποστολής/Εξαγωγής',
    customsValue = 'Στατιστική Αξία',
    netWeight = 'Καθαρό Βάρος',
    preference = 'Προτίμηση',
    supUnits = 'Συμπληρωματικές Μονάδες',

}
export interface CurrencyItem {
    currency: string;
    rate: number;
    valid_from: string; //yyyy-mm-dd
    valid_until: string; //yyyy-mm-dd
    source: string;
}

export interface ExchangeRate {

    currency: string;
    previousCurrency: string;
    retrievedRate: number;
    currentRate: number;
    changedManually: boolean;
}

export interface NationalProductsRequest {
    taricCode: string;
    plato?: string;
}

export interface NationalProduct {
    taricCode: string;
    description: string;
    natCode: string;
    addNatCode: string;
    plato?: string;
}