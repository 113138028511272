import { DescriptionResults } from './product-description.service';
import { ePaymentResponseModel } from './../main/apps/e-payment/_models/e-payment-response.model';
import { NewsType } from './../main/apps/start/start-news/start-news.component';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
    genericItem,
    _pelatesItem,
    User,
    customsAllItem,
    SupUnitDesc,
    NationalProductsRequest,
    NationalProduct
} from '../model/api-model';
import { Observable } from 'rxjs';
import { withCache, CacheBucket, HttpCacheManager } from '@ngneat/cashew';

import { filter, map, share, take } from 'rxjs/operators';
import { Page } from './datasource/page';
import { ArchiveResult } from 'app/main/apps/archive/archive.model';
import { SaveDataModel } from 'app/main/apps/export/declaration/_models';
import { Custom } from 'app/main/apps/customs/customs.model';
import { TaricResult } from 'app/main/apps/taric/taric-results-table/taric-result.model';
import { ProductNode } from 'app/main/apps/taric/taric.service';
import { PrintSettingsModel } from 'app/main/app-settings/app-settings/print-settings/_models/print-settings.model';
import { GetStateParams, DeclarationModels } from './submitServices/submitForm.service';
import { StateResponse } from './declaration-state.service';
import { CustomerStatementRequest, CustomerStatementResponse } from 'app/main/businesses/customer-statements/statements.service';
import { Declarant } from 'app/core/user/user.model';
import { LrnItem } from 'app/services/broadcaster.service';
import { RetrievedDescription } from 'app/services/com-code.service';
import { Co2EmissionCode, CarPriceRange } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.interfaces';
import { InvoiceProductModel } from 'app/main/businesses/invoices/_models/invoice-product/invoice-product.model';


export enum ApiEndPoints {
    //Agriculture Certificate
    agr_remarks = '/select/get/agr_remarks',
    agr_names = '/select/get/agr_names',
    //Declaration
    anastoles = '/select/get/imp_anastoles',
    ateleies = '/ateleies/get_ateleies',
    attached = '/select/get/attached',
    attachedT2L = '/select/get/t2l_certs',

    certificates = '/select/get/certificates',
    control_result = '/select/get/control_result',
    countries = '/select/get/countries',
    currencies = '/select/get/currencies',
    customs = '/select/get/customs',
    dangerous = '/select/get/dangerous',
    declarant_status = '/select/get/declarant_status',
    declarant_status_defk = '/select/get/declarant_status_defk',
    declaration_settings = '/settings/declaration/',
    docs_t1 = '/select/get/docs_t1',
    eAitisi_codes = '/select/get/eAitisi_codes',
    eidi_fortotikis = '/select/get/eidi_fortotikis',
    eidikes_mneies = '/select/get/eidikes_mneies',
    ektimisi_aksias = '/select/get/imp_ektimhsh_aksias',
    epivarinseis = '/select/get/imp_epivarinseis',
    euros_ateleion = '/select/get/euros_ateleion',
    fisi_empor_praxis = '/select/get/fisi_empor_praxis',
    forologikes_apothikes = '/select/get/forologikes_apothikes',
    icisnet_codes = '/select/get/icisnet_codes',
    kathestos = '/select/get/kathestos',
    kathestos_defk = '/select/get/kathestos_defk',
    katigories_pliromon = '/select/get/katigories_pliromon',
    kodikoi_anastolis = '/select/get/kodikoi_anastolis',
    kodikoi_mixanografisis = '/select/get/kodikoi_mixanografisis',
    languages = '/select/get/languages',
    measures = '/ateleies/get_procedure_measures',
    metra_ipologismou_dasmon = '/select/get/imp_metra_ipologismou_dasmon',
    meter_category_codes = '/select/get/meter_category_codes',
    monades_metrisis = '/select/get/monades_metrisis',
    ncts_eidikes_mneies = '/select/get/ncts_eidikes_mneies',
    oroi_paradosis = '/select/get/oroi_paradosis',
    oroi_paradosis_added = '/select/get/oroi_paradosis_added',
    packaging = '/select/get/packaging',
    perifereies_teloneion = '/select/get/perifereies_teloneion',
    pos_1a = '/select/get/1a',
    pos_1b = '/select/get/1b',
    pos_1c = '/select/get/1c',
    pous_docs = '/select/get/pous_docs',
    printSettings = '/settings/print/',
    proigoumena_parastatika_b = '/select/get/proigoumena_parastatika_b',
    proigoumena_parastatika_a = '/select/get/proigoumena_parastatika_a',
    taxAddEle = '/select/get/imp_taxAddEle',
    t2l_prevDocs = '/select/get/t2l_prevDocs',
    protimiseis = '/select/get/imp_protimiseis',
    theme_settings = '/settings/theme/',
    tin_options = '/select/get/ncts_tin_options',
    tipos_metaf_mesou = '/select/get/tipos_metaf_mesou',
    tomeis_ateleion = '/select/get/tomeis_ateleion', //not used
    tropoi_metaforas = '/select/get/tropoi_metaforas',
    vehicles = '/select/get/vehicles',
    warehouse = '/select/get/warehouse',
    exchangeRates = '/exchangeRates/',
    stoixeio_apografikou = '/select/get/emcs_stoixeio_apografikou',
    car_certificate = '/select/get/emcs_car_certificate',
    vessel_type = '/select/get/emcs_vessel_type',
    ports = '/select/get/emcs_ports',
    car_brands = '/select/get/emcs_car_brands',
    eidi_oximaton = '/select/get/emcs_eidi_oximaton',
    emcs_defk_docs = '/select/get/emcs_defk_docs',
    emcs_eidikes_mneies = '/select/get/emcs_eidikes_mneies',
    special_circum = '/select/get/special_circum',
    defk_type_code = '/select/get/defk_type_code',
    efk_tsipoura_params = '/select/get/efk_tsipoura_params',
    efk_beer_products = '/select/get/efk_beer_products',
    efk_beer_params = '/select/get/efk_beer_params',
    efk_car_euro_class = '/select/national/get/vehicle_car_euro_class',
    efk_truck_euro_class = '/select/national/get/vehicle_truck_euro_class',
    efk_car_types = '/select/national/get/vehicle_car_types',
    efk_car_hybrid = '/select/national/vehicle_car_hybrid',
    national_add_codes = '/select/national/get/national_additional_codes',


}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient, private manager: HttpCacheManager) {
    }

    PHP_API_SERVER = environment.API_URL;

    interCustomersBucket = new CacheBucket();
    pelatesBucket = new CacheBucket();
    carriersBucket = new CacheBucket();
    declarantsBucket = new CacheBucket();
    customsBucket = new CacheBucket();
    currentCountry: string;

    //method is called to clear the cache when a customer domestic/international
    // is created, deleted or updated so the get request retrieves new results from the server
    //and not from the cache
    invalidateInterCustomers() {
        this.manager.delete(this.interCustomersBucket);
    }
    invalidatePelates() {
        this.manager.delete(this.pelatesBucket);
    }
    invalidateCarriers() {
        this.manager.delete(this.carriersBucket);
    }
    invalidateDeclarants() {
        this.manager.delete(this.declarantsBucket);
    }
    invalidateCustoms() {
        this.manager.delete(this.customsBucket);
    }

    /**
     * This function that is using a GET request to get the different dropdown options for our forms
     * Takes one paramater that points to the backend endpoint. The function uses the 'withCache' flag
     * which caches the response
     *
     * @param {ApiEndPoints} endpoint
     */
    /*     get_options(endpoint: ApiEndPoints): Observable<any[]> {
        return this.http.get<any[]>(`${this.PHP_API_SERVER}${endpoint}`,
            {
      context: withCache()
    }
        );
    }
 */

    /*  get_taric(search:string, id:number):Observable<any[]>{
        let url = `${this.PHP_API_SERVER}/taric/byCode`;
        let postData = { search, id };
        return this.http.post<any[]>(url, postData);

    } */
    getNews() {
        return this.http.get<NewsType[]>(`${this.PHP_API_SERVER}/aade/getNews`).pipe(take(1));

    }
    get_taric(search: string, id: number): Observable<any[]> {
        const url = `${this.PHP_API_SERVER}/taric/children`;
        const postData = { search, id };
        return this.http.post<any[]>(url, postData);
    }
    getTaricByKeyword(postData): Observable<Page<TaricResult>> {
        const url = `${this.PHP_API_SERVER}/taric/byKeyword`;
        return this.http.post<Page<TaricResult>>(url, postData);
    }
    getSingleProduct(code): Observable<ProductNode> {
        const url = `${this.PHP_API_SERVER}/taric/getSingleProduct`;
        return this.http.post<ProductNode>(url, { search: code });
    }

    getTaricDescriptions(id): Observable<string[]> {
        const url = `${this.PHP_API_SERVER}/taric/getTaricDescriptions`;
        return this.http.post<string[]>(url, { id });
    }
    getDirectMatches(postData): Observable<any[]> {
        const url = `${this.PHP_API_SERVER}/taric/byKeyword`;
        return this.http.post<any[]>(url, postData);
    }

    getRegulation(regulationId): Observable<any[]> {
        const url = `${this.PHP_API_SERVER}/taric/getRegulation`;
        const postData = { regulationId };
        return this.http.post<any[]>(url, postData);
    }

    getTariffs(postData): Observable<any[]> {
        const url = `${this.PHP_API_SERVER}/tariffs/`;
        return this.http.post<any[]>(url, postData);
    }
    /*      getRegulation(type,id):Observable<any[]>{
        let url = `https://webgate.ec.europa.eu/tariffs/public/v1/measures/regulations/type/${type}/regulation/${id}?language=EL`;
        console.log(url);
        return this.http.get<any[]>(url);


    }
 */ //=======================================================================================
    //=======================================================================================
    get_AllCustoms(postData): Observable<Page<Custom>> {
        const url = `${this.PHP_API_SERVER}/customs/get_AllCustoms`;
        return this.http.post<Page<Custom>>(url, postData);
    }

    get_customs(country: string): Observable<any[]> {
        //if the country is different from the previous one, invalidate the cache
        //so the new results are retrieved from the server and not from the cache
        if (!this.currentCountry || this.currentCountry !== country) {
            this.currentCountry = country;
            this.invalidateCustoms();
        }
        const url = `${this.PHP_API_SERVER}/customs/get_customs`;
        const postData = { country };
        return this.http.post<any[]>(url, postData,
            {
                context: withCache({
                    bucket: this.customsBucket
                })
            }
        );

    }

    get_custom(ReferenceNumber: string): Observable<customsAllItem> {
        const url = `${this.PHP_API_SERVER}/customs/get_custom`;
        const postData = { ReferenceNumber };
        return this.http.post<customsAllItem>(url, postData,);
    }

    get_customs_extras(table: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.PHP_API_SERVER}/customs/get/${table}`);
    }

    get_region(postData): Observable<any> {
        const url = `${this.PHP_API_SERVER}/customs/get_region`;
        return this.http.post<any>(url, postData);
    }

    get_customsCountries(): Observable<any[]> {
        return this.http.get<any[]>(`${this.PHP_API_SERVER}/customs/get_customsCountries`, {

            context: withCache()
        });
    }

    //=============================================================================================
    save_options(endpoint: ApiEndPoints, data: any): Observable<any> {
        return this.http.post<any>(`${this.PHP_API_SERVER}${endpoint}`, data);
    }
    get_options(endpoint: ApiEndPoints, cache = true): Observable<any> {

        if (cache) {

            return this.http.get<any[]>(`${this.PHP_API_SERVER}${endpoint}`, {
                context: withCache()
            }).pipe(take(1), share());
        }
        else {
            return this.http.get<any[]>(`${this.PHP_API_SERVER}${endpoint}`).pipe(take(1));
        }
    }

    getNationalProducts(postData: NationalProductsRequest): Observable<NationalProduct[]> {

        return this.http.post<NationalProduct[]>(`${this.PHP_API_SERVER}/select/national_products/`, postData)
    }
    call_GSIS(): Observable<any[]> {
        return this.http.get<any[]>(`${this.PHP_API_SERVER}/gsis/call_GSIS`, {
            context: withCache()
        });
    }

    get_user(): Observable<User> {
        //  return this.http.get<User>(`${this.PHP_API_SERVER}/select/get_user`).pipe(shareReplay());
        return this.http.get<User>(`${this.PHP_API_SERVER}/user/get_user`);
    }

    get_1a(type: string): Observable<genericItem[]> {
        const url = `${this.PHP_API_SERVER}/select/get_1a`;
        const postData = { type };
        return this.http.post<genericItem[]>(url, postData);
    }

    get_business_data(afm): Observable<any> {
        const url = `${this.PHP_API_SERVER}/gsis/getBusinessData`;
        const postData = { afm };
        return this.http.post<any>(url, postData);
    }

    get_eori(eori: string): Observable<any> {
        const url = `${this.PHP_API_SERVER}/gsis/EORI_valid`;
        const postData = { eori };
        return this.http.post<any>(url, postData);
    }

    check_container(conexNum: string): Observable<any> {
        const url = `${this.PHP_API_SERVER}/container_validator/`;
        const postData = { conexNum };
        return this.http.post<any>(url, postData);
    }


    get_carriers(cache = true): Observable<Contact[]> {
        if (!cache) {
            this.invalidateCarriers();
        }
        return this.http.get<Contact[]>(
            `${this.PHP_API_SERVER}/contacts/get/carrier`,

            {
                context: withCache({
                    bucket: this.carriersBucket
                })
            }
        );
    }
    get_international_customers(cache = true): Observable<Contact[]> {
        if (!cache) {
            this.invalidateInterCustomers();
        }
        return this.http.get<Contact[]>(
            `${this.PHP_API_SERVER}/contacts/get/international`,
            {
                context: withCache({
                    bucket: this.interCustomersBucket
                })
            }
        );
    }
    get_pelates(cache = true): Observable<Contact[]> {

        if (!cache) {
            this.invalidatePelates();
        }

        return this.http.get<Contact[]>(
            `${this.PHP_API_SERVER}/contacts/get/domestic`,
            {
                context: withCache({
                    bucket: this.pelatesBucket
                })
            }
        );
    }
    get_declarants(cache = true): Observable<Declarant[]> {
        if (!cache) {
            this.invalidateDeclarants();
        }
        return this.http.get<Declarant[]>(
            `${this.PHP_API_SERVER}/contacts/get/declarants`,
            {
                context: withCache({
                    bucket: this.declarantsBucket
                })
            }
        );
    }

    getLrns(): Observable<LrnItem[]> {
        return this.http.get<LrnItem[]>(`${this.PHP_API_SERVER}/select/getAllLRNs`);

    }

    addDescription(postData): Observable<DescriptionResults> {
        const url = `${this.PHP_API_SERVER}/taric/descriptions/`;

        if (postData.goodsPrivateGroup) {
            delete postData.goodsPrivateGroup;
        }
        return this.http.post<DescriptionResults>(url, postData);
    }
    updateDescription(postData): Observable<DescriptionResults> {
        const url = `${this.PHP_API_SERVER}/taric/descriptions/`;

        if (postData.goodsPrivateGroup) {
            delete postData.goodsPrivateGroup;
        }
        return this.http.patch<DescriptionResults>(url, postData);
    }
    getProductDescription(comCode, taricCode): Observable<RetrievedDescription[]> {
        return this.http.get<RetrievedDescription[]>(`${this.PHP_API_SERVER}/taric/descriptions/index.php/${comCode}/${taricCode}`);
    }
    deleteDescription(comCode, taricCode, id): Observable<RetrievedDescription[]> {
        const url = `${this.PHP_API_SERVER}/taric/descriptions/index.php/${id}`;
        return this.http.delete<RetrievedDescription[]>(url);
    }


    getSupUnits(code, domain) {
        const postData = {
            code,
            domain
        };
        const url = `${this.PHP_API_SERVER}/tariffs/supunits/`;
        return this.http.post<SupUnitDesc>(url, postData);
    }

    getFootnoteDescription(footnotes: string[]): Observable<genericItem[]> {
        const url = `${this.PHP_API_SERVER}/taric/footnotes/`;
        return this.http.post<genericItem[]>(url, { footnotes });

    }

    get_declarationsInvoiced(invoiced?: number, trader_id?: string) {
        let get_invoiced: string;
        let get_trader: string;

        //0: non invoiced, 1: invoiced, 2: removed by user
        if (invoiced === 0 || invoiced === 1 || invoiced === 2) {
            get_invoiced = `?invoiced=${invoiced}`;
        } else {
            get_invoiced = '';
        }

        if (trader_id) {
            if (invoiced === 0 || invoiced === 1 || invoiced === 2) {
                get_trader = `&trader_id=${trader_id}`;
            } else {
                get_trader = `?trader_id=${trader_id}`;
            }
        } else {
            get_trader = '';
        }

        return this.http.get<any>(`${this.PHP_API_SERVER}/declarations/get_declarations${get_invoiced}${get_trader}`);
    }
    mark_declaration_invoiced(declaration: InvoiceProductModel) {
        return this.http.post<any>(`${this.PHP_API_SERVER}/declarations/markInvoiced`, { mrn: declaration.mrn });

    }
    get_declaration(documentType, id): Observable<SaveDataModel> {
        const url = `${this.PHP_API_SERVER}/declarations/get/declaration/${documentType}/${id}`;
        return this.http.get<SaveDataModel>(url);
    }
    get_declaration_by_mrn(mrn: string) {
        const url = `${this.PHP_API_SERVER}/declarations/get/declaration/${mrn}`;
        return this.http.get<SaveDataModel>(url);

    }

    get_declarations(postData): Observable<Page<ArchiveResult>> {
        const url = `${this.PHP_API_SERVER}/declarations/get_declarations`;
        return this.http.post<Page<ArchiveResult>>(url, postData);
    }

    delete_declaration(documentType, id): Observable<SaveDataModel> {
        const url = `${this.PHP_API_SERVER}/declarations/get/declaration/${documentType}/${id}`;
        return this.http.delete<SaveDataModel>(url);
    }

    getAdditionalDeclaration(id: string, requestedDeclaration: 'ARR_AT_EXIT' | 'ICS_DETE'): Observable<SaveDataModel> {
        const url = `${this.PHP_API_SERVER}/declarations/getAddDeclaration/`;
        return this.http.post<SaveDataModel>(url, { id, requestedDeclaration });
    }
    //======================================================================================
    //======================================================================================

    submitForm(postData): Observable<HttpEvent<any>> {
        let url;
        if (environment.testing) {
            url = `${this.PHP_API_SERVER}/gsis/sendMessage_mock`;
        } else {
            url = `${this.PHP_API_SERVER}/gsis/sendMessage`;
        }
        return this.http.post(url, postData, {
            responseType: 'text',
            reportProgress: true,
            observe: 'events',
            headers: new HttpHeaders({ 'Content-Type': 'text/html' })
        });
    }

    getXMLFile(postData) {
        const url = `${this.PHP_API_SERVER}/gsis/getXML`;

        return this.http.post(url, postData, { responseType: 'text' })
            .pipe(
                map(responseText => {
                    // Remove beginning and ending pipes if they exist.
                    responseText = responseText.trim();
                    if (responseText.startsWith('|') && responseText.endsWith('|')) {
                        responseText = responseText.slice(1, -1);
                    }

                    try {
                        // Try to parse the response as JSON.
                        const errorObj = JSON.parse(responseText);
                        throw errorObj; // If it's a valid JSON, throw it as an error.
                    } catch (e) {
                        // If it's not JSON, consider it as XML.
                        const blob = new Blob([responseText], { type: 'application/xml' });
                        return blob;
                    }
                })
            );
    }
    submitRemittance(postData): Observable<ePaymentResponseModel[]> {
        let url;
        if (environment.testing) {
            url = `${this.PHP_API_SERVER}/gsis/remittance/sendRemittance_mock`;
        } else {
            url = `${this.PHP_API_SERVER}/gsis/remittance/sendRemittance`;
        }
        return this.http.post<ePaymentResponseModel[]>(url, postData);
    }
    checkRemittance(postData): Observable<ePaymentResponseModel> {

        const url = `${this.PHP_API_SERVER}/gsis/remittance/check`;

        return this.http.post(url, postData);
    }
    getState(postData: GetStateParams): Observable<HttpEvent<any>> {
        let url;

        if (environment.testing) {
            console.log("ENVIRONEMT TESTING", environment.testing)
            url = `${this.PHP_API_SERVER}/gsis/getState_mock`;
        } else {
            url = `${this.PHP_API_SERVER}/gsis/getState`;
        }
        return this.http.post(url, postData, {
            responseType: 'text',
            reportProgress: true,
            observe: 'events',
            headers: new HttpHeaders({ 'Content-Type': 'text/html' })
        });
    }
    getStateSilent(postData: GetStateParams): Observable<any> {
        console.log("ENVIRONEMT TESTING ENTERED SILENT MODE", environment.testing)
        let url
        if (environment.testing) {
            console.log("ENVIRONEMT TESTING", environment.testing)
            url = `${this.PHP_API_SERVER}/gsis/getState_mock`;
        } else {
            url = `${this.PHP_API_SERVER}/gsis/getState`;
        }

        return this.http.post(url, postData);
    }
    updateState(response: StateResponse, declarationForm?: DeclarationModels): Observable<any> {
        const url = `${this.PHP_API_SERVER}/declarations/updateDeclarationState`;

        return this.http.post(url, { ...response, declarationForm });
    }
    saveForm(saveData: Partial<SaveDataModel>): Observable<any> {
        const url = `${this.PHP_API_SERVER}/declarations/save`;

        return this.http.post(url, saveData);
    }

    deleteForm(lrn: string): Observable<any> {

        const url = `${this.PHP_API_SERVER}/declarations/delete`;

        return this.http.post(url, lrn);
    }

    register(postData): Observable<any> {
        const url = `${this.PHP_API_SERVER}/auth/register`;

        return this.http.post(url, postData);
    }


    ///================================================================
    //-==================================================================
    //=========================TARIFF CALCULATE =========================

    getIcisCreds(declarant: Declarant): Observable<any> {
        const url = `${this.PHP_API_SERVER}/select/get_icisCreds`;
        let postData;

        if (declarant) {
            postData = { declarantId: declarant.afm }

        }

        return this.http.post(url, postData).pipe(take(1));
    }



    //===============================================================================

    savePrintSettings(settings: PrintSettingsModel): Observable<PrintSettingsModel[]> {
        const url = `${this.PHP_API_SERVER}/select/printSettings`;

        return this.http.patch<PrintSettingsModel[]>(url, settings);
    }
    //==================================================================================================
    //=============================================STATEMENTS============================================

    createStatement(postData: CustomerStatementRequest): Observable<CustomerStatementResponse> {

        const url = `${this.PHP_API_SERVER}/customer-statements/`;

        return this.http.post<CustomerStatementResponse>(url, postData).pipe(take(1));


    }

    //============================================================================================================
    //=======================================EFK VEHICLE TABLE====================================================

    getCarAgeReduction(totalMonths: number, chassisType: string): Observable<number> {
        const url = `${this.PHP_API_SERVER}/select/national/car_age_reduction/`;

        return this.http.post<number>(url, { totalMonths, chassisType }).pipe(take(1));


    }

    getCo2EmissionsCode(co2Emissions: number): Observable<Co2EmissionCode> {
        const url = `${this.PHP_API_SERVER}/select/national/co2_add_taric_code/`;

        return this.http.post<Co2EmissionCode>(url, { co2Emissions }).pipe(take(1));


    }

    getHybridReductionRate(co2Emissions: number): Observable<number> {

        const url = `${this.PHP_API_SERVER}${ApiEndPoints.efk_car_hybrid}/`;

        return this.http.post<number>(url, { co2Emissions }).pipe(take(1));
    }

    getCarPriceRanges(): Observable<CarPriceRange[]> {

        const url = `${this.PHP_API_SERVER}/select/national/car_price_ranges/`;

        return this.http.get<CarPriceRange[]>(url, {
            context: withCache()
        }).pipe(take(1));

    }

    getTestSettings(): Observable<any> {
        const url = `${this.PHP_API_SERVER}/settings/test/`;

        return this.http.get<any>(url, {
            context: withCache()
        }).pipe(share(), take(1));
    }
    cbServerDownload(): Observable<{ type: string, value: any }> {
        const url = `${this.PHP_API_SERVER}/download/cbserver/`;

        const headers = new HttpHeaders().set('X-Skip-Interceptor', 'true');

        const req = new HttpRequest('GET', url, {
            headers: headers,
            reportProgress: true,
            responseType: 'blob',
            observe: 'events'
        });

        // Add the custom header to bypass the interceptor

        return this.http.request(req).pipe(
            map(event => {
                switch (event.type) {
                    case HttpEventType.DownloadProgress: {
                        // Report download progress
                        const progress = Math.round(100 * event.loaded / (event.total ?? event.loaded));
                        return { type: 'progress', value: progress };

                    }
                    case HttpEventType.Response: {
                        // Handle the full response
                        const response = event as HttpResponse<Blob>;
                        const filename = response.headers.get('X-File-Name');
                        return { type: 'complete', value: { blob: response.body, filename } };
                    }

                    default:
                        return { type: 'unhandled', value: event };
                }
            }),
            filter(event => event.type === 'progress' || event.type === 'complete')
        );
    }
}
