import { GuarefrefModel } from '../guarefref/guarefref.model';

export class GuaguaModel {
    GuaTypGUA1?: string;
    GUAREFREF?: GuarefrefModel[];

    constructor(value?) {
        this.GuaTypGUA1 = value?.GuaTypGUA1 || null;
        this.GUAREFREF = value?.GUAREFREF;
    }
}
