import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { EMCSVehicleInventoryForm } from "../vehicle-inventory/vehinv-form.model";
import { EMCSVehicleInventoryModel } from "../vehicle-inventory/vehinv.model";
import { VehicleDetailsModel } from "./vehicleDetails.model";

export class VehicleDetailsForm {
    [key: string]: AbstractControl;

    FrameNumber: FormControl;
    CarKind: FormControl;
    ImporterCodeOrBrandName: FormControl;
    FirstAllowanceDate: FormControl;
    VehicleFactoryType: FormControl;
    VehicleUsedFlag: FormControl;
    VehicleModelName: FormControl;
    VehicleRegistrationPlate: FormControl;
    VehicleLicenceReference: FormControl;
    Mileage: FormControl;
    VehicleInventory: FormArray;

    constructor(value: VehicleDetailsModel) {
        this.FrameNumber = new FormControl(value.FrameNumber, { updateOn: 'blur', validators: [Validators.required] });
        this.CarKind = new FormControl(value.CarKind, [Validators.required]);
        this.ImporterCodeOrBrandName = new FormControl(value.ImporterCodeOrBrandName, [Validators.required]);
        this.FirstAllowanceDate = new FormControl(value.FirstAllowanceDate, { updateOn: 'blur' });
        this.VehicleFactoryType = new FormControl(value.VehicleFactoryType, { updateOn: 'blur' });
        this.VehicleUsedFlag = new FormControl(value.VehicleUsedFlag);
        this.VehicleModelName = new FormControl(value.VehicleModelName, { updateOn: 'blur' });
        this.VehicleRegistrationPlate = new FormControl(value.VehicleRegistrationPlate, { updateOn: 'blur' });
        this.VehicleLicenceReference = new FormControl(value.VehicleLicenceReference, { updateOn: 'blur' });
        this.Mileage = new FormControl(value.Mileage);

        this.VehicleInventory = new FormArray([]);

        if (value.VehicleInventory) {
            value.VehicleInventory.forEach((inventory: EMCSVehicleInventoryModel) => {
                this.VehicleInventory.push(new FormGroup(new EMCSVehicleInventoryForm(new EMCSVehicleInventoryModel(inventory))));
            });
        }
        else {
            this.VehicleInventory.push(new FormGroup(new EMCSVehicleInventoryForm(new EMCSVehicleInventoryModel())));

        }


    }
}
