import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { PrevAsdeDocModel, PrevEaadDocModel, PrevExciseTaxDeclDocModel, PreviousDocumentsModel, PrevStampReqDocModel, PrevVehArrivalDocModel, PrevVehMoveDocModel } from "./previous-documents.model";

export class PreviousDocumentsForm {
    [key: string]: AbstractControl;

    PreviousDocumentType: FormControl;
    PreviousAsdeDocument: FormGroup;
    PreviousEaadDocument: FormGroup;
    PreviousStampRequestDocument: FormGroup;
    PreviousVehicleArrivalDocument: FormGroup;
    PreviousVehicleMovementDocument: FormGroup;
    PreviousExciseTaxesDeclarationDocument: FormGroup;


    constructor(value: PreviousDocumentsModel) {

        this.PreviousDocumentType = new FormControl(value.PreviousDocumentType, { updateOn: 'change' });
        this.PreviousAsdeDocument = new FormGroup(new PrevAsdeDocForm(new PrevAsdeDocModel(value.PreviousAsdeDocument)));
        this.PreviousEaadDocument = new FormGroup(new PrevEaadDocForm(new PrevEaadDocModel(value.PreviousEaadDocument)));
        this.PreviousStampRequestDocument = new FormGroup(new PrevStampReqDocForm(new PrevStampReqDocModel(value.PreviousStampRequestDocument)));
        this.PreviousVehicleArrivalDocument = new FormGroup(new PrevVehArrivalDocForm(new PrevVehArrivalDocModel(value.PreviousVehicleArrivalDocument)));
        this.PreviousVehicleMovementDocument = new FormGroup(new PrevVehMoveDocForm(new PrevVehMoveDocModel(value.PreviousVehicleMovementDocument)));
        this.PreviousExciseTaxesDeclarationDocument = new FormGroup(new PrevExciseTaxDeclDocForm(new PrevExciseTaxDeclDocModel(value.PreviousExciseTaxesDeclarationDocument)));

    }
}



export class PrevAsdeDocForm {
    [key: string]: AbstractControl;

    AsdeReferenceNumber: FormControl;
    AsdeRowNumber: FormControl;


    constructor(value: PrevAsdeDocModel) {

        this.AsdeReferenceNumber = new FormControl(value.AsdeReferenceNumber);
        this.AsdeRowNumber = new FormControl(value.AsdeRowNumber);

    }
}

export class PrevEaadDocForm {
    [key: string]: AbstractControl;

    AadReferenceCode: FormControl;
    SequenceNumber: FormControl;


    constructor(value: PrevEaadDocModel) {

        this.AadReferenceCode = new FormControl(value.AadReferenceCode);
        this.SequenceNumber = new FormControl(value.SequenceNumber);

    }
}

export class PrevStampReqDocForm {
    [key: string]: AbstractControl;

    DeclarationReferenceNumber: FormControl;


    constructor(value: PrevStampReqDocModel) {

        this.DeclarationReferenceNumber = new FormControl(value.DeclarationReferenceNumber);

    }
}

export class PrevVehArrivalDocForm {
    [key: string]: AbstractControl;

    DeclarationReferenceNumber: FormControl;


    constructor(value: PrevVehArrivalDocModel) {

        this.DeclarationReferenceNumber = new FormControl(value.DeclarationReferenceNumber);

    }
}

export class PrevVehMoveDocForm {
    [key: string]: AbstractControl;

    DeclarationReferenceNumber: FormControl;


    constructor(value: PrevVehMoveDocModel) {

        this.DeclarationReferenceNumber = new FormControl(value.DeclarationReferenceNumber);

    }
}

export class PrevExciseTaxDeclDocForm {
    [key: string]: AbstractControl;

    DeclarationReferenceNumber: FormControl;
    ExciseTaxesDeclarationRowNumber: FormControl;


    constructor(value: PrevExciseTaxDeclDocModel) {

        this.DeclarationReferenceNumber = new FormControl(value.DeclarationReferenceNumber);
        this.ExciseTaxesDeclarationRowNumber = new FormControl(value.ExciseTaxesDeclarationRowNumber);

    }
}
