import { BaseBuildingComponent } from './../../_baseComponent/base.component';
import {
    Component,
    ViewChild,
    Input,
    ElementRef,
    ChangeDetectionStrategy,
    NgZone,
    AfterViewInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';

@Component({
    selector: 'app-generic-input',
    templateUrl: './generic-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericInputComponent extends BaseBuildingComponent implements AfterViewInit {
    @ViewChild('firstInput') _first;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    // Note: TemplateRef requires a type parameter for the component reference.
    //In this case, we're passing an `any` type as it's not a component.

    @Input() form: FormGroup; //Form to be passed to the component
    @Input() svgDocFocus: string; //svg Document Focus
    @Input() controlName: string; //the name of the formControl
    @Input() inputField: string; //textarea or input
    @Input() maxRows: string; //textarea maxRows
    @Input() classes: string; //class for the form-field
    @Input() inputClass: string; //class of the input
    @Input() upperCase: boolean; //switch to uppercase
    @Input() maxlength: number; //switch to uppercase
    @Input() required: boolean;
    @Input() readonly: boolean;
    @Input() digitsOnly? = false;
    @Input() nullOnEmpty? = false;

    @Input() decimal: boolean;
    @Input() decimalNumbers: number;

    @Input() placeholder: string; //class of the input
    @Input() tabindex? = 0;


    get control(): FormControl {

        return this.form.get(this.controlName) as FormControl
    }

    constructor(
        protected hostElement: ElementRef,
        protected cf: ExtCustomFunctions,
        private _ngZone: NgZone) {

        super(hostElement, cf)
    }

    ngAfterViewInit(): void {
        if (this.inputField === 'textarea') {
            this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
        }
    }

    //===================================================
    onKeyUp(event) {

        if (this.upperCase) {
            this.form.get(this.controlName).setValue(event.target.value.toUpperCase())
        }
    }

    formatDecimal(nullOnEmpty = false) {

        if (nullOnEmpty && this.control.value === '') {
            this.control.setValue(null);
            return;
        }

        if (this.decimalNumbers) {
            this.cf.formatDecimalDigits(this.control, this.decimalNumbers)
        }
    }
}
