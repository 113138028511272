import { MainBroadcasterService } from './../broadcaster.service';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';
import { HttpErrorService } from '../http-error.service';

const RETRY_COUNT = 5;
const RETRY_WAIT_MILLISECONDS = 500;

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private httpErrorService: HttpErrorService,
        private mainBroadcaster: MainBroadcasterService,
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        req = this.setHeaders(req);

        return next.handle(req).pipe(
            retryWhen((error) =>
                error.pipe(
                    concatMap((error, count) => this.handleErrorWithRetry(error, count)
                    ),
                    delay(RETRY_WAIT_MILLISECONDS)
                )
            )
        );
    }

    private setHeaders(req: HttpRequest<any>): HttpRequest<any> {
        if (!req.headers.has('Content-Type') && !(req.body instanceof FormData)) {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json'),
            });
        }
        // Only set the Accept header if it hasn't been set already
        if (!req.headers.has('Accept')) {
            req = req.clone({
                headers: req.headers.set('Accept', 'application/json'),
            });
        }

        return req;

    }

    private handleErrorWithRetry(
        error: HttpErrorResponse,
        count: number
    ): Observable<any> {

        console.log("intercept HandleErrors handleErrorWithRetry", error, count);

        if (count <= RETRY_COUNT && error.status === 400) {

            if (typeof error.error === 'string') {
                try {
                    const parsedError = JSON.parse(error.error.trim());
                    error = new HttpErrorResponse({
                        ...error,
                        error: parsedError
                    });
                } catch (e) {
                    console.error('Failed to parse piped error response:', e);
                }
            }

            this.httpErrorService.showError(error);
            this.mainBroadcaster.updateLoading(false);
            return throwError(error);
        } else if (error.status === 401) {
            //pass error to next interceptor (Auth Interceptor)
            return throwError(error);
        }
        else if (error.status === 504) {
            //   error.error = "Ελέγξτε την σύνδεση σας στο διαδίκτυο."
            this.httpErrorService.showError(error);
            this.mainBroadcaster.updateLoading(false);
            return throwError(error);

        } else {
            this.httpErrorService.showError(error);
            this.mainBroadcaster.updateLoading(false);
            return throwError(error); // propagate the error or you can use `return EMPTY;` if you don't want to.
        }
    }

}