import { VehiclesQueriesService } from './../../vehicle-queries.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { VehiclesForm } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles-form.model';
import { VehiclesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicles.service';
import { Observable, map, switchMap, tap } from 'rxjs';
import { CarPriceRange } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.interfaces';

@Component({
    selector: 'cars-calculation-data',
    templateUrl: './cars-calculation-data.component.html',
    styleUrls: ['./cars-calculation-data.component.scss']
})
export class CarsCalculationDataComponent implements OnInit {

    @Input() vehiclesForm: FormGroup;
    vehiclesForm$: Observable<FormGroup<VehiclesForm>>
    priceRanges$: Observable<CarPriceRange[]>
    priceCategories: string[] = []
    constructor(private vehiclesService: VehiclesService, private vehiclesQueriesService: VehiclesQueriesService) {

        this.priceRanges$ = this.vehiclesQueriesService.priceRanges$.pipe(tap(priceRanges => {

            priceRanges.forEach(priceRange => {

                const priceMax = priceRange.price_max ? ' - ' + priceRange.price_max : '';
                const category = priceRange.price_min + priceMax

                this.priceCategories.push(category)
                console.log("CarsCalculationDataComponent priceRanges", this.priceCategories)

            })
        }
        ))

    }

    ngOnInit() {
        this.vehiclesForm$ = this.vehiclesService.vehiclesForm$.pipe(

            switchMap((form) => form.valueChanges.pipe(map(value => { return { form, value } }))),
            tap((value) => { console.log('CarsCalculationDataComponent valueChanges', value.value) }),
            map(value => value.form),

        )

    }


}
