import { AbstractControl, FormControl } from "@angular/forms";
import { ConsignorContactDetailsModel } from "./consignorContactDetails.model";

export class ConsignorContactDetailsForm {
    [key: string]: AbstractControl;

    PersonIdentification = new FormControl();
    LastNames = new FormControl();
    Forenames = new FormControl();
    NameOfFather = new FormControl();
    Address = new FormControl();
    Phone = new FormControl();
    Country = new FormControl();

    constructor(value: ConsignorContactDetailsModel) {
        this.PersonIdentification.setValue(value.PersonIdentification);
        this.LastNames.setValue(value.LastNames);
        this.Forenames.setValue(value.Forenames);
        this.NameOfFather.setValue(value.NameOfFather);
        this.Address.setValue(value.Address);
        this.Phone.setValue(value.Phone);
        this.Country.setValue(value.Country);

    }
}
