<!--     FILTERS     -->
<div class="mt-6">
    <mat-card class="mb-6 mx-6">
        <mat-card-content>
            <div
                class="customs-list-filters"
                fxLayout="column"
                [formGroup]="filters"
            >
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <ejs-combobox
                        class="s-40"
                        id="countries"
                        [dataSource]="countries"
                        [fields]="fields"
                        [placeholder]="placeholder1"
                        formControlName="countryQuery"
                    ></ejs-combobox>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<!--    / FILTERS     -->
