import { FormControl, AbstractControl } from '@angular/forms';
import { FueCon300Model } from './fuecon300.model';

export class FueCon300Form {
    [key: string]: AbstractControl;

    RelCodFUECON2 = new FormControl();
    QuaFUECON3 = new FormControl();
    NatProCodFUECON4 = new FormControl();
    InvNumFUECON123 = new FormControl();

    constructor(value: FueCon300Model) {
        this.RelCodFUECON2.setValue(value.RelCodFUECON2);
        this.QuaFUECON3.setValue(value.QuaFUECON3);
        this.NatProCodFUECON4.setValue(value.NatProCodFUECON4);
        this.InvNumFUECON123.setValue(value.InvNumFUECON123);
    }
}
