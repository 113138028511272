<div fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div *ngIf="goodsIndex === null || goodsIndex === undefined">
            Επιλέξτε το είδος που θέλετε να εφαρμοστεί {{ title }}
        </div>
        <div *ngIf="goodsIndex !== null && goodsIndex !== undefined">
            Επιλέξτε περιγραφή
        </div>

        <!--  <div>
                <h1><small>MRN </small>{{ mrn }}</h1>
            </div> -->
        <div>
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div
        fxLayout="row"
        fxLayoutAlign="space-around start"
        mat-dialog-content
        style="overflow-y: auto; min-height: 100px; height: 100px"
    >
        <!-- First Column -->
        <mat-card class="zone zone-value flex-column" fxLayout="column">
            <ng-container *ngIf="descriptions">
                <mat-table
                    #table
                    [dataSource]="descriptions"
                    class="wv-table--condensed"
                >
                    <mat-header-row
                        *matHeaderRowDef="displayedColumns"
                    ></mat-header-row>
                    <mat-row
                        *matRowDef="
                            let item;
                            columns: displayedColumns;
                            let index = index
                        "
                        class="attachment"
                        matRipple
                        style="min-height: 45px !important"
                        (click)="onSelectedChange(item.id)"
                    >
                    </mat-row>

                    <!-- domain Column -->
                    <ng-container matColumnDef="checkbox">
                        <mat-header-cell *matHeaderCellDef
                            >Επιλογή Περιγραφής</mat-header-cell
                        >
                        <mat-cell *matCellDef="let item">
                            <mat-checkbox
                                [(ngModel)]="checkboxes[item.id]"
                                (ngModelChange)="onSelectedChange(item.id)"
                                (click)="$event.stopPropagation()"
                                name="checkbox-{{ item.id }}"
                                id="checkbox-{{ item.id }}"
                                class="descCheckbox"
                            >
                                {{ item.description }}
                            </mat-checkbox>
                        </mat-cell>
                    </ng-container>
                </mat-table>
            </ng-container>
            <div style="padding-left: 30px; padding-top: 20px">
                <div *ngIf="type === 'code' || type === 'both'">
                    <span style="font-weight: 600; font-variant: small-caps"
                        >Κωδικός:
                    </span>
                    <span>{{ formattedCode }}</span>
                </div>
                <div *ngIf="type === 'description' || type === 'both'">
                    <span style="font-weight: 600; font-variant: small-caps"
                        >Περιγραφή:
                    </span>
                    <span>{{ finalDescription }}</span>
                </div>
            </div>
        </mat-card>

        <!-- Second Column Send Only -->
    </div>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
        <div
            *ngIf="goodsIndex === null || goodsIndex === undefined"
            style="width: 80%; flex-wrap: wrap"
            fxLayoutAlign="space-around start"
        >
            <ng-container *ngFor="let item of totalItems; let i = index">
                <button
                    #itemButton
                    style="width: 15%; margin: 5px 0"
                    mat-raised-button
                    class="primary"
                    (click)="onSelectItem($event, i)"
                    id="itembutton-{{ i }}"
                >
                    <span>Είδος {{ item }}</span>
                </button>
            </ng-container>
        </div>
        <!-- /When called From TARIC -->
        <!-- When called From comCode -->
        <div
            *ngIf="goodsIndex !== null && goodsIndex !== undefined"
            style="width: 80%; flex-wrap: wrap"
            fxLayoutAlign="space-around start"
        >
            <button
                #itemButton
                style="width: 15%; margin: 5px 0"
                mat-raised-button
                class="primary"
                (click)="onSelectItem($event, goodsIndex)"
                id="itembutton-{{ goodsIndex }}"
            >
                <span>Επιλογή</span>
                <!--       <span><small>09876543</small></span> -->
            </button>
        </div>
        <!-- /When called From comCode -->
    </mat-dialog-actions>
</div>
