import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { SeainfsilModel } from './seainfsli.model';
import { SeaIdSidModel } from './seaidsid/seaidsid.model';
import { SeaIdSidForm } from './seaidsid/seaidsid-form.model';

export class SeainfsilForm {
    [key: string]: AbstractControl;

    SeaNumSLI2 = new FormControl();
    SEAIDSID = new FormArray([]);

    constructor(value: SeainfsilModel) {
        this.SeaNumSLI2.setValue(value.SeaNumSLI2);

        if (value.SEAIDSID) {
            value.SEAIDSID.forEach((sealid: SeaIdSidModel) => {
                this.SEAIDSID.push(new FormGroup(new SeaIdSidForm(new SeaIdSidModel(sealid))));
            });
        } else {
            this.SEAIDSID.push(new FormGroup(new SeaIdSidForm(new SeaIdSidModel())));
        }

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
