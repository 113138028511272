export class TaxComputationModel {
    NationalAccountingCode?: string;
    SuspensionFlag?: string;
    AmountOfTaxes?: string;
    PaymentMethodCode?: string;
    TaxBase?: string;
    TaxRate?: string;

    _natTypeCode?: string;
    _taxFreeCode?: string;
    _taxPaidCode?: string;
    _partialExemption?: boolean;
    _wholeTaxAmount?: number;



    constructor(value?) {
        this.NationalAccountingCode = value?.NationalAccountingCode || null;
        this.SuspensionFlag = value?.SuspensionFlag || null;
        this.AmountOfTaxes = value?.AmountOfTaxes || null;
        this.PaymentMethodCode = value?.PaymentMethodCode || null;
        this.TaxBase = value?.TaxBase || null;
        this.TaxRate = value?.TaxRate || null;
        this._natTypeCode = value?._natTypeCode;
        this._taxFreeCode = value?._taxFreeCode;
        this._taxPaidCode = value?._taxPaidCode;
        this._partialExemption = value?._partialExemption;
        this._wholeTaxAmount = value?._wholeTaxAmount;

    }
}
