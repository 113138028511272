import { FormControl, Validators } from '@angular/forms';
import { TraConexModel } from './traconex2.model';

export class TraConexForm {
    [key: string]: FormControl;

    NamEX27 = new FormControl();
    StrAndNumEX222 = new FormControl();
    PosCodEX223 = new FormControl();
    CitEX224 = new FormControl();
    CouEX225 = new FormControl();
    NADLNGGTEX = new FormControl();
    TINEX259 = new FormControl();
    ExporterObject = new FormControl();

    constructor(value: TraConexModel) {
        this.NamEX27.setValue(value.NamEX27);
        this.StrAndNumEX222.setValue(value.StrAndNumEX222);
        this.PosCodEX223.setValue(value.PosCodEX223);
        this.CitEX224.setValue(value.CitEX224);
        this.CouEX225.setValue(value.CouEX225);
        this.NADLNGGTEX.setValue(value.NADLNGGTEX);
        this.TINEX259.setValue(value.TINEX259);
        this.ExporterObject.setValue(value.ExporterObject);
    }
}
