import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { HeaheaModel } from './heahea.model';
import { LoadingDtsModel } from './loadingDts/loadingDts.model';
import { LoadingDtsForm } from './loadingDts/loadingDts-form.model';

export class HeaheaForm {
    [key: string]: AbstractControl;
    //DOESNT EXIST ON CC515A
    DocNumHEA5 = new FormControl();

    //HEADER FORM
    RefNumHEA4 = new FormControl();
    TypOfDecHEA24 = new FormControl();
    CouOfDesCodHEA30 = new FormControl();
    AgrLocOfGooCodHEA38 = new FormControl();
    AgrLocOfGooHEA39 = new FormControl();
    AgrLocOfGooHEA39LNG = new FormControl();
    AutLocOfGooCodHEA41 = new FormControl();
    CouOfDisCodHEA55 = new FormControl();
    CusSubPlaHEA66 = new FormControl();
    InlTraModHEA75 = new FormControl();
    TraModAtBorHEA76 = new FormControl();
    IdeOfMeaOfTraAtDHEA78 = new FormControl(null, { updateOn: 'blur' });
    IdeOfMeaOfTraAtDHEA78LNG = new FormControl();
    IdeOfMeaOfTraCroHEA85 = new FormControl(null, { updateOn: 'blur' });
    IdeOfMeaOfTraCroHEA85LNG = new FormControl();
    NatOfMeaOfTraCroHEA87 = new FormControl();
    TypOfMeaOfTraCroHEA88 = new FormControl();
    ConIndHEA96 = new FormControl();
    AccDatHEA158 = new FormControl();
    DiaLanIndAtDepHEA254 = new FormControl();
    ECSAccDocHEA601 = new FormControl();
    TotNumOfIteHEA305 = new FormControl(null, { updateOn: 'blur' });
    TotNumOfPacHEA306 = new FormControl();
    TotGroMasHEA307 = new FormControl();
    //On CC515A only
    DecDatHEA383 = new FormControl();
    DecPlaHEA394 = new FormControl();
    DecPlaHEA394LNG = new FormControl();
    //On CC599A only
    ExiDatHEA1 = new FormControl();
    //On CC513A only
    AmdPlaHEA598 = new FormControl();
    AmdPlaHEA598LNG = new FormControl();
    AmdDatHEA599 = new FormControl();

    //On CC504A only Modify Acceptance
    AmeAccDatHEA602 = new FormControl();

    SpeCirIndHEA1 = new FormControl();
    TraChaMetOfPayHEA1 = new FormControl();
    ComRefNumHEA = new FormControl(null, { updateOn: 'blur' });
    GraTotTaxAmoHEA1 = new FormControl();
    TypOfDecBx12HEA651 = new FormControl();
    PlaLoaGOOITE334 = new FormControl(null, { updateOn: 'blur' });
    ECSAccDocDatHEA1023 = new FormControl();
    AddDecDatHEA1024 = new FormControl();
    TIRFolHEA1025 = new FormControl();
    FinBanInfHEA1027 = new FormControl();
    ManEntNumHEA1028 = new FormControl();
    ManEntYeaHEA1029 = new FormControl();
    LoadingDts = new FormArray([]);

    //===========TIR ONLY===============================
    NatOfMeaOfTraAtDHEA80 = new FormControl();

    constructor(value: HeaheaModel) {
        this.DocNumHEA5.setValue(value.DocNumHEA5);

        this.RefNumHEA4.setValue(value.RefNumHEA4);
        this.TypOfDecHEA24.setValue(value.TypOfDecHEA24);
        this.CouOfDesCodHEA30.setValue(value.CouOfDesCodHEA30);
        this.AgrLocOfGooCodHEA38.setValue(value.AgrLocOfGooCodHEA38);
        this.AgrLocOfGooHEA39.setValue(value.AgrLocOfGooHEA39);
        this.AgrLocOfGooHEA39LNG.setValue(value.AgrLocOfGooHEA39LNG);
        this.AutLocOfGooCodHEA41.setValue(value.AutLocOfGooCodHEA41);
        this.CouOfDisCodHEA55.setValue(value.CouOfDisCodHEA55);
        this.CusSubPlaHEA66.setValue(value.CusSubPlaHEA66);
        this.InlTraModHEA75.setValue(value.InlTraModHEA75);
        this.TraModAtBorHEA76.setValue(value.TraModAtBorHEA76);
        this.IdeOfMeaOfTraAtDHEA78.setValue(value.IdeOfMeaOfTraAtDHEA78);
        this.IdeOfMeaOfTraAtDHEA78LNG.setValue(value.IdeOfMeaOfTraAtDHEA78LNG);
        this.IdeOfMeaOfTraCroHEA85.setValue(value.IdeOfMeaOfTraCroHEA85);
        this.IdeOfMeaOfTraCroHEA85LNG.setValue(value.IdeOfMeaOfTraCroHEA85LNG);
        this.NatOfMeaOfTraCroHEA87.setValue(value.NatOfMeaOfTraCroHEA87);
        this.TypOfMeaOfTraCroHEA88.setValue(value.TypOfMeaOfTraCroHEA88);
        this.ConIndHEA96.setValue(value.ConIndHEA96);
        this.AccDatHEA158.setValue(value.AccDatHEA158);

        this.DiaLanIndAtDepHEA254.setValue(value.DiaLanIndAtDepHEA254);
        this.ECSAccDocHEA601.setValue(value.ECSAccDocHEA601);
        this.TotNumOfIteHEA305.setValue(value.TotNumOfIteHEA305);
        this.TotNumOfPacHEA306.setValue(value.TotNumOfPacHEA306);
        this.TotGroMasHEA307.setValue(value.TotGroMasHEA307);

        this.DecDatHEA383.setValue(value.DecDatHEA383);
        this.DecPlaHEA394.setValue(value.DecPlaHEA394);
        this.DecPlaHEA394LNG.setValue(value.DecPlaHEA394LNG);

        this.ExiDatHEA1.setValue(value.ExiDatHEA1);

        this.AmdPlaHEA598.setValue(value.AmdPlaHEA598);
        this.AmdPlaHEA598LNG.setValue(value.AmdPlaHEA598LNG);
        this.AmdDatHEA599.setValue(value.AmdDatHEA599);

        this.AmeAccDatHEA602.setValue(value.AmeAccDatHEA602);

        this.SpeCirIndHEA1.setValue(value.SpeCirIndHEA1);
        this.TraChaMetOfPayHEA1.setValue(value.TraChaMetOfPayHEA1);
        this.ComRefNumHEA.setValue(value.ComRefNumHEA);
        this.GraTotTaxAmoHEA1.setValue(value.GraTotTaxAmoHEA1);
        this.TypOfDecBx12HEA651.setValue(value.TypOfDecBx12HEA651);
        this.PlaLoaGOOITE334.setValue(value.PlaLoaGOOITE334);
        this.ECSAccDocDatHEA1023.setValue(value.ECSAccDocDatHEA1023);
        this.AddDecDatHEA1024.setValue(value.AddDecDatHEA1024);
        this.TIRFolHEA1025.setValue(value.TIRFolHEA1025);
        this.FinBanInfHEA1027.setValue(value.FinBanInfHEA1027);
        this.ManEntNumHEA1028.setValue(value.ManEntNumHEA1028);
        this.ManEntYeaHEA1029.setValue(value.ManEntYeaHEA1029);

        //==================TIR _ONLY-=============================
        this.NatOfMeaOfTraAtDHEA80.setValue(value.NatOfMeaOfTraAtDHEA80);



        if (value.LoadingDts) {
            console.log('Found Loading Details: ', value.LoadingDts);
            value.LoadingDts.forEach((details: LoadingDtsModel) => {
                this.LoadingDts.push(new FormGroup(new LoadingDtsForm(new LoadingDtsModel(details))));
            });
        }

        this.TypOfDecHEA24.setValidators(Validators.required);
        this.TypOfDecBx12HEA651.setValidators(Validators.required);
    }
}
