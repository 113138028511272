import stateCodes from 'assets/stateCodes.json';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

//////////NOT WORKING!!!!//////////////////////////////////////////
@Pipe({
    name: 'headerClass'
})
export class HeaderClassPipe implements PipeTransform {



    constructor() { }

    transform(stateCode: string): string {

        return stateCodes.all
            .filter(e => e.code === stateCode)[0].headerClass



    }
}

@NgModule({
    declarations: [HeaderClassPipe],
    exports: [HeaderClassPipe],
})
export class HeaderClassPipeModule { }