import { FormControl, Validators } from '@angular/forms';
import { Connr2Model } from './connr2.model';

export class Connr2Form {
    [key: string]: FormControl;

    ConNumNR21 = new FormControl();

    constructor(value: Connr2Model) {
        this.ConNumNR21.setValue(value.ConNumNR21);
    }
}
