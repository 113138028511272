import { FormArray, FormControl, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { ImpcusoffForm } from 'app/main/apps/import/_models/impcusoff/impcusoff-form.model';
import { ImpcusoffModel } from 'app/main/apps/import/_models/impcusoff/impcusoff.model';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { eAitisiGooIteGdsForm } from '../gooitegds/gooitegds-form.model';
import { eAitisiGooIteGdsModel } from '../gooitegds/gooitegds.model';
import { eAitisiHeaheaForm } from '../heahea/heahea-form.model';
import { eAitisiHeaheaModel } from '../heahea/heahea.model';
import { RefDeclForm } from '../refDecl/refDecl-form.model';
import { RefDeclModel } from '../refDecl/refDecl.model';
import { eAitisiTasksForm } from '../tasks/tasks-form.model';
import { eAitisiTasksModel } from '../tasks/tasks.model';
import { eAitisiTaxCalcModel } from '../taxCalc/taxcalc.model';
import { eAitisiTaxCalcForm } from '../taxCalc/taxcalx-form.model';
import { eAitisiTradecForm } from '../tradec/tradec-form.model';
import { eAitisiTradecModel } from '../tradec/tradec.model';
import { eAitisiTrarepForm } from '../trarep/trarep-form.model';
import { eAitisiTrarepModel } from '../trarep/trarep.model';
import { eAitisiDeclarationModel } from './declaration.model';

export class eAitisiDeclarationForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    MesSenMES3: FormControl;
    MesRecMES6: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    PriMES15: FormControl;
    TesIndMES18: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    CorIdeMES25: FormControl;

    HEAHEA: FormGroup;
    GOOITEGDS: FormArray;
    TRADEC: FormGroup;
    TRAREP: FormGroup;
    REFDECL: FormArray;
    TAXCALC: FormArray;
    CUSOFF: FormGroup;
    TASKS: FormArray;
    /*

   */

    constructor(declaration: eAitisiDeclarationModel, subdomain?: string, messageType?: string) {

        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));



        this.MesSenMES3 = new FormControl(declaration.MesSenMES3);
        this.MesRecMES6 = new FormControl(declaration.MesRecMES6);
        this.DatOfPreMES9 = new FormControl(declaration.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(declaration.TimOfPreMES10);
        this.PriMES15 = new FormControl(declaration.PriMES15);
        this.TesIndMES18 = new FormControl(declaration.TesIndMES18);
        this.MesIdeMES19 = new FormControl(declaration.MesIdeMES19);
        this.MesTypMES20 = new FormControl(declaration.MesTypMES20);
        this.CorIdeMES25 = new FormControl(declaration.CorIdeMES25);

        this.HEAHEA = new FormGroup(new eAitisiHeaheaForm(new eAitisiHeaheaModel(declaration.HEAHEA)));

        this.GOOITEGDS = new FormArray([]);

        this.TRADEC = new FormGroup(new eAitisiTradecForm(new eAitisiTradecModel(declaration.TRADEC)));

        this.TRAREP = new FormGroup(new eAitisiTrarepForm(new eAitisiTrarepModel(declaration.TRAREP)));

        this.REFDECL = new FormArray([]);

        this.TAXCALC = new FormArray([]);

        this.CUSOFF = new FormGroup(new ImpcusoffForm(new ImpcusoffModel(declaration.CUSOFF)));

        this.TASKS = new FormArray([]);

        if (declaration.GOOITEGDS) {
            declaration.GOOITEGDS.forEach((goods: eAitisiGooIteGdsModel) => {
                this.GOOITEGDS.push(new FormGroup(new eAitisiGooIteGdsForm(new eAitisiGooIteGdsModel(goods))));
            });
        } else {
            this.GOOITEGDS.push(new FormGroup(new eAitisiGooIteGdsForm(new eAitisiGooIteGdsModel())));
        }

        if (declaration.REFDECL) {
            declaration.REFDECL.forEach((refDecl: RefDeclModel) => {
                this.REFDECL.push(new FormGroup(new RefDeclForm(new RefDeclModel(refDecl))));
            });
        } else {
            this.REFDECL.push(new FormGroup(new RefDeclForm(new RefDeclModel())));
        }

        if (declaration.TAXCALC) {
            declaration.TAXCALC.forEach((taxcalc: eAitisiTaxCalcModel) => {
                this.TAXCALC.push(new FormGroup(new eAitisiTaxCalcForm(new eAitisiTaxCalcModel(taxcalc))));
            });
        }

        if (declaration.TASKS) {
            declaration.TASKS.forEach((tasks: eAitisiTasksModel) => {
                this.TASKS.push(new FormGroup(new eAitisiTasksForm(new eAitisiTasksModel(tasks))));
            });
        } else {
            this.TASKS.push(new FormGroup(new eAitisiTasksForm(new eAitisiTasksModel())));
        }
    }
}
