import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariffCalculateComponent } from './tariff-calculate.component';
import { TariffCalcDocumentsTableComponent } from './tariff-calc-documents-table/tariff-calc-documents-table.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TariffCalculateResultsComponent } from './tariff-calculate-results/tariff-calculate-results.component';
import { TariffCalculationDataComponent } from './tariff-calculate-results/tariff-calculation-data/tariff-calculation-data.component';
import { TariffResultsTableComponent } from './tariff-calculate-results/tariff-results-table/tariff-results-table.component';
import { TariffLoadingComponent } from './tariff-loading/tariff-loading.component';
import { FuseProgressBarModule } from '@fuse/components';
import { TariffCalcSupUnitsTableComponent } from './tariff-calc-sup-units-table/tariff-calc-sup-units-table.component';
import { TariffResultsContainerComponent } from 'app/main/apps/tariff-calculate/tariff-calculate-results/tariff-results-container/tariff-results-container.component';
import { CodeToDescPipeModule } from 'app/services/pipes/codeToDesc.pipe';
import { DirectivesModule } from 'app/services/directives/directives.module';

@NgModule({
    declarations: [
        TariffCalculateComponent,
        TariffCalcDocumentsTableComponent,
        TariffCalculateResultsComponent,
        TariffCalculationDataComponent,
        TariffResultsContainerComponent,
        TariffResultsTableComponent,
        TariffLoadingComponent,
        TariffCalcSupUnitsTableComponent
    ],
    imports: [FuseSharedModule, MaterialModule, CodeToDescPipeModule, DirectivesModule],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ]
})
export class TariffCalculateModule { }
