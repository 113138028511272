import { CountryPipeModule } from 'app/services/pipes/countryName.pipe';
import { DotsModule } from '../_scams/dots/dots.module';
import { DigitOnlyModule } from 'app/main/projects/uiowa/digit-only/src/public_api';
import { FormFieldsModule } from 'app/main/main-building-blocks/form-fields/form-fields.module';
import { BaseComponentModule } from './../_baseComponent/base-component.module';
import { GenericAutocompleteComponent } from './generic-autocomplete/generic-autocomplete.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFlagComponent } from './generic-flag/generic-flag.component';
import { GenericCountryComponent } from './generic-country/generic-country.component';
import { GenericDateComponent } from './generic-date/generic-date.component';
import { GenericInputComponent } from './generic-input/generic-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';

export const COMPONENTS = [
    GenericAutocompleteComponent,
    GenericCountryComponent,
    GenericDateComponent,
    GenericFlagComponent,
    GenericInputComponent
]

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        DigitOnlyModule,

        BaseComponentModule,
        CountryPipeModule,
        FormFieldsModule,
        DotsModule
    ],
    exports: COMPONENTS
})
export class GenericComponentsModule { }
