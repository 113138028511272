<div mat-dialog-title class="primary" fxLayout="row">
    <div>Καταγωγή: {{ measure.origin }} - Όροι</div>
    <!--  <div>
                <h1><small>MRN </small>{{ mrn }}</h1>
            </div> -->
    <div>
        <button mat-icon-button (click)="onCloseConfirm()">
            <mat-icon> highlight_off</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <!-- First Column -->
    <div style="width: 100%">
        <mat-card
            class="inport-text-info-container import-table-tariff-tr-widht-content"
            fxLayout="column"
        >
            <!-- ngIf Conditions -->
            <div *ngIf="show === 'conditions'">
                <ng-container
                    *ngTemplateOutlet="
                        conditionsTemplate;
                        context: { $implicit: measure }
                    "
                ></ng-container>
            </div>
            <!-- ngIf Conditions -->
            <!-- ngIf Regulations -->
            <div *ngIf="show === 'regulations'">
                <ng-container
                    *ngTemplateOutlet="
                        regulationsTemplate;
                        context: { $implicit: measure }
                    "
                ></ng-container>
            </div>
            <!-- ngIf Regulations -->

            <!-- ngIf Regulations -->
            <div *ngIf="show === 'quotas'">
                <ng-container
                    *ngTemplateOutlet="
                        quotasTemplate;
                        context: { $implicit: measure }
                    "
                ></ng-container>
            </div>
            <!-- ngIf Regulations -->
        </mat-card>
    </div>

    <!-- Second Column Send Only -->
</div>

<!-- COnditions Table -->
<ng-template #conditionsTemplate let-measure>
    <table>
        <thead style="border-bottom: 2px solid blue">
            <tr>
                <th style="width: 40px"></th>
                <th>Προϋπόθεση</th>
                <th>Ενέργεια</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let condition of measure.conditions">
                <td>{{ condition.type }}{{ condition.sequenceNumber }}</td>
                <td>
                    {{ condition.description }}
                    <span
                        *ngIf="condition.certificateType !== null"
                        [matTooltip]="condition.certificateDescription"
                        matTooltipClass="tooltip"
                        style="color: blue"
                    >
                        : {{ condition.certificateType }}
                        {{ condition.certificateNumber }}
                    </span>
                    <span *ngIf="condition.conditionExpression !== null">
                        : <strong>{{ condition.conditionExpression }}</strong>
                    </span>
                </td>
                <td>
                    {{ condition.actionDescription }}
                    <span *ngIf="condition.tariffFormula !== null"
                        >: <strong>{{ condition.tariffFormula }}</strong></span
                    >
                </td>
            </tr>
        </tbody>
        <!--   <tr>
            <td colspan="2">
                <b>Πρόσθετες Πληροφορίες</b>
            </td>
            <td></td>
        </tr>
        <tr *ngFor="let conditionCertificate of measure.conditions">
            <td colspan="2">
                {{ conditionCertificate.certificateType
                }}{{ conditionCertificate.certificateNumber }}
            </td>
            <td>
                {{ conditionCertificate.certificateDescription }}
            </td>
        </tr> -->
    </table>
</ng-template>
<!-- COnditions Table -->
<!-- Regulations Table -->
<ng-template #regulationsTemplate let-measure>
    <!--    <div data-ng-show="util.tariffsMeasuresRegulations===false">
        <p data-ng-show="model.data.tariffsMeasuresRegulations===null">No data retrieved</p>
    </div> -->
    <table class="ecl-table ecl-table--responsive tabletopbottom table-tarif">
        <thead>
            <tr>
                <th style="width: 8%">Αριθμός κανονισμού</th>
                <th style="width: 8%">Ημερομηνία έναρξης</th>
                <th style="width: 8%">Ημερομηνία λήξης</th>
                <th style="width: 10%">Ημερομηνία δημοσίευσης</th>
                <th style="width: 10%">Αριθμός της εφημερίδας</th>
                <th style="width: 10%">Σελίδα της εφημερίδας</th>
                <th style="width: 10%">Νομοθεσία</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let regulation of measure">
                <!--    <td>
                    {{
                        regulation.number.substring(0, 1) +
                            regulation.number.substring(3, 7) +
                            "/" +
                            regulation.number.substring(1, 3)
                    }}
                </td> -->

                <td>{{ regulation.number }}</td>
                <td *ngIf="regulation.startDate !== null">
                    {{ regulation.startDate | date: "dd-MM-yyyy" }}
                </td>
                <td *ngIf="regulation.startDate===null">-</td>
                <td *ngIf="regulation.endDate !== null">
                    {{ regulation.endDate | date: "dd-MM-yyyy" }}
                </td>
                <td *ngIf="regulation.endDate===null">-</td>
                <td>{{ regulation.publicationDate | date: "dd-MM-yyyy" }}</td>
                <td>
                    <span>{{ regulation.journalNumber }}</span>
                </td>
                <td>
                    <span>{{ regulation.journalPage }}</span>
                </td>
                <td>
                    <span>
                        <a
                            target="_blank"
                            href="{{
                                getDocumentURL(
                                    state.locale,
                                    regulation.publicationDate,
                                    regulation.journalNumber,
                                    regulation.journalPage
                                )
                            }}"
                            >[EUR.Lex]</a
                        >
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- </div> -->
</ng-template>
<!-- Regulations Table -->

<!-- Quotas Table -->
<ng-template #quotasTemplate let-measure>
    <!--    <div data-ng-show="util.tariffsMeasuresRegulations===false">
        <p data-ng-show="model.data.tariffsMeasuresRegulations===null">No data retrieved</p>
    </div> -->
    <table class="ecl-table ecl-table--responsive tabletopbottom table-tarif">
        <thead>
            <tr>
                <th style="width: 8%">Αύξων αριθμός</th>
                <th style="width: 8%">Ημερομηνία έναρξης</th>
                <th style="width: 8%">Ημερομηνία λήξης</th>
                <th style="width: 10%">Ημερομηνία δημοσίευσης</th>
                <th style="width: 10%">Αριθμός της εφημερίδας</th>
                <th style="width: 10%">Σελίδα της εφημερίδας</th>
                <th style="width: 10%">Νομοθεσία</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let regulation of measure">
                <!--    <td>
                    {{
                        regulation.number.substring(0, 1) +
                            regulation.number.substring(3, 7) +
                            "/" +
                            regulation.number.substring(1, 3)
                    }}
                </td> -->

                <td>{{ regulation.number }}</td>
                <td *ngIf="regulation.startDate !== null">
                    {{ regulation.startDate | date: "dd-MM-yyyy" }}
                </td>
                <td *ngIf="regulation.startDate===null">-</td>
                <td *ngIf="regulation.endDate !== null">
                    {{ regulation.endDate | date: "dd-MM-yyyy" }}
                </td>
                <td *ngIf="regulation.endDate===null">-</td>
                <td>{{ regulation.publicationDate | date: "dd-MM-yyyy" }}</td>
                <td>
                    <span>{{ regulation.journalNumber }}</span>
                </td>
                <td>
                    <span>{{ regulation.journalPage }}</span>
                </td>
                <td>
                    <span>
                        <a
                            target="_blank"
                            href="{{
                                getDocumentURL(
                                    state.locale,
                                    regulation.publicationDate,
                                    regulation.journalNumber,
                                    regulation.journalPage
                                )
                            }}"
                            >[EUR.Lex]</a
                        >
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- </div> -->
</ng-template>
<!-- Quotas Table -->
