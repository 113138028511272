import { Component } from '@angular/core';

@Component({
    selector: 'dots',
    template: `
        <style>
            .dots-cont {
                position: relative;
            }

            .dot {
                width: 0.5rem;
                height: 0.5rem;
                background: #22303e;
                display: inline-block;
                border-radius: 50%;
                right: 0px;
                bottom: 0px;
                margin: 0px 0.8px;
                position: relative;
            }

            div:hover > .dots-cont > .dot {
                position: relative;
                bottom: 0px;
                animation-name: jump;
                animation-duration: 0.15s;
                animation-iteration-count: 2;
                animation-direction: alternate;
                animation-timing-function: ease;
            }

            .dots-cont .dot-1 {
                -webkit-animation-delay: 50ms;
                animation-delay: 50ms;
            }

            .dots-cont .dot-2 {
                -webkit-animation-delay: 100ms;
                animation-delay: 100ms;
            }

            .dots-cont .dot-3 {
                -webkit-animation-delay: 150ms;
                animation-delay: 150ms;
            }
            @keyframes jump {
                from {
                    bottom: 0px;
                }
                to {
                    bottom: 7px;
                }
            }
            @-webkit-keyframes jump {
                from {
                    bottom: 0px;
                }
                to {
                    bottom: 7px;
                }
            }
        </style>
        <div>
            <span class="dots-cont">
                <span class="dot dot-1"></span>
                <span class="dot dot-2"></span>
                <span class="dot dot-3"></span>
            </span>
        </div>
    `
})
export class DotsComponent { }
