import { FormControl, AbstractControl } from '@angular/forms';
import { eAitisiHeaheaModel } from './heahea.model';

export class eAitisiHeaheaForm {
    [key: string]: AbstractControl;
    DocNumHEA5 = new FormControl(); //<===NOT USED NORMALLY, TO BE REMOVED BEFORE SUBMIT
    _amountOfTax = new FormControl();
    AmeAccDatHEA602 = new FormControl();

    //HEADER FORM
    RefNumHEA4 = new FormControl();
    AccDatHEA158 = new FormControl();
    //IB13A only
    AmdDatHEA599 = new FormControl();
    //=============
    DecSubDatTimHEA118 = new FormControl();
    CommentsHEA06 = new FormControl();
    MetOfPayHEA590 = new FormControl();
    GenrlPurpsTsksDescr = new FormControl();
    CustomsTasks = new FormControl();
    ManualRF = new FormControl();
    TotalAmount = new FormControl();

    constructor(value: eAitisiHeaheaModel) {
        this.DocNumHEA5.setValue(value.DocNumHEA5);
        this._amountOfTax.setValue(value._amountOfTax);
        this.AmeAccDatHEA602.setValue(value.AmeAccDatHEA602);

        this.RefNumHEA4.setValue(value.RefNumHEA4);
        this.AccDatHEA158.setValue(value.AccDatHEA158);
        this.AmdDatHEA599.setValue(value.AmdDatHEA599);
        this.DecSubDatTimHEA118.setValue(value.DecSubDatTimHEA118);
        this.CommentsHEA06.setValue(value.CommentsHEA06);
        this.MetOfPayHEA590.setValue(value.MetOfPayHEA590);
        this.GenrlPurpsTsksDescr.setValue(value.GenrlPurpsTsksDescr);
        this.CustomsTasks.setValue(value.CustomsTasks);
        this.ManualRF.setValue(value.ManualRF);
        this.TotalAmount.setValue(value.TotalAmount);
    }
}
