import { SubmittingOperatorModel } from "../../submittingOperator/submittingOperator.model";
import { VehicleArrivalModel } from "../../vehicleArrival/vehicleArrival.model";
import { VehicleArrivalConsigneeModel } from "../../vehicleArrivalConsignee/vehicleArrivalConsigee.model";
import { VehicleArrivalConsignorModel } from "../../vehicleArrivalConsignor/vehicleArrivalConsignor.model";
import { VehicleDetailsModel } from "../../vehicleDetails/vehicleDetails.model";

export class VA13ABodyModel {

    VehicleArrivalDeclarationAmendment: VehicleArrivalDeclarationAmendmentType;

    constructor(value?) {

        console.log("VA13ABodyModel value", value)

        this.VehicleArrivalDeclarationAmendment = value?.VehicleArrivalDeclarationAmendment || new VehicleArrivalDeclarationAmendmentType()
    }

}



export class VehicleArrivalDeclarationAmendmentType {



    SubmittingOperator?: SubmittingOperatorModel;
    VehicleArrival: VehicleArrivalModel;
    VehicleArrivalConsignor: VehicleArrivalConsignorModel;
    VehicleArrivalConsignee: VehicleArrivalConsigneeModel;
    VehicleDetails: VehicleDetailsModel[];

    constructor(value?) {

        this.SubmittingOperator = value?.SubmittingOperator;
        this.VehicleArrival = value?.VehicleArrival;
        this.VehicleArrivalConsignor = value?.VehicleArrivalConsignor;
        this.VehicleArrivalConsignee = value?.VehicleArrivalConsignee;
        this.VehicleDetails = value?.VehicleDetails;
    }



}