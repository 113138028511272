import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { FormFieldFunctions } from 'app/main/main-building-blocks/form-fields/form-field-functions.service';

@Pipe({
    name: 'filterNames'
})
export class FilterPipe implements PipeTransform {

    constructor(private formFieldFunctions: FormFieldFunctions) { }  // Inject the service here

    transform(items: any[], searchText: string, key0: string, key1?: string): any[] {
        if (!items) return [];
        if (!searchText) return items;

        searchText = searchText.toUpperCase();

        return items.filter(it => {
            // Call the service method here, passing the required parameters
            // Note that the parameters are dummy values and should be replaced by actual values.
            const filteredOptions = this.formFieldFunctions.autocompleteFilter(searchText, items, key0, key1);
            return filteredOptions.includes(it);
        });
    }
}
@NgModule({
    declarations: [FilterPipe],
    exports: [FilterPipe],
})
export class FilterPipeModule { }