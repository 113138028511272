import { FormControl, AbstractControl } from '@angular/forms';
import { InvoiceAccountModel } from './invoice-account.model';

export class InvoiceAccountForm {
    [key: string]: AbstractControl;

    id = new FormControl();
    active = new FormControl();
    archived = new FormControl();
    sequence = new FormControl();
    business_id = new FormControl();
    customer_id = new FormControl();
    account_name = new FormControl();
    account_display_id = new FormControl();
    group = new FormControl();
    normal_balance_type = new FormControl();
    sub_group = new FormControl();
    accrual_anchor_tier = new FormControl();
    archivable = new FormControl();
    system_created = new FormControl();
    currency_code = new FormControl();
    description = new FormControl();
    metadata = new FormControl();
    external_usage_type = new FormControl();
    permissions = new FormControl();
    account_balance = new FormControl();

    constructor(value: InvoiceAccountModel) {
        this.id.setValue(value.id);
        this.active.setValue(value.active);
        this.archived.setValue(value.archived);
        this.sequence.setValue(value.sequence);
        this.business_id.setValue(value.business_id);
        this.customer_id.setValue(value.customer_id);
        this.account_name.setValue(value.account_name);
        this.account_display_id.setValue(value.account_display_id);
        this.group.setValue(value.group);
        this.normal_balance_type.setValue(value.normal_balance_type);
        this.sub_group.setValue(value.sub_group);
        this.accrual_anchor_tier.setValue(value.accrual_anchor_tier);
        this.archivable.setValue(value.archivable);
        this.system_created.setValue(value.system_created);
        this.currency_code.setValue(value.currency_code);
        this.description.setValue(value.description);
        this.metadata.setValue(value.metadata);
        this.external_usage_type.setValue(value.external_usage_type);
        this.permissions.setValue(value.permissions);
        this.account_balance.setValue(value.account_balance);
    }
}
