import { FormControl, FormGroup, FormArray, Validators, AbstractControl, FormsModule } from '@angular/forms';
import { AccountingForm } from 'app/main/apps/export/declaration/_models/gooitegds/accounting/accounting-form.model';
import { AccountingModel } from 'app/main/apps/export/declaration/_models/gooitegds/accounting/accounting.model';
import { CalTaxForm } from 'app/main/apps/export/declaration/_models/gooitegds/caltaxgod/caltaxgod-form.model';
import { CalTaxModel } from 'app/main/apps/export/declaration/_models/gooitegds/caltaxgod/caltaxgod.model';
import { ComCodForm } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm-form.model';
import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { Connr2Form } from 'app/main/apps/export/declaration/_models/gooitegds/connr2/connr2-form.model';
import { Connr2Model } from 'app/main/apps/export/declaration/_models/gooitegds/connr2/connr2.model';
import { PacgsForm } from 'app/main/apps/export/declaration/_models/gooitegds/pacgs2/pacgs2-form.model';
import { PacgsModel } from 'app/main/apps/export/declaration/_models/gooitegds/pacgs2/pacgs2.model';
import { PreAdmRefForm } from 'app/main/apps/export/declaration/_models/gooitegds/preadmrefar2/preadmrefar2-form.model';
import { PreAdmRefModel } from 'app/main/apps/export/declaration/_models/gooitegds/preadmrefar2/preadmrefar2.model';
import { ProDocForm } from 'app/main/apps/export/declaration/_models/gooitegds/prodocdc2/prodocdc2-form.model';
import { ProDocModel } from 'app/main/apps/export/declaration/_models/gooitegds/prodocdc2/prodocdc2.model';
import { SpeMenForm } from 'app/main/apps/export/declaration/_models/gooitegds/spemenmt2/spemenmt2-form.model';
import { SpeMenModel } from 'app/main/apps/export/declaration/_models/gooitegds/spemenmt2/spemenmt2.model';
import { TraConce2Form } from 'app/main/apps/export/declaration/_models/gooitegds/traconce2/traconce2-form.model';
import { TraConce2Model } from 'app/main/apps/export/declaration/_models/gooitegds/traconce2/traconce2.model';
import { ImpGooIteGdsModel } from './gooitegds.model';
import { PosPayGI400Form } from './pospaygi400/pospaygi400-form.model';
import { PosPayGI400Model } from './pospaygi400/pospaygi400.model';
import { Rel800Form } from './rel800/rel800-form.model';
import { Rel800Model } from './rel800/rel800.model';
import { TaxAddEle100Form } from './taxaddele100/taxaddele100-form.model';
import { TaxAddEle100Model } from './taxaddele100/taxaddele100.model';
import { Traconco2Form } from './traconco2/traconco2-form.model';
import { Traconco2Model } from './traconco2/traconco2.model';
import { ImpVehDetForm } from './vehdet/vehdet-form.model';
import { ImpVehDetModel } from './vehdet/vehdet.model';
import { WarIDGI700Form } from './waridgi700/waridgi700-form.model';
import { WarIDGI700Model } from './waridgi700/waridgi700.model';
import { GrnImpForm } from 'app/main/apps/import/_models/grn_imp/grn-imp-form.model';
import { GrnImpModel } from 'app/main/apps/import/_models/grn_imp/grn-imp.model';

export class ImpGooIteGdsForm {
    [key: string]: AbstractControl;
    IteNumGDS7: FormControl;
    GooDesGDS23: FormControl;
    GooDesGDS23LNG: FormControl;
    GroMasGDS46: FormControl;
    NetMasGDS48: FormControl;
    MetOfPayGDI12: FormControl;
    ProReqGDI1: FormControl;
    PreProGDI1: FormControl;
    ComNatProGIM1: FormControl;
    StaValCurGDI1: FormControl;
    CouOfOriGDI1: FormControl;
    SupUniGDI1: FormControl;
    UNDanGooCodGDI1: FormControl;
    PlaLoaGOOITE333: FormControl;
    PlaUnlGOOITE333: FormControl;
    IdeMeaTraCroBorGDS1031: FormControl;
    IdeMeaTraCroBorGDS1031LNG: FormControl;
    NatMeaTraCroBorGDS1032: FormControl;
    Pre4046: FormControl;
    Quo4013: FormControl;
    ProPri4002: FormControl;
    ProReqEndDat4003: FormControl;
    IdeOfMeaOfTraArrGDI3003: FormControl;
    EstOfValGDS14: FormControl;
    ReconcilGDS15: FormControl;
    ContacPersonGI65: FormControl;
    UcrGI56: FormControl;
    ArrDatGDS150: FormControl;
    StaValAmoGDI1: FormControl;
    NatfmeatraatarrGIMES: FormControl;
    IsSorCerGIMES: FormControl;
    PREADMREFAR2: FormArray;
    PRODOCDC2: FormArray;
    SPEMENMT2: FormArray;
    TRACONCO2: FormArray;
    COMCODGODITM: FormArray;
    CALTAXGOD: FormArray;
    TRACONCE2: FormArray;
    CONNR2: FormArray;
    PACGS2: FormArray;
    GRN: FormArray;
    VEHDET: FormArray;
    TAXADDELE100: FormArray;
    POSPAYGI400: FormArray;
    REL800: FormArray;
    WARIDGI700: FormArray;

    ACCOUNTING: FormArray;

    constructor(goods: ImpGooIteGdsModel) {
        //console.trace("Who creates GooItegds");
        // console.log("___GooIteGdsFormModel constructor", goods);

        this.IteNumGDS7 = new FormControl(goods.IteNumGDS7, {
            updateOn: 'blur'
        });
        this.GooDesGDS23 = new FormControl(goods.GooDesGDS23, {
            updateOn: 'blur'
        });
        this.GooDesGDS23LNG = new FormControl(goods.GooDesGDS23LNG);
        this.GroMasGDS46 = new FormControl(goods.GroMasGDS46, {
            updateOn: 'blur'
        });
        this.NetMasGDS48 = new FormControl(goods.NetMasGDS48, {
            updateOn: 'blur'
        });
        this.MetOfPayGDI12 = new FormControl(goods.MetOfPayGDI12);
        this.ProReqGDI1 = new FormControl(goods.ProReqGDI1);
        this.PreProGDI1 = new FormControl(goods.PreProGDI1);
        this.ComNatProGIM1 = new FormControl(goods.ComNatProGIM1);
        this.StaValCurGDI1 = new FormControl(goods.StaValCurGDI1);
        this.CouOfOriGDI1 = new FormControl(goods.CouOfOriGDI1);
        this.SupUniGDI1 = new FormControl(goods.SupUniGDI1, {
            updateOn: 'blur'
        });
        this.UNDanGooCodGDI1 = new FormControl(goods.UNDanGooCodGDI1, {
            updateOn: 'blur'
        });
        this.PlaLoaGOOITE333 = new FormControl(goods.PlaLoaGOOITE333);
        this.PlaUnlGOOITE333 = new FormControl(goods.PlaUnlGOOITE333, {
            updateOn: 'blur'
        });
        this.IdeMeaTraCroBorGDS1031 = new FormControl(goods.IdeMeaTraCroBorGDS1031, { updateOn: 'blur' });
        this.IdeMeaTraCroBorGDS1031LNG = new FormControl(goods.IdeMeaTraCroBorGDS1031LNG, { updateOn: 'blur' });
        this.NatMeaTraCroBorGDS1032 = new FormControl(goods.NatMeaTraCroBorGDS1032);
        this.Pre4046 = new FormControl(goods.Pre4046);
        this.Quo4013 = new FormControl(goods.Quo4013, { updateOn: 'blur' });
        this.ProPri4002 = new FormControl(goods.ProPri4002, {
            updateOn: 'blur'
        });
        this.ProReqEndDat4003 = new FormControl(goods.ProReqEndDat4003, {
            updateOn: 'blur'
        });
        this.IdeOfMeaOfTraArrGDI3003 = new FormControl(goods.IdeOfMeaOfTraArrGDI3003, { updateOn: 'blur' });
        this.EstOfValGDS14 = new FormControl(goods.EstOfValGDS14);
        this.ReconcilGDS15 = new FormControl(goods.ReconcilGDS15, {
            updateOn: 'blur'
        });
        this.ContacPersonGI65 = new FormControl(goods.ContacPersonGI65, {
            updateOn: 'blur'
        });
        this.UcrGI56 = new FormControl(goods.UcrGI56, { updateOn: 'blur' });
        this.ArrDatGDS150 = new FormControl(goods.ArrDatGDS150, {
            updateOn: 'blur'
        });
        this.StaValAmoGDI1 = new FormControl(goods.StaValAmoGDI1, {
            updateOn: 'blur'
        });
        this.NatfmeatraatarrGIMES = new FormControl(goods.NatfmeatraatarrGIMES, { updateOn: 'blur' });
        this.IsSorCerGIMES = new FormControl(goods.IsSorCerGIMES, {
            updateOn: 'blur'
        });
        this.PREADMREFAR2 = new FormArray([]);
        this.PRODOCDC2 = new FormArray([]);
        this.SPEMENMT2 = new FormArray([]);
        this.TRACONCO2 = new FormArray([]);
        this.COMCODGODITM = new FormArray([]);
        this.CALTAXGOD = new FormArray([]);
        this.TRACONCE2 = new FormArray([]);
        this.CONNR2 = new FormArray([]);
        this.PACGS2 = new FormArray([]);
        this.GRN = new FormArray([]);
        this.VEHDET = new FormArray([]);
        this.TAXADDELE100 = new FormArray([]);
        this.POSPAYGI400 = new FormArray([]);
        this.REL800 = new FormArray([]);
        this.WARIDGI700 = new FormArray([]);
        this.ACCOUNTING = new FormArray([]);

        if (goods.PREADMREFAR2) {
            goods.PREADMREFAR2.forEach((prevdocs: PreAdmRefModel) => {
                this.PREADMREFAR2.push(new FormGroup(new PreAdmRefForm(new PreAdmRefModel(prevdocs))));
            });
        } else {
            this.PREADMREFAR2.push(new FormGroup(new PreAdmRefForm(new PreAdmRefModel())));
        }

        if (goods.PRODOCDC2) {
            goods.PRODOCDC2.forEach((prodocs: ProDocModel) => {
                this.PRODOCDC2.push(new FormGroup(new ProDocForm(new ProDocModel(prodocs))));
            });
        } else {
            this.PRODOCDC2.push(new FormGroup(new ProDocForm(new ProDocModel())));
        }

        if (goods.SPEMENMT2) {
            goods.SPEMENMT2.forEach((spemen: SpeMenModel) => {
                this.SPEMENMT2.push(new FormGroup(new SpeMenForm(new SpeMenModel(spemen))));
            });
        } else {
            this.SPEMENMT2.push(new FormGroup(new SpeMenForm(new SpeMenModel())));
        }

        if (goods.TRACONCO2) {
            goods.TRACONCO2.forEach((tracocno2: Traconco2Model) => {
                this.TRACONCO2.push(new FormGroup(new Traconco2Form(new Traconco2Model(tracocno2))));
            });
        } else {
            this.TRACONCO2.push(new FormGroup(new Traconco2Form(new Traconco2Model())));
        }

        if (goods.COMCODGODITM) {
            goods.COMCODGODITM.forEach((comcod: ComCodModel) => {
                this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel(comcod))));
            });
        } else {
            this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel())));
        }

        if (goods.CALTAXGOD) {
            goods.CALTAXGOD.forEach((caltax: CalTaxModel) => {
                this.CALTAXGOD.push(new FormGroup(new CalTaxForm(new CalTaxModel(caltax))));
            });
        }

        if (goods.TRACONCE2) {
            goods.TRACONCE2.forEach((traconce: TraConce2Model) => {
                this.TRACONCE2.push(new FormGroup(new TraConce2Form(new TraConce2Model(traconce))));
            });
        } else {
            this.TRACONCE2.push(new FormGroup(new TraConce2Form(new TraConce2Model())));
        }

        //FORMARRAYS

        if (goods.CONNR2) {
            goods.CONNR2.forEach((connr: Connr2Model) => {
                this.CONNR2.push(new FormGroup(new Connr2Form(new Connr2Model(connr))));
            });
        } else {
            this.CONNR2.push(new FormGroup(new Connr2Form(new Connr2Model())));
        }

        if (goods.PACGS2) {
            goods.PACGS2.forEach((pacgs: PacgsModel) => {
                this.PACGS2.push(new FormGroup(new PacgsForm(new PacgsModel(pacgs))));
            });
        } else {
            this.PACGS2.push(new FormGroup(new PacgsForm(new PacgsModel())));
        }

        if (goods.GRN) {
            goods.GRN.forEach((grn: GrnImpModel) => {
                this.GRN.push(new FormGroup(new GrnImpForm(new GrnImpModel(grn))));
            });
        } else {
            this.GRN.push(new FormGroup(new GrnImpForm(new GrnImpModel())));
        }
        if (goods.VEHDET) {
            goods.VEHDET.forEach((vehdet: ImpVehDetModel) => {
                this.VEHDET.push(new FormGroup(new ImpVehDetForm(new ImpVehDetModel(vehdet))));
            });
        }
        if (goods.TAXADDELE100) {
            goods.TAXADDELE100.forEach((addele: TaxAddEle100Model) => {
                this.TAXADDELE100.push(new FormGroup(new TaxAddEle100Form(new TaxAddEle100Model(addele))));
            });
        } else {
            this.TAXADDELE100.push(new FormGroup(new TaxAddEle100Form(new TaxAddEle100Model())));
        }
        if (goods.POSPAYGI400) {
            goods.POSPAYGI400.forEach((pospay: PosPayGI400Model) => {
                this.POSPAYGI400.push(new FormGroup(new PosPayGI400Form(new PosPayGI400Model(pospay))));
            });
        }

        if (goods.REL800) {
            goods.REL800.forEach((relief: Rel800Model) => {
                this.REL800.push(new FormGroup(new Rel800Form(new Rel800Model(relief))));
            });
        }
        else {
            this.REL800.push(new FormGroup(new Rel800Form(new Rel800Model())));
        }

        if (goods.WARIDGI700) {
            goods.WARIDGI700.forEach((warehouse: WarIDGI700Model) => {
                this.WARIDGI700.push(new FormGroup(new WarIDGI700Form(new WarIDGI700Model(warehouse))));
            });
        }

        if (goods.ACCOUNTING) {
            goods.ACCOUNTING.forEach((accounting: AccountingModel) => {
                this.ACCOUNTING.push(new FormGroup(new AccountingForm(new AccountingModel(accounting))));
            });
        }
    }
}
