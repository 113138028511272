import { TransactionCategoryGroup, TransactionCategorySubGroup, TransactionCategoryType, TransactionTaxAction } from './../transaction-types.model';
export class TransactionCategoryModel {


    label: string;
    type: TransactionCategoryType;
    accountId: string;
    transactionId: string;
    transactionSequence: number;
    lineItemOrder: number;
    categorizationGroupValue: TransactionCategoryGroup;
    itemType: 'DEBIT' | 'CREDIT';
    taxAction: TransactionTaxAction;
    description: string;
    categorizationSubGroupValue: TransactionCategorySubGroup;
    sortKey: string[];
    customerId: string;
    vendorId: string;


    constructor(value?: TransactionCategoryModel) {

        this.label = value?.label || null;
        this.type = value?.type || null;
        this.accountId = value?.accountId || null;
        this.transactionId = value?.transactionId || null;
        this.transactionSequence = value?.transactionSequence || null;
        this.lineItemOrder = value?.lineItemOrder || null;
        this.categorizationGroupValue = value?.categorizationGroupValue || null;
        this.itemType = value?.itemType || null;
        this.taxAction = value?.taxAction || null;
        this.description = value?.description || null;
        this.categorizationSubGroupValue = value?.categorizationSubGroupValue || null;
        this.sortKey = value?.sortKey || null;
        this.customerId = value?.customerId || null;
        this.vendorId = value?.vendorId || null;
    }
}
