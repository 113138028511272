import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DistillationLicenceAppService } from 'app/main/apps/general-applications/distillation-licence-app/distillation-licence-app.service';
import { BrowserDetectService } from 'app/services/browser-detect.service';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'app-distillation-licence-app-print',
    templateUrl: './distillation-licence-app-print.component.html',
    styleUrls: ['./distillation-licence-app-print.component.scss']
})
export class DistillationLicenceAppPrintComponent implements OnInit {

    dilosiForm$: Observable<FormGroup>;
    dilosiForm: FormGroup;
    producerDetails: FormGroup;
    ownStems: FormGroup;
    returnedStems: FormGroup;
    grapes: FormGroup;
    winery: FormGroup;
    boilerInfo: FormGroup;



    constructor(private dilosiService: DistillationLicenceAppService, public browser: BrowserDetectService) { }

    ngOnInit(): void {

        this.dilosiForm$ = this.dilosiService.dilosiForm$.pipe(tap(form => {


            this.dilosiForm = form
            console.log("this.dilosiForm", this.dilosiForm)

            this.producerDetails = this.dilosiForm.get('producerDetails') as FormGroup;
            this.ownStems = this.dilosiForm.get('ownStems') as FormGroup;
            this.returnedStems = this.dilosiForm.get('returnedStems') as FormGroup;
            this.grapes = this.dilosiForm.get('grapes') as FormGroup;
            this.winery = this.dilosiForm.get('winery') as FormGroup;
            this.boilerInfo = this.dilosiForm.get('boilerInfo') as FormGroup;

        }))
    }

}
