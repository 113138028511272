import { RetrievedDescription } from 'app/services/com-code.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject, switchMap } from 'rxjs';

import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { takeWhile, scan, concatMap, take, tap } from 'rxjs/operators';
import { NestedTreeControl } from '@angular/cdk/tree';
import { TaricQuery } from './taric-results-table/taric-results-table.component';
import { PaginatedDataSource } from 'app/services/datasource/paginated-datasource';
import { Page, PageRequest, Sort } from 'app/services/datasource/page';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { TariffsDialogComponent } from './tariffs-dialog/tariffs-dialog.component';
import { TreeDataSource } from './taric-tree/taric-tree.datasource';
import { interval as observableInterval } from 'rxjs';
import { countriesItem } from 'app/model/api-model';
import { CodeDescDialogComponent } from './code-desc-dialog/code-desc-dialog.component';
import { MainBroadcasterService } from 'app/services/broadcaster.service';

export interface ProductNode {
    id: number;
    description: string;
    hasChildren: boolean;
    ancestors?: ProductNode[];
    code?: string;
    children?: ProductNode[];
    selectable?: boolean;
    requested?: boolean;
    isLoading?: boolean;
    intervalMin?: string;
    intervalMax?: string;
    expanded?: boolean;
    chapter?: string;
    heading?: string;
    subheading?: string;
    subheadingStyle?: string;
    intervalMinChapter?: string;
    intervalMinHeading?: string;
    intervalMinSubheading?: string;
    intervalMinSubheadingStyle?: string;
    intervalMaxChapter?: string;
    intervalMaxHeading?: string;
    intervalMaxSubheading?: string;
    intervalMaxSubheadingStyle?: string;
    hasMatches?: string;
}

export interface Filters {
    exportCountry: string;
    destinationCountry: string;
}
export interface TariffRequest {
    applicant: string; //taric tree or search results
    product: ProductNode;
    filters: Filters;
}

@Injectable({ providedIn: 'root' })
export class TaricService {
    private _destroy: Subject<void>;

    tableDataSource: BehaviorSubject<PaginatedDataSource<ProductNode, TaricQuery>>;

    tableDataSource$: Observable<PaginatedDataSource<ProductNode, TaricQuery>>;

    treeDataSource: BehaviorSubject<TreeDataSource>;

    treeDataSource$: Observable<TreeDataSource>;

    selectedIndex: BehaviorSubject<number>;

    directMatches: BehaviorSubject<any[]>;

    directMatches$: Observable<any[]>;

    loading: BehaviorSubject<boolean>
    loading$: Observable<boolean>


    initialSort: Sort<ProductNode> = {
        property: 'code',
        order: 'desc'
    };

    countries: countriesItem[];

    constructor(
        public dialog: MatDialog,
        private dbQuery: ApiService,
        private mainBroadcaster: MainBroadcasterService,
        public dialogTariffRef: MatDialogRef<TariffsDialogComponent>,
        public codeDescDialogRef: MatDialogRef<CodeDescDialogComponent>

    ) {
        // Set the defaults
        console.log('SERVICE Taric Service Started');

        this._destroy = new Subject<void>();

        this.selectedIndex = new BehaviorSubject(0);
        //   this.tariffLoading = new BehaviorSubject(false);
        this.treeDataSource = new BehaviorSubject(new TreeDataSource(new NestedTreeControl(node => node.children), []));

        this.directMatches = new BehaviorSubject([]);

        this.loading = new BehaviorSubject(false);


        if (!this.treeDataSource$) {
            this.treeDataSource$ = this.treeDataSource.asObservable();
        }

        if (!this.directMatches$) {
            this.directMatches$ = this.directMatches.asObservable();
        }

        if (!this.loading$) {
            this.loading$ = this.loading.asObservable();
        }

        //Initial DataSource Empty
        const tableDataSource = new PaginatedDataSource<ProductNode, TaricQuery>(
            (request, query) => this.getTaricByKeyword(request, query),
            this.initialSort,
            { search: '' },
            5
        );

        this.tableDataSource = new BehaviorSubject(tableDataSource);

        if (!this.tableDataSource$) {
            this.tableDataSource$ = this.tableDataSource.asObservable();
        }

        this.getAllTaric();

        this.mainBroadcaster.countries$
            .pipe(take(1))
            .subscribe(countries => {
                this.countries = countries;
            });
    }

    ngOnDestroy() {
        console.log('TARIX Taric Service Destroyed');

        this._destroy.next();
        this._destroy.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getTaricByKeyword(request: PageRequest<ProductNode>, query: TaricQuery): Observable<Page<ProductNode>> {
        const postData = {
            pageNumber: request.page,
            pageSize: request.size,
            sortColumn: request.sort.property,
            sortOrder: request.sort.order,
            query
        };
        return this.dbQuery.getTaricByKeyword(postData).pipe(
            tap(data => {
                console.log('table data:', data);
                if (data.content) {
                    data.content = this.formatProductCode(data.content);
                }
            })
        );
    }

    updateDatasource(searchQuery: string) {
        console.log('TXTQUERY updateDatasource');
        const tableDataSource = new PaginatedDataSource<ProductNode, TaricQuery>(
            (request, query) => this.getTaricByKeyword(request, query),
            this.initialSort,
            { search: searchQuery },
            5
        );

        //this.getDirectMatches(searchQuery);

        this.tableDataSource.next(tableDataSource);

        console.log('TXTQUERY ResultTable Returning tableDataSource', tableDataSource);

        return of(tableDataSource);
    }

    //==============

    getTariff(reqParams: TariffRequest): Observable<any[]> {
        console.log('Received request params', reqParams);

        console.log(reqParams);

        //this.tariffLoading.next(false);
        //this.tariffLoading.next(true);

        const product = reqParams.product;
        const filters = reqParams.filters;

        const exportCountry = filters.exportCountry;
        const destCountry = filters.destinationCountry;

        let action = '';
        let countryCode = '';

        if (exportCountry === 'GR') {
            action = 'EXP';
            countryCode = destCountry;
        } else {
            action = 'IMP';
            countryCode = exportCountry;
        }

        console.log(product);
        const postData = {
            action: action,
            country: countryCode,
            code: product.code
        };
        const country0 = this.countries.find(country => country.country_code === countryCode);

        const countryName = country0 ? country0.country_name : '';

        return this.dbQuery.getTariffs(postData).pipe(
            take(1),
            tap(duties => {
                //   this.tariffLoading.next(false);

                this.openTariff(duties, product.code, action, countryName);
            })
        );
    }

    openTariff(duties, code, action, countryName) {
        const dialogConfig = new MatDialogConfig();
        // dialogConfig.autoFocus = true;
        dialogConfig.width = '70%';
        dialogConfig.minHeight = '100px';
        dialogConfig.height = 'auto';
        dialogConfig.panelClass = 'mat-dialog-override';
        dialogConfig.data = {
            duties,
            code,
            action,
            country: countryName
        };

        this.dialogTariffRef = this.dialog.open(TariffsDialogComponent, dialogConfig);

        this.dialogTariffRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                this.dialogTariffRef.close();
            }
        });

        this.dialogTariffRef.backdropClick().subscribe(() => {
            this.dialogTariffRef.close();
        });
    }

    searchByCode(searchValue, id) {
        return this.dbQuery.get_taric(searchValue, id).pipe(
            take(1),
            tap((data: ProductNode[]) => {
                // this.treeData.next(data);
                this.treeDataSource.next(new TreeDataSource(new NestedTreeControl(node => node.children), data));
                this.selectedIndex.next(0);
            })
        );
    }

    showInTree(parent: ProductNode) {
        console.log('parentShowInTree', parent);

        this.treeDataSource.value.treeControl.collapseAll();
        this.selectedIndex.next(0);

        from(parent.ancestors)
            .pipe(
                concatMap((record: ProductNode) => {
                    console.log('record to be expanded', record);
                    this.scrollToHook(record.id);
                    return this.expandNode(record);
                })
            )
            .subscribe(childrenNodes => {
                childrenNodes.forEach((element: ProductNode) => {
                    if (element.id === parent.id) {
                        this.scrollToHook(parent.id);
                    }
                });
            });
    }

    formatProductCode(productList: ProductNode[]) {
        for (let i = 0; i < productList.length; i++) {
            const product = productList[i];

            //  product.description=$sce.trustAsHtml(product.description);

            if (product.code && product.code.length >= 2) {
                product.chapter = product.code.substring(0, 2);
            }

            if (product.code && product.code.length >= 4) {
                product.chapter = product.code.substring(0, 2);

                product.heading = product.code.substring(2, 4);

                product.subheading = this.setSubheading(product.code);

                product.subheadingStyle = this.chunk(product.subheading, 2).join(' ');
            }

            if (product.intervalMin && product.intervalMin.length >= 4) {
                product.intervalMinChapter = product.intervalMin.substring(0, 2);

                product.intervalMinHeading = product.intervalMin.substring(2, 4);

                product.intervalMinSubheading = product.intervalMin.substring(4);

                product.intervalMinSubheadingStyle = this.chunk(product.intervalMinSubheading, 2).join(' ');
            }

            if (product.intervalMax && product.intervalMax.length >= 4) {
                product.intervalMaxChapter = product.intervalMax.substring(0, 2);

                product.intervalMaxHeading = product.intervalMax.substring(2, 4);

                product.intervalMaxSubheading = product.intervalMax.substring(4);

                product.intervalMaxSubheadingStyle = this.chunk(product.intervalMaxSubheading, 2).join(' ');
            }

            if (product.children) {
                product.children = this.formatProductCode(product.children);

                product.expanded = product.children.length > 0;
            }
        }

        return productList;
    }

    chunk(str, n) {
        const ret = [];
        const len = str.length;

        for (let i = 0; i < len; i += n) {
            ret.push(str.substr(i, n));
        }

        return ret;
    }

    setSubheading(code) {
        let subheading = code.substring(4);

        if (subheading === '000000') {
            subheading = '';
        } else if (subheading.substring(2, 6) === '0000') {
            subheading = subheading.substring(0, 2);
        } else if (subheading.substring(4, 6) === '00') {
            subheading = subheading.substring(0, 4);
        }
        return subheading;
    }
    hasMatches(isDirectResults, id) {
        let match = 'NOT_FOUND';

        if (isDirectResults) {
            for (let i = 0; i < isDirectResults.length && match === 'NOT_FOUND'; i++) {
                const result = isDirectResults[i];
                if (result.id === id) {
                    match = result.isDirect ? 'DIRECT' : 'INDIRECT';
                }
            }
        }

        return match;
    }

    markSearchResults(productList, isDirectResults) {
        console.log('productList', productList);
        console.log('isDirectResults', isDirectResults);

        for (let i = 0; i < productList.length; i++) {
            const product = productList[i];

            product.hasMatches = this.hasMatches(isDirectResults, product.id);

            if (product.children) {
                product.children = this.markSearchResults(product.children, isDirectResults);
            }
        }

        return productList;
    }

    updateDirectMatches(matches) {
        console.log('TXTQUERY DirectMatches Updated', matches);
        this.directMatches.next(matches);
    }

    getDirectMatches(searchQuery): Observable<any> {
        console.log('TXTQUERY getDirectMatches');

        const postData = {
            query: {
                search: searchQuery,
                directMatches: true
            }
        };
        const directMatches$ = this.dbQuery.getDirectMatches(postData).pipe(take(1));

        const allTree$ = this.dbQuery.get_taric('all', null).pipe(take(1));

        return directMatches$.pipe(
            take(1),
            concatMap(directMatches => {
                this.updateDirectMatches(directMatches);

                return allTree$;
            }),
            tap(allTree => {
                //this.treeData.next(allTree);
                this.treeDataSource.next(new TreeDataSource(this.treeDataSource.value.treeControl, allTree));

                this.markSearchResults(allTree, this.directMatches.value);
            })
        );
    }

    expandNode(parentNode: ProductNode) {
        console.log('expandNode entered');
        console.log('expandNode parentNode.children', parentNode.children);

        if (!parentNode.children) {
            parentNode.isLoading = true;

            if (parentNode.ancestors) {
                console.log('ancestors On Expand', parentNode.ancestors);
            }

            return this.dbQuery.get_taric(null, parentNode.id).pipe(
                take(1),
                tap((nodes: ProductNode[]) => {
                    //console.log("parentNode",parentNode)
                    //console.log("nodes",nodes)
                    parentNode.isLoading = false;

                    let treeData = this.formatProductCode(nodes);

                    treeData = this.markSearchResults(treeData, this.directMatches.value);

                    console.log(treeData);

                    treeData.forEach(node => {
                        //console.log("nodes", nodes)
                        //console.log("node to be added", node)
                        this.treeDataSource.getValue().add(node, parentNode);
                    });
                    parentNode.expanded = true;
                })
            );
        } else {
            this.treeDataSource.value.treeControl.expand(parentNode);

            parentNode.expanded = true;

            return of([]);
        }
    }

    collapseNode(parentNode: ProductNode) {
        if (parentNode.hasChildren && parentNode.children) {
            parentNode.children.forEach(node => {
                if (node.hasChildren && node.children) {
                    this.treeDataSource.value.treeControl.collapse(node);
                    this.collapseNode(node);
                }
            });
        }

        this.treeDataSource.value.treeControl.collapse(parentNode);

        parentNode.expanded = false;
    }

    expandAll(data: ProductNode[]): any {
        console.log('data on expandALl', data);
        const treeData = this.formatProductCode(data);

        treeData.forEach(node => {
            //console.log("data.node", node)

            if (node.hasChildren && node.children) {
                this.treeDataSource.value.treeControl.expand(node);
                this.expandAll(node.children);
            }
        });
    }

    getTaricDescriptions(id) {
        this.dbQuery.getTaricDescriptions(id).pipe(take(1));
    }

    getAllTaric(): Observable<ProductNode[]> {
        return this.dbQuery.get_taric('all', null).pipe(
            tap((data: ProductNode[]) => {
                //this.treeData.next(data);
                this.treeDataSource.next(new TreeDataSource(new NestedTreeControl(node => node.children), data));
            }),
            take(1)
        );
    }

    scrollToTop(el) {
        console.log('scrollToTop', el);
        const duration = 100;
        const interval = 5;
        const move = (el.scrollTop * interval) / duration;

        observableInterval(interval)
            .pipe(
                scan((acc, curr) => acc - move, el.scrollTop),
                tap(position => (el.scrollTop = position)),
                takeWhile(val => val > 0)
            )
            .subscribe();
    }

    scrollToHook(productId) {
        setTimeout(() => {
            const node = document.querySelector('#node_' + productId);
            node.scrollIntoView({ block: 'center' });
        }, 100);

        // node.scrollIntoView();
    }

    isProductNode(toBeDetermined: any): toBeDetermined is ProductNode[] {
        if ((toBeDetermined as ProductNode[]).length) {
            return true;
        }
        return false;
    }


    setCodeDesc(event): Observable<any> {
        const product: ProductNode = event.product;
        const type: string = event.type;
        const goodsIndex: number = event.goodsIndex;

        console.log('type on setCodeDesc', type);
        console.log('product on setCodeDesc', product);
        console.log('goodsIndex on setCodeDesc', goodsIndex);

        if (type === 'code') {
            return this.openCodeDescDialog(type, product);

            //type: description
        } else {
            return this.dbQuery
                .getTaricDescriptions(product.id)
                .pipe(switchMap(descriptions => {
                    return this.openCodeDescDialog(type, product, descriptions, goodsIndex);
                }), take(1));
        }
    }

    openCodeDescDialog(type: string, product: ProductNode, descriptions?: string[], goodsIndex?: number): Observable<any> {
        const dialogConfig = new MatDialogConfig();

        // dialogConfig.autoFocus = true;
        dialogConfig.width = '70%';
        dialogConfig.height = '95%';
        dialogConfig.panelClass = 'mat-dialog-override';
        dialogConfig.data = {
            type,
            product,
            descriptions,
            goodsIndex
        };
        this.codeDescDialogRef = this.dialog.open(CodeDescDialogComponent, dialogConfig);

        this.codeDescDialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                this.codeDescDialogRef.close();
            }
        });

        this.codeDescDialogRef.backdropClick().subscribe(() => {
            this.codeDescDialogRef.close();
        });

        return this.codeDescDialogRef.afterClosed()/* .subscribe(result => {
            console.log('CodeDescDialogComponent on AfterCOlosed', result);
            if (result) {
                console.log('CodeDescDialogComponent on AfterCOlosed REsult found', result);
            } else {
                console.log('CodeDescDialogComponent on AfterCOlosed NOT  found', result);
            } 
        });*/
    }
}
