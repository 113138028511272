import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeclarationForms, DeclarationStates, MainBroadcasterService } from 'app/services/broadcaster.service';
import { DeclarationStateService } from 'app/services/declaration-state.service';
import { NotificationService } from 'app/services/notifications.service';
import { BehaviorSubject, Subject, Observable, of, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, share, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ArchiveResult, ArchiveSaveDataCommon } from '../../archive/archive.model';
import { ArrAtExitForm } from './_models/arrivaAtExit-form.model';
import { ArrAtExitModel } from './_models/arrivaAtExit.model';
import declarationData from 'assets/declarationData.json';
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { ApiService } from 'app/services/api.service';
import { StorageService } from 'app/core/services/storage/storage.service';
import { DeclarationForm } from 'app/main/apps/export/declaration/_models/declaration-form.model';


@Injectable()
export class ArrivalAtExitService implements OnDestroy {
    private arrAtExitForm: BehaviorSubject<FormGroup<ArrAtExitForm>>;
    private _destroy: Subject<void> = new Subject<void>();

    arrAtExitForm$: Observable<FormGroup>;

    saveData: FormGroup;
    userId: number;
    declarantId: string;
    traderId: string;
    refNumber: string;
    domain: string;
    message: string;
    state: string;
    stateDate: string;
    mrn: string;

    subdomain = 'ARR_AT_EXIT';


    constructor(
        public matDialog: MatDialog,
        private fb: FormBuilder,
        private mainBroadcaster: MainBroadcasterService,
        private declarationStateService: DeclarationStateService,
        private notificationService: NotificationService,
        private openDialogsService: OpenDialogsService,
        private dbQuery: ApiService,
        private storageService: StorageService
    ) {
        console.log('ARRATEXIT SERVICE INIT');

        const arrAtExitForm = this.fb.group(new ArrAtExitForm(new ArrAtExitModel(), this.subdomain));



        this.arrAtExitForm = new BehaviorSubject(arrAtExitForm);

        if (!this.arrAtExitForm$) {
            this.arrAtExitForm$ = this.arrAtExitForm.asObservable();
        }

        console.log('ARRATEXIT SERVICE INIT', this.arrAtExitForm.getValue().value);
    }

    ngOnDestroy() {
        console.log('ARRATEXIT SERVICE DESTROYED');

        this._destroy.next();
        this._destroy.complete();
    }

    getCustoms(countryCode) {
        return this.dbQuery.get_customs(countryCode).pipe(share(), take(1));
    }

    getCarriers() {

        return combineLatest([this.mainBroadcaster.carriers$, this.mainBroadcaster.domecticCustomers$])
            .pipe(
                map(([carriers, pelates]) => {

                    return carriers.concat(pelates)
                }),
                take(1)
            )
    }




    fillForm(referrerDeclarationId: string): Observable<[string, FormGroup<DeclarationForm>]> {
        const activeSubdomain$ = this.mainBroadcaster.activeSubdomain$;
        const declarationForms$ = this.mainBroadcaster.declarationForms$;

        return combineLatest([activeSubdomain$, declarationForms$]).pipe(
            map(([subdomain, declarationForms]) => {
                console.log("fillForm activeSubdomain", subdomain)
                const declarationForm = declarationForms[subdomain];

                if (subdomain !== 'ARR_AT_EXIT') {
                    this.patchForm(this.arrAtExitForm.value, declarationForm, referrerDeclarationId);
                }

                return [subdomain, declarationForm];

            })
        );
    }

    patchForm(arrAtExitForm: FormGroup<ArrAtExitForm>, declarationForm: FormGroup<DeclarationForm>, referrerDeclarationId: string) {
        console.log('arrAtExit patchForm declarationForm', declarationForm?.getRawValue())
        if (declarationForm) {
            const heahea = declarationForm?.get('HEAHEA') as FormGroup;
            const mrn = heahea?.get('DocNumHEA5').value;
            const trader = declarationForm.get('_PRIVATE').get('trader').value
            const declarant = declarationForm.get('_PRIVATE').get('declarant').value
            const exitCustoms = declarationForm.get('CUSOFFEXIEXT').get('RefNumEXT1').value


            console.log('arrAtExit mrn', mrn);
            console.log('arrAtExit patchForm declarationForm', declarationForm.getRawValue());
            console.log('arrAtExit patchForm arrAtExitForm', arrAtExitForm.value);

            arrAtExitForm.get('_PRIVATE').get('trader').setValue(trader)
            arrAtExitForm.get('_PRIVATE').get('declarant').setValue(declarant)

            if (referrerDeclarationId) {
                arrAtExitForm.get('_PRIVATE').get('referrerDeclarationId').setValue(referrerDeclarationId)
            }


            if (mrn && mrn !== null) {
                (arrAtExitForm.get('HEAHEA') as FormGroup).get('DocNumHEA5').setValue(mrn);
                arrAtExitForm.get('_PRIVATE').get('mrn').setValue(mrn)

                //set LRN
                arrAtExitForm.get('_PRIVATE').get('refNumber')?.setValue(mrn);
                arrAtExitForm.get('_PRIVATE').get('lrn').setValue(mrn)



                console.log('ArrAtExit Form', arrAtExitForm.value);
            }

            if (exitCustoms) {
                arrAtExitForm.get('ACEACE').get('RefNumACE1').setValue(exitCustoms)
                arrAtExitForm.get('HEAHEA').get('CusSubPlaHEA66').setValue(exitCustoms)
                arrAtExitForm.get('HEAHEA').get('ArrNotPlaHEA60').setValue(exitCustoms)

            }

            if (trader) {
                arrAtExitForm.get('TRAEXICAR').get('CarrierObject').setValue(trader)
            }

            arrAtExitForm.get('HEAHEA').get('StoFlaHEA1').setValue('0')

        }

        //this.arrAtExitForm.next(arrAtExitForm);
    }



    submit() {
        this.openDialogsService.openSubmitDialog(this.arrAtExitForm.getValue()).subscribe();

        console.log('declaration END OF submit() on GeneralComponent component');
    }


    //--=================================================================
    //--=================================================================
    loadSavedForm(declaration: ArchiveSaveDataCommon) {

        const arrAtExitForm = this.fb.group(new ArrAtExitForm(new ArrAtExitModel(declaration.declarationForm), this.subdomain));

        this.arrAtExitForm.next(arrAtExitForm);
    }

    onRefusedEdit() {

        return this.mainBroadcaster.revertToPrevious$.pipe(
            switchMap(() => {
                return this.mainBroadcaster.declarationStates$.pipe(switchMap((allStates) => {

                    if (allStates) {
                        const states = allStates[this.subdomain];
                        const prevCode = states.previous.stateCode;
                        const currCode = states.current.stateCode


                        if ((prevCode === 'Cancellation Submitted' || prevCode === 'Submitted') && currCode === 'Rejected') {
                            return this.returnToPreviousState()
                        }
                        else {
                            return of(null)

                        }

                    }
                    else {
                        return of(null)
                    }

                }))

            }),
            takeUntil(this._destroy))
    }


    returnToPreviousState(): Observable<void> {

        return this.notificationService.returnToPrevState().pipe(tap(() => {
            this.cancelEdit()
        }))
    }

    cancelEdit() {
        console.log("CancelEdit generalCOmponent FUNCTION")

        this.mainBroadcaster.declarationForms$.pipe(map(d => d[this.subdomain]), take(1)).subscribe((declarationForm => {

            const mrn = declarationForm.get('_PRIVATE').get('mrn').value

            const data = this.storageService.getLocalObject(mrn);
            console.log("CancelEdit data", data)

            this.updateForm(data)

            this.storageService.removeLocalObject(mrn)


        }))



    }

    updateForm(data: ArrAtExitModel) {
        console.log('updateForm', data);

        const declForm = this.fb.group(new ArrAtExitForm(new ArrAtExitModel(data), this.subdomain));

        //setting localStorage here because when setTotalItems is called
        this.storageService.setLocalObject(declarationData[this.subdomain].localStorage, declForm.value);


        this.mainBroadcaster.updateDeclarationForms({ [this.subdomain]: declForm });

        this.arrAtExitForm.next(declForm);

        this.declarationStateService.transmitStateCode(declForm);

        const stateCode = declForm.get('_PRIVATE').get('stateCode').value;

        console.log("declFormService updataForm stateCode", stateCode)

        if (stateCode !== 'Pre-Submitted' && stateCode !== 'Edit') {

            this.storageService.removeLocalObject(declarationData[this.subdomain].localStorage)
        }
    }


    onDeclFormValuechanges(declarationForm: FormGroup, subdomain: string): Observable<ArrAtExitModel> {

        return declarationForm.valueChanges.pipe(startWith(declarationForm.value), debounceTime(300), distinctUntilChanged(), tap(() => {
            console.log('followSubscriptionChain onDeclFormValuechanges ', declarationForm.value, "subdomain", subdomain);

            this.mainBroadcaster.updateDeclarationForms({
                [subdomain]: declarationForm
            });


            const state = declarationForm.get('_PRIVATE').get('stateCode').value;


            if (state === 'Pre-Submitted' || state === 'Edit' || state === 'Rejected' || state === 'Refused') {
                //console.log('STATEONVALUECHANGE setLocalStorage item', declarationData[subdomain].localStorage);

                this.storageService.setLocalObject(declarationData[subdomain].localStorage, declarationForm.getRawValue());
            }


        }));
    }

    declarationState(declarationForm: FormGroup): Observable<[DeclarationStates, boolean]> {
        console.log("fn eAitisi declarationState declartionForm", declarationForm.value)

        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value
        const declarationStates$ = this.mainBroadcaster.declarationStates$
        const formDisabled$ = this.mainBroadcaster.formDisabled$.pipe(map(form => form[subdomain]))

        return combineLatest([declarationStates$, formDisabled$]);
    }

    updateFormDisabled(disabled: boolean) {
        this.mainBroadcaster.updateFormDisabled({ [this.subdomain]: disabled });
    }

    updateDeclarationState(stateCode: string) {
        this.mainBroadcaster.updateDeclarationStates({
            [this.subdomain]: { stateCode }
        });
    }

}
