import { CalcTariffResponse } from './../_models';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ImportConnectService } from 'app/services/import-connect.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TariffCalculateService } from '../tariff-calculate.service';
import { TariffDocumentsResponse } from '../_models';

@Component({
    selector: 'app-tariff-calc-documents-table',
    templateUrl: './tariff-calc-documents-table.component.html',
    styleUrls: ['./tariff-calc-documents-table.component.scss']
})
export class TariffCalcDocumentsTableComponent implements OnInit {
    @Input() documentsForm: FormGroup;
    @Input() documents: TariffDocumentsResponse[];

    @Input() goodsFormGroup: FormGroup;

    color1 = '';
    color2 = '';
    private _unsubscribe: Subject<void>;

    processing = false;

    private _destroy: Subject<void> = new Subject<void>();

    constructor(private tariffCalcService: TariffCalculateService, private importConnectService: ImportConnectService) { }

    ngOnInit(): void {
        this.tariffCalcService.documentsForm$.pipe(takeUntil(this._destroy)).subscribe(form => {
            this.documentsForm = form;


        });
        this.setDefaultVAT()
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        console.log('ARRATEXIT COMP DESTROYED');

        this._destroy.next();
        this._destroy.complete();
    }

    onSubmit() {
        this.processing = true;
        this.color2 = 'accent';

        this._unsubscribe = new Subject<void>();

        this.tariffCalcService.submitDocuments().subscribe(

            {
                next: (value: CalcTariffResponse) => {
                    console.log(value);

                    this.importConnectService.updateTariffCalculationState({
                        state: 'results',
                        data: value.responseData,
                        updateFormWithResults: true
                    });

                    this._unsubscribe.next();
                    this._unsubscribe.complete();

                    this.processing = false;
                    this.color2 = 'accent';
                },
                error: (error) => {

                    this._unsubscribe.next();
                    this._unsubscribe.complete();

                    this.processing = false;
                    this.color2 = 'accent';
                }
            });

    }
    checkRadioValue(selected) {
        console.log(selected);

        if (selected.value === 'EMPTY') {
            return;
        }

        this.documents.forEach(item => {
            //get item's options
            const options = item.options;

            //item has options with value as the one selected
            const found = options.find(option => option.value === selected.value);

            if (found) {
                //Find options in item that has
                this.documentsForm.get(item.groupName).setValue(found.id);
            }
        });
    }

    //Default value is EMPTY(Δεν παρουσιαστηκε)
    setDefaultVAT() {

        const defaultArray = [
            { desc: 'Φόρος Προστιθέμενης Αξίας', value: 'EMPTY' },
            { desc: 'Τέλος Ταξινόμισης', value: this.goodsFormGroup.get('CarCertificate')?.value },

        ]


        // this.documents.forEach((item: TariffDocumentsResponse) => {


        defaultArray.forEach(obj => {

            const foundDocumentIndex = this.documents.findIndex(doc => doc.header === obj.desc)

            if (foundDocumentIndex > -1) {

                const item = this.documents[foundDocumentIndex];

                const options = item.options;


                //item has options with value as the one selected
                const found = options.find(option => option.value === obj.value);

                if (found) {
                    //Find options in item that has
                    this.documentsForm.get(item.groupName).setValue(found.id);
                }
            }
        })
        // });
    }
}
