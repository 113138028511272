import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-tariff-calculation-data',
    templateUrl: './tariff-calculation-data.component.html',
    styleUrls: ['./tariff-calculation-data.component.scss']
})
export class TariffCalculationDataComponent implements OnInit {
    @Input() calculationData: { desc: string; value: string }[];

    ngOnInit(): void {
        console.log(this.calculationData);
    }
}
