import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StateResponseError, FunctionalErrorsType, StateResponse } from 'app/services/declaration-state.service';

export interface ErrorDialogData {
    response: StateResponse;
    lastValidState: string;
}

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

    public confirmTitle: string;
    public confirmMessage: any;
    public confirmButton = 'OK';
    public confirmClass = 'danger';

    errors: StateResponseError[] | FunctionalErrorsType[];
    errorStatus = 'Σφάλμα'
    stateCode: string;

    messageIn: string;
    response: StateResponse

    template: string;
    /**
     * Constructor
     *
     * @param dialogRef
     */
    constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData,
    ) {

        this.stateCode = data.lastValidState;
        this.messageIn = data.response.messageIn;
        this.response = data.response;


        this.errors = this.response.error || this.response.data.FunctionalErrors;


        if (this.errors?.[0]?.status) {
            this.errorStatus = this.errors[0].status
            this.template = 'Error';
            //2nd condition EMCS, 1st condition, all Other domains
        } else if (this.messageIn.includes('05A') || (this.response.messageOut.includes('13A') && this.messageIn.includes('04A'))) {
            this.errorStatus = 'Η Τροποποίηση Απορρίφθηκε'
            this.template = 'Edit'
        }
        else if (this.messageIn.includes('09A') || (this.response.messageOut.includes('10A') && this.messageIn.includes('04A'))) {
            this.errorStatus = 'Η Ακύρωση Απορρίφθηκε'
            this.template = 'Cancel'
        }
    }
}
