export class TirPreAdmRefModel {
    PreDocTypAR21?: string;
    PreDocRefLNG?: string;
    ComOfInfAR29?: string;
    ComOfInfAR29LNG?: string;
    CarManLinYeaAR500?: string;
    CarManLinCusAR501?: string;
    CarManLinDocNumAR502?: string;
    CarManLinSerNumAR503?: string;
    CarManLinLinAR504?: string;
    CMrnEdeAR505?: string;
    LinEdeAR506?: string;
    NetMasEdeAR507?: string;
    AddUniEdeAR508?: string;
    PreDocRefAR26?: string;
    CarManLinKinPacAR510?: string;
    CarManLinQuaAR511?: string;

    constructor(value?) {
        this.PreDocTypAR21 = value?.PreDocTypAR21 || null;
        this.PreDocRefLNG = value?.PreDocRefLNG || null;
        this.ComOfInfAR29 = value?.ComOfInfAR29 || null;
        this.ComOfInfAR29LNG = value?.ComOfInfAR29LNG || null;
        this.CarManLinYeaAR500 = value?.CarManLinYeaAR500 || null;
        this.CarManLinCusAR501 = value?.CarManLinCusAR501 || null;
        this.CarManLinDocNumAR502 = value?.CarManLinDocNumAR502 || null;
        this.CarManLinSerNumAR503 = value?.CarManLinSerNumAR503 || null;
        this.CarManLinLinAR504 = value?.CarManLinLinAR504 || null;
        this.CMrnEdeAR505 = value?.CMrnEdeAR505 || null;
        this.LinEdeAR506 = value?.LinEdeAR506 || null;
        this.NetMasEdeAR507 = value?.NetMasEdeAR507 || null;
        this.AddUniEdeAR508 = value?.AddUniEdeAR508 || null;
        this.PreDocRefAR26 = value?.PreDocRefAR26 || null;
        this.CarManLinKinPacAR510 = value?.CarManLinKinPacAR510 || null;
        this.CarManLinQuaAR511 = value?.CarManLinQuaAR511 || null;
    }
}
