import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BroadcasterService {
    // subject
    protected _eventsSubject = new BehaviorSubject<any>({});

    constructor() {
        console.log('SERVICE ExportBroadcaster Started');
    }

    broadcast(key: any, value: any) {
        this._eventsSubject.next({ key, value }); // here we are setting the key and value of our subject
    }

    on<T>(key: any): Observable<T> {
        return this._eventsSubject.asObservable().pipe(
            filter(e => e.key === key),
            map(e => e.value)
        );
    }

    all() {
        return this._eventsSubject.asObservable();
    }
}
