<div id="taric" fxLayout="column">
    <!-- HEADER -->
    <div class="header accent p-6 p-sm-24" fxLayout="column" fxLayoutAlign="center center">
        <!-- FIRST HEADER ROW -->
        <div fxLayout="row" fxLayoutAlign="center" style="width: 100%; margin-bottom: 15px">
            <!-- EMPTY DIV -->
            <div style="width: 10%"></div>
            <!-- EMPTY DIV -->

            <!-- APP TITLE -->
            <div fxLayout="row" style="width: 80%" fxLayoutAlign="center center">
                <!--            
    -->
                <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                    <!--  <mat-icon
                    class="logo-icon mr-16"
                    [@animate]="{
                        value: '*',
                        params: { delay: '50ms', scale: '0.2' }
                    }"
                    >archive
                </mat-icon>
                -->
                    <span class="logo-text h1"> Δασμολόγιο TARIC </span>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- CLOSE BUTTON -->
            <div fxLayout="row" style="width: 10%" fxLayoutAlign="end center">
                <button mat-icon-button (click)="dialogRef.close()">
                    <mat-icon class="dialog-close-button">highlight_off</mat-icon>
                </button>
            </div>
            <!-- CLOSE BUTTON -->
        </div>
        <!-- /FIRST HEADER ROW -->
        <!-- SECOND HEADER ROW -->
        <div style="width: 94%" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- SEARCH ORIGIN COUNTRY -->
            <div fxLayout="row" fxLayoutAlign="center center" style="width: 27%">
                <div class="search-wrapper">
                    <div class="search" fxLayout="row" fxLayoutAlign="start center">
                        <span style="width:15%;color:#333">Από:</span>
                        <ejs-combobox #FirstFocus class="s-36" id="exportCountry" [dataSource]="expCountries"
                            [fields]="fields" [placeholder]="placeholder1" [formControl]="expCountryQuery"
                            (blur)="checkValidCountry(expCountryQuery)">
                        </ejs-combobox>
                    </div>
                </div>
            </div>
            <!--/ SEARCH ORIGIN COUNTRY -->

            <!-- SEARCH -->
            <div class="search-wrapper" style="width: 33%;">
                <div class="search main-search">
                    <mat-icon>search</mat-icon>
                    <input [formControl]="searchQuery" placeholder="Αναζήτηση..." (keyup.Enter)="$event.target.blur()"
                        (focus)="$event.target.value = ''" />


                </div>
                <div class="taric-loading">
                    <mat-progress-bar *ngIf="_taricService.loading$ | async" color="accent" mode="indeterminate">
                    </mat-progress-bar>
                </div>
            </div>
            <!-- / SEARCH -->
            <!-- SEARCH DESTINATION COUNTRY -->
            <div fxLayout="row" fxLayoutAlign="center center" style="width: 27%">
                <div class="search-wrapper">
                    <div class="search" fxLayout="row" fxLayoutAlign="start center">
                        <span style="width:15%;color:#333">Προς:</span>
                        <ejs-combobox class="s-36" id="destinationCountry" [dataSource]="destCountries"
                            [fields]="fields" [placeholder]="placeholder2" [formControl]="destCountryQuery"
                            (blur)="checkValidCountry(destCountryQuery)">
                        </ejs-combobox>
                    </div>
                </div>
            </div>
            <!--/ SEARCH DESTINATION COUNTRY -->
        </div>
        <!-- /SECOND HEADER ROW -->
    </div>
    <!-- / HEADER -->

    <!--  FILTERS  -->
    <!--   <div></div> -->
    <!--  /FILTERS  -->
    <!--    <div fxLayout="row">
        <mat-card style="width: 100%; margin: 0 16px" fxLayout="column">
            <taric-tree
                [treeData]="treeData"
                [requested]="productRequested"
            ></taric-tree>
        </mat-card>
    </div> -->

    <mat-card fxLayout="row" fxLayoutAlign="center start" class="taric-results m-6">
        <div *ngIf="selectedIndex===0" class="taricAll" (click)="getAllTaric()">
            <mat-icon>book</mat-icon>
            <a href="#" (click)="$event.preventDefault()">Όλο το δασμολόγιο</a>
        </div>
        <div class="taricSearchResults" style="position: relative">
            <mat-tab-group #tabGroup class="main-tab-group mt-2 mx-6" (selectedTabChange)="tabChanged($event)"
                [(selectedIndex)]="selectedIndex">
                <mat-tab HideTab #ref="HideTab">
                    <div class="tab-content" #tabContent1 cdkScrollable fusePerfectScrollbar>
                        <ng-template mat-tab-label class="labelGrid">
                            <div *ngIf="!showTabLabel" class="label-title">
                                Δασμολόγιο
                            </div>

                            <div *ngIf="showTabLabel" [matBadge]="page?.totalElements" matBadgeOverlap="false"
                                class="label-title">
                                Δασμολόγιο
                            </div>
                        </ng-template>

                        <div style="width: 100%">
                            <taric-tree [filters]="filters" [treeData]="treeData" [requested]="productRequested"
                                [formDisabled$]="formDisabled$">
                            </taric-tree>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab *ngIf="!ref.hide">
                    <div class="tab-content" #tabContent2 cdkScrollable fusePerfectScrollbar>
                        <ng-template mat-tab-label class="labelGrid" *ngIf="showTabLabel">
                            <div [matBadge]="page?.totalElements" matBadgeOverlap="false" class="label-title">
                                Αποτελέσματα Αναζήτησης
                            </div>
                        </ng-template>

                        <div style="width: 100%">
                            <taric-results-table [filters]="filters" [formDisabled$]="formDisabled$">
                            </taric-results-table>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-card>
</div>