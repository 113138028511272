export class ConsignorContactDetailsModel {

    PersonIdentification: string;
    LastNames: string;
    Forenames: string;
    NameOfFather: string;
    Address: string;
    Phone?: string;
    Country: string;

    constructor(value?) {

        this.PersonIdentification = value?.PersonIdentification || null;
        this.LastNames = value?.LastNames || null;
        this.Forenames = value?.Forenames || null;
        this.NameOfFather = value?.NameOfFather || null;
        this.Address = value?.Address || null;
        this.Phone = value?.Phone;
        this.Country = value?.Country || null;
    }

}
