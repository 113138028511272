export class NctsSpeMenModel {
    AddInfMT21?: string; //70
    AddInfMT21LNG?: string; //2
    AddInfCodMT23?: string; //5

    ExpFroECMT24?: string; // TIR ONLY
    ExpFroCouMT25?: string; // TIR ONLY

    constructor(value?) {
        this.AddInfMT21 = value?.AddInfMT21 || null;
        this.AddInfMT21LNG = value?.AddInfMT21LNG || null;
        this.AddInfCodMT23 = value?.AddInfCodMT23 || null;
        this.ExpFroECMT24 = value?.ExpFroECMT24;
        this.ExpFroCouMT25 = value?.ExpFroCouMT25;
    }
}
