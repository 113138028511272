import { take, tap, switchMap, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Contact } from 'app/main/apps/contacts/contact.model';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'app/services/api.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { _pelatesItem } from 'app/model/api-model';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { ContactsContactFormDialogComponent } from 'app/main/apps/contacts/contact-form/contact-form.component';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { ConfirmDialogConfig, OpenDialogsService } from 'app/services/openDialogs.service';

export interface GetContactArgs {
    sortOrder?: string;
    sortDirection?: string;
    pageNumber?: number;
    pageSize?: number;
    searchTerm?: string;
    contactType?: string;
}
export interface ContactResponse {
    total: number;
    contacts: Contact[];
}

export type Customers = _pelatesItem[];

@Injectable({ providedIn: 'root' })
export class ContactsService implements Resolve<any> {
    PHP_API_SERVER = environment.API_URL;

    onContactsChanged: BehaviorSubject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onInternationalChanged: BehaviorSubject<any>;
    onCarrierChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    onTotalContactsChanged: BehaviorSubject<any>;
    onContactArgsChanged: BehaviorSubject<GetContactArgs>;

    contacts: Contact[];
    international: Contact[];
    carrier: Contact[];
    user: any;
    selectedContacts: string[] = [];

    searchText: string;
    filterBy = 'domestic';

    totalContacts: number;

    initialContactArguments = {
        sortOrder: 'lastname',
        sortDirection: 'asc',
        pageNumber: 0,
        pageSize: 10,
        searchTerm: ''
    }

    public loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    declarationSettings: DeclarationSettingsModel;

    /**
     * Constructor
     *
     * @param _httpClient
     */
    constructor(
        public dialogRef: MatDialogRef<ContactsContactFormDialogComponent>,
        private _matDialog: MatDialog,
        private _httpClient: HttpClient,
        private toastrService: ToastrService,
        private dbQuery: ApiService,
        private mainBroadcaster: MainBroadcasterService,
        private openDialogsService: OpenDialogsService
    ) {
        console.log('CONTAX service started');
        // Set the defaults
        this.onContactsChanged = new BehaviorSubject([]);
        this.onSelectedContactsChanged = new BehaviorSubject([]);
        this.onInternationalChanged = new BehaviorSubject([]);
        this.onCarrierChanged = new BehaviorSubject([]);
        this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject<void>();
        this.onFilterChanged = new Subject<void>();
        this.onTotalContactsChanged = new BehaviorSubject(0);
        this.onContactArgsChanged = new BehaviorSubject(this.initialContactArguments);

    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        console.log('CONTAX service destroyed');
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     * @returns
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        return this.getContacts({ contactType: 'domestic' })
    }
    //===================================================================
    //===================================================================
    /* Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(contactArgs?: GetContactArgs): Observable<Contact[]> {
        console.trace('getContacts');
        const postData = {
            sortOrder: contactArgs?.sortOrder || 'lastname',
            sortDirection: contactArgs?.sortDirection || 'asc',
            pageNumber: contactArgs?.pageNumber || 0,
            pageSize: contactArgs?.pageSize || 10,
            searchTerm: contactArgs?.searchTerm || '',
            type: contactArgs?.contactType
        };

        const contact = contactArgs?.contactType;

        return this.mainBroadcaster.declarationSettings$.pipe(switchMap(settings => {
            this.declarationSettings = settings

            return this._httpClient
                .post<ContactResponse>(`${this.PHP_API_SERVER}/contacts/get.php`, postData)
                .pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    switchMap((response: ContactResponse) => {
                        this.onTotalContactsChanged.next(response.total);

                        if (contact === 'domestic') {
                            this.contacts = [];
                        } else if (contact === 'international') {
                            this.international = [];
                        } else if (contact === 'carrier') {
                            this.carrier = [];
                        }

                        if (response.total > 0) {
                            if (contact === 'domestic') {
                                this.contacts = response.contacts;
                                console.log('Domestic', this.contacts);

                                this.contacts = this.contacts.map(contact => new Contact(this.declarationSettings, contact));
                            } else if (contact === 'international') {
                                this.international = response.contacts;
                                console.log('International', this.international);

                                this.international = this.international.map(item => new Contact(this.declarationSettings, item));
                            } else if (contact === 'carrier') {
                                this.carrier = response.contacts;
                                console.log('Carrier', this.carrier);

                                this.carrier = this.carrier.map(item => new Contact(this.declarationSettings, item));
                            }
                        }

                        if (contact === 'domestic') {
                            this.onContactsChanged.next(this.contacts);
                            return of(this.contacts);
                        } else if (contact === 'international') {
                            this.onInternationalChanged.next(this.international);
                            return of(this.international);
                        } else if (contact === 'carrier') {
                            this.onCarrierChanged.next(this.carrier);
                            return of(this.carrier);
                        }
                    })
                )
        }))

    }


    //===================================================================
    //===================================================================

    /**
     * Update contact
     *
     * @param contact
     * @returns
     */
    updateContact(contact, contactType, getPage = true, internalId = false): Observable<ContactResponse | Contact[]> {
        contact.type = contactType;
        interface PostData {
            [key: string]: any;
        }

        const postData: PostData = { contact }

        if (getPage) {
            postData.pageArgs = this.onContactArgsChanged.value
        }
        if (internalId) {
            postData.internal = true
        }

        return this._httpClient
            .post(`${this.PHP_API_SERVER}/contacts/update.php`, postData)
            .pipe(tap((response: ContactResponse | Contact[]) => {
                //contacts edit
                if ('contacts' in response) {
                    this.actionOnSuccess(contactType, response.contacts, 'update');
                }
                //invoice edit
                else {
                    this.actionOnSuccess(contactType, response, 'update');

                }
            }));
    }
    /**
     * Create contact
     *
     * @param contact
     * @returns
     */
    createContact(contact, contactType): Observable<any> {

        contact.type = contactType;

        return this._httpClient.post(`${this.PHP_API_SERVER}/contacts/create.php`, contact).pipe(
            tap((response: any) => {


                if (response.error) {
                    this.actionOnError(response)
                    return;
                }

                let allContacts = response;

                //carriers do not return created contact and allConctacts
                if (response.allContacts) {
                    allContacts = response.allContacts;
                }
                this.actionOnSuccess(contactType, allContacts, 'create');
            })
        );
    }

    /**
     * Delete contact
     *
     * @param contact
     */
    deleteContact(contact: _pelatesItem, contactType: string): Observable<any> {
        contact.type = contactType;
        console.log('Contact:', contact);

        return this._httpClient
            .post(`${this.PHP_API_SERVER}/contacts/delete.php`, contact)
            .pipe(tap((response: any) => {
                this.actionOnSuccess(contactType, response, 'delete');

            }));

    }

    actionOnSuccess(contactType, response, initialAction) {
        let message = '';

        if (contactType === 'domestic') {
            this.dbQuery.invalidatePelates();
            this.dbQuery.invalidateDeclarants();

            this.mainBroadcaster.updateDomesticCustomers(response);

            this.dbQuery.get_declarants().pipe(take(1)).subscribe(data => {
                this.mainBroadcaster.updateDeclarants(data)
            })

            if (initialAction === 'create') {
                message = 'Επιτυχής Προσθήκη Νέου Πελάτη.';
            } else if (initialAction === 'update') {
                message = 'Επιτυχής Ενημέρωση Πελάτη.';
            } else if (initialAction === 'delete') {
                message = 'Επιτυχής Διαγραφη Πελάτη.';
            }
        } else if (contactType === 'international') {
            this.dbQuery.invalidateInterCustomers();
            this.mainBroadcaster.updateInternationalCustomers(response);
            if (initialAction === 'create') {
                message = 'Επιτυχής Προσθήκη Νέου Πελάτη Εξωτερικού.';
            } else if (initialAction === 'update') {
                message = 'Επιτυχής Ενημέρωση Πελάτη Εξωτερικού.';
            } else if (initialAction === 'delete') {
                message = 'Επιτυχής Διαγραφή Πελάτη Εξωτερικού.';
            }
        } else if (contactType === 'carrier') {
            this.dbQuery.invalidateCarriers();
            this.mainBroadcaster.updateCarriers(response);
            if (initialAction === 'create') {
                message = 'Επιτυχής Προσθήκη Νέου Μεταφορέα.';
            } else if (initialAction === 'update') {
                message = 'Επιτυχής Ενημέρωση Μεταφορέα.';
            } else if (initialAction === 'delete') {
                message = 'Επιτυχής Διαγραφή Μεταφορέα.';
            }
        }

        if (initialAction === 'create') {
            this.getContacts({ contactType }).subscribe()

        } else {
            console.log("onContactArgsChanged", this.onContactArgsChanged.value)

            //invoices edit customer does not update contactType
            if (!this.onContactArgsChanged.value.contactType) {
                this.onContactArgsChanged.value.contactType = 'domestic';
            }
            this.getContacts(this.onContactArgsChanged.value).subscribe()

        }


        this.toastrService.success(message);
    }
    actionOnError(response) {

        this.toastrService.error(response.error, 'Προσοχή!')
    }
    //===================================================================
    //===================================================================

    /**
     * Toggle selected contact by id
     *
     * @param id
     */
    toggleSelectedContact(id): void {
        // First, check if we already have that contact as selected...
        if (this.selectedContacts.length > 0) {
            const index = this.selectedContacts.indexOf(id);

            if (index !== -1) {
                this.selectedContacts.splice(index, 1);

                // Trigger the next event
                this.onSelectedContactsChanged.next(this.selectedContacts);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedContacts.push(id);

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedContacts.length > 0) {
            this.deselectContacts();
        } else {
            this.selectContacts();
        }
    }

    /**
     * Select contacts
     *
     * @param filterParameter
     * @param filterValue
     */
    selectContacts(filterParameter?, filterValue?): void {
        this.selectedContacts = [];

        // If there is no filter, select all contacts
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedContacts = [];
            this.contacts.map(contact => {
                this.selectedContacts.push(contact.id);
            });
        }

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
 * Deselect contacts
 */
    deselectContacts(): void {
        this.selectedContacts = [];

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Delete selected contacts
     */
    deleteSelectedContacts(): void {
        for (const contactId of this.selectedContacts) {
            const contact = this.contacts.find(_contact => _contact.id === contactId);
            const contactIndex = this.contacts.indexOf(contact);
            this.contacts.splice(contactIndex, 1);
        }
        this.onContactsChanged.next(this.contacts);
        this.deselectContacts();
    }


    /**
 * Edit contact
 *
 * @param contact
 */
    editContact(contact, contactType, source): Observable<any> {
        const dialogConfig = new MatDialogConfig();

        console.log("contacts filterBy", this.filterBy)

        // dialogConfig.autoFocus = true;
        dialogConfig.width = '60%';
        dialogConfig.height = '85%';
        dialogConfig.panelClass = 'mat-dialog-override';
        dialogConfig.data = {
            contact: contact,
            action: 'edit',
            class: contactType
        };

        this.dialogRef = this._matDialog.open(ContactsContactFormDialogComponent, dialogConfig);

        return this.dialogRef.afterClosed()
            .pipe(
                switchMap(
                    response => {
                        if (!response) {
                            return of(null);
                        }
                        const actionType: string = response[0];
                        const customerType: string = response[1];
                        const formData: FormGroup = response[2];
                        const getPage = source === 'invoice' ? false : true
                        switch (actionType) {
                            /**
                            * Save
                            */
                            case 'save':
                                return this.updateContact(formData.getRawValue(), customerType, getPage).pipe(take(1));

                            /**
                            * Delete
                            */
                            case 'delete':
                                return this.deleteContact(contact, customerType);

                        }
                    }));
    }


    updateContactConfirmDialog(contact, contactType) {

        const config: ConfirmDialogConfig = {
            message: 'Θέλετε να αποθηκεύσετε τις αλλαγές;',
            title: 'Οι πληροφορίες έχουν αλλάξει',
            confirmButton: 'Ναι',
            cancelButton: 'Οχι',
            confirmClass: 'accent',
            width: '35%',
            height: '45%'
        }
        this.openDialogsService.openConfirmDialog(config).pipe(switchMap(result => {

            if (result) {
                return this.updateContact(contact, contactType);

            }
            return of(null)

        }))


    }
}
