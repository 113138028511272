import { FormControl } from '@angular/forms';
import { TaxAddEle100Model } from './taxaddele100.model';

export class TaxAddEle100Form {
    [key: string]: FormControl;
    SupUniCodTAXADDELE101 = new FormControl();
    AmoOfSupUniTAXADDELE100 = new FormControl(null, { updateOn: 'blur' });

    constructor(value: TaxAddEle100Model) {
        this.SupUniCodTAXADDELE101.setValue(value.SupUniCodTAXADDELE101);
        this.AmoOfSupUniTAXADDELE100.setValue(value.AmoOfSupUniTAXADDELE100);

        // this.RefNumEXT1.setValidators([Validators.required]);
    }
}
