import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

import { CustomsService } from 'app/main/apps/customs/customs.service';
import { countriesItem, _pelatesItem } from 'app/model/api-model';
import { FormControl, FormGroup } from '@angular/forms';
import { L10n } from '@syncfusion/ej2-base';

import { PaginatedDataSource } from 'app/services/datasource/paginated-datasource';
import { CustomsFiltersModel } from './_models/customs-filters.model';
import { Custom } from '../customs.model';
import { CustomQuery } from '../customs-list/customs-list.component';

declare let require: any;

@Component({
    selector: 'customs-filters',
    templateUrl: './customs-filters.component.html',
    styleUrls: ['./customs-filters.component.scss']
})
export class CustomsFiltersComponent implements OnInit {
    @Input() countries: countriesItem[];

    allCustomsDataSource: PaginatedDataSource<Custom, CustomQuery>;

    country = '';

    searchQuery: FormControl;
    countryQuery: FormControl;

    disableStatus = false;

    filters: FormGroup;
    // maps the appropriate column to fields property
    public fields: Object = {
        text: 'CountryName',
        value: 'CountryCode'
    };

    // Private
    private _unsubscribeAll: Subject<void>;

    public placeholder1 = 'Όλες οι χώρες';

    constructor(private _customsService: CustomsService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();

        // Set the defaults
        //  this.searchQuery = new FormControl("");
        //  this.ψοθντρυQuery = new FormControl("");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._customsService.dataSource$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            console.log('activatedRoute on Filters: ', value);
            this.allCustomsDataSource = value;
        });

        this._customsService.customFilters$.pipe(takeUntil(this._unsubscribeAll)).subscribe(filters => {
            console.log(filters);

            this.filters = filters;
            if (this.country) {
                this.filters.get('countryQuery').setValue(this.country.toUpperCase());
            }
        });

        this.filters.valueChanges.pipe(distinctUntilChanged(), takeUntil(this._unsubscribeAll)).subscribe((value: CustomsFiltersModel) => {
            console.log('Filters: ', value);

            this.allCustomsDataSource.queryBy({
                country: value.countryQuery
            });
        });

        L10n.load({
            el: {
                dropdowns: {
                    noRecordsTemplate: 'Δε βρέθηκαν εγγραφές',
                    actionFailureTemplate: 'Η αναζήτηση απέτυχε'
                }
            }
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resetFilters() {
        this.filters.setValue({
            searchQuery: '',
            countryQuery: null
        });

        // this._customsService.onFiltersChanged.next(this.filters);
    }
}
