import { ExchangeRateValidService } from 'app/services/exchangeRateValid.service';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { ElementRef, QueryList, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrencyItem, genericItem } from 'app/model/api-model';
import { Observable, pairwise, startWith, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { exchangeRateValidator } from 'app/services/validators/custom-validators';

@Injectable()
export class AccountingService {

    _third: QueryList<ElementRef>

    statValueControl = 'StaValAmoGDI1';

    accCurrency = 'AccCurrency';
    accValue = 'AccValue';
    accExchangeRate = 'AccExchangeRate';
    accResult = 'AccResult';
    changedManually = 'changedManually'

    defaultCurrency: string;
    currentCurrency: string;
    currentExchangeRate: number;


    allCurrencies: genericItem[] = [];
    rates: { rates: CurrencyItem[], valid_from: string, valid_until: string };
    totalValue: number[] = []


    private _destroy: Subject<void> = new Subject<void>();


    constructor(private dbQuery: ApiService, private cf: ExtCustomFunctions, private mainBroadcaster: MainBroadcasterService, private exchangeRateValidService: ExchangeRateValidService) {
        console.log("ACCOUNTINGSERVICE CREATED")

        this.mainBroadcaster.allExchangeRates$.pipe(takeUntil(this._destroy)).subscribe((rates: { rates: CurrencyItem[], valid_from: string, valid_until: string }) => {
            this.rates = rates;
        })

        this.mainBroadcaster.currencies$.pipe(takeUntil(this._destroy)).subscribe((currencies: genericItem[]) => {
            this.allCurrencies = currencies;
        })
        this.mainBroadcaster.declarationSettings$.pipe(takeUntil(this._destroy)).subscribe((settings: DeclarationSettingsModel) => {
            this.defaultCurrency = settings.statisticValueCurrency;
        })
    }

    ngOnDestroy(): void {
        console.log("ACCOUNTINGSERVICE DESTROYED")
        this._destroy.next();
        this._destroy.complete();
    }

    doMath(GoodsFormGroup: FormGroup, GoodsIndex: number, rowIndex: number, totalArr: number[]) {

        console.log("Accounting doMath==============================================================")
        console.log("Accounting doMath totalArr", totalArr)


        const ACCOUNTING = GoodsFormGroup.get('ACCOUNTING') as FormArray

        const _accCurrency = ACCOUNTING.at(rowIndex).get(this.accCurrency) as FormControl;
        const _accValue = ACCOUNTING.at(rowIndex).get(this.accValue) as FormControl;
        const _accExchangeRate = ACCOUNTING.at(rowIndex).get(this.accExchangeRate) as FormControl;
        const _accResult = ACCOUNTING.at(rowIndex).get(this.accResult) as FormControl;
        const _changedManually = ACCOUNTING.at(rowIndex).get(this.changedManually) as FormControl;

        //=====================================================================
        //=========================VALUE CHANGES===============================

        let currencyItem: CurrencyItem;

        _accCurrency.valueChanges.pipe(
            startWith(_accCurrency.value),
            pairwise(),
            tap(
                ([prevValue, currValue]) => {
                    currencyItem = this.onAccCurrencyValueChanges(GoodsFormGroup, GoodsIndex, rowIndex, prevValue, currValue, _accCurrency, _accValue, _accExchangeRate, _accResult, _changedManually)
                }
            )).subscribe()


        //if the Value Changes
        _accValue.valueChanges.pipe(
            startWith(_accValue.value),
            takeUntil(this._destroy)
        )
            .subscribe(value => {
                this.onAccValueValueChanges(GoodsFormGroup, GoodsIndex, rowIndex, value, _accValue, _accExchangeRate, _accResult, _changedManually)
            });

        //if the exchange rate change (is set manually )
        _accExchangeRate.valueChanges.pipe(
            takeUntil(this._destroy)).subscribe(value => {

                this.onAccExchangeRateValueChanges(GoodsFormGroup, GoodsIndex, rowIndex, value, _accValue, _accExchangeRate, _accResult, _changedManually, currencyItem)
            });
    }

    onAccCurrencyValueChanges(GoodsFormGroup, GoodsIndex, rowIndex, prevValue, currValue, _accCurrency, _accValue, _accExchangeRate, _accResult, _changedManually) {

        const totalArr = this.getTotalArr(GoodsFormGroup.get('ACCOUNTING') as FormArray)

        console.log('Accounting Valuechenged:', prevValue, currValue);

        if (currValue === null || currValue === '') {

            console.log('Accounting: CURR BVALUE NULL');
            _accExchangeRate.setValue(null);
            _accExchangeRate.clearValidators();
            _accExchangeRate.updateValueAndValidity();
            _accExchangeRate.markAsTouched();

        }

        console.log('Accounting currentCurrency:', this.currentCurrency);
        console.log('Accounting currentExchangeRate:', this.currentExchangeRate);

        console.log("////////////////////////////////////////////////////////////////////////////////////////Accounting rates on doMath", this.rates)

        const currencyItem: CurrencyItem = this.rates.rates.find(item => item.currency === currValue?.toUpperCase())

        console.log("Accounting currencyItem", currencyItem, "exists in list", this.allCurrencies.map(curr => curr.code).includes(currValue?.toUpperCase()))
        console.log("Accounting currValue", currValue?.toUpperCase())
        console.log("Accounting this.allCurrencies.", this.allCurrencies)


        //If the selected currency is the current Used currency(invoice currency)
        if (currValue?.toUpperCase() === this.currentCurrency) {
            console.log('Accounting currency is the same as the default currency:', currValue);
            const curRate = this.currentExchangeRate;

            this.setExchangeRateOnControl3(curRate, _accExchangeRate, _accResult, _changedManually, rowIndex, totalArr, GoodsFormGroup, GoodsIndex)
            _accExchangeRate.setValidators(exchangeRateValidator(currencyItem, _changedManually.value,))
            _accExchangeRate.updateValueAndValidity()
            _accExchangeRate.markAsTouched();

        }

        //if the selected currency is not the current currency
        else if (this.allCurrencies.map(curr => curr.code).includes(currValue?.toUpperCase())) {
            console.log('Accounting currency exists in list:', currValue);
            //get the exchange rate from the dq
            console.log('1 Accounting currency Item:', currencyItem);
            const curRate = currencyItem?.rate;
            console.log('1 Accounting currency Rate:', curRate);



            //If the selected currency is valid set exchange Rate and set manually changed to false
            if (this.exchangeRateValidService.isExchangeRateValid(currencyItem.valid_from, currencyItem.valid_until)) {
                this.setExchangeRateOnControl3(curRate, _accExchangeRate, _accResult, _changedManually, rowIndex, totalArr, GoodsFormGroup, GoodsIndex)
            }
            else {
                //if the selected currency is not valid and the exchange rate is NOT manually changed, set the exhange rate and inform user it is not valid.
                if (!_changedManually.value) {

                    this.setExchangeRateOnControl3(curRate, _accExchangeRate, _accResult, _changedManually, rowIndex, totalArr, GoodsFormGroup, GoodsIndex)

                }
                //If the selected currency is not valid and the exchange rate is manually changed, 
                else {
                    //If currency is different(We have changed currencies)
                    console.log("2. Accounting currency", currencyItem)
                    console.log("2. Accounting currency", currencyItem?.currency)
                    console.log("2. Accounting current value", currValue)
                    if (prevValue !== currValue) {

                        this.setExchangeRateOnControl3(curRate, _accExchangeRate, _accResult, _changedManually, rowIndex, totalArr, GoodsFormGroup, GoodsIndex)

                    }
                    else {

                        console.log("2. Accounting is Rate Valid", this.exchangeRateValidService.isExchangeRateValid(currencyItem.valid_from, currencyItem.valid_until))

                    }
                }
            }


            const rateValid = this.exchangeRateValidService.isExchangeRateValid(currencyItem.valid_from, currencyItem.valid_until)

            console.log("1. Accounting is Rate Valid", rateValid)
            _accExchangeRate.setValidators(exchangeRateValidator(currencyItem, _changedManually.value,))
            _accExchangeRate.updateValueAndValidity()
            _accExchangeRate.markAsTouched();

        }

        return currencyItem;
    }

    onAccValueValueChanges(GoodsFormGroup, GoodsIndex, rowIndex, value, _accValue, _accExchangeRate, _accResult, _changedManually) {
        console.log('onTemplate accounting dialog chage value: ', value);

        const totalArr = this.getTotalArr(GoodsFormGroup.get('ACCOUNTING') as FormArray)


        _accValue.setValue(this.cf.formatDecimalString(value, 2), { onlySelf: true, emitEvent: false })
        const exRateVal = _accExchangeRate.value;

        //do the calculation only if exchange rate has a valid value
        if (exRateVal !== '' && exRateVal !== null && exRateVal !== undefined && exRateVal !== 0) {
            const result = (value / _accExchangeRate.value);

            console.log("DO MATH setting _accResult on valueChange of _accValue", result)

            _accResult.setValue(this.cf.formatDecimalString(result, 2))

            //replace at index i, 1 item, with value
            totalArr.splice(rowIndex, 1, result);

            this.setTotalValue(GoodsFormGroup, GoodsIndex, totalArr);

        } else {
            return;
        }

    }

    onAccExchangeRateValueChanges(GoodsFormGroup, GoodsIndex, rowIndex, value, _accValue, _accExchangeRate, _accResult, _changedManually, currencyItem) {

        const totalArr = this.getTotalArr(GoodsFormGroup.get('ACCOUNTING') as FormArray)


        console.trace("_accExchangeRate valuechanges", value)

        // if value is valid and exchange rate is valid
        if (value !== '0' && value !== '' && _accValue.value !== '' && _accValue.value !== null && _accValue.value !== undefined) {
            const result = +(_accValue.value / value).toFixed(2);

            _accResult.setValue(result);

            //replace at index i, 1 item, with value
            totalArr.splice(rowIndex, 1, result);
            console.log("Accounting doMath totalArr after splice on index " + rowIndex + " of accExchangeRate", totalArr)


            this.setTotalValue(GoodsFormGroup, GoodsIndex, totalArr);

            if (_accExchangeRate.dirty) {
                _changedManually.setValue(_accExchangeRate.dirty);
                _accExchangeRate.setValidators(exchangeRateValidator(currencyItem, _changedManually.value))
                _accExchangeRate.updateValueAndValidity({ emitEvent: false, onlySelf: true });
                _accExchangeRate.markAsTouched();
            }
        } else {
            return;
        }
    }
    setExchangeRateOnControl3(curRate: number, _accExchangeRate: FormControl, _accResult: FormControl, _changedManually: FormControl, rowIndex: number, totalArr: number[], GoodsFormGroup: FormGroup, GoodsIndex: number) {

        //iff a value exists for that currency disable the exchangdeclFormServicee rate field
        //and set its value
        if (curRate && curRate !== null) {
            console.log('2 Accounting currency Rate:', curRate);

            if (this._third) {
                // this._third.last.nativeElement.readOnly = true;
                // this._third.last.nativeElement.tabIndex = -1;
            }
            console.log('3 Accounting currency Rate:', curRate);

            console.log('Accounting: CURR BVALUE VALUE', curRate);
            _accExchangeRate.setValue(this.cf.formatDecimalString(curRate, 3, false));
            console.log('4 Accounting currency _accExchangeRate value:', _accExchangeRate.value);


        }
        //if there is no value for exchange rate
        //remove readonly and set tabIndex to input value manually
        //clear the result if is already set and calculate the total
        else {
            console.log('5 Accounting currency _accExchangeRate value:', curRate);

            console.log('5 Accounting currency _accExchangeRate value:', _accExchangeRate.value);

            console.log('Accounting: CURR BVALUE empty string');

            _accExchangeRate.setValue('');
            _accResult.setValue('');
            totalArr.splice(rowIndex, 1, 0);
            console.log("Accounting doMath setExchangeRateon3 totalArr after splice on index " + rowIndex + " of accExchangeRate", totalArr)

            this.setTotalValue(GoodsFormGroup, GoodsIndex, totalArr);

            if (this._third) {

                this._third.last.nativeElement.readOnly = false;
                this._third.last.nativeElement.tabIndex = 0;
            }
        }
        //Reset changedManually Control
        _changedManually.setValue(false)
    }

    setTotalValue(GoodsFormGroup: FormGroup, GoodsIndex: number, resultArray: number[]): number {

        const statisticCurrency = GoodsFormGroup.get('StaValCurGDI1') as FormControl;
        const statisticValue = GoodsFormGroup.get(this.statValueControl) as FormControl;

        const totalValue = +resultArray.reduce((acc, cur) => +acc + +cur, 0).toFixed(2);

        if (this.totalValue[GoodsIndex]) {

            this.totalValue.splice(GoodsIndex, 1, totalValue);
        }
        else {

            this.totalValue[GoodsIndex] = totalValue
        }

        if (totalValue > 0) {
            statisticValue.setValue(totalValue);
            statisticCurrency?.setValue(this.defaultCurrency);
        } else {
            statisticValue.setValue(null);
            statisticCurrency?.setValue(null);
        }

        return totalValue;
    }
    getTotalArr(ACCOUNTING: FormArray) {

        const totalArr = [];

        ACCOUNTING.value.forEach(e => {
            if (!e.AccResult || e.AccResult === '') {
                totalArr.push(0)
            } else {
                totalArr.push(+e.AccResult)
            }
        })
        console.log("setTotalValue getTotalArr ", totalArr)
        return totalArr;
    }

    calculateNewValues(ACCOUNTING: FormArray, GoodsFormGroup: FormGroup, GoodsIndex: number) {
        for (let i = 0; i < ACCOUNTING.length; i++) {
            //this.doMath(GoodsFormGroup, GoodsIndex, i, totalArr);
            this.calculateRow(ACCOUNTING, GoodsFormGroup, GoodsIndex, i)
        }
    }

    calculateRow(ACCOUNTING, GoodsFormGroup, GoodsIndex, rowIndex) {

        const _accCurrency = ACCOUNTING.at(rowIndex).get(this.accCurrency) as FormControl;
        const _accValue = ACCOUNTING.at(rowIndex).get(this.accValue) as FormControl;
        const _accExchangeRate = ACCOUNTING.at(rowIndex).get(this.accExchangeRate) as FormControl;
        const _accResult = ACCOUNTING.at(rowIndex).get(this.accResult) as FormControl;
        const _changedManually = ACCOUNTING.at(rowIndex).get(this.changedManually) as FormControl;
        const totalArr = this.getTotalArr(ACCOUNTING)

        let currencyItem: CurrencyItem
        _accCurrency.valueChanges.pipe(startWith(_accCurrency.value), pairwise()).subscribe(

            ([prevValue, currValue]) => {
                currencyItem = this.onAccCurrencyValueChanges(GoodsFormGroup, GoodsIndex, rowIndex, prevValue, currValue, _accCurrency, _accValue, _accExchangeRate, _accResult, _changedManually)

                this.onAccExchangeRateValueChanges(GoodsFormGroup, GoodsIndex, rowIndex, _accExchangeRate.value, _accValue, _accExchangeRate, _accResult, _changedManually, currencyItem)
            }
        )
    }



    calculateFromInvoice(GoodsFormGroup: FormGroup, GoodsIndex: number, value: number | string, currency: string) {

        console.log("CALCULATING FROM INVOICE",)

        const ACCOUNTING = GoodsFormGroup.get('ACCOUNTING') as FormArray

        const totalArr = this.getTotalArr(ACCOUNTING)

        const firstAccRow = ACCOUNTING.at(0) as FormGroup

        firstAccRow.get(this.accExchangeRate).setValue(currency)
        firstAccRow.get(this.accValue).setValue(value)


        this.mainBroadcaster.currencies$.pipe(take(1)).subscribe(currencies => {

            if (currencies.map(curr => curr.code).includes(currency.toUpperCase())) {
                console.log('Accounting currency exists in list:', currency);
                //get the exchange rate from the dq
                const curRate = this.rates.rates.find(item => item.currency === currency.toUpperCase())?.rate;
                console.log('Accounting currency Rate:', curRate);
                //iff a value exists for that currency disable the exchangdeclFormServicee rate field
                //and set its value
                if (curRate && curRate !== null) {


                    firstAccRow.get(this.accExchangeRate).setValue(this.cf.formatDecimalString(curRate, 3, false));


                    const result = +value / +curRate

                    firstAccRow.get(this.accResult).setValue(this.cf.formatDecimalString(result, 2))

                    totalArr.splice(0, 1, result);

                    this.setTotalValue(GoodsFormGroup, GoodsIndex, totalArr);

                }
            }

        })



    }


}
