import { FormControl } from '@angular/forms';
import { eAitisiTaxCalcModel } from './taxcalc.model';

export class eAitisiTaxCalcForm {
    [key: string]: FormControl;
    TypTax4019 = new FormControl();
    AmoTax4022 = new FormControl();

    constructor(value: eAitisiTaxCalcModel) {
        this.TypTax4019.setValue(value.TypTax4019);
        this.AmoTax4022.setValue(value.AmoTax4022);

        // this.RefNumEXT1.setValidators([Validators.required]);
    }
}
