import { Contact } from "app/main/apps/contacts/contact.model";

export class BeerModel {


    customer?: Contact;
    smallProducer: "0" | "1";
    reducedVat: "0" | "1";
    addTaricCode: string;
    masterProduceValue: number;
    productDuty: number;
    vatRate: number;
    beerCalculationData: BeerCalculationDataModel[];
    beerTotals: BeerTotalsModel;

    constructor(value?: BeerModel) {

        const defaultStartDate = new Date();
        defaultStartDate.setHours(8, 0, 0, 0); // Set time to 8:00:00.000


        this.customer = value?.customer || null;
        this.smallProducer = value?.smallProducer || "0";
        this.reducedVat = value?.reducedVat || "0";
        this.addTaricCode = value?.addTaricCode || null;
        this.masterProduceValue = value?.masterProduceValue || null;
        this.productDuty = value?.productDuty || null;
        this.vatRate = value?.vatRate || null;
        this.beerCalculationData = value?.beerCalculationData || null;
        this.beerTotals = value?.beerTotals || null;
    }


}

export class BeerCalculationDataModel {

    productName: string;
    taricCode: string;
    addTaricCode: string;
    nationalProduct: string;
    quantity: number;
    platoExact: number;
    plato: number;
    produceValue: number;
    platoPer100L: number;
    statisticValue: number;
    eteppaContrib: number;
    ogaContrib: number;
    eteppaStampTax: number;
    amountWithoutVat: number;
    vatTaxBase: number;
    vat: number;
    efk: number;
    totalTax: number


    constructor(value?: BeerCalculationDataModel) {

        this.productName = value?.productName || null;
        this.taricCode = value?.taricCode || null;
        this.addTaricCode = value?.addTaricCode || null;
        this.nationalProduct = value?.nationalProduct || null;
        this.quantity = value?.quantity || null;
        this.platoExact = value?.platoExact || null;
        this.plato = value?.plato || null;
        this.produceValue = value?.produceValue || null;
        this.platoPer100L = value?.platoPer100L || null;
        this.statisticValue = value?.statisticValue || null;
        this.eteppaContrib = value?.eteppaContrib || null;
        this.ogaContrib = value?.ogaContrib || null;
        this.eteppaStampTax = value?.eteppaStampTax || null;
        this.amountWithoutVat = value?.amountWithoutVat || null;
        this.vatTaxBase = value?.vatTaxBase || null;
        this.vat = value?.vat || null;
        this.efk = value?.efk || null;
        this.totalTax = value?.totalTax || null;
    }

}

export class BeerTotalsModel {

    totalEfk: number;
    totalEteppa: number;
    totalEteppaStamp: number;
    totalOga: number;
    totalVat: number;
    totalGrand: number;
    constructor(value?: BeerTotalsModel) {

        this.totalEfk = value?.totalEfk || 0;
        this.totalEteppa = value?.totalEteppa || 0;
        this.totalEteppaStamp = value?.totalEteppaStamp || 0;
        this.totalOga = value?.totalOga || 0;
        this.totalVat = value?.totalVat || 0;
        this.totalGrand = value?.totalGrand || 0;
    }

}