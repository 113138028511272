import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LanguageSelectComponent } from './language-select.component';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';

@NgModule({
    declarations: [LanguageSelectComponent],
    exports: [LanguageSelectComponent],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, RouterModule, MaterialModule]
})
export class LanguageSelectModule {}
