import { AbstractControl, FormControl } from "@angular/forms";
import { SpecialConsigneeModel } from "./special-consignee.model";

export class SpecialConsigneeForm {
    [key: string]: AbstractControl;

    SpecialConsigneeLicenseType: FormControl;
    SpecialConsigneeLicenseNumber: FormControl;

    constructor(value: SpecialConsigneeModel) {

        this.SpecialConsigneeLicenseType = new FormControl(value.SpecialConsigneeLicenseType);
        this.SpecialConsigneeLicenseNumber = new FormControl(value.SpecialConsigneeLicenseNumber);

    }
}
