import { Location } from '@angular/common';
import { ComCodeService } from 'app/services/com-code.service';
import { isArray, isEqual } from 'lodash-es';
import { Injectable, Injector } from '@angular/core';
import { Validators, FormArray, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { map, take, takeUntil } from 'rxjs/operators';

import { Observable, Subject, of, tap, switchMap } from 'rxjs';
import { CalculateTariffRequired, countriesItem, TariffRequired } from 'app/model/api-model';
import { BroadcasterService } from '../apps/export/services/export-broadcaster.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { eAitisiDeclarationForm } from '../apps/e-aitisi/_models/declaration/declaration-form.model';
import { eAitisiDeclarationModel } from '../apps/e-aitisi/_models/declaration/declaration.model';
import { ArrAtExitForm } from '../apps/export/arrival-at-exit/_models/arrivaAtExit-form.model';
import { ArrAtExitModel } from '../apps/export/arrival-at-exit/_models/arrivaAtExit.model';
import { PacgsModel } from '../apps/export/declaration/_models/gooitegds/pacgs2/pacgs2.model';
import { TabService } from '../apps/tab-factory/tab.service';
import declarationData from 'assets/declarationData.json';
import { DeclarationStateService, StateResponse } from 'app/services/declaration-state.service';
import { FormArrayService } from 'app/services/submitServices/formArray.service';
import { LrnService } from 'app/services/lrn.service';
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { HttpResponse } from '@angular/common/http';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { Declarant } from 'app/core/user/user.model';
import { StorageService } from 'app/core/services/storage/storage.service';
import { Router } from '@angular/router';
import { RouteStateService } from 'app/services/routeState.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ProofRequestForm } from 'app/main/apps/transit/t2l-pous/_models/pous/pous-form.model';


@Injectable({
    providedIn: 'root'
})
export class ExtCustomFunctions {

    countries: countriesItem[] = [];
    grossWeightsArray = [];
    allPackagesArray = [];

    constructor(
        private broadcaster: BroadcasterService,
        private lrnService: LrnService,
        private mainBroadcaster: MainBroadcasterService,
        private fb: FormBuilder,
        private tabService: TabService,
        private injector: Injector,
        private formArrayService: FormArrayService,
        private storageService: StorageService,
        private router: Router,
        private location: Location,
        private routeStateService: RouteStateService
    ) {

    }

    //================================================================================
    //================================================================================

    expandPanel(matExpansionPanel: any, event: MouseEvent) {
        if (!this._isExpansionIndicator(event.target as HTMLSpanElement)) {
            matExpansionPanel.close();
        }
    }
    closePanel(matExpansionPanel: any) {
        matExpansionPanel.close();
    }

    private _isExpansionIndicator(target: HTMLSpanElement): boolean {
        const expansionIndicatorClass = 'mat-expansion-indicator';
        return target.classList && target.classList.contains(expansionIndicatorClass);
    }

    //=================================================================
    //================================================================
    onFocusEde(hostElement) {
        const divId = hostElement.nativeElement.parentElement.id;
        if (divId) {
            this.scrollToHook(divId)
            this.broadcaster.broadcast('svgFocus', divId);
        }
    }

    onErrorEde(hostElement, hasError, divId?) {
        //console.trace('CHECK ERROR onErroEde')
        if (!divId) {
            divId = hostElement.nativeElement.parentElement.id;
        }
        this.broadcaster.broadcast('svgError', { divId, hasError });
    }

    scrollToHook(id) {
        console.log("scroll id ", id)

        if (id === 'grossMass' || id === 'netMass') {
            return
        }
        setTimeout(() => {
            const node = document.querySelector('#' + id);
            console.log("scroll node", node)
            node?.scrollIntoView({
                //  behavior: 'smooth',
                block: 'center'
            });

        });

    }

    //========================================================================
    //========================================================================
    setRequired(control) {
        control.setValidators([Validators.required]);
        control.updateValueAndValidity();
    }
    removeValidator(control) {
        control.clearValidators();
        control.updateValueAndValidity();
    }
    //==================================================================
    //==================================================================
    defaultLangSub(formarray: FormArray, index: number, sourceCtrl: string, targetCtrl: string, destroy$: Subject<void>): Observable<string> {

        console.log("defaultLangSub formarray", formarray)
        console.log("defaultLangSub index", index)

        if (index > -1) {
            return this.mainBroadcaster.declarationSettings$
                .pipe(
                    take(1),
                    switchMap(
                        settings => {
                            return formarray
                                .at(index)
                                .get(sourceCtrl)
                                .valueChanges.pipe(
                                    tap(value => {
                                        if (value !== null && value !== '' && value !== undefined) {
                                            formarray.at(index).get(targetCtrl).setValue(settings.language);
                                        } else {
                                            formarray.at(index).get(targetCtrl).setValue(null);
                                        }
                                    }),
                                    takeUntil(destroy$)
                                );

                        }))

        }
        else {
            return of(null)
        }
    }

    deleteLang(formarray: FormArray, sourceCtrl: string, targetCtrl: string) {
        formarray.controls.forEach(control => {
            const value = control.get(sourceCtrl).value;

            if (value === '' || value === null) {
                control.get(targetCtrl).setValue(null);
            }
        });
    }
    //==================================================================
    //==================================================================

    hideAdd(formArray: FormArray, totalItems: number) {
        const items = formArray.length;

        if (items < totalItems) {
            return true;
        } else {
            return false;
        }
    }

    hideDelete(formArray: FormArray) {
        const items = formArray.length;
        if (items > 1) {
            return true;
        } else {
            return false;
        }
    }
    //======================================================================
    //=====================================================================

    deleteEmpty(formArray: FormArray) {
        let index = 0;
        //for each entry in the array
        const emptyIndexArr = [];
        // console.log("deleteEmpty FormArray: ", formArray)
        formArray.getRawValue().forEach(obj => {

            for (const [key, value] of Object.entries(obj)) {
                //check if any obj value is an array
                if (isArray(value)) {
                    //remove empty properties inside the array
                    const newValue = this.removeEmpty(value)
                    //if the arrays properties are empty
                    //replace the object key with null instead of Array[]
                    if (newValue.length === 0) {
                        obj[key] = null
                    }
                }
            }
            console.log("deleteEmpty obj: ", obj)
            console.log("deleteEmpty Object.values", Object.values(obj))
            //check if any input has value
            const isEmpty = !Object.values(obj).some(x => x !== null && x !== '' && x !== undefined);
            console.log("deleteEmpty obj empty: ", isEmpty)

            //if object is empty delete the entry from the array
            if (isEmpty) {
                emptyIndexArr.push(index);
            }

            index++;
        });
        console.log("deleteEmpty Index Array", emptyIndexArr)
        //return array from end to start
        return emptyIndexArr.reverse();
    }

    removeEmpty(object) {
        Object.entries(object).forEach(([k, v]) => {
            if (v && typeof v === 'object') {
                this.removeEmpty(v);
            }
            if ((v && typeof v === 'object' && !Object.keys(v).length) || v === '' || v === null || v === undefined) {
                if (Array.isArray(object)) {
                    //  console.log("ARRAY:" + k, object)
                    object.splice(+k, 1);
                } else {
                    //  console.log("OBJECT:" + k, object)
                    delete object[k];
                }
            }
        });
        return object;
    }

    countryFormat(value: string, english?: boolean, uppercase?: boolean): Observable<string> {
        let property: string;

        return this.mainBroadcaster.countries$.pipe(map(countries => {

            const country = countries.filter(item => item.country_code === value);
            //if there is a result
            if (country.length > 0) {
                //return english else greek
                if (english) {
                    property = 'country_name_en';
                } else {
                    property = 'country_name';
                }
                //return uppercase
                if (uppercase) {
                    if (english) {
                        return country[0][property].toUpperCase();
                    } else {
                        return this.removeAccents(country[0][property]).toUpperCase();
                    }
                } else {
                    return this.removeAccents(country[0][property]);
                }
            }
        }), take(1))

    }

    formatDecimalDigits(control: FormControl, numberOfDigits: number) {
        const value = control.value;

        // We don't want to alter the value if it's null or undefined
        if (value === null || value === undefined) {
            control.reset();
            return;
        }

        const decimals = value.toString().split('.')[1];
        const factorOfTen = Math.pow(10, numberOfDigits);

        // Here we want to format the value even if it is 0
        if (!decimals || decimals.length < numberOfDigits) {
            // The + operator is a shorthand to convert a string to a number
            // The value is rounded and fixed to the specified number of decimal digits
            const formattedValue = (Math.round(+value * factorOfTen) / factorOfTen).toFixed(numberOfDigits);
            control.setValue(formattedValue, {
                onlySelf: true,
                emitEvent: false
            });
        }
        // If the value doesn't need formatting, we do nothing
    }


    formatDecimalString(value: string | number, numberOfDigits: number, strict = true): string | number | null {
        // Handle `null` or `undefined` explicitly, but allow 0
        if (value === null || value === undefined) {
            value = 0;
        }

        const split = value.toString().split('.');

        const decimals = split && split.length > 1 ? split[1] : "";

        const factorOfTen = Math.pow(10, numberOfDigits);

        // Check if the value needs to be formatted
        if (!decimals || decimals.length < numberOfDigits) {
            // Always format the number
            return (Math.round(+value * factorOfTen) / factorOfTen).toFixed(numberOfDigits);
        } else {
            if (strict) {
                // If strict, still format the number
                return (Math.round(+value * factorOfTen) / factorOfTen).toFixed(numberOfDigits);
            } else {
                // If not strict, return the value as-is
                return value;
            }
        }
    }

    /*    formatDecimalString(value: string | number, numberOfDigits: number) {
   
           console.trace("WHO CALLS formatDecimal")
           console.log("formatDecimalString", value)
   
           if (!value) {
               value = 0;
           }
   
           const factorOfTen = Math.pow(10, numberOfDigits);
           if (value) {
               return (Math.round(+value * factorOfTen) / factorOfTen).toFixed(numberOfDigits);
           }
       } */
    //======================================================================
    //=====================================================================
    removeAccents(value: string) {
        return value
            ? value
                .replace(/ά/g, 'α')
                .replace(/έ/g, 'ε')
                .replace(/ή/g, 'η')
                .replace(/ί/g, 'ι')
                .replace(/ΐ/g, 'ι')
                .replace(/ϊ/g, 'ι')
                .replace(/ύ/g, 'υ')
                .replace(/ϋ/g, 'υ')
                .replace(/ΰ/g, 'υ')
                .replace(/ό/g, 'ο')
                .replace(/ώ/g, 'ω')
            : null;
    }

    //======================================================================
    //=====================================================================


    hardCodedValues(declarationForm: FormGroup) {
        this.formArrayService.addFormArray(declarationForm, 'GOOITEGDS');

        const currentHeahea = declarationForm.get('HEAHEA') as FormGroup;
        const currentGoods = declarationForm.get('GOOITEGDS') as FormArray;

        this.mainBroadcaster.declarationSettings$.pipe(take(1)).subscribe(settings => {

            currentHeahea.get('TotNumOfIteHEA305').setValue(1);
            currentHeahea.get('DiaLanIndAtDepHEA254')?.setValue(settings.language); //ECS. NCTS
            currentHeahea.get('ECSAccDocHEA601')?.setValue(settings.language); //EXPORT ONLY
            currentHeahea.get('NCTSAccDocHEA601LNG')?.setValue(settings.language); //NCTS ONLY

            currentGoods.at(0).patchValue({ IteNumGDS7: 1 });
        });
    }

    DefkHardCodedValues(declarationForm: FormGroup) {
        const currentDeclaration = declarationForm.get('Body').get('DeclarationOfExciseTaxesRegistration') as FormGroup;

        const TaxesDeclaration = currentDeclaration.get('ExciseTaxesDeclaration') as FormGroup;

        const currentTaxesRow = currentDeclaration.get('ExciseTaxesRow') as FormArray;

        TaxesDeclaration.patchValue({
            Fallbackflag: "0",
            DocumentNumber: "1",
            TotalDocuments: "1",
            NumberOfRows: "1",
            ExistenceOfContainerFlag: "0",
            ChemicalAnalysisPerformedFlag: "0",
            ThroughSimplifiedProcedureFlag: "0",
            CertificateIssuanceFlag: "0",
            DeclarationTypeCode: "30"
        })

        currentTaxesRow.at(0).get('ExciseTaxesDeclarationRowNumber').setValue('1')

    }
    PousHardCodedValues(declarationForm: FormGroup<ProofRequestForm>) {

        const goodsShipment = declarationForm.get('ProofInformationT2LT2LF').get('GoodsShipmentForT2LT2LF') as FormGroup;
        const currentGoods = goodsShipment.get('GoodsItemsForT2LT2LF') as FormArray;

        const proofInfo = declarationForm.get('ProofInformationT2LT2LF') as FormGroup;

        declarationForm.get('Country').setValue('GR');
        proofInfo.get('RequestedValidityOfTheProof').get('NumberOfDays').setValue('90');
        proofInfo.get('RequestType').setValue('01');
        goodsShipment.get('LocationOfGoods').get('TypeOfLocation').setValue('A');
        goodsShipment.get('LocationOfGoods').get('QualifierOfIdentification').setValue('V');

        currentGoods.at(0).get('GoodsItemNumber').setValue(1);

    }

    getCurrentGoods(declarationForm: FormGroup): FormArray {
        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value

        if (subdomain === 'DEFK') {

            return declarationForm.get('Body').get('DeclarationOfExciseTaxesRegistration').get('ExciseTaxesRow') as FormArray;
        }
        else if (subdomain === 'POUS') {
            return declarationForm.get('ProofInformationT2LT2LF').get('GoodsShipmentForT2LT2LF').get('GoodsItemsForT2LT2LF') as FormArray;
        }
        else {
            return declarationForm.get('GOOITEGDS') as FormArray;
        }

    }

    getComTaric(goodsFormGroup: FormGroup, subdomain: string): { comCode: string, taricCode: string } {

        let comCode: string;
        let taricCode: string;
        if (subdomain === 'DAO') {
            return { comCode: null, taricCode: null }
        }

        if (subdomain === 'DEFK') {
            const comTaric = goodsFormGroup.get('TaricCode').value;
            comCode = comTaric?.substring(0, 8)
            taricCode = comTaric?.substring(8, 10)
        }
        else if (subdomain === 'POUS') {
            const comTaric = goodsFormGroup.get('Commodity').get('HarmonizedSystemSubHeadingCode').value;
            comCode = comTaric?.substring(0, 8)
            taricCode = '00'
        }
        else {
            const comCodGodItm = goodsFormGroup.get('COMCODGODITM') as FormArray;

            comCode = comCodGodItm?.at(0).get('ComNomCMD1').value;
            taricCode = comCodGodItm?.at(0).get('TARCodCMD1').value;


        }

        return { comCode, taricCode };
    }

    //function to get Total Gross Weight from partial Gross Weights
    //it is used by the sendTotalItems() method, updateForm() method and by the total-gross-weight-component
    getGrossWeights(declarationForm: FormGroup, items: number) {
        console.trace('getGrossWeights()');

        const currentGoods = declarationForm.get('GOOITEGDS') as FormArray;
        const currentHeahea = declarationForm.get('HEAHEA') as FormGroup;

        console.log('Grossweight currentGoods:', currentGoods);
        console.log('Grossweight items:', items);

        //get total items
        const totalItems = items;

        //empty the array holding previous values
        const weights: number[] = [];
        this.grossWeightsArray = [];


        for (let i = 0; i < totalItems; i++) {
            //set initial value (when creating GOOITEGDS FormArray we don't create the FormCOntrols
            //that's why the controls are undefined

            //I should check why the actual GOOITEGDS formArray return undefined indexes right here
            //we overcome this with a fail check and set initial value to 0)
            let initialValue: number;

            initialValue = currentGoods?.at(i).get('GroMasGDS46').value;

            initialValue = Number(initialValue) || 0;

            //we create the new array
            weights.push(initialValue);
            this.grossWeightsArray.push(initialValue);

            //we sum all the values
            //let sum = weights.reduce((acc, cur) => acc + cur, 0).toFixed(2);
        }

        const sum = this.grossWeightsArray.reduce((acc, cur) => acc + cur, 0).toFixed(2);
        currentHeahea.get('TotGroMasHEA307').setValue(sum);

        console.log('DECLFORMSERVICE: TOTALX GROSS WEIGHT', sum);
    }

    updateGrossWeights(declarationForm: FormGroup, goodsIndex: number, value: number) {
        const currentHeahea = declarationForm.get('HEAHEA') as FormGroup;

        this.grossWeightsArray.splice(goodsIndex, 1, +value);

        const totalGrossWeight: string = this.grossWeightsArray.reduce((acc, cur) => acc + cur, 0).toFixed(2);

        //console.log("DECLFORMSERVICE: allItemsArray " + allItemsArray);
        if (+totalGrossWeight > 0) {
            currentHeahea.get('TotGroMasHEA307').setValue(totalGrossWeight);
        } else {
            currentHeahea.get('TotGroMasHEA307').setValue(0);
        }

        console.log('DECLFORMSERVICE: TOTALX GROSS WEIGHT', totalGrossWeight);
    }

    getPackagesInitial(declarationForm: FormGroup, totalItems: number) {
        console.log('DECLFORMSERVICE: getPackagesInitial()');

        const currentGoods = declarationForm.get('GOOITEGDS') as FormArray;
        const currentHeahea = declarationForm.get('HEAHEA') as FormGroup;

        this.mainBroadcaster.declarationSettings$
            .pipe(take(1))
            .subscribe((settings: DeclarationSettingsModel) => {

                //THE ARRAY OF ITEMS IN OUR FORM
                //holds the the totals for all items
                //item1-totPacgs, item2-totPacgs,...
                this.allPackagesArray = [];

                let eachItemSum: number;

                for (let i = 0; i < totalItems; i++) {
                    // let obs = "PACGS" + i;

                    const eachItemArray: number[] = [];

                    if (currentGoods.at(i)) {
                        //THE ARRAY OF PACKAGES INSIDE EACH ITEM
                        const pacgsArray = currentGoods.at(i).get('PACGS2');

                        console.log('DECLFORMSERVICE: TOTALX pacgsArray ' + i + pacgsArray);
                        console.log('DECLFORMSERVICE: TOTALX', pacgsArray);

                        pacgsArray.value.forEach((entry: PacgsModel) => {
                            let value = 0;

                            if (entry.NumOfPacGS24) {
                                value = +entry.NumOfPacGS24;
                            } else if (entry.NumOfPieGS25) {
                                value = +entry.NumOfPieGS25;
                            } else if (settings.kodikoi_xima.includes(entry.KinOfPacGS23)) {
                                value = 1;
                            }

                            eachItemArray.push(value);
                        });

                        ////console.log("DECLFORMSERVICE: packages: ", eachItemArray);

                        eachItemSum = eachItemArray.reduce((acc, cur) => acc + cur, 0);

                        // //console.log("DECLFORMSERVICE: eachItemSum: ", eachItemSum);

                        this.allPackagesArray.push(eachItemSum);
                    }
                }

                const totalPackages: number = this.allPackagesArray.reduce((acc, cur) => acc + cur, 0);

                if (totalPackages > 0) {
                    currentHeahea.get('TotNumOfPacHEA306').setValue(totalPackages);
                } else {
                    currentHeahea.get('TotNumOfPacHEA306').setValue(null);
                }
                console.log('DECLFORMSERVICE: TOTALX PACKAGES', totalPackages);
            });
    }

    updatePackagesArray(declarationForm, goodsIndex, value) {
        const currentHeahea = declarationForm.get('HEAHEA') as FormGroup;

        this.allPackagesArray.splice(goodsIndex, 1, +value);

        const totalPackages: number = this.allPackagesArray.reduce((acc, cur) => acc + cur, 0);

        //console.log("DECLFORMSERVICE: allItemsArray " + allItemsArray);
        if (totalPackages > 0) {
            currentHeahea.get('TotNumOfPacHEA306').setValue(totalPackages);
        } else {
            currentHeahea.get('TotNumOfPacHEA306').setValue(null);
        }

        console.log('DECLFORMSERVICE: TOTALX PACKAGES', totalPackages);
    }

    getDeclarant(submitForm) {

        return submitForm._PRIVATE.declarant?.afm;


    }
    filterDeclarants(allDeclarants: Declarant[], trader: Contact, noEori = false): Declarant[] {

        let customersWithCreds;

        const users = allDeclarants.filter((item: Declarant) => {
            //RETURN DECLARANTS WHICH IS OWNER  OR IS_collaborator  
            return item.is_owner || item.is_collaborator;
        });

        console.log("Declarants users", users)
        console.log("Declarants this._trader", trader)
        if (trader) {
            customersWithCreds = allDeclarants.filter(e => e.afm === trader.afm)
        }
        else {
            if (noEori) {
                customersWithCreds = allDeclarants.filter(e => e.is_client)
            }
            else {
                customersWithCreds = allDeclarants.filter(e => e.is_client && e.eori)
            }

        }

        console.log("Declarants customersWithCreds", customersWithCreds)


        return [...users, ...customersWithCreds];
    }

    declarantIsBroker(declarant: FormControl): boolean {

        return declarant.value?.decl_status
    }
    getTraderId(submitFormValue) {
        const { _PRIVATE } = submitFormValue;
        const { subdomain, trader } = _PRIVATE;

        if (!trader) {
            // Handle the case where trader is not defined, possibly by returning null or throwing an error
            return null;
        }

        const targetSubdomains = ['DAO', 'DAO_RECEIVE', 'DEFK', 'ICS_DETE'];
        if (targetSubdomains.includes(subdomain)) {
            return trader.eori ?? trader.afm;
        } else {
            return trader.eori;
        }
    }

    deleteLocalStorage(localDocs: string[]) {
        //cant remember why I need the timeout
        //smth to dowith the restoring of drafts and templates
        //need to check it out
        //  setTimeout(()=>{

        localDocs.forEach(doc => {
            if (this.storageService.checkLocalObject(doc)) {
                this.storageService.removeLocalObject(doc);

                console.log('deleteLocalStorage ' + doc);
            }
        });
        //    },1500)
    }
    addTabsLocalStorage(localDocs: string[], localStorageValue: string) {
        localDocs.forEach(doc => {
            if (this.storageService.checkLocalObject(doc) && doc !== localStorageValue) {
                this.tabService.addDocTab(doc);
                console.trace('Found localSTorageItem ' + doc + '. Adding Tab');
            }

            //update the connect observable so we have values associated even if the componenent is not created
            if (doc === 'eur1') {
                this.mainBroadcaster.updateEur1Form(this.storageService.getLocalObject(doc));
            } else if (doc === 'agrcert') {
                this.mainBroadcaster.updateAgrCertForm(this.storageService.getLocalObject(doc));
            } else if (doc === 'cmr') {
                this.mainBroadcaster.updateCmrForm(this.storageService.getLocalObject(doc));
            } else if (doc === 'atr') {
                this.mainBroadcaster.updateAtrForm(this.storageService.getLocalObject(doc));
            }
        });
    }

    //ΔΙΟΡΘΩΣΗ ΔΙΑΣΑΦΗΣΗΣ
    editDeclaration(declarationForm: FormGroup) {
        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value;
        const messageType = declarationForm.get('_PRIVATE').get('messageOut').value;
        const modifyMessageType = declarationData[subdomain]?.modifyMessageType;

        let state = 'Pre-Submitted';
        //If we return to previous State when the modified Declaration is rejected 
        if (messageType === modifyMessageType) {
            state = 'Edit';
        }
        const declarationStateService = this.injector.get(DeclarationStateService);

        declarationStateService.setState(declarationForm, state);

        console.log("EDIT DECL NEW STATE", state)

        if (state === 'Pre-Submitted') {
            //Set New LRN 
            this.lrnService.setNewLrn(declarationForm).subscribe();
            //Reset Private data(like functional errors)
            declarationForm.get('_PRIVATE').get('data').reset()
            //Change path to new
            this.changePathToNewDeclaration()
        }

    }

    changePathToNewDeclaration() {

        const urlSegments = this.router.url.split('/');

        this.location.go(`${urlSegments[1]}/${urlSegments[2]}/${urlSegments[3]}/new`)
        this.routeStateService.updateRouteParams({ type: 'new', id: null })

    }

    //====================================================================
    //==========================================================================
    getClonedForm(form: FormGroup, subdomain: string): FormGroup {
        const formValue = form.getRawValue();

        switch (subdomain) {
            case 'ICS_DETE': {
                return this.fb.group(new eAitisiDeclarationForm(new eAitisiDeclarationModel(formValue)));
            }
            case 'ARR_AT_EXIT': {
                return this.fb.group(new ArrAtExitForm(new ArrAtExitModel(formValue)));
            }
        }
    }

    changeStyle1(event: MouseEvent) {
        return event.type === 'mouseenter' ? 'accent' : '';
    }
    changeStyle2(form: FormGroup, event: MouseEvent) {
        if (form.valid) {
            return event.type === 'mouseenter' ? 'accent' : 'primary';
        }
        return '';
    }

    JsonToObj(json) {
        return Object.keys(json)
            .sort(function (a, b) {
                // if you need to ensure the order of the keys
                return a.localeCompare(b, undefined, { numeric: true });
            })
            .map(function (key) {
                return {
                    id: key,
                    name: json[key]
                };
            });
    }

    resetFieldValue(array: FormArray, field: string) {

        for (let i = 0; i < array.length; i++) {
            array.at(i).get(field).reset();
        }
    }

    getFileNameFromHttpResponse(httpResponse) {
        const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
        const result = contentDispositionHeader?.split(';')[1]?.trim()?.split('=')[1];
        return result?.replace(/"/g, '');
    }

    showCalculateTariffRequired(required: CalculateTariffRequired) {

        let message = '<h3><b>Απαραιτητα πεδία για τον υπολογισμό δασμών ειναι τα ακόλουθα:</b></h3>';


        for (const [k, v] of (Object.entries(required))) {


            message += '<p>To πεδιο <b>' + TariffRequired[k] + '</b> ειναι απαραίτητο. ';

            if (v) {

                message += ' <span class="material-icons danger info-icon">close</span>';

            }
            else {
                message += ' <span class="material-icons success info-icon">check</span>';

            }

            message += '</p>';


        }
        const openDialogs = this.injector.get(OpenDialogsService)

        openDialogs.openConfirmDialog({ message, title: 'Υπολογισμός Δασμών', confirmClass: 'primary' }).subscribe()
    }


    sameValuesInArray(arr, controlValue) {
        return arr.every(val => {

            if (!val) {
                return true;
            }

            if (typeof (controlValue) === 'object') {

                return isEqual(val, controlValue)
            }

            return val === controlValue
        })
    }

    resetComCode() {
        const comCodeService = this.injector.get(ComCodeService)

        comCodeService.resetComCode();
    }
    downloadDoc(response: HttpResponse<ArrayBuffer>) {
        // Create a new Blob from the response data
        const blob = new Blob([response.body], { type: 'application/pdf' });

        // Use the global URL object to create an object URL for the blob
        const downloadUrl = URL.createObjectURL(blob);

        // Create an anchor element for the download
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = this.getFileNameFromHttpResponse(response) || 'default-filename.pdf';

        // Append the anchor to the document, trigger the download, and then remove the anchor
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Release the created object URL
        URL.revokeObjectURL(downloadUrl);
    }

    printDoc(response: HttpResponse<ArrayBuffer>) {

        const newWindow = this.newWindowPdf(response).newWindow

        setTimeout(() => {
            newWindow.print()
        }, 1000)
    }

    newWindowPdf(response: HttpResponse<ArrayBuffer>) {

        const binaryData = [];

        binaryData.push(response.body);

        const downloadLink = document.createElement('a');

        const blob = new Blob(binaryData, { type: 'application/pdf' });

        downloadLink.href = window.URL.createObjectURL(blob);

        const newWindow = window.open(downloadLink.href, '_blank');

        return { newWindow, downloadLink };

    }

    responseToJson(textArray, prevLength): { jsonArray: StateResponse[], prevLength: number } {
        const jsonArray: StateResponse[] = [];

        const totalLength = textArray.length;

        //because some messages are transmitted by our backend almost simultaneous;y
        //we may receive two messages in one transmission, so we have to know how much
        //our array has grown and display all messages, instead of the last one only
        const offset = totalLength - prevLength;
        let res: StateResponse = null;

        for (let i = offset; i > 0; i--) {
            const responseTxt = textArray[totalLength - i];

            console.log('response text on interceptor', responseTxt);

            if (
                responseTxt &&
                responseTxt !== '' &&
                responseTxt !== ' ' &&
                !responseTxt.match(/^\n/g) &&
                !responseTxt.match(/^ \n/g) &&
                responseTxt !== null &&
                responseTxt.match(/}$/) //There are times where the string gets truncated, check it ends properly
            ) {
                res = JSON.parse(responseTxt);
                console.log('response parsed on interceptor', res);

                jsonArray.push(res)

            }
        }
        //we store the current size of the array to check the offset
        //on the next transmission
        //If the array is empty, probably the response is truncated, so we return the previousLength instead of the totalLength,
        //so that we can retry to parse the responseTxtx in the next try
        prevLength = jsonArray.length > 0 ? totalLength : prevLength;

        return { jsonArray, prevLength };
    }

    getPathSubdomain(subdomain) {
        let pathSubdomain: string;

        if (subdomain === 'EXP_DECL') {
            pathSubdomain = 'EXPORT'
        }
        else if (subdomain === 'IMP_DECL') {
            pathSubdomain = 'IMPORT'
        }
        else if (subdomain === 'ICS_DETE') {
            pathSubdomain = 'DETE'
        }
        else if (subdomain === 'ARR_AT_EXIT') {
            pathSubdomain = 'ARRATEXIT'
        }
        else {
            pathSubdomain = subdomain
        }

        return pathSubdomain.toLowerCase()
    }
    closeDialogButtonFocus(dialogRef: MatDialogRef<any>, callingDivId: string, result?: any) {

        dialogRef?.close();

        console.log("MENTIONSDOCS callingDiv", callingDivId)

        const button = <HTMLElement>document?.getElementById(callingDivId)?.firstElementChild;

        console.log('Element', document.getElementById(callingDivId));
        button?.focus();

    }
}
