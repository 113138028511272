import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseConfirmDialogComponent {
    public confirmTitle: string;
    public confirmMessage: any;
    public confirmButton: string;
    public cancelButton: string;
    public confirmClass = 'fuse-navy-700';
    public cancelClass = 'primary'

    /**
     * Constructor
     *
     * @param dialogRef
     */
    constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>) { }
}
