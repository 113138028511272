import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DotsModule } from '../../_scams/dots/dots.module';
import { CountrySelectModule } from 'app/main/main-building-blocks/form-fields/country-select/country-select.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomsControlComponent } from './customs-control.component';
import { AutoCompleteControlModule } from '../../form-fields/custom-autocomplete/autocomplete-control.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomsNamePipeModule } from 'app/services/pipes/customsName.pipe';



@NgModule({
    declarations: [CustomsControlComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CountrySelectModule,
        AutoCompleteControlModule,
        DotsModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatButtonModule,
        CustomsNamePipeModule
    ],
    exports: [CustomsControlComponent]
})
export class CustomsControlModule { }
