import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { DEFKContactDetailsForm } from "../contactDetails/contactDetails-form.model";
import { DEFKContactDetailsModel } from "../contactDetails/contactDetails.model";
import { ComplementaryConsigneesModel } from "./complementary-consignees.model";

export class ComplementaryConsigneesForm {
    [key: string]: AbstractControl;

    ComplementaryConsigneeIdentificationType: FormControl;
    ComplementaryConsigneeIdentification: FormControl;
    ExemptionBeneficiaryFlag: FormControl;
    OwnershipPercentage: FormControl;
    ContactDetails: FormGroup;

    /*

   */

    constructor(value: ComplementaryConsigneesModel) {

        this.ComplementaryConsigneeIdentificationType = new FormControl(value.ComplementaryConsigneeIdentificationType);
        this.ComplementaryConsigneeIdentification = new FormControl(value.ComplementaryConsigneeIdentification);
        this.ExemptionBeneficiaryFlag = new FormControl(value.ExemptionBeneficiaryFlag);
        this.OwnershipPercentage = new FormControl(value.OwnershipPercentage);
        this.ContactDetails = new FormGroup(new DEFKContactDetailsForm(new DEFKContactDetailsModel(value.ContactDetails)));

    }
}
