export class CusofftrarnsModel {
    _countryCode?: string;
    RefNumRNS1?: string;
    ArrTimTRACUS085?: string;

    constructor(value?) {
        this._countryCode = value?._countryCode || null;
        this.RefNumRNS1 = value?.RefNumRNS1 || null;
        this.ArrTimTRACUS085 = value?.ArrTimTRACUS085;
    }
}
