import { FormControl } from '@angular/forms';
import { ConresersModel } from './conresers.model';

export class ConresersForm {
    [key: string]: FormControl;

    ConResCodERS16 = new FormControl();
    DatLimERS69 = new FormControl();

    constructor(value: ConresersModel) {
        this.ConResCodERS16.setValue(value.ConResCodERS16);
        this.DatLimERS69.setValue(value.DatLimERS69);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
