import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToposTirisisEggrafonService } from 'app/main/apps/general-applications/topos-tirisis-eggrafon/topos-tirisis-eggrafon.service';
import { BrowserDetectService } from 'app/services/browser-detect.service';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'app-topos-tirisis-eggrafon-print',
    templateUrl: './topos-tirisis-eggrafon-print.component.html',
    styleUrls: ['./topos-tirisis-eggrafon-print.component.scss']
})
export class ToposTirisisEggrafonPrintComponent implements OnInit {

    dilosiForm$: Observable<FormGroup>;
    dilosiForm: FormGroup;


    get customer(): FormGroup {
        return this.dilosiForm.get('customer') as FormGroup
    }
    get declarant(): FormGroup {
        return this.dilosiForm.get('declarant') as FormGroup
    }

    get documentsPlace(): FormGroup {
        return this.dilosiForm.get('documentsPlace') as FormGroup
    }

    get contactDetails(): FormGroup {
        return this.dilosiForm.get('contactDetails') as FormGroup
    }

    constructor(private dilosiService: ToposTirisisEggrafonService, public browser: BrowserDetectService) { }

    ngOnInit(): void {

        this.dilosiService.dilosiForm$.pipe(tap(form => {


            this.dilosiForm = form

        })).subscribe()
    }

}
