<div id="cancel-declaration-dialog" fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div>Ακύρωση Διασάφησης</div>
        <!--  <div>
                <h1><small>MRN </small>{{ mrn }}</h1>
            </div> -->
        <div>
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="flex-start center" mat-dialog-content style="
            overflow-y: auto;
            min-height: 100px;
            height: 100px;
            padding: 0 16px;
        " [formGroup]="cancelForm">
        <!-- LEFT SIDE -->
        <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%">
            <div fxLayout="column" fxLayoutAlign="center start" style="width: 50%">
                <div fxLayout="column" class="group">

                    <!-- FIRST ROW -->
                    <table class="eAitisiHea twoColumns">
                        <tbody>
                            <tr *ngIf="showCustoms" [formGroup]="cancelForm.get(customsFormGroup)">
                                <td>Τελωνείο Εξαγωγής</td>
                                <td>
                                    <mat-form-field class="s-l sc-ul s-14" appearance="outline">
                                        <autocomplete-control [formControlName]="customsControl" [options]="customs"
                                            [config]="config" placeholder="Τελωνείο Εξαγωγής">
                                        </autocomplete-control>
                                    </mat-form-field>
                                </td>

                            </tr>

                            <tr>
                                <td>Ημερ/νία Υποβολής</td>
                                <td [formGroup]="cancelForm.get('_PRIVATE')">
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-14">
                                        <date-select formControlName="cancellationDate" [dateType]="dateType"
                                            [defaultDate]="defaultDate" [tabindex]="-1" [readonly]="true">
                                        </date-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>MRN</td>
                                <td [formGroup]="cancelForm.get('_PRIVATE')">
                                    <mat-form-field class="s-l sc-ul s-20" appearance="outline">
                                        <input matInput formControlName="mrn" placeholder="MRN" readonly
                                            tabindex="-1" />

                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr *ngIf="showTrader" [formGroup]="cancelForm.get(traderFormGroup)">
                                <td>Εξαγωγέας</td>
                                <td>
                                    <mat-form-field class="s-l sc-ul s-30" appearance="outline">
                                        <input matInput [formControlName]="traderControl" placeholder="Εξαγωγέας"
                                            readonly tabindex="-1" />

                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- RIGHT SIDE -->
            <div fxLayout="column" fxLayoutAlign="center start" style="width: 42%;">
                <div fxLayout="column" class="group" style="border:none; padding:16px 16px 0 0;">

                    <div [formGroup]="cancelForm.get('_PRIVATE')">
                        <mat-form-field appearance="outline" floatLabel="true" class="s-40">
                            <mat-label>Αιτία Ακύρωσης</mat-label>
                            <textarea #firstInput style="
                            overflow: hidden;
                            resize: none;
                            line-height: 1.3vw;
                        " matInput maxlength="100" rows="8" tabindex="0" formControlName="cancellationReason"
                                required></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>


    </div>

    <div>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="onCloseConfirm()">
                Ακυρο
            </button>
            <button style="width: auto !important" mat-raised-button [disabled]="!cancelForm.valid" [ngClass]="color2"
                (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onSubmit()">
                Υποβολή Ακύρωσης
            </button>
        </mat-dialog-actions>

    </div>
</div>