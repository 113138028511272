<div class="ecl-container">
    <div class="ecl-row">
        <div class="ecl-col-12" style="overflow-x: hidden">
            <div>
                <!-- TARIC TREE -->
                <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl" class="tree" #tree>
                    <!-- TERMINAL NODE (NO CHILDREN) -->

                    <mat-nested-tree-node class="ul" *matTreeNodeDef="let product">
                        <li class="productrow">
                            <div class="borderOnly"></div>

                            <div [ngClass]="{
                                    nomenexpandtrue:
                                        treeControl.isExpanded(product),
                                    nomenexpandfalse:
                                        !treeControl.isExpanded(product)
                                }">
                                <div id="node_{{ product.id }}" class="desc">
                                    <!--     <a href="" class="branch-block"><span class="code treecolapsed">&nbsp;</span></a>
   -->

                                    <!--    <button mat-icon-button disabled>
                        <mat-icon>panorama_fish_eye </mat-icon>
                    </button>
                    -->
                                    <a href="" (click)="
                                            $event.preventDefault();
                                            expand(product)
                                        " *ngIf="
                                            product.hasChildren &&
                                            !treeControl.isExpanded(product)
                                        ">
                                        <span class="code treecolapsed">+</span>
                                    </a>

                                    <a href="" (click)="
                                            $event.preventDefault();
                                            collapse(product)
                                        " *ngIf="treeControl.isExpanded(product)">
                                        <span class="code treeexpanded">–</span>
                                    </a>

                                    <a href="" (click)="
                                            $event.preventDefault();
                                            selectProduct(product)
                                        " *ngIf="product.hasChildren !== true">
                                        <span class="code treeleaf product-selectable-row" [ngClass]="{
                                                'treeleaf-selected':
                                                    requestedProduct(product)
                                            }">&nbsp;</span>
                                    </a>
                                    <!--   <buttonmat-icon-button disabled>
                    <mat-icon>panorama_fish_eye </mat-icon>
                  </button> -->
                                    <!-- Product Description Selectable -->
                                    <span *ngIf="product.selectable" (click)="selectProduct(product)"
                                        class="product-desc product-selectable product-selectable-row" [ngClass]="{
                                            'product-requested':
                                                requestedProduct(product),
                                            'product-direct-match':
                                                product.hasMatches === 'DIRECT',
                                            'product-indirect-match':
                                                product.hasMatches === 'INDIRECT'
                                        }">{{ product.description }}
                                        <!-- Product Footnotes --->
                                        <ng-container *ngIf="product.footnotes">
                                            <span *ngFor="
                                                    let footnote of product.footnotes
                                                " class="product-footnote" [matTooltip]="
                                                    footnote.FootnoteDesc
                                                " matTooltipClass="product-footnote-tooltip">({{ footnote.FootnoteCode
                                                }})
                                            </span>
                                        </ng-container>
                                        <!-- Product Footnotes --->
                                    </span>
                                    <!-- /Product Description Selectable -->

                                    <!-- Product Description Non Selectable -->
                                    <span *ngIf="!product.selectable" class="product-desc product-non-selectable"
                                        [ngClass]="{
                                            'product-requested':
                                                requestedProduct(product),
                                            'product-direct-match':
                                                product.hasMatches === 'DIRECT',
                                            'product-indirect-match':
                                                product.hasMatches === 'INDIRECT'
                                        }">{{ product.description }}
                                        <!-- Product Footnotes --->
                                        <ng-container *ngIf="product.footnotes">
                                            <span *ngFor="
                                                    let footnote of product.footnotes
                                                " class="product-footnote" [matTooltip]="
                                                    footnote.FootnoteDesc
                                                " matTooltipClass="product-footnote-tooltip">({{ footnote.FootnoteCode
                                                }})
                                            </span>
                                        </ng-container>
                                        <!-- Product Footnotes --->
                                    </span>
                                    <!-- /Product Description Non Selectable -->

                                    <mat-progress-bar *ngIf="product.isLoading" mode="indeterminate" color="red"
                                        class="example-tree-progress-bar"></mat-progress-bar>
                                    <!-- Product Code Selectable -->
                                    <div class="prod" *ngIf="product.selectable" (click)="selectProduct(product)"
                                        [ngClass]="{
                                            'product-requested':
                                                requestedProduct(product)
                                        }">
                                        <span class="chapter">{{
                                            product.chapter
                                            }}</span>
                                        <span class="heading">{{
                                            product.heading
                                            }}</span>
                                        <span *ngIf="
                                                product.subheading !== '000000'
                                            " class="subheading">{{ product.subheadingStyle }}</span>
                                    </div>
                                    <div class="moreButton" *ngIf="
                                            product.selectable &&
                                            showActionButtons &&
                                            !(formDisabled$ | async)
                                        ">
                                        <button mat-icon-button [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{
                                                product: product
                                            }">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                    </div>
                                    <!-- /Product Code Selectable -->

                                    <!-- Product Code Non - Selectable -->

                                    <div class="prod code-non-selectable" *ngIf="!product.selectable" [ngClass]="{
                                            'product-requested':
                                                requestedProduct(product)
                                        }">
                                        <span class="chapter">{{
                                            product.chapter
                                            }}</span>
                                        <span *ngIf="product.heading !== '00'" class="heading">{{ product.heading
                                            }}</span>
                                        <span *ngIf="
                                                product.subheading !== '000000'
                                            " class="subheading">{{ product.subheadingStyle }}</span>
                                    </div>
                                    <!-- /Product Code Non - Selectable -->
                                </div>
                            </div>
                        </li>
                    </mat-nested-tree-node>
                    <!-- /TERMINAL NODE (NO CHILDREN) -->

                    <!-- PARENT NODE (HAS CHILDREN) -->

                    <mat-nested-tree-node class="ul" *matTreeNodeDef="let product; when: hasChildren">
                        <li>
                            <div class="borderOnly"></div>
                            <div [ngClass]="{
                                    nomenexpandtrue:
                                        treeControl.isExpanded(product),
                                    nomenexpandfalse:
                                        !treeControl.isExpanded(product)
                                }">
                                <div id="node_{{ product.id }}" class="desc">
                                    <!--  <button
                            mat-icon-button
                            matTreeNodeToggle
                            *ngIf="!treeControl.isExpanded(product)"
                            (click)="expand(product)"
                        >
                            <mat-icon>
                                {{ "add_circle_outline" }}
                            </mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            matTreeNodeToggle
                            *ngIf="treeControl.isExpanded(product)"
                            (click)="collapse(product)"
                        >
                            <mat-icon>
                                {{ "remove_circle_outline" }}
                            </mat-icon></button
                        > -->
                                    <a href="" (click)="
                                            $event.preventDefault();
                                            expand(product)
                                        " *ngIf="
                                            product.hasChildren &&
                                            !treeControl.isExpanded(product)
                                        ">
                                        <span class="code treecolapsed">+</span>
                                    </a>

                                    <a href="" (click)="
                                            $event.preventDefault();
                                            collapse(product)
                                        " *ngIf="treeControl.isExpanded(product)">
                                        <span class="code treeexpanded">–</span>
                                    </a>

                                    <a href="" (click)="
                                            $event.preventDefault();
                                            selectProduct(product)
                                        " *ngIf="product.hasChildren !== true">
                                        <span class="code treeleaf product-selectable-row" [ngClass]="{
                                                'treeleaf-selected':
                                                    requestedProduct(product)
                                            }">&nbsp;</span>
                                    </a>
                                    <!-- Product Description Selectable -->
                                    <span *ngIf="product.selectable" (click)="selectProduct(product)"
                                        class="product-desc product-selectable product-selectable-row" [ngClass]="{
                                            'product-requested':
                                                requestedProduct(product),
                                            'product-direct-match':
                                                product.hasMatches === 'DIRECT',
                                            'product-indirect-match':
                                                product.hasMatches === 'INDIRECT'
                                        }">{{ product.description }}
                                        <!-- Product Footnotes --->
                                        <ng-container *ngIf="product.footnotes">
                                            <span *ngFor="
                                                    let footnote of product.footnotes
                                                " class="product-footnote" [matTooltip]="
                                                    footnote.FootnoteDesc
                                                " matTooltipClass="product-footnote-tooltip">({{ footnote.FootnoteCode
                                                }})
                                            </span>
                                        </ng-container>
                                        <!-- Product Footnotes --->
                                    </span>
                                    <!-- /Product Description Selectable -->

                                    <!-- Product Description Non Selectable -->
                                    <span *ngIf="!product.selectable" class="product-desc product-non-selectable"
                                        [ngClass]="{
                                            'product-requested':
                                                requestedProduct(product),
                                            'product-direct-match':
                                                product.hasMatches === 'DIRECT',
                                            'product-indirect-match':
                                                product.hasMatches === 'INDIRECT'
                                        }">{{ product.description }}
                                        <!-- Product Footnotes --->
                                        <ng-container *ngIf="product.footnotes">
                                            <span *ngFor="
                                                    let footnote of product.footnotes
                                                " class="product-footnote" [matTooltip]="
                                                    footnote.FootnoteDesc
                                                " matTooltipClass="product-footnote-tooltip">({{ footnote.FootnoteCode
                                                }})
                                            </span>
                                        </ng-container>
                                        <!-- Product Footnotes --->
                                    </span>
                                    <!-- /Product Description Non Selectable -->

                                    <mat-progress-bar *ngIf="product.isLoading" mode="indeterminate"
                                        class="example-tree-progress-bar"></mat-progress-bar>
                                    <!-- Product Section -->
                                    <div *ngIf="product.section" class="section">
                                        <span class="section-description">{{
                                            product.section.description
                                            }}</span>
                                        <!-- ngIf: product.section.chapterFrom !== product.section.chapterTo -->

                                        <span *ngIf="
                                                product.section.chapterFrom !==
                                                product.section.chapterTo
                                            " class="section-chapters">({{ product.section.chapterFrom }}
                                            -
                                            {{
                                            product.section.chapterTo
                                            }})</span><!-- end ngIf: product.section.chapterFrom !== product.section.chapterTo -->
                                        <!-- ngIf: product.section.chapterFrom===product.section.chapterTo -->
                                        <span *ngIf="
                                                product.section.chapterFrom ===
                                                product.section.chapterTo
                                            " class="section-chapters">({{
                                            product.section.chapterFrom
                                            }})</span><!-- end ngIf: product.section.chapterFrom===product.section.chapterTo -->
                                    </div>
                                    <!-- / Product Section -->

                                    <!-- Product Code Selectable -->
                                    <div class="prod" *ngIf="product.selectable" (click)="selectProduct(product)"
                                        [ngClass]="{
                                            'product-requested':
                                                requestedProduct(product)
                                        }">
                                        <span class="chapter">{{
                                            product.chapter
                                            }}</span>
                                        <span class="heading">{{
                                            product.heading
                                            }}</span>
                                        <span *ngIf="
                                                product.subheading !== '000000'
                                            " class="subheading">{{ product.subheadingStyle }}</span>
                                    </div>
                                    <!-- /Product Code Selectable -->

                                    <!-- Product Code Non - Selectable -->

                                    <div class="prod code-non-selectable" *ngIf="!product.selectable" [ngClass]="{
                                            'product-requested':
                                                requestedProduct(product)
                                        }">
                                        <span class="chapter">{{
                                            product.chapter
                                            }}</span>
                                        <span *ngIf="product.heading !== '00'" class="heading">{{ product.heading
                                            }}</span>
                                        <span *ngIf="
                                                product.subheading !== '000000'
                                            " class="subheading">{{ product.subheadingStyle }}</span>
                                    </div>
                                    <!-- /Product Code Non - Selectable -->
                                    <div class="prod interval code-non-selectable" *ngIf="
                                            !product.selectable &&
                                            !product.code &&
                                            product.intervalMin
                                        " [ngClass]="{
                                            'product-requested':
                                                requestedProduct(product)
                                        }">
                                        <span class="chapter">
                                            {{
                                            product.intervalMinChapter
                                            }}</span>
                                        <span class="heading">
                                            {{
                                            product.intervalMinHeading
                                            }}</span>
                                        <!-- ngIf: product.intervalMinHeading === product.intervalMaxHeading --><span
                                            class="subheading" *ngIf="
                                                product.intervalMinHeading ===
                                                    product.intervalMaxHeading &&
                                                product.intervalMinSubheadingStyle !==
                                                    product.intervalMaxSubheadingStyle
                                            ">{{
                                            product.intervalMinSubheadingStyle
                                            }}</span>

                                        <!-- end ngIf: product.intervalMinHeading === product.intervalMaxHeading -->

                                        <span *ngIf="
                                                product.intervalMin !==
                                                product.intervalMax
                                            ">
                                            -
                                        </span>

                                        <!-- ngIf: product.intervalMinChapter !== product.intervalMaxChapter -->
                                        <span class="chapter" *ngIf="
                                                product.intervalMinChapter !==
                                                product.intervalMaxChapter
                                            ">{{
                                            product.intervalMaxChapter
                                            }}</span>

                                        <!-- ngIf: product.intervalMinHeading !== product.intervalMaxHeading -->

                                        <span class="heading" *ngIf="
                                                product.intervalMinHeading !==
                                                product.intervalMaxHeading
                                            ">{{
                                            product.intervalMaxHeading
                                            }}</span>
                                        <!-- ngIf: product.intervalMinHeading === product.intervalMaxHeading -->
                                        <span class="subheading" *ngIf="
                                                product.intervalMinHeading ===
                                                    product.intervalMaxHeading &&
                                                product.intervalMinSubheadingStyle !==
                                                    product.intervalMaxSubheadingStyle
                                            ">{{
                                            product.intervalMaxSubheadingStyle
                                            }}</span>
                                        <!-- end ngIf: product.intervalMinHeading === product.intervalMaxHeading -->
                                    </div>
                                </div>
                            </div>
                            <ul>
                                <li *ngIf="treeControl.isExpanded(product)">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </li>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                    <!-- /PARENT NODE (HAS CHILDREN) -->
                </mat-tree>
                <!-- /TARIC TREE -->
            </div>
        </div>
    </div>
</div>

<!-- ACTIONS MENU -->
<mat-menu #actionsMenu="matMenu">
    <ng-template matMenuContent let-product="product">
        <taric-actions-menu [product]="product" (setCodeDescription)="setCodeDesc($event)"></taric-actions-menu>
    </ng-template> </mat-menu><!-- /ACTIONS MENU -->