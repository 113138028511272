<div fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div fxLayout="column">
            <span>Υπολογισμος δασμών/φόρων</span>
            <span style="font-size: 14px"><small>{{ subheading }}</small></span>
        </div>
        <div>
            <button *ngIf="showResults" mat-icon-button (click)="onPrint()" matTooltip="Εκτύπωση"
                matTooltipClass="tooltip">
                <mat-icon> print</mat-icon>
            </button>
            <button mat-icon-button (click)="onCloseConfirm()" matTooltip="Κλείσιμο" matTooltipClass="tooltip">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around start" mat-dialog-content
        style="overflow-y: auto; min-height: 100px; height: 100px">
        <ng-container *ngIf="loading">
            <app-tariff-loading style="
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-self: center;
                "></app-tariff-loading>
        </ng-container>
        <ng-container *ngIf="showDocuments">
            <app-tariff-calc-documents-table style="width: 100%" [documents]="documents" [documentsForm]="documentsForm"
                [goodsFormGroup]="goodsFormGroup"></app-tariff-calc-documents-table>
        </ng-container>
        <ng-container *ngIf="showSupUnits">
            <app-tariff-calc-sup-units-table style="width: 100%" [goodsIndex]="goodsIndex"
                [privateControls]="privateControls" [supUnits]="supUnits" [taxAddEleForm]="taxAddEleForm"
                [goodsFormGroup]="goodsFormGroup">
            </app-tariff-calc-sup-units-table>
        </ng-container>
        <ng-container *ngIf="showResults">
            <app-tariff-calculate-results [data]="TariffFinalResponse" style="width: 100%">
            </app-tariff-calculate-results>
        </ng-container>
    </div>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
        <button *ngIf="!formDisabled" tabindex="1" style="width: auto !important" mat-raised-button [ngClass]="color1"
            (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="onCloseConfirm()">
            Ακυρο
        </button>

        <button *ngIf="showDocuments" style="width: auto !important" mat-raised-button
            [disabled]="processing || !documentsForm.valid" [ngClass]="color2" (mouseenter)="changeStyle2($event)"
            (mouseleave)="changeStyle2($event)" (click)="submitDocuments()">
            Συνέχεια

            <mat-icon *ngIf="processing">
                <mat-spinner diameter="20" color="accent"> </mat-spinner>
            </mat-icon>
        </button>

        <button *ngIf="showSupUnits" style="width: auto !important" mat-raised-button
            [disabled]="processing || !taxAddEleForm.valid" [ngClass]="color2" (mouseenter)="changeStyle2($event)"
            (mouseleave)="changeStyle2($event)" (click)="submitSupUnits()">
            Συνέχεια

            <mat-icon *ngIf="processing">
                <mat-spinner diameter="20" color="accent"> </mat-spinner>
            </mat-icon>
        </button>

        <button *ngIf="showResults && !formDisabled" style="width: auto !important" mat-raised-button [ngClass]="color2"
            (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onUpdateTariff()">
            Εφαρμογή Δασμών
        </button>
        <button *ngIf="formDisabled" style="width: auto !important" mat-raised-button [ngClass]="color2"
            (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onCloseConfirm()">
            Επιστροφή
        </button>

    </mat-dialog-actions>
</div>