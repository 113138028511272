import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ExtCustomFunctions } from "app/main/main-building-functions/ext-custom-functions.service";
import { ExchangeRate } from "app/model/api-model";
import { NgxTippyService } from "ngx-tippy-wrapper";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ExchangeRateValidService {

    private dialogAction: BehaviorSubject<'update' | 'warn' | 'info'>;
    private warnSignShow: BehaviorSubject<boolean>;
    private confirmMessage: BehaviorSubject<string>;
    private warningTitle: BehaviorSubject<string>;

    dialogAction$: Observable<'update' | 'warn' | 'info'>;
    warnSignShow$: Observable<boolean>;
    confirmMessage$: Observable<string>;
    warningTitle$: Observable<string>;

    constructor(private cf: ExtCustomFunctions, private tippyService: NgxTippyService) {

        this.dialogAction = new BehaviorSubject(null);
        this.warnSignShow = new BehaviorSubject(false);
        this.confirmMessage = new BehaviorSubject('');
        this.warningTitle = new BehaviorSubject('');

        this.dialogAction$ = this.dialogAction.asObservable();
        this.warnSignShow$ = this.warnSignShow.asObservable();
        this.confirmMessage$ = this.confirmMessage.asObservable();
        this.warningTitle$ = this.warningTitle.asObservable();
    }


    isExchangeRateValid(validFromDate: string, validUntilDate: string): boolean {

        //EUR does not have a validUntil date
        if (!validUntilDate) {
            return true;
        }

        const today = new Date();
        let validFrom: Date;
        let validUntil: Date;

        //if validUntilDate format is 'dd/mm/yyyy'
        if (validUntilDate.includes('/')) {
            const [dayFrom, monthFrom, yearFrom] = validFromDate.split("/");
            validFrom = new Date(+yearFrom, +monthFrom - 1, +dayFrom);

            const [dayUntil, monthUntil, yearUntil] = validUntilDate.split("/");
            validUntil = new Date(+yearUntil, +monthUntil - 1, +dayUntil);
        }
        // format is yyyy-mm-dd
        else {
            validFrom = new Date(validFromDate);
            validUntil = new Date(validUntilDate);

        }
        console.log('exchangeRatex isExchangeRateValid', today >= validFrom && today <= validUntil);
        return today >= validFrom && today <= validUntil;
    }

    checkForWarning(exchangeRate: ExchangeRate, validFrom: string, validUntil: string, preventAnyChange: boolean, control: FormControl, changedManually: FormControl) {
        console.log('exchangeRatex ExchangeRateValidService checkForWarning')

        //check if the exchange rate is valid
        const isDateInRange = this.isExchangeRateValid(validFrom, validUntil);
        //check if retrievedRate is different from currentRate
        const doRatesMatch = exchangeRate.retrievedRate === exchangeRate.currentRate;



        // Rates don't match, retrieved rates are invalid, rate is manually changed
        // inform user it is manually changed
        //This is the case where we fail to provide valid exchange rates and user has manually set an exchange rate
        if (!doRatesMatch && !isDateInRange && changedManually.value) {
            console.log("exchangeRateValidator case 1")

            if (!preventAnyChange) {
                console.log("exchangeRateValidator case 1.1 setRetrievedRate")

                this.setRetrievedRate(exchangeRate, control, changedManually)

            }

            this.infoMessage(exchangeRate, validFrom, validUntil)

            return;
        }



        // Rates don't match, retrieved rates are invalid, rate is not manually changed

        if (!doRatesMatch && !isDateInRange && !changedManually.value) {
            console.log("exchangeRateValidator case 2")
            this.setRetrievedRate(exchangeRate, control, changedManually)
            this.warnMessage(exchangeRate, validFrom, validUntil)
            return;
        }




        // Rates don't match, retrieved rates are valid (either manually changed or not)
        //This is the case where a draft was recalled in a date where the exchangeRates have changed and an old value has been saved in the draft.
        if (!doRatesMatch && isDateInRange) {
            console.log("exchangeRateValidator case 3")

            this.updateMessage(exchangeRate, validFrom, validUntil)

            return;

        }



        // Rates match, retrieved rates are invalid, rate is manually changed
        // This could happen if the rate is set automatically and we mesh with the value,
        //thatr the control becomes dirty, but we don't change the value
        if (doRatesMatch && !isDateInRange && changedManually.value) {
            console.log("exchangeRateValidator case 4")
            this.warnMessage(exchangeRate, validFrom, validUntil)
            return;

        }


        // Rates match, retrieved rates are invalid, rate is not manually changed                //This is the case where exchangeRates have not been updated by our side and we provide invalid exchangeRates to the user

        if (doRatesMatch && !isDateInRange && !changedManually.value) {
            console.log("exchangeRateValidator case 5")
            this.setRetrievedRate(exchangeRate, control, changedManually)
            this.warnMessage(exchangeRate, validFrom, validUntil)
            return;

        }

        /// Rates match, retrieved rates are valid (either manually changed or not)
        // Do nothing
        if (doRatesMatch && isDateInRange) {
            console.log("exchangeRateValidator case 6")
            this.setRetrievedRate(exchangeRate, control, changedManually)
            return;
        }



    }

    setRetrievedRate(value: ExchangeRate, control: FormControl, changedManually: FormControl) {

        console.log("exchangeRatex ExchangeRateValidService setRetrievedRate")


        if (value.retrievedRate) {
            control.setValue(this.cf.formatDecimalString(value.retrievedRate, 3, false), { emitEvent: false, onlySelf: true });
            control.markAsPristine()
        } else if (!value.retrievedRate) {
            control.setValue(null, { emitEvent: false, onlySelf: true });
        }
        changedManually.setValue(false)

    }



    updateMessage(exchangeRate: ExchangeRate, validFrom: string, validUntil: string) {

        console.log("exchangeRatex ExchangeRateValidService updateMessage")

        this.dialogAction.next('update');
        this.updateWarnSign(true);
        this.warningTitle.next('Αλλαγή Τρέχουσας Ισοτιμίας');
        this.confirmMessage.next(this.createMessage('Η τρέχουσα ισοτιμία εχει αλλάξει. Nα γινει εφαρμογή της νέας ισοτιμίας;', exchangeRate, validFrom, validUntil));

        this.showMessage('warningSignTippy')

    }

    warnMessage(exchangeRate, validFrom: string, validUntil: string) {
        console.log("exchangeRatex ExchangeRateValidService warnMessage")

        this.dialogAction.next('warn');
        this.updateWarnSign(true);
        this.warningTitle.next('Μη έγκυρη Ισοτιμία');
        this.confirmMessage.next(this.createMessage(`Η ημερομηνία ισχύος της ισοτιμίας έχει παρέλθει. <p> Παρακαλώ ανανεώστε την τιμή χειροκίνητα.</p>`, exchangeRate, validFrom, validUntil));

        this.showMessage('warningSignTippy')

    }

    infoMessage(exchangeRate, validFrom: string, validUntil: string) {
        console.log("exchangeRatex ExchangeRateValidService infoMessage")

        this.dialogAction.next('info');
        this.updateWarnSign(true);
        this.warningTitle.next('Χειροκίνητη Ισοτιμία');
        this.confirmMessage.next(this.createMessage(`Δεν βρέθηκε έγκυρη ισοτιμία για το νόμισμα. Η ισοτιμία έχει οριστεί χειροκίνητα. <p> Παρακαλώ ελέγξτε την τιμή.</p>`, exchangeRate, validFrom, validUntil));

        this.showMessage('warningSignTippy')

    }

    createMessage(confirmMessage: string, exchangeRate, validFrom: string, validUntil: string) {

        return `${confirmMessage}
    <p style="font-size: 15px !important;
    font-weight: bold !important;">  ${exchangeRate.currency}: ${this.cf.formatDecimalString(exchangeRate.retrievedRate, 3, false)}</p>
    <p>
    <span>Ισχύς από: ${validFrom}</span><br>
    <span>Ισχύς εώς: ${validUntil}</span>
    </p>
    `;

    }
    showMessage(tippyId: string) {
        console.log("exchangeRatex ExchangeRateValidService showMessage")
        /*     setTimeout(() => {
                this.tippyService.getInstance(tippyId)?.show()
            }, 500)
     */
    }

    updateWarnSign(value: boolean) {
        console.trace("exchangeRatex ExchangeRateValidService updateWarnSign", value)
        this.warnSignShow.next(value)
    }

}