import { Injectable } from "@angular/core"
import { CarPriceRange, Co2EmissionCode, ExciseHelpersEuroClass, HybridReductionRate } from "app/main/apps/excise-helper-tables/vehicles/_models/vehicles.interfaces"
import { genericItem } from "app/model/api-model"
import { ApiService, ApiEndPoints } from "app/services/api.service"
import { BehaviorSubject, Observable, Subject, take, tap } from "rxjs"

@Injectable()
export class VehiclesQueriesService {

    private priceRanges: BehaviorSubject<CarPriceRange[]>

    private hybridRates: BehaviorSubject<HybridReductionRate[]>
    private euroClassOptions: BehaviorSubject<ExciseHelpersEuroClass[]>
    private chassisTypeOptions: BehaviorSubject<genericItem[]>

    priceRanges$: Observable<CarPriceRange[]>
    hybridRates$: Observable<HybridReductionRate[]>
    euroClassOptions$: Observable<ExciseHelpersEuroClass[]>
    chassisTypeOptions$: Observable<genericItem[]>

    carChassisOptions: genericItem[] = [];

    destroy$ = new Subject<void>();

    initialPriceRanges: CarPriceRange[] = []

    constructor(
        private dbQuery: ApiService) {


        this.priceRanges = new BehaviorSubject([])
        this.priceRanges$ = this.priceRanges.asObservable()

        this.hybridRates = new BehaviorSubject([])
        this.hybridRates$ = this.hybridRates.asObservable()

        this.euroClassOptions = new BehaviorSubject([])
        this.euroClassOptions$ = this.euroClassOptions.asObservable()

        this.chassisTypeOptions = new BehaviorSubject([])
        this.chassisTypeOptions$ = this.chassisTypeOptions.asObservable()
    }

    getVehicleTypeOptions() {
        return this.dbQuery.get_options(ApiEndPoints.eidi_oximaton).pipe(take(1), tap((res) => {
            console.log("CarsService getVehicleTypeOptions", this.getVehicleTypeOptions)
        }))
    }
    getCarEuroClassOptions() {
        return this.dbQuery.get_options(ApiEndPoints.efk_car_euro_class).pipe(take(1), tap((res) => {
            this.euroClassOptions.next(res)
            console.log("CarsService euroClassOptions", this.euroClassOptions)
        }))
    }

    getTruckEuroClassOptions() {
        return this.dbQuery.get_options(ApiEndPoints.efk_truck_euro_class).pipe(take(1), tap((res) => {
            this.euroClassOptions.next(res)
            console.log("CarsService euroClassOptions", this.euroClassOptions)
        }))
    }

    getCarTypeOptions() {

        return this.dbQuery.get_options(ApiEndPoints.efk_car_types).pipe(take(1), tap((res) => {
            this.chassisTypeOptions.next(res);
            this.carChassisOptions = res
            console.log("CarsService getCarTypeOptions", this.carChassisOptions)
        }))
    }
    getReductionRates(monthsTotal: number, chassisType: string): Observable<number> {

        return this.dbQuery.getCarAgeReduction(monthsTotal, chassisType).pipe(take(1), tap((res) => {
            console.log("CarsService getReductionRates", res)

        }))
    }

    getAddTaricCode(co2Emissions: number): Observable<Co2EmissionCode> {
        return this.dbQuery.getCo2EmissionsCode(co2Emissions).pipe(take(1), tap((res) => {
            console.log("CarsService getAddTaricCode", res)
        }))

    }

    getPriceRanges(): Observable<CarPriceRange[]> {
        return this.dbQuery.getCarPriceRanges().pipe(take(1), tap((res) => {
            console.log("CarsService getPriceRanges", res)
            this.initialPriceRanges = res
            this.updatePriceRanges(res)
        }))

    }
    getHybridReductionRates(co2Emissions: number): Observable<number> {
        return this.dbQuery.getHybridReductionRate(co2Emissions).pipe(take(1), tap((res) => {
            console.log("CarsService getHybridReductionRates", res)
        }))


    }

    updatePriceRanges(priceRanges: CarPriceRange[]) {
        this.priceRanges.next(priceRanges)
    }
    updateChassisTypeOptions(chassisTypeOptions: genericItem[]) {
        this.chassisTypeOptions.next(chassisTypeOptions)
    }

    updateEuroClassOptions(euroClassOptions: ExciseHelpersEuroClass[]) {
        this.euroClassOptions.next(euroClassOptions)
    }
    setInitialPriceRanges() {
        console.log("CarsService setInitialPriceRanges", this.initialPriceRanges)
        this.updatePriceRanges(this.initialPriceRanges)
    }

}