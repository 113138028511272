import { Declarant } from 'app/core/user/user.model';
export class eAitisiTrarepModel {
    NamTRE1?: string;
    VatNumTRE?: string;
    DeclarantObject?: Declarant;

    constructor(value?) {
        this.NamTRE1 = value?.NamTRE1 || null;
        this.VatNumTRE = value?.VatNumTRE || null;
        this.DeclarantObject = value?.DeclarantObject;
    }
}
