<ng-container *ngIf="dilosiForm$ | async as dilosiForm">
    <div class="distillation-licence-app app_Grid">
        <div class="app_img">
            <distillation-licence-app-svg></distillation-licence-app-svg>
        </div>



        <div id="period" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="period" svgDocFocus="svgFocus" inputField="input"
                classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="defkMRN" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="defkMRN" svgDocFocus="svgFocus" inputField="input"
                classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="customsName" class="declDiv">
            <app-customs-control [customsGroup]="dilosiForm" classes="s-l s-full" [setDefault]="true" [trapFocus]="true"
                controlName="customsName" [showAlterName]="true" [outputName]="true" (keyDown.Escape)="$event.stopPropagation();
        $event.preventDefault();"></app-customs-control>
        </div>
        <div id="producerName" class="declDiv">
            <app-generic-input [form]="producerDetails" controlName="producerName" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="producerId" class="declDiv">
            <app-generic-input [form]="producerDetails" controlName="producerId" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="producerStreet" class="declDiv">
            <app-generic-input [form]="producerDetails" controlName="producerStreet" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="producerStreetNumber" class="declDiv">
            <app-generic-input [form]="producerDetails" controlName="producerStreetNumber" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="producerPhone" class="declDiv">
            <app-generic-input [form]="producerDetails" controlName="producerPhone" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="producerEmail" class="declDiv">
            <app-generic-input [form]="producerDetails" controlName="producerEmail" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full"></app-generic-input>
        </div>



        <div id="startHours" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="startHours" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="startDate" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="startDate" svgDocFocus="svgFocus" inputField="input"
                classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="endHours" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="endHours" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="endDate" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="endDate" svgDocFocus="svgFocus" inputField="input"
                classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>





        <div id="ownStems-quantity" class="declDiv">
            <app-generic-input [form]="ownStems" controlName="quantity" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="ownStems-litresPureAlcohol" class="declDiv">
            <app-generic-input [form]="ownStems" controlName="litresPureAlcohol" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="ownStems-litresGross" class="declDiv">
            <app-generic-input [form]="ownStems" controlName="litresGross" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="ownStems-alcoholicTitle" class="declDiv">
            <app-generic-input [form]="ownStems" controlName="alcoholicTitle" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>



        <div id="wineryReturnedWith" class="declDiv">
            <app-generic-input [form]="winery" controlName="wineryReturnedWith" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="wineryName" class="declDiv">
            <app-generic-input [form]="winery" controlName="wineryName" svgDocFocus="svgFocus" inputField="input"
                classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="wineryAfm" class="declDiv">
            <app-generic-input [form]="winery" controlName="wineryAfm" svgDocFocus="svgFocus" inputField="input"
                classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="wineryDocumentNumber" class="declDiv">
            <app-generic-input [form]="winery" controlName="wineryDocumentNumber" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="returnedStems-quantity" class="declDiv">
            <app-generic-input [form]="returnedStems" controlName="quantity" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="returnedStems-litresPureAlcohol" class="declDiv">
            <app-generic-input [form]="returnedStems" controlName="litresPureAlcohol" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="returnedStems-litresGross" class="declDiv">
            <app-generic-input [form]="returnedStems" controlName="litresGross" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="returnedStems-alcoholicTitle" class="declDiv">
            <app-generic-input [form]="returnedStems" controlName="alcoholicTitle" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>


        <div id="otherRawMaterials" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="otherRawMaterials" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="grapes-quantity" class="declDiv">
            <app-generic-input [form]="grapes" controlName="quantity" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="grapes-litresPureAlcohol" class="declDiv">
            <app-generic-input [form]="grapes" controlName="litresPureAlcohol" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="grapes-litresGross" class="declDiv">
            <app-generic-input [form]="grapes" controlName="litresGross" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="grapes-alcoholicTitle" class="declDiv">
            <app-generic-input [form]="grapes" controlName="alcoholicTitle" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="boilerNumber" class="declDiv">
            <app-generic-input [form]="boilerInfo" controlName="boilerNumber" svgDocFocus="svgFocus" inputField="input"
                classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="boilerCapacity" class="declDiv">
            <app-generic-input [form]="boilerInfo" controlName="boilerCapacity" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="boilerOwnerName" class="declDiv">
            <app-generic-input [form]="boilerInfo" controlName="boilerOwnerName" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="boilerOwnerId" class="declDiv">
            <app-generic-input [form]="boilerInfo" controlName="boilerOwnerId" svgDocFocus="svgFocus" inputField="input"
                classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="boilerRegistrationNumber" class="declDiv">
            <app-generic-input [form]="boilerInfo" controlName="boilerRegistrationNumber" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="boilerGeoLocation" class="declDiv">
            <app-generic-input [form]="boilerInfo" controlName="boilerGeoLocation" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="harvestDeclaration" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="harvestDeclaration" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="previousQuantityLpa" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="previousQuantityLpa" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="previousQuantityGross" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="previousQuantityGross" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="previousQuantityAlcoholTitle" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="previousQuantityAlcoholTitle" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="previousQuantityLocation" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="previousQuantityLocation" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="previousQuantityLpaRights" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="previousQuantityLpaRights" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="previousQuantityGrossRights" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="previousQuantityGrossRights" svgDocFocus="svgFocus"
                inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="previousQuantityAlcoholTitleRights" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="previousQuantityAlcoholTitleRights"
                svgDocFocus="svgFocus" inputField="input" classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>
        <div id="previousQuantityLocationRights" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="previousQuantityLocationRights" svgDocFocus="svgFocus"
                inputField="input" classes="s-l s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="unsealDate" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="unsealDate" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>

        <div id="date" class="declDiv">
            <app-generic-input [form]="dilosiForm" controlName="date" svgDocFocus="svgFocus" inputField="input"
                classes="s-c s-full" [upperCase]="true"></app-generic-input>
        </div>

    </div>


    <div style="display:none">

        <app-distillation-licence-app-print></app-distillation-licence-app-print>

    </div>

</ng-container>