import { AbstractControl, FormGroup, FormArray } from "@angular/forms";
import { SubmittingOperatorForm } from "app/main/apps/excise/dao/_models/submittingOperator/submittingOperator-form.model";
import { SubmittingOperatorModel } from "app/main/apps/excise/dao/_models/submittingOperator/submittingOperator.model";
import { TaxesDeclarationForm } from "../../taxesDeclaration/taxes-declaration-form.model";
import { TaxesDeclarationModel } from "../../taxesDeclaration/taxes-declaration.model";
import { TaxesDeclarationConsigneeForm } from "../../taxesDeclarationConsigee/taxes-declaration-consignee-form.model";
import { TaxesDeclarationConsigneeModel } from "../../taxesDeclarationConsigee/taxes-declaration-consignee.model";
import { TaxesDeclarationConsignorForm } from "../../taxesDeclarationConsignor/taxes-declaration-consignor-form.model";
import { TaxesDeclarationConsignorModel } from "../../taxesDeclarationConsignor/taxes-declaration-consignor.model";
import { TaxesObligeeForm } from "../../taxesObligee/taxes-obligee-form.model";
import { TaxesObligeeModel } from "../../taxesObligee/taxes-obligee.model";
import { TaxesRowForm } from "../../taxesRow/taxes-row-form.model";
import { TaxesRowModel } from "../../taxesRow/taxes-row.model";
import { DeclarationOfExciseTaxesRegistrationType, DEFKBodyModel } from "./body.model";


export class DEFKBodyForm {
    [key: string]: AbstractControl;
    DeclarationOfExciseTaxesRegistration: FormGroup;

    constructor(value: DEFKBodyModel) {
        this.DeclarationOfExciseTaxesRegistration = new FormGroup(new DeclarationOfExciseTaxesRegistrationForm(new DeclarationOfExciseTaxesRegistrationType(value.DeclarationOfExciseTaxesRegistration)));

    }
}


export class DeclarationOfExciseTaxesRegistrationForm {
    [key: string]: AbstractControl;

    SubmittingOperator: FormGroup;
    ExciseTaxesDeclaration: FormGroup;
    ExciseTaxesDeclarationConsignor: FormGroup;
    ExciseTaxesObligee: FormGroup;
    ExciseTaxesDeclarationConsignee: FormGroup;
    ExciseTaxesRow: FormArray;


    constructor(value: DeclarationOfExciseTaxesRegistrationType) {

        this.SubmittingOperator = new FormGroup(new SubmittingOperatorForm(new SubmittingOperatorModel(value.SubmittingOperator)));
        this.ExciseTaxesDeclaration = new FormGroup(new TaxesDeclarationForm(new TaxesDeclarationModel(value.ExciseTaxesDeclaration)));
        this.ExciseTaxesDeclarationConsignor = new FormGroup(new TaxesDeclarationConsignorForm(new TaxesDeclarationConsignorModel(value.ExciseTaxesDeclarationConsignor)));
        this.ExciseTaxesObligee = new FormGroup(new TaxesObligeeForm(new TaxesObligeeModel(value.ExciseTaxesObligee)));
        this.ExciseTaxesDeclarationConsignee = new FormGroup(new TaxesDeclarationConsigneeForm(new TaxesDeclarationConsigneeModel(value.ExciseTaxesDeclarationConsignee)));
        this.ExciseTaxesRow = new FormArray([]);

        if (value.ExciseTaxesRow) {
            value.ExciseTaxesRow.forEach((rows: TaxesRowModel) => {
                this.ExciseTaxesRow.push(new FormGroup(new TaxesRowForm(new TaxesRowModel(rows))));
            });
        }
        else {
            this.ExciseTaxesRow.push(new FormGroup(new TaxesRowForm(new TaxesRowModel())));
        }


    }
}
