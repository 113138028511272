import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { BusinessModel } from 'app/main/businesses/invoices/_models/business/business.model';
import { InvoiceAccountModel } from 'app/main/businesses/invoices/_models/invoice-account/invoice-account.model';
import { TransactionForm } from 'app/main/businesses/transactions/_models/transaction/transaction-form.model';
import { TransactionsService } from 'app/main/businesses/transactions/transactions.service';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { ApiService } from 'app/services/api.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil, filter } from 'rxjs';

export interface TransactionDialogData {
    action: 'new' | 'edit';
    business: BusinessModel;
    accounts: InvoiceAccountModel[];
    direction: 'DEPOSIT' | 'WITHDRAWAL';
}

@Component({
    selector: 'app-transactions-form',
    templateUrl: './transactions-form.component.html',
    styleUrls: ['./transactions-form.component.scss']
})
export class TransactionsFormComponent implements OnInit {

    private _destroy: Subject<void> = new Subject<void>();

    action: string;
    class: string;
    fromDeclaration: boolean;

    transactionForm: FormGroup<TransactionForm>;
    anchorLineItem: FormGroup;
    anchorCategory: FormGroup;
    lineItems: FormArray;

    direction: FormControl;
    account: FormControl;
    category: FormControl;
    vendor: FormControl;
    customer: FormControl;

    showVendor = false;
    showCustomer = false;


    customers: Contact[] = [];
    vendors: Contact[] = [];


    dialogTitle: string;
    status = -1;
    IcisNetChecked: boolean;
    addChecked: boolean;

    eoriErrorMessage: string;

    transactionDate: number;

    accounts: InvoiceAccountModel[] = [];
    accountConfig: AutoCompConfigValues = {
        display: { values: ['account_name'] },
        menuOptions: { options: ['account_name'] },
        filterBy: ['account_name']

    };

    customerConfig: AutoCompConfigValues = {
        display: { values: ['fullname'] },
        menuOptions: { options: ['fullname'] }
    };



    constructor(
        public matDialogRef: MatDialogRef<TransactionsFormComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: TransactionDialogData,
        private transactionsService: TransactionsService,
        private cd: ChangeDetectorRef,
        private toastrService: ToastrService,
        private mainBroadcaster: MainBroadcasterService,
    ) {


        // Set the defaults
        this.action = this._data.action;
        this.accounts = this._data.accounts;

        this.direction = new FormControl(this._data.direction);
        this.account = new FormControl(this._data.accounts[0]);
        this.category = new FormControl();
        this.customer = new FormControl();
        this.vendor = new FormControl();

        console.log('transactionForm: ', this.transactionForm);
        this.transactionsService.createTransactionForm();

    }

    ngOnInit() {
        this.transactionsService.transactionForm.pipe(takeUntil(this._destroy)).subscribe(transactionForm => {
            this.transactionForm = transactionForm;
            this.anchorLineItem = transactionForm.get('anchorLineItem') as FormGroup;
            this.anchorCategory = this.anchorLineItem.get('category') as FormGroup;
            console.log("ANCHORLINEITEM category", this.anchorCategory.value)
            console.log("ANCHORLINEITEM accountId", this.anchorCategory.get('accountId').value)

            this.lineItems = transactionForm.get('lineItems') as FormArray;

            this.transactionDate = Math.floor((new Date().getTime()) / 1000);

            this.transactionsService.setAnchorLineItem(this.anchorLineItem, this._data.direction, 0, this._data.accounts[0])

            this.cd.detectChanges()

            this.valueChanges()
        })

    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._destroy.next();
        this._destroy.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    valueChanges() {

        this.account.valueChanges.pipe(filter(a => Boolean(a)), takeUntil(this._destroy)).subscribe((account: InvoiceAccountModel) => {
            this.anchorLineItem.patchValue({
                category: {
                    accountId: account.id
                }
            })
        })

        this.direction.valueChanges.pipe(takeUntil(this._destroy)).subscribe(direction => {
            const itemType = direction === 'DEPOSIT' ? 'DEBIT' : 'CREDIT';

            this.anchorLineItem.patchValue({
                itemType
            })
        })
    }
    createTransaction() {
        console.log('CreateTransaction', this.transactionForm.getRawValue());
        this.matDialogRef.close({
            transactionType: this.class,
            transactionForm: this.transactionForm.getRawValue()
        });
    }

    updateTransaction() {
        console.log('UpdateTransaction', this.transactionForm.getRawValue());
        this.matDialogRef.close(['save', this.class, this.transactionForm])

        // this.transactionsService.updateTransaction(this.transactionForm.getRawValue(), this.class).pipe(take(1)).subscribe();;
    }
    deleteTransaction() {
        this.matDialogRef.close(['delete', this.class, this.transactionForm])
    }

    addCustomer() {
        this.showCustomer = true
    }
    removeCustomer() {
        this.showCustomer = false
    }

    addVendor() {
        this.showVendor = true;
    }
    removeVendor() {
        this.showVendor = false;
    }


}