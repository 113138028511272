<table [formGroup]="documentsForm">
    <tbody>
        <tr class="document" *ngFor="let item of documents">
            <mat-card>
                <div class="header">
                    {{ item.header }}
                    <ng-container *ngFor="let link of item.headerLinks">
                        <span class="header-footnote" [matTooltip]="link | codeToDesc:item.footnotes"
                            matTooltipClass="tooltip-large">{{link}}</span>
                        <span>&nbsp;</span>
                    </ng-container>
                </div>
                <div class="subheader">
                    {{ item.subheader }}
                </div>
                <div class="options">
                    <table>
                        <tbody>
                            <mat-radio-group class="example-radio-group" formControlName="{{ item.groupName }}">
                                <tr *ngFor="let option of item.options" (click)="checkRadioValue(option)">
                                    <td>
                                        <mat-radio-button class="example-radio-button" [value]="option.id"
                                            (click)="checkRadioValue(option)">
                                        </mat-radio-button>
                                    </td>
                                    <td [content]="option.text" boldParenthesis></td>
                                </tr>
                            </mat-radio-group>
                        </tbody>
                    </table>
                </div>
            </mat-card>
        </tr>
    </tbody>
</table>