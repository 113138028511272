import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { PrintSettingsService } from '../print-settings.service';
import { PrintSettingsModel } from '../_models/print-settings.model';

@Component({
    selector: 'app-print-settings-slider',
    templateUrl: './print-settings-slider.component.html',
    styleUrls: ['./print-settings-slider.component.scss']
})
export class PrintSettingsSliderComponent implements OnInit {
    @Input() DocumentSettings: FormGroup;

    autoTicks = true;
    disabled = false;
    invert = false;
    max = 10;
    min = -10;
    showTicks = true;
    step = 0.5;
    thumbLabel = true;
    vertical = false;

    title: string;

    get tickInterval(): number | 'auto' {
        return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
    }
    set tickInterval(value) {
        this._tickInterval = coerceNumberProperty(value);
    }
    private _tickInterval = 1;

    private _destroy: Subject<void> = new Subject<void>();
    constructor(private printSettingsService: PrintSettingsService) { }

    ngOnInit(): void {
        this.title = this.DocumentSettings.get('title').value;

        this.DocumentSettings.valueChanges
            .pipe(
                debounceTime(500),
                switchMap((value: PrintSettingsModel) => this.printSettingsService.saveSettings(value)),
                takeUntil(this._destroy)
            )
            .subscribe();
    }
    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._destroy.next();
        this._destroy.complete();
    }
}
