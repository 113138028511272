import { FormControl, AbstractControl, FormGroup } from '@angular/forms';
import { CustomsControlModel, StateResponseModel } from './state-response.model';

export class StateResponseForm {
    [key: string]: AbstractControl;

    FunctionalErrors: FormControl;
    acceptanceDate: FormControl;
    issuingDate: FormControl;
    exitDate: FormControl;
    underControl: FormControl;
    underControlDesc: FormControl;
    underControlInfo: FormControl;
    customsControl: FormGroup;
    controlResDesc: FormControl;
    controlResInd: FormControl;
    nonExitExpRespDateLim: FormControl;
    nonExitExpReqDate: FormControl;
    nonExitCustomsRef: FormControl;
    nonExitInfoAvail: FormControl;
    nonExitExitDate: FormControl;
    nonExitDateOfInfoReceived: FormControl;
    cancelDecision: FormControl;
    cancelReqDate: FormControl;
    cancelInitByCustoms: FormControl;
    cancelDecDate: FormControl;
    cancelReason: FormControl;
    deteAmountOfTax: FormControl;
    rejectionDate: FormControl;
    rejectionReason: FormControl;
    amendAcceptanceDate: FormControl;
    amendRejectionDate: FormControl;
    amendRejectionMotivationCode: FormControl;
    amendRejectionMotivationText: FormControl;
    actionToBeTaken: FormControl;
    dateOfRelease: FormControl;
    totalTaxesAmount: FormControl;
    additionalDeclDate: FormControl;
    info: FormControl;
    daoReceivedDate: FormControl;

    payOrderId: FormControl;
    payOrderAmount: FormControl;
    paymentDueDate: FormControl;
    paymentOrderType: FormControl;


    constructor(controls: StateResponseModel) {
        this.FunctionalErrors = new FormControl(controls.FunctionalErrors);
        this.acceptanceDate = new FormControl(controls.acceptanceDate);
        this.issuingDate = new FormControl(controls.issuingDate);
        this.exitDate = new FormControl(controls.exitDate);
        this.underControl = new FormControl(controls.underControl);
        this.underControlDesc = new FormControl(controls.underControlDesc);
        this.underControlInfo = new FormControl(controls.underControlInfo);
        this.customsControl = new FormGroup(new CustomsControlForm(new CustomsControlModel(controls.customsControl)));
        this.controlResDesc = new FormControl(controls.controlResDesc);
        this.controlResInd = new FormControl(controls.controlResInd);
        this.nonExitExpRespDateLim = new FormControl(
            controls.nonExitExpRespDateLim
        );
        this.nonExitExpReqDate = new FormControl(controls.nonExitExpReqDate);
        this.nonExitCustomsRef = new FormControl(controls.nonExitCustomsRef);
        this.nonExitInfoAvail = new FormControl(controls.nonExitInfoAvail);
        this.nonExitExitDate = new FormControl(controls.nonExitExitDate);
        this.nonExitDateOfInfoReceived = new FormControl(
            controls.nonExitDateOfInfoReceived
        );
        this.cancelDecision = new FormControl(controls.cancelDecision);
        this.cancelReqDate = new FormControl(controls.cancelReqDate);
        this.cancelInitByCustoms = new FormControl(controls.cancelInitByCustoms);
        this.cancelDecDate = new FormControl(controls.cancelDecDate);
        this.cancelReason = new FormControl(controls.cancelReason);
        this.deteAmountOfTax = new FormControl(controls.deteAmountOfTax);
        this.rejectionDate = new FormControl(controls.rejectionDate);
        this.rejectionReason = new FormControl(controls.rejectionReason);
        this.amendAcceptanceDate = new FormControl(controls.amendAcceptanceDate);
        this.amendRejectionDate = new FormControl(controls.amendRejectionDate);
        this.amendRejectionMotivationCode = new FormControl(
            controls.amendRejectionMotivationCode
        );
        this.amendRejectionMotivationText = new FormControl(
            controls.amendRejectionMotivationText
        );
        this.actionToBeTaken = new FormControl(controls.actionToBeTaken);
        this.dateOfRelease = new FormControl(controls.dateOfRelease);
        this.totalTaxesAmount = new FormControl(controls.totalTaxesAmount);
        this.additionalDeclDate = new FormControl(controls.additionalDeclDate);
        this.info = new FormControl(controls.info);
        this.daoReceivedDate = new FormControl(controls.daoReceivedDate);

        this.payOrderId = new FormControl(controls.payOrderId);
        this.payOrderAmount = new FormControl(controls.payOrderAmount);
        this.paymentDueDate = new FormControl(controls.paymentDueDate);
        this.paymentOrderType = new FormControl(controls.paymentOrderType);
    }
}


export class CustomsControlForm {

    controlDate: FormControl;
    controlledBy: FormControl;
    controlResult: FormControl;
    controlDateLimit: FormControl;
    seals: FormControl;
    sealNumber: FormControl;

    constructor(controls: CustomsControlModel) {
        this.controlDate = new FormControl(controls.controlDate);
        this.controlledBy = new FormControl(controls.controlledBy);
        this.controlResult = new FormControl(controls.controlResult);
        this.controlDateLimit = new FormControl(controls.controlDateLimit);
        this.seals = new FormControl(controls.seals);
        this.sealNumber = new FormControl(controls.sealNumber);
    }
}