import {
    AbstractControl, FormControl, FormArray,
    FormGroup, Validators
} from "@angular/forms";
import { TsipouraCalculationDataModel, TsipouraModel, TsipouraTotalsModel } from "app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura.model";
import { TsipouraParameters } from "app/main/apps/excise-helper-tables/tsipoura/tsipoura-defk-create.service";

export class TsipouraForm {
    [key: string]: AbstractControl;
    obligee: FormControl;
    boilerOwner: FormControl;
    agm: FormControl;
    boilerCapacity: FormControl;
    boilerRegistrationNumber: FormControl;
    boilerGeoLocation: FormControl;
    startDate: FormControl;
    endDate: FormControl;
    workHoursPerDay: FormControl;
    tsipouraCalculationData: FormArray;
    tsipouraTotals: FormGroup;
    constructor(params: TsipouraParameters, value?: TsipouraModel) {

        this.obligee = new FormControl(value.obligee, Validators.required);
        this.boilerOwner = new FormControl(value.boilerOwner, Validators.required);
        this.agm = new FormControl(value.agm, { updateOn: 'blur', validators: [Validators.required] });
        this.boilerCapacity = new FormControl(value.boilerCapacity, { updateOn: 'blur', validators: [Validators.required] });
        this.boilerRegistrationNumber = new FormControl(value.boilerRegistrationNumber, { updateOn: 'blur', validators: [Validators.required] });
        this.boilerGeoLocation = new FormControl(value.boilerGeoLocation, { updateOn: 'blur', validators: [Validators.required] });
        this.startDate = new FormControl(value.startDate, { updateOn: 'blur' });
        this.endDate = new FormControl(value.endDate);
        this.workHoursPerDay = new FormControl(value.workHoursPerDay, { validators: [Validators.required] });

        this.tsipouraCalculationData = new FormArray([]);
        this.tsipouraTotals = new FormGroup(new TsipouraTotalsForm(new TsipouraTotalsModel(value.tsipouraTotals)));

        if (value.tsipouraCalculationData) {
            value.tsipouraCalculationData.forEach((data: TsipouraCalculationDataModel) => {
                this.tsipouraCalculationData.push(new FormGroup(new TsipouraCalculationDataForm(params, new TsipouraCalculationDataModel(data))))
            });
        }
        else {
            this.tsipouraCalculationData.push(new FormGroup(new TsipouraCalculationDataForm(params, new TsipouraCalculationDataModel())))
        }

    }
}

export class TsipouraCalculationDataForm {
    [key: string]: AbstractControl;

    producerName: FormControl;
    producerAfm: FormControl;
    harvestDeclaration: FormControl;
    alcoholVol: FormControl;
    wineGrapes: FormControl;
    nonWineGrapes: FormControl;
    grapeStems: FormControl;
    lpa: FormControl;
    finalNetWeight: FormControl;
    numberOfBoilers: FormControl;
    hours: FormControl;
    days: FormControl;
    efk: FormControl;
    startDateTime: FormControl;
    endDateTime: FormControl;

    constructor(params: TsipouraParameters, value?: TsipouraCalculationDataModel) {

        this.producerName = new FormControl(value.producerName, { updateOn: 'blur', validators: [Validators.required] })
        this.producerAfm = new FormControl(value.producerAfm, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(9)] })
        this.harvestDeclaration = new FormControl(value.harvestDeclaration, { updateOn: 'blur' })
        this.alcoholVol = new FormControl(value.alcoholVol)
        this.wineGrapes = new FormControl(value.wineGrapes, { updateOn: 'blur', validators: Validators.max(params?.maxWineGrapes) })
        this.nonWineGrapes = new FormControl(value.nonWineGrapes, { updateOn: 'blur' })
        this.grapeStems = new FormControl(value.grapeStems, { updateOn: 'blur', validators: [Validators.max(params?.maxGrapeStems)] })
        this.lpa = new FormControl(value.lpa, { validators: [Validators.required] })
        this.finalNetWeight = new FormControl(value.finalNetWeight)
        this.numberOfBoilers = new FormControl(value.numberOfBoilers)
        this.hours = new FormControl(value.hours, { validators: [Validators.max(params?.maxHours)] })
        this.days = new FormControl(value.days, { validators: [Validators.max(params?.maxDays)] })
        this.efk = new FormControl(value.efk)
        this.startDateTime = new FormControl(value.startDateTime)
        this.endDateTime = new FormControl(value.endDateTime)
    }

}
export class TsipouraTotalsForm {
    [key: string]: AbstractControl;

    totalWineGrapes: FormControl;
    totalNonWineGrapes: FormControl;
    totalGrapeStems: FormControl;
    totalLpa: FormControl;
    totalFinalNetWeight: FormControl;
    totalNumberOfBoilers: FormControl;
    totalHours: FormControl;
    totalDays: FormControl;
    totalEfk: FormControl;

    constructor(value?: TsipouraTotalsModel) {
        this.totalWineGrapes = new FormControl(value?.totalWineGrapes);
        this.totalNonWineGrapes = new FormControl(value?.totalNonWineGrapes);
        this.totalGrapeStems = new FormControl(value?.totalGrapeStems);
        this.totalLpa = new FormControl(value?.totalLpa);
        this.totalFinalNetWeight = new FormControl(value?.totalFinalNetWeight);
        this.totalNumberOfBoilers = new FormControl(value?.totalNumberOfBoilers);
        this.totalHours = new FormControl(value?.totalHours);
        this.totalDays = new FormControl(value?.totalDays);
        this.totalEfk = new FormControl(value?.totalEfk);
    }
}
