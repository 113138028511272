import { FormArray, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import {
    ConresersForm,
    ConresersModel,
    ItiForm,
    ItiModel,
    SeainfsilForm,
    SeainfsilModel,
    TraconceForm,
    TraconceModel,
    TradecForm,
    TradecModel
} from 'app/main/apps/export/declaration/_models';
import { Cartra100Form } from 'app/main/apps/import/_models/cartra100/cartra-form.model';
import { Cartra100Model } from 'app/main/apps/import/_models/cartra100/cartra100.model';
import { Traconco1Form } from 'app/main/apps/import/_models/traconco1/traconco1-form.model';
import { Traconco1Model } from 'app/main/apps/import/_models/traconco1/traconco1.model';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { CusoffdepeptForm } from '../../../_models/cusoffdepept/cusoffdepept-form.model';
import { CusoffdepeptModel } from '../../../_models/cusoffdepept/cusoffdepept.model';
import { CusoffdesestForm } from '../../../_models/cusoffdesest/cusoffdesest-form.model';
import { CusoffdesestModel } from '../../../_models/cusoffdesest/cusoffdesest.model';
import { CusofftrarnsForm } from '../../../_models/cusofftrarns/cusofftrarns-form.model';
import { CusofftrarnsModel } from '../../../_models/cusofftrarns/cusofftrarns.model';
import { T2LGooIteGdsForm } from '../../../_models/gooitegds/gooitegds-form.model';
import { T2LGooIteGdsModel } from '../../../_models/gooitegds/gooitegds.model';
import { CtlCl1Form } from '../ctlcl1/ctlcl1-form.model';
import { CtlCl1Model } from '../ctlcl1/ctlcl1.model';
import { TirGooIteGdsForm } from '../gooitegds-tir/gooitegds-tir-form.model';
import { TirGooIteGdsModel } from '../gooitegds-tir/gooitegds-tir.model';
import { GuaguaForm } from '../guagua/guagua-form.model';
import { GuaguaModel } from '../guagua/guagua.model';
import { TirHeaheaForm } from '../heahea/heahea-form.model';
import { TirHeaheaModel } from '../heahea/heahea.model';
import { ReprepForm } from '../reprep/reprep-form.model';
import { ReprepModel } from '../reprep/reprep.model';
import { TraautcontraForm } from '../traautcontra/traautcontra-form.model';
import { TraautcontraModel } from '../traautcontra/traautcontra.model';
import { Traconsec029Form } from '../traconsec029/traconsec029-form.model';
import { Traconsec029Model } from '../traconsec029/traconsec029.model';
import { Tracorsec037Form } from '../tracorsec037/tracorsec037-form.model';
import { Tracorsec037Model } from '../tracorsec037/tracorsec037.model';
import { Trapripc1Form } from '../trapripc1/trapripc1-form.model';
import { Trapripc1Model } from '../trapripc1/trapripc1.model';
import { TirDeclarationModel } from './declaration.model';

export class TirDeclarationForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    SynIdeMES1: FormControl;
    SynVerNumMES2: FormControl;
    MesSenMES3: FormControl;
    SenIdeCodQuaMES4: FormControl;
    MesRecMES6: FormControl;
    RecIdeCodQuaMES7: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    IntConRefMES11: FormControl;
    RecRefMES12: FormControl;
    RecRefQuaMES13: FormControl;
    AppRefMES14: FormControl;
    PriMES15: FormControl;
    AckReqMES16: FormControl;
    ComAgrIdMES17: FormControl;
    TesIndMES18: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    ComAccRefMES21: FormControl;
    MesSeqNumMES22: FormControl;
    FirAndLasTraMES23: FormControl;

    HEAHEA: FormGroup;
    TRAPRIPC1: FormGroup;
    TRACONCO1: FormGroup;
    TRACONCE1: FormGroup;
    TRAAUTCONTRA: FormGroup;
    CUSOFFDEPEPT: FormGroup;
    CUSOFFTRARNS: FormArray;
    CUSOFFDESEST: FormGroup;
    CTLCL1: FormGroup;
    CONRESERS: FormGroup;
    REPREP: FormGroup;
    SEAINFSLI: FormGroup;
    GUAGUA: FormArray;
    GOOITEGDS: FormArray;
    ITI: FormArray;
    CARTRA100: FormGroup;
    TRACORSEC037: FormGroup;
    TRACONSEC029: FormGroup;

    /*

   */

    constructor(declaration: TirDeclarationModel, subdomain?: string, messageType = 'CC015B') {

        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));

        this.SynIdeMES1 = new FormControl(declaration.SynIdeMES1);
        this.SynVerNumMES2 = new FormControl(declaration.SynVerNumMES2);
        this.MesSenMES3 = new FormControl(declaration.MesSenMES3);
        this.SenIdeCodQuaMES4 = new FormControl(declaration.SenIdeCodQuaMES4);
        this.MesRecMES6 = new FormControl(declaration.MesRecMES6);
        this.RecIdeCodQuaMES7 = new FormControl(declaration.RecIdeCodQuaMES7);
        this.DatOfPreMES9 = new FormControl(declaration.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(declaration.TimOfPreMES10);
        this.IntConRefMES11 = new FormControl(declaration.IntConRefMES11);
        this.RecRefMES12 = new FormControl(declaration.RecRefMES12);
        this.RecRefQuaMES13 = new FormControl(declaration.RecRefQuaMES13);
        this.AppRefMES14 = new FormControl(declaration.AppRefMES14);
        this.PriMES15 = new FormControl(declaration.PriMES15);
        this.AckReqMES16 = new FormControl(declaration.AckReqMES16);
        this.ComAgrIdMES17 = new FormControl(declaration.ComAgrIdMES17);
        this.TesIndMES18 = new FormControl(declaration.TesIndMES18);
        this.MesIdeMES19 = new FormControl(declaration.MesIdeMES19);
        this.MesTypMES20 = new FormControl(declaration.MesTypMES20);
        this.ComAccRefMES21 = new FormControl(declaration.ComAccRefMES21);
        this.MesSeqNumMES22 = new FormControl(declaration.MesSeqNumMES22);
        this.FirAndLasTraMES23 = new FormControl(declaration.FirAndLasTraMES23);

        if (messageType === 'CC013B') {
            /*  this.HEAHEA = new FormGroup(
                new T2LHeaheaTF013AForm(
                    new T2LHeaheaTF013AModel(declaration.HEAHEA)
                )
            ); */
        } else {
            this.HEAHEA = new FormGroup(new TirHeaheaForm(new TirHeaheaModel(declaration.HEAHEA)));
        }
        this.TRAPRIPC1 = new FormGroup(new Trapripc1Form(new Trapripc1Model(declaration.TRAPRIPC1)));
        this.TRACONCO1 = new FormGroup(new Traconco1Form(new Traconco1Model(declaration.TRACONCO1)));
        this.TRACONCE1 = new FormGroup(new TraconceForm(new TraconceModel(declaration.TRACONCE1)));
        this.TRAAUTCONTRA = new FormGroup(new TraautcontraForm(new TraautcontraModel(declaration.TRAAUTCONTRA)));

        this.CUSOFFDEPEPT = new FormGroup(new CusoffdepeptForm(new CusoffdepeptModel(declaration.CUSOFFDEPEPT)));

        this.CUSOFFTRARNS = new FormArray([]);

        this.CUSOFFDESEST = new FormGroup(new CusoffdesestForm(new CusoffdesestModel(declaration.CUSOFFDESEST)));

        this.CTLCL1 = new FormGroup(new CtlCl1Form(new CtlCl1Model(declaration.CTLCL1)));

        this.CONRESERS = new FormGroup(new ConresersForm(new ConresersModel(declaration.CONRESERS)));

        this.REPREP = new FormGroup(new ReprepForm(new ReprepModel(declaration.REPREP)));

        this.SEAINFSLI = new FormGroup(new SeainfsilForm(new SeainfsilModel(declaration.SEAINFSLI)));
        this.GUAGUA = new FormArray([]);

        this.GOOITEGDS = new FormArray([]);
        this.ITI = new FormArray([]);

        this.CARTRA100 = new FormGroup(new Cartra100Form(new Cartra100Model(declaration.CARTRA100)));
        this.TRACORSEC037 = new FormGroup(new Tracorsec037Form(new Tracorsec037Model(declaration.TRACORSEC037)));
        this.TRACONSEC029 = new FormGroup(new Traconsec029Form(new Traconsec029Model(declaration.TRACONSEC029)));

        if (declaration.CUSOFFTRARNS) {
            declaration.CUSOFFTRARNS.forEach((customs: CusofftrarnsModel) => {
                this.CUSOFFTRARNS.push(new FormGroup(new CusofftrarnsForm(new CusofftrarnsModel(customs))));
            });
        } else {
            this.CUSOFFTRARNS.push(new FormGroup(new CusofftrarnsForm(new CusofftrarnsModel())));
        }

        if (declaration.GUAGUA) {
            declaration.GUAGUA.forEach((guagua: GuaguaModel) => {
                this.GUAGUA.push(new FormGroup(new GuaguaForm(new GuaguaModel(guagua))));
            });
        } else {
            this.GUAGUA.push(new FormGroup(new GuaguaForm(new GuaguaModel())));
        }

        if (declaration.GOOITEGDS) {
            declaration.GOOITEGDS.forEach((goods: T2LGooIteGdsModel) => {
                this.GOOITEGDS.push(new FormGroup(new TirGooIteGdsForm(new TirGooIteGdsModel(goods))));
            });
        } /*  else {
            this.GOOITEGDS.push(
                new FormGroup(new TirGooIteGdsForm(new TirGooIteGdsModel()))
            );
        } */

        if (declaration.ITI) {
            declaration.ITI.forEach((iti: ItiModel) => {
                this.ITI.push(new FormGroup(new ItiForm(new ItiModel(iti))));
            });
        }
    }
}
