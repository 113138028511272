import {
    AbstractControl, FormControl, FormArray,
    FormGroup, Validators, Form
} from "@angular/forms";
import { BeerCalculationDataModel, BeerModel, BeerTotalsModel } from "app/main/apps/excise-helper-tables/beer/_models/beer.model";
import { BeerParameters } from "app/main/apps/excise-helper-tables/beer/beer-defk-create.service";

export class BeerForm {
    [key: string]: AbstractControl;
    customer: FormControl;
    smallProducer: FormControl;
    reducedVat: FormControl;
    addTaricCode: FormControl;
    masterProduceValue: FormControl;
    productDuty: FormControl;
    vatRate: FormControl;

    beerCalculationData: FormArray;
    beerTotals: FormGroup;
    constructor(value?: BeerModel) {

        this.customer = new FormControl(value.customer, Validators.required);
        this.smallProducer = new FormControl(value.smallProducer, Validators.required);
        this.reducedVat = new FormControl(value.reducedVat, Validators.required);
        this.addTaricCode = new FormControl(value.addTaricCode);
        this.masterProduceValue = new FormControl(value.masterProduceValue, { updateOn: 'blur' });
        this.productDuty = new FormControl(value.productDuty)
        this.vatRate = new FormControl(value.vatRate)
        this.beerCalculationData = new FormArray([]);

        this.beerTotals = new FormGroup(new BeerTotalsForm(new BeerTotalsModel(value.beerTotals)));

        if (value.beerCalculationData) {
            value.beerCalculationData.forEach((data: BeerCalculationDataModel) => {
                this.beerCalculationData.push(new FormGroup(new BeerCalculationDataForm(new BeerCalculationDataModel(data))))
            });
        }
        else {
            this.beerCalculationData.push(new FormGroup(new BeerCalculationDataForm(new BeerCalculationDataModel())))
        }

    }
}

export class BeerCalculationDataForm {
    [key: string]: AbstractControl;

    productName: FormControl;
    addTaricCode: FormControl;
    taricCode: FormControl;
    nationalProduct: FormControl;
    quantity: FormControl;
    platoExact: FormControl;
    plato: FormControl;
    produceValue: FormControl;
    platoPer100L: FormControl;
    statisticValue: FormControl;
    eteppaContrib: FormControl;
    ogaContrib: FormControl;
    eteppaStampTax: FormControl;
    amountWithoutVat: FormControl;
    vatTaxBase: FormControl;

    vat: FormControl;
    efk: FormControl;
    totalTax: FormControl;

    constructor(value?: BeerCalculationDataModel) {

        this.productName = new FormControl(value.productName, { updateOn: 'blur' })
        this.taricCode = new FormControl(value.taricCode, { validators: [Validators.required] })
        this.addTaricCode = new FormControl(value.addTaricCode)
        this.nationalProduct = new FormControl(value.nationalProduct, { validators: [Validators.required, Validators.minLength(10)] })
        this.quantity = new FormControl(value.quantity, { updateOn: 'blur', validators: [Validators.required] })
        this.platoExact = new FormControl(value.plato, { updateOn: 'blur', validators: [Validators.required] })
        this.plato = new FormControl(value.plato)
        this.produceValue = new FormControl(value.produceValue, { updateOn: 'blur', validators: [Validators.required] })
        this.platoPer100L = new FormControl(value.platoPer100L)
        this.statisticValue = new FormControl(value.statisticValue)
        this.eteppaContrib = new FormControl(value.eteppaContrib)
        this.ogaContrib = new FormControl(value.ogaContrib)
        this.eteppaStampTax = new FormControl(value.eteppaStampTax)
        this.amountWithoutVat = new FormControl(value.amountWithoutVat)
        this.vatTaxBase = new FormControl(value.vatTaxBase)
        this.vat = new FormControl(value.vat)
        this.efk = new FormControl(value.efk)
        this.totalTax = new FormControl(value.totalTax)
    }

}
export class BeerTotalsForm {
    [key: string]: AbstractControl;

    totalEfk: FormControl;
    totalEteppa: FormControl;
    totalEteppaStamp: FormControl;
    totalOga: FormControl;
    totalVat: FormControl;
    totalGrand: FormControl;

    constructor(value?: BeerTotalsModel) {
        this.totalEfk = new FormControl(value?.totalEfk);
        this.totalEteppa = new FormControl(value?.totalEteppa);
        this.totalEteppaStamp = new FormControl(value?.totalEteppaStamp);
        this.totalOga = new FormControl(value?.totalOga);
        this.totalVat = new FormControl(value?.totalVat);
        this.totalGrand = new FormControl(value?.totalGrand);
    }
}
