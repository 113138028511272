import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';

export class eAitisiGooIteGdsModel {
    GooDesGDS23?: string;
    StaValAmoGDI1?: number;
    COMCODGODITM?: ComCodModel[];

    constructor(value?) {
        // console.log("___GOOITEGDSModel", value);
        if (value) {
            this.GooDesGDS23 = value.GooDesGDS23;
            this.StaValAmoGDI1 = value.StaValAmoGDI1;
            this.COMCODGODITM = value.COMCODGODITM;

            if (!this.COMCODGODITM[0].TARFirAddCodCMD1) {
                this.COMCODGODITM[0].TARFirAddCodCMD1 = '0000'
            }
            if (!this.COMCODGODITM[0].TARSecAddCodCMD1) {
                this.COMCODGODITM[0].TARSecAddCodCMD1 = '0000'
            }
            if (!this.COMCODGODITM[0].NAtAddCodCMD1) {
                this.COMCODGODITM[0].NAtAddCodCMD1 = '0000'
            }

        }
    }
}
