
export class PreviousDocumentsModel {
    PreviousDocumentType?: string;
    PreviousAsdeDocument?: PrevAsdeDocModel;
    PreviousEaadDocument?: PrevEaadDocModel;
    PreviousStampRequestDocument?: PrevStampReqDocModel;
    PreviousVehicleArrivalDocument?: PrevVehArrivalDocModel;
    PreviousVehicleMovementDocument?: PrevVehMoveDocModel;
    PreviousExciseTaxesDeclarationDocument?: PrevExciseTaxDeclDocModel;


    constructor(value?) {
        this.PreviousDocumentType = value?.PreviousDocumentType || null;
        this.PreviousAsdeDocument = value?.PreviousAsdeDocument || null;
        this.PreviousEaadDocument = value?.PreviousEaadDocument || null;
        this.PreviousStampRequestDocument = value?.PreviousStampRequestDocument || null;
        this.PreviousVehicleArrivalDocument = value?.PreviousVehicleArrivalDocument || null;
        this.PreviousVehicleMovementDocument = value?.PreviousVehicleMovementDocument || null;
        this.PreviousExciseTaxesDeclarationDocument = value?.PreviousExciseTaxesDeclarationDocument || null;

    }
}



export class PrevAsdeDocModel {
    AsdeReferenceNumber?: string;
    AsdeRowNumber?: string;


    constructor(value?) {
        this.AsdeReferenceNumber = value?.AsdeReferenceNumber || null;
        this.AsdeRowNumber = value?.AsdeRowNumber || null;

    }
}

export class PrevEaadDocModel {
    AadReferenceCode?: string;
    SequenceNumber?: string;


    constructor(value?) {
        this.AadReferenceCode = value?.AadReferenceCode || null;
        this.SequenceNumber = value?.SequenceNumber || null;

    }
}

export class PrevStampReqDocModel {
    DeclarationReferenceNumber?: string;


    constructor(value?) {
        this.DeclarationReferenceNumber = value?.DeclarationReferenceNumber || null;

    }
}

export class PrevVehArrivalDocModel {
    DeclarationReferenceNumber?: string;


    constructor(value?) {
        this.DeclarationReferenceNumber = value?.DeclarationReferenceNumber || null;

    }
}

export class PrevVehMoveDocModel {
    DeclarationReferenceNumber?: string;

    constructor(value?) {
        this.DeclarationReferenceNumber = value?.DeclarationReferenceNumber || null;

    }
}

export class PrevExciseTaxDeclDocModel {
    DeclarationReferenceNumber?: string;
    ExciseTaxesDeclarationRowNumber?: string;


    constructor(value?) {
        this.DeclarationReferenceNumber = value?.DeclarationReferenceNumber || null;
        this.ExciseTaxesDeclarationRowNumber = value?.ExciseTaxesDeclarationRowNumber || null;

    }
}
