export class ComCodModel {
    ComNomCMD1?: string;
    TARCodCMD1?: string;
    TARFirAddCodCMD1?: string;
    TARSecAddCodCMD1?: string;
    NAtAddCodCMD1?: string;
    _combinedComCode?: string;

    constructor(value?) {
        this.ComNomCMD1 = value?.ComNomCMD1 || null;
        this.TARCodCMD1 = value?.TARCodCMD1 || null;
        this.TARFirAddCodCMD1 = value?.TARFirAddCodCMD1 || null;
        this.TARSecAddCodCMD1 = value?.TARSecAddCodCMD1 || null;
        this.NAtAddCodCMD1 = value?.NAtAddCodCMD1 || null;
        this._combinedComCode = value?._combinedComCode || null;
    }
}
