import { FormControl } from '@angular/forms';
import { EMCSVehicleInventoryModel } from './vehinv.model';

export class EMCSVehicleInventoryForm {
    [key: string]: FormControl;

    VehicleInventoryType = new FormControl();

    constructor(value: EMCSVehicleInventoryModel) {
        this.VehicleInventoryType.setValue(value.VehicleInventoryType);
    }
}
