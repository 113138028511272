<div fxLayout="column" style="width: 100%; flex-grow: 1">

    <!-- TITLE -->
    <div mat-dialog-title class="primary">
        <!-- LEFT -->
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 20%">

            <span>Aνάκτηση Στοιχείων Τιμολογίου</span>
        </div>
        <!-- LEFT -->

        <!-- CENTER -->
        <div fxLayout="row" fxLayoutAlign="center center" style="width:50% ;margin-left: 70px">
            <app-loading *ngIf="(loading$ | async)" class="loading loading-customer-invoice loading-white"
                [addValues]="false">
            </app-loading>
        </div>
        <!-- CENTER -->

        <!-- RIGHT -->
        <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
        <!-- RIGHT -->
    </div>
    <!-- TITLE -->

    <!-- CONTENT -->
    <div mat-dialog-content fxLayout="column" fxLayoutAlign="flex-start center" style="padding: 0 16px;">

        <app-customer-invoice-parsing-form #invoiceParsingForm *ngIf="!showResults"
            style="width:100%"></app-customer-invoice-parsing-form>

        <app-customer-invoice-parsing-results *ngIf="showResults" [results]="results"
            style="width:100%"></app-customer-invoice-parsing-results>

    </div>
    <!-- CONTENT -->

    <!-- BUTTONS -->
    <div *ngIf="showButtons">
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="onCloseConfirm()">
                Ακυρο
            </button>
            <button *ngIf="!showResults" style="width: auto !important" mat-raised-button
                [disabled]="(enableButtons$ | async)=== false || (loading$| async)" [ngClass]="color2"
                (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onSubmit()">
                Επεξεργασία
            </button>
            <button *ngIf="showResults" style="width: auto !important" mat-raised-button [ngClass]="color2"
                (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="pasteResults()">
                Επικόλληση Στοιχείων
            </button>
        </mat-dialog-actions>
    </div>
    <!-- BUTTONS -->

</div>