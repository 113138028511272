import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'testDigitOnly'
})
export class TestDigitOnlyPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return /^\d+$/.test(value)
    }
}

@NgModule({
    declarations: [TestDigitOnlyPipe],
    exports: [TestDigitOnlyPipe],
})
export class TestDigitOnlyPipeModule { }