import { FormControl, FormGroup, AbstractControl } from '@angular/forms';

import { eAitisiCancelHeaheaForm } from './heahea/heahea-form.model';
import { eAitisiCancelHeaheaModel } from './heahea/heahea.model';
import { eAitisiTradecForm } from '../tradec/tradec-form.model';
import { eAitisiTradecModel } from '../tradec/tradec.model';
import { eAitisiTrarepForm } from '../trarep/trarep-form.model';
import { eAitisiCancelModel } from './cancelAitisi.model';
import { eAitisiTrarepModel } from '../trarep/trarep.model';
import { ImpcusoffForm } from '../impcusoff/impcusoff-form.model';
import { ImpcusoffModel } from '../impcusoff/impcusoff.model';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';

export class eAitisiCancelForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    MesSenMES3: FormControl;
    MesRecMES6: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    TesIndMES18: FormControl;
    // PriMES15: FormControl;
    // ComAgrIdMES17: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    // ComAccRefMES21: FormControl;
    HEAHEA: FormGroup;
    TRADEC: FormGroup;
    TRAREP: FormGroup;
    CUSOFF: FormGroup;
    /*

   */

    constructor(cancel: eAitisiCancelModel, subdomain?: string, messageType?: string) {

        let privateControls: string | PrivateControlsModel = subdomain;

        if (cancel._PRIVATE) {
            privateControls = cancel._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));


        this.MesSenMES3 = new FormControl(cancel.MesSenMES3);
        this.MesRecMES6 = new FormControl(cancel.MesRecMES6);
        this.DatOfPreMES9 = new FormControl(cancel.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(cancel.TimOfPreMES10);
        this.TesIndMES18 = new FormControl(cancel.TesIndMES18);
        this.MesIdeMES19 = new FormControl(cancel.MesIdeMES19);
        this.MesTypMES20 = new FormControl(cancel.MesTypMES20);

        this.HEAHEA = new FormGroup(new eAitisiCancelHeaheaForm(new eAitisiCancelHeaheaModel(cancel.HEAHEA)));

        this.TRADEC = new FormGroup(new eAitisiTradecForm(new eAitisiTradecModel(cancel.TRADEC)));

        this.TRAREP = new FormGroup(new eAitisiTrarepForm(new eAitisiTrarepModel(cancel.TRAREP)));

        this.CUSOFF = new FormGroup(new ImpcusoffForm(new ImpcusoffModel(cancel.CUSOFF)));
    }
}
