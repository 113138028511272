export class ReferenceDocumentsModel {
    ReferenceDocumentId?: string;
    ReferenceDocumentNumber?: string;


    constructor(value?) {
        this.ReferenceDocumentId = value?.ReferenceDocumentId || null;
        this.ReferenceDocumentNumber = value?.ReferenceDocumentNumber || null;

    }
}
