export class TirHeaheaModel {
    DocNumHEA5?: string;

    RefNumHEA4?: string;
    CouOfDesCodHEA30?: string;
    AgrLocOfGooCodHEA38?: string;
    AgrLocOfGooHEA39?: string;
    AgrLocOfGooHEA39LNG?: string;
    AutLocOfGooCodHEA41?: string;
    PlaOfLoaCodHEA46?: string;
    CouOfDisCodHEA55?: string;
    CusSubPlaHEA66?: string;
    InlTraModHEA75?: string;
    TraModAtBorHEA76?: string;
    IdeOfMeaOfTraAtDHEA78?: string;
    IdeOfMeaOfTraAtDHEA78LNG?: string;
    NatOfMeaOfTraAtDHEA80?: string;
    IdeOfMeaOfTraCroHEA85?: string;
    IdeOfMeaOfTraCroHEA85LNG?: string;
    NatOfMeaOfTraCroHEA87?: string;
    TypOfMeaOfTraCroHEA88?: string;
    ConIndHEA96?: string;
    DiaLanIndAtDepHEA254?: string;
    NCTSAccDocHEA601LNG?: string;
    TotNumOfIteHEA305?: string;
    TotNumOfPacHEA306?: string;
    TotGroMasHEA307?: string;
    ///CC013B ONLY====================
    DecDatHEA383?: string;
    DecPlaHEA394?: string;
    DecPlaHEA394LNG?: string;

    //CC015B ONLy===========
    AmdPlaHEA598?: string;
    AmdPlaHEA598LNG?: string;
    AmdDatHEA599?: string;

    SpeCirIndHEA1?: string;
    TraChaMetOfPayHEA1?: string;
    ComRefNumHEA?: string;
    SecHEA358?: string;
    ConRefNumHEA?: string;
    CodPlUnHEA357?: string;
    CodPlUnHEA357LNG?: string;
    TypOfDecHEA24?: string;

    constructor(value?) {
        if (value) {
            this.DocNumHEA5 = value.DocNumHEA5;

            this.RefNumHEA4 = value.RefNumHEA4;
            this.CouOfDesCodHEA30 = value.CouOfDesCodHEA30;
            this.AgrLocOfGooCodHEA38 = value.AgrLocOfGooCodHEA38;
            this.AgrLocOfGooHEA39 = value.AgrLocOfGooHEA39;
            this.AgrLocOfGooHEA39LNG = value.AgrLocOfGooHEA39LNG;
            this.AutLocOfGooCodHEA41 = value.AutLocOfGooCodHEA41;
            this.PlaOfLoaCodHEA46 = value.PlaOfLoaCodHEA46;
            this.CouOfDisCodHEA55 = value.CouOfDisCodHEA55;
            this.CusSubPlaHEA66 = value.CusSubPlaHEA66;
            this.InlTraModHEA75 = value.InlTraModHEA75;
            this.TraModAtBorHEA76 = value.TraModAtBorHEA76;
            this.IdeOfMeaOfTraAtDHEA78 = value.IdeOfMeaOfTraAtDHEA78;
            this.IdeOfMeaOfTraAtDHEA78LNG = value.IdeOfMeaOfTraAtDHEA78LNG;
            this.NatOfMeaOfTraAtDHEA80 = value.NatOfMeaOfTraAtDHEA80;
            this.IdeOfMeaOfTraCroHEA85 = value.IdeOfMeaOfTraCroHEA85;
            this.IdeOfMeaOfTraCroHEA85LNG = value.IdeOfMeaOfTraCroHEA85LNG;
            this.NatOfMeaOfTraCroHEA87 = value.NatOfMeaOfTraCroHEA87;
            this.TypOfMeaOfTraCroHEA88 = value.TypOfMeaOfTraCroHEA88;
            this.ConIndHEA96 = value.ConIndHEA96;
            this.DiaLanIndAtDepHEA254 = value.DiaLanIndAtDepHEA254;
            this.NCTSAccDocHEA601LNG = value.NCTSAccDocHEA601LNG || 'EL';
            this.TotNumOfIteHEA305 = value.TotNumOfIteHEA305;
            this.TotNumOfPacHEA306 = value.TotNumOfPacHEA306;
            this.TotGroMasHEA307 = value.TotGroMasHEA307;

            this.DecDatHEA383 = value.DecDatHEA383;
            this.DecPlaHEA394 = value.DecPlaHEA394;
            this.DecPlaHEA394LNG = value.DecPlaHEA394LNG;

            this.AmdPlaHEA598 = value.AmdPlaHEA598;
            this.AmdPlaHEA598LNG = value.AmdPlaHEA598LNG;
            this.AmdDatHEA599 = value.AmdDatHEA599;

            this.SpeCirIndHEA1 = value.SpeCirIndHEA1;
            this.TraChaMetOfPayHEA1 = value.TraChaMetOfPayHEA1;
            this.ComRefNumHEA = value.ComRefNumHEA;
            this.SecHEA358 = value.SecHEA358;
            this.ConRefNumHEA = value.ConRefNumHEA;
            this.CodPlUnHEA357 = value.CodPlUnHEA357;
            this.CodPlUnHEA357LNG = value.CodPlUnHEA357LNG;
            this.TypOfDecHEA24 = value.TypOfDecHEA24;

            //===========================================================
            //this.AmdPlaHEA598 = value.AmdPlaHEA598;
            //this.AmdPlaHEA598LNG = value.AmdPlaHEA598LNG;
            //this.RefT2LYeaHEA1012 = value.RefT2LYeaHEA1012;
            //this.RefT2CouCodHEA1010 = value.RefT2CouCodHEA1010;
            //this.RefT2LTypHEA1014 = value.RefT2LTypHEA1014;
            //this.RefT2LCusHEA1013 = value.RefT2LCusHEA1013;
            //this.RefT2LNumHEA1015 = value.RefT2LNumHEA1015;
            //this.AmdDatHEA599 = value.AmdDatHEA599;
        }
    }
}
