import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfirmDialogConfig, OpenDialogsService } from 'app/services/openDialogs.service';
import { BaseSaveFormParams, SaveAsService } from 'app/services/saveAs.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, of, switchMap, tap } from 'rxjs';

@Injectable()
export class GeneralApplicationsService {


    private save: Subject<void>;
    save$: Observable<void>;

    private load: Subject<any>;
    load$: Observable<any>;

    private loadedId: BehaviorSubject<string | null>;
    loadedId$: Observable<string | null>;

    private allApplications: BehaviorSubject<any[]>;
    allApplications$: Observable<any[]>;
    PHP_API_SERVER = environment.API_URL;


    constructor(private http: HttpClient, private saveAsService: SaveAsService, private toastrService: ToastrService, private openDialogsService: OpenDialogsService) {


        this.save = new Subject();
        this.save$ = this.save.asObservable();

        this.load = new Subject();
        this.load$ = this.load.asObservable();

        this.loadedId = new BehaviorSubject(null);
        this.loadedId$ = this.loadedId.asObservable();

        this.allApplications = new BehaviorSubject([]);
        this.allApplications$ = this.allApplications.asObservable();
    }

    saveApplication(postData: any) {
        const loadedId = this.loadedId.getValue();
        const args: BaseSaveFormParams = {
            id: loadedId,
            type: 'distillersApp',
        }

        return this.saveAsService.saveAsDialog(args, 'distillersApp').pipe(switchMap(args => {

            console.log("saveApplication postData: ", postData)
            console.log("saveApplication args: ", args)
            if (args) {
                postData.id = args.id;
                postData.description = args.templateDescription;

                return this.saveApplicationForm(postData, args.action)
            }
            return of(null)

        }))

    }
    saveApplicationForm(postData: any, action: 'create' | 'update'): Observable<any> {

        const url = `${this.PHP_API_SERVER}/generalApps/`;
        let action$ = this.http.post<any>(url, postData)

        if (action === 'update') {
            action$ = this.http.put<any>(url, postData)
        }

        return action$.pipe(tap(res => {
            console.log("saveApplicationForm: ", res);

            this.allApplications.next(res.allApplications)
            this.loadedId.next(res.current.id)

            this.toastrService.success('Η δήλωση αποθηκεύτηκε επιτυχώς', 'Επιτυχία', { timeOut: 2000 })
        }));
    }

    deleteApplication(app: any) {

        const config: ConfirmDialogConfig = {
            message: `Θέλετε να διαγράψετε την δήλωση '${app.description}';`,
            title: 'Διαγραφή Δήλωσης',
            confirmButton: 'Διαγραφή',
            cancelButton: 'Άκυρο',
            confirmClass: 'warn',
            width: '35%',
            height: '45%'
        }
        return this.openDialogsService.openConfirmDialog(config).pipe(switchMap((result) => {

            if (result) {

                const url = `${this.PHP_API_SERVER}/generalApps/index.php/distillersApp/${app.id}`;

                return this.http.delete<any>(url).pipe(tap(res => {
                    console.log("deleteApplication: ", res);
                    this.allApplications.next(res);
                    this.toastrService.success('Η δήλωση διαγράφηκε επιτυχώς', 'Επιτυχία', { timeOut: 2000 })

                }));
            }
            return of(null)
        }))
    }

    getAllApplications(type: 'distillersApp'): Observable<any[]> {
        const url = `${this.PHP_API_SERVER}/generalApps/index.php/${type}`;

        return this.http.get<any>(url).pipe(tap(res => {
            console.log("saveApplicationForm: ", res);
            this.allApplications.next(res);

        }));

    }

    sendSaveSignal() {
        this.save.next();
    }

    sendLoadSignal(id: string, dilosiModel: any) {
        this.load.next(dilosiModel);
        this.loadedId.next(id);
    }
}
