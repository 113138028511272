import { ImportCancelDeclarationForm } from './../../main/apps/import/_models/cancel-declaration/declaration-form.model';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CancelDeclarationComponent } from 'app/main/apps/export/cancel-declaration/cancel-declaration.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { DeclarationStateService } from 'app/services/declaration-state.service';
import { StateResponse } from "app/services/declaration-state.service";
import { NotificationService } from 'app/services/notifications.service';
import { environment } from 'environments/environment';
import { DeclarationForms, MainBroadcasterService } from 'app/services/broadcaster.service';
import { Observable, combineLatest, take, tap } from 'rxjs';
import { CancelForm } from 'app/main/apps/export/cancel-declaration/_models/cancel-form.model';
import { CancelModel } from 'app/main/apps/export/cancel-declaration/_models/cancel.model';
import { eAitisiCancelForm } from 'app/main/apps/e-aitisi/_models/cancelDeclaration/cancelAitisi-form.model';
import { eAitisiCancelModel } from 'app/main/apps/e-aitisi/_models/cancelDeclaration/cancelAitisi.model';
import { T2LCancelDeclarationModel } from 'app/main/apps/transit/t2l/_models/cancel-declaration/declaration.model';
import { T2LCancelDeclarationForm } from 'app/main/apps/transit/t2l/_models/cancel-declaration/declaration-form.model';
import { VA10ADeclarationForm } from 'app/main/apps/excise/dao/_models/VA10A/daoCancelForm.model';
import { VA10ADeclarationModel } from 'app/main/apps/excise/dao/_models/VA10A/daoCancel.model';
import { EF10ADeclarationModel } from 'app/main/apps/excise/defk/_models/EF10A/defkCancel.model';
import { EF10ADeclarationForm } from 'app/main/apps/excise/defk/_models/EF10A/defkCancelForm.model';
import { ImportCancelDeclarationModel } from 'app/main/apps/import/_models/cancel-declaration/declaration.model';
import { TirCancelForm } from 'app/main/apps/transit/tir/_models/cancel-declaration/declaration-form.model';
import { TirCancelModel } from 'app/main/apps/transit/tir/_models/cancel-declaration/declaration.model';
import { StorageService } from 'app/core/services/storage/storage.service';

@Injectable({ providedIn: 'root' })
export class CancelDeclarationService {
    lrn: string;
    mrn: string;
    domain: string;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CancelDeclarationComponent>,
        private notificationService: NotificationService,
        private declarationStateService: DeclarationStateService,
        private mainBroadcaster: MainBroadcasterService,
        private storageService: StorageService
    ) {
        console.log('SERVICE submitForm Started');
    }

    //=====================================================
    //================================CC514A-===============
    cancelDeclaration(): Observable<[string, DeclarationForms]> {

        return combineLatest([this.mainBroadcaster.activeSubdomain$, this.mainBroadcaster.declarationForms$])
            .pipe(take(1),
                tap(([subdomain, declarationForms]) => {
                    const declarationForm = declarationForms[subdomain];

                    console.log('modify subdomain', subdomain);
                    console.log('modify declarationForms', declarationForms);
                    console.log('modify declarationForm', declarationForm);

                    this.getState(subdomain, declarationForm);
                }));

    }

    getState(subdomain, declarationForm) {
        this.declarationStateService.getLatestState(declarationForm).then((response: StateResponse[]) => {
            console.log('modify getLatestState response:', response[0]);

            if (response[0] === undefined || response[0] === null) {
                return;
            }

            let stateCodeValid: boolean = this.stateCodeValid(response[0])

            /////===============TESTING=====================
            if (environment.testing) {
                if (subdomain === 'DAO' || subdomain === 'DEFK') {
                    this.declarationStateService.setState(declarationForm, 'Registered');

                }
                else {
                    this.declarationStateService.setState(declarationForm, 'Accepted');
                }

                stateCodeValid = true

            }
            //----------------------------------------------
            //----------------------------------------------
            //if state is Valid open dialog
            if (stateCodeValid) {
                const mrn = declarationForm.get('_PRIVATE').get('mrn').value

                this.storageService.setLocalObject(mrn, declarationForm.getRawValue());

                this.openCancelDialog(subdomain, declarationForm);
                this.notificationService.clear();
            }

            // else show message
            else {
                this.notificationService.editNotAllowed();
            }
        });
    }

    openCancelDialog(subdomain: string, declarationForm: FormGroup) {
        const dialogConfig = new MatDialogConfig();

        // dialogConfig.autoFocus = true;
        dialogConfig.width = '60%';
        dialogConfig.height = '55%';
        dialogConfig.panelClass = 'mat-dialog-override';
        dialogConfig.data = { subdomain, declarationForm }

        this.dialogRef = this.dialog.open(CancelDeclarationComponent, dialogConfig);

        this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                this.dialogRef.close();
            }
        });

        this.dialogRef.backdropClick().subscribe(() => {
            this.dialogRef.close();
        });
    }

    stateCodeValid(response: StateResponse): boolean {
        const subdomain = response.subdomain;
        const stateCode = response.stateCode;

        switch (subdomain) {
            case 'EXP_DECL': {
                if (
                    stateCode === 'Pre-Accepted' ||
                    stateCode === 'Accepted' ||
                    stateCode === 'Under Amendment' ||
                    stateCode === 'Under Release' ||
                    stateCode === 'Goods Released') {
                    return true;
                }

                break;
            }

            case 'IMP_DECL': {
                if (
                    stateCode === 'Accepted' ||
                    stateCode === 'Under Release' ||
                    stateCode === 'Under Payment' ||
                    stateCode === 'To be Accepted'
                ) {
                    return true;
                }

                break;
            }

            case 'TIR': {
                if (
                    stateCode === 'Accepted' ||
                    stateCode === 'Under Release Request' ||
                    stateCode === 'Idle'
                ) {
                    return true;
                }
                break;
            }
            case 'T2L': {
                if (
                    stateCode === 'Accepted') {
                    return true;
                }

                break;
            }
            case 'ICS_DETE': {
                if (
                    stateCode === 'Accepted' ||
                    stateCode === 'To be Accepted' ||
                    stateCode === '???ics_paid???'
                ) {
                    return true;
                }

                break;
            }
            case 'DAO':
            case 'DEFK': {
                if (stateCode === 'Registered') {
                    return true;
                }
                break;
            }

        }
        return false;
    }


    createCancelForm(declarationForm) {
        const messageType = declarationForm.get('_PRIVATE').get('messageOut').value
        switch (messageType) {

            case 'CC515A':
            case 'CC513A':
                {
                    return new FormGroup(new CancelForm(new CancelModel(declarationForm.getRawValue())));
                }
            case 'CC015B':
            case 'CC013B':
                {
                    return new FormGroup(new TirCancelForm(new TirCancelModel(declarationForm.getRawValue())));
                }
            case 'ID15A':
            case 'ID13A':
                {
                    return new FormGroup(new ImportCancelDeclarationForm(new ImportCancelDeclarationModel(declarationForm.getRawValue())));

                }
            case 'IB15A':
            case 'IB13A':
                {
                    return new FormGroup(new eAitisiCancelForm(new eAitisiCancelModel(declarationForm.getRawValue())));

                }
            case 'VA15A':
            case 'VA13A': {
                return new FormGroup(new VA10ADeclarationForm(new VA10ADeclarationModel(declarationForm.getRawValue())));

            }
            case 'TF015A':
            case 'TF013A': {
                return new FormGroup(new T2LCancelDeclarationForm(new T2LCancelDeclarationModel(declarationForm.getRawValue())));

            }
            case 'EF15A':
            case 'EF13A': {
                return new FormGroup(new EF10ADeclarationForm(new EF10ADeclarationModel(declarationForm.getRawValue())));

            }

        }
    }

}
