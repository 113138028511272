<table class="noPrint" style="width:75%" [formGroup]="beerForm">
    <colgroup>
        <col style="width: 1%;" />
        <col style="width: 7%;" />
        <col style="width: 2%;" />
        <col style="width: 3%;" />
        <col style="width: 3%;" />
        <col style="width: 3%;" />
        <col style="width: 3%;" />
        <col style="width: 2%;" />
        <col class="noPrint" style="width: 2%;" />
    </colgroup>
    <thead>
        <tr>
            <th>Α.Α.</th>
            <th>ΟΝΟΜΑ ΠΡΟΙΟΝΤΟΣ</th>
            <th>PLATO</th>
            <th>ΛΙΤΡΑ</th>
            <th>ΚΟΣΤΟΣ ΠΑΡΑΓΩΓΗΣ</th>
            <th>ΚΩΔΙΚΟΣ TARIC</th>
            <th>ΕΘΝΙΚΟΣ ΚΩΔΙΚΟΣ</th>
            <th>ΠΡΟΣΘΕΤΟΣ ΚΩΔΙΚΟΣ</th>
            <th class="noPrint"></th>
            <!-- ... other headers -->
        </tr>
    </thead>

    <tbody formArrayName="beerCalculationData">
        <tr *ngFor="let item of beerCalculationData.controls; let i = index" [formGroupName]="i">
            <td><input [value]="i+1" readonly tabindex="-1"></td>
            <td><input class="excel-input text-bold text-left" formControlName="productName" /></td>
            <td><input class="excel-input text-bold" formControlName="platoExact" digitOnly [decimal]="true"
                    [numberOfDecimals]="2" maxlength="5" (blur)="formatDecimals(item.get('platoExact'))"></td>
            <td><input class="excel-input text-bold" formControlName="quantity" digitOnly [decimal]="true"
                    [numberOfDecimals]="2" (blur)="formatDecimals(item.get('quantity'))"></td>
            <td><input class="excel-input text-bold" formControlName="produceValue" digitOnly [decimal]="true"
                    [numberOfDecimals]="2" (blur)="formatDecimals(item.get('produceValue'))"></td>
            <td class="text-center" style="line-height:2.3">
                <mat-form-field class="excel-form-field sc-ul s-c s-full">
                    <autocomplete-control formControlName="taricCode" class="autocomplete-excel-input"
                        [options]="products" (keyDown.Escape)="$event.stopPropagation();
                                $event.preventDefault();">
                    </autocomplete-control>

                </mat-form-field>
            </td>
            <ng-container *ngIf="(filteredNationalProducts$ | async) as nationalProducts">
                <td style="line-height:2.3;" class="text-center"
                    [matTooltip]="item.get('nationalProduct').value | natProDesc:nationalProducts[i]"
                    matTooltipClass="tooltip">
                    <mat-form-field class="excel-form-field sc-ul s-c s-full">
                        <autocomplete-control formControlName="nationalProduct" class="autocomplete-excel-input"
                            [options]="nationalProducts[i]" [config]="config" (keyDown.Escape)="$event.stopPropagation();
                        $event.preventDefault();">
                        </autocomplete-control>
                    </mat-form-field>
                </td>
            </ng-container>
            <td><input class="text-bold" formControlName="addTaricCode" readonly tabindex="-1"></td>
            <td class="noPrint"><add-del-buttons [tabindex]="0" [bypassFormDisabled]="true" [hideAdd]="hideAdd()"
                    [hideDelete]="hideDelete()" (addButton)="addProducer()"
                    (deleteButton)="deleteProducer(i)"></add-del-buttons></td>
        </tr>
    </tbody>

</table>



<table class="printOnly beerCalculationData" style="width:75%">
    <colgroup>
        <col style="width: 1%;" />
        <col style="width: 7%;" />
        <col style="width: 2%;" />
        <col style="width: 3%;" />
        <col style="width: 3%;" />
        <col style="width: 3%;" />
        <col style="width: 3%;" />
        <col style="width: 2%;" />
    </colgroup>
    <thead>
        <tr>
            <th>Α.Α.</th>
            <th>ΟΝΟΜΑ ΠΡΟΙΟΝΤΟΣ</th>
            <th>PLATO</th>
            <th>ΛΙΤΡΑ</th>
            <th>ΚΟΣΤΟΣ ΠΑΡΑΓΩΓΗΣ</th>
            <th>ΚΩΔΙΚΟΣ TARIC</th>
            <th>ΕΘΝΙΚΟΣ ΚΩΔΙΚΟΣ</th>
            <th>ΠΡΟΣΘΕΤΟΣ ΚΩΔΙΚΟΣ</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let item of beerForm.get('beerCalculationData').value; let i = index">
            <td>{{i+1}}</td>
            <td class=" text-bold">{{item.productName}}</td>
            <td class=" text-bold">{{item.plato}}</td>
            <td class=" text-bold">{{item.quantity}}</td>
            <td class=" text-bold">{{item.produceValue}}</td>
            <td class=" text-bold">{{item.taricCode}}</td>
            <td class=" text-bold">{{item.nationalProduct}}</td>
            <td class=" text-bold">{{item.addTaricCode}}</td>
    </tbody>


</table>