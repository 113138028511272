export class PrintSettingsModel {
    id: number;
    title: string;
    document: string;
    top: number;
    left: number;

    constructor(value?) {
        this.id = value?.id || null;
        this.title = value?.title || null;
        this.document = value?.document || null;
        this.top = value?.top || 0;
        this.left = value?.left || 0;
    }
}
