import { FormControl, Validators } from '@angular/forms';
import { PreAdmRefModel } from './preadmrefar2.model';

export class PreAdmRefForm {
    [key: string]: FormControl;

    PreDocTypAR21 = new FormControl();
    PreDocRefAR26 = new FormControl(null, { updateOn: 'blur' });
    PreDocRefLNG = new FormControl();
    PreDocCatPREADMREF21 = new FormControl();
    PreDocMrnAR1004 = new FormControl();
    PreDocIteAR1005 = new FormControl();
    PreDocQuaAR1006 = new FormControl();
    PreDocSupAR1007 = new FormControl();
    PreDocSumDecPacAR1035 = new FormControl();
    PreDocBilOfLadTyp10 = new FormControl();
    PreDocBilOfLadNum11 = new FormControl();

    constructor(value: PreAdmRefModel) {
        this.PreDocTypAR21.setValue(value.PreDocTypAR21);
        this.PreDocRefAR26.setValue(value.PreDocRefAR26);
        this.PreDocRefLNG.setValue(value.PreDocRefLNG);
        this.PreDocCatPREADMREF21.setValue(value.PreDocCatPREADMREF21);
        this.PreDocMrnAR1004.setValue(value.PreDocMrnAR1004);
        this.PreDocIteAR1005.setValue(value.PreDocIteAR1005);
        this.PreDocQuaAR1006.setValue(value.PreDocQuaAR1006);
        this.PreDocSupAR1007.setValue(value.PreDocSupAR1007);
        this.PreDocSumDecPacAR1035.setValue(value.PreDocSumDecPacAR1035);
        this.PreDocBilOfLadTyp10.setValue(value.PreDocBilOfLadTyp10);
        this.PreDocBilOfLadNum11.setValue(value.PreDocBilOfLadNum11);
    }
}
