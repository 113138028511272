export class IdewarModel {
    WarTypWID1?: string;
    AutCouWID1?: string;
    WarIdeWARIDE19?: string;

    constructor(value?) {
        if (value) {
            this.WarTypWID1 = value.WarTypWID1;
            this.AutCouWID1 = value.AutCouWID1;
            this.WarIdeWARIDE19 = value.WarIdeWARIDE19;
        }
    }
}
