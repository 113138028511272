import { DeclarationModels } from "app/services/submitServices/submitForm.service";

export interface TabData {
    totalTabs?: number;
    totalItems?: number;
    tabId?: number;
    tabItems?: number;
    declarationFormValue?: DeclarationModels;
    innerTitle?: string;
}

export class Tab {
    public id?: number;
    public title: string;
    public privateTitle: string;
    public tabData: TabData;
    public active?: boolean;
    //public component: Type<any>;

    constructor(title: string, privateTitle: string, tabData: TabData, active = true) {
        //this.component = component;
        this.title = title;
        this.privateTitle = privateTitle;
        this.tabData = tabData;
        this.active = active;
    }
}
