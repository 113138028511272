import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { RouteStateService } from 'app/services/routeState.service';
import { Observable, Subject, of } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'add-del-buttons',
    templateUrl: './add-del-buttons.component.html',
    styleUrls: ['./add-del-buttons.component.scss']
})
export class AddDelButtonsComponent implements OnInit {
    @Input() hideAdd: boolean;
    @Input() tabindex = -1;

    @Input() hideDelete: boolean;

    @Input() show = true;
    @Input() bypassFormDisabled = false;

    formDisabled$: Observable<boolean>;

    //Declare the property
    @Output() addButton: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteButton: EventEmitter<any> = new EventEmitter<any>();

    private _destroy: Subject<void> = new Subject<void>();

    constructor(private mainBroadcaster: MainBroadcasterService) {
        console.log('ADD DEL BUTTONS CONSTRUCTOR');

        const activeSubdomain$ = this.mainBroadcaster.activeSubdomain$;
        const disabled$ = this.mainBroadcaster.formDisabled$;

        if (!this.bypassFormDisabled) {
            this.formDisabled$ = activeSubdomain$.pipe(
                mergeMap(subdomain =>
                    disabled$.pipe(map(disabled => disabled[subdomain]))
                )
            );
        } else {
            this.formDisabled$ = of(false)
        }

    }

    ngOnInit(): void {
        console.log('SHOW', this.show);
    }
    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._destroy.next();
        this._destroy.complete();
    }

    //Raise the event to send the data back to parent
    add(event) {
        this.addButton.emit(event);
    }

    delete() {
        this.deleteButton.emit();
    }
}
