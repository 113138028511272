<div fxLayout="column" style="width: 100%; flex-grow: 1" class="defk-prostheta-dialog">
    <div mat-dialog-title class="primary">
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 45%">
            <span>Στοιχεία Πρόσθετης Καταχώρησης</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="column" mat-dialog-content style="
    overflow-y: auto;
    min-height: 100px;
    height: 100px;
    padding: 0 16px;
">




        <div style="width:100%; margin-bottom: 16px;" fxLayout="column" fxLayoutAlign="flex-start center" class="group">
            <h2>Στοιχεία Χειρόγραφης Καταχώρησης</h2>
            <table class="eAitisiHea twoColumns" [formGroup]="TaxesDeclaration">
                <tbody>
                    <tr>
                        <td>Εκ των Υστέρων Υποβολή</td>

                        <td>
                            <mat-checkbox (change)="onChange($event)" [(checked)]="checkboxChecked"
                                (click)="$event.stopPropagation()" [disabled]="formDisabled">
                            </mat-checkbox>
                        </td>
                    </tr>

                    <tr>
                        <td style="width:40%">Αριθμός Χειρόγραφης Καταχώρησης</td>
                        <td>
                            <mat-form-field appearance="outline" class="s-l sc-ul s-21">
                                <input matInput maxlength="18" formControlName="FallbackDocumentReference" />
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Τελωνείο Καταχώρησης</td>

                        <td>
                            <app-customs-control #OfficeOfOrigin [formDisabled]="formDisabled"
                                [customsGroup]="TaxesDeclaration" controlName="FallbackOffice" appearance="outline"
                                showCustomsName="true"></app-customs-control>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="width:100%; margin-bottom: 16px;" fxLayout="column" fxLayoutAlign="flex-start center" class="group">
            <h2>Γενικές Χημίκες Λεπτομέρειες</h2>
            <table class="eAitisiHea twoColumns" [formGroup]="TaxesDeclaration">
                <tbody>
                    <tr>
                        <td>Ένδειξη Χημικής Ανάλυσης</td>

                        <td>
                            <mat-form-field class="s-l sc-ul dont-float s-8" floatLabel="never" appearance="outline">
                                <mat-label>Επιλογή</mat-label>
                                <mat-select name="ChemicalAnalysisPerformedFlag"
                                    formControlName="ChemicalAnalysisPerformedFlag">
                                    <mat-option value="0">
                                        ΟΧΙ
                                    </mat-option>
                                    <mat-option value="1">
                                        ΝΑΙ
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </td>
                    </tr>

                    <tr>
                        <td style="width:40%">Αριθμός Πρωτοκόλλου Δειγματοληψίας</td>
                        <td>
                            <mat-form-field appearance="outline" class="s-l sc-ul s-18" [formGroup]="ChemistryDetails">
                                <input matInput maxlength="14" formControlName="SampleReference" />
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Αριθμός Δ.Χ.Α.</td>


                        <td>
                            <mat-form-field appearance="outline" class="s-l sc-ul s-18" [formGroup]="ChemistryDetails">
                                <input matInput maxlength="14" formControlName="AnalysisReference" />
                            </mat-form-field>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="width:100%; margin-bottom: 16px;" fxLayout="column" fxLayoutAlign="flex-start center" class="group">
            <h2>Πιστοποιητικά</h2>
            <table class="eAitisiHea twoColumns" [formGroup]="TaxesDeclaration">
                <tbody>
                    <tr>
                        <td>Ένδειξη Δημιουργίας Πιστοποιητικών</td>

                        <td>
                            <mat-form-field class="s-l sc-ul dont-float s-8" floatLabel="never" appearance="outline">
                                <mat-label>Επιλογή</mat-label>
                                <mat-select name="CertificateIssuanceFlag" formControlName="CertificateIssuanceFlag">
                                    <mat-option value="0">
                                        ΟΧΙ
                                    </mat-option>
                                    <mat-option value="1">
                                        ΝΑΙ
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <div>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="cancel()">
                Ακυρο
            </button>
            <button style="width: auto !important" mat-raised-button [ngClass]="color2"
                (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onCloseConfirm()">
                OK
            </button>
        </mat-dialog-actions>
    </div>
</div>