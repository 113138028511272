import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, Injector, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { countriesItem } from 'app/model/api-model';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MainBroadcasterService } from 'app/services/broadcaster.service';

@Component({
    selector: 'app-generic-country',
    templateUrl: './generic-country.component.html',
    styleUrls: ['./generic-country.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericCountryComponent {
    @ViewChild('firstInput') _first;

    @Input() formGroup: FormGroup;
    @Input() placeholder: string;
    @Input() controlName: string;
    @Input() longDesc: boolean;
    @Input() required: boolean;
    @Input() formDisabled: boolean;
    @Input() fullCountry: boolean;
    @Input() formFieldClasses: string;

    countryName: string;
    options: countriesItem[];

    slave_control = new FormControl();


    private _destroy: Subject<void> = new Subject<void>();



    get control(): FormControl {
        return this.formGroup.get(this.controlName) as FormControl;
    }


    constructor(
        private hostElement: ElementRef,
        private customFunctions: ExtCustomFunctions,
        private mainBroadcaster: MainBroadcasterService
    ) {
        this.mainBroadcaster.countries$
            .pipe(take(1))
            .subscribe((data: countriesItem[]) => {
                this.options = data;

            });
    }

    ngOnChanges(changes: SimpleChanges) {
        const formDisabled = changes.formDisabled?.currentValue
        if (formDisabled === true) {
            this.control.disable()
            this.slave_control.disable()
        }
        else {
            this.control.enable()
            this.slave_control.enable()

            this.subscribeToValueChanges()
        }

        //console.log("COUNTRYNAME ngOnChanges formDisabled", formDisabled)
        //console.log("COUNTRYNAME ngOnChanges fullCountry", this.fullCountry)
    }
    ngOnInit() {

        if (!this.formFieldClasses) {
            this.formFieldClasses = 's-c s-2';
        }


        //===============================================================================================
        this.subscribeToValueChanges()
    }

    subscribeToValueChanges() {
        if (this.fullCountry) {


            this.slave_control.setValue(this.control.value)
            //console.log("COUNTRYNAME slave_control", this.slave_control.value)

            this.control.valueChanges.pipe(takeUntil(this._destroy)).subscribe(value => {
                //  console.log("COUNTRYNAME slave_CONTROL VALUE", value)
                this.slave_control.setValue(value)
            });
        }
        //=====================================================================================
        else {
            //if value on bis change stop following changes and updating from main document
            this.control.valueChanges.pipe(takeUntil(this._destroy))
                .subscribe((value) => {
                    //console.log("COUNTRYNAME valueChanges no fullCountry", value)
                    this.countryName = this.formGroup.get(this.controlName)?.value
                });
        }

    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    focus(event) {
        if (event.target.tagName === 'DIV') {
            this._first.inputRef.nativeElement.focus();
        }
    }

    onFocus() {

        this.customFunctions.onFocusEde(this.hostElement);

    }

}
