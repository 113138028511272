export const beerFieldMappings = {

    'customer': 'Ζυθοποιός',
    'taricCode': 'Κωδικός TARIC',
    'nationalProduct': 'Εθνικός Κωδικός',
    'quantity': 'Λίτρα',
    'platoExact': 'Plato',
    'produceValue': 'Κόστος Παραγωγής',
    'beerCalculationData': 'Δεδομένα Υπολογισμού',

};