export class ProDocModel {
    DocTypDC21?: string;
    DocRefDC23?: string;
    DocRefDCLNG?: string;
    DocInfDC1008?: string;
    DocInfDC1008LNG?: string;

    constructor(value?) {
        this.DocTypDC21 = value?.DocTypDC21 || null;
        this.DocRefDC23 = value?.DocRefDC23 || null;
        this.DocRefDCLNG = value?.DocRefDCLNG || null;
        this.DocInfDC1008 = value?.DocInfDC1008 || null;
        this.DocInfDC1008LNG = value?.DocInfDC1008LNG || null;
    }
}
