import { User, _pelatesItem, international_customersItem } from 'app/model/api-model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { eAitisiGooIteGdsModel } from '../gooitegds/gooitegds.model';
import { eAitisiHeaheaForm } from '../heahea/heahea-form.model';
import { eAitisiHeaheaModel } from '../heahea/heahea.model';
import { ImpcusoffModel } from '../impcusoff/impcusoff.model';
import { RefDeclModel } from '../refDecl/refDecl.model';
import { eAitisiTasksModel } from '../tasks/tasks.model';
import { eAitisiTaxCalcModel } from '../taxCalc/taxcalc.model';
import { eAitisiTradecModel } from '../tradec/tradec.model';
import { eAitisiTrarepModel } from '../trarep/trarep.model';

export class eAitisiDeclarationModel {
    _PRIVATE: PrivateControlsModel;



    MesSenMES3?: string;
    MesRecMES6?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    PriMES15?: string;
    TesIndMES18?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    CorIdeMES25?: string;

    HEAHEA?: eAitisiHeaheaModel;
    GOOITEGDS?: eAitisiGooIteGdsModel[];
    TRADEC?: eAitisiTradecModel;
    TRAREP?: eAitisiTrarepModel;
    REFDECL?: RefDeclModel[]; //Only on IB15A
    REFDEC?: RefDeclModel[]; //Only on IB13A
    TAXCALC?: eAitisiTaxCalcModel[];
    CUSOFF?: ImpcusoffModel;
    TASKS?: eAitisiTasksModel[];
    //
    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);
        this._PRIVATE = value?._PRIVATE || null;


        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.PriMES15 = value?.PriMES15 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = value?.MesTypMES20 || 'IB15A';
        this.CorIdeMES25 = value?.CorIdeMES25 || null;

        this.HEAHEA = value?.HEAHEA;
        this.GOOITEGDS = value?.GOOITEGDS;
        this.TRADEC = value?.TRADEC;
        this.TRAREP = value?.TRAREP;
        if (value?._PRIVATE.messageOut === 'IB13A') {
            this.REFDEC = value?.REFDECL;

        }
        this.REFDECL = value?.REFDECL;

        this.TAXCALC = value?.TAXCALC;
        this.CUSOFF = value?.CUSOFF;
        this.TASKS = value?.TASKS;
    }
}
