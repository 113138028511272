import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dao-prostheta',
    templateUrl: './dao-prostheta.component.html',
    styleUrls: ['./dao-prostheta.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DaoProsthetaComponent {
    VehicleArrivalFormGroup: FormGroup;

    color1: string;
    color2: string;

    dateType = 'yyyy-MM-dd';
    defaultDate: Date;
    formDisabled = false;
    checkboxChecked: boolean;

    get fallbackFlag(): FormControl {
        return this.VehicleArrivalFormGroup.get('Fallbackflag') as FormControl;
    }

    constructor(
        public dialogRef: MatDialogRef<DaoProsthetaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private DateTimeConvert: DateTransformPipe,
        private cf: ExtCustomFunctions

    ) {
    }


    ngOnInit() {

        this.VehicleArrivalFormGroup = this.data.declarationForm.get('Body').get('VehicleArrivalDeclarationRegistration').get('VehicleArrival') as FormGroup;

        console.log("VehicleArrivalFormGroup on dialogRef", this.VehicleArrivalFormGroup)

        if (this.VehicleArrivalFormGroup.status === "DISABLED") {
            this.formDisabled = true
        }
        else { this.formDisabled = false }
        console.log("form status on dialogRef", this.VehicleArrivalFormGroup.status)



        //=------------------------------------------------------------------


        //-----------------------------------------------



        //=========================================================

        if (this.fallbackFlag.value === 1) {
            this.checkboxChecked = true
        }
        else { this.checkboxChecked = false }

        //--------------------------------
        const date = this.VehicleArrivalFormGroup.get('RegistrationDate');
        //IF there is a value but form is disabled (submitted)
        // show saved Date
        if (date?.value) {
            this.defaultDate = this.DateTimeConvert.getDate(date.value);
        }

        //========================================================================



    }

    onChange(event) {

        if (event.checked === true) {
            this.fallbackFlag.setValue(1)
        }
        else {
            this.fallbackFlag.setValue(0)

        }

    }

    onCloseConfirm() {
        this.dialogRef.close();
    }
    cancel() {
        //   this.VehicleArrivalConsignee.get('VehicleTaxWarehouseReference').reset()
        //   this.VehicleArrivalConsignee.get('DeliveryPlace').reset()
        this.dialogRef.close();

    }
    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event)

    }
    changeStyle2(event) {
        if (this.VehicleArrivalFormGroup.valid) {
            this.color2 = this.cf.changeStyle2(this.VehicleArrivalFormGroup, event);
        }
    }


}
