import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { TsipouraForm } from 'app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura-form.model';
import { TsipouraModel } from 'app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura.model';
import { TsipouraService } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura.service';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import moment, { Moment } from 'moment';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
    selector: 'tsipoura-header',
    templateUrl: './tsipoura-header.component.html',
    styleUrls: ['./tsipoura-header.component.scss']
})
export class TsipouraHeaderComponent implements OnInit {

    tsipouraForm: FormGroup<TsipouraForm>
    pelates: Contact[] = []

    totalDays: number;


    pelConfig: AutoCompConfigValues = {
        display: { values: ['fullname'] },
        menuOptions: { options: ['fullname', 'afm'] },
        menuTitles: [null, 'ΑΦΜ: ']
    };

    private _destroy = new Subject<void>();


    constructor(private tsipouraService: TsipouraService, private mainBroadcaster: MainBroadcasterService
    ) { }

    ngOnInit() {
        this.mainBroadcaster.domecticCustomers$.pipe(take(1)).subscribe((pelates) => {
            this.pelates = pelates
        })

        this.tsipouraService.tsipouraForm$.pipe(takeUntil(this._destroy)).subscribe((form: FormGroup) => {

            this.tsipouraForm = form
            this.calculateTotalDays(form.value)
        })

        this.tsipouraForm.valueChanges.pipe(takeUntil(this._destroy)).subscribe((value: TsipouraModel) => {
            // console.log('TsipouraHeaderComponent valueChanges', value)
            this.calculateTotalDays(value)

        })
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    changeDateTimeEvent(event: MatDatepickerInputEvent<Date>, controlName) {
        const momentDate = event.value as unknown as moment.Moment;
        const jsDate = momentDate.toDate();
        this.tsipouraForm.get(controlName).setValue(jsDate);
        console.log("changeDateTimeEvent", jsDate)
    }

    calculateTotalDays(tsipouraForm: TsipouraModel) {

        const tsipouraTotals = tsipouraForm.tsipouraTotals;
        console.log("calculateTotalDays", tsipouraTotals)
        const daysTotal = tsipouraTotals.totalDays ?? 0
        console.log("calculateTotalDays", daysTotal)
        console.log("calculateTotalDays rondUP", Math.ceil(+daysTotal))

        //Round up to next integer
        this.totalDays = Math.ceil(+daysTotal)
    }

    createContact() {
        this.tsipouraService.createContact()
            .subscribe(result => {
                if (!result) {
                    return;
                }
                const newContact = result.createdContact;
                console.log('CREATED CONTACT', result);

                this.tsipouraForm.patchValue({
                    boilerOwner: newContact
                });
                //this.cd.detectChanges();
                //this.onCloseConfirm();
            });
    }

}
