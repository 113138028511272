import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { DEFKContactDetailsForm } from "../contactDetails/contactDetails-form.model";
import { DEFKContactDetailsModel } from "../contactDetails/contactDetails.model";
import { TaxesObligeeModel } from "./taxes-obligee.model";

export class TaxesObligeeForm {
    [key: string]: AbstractControl;

    ObligeeType: FormControl;
    ObligeeIdentificationType: FormControl;
    ObligeeIdentification: FormControl;
    ContactDetails: FormGroup;
    ObligeeObject: FormControl;

    /*

   */

    constructor(value: TaxesObligeeModel) {

        this.ObligeeType = new FormControl(value.ObligeeType);
        this.ObligeeIdentificationType = new FormControl(value.ObligeeIdentificationType);
        this.ObligeeIdentification = new FormControl(value.ObligeeIdentification);
        this.ContactDetails = new FormGroup(new DEFKContactDetailsForm(new DEFKContactDetailsModel(value.ContactDetails)));
        this.ObligeeObject = new FormControl(value.ObligeeObject);

    }
}
