import { AbstractControl, FormControl } from "@angular/forms";
import { ePaymentContainModel } from "./e-payment-contain.model";

export class ePaymentContainForm {
    [key: string]: AbstractControl;

    bmrn: FormControl;
    blrn: FormControl;
    bcnt: FormControl;
    bafm: FormControl;
    /*

   */

    constructor(value: ePaymentContainModel) {

        this.bmrn = new FormControl(value.bmrn);
        this.blrn = new FormControl(value.blrn);
        this.bcnt = new FormControl(value.bcnt);
        this.bafm = new FormControl(value.bafm);

    }
}
