import { saveAs } from 'file-saver';
import { VehiclesQueriesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicle-queries.service';
import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { startWith, tap, filter, Observable, of, switchMap, takeUntil, Subject } from 'rxjs';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { ExciseHelpersEuroClass } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.interfaces';

@Injectable()
export class TrucksService {

    vehiclesDestroy$: Subject<void>;
    euroClassOptions: ExciseHelpersEuroClass[] = []
    truckTypes = [
        { code: 'dcCar', desc: 'Διπλοκάμπινο Επιβατικό' },
        { code: 'dcTruck', desc: 'Διπλοκάμπινο Φορτηγό' },
    ]
    constructor(private cf: ExtCustomFunctions, private vehiclesQueriesService: VehiclesQueriesService) {
        console.trace("TrucksService constructor")
        this.vehiclesDestroy$ = this.vehiclesQueriesService.destroy$

        this.vehiclesQueriesService.euroClassOptions$.pipe(takeUntil(this.vehiclesDestroy$)).subscribe(euroClassOptions => {
            this.euroClassOptions = euroClassOptions
        })
    }

    disableControls(vehiclesForm: FormGroup) {

        const vehicleType = vehiclesForm.get('vehicleType').value

        if (vehicleType !== '8') {
            vehiclesForm.get('chassisType').disable()

        }


        vehiclesForm.get('carbonDioxideEmissions').disable()
        vehiclesForm.get('engineCapacity').disable()

        vehiclesForm.get('monthsTotal').disable()
        vehiclesForm.get('ageReductionRate').disable()
        vehiclesForm.get('mileageReductionRate').disable()
        vehiclesForm.get('totalReductionRate').disable()

        vehiclesForm.get('co2IncreaseRate').disable()
        vehiclesForm.get('hybridReductionRate').disable()
    }


    calculateIncreasedTaxRateForCategory(calculationData, carCertificateIncreaseRate) {

        calculationData.controls.forEach((group: FormGroup) => {
            const taxRateInitial = +group.get('taxRateInitial').value || 0;
            const taxRateIncreasedByCategory = taxRateInitial * carCertificateIncreaseRate;
            const result = taxRateInitial + taxRateIncreasedByCategory
            console.log("carsService calculateIncreasedTaxRateForCategory", taxRateInitial, carCertificateIncreaseRate, taxRateIncreasedByCategory)
            console.log("carsService calculateIncreasedTaxRateForCategory", result)
            group.get('taxRateIncreasedByCategory').setValue(result);
        })

    }


    calculateTaxes(vehiclesForm: FormGroup) {


        const calculationData = vehiclesForm.get('carsCalculationData') as FormArray;
        const carCertificateIncreaseRate = vehiclesForm.get('carCertificateIncreaseRate').value || 0

        this.calculateIncreasedTaxRateForCategory(calculationData, carCertificateIncreaseRate)
        this.calculateSubTax(calculationData)

        this.calculateTotals(vehiclesForm)

    }

    calculateSubTax(calculationData: FormArray) {
        calculationData.controls.forEach((group: FormGroup) => {
            const splitRetailValue = +group.get('splitRetailValue').value || 0;
            const taxRateIncreasedByCategory = +group.get('taxRateIncreasedByCategory').value
            const result = splitRetailValue * taxRateIncreasedByCategory
            group.get('subGroupTax').setValue(result);
        })
    }

    calculateTotals(vehiclesForm: FormGroup) {

        const carsCalculationData = vehiclesForm.get('carsCalculationData') as FormArray;

        const totals = carsCalculationData.controls.reduce((acc, group: FormGroup) => {
            acc.subTotal += +group.get('subGroupTax').value || 0;
            return acc;
        }, {
            subTotal: 0,
        });

        const carsTotals = vehiclesForm.get('carsTotals') as FormGroup;

        carsTotals.get('subTotal').setValue(this.cf.formatDecimalString(totals.subTotal, 2));

        const envTax = +carsTotals.get('environmentTax').value || 0;
        const taxTotal = totals.subTotal + envTax;

        carsTotals.get('taxTotal').setValue(taxTotal);

    }

    subToRetailValue(vehiclesForm: FormGroup) {

        const retailValueBeforeTax$ = vehiclesForm.get('retailValueBeforeTax').valueChanges.pipe(startWith(vehiclesForm.get('retailValueBeforeTax').value));

        return retailValueBeforeTax$.pipe(
            tap((retailValueBeforeTax) => { console.log("carsService subToRetailValue before filter", retailValueBeforeTax) }),
            filter((retailValueBeforeTax) => Boolean(retailValueBeforeTax)),
            tap((retailValueBeforeTax) => {
                console.log("trucksService subToRetailValue", retailValueBeforeTax);
                (vehiclesForm.get('carsCalculationData') as FormArray).at(0).get('splitRetailValue').setValue(retailValueBeforeTax);
                this.calculateTaxes(vehiclesForm)
            })
        )
    }

    subToCarCertificateValueChanges(vehiclesForm: FormGroup): Observable<string> {

        const carCertificateCategory$ = vehiclesForm.get('carCertificateCategory').valueChanges.pipe(startWith(vehiclesForm.get('carCertificateCategory').value));

        return carCertificateCategory$.pipe(tap((carCertificateCategory) => {

            const category = this.euroClassOptions.find((item) => item.code === carCertificateCategory)
            const categoryIncreaseRate = category?.categoryIncreaseRate || 0
            const environmentTax = category?.environment_tax || 0


            console.log("subToCarCertificateValueChanges carCertificateCategory", carCertificateCategory)
            console.log("subToCarCertificateValueChanges vehiclesForm", vehiclesForm.value)

            vehiclesForm.get('carCertificateIncreaseRate').setValue(categoryIncreaseRate)
            vehiclesForm.get('carsTotals').get('environmentTax').setValue(environmentTax)
            this.calculateTaxes(vehiclesForm)
        }))
    }


    subToChassisTypeValueChanges(vehiclesForm: FormGroup): Observable<any> {

        const chassisType$ = vehiclesForm.get('chassisType').valueChanges.pipe(startWith(vehiclesForm.get('chassisType').value));

        return chassisType$
            .pipe(
                filter((chassisType) => Boolean(chassisType)),

                tap((chassisType) => {
                    console.log("subToChassisTypeValueChanges chassisType", chassisType)
                    this.setAddTaricCode(vehiclesForm)

                    if (chassisType === 'dcCar') {
                        this.enableControlsFor1936(vehiclesForm)
                        this.vehiclesQueriesService.getCarEuroClassOptions().subscribe()
                    }
                    else {
                        this.disableControlsFor1936(vehiclesForm)
                        this.vehiclesQueriesService.getTruckEuroClassOptions().subscribe()
                    }
                })
            )
    }

    setAddTaricCode(vehiclesForm: FormGroup) {

        let addTaricCode = '';
        const chassisType = vehiclesForm.get('chassisType').value

        switch (vehiclesForm.get('vehicleType').value) {

            case '2': {  // Φορτηγά > 3.5tn
                addTaricCode = '1908';
                break;
            }

            case '19': {  // Βασεις Φορτηγων > 3.5tn
                addTaricCode = '1909';
                break;
            }
            case '8': {  // Ανοικτα Φορτηγά <= 3.5tn

                addTaricCode = chassisType === 'dcCar' ? '1936' : '1901';
                break;
            }
            case '7': {  // Κλειστά Φορτηγά <= 3.5tn
                addTaricCode = '1935';
                break;
            }
            case '20': {  // Βασεις Φορτηγων <= 3.5tn
                addTaricCode = '1907';
                break;
            }

        }
        vehiclesForm.get('addTaricCode').setValue(addTaricCode)
    }

    setInitialTaxes(vehiclesForm: FormGroup) {
        const vehicleType = vehiclesForm.get('vehicleType').value

        let initialTaxRate = 0;
        switch (vehicleType) {

            case '2':
            case '19':
            case '20': {  // Φορτηγά > 3.5tn
                initialTaxRate = 0.05;
                break;
            }
            case '8': {  // Φορτηγά > 3.5tn
                initialTaxRate = 0.08;
                break;
            }
            case '7': {  // Φορτηγά > 3.5tn
                initialTaxRate = 0.10
                break;
            }

        }
        this.vehiclesQueriesService.updatePriceRanges([{ price_min: null, price_max: null, price_range: null, initial_tax_rate: initialTaxRate }]);

        (vehiclesForm.get('carsCalculationData') as FormArray).at(0).get('taxRateInitial').setValue(initialTaxRate);

    }


    enableControlsFor1936(vehiclesForm: FormGroup) {
        vehiclesForm.get('carbonDioxideEmissions').enable()
        vehiclesForm.get('monthsTotal').enable()
        vehiclesForm.get('ageReductionRate').enable()
        vehiclesForm.get('mileageReductionRate').enable()
        vehiclesForm.get('totalReductionRate').enable()

    }

    disableControlsFor1936(vehiclesForm: FormGroup) {

        vehiclesForm.get('carCertificateCategory').reset()
        vehiclesForm.get('carbonDioxideEmissions').reset()
        vehiclesForm.get('monthsTotal').reset()
        vehiclesForm.get('ageReductionRate').reset()
        vehiclesForm.get('mileageReductionRate').reset()
        vehiclesForm.get('totalReductionRate').reset()

        vehiclesForm.get('carbonDioxideEmissions').disable()
        vehiclesForm.get('monthsTotal').disable()
        vehiclesForm.get('ageReductionRate').disable()
        vehiclesForm.get('mileageReductionRate').disable()
        vehiclesForm.get('totalReductionRate').disable()

    }

}
