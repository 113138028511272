import { AutoCompleteControlComponent } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/autocomplete-control.component';
import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { BeerForm } from 'app/main/apps/excise-helper-tables/beer/_models/beer-form.model';
import { BeerParameters } from 'app/main/apps/excise-helper-tables/beer/beer-defk-create.service';
import { BeerService } from 'app/main/apps/excise-helper-tables/beer/beer.service';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { NationalProduct, genericItem } from 'app/model/api-model';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';

@Component({
    selector: 'beer-calculation-data',
    templateUrl: './beer-calculation-data.component.html',
    styleUrls: ['./beer-calculation-data.component.scss']
})
export class BeerCalculationDataComponent implements OnInit {
    @Input() products: genericItem[] = [];
    @ViewChildren('firstInput') _first: QueryList<AutoCompleteControlComponent>;
    beerForm: FormGroup<BeerForm>
    productCodes: genericItem[] = []
    filteredNationalProducts$: Observable<NationalProduct[][]>;


    config: AutoCompConfigValues = {

        output: 'natCode',
        menuOptions: { options: ['natCode', 'description'] },
    }


    get beerCalculationData(): FormArray {
        return this.beerForm.get('beerCalculationData') as FormArray;
    }
    get beerTotals(): FormGroup {
        return this.beerForm.get('beerTotals') as FormGroup;
    }

    private _destroy = new Subject<void>();

    constructor(private beerService: BeerService, private cf: ExtCustomFunctions) {


    }

    ngOnInit() {

        this.filteredNationalProducts$ = this.beerService.filteredNationalProducts$


        this.beerService.beerForm$.subscribe((form) => {

            this.beerForm = form

            this.beerCalculationData.controls.forEach((group, index) => {

                this.beerService.subToQuantityValueChanges(group as FormGroup)
                this.beerService.subToPlatoValueChanges(group as FormGroup)
                this.beerService.subToProduceValueValueChanges(group as FormGroup)
                this.beerService.subToTaricAndPlatoValueChanges(group as FormGroup, index)
            })
        })
    }

    ngOnDestroy() {
        this.beerService.quantitySubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
        this.beerService.platoSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
        this.beerService.produceValueSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        }); this.beerService.taricAndPlatoSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });

    }


    addProducer() {
        this.beerService.addProducer()
        setTimeout(() => {
            this._first.last.inputRef.nativeElement.focus()
        })
    }
    deleteProducer(index: number) {
        this.beerService.deleteProducer(index)
    }

    hideAdd() {
        return this.cf.hideAdd(this.beerCalculationData, 99);
    }
    hideDelete() {
        return this.cf.hideDelete(this.beerCalculationData);
    }

    formatDecimals(control: FormControl) {
        if (control.value) {
            this.cf.formatDecimalDigits(control, 2)
        }
    }

}
