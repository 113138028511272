export class eAitisiTasksModel {
    FlatRate?: string;
    CustomsTaskType?: string;
    CstPerHour?: number;
    CstPerKlmtr?: number;
    NumHours?: number;
    NumMnts?: number;
    NumKlmtrs?: number;
    Amount?: number;

    constructor(value?) {
        this.FlatRate = value?.FlatRate;
        this.CustomsTaskType = value?.CustomsTaskType || null;
        this.CstPerHour = value?.CstPerHour;
        this.CstPerKlmtr = value?.CstPerKlmtr;
        this.NumHours = value?.NumHours;
        this.NumMnts = value?.NumMnts;
        this.NumKlmtrs = value?.NumKlmtrs;
        this.Amount = value?.Amount;
    }
}
