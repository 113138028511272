import { AccountingModel } from 'app/main/apps/export/declaration/_models/gooitegds/accounting/accounting.model';
import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { Connr2Model } from 'app/main/apps/export/declaration/_models/gooitegds/connr2/connr2.model';
import { TraConce2Model } from 'app/main/apps/export/declaration/_models/gooitegds/traconce2/traconce2.model';
import { Traconco2Model } from 'app/main/apps/import/_models/gooitegds/traconco2/traconco2.model';
import { NctsSpeMenModel } from '../../../_models/spemenmt2/spemenmt2.model';
import { TirPacgsModel } from '../pacgs2/pacgs2.model';
import { TirPreAdmRefModel } from '../preadmrefar2/preadmrefar2.model';
import { TirProDocModel } from '../prodocdc2/prodocdc2.model';
import { Sgicodsd2Model } from '../sgicodsd2/sgicodsd2.model';
import { Traconsecgoo013Model } from '../traconsecgoo013/traconsecgoo013.model';
import { Tracorsecgoo021Model } from '../tracorsecgoo021/tracorsecgoo021.model';

export class TirGooIteGdsModel {
    IteNumGDS7?: number;
    GooDesGDS23LNG?: string;
    GroMasGDS46?: number;
    NetMasGDS48?: number;
    CouOfDisGDS58?: string;
    CouOfDesGDS59?: string;
    MetOfPayGDI12?: string;
    ComRefNumGIM1?: string;
    UNDanGooCodGDI1?: string;
    StaValGDS500?: number;
    GooDesGDS23?: string;
    DecTypGDS15?: string;
    ComCodTarCodGDS10?: string;
    PREADMREFAR2?: TirPreAdmRefModel[];
    PRODOCDC2?: TirProDocModel[];
    SPEMENMT2?: NctsSpeMenModel[];
    TRACONCO2?: Traconco2Model[];
    TRACONCE2?: TraConce2Model[];
    CONNR2?: Connr2Model[];
    PACGS2?: TirPacgsModel[];
    SGICODSD2?: Sgicodsd2Model[];
    TRACORSECGOO021?: Tracorsecgoo021Model[];
    TRACONSECGOO013?: Traconsecgoo013Model[];

    COMCODGODITM?: ComCodModel[]; //<=======FOR INTERNAL USE INLY REMOVE ON REMOVEPRIVATE
    //For CalculationPurposes ONLY
    ACCOUNTING?: AccountingModel[];



    constructor(value?) {
        if (value) {
            this.IteNumGDS7 = value.IteNumGDS7;
            this.GooDesGDS23LNG = value.GooDesGDS23LNG;
            this.GroMasGDS46 = value.GroMasGDS46;
            this.NetMasGDS48 = value.NetMasGDS48;
            this.CouOfDisGDS58 = value.CouOfDisGDS58;
            this.CouOfDesGDS59 = value.CouOfDesGDS59;
            this.MetOfPayGDI12 = value.MetOfPayGDI12;
            this.ComRefNumGIM1 = value.ComRefNumGIM1;
            this.UNDanGooCodGDI1 = value.UNDanGooCodGDI1;
            this.StaValGDS500 = value.StaValGDS500;
            this.GooDesGDS23 = value.GooDesGDS23;
            this.DecTypGDS15 = value.DecTypGDS15;
            this.ComCodTarCodGDS10 = value.ComCodTarCodGDS10;
            this.PREADMREFAR2 = value.PREADMREFAR2;
            this.PRODOCDC2 = value.PRODOCDC2;
            this.SPEMENMT2 = value.SPEMENMT2;
            this.TRACONCO2 = value.TRACONCO2;
            this.TRACONCE2 = value.TRACONCE2;
            this.CONNR2 = value.CONNR2;
            this.PACGS2 = value.PACGS2;
            this.SGICODSD2 = value.SGICODSD2;
            this.TRACORSECGOO021 = value.TRACORSECGOO021;
            this.TRACONSECGOO013 = value.TRACONSECGOO013;
            this.COMCODGODITM = value.COMCODGODITM;
            this.ACCOUNTING = value.ACCOUNTING;

        }
    }
}
