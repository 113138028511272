export class SpecialConsigneeModel {
    SpecialConsigneeLicenseType?: string;
    SpecialConsigneeLicenseNumber?: string;

    constructor(value?) {
        this.SpecialConsigneeLicenseType = value?.SpecialConsigneeLicenseType || null;
        this.SpecialConsigneeLicenseNumber = value?.SpecialConsigneeLicenseNumber || null;

    }
}
