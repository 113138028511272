import { FormControl } from '@angular/forms';
import { Tracorsecgoo021Model } from './tracorsecgoo021.model';

export class Tracorsecgoo021Form {
    [key: string]: FormControl;

    NamTRACORSECGOO025 = new FormControl();
    StrNumTRACORSECGOO027 = new FormControl();
    PosCodTRACORSECGOO026 = new FormControl();
    CitTRACORSECGOO022 = new FormControl();
    CouCodTRACORSECGOO023 = new FormControl();
    TRACORSECGOO021LNG = new FormControl();
    TINTRACORSECGOO028 = new FormControl();
    TINTypTRACORSECGOO029 = new FormControl();

    ExporterObject = new FormControl();

    constructor(value: Tracorsecgoo021Model) {
        this.NamTRACORSECGOO025.setValue(value.NamTRACORSECGOO025);
        this.StrNumTRACORSECGOO027.setValue(value.StrNumTRACORSECGOO027);
        this.PosCodTRACORSECGOO026.setValue(value.PosCodTRACORSECGOO026);
        this.CitTRACORSECGOO022.setValue(value.CitTRACORSECGOO022);
        this.CouCodTRACORSECGOO023.setValue(value.CouCodTRACORSECGOO023);
        this.TRACORSECGOO021LNG.setValue(value.TRACORSECGOO021LNG);
        this.TINTRACORSECGOO028.setValue(value.TINTRACORSECGOO028);
        this.TINTypTRACORSECGOO029.setValue(value.TINTypTRACORSECGOO029);
        this.ExporterObject.setValue(value.ExporterObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
