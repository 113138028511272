import { Component, Input, OnInit } from '@angular/core';
import { countriesItem, customsAllItem, genericItem } from 'app/model/api-model';
import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { CustomsService } from '../customs.service';

@Component({
    selector: 'app-customs-dialog-content',
    templateUrl: './customs-dialog-content.component.html',
    styleUrls: ['./customs-dialog-content.component.scss']
})
export class CustomsDialogContentComponent implements OnInit {
    @Input() co: customsAllItem;
    @Input() region: any;
    @Input() index: number;

    countries: countriesItem[];
    languages: genericItem[];
    entryDate: string;

    location: any;
    constructor(private dateTransform: DateTransformPipe, private customsService: CustomsService) { }

    ngOnInit(): void {
        this.location = this.co.CustomsOfficeLsd[this.index];
        console.log(this.co);
        console.log(this.location);

        this.entryDate = this.dateTransform.formatDate(this.co.NctsEntryDate, 'dd/mm/yyyy');

        console.log('Region_' + this.index + ' : ', this.region);
    }

    sortTimetableLine(timetableLine) {
        return timetableLine.sort((a, b) => a.DayInTheWeekBeginDay.localeCompare(b.DayInTheWeekBeginDay));
    }

    goToOffice(officeRefNumber) {
        if (this.co.ReferenceNumber !== officeRefNumber) {
            this.customsService.openCustomsDialog(officeRefNumber);
        }
    }
}
