<div fxLayout="column" style="width: 100%; flex-grow: 1">

    <div mat-dialog-title class="primary" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <div>Εκτύπωση ΕΔΕ-{{edeNumber}}</div>


        </div>

        <div>
            <button mat-icon-button (click)="dialogRef.close()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content style="font-size:15px" fxLayout="column" fxLayoutAlign="flex-start center" style="
overflow-y: auto;
min-height: 100px;
height: 100px;
padding: 0 16px;">

        <ng-container *ngFor="let tab of tabs">

            <button mat-raised-button class="accent" style="width:200px; margin:10px 0"
                (click)="dialogRef.close({document:tab.title, documentType: tab.tabData.innerTitle})">
                Εκτύπωση {{tab.tabData.innerTitle}}</button>
        </ng-container>

        <div class="group" style="width:80%;height:150px;flex-grow: 0; background:wheat">
            <h3>Ρυθμίσεις εκτυπωτή</h3>
            <div>Ο εκτυπωτής πρεπει να εχει τις ακόλουθες ρυθμίσεις για εκτύπωση σελιδάς:</div>
            <div><b>Περιθώρια (Margins): Κανένα (none)</b></div>
        </div>
    </mat-dialog-content>

</div>