export class MeaOfPro200Model {
    DIPRecIDMEAOFPRO1?: string;
    DesMeaofProMEAOFPRO2?: string;
    QuaMEAOFPRO3?: string;

    constructor(value?) {
        this.DIPRecIDMEAOFPRO1 = value?.DIPRecIDMEAOFPRO1 || null;
        this.DesMeaofProMEAOFPRO2 = value?.DesMeaofProMEAOFPRO2 || null;
        this.QuaMEAOFPRO3 = value?.QuaMEAOFPRO3 || null;
    }
}
