import { Component, Input, OnInit } from '@angular/core';
import { ImportConnectService } from 'app/services/import-connect.service';
import { Subject } from 'rxjs';
import { TariffDutiesResults, TariffFinalResponse } from '../_models';
@Component({
    selector: 'app-tariff-calculate-results',
    templateUrl: './tariff-calculate-results.component.html',
    styleUrls: ['./tariff-calculate-results.component.scss']
})
export class TariffCalculateResultsComponent implements OnInit {

    @Input() data: TariffFinalResponse;
    color1 = '';
    color2 = '';

    private _destroy: Subject<void> = new Subject<void>();

    calculationData: any[];
    duties: TariffDutiesResults;
    uvDuties: TariffDutiesResults;
    uvSecurity: TariffDutiesResults;
    errors: string[];
    info: string[];
    constructor(private importConnectService: ImportConnectService) { }

    ngOnInit(): void {


        this.calculationData = this.data.calculationData;
        this.duties = this.data.duties;
        this.uvDuties = this.data.uvDuties;
        this.uvSecurity = this.data.uvSecurity
        this.errors = this.data.errors;
        this.info = this.data.info;

    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        console.log('ARRATEXIT COMP DESTROYED');

        this._destroy.next();
        this._destroy.complete();
    }
}
