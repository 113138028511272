export class ContactDetailsModel {

    PersonIdentification: string;
    LastNames: string;
    Forenames: string;
    NameOfFather: string;
    StreetName: string;
    StreetNumber: string;
    Municipality?: string;
    PostCode?: string;
    Prefecture?: string;
    Country: string;

    constructor(value?) {

        this.PersonIdentification = value?.PersonIdentification || null;
        this.LastNames = value?.LastNames || null;
        this.Forenames = value?.Forenames || null;
        this.NameOfFather = value?.NameOfFather || null;
        this.StreetName = value?.StreetName || null;
        this.StreetNumber = value?.StreetNumber || null;
        this.Municipality = value?.Municipality;
        this.PostCode = value?.PostCode;
        this.Prefecture = value?.Prefecture;
        this.Country = value?.Country || null;
    }

}
