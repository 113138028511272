import { Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { GoodsPrivateForm, PrivateControlsFormModel } from "app/model/private-controls-form.model";
import { GoodsPrivateModel } from "app/model/private-controls.model";

@Injectable({
    providedIn: 'root'
})
export class PrivateControlsService {
    constructor() { }


    addPrivateGroup(privateCtrlsGroup: FormGroup<PrivateControlsFormModel>, data?: GoodsPrivateModel) {
        const currentFormArray = privateCtrlsGroup.get('goodsPrivate') as FormArray;


        currentFormArray.push(new FormGroup(new GoodsPrivateForm(new GoodsPrivateModel(data))));

    }
    deletePrivateGroup(privateCtrlsGroup: FormGroup<PrivateControlsFormModel>, index: number) {
        const currentGoodsPrivate = privateCtrlsGroup.get('goodsPrivate') as FormArray;

        currentGoodsPrivate.removeAt(index);

    }

}
