export class Traconsecgoo013Model {
    NamTRACONSECGOO017?: string;
    StrNumTRACONSECGOO019?: string;
    PosCodTRACONSECGOO018?: string;
    CityTRACONSECGOO014?: string;
    CouCodTRACONSECGOO015?: string;
    TRACONSECGOO013LNG?: string;
    TINTRACONSECGOO020?: string;
    TINTypTRACONSECGOO021?: string;
    ExporterObject?: {};

    constructor(value?) {
        this.NamTRACONSECGOO017 = value?.NamTRACONSECGOO017 || null;
        this.StrNumTRACONSECGOO019 = value?.StrNumTRACONSECGOO019 || null;
        this.PosCodTRACONSECGOO018 = value?.PosCodTRACONSECGOO018 || null;
        this.CityTRACONSECGOO014 = value?.CityTRACONSECGOO014 || null;
        this.CouCodTRACONSECGOO015 = value?.CouCodTRACONSECGOO015 || null;
        this.TRACONSECGOO013LNG = value?.TRACONSECGOO013LNG || null;
        this.TINTRACONSECGOO020 = value?.TINTRACONSECGOO020 || null;
        this.TINTypTRACONSECGOO021 = value?.TINTypTRACONSECGOO021 || null;
        this.ExporterObject = value?.ExporterObject || {};
    }
}
