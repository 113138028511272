import { Declarant } from 'app/core/user/user.model';
export class ReprepModel {
    NamREP5?: string;
    RepCapREP18?: string; // 35
    RepCapREP18LNG?: string;
    DeclarantObject?: Declarant;

    constructor(value?) {
        this.NamREP5 = value?.NamREP5 || null;
        this.RepCapREP18 = value?.RepCapREP18 || null;
        this.RepCapREP18LNG = value?.RepCapREP18LNG || null;
        this.DeclarantObject = value?.DeclarantObject;
    }
}
