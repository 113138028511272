
export class DistillationLicenceAppModel {
    //This is the model of the object that will be passed on our form in case we have some initial values that need to be inserted

    period?: string;
    defkMRN?: string;
    customsName?: string;
    producerDetails?: DistillationContactDetailsModel;
    startHours?: string;
    startDate?: string;
    endHours?: string;
    endDate?: string;
    harvestDeclaration?: string;
    previousQuantityLpa?: string;
    previousQuantityGross?: string;
    previousQuantityAlcoholTitle?: string;
    previousQuantityLocation?: string;
    previousQuantityLpaRights?: string;
    previousQuantityGrossRights?: string;
    previousQuantityAlcoholTitleRights?: string;
    previousQuantityLocationRights?: string;

    ownStems?: DistillationInfoModel;
    returnedStems?: DistillationInfoModel;
    otherRawMaterials?: string;
    grapes?: DistillationInfoModel;
    winery?: WineryInfoModel;
    boilerInfo?: BoilerInfoModel;
    unsealDate?: string;
    date?: string;



    constructor(value?: DistillationLicenceAppModel) {

        this.period = value?.period || null;
        this.defkMRN = value?.defkMRN || null;
        this.customsName = value?.customsName || null;
        this.producerDetails = value?.producerDetails || null;
        this.startHours = value?.startHours || null;
        this.startDate = value?.startDate || null;
        this.endHours = value?.endHours || null;
        this.endDate = value?.endDate || null;
        this.harvestDeclaration = value?.harvestDeclaration || null;
        this.previousQuantityLpa = value?.previousQuantityLpa || null;
        this.previousQuantityGross = value?.previousQuantityGross || null;
        this.previousQuantityAlcoholTitle = value?.previousQuantityAlcoholTitle || null;
        this.previousQuantityLocation = value?.previousQuantityLocation || null;
        this.previousQuantityLpaRights = value?.previousQuantityLpaRights || null;
        this.previousQuantityGrossRights = value?.previousQuantityGrossRights || null;
        this.previousQuantityAlcoholTitleRights = value?.previousQuantityAlcoholTitleRights || null;
        this.previousQuantityLocationRights = value?.previousQuantityLocationRights || null;
        this.ownStems = value?.ownStems || null;
        this.returnedStems = value?.returnedStems || null;
        this.otherRawMaterials = value?.otherRawMaterials || null;
        this.grapes = value?.grapes || null;
        this.winery = value?.winery || null;
        this.boilerInfo = value?.boilerInfo || null;
        this.unsealDate = value?.unsealDate || null;
        this.date = value?.date || null;
    }
}


export class DistillationContactDetailsModel {

    producerName?: string;
    producerId?: string;
    producerStreet?: string;
    producerStreetNumber?: string;
    producerPhone?: string;
    producerEmail?: string;

    constructor(value?: DistillationContactDetailsModel) {

        this.producerName = value?.producerName || null;
        this.producerId = value?.producerId || null;
        this.producerStreet = value?.producerStreet || null;
        this.producerStreetNumber = value?.producerStreetNumber || null;
        this.producerPhone = value?.producerPhone || null;
        this.producerEmail = value?.producerEmail || null;

    }

}

export class DistillationInfoModel {

    quantity?: string;
    litresPureAlcohol?: string;
    litresGross?: string;
    alcoholicTitle?: string;

    constructor(value?: DistillationInfoModel) {

        this.quantity = value?.quantity || null;
        this.litresPureAlcohol = value?.litresPureAlcohol || null;
        this.litresGross = value?.litresGross || null;
        this.alcoholicTitle = value?.alcoholicTitle || null;

    }
}
export class WineryInfoModel {

    wineryName?: string;
    wineryAfm?: string;
    wineryDocumentNumber?: string;
    wineryReturnedWith?: string;


    constructor(
        value?: WineryInfoModel
    ) {
        this.wineryName = value?.wineryName || null;
        this.wineryAfm = value?.wineryAfm || null;
        this.wineryDocumentNumber = value?.wineryDocumentNumber || null;
        this.wineryReturnedWith = value?.wineryReturnedWith || null;

    }

}

export class BoilerInfoModel {

    boilerRegistrationNumber?: string;
    boilerNumber?: string;
    boilerCapacity?: string;
    boilerOwnerName?: string;
    boilerOwnerId?: string;
    boilerGeoLocation?: string;

    constructor(
        value?: BoilerInfoModel
    ) {
        this.boilerRegistrationNumber = value?.boilerRegistrationNumber || null;
        this.boilerNumber = value?.boilerNumber || null;
        this.boilerCapacity = value?.boilerCapacity || null;
        this.boilerOwnerName = value?.boilerOwnerName || null;
        this.boilerOwnerId = value?.boilerOwnerId || null;
        this.boilerGeoLocation = value?.boilerGeoLocation || null;
    }
}