<table class="tsipoura-parameters">
    <colgroup>
        <col style="width:50%">
        <col style="width:15%">
    </colgroup>
    <thead>
        <tr>
            <th colspan="2">ΠΑΡΑΜΕΤΡΟΙ ΥΠΟΛΟΓΙΣΜΟΥ</th>
        </tr>
    </thead>
    <tbody *ngIf="tsipouraForm$ | async as tsipouraForm">
        <tr>
            <td>ΣΤΑΦΥΛΙΑ => Λ.Α ΑΙΘ. ΑΛΚΟΟΛΗΣ</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.grapesToLpa | percent" /></td>
            <td class="printOnly">{{params.grapesToLpa | percent}}</td>
        </tr>
        <tr>
            <td>ΣΤΑΦΥΛΙΑ => ΣΤΕΜΦΥΛΑ</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.grapesToStems | percent" /></td>
            <td class="printOnly">{{params.grapesToStems | percent}}</td>

        </tr>
        <tr>
            <td>ΣΤΕΜΦΥΛΑ => Λ.Α. ΑΙΘ. ΑΛΚΟΟΛΗΣ</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.stemsToLpa | percent" /></td>
            <td class="printOnly">{{params.stemsToLpa | percent}}</td>

        </tr>
        <tr>
            <td>ΠΛΗΡΟΤΗΤΑ ΑΜΒΥΚΑ</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.boilerFullCap | percent" /></td>
            <td class="printOnly">{{params.boilerFullCap | percent}}</td>

        </tr>
        <tr>
            <td>ΩΡΕΣ/ΚΑΖΑΝΙΑ</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.hoursPerBoiler | number:'1.1-1'" /></td>
            <td class="printOnly">{{params.hoursPerBoiler | number:'1.1-1'}}</td>

        </tr>
        <tr [formGroup]="tsipouraForm">
            <td>ΩΡΕΣ ΛΕΙΤΟΥΡΓΙΑΣ ΗΜΕΡΗΣΙΩΣ</td>
            <td class="noPrint text-center">
                <select style="padding: 0px 15px; background: white;" formControlName="workHoursPerDay">
                    <option *ngFor="let item of workHoursList" [value]="item">{{item}}</option>
                </select>

            </td>
            <td class="printOnly">{{ tsipouraForm.get('workHoursPerDay').value| number:'1.0-0'}}</td>

        </tr>
        <tr>
            <td>ΦΟΡΟΣ ΚΑΤ. ΟΙΝΟΠΝ. EUR/LPA</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.efkPerLpa | number:'1.1-1'" /></td>
            <td class="printOnly">{{params.efkPerLpa | number:'1.1-1'}}</td>

        </tr>
    </tbody>
</table>