import { FormControl, Validators } from '@angular/forms';
import { AceAceModel } from './aceace.model';

export class AceAceForm {
    [key: string]: FormControl;
    _countryCode = new FormControl();
    RefNumACE1 = new FormControl();

    constructor(value: AceAceModel) {
        this._countryCode.setValue(value._countryCode);
        this.RefNumACE1.setValue(value.RefNumACE1);

        this.RefNumACE1.setValidators([Validators.required]);
    }
}
