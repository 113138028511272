import { SubmittingOperatorModel } from "../../submittingOperator/submittingOperator.model";

export class VA10ABodyModel {

    VehicleArrivalDeclarationCancellation: VehicleArrivalDeclarationCancellationType;

    constructor(value?) {

        console.log("VA10ABodyModel value", value)

        this.VehicleArrivalDeclarationCancellation = value?.VehicleArrivalDeclarationCancellation || null
    }

}



export class VehicleArrivalDeclarationCancellationType {



    SubmittingOperator?: SubmittingOperatorModel;
    VehicleArrival: CancelVehicleArrival;

    constructor(value?) {

        this.SubmittingOperator = value?.SubmittingOperator;
        this.VehicleArrival = value?.VehicleArrival;
    }



}

export class CancelVehicleArrival {



    DeclarationReferenceNumber?: string;
    CancellationReason: string;

    constructor(value?) {

        this.DeclarationReferenceNumber = value?.DeclarationReferenceNumber;
        this.CancellationReason = value?.CancellationReason;
    }



}
