export class TirProDocModel {
    DocRefDCLNG?: string; //4
    ComOfInfDC25?: string; //35
    ComOfInfDC25LNG?: string;
    DocTypDC21?: string; //26
    DocRefDC23?: string;

    constructor(value?) {
        this.DocRefDCLNG = value?.DocRefDCLNG || null;
        this.ComOfInfDC25 = value?.ComOfInfDC25 || null;
        this.ComOfInfDC25LNG = value?.ComOfInfDC25LNG || null;
        this.DocTypDC21 = value?.DocTypDC21 || null;
        this.DocRefDC23 = value?.DocRefDC23 || null;
    }
}
