import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { ApiService } from 'app/services/api.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { of, Subject, takeUntil } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { PrintSettingsService } from './print-settings.service';
import { PrintSettingsModel } from './_models/print-settings.model';
import { OfficeService } from 'app/services/office.service';

@Component({
    selector: 'app-print-settings',
    templateUrl: './print-settings.component.html',
    styleUrls: ['./print-settings.component.scss']
})
export class PrintSettingsComponent implements OnInit {
    color1 = '';
    color2 = '';

    printSettings: PrintSettingsModel[];
    printSettingsForm: FormGroup;
    settings: FormArray;
    private _destroy: Subject<void> = new Subject();

    constructor(
        public dialogRef: MatDialogRef<PrintSettingsComponent>,
        private printSettingsService: PrintSettingsService,
        private mainBroadcaster: MainBroadcasterService
    ) { }

    ngOnInit(): void {
        this.printSettingsService.printForm$.pipe(takeUntil(this._destroy)).subscribe(form => {
            this.printSettingsForm = form;

            this.settings = this.printSettingsForm?.get('settings') as FormArray;

            console.log('PRINTsETTINGSfORM', this.printSettingsForm);
            console.log('PRINTsETTINGS', this.settings);
        });

        this.mainBroadcaster.printSettings$.pipe(
            switchMap(settings => {
                if (settings) {
                    this.printSettings = settings;
                }
                else {
                    this.printSettingsService.getSettings().subscribe()
                }
                return of(settings)

            })
            , takeUntil(this._destroy)).subscribe((value) => {
                console.log("PRINT SETTINGS$", value)
            });
    }
    ngOnDestroy() {
        this._destroy.next()
        this._destroy.complete()
    }
    changeStyle1(event) {
        this.color1 = event.type === 'mouseenter' ? 'accent' : '';
    }
    changeStyle2(event) {
        this.color2 = event.type === 'mouseenter' ? 'accent' : 'primary';
    }

    onCloseConfirm() {
        this.dialogRef.close();
    }
}
