<table class="cars-header-parameters" style="width:100%" [formGroup]="vehiclesForm">
    <colgroup>
        <col style="width:50%">
        <col style="width:40%">

    </colgroup>
    <tbody>

        <tr>
            <td class="text-left">Οδηγία Αντιρρυπ. Τεχνολογίας</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class=" excel-yellow text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('carCertificateCategory').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('carCertificateCategory').value" />
            </td>
            <td class="printOnly">{{vehiclesForm.get('carCertificateCategory').value}}</td>

        </tr>
        <tr>
            <td class="text-left">Προσαύξηση Κατηγορίας</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class=" excel-pink text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('carCertificateIncreaseRate').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('carCertificateIncreaseRate').value | percent" />
            </td>
            <td class="printOnly">{{vehiclesForm.get('carCertificateIncreaseRate').value | percent}}</td>

        </tr>
        <tr *ngIf="+vehiclesForm.get('vehicleType').value===10">
            <td class="text-left">Προσαύξηση Τροχόσπιτου</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class=" excel-pink text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('caravanIncreaseRate').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('caravanIncreaseRate').value | percent" />
            </td>
            <td class="printOnly">{{vehiclesForm.get('caravanIncreaseRate').value | percent}}</td>

        </tr>
        <tr>
            <td class="text-left">Πρόσθετος Κωδικός CO2</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class=" excel-yellow text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('addTaricCode').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('addTaricCode').value" />
            </td>
            <td class="printOnly">{{vehiclesForm.get('addTaricCode').value}}</td>

        </tr>
        <tr>
            <td class="text-left">Προσαύξηση λόγω CO2</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class="excel-pink text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('co2IncreaseRate').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('co2IncreaseRate').value | percent:'1.2-2'" />
            </td>
            <td class="printOnly">{{vehiclesForm.get('co2IncreaseRate').value | percent:'1.2-2'}}</td>

        </tr>


    </tbody>
</table>