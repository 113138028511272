import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { VehiclesModel } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.model';
import { VehiclesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicles.service';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { genericItem } from 'app/model/api-model';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
    selector: 'cars-header',
    templateUrl: './cars-header.component.html',
    styleUrls: ['./cars-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CarsHeaderComponent implements OnInit {

    @Input() vehiclesForm: FormGroup;
    @Input() vehicleTypeOptions: genericItem[] = [];
    @Input() carChassisOptions: genericItem[] = [];

    // vehiclesForm: FormGroup<VehiclesForm>
    pelates: Contact[] = []

    totalDays: number;


    pelConfig: AutoCompConfigValues = {
        display: { values: ['fullname'] },
        menuOptions: { options: ['fullname', 'afm'] },
        menuTitles: [null, 'ΑΦΜ: ']
    };

    private _destroy = new Subject<void>();


    constructor(private vehiclesService: VehiclesService, private mainBroadcaster: MainBroadcasterService
    ) {
        console.log("VehiclesComponentsRace CarsHeaderComponent")

    }

    ngOnInit() {
        this.mainBroadcaster.domecticCustomers$.pipe(take(1)).subscribe((pelates) => {
            this.pelates = pelates
        })

        this.vehiclesService.vehiclesForm$.pipe(takeUntil(this._destroy)).subscribe((form: FormGroup) => {

            this.vehiclesForm = form
            console.log("VehiclesComponentsRace CarsHeaderComponent vehiclesForm", this.vehiclesForm.value)
        })

        this.vehiclesForm.valueChanges.pipe(takeUntil(this._destroy)).subscribe((value: VehiclesModel) => {
            console.log('CarsHeaderComponent valueChanges', value)

        })
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    changeDateTimeEvent(event: MatDatepickerInputEvent<Date>, controlName) {
        const momentDate = event.value as unknown as moment.Moment;
        const jsDate = momentDate.toDate();
        this.vehiclesForm.get(controlName).setValue(jsDate);
        console.log("changeDateTimeEvent", jsDate)
    }
}
