<div fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 45%">
            <span>Στοιχεία Πρόσθετης Καταχώρησης</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row" mat-dialog-content style="
    overflow-y: auto;
    min-height: 100px;
    height: 100px;
    padding: 0 16px;
">




        <div style="width:100%; margin-bottom: 16px;" fxLayout="column" fxLayoutAlign="flex-start center" class="group">
            <table class="eAitisiHea twoColumns" [formGroup]="VehicleArrivalFormGroup">
                <tbody>
                    <tr>
                        <td>Εκ των Υστέρων Υποβολή</td>

                        <td>
                            <mat-checkbox (change)="onChange($event)" [(checked)]="checkboxChecked"
                                (click)="$event.stopPropagation()" [disabled]="formDisabled">
                            </mat-checkbox>
                        </td>
                    </tr>

                    <tr>
                        <td style="width:40%">Αριθμός Χειρόγραφης Καταχώρησης</td>
                        <td>
                            <mat-form-field appearance="outline" class="s-l sc-ul s-21">
                                <input matInput maxlength="18" formControlName="FallbackDocumentReference" />
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Τελωνείο Καταχώρησης</td>

                        <td>
                            <app-customs-control #OfficeOfOrigin [formDisabled]="formDisabled"
                                [customsGroup]="VehicleArrivalFormGroup" controlName="FallbackOffice"
                                appearance="outline" showCustomsName="true"></app-customs-control>
                        </td>
                    </tr>

                    <tr>
                        <td>Ημερ/νία Χερόγραφης Καταχώρησης</td>

                        <td>
                            <mat-form-field appearance="outline" class="s-l sc-ul s-16">
                                <date-select formControlName="RegistrationDate" [dateType]="dateType"
                                    [defaultDate]="defaultDate" [hideCal]="formDisabled" minlength="10" maxlength="10">
                                </date-select>
                            </mat-form-field>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>


    <div>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="cancel()">
                Ακυρο
            </button>
            <button style="width: auto !important" mat-raised-button [ngClass]="color2"
                (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onCloseConfirm()">
                OK
            </button>
        </mat-dialog-actions>
    </div>
</div>