export class NationalProductsModel {
    NationalProductId?: string;
    Quantity?: string;


    constructor(value?) {
        this.NationalProductId = value?.NationalProductId || null;
        this.Quantity = value?.Quantity || null;

    }
}
