import { FormArray, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { TradecForm, TradecModel } from 'app/main/apps/export/declaration/_models';
import { Traconco1Form } from 'app/main/apps/import/_models/traconco1/traconco1-form.model';
import { Traconco1Model } from 'app/main/apps/import/_models/traconco1/traconco1.model';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { avoidSame } from 'app/services/validators/custom-validators';
import { CusoffdepeptForm } from '../../../_models/cusoffdepept/cusoffdepept-form.model';
import { CusoffdepeptModel } from '../../../_models/cusoffdepept/cusoffdepept.model';
import { CusoffdesestForm } from '../../../_models/cusoffdesest/cusoffdesest-form.model';
import { CusoffdesestModel } from '../../../_models/cusoffdesest/cusoffdesest.model';
import { T2LGooIteGdsForm } from '../../../_models/gooitegds/gooitegds-form.model';
import { T2LGooIteGdsModel } from '../../../_models/gooitegds/gooitegds.model';
import { T2LHeaheaTF013AForm } from '../../../_models/heahea-TF013A/heaheaTF013A-form.model';
import { T2LHeaheaTF013AModel } from '../../../_models/heahea-TF013A/heaheaTF013A.model';
import { T2LHeaheaForm } from '../../../_models/heahea/heahea-form.model';
import { T2LHeaheaModel } from '../../../_models/heahea/heahea.model';
import { T2LDeclarationModel } from './declaration.model';

export class T2LDeclarationForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    MesSenMES3: FormControl;
    MesRecMES6: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    PriMES15: FormControl;
    TesIndMES18: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    MesSeqNumMES22: FormControl;
    FirAndLasTraMES23: FormControl;

    HEAHEA: FormGroup;
    TRACONCO1: FormGroup;
    CUSOFFDEPEPT: FormGroup;
    CUSOFFDESEST: FormGroup;
    GOOITEGDS: FormArray;
    TRADEC: FormGroup;

    /*

   */

    constructor(declaration: T2LDeclarationModel, subdomain?: string, messageType = 'TF015A') {

        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));


        this.MesSenMES3 = new FormControl(declaration.MesSenMES3);
        this.MesRecMES6 = new FormControl(declaration.MesRecMES6);
        this.DatOfPreMES9 = new FormControl(declaration.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(declaration.TimOfPreMES10);
        this.PriMES15 = new FormControl(declaration.PriMES15);
        this.TesIndMES18 = new FormControl(declaration.TesIndMES18);
        this.MesIdeMES19 = new FormControl(declaration.MesIdeMES19);
        this.MesTypMES20 = new FormControl(declaration.MesTypMES20);
        this.MesSeqNumMES22 = new FormControl(declaration.MesSeqNumMES22);
        this.FirAndLasTraMES23 = new FormControl(declaration.FirAndLasTraMES23);

        if (messageType === 'TF013A') {
            this.HEAHEA = new FormGroup(new T2LHeaheaTF013AForm(new T2LHeaheaTF013AModel(declaration.HEAHEA)));
        } else {
            this.HEAHEA = new FormGroup(new T2LHeaheaForm(new T2LHeaheaModel(declaration.HEAHEA)));
        }

        this.TRACONCO1 = new FormGroup(new Traconco1Form(new Traconco1Model(declaration.TRACONCO1)));

        this.CUSOFFDEPEPT = new FormGroup(new CusoffdepeptForm(new CusoffdepeptModel(declaration.CUSOFFDEPEPT)));

        this.CUSOFFDESEST = new FormGroup(new CusoffdesestForm(new CusoffdesestModel(declaration.CUSOFFDESEST)));

        this.GOOITEGDS = new FormArray([]);

        this.TRADEC = new FormGroup(new TradecForm(new TradecModel(declaration.TRADEC)));

        if (declaration.GOOITEGDS) {
            declaration.GOOITEGDS.forEach((goods: T2LGooIteGdsModel) => {
                this.GOOITEGDS.push(new FormGroup(new T2LGooIteGdsForm(new T2LGooIteGdsModel(goods))));
            });
        }  /* else {
            this.GOOITEGDS.push(
                new FormGroup(new T2LGooIteGdsForm(new T2LGooIteGdsModel()))
            );
        }  */


        this.CUSOFFDESEST.get('RefNumEST1').setValidators(avoidSame(this.CUSOFFDEPEPT.get('RefNumEPT1')))
    }
}
