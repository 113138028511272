import { FormControl } from '@angular/forms';
import { Traconco2Model } from './traconco2.model';

export class Traconco2Form {
    [key: string]: FormControl;

    NamCO27 = new FormControl();
    StrAndNumCO222 = new FormControl();
    PosCodCO223 = new FormControl();
    CitCO224 = new FormControl();
    CouCO225 = new FormControl();
    NADLNGGTCO = new FormControl();
    TINCO259 = new FormControl();
    TINTypCO260 = new FormControl(); //NCTS ONLY
    ConsignorObject = new FormControl();

    constructor(value: Traconco2Model) {
        this.NamCO27.setValue(value.NamCO27);
        this.StrAndNumCO222.setValue(value.StrAndNumCO222);
        this.PosCodCO223.setValue(value.PosCodCO223);
        this.CitCO224.setValue(value.CitCO224);
        this.CouCO225.setValue(value.CouCO225);
        this.NADLNGGTCO.setValue(value.NADLNGGTCO);
        this.TINCO259.setValue(value.TINCO259);
        this.TINTypCO260.setValue(value.TINTypCO260);
        this.ConsignorObject.setValue(value.ConsignorObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
