import { FormControl } from '@angular/forms';
import { VehicleInventoryModel } from './vehinv.model';

export class VehicleInventoryForm {
    [key: string]: FormControl;

    VehInvTypVEHINVVECDETGI = new FormControl();

    constructor(value: VehicleInventoryModel) {
        this.VehInvTypVEHINVVECDETGI.setValue(value.VehInvTypVEHINVVECDETGI);
    }
}
