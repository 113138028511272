import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Custom } from 'app/main/apps/customs/customs.model';
import { NationalProduct } from 'app/model/api-model';
import { ar } from 'date-fns/locale';

@Pipe({
    name: 'customsName'
})
export class CustomsNamePipe implements PipeTransform {
    constructor() { }

    transform(customsCode: string, args: { customs: Custom[], name: string }) {

        return (args?.customs?.find(el => el.ReferenceNumber === customsCode))?.[args.name] ?? null;

    }
}

@NgModule({
    declarations: [CustomsNamePipe],
    exports: [CustomsNamePipe],
})
export class CustomsNamePipeModule { }