export class ConresersModel {
    ConResCodERS16?: string;
    DatLimERS69?: string;

    constructor(value?) {
        if (value) {
            this.ConResCodERS16 = value.ConResCodERS16;
            this.DatLimERS69 = value.DatLimERS69;
        }
    }
}
