import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DistillationLicenceAppComponent } from './distillation-licence-app/distillation-licence-app.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { CustomsControlModule } from 'app/main/main-building-blocks/_generic-components/customs-control/customs-control.module';
import { GenericComponentsModule } from 'app/main/main-building-blocks/_generic-components/generic-components.module';
import { FormFieldsModule } from 'app/main/main-building-blocks/form-fields/form-fields.module';
import { DateTransformPipeModule } from 'app/services/pipes/dateTransform.pipe';
import { DistillationLicenceAppSvgComponent } from './distillation-licence-app-svg/distillation-licence-app-svg.component';
import { DistillationLicenceAppPrintComponent } from './distillation-licence-app-print/distillation-licence-app-print.component';



@NgModule({
    declarations: [
        DistillationLicenceAppComponent,
        DistillationLicenceAppSvgComponent,
        DistillationLicenceAppPrintComponent
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        CustomsControlModule,
        GenericComponentsModule,
        DateTransformPipeModule]
})
export class DistillationLicenceAppModule { }
