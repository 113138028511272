import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { DocForms } from './../../../services/broadcaster.service';
import { OfficeService } from 'app/services/office.service';
import { Injectable } from '@angular/core';
import { Tab } from './tab.model';

import { BehaviorSubject, Observable, Subject, filter, takeUntil } from 'rxjs';
import { TabData } from '../export/bis/bis.component';
import { StorageService } from 'app/core/services/storage/storage.service';
//PROVIDING SERVICE IN EXPORT MODULE CAUSES THE TABS BEHAVIORSUBJECT TO NOT UPDATE CORRECTLY
////.///////////  SHOULD CHECK THIS=================================================================

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 *
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array)) {
            return l;
        }
    }
    return -1;
}

@Injectable({
    providedIn: 'root'
})
export class TabService {
    formNames: DocForms[] = ['eur1', 'atr', 'inf4', 'cmr', 'agrcert', 'all'];


    originalButtonList: any[] = [
        {
            title: 'EUR. 1',
            privateTitle: 'eur1',
            tabData: { innerTitle: 'eur1' }
        },
        {
            title: 'ΠΙΣΤ/ΚΟ ΦΥΤ/ΑΣ',
            privateTitle: 'agrcert',
            tabData: { innerTitle: 'agrcert' }
        },
        {
            title: 'CMR',
            privateTitle: 'cmr',
            tabData: { innerTitle: 'cmr' }
        },
        {
            title: 'ATR',
            privateTitle: 'atr',
            tabData: { innerTitle: 'atr' }
        },
        {
            title: 'INF4',
            privateTitle: 'inf4',
            tabData: { innerTitle: 'inf4' }
        }
    ];


    public buttonList: any[] = [...this.originalButtonList];

    private tabs: Tab[] = [];
    //the printDocs array holds the tabs used in the Declaration Print Component
    private printDocs: Tab[] = [];

    private tabSub: BehaviorSubject<Tab[] | undefined>;
    private printSub: BehaviorSubject<Tab[] | undefined>;

    tabSub$: Observable<Tab[]>;
    printSub$: Observable<Tab[]>;

    private _destroy: Subject<void> = new Subject();

    constructor(private officeService: OfficeService, private mainBroadcaster: MainBroadcasterService, private storageService: StorageService) {
        console.log('TabService Created');

        this.tabSub = new BehaviorSubject([]);
        this.printSub = new BehaviorSubject([]);

        if (!this.tabSub$) {
            this.tabSub$ = this.tabSub.asObservable();
        }
        if (!this.printSub$) {
            this.printSub$ = this.printSub.asObservable();
        }


        this.officeService.currentOffice$.pipe(filter(Boolean), takeUntil(this._destroy)).subscribe(office => {
            console.log('tabService office apps', office.apps);

            this.buttonList = this.originalButtonList.filter((button) => office.apps.includes(button.privateTitle));
        });

    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._destroy.next();
        this._destroy.complete();
        console.log('Tab Service Destroyed');
    }

    private addTab(tab: Tab, printDoc?: Tab) {
        console.log('TabService addTab()', tab);
        this.tabs = this.tabSub.value;
        console.log('tabs before add', this.tabs);

        if (this.formNames.includes(tab.privateTitle as DocForms)) {
            this.mainBroadcaster.resetDocForms(tab.privateTitle as DocForms, true);
        }

        for (let i = 0; i < this.tabs.length; i++) {
            if (this.tabs[i].active === true) {
                this.tabs[i].active = false;
            }
        }

        if (tab.privateTitle !== 'bis') {
            tab.id = this.tabs.length + 1;
            this.tabs.push(tab);
        }

        if (tab.privateTitle === 'bis') {
            const lastBisIndex = findLastIndex(this.tabs, tab => tab.privateTitle === 'bis');

            if (lastBisIndex < 0) {
                this.tabs.splice(1, 0, tab);
                tab.id = 2;
            } else {
                this.tabs.splice(lastBisIndex + 1, 0, tab);
                tab.id = this.tabs[lastBisIndex].id + 1;
            }
        }
        // this.sortTabsArray();
        this.tabs = [...new Set(this.tabs)];
        console.log('tabs after Add', this.tabs);

        this.tabSub.next(this.tabs);

        if (printDoc) {
            printDoc.id = this.tabs.length + 1;

            //if the tab does not exist in the printdoc array add it and transmit
            if (!this.printDocs.find(el => el.id === printDoc.id)) {
                this.printDocs.push(printDoc);
                this.printSub.next(this.printDocs);
            }
        }

        console.log('TabService Tabs after add', this.tabSub.value);
        console.log('TabService Tabs after add length', this.tabSub.value.length);
    }

    removeTab(index: number) {
        console.log('TabService removeTab()');

        this.tabs = this.tabSub.value;

        console.log('Tabs on removeTab', this.tabs);

        //remove localStorage Item (this deletes only matching titles, these are eur1 and agrcert, NOT the declaration localstorage)
        if (this.tabs[index]) {
            const title = this.tabs[index].tabData.innerTitle;
            console.log('LocalStorage Item to be removed: ', title);

            //We delay the removal of the localStorage Item because somewhere valueChanges of the declaration cause the localStorage item to be set again. Needs Fixing
            setTimeout(() => {
                this.storageService.removeLocalObject(title);

                console.log('LocalStorage Item after delete delayed: ', this.storageService.checkLocalObject(title));

            }, 1000)

        }

        this.tabs.splice(index, 1);
        this.printDocs.splice(index, 1);

        if (this.tabs.length > 0) {
            // this.tabs[this.tabs.length - 1].active = true;
            //this.printDocs[this.printDocs.length - 1].active = true;
            this.tabs[0].active = true;
            this.printDocs[0].active = true;
        }

        this.tabs = [...new Set(this.tabs)];

        this.tabSub.next(this.tabs);
        this.printSub.next(this.printDocs);

        console.log('TabService Tabs after delete', this.tabSub.value);
        console.log('TabService Tabs after delete length', this.tabSub.value.length);
    }

    public addNewTab(item: Tab) {
        const title: string = item.title;
        const privateTitle: string = item.privateTitle;
        const tabData: TabData = item.tabData;
        const active: boolean = item.active;

        this.addTab(new Tab(title, privateTitle, tabData, active), new Tab(title, privateTitle, tabData, active));
    }

    public addDeclarationTab() {
        this.addNewTab(
            {
                title: 'ΔΙΑΣΑΦΗΣΗ',
                privateTitle: 'declaration',
                tabData: {
                    totalItems: 1,
                    totalTabs: 1,
                    tabId: 0,
                    tabItems: 1
                },
                active: true
            }
        );
    }

    public addBis(totalItems: number, itemsPerPage: number) {
        console.log('export addBis()');

        const filteredData: Tab[] = this.tabs.filter(tab => tab.privateTitle === 'bis').reverse();

        filteredData.forEach(tab => {
            this.removeTab(tab.id - 1);
        });

        if (totalItems > 1) {
            const tabs: number = Math.ceil((totalItems - 1) / itemsPerPage);
            let i: number;

            for (i = 1; i <= tabs; i++) {
                const title = 'BIS ' + i;
                //we should have 3 tabItems per tab

                //we calculate the remaining tabItems for the last tab
                let tabItems = totalItems - (i - 1) * itemsPerPage - 1;

                //restrict to 3 this.tabItems per tab
                tabItems = tabItems > itemsPerPage ? itemsPerPage : tabItems;

                this.addNewTab(
                    {
                        title,
                        privateTitle: 'bis',
                        tabData: {
                            totalItems: totalItems,
                            totalTabs: tabs + 1,
                            tabId: i,
                            tabItems: tabItems
                        },
                        active: false
                    }
                );
            }
            //Update first tab tabData
            this.tabs.filter(tab => tab.privateTitle === 'declaration').map(t => {

                t.tabData.totalItems = totalItems;
                t.tabData.totalTabs = tabs + 1;

                return t;
            })
        }

        console.log("addBis tabs", this.tabs)
    }

    public addDocTab(innerTitle: string) {
        if (!this.tabs.find(x => x.tabData.innerTitle === innerTitle)) {
            console.trace('Adding from local');

            const tab = this.buttonList.find(x => x.tabData.innerTitle === innerTitle);

            const title = tab.title;
            const privateTitle = tab.privateTitle;
            const tabData: TabData = {
                innerTitle
            }

            this.addTab(new Tab(title, privateTitle, tabData, false), new Tab(title, privateTitle, tabData, false));
        }
    }

    addTir() {
        const title = 'TIR';
        const privateTitle = 'tir';
        const tabData: TabData = { innerTitle: 'tir' };
        this.addTab(new Tab(title, privateTitle, tabData, false), new Tab(title, privateTitle, tabData, false));
    }
    removeTir() {
        const tirIndex = this.tabs.findIndex(tab => tab.privateTitle === 'tir');
        console.log('tirIndex', tirIndex);
        if (tirIndex > 0) {
            this.tabs.splice(tirIndex, 1);
        }
    }

    public getButtonListTitles() {
        const tabTitles: string[] = [];
        let filteredTitles: Tab[] = [];
        this.tabs.forEach(tab => {
            tabTitles.push(tab.title);
        });

        filteredTitles = this.buttonList.filter((item: Tab) => {
            //console.log("getButtonListTitles item", item)
            return tabTitles.indexOf(item.title) < 0;
        });
        return filteredTitles;
    }

    getActiveTabsTitles(): string[] {
        const tabTitles: string[] = [];

        this.tabs.forEach(tab => {
            tabTitles.push(tab.privateTitle);
        });

        return tabTitles;
    }

    public resetTabs() {
        const totalTabs = this.tabs.length;
        console.log('resetTabs totaltabs: ', totalTabs);

        //first tab(index=0) is the main tab. Do not delete it
        if (totalTabs > 1) {
            for (let i = totalTabs - 1; i >= 1; i--) {
                console.log('removing tab with index: ', i);

                this.removeTab(i);
            }
        }

        console.log('tabs after delete', this.tabs);

        console.log('TabService after removeTab() tabSub', this.tabSub.value);
    }

    public totalReset() {
        this.tabs = [];
        this.printDocs = [];
        this.tabSub.next([]);
        this.printSub.next([]);
    }
}
