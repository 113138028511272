import { TabData } from './../../export/bis/bis.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tab } from '../../tab-factory/tab.model';

@Component({
    selector: 'app-manual-print-dialog',
    templateUrl: './manual-print-dialog.component.html',
    styleUrls: ['./manual-print-dialog.component.scss']
})
export class ManualPrintDialogComponent {

    tabs: Tab[]
    edeNumber: string;

    /**
     * Constructor
     *
     * @param dialogRef
     */
    constructor(public dialogRef: MatDialogRef<ManualPrintDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Tab[],
    ) {


        console.log("manualPrint tabs", data)

        setTimeout(() => {

            this.tabs = data.map(t => {
                t.tabData.innerTitle = t.title

                switch (t.tabData?.declarationFormValue._PRIVATE.subdomain) {
                    case 'T2L': {
                        this.edeNumber = '7';
                        if (t.privateTitle !== 'bis') {

                            t.tabData.innerTitle = 'εντύπου 4'
                        }

                        break;
                    }
                    case 'TIR': {
                        this.edeNumber = '5';
                        if (t.privateTitle !== 'bis') {

                            t.tabData.innerTitle = 'εντύπων 1, 7, Ε'
                        }

                        break;

                    }
                    case 'IMP_DECL': {
                        this.edeNumber = '6';
                        if (t.privateTitle !== 'bis') {

                            t.tabData.innerTitle = 'εντύπων 6, 7, 8'
                        }
                        break;

                    }
                    case 'EXP_DECL': {
                        this.edeNumber = '2';
                        if (t.privateTitle !== 'bis') {

                            t.tabData.innerTitle = 'εντύπων 1, 2, 3'
                        }
                        break;

                    }

                }


                return t;
            });

            if (data[0].tabData?.declarationFormValue._PRIVATE.subdomain === 'TIR') {

                const tabData: TabData = this.tabs.filter(t => t.privateTitle === 'declaration')[0].tabData;

                const newTabData: TabData = {
                    totalTabs: tabData.totalTabs,
                    totalItems: tabData.totalItems,
                    tabItems: tabData.tabItems,
                    declarationFormValue: tabData.declarationFormValue,
                    innerTitle: 'εντύπων 4, 5'
                }
                const tab = new Tab('ΔΙΑΣΑΦΗΣΗ', 'declaration', newTabData, false);


                this.tabs.splice(1, 0, tab)
            }

        })

    }

    print(tabTitle: string) {

        console.log(tabTitle)
    }
}
