import { AbstractControl, FormControl } from "@angular/forms";
import { ReferenceDocumentsModel } from "./reference-docs.model";

export class ReferenceDocumentsForm {
    [key: string]: AbstractControl;

    ReferenceDocumentId: FormControl;
    ReferenceDocumentNumber: FormControl;

    /*

   */

    constructor(value: ReferenceDocumentsModel) {

        this.ReferenceDocumentId = new FormControl(value.ReferenceDocumentId);
        this.ReferenceDocumentNumber = new FormControl(value.ReferenceDocumentNumber);

    }
}
