import { Declarant } from 'app/core/user/user.model';
import { _pelatesItem } from 'app/model/api-model';

export class TradecModel {
    NamTDE1?: string;
    StrAndNumTDE1?: string;
    PosCodTDE1?: string;
    CiTDE1?: string;
    CouCodTDE1?: string;
    TRADECLNG?: string;
    TINTDE1?: string;
    SpeMenTDE1022?: string; //export ONLY
    TINTypDECTRA?: number; //ncts ONLY
    DeclarantObject?: Declarant;

    constructor(value?) {
        if (value) {
            this.NamTDE1 = value.NamTDE1;
            this.StrAndNumTDE1 = value.StrAndNumTDE1;
            this.PosCodTDE1 = value.PosCodTDE1;
            this.CiTDE1 = value.CiTDE1;
            this.CouCodTDE1 = value.CouCodTDE1;
            this.TRADECLNG = value.TRADECLNG;
            this.TINTDE1 = value.TINTDE1;
            this.SpeMenTDE1022 = value.SpeMenTDE1022;
            this.TINTypDECTRA = value.TINTypDECTRA;
            this.DeclarantObject = value.DeclarantObject;
        }
    }
}
