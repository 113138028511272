import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrucksService } from 'app/main/apps/excise-helper-tables/vehicles/trucks/trucks.service';



@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [TrucksService]
})
export class TrucksModule { }
