<table class="tsipoura-limitations">
    <colgroup>
        <col style="width:50%">
        <col style="width:15%">
    </colgroup>
    <thead>
        <tr>
            <th colspan="2" class="text-left">ΠΕΡΙΟΡΙΣΜΟΙ</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>ΜΑΧ ΣΤΑΦΥΛΙΑ ΟΙΝΟΠΟΙΗΣΙΜΑ (Kg)</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.maxWineGrapes" /></td>
            <td class="printOnly">{{params.maxWineGrapes}}</td>
        </tr>
        <tr>
            <td>ΜΑΧ ΣΤΕΜΦΥΛΑ (Kg)</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.maxGrapeStems" /></td>
            <td class="printOnly">{{params.maxGrapeStems}}</td>

        </tr>
        <tr>
            <td>ΜΑΧ ΚΑΖΑΝΙΕΣ (ΣΤΑΦΥΛΙΑ)</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.maxBoilerNumberGrapes" /></td>
            <td class="printOnly">{{params.maxBoilerNumberGrapes}}</td>

        </tr>
        <tr>
            <td>ΜΑΧ ΚΑΖΑΝΙΕΣ (ΣΤΕΜΦΥΛΑ)</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.maxBoilerNumberStems" /></td>
            <td class="printOnly">{{params.maxBoilerNumberStems}}</td>

        </tr>
        <tr>
            <td>ΜΑΧ ΔΙΑΡΚΕΙΑ ΑΠΟΣΤΑΞΗΣ (24ωρα)</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.maxDays" /></td>
            <td class="printOnly">{{params.maxDays}}</td>

        </tr>
        <tr>
            <td>ΜΑΧ ΩΡΕΣ ΑΠΟΣΤΑΞΗΣ/παραγωγο</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.maxHours" /></td>
            <td class="printOnly">{{params.maxHours}}</td>

        </tr>
    </tbody>
</table>