export class T2LCancelHeaheaModel {
    DocNumHEA5?: string;


    RefT2LYeaHEA1012?: string;
    RefT2CouCodHEA1010?: string;
    RefT2LTypHEA1014?: string;
    RefT2LCusHEA1013?: string;
    RefT2LNumHEA1015?: string;
    DatOfCanReqHEA147?: string;
    CanReaHEA250?: string;
    CanReaHEA250LNG?: string;


    constructor(value?) {
        if (value) {
            this.DocNumHEA5 = value.DocNumHEA5;


            this.RefT2LYeaHEA1012 = value.RefT2LYeaHEA1012;
            this.RefT2CouCodHEA1010 = value.RefT2CouCodHEA1010;
            this.RefT2LTypHEA1014 = value.RefT2LTypHEA1014;
            this.RefT2LCusHEA1013 = value.RefT2LCusHEA1013;
            this.RefT2LNumHEA1015 = value.RefT2LNumHEA1015;
            this.DatOfCanReqHEA147 = value.DatOfCanReqHEA147;
            this.CanReaHEA250 = value.CanReaHEA250;
            this.CanReaHEA250LNG = value.CanReaHEA250LNG;
        }
    }
}
