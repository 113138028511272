import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { genericItem } from 'app/model/api-model';

@Pipe({
    name: 'codeToDesc'
})
export class CodeToDescPipe implements PipeTransform {



    transform(code: string, array: any[]) {


        if (!array) return;

        const obj = array.filter(e => e.code === code)[0]
        if (obj) {
            return obj['desc'];
        }



    }
}

@NgModule({
    declarations: [CodeToDescPipe],
    exports: [CodeToDescPipe],
})
export class CodeToDescPipeModule { }