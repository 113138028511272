import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { Traconco1Form } from 'app/main/apps/import/_models/traconco1/traconco1-form.model';
import { Traconco1Model } from 'app/main/apps/import/_models/traconco1/traconco1.model';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { CusoffdepeptForm } from '../../../_models/cusoffdepept/cusoffdepept-form.model';
import { CusoffdepeptModel } from '../../../_models/cusoffdepept/cusoffdepept.model';
import { T2LCancelHeaheaForm } from './cancel-heahea-form.model';
import { T2LCancelHeaheaModel } from './cancel-heahea.model';
import { T2LCancelDeclarationModel } from './declaration.model';

export class T2LCancelDeclarationForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    MesSenMES3: FormControl;
    MesRecMES6: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    PriMES15: FormControl;
    TesIndMES18: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    MesSeqNumMES22: FormControl;
    FirAndLasTraMES23: FormControl;

    HEAHEA: FormGroup;
    TRACONCO1: FormGroup;
    CUSOFFDEPEPT: FormGroup;

    /*

   */

    constructor(declaration: T2LCancelDeclarationModel, subdomain?: string, messageType?: string) {

        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));


        this.MesSenMES3 = new FormControl(declaration.MesSenMES3);
        this.MesRecMES6 = new FormControl(declaration.MesRecMES6);
        this.DatOfPreMES9 = new FormControl(declaration.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(declaration.TimOfPreMES10);
        this.PriMES15 = new FormControl(declaration.PriMES15);
        this.TesIndMES18 = new FormControl(declaration.TesIndMES18);
        this.MesIdeMES19 = new FormControl(declaration.MesIdeMES19);
        this.MesTypMES20 = new FormControl(declaration.MesTypMES20);
        this.MesSeqNumMES22 = new FormControl(declaration.MesSeqNumMES22);
        this.FirAndLasTraMES23 = new FormControl(declaration.FirAndLasTraMES23);

        this.HEAHEA = new FormGroup(new T2LCancelHeaheaForm(new T2LCancelHeaheaModel(declaration.HEAHEA)));

        this.TRACONCO1 = new FormGroup(new Traconco1Form(new Traconco1Model(declaration.TRACONCO1)));

        this.CUSOFFDEPEPT = new FormGroup(new CusoffdepeptForm(new CusoffdepeptModel(declaration.CUSOFFDEPEPT)));


    }
}
