import { FormArray, FormControl, FormGroup, AbstractControl, Validators } from '@angular/forms';
//import { MyGroupForm } from "../../my-group/_models/mygroup-form.model";
//import { MyGroup } from "../../my-group/_models/mygroup.model";
//import { PlayerForm, Player } from "../../player";
import { Declaration } from './declaration.model';

import {
    CusoffexpertForm,
    CusoffexpertModel,
    CusoffexiextForm,
    CusoffexiextModel,
    Statrep385Form,
    Statrep385Model,
    IdewarForm,
    IdewarModel,
    TradatModel,
    TradatForm,
    DelterForm,
    DelterModel,
    TradecForm,
    TradecModel,
    ConresersForm,
    ConresersModel,
    TraconceModel,
    TraconceForm,
    Traexpex1Form,
    Traexpex1Model,
    SeainfsilForm,
    SeainfsilModel,
    ItiModel,
    ItiForm,
    HeaheaModel,
    HeaheaForm,
    DefPosPayModel,
    DefPosPayForm,
    GooIteGdsForm,
    GooIteGdsModel
} from './';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { GuaguaForm } from 'app/main/apps/transit/tir/_models/guagua/guagua-form.model';
import { GuaguaModel } from 'app/main/apps/transit/tir/_models/guagua/guagua.model';
import { Trapripc1Form } from 'app/main/apps/transit/tir/_models/trapripc1/trapripc1-form.model';
import { Trapripc1Model } from 'app/main/apps/transit/tir/_models/trapripc1/trapripc1.model';
import { CusofftrarnsForm } from 'app/main/apps/transit/_models/cusofftrarns/cusofftrarns-form.model';
import { CusofftrarnsModel } from 'app/main/apps/transit/_models/cusofftrarns/cusofftrarns.model';

export class DeclarationForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;


    SynIdeMES1: FormControl;
    SynVerNumMES2: FormControl;
    MesSenMES3: FormControl;
    MesRecMES6: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    IntConRefMES11: FormControl;
    TesIndMES18: FormControl;
    // PriMES15: FormControl;
    // ComAgrIdMES17: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    // ComAccRefMES21: FormControl;
    HEAHEA: FormGroup;
    TRAEXPEX1: FormGroup;
    TRACONCE1: FormGroup;
    CUSOFFEXPERT: FormGroup;
    CUSOFFEXIEXT: FormGroup;
    CONRESERS: FormGroup;
    SEAINFSLI: FormGroup;
    GOOITEGDS: FormArray;
    ITI: FormArray;
    TRADEC: FormGroup;
    DELTER: FormGroup;
    TRADAT: FormGroup;
    DEFPOSPAY: FormArray;
    IDEWAR: FormGroup;
    STATREP385: FormGroup;
    /*

   */

    //TIR GROUPS===============================
    TRAPRIPC1: FormGroup;
    CUSOFFTRARNS: FormArray;
    GUAGUA: FormArray;
    //=======================================

    constructor(declaration: Declaration, subdomain?: string, messageType?: string) {
        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));


        this.SynIdeMES1 = new FormControl(declaration.SynIdeMES1);
        this.SynVerNumMES2 = new FormControl(declaration.SynVerNumMES2);
        this.MesSenMES3 = new FormControl(declaration.MesSenMES3);
        this.MesRecMES6 = new FormControl(declaration.MesRecMES6);
        this.DatOfPreMES9 = new FormControl(declaration.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(declaration.TimOfPreMES10);
        this.IntConRefMES11 = new FormControl(declaration.IntConRefMES11);
        this.TesIndMES18 = new FormControl(declaration.TesIndMES18);
        this.MesIdeMES19 = new FormControl(declaration.MesIdeMES19);
        this.MesTypMES20 = new FormControl(declaration.MesTypMES20);

        //     this.PriMES15 = new FormControl(declaration.PriMES15);
        //     this.ComAgrIdMES17 = new FormControl(declaration.ComAgrIdMES17);
        //     this.ComAccRefMES21 = new FormControl(declaration.ComAccRefMES21);

        this.HEAHEA = new FormGroup(new HeaheaForm(new HeaheaModel(declaration.HEAHEA)));

        this.TRAEXPEX1 = new FormGroup(new Traexpex1Form(new Traexpex1Model(declaration.TRAEXPEX1)));

        this.TRACONCE1 = new FormGroup(new TraconceForm(new TraconceModel(declaration.TRACONCE1)));

        this.CUSOFFEXPERT = new FormGroup(new CusoffexpertForm(new CusoffexpertModel(declaration.CUSOFFEXPERT)));

        this.CUSOFFEXIEXT = new FormGroup(new CusoffexiextForm(new CusoffexiextModel(declaration.CUSOFFEXIEXT)));

        this.CONRESERS = new FormGroup(new ConresersForm(new ConresersModel(declaration.CONRESERS)));

        this.SEAINFSLI = new FormGroup(new SeainfsilForm(new SeainfsilModel(declaration.SEAINFSLI)));

        this.GOOITEGDS = new FormArray([], Validators.required);

        this.ITI = new FormArray([]);

        this.TRADEC = new FormGroup(new TradecForm(new TradecModel(declaration.TRADEC)));

        this.DELTER = new FormGroup(new DelterForm(new DelterModel(declaration.DELTER)));

        this.TRADAT = new FormGroup(new TradatForm(new TradatModel(declaration.TRADAT)));

        this.DEFPOSPAY = new FormArray([]);

        this.IDEWAR = new FormGroup(new IdewarForm(new IdewarModel(declaration.IDEWAR)));

        this.STATREP385 = new FormGroup(new Statrep385Form(new Statrep385Model(declaration.STATREP385)));

        this.TRAPRIPC1 = new FormGroup(new Trapripc1Form(new Trapripc1Model(declaration.TRAPRIPC1)));
        this.CUSOFFTRARNS = new FormArray([]);

        this.GUAGUA = new FormArray([]);


        if (declaration.DEFPOSPAY) {
            declaration.DEFPOSPAY.forEach((defpospay: DefPosPayModel) => {
                this.DEFPOSPAY.push(new FormGroup(new DefPosPayForm(new DefPosPayModel(defpospay))));
            });
        }

        if (declaration.ITI) {
            declaration.ITI.forEach((itinerary: ItiModel) => {
                this.ITI.push(new FormGroup(new ItiForm(new ItiModel(itinerary))));
            });
        }
        if (declaration.GOOITEGDS) {
            declaration.GOOITEGDS.forEach((goods: GooIteGdsModel) => {
                this.GOOITEGDS.push(new FormGroup(new GooIteGdsForm(new GooIteGdsModel(goods))));
            });
        }

        if (declaration.CUSOFFTRARNS) {
            declaration.CUSOFFTRARNS.forEach((customs: CusofftrarnsModel) => {
                this.CUSOFFTRARNS.push(new FormGroup(new CusofftrarnsForm(new CusofftrarnsModel(customs))));
            });
        } else {
            this.CUSOFFTRARNS.push(new FormGroup(new CusofftrarnsForm(new CusofftrarnsModel())));
        }

        if (declaration.GUAGUA) {
            declaration.GUAGUA.forEach((guagua: GuaguaModel) => {
                this.GUAGUA.push(new FormGroup(new GuaguaForm(new GuaguaModel(guagua))));
            });
        } else {
            this.GUAGUA.push(new FormGroup(new GuaguaForm(new GuaguaModel())));
        }

        console.log('___DeclarationFormModel GOOITEGDS constructor', this.GOOITEGDS);
    }
}
