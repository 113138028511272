import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { Office } from 'app/model/office.model';
import { environment } from 'environments/environment';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class OfficeService {
    private offices: BehaviorSubject<Office[]> = new BehaviorSubject<Office[]>(null);
    private currentOffice: BehaviorSubject<Office> = new BehaviorSubject<Office>(null);

    public officesFetched = new BehaviorSubject<boolean>(false);

    offices$: Observable<Office[]>;
    currentOffice$: Observable<Office>;



    PHP_API_SERVER = environment.API_URL;


    constructor(private http: HttpClient, private mainBroadcaster: MainBroadcasterService, private injector: Injector, private dbQuery: ApiService) {

        console.log("OFFICE SERVICE CONSTRUCTOR")

        this.offices$ = this.offices.asObservable();
        this.currentOffice$ = this.currentOffice.asObservable();

    }

    fetchOffices(overrideCache = false): Observable<Office[]> {

        if (this.offices.value && !overrideCache) {
            return of(this.offices.value)
        }
        else {
            console.trace("officeG fetchOffices")

            return this.http.get<Office[]>(`${this.PHP_API_SERVER}/offices/`).pipe(
                switchMap(offices => {
                    //    console.log("officeG fetchOffices", offices)

                    this.offices.next(offices);
                    this.officesFetched.next(true)
                    console.log("officeG currentOffice", this.currentOffice.value)


                    if (this.currentOffice.value === null) {
                        return this.setDefaultOffice().pipe(map(() => offices))
                    }

                    return of(offices)


                    // Set the default office
                }), take(1));
        }
    }

    setDefaultOffice(currentId?: string): Observable<Office> {

        const offices = this.offices.value
        let defaultOffice: Office;

        const urlOffice = offices.find(office => office?.id === currentId);

        console.log("officeG fetchOffices currentId", currentId)
        console.log("officeG fetchOffices urlOffice", urlOffice?.office_name)

        if (urlOffice) {
            defaultOffice = urlOffice;
        }
        else {
            // Find the default office or get the first office
            defaultOffice = offices.find(office => office.is_default_office) || offices[0];
        }

        console.log("officeG fetchOffices defaultOffice", defaultOffice.office_name)

        return this.injector.get(AuthService).officeAuthenticate(defaultOffice).pipe(map(() => defaultOffice))

    }

    getOfficeById(officeId: string): Office {
        const offices = this.offices.getValue();
        return offices.find(office => office?.id === officeId) || null;
    }

    setCurrentOffice(office: Office): void {
        this.currentOffice.next(office);
        console.log("officeG setCurrentOffice", office?.office_name)

    }



    hasAccess(requiredApps: string[]): boolean {
        const currentOffice = this.currentOffice.getValue();
        const currentApps = currentOffice?.apps || [];


        if (!currentOffice || !currentApps) {
            return false;
        }
        const hasAccess = requiredApps.every(requiredApp => currentApps.includes(requiredApp));

        return hasAccess;
    }

    getDeclarationSettings(): Observable<any> {
        console.trace('getSettings')
        return this.dbQuery
            .get_options(ApiEndPoints.declaration_settings, false)
            .pipe(tap(
                value => {
                    console.log('printSettings value on service', value);
                    this.mainBroadcaster.updateDeclarationSettings(value);
                }

            ), take(1))
    }

    setOffices(offices: Office[]): void {
        this.offices.next(offices);
    }


}
