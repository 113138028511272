<ng-container *ngIf="vehiclesForm$ | async as vehiclesForm">
    <table class="noPrint cars-calculation-data" [formGroup]="vehiclesForm">
        <colgroup>
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
        </colgroup>
        <thead>
            <tr>
                <th>Κλίμακα Φορολόγησης</th>
                <th>Λ.Τ.Π.Φ.</th>
                <th>Λ.Τ.Π.Φ. Μειωμένη κατά {{vehiclesForm.get('totalReductionRate').value | percent:'1.2-2'}}</th>
                <th>Αρχικοί Συντελεστές Τέλους Ταξινόμησης</th>
                <th>Προσαύξηση Κατηγορίας: {{vehiclesForm.get('carCertificateIncreaseRate').value | percent}}</th>
                <th *ngIf="+vehiclesForm.get('vehicleType').value===10">Προσαύξηση Τροχόσπιτου:
                    {{vehiclesForm.get('caravanIncreaseRate').value | percent}}</th>
                <th *ngIf="+vehiclesForm.get('vehicleType').value!==10">Προσαύξηση λόγω CO2:
                    {{vehiclesForm.get('co2IncreaseRate').value | percent}}</th>
                <th>Tέλος Tαξινόμησης</th>

            </tr>
        </thead>

        <tbody formArrayName="carsCalculationData" *ngIf="priceRanges$ | async as priceRanges">
            <tr *ngFor="let item of vehiclesForm.get('carsCalculationData').controls; let i = index"
                [formGroupName]="i">
                <td><input [value]="priceCategories[i]" readonly tabindex="-1"></td>

                <td><input [value]="item.get('splitRetailValue').value | number:'1.2-2'" readonly tabindex="-1"></td>
                <td><input class="text-bold" [value]="item.get('reducedRetailValue').value | number:'1.2-2'" readonly
                        tabindex="-1"></td>
                <td><input [value]="item.get('taxRateInitial').value | percent:'1.2-2'" readonly tabindex="-1"></td>
                <td><input [value]="item.get('taxRateIncreasedByCategory').value | percent:'1.2-2'" readonly
                        tabindex="-1"></td>
                <td *ngIf="+vehiclesForm.get('vehicleType').value===10"><input
                        [value]="item.get('taxRateIncreasedByCaravan').value | percent:'1.2-2'" readonly tabindex="-1">
                </td>
                <td *ngIf="+vehiclesForm.get('vehicleType').value!==10"><input class="text-bold"
                        [value]="item.get('taxRateIncreasedByCo2').value | percent:'1.2-2'" readonly tabindex="-1"></td>
                <td><input class="text-bold" [value]="item.get('subGroupTax').value | number:'1.2-2'" readonly
                        tabindex="-1"></td>
        </tbody>

        <tfoot [formGroup]="vehiclesForm.get('carsTotals')">
            <tr>
                <td></td>
                <td [formGroup]="vehiclesForm" class="text-center"><input
                        [value]="vehiclesForm.get('retailValueBeforeTax').value | number:'1.2-2'" readonly
                        tabindex="-1">
                </td>
                <td><input class="text-bold"
                        [value]="vehiclesForm.get('carsTotals').get('reducedValueTotal').value | number:'1.2-2'"
                        readonly tabindex="-1"></td>

                <td colspan="3" class="text-right">Υποσύνολο: </td>
                <td><input class="text-bold"
                        [value]="vehiclesForm.get('carsTotals').get('subTotal').value | number:'1.2-2'" readonly
                        tabindex="-1"></td>

            </tr>
            <tr *ngIf="vehiclesForm.get('vehicleType').value==='18'">
                <td colspan="6" class="text-right">Μείωση Υβριδικού: </td>
                <td><input class="text-bold"
                        [value]="-vehiclesForm.get('carsTotals').get('hybridReduction').value | number:'1.2-2'" readonly
                        tabindex="-1"></td>

            </tr>
            <tr *ngIf="vehiclesForm.get('vehicleType').value==='10'">
                <td colspan="6" class="text-right">Μείωση Τροχόσπιτου </td>
                <td><input class="text-bold"
                        [value]="-vehiclesForm.get('carsTotals').get('caravanReduction').value | number:'1.2-2'"
                        readonly tabindex="-1"></td>

            </tr>
            <tr *ngIf="vehiclesForm.get('carsTotals').get('environmentTax').value">
                <td colspan="6" class="text-right">Περιβαλλοντικό Τέλος: </td>
                <td><input class="text-bold"
                        [value]="vehiclesForm.get('carsTotals').get('environmentTax').value | number:'1.2-2'" readonly
                        tabindex="-1"></td>

            </tr>
            <tr *ngIf="vehiclesForm.get('vehicleUse').value==='public'">
                <td colspan="6" class="text-right">Δημοσίας Χρήσης {{vehiclesForm.get('publicUseRate').value |
                    percent:'1.2-2'}} Τ.Τ: </td>
                <td><input class="text-bold"
                        [value]="vehiclesForm.get('carsTotals').get('publicUseTotal').value | number:'1.2-2'" readonly
                        tabindex="-1"></td>

            </tr>
            <tr>
                <td colspan="6" class="text-right">ΣΥΝΟΛΟ: </td>
                <td><input class="text-bold excel-yellow"
                        [value]="vehiclesForm.get('carsTotals').get('taxTotal').value | number:'1.2-2'" readonly
                        tabindex="-1"></td>

            </tr>
        </tfoot>
    </table>



    <table class="printOnly cars-calculation-data">
        <colgroup>
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
            <col style="width: 14%;" />
        </colgroup>
        <thead>
            <tr>
                <th>Κλίμακα Φορολόγησης</th>
                <th>Λ.Τ.Π.Φ.</th>
                <th>Λ.Τ.Π.Φ. Μειωμένη κατά {{vehiclesForm.get('totalReductionRate').value | percent:'1.2-2'}}</th>
                <th>Αρχικοί Συντελεστές Τέλους Ταξινόμησης</th>
                <th>Προσαύξηση Κατηγορίας: {{vehiclesForm.get('carCertificateIncreaseRate').value | percent}}</th>
                <th *ngIf="vehiclesForm.get('vehicleType').value==='10'">Προσαύξηση Τροχόσπιτου
                    {{vehiclesForm.get('caravanIncreaseRate').value | percent}}</th>
                <th *ngIf="vehiclesForm.get('vehicleType').value!=='10'">Προσαύξηση λόγω CO2:
                    {{vehiclesForm.get('co2IncreaseRate').value | percent}}</th>
                <th>Tέλος Tαξινόμησης</th>

            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of vehiclesForm.get('carsCalculationData').value; let i = index">
                <td>{{priceCategories[i]}}</td>

                <td>{{item.splitRetailValue | number:'1.2-2'}}</td>
                <td class="text-bold">{{item.reducedRetailValue | number:'1.2-2'}}</td>
                <td>{{item.taxRateInitial | percent:'1.2-2'}}</td>
                <td>{{item.taxRateIncreasedByCategory | percent:'1.2-2'}}</td>
                <td *ngIf="+vehiclesForm.get('vehicleType').value===10">{{item.taxRateIncreasedByCaravan |
                    percent:'1.2-2'}}</td>

                <td *ngIf="vehiclesForm.get('vehicleType').value!=='10'" class=" text-bold">{{item.taxRateIncreasedByCo2
                    | percent:'1.2-2'}}</td>
                <td class=" text-bold">{{item.subGroupTax | number:'1.2-2'}}</td>


        </tbody>

        <tfoot>
            <tr>
                <td></td>
                <td class="text-bold">{{vehiclesForm.get('retailValueBeforeTax').value}}</td>
                <td class="text-bold">{{vehiclesForm.get('carsTotals').get('reducedValueTotal').value | number:'1.2-2'}}
                </td>
                <td colspan="3" class="text-right">Υποσύνολο: </td>
                <td class="text-bold">{{vehiclesForm.get('carsTotals').get('subTotal').value | number:'1.2-2'}}</td>

            </tr>
            <tr *ngIf="vehiclesForm.get('vehicleType').value==='18'">
                <td colspan="6" class="text-right">Μείωση Υβριδικού: </td>
                <td class="text-bold">{{vehiclesForm.get('carsTotals').get('hybridReduction').value | number:'1.2-2'}}
                </td>

            </tr>
            <tr *ngIf="+vehiclesForm.get('vehicleType').value===10">
                <td colspan="6" class="text-right">Μείωση Τροχόσπιτου </td>
                <td class="text-bold">{{vehiclesForm.get('carsTotals').get('caravanReduction').value | number:'1.2-2'}}
                </td>

            </tr>
            <tr *ngIf="vehiclesForm.get('carsTotals').get('environmentTax').value">
                <td colspan="6" class="text-right">Περιβαλλοντικό Τέλος: </td>
                <td class="text-bold">{{vehiclesForm.get('carsTotals').get('environmentTax').value | number:'1.2-2'}}
                </td>

            </tr>
            <tr>
                <td colspan="6" class="text-right">ΣΥΝΟΛΟ: </td>
                <td class="text-bold excel-yellow">{{vehiclesForm.get('carsTotals').get('taxTotal').value |
                    number:'1.2-2'}}
                </td>

            </tr>

        </tfoot>
    </table>

</ng-container>