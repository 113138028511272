<ng-container *ngIf="beerForm$ | async as beerForm">
    <table class="noPrint" [formGroup]="beerForm">
        <colgroup>
            <col style="width: 1.4%;" />
            <col style="width: 10%;" />
            <col style="width: 5%;" />
            <col style="width: 5%;" />
            <col style="width: 5%;" />
            <col style="width: 5%;" />
            <col style="width: 4%;" />
            <col style="width: 4%;" />
            <col style="width: 5%;" />
            <col style="width: 5%;" />
        </colgroup>
        <thead>
            <tr>
                <th>Α.Α.</th>
                <th>ΟΝΟΜΑ ΠΡΟΙΟΝΤΟΣ</th>
                <th>ΜΟΝΑΔΕΣ ΦΟΡΟΛΟΓΗΣΗΣ (Plato*100lt)</th>
                <th>ΣΤΑΤΙΣΤΙΚΗ ΑΞΙΑ</th>
                <th>Ε.Φ.Κ.</th>
                <th>ΕΙΣΦΟΡΑ ΕΤΕΠΠΑ</th>
                <th>ΧΑΡΤΟΣΗΜΟ ΕΤΕΠΠΑ</th>
                <th>ΕΙΣΦΟΡΑ ΟΓΑ</th>
                <th>Φ.Π.Α.</th>
                <th>ΣΥΝΟΛΙΚΟΣ ΦΟΡΟΣ</th>
                <!-- ... other headers -->
            </tr>
        </thead>

        <tbody formArrayName="beerCalculationData">
            <tr *ngFor="let item of beerCalculationData.controls; let i = index" [formGroupName]="i">
                <td><input [value]="i+1" readonly tabindex="-1"></td>
                <td><input class="text-bold text-left" [value]="item.get('productName').value" readonly tabindex="-1">
                </td>
                <td><input class="text-bold" formControlName="platoPer100L" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="statisticValue" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="efk" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="eteppaContrib" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="eteppaStampTax" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="ogaContrib" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="vat" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="totalTax" readonly tabindex="-1"></td>
        </tbody>

        <tfoot>
            <tr [formGroup]="beerTotals">
                <td colspan="4" class="text-right">ΣΥΝΟΛΑ: </td>
                <td><input class="text-bold" formControlName="totalEfk" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="totalEteppa" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="totalEteppaStamp" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="totalOga" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="totalVat" readonly tabindex="-1"></td>
                <td><input class="text-bold" formControlName="totalGrand" readonly tabindex="-1"></td>
            </tr>
        </tfoot>
    </table>



    <table class="printOnly beerCalculationData">
        <colgroup>
            <col style="width: 1.4%;" />
            <col style="width: 10%;" />
            <col style="width: 5%;" />
            <col style="width: 5%;" />
            <col style="width: 5%;" />
            <col style="width: 5%;" />
            <col style="width: 4%;" />
            <col style="width: 4%;" />
            <col style="width: 5%;" />
            <col style="width: 5%;" />
        </colgroup>
        <thead>
            <tr>
                <th>Α.Α.</th>
                <th>ΟΝΟΜΑ ΠΡΟΙΟΝΤΟΣ</th>
                <th>ΜΟΝΑΔΕΣ ΦΟΡΟΛΟΓΗΣΗΣ (Plato*100lt)</th>
                <th>ΣΤΑΤΙΣΤΙΚΗ ΑΞΙΑ</th>
                <th>Ε.Φ.Κ.</th>
                <th>ΕΙΣΦΟΡΑ ΕΤΕΠΠΑ</th>
                <th>ΧΑΡΤΟΣΗΜΟ ΕΤΕΠΠΑ</th>
                <th>ΕΙΣΦΟΡΑ ΟΓΑ</th>
                <th>Φ.Π.Α.</th>
                <th>ΣΥΝΟΛΙΚΟΣ ΦΟΡΟΣ</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of beerForm.get('beerCalculationData').value; let i = index">
                <td>{{i+1}}</td>

                <td class=" text-bold text-left">{{item.productName}}</td>
                <td class=" text-bold">{{item.platoPer100L}}</td>
                <td class=" text-bold">{{item.statisticValue}}</td>
                <td class=" text-bold">{{item.efk}}</td>

                <td class=" text-bold">{{item.eteppaContrib}}</td>
                <td class=" text-bold">{{item.eteppaStampTax}}</td>
                <td class=" text-bold">{{item.ogaContrib}}</td>

                <td class=" text-bold">{{item.vat}}</td>
                <td class=" text-bold">{{item.totalTax}}</td>

        </tbody>

        <tfoot>
            <tr>
                <td colspan="4" class="text-right">ΣΥΝΟΛΑ: </td>
                <td class="text-bold">{{beerTotals.get('totalEfk').value}}</td>
                <td class="text-bold">{{beerTotals.get('totalEteppa').value}}</td>
                <td class="text-bold">{{beerTotals.get('totalEteppaStamp').value}}</td>
                <td class="text-bold">{{beerTotals.get('totalOga').value}}</td>
                <td class="text-bold">{{beerTotals.get('totalVat').value}}</td>
                <td class="text-bold">{{beerTotals.get('totalGrand').value}}</td>
            </tr>
        </tfoot>
    </table>

</ng-container>