import { AbstractControl, FormControl } from "@angular/forms";
import { SpecialMentionsModel } from "./special-mentions.model";

export class SpecialMentionsForm {
    [key: string]: AbstractControl;

    AdditionalInformationCoded: FormControl;
    Text: FormControl;


    constructor(value: SpecialMentionsModel) {

        this.AdditionalInformationCoded = new FormControl(value.AdditionalInformationCoded);
        this.Text = new FormControl(value.Text);

    }
}
