import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { FueCon300Form } from './fuecon300/fuecon300-form.model';
import { FueCon300Model } from './fuecon300/fuecon300.model';
import { MeaOfPro200Form } from './meaofpro200/meaofpro200-form.model';
import { MeaOfPro200Model } from './meaofpro200/meaofpro200.model';
import { Prov100Model } from './prov100.model';

export class Prov100Form {
    [key: string]: AbstractControl;

    ShiNamPRO1 = new FormControl();
    IMONumPRO2 = new FormControl();
    ShiFlaPRO3 = new FormControl();
    GroTonPRO4 = new FormControl();
    MEAOFPRO200 = new FormArray([]);
    FUECON300 = new FormArray([]);

    constructor(value: Prov100Model) {
        this.ShiNamPRO1.setValue(value.ShiNamPRO1);
        this.IMONumPRO2.setValue(value.IMONumPRO2);
        this.ShiFlaPRO3.setValue(value.ShiFlaPRO3);
        this.GroTonPRO4.setValue(value.GroTonPRO4);

        if (value.MEAOFPRO200) {
            value.MEAOFPRO200.forEach((means: MeaOfPro200Model) => {
                this.MEAOFPRO200.push(new FormGroup(new MeaOfPro200Form(new MeaOfPro200Model(means))));
            });
        } else {
            this.MEAOFPRO200.push(new FormGroup(new MeaOfPro200Form(new MeaOfPro200Model())));
        }

        if (value.FUECON300) {
            value.FUECON300.forEach((fuel: FueCon300Model) => {
                this.FUECON300.push(new FormGroup(new FueCon300Form(new FueCon300Model(fuel))));
            });
        } else {
            this.FUECON300.push(new FormGroup(new FueCon300Form(new FueCon300Model())));
        }

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
