export class ePaymentContainModel {
    bmrn?: string;
    blrn?: string;
    bcnt?: string;
    bafm?: string;


    //
    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);
        this.bmrn = value?.bmrn || null;
        this.blrn = value?.blrn || null;
        this.bcnt = value?.bcnt || null;
        this.bafm = value?.bafm || null;
    }
}
