<div fxLayout="column" style="width: 100%; flex-grow: 1">

    <!-- HEADER -->
    <div mat-dialog-title class="header primary p-6 p-sm-24" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- APP TITLE -->
        <div fxLayout="column" fxLayoutAlign="center flex-start">
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <span class="logo-text h1"> {{ dialogTitle }} </span>
            </div>
        </div>
        <!-- / APP TITLE -->

        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button (click)="dialogRef.close()">
                <mat-icon class="dialog-close-button">highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <!-- / HEADER -->

    <div mat-dialog-content #scrollContainer style="
        background-color: #f5f5f5;" class="p-3 m-0" fusePerfectScrollbar>

        <div style="width: 100%; display:flex; flex-direction: column;">
            <div *ngIf="!responses && contact.value==='international'" class="group">
                <h2>ΠΡΟΣΟΧΗ</h2>

                <div [innerHTML]="info_international"></div>
            </div>
            <div *ngIf="!responses && contact.value==='domestic'" class="group">
                <h2>ΠΡΟΣΟΧΗ</h2>

                <div [innerHTML]="info_domestic"></div>
            </div>

            <div *ngIf="responses && contact.value==='international'" class="group">
                <div [innerHTML]="info_results_international"></div>
            </div>

            <div *ngIf="responses && contact.value==='domestic'" class="group">
                <div [innerHTML]="info_results_domestic"></div>
            </div>

            <div *ngIf="fileToUpload.errors?.incorrect" class="group error">
                Το επιλεγμένο αρχείο δεν είναι το
                αναμενόμενο:
                {{filename}}
            </div>

            <div *ngIf="!responses" [formGroup]="form" class="w-full flex flex-row justify-center">

                <div style="width: 25%">

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Τύπος Πελάτη</mat-label>

                        <select matNativeControl name="customerType" formControlName="contactType" required>
                            <option value="domestic">Εισαγωγείς/Εξαγωγείς</option>
                            <option value="international">Διεθνείς Πελάτες</option>
                        </select>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>
                </div>

                <div class="ml-24" style="width: 17%; margin-top: 0.5%;">
                    <div class="button-wrapper mat-raised-button accent" fxLayout="row" (click)="selectFile()">
                        <button mat-icon-button type="button" [disabled]="loading$ | async">
                            <mat-icon>add_circle_outline</mat-icon>
                        </button>
                        <input hidden (change)="onFileSelected()" #fileInput [accept]="'.csv'" type="file" id="file" />
                        <span [ngClass]="{disabled: loading$ | async}">
                            Επιλογή Αρχείου Πελατών
                            <!-- Add this line to display the filename -->
                        </span>
                    </div>
                    <div *ngIf="fileToUpload.value">
                        <div>Επιλεγμένο αρχείο: {{fileToUpload.value.name}}</div>
                    </div>
                </div>
            </div>



            <div class="w-full">
                <div class="mat-elevation-z8" *ngIf="responses">
                    <table mat-table class="w-full" [dataSource]="responses">

                        <!-- Index Column -->
                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
                        </ng-container>

                        <!------------------------ERROR---------------------------------->


                        <ng-container matColumnDef="error">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.error.explanation"> {{element.error.explanation}} </span>
                                <span *ngIf="!element.error.explanation"> {{element.error}} </span>
                            </td>

                        </ng-container>
                        <ng-container matColumnDef="empty">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let element">
                                <span></span>
                            </td>

                        </ng-container>


                        <!------------------------ERROR---------------------------------->


                        <!------------------------DOMESTIC CUSTOMERS---------------->

                        <ng-container matColumnDef="afm">
                            <th mat-header-cell *matHeaderCellDef> Α.Φ.Μ. </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.contact"> {{element.contact?.afm}}</span>
                                <span *ngIf="!element.contact"> {{element.inputLine.afm}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="eori">
                            <th mat-header-cell *matHeaderCellDef> EORI </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.contact?.eori}} </td>
                        </ng-container>
                        <ng-container matColumnDef="customerType">
                            <th mat-header-cell *matHeaderCellDef> Τύπος Πελάτη </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.contact?.customerType===1">Επιχείρηση</span>
                                <span *ngIf="element.contact?.customerType===2">Ιδιώτης</span>

                            </td>
                        </ng-container>

                        <!------------------------/DOMESTIC CUSTOMERS---------------->


                        <!-- Lastname Column -->
                        <ng-container matColumnDef="lastname">
                            <th mat-header-cell *matHeaderCellDef> Επωνυμία </th>
                            <td mat-cell *matCellDef="let element"
                                [class.import-warning]="element.contact && (!element.contact?.lastname || element.contact?.lastname==='-')">
                                <span
                                    *ngIf="element.contact && !element.contact?.trademark">{{element.contact?.lastname}}</span>
                                <span
                                    *ngIf="element.contact && element.contact?.trademark">{{element.contact?.trademark}}</span>
                                <span *ngIf="!element.contact">{{element.inputLine.lastname}}</span>

                            </td>
                        </ng-container>

                        <!-- Firstname Column -->
                        <ng-container matColumnDef="firstname">
                            <th mat-header-cell *matHeaderCellDef> Όνομα </th>
                            <td mat-cell *matCellDef="let element"> {{element.contact?.firstname}} </td>
                        </ng-container>

                        <!-- Street Column -->
                        <ng-container matColumnDef="street">
                            <th mat-header-cell *matHeaderCellDef> Διευθυνση </th>
                            <td mat-cell *matCellDef="let element"
                                [class.import-warning]="!element.contact?.street || element.contact?.street==='-'">
                                {{element.contact?.street}} </td>
                        </ng-container>

                        <!-- City Column -->
                        <ng-container matColumnDef="city">
                            <th mat-header-cell *matHeaderCellDef> Πολη </th>
                            <td mat-cell *matCellDef="let element"
                                [class.import-warning]="!element.contact?.city || element.contact?.city==='-'">
                                {{element.contact?.city}} </td>
                        </ng-container>

                        <!-- Postal Code Column -->
                        <ng-container matColumnDef="postal_code">
                            <th mat-header-cell *matHeaderCellDef> Τ.Κ </th>
                            <td mat-cell *matCellDef="let element"
                                [class.import-warning]="!element.contact?.postal_code || element.contact?.postal_code==='-'">
                                {{element.contact?.postal_code}} </td>
                        </ng-container>

                        <!-- Country Column -->
                        <ng-container matColumnDef="country">
                            <th mat-header-cell *matHeaderCellDef> Χώρα </th>
                            <td mat-cell *matCellDef="let element" [class.import-danger]="!element.contact?.country">
                                {{element.contact?.country |
                                countryPipe:"country_name_en" | uppercase}} </td>
                        </ng-container>

                        <!-- Occupation Column -->
                        <ng-container matColumnDef="occupation">
                            <th mat-header-cell *matHeaderCellDef> Επάγγελμα </th>
                            <td mat-cell *matCellDef="let element"> {{element.contact?.occupation}} </td>
                        </ng-container>

                        <!-- Phone Number Column -->
                        <ng-container matColumnDef="phone_number">
                            <th mat-header-cell *matHeaderCellDef> Τηλέφωνο </th>
                            <td mat-cell *matCellDef="let element"> {{element.contact?.phone_number}} </td>
                        </ng-container>

                        <!-- Actions Column -->
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">

                                <button style="z-index:1000;color:red" mat-icon-button
                                    (click)="$event.stopPropagation(); deleteContact(row.contact)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns; when: normalRow"
                            [matTooltip]="getTooltip(row)" [class.import-inactive]="(row | inactive)"
                            matTooltipClass="tooltip" (click)="editContact(row)"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsError; when: errorRow"></tr>

                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!responses" mat-dialog-actions class="m-0 p-6" fxLayout="row" fxLayoutAlign="end center">


        <button mat-button class="save-button" (click)="process()" [disabled]="form.invalid" aria-label="SAVE">
            ΕΠΕΞΕΡΓΑΣΙΑ
        </button>

        <button mat-button class="save-button" (click)="dialogRef.close()" [disabled]="form.invalid" aria-label="SAVE">
            ΑΚΥΡΟ
        </button>
    </div>
</div>