<div fxLayout="row" fxLayoutAlign="center start">
    <mat-card *ngIf="taxAddEleForm">
        <table class="supUnitTable" [formGroup]="taxAddEleForm">
            <thead>
                <th>Περιγραφή</th>
                <th>Αξία</th>
            </thead>
            <tbody
                formArrayName="array"
                *ngFor="let item of array?.controls; let i = index"
            >
                <tr [formGroupName]="i" class="supUnit">
                    <td style="width: 40%; text-align: right">
                        <span>{{ supUnits[i].desc }}</span>
                    </td>
                    <td style="width: 60%; text-align: center">
                        <mat-form-field class="s-l s-30 sc-ul reset">
                            <input formControlName="value" matInput />
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </mat-card>
</div>
