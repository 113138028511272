import { NgModule } from '@angular/core';
import { ToposTirisisEggrafonComponent } from './topos-tirisis-eggrafon/topos-tirisis-eggrafon.component';
import { ToposTirisisEggrafonSvgComponent } from './topos-tirisis-eggrafon-svg/topos-tirisis-eggrafon-svg.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { FormFieldsModule } from 'app/main/main-building-blocks/form-fields/form-fields.module';
import { CustomsControlModule } from 'app/main/main-building-blocks/_generic-components/customs-control/customs-control.module';
import { GenericComponentsModule } from 'app/main/main-building-blocks/_generic-components/generic-components.module';
import { DateTransformPipeModule } from 'app/services/pipes/dateTransform.pipe';
import { ToposTirisisEggrafonPrintComponent } from './topos-tirisis-eggrafon-print/topos-tirisis-eggrafon-print.component';



@NgModule({

    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        CustomsControlModule,
        GenericComponentsModule,
        DateTransformPipeModule
    ],
    declarations: [
        ToposTirisisEggrafonComponent,
        ToposTirisisEggrafonSvgComponent,
        ToposTirisisEggrafonPrintComponent
    ],


})
export class ToposTirisisEggrafonModule { }
