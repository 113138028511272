import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ComplementaryConsigneesForm } from "../complementary-consignees/complementary-consignees-form.model";
import { ComplementaryConsigneesModel } from "../complementary-consignees/complementary-consignees.model";
import { DEFKContactDetailsForm } from "../contactDetails/contactDetails-form.model";
import { DEFKContactDetailsModel } from "../contactDetails/contactDetails.model";
import { SpecialConsigneeForm } from "../special-consignee/special-consignee-form.model";
import { SpecialConsigneeModel } from "../special-consignee/special-consignee.model";
import { VesselRegistrationDetailsForm } from "../vessel-registration-details/vessel-registration-details-form.model";
import { VesselRegistrationDetailsModel } from "../vessel-registration-details/vessel-registration-details.model";
import { TaxesDeclarationConsigneeModel } from "./taxes-declaration-consignee.model";

export class TaxesDeclarationConsigneeForm {
    [key: string]: AbstractControl;

    ConsigneeType: FormControl;
    ConsigneeIdentificationType: FormControl;
    ConsigneeIdentification: FormControl;
    ContactDetails: FormGroup;
    SpecialConsignee: FormGroup;
    VesselRegistrationDetails: FormGroup;
    ComplementaryConsignees: FormArray;
    ConsigneeObject: FormControl;

    constructor(value: TaxesDeclarationConsigneeModel) {

        this.ConsigneeType = new FormControl(value.ConsigneeType, [Validators.required]);
        this.ConsigneeIdentificationType = new FormControl(value.ConsigneeIdentificationType, [Validators.required]);
        this.ConsigneeIdentification = new FormControl(value.ConsigneeIdentification, [Validators.required]);
        this.ContactDetails = new FormGroup(new DEFKContactDetailsForm(new DEFKContactDetailsModel(value.ContactDetails)));
        this.SpecialConsignee = new FormGroup(new SpecialConsigneeForm(new SpecialConsigneeModel(value.SpecialConsignee)));
        this.VesselRegistrationDetails = new FormGroup(new VesselRegistrationDetailsForm(new VesselRegistrationDetailsModel(value.VesselRegistrationDetails)));
        this.ComplementaryConsignees = new FormArray([]);

        this.ConsigneeObject = new FormControl(value.ConsigneeObject);

        if (value.ComplementaryConsignees) {

            value.ComplementaryConsignees.forEach((consignee: ComplementaryConsigneesModel) => {

                this.ComplementaryConsignees.push(new FormGroup(new ComplementaryConsigneesForm(new ComplementaryConsigneesModel(consignee))))
            })
        }
        else {
            this.ComplementaryConsignees.push(new FormGroup(new ComplementaryConsigneesForm(new ComplementaryConsigneesModel())))
        }


    }
}
