<div class="custom-autocomplete" [formGroup]="form">

    <input matInput formControlName="inputCtrl" [class]="class" [ngClass]="{trapFocus: trapFocus}" [id]="id"
        [matAutocomplete]="auto" matAutocompletePosition="auto" [placeholder]="placeholder" [autofocus]="autofocus"
        (focus)="onFocus($event)" (blur)="onBlur($event)" (keyup.esc)="onEscape($event)" [tabindex]="tabindex" />
    <mat-autocomplete autoActiveFirstOption panelWidth="auto" #auto="matAutocomplete"
        [displayWith]="displayFn.bind(this)">
        <!-- 
    Autocomplete template when output should be an Object (output IS set on the form field component)
 -->
        <ng-container *ngIf="output">
            <mat-option *ngFor="let option of filteredOptions" [value]="option[output]">
                <ng-container *ngTemplateOutlet="optionTemplate; context: {$implicit:option}"></ng-container>
            </mat-option>
        </ng-container>
        <!-- 
    Autocomplete template when output should be a string (output IS NOT set on the form field component)
 -->
        <ng-container *ngIf="!output">
            <mat-option *ngFor="let option of filteredOptions" [value]="option">
                <ng-container *ngTemplateOutlet="optionTemplate; context: {$implicit:option}"></ng-container>
            </mat-option>
        </ng-container>

    </mat-autocomplete>

</div>

<ng-template #optionTemplate let-option>

    <div style="padding: 1px 2px">
        <span *ngIf="!!menuTitle1">{{ menuTitle1 }}</span><span>{{ option[menuOption1] }}</span>
    </div>
    <div style="padding: 1px 2px" *ngIf="!!menuOption2">
        {{ menuSeparator }}
    </div>

    <div style="padding: 0 2px" *ngIf="!!menuOption2">
        <small *ngIf="!optionRegular">
            <span *ngIf="!!menuTitle2">{{ menuTitle2 }}</span><span> {{ option[menuOption2] }}</span>
        </small>
        <span *ngIf="!!optionRegular">
            <span *ngIf="!!menuTitle2">{{ menuTitle2 }}</span><span> {{ option[menuOption2] }}</span>
        </span>
    </div>

</ng-template>