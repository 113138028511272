<div id="customs" fxLayout="column">
    <!-- HEADER -->
    <div class="header primary p-12 p-sm-24" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- APP TITLE -->
        <div fxLayout="row" fxLayoutAlign="start center">
            <!--            
 -->
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <!--  <mat-icon
                    class="logo-icon mr-16"
                    [@animate]="{
                        value: '*',
                        params: { delay: '50ms', scale: '0.2' }
                    }"
                    >archive
                </mat-icon>
                -->
                <span class="logo-text h1"> Τελωνεία </span>
            </div>
        </div>
        <!-- / APP TITLE -->

        <!-- SEARCH -->
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex [formGroup]="filters">
            <div class="search-wrapper">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input formControlName="searchQuery" placeholder="Αναζήτηση..." />
                </div>
            </div>
        </div>
        <!-- / SEARCH -->
        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button (click)="dialogRef.close()">
                <mat-icon class="dialog-close-button">highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <!-- / HEADER -->

    <!--  FILTERS  -->
    <div>
        <customs-filters [countries]="countries"></customs-filters>
    </div>
    <!--  /FILTERS  -->
    <div fxLayout="row" *ngIf="allCustomsDataSource; else loading">
        <mat-card style="width: 100%; margin: 0 16px" fxLayout="column">
            <customs-list [formDisabled]="formDisabled" [DATASOURCE]="allCustomsDataSource"
                [countries]="countries"></customs-list>
        </mat-card>
    </div>
</div>

<ng-template #loading>
    <div>It Is Loading</div>
</ng-template>