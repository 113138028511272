import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { ChemistryDetailsForm } from "../chemical-details/chemistry-details-form.model";
import { ChemistryDetailsType } from "../chemical-details/chemistry-details.model";
import { ClearingAgentForm } from "../clearing-agent/clearing-agent-form.model";
import { ClearingAgentType } from "../clearing-agent/clearing-agent.model";
import { DeliveryDetailsForm } from "../delivery-details/delivery-details-form.model";
import { DeliveryDetailsType } from "../delivery-details/delivery-details.model";
import { GuaranteeReferenceForm } from "../guarantee-reference/guarantee-reference-form.model";
import { GuaranteeReferenceType } from "../guarantee-reference/guarantee-reference.model";
import { TaxesDeclarationModel } from "./taxes-declaration.model";

export class TaxesDeclarationForm {
    [key: string]: AbstractControl;

    DeclarationReferenceNumber: FormControl;

    LocalReferenceNumber: FormControl;
    Fallbackflag: FormControl;
    FallbackDocumentReference: FormControl;
    FallbackOffice: FormControl;
    RegistrationDate: FormControl;
    RegistrationOffice: FormControl;
    DocumentState: FormControl;
    DocumentNumber: FormControl;
    TotalDocuments: FormControl;
    NumberOfRows: FormControl;
    TotalPackages: FormControl;
    FinancialResponsibleTaxId: FormControl;
    DispatchCountry: FormControl;
    DestinationCountry: FormControl;
    ExistenceOfContainerFlag: FormControl;
    DeliveryConditions: FormControl;
    Currency: FormControl;
    InvoiceAmount: FormControl;
    ExchangeFactor: FormControl;
    ChemicalAnalysisPerformedFlag: FormControl;
    ThroughSimplifiedProcedureFlag: FormControl;
    OfficeOfDispatch: FormControl;
    PaymentMethodCode: FormControl;
    CertificateIssuanceFlag: FormControl;
    DeclarationTypeCode: FormControl;
    CalculationOfTaxesDate: FormControl;
    GeneralChemistryDetails: FormGroup;
    DeliveryDetails: FormGroup;
    ClearingAgent: FormGroup;
    GuaranteeReference: FormGroup;

    /*

   */

    constructor(declaration: TaxesDeclarationModel) {

        this.DeclarationReferenceNumber = new FormControl(declaration.DeclarationReferenceNumber);
        this.LocalReferenceNumber = new FormControl(declaration.LocalReferenceNumber);
        this.Fallbackflag = new FormControl(declaration.Fallbackflag);
        this.FallbackDocumentReference = new FormControl(declaration.FallbackDocumentReference);
        this.FallbackOffice = new FormControl(declaration.FallbackOffice);
        this.RegistrationDate = new FormControl(declaration.RegistrationDate);
        this.RegistrationOffice = new FormControl(declaration.RegistrationOffice);
        this.DocumentState = new FormControl(declaration.DocumentState);
        this.DocumentNumber = new FormControl(declaration.DocumentNumber);
        this.TotalDocuments = new FormControl(declaration.TotalDocuments);
        this.NumberOfRows = new FormControl(declaration.NumberOfRows, { updateOn: 'blur' });
        this.TotalPackages = new FormControl(declaration.TotalPackages);
        this.FinancialResponsibleTaxId = new FormControl(declaration.FinancialResponsibleTaxId);
        this.DispatchCountry = new FormControl(declaration.DispatchCountry);
        this.DestinationCountry = new FormControl(declaration.DestinationCountry);
        this.ExistenceOfContainerFlag = new FormControl(declaration.ExistenceOfContainerFlag);
        this.DeliveryConditions = new FormControl(declaration.DeliveryConditions);
        this.Currency = new FormControl(declaration.Currency);
        this.InvoiceAmount = new FormControl(declaration.InvoiceAmount);
        this.ExchangeFactor = new FormControl(declaration.ExchangeFactor, { updateOn: 'blur' });
        this.ChemicalAnalysisPerformedFlag = new FormControl(declaration.ChemicalAnalysisPerformedFlag);
        this.ThroughSimplifiedProcedureFlag = new FormControl(declaration.ThroughSimplifiedProcedureFlag);
        this.OfficeOfDispatch = new FormControl(declaration.OfficeOfDispatch);
        this.PaymentMethodCode = new FormControl(declaration.PaymentMethodCode);
        this.CertificateIssuanceFlag = new FormControl(declaration.CertificateIssuanceFlag);
        this.DeclarationTypeCode = new FormControl(declaration.DeclarationTypeCode);
        this.CalculationOfTaxesDate = new FormControl(declaration.CalculationOfTaxesDate);
        this.GeneralChemistryDetails = new FormGroup(new ChemistryDetailsForm(new ChemistryDetailsType(declaration.GeneralChemistryDetails)));
        this.DeliveryDetails = new FormGroup(new DeliveryDetailsForm(new DeliveryDetailsType(declaration.DeliveryDetails)));
        this.ClearingAgent = new FormGroup(new ClearingAgentForm(new ClearingAgentType(declaration.ClearingAgent)));
        this.GuaranteeReference = new FormGroup(new GuaranteeReferenceForm(new GuaranteeReferenceType(declaration.GuaranteeReference)));
    }
}
