import { Trapripc1Model } from 'app/main/apps/transit/tir/_models/trapripc1/trapripc1.model';
import { Trapripc1Form } from 'app/main/apps/transit/tir/_models/trapripc1/trapripc1-form.model';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { TirCancelModel } from './declaration.model';
import { CancelHeaheaForm } from 'app/main/apps/export/cancel-declaration/_models/heahea/heahea-form.model';
import { CancelHeaheaModel } from 'app/main/apps/export/cancel-declaration/_models/heahea/heahea.model';
import { CusoffdepeptForm } from '../../../_models/cusoffdepept/cusoffdepept-form.model';
import { CusoffdepeptModel } from '../../../_models/cusoffdepept/cusoffdepept.model';

export class TirCancelForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    SynIdeMES1: FormControl;
    SynVerNumMES2: FormControl;
    MesSenMES3: FormControl;
    SenIdeCodQuaMES4: FormControl;
    MesRecMES6: FormControl;
    RecIdeCodQuaMES7: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    IntConRefMES11: FormControl;
    RecRefMES12: FormControl;
    RecRefQuaMES13: FormControl;
    AppRefMES14: FormControl;
    PriMES15: FormControl;
    AckReqMES16: FormControl;
    ComAgrIdMES17: FormControl;
    TesIndMES18: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    ComAccRefMES21: FormControl;
    MesSeqNumMES22: FormControl;
    FirAndLasTraMES23: FormControl;

    HEAHEA: FormGroup;
    TRAPRIPC1: FormGroup;
    CUSOFFDEPEPT: FormGroup;

    /*

   */

    constructor(declaration: TirCancelModel, subdomain?: string, messageType?: string) {

        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));


        this.SynIdeMES1 = new FormControl(declaration.SynIdeMES1);
        this.SynVerNumMES2 = new FormControl(declaration.SynVerNumMES2);
        this.MesSenMES3 = new FormControl(declaration.MesSenMES3);
        this.SenIdeCodQuaMES4 = new FormControl(declaration.SenIdeCodQuaMES4);
        this.MesRecMES6 = new FormControl(declaration.MesRecMES6);
        this.RecIdeCodQuaMES7 = new FormControl(declaration.RecIdeCodQuaMES7);
        this.DatOfPreMES9 = new FormControl(declaration.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(declaration.TimOfPreMES10);
        this.IntConRefMES11 = new FormControl(declaration.IntConRefMES11);
        this.RecRefMES12 = new FormControl(declaration.RecRefMES12);
        this.RecRefQuaMES13 = new FormControl(declaration.RecRefQuaMES13);
        this.AppRefMES14 = new FormControl(declaration.AppRefMES14);
        this.PriMES15 = new FormControl(declaration.PriMES15);
        this.AckReqMES16 = new FormControl(declaration.AckReqMES16);
        this.ComAgrIdMES17 = new FormControl(declaration.ComAgrIdMES17);
        this.TesIndMES18 = new FormControl(declaration.TesIndMES18);
        this.MesIdeMES19 = new FormControl(declaration.MesIdeMES19);
        this.MesTypMES20 = new FormControl(declaration.MesTypMES20);
        this.ComAccRefMES21 = new FormControl(declaration.ComAccRefMES21);
        this.MesSeqNumMES22 = new FormControl(declaration.MesSeqNumMES22);
        this.FirAndLasTraMES23 = new FormControl(declaration.FirAndLasTraMES23);

        this.HEAHEA = new FormGroup(new CancelHeaheaForm(new CancelHeaheaModel(declaration.HEAHEA)));
        this.TRAPRIPC1 = new FormGroup(new Trapripc1Form(new Trapripc1Model(declaration.TRAPRIPC1)));
        this.CUSOFFDEPEPT = new FormGroup(new CusoffdepeptForm(new CusoffdepeptModel(declaration.CUSOFFDEPEPT)));


    }
}
