export class SpecialMentionsModel {
    AdditionalInformationCoded?: string;
    Text?: string;


    constructor(value?) {
        this.AdditionalInformationCoded = value?.AdditionalInformationCoded || null;
        this.Text = value?.Text || null;

    }
}
