import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProductNode, TaricService } from './taric.service';

@Injectable()
export class TaricResolver implements Resolve<ProductNode[]> {
    constructor(private _taricService: TaricService) {
        console.log('CUSTMS resolver hit');
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const allTaric = this._taricService.getAllTaric();

        console.log('CUSTMS dataSource:', allTaric);
        return allTaric;
    }
}
