import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
//import { AppStoreModule } from "app/store/store.module";
import { LayoutModule } from 'app/layout/layout.module';

import { MaterialModule } from './main/angular-material-elements/material.module';
//import { SimpleNotificationsModule } from "angular2-notifications";
import { ToastrModule } from 'ngx-toastr';
import { HttpInterceptProviders } from './services/http-interceptors';
import { HttpErrorService } from './services/http-error.service';

import { HotkeyModule } from 'angular2-hotkeys';
import { TabService } from './main/apps/tab-factory/tab.service';
import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeEl from '@angular/common/locales/el';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { TaricModule } from './main/apps/taric/taric.module';
import { appRoutes } from './app.routing';
import { CustomsModule } from './main/apps/customs/customs.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CoreModule } from '@angular/flex-layout';
import { ArrivalAtExitModule } from './main/apps/export/arrival-at-exit/arrival-at-exit.module';
import { TariffCalculateModule } from './main/apps/tariff-calculate/tariff-calculate.module';
import { AppSettingsModule } from './main/app-settings/app-settings/app-settings.module';
import { EAitisiModule } from './main/apps/e-aitisi/e-aitisi.module';
import { RollbarErrorHandler, RollbarService, rollbarFactory } from './services/rollbar-errorhandler.service';
import { DefaultValueAccessor } from '@angular/forms';
import { EPaymentModule } from './main/apps/e-payment/e-payment.module';
import { OfficeResolver } from 'app/office.resolver';
import { AppResolver } from 'app/app.resolver';
import { BusinessResolver } from 'app/main/businesses/business.resolver';
import { ExciseHelperTablesModule } from 'app/main/apps/excise-helper-tables/excise-helper-tables.module';
import { GeneralApplicationsModule } from 'app/main/apps/general-applications/general-applications.module';

registerLocaleData(localeEl, 'el');

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpCacheInterceptorModule.forRoot(),
        RouterModule.forRoot(appRoutes, {
            relativeLinkResolution: 'legacy'
        }),
        //SimpleNotificationsModule.forRoot(),

        TranslateModule.forRoot(),

        //Material Module
        MaterialModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
            closeButton: true,
            newestOnTop: false,
            progressBar: true,
            maxOpened: 5,
            autoDismiss: false,
            enableHtml: true
        }),
        HotkeyModule.forRoot(),

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        NgxTippyModule,

        CoreModule,

        // App modulehttps://angular-e5k2vm.stackblitz.io/s
        LayoutModule,
        //AppStoreModule,
        CustomsModule,
        TaricModule,
        ArrivalAtExitModule,
        EAitisiModule,
        EPaymentModule,
        TariffCalculateModule,
        ExciseHelperTablesModule,
        GeneralApplicationsModule,
        AppSettingsModule,
        ServiceWorkerModule.register('service-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 5 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:5000'
        })
    ],
    providers: [
        HttpInterceptProviders,
        HttpErrorService,
        TabService,
        DatePipe,
        { provide: LOCALE_ID, useValue: 'el' },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        AppResolver,
        OfficeResolver,
        BusinessResolver
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor() {

        /*   DefaultValueAccessor.prototype.registerOnChange = function (fn: (_: string | null) => void): void {
              this.onChange = (value: string | null) => {
                  fn(value === '' ? null : value);
              };
          }; */
    }


}
