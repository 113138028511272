import { FormControl } from '@angular/forms';
import { SaveDataModel } from './saveData.model';

export class SaveDataForm {
    [key: string]: FormControl;

    id = new FormControl();
    domain = new FormControl();
    subdomain = new FormControl();
    messageType = new FormControl();
    messageIn = new FormControl();
    userId = new FormControl();
    lrn = new FormControl();
    declarationForm = new FormControl();
    eur1Form = new FormControl();
    agrCertForm = new FormControl();
    cmrForm = new FormControl();
    inf4Form = new FormControl();
    remittance = new FormControl();
    declarantId = new FormControl();
    traderId = new FormControl();
    customerId = new FormControl();
    mrn = new FormControl();
    description = new FormControl();
    state = new FormControl();
    documentType = new FormControl();
    submissionDate = new FormControl();
    stateDate = new FormControl();
    last_modified = new FormControl();
    noNotification = new FormControl();
    originalDocType = new FormControl();
    originalDocId = new FormControl();
    referrerDeclarationId = new FormControl();

    constructor(value: SaveDataModel) {
        this.id.setValue(value.id);
        this.domain.setValue(value.domain);
        this.subdomain.setValue(value.subdomain);
        this.messageType.setValue(value.messageType);
        this.messageIn.setValue(value.messageIn);
        this.userId.setValue(value.userId);
        this.lrn.setValue(value.lrn);
        this.declarationForm.setValue(value.declarationForm);
        this.eur1Form.setValue(value.eur1Form);
        this.inf4Form.setValue(value.inf4Form);
        this.agrCertForm.setValue(value.agrCertForm);
        this.cmrForm.setValue(value.cmrForm);
        this.remittance.setValue(value.remittance);
        this.declarantId.setValue(value.declarantId);
        this.traderId.setValue(value.traderId);
        this.customerId.setValue(value.customerId);
        this.mrn.setValue(value.mrn);
        this.description.setValue(value.description);
        this.state.setValue(value.state);
        this.documentType.setValue(value.state);
        this.submissionDate.setValue(value.submissionDate);
        this.stateDate.setValue(value.stateDate);
        this.last_modified.setValue(value.last_modified);
        this.noNotification.setValue(value.noNotification);
        this.originalDocType.setValue(value.originalDocType);
        this.originalDocId.setValue(value.originalDocId);
        this.referrerDeclarationId.setValue(value.referrerDeclarationId);
        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
