<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
    <div class="logo" (click)="goToHomePage()">
        <img class="logo-icon" src="assets/images/logos/fuse.svg" />
        <span class="logo-text secondary-text">TRADE</span><span class="logo-text secondary-text"
            style="font-size: 1.4em; margin-left: 0">XT</span>
        <!-- <span class="logo-text secondary-text" style="margin-left: 0">T</span> -->
    </div>

    <div class="buttons">
        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()">
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>
        <!-- 
        <button
            mat-icon-button
            class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()"
            fxHide.gt-md
        >
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button> -->
    </div>
</div>

<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
        <ng-container *ngIf="user$| async as user">
            <div class="h3 username">
                <span *ngIf="user.declarant">{{ user.declarant.fullname | uppercase }}</span>
                <span *ngIf="!user.declarant">{{ user.user_firstname }}<span *ngIf="user.user_lastname">
                        {{user.user_lastname}}</span></span>
            </div>

            <div *ngIf="user.declarant" class="h5 email hint-text mt-8">
                <span>EORI : {{ user.declarant.eori }}</span>
            </div>
            <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
                (click)="goToHomePage()">
                <img class="avatar" src="assets/images/avatars/Velazquez.jpg" />
            </div>
        </ng-container>
    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>
</div>