import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { customsAllItem, genericItem } from 'app/model/api-model';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { CustomsService } from '../customs.service';

@Component({
    selector: 'app-customs-dialog',
    templateUrl: './customs-dialog.component.html',
    styleUrls: ['./customs-dialog.component.scss']
    // providers: [CustomsService],
})
export class CustomsDialogComponent implements OnInit {
    title: string;
    titleName: string;

    tabChange: MatTabChangeEvent;
    selectedIndex: number;

    color1 = '';
    color2 = 'primary';
    customsOffice: customsAllItem;

    countryCode: string;
    regionCode: string;
    languages: genericItem[];
    region: any = [];

    private _destroy: Subject<void>;

    constructor(
        public dialogRef: MatDialogRef<CustomsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dbQuery: ApiService
    ) {
        console.log('CUSTMS dialog started');

        // Set the private defaults
        this._destroy = new Subject<void>();
    }

    ngOnInit(): void {
        this.dbQuery
            .get_options(ApiEndPoints.languages)
            .pipe(take(1))
            .subscribe((data: genericItem[]) => (this.languages = data));

        this.dbQuery
            .get_custom(this.data.ReferenceNumber)
            .pipe(
                switchMap((value: customsAllItem) => {
                    this.customsOffice = value;

                    this.countryCode = value.CountryCode;
                    this.regionCode = value.RegionCode;

                    console.log('countryCode', this.countryCode);
                    console.log('regionCode', this.regionCode);
                    console.log('customsOffice', this.customsOffice);
                    this.titleName = this.customsOffice.CustomsOfficeLsd[0].CustomsOfficeUsualName;

                    if (this.regionCode) {
                        console.log('getting region');

                        const postdata = {
                            CountryCode: this.countryCode,
                            CountryRegionCode: this.regionCode
                        };

                        return this.dbQuery.get_region(postdata);
                    } else {
                        return [];
                    }
                }),
                take(1)
            )
            .subscribe(region => {
                this.region = region;
                console.log('region', this.region);
            });
    }
    ngOnDestroy() {
        console.log('CUSTMS dialog destroyed');

        this._destroy.next();
        this._destroy.complete();
    }

    getLanguage(code) {
        if (code && this.languages) {
            const note = this.languages.find(e => e.code === code);

            return note.desc;
        }

        return null;
    }

    changeStyle1(event) {
        this.color1 = event.type === 'mouseenter' ? 'accent' : '';
    }
    changeStyle2(event) {
        this.color2 = event.type === 'mouseenter' ? 'accent' : 'primary';
    }

    tabChanged(event: MatTabChangeEvent) {
        this.tabChange = event;

        this.titleName = this.customsOffice.CustomsOfficeLsd[event.index].CustomsOfficeUsualName;
    }
}
