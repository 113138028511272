import { TirCancelModel } from 'app/main/apps/transit/tir/_models/cancel-declaration/declaration.model';
import { TirCancelForm } from 'app/main/apps/transit/tir/_models/cancel-declaration/declaration-form.model';
import { ImportCancelDeclarationModel } from './../../main/apps/import/_models/cancel-declaration/declaration.model';
import { ImportCancelDeclarationForm } from './../../main/apps/import/_models/cancel-declaration/declaration-form.model';
import { EF13ADeclarationModel } from './../../main/apps/excise/defk/_models/EF13A/ef13a-declaration.model';
import { DeclarationOfExciseTaxesAmendmentType } from './../../main/apps/excise/defk/_models/EF13A/body.model';
import { DeclarationOfExciseTaxesRegistrationType } from './../../main/apps/excise/defk/_models/EF15A/Body/body.model';
import { DEFKDeclarationModel } from 'app/main/apps/excise/defk/_models/EF15A/declaration.model';
import { Injectable } from '@angular/core';
import { eAitisiDeclarationModel } from 'app/main/apps/e-aitisi/_models/declaration/declaration.model';
import { VA13ADeclarationModel } from 'app/main/apps/excise/dao/_models/VA13A/va13a-declaration.model';
import { DaoDeclarationModel } from 'app/main/apps/excise/dao/_models/VA15A/declaration.model';
import { ArrAtExitModel } from 'app/main/apps/export/arrival-at-exit/_models/arrivaAtExit.model';
import { CancelModel } from 'app/main/apps/export/cancel-declaration/_models/cancel.model';
import { Declaration } from 'app/main/apps/export/declaration/_models/declaration.model';
import { ImportDeclarationModel } from 'app/main/apps/import/_models/declaration/declaration.model';
import { T2LCancelDeclarationModel } from 'app/main/apps/transit/t2l/_models/cancel-declaration/declaration.model';
import { T2LDeclarationModel } from 'app/main/apps/transit/t2l/_models/declaration/declaration.model';
import { TirDeclarationModel } from 'app/main/apps/transit/tir/_models/declaration/declaration.model';
import { environment } from 'environments/environment';
import { DeclarationModels } from './submitForm.service';
import { isEqual } from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class RemovePrivateService {
    testing: boolean;
    constructor() {
        this.testing = environment.testing;
    }

    removePrivate(submitForm, messageType: string): DeclarationModels {
        console.log("fn removePrivate removePrivate submitForm", submitForm);

        delete submitForm._PRIVATE;


        console.log("messageType on removePrivate ", messageType)
        switch (messageType) {
            case 'CC515A': {
                submitForm = this.removeExport(submitForm);
                break;
            }
            case 'CC513A': {
                submitForm = this.removeExport(submitForm);
                break;
            }
            case 'CC514A': {
                submitForm = this.CC514A(submitForm);
                break;
            }
            case 'ID15A': {
                submitForm = this.removeImport(submitForm);
                break;
            }
            case 'ID13A': {
                submitForm = this.removeImport(submitForm);
                break;
            }
            case 'ID14A': {
                submitForm = this.ID14A(submitForm);
                break;
            }
            case 'CC507A': {
                submitForm = this.arrAtExit(submitForm);
                break;
            }
            case 'IB15A': {
                submitForm = this.eAitisi(submitForm);
                break;
            }
            case 'IB13A': {
                submitForm = this.eAitisi(submitForm);
                submitForm = this.IB13A(submitForm);
                break;
            }
            case 'IB14A': {
                submitForm = this.IB14A(submitForm);
                break;
            }

            case 'TF015A': {
                submitForm = this.TF015A(submitForm);
                break;
            }
            case 'TF013A': {
                submitForm = this.TF015A(submitForm);
                submitForm = this.TF013A(submitForm);
                break;
            }
            case 'TF014A': {
                submitForm = this.TF014A(submitForm);
                break;
            }

            case 'CC015B': {
                submitForm = this.tir(submitForm);
                break;
            }
            case 'CC013B': {
                submitForm = this.tir(submitForm);
                break;
            }

            case 'CC014A': {
                submitForm = this.CC014A(submitForm);
                break;
            }

            case 'VA15A': {
                submitForm = this.VA15A(submitForm);
                break;
            }
            case 'VA13A': {
                submitForm = this.VA13A(submitForm);
                break;
            }
            case 'EF15A': {
                submitForm = this.EF15A(submitForm);
                break;
            }
            case 'EF13A': {
                submitForm = this.EF13A(submitForm);
                break;
            }
        }

        return submitForm;
    }

    private removeExport(submitForm: Declaration): Declaration {

        const heahea = submitForm.HEAHEA;
        const currentGoods = submitForm.GOOITEGDS;


        //////////////////////////////////////////////////////////////////
        ///////////////////////REMOVE AFTER TESTING////////////////////////
        console.log("fn removePrivate removeExport")

        if (this.testing) {

            heahea.TotGroMasHEA307 = '1999999999';
        }

        /////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////

        if (heahea.AmeAccDatHEA602) {
            delete heahea.AmeAccDatHEA602;
        }
        if (heahea.AccDatHEA158) {
            delete heahea.AccDatHEA158;
        }

        this.truncateNames(submitForm);
        this.exportBisHandling(submitForm);
        this.removeEXP_DECL(submitForm)
        //DELETE PRIVATE OBJECTS ON ITEM LEVEL
        //==================================================================
        for (let i = 0; i < currentGoods.length; i++) {
            const GoodsFormGroup = currentGoods[i];

            const traconex2 = GoodsFormGroup.TRACONEX2;
            const traconce2 = GoodsFormGroup.TRACONCE2;
            const comcodgoditm = GoodsFormGroup.COMCODGODITM;

            delete GoodsFormGroup.ACCOUNTING;
            const firstTraconex = traconex2[0];
            const firstTraconce2 = traconce2[0];
            if (firstTraconex) {
                delete firstTraconex.ExporterObject;
            }
            if (firstTraconce2) {
                delete firstTraconce2.ConsigneeObject;
            }
            delete comcodgoditm[0]._combinedComCode;
        }
        console.log('submitForm After removePrivate:', submitForm);

        return submitForm;
    }

    private removeImport(submitForm: ImportDeclarationModel): ImportDeclarationModel {

        const heahea = submitForm.HEAHEA;

        //////////////////////////////////////////////////////////////////
        ///////////////////////REMOVE AFTER TESTING////////////////////////

        if (this.testing) {

            heahea.TotGroMasHEA307 = '1999999999';
        }

        /////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////


        if (heahea.AmeAccDatHEA602) {
            delete heahea.AmeAccDatHEA602;
        }
        if (heahea.AccDatHEA158) {
            delete heahea.AccDatHEA158;
        }

        const entcusoff = submitForm.ENTCUSOFF;
        const impcusoff = submitForm.IMPCUSOFF;

        delete entcusoff._countryCode;
        delete impcusoff._countryCode;

        this.truncateNames(submitForm);
        this.importBisHandling(submitForm);
        //DELETE PRIVATE OBJECTS ON ITEM LEVEL
        //==================================================================

        const currentGoods = submitForm.GOOITEGDS;

        for (let i = 0; i < currentGoods.length; i++) {
            const GoodsFormGroup = currentGoods[i];

            const traconco2 = GoodsFormGroup.TRACONCO2;
            const traconce2 = GoodsFormGroup.TRACONCE2;
            const caltaxgod = GoodsFormGroup.CALTAXGOD;
            const comcodgoditm = GoodsFormGroup.COMCODGODITM;
            delete comcodgoditm[0]._combinedComCode;

            delete GoodsFormGroup.ACCOUNTING;

            const firstTraconco = traconco2[0];
            const firstTraconce2 = traconce2[0];
            if (firstTraconco) {
                delete firstTraconco.ConsignorObject;
            }
            if (firstTraconce2) {
                delete firstTraconce2.ConsigneeObject;
            }

            for (let j = 0; j < caltaxgod.length; j++) {
                delete caltaxgod[j]._natTypeCode;
                delete caltaxgod[j]._taxFreeCode;
                delete caltaxgod[j]._taxPaidCode;
                delete caltaxgod[j]._partialExemption;
                delete caltaxgod[j]._wholeTaxAmount;
            }
        }
        console.log('submitForm After removePrivate:', submitForm);

        return submitForm;
    }

    private exportBisHandling(submitForm: Declaration): Declaration {
        console.log("fn removePrivate exportBisHandling")

        const currentGoods = submitForm.GOOITEGDS;
        const currentTraExpex1 = submitForm.TRAEXPEX1;
        const currentTraConce1 = submitForm.TRACONCE1;
        const heahea = submitForm.HEAHEA;
        const countryOfDisp = heahea.CouOfDisCodHEA55;
        const countryOfDest = heahea.CouOfDesCodHEA30;
        const ExporterSpecialMention = currentTraExpex1 ? currentTraExpex1.SpeMenEX1011 : null;
        const ConsigneeSpecialMention = currentTraConce1 ? currentTraConce1.SpeMenCE1003 : null;

        length = currentGoods.length;

        const exportersArray = [];
        const consigneesArray = [];
        const dispatchCouArray = [];
        const destinCouArray = [];

        for (let i = 0; i < length; i++) {
            const GoodsFormGroup = currentGoods[i];

            const traconex2 = GoodsFormGroup.TRACONEX2[0];
            const traconce2 = GoodsFormGroup.TRACONCE2[0];

            //We get all the item values except of the first item, because the first item at first is empty, the values or set on the main level for the first item
            if (i > 0) {
                const itemExporter = traconex2.ExporterObject;
                const itemConsignee = traconce2.ConsigneeObject;
                const ex15a = GoodsFormGroup.CouOfDisGDS58;
                const ex17a = GoodsFormGroup.CouOfDesGDS59;

                exportersArray.push(itemExporter);
                consigneesArray.push(itemConsignee);
                dispatchCouArray.push(ex15a);
                destinCouArray.push(ex17a);
            }
        }

        //  MULTIPLE EXPORTERS HANDLING==============================
        //==============================================================
        const mainExporter = currentTraExpex1.ExporterObject;
        //if all values in the array are equal to the main exporter value

        console.log("xport bisHandling, mainExporter", mainExporter)
        console.log("xport bisHandling, exportersArray", exportersArray)

        const expEqual = arr => arr.every(val => isEqual(val, mainExporter));

        const sameExporters = expEqual(exportersArray);

        for (let i = 0; i < length; i++) {
            const GoodsFormGroup = currentGoods[i];

            const traconex2 = GoodsFormGroup.TRACONEX2[0];

            console.log("xport bisHandling at index " + i + " traconex2", traconex2)
        }
        //if we have the same exporters delere exporter on item level
        if (sameExporters) {
            for (let i = 0; i < length; i++) {
                const GoodsFormGroup = currentGoods[i];

                const traconex2 = GoodsFormGroup.TRACONEX2[0];

                console.log("xport bisHandling at index " + i + " traconex2", traconex2)


                if (traconex2) {
                    delete traconex2.NamEX27;
                    delete traconex2.StrAndNumEX222;
                    delete traconex2.PosCodEX223;
                    delete traconex2.CitEX224;
                    delete traconex2.CouEX225;
                    delete traconex2.TINEX259;
                    delete traconex2.NADLNGGTEX;
                }
            }
        }

        //if exporters are not the same, copy the main exporter on the first item and delete values on main level
        //set Special Mention to 00200
        else {

            const traconex2 = currentGoods[0].TRACONEX2[0];

            traconex2.NamEX27 = currentTraExpex1.NamEX17;
            traconex2.StrAndNumEX222 = currentTraExpex1.StrAndNumEX122;
            traconex2.PosCodEX223 = currentTraExpex1.PosCodEX123;
            traconex2.CitEX224 = currentTraExpex1.CitEX124;
            traconex2.CouEX225 = currentTraExpex1.CouEX125;
            traconex2.TINEX259 = currentTraExpex1.TINEX159;
            traconex2.NADLNGGTEX = currentTraExpex1.NADLNGEX;

            delete currentTraExpex1.NamEX17;
            delete currentTraExpex1.StrAndNumEX122;
            delete currentTraExpex1.PosCodEX123;
            delete currentTraExpex1.CitEX124;
            delete currentTraExpex1.CouEX125;
            delete currentTraExpex1.TINEX159;
            delete currentTraExpex1.NADLNGEX;

            currentTraExpex1.SpeMenEX1011 = '00200';
        }

        //========= MULTIPLE CONSIGNEES HANDLING
        //=====================================================================
        if (currentTraConce1) {
            const mainConsignee = currentTraConce1.ConsigneeObject;
            //if all values in the array are equal to the main exporter value
            const conEqual = arr => arr.every(val => isEqual(val, mainConsignee));

            const sameConsignees = conEqual(consigneesArray);

            //if we have the same consignees delete consignees on item level
            if (sameConsignees) {
                for (let i = 0; i < length; i++) {
                    const GoodsFormGroup = currentGoods[i];

                    const traconce2 = GoodsFormGroup.TRACONCE2[0];

                    if (traconce2) {
                        delete traconce2.NamCE27;
                        delete traconce2.StrAndNumCE222;
                        delete traconce2.PosCodCE223;
                        delete traconce2.CitCE224;
                        delete traconce2.CouCE225;
                        delete traconce2.TINCE259;
                        delete traconce2.NADLNGGICE;
                    }
                }
            } else {
                const traconce2 = currentGoods[0].TRACONCE2[0];

                traconce2.NamCE27 = currentTraConce1.NamCE17;
                traconce2.StrAndNumCE222 = currentTraConce1.StrAndNumCE122;
                traconce2.PosCodCE223 = currentTraConce1.PosCodCE123;
                traconce2.CitCE224 = currentTraConce1.CitCE124;
                traconce2.CouCE225 = currentTraConce1.CouCE125;
                traconce2.TINCE259 = currentTraConce1.TINCE159;
                traconce2.NADLNGGICE = currentTraConce1.NADLNGCE;

                delete currentTraConce1.NamCE17;
                delete currentTraConce1.StrAndNumCE122;
                delete currentTraConce1.PosCodCE123;
                delete currentTraConce1.CitCE124;
                delete currentTraConce1.CouCE125;
                delete currentTraConce1.TINCE159;
                delete currentTraConce1.NADLNGCE;

                currentTraConce1.SpeMenCE1003 = '00200';
            }
        }

        //========= MULTIPLE DISPATCH COUNTRIES HANDLING
        //=====================================================================
        const dispEqual = arr => arr.every(val => val === countryOfDisp);

        const sameDispatchCountries = dispEqual(dispatchCouArray);

        if (sameDispatchCountries) {
            for (let i = 0; i < length; i++) {
                const GoodsFormGroup = currentGoods[i];
                //ex15a
                GoodsFormGroup.CouOfDisGDS58 = null;
            }
        } else {
            //copy value from mail level to itm level
            currentGoods[0].CouOfDisGDS58 = countryOfDisp;
            //delete value from header level
            delete heahea.CouOfDisCodHEA55;

        }

        //========= MULTIPLE DESTINATION COUNTRIES HANDLING
        //=====================================================================

        const destEqual = arr => arr.every(val => val === countryOfDest);

        const sameDestinationCountries = destEqual(destinCouArray);
        //console.log("destinCouArray",destinCouArray)
        if (sameDestinationCountries || !destinCouArray) {
            for (let i = 0; i < length; i++) {
                const GoodsFormGroup = currentGoods[i];
                //ex17a
                GoodsFormGroup.CouOfDesGDS59 = null;
            }
        } else {
            //copy value from main level to itm level
            currentGoods[0].CouOfDesGDS59 = countryOfDest;
            //delete value from header level
            delete heahea.CouOfDesCodHEA30;

        }



        return submitForm;
    }

    private importBisHandling(submitForm: ImportDeclarationModel): ImportDeclarationModel {
        const currentGoods = submitForm.GOOITEGDS;
        const currentTraConco1 = submitForm.TRACONCO1;
        const currentTraConce1 = submitForm.TRACONCE1;
        const currentTradec = submitForm.TRAREP;
        const ConsignorSpecialMention = currentTraConco1 ? currentTraConco1.SpeMenCO408 : null;
        const ConsigneeSpecialMention = currentTraConce1 ? currentTraConce1.SpeMenCE1003 : null;

        const length = currentGoods.length;

        const consignorsArray = [];
        const consigneesArray = [];

        for (let i = 0; i < length; i++) {
            const GoodsFormGroup = currentGoods[i];

            const traconco2 = GoodsFormGroup.TRACONCO2[0];
            const traconce2 = GoodsFormGroup.TRACONCE2[0];

            //We get all the item values except of the first item, because the first item at first is empty, the values or set on the main level for the first item
            if (i > 0) {
                const itemConsignor = traconco2.ConsignorObject;
                const itemConsignee = traconce2.ConsigneeObject;
                consignorsArray.push(itemConsignor);
                consigneesArray.push(itemConsignee);
            }
        }




        //  MULTIPLE EXPORTERS HANDLING==============================
        //==============================================================
        const mainConsignor = currentTraConco1.ConsignorObject;
        //if all values in the array are equal to the main exporter value

        const consEqual = arr => arr.every(val => isEqual(val, mainConsignor));

        const sameConsignors = consEqual(consignorsArray);

        console.log("removePrivate sameConsignors", sameConsignors)


        //if we have the same exporters delere exporter on item level
        if (sameConsignors) {
            for (let i = 0; i < length; i++) {
                const GoodsFormGroup = currentGoods[i];

                const traconco2 = GoodsFormGroup.TRACONCO2[0];

                if (traconco2) {

                    delete traconco2.NamCO27;
                    delete traconco2.StrAndNumCO222;
                    delete traconco2.PosCodCO223;
                    delete traconco2.CitCO224;
                    delete traconco2.CouCO225;
                    delete traconco2.NADLNGGTCO;
                    delete traconco2.TINCO259;
                }
            }
        }

        //if exporters are not the same, copy the main exporter on the first item and delete values on main level
        //set Special Mention to 00200
        else {
            const traconco2 = currentGoods[0].TRACONCO2[0];

            traconco2.NamCO27 = currentTraConco1.NamCO17;
            traconco2.StrAndNumCO222 = currentTraConco1.StrAndNumCO122;
            traconco2.PosCodCO223 = currentTraConco1.PosCodCO123;
            traconco2.CitCO224 = currentTraConco1.CitCO124;
            traconco2.CouCO225 = currentTraConco1.CouCO125;
            traconco2.NADLNGGTCO = currentTraConco1.NADLNGCO;
            traconco2.TINCO259 = currentTraConco1.TINCO159;

            delete currentTraConco1.NamCO17;
            delete currentTraConco1.StrAndNumCO122;
            delete currentTraConco1.PosCodCO123;
            delete currentTraConco1.CitCO124;
            delete currentTraConco1.CouCO125;
            delete currentTraConco1.NADLNGCO;
            delete currentTraConco1.TINCO159;

            currentTraConco1.SpeMenCO408 = '00200';
        }

        //========= MULTIPLE CONSIGNEES HANDLING
        //=====================================================================
        /*     if (currentTraConce1) {
                const mainConsignee = currentTraConce1.ConsigneeObject;
                //if all values in the array are equal to the main exporter value
                const conEqual = arr => arr.every(val => isEqual(val, mainConsignee));
    
                const sameConsignees = conEqual(consigneesArray);
    
                console.log("removePrivate sameConsignees", sameConsignees)
    
    
    
                //if we have the same consignees delete consignees on item level
                if (sameConsignees) {
                    for (let i = 0; i < length; i++) {
                        const GoodsFormGroup = currentGoods[i];
    
                        const traconce2 = GoodsFormGroup.TRACONCE2[0];
    
                        if (traconce2) {
                            delete traconce2.NamCE27;
                            delete traconce2.StrAndNumCE222;
                            delete traconce2.PosCodCE223;
                            delete traconce2.CitCE224;
                            delete traconce2.CouCE225;
                            delete traconce2.TINCE259;
                            delete traconce2.NADLNGGICE;
                        }
                    }
                } else {
                    const traconce2 = currentGoods[0].TRACONCE2[0];
    
                    traconce2.NamCE27 = currentTraConce1.NamCE17;
                    traconce2.StrAndNumCE222 = currentTraConce1.StrAndNumCE122;
                    traconce2.PosCodCE223 = currentTraConce1.PosCodCE123;
                    traconce2.CitCE224 = currentTraConce1.CitCE124;
                    traconce2.CouCE225 = currentTraConce1.CouCE125;
                    traconce2.TINCE259 = currentTraConce1.TINCE159;
                    traconce2.NADLNGGICE = currentTraConce1.NADLNGCE;
    
                    delete currentTraConce1.NamCE17;
                    delete currentTraConce1.StrAndNumCE122;
                    delete currentTraConce1.PosCodCE123;
                    delete currentTraConce1.CitCE124;
                    delete currentTraConce1.CouCE125;
                    delete currentTraConce1.TINCE159;
                    delete currentTraConce1.NADLNGCE;
    
                    currentTraConce1.SpeMenCE1003 = '00200';
                }
            } */

        if (currentTraConco1) {
            delete currentTraConco1.ConsignorObject;
        }
        if (currentTraConce1) {
            delete currentTraConce1.ConsigneeObject;
        }
        if (currentTradec) {
            delete currentTradec.DeclarantObject;
        }

        return submitForm;
    }

    private tirBisHandling(submitForm: TirDeclarationModel): TirDeclarationModel {
        console.log("fn removePrivate exportBisHandling")

        const currentGoods = submitForm.GOOITEGDS;
        const currentTraConco1 = submitForm.TRACONCO1;
        const currentTraConce1 = submitForm.TRACONCE1;
        const heahea = submitForm.HEAHEA;
        const countryOfDisp = heahea.CouOfDisCodHEA55;
        const countryOfDest = heahea.CouOfDesCodHEA30;
        length = currentGoods.length;

        const exportersArray = [];
        const consigneesArray = [];
        const dispatchCouArray = [];
        const destinCouArray = [];

        for (let i = 0; i < length; i++) {
            const GoodsFormGroup = currentGoods[i];

            const traconco2 = GoodsFormGroup.TRACONCO2[0];
            const traconce2 = GoodsFormGroup.TRACONCE2[0];

            //We get all the item values except of the first item, because the first item at first is empty, the values or set on the main level for the first item
            if (i > 0) {
                const itemExporter = traconco2.ConsignorObject;
                const itemConsignee = traconce2.ConsigneeObject;
                const ex15a = GoodsFormGroup.CouOfDisGDS58;
                const ex17a = GoodsFormGroup.CouOfDesGDS59;

                exportersArray.push(itemExporter);
                consigneesArray.push(itemConsignee);
                dispatchCouArray.push(ex15a);
                destinCouArray.push(ex17a);
            }
        }

        //  MULTIPLE EXPORTERS HANDLING==============================
        //==============================================================
        const mainExporter = currentTraConco1.ConsignorObject;
        //if all values in the array are equal to the main exporter value

        const expEqual = arr => arr.every(val => isEqual(val, mainExporter));

        const sameExporters = expEqual(exportersArray);

        //if we have the same exporters delere exporter on item level
        if (sameExporters) {
            for (let i = 0; i < length; i++) {
                const GoodsFormGroup = currentGoods[i];

                const traconco2 = GoodsFormGroup.TRACONCO2[0];

                if (traconco2) {
                    delete traconco2.NamCO27;
                    delete traconco2.StrAndNumCO222;
                    delete traconco2.PosCodCO223;
                    delete traconco2.CitCO224;
                    delete traconco2.CouCO225;
                    delete traconco2.TINCO259;
                    delete traconco2.NADLNGGTCO;
                    delete traconco2.TINTypCO260;
                }
            }
        }

        //if exporters are not the same, copy the main exporter on the first item and delete values on main level
        //set Special Mention to 00200
        else {

            const traconco2 = currentGoods[0].TRACONCO2[0];

            traconco2.NamCO27 = currentTraConco1.NamCO17;
            traconco2.StrAndNumCO222 = currentTraConco1.StrAndNumCO122;
            traconco2.PosCodCO223 = currentTraConco1.PosCodCO123;
            traconco2.CitCO224 = currentTraConco1.CitCO124;
            traconco2.CouCO225 = currentTraConco1.CouCO125;
            traconco2.TINCO259 = currentTraConco1.TINCO159;
            traconco2.NADLNGGTCO = currentTraConco1.NADLNGCO;
            traconco2.TINTypCO260 = currentTraConco1.TinTypCO1009;

            delete currentTraConco1.NamCO17;
            delete currentTraConco1.StrAndNumCO122;
            delete currentTraConco1.PosCodCO123;
            delete currentTraConco1.CitCO124;
            delete currentTraConco1.CouCO125;
            delete currentTraConco1.TINCO159;
            delete currentTraConco1.NADLNGCO;
            delete currentTraConco1.TinTypCO1009;

        }

        //========= MULTIPLE CONSIGNEES HANDLING
        //=====================================================================
        if (currentTraConce1) {
            const mainConsignee = currentTraConce1.ConsigneeObject;
            //if all values in the array are equal to the main exporter value
            const conEqual = arr => arr.every(val => isEqual(val, mainConsignee));

            const sameConsignees = conEqual(consigneesArray);

            //if we have the same consignees delete consignees on item level
            if (sameConsignees) {
                for (let i = 0; i < length; i++) {
                    const GoodsFormGroup = currentGoods[i];

                    const traconce2 = GoodsFormGroup.TRACONCE2[0];

                    if (traconce2) {
                        delete traconce2.NamCE27;
                        delete traconce2.StrAndNumCE222;
                        delete traconce2.PosCodCE223;
                        delete traconce2.CitCE224;
                        delete traconce2.CouCE225;
                        delete traconce2.TINCE259;
                        delete traconce2.NADLNGGICE;
                    }
                }
            } else {
                const traconce2 = currentGoods[0].TRACONCE2[0];

                traconce2.NamCE27 = currentTraConce1.NamCE17;
                traconce2.StrAndNumCE222 = currentTraConce1.StrAndNumCE122;
                traconce2.PosCodCE223 = currentTraConce1.PosCodCE123;
                traconce2.CitCE224 = currentTraConce1.CitCE124;
                traconce2.CouCE225 = currentTraConce1.CouCE125;
                traconce2.TINCE259 = currentTraConce1.TINCE159;
                traconce2.NADLNGGICE = currentTraConce1.NADLNGCE;

                delete currentTraConce1.NamCE17;
                delete currentTraConce1.StrAndNumCE122;
                delete currentTraConce1.PosCodCE123;
                delete currentTraConce1.CitCE124;
                delete currentTraConce1.CouCE125;
                delete currentTraConce1.TINCE159;
                delete currentTraConce1.NADLNGCE;
            }
        }

        //========= MULTIPLE DISPATCH COUNTRIES HANDLING
        //=====================================================================
        const dispEqual = arr => arr.every(val => val === countryOfDisp);

        const sameDispatchCountries = dispEqual(dispatchCouArray);

        if (sameDispatchCountries) {
            for (let i = 0; i < length; i++) {
                const GoodsFormGroup = currentGoods[i];

                //ex15a
                GoodsFormGroup.CouOfDisGDS58 = null;


            }
        } else {
            //copy value from mail level to itm level
            currentGoods[0].CouOfDisGDS58 = countryOfDisp;
            //delete value from header level
            delete heahea.CouOfDisCodHEA55;
        }

        //========= MULTIPLE DESTINATION COUNTRIES HANDLING
        //=====================================================================

        const destEqual = arr => arr.every(val => val === countryOfDest);

        const sameDestinationCountries = destEqual(destinCouArray);
        //console.log("destinCouArray",destinCouArray)
        if (sameDestinationCountries || !destinCouArray) {
            for (let i = 0; i < length; i++) {
                const GoodsFormGroup = currentGoods[i];

                //ex17a
                GoodsFormGroup.CouOfDesGDS59 = null;


            }
        } else {
            //copy value from main level to itm level
            currentGoods[0].CouOfDesGDS59 = countryOfDest;
            //delete value from header level
            delete heahea.CouOfDesCodHEA30;
        }

        if (currentTraConco1) {
            delete currentTraConco1.ConsignorObject;
        }
        if (currentTraConce1) {
            delete currentTraConce1.ConsigneeObject;
        }
        /*   if (currentTrarep) {
              delete currentTrarep.DeclarantObject;
          } */

        return submitForm;
    }

    private t2lBisHandling(submitForm: Declaration): Declaration {
        console.log("fn removePrivate exportBisHandling")

        const currentGoods = submitForm.GOOITEGDS;
        const heahea = submitForm.HEAHEA;

        const countryOfDisp = heahea.CouOfDisCodHEA55;

        length = currentGoods.length;

        const dispatchCouArray = [];

        for (let i = 0; i < length; i++) {
            const GoodsFormGroup = currentGoods[i];
            //We get all the item values except of the first item, because the first item at first is empty, the values or set on the main level for the first item
            if (i > 0) {
                let ex15a = GoodsFormGroup.CouOfDisGDS58;

                //if user deletes country of disp on bis use header
                if (!ex15a || ex15a === '') {
                    ex15a = countryOfDisp;
                }

                dispatchCouArray.push(ex15a);
            }
        }


        //========= MULTIPLE DISPATCH COUNTRIES HANDLING
        //=====================================================================
        const dispEqual = arr => arr.every(val => val === countryOfDisp);

        const sameDispatchCountries = dispEqual(dispatchCouArray);

        if (sameDispatchCountries) {
            for (let i = 0; i < length; i++) {
                const GoodsFormGroup = currentGoods[i];
                //ex15a
                GoodsFormGroup.CouOfDisGDS58 = null;
            }
        } else {
            //copy value from mail level to itm level
            currentGoods[0].CouOfDisGDS58 = countryOfDisp;
            //delete value from header level
            delete heahea.CouOfDisCodHEA55;
        }

        return submitForm;
    }


    private removeEXP_DECL(submitForm: Declaration): Declaration {

        const cusoffexiext = submitForm.CUSOFFEXIEXT;
        const cusoffexpert = submitForm.CUSOFFEXPERT;
        const currentTradec = submitForm.TRADEC;
        const currentTraExpex1 = submitForm.TRAEXPEX1;
        const currentTraConce1 = submitForm.TRACONCE1;

        //TIR GROUPS
        delete submitForm.GUAGUA;
        delete submitForm.TRAPRIPC1;
        delete submitForm.CUSOFFTRARNS;
        delete submitForm.HEAHEA.NatOfMeaOfTraAtDHEA80;

        if (cusoffexiext) {
            delete cusoffexiext._countryCode;
        }
        if (cusoffexpert) {
            delete cusoffexpert._countryCode;
        }
        if (currentTraExpex1) {
            delete currentTraExpex1.ExporterObject;
        }
        if (currentTraConce1) {
            delete currentTraConce1.ConsigneeObject;
        }
        if (currentTradec) {
            delete currentTradec.DeclarantObject;
        }

        return submitForm;

    }

    remove_ICS_DETE(submitForm: eAitisiDeclarationModel): eAitisiDeclarationModel {

        const trarep = submitForm.TRAREP;
        const tradec = submitForm.TRADEC;
        const cusOff = submitForm.CUSOFF;

        delete cusOff._countryCode;

        delete trarep?.DeclarantObject;
        delete tradec?.DeclarantObject;


        return submitForm;
    }


    private truncateNames(submitForm): any {
        console.log("fn removePrivate truncateNames")

        const currentTraExpex1 = submitForm.TRAEXPEX1;
        const currentTraConce1 = submitForm.TRACONCE1;
        const currentTradec = submitForm.TRADEC;
        const currentTraConco1 = submitForm.TRACONCO1;
        const currentTrarep = submitForm.TRAREP;
        const currentReprep = submitForm.REPREP;
        const currentTrapri = submitForm.TRAPRIPC1;
        const currentTraexicar = submitForm.TRAEXICAR;

        //========================EXPORTER CONSIGNOR============
        if (currentTraExpex1) {
            const name: string = currentTraExpex1.NamEX17;
            const street: string = currentTraExpex1.StrAndNumEX122;

            currentTraExpex1.NamEX17 = this.trunc35(name);
            currentTraExpex1.StrAndNumEX122 = this.trunc35(street);
        } else if (currentTraConco1) {
            const name: string = currentTraConco1.NamCO17;
            const street: string = currentTraConco1.StrAndNumCO122;

            if (name) {
                currentTraConco1.NamCO17 = this.trunc35(name);
            }

            if (street) {
                currentTraConco1.StrAndNumCO122 = this.trunc35(street);
            }
        }

        //===================CONSIGNEEE===============================
        if (currentTraConce1) {
            const name: string = currentTraConce1.NamCE17;
            if (name) {
                currentTraConce1.NamCE17 = this.trunc35(name);
            }

            const street: string = currentTraConce1.StrAndNumCE122;
            if (street) {
                currentTraConce1.StrAndNumCE122 = this.trunc35(street);
            }
        }

        //==================DECLARANT=====================================
        if (currentTradec) {
            const name: string = currentTradec.NamTDE1;
            if (name) {
                currentTradec.NamTDE1 = this.trunc35(name);
            }
            const street: string = currentTradec.StrAndNumTDE1;
            if (street) {
                currentTradec.StrAndNumTDE1 = this.trunc35(street);
            }


        }
        if (currentTrarep) {
            const name: string = currentTrarep.NamTRE1;

            if (name) {
                currentTrarep.NamTRE1 = this.trunc35(name);
            }
            const street: string = currentTrarep.StrAndNumTRE1;
            if (street) {
                currentTrarep.StrAndNumTRE1 = this.trunc35(street);
            }

        }
        if (currentReprep) {
            const name: string = currentReprep.NamREP5;
            if (name) {
                currentReprep.NamREP5 = this.trunc35(name);
            }

        }

        //===================TRADER PRINCIPAL========================

        if (currentTrapri) {
            const name: string = currentTrapri.NamPC17;
            if (name) {
                currentTrapri.NamPC17 = this.trunc35(name);
            }
            const street: string = currentTrapri.StrAndNumPC122;
            if (street) {
                currentTrapri.StrAndNumPC122 = this.trunc35(street);
            }
        }

        //======================= CARRIER ===========================
        if (currentTraexicar) {
            const name: string = currentTraexicar.NamTEC1;
            if (name) {
                currentTraexicar.NamTEC1 = this.trunc35(name);
            }
            const street: string = currentTraexicar.StrAndNumTEC2;
            if (street) {
                currentTraexicar.StrAndNumTEC2 = this.trunc35(street);
            }
        }

        const currentGoods = submitForm.GOOITEGDS;

        if (currentGoods) {
            for (let i = 0; i < currentGoods.length; i++) {
                const GoodsFormGroup = currentGoods[i];

                const traconex2 = GoodsFormGroup.TRACONEX2;
                const traconce2 = GoodsFormGroup.TRACONCE2;
                const traconco2 = GoodsFormGroup.TRACONCO2;

                //We get all the item values except of the first item, because the first item at first is empty, the values or set on the main level for the first item
                if (traconex2) {
                    const name = traconex2[0]?.NamEX27;
                    if (name) {
                        traconex2[0].NamEX27 = this.trunc35(name);
                    }
                    const street = traconex2[0]?.StrAndNumEX222;
                    if (street) {
                        traconex2[0].StrAndNumEX222 = this.trunc35(street);
                    }
                }
                if (traconce2) {
                    const name = traconce2[0]?.NamCE27;
                    if (name) {
                        traconce2[0].NamCE27 = this.trunc35(name);
                    }
                    const street = traconce2[0]?.StrAndNumCE222;
                    if (street) {
                        traconce2[0].StrAndNumCE222 = this.trunc35(street);
                    }
                }
                if (traconco2) {
                    const name = traconco2[0]?.NamCO27;
                    if (name) {
                        traconco2[0].NamCO27 = this.trunc35(name);
                    }
                    const street = traconco2[0]?.StrAndNumCO222;
                    if (street) {
                        traconco2[0].StrAndNumCO222 = this.trunc35(street);
                    }
                }

            }

        }
        return submitForm;
    }

    private arrAtExit(submitForm: ArrAtExitModel): ArrAtExitModel {
        const currentCarrier = submitForm.TRAEXICAR;
        const cusOffRealExit = submitForm.ACEACE;

        delete cusOffRealExit._countryCode;
        delete currentCarrier.CarrierObject;

        this.truncateNames(submitForm);



        console.log('submitForm After removePrivate:', submitForm);

        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        if (environment.testing) {
            cusOffRealExit.RefNumACE1 = 'XX000000';
        }
        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        return submitForm;
    }

    private eAitisi(submitForm: eAitisiDeclarationModel): eAitisiDeclarationModel {
        console.log('submitForm on removePrivate');
        const heahea = submitForm.HEAHEA;
        const gooitegds = submitForm.GOOITEGDS;

        this.remove_ICS_DETE(submitForm);

        console.log('submitForm After removePrivate:', submitForm);

        if (heahea.DocNumHEA5) {
            delete heahea.DocNumHEA5;
        }
        if (heahea._amountOfTax) {
            delete heahea._amountOfTax;
        }
        if (heahea.AmeAccDatHEA602) {
            delete heahea.AmeAccDatHEA602;
        }

        for (let i = 0; i < gooitegds.length; i++) {

            delete gooitegds[i].COMCODGODITM[0]._combinedComCode

        }

        this.truncateNames(submitForm)


        this.testingICSDETE(submitForm)



        return submitForm;
    }

    private IB13A(submitForm: eAitisiDeclarationModel): eAitisiDeclarationModel {

        delete submitForm.REFDECL;
        return submitForm;
    }
    private t2l(submitForm: any): any {
        console.log('submitForm on removePrivate');
        const consignor = submitForm.TRACONCO1;
        const tradec = submitForm.TRADEC;
        const cusOffDep = submitForm.CUSOFFDEPEPT;
        const cusOffDes = submitForm.CUSOFFDESEST;

        delete cusOffDep._countryCode;
        delete cusOffDes?._countryCode;

        delete consignor.ConsignorObject;
        delete tradec?.DeclarantObject;
        console.log('submitForm After removePrivate:', submitForm);

        this.truncateNames(submitForm)

        return submitForm;
    }
    private TF015A(submitForm: any): T2LDeclarationModel {


        this.t2l(submitForm);
        this.t2lBisHandling(submitForm);

        const heahea = submitForm.HEAHEA;
        const currentGoods = submitForm.GOOITEGDS;


        if (heahea.DocNumHEA5) {
            delete heahea.DocNumHEA5;
        }
        if (heahea.AmeAccDatHEA602) {
            delete heahea.AmeAccDatHEA602;
        }

        for (let i = 0; i < currentGoods.length; i++) {
            delete currentGoods[i].COMCODGODITM;
        }

        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        if (environment.testing) {

            submitForm.CUSOFFDEPEPT.RefNumEPT1 = 'XX000000';
        }
        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        return submitForm;
    }

    private TF013A(submitForm: any): T2LDeclarationModel {

        this.t2lBisHandling(submitForm)
        const heahea = submitForm.HEAHEA;

        delete heahea.RefNumHEA4;
        if (heahea.DocNumHEA5) {
            delete heahea.DocNumHEA5;
        }
        if (heahea.AmeAccDatHEA602) {
            delete heahea.AmeAccDatHEA602;
        }
        if (heahea.AccDatHEA158) {
            delete heahea.AccDatHEA158;
        }

        console.log('submitForm After removePrivate:', submitForm);

        return submitForm;
    }

    private TF014A(submitForm: T2LCancelDeclarationModel): T2LCancelDeclarationModel {

        this.t2l(submitForm);

        return submitForm;

    }

    private tir(submitForm: any): TirDeclarationModel {
        const consignor = submitForm.TRACONCO1;
        const consignee = submitForm.TRACONCE1;
        const trapri = submitForm.TRAPRIPC1;
        const tradec = submitForm.REPREP;
        const ctlcl1 = submitForm.CTLCL1;


        const cusOffDep = submitForm.CUSOFFDEPEPT;
        const cusOffDes = submitForm.CUSOFFDESEST;
        const cusOffTrans = submitForm.CUSOFFTRARNS;

        const heahea = submitForm.HEAHEA;
        const currentGoods = submitForm.GOOITEGDS;


        this.truncateNames(submitForm);
        this.tirBisHandling(submitForm);


        delete cusOffDep._countryCode;
        delete cusOffDes._countryCode;



        for (let i = 0; i < cusOffTrans.length; i++) {

            delete cusOffTrans[i]._countryCode
        }

        delete consignor.ConsignorObject;
        delete consignee.ConsigneeObject;
        delete trapri.TrapriObject;
        delete tradec.DeclarantObject;


        if (heahea.AmeAccDatHEA602) {
            delete heahea.AmeAccDatHEA602;
        }


        for (let i = 0; i < currentGoods.length; i++) {
            const GoodsFormGroup = currentGoods[i];

            const traconex2 = GoodsFormGroup.TRACONCO2;
            const traconce2 = GoodsFormGroup.TRACONCE2;

            delete GoodsFormGroup.ACCOUNTING;
            delete GoodsFormGroup.COMCODGODITM;

            GoodsFormGroup.GooDesGDS23 = GoodsFormGroup.GooDesGDS23?.substr(0, 140);


            const firstTraconex = traconex2[0];
            const firstTraconce2 = traconce2[0];
            if (firstTraconex) {
                delete firstTraconex.ConsignorObject;
            }
            if (firstTraconce2) {
                delete firstTraconce2.ConsigneeObject;
            }
        }

        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        if (environment.testing) {
            cusOffDep.RefNumEPT1 = 'XX000000';
        }
        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        console.log('submitForm After removePrivate:', submitForm);

        return submitForm;
    }

    private VA15A(submitForm: DaoDeclarationModel): DaoDeclarationModel {
        const consignor = submitForm.Body.VehicleArrivalDeclarationRegistration.VehicleArrivalConsignor;
        const consignee = submitForm.Body.VehicleArrivalDeclarationRegistration.VehicleArrivalConsignee;
        const vehicleArrival = submitForm.Body.VehicleArrivalDeclarationRegistration.VehicleArrival;


        delete consignor.ConsignorObject;
        delete consignee.ConsigneeObject;
        delete vehicleArrival.DeclarationReferenceNumber;



        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        if (environment.testing) {
            submitForm.Body.VehicleArrivalDeclarationRegistration.VehicleArrival.OfficeOfOrigin = 'XX000000';
        }
        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        console.log('submitForm After removePrivate:', submitForm);

        return submitForm;
    }

    private VA13A(submitForm: VA13ADeclarationModel): VA13ADeclarationModel {
        const consignor = submitForm.Body.VehicleArrivalDeclarationAmendment.VehicleArrivalConsignor;
        const consignee = submitForm.Body.VehicleArrivalDeclarationAmendment.VehicleArrivalConsignee;


        delete consignor.ConsignorObject;
        delete consignee.ConsigneeObject;


        return submitForm;

    }
    private CC514A(submitForm: CancelModel): CancelModel {

        delete submitForm.TRAEXPEX1

        this.removeEXP_DECL(submitForm)

        return submitForm;
    }

    private ID14A(submitForm: ImportCancelDeclarationModel): ImportCancelDeclarationModel {


        const impcusoff = submitForm.IMPCUSOFF;
        const trarep = submitForm.TRAREP;
        const traconce = submitForm.TRACONCE1;

        delete impcusoff._countryCode;
        delete trarep.DeclarantObject;
        delete traconce.ConsigneeObject;

        this.truncateNames(submitForm)


        return submitForm;
    }

    private CC014A(submitForm: TirCancelModel): TirCancelModel {


        const cusoffdep = submitForm.CUSOFFDEPEPT;
        const trapri = submitForm.TRAPRIPC1;

        delete cusoffdep._countryCode;
        delete trapri.TrapriObject;
        delete trapri.TinTypPC1009;

        return submitForm;
    }

    //THIS IS  normally eAItisiCancelDeclarationModel 
    private IB14A(submitForm: eAitisiDeclarationModel): eAitisiDeclarationModel {

        delete submitForm.TRAREP;

        this.remove_ICS_DETE(submitForm)

        this.truncateNames(submitForm)

        this.testingICSDETE(submitForm)

        return submitForm;
    }


    private EF15A(submitForm: DEFKDeclarationModel): DEFKDeclarationModel {

        const body = submitForm.Body.DeclarationOfExciseTaxesRegistration;

        submitForm.Body.DeclarationOfExciseTaxesRegistration = this.generalEF(body)

        return submitForm;

    }


    private EF13A(submitForm: EF13ADeclarationModel): EF13ADeclarationModel {

        const body = submitForm.Body.DeclarationOfExciseTaxesAmendment;

        submitForm.Body.DeclarationOfExciseTaxesAmendment = this.generalEF(body)

        delete body.ExciseTaxesDeclaration.RegistrationDate

        return submitForm;

    }

    generalEF(Body: DeclarationOfExciseTaxesRegistrationType | DeclarationOfExciseTaxesAmendmentType) {

        const consignor = Body.ExciseTaxesDeclarationConsignor;
        const consignee = Body.ExciseTaxesDeclarationConsignee
        const obligee = Body.ExciseTaxesObligee
        const declarant = Body.ExciseTaxesDeclaration.ClearingAgent

        const taxesRow = Body.ExciseTaxesRow;

        // delete consignor.ConsignorObject;
        delete consignee.ConsigneeObject;
        delete consignee.ContactDetails;

        delete obligee.ObligeeObject;
        delete obligee.ContactDetails;

        delete declarant.DeclarantObject;

        taxesRow.forEach(row => {
            const caltax = row.TaxComputation;

            caltax.forEach(tax => {
                delete tax._natTypeCode;
                delete tax._taxFreeCode;
                delete tax._taxPaidCode;
                delete tax._partialExemption;
                delete tax._wholeTaxAmount;
            })
        })

        return Body;

    }
    trunc35(string) {
        return string.substr(0, 35);
    }

    testingICSDETE(submitForm) {

        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        const cusOff = submitForm.CUSOFF

        if (environment.testing) {
            cusOff.RefNumIMPCUSOFF = 'XX000000';
        }
        //////////////////////////////////////////
        //////////////////////////////////// REMOVE AFTER TESTING///////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        return submitForm;
    }
}
