import { AbstractControl, FormControl } from "@angular/forms";
import { TaxComputationModel } from "./tax-computation.model";

export class TaxComputationForm {
    [key: string]: AbstractControl;

    NationalAccountingCode: FormControl;
    SuspensionFlag: FormControl;
    AmountOfTaxes: FormControl;
    PaymentMethodCode: FormControl;
    TaxBase: FormControl;
    TaxRate: FormControl;

    _natTypeCode = new FormControl();
    _taxFreeCode = new FormControl();
    _taxPaidCode = new FormControl();
    _partialExemption = new FormControl();
    _wholeTaxAmount = new FormControl();


    constructor(value: TaxComputationModel) {

        this.NationalAccountingCode = new FormControl(value.NationalAccountingCode);
        this.SuspensionFlag = new FormControl(value.SuspensionFlag);
        this.AmountOfTaxes = new FormControl(value.AmountOfTaxes, { updateOn: 'blur' });
        this.PaymentMethodCode = new FormControl(value.PaymentMethodCode, { updateOn: 'blur' });
        this.TaxBase = new FormControl(value.TaxBase, { updateOn: 'blur' });
        this.TaxRate = new FormControl(value.TaxRate, { updateOn: 'blur' });

        this._natTypeCode.setValue(value._natTypeCode);
        this._taxFreeCode.setValue(value._taxFreeCode);
        this._taxPaidCode.setValue(value._taxPaidCode);
        this._partialExemption.setValue(value._partialExemption);
        this._wholeTaxAmount.setValue(value._wholeTaxAmount);

    }
}
