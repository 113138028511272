<div fxLayout="row" class="space-between" [formGroup]="formGroup" style="overflow: hidden;">
    <div>
        <mat-form-field [class]="classes">
            <autocomplete-control #firstInput [formControlName]="controlName" [options]="options" [config]="config"
                (focus)="onFocus()" (blur)="onError(hasError(control))" [required]="required">
            </autocomplete-control>

        </mat-form-field>
    </div>
    <div *ngIf="showDesc" [style]="descStyle" class="autocomplete-desc" [ngClass]="{disabled: formDisabled}">
        <span *ngIf="control.value">(</span>{{description}}<span *ngIf="control.value">)</span>
    </div>
</div>