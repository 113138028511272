import { AbstractControl, FormControl } from "@angular/forms";
import { TransactionCategoryModel } from "app/main/businesses/transactions/_models/transaction-category/transaction-category.model";

export class TransactionCategoryForm {

    [key: string]: AbstractControl;

    label = new FormControl();
    type = new FormControl();
    accountId = new FormControl();
    transactionId = new FormControl();
    transactionSequence = new FormControl();
    lineItemOrder = new FormControl();
    categorizationGroupValue = new FormControl();
    itemType = new FormControl();
    taxAction = new FormControl();
    description = new FormControl();
    categorizationSubGroupValue = new FormControl();
    sortKey = new FormControl();
    customerId = new FormControl();
    vendorId = new FormControl();

    constructor(value: TransactionCategoryModel) {
        this.label.setValue(value.label);
        this.type.setValue(value.type);
        this.accountId.setValue(value.accountId);
        this.transactionId.setValue(value.transactionId);
        this.transactionSequence.setValue(value.transactionSequence);
        this.lineItemOrder.setValue(value.lineItemOrder);
        this.categorizationGroupValue.setValue(value.categorizationGroupValue);
        this.itemType.setValue(value.itemType);
        this.taxAction.setValue(value.taxAction);
        this.description.setValue(value.description);
        this.categorizationSubGroupValue.setValue(value.categorizationSubGroupValue);
        this.sortKey.setValue(value.sortKey);
        this.customerId.setValue(value.customerId);
        this.vendorId.setValue(value.vendorId);

    }


}