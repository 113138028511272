import { FormControl, AbstractControl, FormGroup, Form } from '@angular/forms';
import { BoilerInfoModel, DistillationContactDetailsModel, DistillationInfoModel, DistillationLicenceAppModel, WineryInfoModel } from 'app/main/apps/general-applications/distillation-licence-app/_models/distillation-licence-app.model';

export class DistillationLicenceAppFormModel {
    [key: string]: AbstractControl;

    period: FormControl;
    defkMRN: FormControl;
    customsName: FormControl;
    producerDetails: FormGroup;
    startHours: FormControl;
    startDate: FormControl;
    endHours: FormControl;
    endDate: FormControl;
    harvestDeclaration: FormControl;
    previousQuantityLpa: FormControl;
    previousQuantityGross: FormControl;
    previousQuantityAlcoholTitle: FormControl;
    previousQuantityLocation: FormControl;
    previousQuantityLpaRights: FormControl;
    previousQuantityGrossRights: FormControl;
    previousQuantityAlcoholTitleRights: FormControl;
    previousQuantityLocationRights: FormControl;
    ownStems: FormGroup;
    returnedStems: FormGroup;
    otherRawMaterials: FormControl;
    grapes: FormGroup;
    winery: FormGroup;
    boilerInfo: FormGroup;
    unsealDate: FormControl;
    date: FormControl;






    constructor(data: DistillationLicenceAppModel) {

        this.period = new FormControl(data?.period, { updateOn: 'blur' });
        this.defkMRN = new FormControl(data?.defkMRN, { updateOn: 'blur' });
        this.customsName = new FormControl(data?.customsName);
        this.producerDetails = new FormGroup(new DistillationContactDetailsFormModel(new DistillationContactDetailsModel(data?.producerDetails)), { updateOn: 'blur' });
        this.startHours = new FormControl(data?.startHours, { updateOn: 'blur' });
        this.startDate = new FormControl(data?.startDate, { updateOn: 'blur' });
        this.endHours = new FormControl(data?.endHours, { updateOn: 'blur' });
        this.endDate = new FormControl(data?.endDate, { updateOn: 'blur' });
        this.harvestDeclaration = new FormControl(data?.harvestDeclaration, { updateOn: 'blur' });
        this.previousQuantityLpa = new FormControl(data?.previousQuantityLpa, { updateOn: 'blur' });
        this.previousQuantityGross = new FormControl(data?.previousQuantityGross, { updateOn: 'blur' });
        this.previousQuantityAlcoholTitle = new FormControl(data?.previousQuantityAlcoholTitle, { updateOn: 'blur' });
        this.previousQuantityLocation = new FormControl(data?.previousQuantityLocation, { updateOn: 'blur' });
        this.previousQuantityLpaRights = new FormControl(data?.previousQuantityLpaRights, { updateOn: 'blur' });
        this.previousQuantityGrossRights = new FormControl(data?.previousQuantityGrossRights, { updateOn: 'blur' });
        this.previousQuantityAlcoholTitleRights = new FormControl(data?.previousQuantityAlcoholTitleRights, { updateOn: 'blur' });
        this.previousQuantityLocationRights = new FormControl(data?.previousQuantityLocationRights, { updateOn: 'blur' });
        this.ownStems = new FormGroup(new DistillationInfoFormModel(new DistillationInfoModel(data?.ownStems)));
        this.returnedStems = new FormGroup(new DistillationInfoFormModel(new DistillationInfoModel(data?.returnedStems)));
        this.otherRawMaterials = new FormControl(data?.otherRawMaterials, { updateOn: 'blur' });
        this.grapes = new FormGroup(new DistillationInfoFormModel(new DistillationInfoModel(data?.grapes)));
        this.winery = new FormGroup(new WineryInfoFormModel(new WineryInfoModel(data?.winery)));
        this.boilerInfo = new FormGroup(new BoilerInfoFormModel(new BoilerInfoModel(data?.boilerInfo)));
        this.unsealDate = new FormControl(data?.unsealDate, { updateOn: 'blur' });
        this.date = new FormControl(data?.date, { updateOn: 'blur' });
    }
}

export class DistillationContactDetailsFormModel {
    [key: string]: AbstractControl;

    producerName: FormControl;
    producerId: FormControl;
    producerStreet: FormControl;
    producerStreetNumber: FormControl;
    producerPhone: FormControl;
    producerEmail: FormControl;
    constructor(value?: DistillationContactDetailsModel) {
        this.producerName = new FormControl(value?.producerName);
        this.producerId = new FormControl(value?.producerId);
        this.producerStreet = new FormControl(value?.producerStreet);
        this.producerStreetNumber = new FormControl(value?.producerStreetNumber);
        this.producerPhone = new FormControl(value?.producerPhone);
        this.producerEmail = new FormControl(value?.producerEmail);

    }

}

export class DistillationInfoFormModel {

    [key: string]: AbstractControl;

    quantity: FormControl;
    litresPureAlcohol: FormControl;
    litresGross: FormControl;
    alcoholicTitle: FormControl;
    constructor(value?: DistillationInfoModel) {

        this.quantity = new FormControl(value?.quantity);
        this.litresPureAlcohol = new FormControl(value?.litresPureAlcohol);
        this.litresGross = new FormControl(value?.litresGross);
        this.alcoholicTitle = new FormControl(value?.alcoholicTitle);
    }
}

export class WineryInfoFormModel {
    [key: string]: AbstractControl;

    wineryName: FormControl;
    wineryAfm: FormControl;
    wineryDocumentNumber: FormControl;
    wineryReturnedWith: FormControl;

    constructor(value?: WineryInfoModel) {
        this.wineryName = new FormControl(value?.wineryName);
        this.wineryAfm = new FormControl(value?.wineryAfm);
        this.wineryDocumentNumber = new FormControl(value?.wineryDocumentNumber);
        this.wineryReturnedWith = new FormControl(value?.wineryReturnedWith);

    }
}

export class BoilerInfoFormModel {
    [key: string]: AbstractControl;


    boilerRegistrationNumber: FormControl;
    boilerNumber: FormControl;
    boilerCapacity: FormControl;
    boilerOwnerName: FormControl;
    boilerOwnerId: FormControl;
    boilerGeoLocation: FormControl;
    constructor(value?: BoilerInfoModel) {
        this.boilerRegistrationNumber = new FormControl(value?.boilerRegistrationNumber);
        this.boilerNumber = new FormControl(value?.boilerNumber);
        this.boilerCapacity = new FormControl(value?.boilerCapacity);
        this.boilerOwnerName = new FormControl(value?.boilerOwnerName);
        this.boilerOwnerId = new FormControl(value?.boilerOwnerId);
        this.boilerGeoLocation = new FormControl(value?.boilerGeoLocation);
    }
}
