import { FormControl, AbstractControl, FormGroup, FormArray } from '@angular/forms';
import { StateResponseForm } from "./state-response-form.model";
import { StateResponseModel } from "./state-response.model";
import { GoodsPrivateModel, PousDocumentsModel, PrivateControlsModel } from 'app/model/private-controls.model';


export class PrivateControlsFormModel {
    [key: string]: AbstractControl;

    lrn: FormControl;
    mrn: FormControl;
    refNumber: FormControl;
    declarant: FormControl;
    trader: FormControl;
    international: FormControl;
    customs: FormControl;
    stateCode: FormControl;
    stateDate: FormControl;
    submissionDate: FormControl;
    domain: FormControl;
    subdomain: FormControl;
    messageOut: FormControl;
    messageIn: FormControl;
    data: FormGroup;
    formBeforeEdit: FormControl;
    lastValidState: FormControl;
    exRateManuallyChanged: FormControl;
    cancellationDate: FormControl;
    cancellationReason: FormControl;
    taxAmount: FormControl;
    referrerDeclarationId: FormControl;
    goodsPrivate: FormArray;
    twoDayDistillers: FormControl;

    beer: FormControl;
    cars: FormControl;
    pousNumberOfItems: FormControl;


    constructor(controls: PrivateControlsModel) {
        this.lrn = new FormControl(controls.lrn);
        this.mrn = new FormControl(controls.mrn);
        this.refNumber = new FormControl(controls.refNumber);
        this.declarant = new FormControl(controls.declarant);
        this.trader = new FormControl(controls.trader);
        this.international = new FormControl(controls.international);
        this.customs = new FormControl(controls.customs);
        this.stateCode = new FormControl(controls.stateCode);
        this.stateDate = new FormControl(controls.stateDate);
        this.submissionDate = new FormControl(controls.submissionDate);
        this.domain = new FormControl(controls.domain);
        this.subdomain = new FormControl(controls.subdomain);
        this.data = new FormGroup(new StateResponseForm(new StateResponseModel(controls.data)));
        this.formBeforeEdit = new FormControl((controls.formBeforeEdit));
        this.lastValidState = new FormControl((controls.lastValidState));
        this.exRateManuallyChanged = new FormControl((controls.exRateManuallyChanged));
        this.messageOut = new FormControl(controls.messageOut);
        this.messageIn = new FormControl(controls.messageIn);
        this.cancellationDate = new FormControl(controls.cancellationDate);
        this.cancellationReason = new FormControl(controls.cancellationReason);
        this.taxAmount = new FormControl(controls.taxAmount);
        this.referrerDeclarationId = new FormControl(controls.referrerDeclarationId);
        this.goodsPrivate = new FormArray([]);
        this.twoDayDistillers = new FormControl(controls.twoDayDistillers);
        this.beer = new FormControl(controls.beer);
        this.cars = new FormControl(controls.cars);
        this.pousNumberOfItems = new FormControl(controls.pousNumberOfItems);


        if (controls.goodsPrivate) {
            controls.goodsPrivate.forEach((privateGroup: GoodsPrivateModel) => {
                this.goodsPrivate.push(new FormGroup(new GoodsPrivateForm(new GoodsPrivateModel(privateGroup))));
            });
        }
        else {
            this.goodsPrivate.push(new FormGroup(new GoodsPrivateForm()));
        }

    }
}

export class GoodsPrivateForm {

    [key: string]: AbstractControl;

    tariffTaxesSum: FormControl;
    tariffResults: FormControl;
    obsolescencePercent: FormControl;
    descriptionEn: FormControl;
    botanicalName: FormControl;
    forceDescriptionforCode: FormControl;
    DOCUMENTS: FormArray;// used in POUS

    constructor(value?: GoodsPrivateModel) {

        this.tariffTaxesSum = new FormControl(value?.tariffTaxesSum);
        this.tariffResults = new FormControl(value?.tariffResults);
        this.obsolescencePercent = new FormControl(value?.obsolescencePercent);
        this.descriptionEn = new FormControl(value?.descriptionEn);
        this.botanicalName = new FormControl(value?.botanicalName);
        //When a taricCode is passed here, the description set by the user will not change automatically wi th the one saved on the db.
        this.forceDescriptionforCode = new FormControl(value?.forceDescriptionforCode);
        this.DOCUMENTS = new FormArray([]);

        if (value.DOCUMENTS) {
            value.DOCUMENTS.forEach((comcod: PousDocumentsModel) => {
                this.DOCUMENTS.push(new FormGroup(new PousDocumentsForm(new PousDocumentsModel(comcod))));
            });
        } else {
            this.DOCUMENTS.push(new FormGroup(new PousDocumentsForm(new PousDocumentsModel())));
        }
    }


}

export class PousDocumentsForm {

    [key: string]: AbstractControl;

    docObject: FormControl;
    ReferenceNumber: FormControl;

    constructor(value?: PousDocumentsModel) {
        this.docObject = new FormControl(value?.docObject);
        this.ReferenceNumber = new FormControl(value?.ReferenceNumber);
    }

}