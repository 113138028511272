import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseFullscreenModule, FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { CancelDeclarationModule } from 'app/main/apps/export/cancel-declaration/cancel-declaration.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { ActivityLedsModule } from 'app/main/ext-components/activity-leds/activity-leds.module';
import { ExtShortcutsModule } from 'app/main/ext-components/shortcuts/shortcuts.module';
import { ToolbarProfileMenuComponent } from './toolbar-profile-menu/toolbar-profile-menu.component';

@NgModule({
    declarations: [ToolbarComponent, ToolbarProfileMenuComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        MatToolbarModule,
        MatListModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        CancelDeclarationModule,
        FuseFullscreenModule,
        //IconsModule,
        ActivityLedsModule,
        ExtShortcutsModule
    ],
    exports: [ToolbarComponent],
    providers: []
})
export class ToolbarModule {}
