import { RemittanceModel } from './_models/remittance.model';
import { RemittanceInput } from './../../../services/openDialogs.service';
import { EPaymentsDocArgs } from 'app/services/api.node.service';
import { EPaymentModel } from './_models/e-payment.model';
import { Observable, Subject, map, switchMap, take, takeUntil, tap } from 'rxjs';
import { EPaymentService } from './e-payment.service';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { Contact } from '../contacts/contact.model';
import { Declarant } from 'app/core/user/user.model';
import { RemittanceForm } from 'app/main/apps/e-payment/_models/remittance-form.model';
import { EPaymentForm } from 'app/main/apps/e-payment/_models/e-payment-form.model';

@Component({
    selector: 'app-e-payment',
    templateUrl: './e-payment.component.html',
    styleUrls: ['./e-payment.component.scss'],
    providers: [EPaymentService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EPaymentComponent implements OnInit {
    remittanceForm: FormGroup<RemittanceForm>;
    remittanceForm$: Observable<FormGroup<RemittanceForm>>;
    ePaymentForm: FormArray<FormGroup<EPaymentForm>>;

    showButtons: boolean;
    formDisabled: boolean;

    traders$: Observable<Contact[]>;
    declarants$: Observable<Declarant[]>;
    allDeclarants: Declarant[] = [];
    loading$: Observable<boolean>;
    customers: any[] = []
    color1 = '';
    color2 = 'primary';
    time = new Date().getTime();

    parentSubdomain: string;

    declConfig: AutoCompConfigValues = {
        display: { values: ['fullname'] },

        menuOptions: {
            options: ['fullname', 'afm'],
            optionRegular: true
        },
        menuTitles: [null, 'ΑΦΜ: ']

    };

    dataToShow = [];
    dataToSubmit = [];
    dataToSubmitLength: number
    private _destroy: Subject<void> = new Subject<void>();
    /*     data = [
            {
                private: {
                    _trader: {
                        id: "47",
                        lastname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ',
                        firstname: '',
                        afm: '127843960',
                        eori: 'GR127843960',
                        doy: 'ΝΑΥΠΛΙΟΥ',
                        passport: '',
                        id_number: '',
                        occupation: 'ΥΠΗΡΕΣΙΕΣ ΕΚΤΕΛΩΝΙΣΤΗ Η ΔΙΑΣΑΦΙΣΤΗ',
                        street: 'ΑΜΥΜΩΝΗΣ 32       ',
                        address2: '',
                        city: 'ΝΑΥΠΛΙΟ',
                        country: 'GR',
                        phone: '',
                        email: 'akisasim@hotmail.com',
                        postal_code: '21100',
                        exporterEng: '',
                        trademarkEn: '',
                        icisUser: null,
                        icisPass: null,
                        icisCreds: false,
                        deleted: false,
                        created: '2022-11-28 22:34:29',
                        last_modified: '2022-11-29 20:47:58',
                        fullname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ ',
                    },
                    declarant: {
                        id: 47,
                        lastname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ',
                        firstname: '',
                        afm: '127843960',
                        eori: 'GR127843960',
                        doy: 'ΝΑΥΠΛΙΟΥ',
                        passport: '',
                        id_number: '',
                        occupation: 'ΥΠΗΡΕΣΙΕΣ ΕΚΤΕΛΩΝΙΣΤΗ Η ΔΙΑΣΑΦΙΣΤΗ',
                        street: 'ΑΜΥΜΩΝΗΣ 32       ',
                        address2: '',
                        city: 'ΝΑΥΠΛΙΟ',
                        country: 'GR',
                        phone: '',
                        email: 'akisasim@hotmail.com',
                        postal_code: '21100',
                        trademarkEn: '',
                        icisUser: null,
                        icisPass: null,
                        icisCreds: false,
                        deleted: false,
                        created: '2022-11-28 22:34:29',
                        last_modified: '2022-11-29 20:47:58',
                        fullname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ ',
                    },
                    mrn: '16GRIM040200025054',
                    stateCode: 'Under Payment'
    
                },
                remittance: null
            },
            {
                private: {
                    _trader: {
                        id: "60",
                        lastname: 'ΙΕΡΑ ΜΗΤΡΟΠΟΛΙΣ ΑΡΓΟΛΙΔΟΣ',
                        firstname: '',
                        afm: '099125172',
                        eori: 'GR099125172',
                        doy: 'ΝΑΥΠΛΙΟΥ',
                        passport: '',
                        id_number: '',
                        occupation: 'ΥΠΗΡΕΣΙΕΣ ΠΟΥ ΠΑΡΕΧΟΝΤΑΙ ΑΠΟ ΘΡΗΣΚΕΥΤΙΚΕΣ ΟΡΓΑΝΩΣΕΙΣ',
                        street: 'ΒΑΣ ΚΩΝΣΤΑΝΤΙΝΟΥ 31       ',
                        address2: '',
                        city: 'ΝΑΥΠΛΙΟ',
                        country: 'GR',
                        phone: '',
                        email: '',
                        postal_code: '21100',
                        exporterEng: null,
                        trademarkEn: '',
                        icisUser: null,
                        icisPass: null,
                        icisCreds: true,
                        deleted: false,
                        created: '2022-12-26 17:29:22',
                        last_modified: '2022-12-26 17:29:22',
                        fullname: 'ΙΕΡΑ ΜΗΤΡΟΠΟΛΙΣ ΑΡΓΟΛΙΔΟΣ ',
                    },
                    declarant: {
                        id: 47,
                        lastname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ',
                        firstname: '',
                        afm: '127843960',
                        eori: 'GR127843960',
                        doy: 'ΝΑΥΠΛΙΟΥ',
                        passport: '',
                        id_number: '',
                        occupation: 'ΥΠΗΡΕΣΙΕΣ ΕΚΤΕΛΩΝΙΣΤΗ Η ΔΙΑΣΑΦΙΣΤΗ',
                        street: 'ΑΜΥΜΩΝΗΣ 32       ',
                        address2: '',
                        city: 'ΝΑΥΠΛΙΟ',
                        country: 'GR',
                        phone: '',
                        email: 'akisasim@hotmail.com',
                        postal_code: '21100',
                        exporterEng: '',
                        trademarkEn: '',
                        icisUser: null,
                        icisPass: null,
                        icisCreds: false,
                        deleted: false,
                        created: '2022-11-28 22:34:29',
                        last_modified: '2022-11-29 20:47:58',
                        fullname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ ',
                    },
                    mrn: '16GRIM040200028530',
                    stateCode: 'Under Payment'
                }, remittance: null
            },
            {
                private: {
                    _trader: {
                        id: "47",
                        lastname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ',
                        firstname: '',
                        afm: '127843960',
                        eori: 'GR127843960',
                        doy: 'ΝΑΥΠΛΙΟΥ',
                        passport: '',
                        id_number: '',
                        occupation: 'ΥΠΗΡΕΣΙΕΣ ΕΚΤΕΛΩΝΙΣΤΗ Η ΔΙΑΣΑΦΙΣΤΗ',
                        street: 'ΑΜΥΜΩΝΗΣ 32       ',
                        address2: '',
                        city: 'ΝΑΥΠΛΙΟ',
                        country: 'GR',
                        phone: '',
                        email: 'akisasim@hotmail.com',
                        postal_code: '21100',
                        exporterEng: '',
                        trademarkEn: '',
                        icisUser: null,
                        icisPass: null,
                        icisCreds: false,
                        deleted: false,
                        created: '2022-11-28 22:34:29',
                        last_modified: '2022-11-29 20:47:58',
                        fullname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ ',
                    },
                    declarant: {
                        id: "47",
                        lastname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ',
                        firstname: '',
                        afm: '127843960',
                        eori: 'GR127843960',
                        doy: 'ΝΑΥΠΛΙΟΥ',
                        passport: '',
                        id_number: '',
                        occupation: 'ΥΠΗΡΕΣΙΕΣ ΕΚΤΕΛΩΝΙΣΤΗ Η ΔΙΑΣΑΦΙΣΤΗ',
                        street: 'ΑΜΥΜΩΝΗΣ 32       ',
                        address2: '',
                        city: 'ΝΑΥΠΛΙΟ',
                        country: 'GR',
                        phone: '',
                        email: 'akisasim@hotmail.com',
                        postal_code: '21100',
                        exporterEng: '',
                        trademarkEn: '',
                        icisUser: null,
                        icisPass: null,
                        icisCreds: false,
                        deleted: false,
                        created: '2022-11-28 22:34:29',
                        last_modified: '2022-11-29 20:47:58',
                        fullname: 'ΑΣΗΜΑΚΟΠΟΥΛΟΣ  ΚΑΝΕΛΛΟΣ ΑΠΟΣΤΟΛΟΣ ',
                    },
                    mrn: '21GREE040200057092',
                    stateCode: 'Under Payment'
    
                }, remittance: {
                    blrn: "12345678",
                    brmt: "2302029827429982743",
                    bamnt: "1234",
                    bmsv: ""
                }
            },
        ];
     */

    get _trader(): FormControl {
        return this.ePaymentForm.at(0).get('trader') as FormControl
    }

    constructor(
        private ePaymentService: EPaymentService,
        public dialogRef: MatDialogRef<EPaymentComponent>,
        private cf: ExtCustomFunctions,
        private _mainBroadcaster: MainBroadcasterService,
        private cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: RemittanceInput[],

    ) {

        console.log("ePaymentComponentX Input data", data)
        this.data.forEach(d => {
            console.log("ePayment data", d)
        })

        this._mainBroadcaster.activeSubdomain$.pipe(take(1)).subscribe((subdomain) => {

            this.parentSubdomain = subdomain

            this._mainBroadcaster.updateActiveSubdomain("RMT");
        })
    }

    ngOnInit(): void {

        this.traders$ = this._mainBroadcaster.domecticCustomers$

        this.loading$ = this._mainBroadcaster.loading$


        this.ePaymentService.remittanceForm$
            .pipe(
                switchMap(remittanceForm => {

                    this.remittanceForm = remittanceForm
                    console.log("ePaymentComponentX remittanceForm", this.remittanceForm.patchValue)

                    this.ePaymentForm = this.remittanceForm.get('ePayment') as FormArray
                    console.log("ePaymentComponentX ePaymentForm", this.ePaymentForm.value)


                    this.remittanceForm.get('declarant').setValue(this.data.map(d => d.private.declarant)[0]);

                    this.declarants$ = this._mainBroadcaster.declarants$.pipe(
                        map((data: Declarant[]) => {
                            this.allDeclarants = data;
                            return this.cf.filterDeclarants(data, this._trader.value, true);
                        }));


                    //Chack if any declaration has a remittance//
                    this.dataToShow = this.data.filter(d => d.remittance)
                    console.log("ePaymentComponentX dataToShow", this.dataToShow)

                    //Check data to submit========================
                    this.dataToSubmit = this.data.filter(d => !d.remittance)
                    console.log("ePaymentComponentX dataToSubmit", this.dataToSubmit)
                    this.dataToSubmitLength = this.dataToSubmit.length

                    //if remittance exists patch values 
                    //SHOW SAVED DATA=================================================================
                    if (this.dataToShow.length > 0) {
                        console.log("ePaymentComponentX Show Saved Data", this.dataToShow)

                        this.ePaymentService.showSaved(this.dataToShow)
                    }

                    //====================================================================================

                    //DATA TO BE SUBMITTED=================================================================

                    if (this.dataToSubmit.length > 0) {

                        console.log("ePaymentComponentX Data To Submit", this.dataToSubmit)

                        this.ePaymentService.dataToSubmit(this.dataToSubmit)
                    }
                    //=================================================================================================

                    return remittanceForm.valueChanges.pipe(
                        tap((remittance: RemittanceModel) => {

                            console.log("ePaymentComponentX ePayment form changed", remittance)

                            this.dataToSubmitLength = remittance.ePayment?.map(p => !p.brmt).length || 0

                            console.log("ePaymentComponentX dataToSubmitLength", this.dataToSubmitLength)

                        }),
                        takeUntil(this._destroy))
                }),
                takeUntil(this._destroy)).subscribe()
    }

    ngOnDestroy(): void {
        //restore parent subdomain
        this._mainBroadcaster.updateActiveSubdomain(this.parentSubdomain);

        this._destroy.next();
        this._destroy.complete();
    }

    addMrn(index) {
        this.ePaymentService.addMrn(index);
    }
    deleteMrn(formIndex, mrnIndex) {
        this.ePaymentService.deleteMrn(formIndex, mrnIndex);
    }
    add() {
        this.ePaymentService.addFormArray();
        // this.cd.detectChanges();
        const ePayment = this.remittanceForm.get('ePayment') as FormArray;

        const lastIndex = ePayment.length - 1;

        this.addMrn(lastIndex)

    }


    delete(i: number, formArrayName?: string) {
        this.ePaymentService.deleteFormArray(i);
    }

    hideAdd(formArray: FormArray) {
        return this.cf.hideAdd(formArray, 99);
    }
    hideDelete(formArray: FormArray) {
        return this.cf.hideDelete(formArray);
    }

    onCloseConfirm() {
        this.dialogRef.close();
    }

    onSubmit() {
        this.ePaymentService.submit().subscribe(() => {
            this.cd.detectChanges()
        })

        console.log("SUBMIT afterPatch", this.remittanceForm.value)

    }

    reSubmit(index: number) {

        this.ePaymentService.reSubmit(index)

    }
    download(ePaymentItem: EPaymentModel, docType) {
        this._mainBroadcaster.updateLoading(true);


        const ePaymentDocArgs: EPaymentsDocArgs = {
            docType,
            rmtType: "2",
            paymentId: ePaymentItem.brmt,
            deadlineDate: ePaymentItem.date,
            declarant: this.remittanceForm.get('declarant').value

        }
        console.log("SUBMIT download", ePaymentItem)

        this.ePaymentService.getDoc(ePaymentDocArgs).pipe(take(1)).subscribe(response => {
            console.log('getDoc Response::', response);
            this._mainBroadcaster.updateLoading(false);

            if (response.body) {
                this.cf.downloadDoc(response)
            }

        })

    }
    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event);
    }
    changeStyle2(event) {
        if (this.remittanceForm.valid) {
            this.color2 = this.cf.changeStyle2(this.remittanceForm, event);
        }
    }


}
