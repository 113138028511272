import { FormControl } from '@angular/forms';
import { WarIDGI700Model } from './waridgi700.model';

export class WarIDGI700Form {
    [key: string]: FormControl;
    WartypWARIDGI10 = new FormControl();
    AutCouWARIDGI20 = new FormControl();
    WarIdeWARIDEGI19 = new FormControl();

    constructor(value: WarIDGI700Model) {
        this.WartypWARIDGI10.setValue(value.WartypWARIDGI10);
        this.AutCouWARIDGI20.setValue(value.AutCouWARIDGI20);
        this.WarIdeWARIDEGI19.setValue(value.WarIdeWARIDEGI19);
    }
}
