<div>
    <h4 style="font-weight: 600">Δεδομένα υπολογισμού</h4>
</div>
<table class="calculationData-table">
    <tbody>
        <tr *ngFor="let data of calculationData">
            <td>{{ data.desc }}</td>
            <td>{{ data.value }}</td>
        </tr>
    </tbody>
</table>
