export class Traconco2Model {
    NamCO27?: string;
    StrAndNumCO222?: string;
    PosCodCO223?: string;
    CitCO224?: string;
    CouCO225?: string;
    NADLNGGTCO?: string;
    TINCO259?: string;
    TINTypCO260?: string; //NCTS ONLY
    ConsignorObject?: {};

    constructor(value?) {
        this.NamCO27 = value?.NamCO27 || null;
        this.StrAndNumCO222 = value?.StrAndNumCO222 || null;
        this.PosCodCO223 = value?.PosCodCO223 || null;
        this.CitCO224 = value?.CitCO224 || null;
        this.CouCO225 = value?.CouCO225 || null;
        this.NADLNGGTCO = value?.NADLNGGTCO || null;
        this.TINCO259 = value?.TINCO259 || null;
        this.TINTypCO260 = value?.TINTypCO260;
        this.ConsignorObject = value?.ConsignorObject;
    }
}
