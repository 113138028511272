import { CancelHeaheaModel } from './heahea/heahea.model';
import { CusoffexpertModel, TradecModel, Traexpex1Model } from '../../declaration/_models';
import { international_customersItem, User, _pelatesItem } from 'app/model/api-model';
import { PrivateControlsModel } from 'app/model/private-controls.model';

export class CancelModel {
    _PRIVATE: PrivateControlsModel;


    SynIdeMES1?: string;
    SynVerNumMES2?: string;
    MesSenMES3?: string;
    MesRecMES6?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    TesIndMES18?: string;
    IntConRefMES11?: string;
    // PriMES15?: string;
    // ComAgrIdMES17?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    // ComAccRefMES21?: string;

    //
    HEAHEA?: CancelHeaheaModel;
    CUSOFFEXPERT?: CusoffexpertModel;
    TRADEC?: TradecModel;
    TRAEXPEX1?: Traexpex1Model;
    //
    constructor(value?) {
        this._PRIVATE = value?._PRIVATE || null;


        //==========================================================

        this.SynIdeMES1 = value?.SynIdeMES1 || null;
        this.SynVerNumMES2 = value?.SynVerNumMES2 || null;
        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.IntConRefMES11 = value?.IntConRefMES11 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        // this.PriMES15 = PriMES15 ? PriMES15 : null;
        // this.ComAgrIdMES17 = ComAgrIdMES17 ? ComAgrIdMES17 : null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = 'CC514A';
        //  this.ComAccRefMES21 = ComAccRefMES21 ? ComAccRefMES21 : null;
        this.HEAHEA = value?.HEAHEA || {};
        this.CUSOFFEXPERT = value?.CUSOFFEXPERT || {};
        this.TRADEC = value?.TRADEC || {};
        this.TRAEXPEX1 = value?.TRAEXPEX1 || {};
    }
}
