import { OfficeService } from 'app/services/office.service';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UtilService } from '../util/util.service';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    officeId: string;
    private _destroy = new Subject<void>();
    constructor(private cookies: CookieService, private _util: UtilService, private officeService: OfficeService) {

        this.officeService.currentOffice$.pipe(takeUntil(this._destroy)).subscribe((office) => {

            if (office) {
                this.officeId = office?.id;
            }
        })
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    saveToken(token: string): void {
        localStorage.setItem('$clk-token', token);
    }

    getToken() {
        return localStorage.getItem('$clk-token');
    }

    removeToken() {
        localStorage.removeItem('$clk-token');
    }

    setLocalObject(key: string, value: any) {
        key = this.officeId + '_' + key;
        localStorage.setItem(key, this._util.encrypt(JSON.stringify(value)));
    }

    getLocalObject(key: string): any {
        key = this.officeId + '_' + key;

        if (!localStorage.getItem(key)) return null;
        return JSON.parse(this._util.decrypt(localStorage.getItem(key)));
    }

    checkLocalObject(key: string): boolean {
        key = this.officeId + '_' + key;

        if (localStorage.getItem(key)) { return true; }
        return false;

    }

    removeLocalObject(key: string) {
        key = this.officeId + '_' + key;

        localStorage.removeItem(key);
    }

    /**
     * to get csrf token
     */
    getCsrfToken() {
        return this.cookies.get('csrfToken');
    }
}
