import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

    @Input() addValues = true;
    message: string;
    progressValue = 0;
    myWebSocket: WebSocketSubject<any> = webSocket({
        url: environment.WS_URL,
        deserializer: msg => msg
    });
    private _destroy: Subject<void> = new Subject<void>();

    constructor(private toastrService: ToastrService, private mainBroadcaster: MainBroadcasterService, private cd: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.myWebSocket.pipe(takeUntil(this._destroy)).subscribe({
            next: (msg: MessageEvent) => {
                // this.message = msg.data[0].message;
                const data = JSON.parse(msg.data);


                // console.log("WebSocket", data)

                let addValue

                //because we are receiving the serverStatus every 5 secs
                //we set value to 0, so it doesnt return undefined
                if (this.addValues) {
                    addValue = 0;
                }

                if (data.value) {
                    addValue = data.value;
                }

                if (data.message) {
                    this.message = data.message;
                }


                if (!this.addValues) {
                    this.progressValue = addValue ? addValue : this.progressValue
                }
                else {
                    this.progressValue = this.progressValue + addValue;
                }
                console.log("WebSocket message", this.message)
                console.log("WebSocket progressValue", this.progressValue)
                this.cd.detectChanges()
            },
            // Called whenever there is a message from the server
            error: (err: unknown) => {
                this.mainBroadcaster.updateLoading(false)

                this.toastrService.error((JSON.stringify(err)), 'WebSocket Error', {
                    disableTimeOut: true
                });
                console.log(err);
            },
            // Called ifworker WebSocket API signals some kind of error
            complete: () => console.log('WEBSOCKET complete')
            // Called when connection is closed (for whatever reason)
        });
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }
}
