export class CusoffexpertModel {
    _countryCode?: string;
    RefNumERT1?: string;

    constructor(value?) {
        if (value) {
            this._countryCode = value?._countryCode || 'GR';
            this.RefNumERT1 = value.RefNumERT1;
        }
    }
}
