import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Traexpex1Model } from './traexpex1.model';

export class Traexpex1Form {
    [key: string]: FormControl;

    NamEX17 = new FormControl();
    StrAndNumEX122 = new FormControl();
    PosCodEX123 = new FormControl();
    CitEX124 = new FormControl();
    CouEX125 = new FormControl();
    NADLNGEX = new FormControl();
    TINEX159 = new FormControl();
    SpeMenEX1011 = new FormControl();
    ExporterObject = new FormControl();

    constructor(value: Traexpex1Model) {
        this.NamEX17.setValue(value.NamEX17);
        this.StrAndNumEX122.setValue(value.StrAndNumEX122);
        this.PosCodEX123.setValue(value.PosCodEX123);
        this.CitEX124.setValue(value.CitEX124);
        this.CouEX125.setValue(value.CouEX125);
        this.NADLNGEX.setValue(value.NADLNGEX);
        this.TINEX159.setValue(value.TINEX159);
        this.SpeMenEX1011.setValue(value.SpeMenEX1011);
        this.ExporterObject.setValue(value.ExporterObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
