import { NgModule } from '@angular/core';
import { BeerComponent } from './beer.component';
import { BeerHeaderComponent } from './beer-header/beer-header.component';
import { BeerCalculationDataComponent } from './beer-calculation-data/beer-calculation-data.component';
import { BeerParametersComponent } from './beer-parameters/beer-parameters.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { ContactFormModule } from 'app/main/apps/contacts/contact-form/contact-form.module';
import { AddDelButtonsModule } from 'app/main/main-building-blocks/_scams/add-del-buttons/add-del-buttons.module';
import { FormFieldsModule } from 'app/main/main-building-blocks/form-fields/form-fields.module';
import { DigitOnlyModule } from 'app/main/projects/uiowa/digit-only/src/public_api';
import { BeerService } from 'app/main/apps/excise-helper-tables/beer/beer.service';
import { NatProDescPipeModule } from 'app/services/pipes/natProDesc.pipe';
import { BeerCalculationResultsComponent } from 'app/main/apps/excise-helper-tables/beer/beer-calculation-results/beer-calculation-results.component';



@NgModule({
    declarations: [
        BeerComponent,
        BeerHeaderComponent,
        BeerCalculationDataComponent,
        BeerCalculationResultsComponent,
        BeerParametersComponent
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        AddDelButtonsModule,
        DigitOnlyModule,
        ContactFormModule,
        NatProDescPipeModule

    ],
    providers: [BeerService]

})
export class BeerModule { }
