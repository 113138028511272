import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { ApiEndPoints, ApiService } from 'app/services/api.service';

// Create the injection token for the custom settings
export const FUSE_CONFIG = new InjectionToken('fuseCustomConfig');

@Injectable({
    providedIn: 'root'
})
export class FuseConfigService {
    // Private
    private _configSubject: BehaviorSubject<any>;
    private readonly _defaultConfig: any;

    // New subject to hold backend config
    private _backendConfigSubject: BehaviorSubject<any>;

    private _configLoaded = false;

    /**
     * Constructor
     *
     * @param _platform
     * @param _router
     * @param _config
     */
    constructor(private _platform: Platform, private _router: Router, @Inject(FUSE_CONFIG) private _config, private dbQuery: ApiService) {
        // Set the default config from the user provided config (from forRoot)
        this._defaultConfig = _config;

        // Initialize the service
        this._init();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set and get the config
     */
    set config(value) {
        // Get the value from the behavior subject
        let config = this._configSubject.getValue();

        // Merge the new config
        config = _.merge({}, config, value);

        // Notify the observers
        this._configSubject.next(config);
    }

    get config(): any | Observable<any> {
        return this._configSubject.asObservable();
    }

    /**
     * Get default config
     *
     * @returns
     */
    get defaultConfig(): any {
        return this._defaultConfig;
    }

    // Add a getter for the Observable
    get backendConfig$(): Observable<any> {
        return this._backendConfigSubject.asObservable();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void {
        /**
         * Disable custom scrollbars if browser is mobile
         */
        if (this._platform.ANDROID || this._platform.IOS) {
            this._defaultConfig.customScrollbars = false;
        }

        // Create a new BehaviorSubject for the backend config
        this._backendConfigSubject = new BehaviorSubject(_.cloneDeep(this._defaultConfig));

        // Set the config from the default config
        this._configSubject = new BehaviorSubject(_.cloneDeep(this._defaultConfig));

        // Subscribe to router events
        this._router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe(() => {
            // Get the current backend configuration
            const backendConfig = this._backendConfigSubject.getValue();

            if (backendConfig && !_.isEqual(this._configSubject.getValue().layout, backendConfig.layout)) {
                // Clone the current config
                const config = _.cloneDeep(this._configSubject.getValue());

                // Reset the layout from the backend config
                config.layout = _.cloneDeep(backendConfig.layout);

                // Set the config
                this._configSubject.next(config);
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set config
     *
     * @param value
     * @param opts
     */
    setConfig(value, opts = { emitEvent: true }): void {
        // Get the value from the behavior subject
        let config = this._configSubject.getValue();

        console.log("fuseConfig setConfig initialConfig", config)

        // Merge the new config
        config = _.merge({}, config, value);
        console.log("fuseConfig setConfig after merge", config)


        // If emitEvent option is true...
        if (opts.emitEvent === true) {
            // Notify the observers
            this._configSubject.next(config);
        }
    }

    /**
     * Get config
     *
     * @returns
     */
    getConfig(): Observable<any> {
        return this._configSubject.asObservable();
    }

    /**
     * Reset to the default config
     */
    resetToDefaults(): void {
        // Set the config from the default config
        this._configSubject.next(_.cloneDeep(this._defaultConfig));
    }

    getDefaultConfig(): Observable<any> {

        // console.trace("fuseConfig getDefaultConfig")

        return this.dbQuery.get_options(ApiEndPoints.theme_settings, false).pipe(
            take(1),
            tap(config => {
                this._backendConfigSubject.next(config);
            })
        );
    }

    loadConfig(): Observable<any> {
        if (this._configLoaded) {
            return of(this._backendConfigSubject.getValue());
        } else {
            return this.getDefaultConfig().pipe(
                tap(() => this._configLoaded = true)
            );
        }
    }
    saveConfig(config): Observable<any> {
        return this.dbQuery.save_options(ApiEndPoints.theme_settings, config).pipe(
            take(1),
            tap(config => {
                // this._backendConfigSubject.next(config);
            })
        );
    }
}
