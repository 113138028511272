export class NatProModel {
    WarNumNP1016?: string;
    ProIdNP1017?: string;
    ProQuaNP1018?: string;

    constructor(value?) {
        if (value) {
            this.WarNumNP1016 = value.WarNumNP1016;
            this.ProIdNP1017 = value.ProIdNP1017;
            this.ProQuaNP1018 = value.ProQuaNP1018;
        }
    }
}
