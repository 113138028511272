import { FormControl, Validators } from '@angular/forms';
import { DefPosPayModel } from './defpospay.model';

export class DefPosPayForm {
    [key: string]: FormControl;

    AutRefDPP1 = new FormControl();

    constructor(value: DefPosPayModel) {
        this.AutRefDPP1.setValue(value.AutRefDPP1);
    }
}
