import { LoadingComponent } from './loading.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
    declarations: [LoadingComponent],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatProgressBarModule
    ],
    exports: [LoadingComponent]
})
export class LoadingModule { }
