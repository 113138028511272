import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'remittanceError'
})
export class RemittanceErrorPipe implements PipeTransform {
    transform(brmt: string, blrn: string): string {
        const array = [
            { brmt: 'lrn /afm check error', translated: 'Προέκυψε λάθος στον έλεγχο παραστατικού (LRN για τον ίδιο ΑΦΜ)' },

            { brmt: 'mrn /afm check error', translated: 'Προέκυψε λάθος στον έλεγχο παραστατικού (MRN και ΑΦΜ)' },

            { brmt: 'completed remittance', translated: 'Υπάρχει ολοκληρωμένη ταυτότητα πληρωμής που περιλαμβάνει τα στοιχεία' },

            { brmt: 'payment already paid', translated: 'Υπάρχει σε εξέλιξη άλλη διαδικασία που περιλαμβάνει τα στοιχεία (πληρωμή ήδη ολοκληρωμένη)' },

            { brmt: 'pending paymnt exist', translated: 'Υπάρχει σε εξέλιξη άλλη διαδικασία που περιλαμβάνει τα στοιχεία (υπάρχουσα αναμονή της πληρωμής)' },


            { brmt: 'invalid field data', translated: 'Υπάρχει λάθος συμπλήρωσης πεδίου' },

            { brmt: 'count error', translated: 'Δεν συμφωνεί το πλήθος ενός LRN στο αρχείο XML με το πλήθος στο αντίστοιχο πεδίο' },

            { brmt: 'perm error', translated: 'Προέκυψε λάθος στον έλεγχο εξουσιοδότησης' },


            { blrn: 'xsd - others error', brmt: 'invalid message content', translated: 'Προέκυψε γενικότερο λάθος στη διαδικασία' },

            { blrn: 'xsd error', brmt: 'invalid message content', translated: 'Η υποβολή δεν περιέχει ορθό περιεχόμενο' },

            { blrn: 'authuser - soapuser error', brmt: 'invalid message content', translated: 'Η υποβολή απέτυχε επειδή διαφέρουν ο χρήστης του μηνύματος με την επαλήθευση κατά TAXIS' },

            { blrn: 'process error', brmt: 'system inaccessibility', translated: 'Η υποβολή απέτυχε λόγω εσωτερικής μη διαθεσιμότητας' },

            { blrn: 'error', brmt: 'data not found', translated: ' Υπάρχει λάθος στα πεδία του αρχείου XML που υποβλήθηκε' }


        ];

        const res = array.filter(x => x.brmt === brmt);

        console.log("remError brmt", brmt)
        console.log("remError blrn", blrn)
        console.log("remError res", res)
        console.log("remError res length", res.length)

        if (res.length === 0) {
            return brmt;
        }
        if (res.length === 1) {

            return res[0].translated;
        }
        else if (res.length > 1) {

            return res.filter(x => x.blrn === blrn)[0].translated
        }
    }
}


@NgModule({
    declarations: [RemittanceErrorPipe],
    exports: [RemittanceErrorPipe],
})
export class RemittanceErrorPipeModule { }