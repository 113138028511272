import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { TariffCalcResults, TariffTotalsType } from '../../_models';

@Component({
    selector: 'app-tariff-results-table',
    templateUrl: './tariff-results-table.component.html',
    styleUrls: ['./tariff-results-table.component.scss']
})
export class TariffResultsTableComponent implements OnInit {
    @Input() results: TariffCalcResults[];
    @Input() totals: TariffTotalsType;
    @Input() tableTitle: string;
    @Input() tableRows: number;

    imgPath = environment.DOMAIN_NAME + '/assets/images/flags/';


    ngOnInit(): void {
        console.log(this.results);
    }
}
