import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ProductNode } from '../taric.service';

/**
 * Product data with nested structure.
 * Each node has a description and an optional list of children.
 */
export interface FootNote {
    FootnoteCode: string;
    FootnoteDesc: string;
}

export class TreeDataSource extends MatTreeNestedDataSource<ProductNode> {
    constructor(public treeControl: NestedTreeControl<ProductNode>, intialData: ProductNode[]) {
        super();
        this._data.next(intialData);
    }

    /** Add node as child of parent */
    public add(node: ProductNode, parent: ProductNode) {
        // add dummy root so we only have to deal with `ProductNode`s
        const newTreeData = {
            id: 0,
            hasChildren: false,
            description: 'Dummy Root',
            children: this.data
        };
        this._add(node, parent, newTreeData);
        this.data = newTreeData.children;
    }

    /** Remove node from tree */
    public remove(node: ProductNode) {
        const newTreeData = {
            id: 0,
            hasChildren: false,
            description: 'Dummy Root',
            children: this.data
        };
        this._remove(node, newTreeData);
        this.data = newTreeData.children;
    }

    /*
     * For immutable update patterns, have a look at:
     * https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns/
     */

    protected _add(newNode: ProductNode, parent: ProductNode, tree: ProductNode) {
        // console.log("DD Tree:",tree.id)
        // console.log("DD parent:",parent.id)
        if (tree.id === parent.id) {
            //console.log('Tree==Parent: Adding...')
            //console.log(
            //  `DDreplacing children array of '${parent.description}', adding ${
            //    newNode.description
            //  }`
            //);
            if (tree.children) {
                //  console.log('DDtree children found! Adding...',tree.children)
                tree.children = [...tree.children!, newNode];
            } else {
                // console.log('DDtree children NOT found! ')
                tree.children = [newNode];
                //  console.log('DDtree children After first add! ',tree.children)
            }
            this.treeControl.expand(tree);
            return true;
        } else {
            //
            // console.log('Tree IS NOt Equal To Parent: Not Adding...')
            // console.log("DD Tree:",tree)
            // console.log("DD parent:",parent)
        }

        if (!tree.children) {
            // console.log('Tree Children Not Found: Not Adding...')

            //console.log(`DDreached leaf node '${tree.description}', backing out`);
            return false;
        }

        return this.update(tree, this._add.bind(this, newNode, parent));
    }

    _remove(node: ProductNode, tree: ProductNode): boolean {
        if (!tree.children) {
            return false;
        }
        const i = tree.children.indexOf(node);
        if (i > -1) {
            tree.children = [...tree.children.slice(0, i), ...tree.children.slice(i + 1)];
            this.treeControl.collapse(node);
            // console.log(`found ${node.description}, removing it from`, tree);
            return true;
        }
        return this.update(tree, this._remove.bind(this, node));
    }

    protected update(tree: ProductNode, predicate: (n: ProductNode) => boolean) {
        let updatedTree: ProductNode;
        let updatedIndex: number;

        tree.children!.find((node, i) => {
            if (predicate(node)) {
                //   console.log(`creating new node for '${node.description}'`);
                updatedTree = { ...node };
                updatedIndex = i;
                this.moveExpansionState(node, updatedTree);
                return true;
            }
            return false;
        });

        if (updatedTree!) {
            //  console.log(
            //    `replacing node '${tree.children![updatedIndex!].description}'`
            //  );
            tree.children![updatedIndex!] = updatedTree!;
            return true;
        }
        return false;
    }

    moveExpansionState(from: ProductNode, to: ProductNode) {
        if (this.treeControl.isExpanded(from)) {
            // console.log(
            //   `'${from.description}' was expanded, setting expanded on new node`
            // );
            this.treeControl.collapse(from);
            this.treeControl.expand(to);
        }
    }
}
