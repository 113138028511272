import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { PrintSettingsForm } from './_models/print-settings-form.model';
import { PrintSettingsModel } from './_models/print-settings.model';

@Injectable({ providedIn: 'root' })
export class PrintSettingsService {
    printSettings: PrintSettingsModel[];
    printSettingsForm: FormGroup;

    printForm$: Observable<FormGroup>;
    private printForm: BehaviorSubject<FormGroup>;
    private _destroy: Subject<void> = new Subject<void>();

    constructor(private mainBroadcaster: MainBroadcasterService, private dbQuery: ApiService) {
        this.printForm = new BehaviorSubject(null);
        this.printForm$ = this.printForm.asObservable();

        this.printSettingsForm = new FormGroup({
            settings: new FormArray([])
        });

        this.mainBroadcaster.printSettings$.pipe(takeUntil(this._destroy)).subscribe(settings => {
            if (settings) {
                this.printSettings = settings;

                this.printSettingsForm = new FormGroup({
                    settings: new FormArray([])
                });

                if (this.printSettings.length > 0) {
                    this.printSettings.forEach(setting => {
                        (this.printSettingsForm.get('settings') as FormArray).push(
                            new FormGroup(new PrintSettingsForm(new PrintSettingsModel(setting)))
                        );
                    });

                    this.printForm.next(this.printSettingsForm);
                }
            }
        });
    }

    saveSettings(settings: PrintSettingsModel): Observable<PrintSettingsModel[]> {
        return this.dbQuery.savePrintSettings(settings).pipe(
            tap(result => {
                if (result) {
                    this.mainBroadcaster.updatePrintSettings(result);
                }
            }),
            take(1)
        );
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._destroy.next();
        this._destroy.complete();
    }
    getSettings(): Observable<PrintSettingsModel[]> {
        return this.dbQuery
            .get_options(ApiEndPoints.printSettings, false)
            .pipe(tap(
                value => {
                    console.log('printSettings value on service', value);
                    this.mainBroadcaster.updatePrintSettings(value);
                }

            ), take(1))
    }
}
