import { FormControl, Validators } from '@angular/forms';
import { NctsPreAdmRefModel } from './preadmrefar2.model';

export class NctsPreAdmRefForm {
    [key: string]: FormControl;

    PreDocTypAR21 = new FormControl();
    PreDocRefAR26 = new FormControl(null, { updateOn: 'blur' });
    PreDocRefLNG = new FormControl();
    ComInfPREADMREFGI4 = new FormControl();
    ComInfLNGPREADMREFGI5 = new FormControl();

    constructor(value: NctsPreAdmRefModel) {
        this.PreDocTypAR21.setValue(value.PreDocTypAR21);
        this.PreDocRefAR26.setValue(value.PreDocRefAR26);
        this.PreDocRefLNG.setValue(value.PreDocRefLNG);
        this.ComInfPREADMREFGI4.setValue(value.ComInfPREADMREFGI4);
        this.ComInfLNGPREADMREFGI5.setValue(value.ComInfLNGPREADMREFGI5);
    }
}
