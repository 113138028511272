import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CountrySelectComponent } from './country-select.component';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';

@NgModule({
    declarations: [CountrySelectComponent],
    exports: [CountrySelectComponent],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, MaterialModule]
})
export class CountrySelectModule {}
