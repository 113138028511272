export class DelterModel {
    IncCodTDL1?: string;
    ComCodTDL1?: string;
    ComInfDELTER387?: string;
    ComInfDELTER387LNG?: string;

    constructor(value?) {
        if (value) {
            this.IncCodTDL1 = value.IncCodTDL1;
            this.ComCodTDL1 = value.ComCodTDL1;
            this.ComInfDELTER387 = value.ComInfDELTER387;
            this.ComInfDELTER387LNG = value.ComInfDELTER387LNG;
        }
    }
}
