import { ePaymentResponseModel } from './../../main/apps/e-payment/_models/e-payment-response.model';
import { RemittanceInput } from './../openDialogs.service';
import { EPaymentSignal } from './../broadcaster.service';
import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CodeDescParams, DeclarationStates, FormDisabledItem, MainBroadcasterService, SupplementaryUnits } from 'app/services/broadcaster.service';
import { combineLatest, merge, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, tap, map, take, switchMap, startWith } from 'rxjs/operators';
import { ModifyDeclarationService } from './modify-declaration.service';
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { DeclarationProductDescriptionService } from 'app/services/product-description.service';
import declarationData from 'assets/declarationData.json';
import { CancelDeclarationService } from './cancel-declaration.service';
import { DeclarationModels } from './submitForm.service';
import { ApiService } from '../api.service';
import { StorageService } from 'app/core/services/storage/storage.service';
import { AccountingService } from 'app/main/main-building-blocks/_scams/accounting/accounting.service';

/**
 * This should be injected on component level because everytime we change domains, it must take the new subdomain
 * and it should not carry the previous one
 */
@Injectable()
export class SharedDeclBisService {
    declarationForm: FormGroup;
    subdomain: string;
    mainMessageType: string;
    modifyMessageType: string;
    cancelMessageType: string;

    modifyDeclaration$: Subscription;
    cancelDeclaration$: Subscription;
    private _destroy: Subject<void> = new Subject<void>();

    constructor(
        private _mainBroadcaster: MainBroadcasterService,
        private modifyDeclarationService: ModifyDeclarationService,
        private cancelDeclarationService: CancelDeclarationService,
        private openDialogService: OpenDialogsService,
        private productDescriptionService: DeclarationProductDescriptionService,
        private storageService: StorageService,
        private dbQuery: ApiService
    ) {
        console.log('...sharedDeclService created');
        this._mainBroadcaster.activeSubdomain$.pipe(takeUntil(this._destroy)).subscribe(subdomain => {
            this.subdomain = subdomain;

            console.log('sharedDeclService subdomain', subdomain);

            this.mainMessageType = declarationData[subdomain]?.messageType
            this.modifyMessageType = declarationData[subdomain]?.modifyMessageType
            this.cancelMessageType = declarationData[subdomain]?.cancelMessageType
        })



    }

    ngOnDestroy() {
        console.log('...sharedDeclService destroyed');
        this._destroy.next();
        this._destroy.complete();
    }

    onDeclFormValuechanges(declarationForm: FormGroup, subdomain: string): Observable<DeclarationModels> {

        return declarationForm.valueChanges.pipe(startWith(declarationForm.value), debounceTime(300), distinctUntilChanged(), tap(() => {
            console.log('followSubscriptionChain onDeclFormValuechanges ', declarationForm.value, "subdomain", subdomain);

            this._mainBroadcaster.updateDeclarationForms({
                [subdomain]: declarationForm
            });

            this.declarationForm = declarationForm;

            const state = declarationForm.get('_PRIVATE').get('stateCode').value;


            if (state === 'Pre-Submitted' || state === 'Edit' || state === 'Rejected' || state === 'Refused') {
                //console.log('STATEONVALUECHANGE setLocalStorage item', declarationData[subdomain].localStorage);

                this.storageService.setLocalObject(declarationData[subdomain].localStorage, declarationForm.getRawValue());
            }

            if (declarationForm.get('HEAHEA')?.get('IdeOfMeaOfTraCroHEA85')?.touched) {
                this._mainBroadcaster.updateTerminateTrasAtDep();
                //this._mainBroadcaster.terminateTransAtDepId.complete();

                //console.log("TRANSPORT CROSS BORDER TOUCHED")
            }

            //this.hasErrors(declarationForm)
        }));
    }

    /*     modifyCancelDecl() {
            console.log("fn sharedDeclBis modifyCancelDecl")
            if (!this.modifyDeclaration$) {
                this.modifyDeclaration$ = this._mainBroadcaster.modifyDeclaration$.pipe(takeUntil(this._destroy)).subscribe(() => {
                    console.log("modifyCancelDecl modifyDeclaration$")
    
                    this.modifyDeclarationService.modifyDeclaration().subscribe();
                });
            }
    
            if (!this.cancelDeclaration$) {
                this.cancelDeclaration$ = this._mainBroadcaster.cancelDeclaration$.pipe(takeUntil(this._destroy)).subscribe(() => {
                    console.log("modifyCancelDecl cancelDeclaration$")
                    this.cancelDeclarationService.cancelDeclaration().subscribe();
                });
            }
    
        } */

    modifyCancelDecl() {
        console.log("fn sharedDeclBis modifyCancelDecl")

        let modifyDeclaration$;
        let cancelDeclaration$;

        if (!this.modifyDeclaration$) {
            modifyDeclaration$ = this._mainBroadcaster.modifyDeclaration$
                .pipe(
                    switchMap(() => this.modifyDeclarationService.modifyDeclaration()),
                    map(res => ({ type: 'modifyDeclaration', data: res }))
                );
        }

        if (!this.cancelDeclaration$) {
            cancelDeclaration$ = this._mainBroadcaster.cancelDeclaration$
                .pipe(
                    switchMap(() => this.cancelDeclarationService.cancelDeclaration()),
                    map(res => ({ type: 'cancelDeclaration', data: res }))
                );
        }

        return merge(modifyDeclaration$, cancelDeclaration$);
    }


    declarationState(declarationForm: FormGroup): Observable<[DeclarationStates, boolean]> {
        console.log("fn eAitisi declarationState declartionForm", declarationForm.value)

        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value
        const declarationStates$ = this._mainBroadcaster.declarationStates$;
        const formDisabled$ = this._mainBroadcaster.formDisabled$.pipe(map(form => form[subdomain]));

        return combineLatest([declarationStates$, formDisabled$]);
    }
    prostheta(): Observable<void> {
        console.log("fn sharedDeclBis prostheta")

        return this._mainBroadcaster.declarationMore$
            .pipe(
                switchMap(() => {
                    console.log("fn sharedDeclBis prostheta declarationMore open")

                    return this.openDialogService.openDeclarationMore(this.subdomain, this.declarationForm);
                }),
                takeUntil(this._destroy)
            );
    }


    ePayment(): Observable<void> {
        return this._mainBroadcaster.ePayment$
            .pipe(
                switchMap((data: EPaymentSignal) => {
                    console.log("declaForm", this.declarationForm)
                    return this.dbQuery.checkRemittance({ mrn: this.declarationForm.get('_PRIVATE').get('mrn').value })
                        .pipe(
                            switchMap((remittance: ePaymentResponseModel) => {
                                const remittanceInput: RemittanceInput = {
                                    private: this.declarationForm.get('_PRIVATE').value,
                                    remittance
                                }
                                return this.openDialogService.openEPaymentDialog([remittanceInput])
                                    .pipe(
                                        tap(value => console.log("ePayment dialog response", value))
                                    )
                            }))
                })
            )
    }


    //TIR ONLY==========================================
    security(): Observable<void> {
        return this._mainBroadcaster.declarationSecurity$.pipe(tap(() => {
            this.openDialogService.openDeclarationSecurity(this.subdomain, this.declarationForm).subscribe();
        }));
    }


    setCodeDesc(): Observable<CodeDescParams> {
        console.log("fn sharedDeclBis setCodeDesc")

        return this._mainBroadcaster.codeDescParms$.pipe(tap((params: CodeDescParams) => {
            this.productDescriptionService.setCodeDesc(this.declarationForm, params);
        }));
    }

    getSupUnits(index): Observable<SupplementaryUnits> {
        console.log("fn sharedDeclBis getSupUnits")

        return this._mainBroadcaster.supUnits$.pipe(
            map(units => units[this.subdomain]),
            map(obj => obj.filter(el => el.index === index)),
            take(1)
        );
    }

    hasErrors(declarationForm) {

        const heahea = declarationForm.get('HEAHEA') as FormGroup;

        const netMass = heahea.get('NetMasGDS48')



    }

    /* 
        calculateGoodsPrice(gooitegds: FormArray) {
    
            const length = gooitegds.length
    
            for (let i = 0; i < length; i++) {
                const productPrice = gooitegds.at(i).get('ProPri4002') as FormControl
                const netWeight = gooitegds.at(i).get('NetMasGDS48') as FormControl
    
                combineLatest([
                    productPrice.valueChanges.pipe(startWith(productPrice.value)),
                    netWeight.valueChanges.pipe(startWith(netWeight.value))
                ])
                    .pipe(
                        takeUntil(this._destroy),
                        tap(([productPrice, netWeight]) => {
    
                            const accounting = gooitegds.at(i).get('ACCOUNTING') as FormArray;
    
                            const goodsValue = productPrice * netWeight
    
                            accounting.at(0).get('AccValue').setValue(goodsValue)
    
                            this._mainBroadcaster.accountingDetectChanges.next();
    
                            this.accountingService.calculateNewValues(accounting, gooitegds.at(i) as FormGroup, i)
    
                        })).subscribe()
    
            }
        }
     */


}
