import { ContentContainerDirectiveModule } from './../tab-factory/content-container.module';
import { NgModule } from '@angular/core';
import { GeneralApplicationsComponent } from './general-applications.component';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { ToposTirisisEggrafonModule } from 'app/main/apps/general-applications/topos-tirisis-eggrafon/topos-tirisis-eggrafon.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DistillationLicenceAppModule } from 'app/main/apps/general-applications/distillation-licence-app/distillation-licence-app.module';
import { GeneralApplicationsService } from 'app/main/apps/general-applications/general-applications.service';
import { FilterPipeModule } from 'app/main/businesses/invoices/add/filterName.pipe';
import { FuseSharedModule } from '@fuse/shared.module';

const MODULES = [

    ToposTirisisEggrafonModule,
    DistillationLicenceAppModule


]

@NgModule({
    declarations: [
        GeneralApplicationsComponent
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
        ContentContainerDirectiveModule,
        FilterPipeModule,
        ...MODULES
    ],
    providers: [
        GeneralApplicationsService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ],
    exports: MODULES
})

export class GeneralApplicationsModule { }
