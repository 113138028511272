import { Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToposTirisisEggrafonService } from 'app/main/apps/general-applications/topos-tirisis-eggrafon/topos-tirisis-eggrafon.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { Declarant } from 'app/core/user/user.model';
import { UserService } from 'app/core/user/user.service';
import { DatePipe } from '@angular/common';
import { ApiService } from 'app/services/api.service';
import { Custom } from 'app/main/apps/customs/customs.model';

@Component({
    selector: 'app-topos-tirisis-eggrafon',
    templateUrl: './topos-tirisis-eggrafon.component.html',
    styleUrls: ['./topos-tirisis-eggrafon.component.scss'],
    providers: [ToposTirisisEggrafonService]
})
export class ToposTirisisEggrafonComponent implements OnInit {

    dilosiForm$: Observable<FormGroup>;
    pelates$: Observable<Contact[]>;
    declarants$: Observable<Declarant[]>;

    customs: Custom[] = []



    pelConfig: AutoCompConfigValues = {
        display: { values: ['lastname'] },
        menuOptions: { options: ['lastname', 'afm'] },
        menuTitles: [null, 'ΑΦΜ: ']
    };


    get customer(): FormGroup {
        return this.dilosiForm.get('customer') as FormGroup
    }
    get declarant(): FormGroup {
        return this.dilosiForm.get('declarant') as FormGroup
    }

    get documentsPlace(): FormGroup {
        return this.dilosiForm.get('documentsPlace') as FormGroup
    }

    get contactDetails(): FormGroup {
        return this.dilosiForm.get('contactDetails') as FormGroup
    }

    dilosiForm: FormGroup

    private _destroy = new Subject<void>();
    constructor(private dilosiService: ToposTirisisEggrafonService, private mainBroadcaster: MainBroadcasterService, private userService: UserService, private dateTransform: DatePipe, private dbQuery: ApiService) {

        this.dbQuery.get_customs('GR').subscribe(customs => {

            this.customs = customs
        })
    }

    ngOnInit(): void {
        this.pelates$ = this.mainBroadcaster.domecticCustomers$
        this.declarants$ = this.mainBroadcaster.declarants$

        this.userService.user$.pipe(take(1)).subscribe(user => {

            console.log("TirisiEggrafonComponent user: ", user)
        })


        this.dilosiForm$ = this.dilosiService.dilosiForm$.pipe(tap(form => {


            this.dilosiForm = form


            this.dilosiForm.valueChanges.subscribe(val => { console.log("dilosiForm valueChanges: ", val) })

            this.controlValueChanges()

            this.dilosiForm.get('date').setValue(this.dateTransform.transform(new Date(), 'dd/MM/yyyy'))


        }));



    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    controlValueChanges() {

        this.dilosiForm.get('customsName').valueChanges.pipe(takeUntil(this._destroy)).subscribe(val => {

            const customsCode = this.customs.find(custom => custom.CustomsOfficeNameAlt === val)?.ReferenceNumber

            this.dilosiForm.get('customsCode').setValue(customsCode)
        })


        this.customer.get('customerObject').valueChanges.pipe(takeUntil(this._destroy)).subscribe(val => {

            this.setCustomer(val)

        })
        this.declarant.get('declarantObject').valueChanges.pipe(takeUntil(this._destroy)).subscribe(val => {

            this.setDeclarant(val)

        })
        this.documentsPlace.get('place').valueChanges.pipe(takeUntil(this._destroy)).subscribe(val => {

            console.log("documentsPlace valueChanges: ", val)

            this.setDocumentsPlace(val)

        })

        this.mainBroadcaster.defaultDeclarant$.pipe(take(1)).subscribe(declarant => {

            this.declarant.get('declarantObject').setValue(declarant)
        })
    }

    setCustomer(customer: Contact) {
        const contactDetails = this.dilosiForm.get('customer').get('contactDetails') as FormGroup;

        contactDetails.get('lastname').setValue(customer.lastname)
        contactDetails.get('firstname').setValue(customer.firstname)
        contactDetails.get('phone').setValue(customer.phone)
        contactDetails.get('email').setValue(customer.email)

    }

    setDeclarant(declarant: Declarant) {

        const contactDetails = this.dilosiForm.get('declarant').get('contactDetails') as FormGroup;

        contactDetails.get('lastname').setValue(declarant.lastname)
        contactDetails.get('firstname').setValue(declarant.firstname)
        //  contactDetails.get('phone').setValue(declarant.phone)
        //  contactDetails.get('email').setValue(declarant.email)
    }

    setDocumentsPlace(value: 0 | 1 | 2) {

        let lastname = null;
        let firstname = null;
        let street = null;
        let postalCode = null;
        let city = null




        if (value === 0) {

            const customer = this.customer.get('customerObject').value as Contact
            street = customer?.street
            postalCode = customer?.postal_code
            city = customer?.city


        }
        else if (value === 2) {
            const declarant = this.declarant.get('declarantObject').value as Declarant

            lastname = declarant?.lastname;
            firstname = declarant?.firstname;

            street = declarant?.street
            postalCode = declarant?.postal_code
            city = declarant?.city



        }

        this.documentsPlace.get('street').setValue(street)
        this.documentsPlace.get('postalCode').setValue(postalCode)
        this.documentsPlace.get('city').setValue(city)


        this.contactDetails.get('lastname').setValue(lastname)
        this.contactDetails.get('firstname').setValue(firstname)
    }
    onFocus(event) {


    }
}
