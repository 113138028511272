import { FormControl, Validators } from '@angular/forms';
import { VehDetModel } from './vehdet.model';

export class VehDetForm {
    [key: string]: FormControl;

    FraNumVD1014 = new FormControl();
    CarWarNumVD1015 = new FormControl();

    constructor(value: VehDetModel) {
        this.FraNumVD1014.setValue(value.FraNumVD1014);
        this.CarWarNumVD1015.setValue(value.CarWarNumVD1015);
    }
}
