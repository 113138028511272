import { TariffFinalResponse } from "app/main/apps/tariff-calculate/_models";
import { NationalProductsModel } from "./national-products/national-products.model";
import { PreviousDocumentsModel } from "./previous-documents/previous-documents.model";
import { ReferenceDocumentsModel } from "./reference-docs/reference-docs.model";
import { SpecialMentionsModel } from "./special-mentions/special-mentions.model";
import { SupplementaryComputationInformationModel } from "./sup-computation/sup-computation.model";
import { TaxComputationModel } from "./tax-computation/tax-computation.model";
import { DEFKVehicleDetailsModel } from "./vehicle-details/vehicle-details.model";


export class TaxesRowModel {


    ExciseTaxesDeclarationRowNumber?: string;
    SymbolNumbers?: string;
    ProductDescription?: string;
    TaricCode?: string;
    TaricAdditionCode?: string;
    TaxQuantity?: string;
    MeasuringUnitsType?: string;
    AdditionalTaxQuantity?: string;
    AdditionalMeasuringUnits?: string;
    Plato?: string;
    EtepaaAmountPaid?: string;
    ExciseTaxCompensation?: string;
    VatAmountCompensation?: string;
    VatInflowAmountCompensation?: string;
    OgaAmountCompensation?: string;
    TaxValue?: string;
    CountryOfOrigin?: string;
    GrossWeight?: string;
    NetWeight?: string;
    ConditionIdentification?: string;
    PreviousConditionIdentification?: string;
    ExemptionCode?: string;
    NetValue?: string;
    StatisticalValue?: string;
    SuspensionTypeCode?: string;
    CarCertificate?: string;
    SupplementaryComputationInformation?: SupplementaryComputationInformationModel[];
    PreviousDocuments?: PreviousDocumentsModel;
    ReferenceDocuments?: ReferenceDocumentsModel[];
    NationalProducts?: NationalProductsModel[];
    VehicleDetails?: DEFKVehicleDetailsModel[];
    TaxComputation?: TaxComputationModel[];
    SpecialMentions?: SpecialMentionsModel[];

    constructor(value?) {



        this.ExciseTaxesDeclarationRowNumber = value?.ExciseTaxesDeclarationRowNumber || null;
        this.SymbolNumbers = value?.SymbolNumbers || null;
        this.ProductDescription = value?.ProductDescription || null;
        this.TaricCode = value?.TaricCode || null;
        this.TaricAdditionCode = value?.TaricAdditionCode || null;
        this.TaxQuantity = value?.TaxQuantity || null;
        this.MeasuringUnitsType = value?.MeasuringUnitsType || null;
        this.AdditionalTaxQuantity = value?.AdditionalTaxQuantity || null;
        this.AdditionalMeasuringUnits = value?.AdditionalMeasuringUnits || null;
        this.Plato = value?.Plato || null;
        this.EtepaaAmountPaid = value?.EtepaaAmountPaid || null;
        this.ExciseTaxCompensation = value?.ExciseTaxCompensation || null;
        this.VatAmountCompensation = value?.VatAmountCompensation || null;
        this.VatInflowAmountCompensation = value?.VatInflowAmountCompensation || null;
        this.OgaAmountCompensation = value?.OgaAmountCompensation || null;
        this.TaxValue = value?.TaxValue || null;
        this.CountryOfOrigin = value?.CountryOfOrigin || null;
        this.GrossWeight = value?.GrossWeight || null;
        this.NetWeight = value?.NetWeight || null;
        this.ConditionIdentification = value?.ConditionIdentification || null;
        this.PreviousConditionIdentification = value?.PreviousConditionIdentification || null;
        this.ExemptionCode = value?.ExemptionCode || null;
        this.NetValue = value?.NetValue || null;
        this.StatisticalValue = value?.StatisticalValue || null;
        this.SuspensionTypeCode = value?.SuspensionTypeCode || null;
        this.CarCertificate = value?.CarCertificate || null;
        this.SupplementaryComputationInformation = value?.SupplementaryComputationInformation || null;
        this.PreviousDocuments = value?.PreviousDocuments || null;
        this.ReferenceDocuments = value?.ReferenceDocuments || null;
        this.NationalProducts = value?.NationalProducts || null;
        this.VehicleDetails = value?.VehicleDetails || null;
        this.TaxComputation = value?.TaxComputation || null;
        this.SpecialMentions = value?.SpecialMentions || null;
    }

}