import { FormControl } from '@angular/forms';
import { PrintSettingsModel } from './print-settings.model';

export class PrintSettingsForm {
    [key: string]: FormControl;
    id = new FormControl();
    title = new FormControl();
    document = new FormControl();
    top = new FormControl();
    left = new FormControl();

    constructor(value: PrintSettingsModel) {
        this.id.setValue(value.id);
        this.title.setValue(value.title);
        this.document.setValue(value.document);
        this.top.setValue(value.top);
        this.left.setValue(value.left);
    }
}
