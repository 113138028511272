import { FormControl, Validators } from '@angular/forms';
import { TirPreAdmRefModel } from './preadmrefar2.model';

export class TirPreAdmRefForm {
    [key: string]: FormControl;

    PreDocTypAR21 = new FormControl();
    PreDocRefLNG = new FormControl();
    ComOfInfAR29 = new FormControl();
    ComOfInfAR29LNG = new FormControl();
    CarManLinYeaAR500 = new FormControl();
    CarManLinCusAR501 = new FormControl();
    CarManLinDocNumAR502 = new FormControl();
    CarManLinSerNumAR503 = new FormControl();
    CarManLinLinAR504 = new FormControl();
    CMrnEdeAR505 = new FormControl();
    LinEdeAR506 = new FormControl();
    NetMasEdeAR507 = new FormControl();
    AddUniEdeAR508 = new FormControl();
    PreDocRefAR26 = new FormControl();
    CarManLinKinPacAR510 = new FormControl();
    CarManLinQuaAR511 = new FormControl();

    constructor(value: TirPreAdmRefModel) {
        this.PreDocTypAR21.setValue(value.PreDocTypAR21);
        this.PreDocRefLNG.setValue(value.PreDocRefLNG);
        this.ComOfInfAR29.setValue(value.ComOfInfAR29);
        this.ComOfInfAR29LNG.setValue(value.ComOfInfAR29LNG);
        this.CarManLinYeaAR500.setValue(value.CarManLinYeaAR500);
        this.CarManLinCusAR501.setValue(value.CarManLinCusAR501);
        this.CarManLinDocNumAR502.setValue(value.CarManLinDocNumAR502);
        this.CarManLinSerNumAR503.setValue(value.CarManLinSerNumAR503);
        this.CarManLinLinAR504.setValue(value.CarManLinLinAR504);
        this.CMrnEdeAR505.setValue(value.CMrnEdeAR505);
        this.LinEdeAR506.setValue(value.LinEdeAR506);
        this.NetMasEdeAR507.setValue(value.NetMasEdeAR507);
        this.AddUniEdeAR508.setValue(value.AddUniEdeAR508);
        this.PreDocRefAR26.setValue(value.PreDocRefAR26);
        this.CarManLinKinPacAR510.setValue(value.CarManLinKinPacAR510);
        this.CarManLinQuaAR511.setValue(value.CarManLinQuaAR511);
    }
}
