import { AbstractControl, FormControl } from "@angular/forms"
import { DEFKContactDetailsModel } from "./contactDetails.model";
export class DEFKContactDetailsForm {
    [key: string]: AbstractControl;

    LastNames = new FormControl();
    Forenames = new FormControl();
    NameOfFather = new FormControl();
    Address = new FormControl();
    Country = new FormControl();

    constructor(value: DEFKContactDetailsModel) {
        this.LastNames.setValue(value.LastNames);
        this.Forenames.setValue(value.Forenames);
        this.NameOfFather.setValue(value.NameOfFather);
        this.Address.setValue(value.Address);
        this.Country.setValue(value.Country);

    }
}
