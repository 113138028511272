import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CountriesService } from '../countries.service';

@Pipe({
    name: 'countryPipe'
})
export class CountryPipe implements PipeTransform {
    constructor(private countryService: CountriesService) { }
    transform(countryCode: string, property?: 'country_code' | 'country_name' | 'country_name_en'): any {
        const country = this.countryService.getCountry(countryCode);

        if (country) {
            return country[property];
        }
    }
}

@NgModule({
    declarations: [CountryPipe],
    exports: [CountryPipe],
})
export class CountryPipeModule { }