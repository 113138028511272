import { ePaymentResponseModel } from './../main/apps/e-payment/_models/e-payment-response.model';
import { Injectable, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PrintSettingsModel } from 'app/main/app-settings/app-settings/print-settings/_models/print-settings.model';
import { AgrCertModel } from 'app/main/apps/export/agrcert/_models/agr.model';
import { CmrModel } from 'app/main/apps/export/cmr/_models/cmr.model';
import { OriginCertModel } from 'app/main/apps/export/origin-cert/_models/origin-cert.model';
import { ProductNode } from 'app/main/apps/taric/taric.service';
import { _pelatesItem, SupUnits, countriesItem, ExchangeRate, CurrencyItem, genericItem } from 'app/model/api-model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StateResponse } from "app/services/declaration-state.service";
import { Contact } from 'app/main/apps/contacts/contact.model';
import { InvoiceParsingResultsModel } from 'app/main/apps/customer-invoice-parsing/_models';
import { Declarant } from 'app/core/user/user.model';
import { LoginResult } from 'app/main/apps/attachments/attachments.service';

export interface ManualPrintType {
    document: string;
    documentType: string;
}
export interface DeclarationState {
    previous: StateResponse;
    current: StateResponse;
}
export interface EPaymentSignal {
    declarations?: any[];
    docType?: string;
}

export interface CodeDescParams {
    type: string; //code, description, null
    index: number; //goodsIndex
    product: ProductNode;
    code: string;
    description: string;
    updateBoth?: boolean;
    showDialog?: boolean;
}
export interface CustomsItem {
    customsRefNumber: string;
    customsName: string;
    type: string; //exit, export, import, entry
    customsTitle: string; //Εξοδου, Εισοδου, Αναχωρησης, Προορισμου....
}

export type DocForms = 'all' | 'eur1' | 'inf4' | 'agrcert' | 'atr' | 'cmr';

export interface DeclarationStates {
    EXP_DECL?: DeclarationState;
    IMP_DECL?: DeclarationState;
    ICS_DETE?: DeclarationState;
    ARR_AT_EXIT?: DeclarationState;
    T2L?: DeclarationState;
    POUS?: DeclarationState;
    TIR?: DeclarationState;
    DAO?: DeclarationState;
    DAO_RECEIVE?: DeclarationState;
    DEFK?: DeclarationState;
}

export interface TotalItems {
    EXP_DECL?: number;
    IMP_DECL?: number;
    T2L?: number;
    POUS?: number;
    TIR?: number;
    DAO?: number;
    DEFK?: number;
}

export interface DeclarationForms {
    EXP_DECL?: FormGroup;
    IMP_DECL?: FormGroup;
    ICS_DETE?: FormGroup;
    ARR_AT_EXIT?: FormGroup;
    T2L?: FormGroup;
    POUS?: FormGroup;
    TIR?: FormGroup;
    DAO?: FormGroup;
    DAO_RECEIVE?: FormGroup;
    DEFK?: FormGroup;
}
export interface SupplementaryUnits {
    EXP_DECL?: SupUnits[];
    IMP_DECL?: SupUnits[];
    DEFK?: SupUnits[];
}
export interface FormDisabledItem {
    EXP_DECL?: boolean;
    IMP_DECL?: boolean;
    ICS_DETE?: boolean;
    ARR_AT_EXIT?: boolean;
    T2L?: boolean;

    POUS?: boolean;
    TIR?: boolean;
    DAO?: boolean;
    DAO_RECEIVE?: boolean;
    DEFK?: boolean;
}

export interface CurrentDeclarant {
    declarant: Declarant;
    onInit: boolean;
}

export interface LrnItem {
    declarantId: string;
    initialLRN: number;
}
@Injectable({
    providedIn: 'root'
})
export class MainBroadcasterService {
    loading$: Observable<boolean>;
    declarationMore$: Observable<void>;
    declarationSecurity$: Observable<void>;
    declarationForms$: Observable<DeclarationForms>;
    declarationStates$: Observable<DeclarationStates>;
    activeSubdomain$: Observable<string>;
    printPage$: Observable<boolean>;
    reportProgressResponse$: Observable<StateResponse>;
    themeSettings$: Observable<any>;
    declarationSettings$: Observable<any>;
    printSettings$: Observable<PrintSettingsModel[]>;
    manualPrint$: Observable<ManualPrintType>;
    domecticCustomers$: Observable<Contact[]>;
    internationalCustomers$: Observable<Contact[]>;
    carriers$: Observable<Contact[]>;
    declarants$: Observable<Declarant[]>;
    currentDeclarant$: Observable<CurrentDeclarant>;
    defaultDeclarant$: Observable<Declarant>;
    newDeclarationFlag$: Observable<number>;
    modifyDeclaration$: Observable<void>;
    cancelDeclaration$: Observable<void>;
    cancelDeclarationEdit$: Observable<void>;
    codeDescParms$: Observable<CodeDescParams>;
    setCustoms$: Observable<CustomsItem>;
    totalItems$: Observable<TotalItems>;
    eur1Form$: Observable<OriginCertModel>;
    inf4Form$: Observable<OriginCertModel>;

    atrForm$: Observable<OriginCertModel>;
    agrCertForm$: Observable<AgrCertModel>;
    cmrForm$: Observable<CmrModel>;
    supUnits$: Observable<SupplementaryUnits>;
    formDisabled$: Observable<FormDisabledItem>;
    resetForms$: Observable<{ [key in DocForms]?: boolean }>;
    updateOtherForms$: Observable<boolean>;
    countries$: Observable<countriesItem[]>;
    va18$: Observable<void>;
    revertToPrevious$: Observable<void>;

    customerInvoiceData$: Observable<InvoiceParsingResultsModel>
    ePayment$: Observable<EPaymentSignal>
    remittance$: Observable<ePaymentResponseModel>

    //used on transport-cross-border
    terminateTransAtDepId$: Observable<void>;
    tirFollows$: Observable<void>;
    showPrint$: Observable<boolean>;

    ratesValid$: Observable<{ from: string, until: string }>
    exchangeRate$: Observable<ExchangeRate>

    lrns$: Observable<LrnItem[]>;

    totalInvoiceAmount$: Observable<number>;
    icisUser$: Observable<LoginResult>;
    isLoginInProgress$: Observable<boolean>;

    currencies$: Observable<genericItem[]>;
    allExchangeRates$: Observable<{ rates: CurrencyItem[], valid_from: string, valid_until: string }>
    resetTariffCalculate$: Observable<void>;
    openAsTemplate$: Observable<void>;


    public accountingDetectChanges: Subject<void> = new Subject<void>();

    private loading: BehaviorSubject<boolean>;
    //activeSubdomain made public used in AteleiaModule facory
    public activeSubdomain: BehaviorSubject<string>;
    private reportProgressResponse = new BehaviorSubject<StateResponse>(null);
    private declarationMore = new Subject<void>();
    private declarationSecurity = new Subject<void>();
    private printPage = new Subject<boolean>();
    private themeSettings = new BehaviorSubject<any>(null);
    private declarationSettings = new BehaviorSubject<any>(null);
    private printSettings = new BehaviorSubject<PrintSettingsModel[]>(null);
    private manualPrint = new Subject<ManualPrintType>();
    private declarationForms: BehaviorSubject<DeclarationForms>;
    private declarationStates: BehaviorSubject<DeclarationStates>;
    private domecticCustomers: BehaviorSubject<Contact[]>;
    private internationalCustomers: BehaviorSubject<Contact[]>;
    private carriers: BehaviorSubject<Contact[]>;
    private declarants: BehaviorSubject<Declarant[]>;
    private currentDeclarant: BehaviorSubject<CurrentDeclarant>;
    private defaultDeclarant: BehaviorSubject<Declarant>;
    private newDeclarationFlag: BehaviorSubject<number>;
    private modifyDeclaration: Subject<void>;
    private cancelDeclaration: Subject<void>;
    private cancelDeclarationEdit: Subject<void>;
    private codeDescParms: BehaviorSubject<CodeDescParams>;
    private setCustoms: BehaviorSubject<CustomsItem>;
    private totalItems: BehaviorSubject<TotalItems>;
    private eur1Form: BehaviorSubject<OriginCertModel | undefined>;
    private inf4Form: BehaviorSubject<OriginCertModel | undefined>;
    private atrForm: BehaviorSubject<OriginCertModel | undefined>;
    private agrCertForm: BehaviorSubject<AgrCertModel | undefined>;
    private cmrForm: BehaviorSubject<CmrModel | undefined>;
    private supUnits: BehaviorSubject<SupplementaryUnits>;
    private formDisabled: BehaviorSubject<FormDisabledItem>;
    private resetForms: BehaviorSubject<{ [key: string]: boolean }>;
    private updateOtherForms: BehaviorSubject<boolean>;
    private countries: BehaviorSubject<countriesItem[]>;
    private va18: Subject<void>;
    private terminateTransAtDepId: Subject<void>;
    private tirFollows: Subject<void>;
    private revertToPrevious: Subject<void>;
    private customerInvoiceData: Subject<InvoiceParsingResultsModel>
    private ePayment: Subject<EPaymentSignal>
    private remittance: BehaviorSubject<ePaymentResponseModel>;
    private showPrint: BehaviorSubject<boolean>;
    private ratesValid: BehaviorSubject<{ from: string, until: string }>;
    private exchangeRate: BehaviorSubject<ExchangeRate>
    private lrns: BehaviorSubject<LrnItem[]>;

    private icisUser: BehaviorSubject<LoginResult>;
    private isLoginInProgress: BehaviorSubject<boolean>;

    private currencies: BehaviorSubject<genericItem[]>;

    private allExchangeRates: BehaviorSubject<{ rates: CurrencyItem[], valid_from: string, valid_until: string }>
    private resetTariffCalculate: Subject<void>;
    private openAsTemplate: Subject<void>;


    totalInvoiceAmount: BehaviorSubject<number>


    constructor() {
        const states = {
            EXP_DECL: {
                previous: null,
                current: { stateCode: 'Pre-Submitted' }
            },
            IMP_DECL: {
                previous: null,
                current: { stateCode: 'Pre-Submitted' }
            },
            ICS_DETE: {
                previous: null,
                current: { stateCode: 'Pre-Submitted' }
            },
            ARR_AT_EXIT: {
                previous: null,
                current: { stateCode: 'Pre-Submitted' }
            },
            T2L: { previous: null, current: { stateCode: 'Pre-Submitted' } },
            POUS: { previous: null, current: { stateCode: 'Pre-Submitted' } },
            TIR: { previous: null, current: { stateCode: 'Pre-Submitted' } },
            DAO: { previous: null, current: { stateCode: 'Pre-Submitted' } },
            DAO_RECEIVE: { previous: null, current: { stateCode: 'Pre-Submitted' } },
            DEFK: { previous: null, current: { stateCode: 'Pre-Submitted' } }
        };

        const forms = {
            EXP_DECL: null,
            IMP_DECL: null,
            ICS_DETE: null,
            ARR_AT_EXIT: null,
            T2L: null,
            POUS: null,
            TIR: null,
            DAO: null,
            DAO_RECEIVE: null,
            DEFK: null
        };

        const totalItems = {
            EXP_DECL: 0,
            IMP_DECL: 0,
            T2L: 0,
            POUS: 0,
            TIR: 0,
            DAO: 0,
            DEFK: 0
        };
        const supUnits = {
            EXP_DECL: [],
            IMP_DECL: [],
            DEFK: []
        };
        const formDisabled = {
            EXP_DECL: null,
            IMP_DECL: null,
            ICS_DETE: null,
            ARR_AT_EXIT: null,
            T2L: null,
            POUS: null,
            TIR: null,
            DAO: null,
            DAO_RECEIVE: null,
            DEFK: null
        };
        this.loading = new BehaviorSubject(false);
        this.activeSubdomain = new BehaviorSubject(null);
        this.domecticCustomers = new BehaviorSubject([]);
        this.internationalCustomers = new BehaviorSubject([]);
        this.carriers = new BehaviorSubject([]);
        this.declarants = new BehaviorSubject([]);
        this.currentDeclarant = new BehaviorSubject(null);
        this.defaultDeclarant = new BehaviorSubject(null);
        this.newDeclarationFlag = new BehaviorSubject(0);
        this.modifyDeclaration = new Subject();
        this.cancelDeclaration = new Subject();
        this.cancelDeclarationEdit = new Subject();
        this.codeDescParms = new BehaviorSubject(null);
        this.setCustoms = new BehaviorSubject(null);
        this.declarationStates = new BehaviorSubject(states);
        this.declarationForms = new BehaviorSubject(forms);
        this.totalItems = new BehaviorSubject(totalItems);
        this.eur1Form = new BehaviorSubject(null);
        this.inf4Form = new BehaviorSubject(null);
        this.atrForm = new BehaviorSubject(null);
        this.agrCertForm = new BehaviorSubject(null);
        this.cmrForm = new BehaviorSubject(null);
        this.supUnits = new BehaviorSubject(supUnits);
        this.formDisabled = new BehaviorSubject(formDisabled);
        this.resetForms = new BehaviorSubject(null);
        this.updateOtherForms = new BehaviorSubject(true);
        this.countries = new BehaviorSubject([]);
        this.va18 = new Subject();
        this.revertToPrevious = new Subject();

        this.terminateTransAtDepId = new Subject();
        this.tirFollows = new Subject();
        this.customerInvoiceData = new Subject();
        this.ePayment = new Subject();
        this.remittance = new BehaviorSubject(null);
        this.showPrint = new BehaviorSubject(false);
        this.ratesValid = new BehaviorSubject({ from: null, until: null });
        this.exchangeRate = new BehaviorSubject({ currency: null, previousCurrency: null, retrievedRate: null, currentRate: null, changedManually: false });
        this.lrns = new BehaviorSubject(null);
        this.totalInvoiceAmount = new BehaviorSubject(null);
        this.icisUser = new BehaviorSubject({ isLoggedIn: false, user: { name: null, afm: null } });
        this.isLoginInProgress = new BehaviorSubject(false);
        this.currencies = new BehaviorSubject([]);
        this.allExchangeRates = new BehaviorSubject(null);
        this.resetTariffCalculate = new Subject();
        this.openAsTemplate = new Subject();


        this.loading$ = this.loading.asObservable();
        this.activeSubdomain$ = this.activeSubdomain.asObservable();
        this.reportProgressResponse$ = this.reportProgressResponse.asObservable();
        this.declarationMore$ = this.declarationMore.asObservable();
        this.declarationSecurity$ = this.declarationSecurity.asObservable();
        this.declarationForms$ = this.declarationForms.asObservable();
        this.declarationStates$ = this.declarationStates.asObservable();
        this.printPage$ = this.printPage.asObservable();
        this.themeSettings$ = this.themeSettings.asObservable();
        this.declarationSettings$ = this.declarationSettings.asObservable();
        this.printSettings$ = this.printSettings.asObservable();
        this.manualPrint$ = this.manualPrint.asObservable();
        this.domecticCustomers$ = this.domecticCustomers.asObservable();
        this.internationalCustomers$ = this.internationalCustomers.asObservable();
        this.carriers$ = this.carriers.asObservable();
        this.declarants$ = this.declarants.asObservable();
        this.currentDeclarant$ = this.currentDeclarant.asObservable();
        this.defaultDeclarant$ = this.defaultDeclarant.asObservable();
        this.newDeclarationFlag$ = this.newDeclarationFlag.asObservable();
        this.modifyDeclaration$ = this.modifyDeclaration.asObservable();
        this.cancelDeclaration$ = this.cancelDeclaration.asObservable();
        this.cancelDeclarationEdit$ = this.cancelDeclarationEdit.asObservable();
        this.codeDescParms$ = this.codeDescParms.asObservable();
        this.totalItems$ = this.totalItems.asObservable();
        this.setCustoms$ = this.setCustoms.asObservable();
        this.eur1Form$ = this.eur1Form.asObservable();
        this.inf4Form$ = this.inf4Form.asObservable();
        this.agrCertForm$ = this.agrCertForm.asObservable();
        this.cmrForm$ = this.cmrForm.asObservable();
        this.atrForm$ = this.atrForm.asObservable();
        this.supUnits$ = this.supUnits.asObservable();
        this.formDisabled$ = this.formDisabled.asObservable();
        this.resetForms$ = this.resetForms.asObservable();
        this.updateOtherForms$ = this.updateOtherForms.asObservable();
        this.countries$ = this.countries.asObservable();
        this.va18$ = this.va18.asObservable();
        this.terminateTransAtDepId$ = this.terminateTransAtDepId.asObservable();
        this.tirFollows$ = this.tirFollows.asObservable();
        this.revertToPrevious$ = this.revertToPrevious.asObservable();
        this.customerInvoiceData$ = this.customerInvoiceData.asObservable();
        this.ePayment$ = this.ePayment.asObservable()
        this.remittance$ = this.remittance.asObservable()
        this.showPrint$ = this.showPrint.asObservable()
        this.ratesValid$ = this.ratesValid.asObservable()
        this.exchangeRate$ = this.exchangeRate.asObservable()
        this.lrns$ = this.lrns.asObservable()
        this.totalInvoiceAmount$ = this.totalInvoiceAmount.asObservable()
        this.icisUser$ = this.icisUser.asObservable()
        this.isLoginInProgress$ = this.isLoginInProgress.asObservable()
        this.currencies$ = this.currencies.asObservable()
        this.allExchangeRates$ = this.allExchangeRates.asObservable()
        this.resetTariffCalculate$ = this.resetTariffCalculate.asObservable()
        this.openAsTemplate$ = this.openAsTemplate.asObservable()
    }

    updateActiveSubdomain(subdomain: string) {
        console.log('brdService updateActiveSubdomain', subdomain);

        this.activeSubdomain.next(subdomain);

    }
    updateResponse(response: StateResponse) {
        console.log('brdService updateResponse', response);

        this.reportProgressResponse.next(response);
    }
    updateDeclarationMore() {
        console.log('brdService updateDeclarationMore');

        this.declarationMore.next();
    }

    updateDeclarationSecurity() {
        console.log('brdService updateDeclarationSecurity');

        this.declarationSecurity.next()
    }
    updateDeclarationForms(form: DeclarationForms) {
        // console.trace('brdService updateDeclarationForms', form);
        console.log('brdService updateDeclarationForms', form);

        const declarationForms = this.declarationForms.getValue();

        for (const [k, v] of Object.entries(form)) {
            declarationForms[k] = v;
        }

        this.declarationForms.next(declarationForms);

        console.log('brdService updateDeclarationForms all', this.declarationForms.value);
    }

    updateDeclarationStates(state: DeclarationStates) {
        console.log('brdService updateDeclarationStates', state);

        //console.trace("notify updateDeclarationStates")
        const declarationStates = this.declarationStates.getValue();
        console.log('notify boradcaster DeclarationState in state', state);
        for (const [k, v] of Object.entries(state)) {
            console.log('notify boradcaster DeclarationState key', k);

            // console.log('notify boradcaster DeclarationState current state', declarationStates[k]['current']?.stateCode);
            // console.log('notify boradcaster DeclarationState in state', v.stateCode);

            // eslint-disable-next-line no-prototype-builtins
            if (!v.hasOwnProperty('error') && declarationStates[k]['current'].stateCode !== v.stateCode) {
                declarationStates[k]['previous'] = declarationStates[k]['current'];
                declarationStates[k]['current'] = v;


                console.log('notify boradcaster DeclarationState ' + k + ' on Broadcaster: ', this.declarationStates.getValue()[k]);

                this.declarationStates.next(declarationStates);

            }
        }


        console.log('DECLARATIONSTATE', this.declarationStates.value);
    }

    updateThemeSettings(settings: any) {
        console.log('brdService updatePrintSettings', settings);

        this.themeSettings.next(settings);
    }
    updatePrintSettings(settings: PrintSettingsModel[]) {
        console.log('brdService updatePrintSettings', settings);

        this.printSettings.next(settings);
    }
    updateDeclarationSettings(settings: any) {
        console.log('brdService updatePrintSettings', settings);

        this.declarationSettings.next(settings);
    }
    sendPrintPage(physical: boolean) {
        console.log('brdService sendPrintPage');

        this.printPage.next(physical);
    }

    updateDomesticCustomers(domestic: Contact[]) {
        console.log('brdService updateDomesticCustomers', domestic);

        this.domecticCustomers.next(domestic);
    }
    updateInternationalCustomers(international: Contact[]) {
        console.log('brdService updateInternationalCustomers', international);

        this.internationalCustomers.next(international);
    }
    updateCarriers(carriers: Contact[]) {
        console.log('brdService updateCarriers', carriers);

        this.carriers.next(carriers);
    }
    updateDeclarants(declarants: Declarant[]) {
        console.log('brdService updateDeclarants', declarants);

        this.declarants.next(declarants);
    }
    updateCurrentDeclarant(declarant: Declarant, onInit = false) {
        console.trace('brdService updateCurrentDeclarant', { declarant, onInit });

        this.currentDeclarant.next({ declarant, onInit });
    }
    updateDefaultDeclarant(declarant: Declarant) {
        console.log('brdService updateDefaultDeclarant', declarant);

        this.defaultDeclarant.next(declarant);
    }


    /**
     * Declaration Flag is a switch that always defaults to 0 which means 'Load LocalStorage
     * The values that it takes is 
     * 0 for Load LocalStorage,
     * 1 for New Form, 
     * 2 for Load Draft, 
     * 3 for Load Template,
     * 4 for Load already submitted Document,
     * 5 Patch TIR w/ Export Declaration Values
     * Resetting of the starting value is made on the declarant component which is the last one
     * that uses the flag value and then resets it
     */
    updateDeclarationFlag(action: number) {
        console.log('brdService updateDeclarationFlag', action);

        this.newDeclarationFlag.next(action);
    }

    /**
     * Modify and Cancel Decl are used in the toolbar as an instant switch to send the modify or cancel signal
     * to the active subscriber, which is the active declaration firm each time
     */
    sendModifyDeclaration() {
        console.log('brdService sendModifyDeclaration');

        this.modifyDeclaration.next();
    }

    sendCancelDeclaration() {
        console.log('brdService sendCancelDeclaration');

        this.cancelDeclaration.next();
    }

    cancelModifications() {
        console.log('brdService cancelModifications');

        this.cancelDeclarationEdit.next()
    }

    revertToPreviousState() {
        console.log('brdService updaterevertToPrevious');


        this.revertToPrevious.next()
    }
    updateCodeDesc(params: CodeDescParams) {
        console.log('brdService updateCodeDesc', params);

        console.log('updateCodeDesc', params);
        this.codeDescParms.next(params);
    }

    updateTotalItems(items: TotalItems) {
        console.log('brdService updateTotalItems', items);

        const totalItems = this.totalItems.getValue();

        for (const [k, v] of Object.entries(items)) {
            totalItems[k] = v;
        }

        this.totalItems.next(totalItems);

        console.log('TOTALITEMS', this.totalItems.value);
    }
    updateFormDisabled(disabled: FormDisabledItem) {
        //  console.trace('brdService updateFormDisabled');
        console.log('brdService updateFormDisabled', disabled);

        const formDisabled = this.formDisabled.getValue();

        for (const [k, v] of Object.entries(disabled)) {
            formDisabled[k] = v;
        }

        this.formDisabled.next(formDisabled);

        console.log('FORMDISABLED', this.formDisabled.value);
    }
    /**
     *
     * @param customs
     * setCustoms is a switch subject that gets its value from the customs-list component
     * and it resets on declaration component. It is used to pass the customs value from the customs dialog
     * to the declaration and update the respective customs
     */
    updateCustoms(customs: CustomsItem) {
        console.log('brdService updateCustoms', customs);

        this.setCustoms.next(customs);
    }

    updateEur1Form(eur1Form: OriginCertModel) {
        console.log('brdService updateEur1Form', eur1Form);

        this.eur1Form.next(eur1Form);
    }
    updateInf4Form(inf4Form: OriginCertModel) {
        console.log('brdService updateInf4Form', inf4Form);

        this.inf4Form.next(inf4Form);
    }
    updateAgrCertForm(agrCertForm: AgrCertModel) {
        console.log('brdService updateAgrCertForm', agrCertForm);

        this.agrCertForm.next(agrCertForm);
    }
    updateCmrForm(cmrForm: CmrModel) {
        console.log('brdService updateCmrForm', cmrForm);

        this.cmrForm.next(cmrForm);
    }
    updateAtrForm(atrForm: OriginCertModel) {
        console.log('brdService updateAtrForm', atrForm);

        this.atrForm.next(atrForm);
    }

    updateSupUnits(units: SupplementaryUnits) {
        console.log('brdService updateSupUnits', units);

        const supUnits = this.supUnits.getValue();

        for (const [k, v] of Object.entries(units)) {
            supUnits[k] = v;
        }

        this.supUnits.next(supUnits);

        console.log('SUPUNITS', this.supUnits.value);
    }
    //momentary switch
    resetDocForms(doc: DocForms, reset: boolean) {
        console.log('brdService resetDocForms', doc, reset);

        this.resetForms.next({ [doc]: reset });
    }

    //When submitting a form, stateChanges causes declarationForm to update
    //other Forms (EUR1, CMR etc...), so we set this to false, while submitting,
    //and reverting it to true when formsubmission has finished
    updateOtherFormsValue(value: boolean) {
        console.log('brdService updateOtherFormsValue', value);

        this.updateOtherForms.next(value);
    }

    updateCountries(countries: countriesItem[]) {
        console.log('brdService updateCountries', countries);

        this.countries.next(countries)
    }

    updateTerminateTrasAtDep() {
        console.log('brdService updateTerminateTrasAtDep');

        this.terminateTransAtDepId.next();
    }
    updateTirFollows() {
        console.log('brdService updateTirFollows');

        this.tirFollows.next();
    }

    sendVA18() {
        console.log('brdService sendVA18');

        this.va18.next()
    }
    updateLoading(value: boolean) {
        console.log('brdService updateLoading', value);

        this.loading.next(value)
    }

    updateCustomerInvoiceDetails(form: InvoiceParsingResultsModel) {
        console.log('brdService updateCustomerInvoiceDetails', form);

        this.customerInvoiceData.next(form)
    }

    updateEPayment(data?: EPaymentSignal) {
        console.log('brdService updateEPayment', data);

        this.ePayment.next(data);
    }
    updateRemittance(data: ePaymentResponseModel) {
        console.log('brdService updateRemittance', data);

        this.remittance.next(data)
    }
    updateManualPrint(value: ManualPrintType) {
        console.log("brdService updateManualPrint", value)
        this.manualPrint.next(value)
    }
    updateShowPrint(value: boolean) {
        console.log("brdService updateShowPrint", value)
        this.showPrint.next(value)
    }

    updateRatesValid(value: { from: string, until: string }) {

        console.log("brdService updateRatesValid", value)
        this.ratesValid.next(value)

    }
    updateExchangeRate(value: Partial<ExchangeRate>) {
        console.log("brdService updateExchangeRate", value)
        const currentValue = this.exchangeRate.getValue();
        this.exchangeRate.next({ ...currentValue, ...value });
    }

    resetExchangeRate() {
        console.log("brdService resetExchangeRate")
        this.exchangeRate.next({ currency: null, previousCurrency: null, retrievedRate: null, currentRate: null, changedManually: false });
    }

    updateLRNs(value: LrnItem[]) {
        console.log("brdService updateLRNs", value)
        this.lrns.next(value)
    }
    updateIcisUser(value: LoginResult) {
        console.trace("brdService updateIcisUser", value)
        this.icisUser.next(value)
    }

    updateIsLoginInProgress(value: boolean) {
        console.trace("brdService updateIsLoginInProgress", value)
        this.isLoginInProgress.next(value)
    }

    updateCurrencies(value: genericItem[]) {
        console.trace("brdService updateCurrencies", value)
        this.currencies.next(value)
    }

    updateAllExchangeRates(value: { rates: CurrencyItem[], valid_from: string, valid_until: string }) {
        console.trace("brdService updateAllExchangeRates", value)
        this.allExchangeRates.next(value)
    }

    resetTariffCalcLoading() {
        console.trace("brdService resetTariffCalculate")
        this.resetTariffCalculate.next()
    }

    updateOpenAsTemplate() {
        console.trace("brdService updateOpenAsTemplate")
        this.openAsTemplate.next()
    }
}
