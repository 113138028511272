import { Declarant } from 'app/core/user/user.model';
import { EPaymentModel } from './e-payment.model';

export class RemittanceModel {
    declarant?: Declarant;

    ePayment?: EPaymentModel[];


    //
    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);
        this.declarant = value?.declarant || null;
        this.ePayment = value?.ePayment || [];

    }
}
