import { Injectable } from '@angular/core';
import { TariffResults, TariffState } from 'app/main/apps/tariff-calculate/_models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImportConnectService {
    tariffCalculationResults$: Observable<TariffResults>;
    tariffCalculationState$: Observable<TariffState>;

    private tariffCalculationResults: Subject<TariffResults>;
    private tariffCalculationState: BehaviorSubject<TariffState>;

    constructor() {
        this.tariffCalculationResults = new Subject();
        this.tariffCalculationState = new BehaviorSubject({ state: null });

        this.tariffCalculationResults$ = this.tariffCalculationResults.asObservable();
        this.tariffCalculationState$ = this.tariffCalculationState.asObservable();
    }

    updateTariffCalculationResults(data: TariffResults) {
        this.tariffCalculationResults.next(data);
    }
    //loading, documents, results
    updateTariffCalculationState(state: TariffState) {
        this.tariffCalculationState.next(state);
    }
}
