import { ComplementaryConsigneesModel } from "../complementary-consignees/complementary-consignees.model";
import { DEFKContactDetailsModel } from "../contactDetails/contactDetails.model";
import { SpecialConsigneeModel } from "../special-consignee/special-consignee.model";
import { VesselRegistrationDetailsModel } from "../vessel-registration-details/vessel-registration-details.model";

export class TaxesDeclarationConsigneeModel {
    ConsigneeType?: string;
    ConsigneeIdentificationType?: string;
    ConsigneeIdentification?: string;
    ContactDetails?: DEFKContactDetailsModel;
    SpecialConsignee?: SpecialConsigneeModel;
    VesselRegistrationDetails?: VesselRegistrationDetailsModel;
    ComplementaryConsignees?: ComplementaryConsigneesModel[];
    ConsigneeObject?: {}

    constructor(value?) {
        this.ConsigneeType = value?.ConsigneeType || "0";
        this.ConsigneeIdentificationType = value?.ConsigneeIdentificationType || "1";
        this.ConsigneeIdentification = value?.ConsigneeIdentification || null;
        this.ContactDetails = value?.ContactDetails || null;
        this.SpecialConsignee = value?.SpecialConsignee || null;
        this.VesselRegistrationDetails = value?.VesselRegistrationDetails || null;
        this.ComplementaryConsignees = value?.ComplementaryConsignees || null;
        this.ConsigneeObject = value?.ConsigneeObject || null;

    }
}
