import declarationData from 'assets/declarationData.json';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { Injectable } from "@angular/core";
import { DEFKDeclarationModel } from "app/main/apps/excise/defk/_models/EF15A/declaration.model";
import { MainBroadcasterService } from "app/services/broadcaster.service";
import { lastValueFrom, map, take } from "rxjs";
import { DEFKDeclarationForm } from 'app/main/apps/excise/defk/_models/EF15A/declaration-form.model';
import { ComplementaryConsigneesModel } from 'app/main/apps/excise/defk/_models/complementary-consignees/complementary-consignees.model';
import { TaxesDeclarationModel } from 'app/main/apps/excise/defk/_models/taxesDeclaration/taxes-declaration.model';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { TaxesRowModel } from 'app/main/apps/excise/defk/_models/taxesRow/taxes-row.model';
import { NationalProductsModel } from 'app/main/apps/excise/defk/_models/taxesRow/national-products/national-products.model';
import { SupplementaryComputationInformationModel } from 'app/main/apps/excise/defk/_models/taxesRow/sup-computation/sup-computation.model';
import { TaxComputationModel } from 'app/main/apps/excise/defk/_models/taxesRow/tax-computation/tax-computation.model';
import { ReferenceDocumentsModel } from 'app/main/apps/excise/defk/_models/taxesRow/reference-docs/reference-docs.model';
import { StorageService } from 'app/core/services/storage/storage.service';
import { GoodsPrivateModel, PrivateControlsModel } from 'app/model/private-controls.model';
import { Declarant } from 'app/core/user/user.model';
import { Router } from '@angular/router';
import { PATHS } from 'app/navigation/paths';
import { OfficeService } from 'app/services/office.service';
import { BeerCalculationDataModel, BeerModel } from 'app/main/apps/excise-helper-tables/beer/_models/beer.model';

export interface BeerParameters {

    addTaricCode: string;
    productDutyRegular: number;
    productDutyReduced: number;
    eteppaContribution: number;
    ogaContribution: number;
    eteppaStampTax: number;
    vatRegular: number;
    vatReduced: number;

    //Limitations

}
@Injectable()
export class BeerDefkCreateService {

    constructor(private mainBroadcaster: MainBroadcasterService, private storageService: StorageService, private router: Router, private officeService: OfficeService) { }

    async createDefk(inputData: BeerModel, params: BeerParameters) {


        const defkForm = new FormGroup(new DEFKDeclarationForm(new DEFKDeclarationModel()))
        const defk = defkForm.value as DEFKDeclarationModel;

        const defaultDeclarant = await lastValueFrom(this.mainBroadcaster.declarants$.pipe(take(1), map(decl => decl.find(d => d.is_owner))));

        console.log('BeerDefkCreateService createDefk', inputData, params, defaultDeclarant)

        const privateCtrls = defk._PRIVATE;
        const exciseTaxesDeclaration = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclaration;

        this.setPrivateCtrls(inputData, privateCtrls, defaultDeclarant, params)

        this.setExciseTaxesDeclaration(inputData, exciseTaxesDeclaration)
        this.setDeclarant(defaultDeclarant, exciseTaxesDeclaration);
        this.setObligee(inputData, defk, privateCtrls)
        this.setConsignee(inputData, defk)
        this.setExciseTaxesRows(inputData, defk, params)


        //Set As LocalStorage
        this.storageService.setLocalObject(declarationData['DEFK'].localStorage, defk)
        this.storageService.removeLocalObject('beer');

        this.officeService.currentOffice$.pipe(take(1)).subscribe(office => {

            this.router.navigateByUrl('/blank', { skipLocationChange: true }).then(() => {
                this.router.navigate([PATHS.localDeclaration(office?.id, 'emcs', 'defk')]);
            });
        })


        console.log('BeerDefkCreateService createDefk defk', defk.Body.DeclarationOfExciseTaxesRegistration)

    }

    setPrivateCtrls(inputData: BeerModel, privateCtrls: PrivateControlsModel, defaultDeclarant: Declarant, params: BeerParameters) {
        privateCtrls.declarant = defaultDeclarant;
        privateCtrls.domain = 'EMCS';
        privateCtrls.messageOut = 'EF15A';
        privateCtrls.stateCode = 'Pre-Submitted';
        privateCtrls.subdomain = 'DEFK'
        privateCtrls.trader = inputData.customer;
        privateCtrls.beer = inputData;

        inputData.beerCalculationData.forEach((calcData, index) => {

            if (!privateCtrls.goodsPrivate[index]) {
                privateCtrls.goodsPrivate.push(new GoodsPrivateModel())
            }
        })

    }
    setExciseTaxesDeclaration(inputData: BeerModel, exciseTaxesDeclaration: TaxesDeclarationModel) {

        const calculationData = inputData.beerCalculationData
        const totalRows = calculationData.length
        const itemsPerPage = declarationData['DEFK'].itemsPerPage
        const totalDocuments = Math.ceil((totalRows - 1) / itemsPerPage) + 1;

        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so +1 is needed.
        const dd = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}-${mm}-${dd}`;

        this.mainBroadcaster.declarationSettings$.pipe(take(1)).subscribe((settings: DeclarationSettingsModel) => {

            exciseTaxesDeclaration.Fallbackflag = "0";
            exciseTaxesDeclaration.RegistrationOffice = settings.export_customs
            exciseTaxesDeclaration.DocumentState = "0"
            exciseTaxesDeclaration.DocumentNumber = "1"
            exciseTaxesDeclaration.TotalDocuments = totalDocuments.toString()
            exciseTaxesDeclaration.NumberOfRows = totalRows.toString()
            exciseTaxesDeclaration.DispatchCountry = "GR"
            exciseTaxesDeclaration.DestinationCountry = "GR"
            exciseTaxesDeclaration.ExistenceOfContainerFlag = "0"
            exciseTaxesDeclaration.ChemicalAnalysisPerformedFlag = "0"
            exciseTaxesDeclaration.ThroughSimplifiedProcedureFlag = "0"
            exciseTaxesDeclaration.PaymentMethodCode = "H"
            exciseTaxesDeclaration.CertificateIssuanceFlag = "0"
            exciseTaxesDeclaration.DeclarationTypeCode = "30"
            exciseTaxesDeclaration.CalculationOfTaxesDate = formattedDate;


            exciseTaxesDeclaration.Currency = "EUR";
            exciseTaxesDeclaration.ExchangeFactor = "1.000";
            exciseTaxesDeclaration.ChemicalAnalysisPerformedFlag = "1"





        })
    }

    setDeclarant(defaultDeclarant: Declarant, exciseTaxesDeclaration: TaxesDeclarationModel) {


        exciseTaxesDeclaration.ClearingAgent.ClearingAgentEORI = defaultDeclarant.eori;

        exciseTaxesDeclaration.ClearingAgent.ClearingAgentType = "2";
        exciseTaxesDeclaration.ClearingAgent.DeclarantObject = defaultDeclarant

    }

    setObligee(inputData: BeerModel, defk: DEFKDeclarationModel, privateCtrls) {
        const obligee = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesObligee


        obligee.ObligeeType = "7";
        obligee.ObligeeIdentificationType = "1";
        obligee.ObligeeIdentification = inputData.customer.afm;
        obligee.ObligeeObject = inputData.customer


    }

    setConsignee(inputData: BeerModel, defk: DEFKDeclarationModel) {

        const consignee = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclarationConsignee

        consignee.ConsigneeType = "0";
        consignee.ConsigneeIdentificationType = "1";
        consignee.ConsigneeIdentification = inputData.customer.afm;
        consignee.ConsigneeObject = inputData.customer;


    }

    setExciseTaxesRows(inputData: BeerModel, defk: DEFKDeclarationModel, params: BeerParameters) {
        const exciseTaxesRow = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesRow
        const calculationData = inputData.beerCalculationData


        calculationData.forEach((data, i) => {


            const rowNumber = i + 1;
            const marksNumbers = this.setMarksNumbers(data)
            const description = this.setDescription(data, inputData)


            if (exciseTaxesRow[i]) {
                exciseTaxesRow[i].ExciseTaxesDeclarationRowNumber = rowNumber.toString();
                exciseTaxesRow[i].SymbolNumbers = marksNumbers;
                //exciseTaxesRow[i].ProductDescription = description;
                exciseTaxesRow[i].TaricCode = data.taricCode;
                exciseTaxesRow[i].TaricAdditionCode = data.addTaricCode;
                exciseTaxesRow[i].TaxQuantity = data.platoPer100L.toString();
                exciseTaxesRow[i].AdditionalTaxQuantity = data.quantity.toString();
                exciseTaxesRow[i].Plato = data.plato.toString();
                exciseTaxesRow[i].CountryOfOrigin = "GR";
                exciseTaxesRow[i].GrossWeight = data.quantity.toString();
                exciseTaxesRow[i].NetWeight = data.quantity.toString();
                exciseTaxesRow[i].ConditionIdentification = "48";
                exciseTaxesRow[i].PreviousConditionIdentification = "00";
                exciseTaxesRow[i].StatisticalValue = data.statisticValue.toString();
                exciseTaxesRow[i].SupplementaryComputationInformation = this.setSupplementaryComputation(data)
                exciseTaxesRow[i].ReferenceDocuments = this.setReferenceDocuments()
                exciseTaxesRow[i].NationalProducts = this.setNationalProducts(data)
                exciseTaxesRow[i].TaxComputation = this.setTaxComputation(inputData, data, params)

            } else {
                exciseTaxesRow.push(new TaxesRowModel({
                    ExciseTaxesDeclarationRowNumber: rowNumber.toString(),
                    SymbolNumbers: marksNumbers,
                    //ProductDescription: description,
                    TaricCode: data.taricCode,
                    TaricAdditionCode: data.addTaricCode,
                    TaxQuantity: data.platoPer100L.toString(),
                    AdditionalTaxQuantity: data.quantity.toString(),
                    Plato: data.plato.toString(),
                    CountryOfOrigin: "GR",
                    GrossWeight: data.quantity.toString(),
                    NetWeight: data.quantity.toString(),
                    ConditionIdentification: "48",
                    PreviousConditionIdentification: "00",
                    StatisticalValue: data.statisticValue.toString(),
                    SupplementaryComputationInformation: this.setSupplementaryComputation(data),
                    ReferenceDocuments: this.setReferenceDocuments(),
                    NationalProducts: this.setNationalProducts(data),
                    TaxComputation: this.setTaxComputation(inputData, data, params)


                }));
            }









        })


    }

    setMarksNumbers(data: BeerCalculationDataModel) {

        let description = ''
        description += data.productName + '\n';
        description += 'Plato: ' + data.platoExact + '\n';
        description += 'Λίτρα: ' + data.quantity + '\n';

        return description;
    }
    setDescription(producer: BeerCalculationDataModel, inputData: BeerModel) {

        /*   let description = '';
  
          description = 'Προιόν Απόσταξης Διήμερου Μικρού Αποσταγματοποιού από '
  
          if (producer.grapeStems) {
  
              description += 'στέμφυλα '
          }
          else if (producer.wineGrapes) {
              description += 'σταφύλια '
  
          }
          description += 'με αλκοολικό βαθμό ' + (producer.alcoholVol * 100) + '%.'
  
          return description;
   */
    }

    setNationalProducts(data: BeerCalculationDataModel) {

        const nationalProducts: NationalProductsModel[] = [{

            NationalProductId: data.nationalProduct,
            Quantity: data.platoPer100L.toString()

        }]

        return nationalProducts
    }

    setSupplementaryComputation(data: BeerCalculationDataModel) {

        const supCompValues: SupplementaryComputationInformationModel[] = [{

            SupplementaryComputationInfo: '057',
            SupplementaryComputationValue: data.platoPer100L.toString()
        }]

        return supCompValues
    }

    setTaxComputation(inputData: BeerModel, data: BeerCalculationDataModel, params: BeerParameters) {

        const taxComputation: TaxComputationModel[] = [
            {
                "_natTypeCode": "ALC",
                "_taxFreeCode": "205",
                "_taxPaidCode": "204",
                "NationalAccountingCode": "204",
                "SuspensionFlag": "0",
                "AmountOfTaxes": data.efk.toString(),
                "PaymentMethodCode": "H",
                "TaxBase": data.platoPer100L.toString(),
                "TaxRate": inputData.productDuty.toString()
            },
            {
                "_natTypeCode": "ETP",
                "_taxFreeCode": "719",
                "_taxPaidCode": "718",
                "NationalAccountingCode": "718",
                "SuspensionFlag": "0",
                "AmountOfTaxes": data.eteppaContrib.toString(),
                "PaymentMethodCode": "H",
                "TaxBase": data.efk.toString(),
                "TaxRate": params.eteppaContribution.toString()
            },
            {
                "_natTypeCode": "XAR",
                "_taxFreeCode": "427",
                "_taxPaidCode": "426",
                "NationalAccountingCode": "426",
                "SuspensionFlag": "0",
                "AmountOfTaxes": data.eteppaStampTax.toString(),
                "PaymentMethodCode": "H",
                "TaxBase": data.eteppaContrib.toString(),
                "TaxRate": params.eteppaStampTax.toString()
            },
            {
                "_natTypeCode": "OGA",
                "_taxFreeCode": "429",
                "_taxPaidCode": "428",
                "NationalAccountingCode": "428",
                "SuspensionFlag": "0",
                "AmountOfTaxes": data.ogaContrib.toString(),
                "PaymentMethodCode": "H",
                "TaxBase": data.eteppaStampTax.toString(),
                "TaxRate": params.ogaContribution.toString()
            },

            {
                "_natTypeCode": "VAT",
                "_taxFreeCode": "331",
                "_taxPaidCode": "330",
                "NationalAccountingCode": "330",
                "SuspensionFlag": "0",
                "AmountOfTaxes": data.vat.toString(),
                "PaymentMethodCode": "H",
                "TaxBase": data.vatTaxBase.toString(),
                "TaxRate": inputData.vatRate.toString()
            }
        ]

        return taxComputation;
    }
    setReferenceDocuments() {

        const refDocs: ReferenceDocumentsModel[] = [

            {
                ReferenceDocumentId: "ΕΦ12",
                ReferenceDocumentNumber: null
            },
            {
                ReferenceDocumentId: "11",
                ReferenceDocumentNumber: null
            },
            {
                ReferenceDocumentId: "17",
                ReferenceDocumentNumber: null
            },
            {
                ReferenceDocumentId: "19",
                ReferenceDocumentNumber: null
            },
            {
                ReferenceDocumentId: "1",
                ReferenceDocumentNumber: null

            }

        ]



        return refDocs;
    }

    setValidators(defkForm: FormGroup<DEFKDeclarationForm>) {
        const exciseTaxesDeclaration = defkForm.get('Body').get('DeclarationOfExciseTaxesRegistration').get('ExciseTaxesDeclaration') as FormGroup;

        exciseTaxesDeclaration.get('InvoiceAmount').setValidators(Validators.required);
        exciseTaxesDeclaration.get('GeneralChemistryDetails').setValidators(Validators.required)

        defkForm.updateValueAndValidity()

    }

}