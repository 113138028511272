import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { NctsPreAdmRefModel } from '../preadmrefar2/preadmrefar2.model';
import { NctsProDocModel } from '../prodocdc2/prodocdc2.model';
import { NctsSpeMenModel } from '../spemenmt2/spemenmt2.model';

export class T2LGooIteGdsModel {
    IteNumGDS7?: number;
    ComCodTarCodGDS10?: string;
    DecTypGDS15?: string;
    GooDesGDS23?: string;
    GooDesGDS23LNG?: string;
    GroMasGDS46?: number;
    NetMasGDS48?: number;
    CouOfDisGDS58?: string;
    PREADMREFAR2?: NctsPreAdmRefModel[];
    PRODOCDC2?: NctsProDocModel[];
    SPEMENMT2?: NctsSpeMenModel[];
    COMCODGODITM?: ComCodModel[]; //<=======FOR INTERNAL USE INLY REMOVE ON REMOVEPRIVATE
    constructor(value?) {
        if (value) {
            this.IteNumGDS7 = value.IteNumGDS7;
            this.ComCodTarCodGDS10 = value.ComCodTarCodGDS10;
            this.DecTypGDS15 = value.DecTypGDS15;
            this.GooDesGDS23 = value.GooDesGDS23;
            this.GooDesGDS23LNG = value.GooDesGDS23LNG;
            this.GroMasGDS46 = value.GroMasGDS46;
            this.NetMasGDS48 = value.NetMasGDS48;
            this.CouOfDisGDS58 = value.CouOfDisGDS58;
            this.PREADMREFAR2 = value.PREADMREFAR2;
            this.PRODOCDC2 = value.PRODOCDC2;
            this.SPEMENMT2 = value.SPEMENMT2;
            this.COMCODGODITM = value.COMCODGODITM;
        }
    }
}
