import { FormControl, Validators } from '@angular/forms';
import { TraexicarModel } from './traexicar.model';

export class TraexicarForm {
    [key: string]: FormControl;

    NamTEC1: FormControl;
    StrAndNumTEC2: FormControl;
    PosCodTEC1: FormControl;
    CitTEC1: FormControl;
    CouCodTEC1: FormControl;
    TRAEXICARLNG: FormControl;
    TINTEC2: FormControl;
    CarrierObject: FormControl;

    constructor(value: TraexicarModel) {
        this.NamTEC1 = new FormControl(value.NamTEC1, { updateOn: 'blur' });
        this.StrAndNumTEC2 = new FormControl(value.StrAndNumTEC2, {
            updateOn: 'blur'
        });
        this.PosCodTEC1 = new FormControl(value.PosCodTEC1, {
            updateOn: 'blur'
        });
        this.CitTEC1 = new FormControl(value.CitTEC1, { updateOn: 'blur' });
        this.CouCodTEC1 = new FormControl(value.CouCodTEC1, {
            updateOn: 'blur'
        });
        this.TRAEXICARLNG = new FormControl(value.TRAEXICARLNG, {
            updateOn: 'blur'
        });
        this.TINTEC2 = new FormControl(value.TINTEC2, {
            updateOn: 'blur',
            validators: Validators.required
        });
        this.CarrierObject = new FormControl(value.CarrierObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
