import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { TransactionLineItemForm } from "app/main/businesses/transactions/_models/transaction-line-item/transaction-line-item-form.model";
import { TransactionLineItemModel } from "app/main/businesses/transactions/_models/transaction-line-item/transaction-line-item.model";
import { TransactionModel } from "app/main/businesses/transactions/_models/transaction/transaction.model";

export class TransactionForm {

    [key: string]: AbstractControl;
    businessId = new FormControl();
    date = new FormControl();
    description = new FormControl();
    anchorLineItem: FormGroup;
    lineItems: FormArray;
    notes = new FormControl();

    constructor(value: TransactionModel) {

        this.businessId.setValue(value.businessId)
        this.date.setValue(value.date)
        this.description.setValue(value.description)
        this.anchorLineItem = new FormGroup(new TransactionLineItemForm(new TransactionLineItemModel(value.anchorLineItem)));
        this.lineItems = new FormArray([]);
        this.notes.setValue(value.notes)

        if (value.lineItems) {
            value.lineItems.forEach((lineItem: TransactionLineItemModel) => {

                this.lineItems.push(new FormGroup(new TransactionLineItemForm(new TransactionLineItemModel(lineItem))))

            })
        }
        else {
            this.lineItems.push(new FormGroup(new TransactionLineItemForm(new TransactionLineItemModel())))

        }
    }
}