import { Contact } from 'app/main/apps/contacts/contact.model';
export class TraConexModel {
    NamEX27?: string;
    StrAndNumEX222?: string;
    PosCodEX223?: string;
    CitEX224?: string;
    CouEX225?: string;
    NADLNGGTEX?: string;
    TINEX259?: string;
    ExporterObject?: Contact;

    constructor(value?) {
        this.NamEX27 = value?.NamEX27 || null;
        this.StrAndNumEX222 = value?.StrAndNumEX222 || null;
        this.PosCodEX223 = value?.PosCodEX223 || null;
        this.CitEX224 = value?.CitEX224 || null;
        this.CouEX225 = value?.CouEX225 || null;
        this.NADLNGGTEX = value?.NADLNGGTEX || null;
        this.TINEX259 = value?.TINEX259 || null;
        this.ExporterObject = value?.ExporterObject || null;
    }
}
