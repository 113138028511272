import { FormControl, Validators } from '@angular/forms';
import { ImpcusoffModel } from './impcusoff.model';

export class ImpcusoffForm {
    [key: string]: FormControl;
    _countryCode = new FormControl();
    RefNumIMPCUSOFF = new FormControl();

    constructor(value: ImpcusoffModel) {
        this._countryCode.setValue(value._countryCode);
        this.RefNumIMPCUSOFF.setValue(value.RefNumIMPCUSOFF);

        // this.RefNumEXT1.setValidators([Validators.required]);
    }
}
