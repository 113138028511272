import { SubmittingOperatorModel } from "../../../dao/_models/submittingOperator/submittingOperator.model";
import { TaxesDeclarationModel } from "../taxesDeclaration/taxes-declaration.model";
import { TaxesDeclarationConsigneeModel } from "../taxesDeclarationConsigee/taxes-declaration-consignee.model";
import { TaxesDeclarationConsignorModel } from "../taxesDeclarationConsignor/taxes-declaration-consignor.model";
import { TaxesObligeeModel } from "../taxesObligee/taxes-obligee.model";
import { TaxesRowModel } from "../taxesRow/taxes-row.model";

export class EF13ABodyModel {

    DeclarationOfExciseTaxesAmendment: DeclarationOfExciseTaxesAmendmentType;

    constructor(value?) {

        console.log("EF13ABodyModel value", value)

        this.DeclarationOfExciseTaxesAmendment = value?.DeclarationOfExciseTaxesAmendment || new DeclarationOfExciseTaxesAmendmentType()
    }

}



export class DeclarationOfExciseTaxesAmendmentType {




    SubmittingOperator?: SubmittingOperatorModel;
    ExciseTaxesDeclaration?: TaxesDeclarationModel;
    ExciseTaxesDeclarationConsignor?: TaxesDeclarationConsignorModel;
    ExciseTaxesObligee?: TaxesObligeeModel;
    ExciseTaxesDeclarationConsignee?: TaxesDeclarationConsigneeModel;
    ExciseTaxesRow?: TaxesRowModel[];

    constructor(value?) {

        this.SubmittingOperator = value?.SubmittingOperator;
        this.ExciseTaxesDeclaration = value?.ExciseTaxesDeclaration;
        this.ExciseTaxesDeclarationConsignor = value?.ExciseTaxesDeclarationConsignor;
        this.ExciseTaxesObligee = value?.ExciseTaxesObligee;
        this.ExciseTaxesDeclarationConsignee = value?.ExciseTaxesDeclarationConsignee;
        this.ExciseTaxesRow = value?.ExciseTaxesRow;
    }




}