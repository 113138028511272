import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VehiclesForm } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles-form.model';

@Component({
    selector: 'cars-header-reductions',
    templateUrl: './cars-header-reductions.component.html',
    styleUrls: ['./cars-header-reductions.component.scss']
})
export class CarsHeaderReductionsComponent {

    @Input() vehiclesForm: FormGroup<VehiclesForm>
    // vehiclesForm$: Observable<FormGroup<VehiclesForm>>
    constructor() {
    }

}
