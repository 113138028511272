//===============================================================
// this formgroup is used only for calculation purposes
// and should not be submitted to the ICISNet
//but it should be saved in the db
//================================================================

import { FormControl, Validators } from '@angular/forms';
import { AccountingModel } from './accounting.model';

export class AccountingForm {
    [key: string]: FormControl;

    AccCurrency = new FormControl();
    AccValue = new FormControl(null, { updateOn: 'blur' });
    AccExchangeRate = new FormControl(null, { updateOn: 'blur' });
    AccResult = new FormControl();
    changedManually = new FormControl();

    constructor(value: AccountingModel) {
        this.AccCurrency.setValue(value.AccCurrency);
        this.AccValue.setValue(value.AccValue);
        this.AccExchangeRate.setValue(value.AccExchangeRate);
        this.AccResult.setValue(value.AccResult);
        this.changedManually.setValue(value.changedManually);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
