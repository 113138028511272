import { FormControl, Validators } from '@angular/forms';
import { CusoffexiextModel } from './cusoffexiext.model';

export class CusoffexiextForm {
    [key: string]: FormControl;
    _countryCode = new FormControl();
    RefNumEXT1 = new FormControl();

    constructor(value: CusoffexiextModel) {
        this._countryCode.setValue(value._countryCode);
        this.RefNumEXT1.setValue(value.RefNumEXT1);

        // this.RefNumEXT1.setValidators([Validators.required]);
    }
}
