import { EMCSVehicleInventoryModel } from "app/main/apps/excise/dao/_models/vehicle-inventory/vehinv.model";

export class DEFKVehicleDetailsModel {
    FrameNumber?: string;
    CarKind?: string;
    ImporterCodeOrBrandName?: string;
    FirstAllowanceDate?: string;
    VehicleFactoryType?: string;
    VehicleUsedFlag?: string;
    EngineCapacity?: string;
    EngineType?: string;
    FuelType?: string;
    MakeYear?: string;
    VehicleModelName?: string;
    ImportedDate?: string;
    CountryLast?: string;
    Mileage?: string;
    CarbonDioxideEmissions?: string;
    ApprovalCodeVehicle?: string;
    ApprovalCodeFactory?: string;
    ApprovalCodeType?: string;
    VehicleRebateFlag?: string;
    RebatedVehicleRegistrationPlate?: string;
    VehicleInventory?: EMCSVehicleInventoryModel[];;


    constructor(value?) {
        this.FrameNumber = value?.FrameNumber || null;
        this.CarKind = value?.CarKind || null;
        this.ImporterCodeOrBrandName = value?.ImporterCodeOrBrandName || null;
        this.FirstAllowanceDate = value?.FirstAllowanceDate || null;
        this.VehicleFactoryType = value?.VehicleFactoryType || null;
        this.VehicleUsedFlag = value?.VehicleUsedFlag || 0;
        this.EngineCapacity = value?.EngineCapacity || null;
        this.EngineType = value?.EngineType || null;
        this.FuelType = value?.FuelType || null;
        this.MakeYear = value?.MakeYear || null;
        this.VehicleModelName = value?.VehicleModelName || null;
        this.ImportedDate = value?.ImportedDate || null;
        this.CountryLast = value?.CountryLast || null;
        this.Mileage = value?.Mileage || null;
        this.CarbonDioxideEmissions = value?.CarbonDioxideEmissions || null;
        this.ApprovalCodeVehicle = value?.ApprovalCodeVehicle || null;
        this.ApprovalCodeFactory = value?.ApprovalCodeFactory || null;
        this.ApprovalCodeType = value?.ApprovalCodeType || null;
        this.VehicleRebateFlag = value?.VehicleRebateFlag || 0;
        this.RebatedVehicleRegistrationPlate = value?.RebatedVehicleRegistrationPlate || null;
        this.VehicleInventory = value?.VehicleInventory || null;

    }
}
