export class SubmittingOperatorModel {

    SubmittingOperatorIdentification: string;
    SubmittingTraderIdentification: string;

    constructor(value?) {

        this.SubmittingOperatorIdentification = value?.SubmittingOperatorIdentification || null;
        this.SubmittingTraderIdentification = value?.SubmittingTraderIdentification || null;
    }

}
