import { User } from 'app/model/api-model';

export class TraexicarModel {
    NamTEC1?: string;
    StrAndNumTEC2?: string;
    PosCodTEC1?: string;
    CitTEC1?: string;
    CouCodTEC1?: string;
    TRAEXICARLNG?: string;
    TINTEC2?: string;
    CarrierObject?: {};

    constructor(value?) {
        this.NamTEC1 = value?.NamTEC1 || null;
        this.StrAndNumTEC2 = value?.StrAndNumTEC2 || null;
        this.PosCodTEC1 = value?.PosCodTEC1 || null;
        this.CitTEC1 = value?.CitTEC1 || null;
        this.CouCodTEC1 = value?.CouCodTEC1 || null;
        this.TRAEXICARLNG = value?.TRAEXICARLNG || null;
        this.TINTEC2 = value?.TINTEC2 || null;
        this.CarrierObject = value?.CarrierObject || {};
    }
}
