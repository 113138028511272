import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { countriesItem } from 'app/model/api-model';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { Page } from 'app/services/datasource/page';
import { PaginatedDataSource } from 'app/services/datasource/paginated-datasource';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, map, mergeMap, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TaricQuery } from './taric-results-table/taric-results-table.component';
import { TreeDataSource } from './taric-tree/taric-tree.datasource';
import { Filters, ProductNode, TaricService } from './taric.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
    selector: 'app-taric',
    templateUrl: './taric.component.html',
    styleUrls: ['./taric.component.scss']
})
export class TaricComponent implements OnInit, OnDestroy {
    @ViewChild('FirstFocus') _first: ComboBoxComponent;
    @ViewChild('ref') ref;
    @ViewChild('tabContent1') tabContent1: ElementRef;
    @ViewChild('tabContent2') tabContent2: ElementRef;

    selectedIndex: number;
    filters: Filters;

    searchQuery: FormControl;

    placeholder1 = 'Χώρα Προέλευσης';
    placeholder2 = 'Χώρα Προορισμού';

    expCountryQuery: FormControl;
    destCountryQuery: FormControl;

    expCountries: countriesItem[];
    destCountries: countriesItem[];
    countries: countriesItem[];
    treeData: ProductNode[];
    productRequested: string;
    taricResultsDatasource: PaginatedDataSource<ProductNode, TaricQuery>;
    page: Page<ProductNode>;

    formDisabled$: Observable<boolean>;

    private _unsubscribeAll: Subject<void>;

    public fields: Object = {
        text: 'country_name',
        value: 'country_code'
    };

    showTabLabel = false;

    /**
     * Constructor
     *
     * @param _taricService
     * @param _fuseSidebarService
     * @param _matDialog
     */
    constructor(
        public _taricService: TaricService,
        private dbQuery: ApiService,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private cd: ChangeDetectorRef,
        private mainBroadcaster: MainBroadcasterService,
        public dialogRef: MatDialogRef<TaricComponent>
    ) {
        // Set the defaults
        this.searchQuery = new FormControl('', { updateOn: 'blur' });
        this.expCountryQuery = new FormControl('');
        this.destCountryQuery = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();

        this._taricService.getAllTaric().subscribe();
        this._taricService.updateDatasource('');
        this.filters = { exportCountry: '', destinationCountry: '' };

        const activeSubdomain$ = this.mainBroadcaster.activeSubdomain$;
        const disabled$ = this.mainBroadcaster.formDisabled$;


        this.formDisabled$ = activeSubdomain$.pipe(
            mergeMap(subdomain =>
                disabled$.pipe(map(disabled => disabled[subdomain]))
            )
        );

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        /*  this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
          this.allCustomsDataSource = value.customs[0];
          console.log(value)
      });
 */

        console.log('TARIX Taric Started');

        this.mainBroadcaster.countries$
            .pipe(take(1))
            .subscribe((data: countriesItem[]) => {
                console.log(data);
                this.countries = data;
                this.expCountries = data;
                this.destCountries = data;
            });

        this._taricService.selectedIndex.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            this.selectedIndex = value;
        });

        this._taricService.treeDataSource$.pipe(takeUntil(this._unsubscribeAll)).subscribe((treeDataSource: TreeDataSource) => {
            const treeData = treeDataSource.data;
            this.treeData = this._taricService.formatProductCode(treeData);

            console.log(this.treeData);
        });

        this.searchQuery.valueChanges
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                switchMap(searchValue => {

                    this._taricService.loading.next(true)

                    if (searchValue.length > 0) {
                        this.productRequested = searchValue;

                        const isNumber = /^\d+$/.test(searchValue);

                        if (isNumber) {
                            console.log('isNUmber: ', searchValue);
                            console.log('makingRequest: ', searchValue);

                            return this._taricService.searchByCode(searchValue, null);
                        } else {
                            //show tab first,because view is not updated with data when we unhide the data on subscribe
                            this.ref.hide = false;
                            console.log('isNOTnumber: ', searchValue);
                            const tableDataSource$ = this._taricService.updateDatasource(searchValue);
                            const directMatches$ = this._taricService.getDirectMatches(searchValue);

                            return directMatches$.pipe(concatMap(() => tableDataSource$));
                        }
                    } else {
                        return of([]);
                    }
                }),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe({
                next: (data: ProductNode[] | PaginatedDataSource<ProductNode, TaricQuery>) => {
                    console.log('TXTQUERY data returned', data);

                    //TaricTree Data
                    if (this.isProductNode(data)) {
                        this.ref.hide = true;
                        this.showTabLabel = false;

                        this._taricService.scrollToTop(this.tabContent1.nativeElement);
                        //Search Results Data
                    } else {
                        this.showTabLabel = true;
                        console.log('TARICTABLE DATA', data);
                        this._taricService.selectedIndex.next(1);
                    }

                    this._taricService.loading.next(false)
                },
                error: () => {
                    this._taricService.loading.next(false)
                }
            });

        this._taricService.tableDataSource$
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap(value => {
                    this.taricResultsDatasource = value;

                    return value.page$;
                })
            )
            .subscribe(page => {
                this.page = page;
                console.log('TXTQUERY page Data', page);
                if (this.tabContent2) {
                    this._taricService.scrollToTop(this.tabContent2.nativeElement);
                }
            });

        this.expCountryQuery.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            if (value === '' || value === null) {
                this.destCountries = this.countries;
            } else if (value !== 'GR') {
                this.destCountryQuery.setValue('GR');
                this.destCountries = [this.countries.find(country => country.country_code === 'GR')];
            } else {
                this.destCountries = this.countries.filter(country => country.country_code !== 'GR');
            }
        });

        this.destCountryQuery.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            if (value === '' || value === null) {
                //this.expCountryQuery.setValue("GR");
                this.expCountries = this.countries;
            } else if (value === 'GR') {
                this.expCountries = this.countries.filter(country => country.country_code !== 'GR');
            } else {
                this.expCountryQuery.setValue('GR');

                this.expCountries = [this.countries.find(country => country.country_code === 'GR')];
            }
        });

        const expCountry$ = this.expCountryQuery.valueChanges.pipe(startWith(''));
        const destCountry$ = this.destCountryQuery.valueChanges.pipe(startWith(''));

        combineLatest([expCountry$, destCountry$])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([expCountry, destCountry]) => {
                this.filters = {
                    exportCountry: expCountry,
                    destinationCountry: destCountry
                };
                console.log('expCountry', expCountry);
                console.log('destCountry', destCountry);
            });

        //we subscribe to the datasource observable
        //but we are updating the observable when using the search.valuechanges
    }
    ngAfterViewInit() {
        this.ref.hide = true;

        setTimeout(() => {
            this._first.focusIn();
        }, 500);
        //this._first.ngEle.nativeElement.focus();
        this.cd.detectChanges();
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        console.log('TARIX Taric Destroyed');

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    tabChanged(event: MatTabChangeEvent) {
        console.log(event);
        //      this.tabName = tabName;
    }

    isProductNode(toBeDetermined) {
        return this._taricService.isProductNode(toBeDetermined);
    }
    getAllTaric() {
        this._taricService.getAllTaric();
        this._taricService.scrollToTop(this.tabContent1.nativeElement);
    }

    checkValidCountry(countryControl: FormControl) {

        if (countryControl.value?.length !== 2) {
            countryControl.setValue(null)
        }
    }
}
