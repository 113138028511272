import { PreAdmRefModel } from './preadmrefar2/preadmrefar2.model';
import { ProDocModel } from './prodocdc2/prodocdc2.model';
import { SpeMenModel } from './spemenmt2/spemenmt2.model';
import { TraConexModel } from './traconex2/traconex2.model';
import { ComCodModel } from './comcodgoditm/comcodgodtm.model';
import { CalTaxModel } from './caltaxgod/caltaxgod.model';
import { TraConce2Model } from './traconce2/traconce2.model';
import { Connr2Model } from './connr2/connr2.model';
import { PacgsModel } from './pacgs2/pacgs2.model';
import { GrnModel } from './grn/grn.model';
import { VehDetModel } from './vehdet/vehdet.model';
import { NatProModel } from './natpro/natpro.model';
import { BasProModel } from './baspro/baspro.model';
import { AccountingModel } from './accounting/accounting.model';

export class GooIteGdsModel {
    IteNumGDS7?: number;
    GooDesGDS23?: string;
    GooDesGDS23LNG?: string;
    GroMasGDS46?: number;
    NetMasGDS48?: number;
    CouOfDisGDS58?: string;
    CouOfDesGDS59?: string;
    MetOfPayGDI12?: string;
    ProReqGDI1?: string;
    PreProGDI1?: string;
    ComNatProGIM1?: string;
    StaValCurGDI1?: string;
    StaValAmoGDI1?: string;
    CouOfOriGDI1?: string;
    DisProRegGDI1?: string;
    SupUniGDI1?: string;
    TotIteTaxAmoGDI1?: string;
    ComRefNumGIM1?: string;
    UNDanGooCodGDI1?: string;
    IdeMeaTraDepGDS1030?: string;
    IdeMeaTraDepGDS1030LNG?: string;
    IdeMeaTraCroBorGDS1031?: string;
    IdeMeaTraCroBorGDS1031LNG?: string;
    NatMeaTraCroBorGDS1032?: string;
    TypMeaTraCroBorGDS1033?: string;
    ProReqEndDat4003?: string;
    PREADMREFAR2?: PreAdmRefModel[];
    PRODOCDC2?: ProDocModel[];
    SPEMENMT2?: SpeMenModel[];
    TRACONEX2?: TraConexModel[];
    COMCODGODITM?: ComCodModel[];
    CALTAXGOD?: CalTaxModel[];
    TRACONCE2?: TraConce2Model[];
    CONNR2?: Connr2Model[];
    PACGS2?: PacgsModel[];
    GRN?: GrnModel[];
    VEHDET?: VehDetModel[];
    NATPRO?: NatProModel[];
    BASPRO?: BasProModel[];

    //For CalculationPurposes ONLY
    ACCOUNTING?: AccountingModel[];

    constructor(value?) {
        // console.log("___GOOITEGDSModel", value);
        if (value) {
            this.IteNumGDS7 = value.IteNumGDS7;
            this.GooDesGDS23 = value.GooDesGDS23;
            this.GooDesGDS23LNG = value.GooDesGDS23LNG;
            this.GroMasGDS46 = value.GroMasGDS46;
            this.NetMasGDS48 = value.NetMasGDS48;
            this.CouOfDisGDS58 = value.CouOfDisGDS58;
            this.CouOfDesGDS59 = value.CouOfDesGDS59;
            this.MetOfPayGDI12 = value.MetOfPayGDI12;
            this.ProReqGDI1 = value.ProReqGDI1;
            this.PreProGDI1 = value.PreProGDI1;
            this.ComNatProGIM1 = value.ComNatProGIM1;
            this.StaValCurGDI1 = value.StaValCurGDI1;
            this.StaValAmoGDI1 = value.StaValAmoGDI1;
            this.CouOfOriGDI1 = value.CouOfOriGDI1;
            this.DisProRegGDI1 = value.DisProRegGDI1;
            this.SupUniGDI1 = value.SupUniGDI1;
            this.TotIteTaxAmoGDI1 = value.TotIteTaxAmoGDI1;
            this.ComRefNumGIM1 = value.ComRefNumGIM1;
            this.UNDanGooCodGDI1 = value.UNDanGooCodGDI1;
            this.IdeMeaTraDepGDS1030 = value.IdeMeaTraDepGDS1030;
            this.IdeMeaTraDepGDS1030LNG = value.IdeMeaTraDepGDS1030LNG;
            this.IdeMeaTraCroBorGDS1031 = value.IdeMeaTraCroBorGDS1031;
            this.IdeMeaTraCroBorGDS1031LNG = value.IdeMeaTraCroBorGDS1031LNG;
            this.NatMeaTraCroBorGDS1032 = value.NatMeaTraCroBorGDS1032;
            this.TypMeaTraCroBorGDS1033 = value.TypMeaTraCroBorGDS1033;
            this.ProReqEndDat4003 = value.ProReqEndDat4003;
            this.PREADMREFAR2 = value.PREADMREFAR2;
            this.PRODOCDC2 = value.PRODOCDC2;
            this.SPEMENMT2 = value.SPEMENMT2;
            this.TRACONEX2 = value.TRACONEX2;
            this.COMCODGODITM = value.COMCODGODITM;
            this.CALTAXGOD = value.CALTAXGOD;
            this.TRACONCE2 = value.TRACONCE2;
            this.CONNR2 = value.CONNR2;
            this.PACGS2 = value.PACGS2;
            this.GRN = value.GRN;
            this.VEHDET = value.VEHDET;
            this.NATPRO = value.NATPRO;
            this.BASPRO = value.BASPRO;

            this.ACCOUNTING = value.ACCOUNTING;
        }
    }
}
