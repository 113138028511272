import { FormControl, AbstractControl, Validators } from '@angular/forms';
import { ArrAtExitHeaheaModel } from './heahea.model';

export class ArrAtExitHeaheaForm {
    [key: string]: AbstractControl;
    //DOESNT EXIST ON CC515A
    DocNumHEA5 = new FormControl();

    //HEADER FORM
    CusSubPlaHEA66: FormControl;
    ArrNotPlaHEA60: FormControl;
    ArrNotPlaHEA60LNG: FormControl;
    ArrAgrLocCodHEA62: FormControl;
    ArrAgrLocOfGooHEA63: FormControl;
    ArrAgrLocOfGooHEA63LNG: FormControl;
    ArrNotDatHEA141: FormControl;
    DiaLanIndAtDesHEA255: FormControl;
    StoFlaHEA1: FormControl;
    AuthStorLocGoodHEA416: FormControl;

    constructor(value: ArrAtExitHeaheaModel) {
        this.DocNumHEA5 = new FormControl(value.DocNumHEA5, {
            updateOn: 'blur',
            validators: Validators.required
        });
        this.CusSubPlaHEA66 = new FormControl(value.CusSubPlaHEA66, {
            updateOn: 'blur'
        });
        this.ArrNotPlaHEA60 = new FormControl(value.ArrNotPlaHEA60, {
            updateOn: 'blur',
            validators: Validators.required
        });
        this.ArrNotPlaHEA60LNG = new FormControl(value.ArrNotPlaHEA60LNG, {
            validators: Validators.required
        });
        this.ArrAgrLocCodHEA62 = new FormControl(value.ArrAgrLocCodHEA62, {
            updateOn: 'blur'
        });
        this.ArrAgrLocOfGooHEA63 = new FormControl(value.ArrAgrLocOfGooHEA63, {
            updateOn: 'blur'
        });
        this.ArrAgrLocOfGooHEA63LNG = new FormControl(value.ArrAgrLocOfGooHEA63LNG);
        this.ArrNotDatHEA141 = new FormControl(value.ArrNotDatHEA141);
        this.DiaLanIndAtDesHEA255 = new FormControl(value.DiaLanIndAtDesHEA255);
        this.StoFlaHEA1 = new FormControl(value.StoFlaHEA1, {
            updateOn: 'blur',
            validators: Validators.required
        });
        this.AuthStorLocGoodHEA416 = new FormControl(value.AuthStorLocGoodHEA416, { updateOn: 'blur' });
    }
}
