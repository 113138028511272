import { FueCon300Model } from './fuecon300/fuecon300.model';
import { MeaOfPro200Model } from './meaofpro200/meaofpro200.model';

export class Prov100Model {
    ShiNamPRO1?: string;
    IMONumPRO2?: string;
    ShiFlaPRO3?: string;
    GroTonPRO4?: string;
    MEAOFPRO200?: MeaOfPro200Model[];
    FUECON300?: FueCon300Model[];

    constructor(value?) {
        this.ShiNamPRO1 = value?.ShiNamPRO1 || null;
        this.IMONumPRO2 = value?.IMONumPRO2 || null;
        this.ShiFlaPRO3 = value?.ShiFlaPRO3 || null;
        this.GroTonPRO4 = value?.GroTonPRO4 || null;
        this.MEAOFPRO200 = value?.MEAOFPRO200 || [];
        this.FUECON300 = value?.FUECON300 || [];
    }
}
