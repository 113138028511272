export class SupplementaryComputationInformationModel {
    SupplementaryComputationInfo?: string;
    SupplementaryComputationValue?: string;


    constructor(value?) {
        this.SupplementaryComputationInfo = value?.SupplementaryComputationInfo || null;
        this.SupplementaryComputationValue = value?.SupplementaryComputationValue || null;

    }
}
