import { Component, OnInit } from '@angular/core';
import { ProducerTime, TsipouraService } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura.service';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'tsipoura-producer-times',
    templateUrl: './tsipoura-producer-times.component.html',
    styleUrls: ['./tsipoura-producer-times.component.scss']
})
export class TsipouraProducerTimesComponent implements OnInit {

    producersTime$: Observable<ProducerTime[]>


    constructor(private tsipouraService: TsipouraService
    ) { }

    ngOnInit() {
        this.producersTime$ = this.tsipouraService.producersTime$

    }

}