import { FormControl, AbstractControl } from '@angular/forms';
import { T2LCancelHeaheaModel } from './cancel-heahea.model';
export class T2LCancelHeaheaForm {
    [key: string]: AbstractControl;

    //HEADER FORM
    RefT2LYeaHEA1012 = new FormControl();
    RefT2CouCodHEA1010 = new FormControl();
    RefT2LTypHEA1014 = new FormControl();
    RefT2LCusHEA1013 = new FormControl();
    RefT2LNumHEA1015 = new FormControl();
    DatOfCanReqHEA147 = new FormControl();
    CanReaHEA250 = new FormControl();
    CanReaHEA250LNG = new FormControl();

    constructor(value: T2LCancelHeaheaModel) {

        this.RefT2LYeaHEA1012.setValue(value.RefT2LYeaHEA1012);
        this.RefT2CouCodHEA1010.setValue(value.RefT2CouCodHEA1010);
        this.RefT2LTypHEA1014.setValue(value.RefT2LTypHEA1014);
        this.RefT2LCusHEA1013.setValue(value.RefT2LCusHEA1013);
        this.RefT2LNumHEA1015.setValue(value.RefT2LNumHEA1015);
        this.DatOfCanReqHEA147.setValue(value.DatOfCanReqHEA147);
        this.CanReaHEA250.setValue(value.CanReaHEA250);
        this.CanReaHEA250LNG.setValue(value.CanReaHEA250LNG);
    }
}
