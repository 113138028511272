import { ConresersModel, ItiModel, SeainfsilModel, TraconceModel } from 'app/main/apps/export/declaration/_models';
import { Traconco1Model } from 'app/main/apps/import/_models/traconco1/traconco1.model';
import { CusoffdepeptModel } from '../../../_models/cusoffdepept/cusoffdepept.model';
import { CusoffdesestModel } from '../../../_models/cusoffdesest/cusoffdesest.model';
import { CusofftrarnsModel } from '../../../_models/cusofftrarns/cusofftrarns.model';
import { TirGooIteGdsModel } from '../gooitegds-tir/gooitegds-tir.model';
import { GuaguaModel } from '../guagua/guagua.model';
import { TirHeaheaModel } from '../heahea/heahea.model';
import { ReprepModel } from '../reprep/reprep.model';
import { TraautcontraModel } from '../traautcontra/traautcontra.model';
import { Trapripc1Model } from '../trapripc1/trapripc1.model';
import { Cartra100Model } from 'app/main/apps/import/_models/cartra100/cartra100.model';
import { Traconsec029Model } from '../traconsec029/traconsec029.model';
import { Tracorsec037Model } from '../tracorsec037/tracorsec037.model';
import { CtlCl1Model } from '../ctlcl1/ctlcl1.model';
import { User, _pelatesItem, international_customersItem } from 'app/model/api-model';
import { PrivateControlsModel } from 'app/model/private-controls.model';

export class TirDeclarationModel {
    _PRIVATE: PrivateControlsModel;

    SynIdeMES1?: string;
    SynVerNumMES2?: string;
    MesSenMES3?: string;
    SenIdeCodQuaMES4?: string;
    MesRecMES6?: string;
    RecIdeCodQuaMES7?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    IntConRefMES11?: string;
    RecRefMES12?: string;
    RecRefQuaMES13?: string;
    AppRefMES14?: string;
    PriMES15?: string;
    AckReqMES16?: string;
    ComAgrIdMES17?: string;
    TesIndMES18?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    ComAccRefMES21?: string;
    MesSeqNumMES22?: string;
    FirAndLasTraMES23?: string;
    //CorIdeMES25?: string;

    HEAHEA?: TirHeaheaModel; //////////////
    TRAPRIPC1?: Trapripc1Model; //////////////
    TRACONCO1?: Traconco1Model; ////////////
    TRACONCE1?: TraconceModel; ////////////////
    TRAAUTCONTRA?: TraautcontraModel;
    CUSOFFDEPEPT?: CusoffdepeptModel; /////////////////
    CUSOFFTRARNS?: CusofftrarnsModel[];
    CUSOFFDESEST?: CusoffdesestModel; //////////////////
    CTLCL1?: CtlCl1Model;//////////////////////////ON CC013b ONLY===========
    CONRESERS?: ConresersModel;
    REPREP?: ReprepModel; ////////////////////
    SEAINFSLI?: SeainfsilModel;
    GUAGUA?: GuaguaModel[]; ///////////////////
    GOOITEGDS?: TirGooIteGdsModel[];
    ITI?: ItiModel[];
    CARTRA100?: Cartra100Model;
    TRACORSEC037?: Tracorsec037Model;
    TRACONSEC029?: Traconsec029Model;
    //
    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);
        this._PRIVATE = value?._PRIVATE || null;

        this.SynIdeMES1 = value?.SynIdeMES1 || null;
        this.SynVerNumMES2 = value?.SynVerNumMES2 || null;
        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.SenIdeCodQuaMES4 = value?.SenIdeCodQuaMES4 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.RecIdeCodQuaMES7 = value?.RecIdeCodQuaMES7 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.IntConRefMES11 = value?.IntConRefMES11 || null;
        this.RecRefMES12 = value?.RecRefMES12 || null;
        this.RecRefQuaMES13 = value?.RecRefQuaMES13 || null;
        this.AppRefMES14 = value?.AppRefMES14 || null;
        this.PriMES15 = value?.PriMES15 || null;
        this.AckReqMES16 = value?.AckReqMES16 || null;
        this.ComAgrIdMES17 = value?.ComAgrIdMES17 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = value?.MesTypMES20 || 'CC015B';
        this.ComAccRefMES21 = value?.ComAccRefMES21 || null;
        this.MesSeqNumMES22 = value?.MesSeqNumMES22 || null;
        this.FirAndLasTraMES23 = value?.FirAndLasTraMES23 || null;

        this.HEAHEA = value?.HEAHEA;
        this.TRAPRIPC1 = value?.TRAPRIPC1;
        this.TRACONCO1 = value?.TRACONCO1;
        this.TRACONCE1 = value?.TRACONCE1;
        this.TRAAUTCONTRA = value?.TRAAUTCONTRA;
        this.CUSOFFDEPEPT = value?.CUSOFFDEPEPT;
        this.CUSOFFTRARNS = value?.CUSOFFTRARNS;
        this.CUSOFFDESEST = value?.CUSOFFDESEST;
        this.CTLCL1 = value?.CTLCL1;
        this.CONRESERS = value?.CONRESERS;
        this.REPREP = value?.REPREP;
        this.SEAINFSLI = value?.SEAINFSLI;
        this.GUAGUA = value?.GUAGUA;
        this.GOOITEGDS = value?.GOOITEGDS;
        this.ITI = value?.ITI;
        this.CARTRA100 = value?.CARTRA100;
        this.TRACORSEC037 = value?.TRACORSEC037;
        this.TRACONSEC029 = value?.TRACONSEC029;
    }
}
