import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Component({
    selector: 'app-tariff-loading',
    templateUrl: './tariff-loading.component.html',
    styleUrls: ['./tariff-loading.component.scss']
})
export class TariffLoadingComponent implements OnInit {
    message: string;
    progressValue = 0;
    myWebSocket: WebSocketSubject<any> = webSocket({
        url: environment.WS_URL,
        deserializer: msg => msg
    });
    private _destroy: Subject<void> = new Subject<void>();

    constructor(private toastrService: ToastrService) {}

    ngOnInit(): void {
        this.myWebSocket.pipe(takeUntil(this._destroy)).subscribe(
            (msg: MessageEvent) => {
                // this.message = msg.data[0].message;
                const data = JSON.parse(msg.data);

                //because we are receiving the serverStatus every 5 secs
                //we set value to 0, so it doesnt return undefined
                let addValue = 0;

                if (data.value) {
                    addValue = data.value;
                }

                if (data.message) {
                    this.message = data.message;
                }

                this.progressValue = this.progressValue + addValue;
            },
            // Called whenever there is a message from the server
            (err: unknown) => {
                this.toastrService.error('WebSocket Error', err.toString(), {
                    disableTimeOut: true
                });
                console.log(err);
            },
            // Called if WebSocket API signals some kind of error
            () => console.log('WEBSOCKET complete')
            // Called when connection is closed (for whatever reason)
        );
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }
}
