import { FormControl, Validators } from '@angular/forms';
import { LoadingDtsModel } from './loadingDts.model';

export class LoadingDtsForm {
    [key: string]: FormControl;

    LoadingBegDateHEA = new FormControl();
    LoadingEndDat = new FormControl();

    constructor(value: LoadingDtsModel) {
        this.LoadingBegDateHEA.setValue(value.LoadingBegDateHEA);
        this.LoadingEndDat.setValue(value.LoadingEndDat);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
