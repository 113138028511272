import { ArchiveTabType } from 'app/main/apps/archive/archive.service';
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ChooseDialogComponent } from "app/main/main-building-blocks/dialogs/choose-dialog/choose-dialog.component";
import { SaveFormParams } from "app/services/saveForm.service";
import { Observable, switchMap, of, take, finalize } from "rxjs";
export type SaveDocumentType = ArchiveTabType | 'distillersApp'

export interface BaseSaveFormParams {

    id?: string | null;
    type?: SaveDocumentType;
    templateDescription?: string;
    action?: 'create' | 'update';
}


interface ItemConfig {
    dialogTitle: string;
    contentDescription: string;
    confirmButtons: any[];
    inputTitle?: string;
    // ... other configuration properties
}
@Injectable({ providedIn: 'root' })
export class SaveAsService {

    createTemplateConfig: ItemConfig = {
        dialogTitle: 'Αποθήκευση ως Πρότυπο',
        contentDescription: 'Θέλετε να αποθηκεύσετε τη διασάφηση ως πρότυπο;',
        confirmButtons: [
            { title: 'Αποθήκευση', class: 'accent', action: 'create' },
            { title: 'Άκυρο', class: 'primary', action: false }
        ],
        inputTitle: 'Περιγραφή Προτύπου'
    }

    updateTemplateConfig: ItemConfig = {
        dialogTitle: 'Αποθήκευση ως Πρότυπο',
        contentDescription: 'Θέλετε να αντικαταστήσετε το υπάρχον πρότυπο;',
        confirmButtons: [
            { title: 'Αντικατάσταση', class: 'accent', action: 'update' },
            { title: 'Νέο Πρότυπο', class: 'primary', action: 'create' }
        ]
    }

    createDraftConfig: ItemConfig = {
        dialogTitle: 'Αποθήκευση ως Πρόχειρο',
        contentDescription: 'Θέλετε να αποθηκεύσετε τη διασάφηση ως πρόχειρο;',
        confirmButtons: [
            { title: 'Αποθήκευση', class: 'accent', action: 'create' },
            { title: 'Άκυρο', class: 'primary', action: false }
        ],
        inputTitle: 'Περιγραφή Προχείρου'
    }

    updateDraftConfig: ItemConfig = {
        dialogTitle: 'Αποθήκευση ως Πρόχειρο',
        contentDescription: 'Θέλετε να αντικαταστήσετε το υπάρχον πρόχειρο;',
        confirmButtons: [
            { title: 'Αντικατάσταση', class: 'accent', action: 'update' },
            { title: 'Νέο Πρόχειρο', class: 'primary', action: 'create' }
        ]
    }

    createDistillersAppConfig: ItemConfig = {
        dialogTitle: 'Αποθήκευση Δήλωσης',
        contentDescription: 'Θέλετε να αποθηκεύσετε τη δήλωση;',
        confirmButtons: [
            { title: 'Αποθήκευση', class: 'accent', action: 'create' },
            { title: 'Άκυρο', class: 'primary', action: false }
        ],
        inputTitle: 'Περιγραφή Δήλωσης'
    }

    updateDistillersAppConfig: ItemConfig = {
        dialogTitle: 'Αποθήκευση Δήλωσης',
        contentDescription: 'Θέλετε να αντικαταστήσετε την υπάρχουσα δήλωση;',
        confirmButtons: [
            { title: 'Αντικατάσταση', class: 'accent', action: 'update' },
            { title: 'Νέο Πρότυπο', class: 'primary', action: 'create' }
        ]
    }

    constructor(
        private dialog: MatDialog,
        private saveAsDialogRef: MatDialogRef<ChooseDialogComponent>,
    ) { }
    saveAsDialog(args: BaseSaveFormParams, type: SaveDocumentType): Observable<BaseSaveFormParams | null> {
        if (args.id) {
            return this.updateItem(args, type)
        } else {
            args.id = null;
            return this.createItem(args, type)
        }
    }

    createItem(args: BaseSaveFormParams, type: SaveDocumentType): Observable<BaseSaveFormParams | null> {
        console.trace("createItem");

        this.handleDialog(this.getCreateConfig(type));


        return this.saveAsDialogRef.afterClosed()
            .pipe(
                switchMap(result => {
                    console.log('Save Result', result);
                    if (result?.action === 'create') {
                        args.type = type;
                        args.templateDescription = result.inputValue;
                        args.id = null;
                        args.action = result.action;
                        return of(args);
                    }
                    return of(null)

                }),
                take(1),
                finalize(() => {
                    this.saveAsDialogRef.close();
                    this.saveAsDialogRef = null;
                })
            );

    }

    updateItem(args: BaseSaveFormParams, type: SaveDocumentType): Observable<BaseSaveFormParams | null> {
        console.trace("updateItem");

        this.handleDialog(this.getUpdateConfig(type));

        return this.saveAsDialogRef.afterClosed()
            .pipe(
                switchMap(result => {
                    console.log('Save Result', result);
                    if (result?.action === 'update') {

                        args.type = type;
                        args.templateDescription = result.inputValue;
                        args.action = result.action;
                        return of(args);
                    } else if (result?.action === 'create') {

                        if (args.type === 'template' || args.type === 'distillersApp') {
                            return this.createItem(args, type);
                        }
                        else if (args.type === 'draft') {
                            args.type = type;
                            args.action = result.action;
                            args.id = null;
                            return of(args);
                        }


                    }
                    return of(null)

                }),
                take(1),
                finalize(() => {
                    this.saveAsDialogRef.close();
                    this.saveAsDialogRef = null;
                })
            );


    }

    handleDialog(config: ItemConfig) {
        // Use config to set up dialog properties
        this.saveAsDialogRef = this.dialog.open(ChooseDialogComponent, {
            disableClose: false,
            autoFocus: true,
            panelClass: 'mat-dialog-override',
            width: '40%',
            height: '55%'
        });

        this.saveAsDialogRef.componentInstance.dialogTitle = config.dialogTitle;
        this.saveAsDialogRef.componentInstance.contentDescription = config.contentDescription;
        this.saveAsDialogRef.componentInstance.confirmButtons = config.confirmButtons;
        if (config.inputTitle) {
            this.saveAsDialogRef.componentInstance.inputTitle = config.inputTitle;
        }
    }


    getCreateConfig(itemType: SaveDocumentType): ItemConfig {
        switch (itemType) {
            case 'template':
                return this.createTemplateConfig;
            case 'draft':
                return this.createDraftConfig;
            case 'distillersApp':
                return this.createDistillersAppConfig;
            default:
                throw new Error('Unsupported item type');
        }
    }

    getUpdateConfig(itemType: SaveDocumentType): ItemConfig {
        switch (itemType) {
            case 'template':
                return this.updateTemplateConfig;
            case 'draft':
                return this.updateDraftConfig;

            case 'distillersApp':
                return this.updateDistillersAppConfig;

            default:
                throw new Error('Unsupported item type');
        }
    }

}
