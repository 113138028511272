import { AbstractControl, FormControl } from "@angular/forms";
import { TaxesDeclarationConsignorModel } from "./taxes-declaration-consignor.model";

export class TaxesDeclarationConsignorForm {
    [key: string]: AbstractControl;

    ExciseTaxesDeclarationConsignorType: FormControl;
    TaxOrVehicleWarehouseReference: FormControl;


    constructor(value: TaxesDeclarationConsignorModel) {

        this.ExciseTaxesDeclarationConsignorType = new FormControl(value.ExciseTaxesDeclarationConsignorType);
        this.TaxOrVehicleWarehouseReference = new FormControl(value.TaxOrVehicleWarehouseReference);

    }
}
