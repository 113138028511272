import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { Filters, ProductNode, TaricService } from '../taric.service';
import { TaricResult } from './taric-result.model';
import { Page, Sort } from 'app/services/datasource/page';
import { takeUntil } from 'rxjs/operators';
import { NestedTreeControl } from '@angular/cdk/tree';
import { TreeDataSource } from '../taric-tree/taric-tree.datasource';

export interface TaricQuery {
    search: string;
}

@Component({
    selector: 'taric-results-table',
    templateUrl: './taric-results-table.component.html',
    styleUrls: ['./taric-results-table.component.scss']
})
export class TaricResultsTableComponent {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    //@Input() DATASOURCE: PaginatedDataSource<TaricResult, TaricQuery> | null;
    DATASOURCE: any;
    @Input() filters: Filters;
    @Input() formDisabled$: Observable<boolean>;
    @Input() dataLoading: boolean;

    showActionButtons: boolean;
    dialogContent: TemplateRef<any>;
    treeControl: NestedTreeControl<ProductNode>;

    taric: any;

    isLoading: boolean[] = [];
    selectedIndex: number;
    page: Page<TaricResult>;

    displayedColumns = ['resultColumn'];

    initialSort: Sort<TaricResult> = {
        property: 'code',
        order: 'asc'
    };

    dialogRef: any;
    totalResults$: Observable<number>;

    // Private
    private _destroy: Subject<void> = new Subject<void>();

    /**
     * Constructor
     *
     * @param _matDialog
     */
    constructor(private _taricService: TaricService, public _matDialog: MatDialog, private toastrService: ToastrService, private mainBroadcaster: MainBroadcasterService) {
        console.log('TARIX Taric ResultsTable Started');

        this._taricService.treeDataSource$.pipe(takeUntil(this._destroy)).subscribe((treeDataSource: TreeDataSource) => {
            this.treeControl = treeDataSource.treeControl;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {

        this.mainBroadcaster.activeSubdomain$.pipe(takeUntil(this._destroy)).subscribe(subdomain => {
            if (subdomain) {
                this.showActionButtons = true
            }
            else { this.showActionButtons = false; }
        })
        this._taricService.tableDataSource$.pipe(takeUntil(this._destroy)).subscribe(tableDataSource => {
            this.DATASOURCE = tableDataSource;
            console.log('TABLEDATASOURCE oN INput', this.DATASOURCE);

        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        console.log('TARIX Taric ResultsTable Destroyed');

        this._destroy.next();
        this._destroy.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    showInTree(product) {
        this._taricService.showInTree(product);

        const elements = document.getElementsByClassName('product-direct-match');

        console.log(elements);

    }

    selectProduct(product, index) {
        console.log(index);
        this.selectedIndex = index;
        if (this.filters.destinationCountry && this.filters.exportCountry) {

            this.isLoading[index] = true;

            this._taricService.getTariff({
                'applicant': 'searchResults',
                product,
                'filters': this.filters
            }).subscribe(() => {

                this.isLoading[index] = false;
            })

        }
        else {

            this.toastrService.warning('Παρακαλώ επιλέξτε χώρα αποστολής/προορισμού.')
        }
    }


    setCodeDesc(event) {
        this._taricService.setCodeDesc(event).subscribe();
    }
}
