import { AbstractControl, FormControl } from "@angular/forms";
import { VesselRegistrationDetailsModel } from "./vessel-registration-details.model";

export class VesselRegistrationDetailsForm {
    [key: string]: AbstractControl;

    VesselRegistrationType: FormControl;
    VesselRegistrationNumber: FormControl;
    VesselName: FormControl;
    VesselPortOfRegistration: FormControl;
    VesselIMO: FormControl;
    VesselCountryOfOrigin: FormControl;
    VesselCategory: FormControl;
    VesselCapacity: FormControl;
    VesselHorsePower: FormControl;

    /*

   */

    constructor(value: VesselRegistrationDetailsModel) {

        this.VesselRegistrationType = new FormControl(value.VesselRegistrationType);
        this.VesselRegistrationNumber = new FormControl(value.VesselRegistrationNumber);
        this.VesselName = new FormControl(value.VesselName);
        this.VesselPortOfRegistration = new FormControl(value.VesselPortOfRegistration);
        this.VesselIMO = new FormControl(value.VesselIMO);
        this.VesselCountryOfOrigin = new FormControl(value.VesselCountryOfOrigin);
        this.VesselCategory = new FormControl(value.VesselCategory);
        this.VesselCapacity = new FormControl(value.VesselCapacity);
        this.VesselHorsePower = new FormControl(value.VesselHorsePower);

    }
}
