<div>
    <div class="tsipoura-producer-times-header" style="display:flex;width:100%">
        <table style="width:95%">
            <thead>
                <tr>
                    <th class="text-center">ΩΡΕΣ ΑΠΟΣΤΑΞΗΣ</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="padding:unset;">
                        <table class="tsipoura-producer-times-table">
                            <colgroup>
                                <col style="width:50%">
                                <col style="width:25%">
                                <col style="width:25%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th class="text-left">ΠΑΡΑΓΩΓΟΣ</th>
                                    <th>ΕΝΑΡΞΗ</th>
                                    <th>ΛΗΞΗ</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="producersTime$ | async as producersTime">
                                <tr *ngFor=" let item of producersTime; let i=index">
                                    <td class="text-left">{{item.producerName}}</td>
                                    <td>{{item.startTime | date:'EEE, dd/MM/yy HH:mm'}}</td>
                                    <td>{{item.endTime | date:'EEE, dd/MM/yy HH:mm'}}</td>
                                </tr>
                            </tbody>
                        </table>

                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>