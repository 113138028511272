import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { BeerForm } from 'app/main/apps/excise-helper-tables/beer/_models/beer-form.model';
import { BeerService } from 'app/main/apps/excise-helper-tables/beer/beer.service';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { Observable, Subject, tap } from 'rxjs';

@Component({
    selector: 'beer-calculation-results',
    templateUrl: './beer-calculation-results.component.html',
    styleUrls: ['./beer-calculation-results.component.scss']
})
export class BeerCalculationResultsComponent implements OnInit {
    beerForm: FormGroup<BeerForm>
    beerForm$: Observable<FormGroup<BeerForm>>


    get beerCalculationData(): FormArray {
        return this.beerForm.get('beerCalculationData') as FormArray;
    }
    get beerTotals(): FormGroup {
        return this.beerForm.get('beerTotals') as FormGroup;
    }

    constructor(private beerService: BeerService) { }

    ngOnInit() {

        this.beerForm$ = this.beerService.beerForm$.pipe(tap((form => {

            this.beerForm = form
        })))
    }

}
