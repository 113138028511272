export class eAitisiHeaheaModel {
    //Privates====================
    DocNumHEA5?: string; //<===NOT USED NORMALLY, TO BE REMOVED BEFORE SUBMIT
    _amountOfTax?: string;
    AmeAccDatHEA602?: string;
    //========================================

    RefNumHEA4?: string;
    AccDatHEA158?: string;
    //On IB13A only
    AmdDatHEA599?: string;
    //-----------------------
    DecSubDatTimHEA118?: string;
    CommentsHEA06?: string;
    MetOfPayHEA590?: string;
    GenrlPurpsTsksDescr?: string;
    CustomsTasks?: string;
    ManualRF?: string;
    TotalAmount?: string;

    constructor(value?) {
        if (value) {
            this.DocNumHEA5 = value.DocNumHEA5;
            this._amountOfTax = value._amountOfTax;
            this.AmeAccDatHEA602 = value.AmeAccDatHEA602;

            this.RefNumHEA4 = value.RefNumHEA4;
            this.AccDatHEA158 = value.AccDatHEA158;
            this.AmdDatHEA599 = value.AmdDatHEA599;
            this.DecSubDatTimHEA118 = value.DecSubDatTimHEA118;
            this.CommentsHEA06 = value.CommentsHEA06;
            this.MetOfPayHEA590 = value.MetOfPayHEA590;
            this.GenrlPurpsTsksDescr = value.GenrlPurpsTsksDescr;
            this.CustomsTasks = value.CustomsTasks;
            this.ManualRF = value.ManualRF;
            this.TotalAmount = value.TotalAmount;
        }
    }
}
