import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToposTirisisEggafonModel } from 'app/main/apps/general-applications/topos-tirisis-eggrafon/_models/topos-tirisis-eggrafon.model';
import { ToposTirisisEggafonFormModel } from 'app/main/apps/general-applications/topos-tirisis-eggrafon/_models/topos-tirisis.egrafon-form.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ToposTirisisEggrafonService {

    dilosiForm: BehaviorSubject<FormGroup<ToposTirisisEggafonFormModel>>
    dilosiForm$: Observable<FormGroup<ToposTirisisEggafonFormModel>>
    constructor() {

        this.dilosiForm = new BehaviorSubject(new FormGroup(new ToposTirisisEggafonFormModel(new ToposTirisisEggafonModel())));

        this.dilosiForm$ = this.dilosiForm.asObservable();
    }
}
