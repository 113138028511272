<div fxLayout="column" style="width: 100%" fxLayoutAlign="space-between center">
    <!--  <div *ngIf="(totalCustoms$ | async) > 0"> -->
    <div class="customs-list-table" fxLayout="column">
        <mat-table #table [dataSource]="DATASOURCE" matSort matSortActive="country" matSortDirection="asc"
            matSortDisableClear class="wv-table--condensed">
            <!-- lrn Column -->
            <ng-container matColumnDef="CountryCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Χώρα</mat-header-cell>
                <mat-cell *matCellDef="let custom">
                    <p class="text-truncate font-weight-600">
                        {{ getCountry(custom.CountryCode) }}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- mrn Column -->
            <ng-container matColumnDef="ReferenceNumber">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Κωδικός</mat-header-cell>
                <mat-cell *matCellDef="let custom">
                    <p class="text-truncate font-weight-600">
                        {{ custom.ReferenceNumber }}
                    </p>
                </mat-cell>
            </ng-container>
            <!-- trader Column -->
            <ng-container matColumnDef="City">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Πόλη</mat-header-cell>
                <mat-cell *matCellDef="let custom">
                    <p class="city text-truncate">
                        {{ custom.City }}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- trader Column -->
            <ng-container matColumnDef="CustomsOfficeName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Όνομα</mat-header-cell>
                <mat-cell *matCellDef="let custom">
                    <p class="city text-truncate">
                        {{ custom.CustomsOfficeName }}
                    </p>
                </mat-cell>
            </ng-container>

            <!-- international Column -->
            <ng-container matColumnDef="usage">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let custom">
                    <button *ngIf="subdomain" mat-button matTooltip="Χρήση ως" matTooltipClass="tooltip"
                        [matMenuTriggerFor]="useAs" [matMenuTriggerData]="{ custom: custom }" aria-label="UseAs"
                        (click)="$event.stopPropagation()">
                        <mat-icon class="secondary-text">more_vert</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" sticky="true"></mat-header-row>
            <mat-row *matRowDef="let custom; columns: displayedColumns" class="custom"
                (click)="goToOffice(custom.ReferenceNumber)" matRipple>
            </mat-row>
        </mat-table>
    </div>
    <div *ngIf="(totalCustoms$ | async) === 0" fxLayout="row" fxLayoutAlign="center">
        <p class="text-truncate font-weight-600">
            Δεν βρέθηκαν εγγραφές με τα συγκεκριμένα κριτήρια
        </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="center">
        <mat-paginator class="mat-paginator-sticky" *ngIf="DATASOURCE?.page$ | async as page"
            [length]="page.totalElements" [pageSize]="page.size" [pageIndex]="page.number" [hidePageSize]="false"
            [pageSizeOptions]="[10, 15, 20]" (page)="DATASOURCE.fetch($event.pageIndex, $event.pageSize)">
        </mat-paginator>
    </div>
</div>

<mat-menu #useAs="matMenu">
    <ng-template matMenuContent let-custom="custom">
        <mat-nav-list style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
            <mat-list-item (click)="
                    setCustoms(
                        custom.ReferenceNumber,
                        custom.CustomsOfficeName,
                        customs1.type,
                        customs1.title
                    )
                ">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                    <p matLine fxFlex>Τελωνείο {{ customs1.title }}</p>
                </div>
            </mat-list-item>

            <mat-list-item (click)="
                    setCustoms(
                        custom.ReferenceNumber,
                        custom.CustomsOfficeName,
                        customs2.type,
                        customs2.title
                    )
                ">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                    <p matLine fxFlex>Τελωνείο {{ customs2.title }}</p>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </ng-template>
</mat-menu>