import { NgModule } from '@angular/core';
import { TsipouraComponent } from './tsipoura.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { AddDelButtonsModule } from 'app/main/main-building-blocks/_scams/add-del-buttons/add-del-buttons.module';
import { FormFieldsModule } from 'app/main/main-building-blocks/form-fields/form-fields.module';
import { TsipouraService } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura.service';
import { TsipouraLimitationsComponent } from './tsipoura-limitations/tsipoura-limitations.component';
import { TsipouraParametersComponent } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura-parameters/tsipoura-parameters.component';
import { TsipouraHeaderComponent } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura-header/tsipoura-header.component';
import { TsipouraCalculationDataComponent } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura-calculation-data/tsipoura-calculation-data.component';
import { DigitOnlyModule } from 'app/main/projects/uiowa/digit-only/src/public_api';
import { TsipouraProducerTimesComponent } from './tsipoura-producer-times/tsipoura-producer-times.component';
import { ContactFormModule } from 'app/main/apps/contacts/contact-form/contact-form.module';

@NgModule({
    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        AddDelButtonsModule,
        DigitOnlyModule,
        ContactFormModule

    ],
    declarations: [TsipouraComponent, TsipouraHeaderComponent, TsipouraCalculationDataComponent, TsipouraParametersComponent, TsipouraLimitationsComponent, TsipouraProducerTimesComponent],
    providers: [TsipouraService]

})
export class TsipouraModule { }
