import { FormControl, FormGroup, FormArray, AbstractControl, Form } from '@angular/forms';
import { AccountingForm } from 'app/main/apps/export/declaration/_models/gooitegds/accounting/accounting-form.model';
import { AccountingModel } from 'app/main/apps/export/declaration/_models/gooitegds/accounting/accounting.model';
import { ComCodForm } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm-form.model';
import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { Connr2Form } from 'app/main/apps/export/declaration/_models/gooitegds/connr2/connr2-form.model';
import { Connr2Model } from 'app/main/apps/export/declaration/_models/gooitegds/connr2/connr2.model';
import { TraConce2Form } from 'app/main/apps/export/declaration/_models/gooitegds/traconce2/traconce2-form.model';
import { TraConce2Model } from 'app/main/apps/export/declaration/_models/gooitegds/traconce2/traconce2.model';
import { Traconco2Form } from 'app/main/apps/import/_models/gooitegds/traconco2/traconco2-form.model';
import { Traconco2Model } from 'app/main/apps/import/_models/gooitegds/traconco2/traconco2.model';
import { NctsPreAdmRefModel } from '../../../_models/preadmrefar2/preadmrefar2.model';
import { NctsSpeMenForm } from '../../../_models/spemenmt2/spemenmt2-form.model';
import { NctsSpeMenModel } from '../../../_models/spemenmt2/spemenmt2.model';
import { TirPacgsForm } from '../pacgs2/pacgs2-form.model';
import { TirPacgsModel } from '../pacgs2/pacgs2.model';
import { TirPreAdmRefForm } from '../preadmrefar2/preadmrefar2-form.model';
import { TirPreAdmRefModel } from '../preadmrefar2/preadmrefar2.model';
import { TirProDocForm } from '../prodocdc2/prodocdc2-form.model';
import { TirProDocModel } from '../prodocdc2/prodocdc2.model';
import { Sgicodsd2Form } from '../sgicodsd2/sgicodsd2-form.model';
import { Sgicodsd2Model } from '../sgicodsd2/sgicodsd2.model';
import { Traconsecgoo013Form } from '../traconsecgoo013/traconsecgoo013-form.model';
import { Traconsecgoo013Model } from '../traconsecgoo013/traconsecgoo013.model';
import { Tracorsecgoo021Form } from '../tracorsecgoo021/tracorsecgoo021-form.model';
import { Tracorsecgoo021Model } from '../tracorsecgoo021/tracorsecgoo021.model';
import { TirGooIteGdsModel } from './gooitegds-tir.model';

export class TirGooIteGdsForm {
    [key: string]: AbstractControl;

    IteNumGDS7: FormControl;
    GooDesGDS23LNG: FormControl;
    GroMasGDS46: FormControl;
    NetMasGDS48: FormControl;
    CouOfDisGDS58: FormControl;
    CouOfDesGDS59: FormControl;
    MetOfPayGDI12: FormControl;
    ComRefNumGIM1: FormControl;
    UNDanGooCodGDI1: FormControl;
    StaValGDS500: FormControl;
    GooDesGDS23: FormControl;
    DecTypGDS15: FormControl;
    ComCodTarCodGDS10: FormControl;
    PREADMREFAR2: FormArray;
    PRODOCDC2: FormArray;
    SPEMENMT2: FormArray;
    TRACONCO2: FormArray; //Turned to FormArray to maintain order in form
    TRACONCE2: FormArray; //Turned to FormArray to maintain order in form
    CONNR2: FormArray;
    PACGS2: FormArray;
    SGICODSD2: FormArray;
    TRACORSECGOO021: FormArray; //Turned to FormArray to maintain order in form
    TRACONSECGOO013: FormArray; //Turned to FormArray to maintain order in form

    COMCODGODITM: FormArray;
    ACCOUNTING: FormArray;

    constructor(goods: TirGooIteGdsModel) {
        //console.trace("Who creates GooItegds");
        // console.log("___GooIteGdsFormModel constructor", goods);

        this.IteNumGDS7 = new FormControl(goods.IteNumGDS7, {
            updateOn: 'blur'
        });
        this.GooDesGDS23LNG = new FormControl(goods.GooDesGDS23LNG);
        this.GroMasGDS46 = new FormControl(goods.GroMasGDS46, {
            updateOn: 'blur'
        });
        this.NetMasGDS48 = new FormControl(goods.NetMasGDS48, {
            updateOn: 'blur'
        });
        this.CouOfDisGDS58 = new FormControl(goods.CouOfDisGDS58);
        this.CouOfDesGDS59 = new FormControl(goods.CouOfDesGDS59);
        this.MetOfPayGDI12 = new FormControl(goods.MetOfPayGDI12);
        this.ComRefNumGIM1 = new FormControl(goods.ComRefNumGIM1);
        this.UNDanGooCodGDI1 = new FormControl(goods.UNDanGooCodGDI1);
        this.StaValGDS500 = new FormControl(goods.StaValGDS500);
        this.GooDesGDS23 = new FormControl(goods.GooDesGDS23, {
            updateOn: 'blur'
        });
        this.DecTypGDS15 = new FormControl(goods.DecTypGDS15);
        this.ComCodTarCodGDS10 = new FormControl(goods.ComCodTarCodGDS10);

        this.PREADMREFAR2 = new FormArray([]);
        this.PRODOCDC2 = new FormArray([]);
        this.SPEMENMT2 = new FormArray([]);
        this.TRACONCO2 = new FormArray([]);
        this.TRACONCE2 = new FormArray([]);
        this.CONNR2 = new FormArray([]);
        this.PACGS2 = new FormArray([]);
        this.SGICODSD2 = new FormArray([]);
        this.TRACORSECGOO021 = new FormArray([]);
        this.TRACONSECGOO013 = new FormArray([]);
        this.COMCODGODITM = new FormArray([]);
        this.ACCOUNTING = new FormArray([]);

        if (goods.PREADMREFAR2) {
            goods.PREADMREFAR2.forEach((prevdocs: NctsPreAdmRefModel) => {
                this.PREADMREFAR2.push(new FormGroup(new TirPreAdmRefForm(new TirPreAdmRefModel(prevdocs))));
            });
        } else {
            this.PREADMREFAR2.push(new FormGroup(new TirPreAdmRefForm(new TirPreAdmRefModel())));
        }

        if (goods.PRODOCDC2) {
            goods.PRODOCDC2.forEach((prodocs: TirProDocModel) => {
                this.PRODOCDC2.push(new FormGroup(new TirProDocForm(new TirProDocModel(prodocs))));
            });
        } else {
            this.PRODOCDC2.push(new FormGroup(new TirProDocForm(new TirProDocModel())));
        }

        if (goods.SPEMENMT2) {
            goods.SPEMENMT2.forEach((spemen: NctsSpeMenModel) => {
                this.SPEMENMT2.push(new FormGroup(new NctsSpeMenForm(new NctsSpeMenModel(spemen))));
            });
        } else {
            this.SPEMENMT2.push(new FormGroup(new NctsSpeMenForm(new NctsSpeMenModel())));
        }




        if (goods.TRACONCO2) {
            goods.TRACONCO2.forEach((traconco2: Traconco2Model) => {
                this.TRACONCO2.push(new FormGroup(new Traconco2Form(new Traconco2Model(traconco2))));
            });
        } else {
            this.TRACONCO2.push(new FormGroup(new Traconco2Form(new Traconco2Model())));
        }

        if (goods.TRACONCE2) {
            goods.TRACONCE2.forEach((traconce2: TraConce2Model) => {
                this.TRACONCE2.push(new FormGroup(new TraConce2Form(new TraConce2Model(traconce2))))
            });
        } else {
            this.TRACONCE2.push(new FormGroup(new TraConce2Form(new TraConce2Model())))
        }

        if (goods.CONNR2) {
            goods.CONNR2.forEach((connr: Connr2Model) => {
                this.CONNR2.push(new FormGroup(new Connr2Form(new Connr2Model(connr))));
            });
        } else {
            this.CONNR2.push(new FormGroup(new Connr2Form(new Connr2Model())));
        }
        if (goods.PACGS2) {
            goods.PACGS2.forEach((pacgs: TirPacgsModel) => {
                this.PACGS2.push(new FormGroup(new TirPacgsForm(new TirPacgsModel(pacgs))));
            });
        } else {
            this.PACGS2.push(new FormGroup(new TirPacgsForm(new TirPacgsModel())));
        }

        if (goods.SGICODSD2) {
            goods.SGICODSD2.forEach((pacgs: Sgicodsd2Model) => {
                this.SGICODSD2.push(new FormGroup(new Sgicodsd2Form(new Sgicodsd2Model(pacgs))));
            });
        } else {
            this.SGICODSD2.push(new FormGroup(new Sgicodsd2Form(new Sgicodsd2Model())));
        }




        if (goods.TRACORSECGOO021) {
            goods.TRACORSECGOO021.forEach((tracorsec: Tracorsecgoo021Model) => {
                this.TRACORSECGOO021.push(new FormGroup(new Tracorsecgoo021Form(new Tracorsecgoo021Model(tracorsec))))
            });
        } else {
            this.TRACORSECGOO021.push(new FormGroup(new Tracorsecgoo021Form(new Tracorsecgoo021Model())))
        }





        if (goods.TRACONSECGOO013) {
            goods.TRACONSECGOO013.forEach((traconsec: Tracorsecgoo021Model) => {
                this.TRACONSECGOO013.push(new FormGroup(new Traconsecgoo013Form(new Traconsecgoo013Model(traconsec))))
            });
        } else {
            this.TRACONSECGOO013.push(new FormGroup(new Traconsecgoo013Form(new Traconsecgoo013Model())))
        }




        if (goods.COMCODGODITM) {
            goods.COMCODGODITM.forEach((comcode: ComCodModel) => {
                this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel(comcode))));
            });
        } else {
            this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel())));
        }

        if (goods.ACCOUNTING) {
            goods.ACCOUNTING.forEach((accounting: AccountingModel) => {
                this.ACCOUNTING.push(new FormGroup(new AccountingForm(new AccountingModel(accounting))));
            });
        }
    }
}
