export class Tracorsecgoo021Model {
    NamTRACORSECGOO025?: string;
    StrNumTRACORSECGOO027?: string;
    PosCodTRACORSECGOO026?: string;
    CitTRACORSECGOO022?: string;
    CouCodTRACORSECGOO023?: string;
    TRACORSECGOO021LNG?: string;
    TINTRACORSECGOO028?: string;
    TINTypTRACORSECGOO029?: string;
    ExporterObject?: {};

    constructor(value?) {
        this.NamTRACORSECGOO025 = value?.NamTRACORSECGOO025 || null;
        this.StrNumTRACORSECGOO027 = value?.StrNumTRACORSECGOO027 || null;
        this.PosCodTRACORSECGOO026 = value?.PosCodTRACORSECGOO026 || null;
        this.CitTRACORSECGOO022 = value?.CitTRACORSECGOO022 || null;
        this.CouCodTRACORSECGOO023 = value?.CouCodTRACORSECGOO023 || null;
        this.TRACORSECGOO021LNG = value?.TRACORSECGOO021LNG || null;
        this.TINTRACORSECGOO028 = value?.TINTRACORSECGOO028 || null;
        this.TINTypTRACORSECGOO029 = value?.TINTypTRACORSECGOO029 || null;
        this.ExporterObject = value?.ExporterObject || {};
    }
}
