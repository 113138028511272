import { of, switchMap } from 'rxjs';
import { OfficeService } from 'app/services/office.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { PATHS } from 'app/navigation/paths';
import _ from 'lodash';

@Component({
    template: '',
})
export class RedirectComponent implements OnInit {
    constructor(private router: Router, private officeService: OfficeService) { }
    ngOnInit() {
        // Get the officeId from the route params or wherever you have it
        this.officeService.currentOffice$.pipe(take(1)).pipe(switchMap(office => {

            if (!office) {
                return this.officeService.fetchOffices(true).pipe(
                    switchMap(_ => this.officeService.setDefaultOffice().pipe(take(1)))
                )

            }
            return of(office)

        })).subscribe(office => {

            const startPath = PATHS.start(office?.id);

            this.router.navigate([startPath]);
        })

    }
}