import { FormControl } from '@angular/forms';
import { ReprepModel } from './reprep.model';

export class ReprepForm {
    [key: string]: FormControl;

    NamREP5 = new FormControl();
    RepCapREP18 = new FormControl();
    RepCapREP18LNG = new FormControl();
    DeclarantObject = new FormControl();

    constructor(value: ReprepModel) {
        this.NamREP5.setValue(value.NamREP5);
        this.RepCapREP18.setValue(value.RepCapREP18);
        this.RepCapREP18LNG.setValue(value.RepCapREP18LNG);
        this.DeclarantObject.setValue(value.DeclarantObject);
    }
}
