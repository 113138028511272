export class CalTaxModel {
    TypOfTaxCTX1?: string;
    TaxBasCTX1?: number;
    RatOfTaxCTX1?: number;
    AmoOfTaxTCL1?: number;
    MetOfPayCTX1?: string;
    CalTypTAXCALGI?: string;
    InRelTAXCALGI?: string;
    SusDueDatTAXCALGI?: string;
    QuaCTX1?: number;
    AmoCTX1?: number;

    _natTypeCode?: string;
    _taxFreeCode?: string;
    _taxPaidCode?: string;
    _partialExemption?: boolean;
    _wholeTaxAmount?: number;

    constructor(value?) {
        this.TypOfTaxCTX1 = value?.TypOfTaxCTX1 || null;
        this.TaxBasCTX1 = value?.TaxBasCTX1 || null;
        this.RatOfTaxCTX1 = value?.RatOfTaxCTX1 || null;
        this.AmoOfTaxTCL1 = value?.AmoOfTaxTCL1 || null;
        this.MetOfPayCTX1 = value?.MetOfPayCTX1 || null;
        this.CalTypTAXCALGI = value?.CalTypTAXCALGI;
        this.InRelTAXCALGI = value?.InRelTAXCALGI;
        this.SusDueDatTAXCALGI = value?.SusDueDatTAXCALGI;
        this.QuaCTX1 = value?.QuaCTX1 || null;
        this.AmoCTX1 = value?.AmoCTX1 || null;
        this._natTypeCode = value?._natTypeCode;
        this._taxFreeCode = value?._taxFreeCode;
        this._taxPaidCode = value?._taxPaidCode;
        this._partialExemption = value?._partialExemption;
        this._wholeTaxAmount = value?._wholeTaxAmount;
    }
}
