import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { filter, map, take } from 'rxjs';

@Component({
    selector: 'app-defk-prostheta',
    templateUrl: './defk-prostheta.component.html',
    styleUrls: ['./defk-prostheta.component.scss']
})
export class DefkProsthetaComponent implements OnInit {

    TaxesDeclaration: FormGroup;
    ChemistryDetails: FormGroup;

    color1: string;
    color2: string;

    dateType = 'yyyy-MM-dd';
    defaultDate: Date;
    formDisabled = false;
    checkboxChecked: boolean;

    get fallbackFlag(): FormControl {
        return this.TaxesDeclaration.get('Fallbackflag') as FormControl;
    }

    constructor(
        public dialogRef: MatDialogRef<DefkProsthetaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private DateTimeConvert: DateTransformPipe,
        private cf: ExtCustomFunctions,
        private mainBroadcaster: MainBroadcasterService

    ) {
    }


    ngOnInit() {

        this.mainBroadcaster.declarationForms$.pipe(take(1), map(forms => forms['DEFK'])).subscribe((value) => {

            this.TaxesDeclaration = value.get('Body').get('DeclarationOfExciseTaxesRegistration').get('ExciseTaxesDeclaration') as FormGroup;

            console.log("declarationForms on DefkProsthetaComponent", value)
            console.log("TaxesDeclaration on DefkProsthetaComponent", this.TaxesDeclaration)
        })

        // this.TaxesDeclaration = this.data.declarationForm.get('Body').get('DeclarationOfExciseTaxesRegistration').get('ExciseTaxesDeclaration') as FormGroup;

        this.ChemistryDetails = this.TaxesDeclaration.get('GeneralChemistryDetails') as FormGroup;
        console.log("TaxesDeclaration on dialogRef", this.TaxesDeclaration)

        if (this.TaxesDeclaration.status === "DISABLED") {
            this.formDisabled = true
        }
        else { this.formDisabled = false }
        console.log("form status on dialogRef", this.TaxesDeclaration.status)



        //=------------------------------------------------------------------


        //-----------------------------------------------



        //=========================================================

        if (this.fallbackFlag.value === 1) {
            this.checkboxChecked = true
        }
        else { this.checkboxChecked = false }

        //--------------------------------
        const date = this.TaxesDeclaration.get('RegistrationDate');
        //IF there is a value but form is disabled (submitted)
        // show saved Date
        if (date?.value) {
            this.defaultDate = this.DateTimeConvert.getDate(date.value);
        }

        //========================================================================



    }

    onChange(event) {

        if (event.checked === true) {
            this.fallbackFlag.setValue(1)
        }
        else {
            this.fallbackFlag.setValue(0)

        }

    }

    onCloseConfirm() {
        this.dialogRef.close();
    }
    cancel() {
        //   this.VehicleArrivalConsignee.get('VehicleTaxWarehouseReference').reset()
        //   this.VehicleArrivalConsignee.get('DeliveryPlace').reset()
        this.dialogRef.close();

    }
    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event)

    }
    changeStyle2(event) {
        if (this.TaxesDeclaration.valid) {
            this.color2 = this.cf.changeStyle2(this.TaxesDeclaration, event);
        }
    }


}
