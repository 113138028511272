//import { Player } from "../../player/_models/player.model";
//import { MyGroup } from "../../my-group/_models/mygroup.model";
import { User, _pelatesItem, international_customersItem } from 'app/model/api-model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { AceAceModel } from './aceace/aceace.model';
import { ArrAtExitHeaheaModel } from './heahea/heahea.model';
import { TraexicarModel } from './traexicar/traexicar.model';

export class ArrAtExitModel {
    //This is the model of the object that will be passed on our form in case we have some initial values that need to be inserted
    _PRIVATE: PrivateControlsModel;



    SynIdeMES1?: string;
    SynVerNumMES2?: string;
    MesSenMES3?: string;
    MesRecMES6?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    TesIndMES18?: string;
    IntConRefMES11?: string;
    // PriMES15?: string;
    // ComAgrIdMES17?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    // ComAccRefMES21?: string;

    //
    HEAHEA?: ArrAtExitHeaheaModel;
    ACEACE?: AceAceModel;
    TRAEXICAR?: TraexicarModel;
    //
    constructor(value?) {
        //    this.group1 = group1 ? group1 : {};
        //    this.players = players;
        this._PRIVATE = value?._PRIVATE || null;



        this.SynIdeMES1 = value?.SynIdeMES1 || null;
        this.SynVerNumMES2 = value?.SynVerNumMES2 || null;
        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.IntConRefMES11 = value?.IntConRefMES11 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        //this.PriMES15 = value?.PriMES15 || null;
        //this.ComAgrIdMES17 = value?.ComAgrIdMES17 || null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = value?.MesTypMES20 || 'CC507A';
        //this.ComAccRefMES21 = value?.ComAccRefMES21 || null;
        this.HEAHEA = value?.HEAHEA || {};
        this.ACEACE = value?.ACEACE || {};
        this.TRAEXICAR = value?.TRAEXICAR || {};
    }
}
