import { AbstractControl, FormControl, FormArray, FormGroup } from "@angular/forms";
import { ePaymentContainForm } from "./e-payment-contain-form.model";
import { ePaymentContainModel } from "./e-payment-contain.model";
import { ePaymentICISModel } from "./e-payment-icis.model";

export class ePaymentICISForm {
    [key: string]: AbstractControl;

    wsTin: FormControl;
    wsUser: FormControl;
    wsPswd: FormControl;
    contain: FormArray;
    /*

   */

    constructor(declaration: ePaymentICISModel) {

        this.wsTin = new FormControl(declaration.wsTin);
        this.wsUser = new FormControl(declaration.wsUser);
        this.wsPswd = new FormControl(declaration.wsPswd);

        this.contain = new FormArray([]);


        if (declaration.contain) {
            declaration.contain.forEach((data: ePaymentContainModel) => {
                this.contain.push(new FormGroup(new ePaymentContainForm(new ePaymentContainModel(data))));
            });
        }
    }
}
