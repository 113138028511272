import { _pelatesItem } from 'app/model/api-model';
import { FuseUtils } from '@fuse/utils';

export class EPaymentModel {
    blrn?: string;
    trader?: any //Contact | _pelatesItem;
    stateCode?: string;
    mrns?: string[];
    bamnt?: string;
    date?: string;
    brmt?: string;
    bmsv?: string;
    expired?: boolean;



    //
    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);
        this.blrn = value?.blrn || (new Date().getTime() + FuseUtils.generateGUID()).substring(0, 22);
        this.trader = value?.trader || null;
        this.stateCode = value?.stateCode || null;
        this.mrns = value?.mrns || [];
        this.bamnt = value?.bamnt || null;
        this.date = value?.date || null;
        this.brmt = value?.brmt || null;
        this.bmsv = value?.bmsv || null;
        this.expired = value?.expired || null;

    }
}
