import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { GooIteGdsModel } from 'app/main/apps/export/declaration/_models';
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { User, _pelatesItem } from 'app/model/api-model';
import { ApiService } from 'app/services/api.service';
import { DeclarationStates, EPaymentSignal, MainBroadcasterService } from 'app/services/broadcaster.service';
import { BehaviorSubject, Subject, Observable, of, combineLatest, Subscription, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, share, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ArchiveResult, ArchiveSaveDataCommon } from '../archive/archive.model';
import { eAitisiDeclarationForm } from './_models/declaration/declaration-form.model';
import { eAitisiDeclarationModel } from './_models/declaration/declaration.model';
import { eAitisiGooIteGdsForm } from './_models/gooitegds/gooitegds-form.model';
import { eAitisiGooIteGdsModel } from './_models/gooitegds/gooitegds.model';
import { RefDeclForm } from './_models/refDecl/refDecl-form.model';
import { RefDeclModel } from './_models/refDecl/refDecl.model';
import { eAitisiTasksForm } from './_models/tasks/tasks-form.model';
import { eAitisiTasksModel } from './_models/tasks/tasks.model';
import { eAitisiTaxCalcModel } from './_models/taxCalc/taxcalc.model';
import { eAitisiTaxCalcForm } from './_models/taxCalc/taxcalx-form.model';
import declarationData from 'assets/declarationData.json';
import { ModifyDeclarationService } from 'app/services/submitServices/modify-declaration.service';
import { ChooseDialogComponent } from 'app/main/main-building-blocks/dialogs/choose-dialog/choose-dialog.component';
import { DeclarationStateService } from 'app/services/declaration-state.service';
import { OpenDialogsService, RemittanceInput } from 'app/services/openDialogs.service';
import { CancelDeclarationService } from 'app/services/submitServices/cancel-declaration.service';
import { LrnService } from 'app/services/lrn.service';
import { TaxesRowModel } from '../excise/defk/_models/taxesRow/taxes-row.model';
import { Declarant } from 'app/core/user/user.model';
import { StorageService } from 'app/core/services/storage/storage.service';
import { DeclarationForm } from 'app/main/apps/export/declaration/_models/declaration-form.model';


@Injectable()
export class EAitisiService {
    private eAitisiForm: BehaviorSubject<FormGroup>;
    private _destroy: Subject<void> = new Subject<void>();

    eAitisiForm$: Observable<FormGroup>;

    saveData: FormGroup;
    userId: string;
    declarantId: string;
    traderId: string;
    lrn: string;
    domain: string;
    message: string;
    state: string;
    stateDate: string;
    mrn: string;

    codes: any[];

    metOfPay: string;
    defaultTasks: string[];

    subdomain = 'ICS_DETE';

    declConfig: AutoCompConfigValues = {
        display: { values: ['fullname'] },

        menuOptions: {
            options: ['fullname', 'afm'],
            optionRegular: true
        },
        menuTitles: [null, 'ΑΦΜ: ']

    };

    consConfig: AutoCompConfigValues = {
        display: { values: ['fullname'] },
        menuOptions: { options: ['fullname', 'eori'] }
    };
    customsConfig: AutoCompConfigValues = {
        output: 'ReferenceNumber',
        menuOptions: {
            options: ['CustomsOfficeName', 'ReferenceNumber']
        }
    };

    constructor(
        public matDialog: MatDialog,
        public submitDialogRef: MatDialogRef<ChooseDialogComponent>,
        private fb: FormBuilder,
        private lrnService: LrnService,
        private dateTimeService: DateTransformPipe,
        private mainBroadcaster: MainBroadcasterService,
        private dbQuery: ApiService,
        private openDialogsService: OpenDialogsService,
        private modifyDeclarationService: ModifyDeclarationService,
        private cancelDeclarationService: CancelDeclarationService,
        private declarationStateService: DeclarationStateService,
        private storageService: StorageService
    ) {
        console.log('eAitisi SERVICE INIT');

        this.mainBroadcaster.declarationSettings$.pipe(take(1)).subscribe((settings) => {

            this.metOfPay = settings.eAitisi.methodOfPayment;
            this.defaultTasks = settings.eAitisi.tasks

        })
        const eAitisiForm = this.fb.group(new eAitisiDeclarationForm(new eAitisiDeclarationModel(), this.subdomain));


        this.eAitisiForm = new BehaviorSubject(eAitisiForm);

        if (!this.eAitisiForm$) {
            this.eAitisiForm$ = this.eAitisiForm.asObservable();
        }
    }

    ngOnDestroy() {
        console.log('eAitisi SERVICE DESTROYED');

        this._destroy.next();
        this._destroy.complete();
    }



    fillForm(referrerDeclarationId: string): Observable<[string, FormGroup<DeclarationForm>]> {
        const activeSubdomain$ = this.mainBroadcaster.activeSubdomain$;
        const declarationForms$ = this.mainBroadcaster.declarationForms$;

        return combineLatest([activeSubdomain$, declarationForms$]).pipe(
            map(([subdomain, declarationForms]) => {
                console.log("fillForm activeSubdomain", subdomain)
                const declarationForm = declarationForms[subdomain];
                // console.log("fillForm declarationForm", declarationForm?.value)
                // console.log("fillForm eAitisiForm", this.eAitisiForm.getValue()?.value)
                if (subdomain !== 'ICS_DETE') {
                    this.patchForm(this.eAitisiForm.value, subdomain, declarationForm, referrerDeclarationId);
                }

                return [subdomain, declarationForm]
            })
        );
    }
    getDeclarants() {
        return this.mainBroadcaster.declarants$.pipe(take(1));
    }

    getTraders() {
        return this.mainBroadcaster.domecticCustomers$.pipe(take(1));
    }

    getCustoms(countryCode) {
        return this.dbQuery.get_customs(countryCode).pipe(share(), take(1));
    }

    patchForm(eAitisiForm: FormGroup, subdomain: string, declarationForm: FormGroup, referrerDeclarationId: string) {

        console.log("declarationForm on patchForm", declarationForm)
        if (declarationForm) {
            console.log('eAitisi patchForm eAitisi', eAitisiForm.value);

            const mrn = declarationForm?.get('_PRIVATE').get('mrn').value
            const declarant = declarationForm?.get('_PRIVATE').get('declarant').value;
            const trader = declarationForm?.get('_PRIVATE').get('trader').value;
            let gooitegds = declarationForm?.get('GOOITEGDS') as FormArray;

            let customs;

            if (subdomain === 'EXP_DECL') {

                const cusoff = declarationForm?.get('CUSOFFEXPERT') as FormGroup;
                customs = cusoff.get('RefNumERT1').value;
            } else if (subdomain === 'IMP_DECL') {

                const impcus = declarationForm?.get('IMPCUSOFF') as FormGroup;
                customs = impcus?.get('RefNumIMPCUSOFF').value;
            } else if (subdomain === 'T2L' || subdomain === 'TIR') {

                const impcus = declarationForm?.get('CUSOFFDEPEPT') as FormGroup;
                customs = impcus?.get('RefNumEPT1').value;
            } else if (subdomain === 'DAO') {
                gooitegds = null;

            } else if (subdomain === 'DEFK') {
                customs = declarationForm?.get('Body').get('DeclarationOfExciseTaxesRegistration').get('ExciseTaxesDeclaration').get('RegistrationOffice').value;

                gooitegds = declarationForm?.get('Body').get('DeclarationOfExciseTaxesRegistration').get('ExciseTaxesRow') as FormArray;

            }


            if (referrerDeclarationId) {
                eAitisiForm.get('_PRIVATE').get('referrerDeclarationId').setValue(referrerDeclarationId)
            }

            console.log('eAitisi mrn', mrn);
            console.log('eAitisi patchForm declarationForm', declarationForm?.getRawValue());


            //set DateTime
            const datetime = this.dateTimeService.convertDateTime('yyyyMMddHHmm');
            (eAitisiForm.get('HEAHEA') as FormGroup).get('DecSubDatTimHEA118').setValue(datetime);
            //set first REFDECL
            if (mrn && mrn !== null) {
                (eAitisiForm.get('REFDECL') as FormArray).at(0).get('DocRefNumREFDEC').setValue(mrn);
            }

            //set customsoffice
            eAitisiForm.get('CUSOFF').get('RefNumIMPCUSOFF').setValue(customs);

            //set tradec
            const tradec = eAitisiForm.get('TRADEC') as FormGroup;

            tradec?.get('DeclarantObject').setValue(trader);
            eAitisiForm.get('_PRIVATE').get('trader').setValue(trader)



            if (trader && tradec) {
                this.patchTradec(tradec, trader);
            }

            //set trarep
            const trarep = eAitisiForm.get('TRAREP') as FormGroup;

            trarep?.get('DeclarantObject').setValue(declarant);
            eAitisiForm.get('_PRIVATE').get('declarant').setValue(declarant)



            if (trarep && declarant) {
                this.patchTrarep(trarep, declarant);
            }

            //set gooitegds
            const currentGoods = eAitisiForm.get('GOOITEGDS') as FormArray;
            let i = 0;

            for (let j = currentGoods.length; j >= 0; j--) {
                currentGoods.removeAt(j);
            }
            gooitegds?.getRawValue().forEach(item => {

                const newItem = this.setNewItem(item)

                currentGoods.push(new FormGroup(new eAitisiGooIteGdsForm(new eAitisiGooIteGdsModel(newItem))));

                i++;
            });
            //set default MethodOf Payment
            if (this.metOfPay) {
                eAitisiForm.get('HEAHEA').get('MetOfPayHEA590').setValue(this.metOfPay);
            }
            //set default Tasks
            if (this.defaultTasks) {

                //show Tasks option
                this.eAitisiForm.getValue().get('HEAHEA').get('CustomsTasks').setValue('1')

                this.setDefaultTasks(declarationForm);
            }

            console.log('eAitisi patchForm eAitisiForm', eAitisiForm.value);

            //set LRN
            this.lrnService.setNewLrn(eAitisiForm).subscribe();

        }


        this.eAitisiForm.next(eAitisiForm);

    }

    setNewItem(item: GooIteGdsModel | TaxesRowModel): eAitisiGooIteGdsModel {
        const newItem = new eAitisiGooIteGdsModel();

        let comCodeItem;
        let goodsDesc;
        let statisticValue;

        if ('COMCODGODITM' in item) {
            comCodeItem = item.COMCODGODITM;
            goodsDesc = item.GooDesGDS23;
            statisticValue = item.StaValAmoGDI1;
            newItem.COMCODGODITM = comCodeItem;
            newItem.COMCODGODITM[0]._combinedComCode = item.COMCODGODITM[0].ComNomCMD1 + item.COMCODGODITM[0].TARCodCMD1;

        }
        else if ('ProductDescription' in item) {
            goodsDesc = item.ProductDescription
            statisticValue = item.StatisticalValue
            newItem.COMCODGODITM = [new ComCodModel()];
            newItem.COMCODGODITM[0].ComNomCMD1 = item.TaricCode.substring(0, 8);
            newItem.COMCODGODITM[0].TARCodCMD1 = item.TaricCode.substring(8, 10);
            newItem.COMCODGODITM[0].TARFirAddCodCMD1 = item.TaricAdditionCode;
            newItem.COMCODGODITM[0]._combinedComCode = item.TaricCode
        }


        newItem.GooDesGDS23 = goodsDesc;
        newItem.StaValAmoGDI1 = statisticValue;


        console.log('newItem', newItem);

        return newItem;


    }

    patchTradec(tradec: FormGroup, trader: _pelatesItem) {

        if (trader) {
            tradec.patchValue({
                NamTDE1: trader.fullname,
                StrAndNumTDE1: trader.street,
                PosCodTDE1: trader.postal_code,
                CiTDE1: trader.city,
                CouCodTDE1: trader.country
            });

            /*  if (trader.eori) {
                 tradec.patchValue({
                     IDTypDECL: 10,
                     IDNumDECL: trader.eori
                 });
             } else {
                 tradec.patchValue({
                     IDTypDECL: 20,
                     IDNumDECL: trader.afm
                 });
             }
  */
            //Set afm always because otherwise you can't issue a remittance duo to authorization issues
            tradec.patchValue({
                IDTypDECL: 20,
                IDNumDECL: trader.afm
            });
        } else {
            tradec.patchValue({
                NamTDE1: null,
                StrAndNumTDE1: null,
                PosCodTDE1: null,
                CiTDE1: null,
                CouCodTDE1: null,
                IDTypDECL: null,
                IDNumDECL: null
            });
        }
    }

    patchTrarep(trarep: FormGroup, declarant: Declarant) {

        trarep.patchValue({
            NamTRE1: declarant?.fullname,
            VatNumTRE: declarant?.afm
        });
    }

    addFormArray(formArrayName: string, data?: any) {
        const form = this.eAitisiForm.getValue();
        const currentFormArray = form.get(formArrayName) as FormArray;

        let formGroup;
        switch (formArrayName) {
            case 'REFDECL': {
                formGroup = this.fb.group(new RefDeclForm(new RefDeclModel(data)));
                break;
            }
            case 'GOOITEGDS': {
                formGroup = this.fb.group(new eAitisiGooIteGdsForm(new eAitisiGooIteGdsModel(data)));
                break;
            }
            case 'TAXCALC': {
                formGroup = this.fb.group(new eAitisiTaxCalcForm(new eAitisiTaxCalcModel(data)));
                break;
            }
            case 'TASKS': {
                formGroup = this.fb.group(new eAitisiTasksForm(new eAitisiTasksModel(data)));
                break;
            }
        }
        currentFormArray.push(formGroup);
    }

    deleteFormArray(formArrayName: string, FormArrayIndex: number) {
        const currentDeclaration = this.eAitisiForm.getValue();

        const currentFormArray = currentDeclaration.get(formArrayName) as FormArray;

        currentFormArray.removeAt(FormArrayIndex);
    }

    submit() {
        this.openDialogsService.openSubmitDialog(this.eAitisiForm.getValue()).subscribe();

        console.log('declaration END OF submit() on GeneralComponent component');
    }

    //--=================================================================
    //--=================================================================
    loadSavedForm(declaration: ArchiveSaveDataCommon) {
        const eAitisiForm = this.fb.group(new eAitisiDeclarationForm(new eAitisiDeclarationModel(declaration.declarationForm), this.subdomain));

        this.mainBroadcaster.updateDeclarationForms({ [this.subdomain]: eAitisiForm });

        this.eAitisiForm.next(eAitisiForm);
    }
    updateForm(data: eAitisiDeclarationModel) {
        console.log('updateForm', data);

        const declForm = this.fb.group(new eAitisiDeclarationForm(new eAitisiDeclarationModel(data), this.subdomain));

        //setting localStorage here because when setTotalItems is called
        this.storageService.setLocalObject(declarationData[this.subdomain].localStorage, declForm.value);


        this.mainBroadcaster.updateDeclarationForms({ [this.subdomain]: declForm });

        this.eAitisiForm.next(declForm);

        this.declarationStateService.transmitStateCode(declForm);

        const stateCode = declForm.get('_PRIVATE').get('stateCode').value;

        console.log("declFormService updataForm stateCode", stateCode)

        if (stateCode !== 'Pre-Submitted' && stateCode !== 'Edit') {

            this.storageService.removeLocalObject(declarationData[this.subdomain].localStorage)
        }
    }

    onRefusedEdit() {

        return this.mainBroadcaster.revertToPrevious$
            .pipe(
                switchMap(() => {
                    return this.mainBroadcaster.declarationStates$.pipe(switchMap((allStates) => {

                        if (allStates) {
                            const states = allStates[this.subdomain];
                            const prevCode = states.previous.stateCode;
                            const currCode = states.current.stateCode
                            console.log("EAITISI revertToPrevious states", states)

                            return this.returnToPreviousState()
                        }
                        else {
                            return of(null)
                        }
                    }))
                }),
                takeUntil(this._destroy),

            )
    }


    returnToPreviousState(): Observable<void> {

        return of(this.cancelEdit())
    }


    cancelEdit() {
        console.log("CancelEdit generalCOmponent FUNCTION")

        this.mainBroadcaster.declarationForms$.pipe(map((forms, index) => [forms, index]), take(2)).subscribe(([forms, index]) => {

            console.log("cancelEdit patchDeclForm forms", forms)
            console.log("cancelEdit patchDeclForm subdomain", this.subdomain)

            if (index === 0) {
                const declForm = forms[this.subdomain];

                const mrn = declForm.get('_PRIVATE').get('mrn').value


                const data = this.storageService.getLocalObject(mrn)

                console.log("cancelEdit patchDeclForm index", index)
                console.log("cancelEdit patchDeclForm declarationForm befor", declForm.value)
                console.log("cancelEdit patchDeclForm data", data)

                this.updateForm(data)
                this.storageService.removeLocalObject(mrn)
            }
            else {
                const declForm = forms[this.subdomain];
                console.log("cancelEdit patchDeclForm index", index)
                console.log("cancelEdit patchDeclForm declForm", declForm.getRawValue())

                this.declarationStateService.saveForm(this.subdomain, declForm)

            }

        })

    }

    setDefaultTasks(declarationForm) {

        const TASKS = this.eAitisiForm.getValue().get('TASKS') as FormArray;

        for (let i = 0; i < this.defaultTasks.length; i++) {

            if (!TASKS.at(i)) {
                this.addFormArray('TASKS');
            }
            this.setCustomsTaskType(declarationForm, i)
            // TASKS.at(i).get('CustomsTaskType').setValue(this.defaultTasks[i])

        };

    }
    setCustomsTaskType(declarationForm: FormGroup, index: number) {

        let taskType = '01';
        const submissionDate = declarationForm?.get('_PRIVATE').get('submissionDate').value;
        const physicalCheck = declarationForm?.get('_PRIVATE').get('data').get('underControl').value === '1';
        //if time now is weekday after 15:00 set customsTaskType to 01

        console.log("setCustomsTaskType submissionDate", new Date(submissionDate * 1000))
        if (this.isWeekdayNormalHours(submissionDate) && physicalCheck) {
            taskType = '05';
        }
        else if (this.isWeekdayAfterThree(submissionDate)) {

            taskType = '01';
            if (physicalCheck) {
                taskType = '06';
            }
        }
        else if (this.isWeekendBetweenSixAndTen(submissionDate)) {
            taskType = '03';
            if (physicalCheck) {
                taskType = '08';
            }
        }

        const tasks = this.eAitisiForm.getValue().get('TASKS') as FormArray;

        tasks.at(index).get('CustomsTaskType').setValue(taskType);
    }

    isWeekdayNormalHours(submissionDate: number) {
        // Convert the Unix timestamp to a JavaScript Date object
        const date = new Date(submissionDate * 1000); // Convert seconds to milliseconds

        const day = date.getDay(); // Returns a number from 0 (Sunday) to 6 (Saturday)
        const hour = date.getHours(); // Returns the hour (0 to 23)

        // Check if it's a weekday (1-5) and  08:00-15:00
        return day >= 1 && day <= 5 && hour >= 8 && hour <= 15;
    }

    isWeekdayAfterThree(submissionDate: number) {
        // Convert the Unix timestamp to a JavaScript Date object
        const date = new Date(submissionDate * 1000); // Convert seconds to milliseconds

        const day = date.getDay(); // Returns a number from 0 (Sunday) to 6 (Saturday)
        const hour = date.getHours(); // Returns the hour (0 to 23)

        // Check if it's a weekday (1-5) and after 15:00
        return day >= 1 && day <= 5 && hour >= 15;
    }

    isWeekendBetweenSixAndTen(submissionDate: number) {
        // Convert the Unix timestamp to a JavaScript Date object
        const date = new Date(submissionDate * 1000); // Convert seconds to milliseconds

        const day = date.getDay(); // Returns a number from 0 (Sunday) to 6 (Saturday)
        const hour = date.getHours(); // Returns the hour (0 to 23)

        // Check if it's a weekend (0 or 6) and between 06:00 and 22:00
        return (day === 0 || day === 6) && hour >= 6 && hour < 22;
    }

    removeLocalMRN(mrn: string) {
        this.storageService.removeLocalObject(mrn)
    }

}
