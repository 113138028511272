import { FormControl, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { GooIteGdsModel } from './gooitegds.model';
import { PreAdmRefModel } from './preadmrefar2/preadmrefar2.model';
import { PreAdmRefForm } from './preadmrefar2/preadmrefar2-form.model';
import { ProDocModel } from './prodocdc2/prodocdc2.model';
import { ProDocForm } from './prodocdc2/prodocdc2-form.model';
import { SpeMenModel } from './spemenmt2/spemenmt2.model';
import { SpeMenForm } from './spemenmt2/spemenmt2-form.model';
import { TraConexModel } from './traconex2/traconex2.model';
import { TraConce2Form } from './traconce2/traconce2-form.model';
import { TraConexForm } from './traconex2/traconex2-form.model';
import { TraConce2Model } from './traconce2/traconce2.model';
import { ComCodModel } from './comcodgoditm/comcodgodtm.model';
import { ComCodForm } from './comcodgoditm/comcodgodtm-form.model';
import { CalTaxModel } from './caltaxgod/caltaxgod.model';
import { Connr2Model } from './connr2/connr2.model';
import { Connr2Form } from './connr2/connr2-form.model';
import { PacgsModel } from './pacgs2/pacgs2.model';
import { PacgsForm } from './pacgs2/pacgs2-form.model';
import { GrnModel } from './grn/grn.model';
import { GrnForm } from './grn/grn-form.model';
import { VehDetForm } from './vehdet/vehdet-form.model';
import { NatProModel } from './natpro/natpro.model';
import { NatProForm } from './natpro/natpro-form.model';
import { BasProModel } from './baspro/baspro.model';
import { BasProForm } from './baspro/baspro-form.model';
import { CalTaxForm } from './caltaxgod/caltaxgod-form.model';
import { VehDetModel } from './vehdet/vehdet.model';
import { AccountingModel } from './accounting/accounting.model';
import { AccountingForm } from './accounting/accounting-form.model';

export class GooIteGdsForm {
    [key: string]: AbstractControl;

    IteNumGDS7: FormControl;
    GooDesGDS23: FormControl;
    GooDesGDS23LNG: FormControl;
    GroMasGDS46: FormControl;
    NetMasGDS48: FormControl;
    CouOfDisGDS58: FormControl;
    CouOfDesGDS59: FormControl;
    MetOfPayGDI12: FormControl;
    ProReqGDI1: FormControl;
    PreProGDI1: FormControl;
    ComNatProGIM1: FormControl;
    StaValCurGDI1: FormControl;
    StaValAmoGDI1: FormControl;
    CouOfOriGDI1: FormControl;
    DisProRegGDI1: FormControl;
    SupUniGDI1: FormControl;
    TotIteTaxAmoGDI1: FormControl;
    ComRefNumGIM1: FormControl;
    UNDanGooCodGDI1: FormControl;
    IdeMeaTraDepGDS1030: FormControl;
    IdeMeaTraDepGDS1030LNG: FormControl;
    IdeMeaTraCroBorGDS1031: FormControl;
    IdeMeaTraCroBorGDS1031LNG: FormControl;
    NatMeaTraCroBorGDS1032: FormControl;
    TypMeaTraCroBorGDS1033: FormControl;
    ProReqEndDat4003: FormControl;
    PREADMREFAR2: FormArray;
    PRODOCDC2: FormArray;
    SPEMENMT2: FormArray;
    // TRACONEX2: FormGroup;
    TRACONEX2: FormArray;
    // COMCODGODITM: FormGroup;
    COMCODGODITM: FormArray;
    CALTAXGOD: FormArray;
    // TRACONCE2: FormGroup;
    TRACONCE2: FormArray;
    CONNR2: FormArray;
    PACGS2: FormArray;
    GRN: FormArray;
    VEHDET: FormArray;
    NATPRO: FormArray;
    BASPRO: FormArray;

    ACCOUNTING: FormArray;

    constructor(goods: GooIteGdsModel) {
        //console.trace("Who creates GooItegds");
        // console.log("___GooIteGdsFormModel constructor", goods);

        this.IteNumGDS7 = new FormControl(goods.IteNumGDS7, {
            updateOn: 'blur'
        });
        this.GooDesGDS23 = new FormControl(goods.GooDesGDS23, {
            updateOn: 'blur'
        });
        this.GooDesGDS23LNG = new FormControl(goods.GooDesGDS23LNG);
        this.GroMasGDS46 = new FormControl(goods.GroMasGDS46, {
            updateOn: 'blur'
        });
        this.NetMasGDS48 = new FormControl(goods.NetMasGDS48, {
            updateOn: 'blur'
        });
        this.CouOfDisGDS58 = new FormControl(goods.CouOfDisGDS58);
        this.CouOfDesGDS59 = new FormControl(goods.CouOfDesGDS59);
        this.MetOfPayGDI12 = new FormControl(goods.MetOfPayGDI12);
        this.ProReqGDI1 = new FormControl(goods.ProReqGDI1);
        this.PreProGDI1 = new FormControl(goods.PreProGDI1);
        this.ComNatProGIM1 = new FormControl(goods.ComNatProGIM1);
        this.StaValCurGDI1 = new FormControl(goods.StaValCurGDI1, {
            updateOn: 'blur'
        });
        this.StaValAmoGDI1 = new FormControl(goods.StaValAmoGDI1, {
            updateOn: 'blur'
        });
        this.CouOfOriGDI1 = new FormControl(goods.CouOfOriGDI1);
        this.DisProRegGDI1 = new FormControl(goods.DisProRegGDI1, {
            updateOn: 'blur'
        });
        this.SupUniGDI1 = new FormControl(goods.SupUniGDI1, {
            updateOn: 'blur'
        });
        this.TotIteTaxAmoGDI1 = new FormControl(goods.TotIteTaxAmoGDI1, {
            updateOn: 'blur'
        });
        this.ComRefNumGIM1 = new FormControl(goods.ComRefNumGIM1, {
            updateOn: 'blur'
        });
        this.UNDanGooCodGDI1 = new FormControl(goods.UNDanGooCodGDI1);
        this.IdeMeaTraDepGDS1030 = new FormControl(goods.IdeMeaTraDepGDS1030, {
            updateOn: 'blur'
        });
        this.IdeMeaTraDepGDS1030LNG = new FormControl(goods.IdeMeaTraDepGDS1030LNG, { updateOn: 'blur' });
        this.IdeMeaTraCroBorGDS1031 = new FormControl(goods.IdeMeaTraCroBorGDS1031, { updateOn: 'blur' });
        this.IdeMeaTraCroBorGDS1031LNG = new FormControl(goods.IdeMeaTraCroBorGDS1031LNG, { updateOn: 'blur' });
        this.NatMeaTraCroBorGDS1032 = new FormControl(goods.NatMeaTraCroBorGDS1032);
        this.TypMeaTraCroBorGDS1033 = new FormControl(goods.TypMeaTraCroBorGDS1033);
        this.ProReqEndDat4003 = new FormControl(goods.ProReqEndDat4003, {
            updateOn: 'blur'
        });
        this.PREADMREFAR2 = new FormArray([]);
        this.PRODOCDC2 = new FormArray([]);
        this.SPEMENMT2 = new FormArray([]);
        this.TRACONEX2 = new FormArray([]);
        this.COMCODGODITM = new FormArray([]);
        this.CALTAXGOD = new FormArray([]);
        this.TRACONCE2 = new FormArray([]);
        this.CONNR2 = new FormArray([]);
        this.PACGS2 = new FormArray([]);
        this.GRN = new FormArray([]);
        this.VEHDET = new FormArray([]);
        this.NATPRO = new FormArray([]);
        this.BASPRO = new FormArray([]);
        this.ACCOUNTING = new FormArray([]);

        if (goods.PREADMREFAR2) {
            goods.PREADMREFAR2.forEach((prevdocs: PreAdmRefModel) => {
                this.PREADMREFAR2.push(new FormGroup(new PreAdmRefForm(new PreAdmRefModel(prevdocs))));
            });
        } else {

            this.PREADMREFAR2.push(new FormGroup(new PreAdmRefForm(new PreAdmRefModel())));
        }

        if (goods.PRODOCDC2) {
            goods.PRODOCDC2.forEach((prodocs: ProDocModel) => {
                this.PRODOCDC2.push(new FormGroup(new ProDocForm(new ProDocModel(prodocs))));
            });
        } else {
            this.PRODOCDC2.push(new FormGroup(new ProDocForm(new ProDocModel())));
        }

        if (goods.SPEMENMT2) {
            goods.SPEMENMT2.forEach((spemen: SpeMenModel) => {
                this.SPEMENMT2.push(new FormGroup(new SpeMenForm(new SpeMenModel(spemen))));
            });
        } else {
            this.SPEMENMT2.push(new FormGroup(new SpeMenForm(new SpeMenModel())));
        }

        if (goods.TRACONEX2) {
            goods.TRACONEX2.forEach((traconex: TraConexModel) => {
                this.TRACONEX2.push(new FormGroup(new TraConexForm(new TraConexModel(traconex))));
            });
        } else {
            this.TRACONEX2.push(new FormGroup(new TraConexForm(new TraConexModel())));
        }

        if (goods.COMCODGODITM) {
            goods.COMCODGODITM.forEach((comcod: ComCodModel) => {
                this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel(comcod))));
            });
        } else {
            this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel())));
        }
        this.COMCODGODITM.at(0).get('NAtAddCodCMD1').setValue('0000');
        this.COMCODGODITM.at(0).get('NAtAddCodCMD1').disable();

        if (goods.CALTAXGOD) {
            goods.CALTAXGOD.forEach((caltax: CalTaxModel) => {
                this.CALTAXGOD.push(new FormGroup(new CalTaxForm(new CalTaxModel(caltax))));
            });
        }

        if (goods.TRACONCE2) {
            goods.TRACONCE2.forEach((traconce: TraConce2Model) => {
                this.TRACONCE2.push(new FormGroup(new TraConce2Form(new TraConce2Model(traconce))));
            });
        } else {
            this.TRACONCE2.push(new FormGroup(new TraConce2Form(new TraConce2Model())));
        }

        //FORMARRAYS

        if (goods.CONNR2) {
            goods.CONNR2.forEach((connr: Connr2Model) => {
                this.CONNR2.push(new FormGroup(new Connr2Form(new Connr2Model(connr))));
            });
        } else {
            this.CONNR2.push(new FormGroup(new Connr2Form(new Connr2Model())));
        }
        if (goods.PACGS2) {
            goods.PACGS2.forEach((pacgs: PacgsModel) => {
                this.PACGS2.push(new FormGroup(new PacgsForm(new PacgsModel(pacgs))));
            });
        } else {
            this.PACGS2.push(new FormGroup(new PacgsForm(new PacgsModel())));
        }
        if (goods.GRN) {
            goods.GRN.forEach((grn: GrnModel) => {
                this.GRN.push(new FormGroup(new GrnForm(new GrnModel(grn))));
            });
        }
        if (goods.VEHDET) {
            goods.VEHDET.forEach((vehdet: VehDetModel) => {
                this.VEHDET.push(new FormGroup(new VehDetForm(new VehDetModel(vehdet))));
            });
        }
        if (goods.NATPRO) {
            goods.NATPRO.forEach((natpro: NatProModel) => {
                this.NATPRO.push(new FormGroup(new NatProForm(new NatProModel(natpro))));
            });
        }
        if (goods.BASPRO) {
            goods.BASPRO.forEach((baspro: BasProModel) => {
                this.BASPRO.push(new FormGroup(new BasProForm(new BasProModel(baspro))));
            });
        }

        if (goods.ACCOUNTING) {
            goods.ACCOUNTING.forEach((accounting: AccountingModel) => {
                this.ACCOUNTING.push(new FormGroup(new AccountingForm(new AccountingModel(accounting))));
            });
        }
    }
}
