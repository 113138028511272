import { NgModule } from '@angular/core';
import { CarsComponent } from './cars.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { ContactFormModule } from 'app/main/apps/contacts/contact-form/contact-form.module';
import { AddDelButtonsModule } from 'app/main/main-building-blocks/_scams/add-del-buttons/add-del-buttons.module';
import { FormFieldsModule } from 'app/main/main-building-blocks/form-fields/form-fields.module';
import { DigitOnlyModule } from 'app/main/projects/uiowa/digit-only/src/public_api';
import { CarsHeaderComponent } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars-header/cars-header.component';
import { CarsHeaderInputsComponent } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars-header/cars-header-inputs/cars-header-inputs.component';
import { CarsHeaderReductionsComponent } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars-header/cars-header-reductions/cars-header-reductions.component';
import { CarsHeaderParametersComponent } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars-header/cars-header-parameters/cars-header-parameters.component';
import { CarsService } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars.service';
import { CarsCalculationDataComponent } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars-calculation-data/cars-calculation-data.component';
import { CodeToDescPipeModule } from 'app/services/pipes/codeToDesc.pipe';

@NgModule({
    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        AddDelButtonsModule,
        DigitOnlyModule,
        ContactFormModule,
        CodeToDescPipeModule],
    declarations: [CarsComponent, CarsHeaderComponent, CarsHeaderInputsComponent, CarsHeaderReductionsComponent, CarsHeaderParametersComponent, CarsCalculationDataComponent],
    exports: [CarsHeaderComponent, CarsCalculationDataComponent]
})
export class CarsModule { }
