import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { CancelModel } from './cancel.model';

import {
    CusoffexpertForm,
    CusoffexpertModel,
    TradecForm,
    TradecModel,
    Traexpex1Form,
    Traexpex1Model
} from 'app/main/apps/export/declaration/_models';
import { CancelHeaheaForm } from './heahea/heahea-form.model';
import { CancelHeaheaModel } from './heahea/heahea.model';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';

export class CancelForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    SynIdeMES1: FormControl;
    SynVerNumMES2: FormControl;
    MesSenMES3: FormControl;
    MesRecMES6: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    IntConRefMES11: FormControl;
    TesIndMES18: FormControl;
    // PriMES15: FormControl;
    // ComAgrIdMES17: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    // ComAccRefMES21: FormControl;
    HEAHEA: FormGroup;
    CUSOFFEXPERT: FormGroup;
    TRADEC: FormGroup;
    TRAEXPEX1: FormGroup;
    /*

   */

    constructor(cancel: CancelModel, subdomain?: string, messageType?: string) {
        let privateControls: string | PrivateControlsModel = subdomain;

        if (cancel._PRIVATE) {
            privateControls = cancel._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));


        this.SynIdeMES1 = new FormControl(cancel.SynIdeMES1);
        this.SynVerNumMES2 = new FormControl(cancel.SynVerNumMES2);
        this.MesSenMES3 = new FormControl(cancel.MesSenMES3);
        this.MesRecMES6 = new FormControl(cancel.MesRecMES6);
        this.DatOfPreMES9 = new FormControl(cancel.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(cancel.TimOfPreMES10);
        this.IntConRefMES11 = new FormControl(cancel.IntConRefMES11);
        this.TesIndMES18 = new FormControl(cancel.TesIndMES18);
        this.MesIdeMES19 = new FormControl(cancel.MesIdeMES19);
        this.MesTypMES20 = new FormControl(cancel.MesTypMES20);

        this.HEAHEA = new FormGroup(new CancelHeaheaForm(new CancelHeaheaModel(cancel.HEAHEA)));

        this.CUSOFFEXPERT = new FormGroup(new CusoffexpertForm(new CusoffexpertModel(cancel.CUSOFFEXPERT)));

        this.TRADEC = new FormGroup(new TradecForm(new TradecModel(cancel.TRADEC)));

        this.TRAEXPEX1 = new FormGroup(new Traexpex1Form(new Traexpex1Model(cancel.TRAEXPEX1)));
    }
}
