import { FormControl, Validators } from '@angular/forms';
import { CusoffdepeptModel } from './cusoffdepept.model';

export class CusoffdepeptForm {
    [key: string]: FormControl;
    _countryCode = new FormControl();
    RefNumEPT1 = new FormControl();

    constructor(value: CusoffdepeptModel) {
        this._countryCode.setValue(value._countryCode);
        this.RefNumEPT1.setValue(value.RefNumEPT1);

        this.RefNumEPT1.setValidators(Validators.required);
    }
}
