import { Route, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { OfficeGuard } from 'app/services/guards/office.guard';
import { AppResolver } from 'app/app.resolver';
import { RedirectComponent } from 'app/app-redirect.component';
import { OfficeResolver } from 'app/office.resolver';
import { BusinessResolver } from 'app/main/businesses/business.resolver';
import { BusinessGuard } from 'app/main/businesses/services/guards/business.guard';
import { BlankComponent } from 'app/blank.component';

export const appRoutes: Route[] = [
    // Redirect empty path to '/home'
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    // Redirect signed in user to the '/apps'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', component: RedirectComponent },
    { path: 'blank', pathMatch: 'full', component: BlankComponent },

    { path: 'home', loadChildren: () => import('app/main/landing/home/home.module').then(m => m.LandingHomeModule) },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/main/pages/authentication/mail-confirm/mail-confirm.module').then(m => m.MailConfirmModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import('app/main/pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import('app/main/pages/authentication/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/main/pages/authentication/login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/main/pages/authentication/register/register.module').then(m => m.RegisterModule)
            }
        ]
    },
    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'unlock-session',
                loadChildren: () => import('app/main/pages/authentication/lock/lock.module').then(m => m.LockModule)
            },
            {
                path: 'b/:businessId',
                canLoad: [BusinessGuard],
                resolve: { appResolver: AppResolver, businessResolver: BusinessResolver },
                loadChildren: () => import('app/main/businesses/businesses.module').then(m => m.BusinessesModule)
            }, {
                path: ':officeId',
                canLoad: [OfficeGuard],
                resolve: { appResolver: AppResolver, officeResolver: OfficeResolver },
                loadChildren: () => import('app/main/apps/apps.module').then(m => m.AppsModule),
            }

        ]
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];
