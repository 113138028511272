import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, of, Subject } from 'rxjs';
import { ApiService } from 'app/services/api.service';
import { take, concatMap, takeUntil, map, switchMap, finalize, tap } from 'rxjs/operators';
import { HttpSuccessService } from 'app/services/http-success.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SaveDataForm, SaveDataModel } from '../main/apps/export/declaration/_models';
import { OriginCertModel } from '../main/apps/export/origin-cert/_models/origin-cert.model';
import { AgrCertModel } from '../main/apps/export/agrcert/_models/agr.model';
import { CancelDeclarationComponent } from 'app/main/apps/export/cancel-declaration/cancel-declaration.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RouteStateService } from 'app/services/routeState.service';
import { UserService } from 'app/core/user/user.service';
import { ChooseDialogComponent } from 'app/main/main-building-blocks/dialogs/choose-dialog/choose-dialog.component';
import { Declaration } from '../main/apps/export/declaration/_models/declaration.model';
import { CmrModel } from 'app/main/apps/export/cmr/_models/cmr.model';
import { Params, } from '@angular/router';
import { DeclarationStates, MainBroadcasterService } from './broadcaster.service';
import { ToastrService } from 'ngx-toastr';
import declarationData from 'assets/declarationData.json';
import { Location } from '@angular/common';
import { ArchiveTabType } from 'app/main/apps/archive/archive.service';
import { BaseSaveFormParams, SaveAsService } from 'app/services/saveAs.service';
import { StorageService } from 'app/core/services/storage/storage.service';

export interface SaveFormResult {
    id: string;
    type: ArchiveTabType;
    lrn?: string;
    id_to_delete?: string;
    originalDocType?: ArchiveTabType;
    noNotification?: boolean;
}
export interface SaveFormParams extends BaseSaveFormParams {
    subdomain: string;
    systemCall: boolean;// called by system or called bu user
    noNotification?: boolean;
    declarationForm?: FormGroup;
}

@Injectable({ providedIn: 'root' })
export class SaveFormService {
    saveDataForm$: Observable<FormGroup<SaveDataForm>>;

    saveDeclarationData: Declaration;
    saveData: FormGroup<Partial<SaveDataForm>>;
    userId: string;
    declarantId: string;
    traderId: string;
    internationalId: string;
    lrn: string;
    domain: string;
    subdomain: string;
    messageType: string;
    messageIn: string;
    state: string;
    stateDate: string;
    mrn: string;
    declarant: string;
    documentType: ArchiveTabType;
    submissionDate: string;
    postEur1Form: OriginCertModel;
    postInf4Form: OriginCertModel;
    postAgrCertForm: AgrCertModel;
    postCmrForm: CmrModel;
    //declaration id and originalDoctype are used
    // to delete drafts after successfully submitting the form
    originalDocType: string;
    originalDocId: string;
    referrerDeclarationId: string;
    declarationId: string;
    officeId: string;
    pathDomain: string;
    pathSubdomain: string;
    type: string;
    templateDescription: string;
    noNotification: boolean;

    localStorageItem: string;
    routeParams$: Observable<Params>;
    private _destroy: Subject<void>;

    constructor(
        private fb: FormBuilder,
        //  private declFormService: ExpDeclarationFormService,
        private dbQuery: ApiService,
        private httpSuccess: HttpSuccessService,
        private userService: UserService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CancelDeclarationComponent>,
        public saveTemplateDialogRef: MatDialogRef<ChooseDialogComponent>,
        private routeStateService: RouteStateService,
        private mainBroadcaster: MainBroadcasterService,
        private saveAsService: SaveAsService,
        private toastrService: ToastrService,
        private location: Location,
        private storageService: StorageService
    ) {
        console.log('SERVICE saveForm Started');

        this._destroy = new Subject<void>();

        this.userService.user$.pipe(takeUntil(this._destroy)).subscribe(user => {
            this.userId = user.id;
        });

        this.routeParams$ = this.routeStateService.pathParam$;
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }
    //SAVE FORM=====================================
    saveForm(args: SaveFormParams): Observable<any> {

        console.log('SAVE FORM CALLED');
        console.log('SAVE FORM arguments', args);

        this.localStorageItem = declarationData[args.subdomain].localStorage;

        if (args.subdomain === 'ICS_DETE' || args.subdomain === 'ARR_AT_EXIT') {
            return this.save(args);
        }

        return this.mainBroadcaster.declarationStates$.pipe(
            switchMap((declarationStates: DeclarationStates) => {
                const state = declarationStates[args.subdomain].current;
                console.log('SAVE FORM STATE on SUBSCRIBE', state);


                //If you try to save a declaration that is already submitted(it is not in edit mode), save as template
                if (state.stateCode === 'Edit' && !args.systemCall) {
                    this.toastrService.warning('Η διασάφηση δεν μπορεί να αποθηκευτεί όταν βρίσκεται υπό τροποποίηση.', '', { timeOut: 4000 });
                    return of(null);
                }
                //
                if (args.templateDescription) {
                    return this.save(args);
                }
                //If you try to save a declaration that is already submitted(it is not in edit mode), save as template
                if (!args.noNotification && state.stateCode !== 'Pre-Submitted') {
                    return this.saveAsTemplateDialog(args);
                }

                return this.save(args);
            }),
            take(1));
    }

    save(args: SaveFormParams, shouldReturnDataOnly = false): Observable<any> {

        console.log("saveFormX save args", args)

        this.subdomain = args.subdomain;

        this.saveData = this.fb.group(new SaveDataForm(new SaveDataModel())) as FormGroup;

        if (this.subdomain === 'EXP_DECL') {

            this.getOtherExportForms();


        }

        this.getDocumentSpecificValues(args);

        const declarationForm$ = this.mainBroadcaster.declarationForms$.pipe(
            map(declarationForms => declarationForms[this.subdomain]),
            tap(declarationForm => console.log("broadcaster declarationForm  on save", declarationForm.getRawValue())),
            take(1)
        );

        return declarationForm$
            .pipe(
                concatMap((declarationForm: FormGroup) => {
                    console.log("declarationForm on save", declarationForm.getRawValue())

                    this.getDeclarationSpecificValues(declarationForm);

                    this.patchSaveForm(args);

                    // Check the flag and return data or save it
                    if (shouldReturnDataOnly) {
                        return of(this.saveData.value);
                    }

                    return this.dbQuery.saveForm(this.saveData.value);
                })
            )
            .pipe(
                concatMap((result: SaveFormResult) => {
                    console.log('saveFormX saveResult', result);

                    // Check the flag and return data or save it
                    if (shouldReturnDataOnly) {
                        return of(this.saveData.value);
                    }

                    //remove localstorage after  succesfull Save of Submitted Declaration
                    if (this.state !== 'Pre-Submitted' && this.state !== 'Edit') {
                        this.storageService.removeLocalObject(this.localStorageItem);
                    }

                    //Show succes notification in case of manual save
                    //no Notifications shown when auto save
                    if (result.noNotification !== true) {
                        this.httpSuccess.saveSuccess(result, this.documentType, this.templateDescription);
                    }

                    // If manual save, Update the URL without reloading the component
                    // if (!args.systemCall) {
                    const url = `/${this.officeId}/${this.pathDomain}/${this.pathSubdomain}/${result.type}/${result.id}`
                    this.location.go(url);

                    //Update the routeStateService with the new route params
                    this.routeStateService.updateRouteParams({ type: result.type, id: result.id })
                    //}

                    //and we delete from the draft declaration table
                    if (result.id_to_delete && result.originalDocType === 'draft') {
                        return this.dbQuery.delete_declaration(result.originalDocType, result.id_to_delete).pipe(take(1));
                    } else {
                        return of([]);
                    }

                })
            )
    }

    getOtherExportForms() {

        const eur1Form$ = this.mainBroadcaster.eur1Form$.pipe(take(1));
        const inf4Form$ = this.mainBroadcaster.inf4Form$.pipe(take(1));
        const cmrForm$ = this.mainBroadcaster.cmrForm$.pipe(take(1));
        const agrCertForm$ = this.mainBroadcaster.agrCertForm$.pipe(take(1));

        combineLatest([eur1Form$, inf4Form$, agrCertForm$, cmrForm$])
            .pipe(take(1))
            .subscribe(([eur1Form, inf4Form, agrCertForm, cmrForm]) => {
                console.log('saveFormX eur1Form', eur1Form);
                console.log('saveFormX agrCertForm', agrCertForm);

                this.postEur1Form = eur1Form?.LRN !== null ? eur1Form : null;
                this.postCmrForm = cmrForm?.LRN !== null ? cmrForm : null;
                this.postInf4Form = inf4Form?.LRN !== null ? inf4Form : null;

                this.postAgrCertForm = agrCertForm?.LRN !== null ? agrCertForm : null;
            });
    }

    getDocumentSpecificValues(args: SaveFormParams) {
        this.routeParams$.pipe(take(1)).subscribe(routeParams => {
            console.log('saveFormX getDocumentSpecificValues routeParams', routeParams);
            console.log('saveFormX getDocumentSpecificValues args', args);

            this.officeId = routeParams.officeId;
            this.pathDomain = routeParams.domain;
            this.pathSubdomain = routeParams.subdomain;
            this.declarationId = routeParams.id;
            this.originalDocType = routeParams.type;

            if (args.type === 'template' && args.action === 'update') {
                this.declarationId = args.id;
                this.originalDocType = args.type;

            }

            if (args.type === 'draft' && args.action === 'create') {

                this.declarationId = null;
            }

        });
    }

    getDeclarationSpecificValues(declarationForm) {

        const _PRIVATE = declarationForm.get('_PRIVATE') as FormGroup

        this.subdomain = _PRIVATE.get('subdomain').value;

        const mrn: FormControl = _PRIVATE.get('mrn') as FormControl
        this.messageType = _PRIVATE.get('messageOut').value
        this.messageIn = _PRIVATE.get('messageIn').value

        this.declarantId = _PRIVATE.get('declarant').value?.afm
        this.traderId = _PRIVATE.get('trader').value?.afm
        this.internationalId = _PRIVATE.get('international')?.value?.id
        this.referrerDeclarationId = _PRIVATE.get('referrerDeclarationId')?.value

        this.mrn = mrn ? mrn.value : null;
        this.lrn = _PRIVATE.get('lrn').value;
        this.domain = _PRIVATE.get('domain').value;
        this.state = _PRIVATE.get('stateCode').value;
        this.stateDate = _PRIVATE.get('stateDate').value;

        const submissionDate = _PRIVATE.get('submissionDate').value;

        if (submissionDate && submissionDate !== null) {
            this.submissionDate = submissionDate;
        }

        this.saveDeclarationData = declarationForm.getRawValue();

        console.log('saveForm declarationForm', this.saveDeclarationData);
    }

    patchSaveForm(args) {
        this.documentType = args.type;
        this.templateDescription = args.templateDescription;
        this.noNotification = args.noNotification;

        /*      if (this.documentType) {
                 console.log('');
             } else if (this.state !== 'Pre-Submitted' && !this.documentType) {
                 this.documentType = 'all';
             } else {
                 if (!this.documentType || this.documentType !== 'all') {
                     this.documentType = 'draft';
                     console.log('type from state:', this.documentType);
                 }
                 if (this.domain === 'ECS') {
                     this.messageType = 'CC515A';
                 } else if (this.domain === 'IMP') {
                     this.messageType = 'ID15A';
                 }
             }
      */

        if (!this.documentType) {
            if (this.state !== 'Pre-Submitted') {
                this.documentType = 'all';
            } else {
                this.documentType = (this.documentType === 'all') ? 'all' : 'draft';
                console.log('type from state:', this.documentType);

                if (this.domain === 'ECS') {
                    this.messageType = 'CC515A';
                } else if (this.domain === 'IMP') {
                    this.messageType = 'ID15A';
                }
            }
        }

        //First Response from server set submission date
        if (
            this.messageType === 'CC515A' ||
            this.messageType === 'CC015B' ||
            this.messageType === 'ID15A' ||
            this.messageType === 'IB15A' ||
            this.messageType === 'EF15A' ||
            this.messageType === 'VA15A' ||
            this.messageType === 'TF015A'
        ) {
            switch (this.state) {

                case 'Submitted':
                case 'Under Amendment':
                case 'Under Cancellation':
                case 'Cancellation Submitted':
                case 'Under Amendment Request':
                case 'Under Cancellation Request':
                case 'Pending Reception Registration':
                case 'Pending Amendment':
                case 'Pending Cancellation':
                case 'Pending Registration': {
                    this.submissionDate = this.stateDate;

                }
            }


        }

        //If we try to save a loaded template as draft, we set the declarationId to null, since the id is the template's id and backend will try to update the draft with the provided id and not create a new one
        if (this.originalDocType === 'template' && this.documentType === 'draft') {
            this.declarationId = null;
        }

        //Each time we create a new template, we set the declarationId to null, so that the backend will create a new template and not update the existing one
        if (this.documentType === 'template' && args.action === 'create') {
            this.declarationId = null
        }

        /*   //If we try to save a submitted declaration(all) and the originalDocument was draft or template, remove the id so that the backend will create a new all declaration
          if ((this.originalDocType === 'draft' || this.originalDocType === 'template') && this.documentType === 'all') {
              this.declarationId = null
          } */

        if (this.messageType === 'CC507A' && this.state === 'Accepted') {
            this.submissionDate = this.stateDate;
        }


        if (this.documentType === 'draft' || this.documentType === 'template') {
            console.log('saveDeclarationData', this.saveDeclarationData);
            this.saveDeclarationData._PRIVATE.stateCode = 'Pre-Submitted';
            this.saveDeclarationData._PRIVATE.stateDate = null;
            this.saveDeclarationData._PRIVATE.submissionDate = null;
            this.saveDeclarationData._PRIVATE.refNumber = null;
            this.saveDeclarationData._PRIVATE.lrn = null;

            if (this.saveDeclarationData.HEAHEA) {
                this.saveDeclarationData.HEAHEA.DocNumHEA5 = null;
                this.saveDeclarationData.HEAHEA.RefNumHEA4 = null;
            }
            this.state = 'Pre-Submitted';
            this.stateDate = null;
        }



        console.log('saveDeclarationData', this.saveDeclarationData);
        console.log('saveDeclarationData mrn', this.mrn);
        this.saveData.patchValue({
            id: this.declarationId,
            domain: this.domain,
            subdomain: this.subdomain,
            messageType: this.messageType,
            userId: this.userId,
            lrn: this.lrn,
            state: this.state,
            documentType: this.documentType,
            declarationForm: this.saveDeclarationData,
            eur1Form: this.postEur1Form,
            inf4Form: this.postInf4Form,
            agrCertForm: this.postAgrCertForm,
            cmrForm: this.postCmrForm,
            declarantId: this.declarantId,
            traderId: this.traderId,
            customerId: this.internationalId,
            mrn: this.mrn,
            description: this.templateDescription || null,
            submissionDate: this.submissionDate,
            stateDate: this.stateDate,
            originalDocType: this.originalDocType,
            originalDocId: this.declarationId,
            referrerDeclarationId: this.referrerDeclarationId,
            noNotification: this.noNotification || false
        });

        console.log('saveFormX saveData', this.saveData.value);
    }

    saveAsTemplateDialog(args: SaveFormParams): Observable<any> {


        return this.saveAsService.saveAsDialog(args, 'template').pipe(switchMap(result => {
            console.log('saveAsTemplateDialog Result', result);
            if (result) {
                return this.save(result as SaveFormParams)
            }
            return of(null)
        }))
    }

    saveAsDraftDialog(args: SaveFormParams): Observable<any> {


        return this.saveAsService.saveAsDialog(args, 'draft').pipe(switchMap(result => {
            console.log('saveAsDraftDialog Result', result);
            if (result) {
                return this.save(result as SaveFormParams)
            }
            return of(null)
        }))
    }

    deleteForm(lrn) {
        return this.dbQuery.deleteForm(lrn).pipe(take(1))
    }
}
