import { Declarant } from "app/core/user/user.model";

export class TrarepModel {
    NamTRE1?: string;
    StrAndNumTRE1?: string;
    PosCodTRE1?: string;
    CitTRE1?: string;
    CouCodTRE1?: string;
    TRAREPLNG?: string;
    TINTRE1?: string;
    SpeMenTRE410?: string;
    DeclarantObject?: Declarant;

    constructor(value?) {
        this.NamTRE1 = value?.NamTRE1 || null;
        this.StrAndNumTRE1 = value?.StrAndNumTRE1 || null;
        this.PosCodTRE1 = value?.PosCodTRE1 || null;
        this.CitTRE1 = value?.CitTRE1 || null;
        this.CouCodTRE1 = value?.CouCodTRE1 || null;
        this.TRAREPLNG = value?.TRAREPLNG || null;
        this.TINTRE1 = value?.TINTRE1 || null;
        this.SpeMenTRE410 = value?.SpeMenTRE410 || null;
        this.DeclarantObject = value?.DeclarantObject;
    }
}
