<div mat-dialog-title class="primary" fxLayout="row">
    <div>{{ chooseTitle }}</div>
    <div>
        <button mat-icon-button (click)="dialogRef.close()">
            <mat-icon> highlight_off</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content fxLayout="row" style="height: 3vw; overflow: auto">
    <div fxLayout="column" style="width: 90%">
        <ul class="text-danger" *ngFor="let error of errorMessages; let i = index">
            <div style="display: block; margin-bottom: 10px"
                *ngIf="i < 1 && (error.array === 'GOOITEGDS' || error.array==='VehicleDetails')">

                <span style="margin-top: 10px; font-size: 1.2em; font-weight: 600"
                    *ngIf="error.array && (error.grandArray === 'GOOITEGDS' || error.grandArray === 'VehicleDetails')">
                    Είδος {{ +error.grandGroup + 1 }}</span>

                <mat-divider style="display: block; border: 1px solid"></mat-divider>
            </div>

            <div style="display: block; margin-bottom: 10px" *ngIf="
                    i >= 1 &&
                    errorMessages[i].grandGroup !==  errorMessages[i - 1].grandGroup &&
                    (error.array === 'GOOITEGDS' ||error.array==='VehicleDetails')">
                <span style="margin-top: 10px; font-size: 1.2em; font-weight: 600"
                    *ngIf="error.array && (error.grandArray === 'GOOITEGDS' || error.grandArray === 'VehicleDetails')">
                    Είδος {{ +error.grandGroup + 1 }}</span>

                <mat-divider style="display: block; border: 1px solid"></mat-divider>
            </div>
            <mat-divider
                *ngIf="
                    i > 1 &&
                    errorMessages[i].group !== errorMessages[i - 1].group &&

                   ( (errorMessages[i - 1].grandArray === 'GOOITEGDS' && errorMessages[i].grandArray !== 'GOOITEGDS') ||
                    (errorMessages[i - 1].grandArray === 'VehicleDetails' && errorMessages[i].grandArray !== 'VehicleDetails'))"
                style="display: block; margin: 10px 0; border: 1px solid"></mat-divider>

            <li *ngIf="error">{{ error | errorDesc }}</li>
            <!--   <li *ngIf="!error">
                <b>{{ error.controlName }}</b>
            </li> -->
        </ul>
    </div>
</div>