import { AbstractControl, FormControl } from "@angular/forms";
import { DeliveryDetailsType } from "./delivery-details.model";

export class DeliveryDetailsForm {
    [key: string]: AbstractControl;

    TransportVehicleIdentificationNumber: FormControl;
    TransportVehicleCountry: FormControl;
    NationalTransportMode: FormControl;
    DipennakNumber: FormControl;

    /*

   */

    constructor(value: DeliveryDetailsType) {

        this.TransportVehicleIdentificationNumber = new FormControl(value.TransportVehicleIdentificationNumber);
        this.TransportVehicleCountry = new FormControl(value.TransportVehicleCountry);
        this.NationalTransportMode = new FormControl(value.NationalTransportMode);
        this.DipennakNumber = new FormControl(value.DipennakNumber);

    }
}
