import {
    TraconceModel,
    ConresersModel,
    SeainfsilModel,
    ItiModel,
    DelterModel,
    TradatModel,
    Statrep385Model
} from 'app/main/apps/export/declaration/_models';
import { User, _pelatesItem, international_customersItem } from 'app/model/api-model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { AddCon789Model } from '../addcon789/addcon789.model';
import { Cartra100Model } from '../cartra100/cartra100.model';
import { EntcusoffModel } from '../entcusoff/entcusoff.model';
import { ImpGooIteGdsModel } from '../gooitegds/gooitegds.model';
import { ImpHeaheaModel } from '../heahea/heahea.model';
import { ImpcusoffModel } from '../impcusoff/impcusoff.model';
import { Prov100Model } from '../prov100/prov100.model';
import { Traconco1Model } from '../traconco1/traconco1.model';
import { TrarepModel } from '../trarep/trarep.model';

export class ImportDeclarationModel {
    _PRIVATE?: PrivateControlsModel;

    MesSenMES3?: string;
    MesRecMES6?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    PriMES15?: string;
    TesIndMES18?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    CorIdeMES25?: string;

    //
    HEAHEA?: ImpHeaheaModel;
    TRACONCO1?: Traconco1Model;
    TRACONCE1?: TraconceModel;
    CONRESERS?: ConresersModel;
    SEAINFSLI?: SeainfsilModel;
    GOOITEGDS?: ImpGooIteGdsModel[];
    ITI?: ItiModel[];
    DELTER?: DelterModel;
    TRADAT?: TradatModel;
    TRAREP?: TrarepModel;
    STATREP385?: Statrep385Model;
    CARTRA100?: Cartra100Model;
    ADDCON789?: AddCon789Model[];
    ENTCUSOFF?: EntcusoffModel;
    PROV100?: Prov100Model;
    IMPCUSOFF?: ImpcusoffModel;
    //
    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);
        this._PRIVATE = value?._PRIVATE || null;

        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.PriMES15 = value?.PriMES15 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = value?.MesTypMES20 || 'ID15A';
        this.CorIdeMES25 = value?.CorIdeMES25 || null;

        this.HEAHEA = value?.HEAHEA || {};
        this.TRACONCO1 = value?.TRACONCO1 || {};
        this.TRACONCE1 = value?.TRACONCE1 || {};
        this.CONRESERS = value?.CONRESERS || {};
        this.SEAINFSLI = value?.SEAINFSLI || {};
        this.GOOITEGDS = value?.GOOITEGDS || [];
        this.ITI = value?.ITI || [];
        this.DELTER = value?.DELTER || {};
        this.TRADAT = value?.TRADAT || {};
        this.TRAREP = value?.TRAREP || {};
        this.STATREP385 = value?.STATREP385 || {};
        this.CARTRA100 = value?.CARTRA100 || {};
        this.ADDCON789 = value?.ADDCON789 || [];
        this.ENTCUSOFF = value?.ENTCUSOFF || {};
        this.PROV100 = value?.PROV100 || {};
        this.IMPCUSOFF = value?.IMPCUSOFF || {};
    }
}
