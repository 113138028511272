export const tsipouraFieldMappings = {

    'boilerOwner': 'Κάτοχος Άμβυκα',
    'boilerCapacity': 'Χωρητικότητα Άμβυκα',
    'boilerRegistrationNumber': 'Αριθμός Άδειας Άμβυκα',
    'boilerGeoLocation': 'Συντεταγμένες Άμβυκα',
    'agm': 'Α.Γ.Μ.',
    'tsipouraCalculationData': 'Δεδομένα Υπολογισμού',
    'lpa': 'Λ.Α. Αιθυλικής Αλκοόλης',
    'producerName': 'Παραγωγός',
    'producerAfm': 'ΑΦΜ',
    'alcoholVol': 'Αλκοολικός Τίτλος',
    'wineGrapes': 'Σταφύλια Οινοποιήσιμα',
    'nonWineGrapes': 'Σταφύλια Μή Οινοποιήσιμα',
    'grapeStems': 'Στέμφυλα',
    'finalNetWeight': 'Λ.Ε. Τελικού Προιόντος',
    'numberOfBoilers': 'Καζανιές',
    'hours': 'Ώρες Εργασίας',
    'days': 'Ημέρες Εργασίας'
};