export class Trapripc1Model {
    NamPC17?: string;
    StrAndNumPC122?: string;
    PosCodPC123?: string;
    CitPC124?: string;
    CouPC125?: string;
    NADLNGPC?: string;
    TINPC159?: string;
    HITPC126?: string;
    TinTypPC1009?: string;
    TrapriObject?: {};

    constructor(value?) {
        this.NamPC17 = value?.NamPC17 || null;
        this.StrAndNumPC122 = value?.StrAndNumPC122 || null;
        this.PosCodPC123 = value?.PosCodPC123 || null;
        this.CitPC124 = value?.CitPC124 || null;
        this.CouPC125 = value?.CouPC125 || null;
        this.NADLNGPC = value?.NADLNGPC || null;
        this.TINPC159 = value?.TINPC159 || null;
        this.HITPC126 = value?.HITPC126 || null;
        this.TinTypPC1009 = value?.TinTypPC1009 || null;
        this.TrapriObject = value?.TrapriObject || {};
    }
}
