import { ApiNodeService } from 'app/services/api.node.service';
import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/services/api.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { TariffCalculateResultsComponent } from './tariff-calculate-results/tariff-calculate-results.component';
import { TariffDocumentsResponse } from './_models';


@Injectable({
    providedIn: 'root'
})
export class TariffCalculateService {
    private documents: BehaviorSubject<TariffDocumentsResponse[]>;
    documents$: Observable<TariffDocumentsResponse[]>;

    private documentsForm: BehaviorSubject<FormGroup | undefined>;
    documentsForm$: Observable<FormGroup>;

    private taxAddEleForm: BehaviorSubject<FormGroup | undefined>;
    taxAddEleForm$: Observable<FormGroup>;

    NODE_API_SERVER = environment.NODE_API;

    constructor(
        private apiLocal: ApiNodeService,
        private dbQuery: ApiService,
        public calculateTariffResultsRef: MatDialogRef<TariffCalculateResultsComponent>,
        public dialog: MatDialog
    ) {
        console.log('tariffCall service onComponentInit');

        this.documentsForm = new BehaviorSubject(new FormGroup({}));
        this.documents = new BehaviorSubject([]);
        this.taxAddEleForm = new BehaviorSubject(new FormGroup({ array: new FormArray([]) }));

        if (!this.documentsForm$) {
            this.documentsForm$ = this.documentsForm.asObservable();
        }
        if (!this.documents$) {
            this.documents$ = this.documents.asObservable();
        }
        if (!this.taxAddEleForm$) {
            this.taxAddEleForm$ = this.taxAddEleForm.asObservable();
        }
    }
    onComponentInit() { }

    createDocumentsForm(data) {
        data.forEach(doc => {
            this.documentsForm.getValue().addControl(doc.groupName, new FormControl('', Validators.required));
        });
    }

    createSupUnitsForm(data) {
        console.log('tariffCall create taxAddEleForm', data);
        data.forEach(supUnit => {
            (this.taxAddEleForm.getValue().get('array') as FormArray).push(
                new FormGroup({
                    desc: new FormControl(supUnit.desc),
                    value: new FormControl('', {
                        validators: Validators.required
                    })
                })
            );
        });

        console.log('tariffCall taxAddEleForm', this.taxAddEleForm.getValue());
    }

    submitDocuments() {
        const postData = this.documentsForm.getValue().value;
        console.log(postData);
        return this.apiLocal.submitTariffDocumentsResponse(postData);
    }

    submitSupUnits() {
        let postData = this.taxAddEleForm.getValue().get('array').value;
        console.log(postData);

        postData = postData.map(({ value }) => {
            value = (+value).toFixed(3);
            value = value.replace('.', ',');

            return { value };
        });

        console.log(postData);

        return this.apiLocal.submitTariffCalcSupUnits(postData);
    }

    openCalculateTariffResults(results) {
        const dialogConfig = new MatDialogConfig();

        // dialogConfig.autoFocus = true;
        dialogConfig.width = '70%';
        dialogConfig.height = '95%';
        dialogConfig.panelClass = 'mat-dialog-override';

        if (results) {
            dialogConfig.data = results;
        }

        this.calculateTariffResultsRef = this.dialog.open(TariffCalculateResultsComponent, dialogConfig);

        this.calculateTariffResultsRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                this.calculateTariffResultsRef.close();
            }
        });

        this.calculateTariffResultsRef.backdropClick().subscribe(() => {
            this.calculateTariffResultsRef.close();
        });
    }

    onComponentDestroy() {
        this.documents.next([]);
        this.documentsForm.next(new FormGroup({}));

        this.taxAddEleForm.next(new FormGroup({ array: new FormArray([]) }));
    }

    getFootnoteDescription(footnotes: string[]) {

        return this.dbQuery.getFootnoteDescription(footnotes);
    }
}
