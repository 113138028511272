import { FormControl, Validators } from '@angular/forms';
import { DelterModel } from './delter.model';

export class DelterForm {
    [key: string]: FormControl;

    IncCodTDL1 = new FormControl();
    ComCodTDL1 = new FormControl();
    ComInfDELTER387 = new FormControl(null, { updateOn: 'blur' });
    ComInfDELTER387LNG = new FormControl();

    constructor(value: DelterModel) {
        this.IncCodTDL1.setValue(value.IncCodTDL1);
        this.ComCodTDL1.setValue(value.ComCodTDL1);
        this.ComInfDELTER387.setValue(value.ComInfDELTER387);
        this.ComInfDELTER387LNG.setValue(value.ComInfDELTER387LNG);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
