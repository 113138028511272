<mat-card class="sliders" [formGroup]="DocumentSettings">
    <mat-card-content>
        <h3>{{ title }}</h3>

        <div fxLayout="row" fxLayoutAlign="space-around center">
            <mat-slider
                class="example-margin"
                [max]="max"
                [min]="min"
                [step]="step"
                [thumbLabel]="thumbLabel"
                [tickInterval]="tickInterval"
                formControlName="top"
                invert="true"
                vertical="true"
            >
            </mat-slider>

            <mat-slider
                class="example-margin"
                [max]="max"
                [min]="min"
                [step]="step"
                [thumbLabel]="thumbLabel"
                [tickInterval]="tickInterval"
                formControlName="left"
                [vertical]="vertical"
            >
            </mat-slider>
        </div>
    </mat-card-content>
</mat-card>
