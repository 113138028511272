import { FormControl, Validators } from '@angular/forms';
import { NctsProDocModel } from './prodocdc2.model';

export class NctsProDocForm {
    [key: string]: FormControl;

    DocTypDC21 = new FormControl();
    DocRefDC23 = new FormControl();
    DocRefDCLNG = new FormControl();
    ComInfPRODOCCERGI4 = new FormControl();
    ComInfLNGPRODOCCERGI5 = new FormControl();

    constructor(value: NctsProDocModel) {
        this.DocTypDC21.setValue(value.DocTypDC21);
        this.DocRefDC23.setValue(value.DocRefDC23);
        this.DocRefDCLNG.setValue(value.DocRefDCLNG);
        this.ComInfPRODOCCERGI4.setValue(value.ComInfPRODOCCERGI4);
        this.ComInfLNGPRODOCCERGI5.setValue(value.ComInfLNGPRODOCCERGI5);

        this.DocTypDC21.setValidators(Validators.required)
        this.DocRefDC23.setValidators(Validators.required)
        this.DocTypDC21.updateValueAndValidity()
        this.DocRefDC23.updateValueAndValidity()
    }
}
