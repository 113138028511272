import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CarPriceRange } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.interfaces';
import { CarsService } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars.service';
import { VehiclesQueriesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicle-queries.service';
import { filter, map, startWith, take, takeUntil, tap } from 'rxjs';

@Injectable()
export class ElectricService {

    priceRanges: CarPriceRange[] = []

    constructor(private vehiclesQueriesService: VehiclesQueriesService, private carsService: CarsService) {

    }

    setPriceRanges() {
        this.vehiclesQueriesService.priceRanges$.pipe(
            filter(priceRanges => priceRanges.length > 0),
            map(priceRanges => priceRanges.map(priceRange => ({
                ...priceRange,
                initial_tax_rate: 0
            }))),
            take(1)
        ).subscribe((priceRanges) => this.vehiclesQueriesService.updatePriceRanges(priceRanges))
    }


    setElectricVehicleValues(vehiclesForm: FormGroup) {


        this.setPriceRanges()


        const calculationData = vehiclesForm.get('carsCalculationData') as FormGroup;

        vehiclesForm.patchValue({
            carbonDioxideEmissions: 0,
            engineCapacity: 0,
            carCertificateIncreaseRate: 0,
            addTaricCode: 1937,
            co2IncreaseRate: 0,
            hybridReductionRate: 0
        })
        calculationData.reset()
        calculationData.updateValueAndValidity()


    }

    disableControls(vehiclesForm: FormGroup) {


        vehiclesForm.get('chassisType').disable()
        vehiclesForm.get('carCertificateCategory').disable()
        vehiclesForm.get('carbonDioxideEmissions').disable()
        vehiclesForm.get('engineCapacity').disable()

        vehiclesForm.get('monthsTotal').disable()
        vehiclesForm.get('ageReductionRate').disable()
        vehiclesForm.get('mileageReductionRate').disable()
        vehiclesForm.get('totalReductionRate').disable()

        vehiclesForm.get('carCertificateCategory').disable()
        vehiclesForm.get('carCertificateIncreaseRate').disable()
        vehiclesForm.get('co2IncreaseRate').disable()
        vehiclesForm.get('hybridReductionRate').disable()
    }


    subToRetailValue(vehiclesForm: FormGroup) {

        const retailValueBeforeTax$ = vehiclesForm.get('retailValueBeforeTax').valueChanges.pipe(startWith(vehiclesForm.get('retailValueBeforeTax').value));

        return retailValueBeforeTax$.pipe(
            tap((retailValueBeforeTax) => { console.log("carsService subToRetailValue before filter", retailValueBeforeTax) }),
            filter((retailValueBeforeTax) => Boolean(retailValueBeforeTax)),
            tap((retailValueBeforeTax) => {
                console.log("carsService subToRetailValue", retailValueBeforeTax)
                this.carsService.calculateTaxes(vehiclesForm)
            })
        )
    }


}
