export class VesselRegistrationDetailsModel {
    VesselRegistrationType?: string;
    VesselRegistrationNumber?: string;
    VesselName?: string;
    VesselPortOfRegistration?: string;
    VesselIMO?: string;
    VesselCountryOfOrigin?: string;
    VesselCategory?: string;
    VesselCapacity?: string;
    VesselHorsePower?: string;



    constructor(value?) {
        this.VesselRegistrationType = value?.VesselRegistrationType || null;
        this.VesselRegistrationNumber = value?.VesselRegistrationNumber || null;
        this.VesselName = value?.VesselName || null;
        this.VesselPortOfRegistration = value?.VesselPortOfRegistration || null;
        this.VesselIMO = value?.VesselIMO || null;
        this.VesselCountryOfOrigin = value?.VesselCountryOfOrigin || null;
        this.VesselCategory = value?.VesselCategory || null;
        this.VesselCapacity = value?.VesselCapacity || null;
        this.VesselHorsePower = value?.VesselHorsePower || null;

    }
}
