<div class="px-8" fxLayout="row" fxLayoutAlign="center center">
    <div class="led" matTooltip="Σύνδεση στο Ιντερνετ" matTooltipClass="tooltip">
        <img *ngIf="networkOnline$ | async" src="assets/images/general/led-green.png" width="12" height="12" alt="" />
        <img *ngIf="!(networkOnline$ | async)" src="assets/images/general/led-red.png" width="12" height="12" alt="" />
    </div>
    <div class="led" matTooltip="Τοπική Διασύνδεση" matTooltipClass="tooltip">
        <img *ngIf="(serverOnline$ | async) && (networkOnline$ | async)" src="assets/images/general/led-green.png"
            width="12" height="12" alt="" />

        <img *ngIf="!(serverOnline$ | async) || !(networkOnline$ | async)" src="assets/images/general/led-red.png"
            width="12" height="12" alt="" />
    </div>
    <div class="led" matTooltip="Σύνδεση με ICISNet" matTooltipClass="tooltip">
        <img *ngIf="(icisOnline$ | async) && (serverOnline$ | async) && (isLoginInProgress$ | async)===false"
            src="assets/images/general/led-green.png" width="12" height="12" alt="" />

        <img *ngIf="(icisOnline$ | async) && (serverOnline$ | async) && (isLoginInProgress$ | async)"
            [class.blink]="(isLoginInProgress$ | async)" src="assets/images/general/led-orange.png" width="12"
            height="12" alt="" />


        <img *ngIf="!(icisOnline$ | async) && (serverOnline$ | async)" src="assets/images/general/led-red.png"
            width="12" height="12" alt="" />

        <img *ngIf="!(serverOnline$ | async)" src="assets/images/general/led-orange.png" width="12" height="12"
            alt="" />
    </div>
</div>