import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';
import { CustomsService } from 'app/main/apps/customs/customs.service';
import { PaginatedDataSource } from 'app/services/datasource/paginated-datasource';
import { CustomQuery } from '../customs-list/customs-list.component';
import { Custom } from '../customs.model';
import { countriesItem } from 'app/model/api-model';
import { ApiService } from 'app/services/api.service';
import { FormDisabledItem, MainBroadcasterService } from 'app/services/broadcaster.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomsDialogContentService } from 'app/main/apps/customs/customs-dialog-content/customs-dialog-content.service';

@Component({
    selector: 'app-customs-search',
    templateUrl: './customs-search.component.html',
    styleUrls: ['./customs-search.component.scss']
    //    providers: [CustomsService],
})
export class CustomsSearchComponent implements OnInit, OnDestroy {
    searchQuery: FormControl;

    filters: FormGroup;
    countries: countriesItem[];
    formDisabled: boolean;
    subdomain: string;

    allCustomsDataSource: PaginatedDataSource<Custom, CustomQuery>;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param _customsService
     * @param _fuseSidebarService
     * @param _matDialog
     */
    constructor(
        private _customsService: CustomsService,
        private dbQuery: ApiService,
        private mainBroadcster: MainBroadcasterService,
        private cd: ChangeDetectorRef,
        public dialogRef: MatDialogRef<CustomsSearchComponent>,
        private customsDialogContentService: CustomsDialogContentService

    ) {
        console.log('CUSTMS search started');

        this._unsubscribeAll = new Subject<void>();
        this.searchQuery = new FormControl('');
        this.customsDialogContentService.init();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._customsService.dataSource$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            this.allCustomsDataSource = value;
        });

        this.mainBroadcster.activeSubdomain$
            .pipe(
                switchMap(subdomain => {
                    this.subdomain = subdomain;
                    return this.mainBroadcster.formDisabled$;
                }),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((disabled: FormDisabledItem) => {
                const state = disabled[this.subdomain];

                console.log('CUSTMS state', state);

                this.formDisabled = state ?? false;
                console.log('CUSTMS formDisabled', this.formDisabled);
            });

        this.dbQuery
            .get_customsCountries()
            .pipe(take(1))
            .subscribe((data: countriesItem[]) => {
                this.countries = data;
                console.log('CUSTMS countries', this.countries);
            });

        this._customsService.customFilters$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
            this.filters = value;

            console.log('CUSTMS filters', this.filters);
        });

        this.filters
            .get('searchQuery')
            .valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                console.log('searchQuery: ', value);

                this.allCustomsDataSource.queryBy({ search: value });
                //reset all lists on empty search value
                if (value === '') {
                    this.allCustomsDataSource.queryBy({ search: value });
                }
            });
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        this.cd.detectChanges();
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        console.log('CUSTMS search destroyed');

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
