//import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
import Rollbar from 'rollbar';
//` is the required syntax for Typescript 3.6.x. 
// However, it will only work when setting either `allowSyntheticDefaultImports` 
// or `esModuleInterop` in your Typescript options.

import {
    Injectable,
    Inject,
    InjectionToken,
    ErrorHandler
} from '@angular/core';
import { environment } from 'environments/environment';

const rollbarConfig = {
    //post_client_item_token
    accessToken: environment.rollbar.accessToken,
    environment: environment.rollbar.environment,
    captureUncaught: true,
    captureUnhandledRejections: true,
    source_map_enabled: true,
    guess_uncaught_frames: true,
    nodeSourceMaps: false,
    inspectAnonymousErrors: true,
    ignoreDuplicateErrors: true,
    wrapGlobalEventHandlers: false,
    scrubRequestBody: true,
    exitOnUncaughtException: false,
    stackTraceLimit: 20,
    payload: {
        code_version: environment.version,
        custom_data: 'foo'
    }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

    handleError(err: any): void {

        if (environment.rollbar.enabled) {
            this.rollbar.error(err.originalError || err);

        } else {
            console.error(err);
        }
    }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}
