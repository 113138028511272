import { Directive, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';

@Directive({
    selector: '[HideTab]',
    exportAs: 'HideTab'
})
export class HideTabDirective implements AfterViewInit {
    hide: boolean;

    constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.hide = false;
        this.cdr.detectChanges();
    }
}
