import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { NationalProductsForm } from "./national-products/national-products-form.model";
import { NationalProductsModel } from "./national-products/national-products.model";
import { PreviousDocumentsForm } from "./previous-documents/previous-documents-form.model";
import { PreviousDocumentsModel } from "./previous-documents/previous-documents.model";
import { ReferenceDocumentsForm } from "./reference-docs/reference-docs-form.model";
import { ReferenceDocumentsModel } from "./reference-docs/reference-docs.model";
import { SpecialMentionsForm } from "./special-mentions/special-mentions-form.model";
import { SpecialMentionsModel } from "./special-mentions/special-mentions.model";
import { SupplementaryComputationInformationForm } from "./sup-computation/sup-computation-form.model";
import { SupplementaryComputationInformationModel } from "./sup-computation/sup-computation.model";
import { TaxComputationForm } from "./tax-computation/tax-computation-form.model";
import { TaxComputationModel } from "./tax-computation/tax-computation.model";
import { TaxesRowModel } from "./taxes-row.model";
import { DEFKVehicleDetailsForm } from "./vehicle-details/vehicle-details-form.model";
import { DEFKVehicleDetailsModel } from "./vehicle-details/vehicle-details.model";

export class TaxesRowForm {
    [key: string]: AbstractControl;
    ExciseTaxesDeclarationRowNumber: FormControl;
    SymbolNumbers: FormControl;
    ProductDescription: FormControl;
    TaricCode: FormControl;
    TaricAdditionCode: FormControl;
    TaxQuantity: FormControl;
    MeasuringUnitsType: FormControl;
    AdditionalTaxQuantity: FormControl;
    AdditionalMeasuringUnits: FormControl;
    Plato: FormControl;
    EtepaaAmountPaid: FormControl;
    ExciseTaxCompensation: FormControl;
    VatAmountCompensation: FormControl;
    VatInflowAmountCompensation: FormControl;
    OgaAmountCompensation: FormControl;
    TaxValue: FormControl;
    CountryOfOrigin: FormControl;
    GrossWeight: FormControl;
    NetWeight: FormControl;
    ConditionIdentification: FormControl;
    PreviousConditionIdentification: FormControl;
    ExemptionCode: FormControl;
    NetValue: FormControl;
    StatisticalValue: FormControl;
    SuspensionTypeCode: FormControl;
    CarCertificate: FormControl;
    SupplementaryComputationInformation: FormArray;
    PreviousDocuments: FormGroup;
    ReferenceDocuments: FormArray;
    NationalProducts: FormArray;
    VehicleDetails: FormArray;
    TaxComputation: FormArray;
    SpecialMentions: FormArray;

    constructor(value: TaxesRowModel) {

        this.ExciseTaxesDeclarationRowNumber = new FormControl(value.ExciseTaxesDeclarationRowNumber);
        this.SymbolNumbers = new FormControl(value.SymbolNumbers, { updateOn: 'blur' });
        this.ProductDescription = new FormControl(value.ProductDescription, { updateOn: 'blur' });
        this.TaricCode = new FormControl(value.TaricCode, { updateOn: 'blur' });
        this.TaricAdditionCode = new FormControl(value.TaricAdditionCode);
        this.TaxQuantity = new FormControl(value.TaxQuantity, { updateOn: 'blur' });
        this.MeasuringUnitsType = new FormControl(value.MeasuringUnitsType);
        this.AdditionalTaxQuantity = new FormControl(value.AdditionalTaxQuantity, { updateOn: 'blur' });
        this.AdditionalMeasuringUnits = new FormControl(value.AdditionalMeasuringUnits, { updateOn: 'blur' });
        this.Plato = new FormControl(value.Plato, { updateOn: 'blur' });
        this.EtepaaAmountPaid = new FormControl(value.EtepaaAmountPaid, { updateOn: 'blur' });
        this.ExciseTaxCompensation = new FormControl(value.ExciseTaxCompensation, { updateOn: 'blur' });
        this.VatAmountCompensation = new FormControl(value.VatAmountCompensation, { updateOn: 'blur' });
        this.VatInflowAmountCompensation = new FormControl(value.VatInflowAmountCompensation, { updateOn: 'blur' });
        this.OgaAmountCompensation = new FormControl(value.OgaAmountCompensation, { updateOn: 'blur' });
        this.TaxValue = new FormControl(value.TaxValue, { updateOn: 'blur' });
        this.CountryOfOrigin = new FormControl(value.CountryOfOrigin);
        this.GrossWeight = new FormControl(value.GrossWeight, { updateOn: 'blur' });
        this.NetWeight = new FormControl(value.NetWeight, { updateOn: 'blur' });
        this.ConditionIdentification = new FormControl(value.ConditionIdentification);
        this.PreviousConditionIdentification = new FormControl(value.PreviousConditionIdentification);
        this.ExemptionCode = new FormControl(value.ExemptionCode);
        this.NetValue = new FormControl(value.NetValue, { updateOn: 'blur' });
        this.StatisticalValue = new FormControl(value.StatisticalValue);
        this.SuspensionTypeCode = new FormControl(value.SuspensionTypeCode);
        this.CarCertificate = new FormControl(value.CarCertificate);
        this.SupplementaryComputationInformation = new FormArray([])
        this.PreviousDocuments = new FormGroup(new PreviousDocumentsForm(new PreviousDocumentsModel(value.PreviousDocuments)), { updateOn: 'blur' });
        this.ReferenceDocuments = new FormArray([]);
        this.NationalProducts = new FormArray([]);
        this.VehicleDetails = new FormArray([]);
        this.TaxComputation = new FormArray([]);
        this.SpecialMentions = new FormArray([]);



        if (value.SupplementaryComputationInformation) {
            value.SupplementaryComputationInformation.forEach((info: SupplementaryComputationInformationModel) => {
                this.SupplementaryComputationInformation.push(new FormGroup(new SupplementaryComputationInformationForm(new SupplementaryComputationInformationModel(info))));
            });
        } else {
            this.SupplementaryComputationInformation.push(new FormGroup(new SupplementaryComputationInformationForm(new SupplementaryComputationInformationModel())));
        }

        if (value.ReferenceDocuments) {
            value.ReferenceDocuments.forEach((docs: ReferenceDocumentsModel) => {
                this.ReferenceDocuments.push(new FormGroup(new ReferenceDocumentsForm(new ReferenceDocumentsModel(docs))));
            });
        } else {
            this.ReferenceDocuments.push(new FormGroup(new ReferenceDocumentsForm(new ReferenceDocumentsModel())));
        }

        if (value.NationalProducts) {
            value.NationalProducts.forEach((prods: NationalProductsModel) => {
                this.NationalProducts.push(new FormGroup(new NationalProductsForm(new NationalProductsModel(prods))));
            });
        } else {
            this.NationalProducts.push(new FormGroup(new NationalProductsForm(new NationalProductsModel())));
        }

        if (value.VehicleDetails) {
            value.VehicleDetails.forEach((goods: DEFKVehicleDetailsModel) => {
                this.VehicleDetails.push(new FormGroup(new DEFKVehicleDetailsForm(new DEFKVehicleDetailsModel(goods)), { updateOn: 'blur' }));
            });
        } else {
            // this.VehicleDetails.push(new FormGroup(new DEFKVehicleDetailsForm(new DEFKVehicleDetailsModel())));
        }

        if (value.TaxComputation) {
            value.TaxComputation.forEach((tax: TaxComputationModel) => {
                this.TaxComputation.push(new FormGroup(new TaxComputationForm(new TaxComputationModel(tax))));
            });
        } else {
            this.TaxComputation.push(new FormGroup(new TaxComputationForm(new TaxComputationModel())));
        }

        if (value.SpecialMentions) {
            value.SpecialMentions.forEach((mentions: SpecialMentionsModel) => {
                this.SpecialMentions.push(new FormGroup(new SpecialMentionsForm(new SpecialMentionsModel(mentions))));
            });
        } else {
            this.SpecialMentions.push(new FormGroup(new SpecialMentionsForm(new SpecialMentionsModel())));
        }

    }


}
