import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CarsDefkCreateService } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars-defk-create.service';
import { CarsComponent } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars.component';
import { CarsService } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars.service';
import { vehiclesFieldMappings } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles-field-mappings';
import { VehiclesModel } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.model';
import { VehiclesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicles.service';
import { DEFKDeclarationForm } from 'app/main/apps/excise/defk/_models/EF15A/declaration-form.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { genericItem } from 'app/model/api-model';
import { AbstractControlErrorService, ErrorMessageConfig } from 'app/services/abstract-contol-error.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { environment } from 'environments/environment';
import { Subject, forkJoin, tap, switchMap, map, takeUntil, startWith, debounceTime } from 'rxjs';
import { VehiclesQueriesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicle-queries.service';
import { ElectricService } from 'app/main/apps/excise-helper-tables/vehicles/electric.service';

@Component({
    selector: 'app-vehicles',
    templateUrl: './vehicles.component.html',
    styleUrls: ['./vehicles.component.scss'],
    providers: [VehiclesService, CarsService, ElectricService]
})
export class VehiclesComponent implements OnInit {


    vehiclesForm: FormGroup
    defkDeclarationForm: FormGroup<DEFKDeclarationForm>
    euroClassOptions: genericItem[] = []
    carChassisOptions: genericItem[] = [];

    vehicleTypeOptions: genericItem[] = [];

    products: genericItem[] = []
    errorMessages: string[] = [];
    showBanner = false;

    color1 = '';
    color2 = 'primary';

    showCarsCalculationData = true;

    carsCalculationDataArray = [
        1, 9, 10, 18, 8
    ]


    get vehicleType(): FormControl {
        return this.vehiclesForm.get('vehicleType') as FormControl
    }

    get chassisType(): FormControl {
        return this.vehiclesForm.get('chassisType') as FormControl
    }

    private _destroy = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<CarsComponent>,
        private mainBroadcaster: MainBroadcasterService,
        private vehicleQueriesService: VehiclesQueriesService,
        private vehiclesService: VehiclesService,
        private cf: ExtCustomFunctions,
        private createDefkService: CarsDefkCreateService,
        private errorService: AbstractControlErrorService
    ) {
        console.log("VehiclesComponentsRace VehiclesComponent")
    }


    ngOnInit() {
        console.log('CarsComponent ngOnInit')

        forkJoin([
            this.vehicleQueriesService.getVehicleTypeOptions(),
            this.vehicleQueriesService.getCarEuroClassOptions(),
            this.vehicleQueriesService.getCarTypeOptions(),
            this.vehicleQueriesService.getPriceRanges()
        ])
            .pipe(
                tap(([vehicleTypeOpt, euroClassOpt, carTypes, priceRanges]) => { this.euroClassOptions = euroClassOpt, this.carChassisOptions = carTypes, this.vehicleTypeOptions = vehicleTypeOpt }),
                switchMap(() => this.mainBroadcaster.declarationForms$.pipe(map(forms => forms['DEFK']))),
                switchMap(defkDeclaration => this.vehiclesService.vehiclesForm$.pipe(map(form => ({ form, defkDeclaration })))),
                map(
                    (value: { form: FormGroup, defkDeclaration: FormGroup }, index) => {
                        this.vehiclesForm = value.form
                        console.log("VehiclesComponentsRace VehiclesComponent vehiclesForm", this.vehiclesForm.value)


                        this.defkDeclarationForm = value?.defkDeclaration
                        console.log("CarsComponent map index", index)

                        const savedForm = value?.defkDeclaration?.get('_PRIVATE')?.get('cars')?.value

                        if (index === 0) {

                            console.log("CarsService Subscribed to valuechanhes")

                            if (savedForm) {
                                this.vehiclesService.loadLocalStorage(savedForm)
                            }
                        }

                        return value.form;

                    }
                ),
                switchMap(_ => this.vehiclesService.setVehicleRelatedSubscriptions().pipe(takeUntil(this._destroy))),
                switchMap(_ => this.vehiclesService.subToVehicleChassisType().pipe(takeUntil(this._destroy))),
                tap(([vehicleType, chassisType]) => {
                    // Check if vehicleType is included in carsCalculationDataArray and is not 8
                    // or if vehicleType is 8 and chassisType is 'doublecabin'
                    this.showCarsCalculationData =
                        (this.carsCalculationDataArray.includes(+vehicleType) && +vehicleType !== 8)
                })
            )
            .subscribe()


        this.dialogRef.afterClosed().subscribe(_ => {
            if (this.defkDeclarationForm) {

                this.vehiclesService.patchPrivateCtrls(this.defkDeclarationForm, this.vehiclesForm.value)
            }
        })



    }

    ngOnDestroy() {

        this._destroy.next();
        this._destroy.complete();
        this.vehiclesService.destroy$.next();
        this.vehiclesService.destroy$.complete();
    }


    onPrint() {
        const content = document.getElementById('printable').outerHTML;
        const cssPath = environment.DOMAIN_NAME + '/assets/css/twoDayDistillers-print.css'; // Your logged css path
        const newWindow = window.open('', '_blank');

        if (newWindow && newWindow.document) {
            // Create new link element
            const linkEl = newWindow.document.createElement('link');
            linkEl.setAttribute('rel', 'stylesheet');
            linkEl.setAttribute('type', 'text/css');

            // Set href attribute to the cssPath
            linkEl.setAttribute('href', cssPath);

            // Append the link element to the head of the document
            newWindow.document.head.appendChild(linkEl);

            // Add content to the body of the new window
            newWindow.document.body.innerHTML = content;

            // Listen for the load event on the link element
            linkEl.onload = function () {
                newWindow.print();
                newWindow.close();
            };
        }
    }

    onSubmit() {

        if (this.vehiclesForm.status === 'INVALID') {

            const customErrorConfig: ErrorMessageConfig[] = [{
                path: 'carsCalculationData'
            }];

            const allErrors = this.errorService.getAllErrors(this.vehiclesForm);
            this.errorService.convertErrorsToFriendlyMessages(allErrors, [], vehiclesFieldMappings, customErrorConfig);
            console.log("Cars Errors", allErrors);
            console.log("Cars Errors Messages", this.errorMessages);
            this.showBanner = true

            return;
        }
        /*      this.createDefkService.createDefk(this.vehiclesForm.value, this.params).then(_ => {
     
                 this.dialogRef.close()
             }) */
    }



    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event);
    }
    changeStyle2(event) {
        if (this.vehiclesForm.valid) {
            this.color2 = this.cf.changeStyle2(this.vehiclesForm, event);
        }
    }
    onCloseConfirm() {
        this.dialogRef.close()
    }

}
