import { _pelatesItem } from "app/model/api-model";
import { PrivateControlsModel } from "app/model/private-controls.model";
import { EMCSHeaderModel } from "../../../_models/emcs-header.model";
import { EF10ABodyModel } from "./Body/body.model";

export class EF10ADeclarationModel {
    _PRIVATE: PrivateControlsModel;

    Header: EMCSHeaderModel;
    Body: EF10ABodyModel;

    constructor(value?) {

        // console.log("___DeclarationModel constructor", value);
        this._PRIVATE = value?._PRIVATE || null;

        this.Header = value?.Header;

        if (value.Body.DeclarationOfExciseTaxesCancellation) {
            this.Body = value?.Body;
        }
        else {
            this.Body = new EF10ABodyModel();
        }
    }
}