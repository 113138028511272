import { Declarant } from 'app/core/user/user.model';
export class ClearingAgentType {
    ClearingAgentType?: string;
    ClearingAgentEORI?: string;
    ClearingAgentAfm?: string;
    SpecialReferenceCode?: string;
    DeclarantObject?: Declarant;


    constructor(value?) {
        this.ClearingAgentType = value?.ClearingAgentType || null;
        this.ClearingAgentEORI = value?.ClearingAgentEORI || null;
        this.ClearingAgentAfm = value?.ClearingAgentAfm || null;
        this.SpecialReferenceCode = value?.SpecialReferenceCode || null;
        this.DeclarantObject = value?.DeclarantObject || null;

    }
}
