<div mat-dialog-title class="primary" fxLayout="row">
    <div style="width:25%">{{ title }}</div>


    <!-- CENTER -->
    <div fxLayout="row" fxLayoutAlign="center center" style="width:40%;">
        <app-loading *ngIf="loading$ | async" class="loading loading-epayment loading-white" [addValues]="true">
        </app-loading>
    </div>
    <!-- CENTER -->

    <div style="width:20%;text-align: end;">
        <button mat-icon-button (click)="onCloseConfirm()">
            <mat-icon> highlight_off</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content [formGroup]="attachmentForm" [ngClass]="{
        displayRow: action === 'send',
        displayColumn: action === 'check'
    }">
    <!-- First Column -->
    <div *ngIf="action === 'send'" style="width: 30%; height: 100%" class="form">
        <mat-card class="zone zone-value flex-column" fxLayout="column" style="height: 90%">
            <attachment-filters [attachmentForm]="attachmentForm" [action]="action"></attachment-filters>
        </mat-card>
    </div>

    <div *ngIf="action === 'check'" style="width: 100%" class="form">
        <attachment-filters-check [attachmentForm]="attachmentForm" [action]="action"
            [processing]="processing"></attachment-filters-check>
    </div>
    <div *ngIf="action === 'check'" fxLayout="column" style="width: 100%; flex-grow: 1">
        <!-- Attachments Results Table -->
        <mat-card class="mx-6 py-0" style="flex-grow: 1">
            <app-attachment-list [results]="results"></app-attachment-list>
        </mat-card>
    </div>

    <!-- Second Column Send Only -->
    <div *ngIf="action === 'send'" fxLayout="column" class="files" style="width: 50%">
        <div fxLayout="row" class="fileSelect" style="width: 80%">
            <mat-card class="zone zone-value flex-column" style="width: 100%">
                <mat-form-field class="sc-ul s-l s-34 reset">
                    <mat-label>Επιλογή Αρχείων</mat-label>

                    <ngx-mat-file-input [formControl]="fileControl" [multiple]="multiple" [accept]="accept"
                        [color]="color" class="file-input">
                        <mat-icon ngxMatFileInputIcon
                            style="font-size: 28px; color: cornflowerblue">attach_file</mat-icon>
                    </ngx-mat-file-input>
                </mat-form-field>
                <mat-error *ngIf="
                        fileControl.touched && fileControl.hasError('maxSize')
                    ">Το μέγεθος του άρχειου υπερβαίνει το επιτρεπόμενο όριο των
                    8Mb</mat-error>
            </mat-card>
        </div>

        <div fxLayout="row" class="results mt-6" style="width: 80%">
            <mat-card class="zone zone-value flex-column" style="width: 100%">
                <div class="title">Επιλεγμένα αρχεία:</div>
                <div class="value">
                    <ul>
                        <li *ngFor="let item of files">
                            {{ item?.name }} :
                            {{ item?.size / 1024 | number: "1.0-0" }}Kb
                        </li>
                    </ul>
                </div>
            </mat-card>
        </div>
    </div>
</div>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
    <button style="width: 25% !important" mat-raised-button [ngClass]="color1" (mouseenter)="changeStyle1($event)"
        (mouseleave)="changeStyle1($event)" (click)="cancel()">
        Ακυρο
    </button>
    <button *ngIf="action === 'check'" style="width: 25% !important" mat-raised-button [disabled]="
            processing || attachmentForm.invalid
        " [ngClass]="color2" (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)"
        (click)="onSubmit(action)">
        {{ submitTitle }}

        <mat-icon *ngIf="processing"><mat-spinner diameter="20"> </mat-spinner></mat-icon>
    </button>

    <button *ngIf="action === 'send'" style="width: 25% !important" mat-raised-button [disabled]="
            processing ||
            fileControl.invalid ||
            attachmentForm.invalid
        " [ngClass]="color2" (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)"
        (click)="onSubmit(action)">
        {{ submitTitle }}

        <mat-icon *ngIf="processing"><mat-spinner diameter="20"> </mat-spinner></mat-icon>
    </button>
</mat-dialog-actions>