<div mat-dialog-title class="primary" fxLayout="row" style="padding: 10px 20px">
    <div>
        <span>Μέτρα {{ action }} <br /></span>
        <span style="font-size: 14px; font-weight: normal; line-height: 0.5">
            <p>Κωδικός: {{ code }}</p>
            <p>{{ prop }} {{ country }}</p>
        </span>
    </div>
    <!--  <div>
                <h1><small>MRN </small>{{ mrn }}</h1>
            </div> -->
    <div>
        <button mat-icon-button (click)="onCloseConfirm()">
            <mat-icon> highlight_off</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <!-- First Column -->
    <div style="width: 100%">
        <mat-card class="zone zone-value flex-column" fxLayout="column">
            <tariffs></tariffs>
        </mat-card>
    </div>

    <!-- Second Column Send Only -->
</div>
<!-- <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
    <button
        style="width: 25% !important"
        mat-raised-button
        [ngClass]="color1"
        (mouseenter)="changeStyle1($event)"
        (mouseleave)="changeStyle1($event)"
        (click)="cancel()"
    >
        Ακυρο
    </button>
    <button
        style="width: 25% !important"
        mat-raised-button
        [ngClass]="color2"
        (mouseenter)="changeStyle2($event)"
        (mouseleave)="changeStyle2($event)"
        (click)="setExit()"
    >
        Τελωνείο Εξόδου
    </button>
</mat-dialog-actions>
 -->
