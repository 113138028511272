import { FormControl, Validators } from '@angular/forms';
import { TirPacgsModel } from './pacgs2.model';

export class TirPacgsForm {
    [key: string]: FormControl;

    MarNumOfPacGS21 = new FormControl(null, { updateOn: 'blur' });
    MarNumOfPacGS21LNG = new FormControl();
    NumOfPacGS24 = new FormControl(null, { updateOn: 'blur' });
    NumOfPieGS25 = new FormControl(null, { updateOn: 'blur' });

    KinOfPacGS23 = new FormControl(null);

    constructor(value: TirPacgsModel) {
        this.MarNumOfPacGS21.setValue(value.MarNumOfPacGS21);
        this.MarNumOfPacGS21LNG.setValue(value.MarNumOfPacGS21LNG);
        this.NumOfPacGS24.setValue(value.NumOfPacGS24);
        this.NumOfPieGS25.setValue(value.NumOfPieGS25);
        this.KinOfPacGS23.setValue(value.KinOfPacGS23);
    }
}
