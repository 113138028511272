import { AbstractControl, FormGroup, FormArray } from "@angular/forms";
import { SubmittingOperatorForm } from "../../submittingOperator/submittingOperator-form.model";
import { SubmittingOperatorModel } from "../../submittingOperator/submittingOperator.model";
import { VehicleArrivalForm } from "../../vehicleArrival/vehicleArrival-form.model";
import { VehicleArrivalModel } from "../../vehicleArrival/vehicleArrival.model";
import { VehicleArrivalConsigneeForm } from "../../vehicleArrivalConsignee/vehicleArrivalConsigee-form.model";
import { VehicleArrivalConsigneeModel } from "../../vehicleArrivalConsignee/vehicleArrivalConsigee.model";
import { VehicleArrivalConsignorForm } from "../../vehicleArrivalConsignor/vehicleArrivalConsignor-form.model";
import { VehicleArrivalConsignorModel } from "../../vehicleArrivalConsignor/vehicleArrivalConsignor.model";
import { VehicleDetailsForm } from "../../vehicleDetails/vehicleDetails-form.model";
import { VehicleDetailsModel } from "../../vehicleDetails/vehicleDetails.model";
import { DaoBodyModel, VehicleArrivalDeclarationRegistrationType } from "./body.model";


export class DaoBodyForm {
    [key: string]: AbstractControl;
    VehicleArrivalDeclarationRegistration: FormGroup;

    constructor(value: DaoBodyModel) {
        this.VehicleArrivalDeclarationRegistration = new FormGroup(new VehicleArrivalDeclarationRegistrationForm(new VehicleArrivalDeclarationRegistrationType(value.VehicleArrivalDeclarationRegistration)));

    }
}


export class VehicleArrivalDeclarationRegistrationForm {
    [key: string]: AbstractControl;

    SubmittingOperator: FormGroup;
    VehicleArrival: FormGroup;
    VehicleArrivalConsignor: FormGroup;
    VehicleArrivalConsignee: FormGroup;
    VehicleDetails: FormArray;


    constructor(value: VehicleArrivalDeclarationRegistrationType) {

        this.SubmittingOperator = new FormGroup(new SubmittingOperatorForm(new SubmittingOperatorModel(value.SubmittingOperator)));
        this.VehicleArrival = new FormGroup(new VehicleArrivalForm(new VehicleArrivalModel(value.VehicleArrival)));
        this.VehicleArrivalConsignor = new FormGroup(new VehicleArrivalConsignorForm(new VehicleArrivalConsignorModel(value.VehicleArrivalConsignor)));
        this.VehicleArrivalConsignee = new FormGroup(new VehicleArrivalConsigneeForm(new VehicleArrivalConsigneeModel(value.VehicleArrivalConsignee)));

        this.VehicleDetails = new FormArray([]);

        if (value.VehicleDetails) {
            value.VehicleDetails.forEach((details: VehicleDetailsModel) => {
                this.VehicleDetails.push(new FormGroup(new VehicleDetailsForm(new VehicleDetailsModel(details))));
            });
        }
        else {
            this.VehicleDetails.push(new FormGroup(new VehicleDetailsForm(new VehicleDetailsModel())));
        }


    }
}
