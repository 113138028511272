import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { TirHeaheaModel } from './heahea.model';
export class TirHeaheaForm {
    [key: string]: AbstractControl;
    //DOESNT EXIST ON CC515A
    DocNumHEA5 = new FormControl();

    //HEADER FORM
    RefNumHEA4 = new FormControl();
    CouOfDesCodHEA30 = new FormControl();
    AgrLocOfGooCodHEA38 = new FormControl();
    AgrLocOfGooHEA39 = new FormControl();
    AgrLocOfGooHEA39LNG = new FormControl();
    AutLocOfGooCodHEA41 = new FormControl();
    PlaOfLoaCodHEA46 = new FormControl();
    CouOfDisCodHEA55 = new FormControl();
    CusSubPlaHEA66 = new FormControl();
    InlTraModHEA75 = new FormControl();
    TraModAtBorHEA76 = new FormControl();
    IdeOfMeaOfTraAtDHEA78 = new FormControl(null, { updateOn: 'blur' });
    IdeOfMeaOfTraAtDHEA78LNG = new FormControl();
    NatOfMeaOfTraAtDHEA80 = new FormControl();
    IdeOfMeaOfTraCroHEA85 = new FormControl();
    IdeOfMeaOfTraCroHEA85LNG = new FormControl();
    NatOfMeaOfTraCroHEA87 = new FormControl();
    TypOfMeaOfTraCroHEA88 = new FormControl();
    ConIndHEA96 = new FormControl();
    DiaLanIndAtDepHEA254 = new FormControl();
    NCTSAccDocHEA601LNG = new FormControl();
    TotNumOfIteHEA305 = new FormControl();
    TotNumOfPacHEA306 = new FormControl();
    TotGroMasHEA307 = new FormControl();
    DecDatHEA383 = new FormControl();
    DecPlaHEA394 = new FormControl();
    DecPlaHEA394LNG = new FormControl();
    AmdPlaHEA598 = new FormControl();
    AmdPlaHEA598LNG = new FormControl();
    AmdDatHEA599 = new FormControl();
    SpeCirIndHEA1 = new FormControl();
    TraChaMetOfPayHEA1 = new FormControl();
    ComRefNumHEA = new FormControl();
    SecHEA358 = new FormControl();
    ConRefNumHEA = new FormControl();
    CodPlUnHEA357 = new FormControl();
    CodPlUnHEA357LNG = new FormControl();
    TypOfDecHEA24 = new FormControl();
    //TF013A====================================
    //AmdPlaHEA598 = new FormControl();
    //AmdPlaHEA598LNG = new FormControl();
    //RefT2LYeaHEA1012 = new FormControl();
    //RefT2CouCodHEA1010 = new FormControl();
    //RefT2LTypHEA1014 = new FormControl();
    //RefT2LCusHEA1013 = new FormControl();
    //RefT2LNumHEA1015 = new FormControl();
    //AmdDatHEA599 = new FormControl();

    constructor(value: TirHeaheaModel) {
        this.DocNumHEA5.setValue(value.DocNumHEA5);

        this.RefNumHEA4.setValue(value.RefNumHEA4);
        this.CouOfDesCodHEA30.setValue(value.CouOfDesCodHEA30);
        this.AgrLocOfGooCodHEA38.setValue(value.AgrLocOfGooCodHEA38);
        this.AgrLocOfGooHEA39.setValue(value.AgrLocOfGooHEA39);
        this.AgrLocOfGooHEA39LNG.setValue(value.AgrLocOfGooHEA39LNG);
        this.AutLocOfGooCodHEA41.setValue(value.AutLocOfGooCodHEA41);
        this.PlaOfLoaCodHEA46.setValue(value.PlaOfLoaCodHEA46);
        this.CouOfDisCodHEA55.setValue(value.CouOfDisCodHEA55);
        this.CusSubPlaHEA66.setValue(value.CusSubPlaHEA66);
        this.InlTraModHEA75.setValue(value.InlTraModHEA75);
        this.TraModAtBorHEA76.setValue(value.TraModAtBorHEA76);
        this.IdeOfMeaOfTraAtDHEA78.setValue(value.IdeOfMeaOfTraAtDHEA78);
        this.IdeOfMeaOfTraAtDHEA78LNG.setValue(value.IdeOfMeaOfTraAtDHEA78LNG);
        this.NatOfMeaOfTraAtDHEA80.setValue(value.NatOfMeaOfTraAtDHEA80);
        this.IdeOfMeaOfTraCroHEA85.setValue(value.IdeOfMeaOfTraCroHEA85);
        this.IdeOfMeaOfTraCroHEA85LNG.setValue(value.IdeOfMeaOfTraCroHEA85LNG);
        this.NatOfMeaOfTraCroHEA87.setValue(value.NatOfMeaOfTraCroHEA87);
        this.TypOfMeaOfTraCroHEA88.setValue(value.TypOfMeaOfTraCroHEA88);
        this.ConIndHEA96.setValue(value.ConIndHEA96);
        this.DiaLanIndAtDepHEA254.setValue(value.DiaLanIndAtDepHEA254);
        this.NCTSAccDocHEA601LNG.setValue(value.NCTSAccDocHEA601LNG);
        this.TotNumOfIteHEA305.setValue(value.TotNumOfIteHEA305);
        this.TotNumOfPacHEA306.setValue(value.TotNumOfPacHEA306);
        this.TotGroMasHEA307.setValue(value.TotGroMasHEA307);

        this.DecDatHEA383.setValue(value.DecDatHEA383);
        this.DecPlaHEA394.setValue(value.DecPlaHEA394);
        this.DecPlaHEA394LNG.setValue(value.DecPlaHEA394LNG);

        this.AmdPlaHEA598.setValue(value.AmdPlaHEA598);
        this.AmdPlaHEA598LNG.setValue(value.AmdPlaHEA598LNG);
        this.AmdDatHEA599.setValue(value.AmdDatHEA599);

        this.SpeCirIndHEA1.setValue(value.SpeCirIndHEA1);
        this.TraChaMetOfPayHEA1.setValue(value.TraChaMetOfPayHEA1);
        this.ComRefNumHEA.setValue(value.ComRefNumHEA);
        this.SecHEA358.setValue(value.SecHEA358);
        this.ConRefNumHEA.setValue(value.ConRefNumHEA);
        this.CodPlUnHEA357.setValue(value.CodPlUnHEA357);
        this.CodPlUnHEA357LNG.setValue(value.CodPlUnHEA357LNG);
        this.TypOfDecHEA24.setValue(value.TypOfDecHEA24);
        //==============TF013A=======================================
        //this.AmdPlaHEA598.setValue(value.AmdPlaHEA598);
        //this.AmdPlaHEA598LNG.setValue(value.AmdPlaHEA598LNG);
        //this.RefT2LYeaHEA1012.setValue(value.RefT2LYeaHEA1012);
        //this.RefT2CouCodHEA1010.setValue(value.RefT2CouCodHEA1010);
        //this.RefT2LTypHEA1014.setValue(value.RefT2LTypHEA1014);
        //this.RefT2LCusHEA1013.setValue(value.RefT2LCusHEA1013);
        //this.RefT2LNumHEA1015.setValue(value.RefT2LNumHEA1015);
        //this.AmdDatHEA599.setValue(value.AmdDatHEA599);

        this.TypOfDecHEA24.setValidators(Validators.required);
    }
}
