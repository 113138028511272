import { FormControl, Validators } from '@angular/forms';
import { ComCodModel } from './comcodgodtm.model';

export class ComCodForm {
    [key: string]: FormControl;

    ComNomCMD1 = new FormControl(null, { updateOn: 'blur' });
    TARCodCMD1 = new FormControl(null, { updateOn: 'blur' });
    TARFirAddCodCMD1 = new FormControl(null, { updateOn: 'blur' });
    TARSecAddCodCMD1 = new FormControl(null, { updateOn: 'blur' });
    NAtAddCodCMD1 = new FormControl(null, { updateOn: 'blur' });
    _combinedComCode = new FormControl(null, { updateOn: 'blur' });

    constructor(value: ComCodModel) {
        console.log('COMCOD COSTRUCTOR');
        this.ComNomCMD1.setValue(value.ComNomCMD1);
        this.TARCodCMD1.setValue(value.TARCodCMD1);
        this.TARFirAddCodCMD1.setValue(value.TARFirAddCodCMD1);
        this.TARSecAddCodCMD1.setValue(value.TARSecAddCodCMD1);
        this.NAtAddCodCMD1.setValue(value.NAtAddCodCMD1);
        this._combinedComCode.setValue(value._combinedComCode);
    }
}
