export class TradatModel {
    CurTRD1?: string;
    TotAmoInvTRD1?: string;
    ExcRatTRD1?: string;
    NatOfTraFirCodTRD1?: string;
    NatOfTraSecCodTRD1?: string;

    constructor(value?) {
        if (value) {
            this.CurTRD1 = value.CurTRD1;
            this.TotAmoInvTRD1 = value.TotAmoInvTRD1;
            this.ExcRatTRD1 = value.ExcRatTRD1;
            this.NatOfTraFirCodTRD1 = value.NatOfTraFirCodTRD1;
            this.NatOfTraSecCodTRD1 = value.NatOfTraSecCodTRD1;
        }
    }
}
