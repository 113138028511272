import { LoadingDtsModel } from './loadingDts/loadingDts.model';

export class HeaheaModel {
    DocNumHEA5?: string;

    RefNumHEA4?: string;
    TypOfDecHEA24?: string;
    CouOfDesCodHEA30?: string;
    AgrLocOfGooCodHEA38?: string;
    AgrLocOfGooHEA39?: string;
    AgrLocOfGooHEA39LNG?: string;
    AutLocOfGooCodHEA41?: string;
    CouOfDisCodHEA55?: string;
    CusSubPlaHEA66?: string;
    InlTraModHEA75?: string;
    TraModAtBorHEA76?: string;
    IdeOfMeaOfTraAtDHEA78?: string;
    IdeOfMeaOfTraAtDHEA78LNG?: string;
    IdeOfMeaOfTraCroHEA85?: string;
    IdeOfMeaOfTraCroHEA85LNG?: string;
    NatOfMeaOfTraCroHEA87?: string;
    TypOfMeaOfTraCroHEA88?: string;
    ConIndHEA96?: string;
    AccDatHEA158?: string;
    DiaLanIndAtDepHEA254?: string;
    ECSAccDocHEA601?: string;
    TotNumOfIteHEA305?: string;
    TotNumOfPacHEA306?: string;
    TotGroMasHEA307?: string;
    //Only on CC515A
    DecDatHEA383?: string;
    DecPlaHEA394?: string;
    DecPlaHEA394LNG?: string;
    //CC599A only
    ExiDatHEA1?: string;
    //Only on CC513A
    AmdPlaHEA598?: string;
    AmdPlaHEA598LNG?: string;
    AmdDatHEA599?: string;
    //Only ON CC504A
    AmeAccDatHEA602?: string;
    //////////////////////////
    SpeCirIndHEA1?: string;
    TraChaMetOfPayHEA1?: string;
    ComRefNumHEA?: string;
    GraTotTaxAmoHEA1?: string;
    TypOfDecBx12HEA651?: string;
    PlaLoaGOOITE334?: string;
    ECSAccDocDatHEA1023?: string;
    AddDecDatHEA1024?: string;
    TIRFolHEA1025?: string;
    FinBanInfHEA1027?: string;
    ManEntNumHEA1028?: string;
    ManEntYeaHEA1029?: string;
    LoadingDts?: LoadingDtsModel[];


    //USED FOR TIR ONLY=========================
    NatOfMeaOfTraAtDHEA80?: string;

    constructor(value?) {
        if (value) {
            this.DocNumHEA5 = value.DocNumHEA5;

            this.RefNumHEA4 = value.RefNumHEA4;
            this.TypOfDecHEA24 = value.TypOfDecHEA24 || null;
            this.CouOfDesCodHEA30 = value.CouOfDesCodHEA30;
            this.AgrLocOfGooCodHEA38 = value.AgrLocOfGooCodHEA38;
            this.AgrLocOfGooHEA39 = value.AgrLocOfGooHEA39;
            this.AgrLocOfGooHEA39LNG = value.AgrLocOfGooHEA39LNG;
            this.AutLocOfGooCodHEA41 = value.AutLocOfGooCodHEA41;
            this.CouOfDisCodHEA55 = value.CouOfDisCodHEA55;
            this.CusSubPlaHEA66 = value.CusSubPlaHEA66;
            this.InlTraModHEA75 = value.InlTraModHEA75;
            this.TraModAtBorHEA76 = value.TraModAtBorHEA76;
            this.IdeOfMeaOfTraAtDHEA78 = value.IdeOfMeaOfTraAtDHEA78;
            this.IdeOfMeaOfTraAtDHEA78LNG = value.IdeOfMeaOfTraAtDHEA78LNG;
            this.IdeOfMeaOfTraCroHEA85 = value.IdeOfMeaOfTraCroHEA85;
            this.IdeOfMeaOfTraCroHEA85LNG = value.IdeOfMeaOfTraCroHEA85LNG;
            this.NatOfMeaOfTraCroHEA87 = value.NatOfMeaOfTraCroHEA87;
            this.TypOfMeaOfTraCroHEA88 = value.TypOfMeaOfTraCroHEA88;
            this.ConIndHEA96 = value.ConIndHEA96;
            this.AccDatHEA158 = value.AccDatHEA158;
            this.DiaLanIndAtDepHEA254 = value.DiaLanIndAtDepHEA254;
            this.ECSAccDocHEA601 = value.ECSAccDocHEA601;
            this.TotNumOfIteHEA305 = value.TotNumOfIteHEA305;
            this.TotNumOfPacHEA306 = value.TotNumOfPacHEA306;
            this.TotGroMasHEA307 = value.TotGroMasHEA307;

            this.DecDatHEA383 = value.DecDatHEA383;
            this.DecPlaHEA394 = value.DecPlaHEA394;
            this.DecPlaHEA394LNG = value.DecPlaHEA394LNG;

            this.ExiDatHEA1 = value.ExiDatHEA1;

            this.AmdPlaHEA598 = value.AmdPlaHEA598;
            this.AmdPlaHEA598LNG = value.AmdPlaHEA598LNG;
            this.AmdDatHEA599 = value.AmdDatHEA599;

            this.AmeAccDatHEA602 = value.AmeAccDatHEA602;

            this.SpeCirIndHEA1 = value.SpeCirIndHEA1;
            this.TraChaMetOfPayHEA1 = value.TraChaMetOfPayHEA1;
            this.ComRefNumHEA = value.ComRefNumHEA;
            this.GraTotTaxAmoHEA1 = value.GraTotTaxAmoHEA1;
            this.TypOfDecBx12HEA651 = value.TypOfDecBx12HEA651 || null;
            this.PlaLoaGOOITE334 = value.PlaLoaGOOITE334;
            this.ECSAccDocDatHEA1023 = value.ECSAccDocDatHEA1023;
            this.AddDecDatHEA1024 = value.AddDecDatHEA1024;
            this.TIRFolHEA1025 = value.TIRFolHEA1025;
            this.FinBanInfHEA1027 = value.FinBanInfHEA1027;
            this.ManEntNumHEA1028 = value.ManEntNumHEA1028;
            this.ManEntYeaHEA1029 = value.ManEntYeaHEA1029;
            this.LoadingDts = value.LoadingDts;

            this.NatOfMeaOfTraAtDHEA80 = value.NatOfMeaOfTraAtDHEA80;

        }
    }
}
