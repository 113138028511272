import { environment } from '../../../../../../environments/environment.test';
import { Contact } from "app/main/apps/contacts/contact.model";

export class VehiclesModel {



    vehicleType: string;
    vehicleUse: 'public' | 'private';
    chassisType: string;
    firstAllowanceDate: Date;
    calculationOfTaxesDate: Date;
    carCertificateCategory: string;
    carbonDioxideEmissions: number;
    mileage: number;
    engineCapacity: number;
    retailValueBeforeTax: number;
    vehicleUsedFlag: 0 | 1;

    //=================================
    monthsTotal: number;
    ageReductionRate: number;

    mileageReductionRate: number;
    totalReductionRate: number;
    //==================================
    carCertificateIncreaseRate: number;
    caravanIncreaseRate: number;
    addTaricCode: string;
    co2IncreaseRate: number;
    hybridReductionRate: number;
    caravanReductionRate: number;
    publicUseRate: number;

    carsCalculationData: CarsCalculationDataModel[];
    carsTotals: CarsTotalsModel;


    constructor(value?: VehiclesModel) {



        const defaultStartDate = new Date();

        this.vehicleType = value?.vehicleType || "1"; //Epivatika
        this.vehicleUse = value?.vehicleUse || "private";
        this.chassisType = value?.chassisType || null;
        this.firstAllowanceDate = value?.firstAllowanceDate || null;
        this.calculationOfTaxesDate = value?.calculationOfTaxesDate || defaultStartDate;
        this.carCertificateCategory = value?.carCertificateCategory || null;
        this.carbonDioxideEmissions = value?.carbonDioxideEmissions || null;
        this.mileage = value?.mileage || null;
        this.engineCapacity = value?.engineCapacity || null;
        this.retailValueBeforeTax = value?.retailValueBeforeTax || null;
        this.vehicleUsedFlag = value?.vehicleUsedFlag || null;

        //=================================
        this.monthsTotal = value?.monthsTotal || null;
        this.ageReductionRate = value?.ageReductionRate || null;
        this.mileageReductionRate = value?.mileageReductionRate || null;
        this.totalReductionRate = value?.totalReductionRate || null;
        //==================================
        this.carCertificateIncreaseRate = value?.carCertificateIncreaseRate || null;
        this.caravanIncreaseRate = value?.caravanIncreaseRate || null;
        this.addTaricCode = value?.addTaricCode || null;
        this.co2IncreaseRate = value?.co2IncreaseRate || null;
        this.hybridReductionRate = value?.hybridReductionRate || null;
        this.caravanReductionRate = value?.caravanReductionRate || null;
        this.publicUseRate = value?.publicUseRate || null;


        this.carsCalculationData = value?.carsCalculationData || null;
        this.carsTotals = value?.carsTotals || null;
    }




}

export class CarsCalculationDataModel {

    splitRetailValue?: number;
    reducedRetailValue?: number;
    taxRateInitial?: number;
    taxRateIncreasedByCategory?: number;
    taxRateIncreasedByCaravan?: number;
    taxRateIncreasedByCo2?: number;
    subGroupTax?: number;


    constructor(value?: CarsCalculationDataModel) {


        this.splitRetailValue = value?.splitRetailValue || null;
        this.reducedRetailValue = value?.reducedRetailValue || null;
        this.taxRateInitial = value?.taxRateInitial || null;
        this.taxRateIncreasedByCategory = value?.taxRateIncreasedByCategory || null;
        this.taxRateIncreasedByCaravan = value?.taxRateIncreasedByCaravan || null;
        this.taxRateIncreasedByCo2 = value?.taxRateIncreasedByCo2 || null;
        this.subGroupTax = value?.subGroupTax || null;
    }

}

export class CarsTotalsModel {
    reducedValueTotal: number;

    subTotal: number;
    environmentTax: number;
    hybridReduction: number;
    caravanReduction: number;
    taxTotal: number;
    publicUseTotal: number;

    constructor(value?: CarsTotalsModel) {


        this.reducedValueTotal = value?.reducedValueTotal || 0;
        this.subTotal = value?.subTotal || 0;
        this.environmentTax = value?.environmentTax || 0;
        this.hybridReduction = value?.hybridReduction || 0;
        this.caravanReduction = value?.caravanReduction || 0;
        this.taxTotal = value?.taxTotal || 0;
        this.publicUseTotal = value?.publicUseTotal || 0;
    }

}