import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { Pipe, PipeTransform } from '@angular/core';
import { CustomsDialogContentService } from './customs-dialog-content.service';

@Pipe({
    name: 'role'
})
export class RolePipe implements PipeTransform {
    constructor(private customsDialogService: CustomsDialogContentService) { }
    transform(code: string, args?: any): any {
        return this.customsDialogService.getRole(code);
    }
}

@Pipe({
    name: 'note'
})
export class NotePipe implements PipeTransform {
    constructor(private customsDialogService: CustomsDialogContentService) { }
    transform(code: string, args?: any): any {
        return this.customsDialogService.getNote(code);
    }
}

@Pipe({
    name: 'geoInfo'
})
export class GeoInfoPipe implements PipeTransform {
    constructor(private customsDialogService: CustomsDialogContentService) { }
    transform(code: string, args?: any): any {
        return this.customsDialogService.getGeoInfo(code);
    }
}

@Pipe({
    name: 'trafficType'
})
export class TrafficTypePipe implements PipeTransform {
    constructor(private customsDialogService: CustomsDialogContentService) { }
    transform(code: string, args?: any): any {
        return this.customsDialogService.getTrafficType(code);
    }
}

@Pipe({
    name: 'greekDay'
})
export class GreekDayPipe implements PipeTransform {
    transform(d: string, args?: any): any {
        let day: string;

        switch (+d) {
            case 1: {
                day = 'ΔΕΥ';
                break;
            }
            case 2: {
                day = 'ΤΡΙ';
                break;
            }
            case 3: {
                day = 'ΤΕΤ';
                break;
            }
            case 4: {
                day = 'ΠΕΜ';
                break;
            }
            case 5: {
                day = 'ΠΑΡ';
                break;
            }
            case 6: {
                day = 'ΣΑΒ';
                break;
            }
            case 7: {
                day = 'ΚΥΡ';
                break;
            }
        }
        return day;
    }
}

@Pipe({
    name: 'ddmm'
})
export class FormatDatePipe implements PipeTransform {
    constructor(private dateTransform: DateTransformPipe) { }
    transform(date: string, args?: any): any {
        return this.dateTransform.formatDate(date, 'dd/mm');
    }
}
