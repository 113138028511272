export class EMCSHeaderModel {

    MessageSender: string;
    MessageRecipient: string;
    DateOfPreparation: string;
    TimeOfPreparation: string;
    MessageIdentifier: string;
    CorrelationIdentifier?: string;
    MessageRole?: string;

    constructor(value?) {

        this.MessageSender = value?.MessageSender;
        this.MessageRecipient = value?.MessageRecipient;
        this.DateOfPreparation = value?.DateOfPreparation;
        this.TimeOfPreparation = value?.TimeOfPreparation;
        this.MessageIdentifier = value?.MessageIdentifier;
        this.CorrelationIdentifier = value?.CorrelationIdentifier;
        this.MessageRole = value?.MessageRole;
    }

}
