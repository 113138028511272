import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { SubmittingOperatorForm } from "app/main/apps/excise/dao/_models/submittingOperator/submittingOperator-form.model";
import { SubmittingOperatorModel } from "app/main/apps/excise/dao/_models/submittingOperator/submittingOperator.model";
import { CancelTaxesDeclarationModel, DeclarationOfExciseTaxesCancellationType, EF10ABodyModel } from "./body.model";

export class EF10ABodyForm {
    [key: string]: AbstractControl;
    DeclarationOfExciseTaxesCancellation: FormGroup;

    constructor(value: EF10ABodyModel) {
        this.DeclarationOfExciseTaxesCancellation = new FormGroup(new DeclarationOfExciseTaxesCancellationForm(new DeclarationOfExciseTaxesCancellationType(value.DeclarationOfExciseTaxesCancellation)));

    }
}


export class DeclarationOfExciseTaxesCancellationForm {
    [key: string]: AbstractControl;

    SubmittingOperator: FormGroup;
    ExciseTaxesDeclaration: FormGroup;


    constructor(value: DeclarationOfExciseTaxesCancellationType) {

        this.SubmittingOperator = new FormGroup(new SubmittingOperatorForm(new SubmittingOperatorModel(value.SubmittingOperator)));
        this.ExciseTaxesDeclaration = new FormGroup(new CancelTaxesDeclarationForm(new CancelTaxesDeclarationModel(value.ExciseTaxesDeclaration)));

    }
}


export class CancelTaxesDeclarationForm {
    [key: string]: AbstractControl;

    DeclarationReferenceNumber: FormControl;
    CancellationReason: FormControl;


    constructor(value: CancelTaxesDeclarationModel) {

        this.DeclarationReferenceNumber = new FormControl(value.DeclarationReferenceNumber);
        this.CancellationReason = new FormControl(value.CancellationReason);

    }
}
