import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType, HttpDownloadProgressEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { StateResponse } from 'app/services/declaration-state.service';

@Injectable()
export class ReportProgressInterceptor implements HttpInterceptor {
    prevLength = 0;

    constructor(private cf: ExtCustomFunctions) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

        if (req.headers.has('X-Skip-Interceptor')) {
            // Clone the request without the custom header and forward it
            const newReq = req.clone({ headers: req.headers.delete('X-Skip-Interceptor') });
            return next.handle(newReq);
        }


        if (req.reportProgress) {
            // only intercept when the request is configured to report its progress
            return next.handle(req).pipe(
                tap((event: HttpEvent<any>) => {
                    //Final Response from our server
                    //we use the final response to reset our vars
                    //we don't care about the actual response
                    if (event.type === HttpEventType.Response) {
                        this.prevLength = 0;
                    }
                }),
                filter((event: HttpEvent<any>) => event.type === HttpEventType.DownloadProgress),
                map((event: HttpDownloadProgressEvent) => {
                    //get the partialText property of the received event

                    const partialText = event.partialText;
                    //   if (partialText !== undefined) {
                    //split the received text on '|' and filter empty elements
                    const textArray: string[] = partialText?.split('|').filter(x => x);

                    console.log('interceptor partialText:', partialText);
                    console.log('interceptor textArray:', textArray);

                    if (textArray && textArray.length > 0) {
                        return this.responseToJson(textArray);
                    }
                    return null;

                }),
                filter(res => res !== null) // don't emit null values
            );
        } else {
            return next.handle(req);
        }
    }
    responseToJson(textArray): StateResponse[] {

        const { jsonArray, prevLength } = this.cf.responseToJson(textArray, this.prevLength)

        this.prevLength = prevLength;

        return jsonArray;
    }
}
