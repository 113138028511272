import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { CountryPipeModule } from 'app/services/pipes/countryName.pipe';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { CustomsDialogComponent } from './customs-dialog/customs-dialog.component';
import { CustomsDialogContentComponent } from './customs-dialog-content/customs-dialog-content.component';
import { CustomsListComponent } from './customs-list/customs-list.component';
import { CustomsFiltersComponent } from './customs-filters/customs-filters.component';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { CustomsSearchComponent } from './customs-search/customs-search.component';
import { CustomsService } from './customs.service';
import { CustomsEntryComponent } from './customs-entry.component';
import { CustomsResolver } from './customs.resolver';
import { FormatDatePipe, GeoInfoPipe, GreekDayPipe, NotePipe, RolePipe, TrafficTypePipe } from './customs-dialog-content/customsPipes.pipe';

@NgModule({
    declarations: [
        CustomsEntryComponent,
        CustomsDialogComponent,
        CustomsDialogContentComponent,
        CustomsListComponent,
        CustomsFiltersComponent,
        CustomsSearchComponent,
        RolePipe,
        TrafficTypePipe,
        NotePipe,
        GeoInfoPipe,
        GreekDayPipe,
        FormatDatePipe
    ],
    imports: [
        FuseSharedModule,
        MaterialModule,
        ComboBoxModule,
        CountryPipeModule
    ],
    providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }, CustomsService, CustomsResolver, DateTransformPipe]
})
export class CustomsModule { }
