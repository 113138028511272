import { FormControl, Validators } from '@angular/forms';
import { EntcusoffModel } from './entcusoff.model';

export class EntcusoffForm {
    [key: string]: FormControl;
    _countryCode = new FormControl();
    EnCusOffRefNum01 = new FormControl();

    constructor(value: EntcusoffModel) {
        this._countryCode.setValue(value._countryCode);
        this.EnCusOffRefNum01.setValue(value.EnCusOffRefNum01);

        // this.RefNumEXT1.setValidators([Validators.required]);
    }
}
