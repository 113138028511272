import {
    AbstractControl, FormControl, FormArray,
    FormGroup, Validators
} from "@angular/forms";
import { VehiclesModel, CarsTotalsModel, CarsCalculationDataModel } from "app/main/apps/excise-helper-tables/vehicles/_models/vehicles.model";

export class VehiclesForm {
    [key: string]: AbstractControl;
    vehicleType: FormControl;
    vehicleUse: FormControl;
    chassisType: FormControl;
    firstAllowanceDate: FormControl;
    calculationOfTaxesDate: FormControl;
    carCertificateCategory: FormControl;
    carbonDioxideEmissions: FormControl;
    mileage: FormControl;
    engineCapacity: FormControl;
    retailValueBeforeTax: FormControl;
    vehicleUsedFlag: FormControl;
    //=================================
    monthsTotal: FormControl;
    ageReductionRate: FormControl;
    mileageReductionRate: FormControl;
    totalReductionRate: FormControl;
    //==================================
    carCertificateIncreaseRate: FormControl;
    caravanIncreaseRate: FormControl;
    addTaricCode: FormControl;
    co2IncreaseRate: FormControl;
    hybridReductionRate: FormControl;
    caravanReductionRate: FormControl;
    publicUseRate: FormControl;

    carsCalculationData: FormArray;
    carsTotals: FormGroup;


    constructor(value?: VehiclesModel) {

        this.vehicleType = new FormControl(value?.vehicleType);
        this.vehicleUse = new FormControl(value?.vehicleUse);
        this.chassisType = new FormControl(value?.chassisType);
        this.firstAllowanceDate = new FormControl(value?.firstAllowanceDate, { updateOn: 'blur' });
        this.calculationOfTaxesDate = new FormControl(value?.calculationOfTaxesDate, { updateOn: 'blur' });
        this.carCertificateCategory = new FormControl(value?.carCertificateCategory);
        this.caravanIncreaseRate = new FormControl(value?.caravanIncreaseRate);
        this.carbonDioxideEmissions = new FormControl(value?.carbonDioxideEmissions, { updateOn: 'blur' });
        this.mileage = new FormControl(value?.mileage, { updateOn: 'blur' });
        this.engineCapacity = new FormControl(value?.engineCapacity, { updateOn: 'blur' });
        this.retailValueBeforeTax = new FormControl(value?.retailValueBeforeTax, { updateOn: 'blur' });
        this.vehicleUsedFlag = new FormControl(value?.vehicleUsedFlag)
        //=================================
        this.monthsTotal = new FormControl(value?.monthsTotal);
        this.ageReductionRate = new FormControl(value?.ageReductionRate);
        this.mileageReductionRate = new FormControl(value?.mileageReductionRate);
        this.totalReductionRate = new FormControl(value?.totalReductionRate);
        //==================================
        this.carCertificateIncreaseRate = new FormControl(value?.carCertificateIncreaseRate);
        this.addTaricCode = new FormControl(value?.addTaricCode);
        this.co2IncreaseRate = new FormControl(value?.co2IncreaseRate);
        this.hybridReductionRate = new FormControl(value?.hybridReductionRate);
        this.caravanReductionRate = new FormControl(value?.caravanReductionRate);
        this.publicUseRate = new FormControl(value?.publicUseRate);

        this.carsCalculationData = new FormArray([]);

        this.carsTotals = new FormGroup(new CarsTotalsForm(new CarsTotalsModel(value.carsTotals)));

        if (value.carsCalculationData) {
            value.carsCalculationData.forEach((data: CarsCalculationDataModel) => {
                this.carsCalculationData.push(new FormGroup(new CarsCalculationDataForm(new CarsCalculationDataModel(data))))
            });
        }
        else {
            this.carsCalculationData.push(new FormGroup(new CarsCalculationDataForm(new CarsCalculationDataModel())))
        }


    }
}

export class CarsCalculationDataForm {
    [key: string]: AbstractControl;

    splitRetailValue: FormControl;
    reducedRetailValue: FormControl;
    taxRateInitial: FormControl;
    taxRateIncreasedByCategory: FormControl;
    taxRateIncreasedByCaravan: FormControl;
    taxRateIncreasedByCo2: FormControl;
    subGroupTax: FormControl;


    constructor(value?: CarsCalculationDataModel) {
        this.splitRetailValue = new FormControl(value?.splitRetailValue);
        this.reducedRetailValue = new FormControl(value?.reducedRetailValue);
        this.taxRateInitial = new FormControl(value?.taxRateInitial);
        this.taxRateIncreasedByCategory = new FormControl(value?.taxRateIncreasedByCategory);
        this.taxRateIncreasedByCaravan = new FormControl(value?.taxRateIncreasedByCaravan);
        this.taxRateIncreasedByCo2 = new FormControl(value?.taxRateIncreasedByCo2);
        this.subGroupTax = new FormControl(value?.subGroupTax);
    }

}
export class CarsTotalsForm {
    [key: string]: AbstractControl;

    reducedValueTotal: FormControl;
    subTotal: FormControl;

    environmentTax: FormControl;
    hybridReduction: FormControl;
    caravanReduction: FormControl;
    taxTotal: FormControl;
    publicUseTotal: FormControl;

    constructor(value?: CarsTotalsModel) {
        this.reducedValueTotal = new FormControl(value?.reducedValueTotal);
        this.subTotal = new FormControl(value?.subTotal);
        this.environmentTax = new FormControl(value?.environmentTax);
        this.hybridReduction = new FormControl(value?.hybridReduction);
        this.caravanReduction = new FormControl(value?.caravanReduction);
        this.taxTotal = new FormControl(value?.taxTotal);
        this.publicUseTotal = new FormControl(value?.publicUseTotal);
    }
}
