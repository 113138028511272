import { NgModule } from '@angular/core';
import { BeerModule } from 'app/main/apps/excise-helper-tables/beer/beer.module';
import { TsipouraModule } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura.module';
import { VehiclesModule } from 'app/main/apps/excise-helper-tables/vehicles/vehicles.module';

const MODULES = [

    TsipouraModule,
    BeerModule,
    VehiclesModule,


]
@NgModule({
    imports: MODULES,
    exports: MODULES
})
export class ExciseHelperTablesModule { }
