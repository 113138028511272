import { FormControl, AbstractControl } from '@angular/forms';
import { CustomsFiltersModel } from './customs-filters.model';

export class CustomsFiltersForm {
    [key: string]: AbstractControl;

    searchQuery = new FormControl();
    countryQuery = new FormControl();

    constructor(value: CustomsFiltersModel) {
        this.searchQuery.setValue(value.searchQuery);
        this.countryQuery.setValue(value.countryQuery);
    }
}
