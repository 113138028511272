<table class="cars-header-reductions" style="width:100%" [formGroup]="vehiclesForm">
    <colgroup>
        <col style="width:50%">
        <col style="width:30%">

    </colgroup>
    <tbody>

        <tr>
            <td class=" text-left">Μήνες</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class="text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('monthsTotal').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('monthsTotal').value" />
            </td>
            <td class="printOnly text-left">{{vehiclesForm.get('monthsTotal').value}}</td>
        </tr>
        <tr>
            <td class=" text-left">Απομείωση Παλαιότητας (%)</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class="text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('ageReductionRate').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('ageReductionRate').value | percent:'1.2-2'" />
            </td>
            <td class="printOnly text-left">{{vehiclesForm.get('ageReductionRate').value| percent:'1.2-2' }}</td>

        </tr>
        <tr>
            <td class=" text-left">Απομείωση Xιλιομέτρων (%)</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class="text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('mileageReductionRate').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('mileageReductionRate').value | percent:'1.2-2'" />
            </td>
            <td class="printOnly text-left">{{vehiclesForm.get('mileageReductionRate').value | percent:'1.2-2'}}</td>

        </tr>
        <tr>
            <td class=" text-left">Συνολική Απομείωση (%)</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class="excel-green text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('totalReductionRate').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('totalReductionRate').value | percent:'1.2-2'" />
            </td>
            <td class="printOnly text-left">{{vehiclesForm.get('totalReductionRate').value | percent:'1.2-2'}}</td>

        </tr>
        <tr *ngIf="vehiclesForm.get('vehicleType').value ==='18'">
            <td class=" text-left">Μείωση Υβριδικού (%)</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class="excel-green text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('hybridReductionRate').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('hybridReductionRate').value | percent:'1.2-2'" />
            </td>
            <td class="printOnly text-left">{{vehiclesForm.get('hybridReductionRate').value | percent:'1.2-2'}}</td>

        </tr>
        <tr *ngIf="vehiclesForm.get('vehicleType').value ==='10'">
            <td class=" text-left">Μείωση Τροχόσπιτου (%)</td>
            <td class="noPrint">
                <input tabindex="-1" style="width:80px" class="excel-green text-bold"
                    [ngClass]="{'excel-disabled': vehiclesForm.get('caravanReductionRate').disabled}" readonly
                    autocomplete="false" [value]="vehiclesForm.get('caravanReductionRate').value | percent:'1.2-2'" />
            </td>
            <td class="printOnly text-left">{{vehiclesForm.get('caravanReductionRate').value | percent:'1.2-2'}}</td>

        </tr>


    </tbody>
</table>