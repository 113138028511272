import { CustomerInvoiceParsingFormComponent } from './customer-invoice-parsing-form/customer-invoice-parsing-form.component';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { Observable, Subject, take, takeUntil, Subscription } from 'rxjs';
import { CustomerInvoiceParsingService } from './customer-invoice-parsing.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';



@Component({
    selector: 'app-customer-invoice-parsing',
    templateUrl: './customer-invoice-parsing.component.html',
    styleUrls: ['./customer-invoice-parsing.component.scss'],
    providers: [CustomerInvoiceParsingService]
})
export class CustomerInvoiceParsingComponent implements OnInit, OnDestroy {
    @ViewChild('invoiceParsingForm') _invoiceParsingForm: CustomerInvoiceParsingFormComponent
    showButtons = true;
    enableButtons$: Observable<boolean>;
    showResults = false;
    loading$: Observable<boolean>;
    accept: string;
    color1 = '';
    color2 = 'primary';
    results: any;
    invoiceParsingForm: FormGroup
    subscription: Subscription;
    private _destroy: Subject<void> = new Subject()
    constructor(
        public dialogRef: MatDialogRef<CustomerInvoiceParsingComponent>,
        private cf: ExtCustomFunctions,
        private invoiceParsingService: CustomerInvoiceParsingService,
        private mainBroadcaster: MainBroadcasterService
    ) { }

    ngOnInit(): void {

        this.loading$ = this.mainBroadcaster.loading$
        this.invoiceParsingService.invoiceParsingForm.pipe(takeUntil(this._destroy)).subscribe(value => {
            this.invoiceParsingForm = value
            console.log("DISABLED", this.invoiceParsingForm.value)
        })

        this.enableButtons$ = this.invoiceParsingService.enableButtons

    }

    ngAfterViewInit() {
        this._invoiceParsingForm._fileInput.nativeElement.click()
    }

    ngOnDestroy() {
        this._destroy.next()
        this._destroy.complete()
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }
    onCloseConfirm() {
        this.dialogRef.close();
    }
    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event);
    }
    changeStyle2(event) {
        if (this.invoiceParsingForm.valid) {
            this.color2 = this.cf.changeStyle2(this.invoiceParsingForm, event);
        }
    }

    onSubmit() {

        /*     this.results = { "invoice": "4980", "trader": "094016220", "consignee": "VOCE PROMET DOO", "transport": "GE4870AC/GE2428AC", "goods": [{ "product": "ORANGES", "items": [{ "product": "ORANGES", "packs": "264", "gross": "2830.0", "net": "2665.0", "value": "1678.95" }, { "product": "ORANGES", "packs": "264", "gross": "2885.0", "net": "2720.0", "value": "1523.20" }, { "product": "ORANGES", "packs": "176", "gross": "1845.01", "net": "1735.0", "value": "902.20" }, { "product": "ORANGES", "packs": "640", "gross": "8870.0", "net": "8360.0", "value": "3176.80" }, { "product": "ORANGES", "packs": "400", "gross": "5540.0", "net": "5220.0", "value": "1983.60" }] }], "totals": { "packs": "1744", "gross": "21970", "net": "20700", "value": "9264.75" } }
    
            this.mainBroadcaster.updateLoading(false)
            this.showResults = true; */

        this.subscription && this.subscription.unsubscribe();
        this.subscription = this.invoiceParsingService.submit().subscribe((results) => {

            if (results) {
                this.results = results
                this.mainBroadcaster.updateLoading(false)
                this.showResults = true;
            }
        })
    }


    pasteResults() {


        this.invoiceParsingService.invoiceParsingResultsForm$.pipe(take(1)).subscribe(form => {

            this.mainBroadcaster.updateCustomerInvoiceDetails(form.value)
        })

    }



}
