export class Tracorsec037Model {
    NamTRACORSEC041?: string;
    StrNumTRACORSEC043?: string;
    PosCodTRACORSEC042?: string;
    CitTRACORSEC038?: string;
    CouCodTRACORSEC039?: string;
    TRACORSEC037LNG?: string;
    TINTRACORSEC044?: string;
    TINTypTRACORSEC045?: string;
    ExporterObject?: {};

    constructor(value?) {
        this.NamTRACORSEC041 = value?.NamTRACORSEC041 || null;
        this.StrNumTRACORSEC043 = value?.StrNumTRACORSEC043 || null;
        this.PosCodTRACORSEC042 = value?.PosCodTRACORSEC042 || null;
        this.CitTRACORSEC038 = value?.CitTRACORSEC038 || null;
        this.CouCodTRACORSEC039 = value?.CouCodTRACORSEC039 || null;
        this.TRACORSEC037LNG = value?.TRACORSEC037LNG || null;
        this.TINTRACORSEC044 = value?.TINTRACORSEC044 || null;
        this.TINTypTRACORSEC045 = value?.TINTypTRACORSEC045 || null;
        this.ExporterObject = value?.ExporterObject || {};
    }
}
