import { FormControl, Validators } from '@angular/forms';
import { NctsSpeMenModel } from './spemenmt2.model';

export class NctsSpeMenForm {
    [key: string]: FormControl;

    AddInfMT21 = new FormControl();
    AddInfMT21LNG = new FormControl();
    AddInfCodMT23 = new FormControl();
    ExpFroECMT24 = new FormControl();
    ExpFroCouMT25 = new FormControl();

    constructor(value: NctsSpeMenModel) {
        this.AddInfMT21.setValue(value.AddInfMT21);
        this.AddInfMT21LNG.setValue(value.AddInfMT21LNG);
        this.AddInfCodMT23.setValue(value.AddInfCodMT23);
        //TIR ONLY
        this.ExpFroECMT24.setValue(value.ExpFroECMT24);
        this.ExpFroCouMT25.setValue(value.ExpFroCouMT25);
    }
}
