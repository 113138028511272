import { FormControl, Validators } from '@angular/forms';
import { GrnModel } from './grn.model';

export class GrnForm {
    [key: string]: FormControl;

    GrnNum1010 = new FormControl();

    constructor(value: GrnModel) {
        this.GrnNum1010.setValue(value.GrnNum1010);
    }
}
