import { tsipouraFieldMappings } from 'app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura-field-mappings';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TsipouraModel } from 'app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura.model';
import { TsipouraService } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura.service';
import { environment } from 'environments/environment';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { TsipouraDefkCreateService, TsipouraParameters } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura-defk-create.service';
import { DEFKDeclarationForm } from 'app/main/apps/excise/defk/_models/EF15A/declaration-form.model';
import { AbstractControlErrorService, ErrorMessageConfig } from 'app/services/abstract-contol-error.service';

@Component({
    selector: 'app-tsipoura',
    templateUrl: './tsipoura.component.html',
    styleUrls: ['./tsipoura.component.scss'],
    providers: [TsipouraDefkCreateService]
})
export class TsipouraComponent implements OnInit {

    tsipouraForm: FormGroup
    defkDeclarationForm: FormGroup<DEFKDeclarationForm>
    params: TsipouraParameters;
    errorMessages: string[] = [];
    showBanner = false;

    color1 = '';
    color2 = 'primary';

    private boilerCapacitySubscription: Subscription;
    private workHoursPerDaySubscription: Subscription;
    private obligeeSubscription: Subscription;



    private _destroy = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<TsipouraComponent>,
        private mainBroadcaster: MainBroadcasterService,
        private tsipouraService: TsipouraService,
        private cf: ExtCustomFunctions,
        private createDefkService: TsipouraDefkCreateService,
        private errorService: AbstractControlErrorService
    ) { }

    ngOnInit() {
        console.log('TsipouraComponent ngOnInit')

        this.tsipouraService.getTsipouraParams()
            .pipe(
                tap(params => this.params = params[0]),
                switchMap(() => this.mainBroadcaster.declarationForms$.pipe(map(forms => forms['DEFK']))),
                switchMap(defkDeclaration => this.tsipouraService.tsipouraForm$.pipe(map(form => ({ form, defkDeclaration })))),
                map(
                    (value: { form: FormGroup, defkDeclaration: FormGroup }, index) => {
                        this.tsipouraForm = value.form
                        this.defkDeclarationForm = value?.defkDeclaration

                        const savedForm = value?.defkDeclaration?.get('_PRIVATE')?.get('twoDayDistillers')?.value

                        if (index === 0) {

                            this.subscribeToFormControls();

                            if (savedForm) {
                                this.tsipouraService.loadLocalStorage(savedForm)
                                this.subscribeToFormControls();
                            }
                        }

                        return value.form;
                    }
                ),
                switchMap(_ => this.tsipouraForm.valueChanges.pipe(startWith(this.tsipouraForm.value), debounceTime(200), takeUntil(this._destroy))),
                tap((value: TsipouraModel) => {
                    this.tsipouraService.calculateProducerTimes()
                })
            )
            .subscribe()


        this.dialogRef.afterClosed().subscribe(_ => {
            if (this.defkDeclarationForm) {

                this.tsipouraService.patchPrivateCtrls(this.defkDeclarationForm, this.tsipouraForm.value)
            }
        })



    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    subscribeToFormControls() {
        // Unsubscribe from the old subscriptions if they exist
        this.boilerCapacitySubscription?.unsubscribe();
        this.workHoursPerDaySubscription?.unsubscribe();
        this.obligeeSubscription?.unsubscribe();

        // Create new subscriptions and assign them to the properties
        this.boilerCapacitySubscription = this.tsipouraService.subToBoilerCapacityValueChanges().pipe(takeUntil(this._destroy)).subscribe();
        this.workHoursPerDaySubscription = this.tsipouraService.subToWorkHoursPerDayValueChanges().pipe(takeUntil(this._destroy)).subscribe();
        this.obligeeSubscription = this.tsipouraService.subToObligeeValueChanges().pipe(takeUntil(this._destroy)).subscribe();
    }

    // ...

    onPrint() {
        const content = document.getElementById('printable').outerHTML;
        const cssPath = environment.DOMAIN_NAME + '/assets/css/twoDayDistillers-print.css'; // Your logged css path
        const newWindow = window.open('', '_blank');

        if (newWindow && newWindow.document) {
            // Create new link element
            const linkEl = newWindow.document.createElement('link');
            linkEl.setAttribute('rel', 'stylesheet');
            linkEl.setAttribute('type', 'text/css');

            // Set href attribute to the cssPath
            linkEl.setAttribute('href', cssPath);

            // Append the link element to the head of the document
            newWindow.document.head.appendChild(linkEl);

            // Add content to the body of the new window
            newWindow.document.body.innerHTML = content;

            // Listen for the load event on the link element
            linkEl.onload = function () {
                newWindow.print();
                newWindow.close();
            };
        }
    }

    onSubmit() {

        if (this.tsipouraForm.status === 'INVALID') {

            const customErrorConfig: ErrorMessageConfig[] = [{
                path: 'tsipouraCalculationData',
            }];
            const allErrors = this.errorService.getAllErrors(this.tsipouraForm);
            this.errorService.convertErrorsToFriendlyMessages(allErrors, [], tsipouraFieldMappings, customErrorConfig);
            console.log("Tsipoura Errors", allErrors);
            console.log("Tsipoura Errors Messages", this.errorMessages);
            this.showBanner = true

            return;
        }
        this.createDefkService.createDefk(this.tsipouraForm.value, this.params).then(_ => {

            this.dialogRef.close()
        })
    }



    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event);
    }
    changeStyle2(event) {
        if (this.tsipouraForm.valid) {
            this.color2 = this.cf.changeStyle2(this.tsipouraForm, event);
        }
    }
    onCloseConfirm() {
        this.dialogRef.close()
    }
}
