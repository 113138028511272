import { FormControl, Validators } from '@angular/forms';
import { ProDocModel } from './prodocdc2.model';

export class ProDocForm {
    [key: string]: FormControl;

    DocTypDC21 = new FormControl();
    DocRefDC23 = new FormControl();
    DocRefDCLNG = new FormControl();
    DocInfDC1008 = new FormControl();
    DocInfDC1008LNG = new FormControl();

    constructor(value: ProDocModel) {
        this.DocTypDC21.setValue(value.DocTypDC21);
        this.DocRefDC23.setValue(value.DocRefDC23);
        this.DocRefDCLNG.setValue(value.DocRefDCLNG);
        this.DocInfDC1008.setValue(value.DocInfDC1008);
        this.DocInfDC1008LNG.setValue(value.DocInfDC1008LNG);
    }
}
