import { Declarant } from 'app/core/user/user.model';
export class eAitisiTradecModel {
    NamTDE1?: string;
    StrAndNumTDE1?: string;
    PosCodTDE1?: string;
    CiTDE1?: string;
    CouCodTDE1?: string;
    PhoneDECL?: string;
    EmailDECL?: string;
    IDTypDECL?: number;
    IDNumDECL?: string;
    DeclarantObject?: Declarant;

    constructor(value?) {
        this.NamTDE1 = value?.NamTDE1 || null;
        this.StrAndNumTDE1 = value?.StrAndNumTDE1 || null;
        this.PosCodTDE1 = value?.PosCodTDE1 || null;
        this.CiTDE1 = value?.CiTDE1 || null;
        this.CouCodTDE1 = value?.CouCodTDE1 || null;
        this.PhoneDECL = value?.PhoneDECL || null;
        this.EmailDECL = value?.EmailDECL || null;
        this.IDTypDECL = value?.IDTypDECL || null;
        this.IDNumDECL = value?.IDNumDECL || null;
        this.DeclarantObject = value?.DeclarantObject;
    }
}
