<mat-nav-list style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
    <mat-list-item (click)="setCodeDesc(product, 'code')">
        <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
            <p matLine fxFlex>Χρήση Κωδικού</p>
        </div>
    </mat-list-item>

    <mat-list-item (click)="setCodeDesc(product, 'description')">
        <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
            <p matLine fxFlex>Χρήση Περιγραφής</p>
        </div>
    </mat-list-item>
    <mat-list-item (click)="setCodeDesc(product, 'both')">
        <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
            <p matLine fxFlex>Χρήση Κωδικού και Περιγραφής</p>
        </div>
    </mat-list-item>
</mat-nav-list>
