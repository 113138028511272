import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { genericItem } from 'app/model/api-model';
import { ApiService, ApiEndPoints } from 'app/services/api.service';
import { takeUntil } from 'rxjs';
import { AutoCompConfigValues } from '../../form-fields/custom-autocomplete/config.model';
import { BaseBuildingComponent } from '../../_baseComponent/base.component';

@Component({
    selector: 'app-generic-autocomplete',
    templateUrl: './generic-autocomplete.component.html',
    styleUrls: ['./generic-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericAutocompleteComponent extends BaseBuildingComponent implements OnInit {

    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() endpoint: string;
    @Input() classes: string;
    @Input() required?: boolean;
    @Input() formDisabled?: boolean;
    @Input() showDesc?: boolean;
    @Input() descStyle?: string;


    options: genericItem[] = [];

    config: AutoCompConfigValues = {
        output: 'code',
        menuOptions: {
            options: ['code', 'desc']
        }
    };

    description: string;

    get control(): FormControl {
        return this.formGroup.get(this.controlName) as FormControl;
    }


    constructor(
        protected hostElement: ElementRef,
        protected cf: ExtCustomFunctions,
        private dbQuery: ApiService
    ) {
        super(hostElement, cf)


    }

    ngOnInit() {

        this.dbQuery.get_options(ApiEndPoints[this.endpoint]).subscribe((data: genericItem[]) => {
            this.options = data;

            this.description = this.options.filter(e => e.code === this.control.value)[0]?.desc

        });



        this.control.valueChanges.pipe(takeUntil(this._destroy)).subscribe(value => {

            this.control.setValue(value, { emitEvent: false })

            this.description = this.options.filter(e => e.code === value)[0]?.desc

        })
    }


}
