import { Injectable, Injector } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { StorageService } from 'app/core/services/storage/storage.service';
import { VehiclesForm } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles-form.model';
import { genericItem } from 'app/model/api-model';
import { BehaviorSubject, Observable, take, startWith, switchMap, Subject, takeUntil, merge, EMPTY, combineLatest } from 'rxjs';
import { VehiclesModel } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.model';
import { DEFKDeclarationForm } from 'app/main/apps/excise/defk/_models/EF15A/declaration-form.model';
import { CarsService } from 'app/main/apps/excise-helper-tables/vehicles/cars/cars.service';
import { TrucksService } from 'app/main/apps/excise-helper-tables/vehicles/trucks/trucks.service';
import { VehiclesQueriesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicle-queries.service';
import { ElectricService } from 'app/main/apps/excise-helper-tables/vehicles/electric.service';

@Injectable()
export class VehiclesService {
    private vehiclesForm: BehaviorSubject<FormGroup<VehiclesForm>>
    vehiclesForm$: Observable<FormGroup<VehiclesForm>>
    private vehicleTypeGeneral: BehaviorSubject<string>
    vehicleTypeGeneral$: Observable<string>

    destroy$ = new Subject<void>();

    constructor(
        private storageService: StorageService, private vehicleQueriesService: VehiclesQueriesService, private carsService: CarsService, private trucksService: TrucksService, private electricService: ElectricService) {

        this.vehiclesForm = new BehaviorSubject(new FormGroup(new VehiclesForm(new VehiclesModel())))
        this.vehiclesForm$ = this.vehiclesForm.asObservable()

        this.vehicleTypeGeneral = new BehaviorSubject('cars')
        this.vehicleTypeGeneral$ = this.vehicleTypeGeneral.asObservable()

        console.log('CarsService constructor vehiclesForm', this.vehiclesForm.getValue())
        this.vehicleQueriesService.getCarEuroClassOptions().pipe(take(1)).subscribe(params => {
            this.vehiclesForm.next(new FormGroup(new VehiclesForm(new VehiclesModel())))
        })
    }

    patchPrivateCtrls(defkDeclarationForm: FormGroup<DEFKDeclarationForm>, value: VehiclesModel) {
        defkDeclarationForm.get('_PRIVATE').get('cars').setValue(value)
    }
    loadLocalStorage(savedForm: VehiclesModel) {
        let form: FormGroup<VehiclesForm>;

        if (savedForm) {
            form = new FormGroup(new VehiclesForm(new VehiclesModel(savedForm)));
        }
        else {
            const localValue = this.storageService.getLocalObject('cars') as VehiclesModel;

            form = new FormGroup(new VehiclesForm(new VehiclesModel(localValue)));
        }
        this.vehiclesForm.next(form);
    }

    subToVehicleChassisType(): Observable<[number, string]> {
        const vehiclesForm = this.vehiclesForm.getValue()

        const vehicleType$ = vehiclesForm.get('vehicleType') as FormControl;
        const chassisType$ = vehiclesForm.get('chassisType') as FormControl;

        return combineLatest([
            vehicleType$.valueChanges.pipe(startWith(vehicleType$.value)),
            chassisType$.valueChanges.pipe(startWith(chassisType$.value))
        ])
    }
    setVehicleRelatedSubscriptions(): Observable<any> {

        const vehiclesForm = this.vehiclesForm.getValue()
        const vehicleType = vehiclesForm.get('vehicleType') as FormControl

        return vehicleType.valueChanges.pipe(
            startWith(vehicleType.value),
            switchMap((vehicleType) => {

                // Unsubscribe from any previous subscriptions if needed
                this.destroy$.next();

                vehiclesForm.enable({ emitEvent: false });
                vehiclesForm.reset({ vehicleType, vehicleUse: 'private', calculationOfTaxesDate: new Date() }, { emitEvent: false })

                if (this.isCarType(vehicleType)) {
                    // Return an observable that combines all car-related observables
                    return this.cars(vehiclesForm)
                } else if (this.isTruckType(vehicleType)) {
                    // Return the observable for truck-related operations
                    return this.trucks(vehiclesForm);
                }
                else if (this.isElectricType(vehicleType)) {
                    // Return the observable for electric-related operations
                    this.electricService.disableControls(vehiclesForm)
                    this.electricService.setElectricVehicleValues(vehiclesForm)
                    return this.electricService.subToRetailValue(vehiclesForm).pipe(takeUntil(this.destroy$));
                }

                // If neither, return an empty observable
                return EMPTY;
            }))
    }


    // Utility functions to check vehicle type
    isCarType(vehicleType: number): boolean {
        const carsTypes = [1, 9, 10, 18];//Επιβατικα, Jeep, Αυτοκινουμενα τροχσπιτα, Υβριδικα   
        this.vehicleTypeGeneral.next('cars')
        return carsTypes.includes(+vehicleType);
    }

    isTruckType(vehicleType: number): boolean {
        const truckTypes = [2, 7, 8, 19, 20, 21];
        this.vehicleTypeGeneral.next('trucks')
        return truckTypes.includes(+vehicleType);
    }

    isElectricType(vehicleType: number): boolean {
        const electricTypes = [15, 16, 25];
        this.vehicleTypeGeneral.next('electric')
        return electricTypes.includes(+vehicleType);
    }

    private cars(vehiclesForm: FormGroup): Observable<any> {
        //reset vehicleUse
        vehiclesForm.get('vehicleUse').setValue('private')

        this.vehicleQueriesService.setInitialPriceRanges()
        return this.combineCarObservables(vehiclesForm);

    }
    private combineCarObservables(vehiclesForm: FormGroup): Observable<any> {
        // Return an observable that combines all car-related observables
        return merge(
            this.carsService.subToDatesValueChanges(vehiclesForm).pipe(takeUntil(this.destroy$)),
            this.carsService.subToMonthsCarTypeValueChanges(vehiclesForm).pipe(takeUntil(this.destroy$)),
            this.carsService.subToMileageValueChanges(vehiclesForm).pipe(takeUntil(this.destroy$)),
            this.carsService.subToCarCertificateValueChanges(vehiclesForm).pipe(takeUntil(this.destroy$)),
            this.carsService.subToCarEmissionsValueChanges(vehiclesForm).pipe(takeUntil(this.destroy$)),
            this.carsService.subToRetailValue(vehiclesForm).pipe(takeUntil(this.destroy$)),
            this.carsService.subToCo2EmissionsHybridValueChanges(vehiclesForm).pipe(takeUntil(this.destroy$)),
            this.carsService.subToVehicleUseValueChange(vehiclesForm).pipe(takeUntil(this.destroy$)),

        );
    }

    private trucks(vehiclesForm: FormGroup): Observable<any> {

        this.trucksService.disableControls(vehiclesForm)
        this.trucksService.setAddTaricCode(vehiclesForm)
        this.trucksService.setInitialTaxes(vehiclesForm)
        this.vehicleQueriesService.updateChassisTypeOptions(this.trucksService.truckTypes)

        return merge(
            this.vehicleQueriesService.getTruckEuroClassOptions().pipe(take(1)),
            this.trucksService.subToCarCertificateValueChanges(vehiclesForm).pipe(takeUntil(this.destroy$)),
            this.trucksService.subToRetailValue(vehiclesForm).pipe(takeUntil(this.destroy$)),
            this.trucksService.subToChassisTypeValueChanges(vehiclesForm).pipe(takeUntil(this.destroy$)),

        )
    }


}
