import { FormControl, Validators } from '@angular/forms';
import { IdewarModel } from './idewar.model';

export class IdewarForm {
    [key: string]: FormControl;

    WarTypWID1 = new FormControl();
    AutCouWID1 = new FormControl();
    WarIdeWARIDE19 = new FormControl();

    constructor(value: IdewarModel) {
        this.WarTypWID1.setValue(value.WarTypWID1);
        this.AutCouWID1.setValue(value.AutCouWID1);
        this.WarIdeWARIDE19.setValue(value.WarIdeWARIDE19);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
