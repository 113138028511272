import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { TransactionCategoryForm } from "app/main/businesses/transactions/_models/transaction-category/transaction-category-form.model";
import { TransactionCategoryModel } from "app/main/businesses/transactions/_models/transaction-category/transaction-category.model";
import { TransactionLineItemModel } from "app/main/businesses/transactions/_models/transaction-line-item/transaction-line-item.model";

export class TransactionLineItemForm {

    [key: string]: AbstractControl;

    itemType = new FormControl();
    amount = new FormControl();
    category: FormGroup;

    constructor(value: TransactionLineItemModel) {
        this.itemType.setValue(value?.itemType);
        this.amount.setValue(value?.amount);
        this.category = new FormGroup(new TransactionCategoryForm(new TransactionCategoryModel(value?.category)));

    }
}