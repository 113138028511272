import { FormControl } from '@angular/forms';
import { Sgicodsd2Model } from './sgicodsd2.model';

export class Sgicodsd2Form {
    [key: string]: FormControl;

    SenGooCodSD22 = new FormControl();
    SenQuaSD23 = new FormControl();

    constructor(value: Sgicodsd2Model) {
        this.SenGooCodSD22.setValue(value.SenGooCodSD22);
        this.SenQuaSD23.setValue(value.SenQuaSD23);
    }
}
