import { EPaymentForm } from './e-payment-form.model';
import { AbstractControl, FormControl, FormArray, FormGroup } from "@angular/forms";
import { RemittanceModel } from "./remittance.model";
import { EPaymentModel } from './e-payment.model';

export class RemittanceForm {
    [key: string]: AbstractControl;

    declarant: FormControl;
    ePayment: FormArray;

    /*

   */

    constructor(declaration: RemittanceModel) {


        this.declarant = new FormControl(declaration.declarant);
        this.ePayment = new FormArray([]);

        if (declaration.ePayment) {
            declaration.ePayment.forEach((ePayment: EPaymentModel) => {
                this.ePayment.push(new EPaymentForm(new EPaymentModel(ePayment)));
            });
        }
        else {

            this.ePayment.push(new EPaymentForm(new EPaymentModel()));

        }
    }
}
