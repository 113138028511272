import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    Optional,
    Self,
    ViewChild,
    ViewEncapsulation,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatFormFieldControl } from '@angular/material/form-field';
import { getUnixTime } from 'date-fns';

@Component({
    selector: 'date-select',
    styleUrls: ['./date-select.component.scss'],
    templateUrl: './date-select.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: MatFormFieldControl, useExisting: DateSelectComponent }],
    host: {
        '(focusout)': 'onTouched()'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateSelectComponent implements AfterViewInit, ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {
    static nextId = 0;

    private _ID = `date-select-${++DateSelectComponent.nextId}`;
    private _dateType = 'dd/MM/yyyy';
    private _defaultDate: number;
    public _pickerValue: Date;
    public _inputValue: string;
    private _hideCal = false;
    private _min: Date;
    private _max: Date;

    private _disabled = false;
    private _focused = false;
    private _autofocus = false;
    private _placeholder = '';
    private _required = false;
    private _readonly = false;
    private _tabIndex = 0;
    private _trapFocus = false;


    private destroy: Subject<void> = new Subject<void>();

    @Input() unixTimestamp: boolean;

    @Input()
    get hideCal(): boolean {
        return this._hideCal;
    }
    set hideCal(value: boolean) {
        this._hideCal = value;
    }
    @Input() _value;
    onChange: any = () => { };
    onTouched: any = () => { };

    get value() {
        return this._value;
    }
    set value(val) {
        this._value = val;
        this.onChange(val);
        this.stateChanges.next();
    }

    //
    @Input()
    get defaultDate(): number {
        return this._defaultDate;
    }
    set defaultDate(value: number) {
        this._defaultDate = value;
        this.stateChanges.next();
    }

    @Input()
    get dateType(): string {
        return this._dateType;
    }
    set dateType(value: string) {
        this._dateType = value;
    }

    @Input()
    get min(): Date {
        return this._min;
    }
    set min(value: Date) {
        this._min = value;
        this.stateChanges.next();
    }

    @Input()
    get max(): Date {
        return this._max;
    }
    set max(value: Date) {
        this._max = value;
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input()
    get readonly(): boolean {
        return this._readonly;
    }
    set readonly(value: boolean) {
        this._readonly = value;
        this._hideCal = value;
        this.stateChanges.next();
    }
    @Input()
    get autofocus(): boolean {
        return this._autofocus;
    }
    set autofocus(value: boolean) {
        this._autofocus = value;
        this.stateChanges.next();
    }

    @Input()
    get tabindex(): number {
        return this._tabIndex;
    }
    set tabindex(value: number) {
        this._tabIndex = value;
        this.stateChanges.next();
    }

    @Input()
    get trapFocus(): boolean {
        return this._trapFocus;
    }
    set trapFocus(value: boolean) {
        this._trapFocus = value;
    }

    @Output() blur: EventEmitter<any> = new EventEmitter();

    @Output() focus: EventEmitter<any> = new EventEmitter(); //

    @HostBinding('attr.aria-describedby')
    describedBy = '';

    @HostBinding('id')
    externalId = `date-select-${++DateSelectComponent.nextId}`;

    //we set both the custom autocomplete's id (externalId) and the input's id (id)
    //if an id is not set default id is used for externalId above and for id (private _ID above)
    @Input()
    set id(value: string) {
        this._ID = value + '-input';
        this.externalId = value;
    }

    get id() {
        return this._ID;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @ViewChild('date', { read: ElementRef })
    inputRef: ElementRef<HTMLInputElement>;

    controlType = 'custom-date-select';

    get empty(): boolean {
        const n = this.value;
        return !n;
    }

    get errorState(): boolean {
        return this.ngControl.errors !== null && !!this.ngControl.touched;
    }
    get focused(): boolean {
        return this._focused;
    }
    set focused(value: boolean) {
        this._focused = value;
        this.stateChanges.next();
    }

    stateChanges: Subject<void> = new Subject<void>();

    constructor(
        private focusMonitor: FocusMonitor,
        private elementRef: ElementRef<HTMLElement>,
        @Optional() @Self() public ngControl: NgControl,
        public datepipe: DatePipe,
        private dateTimeService: DateTransformPipe,
        private cd: ChangeDetectorRef
    ) {
        if (ngControl) {
            // Set the value accessor directly (instead of providing
            // NG_VALUE_ACCESSOR) to avoid running into a circular import
            this.ngControl.valueAccessor = this;
            ngControl.valueAccessor = this;
        }
    }

    ngAfterViewInit(): void {

        console.log("READONLY: ", this.readonly)
        this.focusMonitor.monitor(this.elementRef.nativeElement, true).subscribe(focusOrigin => {
            this.focused = !!focusOrigin;
        });

        if (this.defaultDate) {

            console.log('dueDate id: ' + this.id + ' this.defaultDate', this.defaultDate)


            if (this.unixTimestamp) {

                this._pickerValue = new Date(this.defaultDate * 1000);

                this.value = this.defaultDate

                this._inputValue = this.datepipe.transform(+this.defaultDate * 1000, 'dd/MM/yyyy');

                //console.log('dueDate this._inputValue dateSelect', this._inputValue)
                //console.log('dueDate this.defaultDate this.value', this.value)
                this.cd.detectChanges()
                return;
            }
            else {
                this._pickerValue = new Date(this.defaultDate);

                this.value = this.datepipe.transform(this.defaultDate, this.dateType)
                this._inputValue = this.datepipe.transform(this.defaultDate, 'dd/MM/yyyy');


            }
        }
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
        this.stateChanges.next();
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    }


    pickerEvent(event: MatDatepickerInputEvent<Date>) {
        const d = new Date(event.value);

        this._inputValue = this.datepipe.transform(d, 'dd/MM/yyyy');

        if (this.unixTimestamp) {
            this.value = getUnixTime(d);
            return;
        }

        this.value = this.datepipe.transform(d, this.dateType);
    }

    inputEvent(event) {
        //exlude Backspace (keyCode 8)
        if (event.keyCode !== 8) {
            const input = event.target.value;
            let out = input.replace(/\D/g, '');
            const len = out.length;

            //on Escape key (27)
            if (event.keyCode === 27) {
                console.log(event.keyCode);

                this._inputValue = '';
                this._pickerValue = null;
            } else {
                //DAY LOGIC
                if (len > 1 && len < 4) {
                    if (out.substring(0, 2) > '00' && out.substring(0, 2) <= '31') {
                        out = out.substring(0, 2) + '/' + out.substring(2, 3);
                    } else {
                        out = out.substring(0, 0);
                    }
                }

                //MONTH LOGIC
                else if (len >= 4 && len < 6) {
                    if (out.substring(2, 4) > '00' && out.substring(2, 4) <= '12') {
                        out = out.substring(0, 2) + '/' + out.substring(2, 4) + '/' + out.substring(4, 6);
                    } else {
                        out = out.substring(0, 2) + '/';
                    }
                }

                //YEAR LOGIC
                else if (len > 5) {
                    if (out.substring(4, 6) < '19' || out.substring(4, 6) >= '21') {
                        out = out.substring(0, 2) + '/' + out.substring(2, 4) + '/';
                    } else {
                        out = out.substring(0, 2) + '/' + out.substring(2, 4) + '/' + out.substring(4, len);
                    }
                }

                this._inputValue = out;
            }
        }

        const dd = this._inputValue.substring(0, 2);
        const MM = this._inputValue.substring(3, 5);
        const yyyy = this._inputValue.substring(6, 10);
        const d = new Date(MM + '/' + dd + '/' + yyyy);

        if (this._inputValue.length === 10) {
            if (this.unixTimestamp) {
                this.value = getUnixTime(d);
            }
            else {
                this.value = this.datepipe.transform(d, this.dateType);

            }

            this._pickerValue = d;
        } else {
            this.value = dd + MM + yyyy;
            this._pickerValue = null;
        }
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.focusMonitor.focusVia(this.inputRef.nativeElement, 'mouse');
        }
    }

    writeValue(value: any): void {

        const isUnixTime = /^\d{10}$/.test(value);

        if (isUnixTime) {
            console.log("dateSelect UNIX Timestamp writeValue", value)

            const date = new Date(value * 1000)
            //set DatePicker value with date
            this._pickerValue = date;

            // set Input Value
            this._inputValue = this.datepipe.transform(date, 'dd/MM/yyyy');

            return

        }

        console.log("dateSelect writeValue", value)
        //convert string to Date
        const date = this.dateTimeService.getDate(value)
        //set DatePicker value with date
        this._pickerValue = value;
        // set Input Value
        this._inputValue = this.datepipe.transform(date, 'dd/MM/yyyy');

    }

    registerOnChange(fn: (_: string | null) => void): void {
        this.onChange = (value: string | null) => {
            fn(value === '' ? null : value);
        };
    }


    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cd.detectChanges();
    }
}
