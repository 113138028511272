<div>
    <input #focusMe matInput [placeholder]="placeholder" [disabled]="disabled" [tabindex]="tabindex"
        [autofocus]="autofocus" [(ngModel)]="value" [matAutocomplete]="auto" (keyup.esc)="onEscape($event)"
        (focus)="onFocus($event)" (blur)="onBlur($event)" />
    <mat-autocomplete autoActiveFirstOption panelWidth="auto" #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option[outputValue]">
            <!-- 		<img style="vertical-align:middle;" aria-hidden src="{{option.flag}}" height="25" />
		 -->
            <span *ngIf="!!menuTitle1">{{ menuTitle1 }}</span><span>{{ option[menuOption1] }}</span>

            <span *ngIf="!!menuOption2"> | </span>
            <small *ngIf="!!menuOption2">
                <span *ngIf="!!menuTitle2">{{ menuTitle2 }}</span><span> {{ option[menuOption2] }}</span></small>
        </mat-option>
    </mat-autocomplete>
</div>