<div fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 20%">
            <span>Προορισμός</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="flex-start center" mat-dialog-content style="
            overflow-y: auto;
            min-height: 100px;
            height: 100px;
            padding: 0 16px;
        ">

        <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%">

            <div fxLayout="column" fxLayoutAlign="center start" style="width: 100%; margin-bottom: 16px">

                <table class="eAitisiHea twoColumns" [formGroup]="VehicleArrivalConsignee">
                    <tbody>
                        <tr>
                            <td>Αρ. Αποθήκης</td>
                            <td>
                                <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                    <input #firstInput matInput formControlName="VehicleTaxWarehouseReference" />
                                </mat-form-field>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div fxLayout="column" fxLayoutAlign="center start" class="group">
                    <h2>Τόπος Προορισμού</h2>
                    <table class="eAitisiHea twoColumns" [formGroup]="deliveryPlace">
                        <tbody>
                            <tr>
                                <td>Οδός</td>
                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <input matInput formControlName="StreetName" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Αριθμός</td>

                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <input matInput formControlName="StreetNumber" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Τ.Κ.</td>

                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <input matInput formControlName="PostCode" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Δήμος</td>

                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <input matInput formControlName="Municipality" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Νομός</td>

                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-34">
                                        <input matInput formControlName="Prefecture" />
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


    <div>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="cancel()">
                Ακυρο
            </button>
            <button style="width: auto !important" mat-raised-button [ngClass]="color2"
                (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onCloseConfirm()">
                OK
            </button>
        </mat-dialog-actions>
    </div>
</div>