import { ArchiveTabType } from "app/main/apps/archive/archive.service";

export const PATHS = {
    signIn: 'sign-in',
    profile: 'pages/profile',
    documentation: '/documentation/getting-started/introduction',
    changeLog: '/documentation/changelog',

    office: (officeId: string) => `/${officeId}`,
    start: (officeId: string) => `${officeId}/start`,
    startButton: (officeId: string, buttonUri: string) => `/${officeId}/${buttonUri}`,
    export: (officeId: string) => `${officeId}/start/export`,
    import: (officeId: string) => `${officeId}/start/import`,
    transit: (officeId: string) => `${officeId}/start/transit`,
    excise: (officeId: string) => `${officeId}/start/excise`,
    contacts: (officeId: string) => `${officeId}/contacts`,
    archive: (officeId: string) => `${officeId}/archive`,
    tirRedirect: (officeId: string) => `${officeId}/ncts/tir/fromExport`,
    tirNew: (officeId: string) => `${officeId}/ncts/tir/new`,
    arrAtExit: (officeId: string, documentType: ArchiveTabType, id: string) => `${officeId}/ecs/arratexit/${documentType}/${id}`,
    dete: (officeId: string, documentType: ArchiveTabType, id: string) => `${officeId}/imp/dete/${documentType}/${id}`,
    printLocalDecl: (officeId: string, subdomain: string, id: string) => `${officeId}/readonly/declaration/${subdomain}/${id}`,
    customsManual: (officeId: string) => `${officeId}/customs-manual`,


    newDeclaration: (officeId: string, domain: string, subdomain: string) => `${officeId}/${domain}/${subdomain}/new`,
    draftDeclaration: (officeId: string, domain: string, subdomain: string) => `${officeId}/${domain}/${subdomain}/draft`,
    localDeclaration: (officeId: string, domain: string, subdomain: string) => `${officeId}/${domain}/${subdomain}`,



    accounts: (businessId: string) => `b/${businessId}/accounts`,
    transactions: (businessId: string) => `b/${businessId}/transactions`,
    invoices: (businessId: string) => `b/${businessId}/invoices`,
    invoiceSettings: (businessId: string) => `b/${businessId}/invoices/settings`,
    invoiceAdd: (businessId: string) => `b/${businessId}/invoices/add`,
    invoiceCopy: (businessId: string, invoiceId: string) => `b/${businessId}/invoices/add/${invoiceId}`,
    invoiceView: (businessId: string, invoiceId: string) => `b/${businessId}/invoices/${invoiceId}/view`,
    invoiceEdit: (businessId: string, invoiceId: string) => `b/${businessId}/invoices/${invoiceId}/edit`,
    customerStatements: (businessId: string) => `b/${businessId}/customer-statements`,
    // ... add other routes here
};