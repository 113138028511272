import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { PATHS } from 'app/navigation/paths';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private _authService: AuthService, private injector: Injector, private router: Router) { }


    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Check if the current request is for checking authentication status
        // Clone the request object
        const cookieService = this.injector.get(CookieService);
        const csrfToken = cookieService.get('csrfToken');
        const csrfTokenDocument = this._authService.getCookieValue('csrfToken');
        console.log("intercept csrfToken local", csrfToken)
        console.log("intercept csrfTokenDocument ", csrfTokenDocument)



        const headers = csrfToken ? req.headers.set('X-CSRF-Token', csrfToken) : req.headers;

        const newReq = req.clone({
            headers: headers,
            withCredentials: true
        });
        // Response
        return next.handle(newReq).pipe(
            catchError((error: HttpErrorResponse) => {
                // Catch "401 Unauthorized" responses
                if (error.status === 401) {
                    this._authService.updateUserAuthenticatedStatus(false)
                    this.router.navigate([PATHS.signIn]);
                }
                return throwError(error);
            })
        );
    }
}
