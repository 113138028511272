export class ChemistryDetailsType {
    SampleReference?: string;
    AnalysisReference?: string;


    constructor(value?) {
        this.SampleReference = value?.SampleReference || null;
        this.AnalysisReference = value?.AnalysisReference || null;

    }
}
