import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { ImpHeaheaModel } from './heahea.model';

export class ImpHeaheaForm {
    [key: string]: AbstractControl;
    //DOESNT EXIST ON CC515A
    DocNumHEA5 = new FormControl();

    //HEADER FORM
    RefNumHEA4 = new FormControl();
    TypOfDecHEA24 = new FormControl();
    CouOfDesCodHEA30 = new FormControl();
    AgrLocOfGooCodHEA38 = new FormControl();
    AgrLocOfGooHEA39 = new FormControl();
    AgrLocOfGooHEA39LNG = new FormControl();
    AutLocOfGooCodHEA41 = new FormControl();
    CouOfDisCodHEA55 = new FormControl();
    InlTraModHEA75 = new FormControl();
    TraModAtBorHEA76 = new FormControl();
    IdeOfMeaOfTraCroHEA85 = new FormControl(null, { updateOn: 'blur' });
    IdeOfMeaOfTraCroHEA85LNG = new FormControl();
    NatOfMeaOfTraCroHEA87 = new FormControl();
    ConIndHEA96 = new FormControl();
    AccDatHEA158 = new FormControl();
    TotNumOfIteHEA305 = new FormControl(null, { updateOn: 'blur' });
    TotNumOfPacHEA306 = new FormControl();
    TotGroMasHEA307 = new FormControl();
    //On CC515A only
    DecDatHEA383 = new FormControl();
    DecPlaHEA394 = new FormControl();
    DecPlaHEA394LNG = new FormControl();
    //On CC599A only
    ExiDatHEA1 = new FormControl();
    //On CC513A only
    AmdPlaHEA598 = new FormControl();
    AmdPlaHEA598LNG = new FormControl();
    AmdDatHEA599 = new FormControl();

    //On CC504A only Modify Acceptance
    AmeAccDatHEA602 = new FormControl();

    SpeCirIndHEA1 = new FormControl();
    TraChaMetOfPayHEA1 = new FormControl();
    ComRefNumHEA = new FormControl(null, { updateOn: 'blur' });
    TypOfDecBx12HEA651 = new FormControl();
    PlaLoaGOOITE334 = new FormControl(null, { updateOn: 'blur' });
    PlaUnlGOOITE334 = new FormControl();
    AddDecDatHEA1024 = new FormControl();
    FinBanInfHEA1027 = new FormControl();
    ManEntNumHEA1028 = new FormControl();
    ManEntYeaHEA1029 = new FormControl();
    IdeMeaTraArr4004 = new FormControl();
    IdeMeaTraArr4005LNG = new FormControl();
    PlaLoaHEA126 = new FormControl();
    NatOfMeaOfTraAtArrHEA54 = new FormControl();
    RefNumAttHEA55 = new FormControl();
    DocRefNumAttHEA56 = new FormControl();
    ConcPersHEA45 = new FormControl();
    TranRefNumbHEA46 = new FormControl();
    ArrLocatGoodHEA47 = new FormControl();
    ArrDatTimeHEA48 = new FormControl();
    ICSLicDocDatHEA65 = new FormControl();
    MetOfPayHEA590 = new FormControl();
    NexCusRefNumHEA450 = new FormControl();

    constructor(value: ImpHeaheaModel) {
        this.DocNumHEA5.setValue(value.DocNumHEA5);

        this.RefNumHEA4.setValue(value.RefNumHEA4);
        this.TypOfDecHEA24.setValue(value.TypOfDecHEA24);
        this.CouOfDesCodHEA30.setValue(value.CouOfDesCodHEA30);
        this.AgrLocOfGooCodHEA38.setValue(value.AgrLocOfGooCodHEA38);
        this.AgrLocOfGooHEA39.setValue(value.AgrLocOfGooHEA39);
        this.AgrLocOfGooHEA39LNG.setValue(value.AgrLocOfGooHEA39LNG);
        this.AutLocOfGooCodHEA41.setValue(value.AutLocOfGooCodHEA41);
        this.CouOfDisCodHEA55.setValue(value.CouOfDisCodHEA55);
        this.InlTraModHEA75.setValue(value.InlTraModHEA75);
        this.TraModAtBorHEA76.setValue(value.TraModAtBorHEA76);
        this.IdeOfMeaOfTraCroHEA85.setValue(value.IdeOfMeaOfTraCroHEA85);
        this.IdeOfMeaOfTraCroHEA85LNG.setValue(value.IdeOfMeaOfTraCroHEA85LNG);
        this.NatOfMeaOfTraCroHEA87.setValue(value.NatOfMeaOfTraCroHEA87);
        this.ConIndHEA96.setValue(value.ConIndHEA96);
        this.AccDatHEA158.setValue(value.AccDatHEA158);
        this.TotNumOfIteHEA305.setValue(value.TotNumOfIteHEA305);
        this.TotNumOfPacHEA306.setValue(value.TotNumOfPacHEA306);
        this.TotGroMasHEA307.setValue(value.TotGroMasHEA307);

        this.DecDatHEA383.setValue(value.DecDatHEA383);
        this.DecPlaHEA394.setValue(value.DecPlaHEA394);
        this.DecPlaHEA394LNG.setValue(value.DecPlaHEA394LNG);

        this.AmdPlaHEA598.setValue(value.AmdPlaHEA598);
        this.AmdPlaHEA598LNG.setValue(value.AmdPlaHEA598LNG);
        this.AmdDatHEA599.setValue(value.AmdDatHEA599);

        this.AmeAccDatHEA602.setValue(value.AmeAccDatHEA602);

        this.SpeCirIndHEA1.setValue(value.SpeCirIndHEA1);
        this.TraChaMetOfPayHEA1.setValue(value.TraChaMetOfPayHEA1);
        this.ComRefNumHEA.setValue(value.ComRefNumHEA);
        this.TypOfDecBx12HEA651.setValue(value.TypOfDecBx12HEA651);
        this.PlaLoaGOOITE334.setValue(value.PlaLoaGOOITE334);
        this.PlaUnlGOOITE334.setValue(value.PlaUnlGOOITE334);
        this.AddDecDatHEA1024.setValue(value.AddDecDatHEA1024);
        this.FinBanInfHEA1027.setValue(value.FinBanInfHEA1027);
        this.ManEntNumHEA1028.setValue(value.ManEntNumHEA1028);
        this.ManEntYeaHEA1029.setValue(value.ManEntYeaHEA1029);
        this.IdeMeaTraArr4004.setValue(value.IdeMeaTraArr4004);
        this.IdeMeaTraArr4005LNG.setValue(value.IdeMeaTraArr4005LNG);
        this.PlaLoaHEA126.setValue(value.PlaLoaHEA126);
        this.NatOfMeaOfTraAtArrHEA54.setValue(value.NatOfMeaOfTraAtArrHEA54);
        this.RefNumAttHEA55.setValue(value.RefNumAttHEA55);
        this.DocRefNumAttHEA56.setValue(value.DocRefNumAttHEA56);
        this.ConcPersHEA45.setValue(value.ConcPersHEA45);
        this.TranRefNumbHEA46.setValue(value.TranRefNumbHEA46);
        this.ArrLocatGoodHEA47.setValue(value.ArrLocatGoodHEA47);
        this.ArrDatTimeHEA48.setValue(value.ArrDatTimeHEA48);
        this.ICSLicDocDatHEA65.setValue(value.ICSLicDocDatHEA65);
        this.MetOfPayHEA590.setValue(value.MetOfPayHEA590);
        this.NexCusRefNumHEA450.setValue(value.NexCusRefNumHEA450);

        this.TypOfDecHEA24.setValidators(Validators.required);
        this.TypOfDecBx12HEA651.setValidators(Validators.required);
    }
}
