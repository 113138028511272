<div fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div>Ασφάλεια T.I.R.</div>
        <!--  <div>
                <h1><small>MRN </small>{{ mrn }}</h1>
            </div> -->
        <div>
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div mat-dialog-content class="men-doc-dialog-content">
        <div class="sidebar">
            <mat-card>
                <h2>
                    <u><span>Info </span></u>
                </h2>
            </mat-card>
            <mat-card class="sidebar-2">Πληροφορίες</mat-card>
        </div>
        <div class="content">
            <mat-card>
                <div style="flex-grow: 1">
                    <mat-tab-group mat-stretch-tabs [(selectedIndex)]="tabIndex" class="main-tab-group mt-2">
                        <mat-tab #tab0 label="Γενικά Στοιχεία">
                            <div class="tab-content p-24" fusePerfectScrollbar fxlayout="column">
                                <app-control-result [CONRESERS]="conresers"></app-control-result>

                                <!-- / PUT YOUR TAB CONTENT HERE -->
                            </div>
                        </mat-tab>
                        <mat-tab #tab1 label="Συναλασσόμενοι">
                            <div class="tab-content p-24" fusePerfectScrollbar fxlayout="column">
                                <app-control-result [CONRESERS]="conresers"></app-control-result>

                                <mat-divider></mat-divider>




                                <!-- / PUT YOUR TAB CONTENT HERE -->
                            </div>
                        </mat-tab>
                        <mat-tab #tab2 label="Δρομολόγιο">
                            <div class="tab-content p-24" fusePerfectScrollbar fxlayout="column">
                                <app-iti-array fxLayout="row" fxLayoutAlign="center start" class='w-full'
                                    [DECLFORM]="declarationForm">
                                </app-iti-array>


                                <!-- / PUT YOUR TAB CONTENT HERE -->
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-card>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-raised-button class="mat-accent" (click)="onCloseConfirm()">
            Κλείσιμο
        </button>
    </div>
</div>