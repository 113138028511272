export class NctsProDocModel {
    DocTypDC21?: string; //4
    DocRefDC23?: string; //35
    DocRefDCLNG?: string;
    ComInfPRODOCCERGI4?: string; //26
    ComInfLNGPRODOCCERGI5?: string;

    constructor(value?) {
        this.DocTypDC21 = value?.DocTypDC21 || null;
        this.DocRefDC23 = value?.DocRefDC23 || null;
        this.DocRefDCLNG = value?.DocRefDCLNG || null;
        this.ComInfPRODOCCERGI4 = value?.ComInfPRODOCCERGI4 || null;
        this.ComInfLNGPRODOCCERGI5 = value?.ComInfLNGPRODOCCERGI5 || null;
    }
}
