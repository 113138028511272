import { AbstractControl, FormControl } from "@angular/forms";
import { ChemistryDetailsType } from "./chemistry-details.model";

export class ChemistryDetailsForm {
    [key: string]: AbstractControl;

    SampleReference: FormControl;
    AnalysisReference: FormControl;

    /*

   */

    constructor(value: ChemistryDetailsType) {

        this.SampleReference = new FormControl(value.SampleReference);
        this.AnalysisReference = new FormControl(value.AnalysisReference);

    }
}
