import { Component, Input, OnInit } from '@angular/core';
import { TsipouraParameters } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura-defk-create.service';

@Component({
    selector: 'tsipoura-limitations',
    templateUrl: './tsipoura-limitations.component.html',
    styleUrls: ['./tsipoura-limitations.component.scss']
})
export class TsipouraLimitationsComponent {

    @Input() params: TsipouraParameters
    constructor() { }


}
