import { ContactDetailsModel } from "../contactDetails/contactDetails.model";
import { DeliveryPlaceModel } from "../deliveryPlace/deliveryPlace.model";

export class VehicleArrivalConsigneeModel {

    PersonIdentificationType: string;
    TaxIdNumber?: string;
    OfficeOfResponsibility?: string;
    VehicleTaxWarehouseReference?: string;
    ContactDetails?: ContactDetailsModel;
    DeliveryPlace?: DeliveryPlaceModel;
    ConsigneeObject?: {}


    constructor(value?) {

        this.PersonIdentificationType = value?.PersonIdentificationType || null;
        this.TaxIdNumber = value?.TaxIdNumber
        this.OfficeOfResponsibility = value?.OfficeOfResponsibility
        this.VehicleTaxWarehouseReference = value?.VehicleTaxWarehouseReference
        this.ContactDetails = value?.ContactDetails
        this.DeliveryPlace = value?.DeliveryPlace
        this.ConsigneeObject = value?.ConsigneeObject
    }

}
