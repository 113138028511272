import { genericItem } from './../../../model/api-model';
import { ApiService, ApiEndPoints } from './../../../services/api.service';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-declaration-more',
    templateUrl: './declaration-more.component.html',
    styleUrls: ['./declaration-more.component.scss']
})
export class DeclarationMoreComponent implements OnInit {
    @ViewChild('tab0') _tab0;

    private _destroy: Subject<void> = new Subject<void>();

    color1: string;
    color2: string;


    tabIndex: number;
    formDisabled: boolean;
    declarationForm: FormGroup;
    conresers: FormGroup;
    heahea: FormGroup;

    tirChecked: boolean;

    subdomain: string

    control_results: genericItem[] = [];

    dateType = 'yyyyMMdd';
    //len is used for minLength validation does not work well for len < 8
    // I have to find smth else
    len: number = this.dateType.length;
    defaultDate: Date = new Date();

    get DatLimERS69() {
        return this.conresers.get('DatLimERS69') as FormControl;
    }

    constructor(
        public dialogRef: MatDialogRef<DeclarationMoreComponent>,
        private mainBroadcaster: MainBroadcasterService,
        private dbQuery: ApiService,
        private cf: ExtCustomFunctions,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.tabIndex = 0;
        //  console.log(" this.callingDivId", this.callingDivId);

        this.declarationForm = data.declarationForm;
        this.subdomain = data.subdomain;

        console.log('DeclarationMoreComponent declarationForm', this.declarationForm.value);
        console.log('DeclarationMoreComponent subdomain', this.subdomain);

        this.dbQuery
            .get_options(ApiEndPoints.control_result)
            .pipe(takeUntil(this._destroy))
            .subscribe((data: any[]) => {
                this.control_results = data;
            });


    }

    ngOnInit(): void {


        combineLatest([this.mainBroadcaster.activeSubdomain$, this.mainBroadcaster.formDisabled$])
            .pipe(takeUntil(this._destroy))
            .subscribe(([subdomain, disabledForms]) => {
                this.formDisabled = disabledForms[subdomain];
            });

        this.conresers = this.declarationForm.get('CONRESERS') as FormGroup;
        this.heahea = this.declarationForm.get('HEAHEA') as FormGroup;

        this.tirChecked = this.heahea.get('TIRFolHEA1025').value;


        console.log('CONRESERS', this.conresers.value);

        this.declarationForm.valueChanges.pipe(takeUntil(this._destroy)).subscribe(value => {
            console.log('declarationForm Changed', value);
        });
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }


    OnChange($event) {
        //MatCheckboxChange {checked,MatCheckbox}
        this.tirChecked = $event.checked;

        if ($event.checked === true) {
            this.heahea.get('TIRFolHEA1025').setValue(1);
        } else {
            this.heahea.get('TIRFolHEA1025').setValue(null);
        }
    }

    onCloseConfirm() {
        this.dialogRef.close();
    }
    cancel() {
        //   this.VehicleArrivalConsignee.get('VehicleTaxWarehouseReference').reset()
        //   this.VehicleArrivalConsignee.get('DeliveryPlace').reset()
        this.dialogRef.close();

    }
    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event)

    }
    changeStyle2(event) {
        if (this.declarationForm.valid) {
            this.color2 = this.cf.changeStyle2(this.declarationForm, event);
        }
    }

}
