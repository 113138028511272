import { AbstractControl, FormControl } from "@angular/forms";
import { NationalProductsModel } from "./national-products.model";

export class NationalProductsForm {
    [key: string]: AbstractControl;

    NationalProductId: FormControl;
    Quantity: FormControl;

    constructor(value: NationalProductsModel) {

        this.NationalProductId = new FormControl(value.NationalProductId);
        this.Quantity = new FormControl(value.Quantity);

    }
}
