import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { DeclarationStateService } from 'app/services/declaration-state.service';
import { FormArrayService } from 'app/services/submitServices/formArray.service';
import { DeclarationFormService } from 'app/services/declaration-form.service';
import { EMCSVehicleInventoryForm } from '../_models/vehicle-inventory/vehinv-form.model';
import { EMCSVehicleInventoryModel } from '../_models/vehicle-inventory/vehinv.model';
import { VehicleDetailsForm } from '../_models/vehicleDetails/vehicleDetails-form.model';
import { VehicleDetailsModel } from '../_models/vehicleDetails/vehicleDetails.model';
import { LrnService } from 'app/services/lrn.service';
import { RouteStateService } from 'app/services/routeState.service';
import { StorageService } from 'app/core/services/storage/storage.service';
import { PrivateControlsService } from 'app/model/private-controls.service';

@Injectable()
export class DaoDeclarationFormService extends DeclarationFormService {
    //subjects used to terminate following of valuechanges on main document
    //when value on bis is changed by user
    constructor(
        protected _fuseSplashScreenService: FuseSplashScreenService,
        protected mainBroadcaster: MainBroadcasterService,
        protected cf: ExtCustomFunctions,
        protected lrnService: LrnService,

        protected declarationStateService: DeclarationStateService,
        protected formArrayService: FormArrayService,
        protected toastrService: ToastrService,
        protected routeStateService: RouteStateService,
        protected storageService: StorageService,
        protected privateControlsService: PrivateControlsService,

    ) {
        super(
            mainBroadcaster,
            cf,
            lrnService,
            declarationStateService,
            formArrayService,
            _fuseSplashScreenService,
            toastrService,
            routeStateService,
            storageService,
            privateControlsService,
            'DAO'
        );
    }


    //=======================================================================================================


    openAsTemplate(data) {

        this.openAsTemplateGeneral(data);

        const currentDeclaration = this.declarationForm.getValue();
        const currentVehicleArrival = currentDeclaration.at(0).get('Body').get('VehicleArrivalDeclarationRegistration').get('VehicleArrival') as FormGroup;

        const currentVehicleDetails = currentDeclaration.at(0).get('Body').get('VehicleArrivalDeclarationRegistration').get('VehicleDetails') as FormArray;

        const length = currentVehicleDetails.length;

        for (let i = 0; i < length; i++) {
            const VehicleInventory = currentVehicleDetails.at(i).get('VehicleInventory') as FormArray;


            this.cf.resetFieldValue(VehicleInventory, 'VehicleInventoryType');

            this.cf.resetFieldValue(currentVehicleDetails, 'FrameNumber');
            this.cf.resetFieldValue(currentVehicleDetails, 'FirstAllowanceDate');
            this.cf.resetFieldValue(currentVehicleDetails, 'VehicleFactoryType');
            this.cf.resetFieldValue(currentVehicleDetails, 'VehicleModelName');
            this.cf.resetFieldValue(currentVehicleDetails, 'VehicleRegistrationPlate');
            this.cf.resetFieldValue(currentVehicleDetails, 'VehicleLicenceReference');
        }

        currentVehicleArrival.get('DeclarationReferenceNumber').reset();
    }

    //monitor Exporter,Consignne, Countries of Dispatch,Destination,Origin and Procedure and update Bis

    addVehicleInventoryArray(VehicleDetailsIndex: number, data?: any) {
        const form = this.declarationForm.getValue();
        const currentVehicleInventory = (form.at(0).get('Body').get('VehicleArrivalDeclarationRegistration').get('VehicleDetails') as FormArray).at(VehicleDetailsIndex).get('VehicleInventory') as FormArray;


        console.log("VehicleInventoryArray currentVehicleInventory", currentVehicleInventory.value)
        console.log("VehicleInventoryArray VehicleDetailsIndex", VehicleDetailsIndex)

        const formGroup = new FormGroup(new EMCSVehicleInventoryForm(new EMCSVehicleInventoryModel(data)));
        currentVehicleInventory.push(formGroup);
    }
    addVehicleDetailsArray(data?: any) {
        const form = this.declarationForm.getValue();
        const currentVehicleDetails = form.at(0).get('Body').get('VehicleArrivalDeclarationRegistration').get('VehicleDetails') as FormArray;

        const formGroup = new FormGroup(new VehicleDetailsForm(new VehicleDetailsModel(data)));
        currentVehicleDetails.push(formGroup);
    }

}
