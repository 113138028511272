import { FormControl, Validators } from '@angular/forms';
import { CusofftrarnsModel } from './cusofftrarns.model';

export class CusofftrarnsForm {
    [key: string]: FormControl;
    _countryCode = new FormControl();
    RefNumRNS1 = new FormControl();
    ArrTimTRACUS085 = new FormControl();

    constructor(value: CusofftrarnsModel) {
        this._countryCode.setValue(value._countryCode);
        this.RefNumRNS1.setValue(value.RefNumRNS1);
        this.ArrTimTRACUS085.setValue(value.ArrTimTRACUS085);

        // this.RefNumEPT1.setValidators([Validators.required]);
    }
}
