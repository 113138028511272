<div mat-dialog-title class="{{ confirmClass }}" fxLayout="row" fxLayoutAlign="space-between center">
    <div>{{ confirmTitle }}</div>
    <div>
        <button mat-icon-button (click)="dialogRef.close(false)">
            <mat-icon> highlight_off</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>
    <div class="p-6" style="font-size:15px;display: block;text-align: center;" [innerHtml]="confirmMessage"></div>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-raised-button class="{{ confirmClass }} mr-16" (click)="dialogRef.close(true)">
        {{ confirmButton }}
    </button>
    <button *ngIf="cancelButton" class={{cancelClass}} mat-button (click)="dialogRef.close(false)">
        {{ cancelButton }}
    </button>
</div>