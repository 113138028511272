import { FocusMonitor } from '@angular/cdk/a11y';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    OnDestroy,
    Optional,
    Self,
    ViewChild,
    Output,
    EventEmitter,
    HostListener,
    ChangeDetectionStrategy,

} from '@angular/core';
import { ControlValueAccessor, NgControl, FormControl, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { FormFieldFunctions } from '../form-field-functions.service';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { ErrorStateMatcher, MatOptionSelectionChange } from '@angular/material/core';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { countriesItem } from 'app/model/api-model';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';

@Component({
    selector: 'country-select',
    templateUrl: './country-select.component.html',
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: CountrySelectComponent
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectComponent
    implements
    OnInit,
    AfterViewInit,
    ControlValueAccessor,
    MatFormFieldControl<Object>,
    OnDestroy {
    @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

    @ViewChild(MatInput, { read: ElementRef, static: true })
    inputRef: ElementRef<HTMLInputElement>;

    defaultConfig: AutoCompConfigValues = {
        output: 'country_code',
        menuOptions: {
            options: ['country_code', 'country_name']
        }
    };
    static nextId = 0;

    private _ID = `country-select-${++CountrySelectComponent.nextId}`;
    private _focused = false;
    private _autofocus = false;
    private _placeholder = '';
    private _killPanel = false;
    private _uppercase = false;
    private _readonly = false;
    private _display: "country_code" | "country_name" | "country_name_en" = "country_code";
    private _destroy: Subject<void> = new Subject<void>();

    stateChanges: Subject<void> = new Subject<void>();

    replacedItems: any[] = [];
    filteredOptions: any[] = [];
    selectedOption: Object = {};
    subscription: Subscription;

    output: string;
    view: string;
    showValue: string[];
    separator: string;
    menuTitle1: string;
    menuTitle2: string;
    menuOption1: string;
    menuOption2: string;
    menuSeparator: string;
    optionRegular: boolean;

    controlValue1: any;
    controlValue2: any;

    inputCtrl: FormControl;

    form: FormGroup;

    onChange: any = () => { };
    onTouched: any = () => { };

    @Input()
    set value(value: string | countriesItem) {
        this.form.get('inputCtrl').setValue(value)
        this.stateChanges.next();
    }
    get value() {
        return this.form.get('inputCtrl').value

    }

    @Input() class: string;

    @Input() options: any[] = [];

    @Input() config: AutoCompConfigValues;

    @Input() required: boolean;

    @Input() disabled: boolean;

    @Input() customsCountries: boolean;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    @Input()
    get autofocus(): boolean {
        return this._autofocus;
    }
    set autofocus(value: boolean) {
        this._autofocus = value;
        this.stateChanges.next();
    }
    @Input()
    get readonly(): boolean {
        return this._readonly;
    }
    set readonly(value: boolean) {
        this._readonly = value;
        this.stateChanges.next();
    }

    @Input() maxLength: number;

    @Input() tabindex: number;

    @Input() trapFocus: boolean;


    @Input()
    get killPanel(): boolean {
        // console.log("autocomplete getting killPanel value",this._killPanel)
        return this._killPanel;
    }
    set killPanel(value: boolean) {
        //console.log("autocomplete setting killPanel value",value)
        this._killPanel = value;
        if (value) {
            //  console.log("autocomplete closing panel",value)
            this.trigger.closePanel();
            this._killPanel = false;
        }
    }

    @Output() blur: EventEmitter<any> = new EventEmitter();

    @Output() focus: EventEmitter<any> = new EventEmitter(); //

    //if an external id is not set use the default extrenalId
    @HostBinding('id')
    externalId = `country-select-${++CountrySelectComponent.nextId}`;

    //we set both the custom autocomplete's id (externalId) and the input's id (id)
    //if an id is not set default id is used for externalId above and for id (private _ID above)
    @Input()
    set id(value: string) {
        this._ID = value + '-input';
        this.externalId = value;
    }
    get id() {
        return this._ID;
    }

    @Input()
    get uppercase(): boolean {
        return this._uppercase;
    }
    set uppercase(value: boolean) {
        this._uppercase = value;
    }

    @Input()
    get display(): "country_name" | "country_name_en" | "country_code" {
        return this._display;
    }
    set display(value: "country_name" | "country_name_en" | "country_code") {
        this._display = value;
    }



    @HostBinding('attr.aria-describedby') describedBy = '';

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    controlType = 'custom-autocomplete';

    get empty(): boolean {
        return !this.value;
    }

    get errorState(): boolean {
        return this.errorMatcher.isErrorState(
            this.ngControl?.control as FormControl,
            null
        );
    }
    get focused(): boolean {
        return this._focused;
    }
    set focused(value: boolean) {
        this._focused = value;
        this.stateChanges.next();
    }
    //..================================
    @HostListener('keydown', ['$event'])
    onKeyDown(e) {
        if (!this.trigger.autocompleteDisabled === false) {
            this.trigger.autocompleteDisabled = false;
        }

        if (e.key === 'ContextMenu' || e.key === 'Meta' || e.key === 'WakeUp') {
            this.trigger._onChange('');

            this.trigger.panelOpen
                ? this.trigger.closePanel()
                : this.trigger.openPanel();
            e.stopImmediatePropagation();
            e.preventDefault();
        }
    }

    constructor(
        private focusMonitor: FocusMonitor,
        private formFieldFunctions: FormFieldFunctions,
        private errorMatcher: ErrorStateMatcher,
        private mainBroadcaster: MainBroadcasterService,

        @Optional() @Self() public ngControl: NgControl
    ) {
        //  this.inputCtrl = new FormControl(null);
        this.form = new FormGroup({ inputCtrl: new FormControl(null) })

        if (this.ngControl) {
            // Set the value accessor directly (instead of providing
            // NG_VALUE_ACCESSOR) to avoid running into a circular import
            this.ngControl.valueAccessor = this;
        }

        //We are subscribing here first, because writeValue is triggered before OnInit and the options are undefined
        //and the again on the OnInit to get the options based on the customsCountries value
        this.mainBroadcaster.countries$
            .pipe(take(1))
            .subscribe((data: countriesItem[]) => {
                this.options = data;
            });

    }

    ngOnInit() {

        this.mainBroadcaster.countries$
            .pipe(take(1))
            .subscribe((data: countriesItem[]) => {
                if (this.customsCountries) {

                    this.options = data.filter(c => c.customsCountry === 1);
                }
                else {
                    this.options = data;
                }
                this.setConfigOptions()
                this.valuchangesSub()
            });



        this.focusMonitor
            .monitor(this.inputRef)
            .pipe(takeUntil(this._destroy))
            .subscribe((focused) => {
                this.focused = !!focused;
                this.stateChanges.next();
            });
        this.focusMonitor
            .monitor(this.inputRef)
            .pipe(take(1))
            .subscribe(() => {
                this.onTouched();
            });




    }

    ngAfterViewInit(): void {
        //When the options panel closes... force list option
        this.panelClosingSubscription();

        this.trigger.autocompleteDisabled = true;
    }

    ngOnDestroy(): void {
        this._destroy.next(); // trigger the unsubscribe
        this._destroy.complete(); // finalize & clean up the subject stream
        this.stateChanges.next();
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.inputRef);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    //================================================================
    //================================================================
    // Value Accessor Methods
    writeValue(value: any): void {
        this.value = value ? value : '';
        this.selectedOption = this.options.filter(e => e.country_code === value)[0]
    }

    registerOnChange(fn: (_: string | null) => void): void {
        this.onChange = (value: string | null) => {
            fn(value === '' ? null : value);
        };

    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        //this.inputCtrl.disable();
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
        this.stateChanges.next();
    }

    onContainerClick(): void {
        this.focusMonitor.focusVia(this.inputRef, 'program');
    }
    //=============================================================================
    //=============================================================================
    //function to force List only option

    setConfigOptions() {


        if (!this.config) {
            this.config = this.defaultConfig;
        }

        this.output = this.config.output;

        if (this.config.display) {
            this.showValue = this.config.display.values;

            if (this.config.display.separator) {
                this.separator = this.config.display.separator;
            } else {
                this.separator = ' ';
            }
        }
        if (this.config.menuTitles) {
            this.menuTitle1 = this.config.menuTitles[0];
            this.menuTitle2 = this.config.menuTitles[1];
        } else {
            this.menuTitle1 = null;
            this.menuTitle2 = null;
        }

        this.menuOption1 = this.config.menuOptions.options[0];

        this.menuOption2 = this.config.menuOptions.options[1];

        if (this.config.menuOptions.separator) {
            this.menuSeparator = this.config.menuOptions.separator;
        } else {
            this.menuSeparator = ' | ';
        }

        if (this.config.menuOptions.optionRegular) {
            this.optionRegular = this.config.menuOptions.optionRegular;
        } else {
            this.optionRegular = false;
        }
        //========================================================
        let key0: string;
        let key1: string;

        //if filters are set
        if (this.config.filterBy) {
            key0 = this.config.filterBy[0];
            key1 = this.config.filterBy[1];
        }
        //else use menuOptions values
        else {
            key0 = this.menuOption1;
            key1 = this.menuOption2;
        }
        //check value on input against the values of the option array
        //second array is used only when two values are displayed like on declarant field
        this.controlValue1 = this.options.map((option) => option[key0]);
        this.controlValue2 = this.options.map((option) => option[key1]);

    }

    valuchangesSub() {

        this.form.get('inputCtrl').valueChanges
            .pipe(
                startWith(''),
                map((option) =>
                    option ? this.filterOptions(option) : this.options.slice()
                ),
                takeUntil(this._destroy)
            )
            .subscribe((value: any) => {
                this.filteredOptions = value;
            });

    }


    private panelClosingSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }


        this.subscription = this.trigger.panelClosingActions.pipe(takeUntil(this._destroy)).subscribe({
            next: (closingActions: MatOptionSelectionChange) => {
                if (!closingActions) {
                    //onBlur (closingActions==null) user must type a value for the option to be selected    
                    if (this.value && this.value !== '' && this.filterOptions(this.value).length > 0) {
                        this.value = this.trigger.activeOption.value;
                        this.onChange(this.trigger.activeOption.value)
                        this.selectedOption = this.options.filter(e => e.country_code === this.trigger.activeOption.value)[0]
                    }
                    else {
                        this.value = null;
                        this.onChange(null);
                        this.selectedOption = null
                    }
                    console.log("ACMP 1. onBlur value", this.value);
                    this.panelClosingSubscription();

                }
                else {
                    //on user select (onEnter/onClick)
                    if (this.trigger.activeOption && closingActions.isUserInput) {
                        console.log("ACMP 2 on Panel Interaction isUserInput", closingActions.isUserInput);
                        this.value = closingActions.source.value;
                        this.onChange(closingActions.source.value)
                        this.selectedOption = this.options.filter(e => e.country_code === closingActions.source.value)[0]
                        console.log("ACMP 2. on Panel Interaction value", this.value);
                    }
                }
            },
            error: (e: unknown) => console.log('error')
        });
    }

    filterOptions(name: any) {
        let key0: string;
        let key1: string;

        //  console.log("filter name:", name)
        //if filters are set
        if (this.config.filterBy) {

            key0 = this.config.filterBy[0];
            key1 = this.config.filterBy[1];
        }
        //else use menuOptions values
        else {
            key0 = this.menuOption1;
            key1 = this.menuOption2;
        }

        const filtered = this.formFieldFunctions.autocompleteFilter(
            name,
            this.options,
            key0,
            key1
        );
        return filtered;
    }

    onEscape(event) {
        //  this.inputCtrl.reset();
        this.form.reset();
        this.ngControl.reset();
        event.stopImmediatePropagation();
        event.preventDefault();
    }


    //open panel with delay
    onFocus(event) {
        this.focus.emit(event);
    }

    onBlur(event) {
        this.blur.emit(event);
        this.trigger.autocompleteDisabled = true;
    }

    displayFn(option?: any): string | undefined {
        //for string output
        if (this.output) {
            if (this.display) {
                //console.log("ACMP countrySelect view", this.display)
                //console.log("ACMP countrySelect display", this.selectedOption?.[this.display])
                return option ? this.selectedOption?.[this.display] : undefined;
            }
            return option ? option : undefined;
        }
    }

}
