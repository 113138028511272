import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ApiService } from 'app/services/api.service';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { ApiNodeService } from 'app/services/api.node.service';
import { Declarant } from 'app/core/user/user.model';

export interface LoginResult {
    isLoggedIn: boolean;
    user?: { name: string; afm: string };
    error?: string;
}
@Injectable()
export class AttachmentsService {
    message: BehaviorSubject<string>;
    progressValue: BehaviorSubject<number>;
    processing: BehaviorSubject<boolean>;
    color2: BehaviorSubject<string>;
    attachmentForm: BehaviorSubject<FormGroup>;
    documentType: string;

    myWebSocket: WebSocketSubject<any> = webSocket({
        url: environment.WS_URL,
        deserializer: msg => msg
    });

    private _unsubscribe: Subject<void>;
    private _destroy: Subject<void> = new Subject<void>();

    constructor(private toastrService: ToastrService, private fb: FormBuilder, private dbQuery: ApiService, private apiLocalService: ApiNodeService, private mainBroadcaster: MainBroadcasterService) {
        ///==========================    TESTING ============================
        this.documentType = environment.testing ? 'XLS' : 'OTH';
        //===================================================================

        console.log('ATTACH SERVICE created');
        this.message = new BehaviorSubject('');
        this.progressValue = new BehaviorSubject(0);
        this.processing = new BehaviorSubject(false);
        this.color2 = new BehaviorSubject('primary');
        console.log('ATTACH DOcument Type', this.documentType);
        this.attachmentForm = new BehaviorSubject(null);

        this.myWebSocket.pipe(takeUntil(this._destroy)).subscribe(
            (msg: MessageEvent) => {
                // this.message = msg.data[0].message;
                const data = JSON.parse(msg.data);

                //because we are receiving the serverStatus every 5 secs
                //we set value to 0, so it doesnt return undefined
                let addValue = 0;

                if (data.value) {
                    addValue = data.value;
                }

                if (data.message) {
                    this.message.next(data.message);
                }

                this.progressValue.next(this.progressValue.getValue() + addValue);

                console.log(this);
            },
            // Called whenever there is a message from the server
            (err: unknown) => {
                this.toastrService.error(err.toString(), 'Υπήρξε πρόβλημα στην τοπική διασύνδεση', {
                    disableTimeOut: true
                });
                console.log(err);
            },
            // Called if WebSocket API signals some kind of error
            () => console.log('WEBSOCKET complete')
            // Called when connection is closed (for whatever reason)
        );
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    //ICIS  ATTACHMENTS======================
    createForm() {
        const form = this.fb.group({
            icisUser: '',
            icisPass: '',
            domain: '',
            role: '',
            type: this.documentType,
            key_type: '',
            key: '',
            remarks: '',
            fromDate: '',
            fromCurrentDate: '',
            toDate: '',
            toCurrentDate: '',
            fileToUpload: null
        });

        this.attachmentForm.next(form);
    }

    onSend(declarant: Declarant, files: File[]): Observable<any> {
        this.processing.next(true);
        this.mainBroadcaster.updateLoading(true)
        this.color2.next('');
        this._unsubscribe = new Subject<void>();

        const key_type = this.attachmentForm.getValue().get('key_type').value;
        const key = this.attachmentForm.getValue().get('key').value;
        const domain = this.attachmentForm.getValue().get('domain').value;
        const remarks = this.attachmentForm.getValue().get('remarks').value;

        const formData = new FormData();

        formData.set('domain', domain);
        formData.append('type', this.documentType); //OTH other type (include all)
        formData.append('key_type', key_type);
        formData.append('key', key);
        formData.append('role', 'null');
        formData.append('remarks', remarks);

        files.forEach(file => {
            formData.append('fileToUpload', file);
        });

        return this.apiLocalService.sendAttachment(declarant, formData)
            .pipe(takeUntil(this._unsubscribe))

    }

    onCheck(declarant: Declarant) {
        this._unsubscribe = new Subject<void>();

        console.log('Check');
        this.processing.next(true);
        this.mainBroadcaster.updateLoading(true)

        this.color2.next('');

        this.attachmentForm.getValue().get('type').setValue('all');
        const key = this.attachmentForm.getValue().get('key');
        if (!key.value) {
            key.setValue('');
        }

        return this.apiLocalService.checkAttachment(declarant, this.attachmentForm.getValue().value).pipe(
            tap((result: any) => {
                if (result.error) {
                    this.toastrService.error(result.error, '', {
                        positionClass: 'toast-top-center'
                    });
                }

                this.processing.next(false);
                this.mainBroadcaster.updateLoading(false)

                this.color2.next('primary');

                console.log(this.processing.value);
            })
        );
    }



}
