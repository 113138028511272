export class TaxesDeclarationConsignorModel {
    ExciseTaxesDeclarationConsignorType?: string;
    TaxOrVehicleWarehouseReference?: string;


    constructor(value?) {
        this.ExciseTaxesDeclarationConsignorType = value?.ExciseTaxesDeclarationConsignorType || null;
        this.TaxOrVehicleWarehouseReference = value?.TaxOrVehicleWarehouseReference || null;

    }
}
