export class Traconsec029Model {
    NameTRACONSEC033?: string;
    StrNumTRACONSEC035?: string;
    PosCodTRACONSEC034?: string;
    CitTRACONSEC030?: string;
    CouCodTRACONSEC031?: string;
    TRACONSEC029LNG?: string;
    TINTRACONSEC036?: string;
    TINTypTRACONSEC037?: string;
    ExporterObject?: {};

    constructor(value?) {
        this.NameTRACONSEC033 = value?.NameTRACONSEC033 || null;
        this.StrNumTRACONSEC035 = value?.StrNumTRACONSEC035 || null;
        this.PosCodTRACONSEC034 = value?.PosCodTRACONSEC034 || null;
        this.CitTRACONSEC030 = value?.CitTRACONSEC030 || null;
        this.CouCodTRACONSEC031 = value?.CouCodTRACONSEC031 || null;
        this.TRACONSEC029LNG = value?.TRACONSEC029LNG || null;
        this.TINTRACONSEC036 = value?.TINTRACONSEC036 || null;
        this.TINTypTRACONSEC037 = value?.TINTypTRACONSEC037 || null;
        this.ExporterObject = value?.ExporterObject || {};
    }
}
