import { FormControl, Validators } from '@angular/forms';
import { TraConce2Model } from './traconce2.model';

export class TraConce2Form {
    [key: string]: FormControl;

    NamCE27 = new FormControl();
    StrAndNumCE222 = new FormControl();
    PosCodCE223 = new FormControl();
    CitCE224 = new FormControl();
    CouCE225 = new FormControl();
    NADLNGGICE = new FormControl();
    TINCE259 = new FormControl();
    ConsigneeObject = new FormControl();

    constructor(value: TraConce2Model) {
        this.NamCE27.setValue(value.NamCE27);
        this.StrAndNumCE222.setValue(value.StrAndNumCE222);
        this.PosCodCE223.setValue(value.PosCodCE223);
        this.CitCE224.setValue(value.CitCE224);
        this.CouCE225.setValue(value.CouCE225);
        this.NADLNGGICE.setValue(value.NADLNGGICE);
        this.TINCE259.setValue(value.TINCE259);
        this.ConsigneeObject.setValue(value.ConsigneeObject);
    }
}
