import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { beerFieldMappings } from 'app/main/apps/excise-helper-tables/beer/_models/beer-field-mappings';
import { BeerModel } from 'app/main/apps/excise-helper-tables/beer/_models/beer.model';
import { BeerDefkCreateService, BeerParameters } from 'app/main/apps/excise-helper-tables/beer/beer-defk-create.service';
import { BeerService } from 'app/main/apps/excise-helper-tables/beer/beer.service';
import { DEFKDeclarationForm } from 'app/main/apps/excise/defk/_models/EF15A/declaration-form.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { genericItem } from 'app/model/api-model';
import { AbstractControlErrorService, ErrorMessageConfig } from 'app/services/abstract-contol-error.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { environment } from 'environments/environment';
import { Subject, tap, switchMap, map, takeUntil, startWith, debounceTime, forkJoin } from 'rxjs';

@Component({
    selector: 'app-beer',
    templateUrl: './beer.component.html',
    styleUrls: ['./beer.component.scss'],
    providers: [BeerDefkCreateService]
})
export class BeerComponent implements OnInit {

    beerForm: FormGroup
    defkDeclarationForm: FormGroup<DEFKDeclarationForm>
    params: BeerParameters;
    products: genericItem[] = []
    errorMessages: string[] = [];
    showBanner = false;

    color1 = '';
    color2 = 'primary';


    private _destroy = new Subject<void>();
    constructor(
        public dialogRef: MatDialogRef<BeerComponent>,
        private mainBroadcaster: MainBroadcasterService,
        private beerService: BeerService,
        private cf: ExtCustomFunctions,
        private createDefkService: BeerDefkCreateService,
        private errorService: AbstractControlErrorService
    ) { }

    ngOnInit() {
        console.log('BeerComponent ngOnInit')

        forkJoin([
            this.beerService.getBeerParams(),
            this.beerService.getBeerProducts()
        ])
            .pipe(
                tap(([params, products]) => { this.params = params[0], this.products = products }),
                switchMap(() => this.mainBroadcaster.declarationForms$.pipe(map(forms => forms['DEFK']))),
                switchMap(defkDeclaration => this.beerService.beerForm$.pipe(map(form => ({ form, defkDeclaration })))),
                map(
                    (value: { form: FormGroup, defkDeclaration: FormGroup }, index) => {
                        this.beerForm = value.form
                        this.defkDeclarationForm = value?.defkDeclaration
                        console.log("BeerComponent map index", index)

                        const savedForm = value?.defkDeclaration?.get('_PRIVATE')?.get('beer')?.value

                        if (index === 0) {
                            this.beerService.subToMasterProduceValueValueChanges().pipe(takeUntil(this._destroy)).subscribe()
                            this.beerService.subToSmallProducerValueChanges().pipe(takeUntil(this._destroy)).subscribe()
                            this.beerService.subToReducedVatValueChanges().pipe(takeUntil(this._destroy)).subscribe()

                            if (savedForm) {
                                this.beerService.loadLocalStorage(savedForm)
                            }
                        }

                        return value.form;

                    }
                ),
                switchMap(_ => this.beerForm.valueChanges.pipe(startWith(this.beerForm.value), debounceTime(200), takeUntil(this._destroy))),
                tap((value: BeerModel) => {


                    console.log('BeerComponent valueChanges', value)
                })


            )
            .subscribe()


        this.dialogRef.afterClosed().subscribe(_ => {
            if (this.defkDeclarationForm) {

                this.beerService.patchPrivateCtrls(this.defkDeclarationForm, this.beerForm.value)
            }
        })



    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    onPrint() {
        const content = document.getElementById('printable').outerHTML;
        const cssPath = environment.DOMAIN_NAME + '/assets/css/twoDayDistillers-print.css'; // Your logged css path
        const newWindow = window.open('', '_blank');

        if (newWindow && newWindow.document) {
            // Create new link element
            const linkEl = newWindow.document.createElement('link');
            linkEl.setAttribute('rel', 'stylesheet');
            linkEl.setAttribute('type', 'text/css');

            // Set href attribute to the cssPath
            linkEl.setAttribute('href', cssPath);

            // Append the link element to the head of the document
            newWindow.document.head.appendChild(linkEl);

            // Add content to the body of the new window
            newWindow.document.body.innerHTML = content;

            // Listen for the load event on the link element
            linkEl.onload = function () {
                newWindow.print();
                newWindow.close();
            };
        }
    }

    onSubmit() {

        if (this.beerForm.status === 'INVALID') {

            const customErrorConfig: ErrorMessageConfig[] = [{
                path: 'beerCalculationData'
            }];

            const allErrors = this.errorService.getAllErrors(this.beerForm);
            this.errorService.convertErrorsToFriendlyMessages(allErrors, [], beerFieldMappings, customErrorConfig);
            console.log("Beer Errors", allErrors);
            console.log("Beer Errors Messages", this.errorMessages);
            this.showBanner = true

            return;
        }
        this.createDefkService.createDefk(this.beerForm.value, this.params).then(_ => {

            this.dialogRef.close()
        })
    }



    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event);
    }
    changeStyle2(event) {
        if (this.beerForm.valid) {
            this.color2 = this.cf.changeStyle2(this.beerForm, event);
        }
    }
    onCloseConfirm() {
        this.dialogRef.close()
    }
}
