import { FormControl } from '@angular/forms';
import { AddCon789Model } from './addcon789.model';

export class AddCon789Form {
    [key: string]: FormControl;

    NamAdTraAdCo1 = new FormControl();
    StrNumAdTraAdCo2 = new FormControl();
    PosCoAdTraAdCo3 = new FormControl();
    CitAdTraAdCo4 = new FormControl();
    NADLNGAdTraAdCo6 = new FormControl();
    VATAdTraAdCo7 = new FormControl();
    IdCarAdTraAdCo8 = new FormControl();
    OwnAdTraAdCo9 = new FormControl();
    OwnPerAdTraAdCo10 = new FormControl();
    PhoNumASCOASTR = new FormControl();
    AddConsigneeObject = new FormControl();

    constructor(value: AddCon789Model) {
        this.NamAdTraAdCo1.setValue(value.NamAdTraAdCo1);
        this.StrNumAdTraAdCo2.setValue(value.StrNumAdTraAdCo2);
        this.PosCoAdTraAdCo3.setValue(value.PosCoAdTraAdCo3);
        this.CitAdTraAdCo4.setValue(value.CitAdTraAdCo4);
        this.NADLNGAdTraAdCo6.setValue(value.NADLNGAdTraAdCo6);
        this.VATAdTraAdCo7.setValue(value.VATAdTraAdCo7);
        this.IdCarAdTraAdCo8.setValue(value.IdCarAdTraAdCo8);
        this.OwnAdTraAdCo9.setValue(value.OwnAdTraAdCo9);
        this.OwnPerAdTraAdCo10.setValue(value.OwnPerAdTraAdCo10);
        this.PhoNumASCOASTR.setValue(value.PhoNumASCOASTR);
        this.AddConsigneeObject.setValue(value.AddConsigneeObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
