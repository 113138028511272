import { Component, Input, OnInit } from '@angular/core';
import { TariffDutiesResults } from 'app/main/apps/tariff-calculate/_models';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-tariff-results-container',
    templateUrl: './tariff-results-container.component.html',
    styleUrls: ['./tariff-results-container.component.scss']
})
export class TariffResultsContainerComponent {
    @Input() duties: TariffDutiesResults;
    @Input() uvDuties: TariffDutiesResults;
    @Input() uvSecurity: TariffDutiesResults;
    @Input() info: string[];
    @Input() errors: string[];


}
