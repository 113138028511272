import { SubmittingOperatorModel } from "../../submittingOperator/submittingOperator.model";

export class VA18ABodyModel {

    VehicleArrivalDeclarationReception: VehicleArrivalDeclarationReceptionType;

    constructor(value?) {

        console.log("VA18ABodyModel value", value)

        this.VehicleArrivalDeclarationReception = value?.VehicleArrivalDeclarationReception || new VehicleArrivalDeclarationReceptionType()
    }

}



export class VehicleArrivalDeclarationReceptionType {


    SubmittingOperator?: SubmittingOperatorModel;
    VehicleArrivalReceivedDate: VehicleArrivalReceivedDateModel;


    constructor(value?) {

        this.SubmittingOperator = value?.SubmittingOperator || null;
        this.VehicleArrivalReceivedDate = value?.VehicleArrivalReceivedDate || new VehicleArrivalReceivedDateModel();

    }


}

export class VehicleArrivalReceivedDateModel {


    DeclarationReferenceNumber: string;
    ReceivedDate: string;


    constructor(value?) {

        this.DeclarationReferenceNumber = value?.DeclarationReferenceNumber || null;
        this.ReceivedDate = value?.ReceivedDate || null;

    }


}