import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface ConfirmButton {
    title: string;
    class: string;
    action: string | boolean | null;
}

@Component({
    selector: 'app-choose-dialog',
    templateUrl: './choose-dialog.component.html',
    styleUrls: ['./choose-dialog.component.scss']
})
export class ChooseDialogComponent {
    public dialogTitle: string;
    public dialogClass = 'fuse-navy-700';
    public contentDescription: string;
    public contentButtons: ConfirmButton[];
    public confirmButtons: ConfirmButton[];
    public inputTitle: string;

    inputValue: string;

    /*   contentButtons:  ConfirmButton[] = [

    ]
    confirmButtons: ConfirmButton[] = [
        {
            title: "OK",
            class: "fuse-navy-700",
            action: "true",
        },
        {
            title: "Cancel",
            class: "fuse-navy-700",
            action: "false",
        },
    ]; */

    /* Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(public dialogRef: MatDialogRef<ChooseDialogComponent>) { }
}
