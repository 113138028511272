import { Contact } from 'app/main/apps/contacts/contact.model';
import { avoidSame, checkSame } from 'app/services/validators/custom-validators';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

export class InvoiceParsingResultsForm {

    [key: string]: AbstractControl;

    trader: FormControl;
    consignee: FormControl;
    transport: FormControl;
    deliveryTerms: FormControl;
    relevant: FormControl;
    destinationCountry: FormControl;
    invoice: FormControl;
    currency: FormControl;
    goods: FormArray;
    totals: FormGroup;
    calculatedTotals: FormGroup;


    constructor(value: InvoiceParsingResultsModel) {

        this.trader = new FormControl(value.trader)
        this.consignee = new FormControl(value.consignee,)
        this.transport = new FormControl(value.transport)
        this.deliveryTerms = new FormControl(value.deliveryTerms)
        this.relevant = new FormControl(value.relevant)
        this.destinationCountry = new FormControl(value.destinationCountry)
        this.invoice = new FormControl(value.invoice)
        this.currency = new FormControl(value.currency)
        this.goods = new FormArray([])
        this.totals = new FormGroup(new InvoiceParsingGoodsItemsForm(new InvoiceParsingGoodsItemsModel(value.totals)))
        this.calculatedTotals = new FormGroup(new InvoiceParsingGoodsItemsForm(new InvoiceParsingGoodsItemsModel(value.calculatedTotals)))


        if (value.goods) {

            value.goods.forEach((good: InvoiceParsingGoodsModel) => {
                this.goods.push(new FormGroup(new InvoiceParsingGoodsForm(new InvoiceParsingGoodsModel(good))))


            })
        }

        const totPal = this.totals.get('pal') as FormControl
        const calcTotPal = this.calculatedTotals.get('pal') as FormControl
        const totPacks = this.totals.get('packs') as FormControl
        const calcTotPacks = this.calculatedTotals.get('packs') as FormControl
        const totGross = this.totals.get('gross') as FormControl
        const calcTotGross = this.calculatedTotals.get('gross') as FormControl
        const totNet = this.totals.get('net') as FormControl
        const calcTotNet = this.calculatedTotals.get('net') as FormControl
        const totValue = this.totals.get('value') as FormControl
        const calcTotValue = this.calculatedTotals.get('value') as FormControl

        totPal.setValidators([avoidSame(calcTotPal), checkSame(calcTotPal)])
        calcTotPal.setValidators([avoidSame(totPal), checkSame(totPal)])

        totPacks.setValidators([avoidSame(calcTotPacks), checkSame(calcTotPacks)])
        calcTotPacks.setValidators([avoidSame(totPacks), checkSame(totPacks)])

        totGross.setValidators([avoidSame(calcTotGross), checkSame(calcTotGross)])
        calcTotGross.setValidators([avoidSame(totGross), checkSame(totGross)])

        totNet.setValidators([avoidSame(calcTotNet), checkSame(calcTotNet)])
        calcTotNet.setValidators([avoidSame(totNet), checkSame(totNet)])

        totValue.setValidators([avoidSame(calcTotValue), checkSame(calcTotValue)])
        calcTotValue.setValidators([avoidSame(totValue), checkSame(totValue)])

        //====================================================

        totPal.updateValueAndValidity()
        calcTotPal.updateValueAndValidity()
        totPacks.updateValueAndValidity()
        calcTotPacks.updateValueAndValidity()
        totGross.updateValueAndValidity()
        calcTotGross.updateValueAndValidity()
        totNet.updateValueAndValidity()
        calcTotNet.updateValueAndValidity()
        totValue.updateValueAndValidity()
        calcTotValue.updateValueAndValidity()

    }
}


export class InvoiceParsingResultsModel {


    trader?: Contact;
    consignee?: Contact;
    transport?: string;
    deliveryTerms?: string;
    relevant?: { documentType: string, documentNumber: string; };
    destinationCountry?: string;
    invoice?: string;
    currency?: string;
    goods?: InvoiceParsingGoodsModel[];
    totals?: InvoiceParsingGoodsItemsModel;
    calculatedTotals?: InvoiceParsingGoodsItemsModel;



    constructor(value?) {

        this.trader = value?.trader || null
        this.consignee = value?.consignee || null
        this.transport = value?.transport || null
        this.deliveryTerms = value?.deliveryTerms || null
        this.relevant = value?.relevant || null;
        this.destinationCountry = value?.destinationCountry || null;
        this.invoice = value?.invoice || null
        this.currency = value?.currency || 'EUR'
        this.goods = value?.goods || []
        this.totals = value?.totals || null
        this.calculatedTotals = value?.calculatedTotals || null

    }


}



export class InvoiceParsingGoodsForm {
    [key: string]: AbstractControl;


    product: FormControl;
    items: FormArray;
    subtotal: FormGroup;

    constructor(value: InvoiceParsingGoodsModel) {

        this.product = new FormControl(value.product)
        this.items = new FormArray([])
        this.subtotal = new FormGroup(new InvoiceParsingGoodsItemsForm(new InvoiceParsingGoodsItemsModel(value.subtotal)))


        if (value.items) {

            value.items.forEach((item: InvoiceParsingGoodsItemsModel) => {
                this.items.push(new FormGroup(new InvoiceParsingGoodsItemsForm(new InvoiceParsingGoodsItemsModel(item)), { updateOn: 'blur' }))


            })
        }


    }


}

export class InvoiceParsingGoodsModel {


    product: string;
    items: InvoiceParsingGoodsItemsModel[];

    subtotal: InvoiceParsingGoodsItemsModel;


    constructor(value?) {

        this.product = value?.product || null
        this.items = value?.items || []
        this.subtotal = value?.subtotal || null

    }


}


export class InvoiceParsingGoodsItemsForm {
    [key: string]: AbstractControl;

    pal: FormControl;
    packs: FormControl;
    gross: FormControl;
    net: FormControl;
    value: FormControl;

    constructor(value) {

        this.pal = new FormControl(value.pal, [Validators.pattern(/^[+-]?(\d*)(\.\d+)?\b$/)]);
        this.packs = new FormControl(value.packs, [Validators.pattern(/^[+-]?(\d*)(\.\d+)?\b$/)]);
        this.gross = new FormControl(value.gross, [Validators.pattern(/^[+-]?(\d*)(\.\d+)?\b$/)]);
        this.net = new FormControl(value.net, [Validators.pattern(/^[+-]?(\d*)(\.\d+)?\b$/)]);
        this.value = new FormControl(value.value, [Validators.pattern(/^[+-]?(\d*)(\.\d+)?\b$/)]);




    }
}

export class InvoiceParsingGoodsItemsModel {
    pal: string;
    packs: string;
    gross: string;
    net: string;
    value: string;

    constructor(value?) {

        this.pal = value?.pal || null
        this.packs = value?.packs || null
        this.gross = value?.gross || null
        this.net = value?.net || null
        this.value = value?.value || null

    }

}