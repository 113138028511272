export class Cartra100Model {
    NamCARTRA121?: string;
    StrAndNumCARTRA254?: string;
    PosCodCARTRA121?: string;
    CitCARTRA789?: string;
    CouCodCARTRA587?: string;
    NADCARTRA121?: string;
    TINCARTRA254?: string;
    TinTypCARTRA1009?: string; //TIR ONLY
    CarrierObject?: {};

    constructor(value?) {
        this.NamCARTRA121 = value?.NamCARTRA121 || null;
        this.StrAndNumCARTRA254 = value?.StrAndNumCARTRA254 || null;
        this.PosCodCARTRA121 = value?.PosCodCARTRA121 || null;
        this.CitCARTRA789 = value?.CitCARTRA789 || null;
        this.CouCodCARTRA587 = value?.CouCodCARTRA587 || null;
        this.NADCARTRA121 = value?.NADCARTRA121 || null;
        this.TINCARTRA254 = value?.TINCARTRA254 || null;
        this.TinTypCARTRA1009 = value?.TinTypCARTRA1009;
        this.CarrierObject = value?.CarrierObject || {};
    }
}
