export class Traconco1Model {
    NamCO17?: string;
    StrAndNumCO122?: string;
    PosCodCO123?: string;
    CitCO124?: string;
    CouCO125?: string;
    NADLNGCO?: string;
    TINCO159?: string;
    SpeMenCO408?: string; //On IMPORT ONLY
    TinTypCO1009?: string; //ON NCTS ONLY
    ConsignorObject?: {};

    constructor(value?) {
        this.NamCO17 = value?.NamCO17 || null;
        this.StrAndNumCO122 = value?.StrAndNumCO122 || null;
        this.PosCodCO123 = value?.PosCodCO123 || null;
        this.CitCO124 = value?.CitCO124 || null;
        this.CouCO125 = value?.CouCO125 || null;
        this.NADLNGCO = value?.NADLNGCO || null;
        this.TINCO159 = value?.TINCO159 || null;
        this.SpeMenCO408 = value?.SpeMenCO408 || null;
        this.TinTypCO1009 = value?.TinTypCO1009;
        this.ConsignorObject = value?.ConsignorObject;
    }
}
