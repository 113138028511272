<div fxLayout="column" style="width: 100%; flex-grow: 1">

    <!-- HEADER -->
    <div mat-dialog-title class="header primary p-6 p-sm-24" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- APP TITLE -->
        <div fxLayout="column" fxLayoutAlign="center flex-start">
            <!--            
-->
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <span class="logo-text h1"> {{ dialogTitle }} </span>
            </div>
            <div *ngIf="contact">{{ contact.lastname }}</div>
        </div>
        <!-- / APP TITLE -->

        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button (click)="matDialogRef.close()">
                <mat-icon class="dialog-close-button">highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <!-- / HEADER -->

    <div mat-dialog-content style="
        background-color: #f5f5f5;" class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <div [formGroup]="contactForm" style="width: 100%;height:100px;">
            <div class="mr-24">
                <h3 *ngIf="!carrier" mat-subheader>Στοιχεία Πελάτη</h3>
                <h3 *ngIf="carrier" mat-subheader>Στοιχεία Μεταφορέα</h3>
            </div>


            <div *ngIf="domestic || carrier" fxLayout="row" fxLayoutAlign="space-between start">

                <div fxLayout="row" style="width: 35%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>A.Φ.Μ.</mat-label>

                        <input name="afm" formControlName="afm" maxlength="9" matInput required />
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <mat-error>Μέγιστος αριθμός χαρακτήρων 9</mat-error>
                    </mat-form-field>

                </div>
                <div fxLayout="row" style="width: 45%; margin-top: 1.6%" fxLayoutAlign="start center">
                    <button mat-raised-button type="button" [disabled]="afm.value?.length !== 9"
                        (click)="getBusinessData()">
                        Ανάκτηση Στοιχείων
                    </button>
                </div>
            </div>

            <div *ngIf="domestic" fxLayout="row" style="width: 100%" fxLayoutAlign="space-between start">

                <div style="width: 35%">

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Τύπος Πελάτη</mat-label>

                        <select matNativeControl name="customerType" formControlName="customerType" required>
                            <option [ngValue]=1>Επιχείρηση</option>
                            <option [ngValue]=2>Ιδιώτης</option>
                        </select>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>
                </div>

                <div style="width: 45%">
                    <mat-form-field *ngIf="contactForm.get('customerType').value===1" appearance="outline" fxFlex>
                        <mat-label>Εμπορικό Σήμα</mat-label>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <input name="trademark" formControlName="trademark" matInput />
                    </mat-form-field>
                </div>

            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Επωνυμία</mat-label>
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <input name="lastname" formControlName="lastname" matInput required />
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div *ngIf="domestic || carrier" fxLayout="row" style="width: 35%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Δ.Ο.Υ.</mat-label>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <input name="doy" formControlName="doy" matInput required />
                    </mat-form-field>
                </div>
                <div fxLayout="column" style="width: 35%">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div style="width: 85%">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>EORI</mat-label>

                                <input name="eori" [formControl]="eoriControl" matInput />
                                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                                <mat-error *ngIf="eoriControl.hasError('pattern')">
                                    To EORI πρέπει να ξεκινάει με τον κωδικό της χώρας και ακολουθείται απο μέγιστο 15
                                    χαρακτήρων.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div style="width: 10%; margin-top: -4.5%" fxLayoutAlign="center">
                            <mat-icon *ngIf="status===0" class="check success">check</mat-icon>
                            <mat-icon *ngIf="status===1" class="check danger">close</mat-icon>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="status===1" style="margin-top: -7%; margin-left: 2%; color: red">
                        <small>{{eoriErrorMessage}}</small>
                    </div>
                </div>
                <div fxLayout="row" style="width: 16%; margin-top: 1.6%" fxLayoutAlign="start center">
                    <button mat-raised-button type="button" (click)="checkEori()">
                        Έλεγχος EORI
                    </button>
                </div>
            </div>

            <!--             <div fxLayout="row" fxLayoutAlign="space-between start">
            </div>
 -->
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Εργασίες</mat-label>
                    <mat-icon matSuffix class="secondary-text">work</mat-icon>
                    <input name="occupation" formControlName="occupation"
                        [required]="domestic && contactForm.get('customerType').value===1" matInput />
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div fxLayout="row" style="width: 55%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Διεύθυνση</mat-label>
                        <mat-icon matSuffix class="secondary-text">home</mat-icon>
                        <input name="street" formControlName="street" matInput />

                    </mat-form-field>
                </div>

                <div fxLayout="row" style="width: 35%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Ταχ. Κωδικός</mat-label>
                        <mat-icon matSuffix class="secondary-text">domain</mat-icon>
                        <input name="postal_code" formControlName="postal_code" matInput />
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div fxLayout="row" style="width: 45%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Πόλη</mat-label>
                        <mat-icon matSuffix class="secondary-text">domain</mat-icon>
                        <input name="city" formControlName="city" matInput required />
                    </mat-form-field>
                </div>

                <div id="country-select" fxLayout="row" style="width: 45%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Χώρα</mat-label>
                        <mat-icon matSuffix class="secondary-text">domain</mat-icon>
                        <country-select style="width:10%" name="country" formControlName="country" [required]="true"
                            [config]="config1"></country-select>
                        {{contactForm.get('country').value | countryPipe: 'country_name' }}
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div fxLayout="row" style="width: 45%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Email</mat-label>
                        <mat-icon matSuffix class="secondary-text">email</mat-icon>
                        <input name="email" formControlName="email" matInput type="email" autocomplete="new-email" />
                    </mat-form-field>
                </div>

                <div fxLayout="row" style="width: 45%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Τηλέφωνο</mat-label>
                        <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                        <input name="phone" formControlName="phone" matInput autocomplete="new-phone" />
                    </mat-form-field>
                </div>
            </div>

            <mat-divider [fxShow]="domestic"></mat-divider>

            <div [fxShow]="domestic" fxLayout="row" fxLayoutAlign="start center">
                <div class="mr-24">
                    <h3 mat-subheader>Διαπιστευτήρια ICISNet</h3>
                </div>
                <div style="margin-top: -3px">
                    <mat-checkbox id="icisCheck" (change)="OnChange($event)" [(checked)]="IcisNetChecked"
                        (click)="$event.stopPropagation()">
                    </mat-checkbox>
                </div>
            </div>
            <div *ngIf="domestic" [fxShow]="IcisNetChecked" fxLayout="row" fxLayoutAlign="space-between start">
                <div fxLayout="row" style="width: 30%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Όνομα Χρήστη</mat-label>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <input name="icisUser" formControlName="icisUser" matInput />
                    </mat-form-field>
                </div>

                <div fxLayout="row" style="width: 30%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Συνθηματικό</mat-label>
                        <input matInput type="password" formControlName="icisPass" />
                        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" style="width: 30%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline">
                        <mat-label>Συνθηματικό (Επιβεβαίωση)</mat-label>
                        <input matInput type="password" formControlName="icisPassConfirm" />
                        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                        <mat-error *ngIf="
                            contactForm
                                .get('icisPassConfirm')
                                .hasError('required')
                        ">
                            Το πεδίο είναι απαραίτητο
                        </mat-error>
                        <mat-error *ngIf="
                            !contactForm
                                .get('icisPassConfirm')
                                .hasError('required') &&
                            contactForm
                                .get('icisPassConfirm')
                                .hasError('passNotMatching')
                        ">
                            Τα συνθηματικά πρέπει να είναι ίδια
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <mat-divider *ngIf="domestic"></mat-divider>
            <div *ngIf="domestic" fxLayout="row" fxLayoutAlign="start center">
                <div class="mr-24">
                    <h3 mat-subheader>Πρόσθετα Εξαγωγής</h3>
                </div>
                <div style="margin-top: -3px">
                    <mat-checkbox id="addCheck" (change)="OnChange($event)" [(checked)]="addChecked"
                        (click)="$event.stopPropagation()">
                    </mat-checkbox>
                </div>
            </div>
            <div *ngIf="domestic" [fxShow]="addChecked" fxLayout="column" fxLayoutAlign="start start">
                <div fxLayout="row" class="textarea-wrapper" fxLayoutAlign="start start" style="width: 100%">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Στοιχεία με Λατινικούς Χαρακτήρες</mat-label>
                        <textarea name="exporterEng" formControlName="exporterEng" matInput
                            placeholder="Επωνυμία&#10;Διεύθυνση&#10;Πόλη&#10;Χώρα" type="text" rows="4"></textarea>
                    </mat-form-field>
                </div>

                <div fxLayout="row" style="width: 45%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Διακριτικό Εξαγωγέα</mat-label>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <input name="trademarkEn" formControlName="trademarkEn" matInput />
                    </mat-form-field>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div *ngIf="domestic" fxLayout="row" fxLayoutAlign="start center">
                <div class="mr-24">
                    <h3 mat-subheader>Εγγεκριμένος Αποθηκευτής</h3>
                </div>
                <div style="margin-top: -3px">
                    <mat-checkbox id="registeredCheck" (change)="OnChange($event)" [(checked)]="registeredChecked"
                        (click)="$event.stopPropagation()">
                    </mat-checkbox>
                </div>
            </div>
            <div *ngIf="domestic" [fxShow]="registeredChecked" fxLayout="row" fxLayoutAlign="space-between start">

                <div fxLayout="row" style="width: 45%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Αριθμός Αναγνώρισης</mat-label>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <input name="registeredWarehouseOwnerId" formControlName="registeredWarehouseOwnerId"
                            matInput />
                    </mat-form-field>


                </div>

                <div fxLayout="row" fxLayoutAlign="start start" style="width: 45%">
                    <div formArrayName="warehouseRefNumbers" style="width:100%">
                        <div class="w-full" fxLayout="row" fxLayoutAlign="start start"
                            *ngFor="let item of warehouseRefNumbers.controls; let i = index">
                            <div style="width:80%">
                                <mat-form-field appearance="outline" fxFlex>
                                    <mat-label>Αριθμός Φορολογικής Aποθήκης</mat-label>
                                    <input #firstInput matInput [formControlName]="i" (keyup.Enter)="onEnter()" />
                                </mat-form-field>
                            </div>
                            <div class="add_del_buttons">
                                <add-del-buttons [hideAdd]="hideAdd()" [hideDelete]="hideDelete()" (addButton)="add()"
                                    (deleteButton)="delete(i)"></add-del-buttons>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div mat-dialog-actions class="m-0 p-6" fxLayout="row" fxLayoutAlign="end center">
        <button *ngIf="action === 'edit'" mat-button class="mr-8" (click)="deleteContact()" aria-label="Delete"
            matTooltip="Delete">
            ΔΙΑΓΡΑΦΗ
        </button>

        <button *ngIf="action !== 'edit'" mat-button class="save-button" (click)="createContact()"
            [disabled]="contactForm.invalid" aria-label="SAVE">
            ΠΡΟΣΘΗΚΗ
        </button>

        <button *ngIf="action === 'edit'" mat-button class="save-button" (click)="updateContact()"
            [disabled]="contactForm.invalid" aria-label="SAVE">
            ΑΠΟΘΗΚΕΥΣΗ
        </button>
    </div>
</div>