import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delivery-place-dialog',
    templateUrl: './delivery-place-dialog.component.html',
    styleUrls: ['./delivery-place-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DAODeliveryPlaceDialogComponent {
    @ViewChild('firstInput') _first: ElementRef
    deliveryPlace: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<DAODeliveryPlaceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public VehicleArrivalConsignee: FormGroup

    ) {
        console.log("VehicleArrivalConsignee on dialogRef", VehicleArrivalConsignee)
        this.deliveryPlace = VehicleArrivalConsignee.get('DeliveryPlace') as FormGroup
    }

    color1: string;
    color2: string;

    ngAfterViewInit() {
        setTimeout(() => {
            this._first.nativeElement.focus()
        }, 200)
    }

    onCloseConfirm() {
        this.dialogRef.close();
    }
    cancel() {
        this.VehicleArrivalConsignee.get('VehicleTaxWarehouseReference').reset()
        this.VehicleArrivalConsignee.get('DeliveryPlace').reset()
        this.dialogRef.close();

    }
    changeStyle1(event) {
        this.color1 = event.type === 'mouseenter' ? 'accent' : '';
    }
    changeStyle2(event) {
        if (this.VehicleArrivalConsignee.valid) {
            this.color2 = event.type === 'mouseenter' ? 'accent' : 'primary';
        }
    }
}
