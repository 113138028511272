import { AbstractControl, FormControl } from "@angular/forms";
import { GuaranteeReferenceType } from "./guarantee-reference.model";

export class GuaranteeReferenceForm {
    [key: string]: AbstractControl;

    GuaranteeReferenceNumber: FormControl;
    GuaranteeAmount: FormControl;

    /*

   */

    constructor(value: GuaranteeReferenceType) {

        this.GuaranteeReferenceNumber = new FormControl(value.GuaranteeReferenceNumber);
        this.GuaranteeAmount = new FormControl(value.GuaranteeAmount);

    }
}
