import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ImpVehDetModel } from './vehdet.model';
import { VehicleInventoryForm } from './vehicle-inventory/vehinv-form.model';
import { VehicleInventoryModel } from './vehicle-inventory/vehinv.model';

export class ImpVehDetForm {
    [key: string]: AbstractControl;

    FraNumVD1014 = new FormControl();
    VehCC4006 = new FormControl();
    VehFue4007 = new FormControl();
    VehTyp4008 = new FormControl();
    VehEngTyp4009 = new FormControl();
    ProdYea4010 = new FormControl();
    VecTra4023 = new FormControl();
    VehFacTyp4001 = new FormControl();
    VehUseFl4015 = new FormControl();
    WarNumVEHDET40 = new FormControl();
    VecModDatVECDET02 = new FormControl();
    ArImpDatVECDET01 = new FormControl();
    ImpCodVECDETGI = new FormControl();
    CouLasVECDETGI = new FormControl();
    AppCodVehVECDETGI = new FormControl();
    AppCodFacVECDETGI = new FormControl();
    AppCodTypVECDETGI = new FormControl();
    VehRebFlaVEDEGI = new FormControl();
    RebVehRegPlaVEDEGI = new FormControl();
    MilVECDET03 = new FormControl();
    CarDioEmiVECDET04 = new FormControl();
    VEHINVVECDETGI = new FormArray([]);

    constructor(value: ImpVehDetModel) {
        this.FraNumVD1014.setValue(value.FraNumVD1014);
        this.VehCC4006.setValue(value.VehCC4006);
        this.VehFue4007.setValue(value.VehFue4007);
        this.VehTyp4008.setValue(value.VehTyp4008);
        this.VehEngTyp4009.setValue(value.VehEngTyp4009);
        this.ProdYea4010.setValue(value.ProdYea4010);
        this.VecTra4023.setValue(value.VecTra4023);
        this.VehFacTyp4001.setValue(value.VehFacTyp4001);
        this.VehUseFl4015.setValue(value.VehUseFl4015);
        this.WarNumVEHDET40.setValue(value.WarNumVEHDET40);
        this.VecModDatVECDET02.setValue(value.VecModDatVECDET02);
        this.ArImpDatVECDET01.setValue(value.ArImpDatVECDET01);
        this.ImpCodVECDETGI.setValue(value.ImpCodVECDETGI);
        this.CouLasVECDETGI.setValue(value.CouLasVECDETGI);
        this.AppCodVehVECDETGI.setValue(value.AppCodVehVECDETGI);
        this.AppCodFacVECDETGI.setValue(value.AppCodFacVECDETGI);
        this.AppCodTypVECDETGI.setValue(value.AppCodTypVECDETGI);
        this.VehRebFlaVEDEGI.setValue(value.VehRebFlaVEDEGI);
        this.RebVehRegPlaVEDEGI.setValue(value.RebVehRegPlaVEDEGI);
        this.MilVECDET03.setValue(value.MilVECDET03);
        this.CarDioEmiVECDET04.setValue(value.CarDioEmiVECDET04);

        if (value.VEHINVVECDETGI) {
            value.VEHINVVECDETGI.forEach((inventory: VehicleInventoryModel) => {
                this.VEHINVVECDETGI.push(new FormGroup(new VehicleInventoryForm(new VehicleInventoryModel(inventory))));
            });
        } else {
            this.VEHINVVECDETGI.push(new FormGroup(new VehicleInventoryForm(new VehicleInventoryModel())));
        }
    }
}
