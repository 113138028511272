import { FormControl, Validators } from '@angular/forms';
import { CusoffdesestModel } from './cusoffdesest.model';

export class CusoffdesestForm {
    [key: string]: FormControl;
    _countryCode = new FormControl();
    RefNumEST1 = new FormControl();

    constructor(value: CusoffdesestModel) {
        this._countryCode.setValue(value._countryCode);
        this.RefNumEST1.setValue(value.RefNumEST1);

        // this.RefNumEST1.setValidators([Validators.required]);
    }
}
