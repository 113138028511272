import { NgModule } from '@angular/core';
import { ActivityLedsComponent } from './activity-leds.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';

@NgModule({
    declarations: [ActivityLedsComponent],
    imports: [FuseSharedModule, MaterialModule],
    exports: [ActivityLedsComponent]
})
export class ActivityLedsModule {}
