import { CustomersService } from 'app/services/customers.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { filter, map, first, switchMap, tap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { CountriesService } from 'app/services/countries.service';
import { BusinessService } from 'app/main/businesses/services/business.service';
import { AuthService } from 'app/core/auth/auth.service';
import { Accounts, AccountsService } from 'app/main/businesses/accounts/accounts.service';
import { User } from 'app/core/user/user.model';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { countriesItem } from 'app/model/api-model';

export interface BusinessResolverData {
    user: User;
    pelates: Contact[];
    countries: countriesItem[];
    paymentAccounts: Accounts
}

@Injectable()
export class BusinessResolver implements Resolve<any> {
    // declaration_id: number;

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private businessService: BusinessService,
        private customersService: CustomersService,
        private countiesService: CountriesService,
        private accountsService: AccountsService
    ) {
        console.log('Business Resolver hit');
    }

    /**
     * Resolver
     *
     * @param route
     * @param state
     * @returns
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {



        const businessId: string = route.params['businessId'];

        //console.log('Office Resolver hit', businessId);
        //console.log('Office Resolver hit', route.params);
        //console.log('Office Resolver hit route', route);
        //console.log('Office Resolver hit state', route);

        const business = this.businessService.getBusinessById(businessId);


        const user$ = this.userService.user$;
        const pelates$ = this.customersService.getDomesticCustomers(false);
        const countries$ = this.countiesService.getCountries();
        const paymentAccounts$ = this.accountsService.getAccounts('payment', false)

        const combine$ = combineLatest([user$, pelates$, countries$, paymentAccounts$]).pipe(
            filter(([user, pelates, countries, paymentAccounts]) => Boolean(user && pelates && countries && paymentAccounts)),
            map(([user, pelates, countries, paymentAccounts]) => ({ user, pelates, countries, paymentAccounts })),
            first()
        );



        return this.authService.businessAuthenticate(business).pipe(switchMap(() => combine$))

    }
}
