import { FormGroup } from '@angular/forms';
import { InvoiceAccountForm } from '../invoices/_models/invoice-account/invoice-account-form.model';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, tap, take, Subject, switchMap } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { InvoiceAccountModel } from 'app/main/businesses/invoices/_models/invoice-account/invoice-account.model';
import { AccountGenericModel } from 'app/main/businesses/invoices/_models/account-generic/account-generic.model';
import { BusinessService } from 'app/main/businesses/services/business.service';

export interface Accounts {
    count: number;
    accounts: InvoiceAccountModel[];
}
export interface AccountsChart {
    accounts: AccountsChartModel;
}
export interface AccountsChartModel {

    group: AccountGenericModel;
    label: string;
    sub_group: AccountSubGroup[];
}

export interface AccountSubGroup {
    name: string;
    add_new_sub_group_type: AccountGenericModel;
    anchor_eligible: boolean;
    anchor_tier: AccountGenericModel;
    description: string;
    accounts: InvoiceAccountModel[];

}
@Injectable({
    providedIn: 'root'
})
export class AccountsService {

    filterBy: number;

    accountForm$: Observable<FormGroup<InvoiceAccountForm>>;
    paymentAccounts$: Observable<Accounts>
    anchorAccounts$: Observable<Accounts>
    allAccounts$: Observable<Accounts>

    customerCreditBalance$: Observable<number>;

    private onAccountsChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    private onFilterChanged: Subject<number> = new Subject();


    private accountForm: BehaviorSubject<FormGroup<InvoiceAccountForm>> = new BehaviorSubject(null);
    private paymentAccounts: BehaviorSubject<Accounts> = new BehaviorSubject<Accounts>({ count: 0, accounts: [] });
    private anchorAccounts: BehaviorSubject<Accounts> = new BehaviorSubject<Accounts>(null);
    private allAccounts: BehaviorSubject<Accounts> = new BehaviorSubject<Accounts>(null);
    private customerCreditBalance: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    PHP_API_SERVER = environment.API_URL;


    constructor(private http: HttpClient, private businessService: BusinessService) {

        this.accountForm$ = this.accountForm.asObservable()
        this.allAccounts$ = this.allAccounts.asObservable();
        this.paymentAccounts$ = this.paymentAccounts.asObservable();
        this.anchorAccounts$ = this.anchorAccounts.asObservable();
        this.customerCreditBalance$ = this.customerCreditBalance.asObservable();

        this.onAccountsChanged = new BehaviorSubject([]);

        this.onFilterChanged = new Subject<number>();


    }


    createAccount() {

        const defaultValues: InvoiceAccountModel = {
            sequence: 0,
            active: true,
            archived: false,
            currency_code: "EUR"
        }
        const newForm = new FormGroup(new InvoiceAccountForm(new InvoiceAccountModel(defaultValues)));

        this.accountForm.next(newForm)
    }

    updateAccount() { }
    deleteAccount() { }
    getAccount(id) { }
    getAccounts(type, isArchived) {

        return this.businessService.currentBusiness$.pipe(switchMap(currentBusiness => {

            const postData = {
                business_id: currentBusiness.business_id, type, isArchived
            }
            return this.http.post<Accounts>(`${this.PHP_API_SERVER}/accounts/`, postData).pipe(tap(accounts => {
                switch (type) {
                    case 'payment':
                        this.updatePaymentAccounts(accounts);
                        break;
                    case 'anchor':
                        this.updateAnchorAccounts(accounts);
                        break;
                    case 'all':
                        this.updateAllAccounts(accounts);
                        break;

                    default:
                        break;

                }
            }))

        }))


    }

    getCustomerCreditsAccount(customerId) {

        //console.trace("getCustomerCreditsAccount")
        return this.http.get<Accounts>(`${this.PHP_API_SERVER}/accounts/index.php/customer/${customerId}`).pipe(tap(accounts => {

            //   console.log("getCustomerCreditsAccount ", accounts?.accounts[0]?.customer_id, 'balance', accounts?.accounts[0]?.account_balance.balance)

            this.customerCreditBalance.next(accounts?.accounts[0]?.account_balance.balance || 0)
            // Check if customer has any credit
            if (Math.abs(accounts?.accounts[0]?.account_balance.balance) > 0) {

                // Change account name
                accounts.accounts.forEach(account => {
                    account.account_name = "Πιστωτικό Υπόλοιπο (Ποσό:  €" + Math.abs(account.account_balance.balance) + ")"
                });

                // Merge with payment accounts
                this.paymentAccounts$.pipe(take(1)).subscribe(paymentAccounts => {
                    // Filter out any previous customer credit accounts
                    const filteredPaymentAccounts = paymentAccounts.accounts.filter(account => account.sub_group.value !== 36);

                    // Merge the current customer credit accounts with the filtered payment accounts
                    const mergedAccounts = {
                        count: accounts.count + filteredPaymentAccounts.length,
                        accounts: [...accounts.accounts, ...filteredPaymentAccounts]
                    };
                    this.updatePaymentAccounts(mergedAccounts);
                });
            }
            else {
                // Filter out any previous customer credit accounts 
                this.paymentAccounts$.pipe(take(1)).subscribe(paymentAccounts => {
                    this.updatePaymentAccounts(paymentAccounts);
                });
            }
        }));
    }

    getAccountsChart(): Observable<AccountsChart> {
        return this.http.get<AccountsChart>(`${this.PHP_API_SERVER}/accounts/accounts-chart/`).pipe(tap(data => {

            console.log("ACCOUNTSCHART", data.accounts)
        }))
    }

    updatePaymentAccounts(accounts: Accounts) {
        console.log("updatePaymentAccounts", accounts)
        this.paymentAccounts.next(accounts)

        this.paymentAccounts$.pipe(take(1)).subscribe(value => {
            console.log("updatePaymentAccounts subscription", value)


        })
    }

    updateAnchorAccounts(accounts: Accounts) {
        this.anchorAccounts.next(accounts)
    }

    updateAllAccounts(accounts: Accounts) {
        this.allAccounts.next(accounts)
    }

    filterChange(groupId: number) {
        this.onFilterChanged.next(groupId);
    }

}
