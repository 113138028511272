import { genericItem } from 'app/model/api-model';
import { Observable, Subject, of, share, switchMap, take } from 'rxjs';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { Injectable } from '@angular/core';
import { Declarant } from 'app/core/user/user.model';
import { ApiNodeService } from 'app/services/api.node.service';
import { StateResponse } from 'app/services/declaration-state.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';

@Injectable({
    providedIn: 'root'
})
export class ExciseService {

    private loginCompleted$ = new Subject<void>();

    // An array to queue any requests that need to wait until the user is logged in.
    private queuedRequests: Function[] = [];


    PrevDocsOptions = [
        { code: '1', desc: 'ΑΣΔΕ' },
        { code: '2', desc: 'e-ΔΕ' },
        { code: '3', desc: 'Αίτηση Διάθεσης Ενσήμων Ταινιών' },
        { code: '4', desc: 'ΔΑΟ' },
        { code: '5', desc: 'ΑΔΟ' },
        { code: '6', desc: 'ΔΕΦΚ' },
    ]

    docStateOptions = [{ code: '0', desc: 'COM 4' }, { code: '1', desc: 'COM 9' }]

    constructor(private dbQuery: ApiService, private apiNode: ApiNodeService, private mainBroadcaster: MainBroadcasterService) { }


    getReferenceDocs(): Observable<genericItem[]> {
        return this.dbQuery
            .get_options(ApiEndPoints.emcs_defk_docs)
            .pipe(share(), take(1))
    }
    getSpecialMentions(): Observable<genericItem[]> {
        return this.dbQuery
            .get_options(ApiEndPoints.emcs_eidikes_mneies)
            .pipe(share(), take(1))
    }


    /**
   * Fetches the state from the ICIS system.
   * If the user isn't logged in yet, it queues the request to be executed later.
   *
   * @param {string} subdomain - The subdomain to use for the request.
   * @param {Declarant} declarant - The declarant information.
   * @param {string} lrn - The LRN for the request.
   * @returns {Observable<StateResponse> | undefined}
   */
    getStateEfk(subdomain: string, declarant: Declarant, lrn: string): Observable<{ results?: StateResponse, error?: any }> {
        console.log("ExciseService getStateEfk called")
        return this.mainBroadcaster.icisUser$.pipe(take(1),

            switchMap((icisUser) => {

                if (!icisUser.isLoggedIn) {
                    console.log("ExciseService, user is not logged in, Queuing request...")
                    this.queueRequest(() => this._getStateEfk(subdomain, declarant, lrn));
                    return of({ error: "User not logged in" }); // Or return an empty observable
                }
                console.log("ExciseService, user is logged in, executing request...")
                return this._getStateEfk(subdomain, declarant, lrn);
            })
        )
    }

    /**
     * Internal method to make the actual API call to get the state from ICIS system.
     *
     * @private
     * @param {string} subdomain - The subdomain to use for the request.
     * @param {Declarant} declarant - The declarant information.
     * @param {string} lrn - The LRN for the request.
     * @returns {Observable<StateResponse>}
     */
    private _getStateEfk(subdomain: string, declarant: Declarant, lrn: string): Observable<StateResponse> {
        return this.apiNode.getStateEfk(subdomain, declarant, lrn);
    }

    /**
     * Adds a new request to the queue.
     *
     * @private
     * @param {Function} request - The request function to be queued.
     */
    private queueRequest(request: Function): void {
        console.log("ExciseService queueRequest", request)
        this.queuedRequests.push(request);
        console.log("ExciseService All queuedRequests", this.queuedRequests)

    }

    /**
     * Executes all the queued requests.
     * This should be called after the user successfully logs in.
     *
     * @private
     */
    private executeQueuedRequests(): void {
        console.log("ExciseService executeQueuedRequests")
        while (this.queuedRequests.length) {
            const nextRequest = this.queuedRequests.shift();
            if (nextRequest) {
                console.log("ExciseService executeQueuedRequests nextRequest", nextRequest)
                nextRequest();
            }
        }
    }


    private areDeclarantsEqual(declarant1: Declarant, declarant2: Declarant): boolean {
        // Modify this comparison based on what constitutes equality for your Declarant type.
        // For simplicity, I'm using a direct comparison, but you might want to check specific properties.
        return declarant1?.id === declarant2?.id;
    }

    private isIcisLoggedIn(declarant: Declarant) {

    }
}
