import { PrivateControlsModel } from "app/model/private-controls.model";

export class Address {
    Country: string;
    City: string;
    Postcode: string;
    StreetAndNumber: string;

    constructor(value?) {
        this.Country = value?.Country || null;
        this.City = value?.City || null;
        this.Postcode = value?.Postcode || null;
        this.StreetAndNumber = value?.StreetAndNumber || null;
    }
}

export class EoNameAndAddress {
    Address: Address;
    Name: string;

    constructor(value?) {
        this.Address = new Address(value?.Address);
        this.Name = value?.Name || null;
    }
}

export class PersonPresentingGoodsToCustoms {
    IdentificationNumber: string;
    IdentificationType: string;
    EoNameAndAddress: EoNameAndAddress;

    constructor(value?) {
        this.IdentificationNumber = value?.IdentificationNumber || null;
        this.IdentificationType = value?.IdentificationType || null;
        this.EoNameAndAddress = new EoNameAndAddress(value?.EoNameAndAddress);
    }
}

export class Justification {
    FreeText: string;

    constructor(value?) {
        this.FreeText = value?.FreeText || null;
    }
}

export class RequestedValidityOfTheProof {
    NumberOfDays: number;
    Justification: Justification;

    constructor(value?) {
        this.NumberOfDays = value?.NumberOfDays || null;
        this.Justification = new Justification(value?.Justification);
    }
}

export class AdditionalInformation {
    Text: string;
    Code: string;

    constructor(value?) {
        this.Text = value?.Text || null;
        this.Code = value?.Code || null;
    }
}

export class Commodity {
    HarmonizedSystemSubHeadingCode: string;
    CombinedNomenclatureCode: string;

    constructor(value?) {
        this.HarmonizedSystemSubHeadingCode = value?.HarmonizedSystemSubHeadingCode || null;
        this.CombinedNomenclatureCode = value?.CombinedNomenclatureCode || null;
    }
}

export class GoodsMeasure {
    GrossMass: number;
    NetMass: number;

    constructor(value?) {
        this.GrossMass = value?.GrossMass || null;
        this.NetMass = value?.NetMass || null;
    }
}

export class Packaging {
    ShippingMarks: string;
    NumberOfPackages: number;
    TypeOfPackages?: string;

    constructor(value?) {
        this.ShippingMarks = value?.ShippingMarks || null;
        this.NumberOfPackages = value?.NumberOfPackages || null;
        this.TypeOfPackages = value?.TypeOfPackages || null;
    }
}

export class SupportingDocuments {
    Type: string;
    ReferenceNumber: string;
    Date?: string;

    constructor(value?) {
        this.Type = value?.Type || null;
        this.ReferenceNumber = value?.ReferenceNumber || null;
        this.Date = value?.Date || null;
    }
}

export class AdditionalReferences {
    Type: string;
    ReferenceNumber: string;

    constructor(value?) {
        this.Type = value?.Type || null;
        this.ReferenceNumber = value?.ReferenceNumber || null;
    }
}

export class PreviousDocuments {
    Type: string;
    ReferenceNumber: string;
    Date?: string;

    constructor(value?) {
        this.Type = value?.Type || null;
        this.ReferenceNumber = value?.ReferenceNumber || null;
        this.Date = value?.Date || null;
    }
}

export class GoodsItemsForT2LT2LF {
    AdditionalInformation: AdditionalInformation;
    Commodity: Commodity;
    CusCode?: string;
    GoodsItemNumber: number;
    DescriptionOfGoods: string;
    GoodsMeasure: GoodsMeasure;
    Packaging: Packaging[];
    SupportingDocuments: SupportingDocuments[];
    AdditionalReferences?: AdditionalReferences[];
    PreviousDocuments: PreviousDocuments[];

    constructor(value?) {
        this.AdditionalInformation = new AdditionalInformation(value?.AdditionalInformation);
        this.Commodity = new Commodity(value?.Commodity);
        this.CusCode = value?.CusCode || null;
        this.GoodsItemNumber = value?.GoodsItemNumber || null;
        this.DescriptionOfGoods = value?.DescriptionOfGoods || null;
        this.GoodsMeasure = new GoodsMeasure(value?.GoodsMeasure);
        this.Packaging = value?.Packaging;
        this.SupportingDocuments = (value?.SupportingDocuments || []).map(sd => new SupportingDocuments(sd));
        this.AdditionalReferences = (value?.AdditionalReferences || []).map(ar => new AdditionalReferences(ar));
        this.PreviousDocuments = (value?.PreviousDocuments || []).map(pd => new PreviousDocuments(pd));
    }
}

export class LocationOfGoods {
    TypeOfLocation: string;
    QualifierOfIdentification: string;
    CustomsOffice: string;

    constructor(value?) {
        this.TypeOfLocation = value?.TypeOfLocation || null;
        this.QualifierOfIdentification = value?.QualifierOfIdentification || null;
        this.CustomsOffice = value?.CustomsOffice || null;
    }
}

export class TransportDocuments {
    Type: string;
    ReferenceNumber: string;
    Date?: string;

    constructor(value?) {
        this.Type = value?.Type || null;
        this.ReferenceNumber = value?.ReferenceNumber || null;
        this.Date = value?.Date || null;
    }
}

export class TransportEquipments {
    ContainerIdentificationNumber: string;
    GoodsReferences: number[];

    constructor(value?) {
        this.ContainerIdentificationNumber = value?.ContainerIdentificationNumber || null;
        this.GoodsReferences = value?.GoodsReferences || null;
    }
}

export class GoodsShipmentForT2LT2LF {
    ContainerIndication: string | boolean;
    LocationOfGoods: LocationOfGoods;
    GoodsItemsForT2LT2LF: GoodsItemsForT2LT2LF[];
    TransportDocuments?: TransportDocuments[];
    SupportingDocuments?: SupportingDocuments[];
    TransportEquipments: TransportEquipments[];
    PreviousDocuments: PreviousDocuments[];

    constructor(value?) {
        this.ContainerIndication = value?.ContainerIndication || null;
        this.LocationOfGoods = new LocationOfGoods(value?.LocationOfGoods);
        this.GoodsItemsForT2LT2LF = value?.GoodsItemsForT2LT2LF || null;
        this.TransportDocuments = (value?.TransportDocuments || []).map(td => new TransportDocuments(td));
        this.SupportingDocuments = (value?.SupportingDocuments || []).map(sd => new SupportingDocuments(sd));
        this.TransportEquipments = value?.TransportEquipments || [];
        this.PreviousDocuments = (value?.PreviousDocuments || []).map(pd => new PreviousDocuments(pd));
    }
}

export class ProofInformationT2LT2LF {
    CompetentCustomsOffice: string;
    DeclarationType: string;
    DeclarationDate: string;
    PersonPresentingGoodsToCustoms: PersonPresentingGoodsToCustoms;
    TotalGrossMassKg: number;
    RequestedValidityOfTheProof: RequestedValidityOfTheProof;
    GoodsShipmentForT2LT2LF: GoodsShipmentForT2LT2LF;
    RequestType: string;

    constructor(value?) {
        this.CompetentCustomsOffice = value?.CompetentCustomsOffice || null;
        this.DeclarationType = value?.DeclarationType || null;
        this.DeclarationDate = value?.DeclarationDate || null;
        this.PersonPresentingGoodsToCustoms = new PersonPresentingGoodsToCustoms(value?.PersonPresentingGoodsToCustoms);
        this.TotalGrossMassKg = value?.TotalGrossMassKg || null;
        this.RequestedValidityOfTheProof = new RequestedValidityOfTheProof(value?.RequestedValidityOfTheProof);
        this.GoodsShipmentForT2LT2LF = new GoodsShipmentForT2LT2LF(value?.GoodsShipmentForT2LT2LF);
        this.RequestType = value?.RequestType || null;
    }
}

export class ProofRequest {
    _PRIVATE: PrivateControlsModel;

    DelegateIdentificationType: string;
    TraderIdentificationType: string;
    LRN: string;
    TraderIdentificationNumber: string;
    Country: string;
    ProofInformationT2LT2LF: ProofInformationT2LT2LF;
    DelegateIdentificationNumber: string;


    constructor(value?) {
        this._PRIVATE = value?._PRIVATE || null;
        this.DelegateIdentificationType = value?.DelegateIdentificationType || null;
        this.TraderIdentificationType = value?.TraderIdentificationType || null;
        this.LRN = value?.LRN || null;
        this.TraderIdentificationNumber = value?.TraderIdentificationNumber || null;
        this.Country = value?.Country || null;
        this.ProofInformationT2LT2LF = new ProofInformationT2LT2LF(value?.ProofInformationT2LT2LF);
        this.DelegateIdentificationNumber = value?.DelegateIdentificationNumber || null;
    }
}
