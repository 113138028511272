import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { VallimecvleForm } from '../../../_models/vallimecvle/vallimecvle-form.model';
import { VallimecvleModel } from '../../../_models/vallimecvle/vallimecvle.model';
import { VallimnoneclimForm } from '../../../_models/vallimnoneclim/vallimnoneclim-form.model';
import { VallimnoneclimModel } from '../../../_models/vallimnoneclim/vallimnoneclim.model';
import { GuarefrefModel } from './guarefref.model';

export class GuarefrefForm {
    [key: string]: AbstractControl;

    OthGuaRefREF4: FormControl;
    AccCodREF6: FormControl;
    GuaRefNum400: FormControl;
    VALLIMECVLE: FormGroup;
    VALLIMNONECLIM: FormArray;

    constructor(value: GuarefrefModel) {
        this.OthGuaRefREF4 = new FormControl(value.OthGuaRefREF4, {
            updateOn: 'blur'
        });
        this.AccCodREF6 = new FormControl(value.AccCodREF6, {
            updateOn: 'blur'
        });
        this.GuaRefNum400 = new FormControl(value.GuaRefNum400, {
            updateOn: 'blur'
        });
        this.VALLIMECVLE = new FormGroup(new VallimecvleForm(new VallimecvleModel(value.VALLIMECVLE)));

        this.VALLIMNONECLIM = new FormArray([]);

        if (value.VALLIMNONECLIM) {
            value.VALLIMNONECLIM.forEach((nonVal: VallimnoneclimModel) => {
                this.VALLIMNONECLIM.push(new FormGroup(new VallimnoneclimForm(new VallimnoneclimModel(nonVal))));
            });
        } else {
            this.VALLIMNONECLIM.push(new FormGroup(new VallimnoneclimForm(new VallimnoneclimModel())));
        }
    }
}
