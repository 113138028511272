import { ExtCustomFunctions } from './../main/main-building-functions/ext-custom-functions.service';
import { of, Subject, takeUntil } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductNode, TaricService } from 'app/main/apps/taric/taric.service';
import { ChooseDialogComponent } from 'app/main/main-building-blocks/dialogs/choose-dialog/choose-dialog.component';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { CodeDescParams, MainBroadcasterService } from './broadcaster.service';
import { ComCodeService, RetrievedDescription } from './com-code.service';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { OfficeService } from 'app/services/office.service';

export interface DescriptionResults {
    current: RetrievedDescription;
    allDescriptions: RetrievedDescription[];
}
@Injectable({
    providedIn: 'root'
})
export class DeclarationProductDescriptionService {
    chooseDialogRef: MatDialogRef<ChooseDialogComponent>;

    lang: string;
    officeId: string;

    subdomain: string;
    goodsDesc: string;
    itemNumber: string;

    private _destroy: Subject<void> = new Subject();

    constructor(
        private dbQuery: ApiService,
        private matDialog: MatDialog,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<ChooseDialogComponent>,
        private taricService: TaricService,
        private toastrService: ToastrService,
        private injector: Injector,
        private mainBroadcaster: MainBroadcasterService,
        private cf: ExtCustomFunctions,
        private officeService: OfficeService
    ) {
        this.officeService.currentOffice$.pipe(takeUntil(this._destroy)).subscribe(office => {
            this.officeId = office?.id
        })

        this.mainBroadcaster.declarationSettings$.pipe(takeUntil(this._destroy)).subscribe((settings: DeclarationSettingsModel) => {
            this.lang = settings.language;
        })
        this.mainBroadcaster.activeSubdomain$.pipe(takeUntil(this._destroy)).subscribe(subdomain => {

            console.log("SUBDOMAIN IN PRODUCTDESC SERVICE", subdomain)
            this.subdomain = subdomain;
            switch (subdomain) {

                case "DEFK": {
                    this.goodsDesc = 'ProductDescription';
                    this.itemNumber = 'ExciseTaxesDeclarationRowNumber';
                    break;
                }
                case "POUS": {
                    this.goodsDesc = 'DescriptionOfGoods';
                    this.itemNumber = 'GoodsItemNumber';
                    break;

                }
                default: {

                    this.goodsDesc = 'GooDesGDS23';
                    this.itemNumber = 'IteNumGDS7';
                    break;
                }
            }
        })
    }

    ngOnDestroy() {
        this._destroy.next()
        this._destroy.complete()
    }

    async getProductDescription(
        goodsIndex: number,
        goodsFormGroup: FormGroup,
        goodsPrivateGroup: FormGroup,
        comCode: string,
        taricCode: string,
        product: ProductNode,
        openCodeDescDialog = true
    ) {
        console.log('COMCODE GETPRODUCTDESCRITPION()');

        const results = await this.getAllDescriptions(comCode, taricCode).toPromise();

        let currentDescription: RetrievedDescription = this.currentDescription(goodsFormGroup, goodsPrivateGroup, comCode, taricCode);
        //If a description is already set, but does not match the current comCode
        //that is, when we insert the description first and the comCode next
        //or if we caleed a template or draft and e change the product code and the description is already set
        if (results.length === 0) {
            if (currentDescription && currentDescription.description !== null && openCodeDescDialog) {
                this.openNoCodeDescDialog(currentDescription.description, product, comCode, taricCode, goodsIndex, goodsFormGroup, goodsPrivateGroup);
            }
            return { currentDescription, retrievedDescriptions: [] };
            //if comCode is associated with one result use it
        } else if (results.length > 0) {
            console.log('COMCODE GETDESC RESULTS', results);

            console.log('COMCODE RETRIEVED RESULTS', results);



            const combinedCode = comCode + taricCode;
            const forceDescriptionForCode = goodsPrivateGroup.get('forceDescriptionforCode').value;

            console.log('COMCODE forceDescriptionForCode', forceDescriptionForCode);
            console.log('COMCODE combinedCode', combinedCode);

            //forceDescription used in twoDayDistillers to force the description set by the user on the dialog, without setting the one saved in the db
            if (!forceDescriptionForCode || forceDescriptionForCode !== combinedCode) {

                currentDescription = this.getLastUsedDescription(results, goodsFormGroup);
                //update observable
                this.updateComCodeItem(goodsIndex, goodsPrivateGroup, currentDescription);
            }
            return { currentDescription, retrievedDescriptions: results };
        }
    }

    currentDescription(GoodsFormGroup: FormGroup, goodsPrivateGroup: FormGroup, comCode: string, taricCode: string): RetrievedDescription {
        let description: string = GoodsFormGroup?.get(this.goodsDesc)?.value;
        let descriptionEn: string = goodsPrivateGroup?.get('descriptionEn')?.value
        let botanicalName: string = goodsPrivateGroup?.get('botanicalName')?.value


        console.log('COMCODE  currentDescription description', description);
        console.log('COMCODE  currentDescription goodsPrivateGroup', goodsPrivateGroup.value);

        description = description !== null ? description : null;
        descriptionEn = descriptionEn !== null ? descriptionEn : 'null';
        botanicalName = botanicalName !== null ? botanicalName : null;

        const currentDescription: RetrievedDescription = {
            id: null,
            officeId: this.officeId,
            comCode: comCode,
            taricCode: taricCode,
            description,
            descriptionEn,
            botanicalName,
            lastUsed: 1
        };

        return currentDescription;
    }

    private updateComCode(goodsFormGroup: FormGroup, goodsPrivateGroup: FormGroup, product: ProductNode, showMessage = false) {
        console.log('updateCOmCode product', product);
        const comCode = product.code.substr(0, 8);
        const taricCode = product.code.substr(8, 10);

        const index = goodsFormGroup.get(this.itemNumber).value - 1;

        if (this.subdomain !== 'DEFK') {
            const comCodGodItm = goodsFormGroup.get('COMCODGODITM') as FormArray;

            const firstAddCod = comCodGodItm.at(0).get('TARFirAddCodCMD1');
            const secondAddCod = comCodGodItm.at(0).get('TARSecAddCodCMD1');
            const nationalAddCod = comCodGodItm.at(0).get('NAtAddCodCMD1');

            comCodGodItm.at(0).get('ComNomCMD1').setValue(comCode);
            comCodGodItm.at(0).get('TARCodCMD1').setValue('00');

            if (firstAddCod.value === null) {
                firstAddCod.setValue('0000');
            }
            if (secondAddCod.value === null) {
                secondAddCod.setValue('0000');
            }
            if (nationalAddCod.value === null) {
                nationalAddCod.setValue('0000');
            }
        } else {
            goodsFormGroup.get('TaricCode').setValue(product.code)
        }
        //update observable with code
        this.updateComCodeItem(index, goodsPrivateGroup, null, product);
        //delete currnet description
        this.updateDescription(goodsFormGroup, null);
        //set new description if fpund
        this.getProductDescription(index, goodsFormGroup, goodsPrivateGroup, comCode, taricCode, product, false);

        if (showMessage) {
            this.toastrService.success('Ο κωδικός ανανεώθηκε.', 'Είδος ' + (index + 1));
        }
    }

    updateDescription(goodsFormGroup: FormGroup, description: string, showMessage = false) {
        goodsFormGroup.get(this.goodsDesc)?.setValue(description, { emitEvent: false });

        //NOT on DEFK
        goodsFormGroup.get('GooDesGDS23LNG')?.setValue(this.lang);

        const index = goodsFormGroup.get(this.itemNumber).value - 1;

        if (showMessage) {
            this.toastrService.success('H περιγραφή ανανεώθηκε.', 'Είδος ' + (index + 1));
        }
    }

    private updateBoth(goodsFormGroup, goodsPrivateGroup, product, description) {
        this.updateComCode(goodsFormGroup, goodsPrivateGroup, product);
        this.updateDescription(goodsFormGroup, description);
        const index = goodsFormGroup.get(this.itemNumber).value - 1;

        this.toastrService.success('Ο κωδικός και η περιγραφή ανανεώθηκαν.', 'Είδος ' + (index + 1));
    }

    updateStoredDescription(
        goodsIndex: number,
        goodsPrivateGroup: FormGroup,
        comCode: string,
        taricCode: string,
        description: string,
        add: boolean,
        selectedProduct?: any //when description is already saved in the Database we return the saved  Product
    ): Observable<DescriptionResults> {
        let id = null;

        console.log('this.selectedProduct on updateDesr', selectedProduct);

        if (selectedProduct) {
            id = selectedProduct.id;
        }

        if (comCode !== null && comCode !== '') {
            const postData = {
                goodsIndex,
                goodsPrivateGroup,
                comCode,
                taricCode,
                description,
                id,
                officeId: this.officeId
            };

            if (add) {
                console.log('postData not id');
                return this.addDescription(postData);
            } else {
                console.log('postData if id');
                return this.editDescription(postData);
            }
        }
    }

    private openNoCodeDescDialog(currentDescription, product, comCode, taricCode, goodsIndex, goodsFormGroup, goodsPrivateGroup) {
        this.dialogRef = this.dialog.open(ChooseDialogComponent, {
            restoreFocus: true,
            disableClose: false,
            panelClass: 'mat-dialog-override',
            width: '55%',
            height: '70%'
        });

        this.dialogRef.componentInstance.dialogTitle = 'Αντιστοίχιση Κωδικού - Περιγραφής';
        this.dialogRef.componentInstance.dialogClass = 'primary';
        this.dialogRef.componentInstance.contentDescription =
            '<b>Η περιγραφή δεν αντιστοιχεί στον επιλεγμένο κωδικο.<b><br><br>Θέλετε:<br><br> - Να γίνει αποθήκευση της περιγραφής;<br><br> - Να προταθούν περιγραφές από το δασμολόγιο TARIC;<br><br> - Να γίνει χειρόγραφη εισαγωγή περιγραφής από το χρήστη;';

        this.dialogRef.componentInstance.confirmButtons = [
            { title: 'Αποθήκευση', class: 'accent', action: 'save' },
            { title: 'Περιγραφή TARIC', class: 'accent', action: 'taric' },
            {
                title: 'Χειρόγραφη Εισαγωγή',
                class: 'accent',
                action: 'manual'
            }
        ];
        ///CLose Dialog Events
        this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                this.dialogRef.close();
            }
        });

        this.dialogRef.backdropClick().subscribe(() => {
            this.dialogRef.close();
        });

        this.dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                //save the description to the current comCode
                if (result?.action === 'save') {
                    this.updateStoredDescription(goodsIndex, goodsPrivateGroup, comCode, taricCode, currentDescription, true).subscribe();

                    // this._input3.nativeElement.focus();
                    //open taric dialog
                } else if (result?.action === 'taric') {
                    console.log('setCodeDesc product:', product);
                    this.taricService.setCodeDesc({
                        product,
                        type: 'description',
                        goodsIndex
                    }).subscribe();

                    //set manual input later or dialog closed without choosing option
                } else {
                    this.updateDescription(goodsFormGroup, null); //  this._input3.nativeElement.focus();
                }
            });
    }

    onDescriptionChange(
        goodsIndex: number,
        goodsPrivateGroup: FormGroup,
        comCode: string,
        taricCode: string,
        value: string,
        currentDescription: RetrievedDescription
    ): Observable<DescriptionResults> {
        this.chooseDialogRef = this.matDialog.open(ChooseDialogComponent, {
            disableClose: false,
            panelClass: 'mat-dialog-override',
            width: '40%',
            height: '55%'
        });

        this.chooseDialogRef.componentInstance.dialogTitle = 'Η περιγραφή του είδους άλλαξε.';

        this.chooseDialogRef.componentInstance.contentButtons = [
            {
                title: 'Αντικατάσταση περιγραφής',
                class: 'accent',
                action: 'option1'
            },
            {
                title: 'Προσθήκη νέας περιγραφής',
                class: 'accent',
                action: 'option2'
            },
            { title: 'Καμία ενέργεια', class: 'accent', action: 'option3' }
        ];

        return this.chooseDialogRef.afterClosed().pipe(
            switchMap(result => {
                console.log('ChooseDialog AfterClosed Result', result);

                this.chooseDialogRef.close();

                this.chooseDialogRef = null;

                if (result === 'option1') {
                    return this.updateStoredDescription(goodsIndex, goodsPrivateGroup, comCode, taricCode, value, false, currentDescription);
                } else if (result === 'option2') {
                    return this.updateStoredDescription(goodsIndex, goodsPrivateGroup, comCode, taricCode, value, true);
                }
                else {
                    return of(null)
                }
            }),
            take(1)
        );
    }

    getAllDescriptions(comCode, taricCode): Observable<RetrievedDescription[]> {
        return this.dbQuery.getProductDescription(comCode, taricCode).pipe(take(1));
    }

    private addDescription(postData): Observable<DescriptionResults> {
        const comCode = postData.comCode;
        const taricCode = postData.taricCode;
        const index = postData.goodsIndex;
        const goodsPrivateGroup = postData.goodsPrivateGroup

        return this.dbQuery.addDescription(postData).pipe(
            tap((results: DescriptionResults) => {
                console.log('description added', results);
                //UPDATE OBSERVABLE
                this.updateComCodeItem(index, goodsPrivateGroup, results.current);
                //SHOW NOTIFICATION
                this.toastrService.success('', `Προσθήκη νέας περιγραφής για τον κωδικό '${comCode} ${taricCode}'.`, { timeOut: 3000 });
            }),
            take(1)
        );
    }

    private editDescription(postData): Observable<DescriptionResults> {
        const comCode = postData.comCode;
        const taricCode = postData.taricCode;
        const index = postData.goodsIndex;
        const goodsPrivateGroup = postData.goodsPrivateGroup


        return this.dbQuery.updateDescription(postData).pipe(
            tap((results: DescriptionResults) => {
                console.log('description updated', results);
                //UPDATE OBSERVABLE
                this.updateComCodeItem(index, goodsPrivateGroup, results.current);
                //SHOW NOTIFICATION
                this.toastrService.success('', `Αντικατάσταση περιγραφής για τον κωδικό '${comCode} ${taricCode}'.`, { timeOut: 3000 });
            }),
            take(1)
        );
    }

    deleteDescription(postData): Observable<RetrievedDescription[]> {
        const id = postData.id;
        const comCode = postData.comCode;
        const taricCode = postData.taricCode;
        const goodsFormGroup = postData.goodsFormGroup;
        const goodsPrivateGroup = postData.goodsPrivateGroup;
        const deleteCurrent = postData.deleteCurrent;

        return this.dbQuery.deleteDescription(comCode, taricCode, id).pipe(
            tap((descriptions: RetrievedDescription[]) => {
                if (deleteCurrent) {
                    const index = goodsFormGroup.get(this.itemNumber).value - 1;

                    const description: RetrievedDescription = {
                        id: null,
                        officeId: this.officeId,
                        comCode,
                        taricCode,
                        description: null,
                        descriptionEn: null,
                        botanicalName: null,
                        lastUsed: 1
                    };

                    //delete Current Description
                    this.updateDescription(goodsFormGroup, null);
                    //update observable
                    this.updateComCodeItem(index, goodsPrivateGroup, description);
                }

                console.log('description updated', descriptions);
                this.toastrService.success('', 'Η περιγραφή διαγράφηκε.', {
                    timeOut: 3000
                });
            }),
            take(1)
        );
    }

    private getLastUsedDescription(allDescriptions: RetrievedDescription[], goodsFormGroup: FormGroup): RetrievedDescription {
        const result = allDescriptions.filter(result => result.lastUsed === 1);

        const descResult: RetrievedDescription = result.length > 0 ? result[0] : allDescriptions[0];

        this.updateDescription(goodsFormGroup, descResult?.description);

        return descResult;
    }

    updateLastUsedDescription(goodsIndex: number, goodsPrivateGroup: FormGroup, description: RetrievedDescription) {
        const postData = {
            comCode: description.comCode,
            taricCode: description.taricCode,
            id: description.id,
            lastUsed: true
        };

        this.dbQuery
            .updateDescription(postData)
            .pipe(take(1))
            .subscribe(() => {
                this.updateComCodeItem(goodsIndex, goodsPrivateGroup, description);
            });
    }

    updateComCodeItem(index: number, goodsPrivateGroup: FormGroup, description: RetrievedDescription, product?: ProductNode) {
        const comCodeService = this.injector.get(ComCodeService);

        comCodeService.updateComCodeItemFromDescService(index, goodsPrivateGroup, description, product);
    }

    setCodeDesc(declarationForm: FormGroup, params: CodeDescParams) {
        console.log('CODEDESC on declFormService params', params);

        if (params) {
            console.log('CODEDESC on declForm Service if params', params);
            const index = params.index;
            const type = params.type;
            const product = params.product;
            const code = params.code;
            const desc = params.description;

            const currentGoods = this.cf.getCurrentGoods(declarationForm)
            const goodsPrivate = declarationForm.get('_PRIVATE').get('goodsPrivate') as FormArray

            const goodsFormGroup = currentGoods.at(index) as FormGroup;
            const goodsPrivateGroup = goodsPrivate.at(index) as FormGroup;

            if (type === 'code') {
                this.updateComCode(goodsFormGroup, goodsPrivateGroup, product, true);
                //if we set only the description, we get the code from our form
            } else if (type === 'description') {

                const comTaric = this.cf.getComTaric(goodsFormGroup, this.subdomain);

                const comCode = comTaric.comCode;
                const taricCode = comTaric.taricCode;

                this.updateDescription(goodsFormGroup, desc, true);
                this.updateStoredDescription(index, goodsPrivateGroup, comCode, taricCode, desc, true).subscribe();
                this.mainBroadcaster.updateCodeDesc(null);
                //here we get the code from TARIC
            } else {
                const comCode = code.substring(0, 8);
                const taricCode = code.substring(8, 10);

                this.updateBoth(goodsFormGroup, goodsPrivateGroup, product, desc);
                this.updateStoredDescription(index, goodsPrivateGroup, comCode, taricCode, desc, true).subscribe();
                this.mainBroadcaster.updateCodeDesc(null);
            }
        }
    }



}
