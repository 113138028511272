<div *ngIf="results && results.length > 0">
    <h4 style="font-weight: 600">{{tableTitle}}</h4>
</div>

<table class="totals-table" *ngIf="results && results.length > 0">
    <colgroup *ngIf="tableRows===6">
        <col style="width: 8%" />
        <col style="width: 15%" />
        <col style="width: 12%" />
        <col style="width: auto" />
        <col style="width: 15%" />
        <col style="width: 15%" />
    </colgroup>
    <colgroup *ngIf="tableRows===4">
        <col style="width: 8%" />
        <col style="width: auto" />
        <col style="width: 15%" />
        <col style="width: 15%" />
    </colgroup>

    <thead>
        <th>Καταγωγή</th>
        <th *ngIf="tableRows===6">Τύπος Μέτρου</th>
        <th *ngIf="tableRows===6">Κανονισμός</th>
        <th>Συντελεστής Δασμού</th>
        <th>Κωδικός Μηχανογράφησης</th>
        <th>Σύνολο Δασμού</th>
    </thead>
    <tfoot>
        <tr>
            <td *ngIf="tableRows===6" colspan="4" rowspan="4"></td>
            <td *ngIf="tableRows===4" colspan="2" rowspan="4"></td>
        </tr>
        <tr>
            <td class="totals">Σύνολο εκτός ΦΠΑ:</td>
            <td class="totals">{{ totals.sumWithoutVat }} EUR</td>
        </tr>
        <tr>
            <td class="totals">ΦΠΑ:</td>
            <td class="totals">{{ totals.vat }} EUR</td>
        </tr>
        <tr>
            <td class="totals">Σύνολο:</td>
            <td class="totals">{{ totals.total }} EUR</td>
        </tr>
    </tfoot>
    <tbody>
        <tr *ngFor="let result of results; let i = index">
            <td style="text-align: center">
                <img *ngIf="result.img" src="{{imgPath}}{{ result.img }}" alt="{{result.img}}" />
            </td>

            <td *ngIf="tableRows===6" style="text-align: left">{{ result.measureType }}</td>

            <td *ngIf="tableRows===6" style="text-align: center">
                <a href="{{ result.regulation.link }}">{{ result.regulation.value }}</a>
            </td>

            <ng-container *ngIf="tableRows===6">

                <td *ngIf="result.measureTypeCode !== 'RAE'" style="text-align: left">
                    {{ result.dutyRate.taxRateStr }}
                </td>
                <td *ngIf="result.measureTypeCode === 'RAE'" style="text-align: left">
                    {{ results[i + 1].dutyRate.taxRateStr }}
                </td>
            </ng-container>

            <ng-container *ngIf="tableRows===4">

                <td *ngIf="result.measureTypeCode !== 'RAE'" style="text-align: left">
                    {{ result.dutyRate.taxRateStr }}
                </td>
                <td *ngIf="result.measureTypeCode === 'RAE'" style="text-align: left">
                    {{ results[i + 1].dutyRate.taxRateStr }}
                </td>

            </ng-container>


            <td style="text-align: center">{{ result.taxCode }}</td>
            <td style="text-align: right">{{ result.dutyAmount }} EUR</td>
        </tr>
    </tbody>
</table>