import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddDelButtonsComponent } from './add-del-buttons.component';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';

@NgModule({
    declarations: [AddDelButtonsComponent],
    imports: [CommonModule, MaterialModule],
    exports: [AddDelButtonsComponent]
})
export class AddDelButtonsModule { }
