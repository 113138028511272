import { LeadingZero3digitsPipeModule } from './../../../services/pipes/leadingZero3digits.pipe';
import { NgModule } from '@angular/core';
import { TaricComponent } from './taric.component';
import { TaricTreeComponent } from './taric-tree/taric-tree.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { TaricResultsTableComponent } from './taric-results-table/taric-results-table.component';
import { TariffsComponent } from './tariffs/tariffs.component';
import { TariffsDialogComponent } from './tariffs-dialog/tariffs-dialog.component';
import { TariffInfoDialogComponent } from './tariffs/tariff-info-dialog/tariff-info-dialog.component';
import { CodeDescDialogComponent } from './code-desc-dialog/code-desc-dialog.component';
import { TaricActionsMenuComponent } from './taric-actions-menu/taric-actions-menu.component';
import { TaricResolver } from './taric.resolver';
import { TaricService } from './taric.service';
import { DirectivesModule } from 'app/services/directives/directives.module';
import { LoadingModule } from 'app/main/main-building-blocks/loading/loading.module';
import { AddWhiteSpaceModule } from 'app/services/pipes/addWhiteSpace.pipe';

@NgModule({
    declarations: [
        TaricComponent,
        TaricTreeComponent,
        TaricResultsTableComponent,
        TariffsComponent,
        TariffsDialogComponent,
        TariffInfoDialogComponent,
        CodeDescDialogComponent,
        TaricActionsMenuComponent
    ],
    imports: [
        //  RouterModule.forChild(routes),
        FuseSharedModule,
        MaterialModule,
        ComboBoxModule,
        DirectivesModule,
        LoadingModule,
        AddWhiteSpaceModule,
        LeadingZero3digitsPipeModule
    ],
    providers: [TaricService, TaricResolver, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }]
})
export class TaricModule { }
