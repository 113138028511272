import { TransactionCategoryModel } from "app/main/businesses/transactions/_models/transaction-category/transaction-category.model";

export class TransactionLineItemModel {

    itemType: 'DEBIT' | 'CREDIT';
    amount: string;
    category: TransactionCategoryModel;


    constructor(value?: TransactionLineItemModel) {

        this.itemType = value?.itemType || null;
        this.amount = value?.amount || null;
        this.category = value?.category || null;


    }
}