import { FormControl, FormGroup, FormArray, Validators, AbstractControl, FormsModule } from '@angular/forms';
import { ComCodForm } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm-form.model';
import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { eAitisiGooIteGdsModel } from './gooitegds.model';

export class eAitisiGooIteGdsForm {
    [key: string]: AbstractControl;
    GooDesGDS23: FormControl;
    StaValAmoGDI1: FormControl;
    COMCODGODITM: FormArray;

    constructor(goods: eAitisiGooIteGdsModel) {
        //console.trace("Who creates GooItegds");
        // console.log("___GooIteGdsFormModel constructor", goods);
        this.GooDesGDS23 = new FormControl(goods.GooDesGDS23, {
            updateOn: 'blur'
        });
        this.StaValAmoGDI1 = new FormControl(goods.StaValAmoGDI1, {
            updateOn: 'blur'
        });
        this.COMCODGODITM = new FormArray([]);

        if (goods.COMCODGODITM) {
            goods.COMCODGODITM.forEach((comcod: ComCodModel) => {
                this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel(comcod))));
            });
        } else {
            this.COMCODGODITM.push(new FormGroup(new ComCodForm(new ComCodModel())));
        }
    }
}
