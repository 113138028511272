import { Injectable } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { BeerCalculationDataForm, BeerForm } from "app/main/apps/excise-helper-tables/beer/_models/beer-form.model";
import { BeerCalculationDataModel, BeerModel } from "app/main/apps/excise-helper-tables/beer/_models/beer.model";
import { BehaviorSubject, Observable, Subscription, combineLatest, concatMap, filter, forkJoin, of, startWith, switchMap, take, tap } from "rxjs";
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { StorageService } from 'app/core/services/storage/storage.service';
import { ApiEndPoints, ApiService } from "app/services/api.service";
import { BeerParameters } from "app/main/apps/excise-helper-tables/beer/beer-defk-create.service";
import { DEFKDeclarationForm } from "app/main/apps/excise/defk/_models/EF15A/declaration-form.model";
import { OpenDialogsService } from "app/services/openDialogs.service";
import { ContactsService } from "app/main/apps/contacts/contacts.service";
import { NationalProduct, genericItem } from "app/model/api-model";

export type ProducerTime = {
    producerName: string;
    days: number;
    startTime: Date;
    endTime: Date;
};

@Injectable()
export class BeerService {

    private beerForm: BehaviorSubject<FormGroup<BeerForm>>

    nationalProducts: NationalProduct[][] = [];

    beerForm$: Observable<FormGroup<BeerForm>>

    private filteredNationalProducts: BehaviorSubject<NationalProduct[][]>;
    filteredNationalProducts$: Observable<NationalProduct[][]>

    quantitySubs: Subscription[] = [];
    platoSubs: Subscription[] = [];
    produceValueSubs: Subscription[] = [];
    taricAndPlatoSubs: Subscription[] = [];

    params: BeerParameters;
    products: genericItem[] = [];


    constructor(private cf: ExtCustomFunctions, private storageService: StorageService, private dbQuery: ApiService, private openDialogsService: OpenDialogsService, private contactService: ContactsService) {

        this.beerForm = new BehaviorSubject(new FormGroup(new BeerForm(new BeerModel())))
        this.filteredNationalProducts = new BehaviorSubject([[]])
        this.beerForm$ = this.beerForm.asObservable()
        this.filteredNationalProducts$ = this.filteredNationalProducts.asObservable()

        forkJoin([
            this.getBeerParams().pipe(take(1)),
            this.getBeerProducts().pipe(take(1))
        ])
            .subscribe(([params, products]) => {

                this.params = params[0]
                this.products = products
                this.beerForm.next(new FormGroup(new BeerForm(new BeerModel())))

            })
    }
    getBeerProducts() {
        return this.dbQuery.get_options(ApiEndPoints.efk_beer_products).pipe(take(1), tap((products) => {
            this.products = products
            console.log("BeerService products", this.products)
        }))
    }
    getBeerParams() {
        return this.dbQuery.get_options(ApiEndPoints.efk_beer_params).pipe(take(1), tap((res) => {
            this.params = res[0]
            console.log("BeerService params", this.params)
        }))
    }
    getNationalProducts(taricCode: string, plato: string) {
        return this.dbQuery.getNationalProducts({ taricCode, plato }).pipe(tap(res => {
            console.log("BEER NAtionalProducts", res)
        }))
    }
    setLocalStorage(value: BeerModel) {
        this.storageService.setLocalObject('beer', value);
    }
    patchPrivateCtrls(defkDeclarationForm: FormGroup<DEFKDeclarationForm>, value: BeerModel) {
        defkDeclarationForm.get('_PRIVATE').get('beer').setValue(value)
    }

    loadLocalStorage(savedForm: BeerModel) {
        let form: FormGroup<BeerForm>;

        if (savedForm) {
            form = new FormGroup(new BeerForm(new BeerModel(savedForm)));
        }
        else {
            const localValue = this.storageService.getLocalObject('beer') as BeerModel;

            form = new FormGroup(new BeerForm(new BeerModel(localValue)));
        }
        this.beerForm.next(form);
    }

    addProducer() {
        const form = this.beerForm.getValue()
        const beerCalculationData = form.get('beerCalculationData') as FormArray
        const masterProduceValue = form.get('masterProduceValue').value

        const newGroup = new FormGroup(new BeerCalculationDataForm(new BeerCalculationDataModel()))
        if (masterProduceValue && masterProduceValue !== 0) {
            newGroup.get('produceValue').setValue(masterProduceValue)
        }
        beerCalculationData.push(newGroup)

        this.subToQuantityValueChanges(newGroup)
        this.subToPlatoValueChanges(newGroup)
        this.subToProduceValueValueChanges(newGroup)
        this.subToTaricAndPlatoValueChanges(newGroup, beerCalculationData.length - 1)

    }

    deleteProducer(index: number) {
        const form = this.beerForm.getValue()
        const beerCalculationData = form.get('beerCalculationData') as FormArray
        beerCalculationData.removeAt(index)
        //this.beerForm.next(form)
        this.quantitySubs.splice(index, 1);
        this.platoSubs.splice(index, 1);
        this.produceValueSubs.splice(index, 1);
        this.taricAndPlatoSubs.splice(index, 1);

    }

    calculate(group: FormGroup) {

        this.calculatePlatoPer100L(group)
        this.calculateStatisticValue(group)
        this.calculateEFK(group)
        this.calculateOtherTaxes(group)
        this.calculateVat(group)
        this.calculateTotalTax(group)
        this.calculateTotals()
    }

    calculatePlatoPer100L(group: FormGroup) {
        const plato = group.get('plato').value ?? 0;
        const litres = group.get('quantity').value ?? 0;

        let result = 0;
        if (litres !== 0) {
            result = plato * (litres / 100);
        }
        // If litres is zero, result remains zero (or any default you want)

        group.get('platoPer100L').setValue(this.cf.formatDecimalString(result, 2));
    }

    calculateStatisticValue(group: FormGroup) {
        const litres = group.get('quantity').value ?? 0;
        const produceValue = group.get('produceValue').value ?? 0;

        let result = 0;
        if (litres !== 0) {
            result = litres * produceValue;
        }
        // If litres is zero, result remains zero (or any default you want)

        group.get('statisticValue').setValue(this.cf.formatDecimalString(result, 2));
    }


    calculateEFK(group: FormGroup) {

        let result;

        const taxUnits = group.get('platoPer100L').value ?? 0;
        const productDuty = this.beerForm.getValue().get('productDuty').value ?? 0;

        if (taxUnits) {
            result = taxUnits * productDuty;
        }
        else {
            result = null;
        }

        group.get('efk').setValue(this.cf.formatDecimalString(result, 2))
    }

    calculateOtherTaxes(group: FormGroup) {

        const efk = group.get('efk').value ?? 0;
        const eteppaAmount = efk * this.params.eteppaContribution;
        const eteppaStampTaxAmount = eteppaAmount * this.params.eteppaStampTax;
        const ogaAmount = eteppaStampTaxAmount * this.params.ogaContribution;

        group.get('eteppaContrib').setValue(this.cf.formatDecimalString(eteppaAmount, 2))
        group.get('eteppaStampTax').setValue(this.cf.formatDecimalString(eteppaStampTaxAmount, 2))
        group.get('ogaContrib').setValue(this.cf.formatDecimalString(ogaAmount, 2))

    }

    calculateVat(group: FormGroup) {


        const efk = +group.get('efk').value ?? 0;
        const eteppaAmount = +group.get('eteppaContrib').value ?? 0;
        const eteppaStampTaxAmount = +group.get('eteppaStampTax').value ?? 0;
        const ogaAmount = +group.get('ogaContrib').value ?? 0;
        const statisticValue = +group.get('statisticValue').value ?? 0;
        const vatRate = +this.beerForm.getValue().get('vatRate').value ?? 0;

        console.log("BeerService calculateVat", vatRate)
        console.log("BeerService calculateVat efk", efk)
        console.log("BeerService calculateVat eteppaAmount", eteppaAmount)
        console.log("BeerService calculateVat eteppaStampTaxAmount", eteppaStampTaxAmount)
        console.log("BeerService calculateVat ogaAmount", ogaAmount)
        console.log("BeerService calculateVat statisticValue", statisticValue)
        const vatTaxBase = efk + eteppaAmount + eteppaStampTaxAmount + ogaAmount + statisticValue;
        const vatAmount = vatTaxBase * vatRate;

        console.log("BeerService calculateVat vatAmount", vatAmount)

        group.get('vatTaxBase').setValue(this.cf.formatDecimalString(vatTaxBase, 2))
        group.get('vat').setValue(this.cf.formatDecimalString(vatAmount, 2))

    }
    calculateTotalTax(group: FormGroup) {

        const efk = +group.get('efk').value ?? 0;
        const vat = +group.get('vat').value ?? 0;
        const eteppaAmount = +group.get('eteppaContrib').value ?? 0;
        const eteppaStampTaxAmount = +group.get('eteppaStampTax').value ?? 0;
        const ogaAmount = +group.get('ogaContrib').value ?? 0;
        const amountWithoutVat = efk + eteppaAmount + eteppaStampTaxAmount + ogaAmount

        const totalTax = amountWithoutVat + vat;

        group.get('amountWithoutVat').setValue(this.cf.formatDecimalString(amountWithoutVat, 2))
        group.get('totalTax').setValue(this.cf.formatDecimalString(totalTax, 2))
    }


    calculateTotals() {
        const form = this.beerForm.getValue();

        const beerCalculationData = form.get('beerCalculationData') as FormArray;

        const totals = beerCalculationData.controls.reduce((acc, group: FormGroup) => {
            acc.totalEfk += +group.get('efk').value || 0;
            acc.totalEteppa += +group.get('eteppaContrib').value || 0;
            acc.totalEteppaStamp += +group.get('eteppaStampTax').value || 0;
            acc.totalOga += +group.get('ogaContrib').value || 0;
            acc.totalVat += +group.get('vat').value || 0;
            acc.totalGrand += +group.get('totalTax').value || 0;
            return acc;
        }, {
            totalEfk: 0,
            totalEteppa: 0,
            totalEteppaStamp: 0,
            totalOga: 0,
            totalVat: 0,
            totalGrand: 0
        });

        const beerTotals = form.get('beerTotals') as FormGroup;

        beerTotals.get('totalEfk').setValue(this.cf.formatDecimalString(totals.totalEfk, 2));
        beerTotals.get('totalEteppa').setValue(this.cf.formatDecimalString(totals.totalEteppa, 2));
        beerTotals.get('totalEteppaStamp').setValue(this.cf.formatDecimalString(totals.totalEteppaStamp, 2));
        beerTotals.get('totalOga').setValue(this.cf.formatDecimalString(totals.totalOga, 2));
        beerTotals.get('totalVat').setValue(this.cf.formatDecimalString(totals.totalVat, 2));
        beerTotals.get('totalGrand').setValue(this.cf.formatDecimalString(totals.totalGrand, 2));

    }


    setDefaultNationalProduct(group: FormGroup, products: NationalProduct[]) {

        const nationalProduct = group.get('nationalProduct') as FormControl

        if (products.length > 0) {

            const taricCode = group.get('taricCode').value
            const plato = group.get('plato').value

            console.log("BeerService setDefaultNationalProduct", taricCode, plato)

            const product = products.find((product) => product.description.includes(taricCode) && product.plato == plato);
            const natCode = product ? product.natCode : null;

            this.setAddTaricCode(group, product)

            nationalProduct.setValue(natCode)
        }
        else {
            nationalProduct.setValue(null)
        }
    }

    setAddTaricCode(group: FormGroup, product: NationalProduct) {
        if (product?.addNatCode) {
            group.get('addTaricCode').setValue(product.addNatCode)
        }
        else {
            group.get('addTaricCode').setValue(this.beerForm.getValue().get('addTaricCode').value)
        }
    }

    subToQuantityValueChanges(group: FormGroup<BeerCalculationDataForm>) {

        const quantitySub = group.get('quantity').valueChanges.subscribe((value) => {
            console.log("BeerService valueChangesRace subToQuantityChanges")

            console.log('BeerService subToQuantityChanges', value)

            this.calculate(group)

        })

        this.quantitySubs.push(quantitySub)
    }
    subToPlatoValueChanges(group: FormGroup<BeerCalculationDataForm>) {

        const platoSub = group.get('platoExact').valueChanges.subscribe((value) => {
            console.log("BeerService valueChangesRace subToPlatoValueChanges")

            console.log('BeerService subToWineGrapesValueChanges', value)

            //Round plato to its nearest integer
            group.get('plato').setValue(Math.round(+value))

            this.calculate(group)
        })

        this.platoSubs.push(platoSub)
    }

    subToProduceValueValueChanges(group: FormGroup<BeerCalculationDataForm>) {

        const produceValueSub = group.get('produceValue').valueChanges.subscribe((value) => {
            console.log("BeerService valueChangesRace subToProduceValueValueChanges")

            console.log('BeerService subToNonWineGrapesValueChanges', value)

            this.calculate(group)

        })

        this.produceValueSubs.push(produceValueSub)
    }


    subToSmallProducerValueChanges(): Observable<number> {

        const form = this.beerForm.getValue()
        const smallProducer = form.get('smallProducer') as FormControl

        return smallProducer.valueChanges.pipe(startWith(smallProducer.value), tap((value) => {
            console.log("BeerService valueChangesRace subToSmallProducerValueChanges")

            const productDuty = value === "1" ? this.params.productDutyReduced : this.params.productDutyRegular
            const addTaricCode = value === "1" ? '1336' : '1310'
            this.beerForm.getValue().get('productDuty').setValue(productDuty)
            this.beerForm.getValue().get('addTaricCode').setValue(addTaricCode)

            const beerCalculationData = form.get('beerCalculationData') as FormArray

            beerCalculationData.controls.forEach((group: FormGroup, index: number) => {
                const taricCode = group.get('taricCode').value
                const plato = group.get('plato').value

                const product = this.nationalProducts[index]?.find((product) => product.description.includes(taricCode) && product.plato == plato);

                this.setAddTaricCode(group, product)
                this.calculate(group)

            })

        }))

    }

    subToReducedVatValueChanges(): Observable<number> {

        const form = this.beerForm.getValue()
        const reducedVat = form.get('reducedVat') as FormControl

        return reducedVat.valueChanges.pipe(startWith(reducedVat.value), tap((value) => {
            console.log("BeerService valueChangesRace subToReducedVatValueChanges")

            const vatRate = value === "1" ? this.params.vatReduced : this.params.vatRegular

            console.log("beerService subToReducedVatValueChanges", vatRate)

            this.beerForm.getValue().get('vatRate').setValue(vatRate)

            const beerCalculationData = form.get('beerCalculationData') as FormArray

            beerCalculationData.controls.forEach((group: FormGroup) => {
                this.calculateVat(group)
                this.calculateTotalTax(group)
                this.calculateTotals()
            })

        }))

    }

    subToTaricAndPlatoValueChanges(group: FormGroup, index) {

        const taricCode$ = group.get('taricCode').valueChanges.pipe(startWith(group.get('taricCode').value))
        const plato$ = group.get('plato').valueChanges.pipe(startWith(group.get('plato').value))

        const taricPlatoSub = combineLatest([taricCode$, plato$]).pipe(
            filter(([taricCode, plato]) => Boolean(taricCode)),
            switchMap(([taricCode, plato]) => this.getNationalProducts(taricCode, plato)),
            tap((products: NationalProduct[]) => {
                this.nationalProducts[index] = products
                this.filteredNationalProducts.next(this.nationalProducts)

                this.setDefaultNationalProduct(group, products)
            })
        ).subscribe()

        this.taricAndPlatoSubs.push(taricPlatoSub)
    }

    subToMasterProduceValueValueChanges(): Observable<number> {

        const form = this.beerForm.getValue()
        const produceValue = form.get('masterProduceValue') as FormControl

        return produceValue.valueChanges.pipe(tap((value) => {
            console.log("BeerService valueChangesRace subToMasterProduceValueValueChanges")

            const beerCalculationData = form.get('beerCalculationData') as FormArray

            beerCalculationData.controls.forEach((group: FormGroup) => {

                const currentValue = group.get('produceValue').value
                group.get('produceValue').setValue(value)
                //this.calculateTotals()
            })

        }))

    }

    subToAddTaricCodeValueChanges(): Observable<number> {

        const form = this.beerForm.getValue()
        const addTaricCode = form.get('addTaricCode') as FormControl

        return addTaricCode.valueChanges.pipe(tap((value) => {
            console.log("BeerService valueChangesRace subToAddTaricCodeValueChanges")

            const beerCalculationData = form.get('beerCalculationData') as FormArray

            beerCalculationData.controls.forEach((group: FormGroup) => {
            })

        }))

    }



    createContact() {
        return this.openDialogsService
            .createContactDialog('domestic', true)
            .pipe(
                concatMap(dialogResults => {
                    console.log('AFTER CONTACT CREATE value', dialogResults);
                    if (dialogResults) {
                        return this.contactService.createContact(dialogResults.contactForm, dialogResults.contactType);
                    }
                    else {
                        return of(null);
                    }
                })
            )

    }

}
