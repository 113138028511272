import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';
import { ApiNodeService } from 'app/services/api.node.service';
import { InvoiceParsingResultsForm, InvoiceParsingResultsModel } from './_models';
export interface CustomerInvoiceParsingResults {

    trader?: string;
    consignee?: string;
    invoice?: string;
    deliveryTerms?: string;
    relevant?: { documentType: string, documentNumber: string };
    destinationCountry: string;
    transport?: string;
    goods?: {
        product?: string;
        items?: CustomerInvoiceParsingGoods[];
    }[];
    totals?: CustomerInvoiceParsingGoods;

}
export interface CustomerInvoiceParsingGoods {
    pal?: string;
    packs?: string;
    gross?: string;
    net?: string;
    value?: string;

}
@Injectable()
export class CustomerInvoiceParsingService {

    invoiceParsingForm: BehaviorSubject<FormGroup | null>
    invoiceParsingForm$: Observable<FormGroup | null>
    enableButtons: BehaviorSubject<boolean | null>
    enableButtons$: Observable<boolean | null>

    invoiceParsingResultsForm: BehaviorSubject<FormGroup | null>
    invoiceParsingResultsForm$: Observable<FormGroup | null>


    private _destroy: Subject<void> = new Subject()
    constructor(private fb: FormBuilder, private apiLocalService: ApiNodeService, private mainBroadcaster: MainBroadcasterService
    ) {
        this.createForm()
        this.createResultsForm()
        this.enableButtons = new BehaviorSubject(false)
        this.invoiceParsingForm$ = this.invoiceParsingForm.asObservable();
        this.invoiceParsingResultsForm$ = this.invoiceParsingResultsForm.asObservable();
        this.enableButtons$ = this.enableButtons.asObservable();
    }

    ngOnDestroy() {
        this._destroy.next()
        this._destroy.complete()
    }
    createForm() {
        const form = this.fb.group({
            afm: null,
            invoicePage: null,
            fileToUpload: null
        });

        this.invoiceParsingForm = new BehaviorSubject(form);
    }

    createResultsForm() {

        const form = new FormGroup(new InvoiceParsingResultsForm(new InvoiceParsingResultsModel()))

        this.invoiceParsingResultsForm = new BehaviorSubject(form)
    }

    submit(): Observable<any> {

        this.mainBroadcaster.updateLoading(true)
        console.log("submit")
        const form = this.invoiceParsingForm.getValue()
        console.log("submit", form.value)


        // this.processing.next(true);

        const afm = form.get('afm').value;
        const invoicePage = form.get('invoicePage').value;
        const file = form.get('fileToUpload').value;

        const formData = new FormData();

        formData.set('afm', afm);
        formData.append('invoicePage', invoicePage); //OTH other type (include all)
        formData.append('fileToUpload', file);

        console.log("formData", formData)
        return this.apiLocalService.invoiceParse(formData)
    }


    pasteResults() {

    }
    updateForm(value: FormGroup) {
        this.invoiceParsingForm.next(value)
    }
    updateEnableButtons(value) {
        this.enableButtons.next(value)
    }
}
