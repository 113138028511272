import { countriesItem } from 'app/model/api-model';
import { genericItem } from './../../../model/api-model';
import { Injectable } from '@angular/core';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';

@Injectable()
export class FormFieldFunctions {
    constructor(private cf: ExtCustomFunctions) { }


    /**
     * This is the main function. It prepares the search values and filters the options based on the input. If duplicate results are found, it removes them.
     * @param searchValue 
     * @param options 
     * @param key0 
     * @param key1 
     * @returns 
     */
    autocompleteFilter(searchValue: string | genericItem | countriesItem | null, options: any[], key0: string, key1?: string) {
        const q = this.prepareSearchValue(searchValue, key0, key1);
        const r = this.charIsLetter(q?.substring(0, 1)) ? this.replaceCharacters(q) : q;

        const results = options.reduce((res, option) => {
            if (!option) return res;

            const [option1, option2] = this.getOptionKeys(option, key0, key1);

            // Search both keys
            if (!!key1 && !!key0) {
                return this.filterByBothKeys(res, option, option1, option2, q, r);
            }
            // Search only by the first key
            else {
                return this.filterByKey0(res, option, option1, q, r);
            }
        }, [[], [], [], [], [], [], [], [], [], [], [], []]);

        return this.removeDuplicates(results, key0, key1);
    }

    /**
     * This function prepares the search value by removing any accents and converting the string to lower case. It also handles the case when the search value is an object.
     * @param searchValue 
     * @param key0 
     * @param key1 
     * @returns 
     */
    prepareSearchValue(searchValue: string | object | null, key0: string, key1: string) {
        let q: string;

        if (typeof searchValue === 'string') {
            q = this.cf.removeAccents(searchValue.toLowerCase());
        } else if (searchValue && typeof searchValue === 'object' && Object.keys(searchValue).length) {
            q = this.cf.removeAccents(searchValue[key0]?.toLowerCase());
        }
        return q;
    }
    /**
     * This function retrieves the values of the object keys, removes any accents and converts the values to lowercase.
     * @param option 
     * @param key0 
     * @param key1 
     * @returns 
     */
    getOptionKeys(option: object, key0: string, key1?: string) {
        const option1 = this.cf.removeAccents(option[key0]?.toLowerCase());
        const option2 = key1 ? this.cf.removeAccents(option[key1]?.toLowerCase()) : '';
        return [option1, option2];
    }

    /**
     * This function handles filtering when two keys are provided. It delegates to filterByKey() to filter options based on each key.
     * @param res 
     * @param option 
     * @param option1 
     * @param option2 
     * @param q 
     * @param r 
     * @returns 
     */
    filterByBothKeys(res: any[], option: any, option1: string, option2: string, q: string, r: string) {
        this.filterByKey(res, option, option1, q, 0);
        this.filterByKey(res, option, option2, q, 3);
        this.filterByKey(res, option, option1, r, 6);
        this.filterByKey(res, option, option2, r, 9);

        return res;
    }

    /**
     * This function handles filtering when only one key is provided. It delegates to filterByKey() to filter options based on the key.
     * @param res 
     * @param option 
     * @param option1 
     * @param q 
     * @param r 
     * @returns 
     */
    filterByKey0(res: any[], option: any, option1: string, q: string, r: string) {
        this.filterByKey(res, option, option1, q, 0);
        this.filterByKey(res, option, option1, r, 3);

        return res;
    }

    /**
     * This function checks if the option's key exactly matches, starts with, or contains the term. If a match is found, it pushes the option to the appropriate result array.
     * @param res 
     * @param option 
     * @param optionKey 
     * @param term 
     * @param offset 
     */
    filterByKey(res: any[], option: any, optionKey: string, term: string, offset: number) {
        // exact match
        if (optionKey === term) {
            res[offset].push(option);
        }
        // starts with
        else if (optionKey?.indexOf(term) === 0) {
            res[offset + 1].push(option);
        }
        // contains
        else if (optionKey?.indexOf(term) > 0) {
            res[offset + 2].push(option);
        }
    }


    /**
     * This function removes any duplicate items from the results, maintaining the order of preference.
     * @param results 
     * @param key0 
     * @param key1 
     * @returns 
     */
    removeDuplicates(results: any[], key0: string, key1?: string) {
        const added = new Set();
        const orderedResults = [];

        // Set the order array based on whether key1 is defined
        const order = key1 ? [0, 6, 3, 9, 1, 7, 4, 10, 2, 8, 5, 11] : [0, 3, 1, 4, 2, 5];

        for (const i of order) {
            for (const item of results[i]) {
                if (!added.has(item)) {
                    orderedResults.push(item);
                    added.add(item);
                }
            }
        }

        return orderedResults;
    }

    /**
     * This function is used to replace English characters with their Greek counterparts and vice versa, depending on the first character of the input string.
     * @param string 
     * @returns 
     */
    replaceCharacters(string) {
        let r: string;
        const enToGr = {
            a: 'α',
            b: 'β',
            c: 'ψ',
            d: 'δ',
            e: 'ε',
            f: 'φ',
            g: 'γ',
            h: 'η',
            i: 'ι',
            j: 'ξ',
            k: 'κ',
            l: 'λ',
            m: 'μ',
            n: 'ν',
            o: 'ο',
            p: 'π',
            q: ';',
            r: 'ρ',
            s: 'σ',
            t: 'τ',
            u: 'θ',
            v: 'ω',
            w: 'ς',
            x: 'χ',
            y: 'υ',
            z: 'ζ'
        };

        const grToEn = {
            α: 'a',
            β: 'b',
            ψ: 'c',
            δ: 'd',
            ε: 'e',
            φ: 'f',
            γ: 'g',
            η: 'h',
            ι: 'i',
            ξ: 'j',
            κ: 'k',
            λ: 'l',
            μ: 'm',
            ν: 'n',
            ο: 'o',
            π: 'p',
            ρ: 'r',
            σ: 's',
            τ: 't',
            θ: 'u',
            ω: 'v',
            ς: 'w',
            χ: 'x',
            υ: 'y',
            ζ: 'z'
        };

        // eslint-disable-next-line no-prototype-builtins
        if (enToGr.hasOwnProperty(string.substr(0, 1))) {
            r = string.replace(/[abcdefghijklmnopqrstuvwxyz]/g, m => enToGr[m]);
        } else {
            r = string.replace(/[αβγδεζηθικλμνξοπρσςτυφχψω]/g, m => grToEn[m]);
        }

        return r;
    }

    /**
     * This function checks if a character is a letter, accepting both English and Greek alphabets.
     * @param char 
     * @returns 
     */
    charIsLetter(char) {
        if (typeof char !== 'string') {
            return false;
        }
        return /^[a-ω]+$/i.test(char);
    }
}
