import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { EMCSVehicleInventoryForm } from "app/main/apps/excise/dao/_models/vehicle-inventory/vehinv-form.model";
import { EMCSVehicleInventoryModel } from "app/main/apps/excise/dao/_models/vehicle-inventory/vehinv.model";
import { DEFKVehicleDetailsModel } from "./vehicle-details.model";

export class DEFKVehicleDetailsForm {
    [key: string]: AbstractControl;

    FrameNumber: FormControl;
    CarKind: FormControl;
    ImporterCodeOrBrandName: FormControl;
    FirstAllowanceDate: FormControl;
    VehicleFactoryType: FormControl;
    VehicleUsedFlag: FormControl;
    EngineCapacity: FormControl;
    EngineType: FormControl;
    FuelType: FormControl;
    MakeYear: FormControl;
    VehicleModelName: FormControl;
    ImportedDate: FormControl;
    CountryLast: FormControl;
    Mileage: FormControl;
    CarbonDioxideEmissions: FormControl;
    ApprovalCodeVehicle: FormControl;
    ApprovalCodeFactory: FormControl;
    ApprovalCodeType: FormControl;
    VehicleRebateFlag: FormControl;
    RebatedVehicleRegistrationPlate: FormControl;
    VehicleInventory: FormArray;


    constructor(value: DEFKVehicleDetailsModel) {

        this.FrameNumber = new FormControl(value.FrameNumber, { validators: Validators.required });
        this.CarKind = new FormControl(value.CarKind, { updateOn: 'change', validators: Validators.required });
        this.ImporterCodeOrBrandName = new FormControl(value.ImporterCodeOrBrandName, { updateOn: 'change', validators: Validators.required });
        this.FirstAllowanceDate = new FormControl(value.FirstAllowanceDate);
        this.VehicleFactoryType = new FormControl(value.VehicleFactoryType, { validators: Validators.required });
        this.VehicleUsedFlag = new FormControl(value.VehicleUsedFlag, { validators: Validators.required });
        this.EngineCapacity = new FormControl(value.EngineCapacity, { validators: Validators.required });
        this.EngineType = new FormControl(value.EngineType, { validators: Validators.required });
        this.FuelType = new FormControl(value.FuelType, { validators: Validators.required });
        this.MakeYear = new FormControl(value.MakeYear, { validators: Validators.required });
        this.VehicleModelName = new FormControl(value.VehicleModelName, { validators: Validators.required });
        this.ImportedDate = new FormControl(value.ImportedDate, { validators: Validators.required });
        this.CountryLast = new FormControl(value.CountryLast, { updateOn: 'change' });
        this.Mileage = new FormControl(value.Mileage);
        this.CarbonDioxideEmissions = new FormControl(value.CarbonDioxideEmissions, { validators: Validators.required });
        this.ApprovalCodeVehicle = new FormControl(value.ApprovalCodeVehicle);
        this.ApprovalCodeFactory = new FormControl(value.ApprovalCodeFactory);
        this.ApprovalCodeType = new FormControl(value.ApprovalCodeType);
        this.VehicleRebateFlag = new FormControl(value.VehicleRebateFlag);
        this.RebatedVehicleRegistrationPlate = new FormControl(value.RebatedVehicleRegistrationPlate);
        this.VehicleInventory = new FormArray([]);

        if (value.VehicleInventory) {
            value.VehicleInventory.forEach((inventory: EMCSVehicleInventoryModel) => {
                this.VehicleInventory.push(new FormGroup(new EMCSVehicleInventoryForm(new EMCSVehicleInventoryModel(inventory)), { updateOn: 'change', }));
            });
        }
        else {
            this.VehicleInventory.push(new FormGroup(new EMCSVehicleInventoryForm(new EMCSVehicleInventoryModel())));

        }

    }
}
