import { Component, ElementRef, Inject, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { ApiService } from 'app/services/api.service';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { countriesItem, international_customersItem } from 'app/model/api-model';
import { ToastrService } from 'ngx-toastr';
import { confirmPasswordValidator } from 'app/services/validators/custom-validators';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';

interface ContactFormDialogData {

    action: 'add' | 'edit';
    class: 'domestic' | 'international' | 'carrier';
    fromDeclaration: boolean;
    contact: Contact;
}
@Component({
    selector: 'contacts-contact-form-dialog',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    // encapsulation: ViewEncapsulation.None
})
export class ContactsContactFormDialogComponent {

    @ViewChildren('firstInput') _first: QueryList<ElementRef>;

    private _destroy: Subject<void> = new Subject<void>();

    action: string;
    class: string;
    fromDeclaration: boolean;

    contact: Contact | international_customersItem;
    contactForm: FormGroup;
    dialogTitle: string;
    status = -1;
    IcisNetChecked: boolean;
    addChecked: boolean;
    registeredChecked: boolean;
    warehouseRefNumbers: FormArray;

    domestic = true;
    carrier = false;
    eoriControl: FormControl;
    eoriErrorMessage: string;

    countries: countriesItem[];

    config1 = {
        output: 'country_code',
        menuOptions: {
            options: ['country_code', 'country_name']
        }
    };

    get afm(): FormControl {

        return this.contactForm.get('afm') as FormControl
    }
    get eori(): FormControl {

        return this.contactForm.get('eori') as FormControl
    }


    /**
     * Constructor
     *
     * @param matDialogRef
     * @param _data
     * @param _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ContactsContactFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: ContactFormDialogData,
        private _formBuilder: FormBuilder,
        private dbQuery: ApiService,
        private toastrService: ToastrService,
        private mainBroadcaster: MainBroadcasterService,
        private cf: ExtCustomFunctions
    ) {
        this.eoriControl = new FormControl(null, [
            Validators.pattern('^[A-Z]{2}[A-Za-z0-9]{0,15}$')
        ]);

        // Set the defaults
        this.action = this._data.action;
        this.class = this._data.class;
        this.fromDeclaration = this._data.fromDeclaration;

        this.mainBroadcaster.declarationSettings$.pipe(take(1)).subscribe(settings => {

            this.setContact(this._data, settings);
        })


        console.log('DOMESTIC: ', this.domestic);
        console.log('contactForm: ', this.contactForm);
        this.mainBroadcaster.countries$
            .pipe(take(1))
            .subscribe(value => {
                this.countries = value;

            });
    }

    ngOnInit() {
        if (this.class === 'domestic' || this.class === 'carrier') {

            if (this.contactForm.get('icisUser').value !== null && this.contactForm.get('icisUser').value !== '') {
                this.IcisNetChecked = true;
            } else {
                this.IcisNetChecked = false;
            }

            if (
                (this.contactForm.get('trademarkEn').value !== null && this.contactForm.get('trademarkEn').value !== '') ||
                (this.contactForm.get('exporterEng').value !== null && this.contactForm.get('exporterEng').value !== '')
            ) {
                this.addChecked = true;
            } else {
                this.addChecked = false;
            }
        }



        this.eoriControl.valueChanges.pipe(takeUntil(this._destroy)).subscribe(value => {

            this.eori.setValue(value)


        })
        this.eoriControl.statusChanges.pipe(takeUntil(this._destroy)).subscribe(status => {

            if (status === 'INVALID') {
                this.eori.setErrors({ invalid: true })
            }
            else {
                this.eori.setErrors(null)
            }
        })

    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._destroy.next();
        this._destroy.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    setContact(_data: ContactFormDialogData, settings: DeclarationSettingsModel) {
        if (this.class === 'domestic') {
            this.domestic = true;
            this.carrier = false;
            let domestic: Contact;

            if (this.action === 'edit') {
                this.dialogTitle = 'Επεξεργασία Πελάτη';

                domestic = _data.contact;
            } else {
                this.dialogTitle = 'Νέος Πελάτης';

                domestic = new Contact(settings);
            }

            this.contact = domestic;

            this.contactForm = this.createContactForm('domestic');
            this.warehouseRefNumbers = this.contactForm.get('warehouseRefNumbers') as FormArray;
        } else if (this.class === 'international') {
            this.domestic = false;
            this.carrier = false;
            let international: Contact;

            if (this.action === 'edit') {
                this.dialogTitle = 'Επεξεργασία Διεθνούς Πελάτη';

                international = _data.contact;
            } else {
                this.dialogTitle = 'Νέος Διεθνής Πελάτης';

                international = new Contact(settings);
            }

            this.contact = international;
            this.contactForm = this.createContactForm('international');
        } else if (this.class === 'carrier') {
            this.domestic = false;
            this.carrier = true;

            let carrier: Contact;

            if (this.action === 'edit') {
                this.dialogTitle = 'Επεξεργασία Μεταφορέα';

                carrier = _data.contact;
            } else {
                this.dialogTitle = 'Νέος Μεταφορέας';

                carrier = new Contact(settings);
            }

            this.contact = carrier;
            this.contactForm = this.createContactForm('carrier');
            this.warehouseRefNumbers = this.contactForm.get('warehouseRefNumbers') as FormArray;
        }
    }
    /**
     * Create contact form
     *
     * @returns
     */
    createContactForm(type: string): FormGroup {
        console.log('createContactForm');

        if (type === 'domestic' || type === 'carrier') {
            const form = this._formBuilder.group({
                id: [this.contact.id],
                customerType: [(this.contact as Contact).customerType],
                lastname: [this.contact.lastname],
                firstname: [this.contact.firstname],
                afm: [(this.contact as Contact).afm],
                eori: [this.contact.eori],
                passport: [this.contact.passport],
                id_number: [(this.contact as Contact).id_number],
                doy: [(this.contact as Contact).doy],
                occupation: [this.contact.occupation],
                street: [this.contact.street, Validators.required],
                address2: [(this.contact as Contact).address2],
                city: [this.contact.city, Validators.required],
                country: [this.contact.country, Validators.required],
                postal_code: [this.contact.postal_code, Validators.required],
                email: [this.contact.email],
                phone: [this.contact.phone],
                exporterEng: [(this.contact as Contact).exporterEng],
                trademark: [(this.contact as Contact).trademark],
                trademarkEn: [(this.contact as Contact).trademarkEn],
                registeredWarehouseOwnerId: [(this.contact as Contact).registeredWarehouseOwnerId],
                icisUser: [(this.contact as Contact).icisUser],
                icisPass: [(this.contact as Contact).icisPass],
                icisPassConfirm: [(this.contact as Contact).icisPassConfirm],
                active: [this.contact.active],
                //Instatiate the warehouseRefNumbers formArray with one element (warehouseRefNumbers is a FormArray of FormControls)
                warehouseRefNumbers: this._formBuilder.array([this._formBuilder.control(null)])
            });

            this.eoriControl.setValue(this.contact.eori, {
                emitEvent: false,
                onlySelf: true
            });

            return form;
        } else if (type === 'international') {

            if (this.action === 'new') {
                this.contact.country = null;
            }
            return this._formBuilder.group({
                id: [this.contact.id],
                lastname: [this.contact.lastname, Validators.required],
                firstname: [this.contact.firstname],
                eori: [this.contact.eori],
                passport: [this.contact.passport],
                occupation: [this.contact.occupation],
                street: [this.contact.street, Validators.required],
                city: [this.contact.city, Validators.required],
                country: [this.contact.country, Validators.required],
                postal_code: [this.contact.postal_code, Validators.required],
                email: [this.contact.email],
                phone: [this.contact.phone],
                active: [this.contact.active]
            });
        }

    }

    checkEori() {
        this.toastrService.clear()
        const eori = this.eoriControl.value ? this.eoriControl.value : 'GR' + this.afm.value;
        this.dbQuery
            .get_eori(eori)
            .pipe(takeUntil(this._destroy))
            .subscribe(result => {
                console.log('Contact Eori Result', result);
                console.log('Contact Eori value', eori);

                if (result.status === 0) {
                    this.status = 0;
                    this.eori.setValue(eori);
                } else {
                    this.status = 1;
                    this.eoriErrorMessage = this.eoriControl.value ? 'Ο αριθμός EORI δεν είναι έγκυρος.' : 'Δεν έχει εκδοθεί αριθμός EORI.'
                    this.eori.setValue(null);
                }
                console.log('Contact Form value', this.contactForm.getRawValue());
            });
    }

    getBusinessData() {
        this.toastrService.clear()


        this.dbQuery
            .get_business_data(this.afm.value)
            .pipe(takeUntil(this._destroy))
            .subscribe(result => {
                if (result.error) {
                    console.log(result);
                    this.toastrService.error(result.error.explanation, 'Σφάλμα.', {
                        disableTimeOut: true,
                        closeButton: true
                    });

                    this.contactForm.patchValue({
                        customerType: 2,
                        doy: null,
                        lastname: null,
                        firstname: null,
                        occupation: null,
                        street: null,
                        postal_code: null,
                        city: null
                    });

                    this.status = 2;
                } else {
                    let occupation: string;
                    let address: string;

                    // Handle occupation safely
                    if (Array.isArray(result.firmActivity) && result.firmActivity.length > 0) {
                        occupation = result.firmActivity[0].firm_act_descr;
                    } else if (result.firmActivity && result.firmActivity.firm_act_descr) {
                        occupation = result.firmActivity.firm_act_descr;
                    }

                    // Handle address safely
                    if (result.firmData.postal_address) {
                        address = result.firmData.postal_address;
                        if (result.firmData.postal_address_no && result.firmData.postal_address_no.trim() !== '0') {
                            address += ' ' + result.firmData.postal_address_no.trim();
                        }
                    }



                    this.contactForm.patchValue({
                        doy: result.firmData.doy_descr,
                        lastname: result.firmData.onomasia,
                    });

                    if (result.status === 0) {

                        //active business
                        this.contactForm.patchValue({
                            customerType: 1,
                            trademark: result.firmData.commer_title,
                            occupation: occupation,
                            street: address,
                            postal_code: result.firmData.postal_zip_code,
                            city: result.firmData.postal_area_description
                        });
                    }

                    else if (result.status === 1) {
                        //inactive business
                        this.contactForm.patchValue({
                            customerType: 2,
                            trademark: null,
                            occupation: result.firmData.firm_flag_descr,
                            street: null,
                            postal_code: null,
                            city: null
                        });

                    }

                    this.status = result.status === 0 ? 0 : 1;




                }

                const eori = 'GR' + this.afm.value;
                console.log("EORI:", eori)
                this.eori.setValue(eori);
                this.eoriControl.setValue(eori)
            });
    }

    OnChange($event) {
        //MatCheckboxChange {checked,MatCheckbox}
        if ($event.source.id === 'icisCheck') {
            this.IcisNetChecked = $event.checked;

            if ($event.checked === true) {
                this.contactForm.get('icisUser').setValidators(Validators.required);

                this.contactForm.get('icisPass').setValidators(Validators.required);
                this.contactForm.get('icisPassConfirm').setValidators([Validators.required, confirmPasswordValidator(this.contactForm.get('icisPass'))]);
                this.contactForm.get('icisUser').updateValueAndValidity();
                this.contactForm.get('icisPass').updateValueAndValidity();
                this.contactForm.get('icisPassConfirm').updateValueAndValidity();
            } else {
                this.contactForm.get('icisUser').setValue(null);
                this.contactForm.get('icisPass').setValue(null);
                this.contactForm.get('icisPassConfirm').setValue(null);

                this.contactForm.get('icisUser').clearValidators();
                this.contactForm.get('icisPass').clearValidators();
                this.contactForm.get('icisPassConfirm').clearValidators();

                this.contactForm.get('icisUser').markAsUntouched();
                this.contactForm.get('icisPass').markAsUntouched();
                this.contactForm.get('icisPassConfirm').markAsUntouched();

                this.contactForm.get('icisUser').updateValueAndValidity();
                this.contactForm.get('icisPass').updateValueAndValidity();
                this.contactForm.get('icisPassConfirm').updateValueAndValidity();
            }
        } else if ($event.source.id === 'addCheck') {
            this.addChecked = $event.checked;
        }
        else if ($event.source.id === 'registeredCheck') {
            this.registeredChecked = $event.checked;

            if ($event.checked === true) {
                this.contactForm.get('registeredWarehouseOwnerId').setValidators(Validators.required);
                this.contactForm.get('registeredWarehouseOwnerId').updateValueAndValidity();
            } else {
                this.contactForm.get('registeredWarehouseOwnerId').setValue(null);
                this.contactForm.get('registeredWarehouseOwnerId').clearValidators();
                this.contactForm.get('registeredWarehouseOwnerId').markAsUntouched();
                this.contactForm.get('registeredWarehouseOwnerId').updateValueAndValidity();
            }


        }
    }

    createContact() {
        console.log('CreateContact', this.contactForm.getRawValue());
        this.matDialogRef.close({
            contactType: this.class,
            contactForm: this.contactForm.getRawValue()
        });
    }

    updateContact() {
        console.log('UpdateContact', this.contactForm.getRawValue());
        this.matDialogRef.close(['save', this.class, this.contactForm])

        // this.contactsService.updateContact(this.contactForm.getRawValue(), this.class).pipe(take(1)).subscribe();;
    }
    deleteContact() {
        this.matDialogRef.close(['delete', this.class, this.contactForm])
    }


    deleteEmpty() {
        const emptyArray = this.cf.deleteEmpty(this.warehouseRefNumbers);
        //start deleting empty entries
        emptyArray.forEach(value => {
            this.delete(value);
        });
    }
    add() {


        this.warehouseRefNumbers.push(new FormControl(null));

        setTimeout(() => {
            this._first.last.nativeElement.focus();
        }, 200);
    }
    delete(i) {
        this.warehouseRefNumbers.removeAt(i);
    }

    hideAdd() {
        return this.cf.hideAdd(this.warehouseRefNumbers, 90);
    }
    hideDelete() {
        return this.cf.hideDelete(this.warehouseRefNumbers);
    }
    onEnter() {
        this.add();
    }


}
