import { StorageService } from 'app/core/services/storage/storage.service';
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { map, retry } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ArchiveResult } from 'app/main/apps/archive/archive.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { catchError, combineLatest, Observable, of, Subject, take, tap, lastValueFrom, timer, switchMap } from 'rxjs';
import { ApiService } from './api.service';
import { MainBroadcasterService } from './broadcaster.service';
import { Page } from './datasource/page';
import { DisableFormService } from './disableForm.service';
import { NotificationService } from './notifications.service';
import { SaveFormParams, SaveFormService } from './saveForm.service';
import { DeclarationModels, GetStateParams } from './submitServices/submitForm.service';
import { environment } from 'environments/environment';
import { ArchiveTabType } from 'app/main/apps/archive/archive.service';
import { RouteStateService } from 'app/services/routeState.service';
import { Location } from '@angular/common';
import declarationData from 'assets/declarationData.json';
import { Params, Router } from '@angular/router';



export interface StateResponse {

    status?: string;
    revert?: boolean; //revrtToPrevious state, on Failed edit or failed
    messageOut?: string;
    subdomain?: string;
    messageIn?: string;

    message?: string;
    data?: StateResponseData;
    error?: StateResponseError[];
    debug?: any;


    lrn?: string;
    mrn?: string;
    stateCode?: string;
    stateDate?: number;
    submissionDate?: number;

    internalState?: "OK" | "CHECK"; //used to trigger declarant update
    noWaitNotify?: boolean;
    declarationForm?: DeclarationModels


    //===========ON SAVE DATA=================
    declarationId?: string;
    originalDocType?: ArchiveTabType;
    originalDocId?: string;

}

export interface StateResponseError {
    place?: string;
    status?: string;
    errorType?: number;
    errorDesc?: string;
    explanation?: string;

}

export interface FunctionalErrorsType extends StateResponseError {

    errorPointer?: string;  //declaration form field
    errorReason?: string;  //
    errorOriAttVal?: string; //The wrong value 
}
export interface StateResponseData {
    //
    FunctionalErrors?: FunctionalErrorsType[];
    acceptanceDate?: string; //EXP, DETE, IMP,, DETE as RegistrationDate
    issuingDate?: string; //EXP
    exitDate?: string; //EXP

    underControl?: string; //EXP
    underControlDesc?: string; //EXP
    underControlInfo?: string; //EXP

    controlDate?: string; // NOT YET HANDLED
    controlResDesc?: string;  // NOT YET HANDLED
    controlResInd?: string;  // NOT YET HANDLED

    nonExitExpRespDateLim?: string; //CC582A  NOT YET HANDLED  Προθεσμία για Απάντηση
    nonExitExpReqDate?: string; //CC582A  NOT YET HANDLED  Ημ/νία για Πληροφορίες Mη-Eξαχθέντων Eξαγωγών
    nonExitCustomsRef?: string; //CC582A  NOT YET HANDLED  Αρμόδια Τελωνειακή Αρχή Εξαγωγής


    nonExitInfoAvail?: string; //CC583A NOT YET HANDLED
    nonExitExitDate?: string; //CC583A NOT YET HANDLED
    nonExitDateOfInfoReceived?: string; //CC583A NOT YET HANDLED

    cancelDecision?: boolean;
    cancelReqDate?: string;
    cancelInitByCustoms?: boolean;
    cancelDecDate?: string;
    cancelReason?: string;

    //DETE
    deteAmountOfTax?: string; //DETE
    rejectionDate?: string;  //ARR_AT_EXIT
    rejectionReason?: string; //ARR_AT_EXIT
    amendDate?: string;
    amendAcceptanceDate?: string;
    amendRejectionDate?: string;
    amendRejectionMotivationCode?: string;
    amendRejectionMotivationText?: string;
    //ARR_AT_EXIT
    actionToBeTaken?: string;
    dateOfRelease?: string;

    //IMP
    totalTaxesAmount?: string;
    additionalDeclDate?: string;

    //DAO
    daoReceivedDate?: string;
}

export interface RevertValue {
    response: StateResponse;
    declaration?: ArchiveResult;
    declarationForm?: FormGroup;
}

@Injectable({
    providedIn: 'root'
})
export class DeclarationStateService {
    domain: string;
    localStorageItem: string;

    routeParams$: Observable<Params>;
    declarationData: any;
    currentParams: { officeId: string, domain: string, subdomain: string, type: string, id: string };

    private _destroy: Subject<void> = new Subject<void>();

    constructor(
        private dbQuery: ApiService,
        private mainBroadcaster: MainBroadcasterService,
        private cf: ExtCustomFunctions,
        private saveFormService: SaveFormService,
        private notificationService: NotificationService,
        private disableFormService: DisableFormService,
        private injector: Injector,

        private routeStateService: RouteStateService,
        private location: Location,
        private storageService: StorageService

    ) {

        this.routeParams$ = this.routeStateService.pathParam$;
        this.declarationData = declarationData


    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    updateStateServerResp(response: StateResponse) {

        if (!response.subdomain) {
            return;
        }

        this.localStorageItem = this.declarationData[response.subdomain].localStorage;

        console.log("updateState  NEW RESPONSE IN", response)


        //if declaration does not exist( when called from reportProgress interceptor)

        const responseSubdomain = response.subdomain;

        const declarationForms$ = this.mainBroadcaster.declarationForms$;
        const activeSubdomain$ = this.mainBroadcaster.activeSubdomain$;



        combineLatest([declarationForms$, activeSubdomain$])
            .pipe(take(1))
            .subscribe(([declarationForms, activeSubdomain]) => {

                console.log("ALL DeclarationForms", declarationForms)

                const activeDeclaration = declarationForms[responseSubdomain];
                console.log('declarationForm on updateState', activeDeclaration?.getRawValue());
                console.log('declarationForms ALL on updateState', declarationForms);

                const activeDeclarationLrn = activeDeclaration?.get('_PRIVATE').get('lrn').value;
                const activeDeclarationMrn = activeDeclaration?.get('_PRIVATE').get('mrn').value;

                console.log('updateState activeSubdomain', activeSubdomain);
                console.log('updateState responseSubdomain', responseSubdomain);
                console.log('updateState activeDeclarationLrn', activeDeclarationLrn);
                console.log('updateState activeDeclarationMrn', activeDeclarationMrn);
                console.log('updateState response.lrn', response.lrn);

                //THIS IS FOR TESTING ONLY FOR RESPONSES FROM NODESERVER
                if (environment.testing && (responseSubdomain === 'DAO' || responseSubdomain === 'DEFK' || responseSubdomain === 'DAO_RECEIVE')) {
                    response.lrn = activeDeclarationLrn
                }
                const sameDeclaration = activeDeclarationLrn === response.lrn || activeDeclarationMrn === response.lrn
                const sameSubdomain = activeSubdomain === responseSubdomain

                //iF checked declaration is the active one, then update state 
                if (sameDeclaration && sameSubdomain
                ) {

                    //Notify app with state
                    this.notificationService.notify(response);

                    //Update DeclarationForm controls
                    this.updateDeclarationOnView(activeDeclaration, response)

                }
                /**
                *if for whatever reason the response comes in a later time and we have navigated away
                *from the declaration the response is meant for, 
                *send response to backend to update the relevant declaration
                */
                else {

                    this.updateDeclarationSilent({ response }).subscribe()
                }


                //=======================================TRANSFERED FROM SAVE FROM===========================
                //remove localstorage after  succesfull Save of Submitted Declaration
                if (response.stateCode !== 'Pre-Submitted' && response.stateCode !== 'Edit' && response.status !== 'Error') {
                    this.storageService.removeLocalObject(this.localStorageItem);
                }

                //Update path, delete draft if initiated declaration was draft
                if (response.stateCode === 'Sent') {
                    this.onSentDeclaration(response)
                }




            });


    }

    updateDeclarationSilent(value: RevertValue): Observable<any> {


        console.log('updateState updateDeclarationSilent value', value);

        const declaration = value.declaration
        const res = value.response

        if (value.declaration && res.declarationForm) {
            console.log('updateState updateDeclarationSilent declarationFound', value.declaration);

            //if there is a declaration (fromArchive)
            declaration.declarationForm = res.declarationForm;

            // (new RecursiveAssign).recursiveAssign(privateCtrl, res);

            console.log("declarationForm after patch", declaration.declarationForm)
        }

        if (res.stateCode) {
            console.log('updateState updateDeclarationSilent stateCodeFound', res.stateCode);
            this.notificationService.notifyNewState(res)



            /*  return this.dbQuery.updateState(res, declaration?.declarationForm)
                 .pipe(
                     tap(() => {
                         this.notificationService.notifyNewState(res)
                         console.log("checkStateArchive updateState res", value)
                     }),
                     take(1)) */
        }

        return of(null)

    }

    updateDeclarationOnView(declarationForm: FormGroup, res: StateResponse) {

        console.log('updateState updateDeclarationOnView res', res);

        if (res.revert) {

            console.log('updateState updateDeclarationOnView REVERTING', res);

            this.revertToPreviousState({ response: res, declarationForm }).subscribe()
            return;
        }

        console.log('updateState updateDeclarationOnView declarationForm', declarationForm.value);
        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value;

        if (res.message || res.message === '') {
            //first stateCode that is returned is "Sent"
            //So when form is sent to IcisNet
            console.log('RESPONSE XXX MESSAGE', res);
            if (res.stateCode) {
                this.onCommonMessage(declarationForm, res)

                if (res.stateCode === 'Sent' || res.stateCode === 'Submitted') {
                    // this.saveForm(subdomain, declarationForm, 'all');
                }


            }
        } else if (res.data) {
            //When we receive the final data message
            console.log("updateDeclarationOnView Updating Form DO CONVERT DATE", res)

            this.onCommonMessage(declarationForm, res)

            if (res.subdomain === 'DEFK') {
                this.onFinalDataDEFK(declarationForm, res)
            }
            else if (res.subdomain === 'DAO') {
                this.onFinalDataDAO(declarationForm, res)
            }
            else {
                this.onFinalData(declarationForm, res);
            }

            //this.saveForm(subdomain, declarationForm);



            /*          //update the observable used to show state on app
              this.mainBroadcaster.updateDeclarationStates({
                  [subdomain]: res
              }); */

            //reset ReportProgressResponse Observable, sp we waont carry values to othe declarations
            this.mainBroadcaster.updateResponse(null);
            //On error
        }

    }

    /**
     * called when we receive messages from server containing connection status, 
     * or state messages like Submitted or Sent
     * 
     */
    onCommonMessage(declarationForm, res) {

        console.log("RESPONSE STATECODE FOUND. Updating Form", res.stateCode)
        console.log("updateDeclarationOnView Updating Form DO NOT CONVERT DATE", res)
        this.updateStateOnActiveDecl(declarationForm, res);

        this.transmitStateCode(declarationForm)


    }

    setState(declarationForm: FormGroup, state: string) {
        const stateCode = declarationForm.get('_PRIVATE').get('stateCode');
        const stateDate = declarationForm.get('_PRIVATE').get('stateDate')

        if (state === 'Pre-Submitted' || state === 'Edit') {
            stateDate.setValue(null);
        }

        if (state !== 'Sent' && state !== 'Edit' && state !== 'Submit VA18') {

            declarationForm.get('_PRIVATE').get('lastValidState').setValue(state)
        }

        stateCode.setValue(state);


        console.log('EDIT declForm after νγ', declarationForm.getRawValue());

        this.transmitStateCode(declarationForm);
    }


    transmitStateCode(declarationForm: FormGroup) {

        const currentState = declarationForm.get('_PRIVATE').get('stateCode').value;
        const subdomain = declarationForm.get('_PRIVATE').get('subdomain').value;
        console.log('declarationForm$ transmiStateCode currentState', currentState);
        console.log('declarationForm$ transmiStateCode subdomain', subdomain);

        this.mainBroadcaster.updateDeclarationStates({
            [subdomain]: { stateCode: currentState }
        });

        this.disableFormService.checkFormState(declarationForm)
    }


    async checkState(declarationForm: FormGroup) {
        console.log('checkState declarationForm', declarationForm);

        const currentStateCode = declarationForm.get('_PRIVATE').get('stateCode').value;

        await this.getLatestState(declarationForm).then((responses: StateResponse[]) => {

            //reportProgressInterceptor transforms response to array
            responses.forEach(response => {
                const stateCode = response.stateCode;
                if (currentStateCode !== stateCode) {
                    console.log('patchDeclState on  checkState', response);

                    //First Notify THEN update Broadcaster. Current state is used by notify, before it's updated
                    this.notificationService.notifyNewState(response);

                    this.transmitStateCode(declarationForm)
                }
                /*   else {
                      this.notificationService.notifyNoChange(response)
                  } */
            })
        });
    }

    async getLatestState(declarationForm: FormGroup): Promise<StateResponse[]> {

        const traderId = this.cf.getTraderId(declarationForm.getRawValue())
        const declarantId: string = this.cf.getDeclarant(declarationForm.getRawValue());
        const messageOut = declarationForm.get('_PRIVATE').get('messageOut').value
        const lrn: string = declarationForm.get('_PRIVATE').get('lrn').value;
        const mrn: string = declarationForm.get('_PRIVATE').get('mrn').value;

        console.log('getLatestState traderId', traderId);
        console.log('getLatestState declarantId', declarantId);

        const submitData: GetStateParams = {
            declarantId,
            traderId,
            messageOut,
            lrn,
            silent: false
        };
        if (mrn) {
            submitData['mrn'] = mrn;
        }
        console.log('submitData', submitData);

        return await lastValueFrom(
            this.dbQuery.getState(submitData).pipe(tap((response: any) => {
                response.forEach((res: StateResponse) => {
                    this.updateStateServerResp(res)

                })
            })));

    }

    saveForm(subdomain: string, declarationForm: FormGroup, docType?: ArchiveTabType) {

        const options: SaveFormParams = {
            subdomain,
            systemCall: true,
            noNotification: true,
            declarationForm
        }

        if (docType) {
            options.type = docType
        }

        this.saveFormService.saveForm(options).subscribe();
    }

    deleteForm(lrn: string) {
        return this.saveFormService.deleteForm(lrn)
    }

    updateStateOnActiveDecl(declarationForm: FormGroup, response: StateResponse) {

        const currentLRN = declarationForm.get('_PRIVATE').get('lrn').value
        const currentMRN = declarationForm.get('_PRIVATE').get('mrn').value
        const messageIn = response.messageIn;
        let stateCode = response.stateCode;
        const stateDate: string | number | Date = response.stateDate;
        const submissionDate: string | number | Date = response.submissionDate;

        //if lrns match (state is of current declaration)
        if (response.lrn && (currentLRN === response.lrn || currentMRN === response.lrn)) {
            console.log('LRNs MATCH: updating State! current:' + currentLRN + ', response: ' + response.lrn);


            console.log('patchDeclState on  updateDeclarationForm', response);

            //If a processingError occurs revert to previous valid State
            if (response.revert) {
                stateCode = declarationForm.get('_PRIVATE').get('lastValidState').value
            }
            //update state
            declarationForm.get('_PRIVATE').patchValue({
                messageIn,
                stateCode,
                stateDate,
                submissionDate
            });

            //if lrns don't match(state is not of current declaration)
        }
    }

    checkIncompleteStates() {
        const postData = {
            pageNumber: 0,
            pageSize: 100,
            query: { status: 'Sent,Submitted' },
            sortColumn: 'last_modified',
            sortOrder: 'asc'
        };

        this.dbQuery.get_declarations(postData).pipe(take(1))

            .subscribe((declarations: Page<ArchiveResult>) => {
                const unresolved = declarations.content;

                console.log('Incomplete Declarations', unresolved);
                if (unresolved) {
                    unresolved.forEach((declaration: ArchiveResult) => {
                        this.checkStateSilent(declaration);
                    });
                }
            });
    }

    checkStateSilent(declaration: ArchiveResult) {
        const lrn = declaration.lrn;
        const traderId = (declaration.trader.eori ? declaration.trader.eori : declaration.trader.afm); //AFM
        const declarantId = declaration.declarant.afm;
        const currentState = declaration.state;
        const declarationForm = declaration.declarationForm;
        const messageOut = declaration.messageType;

        console.log('declarationForm on checkState', declarationForm);

        const submitData = { declarantId, traderId, messageOut, lrn, silent: true };


        this.dbQuery
            .getStateSilent(submitData)
            .pipe(
                retry({
                    count: 50,
                    delay: (error, retryCount) => {
                        console.log("checkSilent id: " + declaration.id + " attempt: " + retryCount);
                        if (retryCount < 10) {
                            return timer(30000)
                        }
                        return timer(60000)
                    }
                }),
                catchError((e: unknown) => {
                    console.log('Timeout on checkState id: ' + declaration.id + ', Error:', e);

                    //wait 30 secs, then keep checking on the background, reset reportProgressResponse
                    //repeat Request
                    // this.checkStateSilent(declaration);

                    return of(null);
                })
            )
            .subscribe((response: StateResponse) => {
                console.log('DATA', response);
                if (response && response !== null) {


                    if (response.revert) {
                        this.revertToPreviousState({ response, declaration }).subscribe()
                        return;
                    }

                    if (response.stateCode !== currentState) {


                        //update db
                        this.updateDeclarationSilent({ response, declaration }).subscribe();

                        if (response.stateCode === 'Submitted') {
                            setTimeout(() => { this.checkStateSilent(declaration) }, 5000)
                        } else {
                            //remove from incomplete

                        }
                        //notify
                        this.notify(response);
                    }
                }
            });
    }

    checkStateArchive(declaration: ArchiveResult): Observable<StateResponse> {

        const lrn = declaration.lrn;
        const traderId = (declaration.trader.eori ? declaration.trader.eori : declaration.trader.afm); //AFM
        const declarantId = declaration.declarant.afm;
        const currentState = declaration.state;
        const declarationForm = declaration.declarationForm;
        const messageOut = declaration.messageType

        //mrn is used on mock createResponse to set same mrn on response
        const mrn = declaration.mrn;
        console.log('declarationForm on checkState', declarationForm);

        const submitData = { declarantId, traderId, messageOut, mrn, lrn, silent: true };

        return this.dbQuery.getStateSilent(submitData)
            .pipe(
                switchMap((res: StateResponse) => {

                    console.log('response on Archive checkStateArchive on DeclarationStateService', res)
                    /* 
                                if (environment.testing) {
                                    res.stateCode = "Rejected";
                                    res.data = {
                                        actionToBeTaken: "TakeACtion",
                                        dateOfRelease: "2012-10-12"
                                    }
                                }
                     */
                    if (currentState !== res?.stateCode || res?.revert) {
                        this.checkModifiedDecl(res)

                        //Patch stateResponse to declaration private ctrls, so the new data is saved in db
                        return this.updateDeclarationSilent({ response: res, declaration }).pipe(map(e => res))
                    }

                    this.notificationService.notifyNoChange(res)
                    return of(res)

                }), take(1))
    }

    notify(response: StateResponse) {
        this.notificationService.notifyNewState(response);
    }

    checkModifiedDecl(res: StateResponse) {

        //If messageOut is amendment message and it is accepted,
        // delete temporary inital declaration data form loclstorage

        console.log("checkModifiedDecl", res)

        if (
            res.messageOut !== 'CC513A' &&
            res.messageOut !== 'ID13A' &&
            res.messageOut !== 'TF013A' &&
            res.messageOut !== 'IB13A' &&
            res.messageOut !== 'CC013B' &&
            res.messageOut !== 'EF13A' &&
            res.messageOut !== 'VA13A'
        ) {
            return;
        }

        if (res.stateCode === 'Accepted') {
            this.storageService.removeLocalObject(res.mrn)

        }

    }
    onFinalData(declarationForm: FormGroup, res: StateResponse) {

        const data: StateResponseData = res.data
        const currentHeahea = declarationForm.get('HEAHEA') as FormGroup;

        console.log("RESPONSE ON FINAL DATA", res)

        //CHECK CONSISTENCY OF DECLARATION FORM BEFORE RESTTING/ _PRIVATE I NOT ENOUGH I THINK...
        ///==============================================U{DATE DECLARATIONFORM WITH DATA FROM BACKEND=======================

        if (res.declarationForm._PRIVATE) {
            declarationForm.reset(res.declarationForm);
        }

        console.log("PATCH FINAL DATA after reset", declarationForm.value);

        //TIR=====================================
        if (res.messageIn === 'CC528A' && res.messageOut === 'CC515A' && currentHeahea.get('TIRFolHEA1025').value === 1) {
            this.mainBroadcaster.updateTirFollows()
        }


        //Cancellation===============================
        //Cancel Accepted or Modify Accepted
        if ((res.messageIn === 'CC509A' || res.messageIn === 'CC504A') && res.stateCode === 'Accepted') {

            this.storageService.removeLocalObject(res.mrn);
        }

        //Cancelled by Customs
        if (data.cancelInitByCustoms) {
            this.notificationService.cancellationReason(data.cancelReason, data.cancelInitByCustoms);

        }
        //Cancellation Rejected
        if (data.cancelDecision === false) {
            this.notificationService.cancellationRefused(data.cancelReason)

        }
    }

    onSentDeclaration(res: StateResponse) {


        //=========================TRANSFERED FROM SAVEFORMSERVICE==============================
        this.routeParams$.pipe(
            take(1),
            switchMap(routeParams => {
                console.log('onSentDeclaration saveFormX getDocumentSpecificValues routeParams', routeParams);

                const officeId = routeParams.officeId;
                const pathDomain = routeParams.domain;
                const pathSubdomain = routeParams.subdomain;
                const type = routeParams.type;
                const id = routeParams.id

                this.currentParams = { officeId, domain: pathDomain, subdomain: pathSubdomain, type, id }
                // If manual save, Update the URL without reloading the component
                // if (!args.systemCall) {
                const url = `/${officeId}/${pathDomain}/${pathSubdomain}/all/${res.declarationId}`
                this.location.go(url);

                //Update the routeStateService with the new route params
                this.routeStateService.updateRouteParams({ type: 'all', id: res.declarationId })
                //}

                //and we delete from the draft declaration table
                if (res.originalDocId && res.originalDocType === 'draft') {
                    return this.dbQuery.delete_declaration(res.originalDocType, res.originalDocId).pipe(take(1));
                } else {
                    return of(null);
                }

            })).subscribe();

    }

    revertCurrentPath() {

        let url = `/${this.currentParams.officeId}/${this.currentParams.domain}/${this.currentParams.subdomain}/`;

        if (this.currentParams.type) {
            url += `${this.currentParams.type}/`;
            this.routeStateService.updateRouteParams({ type: this.currentParams.type })

        }
        else {
            this.routeStateService.updateRouteParams({ type: null })
        }

        if (this.currentParams.id) {
            if (this.currentParams.type !== 'draft') {
                url += `${this.currentParams.id}`;
                this.routeStateService.updateRouteParams({ id: this.currentParams.id })
            }
            else {
                this.routeStateService.updateRouteParams({ id: null })
            }
        }
        else {
            this.routeStateService.updateRouteParams({ id: null })
        }

        this.location.go(url);


    }

    onFinalDataDAO(declarationForm: FormGroup, res: StateResponse) {

        const currentVehicleArrival = declarationForm.get('Body').get('VehicleArrivalDeclarationRegistration').get('VehicleArrival') as FormGroup;


        console.log("RESPONSE ON FINAL DATA", res)

        if (res.mrn) {
            //action
            currentVehicleArrival.get('DeclarationReferenceNumber')?.setValue(res.mrn);
            declarationForm.get('_PRIVATE').get('mrn').setValue(res.mrn)

        }
        if (res.data.daoReceivedDate) {
            currentVehicleArrival.get('_arrivalDate')?.setValue(res.data.daoReceivedDate);
        }

        console.log("HEAHEA AFTER RESPONSE", currentVehicleArrival?.value)

    }

    onFinalDataDEFK(declarationForm: FormGroup, res: StateResponse) {

        const currentTaxesDeclaration = declarationForm.get('Body').get('DeclarationOfExciseTaxesRegistration').get('ExciseTaxesDeclaration') as FormGroup;


        console.log("RESPONSE ON FINAL DATA", res)

        if (res.mrn) {
            //action
            currentTaxesDeclaration.get('DeclarationReferenceNumber')?.setValue(res.mrn);
            currentTaxesDeclaration.get('RegistrationDate')?.setValue(res.data.acceptanceDate);
            declarationForm.get('_PRIVATE').get('mrn').setValue(res.mrn)



        }

        console.log("HEAHEA AFTER RESPONSE", currentTaxesDeclaration?.value)

    }

    revertToPreviousState(value: RevertValue): Observable<any> {
        console.log('updateState revertToPreviousState res', value);

        this.notificationService.clear();

        const messageOut = value.response.messageOut

        if (messageOut.includes('15A') || messageOut.includes('15B') || messageOut === 'CC507A') {
            return this.revertInitial(value)
        }
        else if (messageOut.includes('13A') || messageOut.includes('13B') || messageOut === 'VA18A') {
            return this.revertEdit(value)
        }
        else if (messageOut.includes('14A') || messageOut.includes('14B') || messageOut.includes('10A')) {
            return this.revertCancel(value)
        }

    }

    revertInitial(value: RevertValue): Observable<any> {
        const lastValidState = value.declarationForm.get('_PRIVATE').get('lastValidState').value
        const subdomain = value.declarationForm.get('_PRIVATE').get('subdomain').value
        const lrn = value.declarationForm.get('_PRIVATE').get('lrn').value


        const openDialogsService = this.injector.get(OpenDialogsService);

        const errorDialogData = {
            response: value.response,
            lastValidState,
        }

        return openDialogsService.openErrorDialog(errorDialogData).pipe(tap(() => {

            this.onCommonMessage(value.declarationForm, value.response)

            if (lastValidState === 'Pre-Submitted') {
                this.deleteForm(lrn).subscribe()
            } else {
                this.saveForm(subdomain, value.declarationForm);
            }

            this.revertCurrentPath()

        }))


    }

    revertEdit(value: RevertValue): Observable<any> {

        const lastValidState = value.declarationForm.get('_PRIVATE').get('lastValidState').value

        const openDialogsService = this.injector.get(OpenDialogsService);

        const errorDialogData = {
            response: value.response,
            lastValidState
        }

        return openDialogsService.openErrorDialog(errorDialogData).pipe(tap(() => {

            //We need access to declarationFormServic, show we let the actual component handle the revert
            //here.. with cancelEdit()
            this.mainBroadcaster.revertToPreviousState()
            this.revertCurrentPath()

        }))


    }

    revertCancel(value: RevertValue): Observable<any> {


        const lastValidState = value.declarationForm.get('_PRIVATE').get('lastValidState').value
        const subdomain = value.declarationForm.get('_PRIVATE').get('subdomain').value
        const lrn = value.declarationForm.get('_PRIVATE').get('lrn').value


        const openDialogsService = this.injector.get(OpenDialogsService);

        const errorDialogData = {
            response: value.response,
            lastValidState
        }
        return openDialogsService.openErrorDialog(errorDialogData).pipe(tap(() => {

            this.onCommonMessage(value.declarationForm, value.response)

            if (lastValidState === 'Pre-Submitted') {
                this.deleteForm(lrn).subscribe()
            } else {
                this.saveForm(subdomain, value.declarationForm);

            }
            this.revertCurrentPath()

        }))
    }
}