import { AbstractControl, FormControl } from "@angular/forms";
import { VehicleArrivalModel } from "./vehicleArrival.model";

export class VehicleArrivalForm {
    [key: string]: AbstractControl;

    _arrivalDate = new FormControl();
    DeclarationReferenceNumber = new FormControl();

    LocalReferenceNumber = new FormControl();
    Fallbackflag = new FormControl();
    FallbackDocumentReference = new FormControl();
    FallbackOffice = new FormControl();
    RegistrationDate = new FormControl();
    VehicleMovementMeans = new FormControl();
    TransportVehicleRegistrationPlate = new FormControl();
    TransportVehicleCountry = new FormControl();
    DurationInDays = new FormControl();
    OfficeOfOrigin = new FormControl();
    CountryOfOrigin = new FormControl();

    constructor(value: VehicleArrivalModel) {
        this._arrivalDate.setValue(value._arrivalDate);
        this.DeclarationReferenceNumber.setValue(value.DeclarationReferenceNumber);

        this.LocalReferenceNumber.setValue(value.LocalReferenceNumber);
        this.Fallbackflag.setValue(value.Fallbackflag);
        this.FallbackDocumentReference.setValue(value.FallbackDocumentReference);
        this.FallbackOffice.setValue(value.FallbackOffice);
        this.RegistrationDate.setValue(value.RegistrationDate);
        this.VehicleMovementMeans.setValue(value.VehicleMovementMeans);
        this.TransportVehicleRegistrationPlate.setValue(value.TransportVehicleRegistrationPlate);
        this.TransportVehicleCountry.setValue(value.TransportVehicleCountry);
        this.DurationInDays.setValue(value.DurationInDays);
        this.OfficeOfOrigin.setValue(value.OfficeOfOrigin);
        this.CountryOfOrigin.setValue(value.CountryOfOrigin);

    }
}
