<div mat-dialog-title class="primary" fxLayout="row">
    <div>{{ titleName }} - {{ customsOffice?.ReferenceNumber }}</div>
    <!--  <div>
                <h1><small>MRN </small>{{ mrn }}</h1>
            </div> -->
    <div>
        <button mat-icon-button (click)="dialogRef.close()">
            <mat-icon> highlight_off</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <!-- First Column -->
    <div style="width: 100%">
        <mat-card class="zone zone-value flex-column" fxLayout="column" style="margin-top: -32px; padding-bottom: 48px">
            <div class="customs-tabs" style="position: relative">
                <mat-tab-group #tabGroup class="main-tab-group mt-2 mx-6" (selectedTabChange)="tabChanged($event)"
                    [(selectedIndex)]="selectedIndex">
                    <mat-tab *ngFor="
                            let location of customsOffice?.CustomsOfficeLsd;
                            let region;
                            of: region?.CountryRegionLsd;
                            let i = index
                        ">
                        <ng-template mat-tab-label>{{
                            getLanguage(location.LanguageCode)
                            }}</ng-template>

                        <div class="tab-content" style="
                                width: 100%;
                                font-size: 0.9em;
                                background: white !important;
                            " cdkScrollable fusePerfectScrollbar>
                            <div style="width: 100%; overflow:hidden;">
                                <app-customs-dialog-content [co]="customsOffice" [region]="region"
                                    [index]="i"></app-customs-dialog-content>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card>
    </div>

    <!-- Second Column Send Only -->
</div>
<!-- <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
    <button
        style="width: 25% !important"
        mat-raised-button
        [ngClass]="color1"
        (mouseenter)="changeStyle1($event)"
        (mouseleave)="changeStyle1($event)"
        (click)="cancel()"
    >
        Ακυρο
    </button>
    <button
        style="width: 25% !important"
        mat-raised-button
        [ngClass]="color2"
        (mouseenter)="changeStyle2($event)"
        (mouseleave)="changeStyle2($event)"
        (click)="setExit()"
    >
        Τελωνείο Εξόδου
    </button>
</mat-dialog-actions>
 -->