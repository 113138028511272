import { FormControl, Validators } from '@angular/forms';
import { Traconco1Model } from './traconco1.model';

export class Traconco1Form {
    [key: string]: FormControl;

    NamCO17 = new FormControl();
    StrAndNumCO122 = new FormControl();
    PosCodCO123 = new FormControl();
    CitCO124 = new FormControl();
    CouCO125 = new FormControl();
    NADLNGCO = new FormControl();
    TINCO159 = new FormControl();
    SpeMenCO408 = new FormControl();
    TinTypCO1009 = new FormControl();
    ConsignorObject = new FormControl();

    constructor(value: Traconco1Model) {
        this.NamCO17.setValue(value.NamCO17);
        this.StrAndNumCO122.setValue(value.StrAndNumCO122);
        this.PosCodCO123.setValue(value.PosCodCO123);
        this.CitCO124.setValue(value.CitCO124);
        this.CouCO125.setValue(value.CouCO125);
        this.NADLNGCO.setValue(value.NADLNGCO);
        this.TINCO159.setValue(value.TINCO159);
        this.SpeMenCO408.setValue(value.SpeMenCO408);
        this.TinTypCO1009.setValue(value.TinTypCO1009);
        this.ConsignorObject.setValue(value.ConsignorObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);

        //this.TINCO159.setValidators(Validators.required)
    }
}
