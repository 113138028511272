import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy,
    Input,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { ApiService } from 'app/services/api.service';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    mergeMap,
    pairwise,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { FormDisabledItem, MainBroadcasterService } from 'app/services/broadcaster.service';

import { AutoCompConfigValues } from '../../form-fields/custom-autocomplete/config.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { BaseBuildingComponent } from '../../_baseComponent/base.component';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { Custom } from 'app/main/apps/customs/customs.model';

@Component({
    selector: 'app-customs-control',
    templateUrl: './customs-control.component.html',
    styleUrls: ['./customs-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomsControlComponent
    extends BaseBuildingComponent
    implements OnInit, OnDestroy {
    @ViewChild('countryInput') _countryInput: any;

    @Input() outputName: boolean;
    @Input() customsGroup: FormGroup;
    @Input() controlName: string;
    @Input() showCountry: boolean;
    @Input() showCustomsName: boolean;
    @Input() showAlterName: boolean;
    @Input() setDefault: boolean;
    @Input() formDisabled: boolean;
    @Input() resetStyle: boolean;
    @Input() classes: string;
    @Input() appearance: string;
    @Input() OfficeNameStyle: string;

    @Input() tabindex: string;
    @Input() trapFocus: boolean;
    @Input() required: boolean;
    @Input() showTooltip: boolean;

    @Output() changeLine: EventEmitter<void>;


    customs: Custom[] = [];
    config: AutoCompConfigValues = {
        output: 'ReferenceNumber',
        menuOptions: {
            options: ['CustomsOfficeName', 'ReferenceNumber']
        }
    };
    officeName: string;
    CustomsOfficeName: string;

    activeSubdomain$: Observable<string>;
    disabled$: Observable<FormDisabledItem>;

    get control(): FormControl {
        return this.customsGroup.get(this.controlName) as FormControl;
    }
    //===============================================================

    constructor(
        protected hostElement: ElementRef,
        protected cf: ExtCustomFunctions,
        private dbQuery: ApiService,
        private openDialogs: OpenDialogsService,
        private mainBroadcaster: MainBroadcasterService,
        private cd: ChangeDetectorRef
    ) {
        super(hostElement, cf);

        this.changeLine = new EventEmitter();

        this.activeSubdomain$ = this.mainBroadcaster.activeSubdomain$;
        this.disabled$ = this.mainBroadcaster.formDisabled$;

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.showCountry) {
            console.log(
                'CUSTOMSCONTROL customs ' + this.controlName + ' showCountry',
                this.showCountry
            );
            const countryCode = this.customsGroup.get('_countryCode');


            console.log('CUSTOMSCONTROL ON CHANGES', this.control.value);
            console.log('CUSTOMSCONTROL ON CHANGES', countryCode.value);
            const formDisabled$ = this.activeSubdomain$.pipe(
                mergeMap(subdomain =>
                    this.disabled$.pipe(map(disabled => disabled[subdomain]))
                )
            );

            if (countryCode) {
                countryCode.valueChanges
                    .pipe(
                        distinctUntilChanged(),
                        debounceTime(300),
                        switchMap(country => {
                            console.log('COUNTRY CODE ON CUSTOMSCONTROL CHANGED', country);
                            return combineLatest([
                                this.dbQuery.get_customs(country),
                                formDisabled$
                            ]).pipe(take(1));
                        }),
                        takeUntil(this._destroy)
                    )
                    .subscribe(([customs, formDisabled]) => {
                        console.log('CUSTOMS ON CUSTOMSCONTROL', customs);
                        if (!formDisabled) {
                            if (customs === null) {
                                this.customsGroup.get(this.controlName).setValue(null);
                                this.customsGroup.get(this.controlName).disable();
                            } else {
                                this.customsGroup.get(this.controlName).enable();
                                this.customs = customs;
                            }
                        }
                    });
            }

        }
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.

        this.required !== undefined ? true : false

        if (this.outputName) {
            this.config.output = this.showAlterName ? 'CustomsOfficeNameAlt' : 'CustomsOfficeName';
        }

        if (this.showAlterName) {
            this.showCustomsName = true;
            this.officeName = 'CustomsOfficeNameAlt';
            this.config.menuOptions.options.splice(0, 1, 'CustomsOfficeNameAlt')
        }
        else {
            this.officeName = 'CustomsOfficeName';

        }


        const countryCode = this.customsGroup.get('_countryCode');

        if (countryCode?.value) {
            this.getCustoms(countryCode.value, this.control.value).subscribe();
        } else {
            this.getCustoms('GR', this.control.value).subscribe();
        }



        //This is to update the value in the DOM after it has changed
        this.control.valueChanges
            .pipe(startWith(this.control.value), pairwise(), takeUntil(this._destroy))
            .subscribe(([prevValue, currentValue]) => {
                if (prevValue !== currentValue) {
                    //Focus element to show the change
                    //  this._first.inputRef.nativeElement.focus();

                    console.log('CUSTOMS CURRENT VALUE', currentValue);

                    this.CustomsOfficeName = this.customs?.filter(
                        e => e.ReferenceNumber === currentValue
                    )[0]?.[this.officeName];
                } else {
                    this.CustomsOfficeName = this.customs?.filter(
                        e => e.ReferenceNumber === prevValue
                    )[0]?.[this.officeName];
                }
            });
    }

    openDialog() {
        const officeRefNumber = this.customsGroup.get(this.controlName).value;

        this.openDialogs.openCustomsSearch().subscribe();

    }

    getCustoms(countryCode: string, customs: string): Observable<any> {

        return this.mainBroadcaster.declarationSettings$.pipe(
            switchMap((settings: DeclarationSettingsModel) => {

                return this.dbQuery.get_customs(countryCode).pipe(
                    tap((data: any[]) => {
                        this.customs = data;

                        if (this.setDefault && !customs) {
                            let defaultCustomsCode = settings.export_customs;

                            if (this.outputName) {
                                const defaultCustoms = this.customs.find(e => e.ReferenceNumber === defaultCustomsCode)
                                defaultCustomsCode = this.showAlterName ? defaultCustoms?.CustomsOfficeNameAlt : defaultCustoms?.CustomsOfficeName;
                            }
                            this.customsGroup.get(this.controlName).setValue(defaultCustomsCode);
                        }

                        this.CustomsOfficeName = this.customs.filter(
                            e => e.ReferenceNumber === customs
                        )[0]?.[this.officeName];

                        this.cd.detectChanges();
                    }),
                    take(1)
                );
            }),

            take(1))

    }

    onEnter() {
        this.changeLine.emit()
        console.log("customs-control onEnter")
    }

}
