import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { GuarefrefForm } from '../guarefref/guarefref-form.model';
import { GuarefrefModel } from '../guarefref/guarefref.model';
import { GuaguaModel } from './guagua.model';

export class GuaguaForm {
    [key: string]: AbstractControl;

    GuaTypGUA1: FormControl;
    GUAREFREF: FormArray;

    constructor(value: GuaguaModel) {
        this.GuaTypGUA1 = new FormControl(value.GuaTypGUA1);

        this.GUAREFREF = new FormArray([]);

        if (value.GUAREFREF) {
            value.GUAREFREF.forEach((guarefref: GuarefrefModel) => {
                this.GUAREFREF.push(new FormGroup(new GuarefrefForm(new GuarefrefModel(guarefref))));
            });
        } else {
            this.GUAREFREF.push(new FormGroup(new GuarefrefForm(new GuarefrefModel())));
        }
    }
}
