import { HeaderClassPipeModule } from 'app/services/pipes/headerClass.pipe';
import { DeclarationStatePipeModule } from 'app/services/pipes/declaration-state.pipe';
import { CodeToDescPipeModule } from 'app/services/pipes/codeToDesc.pipe';
import { FormFieldsModule } from './../../main-building-blocks/form-fields/form-fields.module';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { AddDelButtonsModule } from 'app/main/main-building-blocks/_scams/add-del-buttons/add-del-buttons.module';
import { EAitisiComponent } from './e-aitisi.component';
import { EAitisiService } from './e-aitisi.service';

@NgModule({
    declarations: [EAitisiComponent],
    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        AddDelButtonsModule,
        CodeToDescPipeModule,
        HeaderClassPipeModule,
        DeclarationStatePipeModule,

    ],
    providers: [EAitisiService, { provide: MAT_DIALOG_DATA, useValue: {} }, { provide: MatDialogRef, useValue: {} }]
})
export class EAitisiModule { }
