//import { Player } from "../../player/_models/player.model";
//import { MyGroup } from "../../my-group/_models/mygroup.model";
import { GuaguaModel } from 'app/main/apps/transit/tir/_models/guagua/guagua.model';
import { Trapripc1Model } from 'app/main/apps/transit/tir/_models/trapripc1/trapripc1.model';
import { CusofftrarnsModel } from 'app/main/apps/transit/_models/cusofftrarns/cusofftrarns.model';
import { User, _pelatesItem, international_customersItem } from 'app/model/api-model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { CusoffexpertModel } from '.';
import { CusoffexiextModel } from '.';
import { Statrep385Model } from '.';
import { IdewarModel } from '.';
import { TradatModel } from '.';
import { DelterModel } from '.';
import { TradecModel } from '.';
import { ConresersModel } from '.';
import { TraconceModel } from '.';
import { Traexpex1Model } from '.';
import { HeaheaModel } from '.';
import { SeainfsilModel } from '.';
import { ItiModel } from '.';
import { DefPosPayModel } from '.';
import { GooIteGdsModel } from '.';

export class Declaration {
    //This is the model of the object that will be passed on our form in case we have some initial values that need to be inserted
    _PRIVATE?: PrivateControlsModel;



    SynIdeMES1?: string;
    SynVerNumMES2?: string;
    MesSenMES3?: string;
    MesRecMES6?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    TesIndMES18?: string;
    IntConRefMES11?: string;
    // PriMES15?: string;
    // ComAgrIdMES17?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    // ComAccRefMES21?: string;

    //
    HEAHEA?: HeaheaModel;
    TRAEXPEX1?: Traexpex1Model;
    TRACONCE1?: TraconceModel;
    CUSOFFEXPERT?: CusoffexpertModel;
    CUSOFFEXIEXT?: CusoffexiextModel;
    CONRESERS?: ConresersModel;
    SEAINFSLI?: SeainfsilModel;
    GoodsItemsArray?: GooIteGdsModel[];
    GOOITEGDS?: GooIteGdsModel[];
    ITI?: ItiModel[];
    TRADEC?: TradecModel;
    DELTER?: DelterModel;
    TRADAT?: TradatModel;
    DEFPOSPAY?: DefPosPayModel[];
    IDEWAR?: IdewarModel;
    STATREP385?: Statrep385Model;


    ///TIR===============================
    TRAPRIPC1?: Trapripc1Model; //////////////
    CUSOFFTRARNS?: CusofftrarnsModel[];
    GUAGUA?: GuaguaModel[]; ///////////////////
    //
    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);


        this._PRIVATE = value?._PRIVATE || null;

        this.SynIdeMES1 = value?.SynIdeMES1 || null;
        this.SynVerNumMES2 = value?.SynVerNumMES2 || null;
        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.IntConRefMES11 = value?.IntConRefMES11 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        //this.PriMES15 = value?.PriMES15 || null;
        //this.ComAgrIdMES17 = value?.ComAgrIdMES17 || null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = value?.MesTypMES20 || 'CC515A';
        //this.ComAccRefMES21 = value?.ComAccRefMES21 || null;
        this.HEAHEA = value?.HEAHEA || {};
        this.TRAEXPEX1 = value?.TRAEXPEX1 || {};
        this.TRACONCE1 = value?.TRACONCE1 || {};
        this.CUSOFFEXPERT = value?.CUSOFFEXPERT || {};
        this.CUSOFFEXIEXT = value?.CUSOFFEXIEXT || {};
        this.CONRESERS = value?.CONRESERS || {};
        this.SEAINFSLI = value?.SEAINFSLI || {};
        this.GOOITEGDS = value?.GOOITEGDS || [];
        this.ITI = value?.ITI || [];
        this.TRADEC = value?.TRADEC || {};
        this.DELTER = value?.DELTER || {};
        this.TRADAT = value?.TRADAT || {};
        this.DEFPOSPAY = value?.DEFPOSPAY || [];
        this.IDEWAR = value?.IDEWAR || {};
        this.STATREP385 = value?.STATREP385 || {};
        this.TRAPRIPC1 = value?.TRAPRIPC1;
        this.CUSOFFTRARNS = value?.CUSOFFTRARNS;
        this.GUAGUA = value?.GUAGUA;
    }
}
