import { AbstractControl, FormControl } from "@angular/forms";
import { DeliveryPlaceModel } from "./deliveryPlace.model";

export class DeliveryPlaceForm {
    [key: string]: AbstractControl;

    StreetName: FormControl;
    StreetNumber: FormControl;
    Municipality: FormControl;
    PostCode: FormControl;
    Prefecture: FormControl;

    constructor(value: DeliveryPlaceModel) {
        this.StreetName = new FormControl(value.StreetName, { updateOn: "blur" });
        this.StreetNumber = new FormControl(value.StreetNumber, { updateOn: "blur" });
        this.Municipality = new FormControl(value.Municipality, { updateOn: "blur" });
        this.PostCode = new FormControl(value.PostCode, { updateOn: "blur" });
        this.Prefecture = new FormControl(value.Prefecture, { updateOn: "blur" });

    }
}
