import { FormControl } from '@angular/forms';
import { Traconsec029Model } from './traconsec029.model';

export class Traconsec029Form {
    [key: string]: FormControl;

    NameTRACONSEC033 = new FormControl();
    StrNumTRACONSEC035 = new FormControl();
    PosCodTRACONSEC034 = new FormControl();
    CitTRACONSEC030 = new FormControl();
    CouCodTRACONSEC031 = new FormControl();
    TRACONSEC029LNG = new FormControl();
    TINTRACONSEC036 = new FormControl();
    TINTypTRACONSEC037 = new FormControl();

    ExporterObject = new FormControl();

    constructor(value: Traconsec029Model) {
        this.NameTRACONSEC033.setValue(value.NameTRACONSEC033);
        this.StrNumTRACONSEC035.setValue(value.StrNumTRACONSEC035);
        this.PosCodTRACONSEC034.setValue(value.PosCodTRACONSEC034);
        this.CitTRACONSEC030.setValue(value.CitTRACONSEC030);
        this.CouCodTRACONSEC031.setValue(value.CouCodTRACONSEC031);
        this.TRACONSEC029LNG.setValue(value.TRACONSEC029LNG);
        this.TINTRACONSEC036.setValue(value.TINTRACONSEC036);
        this.TINTypTRACONSEC037.setValue(value.TINTypTRACONSEC037);
        this.ExporterObject.setValue(value.ExporterObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
