import { FormControl } from '@angular/forms';
import { Trapripc1Model } from './trapripc1.model';

export class Trapripc1Form {
    [key: string]: FormControl;

    NamPC17: FormControl;
    StrAndNumPC122: FormControl;
    PosCodPC123: FormControl;
    CitPC124: FormControl;
    CouPC125: FormControl;
    NADLNGPC: FormControl;
    TINPC159: FormControl;
    HITPC126: FormControl;
    TinTypPC1009: FormControl;

    TrapriObject: FormControl;

    constructor(value: Trapripc1Model) {
        this.NamPC17 = new FormControl(value.NamPC17, { updateOn: 'blur' });
        this.StrAndNumPC122 = new FormControl(value.StrAndNumPC122, { updateOn: 'blur' });
        this.PosCodPC123 = new FormControl(value.PosCodPC123, { updateOn: 'blur' });
        this.CitPC124 = new FormControl(value.CitPC124, { updateOn: 'blur' });
        this.CouPC125 = new FormControl(value.CouPC125);
        this.NADLNGPC = new FormControl(value.NADLNGPC);
        this.TINPC159 = new FormControl(value.TINPC159, { updateOn: 'blur' });
        this.HITPC126 = new FormControl(value.HITPC126, { updateOn: 'blur' });
        this.TinTypPC1009 = new FormControl(value.TinTypPC1009);
        this.TrapriObject = new FormControl(value.TrapriObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
