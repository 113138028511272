import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[trapFocus]'
})
export class TrapFocusDirective implements AfterViewInit {
    constructor(private el: ElementRef) { }

    ngAfterViewInit() {
        this.trapFocus(this.el.nativeElement);

        console.log("trapFocus el", this.el.nativeElement)
    }
    /**
     * For this to work we have to set and then check for inputs with the class 'trapFocus
     * We need at least two elements, the first and last element of our form
     * When our active element is equal th our last element the next focus goes to our first element again'
     *
     * @param element
     */
    trapFocus(element) {
        const focusableEls1 = element.querySelectorAll(
            // 'a[href], textarea, input[type="text"],' +
            //       'input[type="radio"], input[type="checkbox"], select,' +
            '.trapFocus'
        );
        console.log('TrapFocus Elements', focusableEls1);


        const focusableEls = Array.from(focusableEls1).filter((el: any) => !el.disabled);

        if (focusableEls.length > 1) {

            const firstFocusableEl: any = focusableEls[0];
            const lastFocusableEl: any = focusableEls[focusableEls.length - 1];



            element.addEventListener('keydown', function (e) {
                const isTabPressed = e.keyCode === 9; // isTabPressed
                if (!isTabPressed) {
                    return;
                }

                //console.log('TrapFocus document.activeElement', document.activeElement);
                //console.log('TrapFocus firstFocusableEl', firstFocusableEl);
                //console.log('TrapFocus lastFocusableEl', lastFocusableEl);

                if (e.shiftKey) {
                    /* shift + tab */
                    if (document.activeElement === firstFocusableEl) {
                        lastFocusableEl.focus();
                        e.preventDefault();
                    }
                } /* tab */ else {
                    if (document.activeElement === lastFocusableEl) {
                        firstFocusableEl.focus();
                        e.preventDefault();
                    }
                }

            });
        }
    }
}
