import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TsipouraForm } from 'app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura-form.model';
import { TsipouraParameters } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura-defk-create.service';
import { TsipouraService } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura.service';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'tsipoura-parameters',
    templateUrl: './tsipoura-parameters.component.html',
    styleUrls: ['./tsipoura-parameters.component.scss']
})
export class TsipouraParametersComponent implements OnInit {

    @Input() params: TsipouraParameters;
    tsipouraForm$: Observable<FormGroup<TsipouraForm>>

    workHoursList: string[] = [];

    private _destroy = new Subject<void>();
    constructor(private tsipouraService: TsipouraService) { }

    ngOnInit(): void {
        this.tsipouraForm$ = this.tsipouraService.tsipouraForm$
        console.log("TsipouraParametersComponent ngOnInit this.params.workHoursPerDay", this.params.workHoursPerDay)
        console.log("TsipouraParametersComponent ngOnInit split", this.params.workHoursPerDay.split(';'))
        this.workHoursList = this.params.workHoursPerDay.split(';')
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }
}
