import { FormFieldFunctions } from './form-field-functions.service';
import { DateSelectModule } from './date-select/date-select.module';
import { LanguageSelectModule } from 'app/main/main-building-blocks/form-fields/language-select/language-select.module';
import { CountrySelectModule } from './country-select/country-select.module';
import { NgModule } from '@angular/core';
import { AutoCompleteControlModule } from './custom-autocomplete/autocomplete-control.module';


export const modules = [

    CountrySelectModule,
    LanguageSelectModule,
    AutoCompleteControlModule,
    DateSelectModule
]
@NgModule({
    declarations: [],
    imports: modules,
    exports: modules,
    providers: [FormFieldFunctions]
})
export class FormFieldsModule { }
