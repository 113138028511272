import { Directive, ElementRef, Renderer2, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[boldParenthesis]'
})
export class BoldParenthesisDirective implements OnChanges {

    @Input() content: string;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        console.log("BOLDPARENTHESIS DIRECTIVE td", el)
    }

    ngOnChanges() {

        this.boldTextInParenthesis();
    }

    ngOnInit() {

        console.log("BOLDPARENTHESIS DIRECTIVE content", this.content)
    }
    boldTextInParenthesis(): void {
        if (this.content) {
            //replace all occurrences of (A000) with <strong> (A000)</strong>
            const formattedContent = this.content.replace(/\(([A-Z]\d{3})\)/g, '<strong> ($1)</strong>');
            this.renderer.setProperty(this.el.nativeElement, 'innerHTML', formattedContent);
        }
    }
}
