import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { CarsCalculationDataForm } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles-form.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { genericItem } from 'app/model/api-model';
import { Observable, tap, of, combineLatest, startWith, map, filter, switchMap, takeUntil } from 'rxjs';
import moment, { Moment } from 'moment';
import { VehiclesQueriesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicle-queries.service';
import { CarPriceRange, HybridReductionRate, ExciseHelpersEuroClass, Co2EmissionCode } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.interfaces';
import { ca } from 'date-fns/locale';

@Injectable()
export class CarsService {

    priceRanges: CarPriceRange[] = []
    hybridRates: HybridReductionRate[] = []
    euroClassOptions: ExciseHelpersEuroClass[] = []
    carChassisOptions: genericItem[] = [];


    constructor(private vehiclesQueriesService: VehiclesQueriesService, private cf: ExtCustomFunctions) {


        this.vehiclesQueriesService.priceRanges$.pipe(takeUntil(this.vehiclesQueriesService.destroy$)).subscribe((priceRanges) => this.priceRanges = priceRanges)
        this.vehiclesQueriesService.hybridRates$.pipe(takeUntil(this.vehiclesQueriesService.destroy$)).subscribe((hybridRates) => this.hybridRates = hybridRates)

        this.vehiclesQueriesService.euroClassOptions$.pipe(takeUntil(this.vehiclesQueriesService.destroy$)).subscribe((euroClassOptions) => this.euroClassOptions = euroClassOptions)
    }


    calculateTaxes(vehiclesForm: FormGroup) {

        const retailValue = vehiclesForm.get('retailValueBeforeTax').value || 0;

        const calculationData = vehiclesForm.get('carsCalculationData') as FormArray;
        const totalReductionRate = vehiclesForm.get('totalReductionRate').value || 0;
        const carCertificateIncreaseRate = vehiclesForm.get('carCertificateIncreaseRate').value || 0
        const caravanIncreaseRate = vehiclesForm.get('caravanIncreaseRate').value || 0
        const co2IncreaseRate = vehiclesForm.get('co2IncreaseRate').value || 0;



        this.splitRetailValue(calculationData, retailValue)
        this.reduceRetailValue(calculationData, totalReductionRate)
        this.calculateIncreasedTaxRateForCategory(calculationData, carCertificateIncreaseRate)
        if (+vehiclesForm.get('vehicleType').value === 10) {
            this.calculateIncreasedTaxRateForCaravan(calculationData, caravanIncreaseRate)
        }
        this.calculateIncreasedTaxRateForCo2(vehiclesForm, calculationData, co2IncreaseRate)
        this.calculateSubTax(vehiclesForm, calculationData)

        this.calculateTotals(vehiclesForm)
    }

    splitRetailValue(calculationData, retailValue) {

        const result = [];
        for (const range of this.priceRanges) {
            const difference = range.price_range;
            console.log("splitRetailValue difference", difference)

            if (retailValue <= difference) {
                result.push(retailValue);
                break;
            } else {
                result.push(difference);
                retailValue -= difference;
            }
        }

        for (let i = calculationData.length - 1; i >= 0; i--) {
            calculationData.removeAt(i);
        }

        result.forEach((item, index) => {
            if (!calculationData.controls[index]) {
                calculationData.push(new FormGroup(new CarsCalculationDataForm({ splitRetailValue: item, taxRateInitial: this.priceRanges[index].initial_tax_rate })))
            } else {
                calculationData.controls[index].get('splitRetailValue').setValue(item);
                calculationData.controls[index].get('taxRateInitial').setValue(this.priceRanges[index].initial_tax_rate);
            }
        });
    }
    reduceRetailValue(calculationData, totalReductionRate) {

        calculationData.controls.forEach((group: FormGroup) => {
            const splitRetailValue = group.get('splitRetailValue').value || 0;
            const reduction = splitRetailValue * totalReductionRate;
            const result = splitRetailValue - reduction;


            group.get('reducedRetailValue').setValue((result));
        })
    }
    calculateIncreasedTaxRateForCategory(calculationData, carCertificateIncreaseRate) {

        calculationData.controls.forEach((group: FormGroup) => {
            const taxRateInitial = +group.get('taxRateInitial').value || 0;
            const taxRateIncreasedByCategory = taxRateInitial * carCertificateIncreaseRate;
            const result = taxRateInitial + taxRateIncreasedByCategory
            console.log("carsService calculateIncreasedTaxRateForCategory", taxRateInitial, carCertificateIncreaseRate, taxRateIncreasedByCategory)
            console.log("carsService calculateIncreasedTaxRateForCategory", result)
            group.get('taxRateIncreasedByCategory').setValue(result);
        })

    }
    calculateIncreasedTaxRateForCaravan(calculationData, caravanIncreaseRate) {

        calculationData.controls.forEach((group: FormGroup) => {
            const taxRateIncreasedByCategory = +group.get('taxRateIncreasedByCategory').value || 0;
            const taxRateIncreasedByCaravan = taxRateIncreasedByCategory * caravanIncreaseRate;
            const result = taxRateIncreasedByCategory + taxRateIncreasedByCaravan
            group.get('taxRateIncreasedByCaravan').setValue(result);
        })

    }
    calculateIncreasedTaxRateForCo2(vehiclesForm, calculationData, carCertificateIncreaseRate) {

        calculationData.controls.forEach((group: FormGroup) => {
            const taxRateIncreasedByCategory = +group.get('taxRateIncreasedByCategory').value || 0;
            const taxRateIncreasedByCo2 = taxRateIncreasedByCategory * carCertificateIncreaseRate;
            const result = taxRateIncreasedByCategory + taxRateIncreasedByCo2

            group.get('taxRateIncreasedByCo2').setValue(result);
        })

    }

    calculateSubTax(vehiclesForm, calculationData) {

        calculationData.controls.forEach((group: FormGroup) => {
            const reducedRetailValue = +group.get('reducedRetailValue').value || 0;
            const taxRateIncreasedByCo2 = +group.get('taxRateIncreasedByCo2').value || 0;
            const taxRateIncreasedByCaravan = +group.get('taxRateIncreasedByCaravan').value || 0;
            let result = reducedRetailValue * taxRateIncreasedByCo2

            if (vehiclesForm.get('vehicleType').value === '10') {

                result = reducedRetailValue * taxRateIncreasedByCaravan
            }
            group.get('subGroupTax').setValue(result);
        })
    }

    calculateMonths(firstAllowanceDate: Date, calculationOfTaxesDate: Date) {

        if (firstAllowanceDate && calculationOfTaxesDate) {
            // Ensure calculationOfTaxesDate is always the earlier date and firstAllowanceDate the later date
            if (calculationOfTaxesDate > firstAllowanceDate) {
                [calculationOfTaxesDate, firstAllowanceDate] = [firstAllowanceDate, calculationOfTaxesDate];
            }

            let months = (firstAllowanceDate.getFullYear() - calculationOfTaxesDate.getFullYear()) * 12 + firstAllowanceDate.getMonth() - calculationOfTaxesDate.getMonth();

            // If the day of the month of firstAllowanceDate is greater than or equal to the day of the month of calculationOfTaxesDate, add 1 month
            if (firstAllowanceDate.getDate() >= calculationOfTaxesDate.getDate()) {
                months++;
            }

            console.log("carsService calculateMonths", months)

            return months > 192 ? 192 : months;
        }
        return null
    }

    calculateMileageReduction(vehiclesForm: FormGroup, months: number, mileage: number) {

        const klmPerMonth = 1250; //15000 per year
        const excessKlm = 500;
        const reductionRate = 0.1;
        const maxReductionRate = 0.1;

        const expectedMileage = months * klmPerMonth;
        const remainingMileage = mileage - expectedMileage;


        //we substract 10% for every 500 of remaining mileage
        let rate = remainingMileage / excessKlm * reductionRate / 100;

        console.log('carsService calculateMileageReduction expectedMileage', expectedMileage)
        console.log('carsService calculateMileageReduction remainingMileage', remainingMileage)
        console.log('carsService calculateMileageReduction rate', rate)


        if (rate > maxReductionRate) {
            rate = maxReductionRate;
        } else if (rate < 0) {
            rate = 0;
        }

        console.log('carsService calculateMileageReduction final rate', rate)

        vehiclesForm.get('mileageReductionRate').setValue(rate);

        this.calculateTotalReduction(vehiclesForm);


    }

    calculateTotalReduction(vehiclesForm: FormGroup) {


        const ageReductionRate = vehiclesForm.get('ageReductionRate').value || 0;
        const mileageReductionRate = vehiclesForm.get('mileageReductionRate').value || 0;

        let reduction = (((ageReductionRate * 100) + (mileageReductionRate * 100)) - (ageReductionRate * 100) * mileageReductionRate) / 100;


        //Max Reduction cannot exceed 95%
        reduction = reduction > 0.95 ? 0.95 : reduction;

        console.log("carsService calculateTotalReduction", reduction)

        vehiclesForm.get('totalReductionRate').setValue(reduction);

        this.calculateTaxes(vehiclesForm);

    }

    calculateTotals(vehiclesForm: FormGroup) {

        const carsCalculationData = vehiclesForm.get('carsCalculationData') as FormArray;

        const totals = carsCalculationData.controls.reduce((acc, group: FormGroup) => {
            acc.reducedValueTotal += +group.get('reducedRetailValue').value || 0;
            acc.subTotal += +group.get('subGroupTax').value || 0;
            return acc;
        }, {
            reducedValueTotal: 0,
            subTotal: 0,
        });

        const carsTotals = vehiclesForm.get('carsTotals') as FormGroup;

        carsTotals.get('subTotal').setValue(this.cf.formatDecimalString(totals.subTotal, 2));

        const envTax = +carsTotals.get('environmentTax').value || 0;
        const hybridReduction = (totals.subTotal * +vehiclesForm.get('hybridReductionRate').value) || 0;
        const caravanReduction = (totals.subTotal * +vehiclesForm.get('caravanReductionRate').value) || 0;


        let taxTotal = totals.subTotal - hybridReduction + envTax;

        if (+vehiclesForm.get('vehicleType').value === 10) {
            taxTotal = taxTotal - caravanReduction;
        }

        console.log("publicUseRate", vehiclesForm.get('publicUseRate').value)
        console.log("publicUseRate vehicleUse", vehiclesForm.get('vehicleUse').value)

        if (vehiclesForm.get('vehicleUse').value === 'public') {
            const publicUseRate = this.setPublicUseRate(vehiclesForm);
            taxTotal = taxTotal * publicUseRate || 0;

            carsTotals.get('publicUseTotal').setValue(taxTotal);


        }
        carsTotals.get('reducedValueTotal').setValue(totals.reducedValueTotal);
        carsTotals.get('hybridReduction').setValue(hybridReduction);
        carsTotals.get('caravanReduction').setValue(caravanReduction);
        carsTotals.get('taxTotal').setValue(taxTotal);

    }
    setPublicUseRate(vehiclesForm: FormGroup): number {
        const publicUseRate = this.euroClassOptions.find((item) => item.code === vehiclesForm.get('carCertificateCategory').value)?.publicUseRate || 0

        vehiclesForm.get('publicUseRate').setValue(+publicUseRate)
        return +publicUseRate;
    }
    subToRetailValue(vehiclesForm: FormGroup) {

        const retailValueBeforeTax$ = vehiclesForm.get('retailValueBeforeTax').valueChanges.pipe(startWith(vehiclesForm.get('retailValueBeforeTax').value));

        return retailValueBeforeTax$.pipe(
            tap((retailValueBeforeTax) => { console.log("carsService subToRetailValue before filter", retailValueBeforeTax) }),
            filter((retailValueBeforeTax) => Boolean(retailValueBeforeTax)),
            tap((retailValueBeforeTax) => {
                console.log("carsService subToRetailValue", retailValueBeforeTax)
                this.calculateTaxes(vehiclesForm)
            })
        )
    }

    subToVehicleUseValueChange(vehiclesForm: FormGroup): Observable<any> {

        const vehicleUse$ = vehiclesForm.get('vehicleUse').valueChanges.pipe(startWith(vehiclesForm.get('vehicleUse').value));

        return vehicleUse$.pipe(
            tap((vehicleUse) => {
                console.log("carsService subToVehicleUseValueChange", vehicleUse)
                this.calculateTaxes(vehiclesForm)
            })
        )
    }

    subToCarEmissionsValueChanges(vehiclesForm: FormGroup): Observable<Co2EmissionCode> {
        const carbonDioxideEmissions$ = vehiclesForm.get('carbonDioxideEmissions').valueChanges.pipe(startWith(vehiclesForm.get('carbonDioxideEmissions').value));

        return carbonDioxideEmissions$.pipe(
            filter((carbonDioxideEmissions) => Boolean(carbonDioxideEmissions)),
            switchMap((carbonDioxideEmissions) => this.vehiclesQueriesService.getAddTaricCode(carbonDioxideEmissions)),
            tap((co2EmissionCode) => {

                vehiclesForm.get('addTaricCode').setValue(co2EmissionCode.addTaricCode)
                vehiclesForm.get('co2IncreaseRate').setValue(co2EmissionCode.co2_differentiation)
                this.calculateTaxes(vehiclesForm)
            })
        )
    }
    subToCarCertificateValueChanges(vehiclesForm: FormGroup): Observable<string> {

        const carCertificateCategory$ = vehiclesForm.get('carCertificateCategory').valueChanges.pipe(startWith(vehiclesForm.get('carCertificateCategory').value));

        return carCertificateCategory$.pipe(tap((carCertificateCategory) => {

            const category = this.euroClassOptions.find((item) => item.code === carCertificateCategory)
            const publicUseRate = category?.publicUseRate || 0
            const categoryIncreaseRate = category?.categoryIncreaseRate || 0
            const caravanIncreaseRate = category?.caravanIncreaseRate || 0;
            const caravanReductionRate = category?.caravanReductionRate || 0;
            const environmentTax = category?.environment_tax || 0

            vehiclesForm.get('carCertificateIncreaseRate').setValue(categoryIncreaseRate)
            vehiclesForm.get('caravanIncreaseRate').setValue(caravanIncreaseRate)
            vehiclesForm.get('caravanReductionRate').setValue(caravanReductionRate)
            vehiclesForm.get('carsTotals').get('environmentTax').setValue(environmentTax)

            this.calculateTaxes(vehiclesForm)
        }))

    }
    subToMileageValueChanges(vehiclesForm: FormGroup) {
        const mileage$ = vehiclesForm.get('mileage').valueChanges.pipe(startWith(vehiclesForm.get('mileage').value));
        const monthsTotal$ = vehiclesForm.get('monthsTotal').valueChanges.pipe(startWith(vehiclesForm.get('monthsTotal').value));


        return combineLatest([
            monthsTotal$,
            mileage$
        ]).pipe(
            filter(([monthsTotal, mileage]) => Boolean(monthsTotal && mileage)),
            tap(([monthsTotal, mileage]) => {

                console.log("carsService subToMileageValueChanges", monthsTotal, mileage)

                const vehicleUsedFlag = +mileage > 5000 ? 1 : 0

                vehiclesForm.get('vehicleUsedFlag').setValue(vehicleUsedFlag)

                this.calculateMileageReduction(vehiclesForm, monthsTotal, mileage)
            })
        )
    }
    subToDatesValueChanges(vehiclesForm: FormGroup): Observable<any> {

        const firstAllowanceDate$ = vehiclesForm.get('firstAllowanceDate').valueChanges.pipe(startWith(vehiclesForm.get('firstAllowanceDate').value));
        const calculationOfTaxesDate$ = vehiclesForm.get('calculationOfTaxesDate').valueChanges.pipe(startWith(vehiclesForm.get('calculationOfTaxesDate').value));


        return combineLatest([
            firstAllowanceDate$,
            calculationOfTaxesDate$
        ]).pipe(
            filter(([firstAllowanceDate, calculationOfTaxesDate]) => Boolean(firstAllowanceDate && calculationOfTaxesDate)),
            map(([firstAllowanceDate, calculationOfTaxesDate]) => {

                firstAllowanceDate = this.convertMomentToDate(firstAllowanceDate)
                calculationOfTaxesDate = this.convertMomentToDate(calculationOfTaxesDate)

                return [firstAllowanceDate, calculationOfTaxesDate]
            }),
            tap(([firstAllowanceDate, calculationOfTaxesDate]) => {

                const months = this.calculateMonths(firstAllowanceDate, calculationOfTaxesDate)

                vehiclesForm.get('monthsTotal').setValue(months)

            }))
    }
    subToMonthsCarTypeValueChanges(vehiclesForm: FormGroup): Observable<any> {

        const monthsTotal$ = vehiclesForm.get('monthsTotal').valueChanges.pipe(startWith(vehiclesForm.get('monthsTotal').value));
        const chassisType$ = vehiclesForm.get('chassisType').valueChanges.pipe(startWith(vehiclesForm.get('chassisType').value));

        return combineLatest([
            monthsTotal$,
            chassisType$
        ]).pipe(
            filter(([monthsTotal, chassisType]) => Boolean(monthsTotal && chassisType)),

            switchMap(([monthsTotal, chassisType]) => this.vehiclesQueriesService.getReductionRates(monthsTotal, chassisType)),
            tap((reductionRate) => {

                vehiclesForm.get('ageReductionRate').setValue(reductionRate)
                this.calculateTotalReduction(vehiclesForm);
            })
        )

    }
    subToCo2EmissionsHybridValueChanges(vehiclesForm: FormGroup): Observable<any> {

        const carbonDioxideEmissions$ = vehiclesForm.get('carbonDioxideEmissions').valueChanges.pipe(startWith(vehiclesForm.get('carbonDioxideEmissions').value));
        const vehicleType$ = vehiclesForm.get('vehicleType').valueChanges.pipe(startWith(vehiclesForm.get('vehicleType').value));

        return combineLatest([
            carbonDioxideEmissions$,
            vehicleType$
        ]).pipe(
            filter(([carbonDioxideEmissions, vehicleType]) => Boolean(carbonDioxideEmissions && vehicleType)),
            switchMap(([carbonDioxideEmissions, vehicleType]) => {
                //VehicleType 18 is Hybrid
                if (vehicleType === '18') {
                    return this.vehiclesQueriesService.getHybridReductionRates(carbonDioxideEmissions);
                } else {
                    return of(0); // Using of to return 0 reductionRate if hybrid is set to 0
                }
            }),
            tap((reduction) => {
                console.log("carsService subToCo2EmissionsHybridValueChanges red", reduction);
                vehiclesForm.get('hybridReductionRate').setValue(reduction);

                this.calculateTaxes(vehiclesForm);
            })
        );

    }
    convertMomentToDate(date: string | Date | moment.Moment): Date {
        if (date instanceof moment || typeof date === 'string' && moment(date, moment.ISO_8601, true).isValid()) {
            const momentDate = moment(date);
            return momentDate.toDate();
        }
        return date as Date;
    }


}
