<div fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 45%">
            <span>Πρόσθετα Στοιχεία</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="column" mat-dialog-content fxLayoutAlign="flex-start flex-start" style="
    overflow-y: auto;
    flex-grow:1;
    padding: 0 16px;
">


        <div style="width:35%; margin-bottom: 16px;flex:unset;" fxLayout="column" fxLayoutAlign="flex-start center"
            class="group">
            <h2>Αποτελέσματα Ελέγχου</h2>
            <table class="eAitisiHea threeColumns" [formGroup]="conresers">
                <tbody>
                    <tr>
                        <td>Αποτέλεσμα</td>

                        <td>
                            <mat-form-field appearance="outline" class="sc-ul s-c s-4">
                                <autocomplete-control #firstInput formControlName="ConResCodERS16"
                                    [options]="control_results">
                                </autocomplete-control>
                            </mat-form-field>
                        </td>
                        <td>{{conresers.get('ConResCodERS16').value | codeToDesc:control_results}}</td>
                    </tr>
                    <tr>
                        <td>Προθεσμία</td>
                        <td colspan="2">
                            <mat-form-field appearance="outline" class="sc-ul s-l s-16 " style="margin-top: -2%">
                                <date-select formControlName="DatLimERS69" [dateType]="dateType" [minlength]="len">
                                </date-select>
                            </mat-form-field>
                        </td>
                    </tr>


                </tbody>
            </table>
        </div>

        <div style="width:35%; margin-bottom: 16px;flex:unset;" fxLayout="column" fxLayoutAlign="flex-start center"
            class="group">
            <h2>Αριθμός Χειρόγραφης Καταχώρησης</h2>
            <table class="eAitisiHea threeColumns" [formGroup]="heahea">
                <tbody>


                    <tr>
                        <td>Αριθμός</td>
                        <td colspan="2">
                            <mat-form-field appearance="outline" class="sc-ul s-l s-10 ">
                                <input #firstInput matInput formControlName="ManEntNumHEA1028" maxlength="6" />
                            </mat-form-field>
                        </td>
                        <td></td>

                    </tr>
                    <tr>
                        <td>Έτος</td>

                        <td>
                            <mat-form-field appearance="outline" class="sc-ul s-c s-6 ">
                                <input matInput formControlName="ManEntYeaHEA1029" maxlength="4" />
                            </mat-form-field>
                        </td>
                        <td></td>

                    </tr>

                </tbody>
            </table>
        </div>

        <div style="width:35%; margin-bottom: 16px;flex:unset;" fxLayout="column" fxLayoutAlign="flex-start center"
            class="group">
            <table class="eAitisiHea threeColumns" [formGroup]="heahea">
                <tbody>
                    <tr>
                        <td>Ακολουθεί Διαμετακόμιση / TIR</td>
                        <td colspan="2">
                            <mat-checkbox id="tirCheck" (change)="OnChange($event)" [(checked)]="tirChecked"
                                (click)="$event.stopPropagation()" [disabled]="heahea.get('TIRFolHEA1025').disabled">
                            </mat-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
    <div>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="cancel()">
                Ακυρο
            </button>
            <button style="width: auto !important" mat-raised-button [ngClass]="color2"
                (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onCloseConfirm()">
                OK
            </button>
        </mat-dialog-actions>
    </div>
</div>