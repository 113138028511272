import { AbstractControl, FormControl } from "@angular/forms";
import { ContactDetailsModel } from "./contactDetails.model";

export class ContactDetailsForm {
    [key: string]: AbstractControl;

    PersonIdentification = new FormControl();
    LastNames = new FormControl();
    Forenames = new FormControl();
    NameOfFather = new FormControl();
    StreetName = new FormControl();
    StreetNumber = new FormControl();
    Municipality = new FormControl();
    PostCode = new FormControl();
    Prefecture = new FormControl();
    Country = new FormControl();

    constructor(value: ContactDetailsModel) {
        this.PersonIdentification.setValue(value.PersonIdentification);
        this.LastNames.setValue(value.LastNames);
        this.Forenames.setValue(value.Forenames);
        this.NameOfFather.setValue(value.NameOfFather);
        this.StreetName.setValue(value.StreetName);
        this.StreetNumber.setValue(value.StreetNumber);
        this.Municipality.setValue(value.Municipality);
        this.PostCode.setValue(value.PostCode);
        this.Prefecture.setValue(value.Prefecture);
        this.Country.setValue(value.Country);

    }
}
