import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TariffCalculateService } from '../tariff-calculate.service';

@Component({
    selector: 'app-tariff-calc-sup-units-table',
    templateUrl: './tariff-calc-sup-units-table.component.html',
    styleUrls: ['./tariff-calc-sup-units-table.component.scss']
})
export class TariffCalcSupUnitsTableComponent implements OnInit {
    @Input() taxAddEleForm: FormGroup;
    @Input() supUnits: { desc: string; id: string }[];
    @Input() goodsFormGroup: FormGroup;
    @Input() goodsIndex: number;

    @Input() privateControls: FormGroup;

    color1 = '';
    color2 = '';
    processing = false;
    array: FormArray;
    declarationForm: FormGroup;
    subdomain: string;
    private _unsubscribe: Subject<void>;
    private _destroy: Subject<void> = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<TariffCalcSupUnitsTableComponent>,
        private tariffCalcService: TariffCalculateService,
        private mainBroadcaster: MainBroadcasterService
    ) { }

    ngOnInit(): void {

        const activeSubdomain$ = this.mainBroadcaster.activeSubdomain$;

        activeSubdomain$.pipe(take(1))
            .subscribe(subdomain => {

                this.subdomain = subdomain;

                console.log('attachment subdomain', subdomain);

            });

        this.tariffCalcService.taxAddEleForm$.pipe(takeUntil(this._destroy)).subscribe(form => {
            this.taxAddEleForm = form;

            this.array = form.get('array') as FormArray;

            if (this.subdomain === 'DEFK') {
                this.setDefaultSupUnits()
            }

            console.log('taxAddEleForm', this.taxAddEleForm.value);

            this.taxAddEleForm.valueChanges.pipe(takeUntil(this._destroy)).subscribe(value => {
                console.log('taxAddEleForm.valueChanges', value);
            });
        });
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        console.log('ARRATEXIT COMP DESTROYED');

        this._destroy.next();
        this._destroy.complete();
    }

    onSubmit() {
        this.processing = true;
        this.color2 = 'accent';

        this._unsubscribe = new Subject<void>();

        this.tariffCalcService.submitSupUnits().subscribe(value => {
            console.log(value);

            this.tariffCalcService.openCalculateTariffResults(value);
            this.dialogRef.close();

            this._unsubscribe.next();
            this._unsubscribe.complete();

            this.processing = false;
            this.color2 = 'accent';
        });
    }

    setDefaultSupUnits() {

        const goodsPrivate = this.privateControls.get('goodsPrivate') as FormArray;

        const defaultArray = [
            //ALCOHOL
            { desc: 'Λίτρο καθαρής αλκοόλης (100%)', value: this.goodsFormGroup.get('TaxQuantity').value },
            //CARS
            { desc: 'Αριθμός τεμαχίων', value: this.goodsFormGroup.get('AdditionalTaxQuantity').value },
            { desc: 'Λιανική τιμή πώλησης προ φόρων για την διαμόρφωση της φορολογητέας αξίας για την επιβολή ΤΤ (832)', value: this.goodsFormGroup.get('TaxValue').value },
            { desc: 'Ποσοστό απομείωσης λόγω παλαιότητας και χιλιομέτρων - Μεταχειρισμένα οχήματα', value: goodsPrivate.at(this.goodsIndex).get('obsolescencePercent').value },
            { desc: 'Φορολογητέα αξία', value: this.goodsFormGroup.get('TaxValue').value },
            { desc: 'πλατό επί εκατόλιτρα', value: this.getPlatoPer100Lt() }
        ]

        defaultArray.forEach(obj => {

            const foundIndex = this.supUnits.findIndex(sup => sup.desc === obj.desc)

            if (foundIndex > -1) {

                this.array.at(foundIndex).get('value').setValue(obj.value)
            }
        })

    }

    getPlatoPer100Lt() {

        const plato = this.goodsFormGroup.get('Plato').value
        const litre100 = +this.goodsFormGroup.get('AdditionalTaxQuantity')?.value / 100

        return +plato * litre100;
    }
}
