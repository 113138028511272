import { FormControl, Validators } from '@angular/forms';
import { SpeMenModel } from './spemenmt2.model';

export class SpeMenForm {
    [key: string]: FormControl;

    AddInfCodMT23 = new FormControl();
    TexSPM1 = new FormControl();
    TexSPM1LNG = new FormControl();

    constructor(value: SpeMenModel) {
        this.AddInfCodMT23.setValue(value.AddInfCodMT23);
        this.TexSPM1.setValue(value.TexSPM1);
        this.TexSPM1LNG.setValue(value.TexSPM1LNG);
    }
}
