import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VehiclesForm } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles-form.model';
import { genericItem } from 'app/model/api-model';

@Component({
    selector: 'cars-header-parameters',
    templateUrl: './cars-header-parameters.component.html',
    styleUrls: ['./cars-header-parameters.component.scss']
})
export class CarsHeaderParametersComponent {

    @Input() vehiclesForm: FormGroup<VehiclesForm>
    @Input() carChassisOptions: genericItem[] = [];

    constructor() {

    }

}
