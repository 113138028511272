import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AutoCompleteControlComponent } from './autocomplete-control.component';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';

@NgModule({
    declarations: [AutoCompleteControlComponent],
    exports: [AutoCompleteControlComponent],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, RouterModule, MaterialModule]
})
export class AutoCompleteControlModule {}
