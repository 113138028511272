import { FuseNavigation } from '@fuse/types';
import { BusinessModel } from 'app/main/businesses/invoices/_models/business/business.model';
import { Office } from 'app/model/office.model';
import { PATHS } from 'app/navigation/paths';

export function generateNavigation(office: Office, business: BusinessModel): FuseNavigation[] {



    const initialNavigation: FuseNavigation[] = [
        {
            id: 'applications',
            title: 'Εφαρμογές',
            // translate: 'NAV.APPLICATIONS',
            type: 'group',
            children: [
                {
                    id: 'ecs',
                    title: 'Εξαγωγή',
                    //   translate: 'NAV.SAMPLE.TITLE',
                    type: 'item',
                    icon: 'call_made',
                    url: PATHS.export(office?.id),
                    apps: ['export']

                },
                {
                    id: 'imp',
                    title: 'Εισαγωγή',
                    //  translate: 'NAV.SAMPLE.TITLE',
                    type: 'item',
                    icon: 'call_received',
                    url: PATHS.import(office?.id),
                    apps: ['import']

                },
                {
                    id: 'ncts',
                    title: 'Διαμετακόμιση',
                    //  translate: 'NAV.SAMPLE.TITLE',
                    type: 'item',
                    icon: 'import_export',
                    url: PATHS.transit(office?.id),
                    apps: ['tir', 't2l']

                },
                {
                    id: 'emcs',
                    title: 'Ειδικοί Φόροι',
                    ///   translate: 'NAV.SAMPLE.TITLE',
                    type: 'item',
                    icon: 'how_to_vote',
                    url: PATHS.excise(office?.id),
                    apps: ['defk', 'dao']

                },
                {
                    id: 'contacts',
                    title: 'Πελάτες',
                    //translate: "NAV.CONTACTS",
                    type: 'item',
                    icon: 'account_box',
                    url: PATHS.contacts(office?.id),
                    apps: ['export', 'import', 'tir', 't2l', 'dao', 'defk']

                },
                {
                    id: 'archive',
                    title: 'Αρχείο',
                    // translate: "NAV.ARCHIVE",
                    type: 'item',
                    icon: 'archive',
                    url: PATHS.archive(office?.id),
                    apps: ['export', 'import', 'tir', 't2l', 'dao', 'defk']

                },
                {
                    id: 'accounting',
                    title: 'Λογιστικά',
                    // translate: "NAV.ARCHIVE",
                    type: 'group',
                    icon: 'receipt',
                    apps: ['invoice', 'customer_statements', 'accounting'],
                    roles: [1, 2, 6],
                    children: [

                        {
                            id: 'invoices',
                            title: 'Τιμολόγια',
                            // translate: "NAV.ARCHIVE",
                            type: 'item',
                            icon: 'receipt',
                            url: PATHS.invoices(business?.business_id),
                            apps: ['invoice']

                        },
                        {
                            id: 'customer-statements',
                            title: 'Καταστάσεις Πελατών',
                            // translate: "NAV.ARCHIVE",
                            type: 'item',
                            icon: 'receipt',
                            url: PATHS.customerStatements(business?.business_id),
                            apps: ['customer_statements']

                        }
                        ,
                        {
                            id: 'accounts',
                            title: 'Λογαριασμοί',
                            // translate: "NAV.ARCHIVE",
                            type: 'item',
                            icon: 'receipt',
                            url: PATHS.accounts(business?.business_id),
                            apps: ['accounting']

                        }
                        ,
                        {
                            id: 'transactions',
                            title: 'Συναλλαγές',
                            // translate: "NAV.ARCHIVE",
                            type: 'item',
                            icon: 'receipt',
                            url: PATHS.transactions(business?.business_id),
                            apps: ['accounting']

                        }
                    ]
                },

            ]
        }
    ];


    const role = business.is_owner ? 1 : business.is_admin ? 2 : business.roles_description === 'collaborator' ? 3 : business.roles_description === 'accountant' ? 6 : 0;

    return filterNavigation(initialNavigation, [...office.apps, ...business.apps], role);
}


function filterNavigation(navigation: FuseNavigation[], apps: string[], role: number): FuseNavigation[] {
    return navigation.filter(item => {
        // Check if the item is allowed
        const allowed = item.apps ? item.apps.some(app => apps.includes(app)) : true;

        // Check if the item has roles and if the role is included
        const roleAllowed = item.roles ? item.roles.includes(role) : true;

        if (item.children) {
            // Filter the children
            item.children = filterNavigation(item.children, apps, role);
            // If the item is a group and all of its children were removed, it is not allowed
            if (item.type === 'group' && item.children.length === 0) {
                return false;
            }
        }

        return allowed && roleAllowed;

    });
}