import { Component, Inject, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpDeclarationFormService } from 'app/main/apps/export/declaration/exp-declaration-form.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-declaration-security',
    templateUrl: './declaration-security.component.html',
    styleUrls: ['./declaration-security.component.scss']
})
export class DeclarationSecurityComponent implements OnInit {
    @ViewChild('tab0') _tab0;

    private _destroy: Subject<void> = new Subject<void>();

    tabIndex: number;

    formDisabled: boolean;
    declarationForm: FormGroup;
    conresers: FormGroup;
    heahea: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<DeclarationSecurityComponent>,
        private mainBroadcaster: MainBroadcasterService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.tabIndex = 0;
        //  console.log(" this.callingDivId", this.callingDivId);

        this.declarationForm = data.declarationForm;
    }

    ngOnInit(): void {
        combineLatest([this.mainBroadcaster.activeSubdomain$, this.mainBroadcaster.formDisabled$])
            .pipe(takeUntil(this._destroy))
            .subscribe(([subdomain, disabledForms]) => {
                this.formDisabled = disabledForms[subdomain];
            });

        this.conresers = this.declarationForm.get('CONRESERS') as FormGroup;
        this.heahea = this.declarationForm.get('HEAHEA') as FormGroup;

        console.log('CONRESERS', this.conresers.value);

        this.declarationForm.valueChanges.pipe(takeUntil(this._destroy)).subscribe(value => {
            console.log('declarationForm Changed', this.declarationForm.value);
        });
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    onCloseConfirm() {
        this.dialogRef.close();
    }
}
