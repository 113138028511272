import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductNode } from '../taric.service';

@Component({
    selector: 'taric-actions-menu',
    templateUrl: './taric-actions-menu.component.html',
    styleUrls: ['./taric-actions-menu.component.scss']
})
export class TaricActionsMenuComponent {
    @Input() product: ProductNode;
    @Output() setCodeDescription: EventEmitter<any>;

    constructor() {
        this.setCodeDescription = new EventEmitter();
    }

    setCodeDesc(product: ProductNode, type: string) {
        this.setCodeDescription.next({ type, product });
    }
}
