<div class="ecl-row">
    <div
        class="ecl-col-md-12"
        style="height: calc(100vh - 200px)"
        *ngIf="model.data.tariffsEU===null"
    >
        <h4 class="ecl-heading ecl-heading--h2">Δεν εφαρμόζονται μέτρα.</h4>
    </div>
</div>
<!-- 
<div class="results-last-update">
    <p>
        <strong>latest update:</strong>
            <time
            datetime="{{
                model.data.lastUpdate.timestamp | date: 'yyyy-MM-dd'
            }}"
            >{{ model.data.lastUpdate.dateFormatted }}</time
        >
        </p>
</div>
 -->
<!-- Tariffs EU -->
<div
    *ngIf="model.data.tariffsEU && model.data.tariffsEU.length > 0"
    style="overflow: auto"
>
    <!--     <div
        data-ng-class="{ 'alert' : msg.tariffsEUCode===0 , 'warning' : msg.tariffsEUCode===1 , 'info' : msg.tariffsEUCode===2} "
        data-ng-show="msg.tariffsEU"
    >
           <span
            class="closebtn"
            (click)="this.parentElement.style.display = 'none'"
            >&times;</span
        >
        <span *ngIf="msg.barriersText==='view.results.noTariffsFound'">
            No tariffs found
        </span>
    </div>

 -->
    <div data-ng-show="util.tariffsEULoading===false">
        <div
            *ngIf="model.data.tariffsEU===null"
            style="height: calc(100vh - 200px)"
        >
            No tariffs found
        </div>
        <div
            *ngIf="model.data.tariffsEU !== null"
            style="height: calc(100vh - 200px)"
        >
            <div class="row" *ngFor="let tariff of model.data.tariffsEU">
                <table
                    class="
                        ecl-table ecl-table--responsive
                        table-repeat-tbody table-tarif table-import-tarif
                    "
                >
                    <thead>
                        <tr>
                            <th style="width: 200px">
                                Καταγωγή/<br />Είδος μέτρου
                            </th>
                            <th class="width250px">Δασμός</th>
                            <th *ngIf="tariff.addCodeForTable">
                                Πρόσθετος Κωδικός
                            </th>
                            <th *ngIf="tariff.orderNoForTable">
                                Αύξων Αριθμός Ποσόστωσης
                            </th>
                            <th>Προϋποθέσεις</th>
                            <th>Υποσημείωση</th>
                            <th>Νόμος Ε.Ε.</th>
                        </tr>
                    </thead>
                    <tbody
                        *ngFor="
                            let measure of tariff.measures;
                            let index = index
                        "
                    >
                        <tr>
                            <td data-th="Καταγωγή:">
                                <strong>
                                    <div style="margin-bottom: 5px">
                                        <span class=""
                                            >{{ measure.origin }}
                                        </span>

                                        <!-- ngIf: (measure.exclusions !== null) && (!isWithConditions(measure)) -->
                                        <span
                                            *ngIf="measure.exclusions !== null"
                                            style="color: #8a020ea3"
                                        >
                                            <br />
                                            <small>
                                                Εξαιρούνται:
                                                <span class="exclusions"
                                                    >{{ measure.exclusions }}
                                                </span></small
                                            >
                                        </span>
                                    </div>
                                </strong>
                                <span>{{ measure.type }} </span>

                                <span
                                    *ngIf="
                                        selectMeasureType(measure) !==
                                        'SUPPLEMENTARY_UNIT'"
                                    style="font-size: 0.75em; color: #828282"
                                >
                                    <br /><span
                                        *ngIf="
                                            measure.endDate !== '01/01/3000' &&
                                            measure.endDate !== null
                                        "
                                        >[{{
                                            measure.startDate
                                                | date: "dd/MM/yyyy"
                                        }}
                                        -
                                        {{
                                            measure.endDate
                                                | date: "dd/MM/yyyy"
                                        }}]</span
                                    >
                                    <span *ngIf="measure.endDate===null"
                                        >από
                                        {{
                                            measure.startDate
                                                | date: "dd/MM/yyyy"
                                        }}</span
                                    >
                                </span>
                            </td>
                            <td data-th="Δασμός:" class="linkImport">
                                <span
                                    class="tariff-formula"
                                    [matTooltip]="measure.tariffUnitDesc"
                                    matTooltipClass="tooltip-description"
                                    *ngIf="
                                        selectMeasureType(measure) === 'DEFAULT' ||
                                        selectMeasureType(measure) === 'TARIFF_QUOTA'"
                                    >{{ measure.tariffFormula }}
                                </span>

                                <span
                                    [matTooltip]="measure.supUnitDesc"
                                    matTooltipClass="tooltip-description"
                                    *ngIf="
                                        selectMeasureType(measure) ===
                                        'SUPPLEMENTARY_UNIT'">
                                    <strong>{{ measure.tariffFormula }}</strong>
                                    <!--   (
                                    <span>{{
                                        getSupplyUnit(model.state.supplUnit)
                                    }}</span>

                                    ) -->
                                </span>
                            </td>
                            <td
                                *ngIf="tariff.addCodeForTable"
                                data-th="Πρόσθετος Κωδικός:"
                                class="linkImport"
                            >
                                <a
                                    href=""
                                    id="linkAddCode-{{ index }}"
                                    class="linkImport"
                                    [matTooltip]="measure.additionalCodeText"
                                    matTooltipClass="tooltip"
                                    (click)="$event.preventDefault()"
                                    >{{ measure.additionalCodeId }}
                                </a>
                            </td>

                            <td
                                *ngIf="tariff.orderNoForTable"
                                data-th="Αύξων Αριθμός Ποσόστωσης:"
                                class="linkImport"
                            >
                                <a
                                    href=""
                                    id="linkOrderNo-{{ index }}"
                                    class="linkImport"
                                    (click)="$event.preventDefault()"
                                    >{{ measure.quotaOrderNo }}
                                </a>
                            </td>
                            <td class="linkImport" data-th="Προϋποθέσεις:">
                                <a
                                    href=""
                                    id="linkConditions-{{ index }}"
                                    class="linkImport"
                                    *ngIf="measure.conditions"
                                    (click)="
                                        $event.preventDefault();
                                        showTariffInfoDialog(
                                            measure,
                                            'conditions'
                                        )
                                    "
                                    >Εμφάνιση</a
                                >

                                <!-- Tariff Conditions Table -->
                                <div
                                    id="conditionsInfo_{{ index }}"
                                    style="display: none"
                                ></div>
                                <!-- Tariff Conditions Table -->
                            </td>
                            <td data-th="Υποσημείωση:" class="linkImport">
                                <div
                                    class="ecl-paragraph"
                                    *ngFor="
                                        let footnote of measure.footnotes;
                                        let footnoteIndex = index
                                    "
                                >
                                    <a
                                        href=""
                                        id="linkfootnote-{{ index }}"
                                        class="linkImport"
                                        [matTooltip]="footnote.text"
                                        matTooltipClass="tooltip"
                                        (click)="$event.preventDefault()"
                                    >
                                        {{ footnote.type
                                        }}{{
                                            footnote.code | leadingZero3digits
                                        }}
                                    </a>
                                </div>
                            </td>

                            <td class="linkImport">
                                <a
                                    *ngIf="measure.regulationId"
                                    href=""
                                    class="linkImport linkeEULaw"
                                    id="LinkEULaw-{{ index }}"
                                    (click)="
                                        $event.preventDefault();
                                        getTariffsMeasuresRegulations(
                                            measure.regulationId
                                        )
                                    "
                                >
                                    {{ measure.regulationId }}
                                </a>
                            </td>
                        </tr>

                        <!--                         <tr>
                            <td
                                colspan="5"
                                class="inport-text-info-container"
                                id="container-info-{{ index }}"
                            >
                                <button
                                    (click)="
                                        closeImportTariff(
                                            'container-info',
                                            index
                                        )
                                    "
                                    class="ecl-message__dismiss--inverted ecl-message__dismiss import-tariff-close-buttom"
                                >
                                    Κλέισιμο
                                </button>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{ footnote.type
                                                    }}{{ footnote.code }}
                                                </td>
                                                <td>
                                                    <span>{{
                                                        footnote.text
                                                    }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
 -->
                        <!-- E.U Regulation Law Table -->
                        <!-- /E.U Regulation Law Table -->
                    </tbody>
                </table>
                <!--                 <br /><br />
                <div id="informationDiv" tabindex="-1"></div>
                <br /><br />
 -->
                <!--- info eulaw click -->
            </div>
        </div>
    </div>
</div>
