<div fxLayout="column" style="width: 100%; flex-grow: 1">
    <div mat-dialog-title class="primary">
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 24%">
            <span>Εκδοση Ταυτότητας Πληρωμής</span>
        </div>
        <!-- CENTER -->
        <div fxLayout="row" fxLayoutAlign="center center" style="width:40% ;margin-left: -25px">
            <app-loading *ngIf="loading$ | async" class="loading loading-epayment loading-white" [addValues]="true">
            </app-loading>
        </div>
        <!-- CENTER -->
        <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">

            <button mat-raised-button class="accent" [disabled]="remittanceForm.disabled" (click)="add()">
                Προσθήκη Νέας Ταυτότητας
            </button>
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="flex-start center" mat-dialog-content style="
            overflow-y: auto;
            min-height: 100px;
            height: 100px;
            padding: 0 16px;
        " [formGroup]="remittanceForm">
        <div fxLayout="row" class="group" style="width:100%; flex:0 0 auto;">
            <h2>Υποβάλλων</h2>

            <table class="eAitisiHea threeColumns" style="width:41.5%;">
                <tbody>
                    <tr>
                        <td>Υποβάλλων</td>
                        <td colspan="2">
                            <mat-form-field class="s-c sc-ul s-32" appearance="outline">
                                <autocomplete-control #firstInput formControlName="declarant"
                                    [options]="declarants$ | async" [config]="declConfig" (keyDown.Escape)="$event.stopPropagation();
                                    $event.preventDefault();">
                                </autocomplete-control>
                            </mat-form-field>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <ng-container formArrayName="ePayment" *ngFor="let item of ePaymentForm.controls; let i= index">
            <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%">
                <div fxLayout="row" class="group" style="width:100%" [formGroupName]="i"
                    [ngClass]="{'rem-expired': item.get('expired').value && item.get('stateCode').value==='Under Payment'}">
                    <h2 *ngIf=" !item.get('expired').value && item.get('stateCode').value==='Under Payment'">Ταυτότητα
                        Πληρωμής {{i+ 1}}</h2>
                    <h2 *ngIf=" item.get('expired').value && item.get('stateCode').value==='Under Payment'">ΕΚΠΡΟΘΕΣΜΗ
                    </h2>
                    <table class="eAitisiHea" style="width:50%;">
                        <tbody>
                            <tr>
                                <td style="border-right: 1px solid #ccc;">


                                    <table class="ePayment_table fourColumns">
                                        <tbody>

                                            <tr>
                                                <td>Υπόχρεος</td>
                                                <td>
                                                    <mat-form-field class="s-c sc-ul s-32" appearance="outline">
                                                        <autocomplete-control formControlName="trader"
                                                            [options]="traders$ | async" [config]="declConfig"
                                                            (keyDown.Escape)="$event.stopPropagation();
                                                            $event.preventDefault();">
                                                        </autocomplete-control>
                                                    </mat-form-field>
                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>

                                            <tr>
                                                <td colspan="4">
                                                    <table class="ePayment_table fourColumns" style="padding:10px 0"
                                                        formArrayName="mrns">
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let mrns of item.get('mrns').controls; let j=index">
                                                                <td>MRN</td>
                                                                <td>
                                                                    <mat-form-field class="s-c sc-ul s-20"
                                                                        appearance="outline" required>
                                                                        <input matInput [formControlName]="j"
                                                                            maxlength="21" required />
                                                                    </mat-form-field>
                                                                </td>
                                                                <td class="add_del_buttons">
                                                                    <add-del-buttons [show]="!item.disabled"
                                                                        [hideAdd]="hideAdd(item.get('mrns'))"
                                                                        [hideDelete]="hideDelete(item.get('mrns'))"
                                                                        (addButton)="addMrn(i)"
                                                                        (deleteButton)="deleteMrn( i, j)">
                                                                    </add-del-buttons>
                                                                </td>
                                                                <td>
                                                                    <button mat-icon-button
                                                                        matTooltip="Εκτύπωση Αποδεικτικού Πληρωμής"
                                                                        matTooltipClass="tooltip"
                                                                        (click)="download(item.value,'receipt')"
                                                                        style="align-self: flex-start;">
                                                                        <mat-icon
                                                                            style="font-size: 32px;width:32px;height:32px">receipt</mat-icon>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>

                                                </td>
                                            </tr>


                                            <tr>
                                                <td style="text-align:center;"
                                                    *ngIf=" item.get('expired').value && item.get('stateCode').value==='Under Payment'"
                                                    colspan="4">

                                                    <button style="width: 80%" mat-raised-button ngClass="accent"
                                                        (click)="reSubmit(i)">
                                                        Επανέκδοση
                                                    </button>

                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>


                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <table class="eAitisiHea twoColumns" style="width:50%;">
                        <ng-container *ngIf="item.get('brmt').value"
                            [ngTemplateOutlet]="(item.get('brmt').value | testDigitOnly) ?showData : showError"
                            [ngTemplateOutletContext]="{brmt: item.get('brmt').value, blrn: item.get('blrn').value, formGroup:item}">
                        </ng-container>
                    </table>
                    <button *ngIf="!item.disabled" mat-icon-button mat-icon-button matTooltip="Διαγραφή στήλης"
                        matTooltipClass="tooltip" (click)="delete(i)" style="color:red;align-self: flex-end;"><mat-icon
                            style="font-size: 32px;width:32px;height:32px">delete</mat-icon>
                    </button>

                    <button
                        *ngIf="!item.get('expired').value && (item.get('brmt').value | testDigitOnly) && item.get('stateCode').value ==='Under Payment'"
                        mat-icon-button matTooltip="Εκτύπωση Ταυτότητας Πληρωμής" matTooltipClass="tooltip"
                        (click)="download(item.value,'remittance')" style="align-self: flex-start;">
                        <mat-icon style="font-size: 32px;width:32px;height:32px">picture_as_pdf</mat-icon>
                    </button>

                </div>





            </div>
        </ng-container>

    </div>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
        <button style="width: auto !important" mat-raised-button [ngClass]="color1" (mouseenter)="changeStyle1($event)"
            (mouseleave)="changeStyle1($event)" (click)="onCloseConfirm()">
            Ακυρο
        </button>
        <button style="width: auto !important" mat-raised-button
            [disabled]="!remittanceForm.valid || remittanceForm.disabled || dataToSubmitLength===0" [ngClass]="color2"
            (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onSubmit()">
            <span *ngIf="ePaymentForm.length<2"> Έκδοση Ταυτότητας </span>
            <span *ngIf="ePaymentForm.length>1"> Έκδοση Ταυτοτήτων </span>
        </button>
    </mat-dialog-actions>
</div>


<ng-template #showData let-formGroup="formGroup">

    <tbody [formGroup]="formGroup">
        <tr>
            <td style="text-align: right;">Ταυτοτητα Πληρωμής</td>
            <td>
                <mat-form-field class="s-c sc-ul s-21" appearance="outline">
                    <input matInput formControlName="brmt" readonly />
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td style="text-align: right;">Ποσό</td>
            <td> <mat-form-field class="s-c sc-ul s-12" appearance="outline">
                    <input matInput formControlName="bamnt" readonly />
                </mat-form-field></td>
        </tr>
        <tr>
            <td style="text-align: right;">Προθεσμία Καταβολής</td>
            <td> <mat-form-field class="s-c sc-ul s-12" appearance="outline">
                    <input matInput formControlName="date" readonly />
                </mat-form-field></td>
        </tr>
        <tr>
            <td style="text-align: right;">Επιμέρους Ποσά</td>
            <td> <mat-form-field class="s-c sc-ul s-12" appearance="outline">
                    <input matInput formControlName="bmsv" readonly />
                </mat-form-field></td>
        </tr>
    </tbody>

</ng-template>


<ng-template #showError let-brmt="brmt" let-blrn="blrn">

    <tbody class="rem-error">

        <tr>
            <td>
                <h1> Σφάλμα </h1>
            </td>
        </tr>
        <tr>
            <td>
                <h3>{{brmt | remittanceError: blrn }}</h3>
            </td>
        </tr>
    </tbody>


</ng-template>