import { CustomersService } from 'app/services/customers.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { filter, map, first, switchMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { User } from 'app/model/api-model';
import { UserService } from 'app/core/user/user.service';
import { OfficeService } from 'app/services/office.service';
import { PrintSettingsModel } from 'app/main/app-settings/app-settings/print-settings/_models/print-settings.model';
import { PrintSettingsService } from 'app/main/app-settings/app-settings/print-settings/print-settings.service';
import { CountriesService } from 'app/services/countries.service';
import { AuthService } from 'app/core/auth/auth.service';

export interface IResolvedData {
    user: User;
    printSettings: PrintSettingsModel[]
}

@Injectable()
export class OfficeResolver implements Resolve<any> {
    // declaration_id: number;

    constructor(
        private userService: UserService,
        private printSettingsService: PrintSettingsService,
        private officeService: OfficeService,
        private authService: AuthService,
        private customersService: CustomersService,
        private countiesService: CountriesService
    ) {
        console.log('Office Resolver hit');
    }

    /**
     * Resolver
     *
     * @param route
     * @param state
     * @returns
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {



        const officeId: string = route.params['officeId'];

        //console.log('Office Resolver hit', officeId);
        //console.log('Office Resolver hit', route.params);
        //console.log('Office Resolver hit route', route);
        //console.log('Office Resolver hit state', route);

        const office = this.officeService.getOfficeById(officeId);


        const user$ = this.userService.user$;
        const printSettings$ = this.printSettingsService.getSettings()
        const declarationSettings$ = this.officeService.getDeclarationSettings();
        const pelates$ = this.customersService.getDomesticCustomers(false);
        const international_customers$ = this.customersService.getInternationalCustomers(false);
        const carriers$ = this.customersService.getCarriers(false);
        const declarants$ = this.customersService.getDeclarants(false);
        const countries$ = this.countiesService.getCountries();


        const combine$ = combineLatest([user$, printSettings$, declarationSettings$, pelates$, international_customers$, carriers$, declarants$, countries$]).pipe(
            filter(([user, printSettings, declarationSettings, pelates, international_customers, carriers, declarants, countries]) => Boolean(user && printSettings && declarationSettings && pelates && international_customers && carriers && declarants && countries)),
            map(([user, printSettings, declarationSettings, pelates, international_customers, carriers, declarants, countries]) => ({ user, printSettings, declarationSettings, pelates, international_customers, carriers, declarants, countries })),
            first()
        );

        return this.authService.officeAuthenticate(office).pipe(switchMap(_ => combine$));
    }
}
