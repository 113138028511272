import { isArray, forEach } from 'lodash-es';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { StateResponse } from 'app/services/declaration-state.service';

@Injectable({
    providedIn: 'root',
})
export class HttpErrorService {
    constructor(private toastrService: ToastrService, private cf: ExtCustomFunctions) { }

    showError(err: HttpErrorResponse): void {
        console.log('HttpErrorService Error received', err);

        if (err?.error instanceof ErrorEvent) {
            // Client-side or network error
            console.log('HttpErrorService Client Side Error: ', err.error.message);
            //THIS HANDLES ERRORS ON CREATE XML FILE
        } else if (err?.error instanceof Blob && err?.headers.get('Content-Type') !== 'application/json') {
            // Error returned as Blob
            const reader = new FileReader();
            reader.onload = (e: any) => {

                const textArray: string[] = e.target.result.split('|').filter(x => x);

                const jsonResponse = this.cf.responseToJson(textArray, 0).jsonArray;

                this.handleGsisMessageError(jsonResponse);
            };
            reader.readAsText(err.error);
        } else {
            // Regular JSON error
            this.handleServerError(err);
        }
    }
    private handleServerError(err: HttpErrorResponse | any): void {
        let message: string;
        let desc: string;
        let icisError;

        console.log('HttpErrorService Server Side Error: ', err);
        if (isArray(err)) {
            console.log('HttpErrorService ERROR IS ARRAY', err);
            err.forEach((e) => {
                console.log('e.error', e.error);
                message = e.explanation ? e.explanation : e.error;
                desc = e.status ? e.status : err.statusText ? err.statusText : 'Σφάλμα';



                this.showToastrError(message, desc, {
                    timeOut: 0,
                    disableTimeOut: true,
                    closeButton: true,
                });
            })

            return;
        }

        else if (isArray(err?.error)) {
            console.log('HttpErrorService ERROR IS ARRAY', err.error);
            err.error.forEach((e) => {
                console.log('e.error', e.error);
                message = e.explanation ? e.explanation : e.error;
                desc = e.status ? e.status : err.statusText ? err.statusText : 'Σφάλμα';



                this.showToastrError(message, desc, {
                    timeOut: 0,
                    disableTimeOut: true,
                    closeButton: true,
                });
            });
            return;
        }

        const error = err?.error?.error;

        message = this.getMessageFromError(err);
        desc = this.getDescriptionFromError(err);

        //icisNetErrors

        if (!error) {

            console.log('HttpErrorService ERROR IS ICISNET: ', err?.error);

            icisError = err?.error

            message = icisError?.status
            desc = icisError?.explanation;
        }



        console.log('HttpErrorService url: ', err?.url);
        console.log('HttpErrorService message: ', message);
        console.log('HttpErrorService error: ', error);
        console.log('HttpErrorService desc: ', desc);

        if (!err?.url?.includes('icisLogout') && !icisError) {
            if (error) {
                message = error;
                console.log("HttpErrorService message changed:", message)
            }

        }
        this.showToastrError(desc, message, {
            disableTimeOut: true,
            closeButton: true,
        });

    }

    private getMessageFromError(err: HttpErrorResponse): string {
        if (err?.error) {
            return err.error;
        }
        if (err?.error?.message) {
            return 'Server Side Error: (${err.error.message})';
        }
        return 'Server Side Error: (${err.name})';
    }

    private getDescriptionFromError(err: HttpErrorResponse): string {
        if (err?.error?.text) {
            return err.error.text;
        }
        if (err?.message) {
            return err.message;
        }
        if (err?.statusText) {
            return err.statusText;
        }

        console.log('HttpErrorService getDescriptionFromError: ', err)
        return 'Άγνωστο Σφάλμα';
    }

    private showToastrError(
        message: string,
        desc: string,
        options: {
            timeOut?: number;
            disableTimeOut?: boolean;
            closeButton?: boolean;
        }
    ): void {
        this.toastrService.error(message, desc, options);
    }

    private handleGsisMessageError(messages: StateResponse[]): void {

        messages.forEach((message: StateResponse) => {

            const errors = message.error;

            errors.forEach((error) => {
                const message = error.status;
                const desc = error.explanation;

                this.showToastrError(desc, message, {
                    timeOut: 0,
                    disableTimeOut: true,
                    closeButton: true,
                });
            })
        })
    }
}
