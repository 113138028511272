import { Injectable } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { TsipouraCalculationDataForm, TsipouraForm } from "app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura-form.model";
import { TsipouraCalculationDataModel, TsipouraModel } from "app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura.model";
import { BehaviorSubject, Observable, Subscription, concatMap, of, take, tap } from "rxjs";
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { StorageService } from 'app/core/services/storage/storage.service';
import { ApiEndPoints, ApiService } from "app/services/api.service";
import { TsipouraParameters } from "app/main/apps/excise-helper-tables/tsipoura/tsipoura-defk-create.service";
import { DEFKDeclarationForm } from "app/main/apps/excise/defk/_models/EF15A/declaration-form.model";
import { OpenDialogsService } from "app/services/openDialogs.service";
import { ContactsService } from "app/main/apps/contacts/contacts.service";
import { DistillationLicenceAppComponent } from "app/main/apps/general-applications/distillation-licence-app/distillation-licence-app/distillation-licence-app.component";

export type ProducerTime = {
    producerName: string;
    days: number;
    startTime: Date;
    endTime: Date;
};

@Injectable()
export class TsipouraService {

    private tsipouraForm: BehaviorSubject<FormGroup<TsipouraForm>>
    private producersTime: BehaviorSubject<ProducerTime[]>


    tsipouraForm$: Observable<FormGroup<TsipouraForm>>
    producersTime$: Observable<ProducerTime[]>

    alcoholVolSubs: Subscription[] = [];
    wineGrapesSubs: Subscription[] = [];
    nonWineGrapesSubs: Subscription[] = [];
    grapeStemsSubs: Subscription[] = [];
    lpaSubs: Subscription[] = [];

    numberOfBoilersSubs: Subscription[] = [];
    workHoursSubs: Subscription[] = [];
    params: TsipouraParameters

    constructor(private cf: ExtCustomFunctions, private storageService: StorageService, private dbQuery: ApiService, private openDialogsService: OpenDialogsService, private contactService: ContactsService) {

        this.tsipouraForm = new BehaviorSubject(new FormGroup(new TsipouraForm(null, new TsipouraModel())))
        this.tsipouraForm$ = this.tsipouraForm.asObservable()
        this.producersTime = new BehaviorSubject([])

        this.producersTime$ = this.producersTime.asObservable()


        this.getTsipouraParams().pipe(take(1)).subscribe(params => {

            this.params = params[0]
            this.tsipouraForm.next(new FormGroup(new TsipouraForm(this.params, new TsipouraModel())))

        })
    }

    getTsipouraParams() {
        return this.dbQuery.get_options(ApiEndPoints.efk_tsipoura_params).pipe(take(1), tap((res) => {
            this.params = res[0]
            console.log("TsipouraService params", this.params)
        }))
    }

    setLocalStorage(value: TsipouraModel) {
        this.storageService.setLocalObject('twoDayDistillers', value);
    }
    patchPrivateCtrls(defkDeclarationForm: FormGroup<DEFKDeclarationForm>, value: TsipouraModel) {
        defkDeclarationForm.get('_PRIVATE').get('twoDayDistillers').setValue(value)
    }

    loadLocalStorage(savedForm: TsipouraModel) {
        let form: FormGroup<TsipouraForm>;

        if (savedForm) {
            form = new FormGroup(new TsipouraForm(this.params, new TsipouraModel(savedForm)));
        }
        else {
            const localValue = this.storageService.getLocalObject('twoDayDistillers') as TsipouraModel;

            form = new FormGroup(new TsipouraForm(this.params, new TsipouraModel(localValue)));
        }
        this.tsipouraForm.next(form);
    }

    addProducer() {
        const form = this.tsipouraForm.getValue()
        const tsipouraCalculationData = form.get('tsipouraCalculationData') as FormArray
        const newGroup = new FormGroup(new TsipouraCalculationDataForm(this.params, new TsipouraCalculationDataModel()))
        tsipouraCalculationData.push(newGroup)

        this.subToAlcoholVolValueChanges(newGroup)
        this.subToWineGrapesValueChanges(newGroup)
        this.subToNonWineGrapesValueChanges(newGroup)
        this.subToWineStemsValueChanges(newGroup)
        this.subToLpaValueChanges(newGroup)
        this.subToNumberOfBoilersValueChanges(newGroup)
        this.subToWorkHoursValueChanges(newGroup)
        // this.tsipouraForm.next(form)
    }

    deleteProducer(index: number) {
        const form = this.tsipouraForm.getValue()
        const tsipouraCalculationData = form.get('tsipouraCalculationData') as FormArray
        tsipouraCalculationData.removeAt(index)
        //this.tsipouraForm.next(form)
        this.alcoholVolSubs.splice(index, 1);
        this.wineGrapesSubs.splice(index, 1);
        this.nonWineGrapesSubs.splice(index, 1);
        this.grapeStemsSubs.splice(index, 1);
        this.lpaSubs.splice(index, 1);
        this.numberOfBoilersSubs.splice(index, 1);
        this.workHoursSubs.splice(index, 1);
        this.calculateTotals()

    }


    calculateLPA(group: FormGroup, wineGrapes: number, grapeStems: number) {

        let result;

        if (wineGrapes) {
            result = wineGrapes * this.params.grapesToLpa;
        } else if (grapeStems) {
            result = grapeStems * this.params.stemsToLpa;
        } else {
            result = null;
        }

        group.get('lpa').setValue(this.cf.formatDecimalString(result, 2))

    }

    calculateNetWeight(group: FormGroup) {

        const alcoholVol = group.get('alcoholVol').value
        const lpa = group.get('lpa').value ?? null

        let result;

        if (lpa) {
            result = lpa / alcoholVol;
        } else {
            result = null;
        }

        group.get('finalNetWeight').setValue(this.cf.formatDecimalString(result, 2))
    }
    calculateNumberOfBoilers(group: FormGroup, wineGrapes: number, grapeStems: number) {

        let result;
        const currentForm = this.tsipouraForm.getValue()
        const boilerCapacity = currentForm.get('boilerCapacity')?.value ?? 0;
        const numberOfBoilers = group.get('numberOfBoilers') as FormControl

        if (wineGrapes) {
            result = wineGrapes / (boilerCapacity * this.params.boilerFullCap);
            numberOfBoilers.setValidators([Validators.max(this.params.maxBoilerNumberGrapes)])
        } else if (grapeStems) {
            result = grapeStems / (boilerCapacity * this.params.boilerFullCap);
            numberOfBoilers.setValidators([Validators.max(this.params.maxBoilerNumberStems)])

        } else {
            result = null;
        }

        numberOfBoilers.setValue(this.cf.formatDecimalString(result, 2))
        numberOfBoilers.updateValueAndValidity();
    }

    calculateHours(group: FormGroup, numberOfBoilers: number, roundUp = true) {
        let result;

        if (numberOfBoilers) {
            //3.5 hours per boiler
            result = Math.round(numberOfBoilers * this.params.hoursPerBoiler * 10) / 10; // Multiplying and dividing by 10 to round to 1 decimal place
        } else {
            result = null;
        }


        //Stroggilopoiisi pros ta pano
        if (roundUp) {
            if (result !== null) {
                result = Math.ceil(result * 2) / 2;
            } else {
                result = null;
            }
        }

        group.get('hours').setValue(this.cf.formatDecimalString(result, 1))

    }

    calculateWorkDays(group: FormGroup, hours: number, roundUp = true) {
        let result;
        const workHoursPerDay = this.tsipouraForm.getValue().get('workHoursPerDay').value

        console.log("calculateWorkDays workHoursPerDay", workHoursPerDay)
        console.log("calculateWorkDays hours", hours)

        if (hours) {
            result = hours / workHoursPerDay;
        } else {
            result = null;

        }
        console.log("calculateWorkDays result", result)

        if (roundUp) {

            if (result !== null) {
                result = Math.ceil(result * 2) / 2;
            } else {
                result = null;
            }
        }

        group.get('days').setValue(this.cf.formatDecimalString(result, 1))
    }

    calculateEFK(group: FormGroup, lpa: number) {
        let result;

        if (lpa) {
            result = lpa * this.params.efkPerLpa;
        }
        else {
            result = null;
        }

        group.get('efk').setValue(this.cf.formatDecimalString(result, 2))
    }

    calculateTotals() {
        const form = this.tsipouraForm.getValue();

        const tsipouraCalculationData = form.get('tsipouraCalculationData') as FormArray;

        const totals = tsipouraCalculationData.controls.reduce((acc, group: FormGroup) => {
            acc.totalWineGrapes += +group.get('wineGrapes').value || 0;
            acc.totalNonWineGrapes += +group.get('nonWineGrapes').value || 0;
            acc.totalGrapeStems += +group.get('grapeStems').value || 0;
            acc.totalLpa += +group.get('lpa').value || 0;
            acc.totalFinalNetWeight += +group.get('finalNetWeight').value || 0;
            acc.totalNumberOfBoilers += +group.get('numberOfBoilers').value || 0;
            acc.totalHours += +group.get('hours').value || 0;
            acc.totalDays += +group.get('days').value || 0;
            acc.totalEfk += +group.get('efk').value || 0;
            return acc;
        }, {
            totalWineGrapes: 0,
            totalNonWineGrapes: 0,
            totalGrapeStems: 0,
            totalLpa: 0,
            totalFinalNetWeight: 0,
            totalNumberOfBoilers: 0,
            totalHours: 0,
            totalDays: 0,
            totalEfk: 0
        });

        const tsipouraTotals = form.get('tsipouraTotals') as FormGroup;

        tsipouraTotals.get('totalWineGrapes').setValue(this.cf.formatDecimalString(totals.totalWineGrapes, 2));
        tsipouraTotals.get('totalNonWineGrapes').setValue(this.cf.formatDecimalString(totals.totalNonWineGrapes, 2));
        tsipouraTotals.get('totalGrapeStems').setValue(this.cf.formatDecimalString(totals.totalGrapeStems, 2));
        tsipouraTotals.get('totalLpa').setValue(this.cf.formatDecimalString(totals.totalLpa, 2));
        tsipouraTotals.get('totalFinalNetWeight').setValue(this.cf.formatDecimalString(totals.totalFinalNetWeight, 2));
        tsipouraTotals.get('totalNumberOfBoilers').setValue(this.cf.formatDecimalString(totals.totalNumberOfBoilers, 2));
        tsipouraTotals.get('totalHours').setValue(this.cf.formatDecimalString(totals.totalHours, 1));
        tsipouraTotals.get('totalDays').setValue(this.cf.formatDecimalString(totals.totalDays, 1));
        tsipouraTotals.get('totalEfk').setValue(this.cf.formatDecimalString(totals.totalEfk, 2));

    }

    calculateProducerTimes() {
        const startDate = this.tsipouraForm.getValue().get('startDate').value;
        const tsipouraCalculationData = this.tsipouraForm.getValue().get('tsipouraCalculationData') as FormArray;

        const producerNames = tsipouraCalculationData.controls.map((group: FormGroup) => group.get('producerName').value);
        const days = tsipouraCalculationData.controls.map((group: FormGroup) => group.get('days').value);

        // Sort days in descending order and get the sorted indices
        const sortedIndices = days.map((value, index) => index)
            .sort((a, b) => {
                const isWholeDayA = days[a] % 1 === 0;
                const isWholeDayB = days[b] % 1 === 0;

                if (isWholeDayA && isWholeDayB) {
                    return days[b] - days[a]; // Sort whole days in descending order
                } else if (isWholeDayA) {
                    return -1; // Prioritize whole day A
                } else if (isWholeDayB) {
                    return 1; // Prioritize whole day B
                } else {
                    return days[b] - days[a]; // Sort half days in descending order
                }
            });



        let currentStartTime = new Date(startDate);
        const result: ProducerTime[] = [];

        for (const index of sortedIndices) {
            const dayValue = days[index];
            const endTime = new Date(currentStartTime);

            // Calculate the total hours for the given days
            const fullDays = Math.floor(dayValue);
            const halfDay = dayValue % 1;

            // Add hours for full days
            if (fullDays > 0) {
                endTime.setDate(endTime.getDate() + fullDays);
                if (halfDay === 0) {
                    endTime.setDate(endTime.getDate() - 1); // Subtract one day for whole days
                    endTime.setHours(20);
                    endTime.setMinutes(0);
                }
            }

            // Add hours for half day
            if (halfDay > 0) {
                endTime.setHours(endTime.getHours() + 6);
            }

            result.push({
                producerName: producerNames[index],
                days: dayValue,
                startTime: new Date(currentStartTime),
                endTime: endTime
            });

            // Adjust the start time for the next producer
            if (endTime.getHours() === 20 || halfDay === 0) {
                currentStartTime = new Date(endTime);
                currentStartTime.setHours(8);
                currentStartTime.setDate(currentStartTime.getDate() + 1);
            } else {
                currentStartTime = endTime;
            }
        }

        tsipouraCalculationData.controls.forEach((group: FormGroup, index) => {
            const producerName = group.get('producerName').value;
            const producerTime = result.find((producer) => producer.producerName === producerName);
            if (producerTime) {
                group.get('startDateTime').setValue(producerTime.startTime, { emitEvent: false });
                group.get('endDateTime').setValue(producerTime.endTime, { emitEvent: false });
            }
        });

        this.producersTime.next(result);

        const lastEndDate = result[result.length - 1].endTime;
        this.tsipouraForm.getValue().get('endDate').setValue(lastEndDate, { emitEvent: false });

        console.log("TsipouraService calculateProducerTimes result", result);
        console.log("TsipouraService calculateProducerTimes tsipouraCalculationData", tsipouraCalculationData.value);
    }

    subToAlcoholVolValueChanges(group: FormGroup<TsipouraCalculationDataForm>) {

        const alcoholVolSub = group.get('alcoholVol').valueChanges.subscribe((value) => {

            console.log('TsipouraService subToAlcoholVoValueChanges', value)

            this.calculateNetWeight(group)

            //this.cf.formatDecimalDigits(group.get('lpa') as FormControl, 2)

        })

        this.alcoholVolSubs.push(alcoholVolSub)
    }
    subToWineGrapesValueChanges(group: FormGroup<TsipouraCalculationDataForm>) {

        const wineGrapesSub = group.get('wineGrapes').valueChanges.subscribe((value) => {

            console.log('TsipouraService subToWineGrapesValueChanges', value)
            const grapeStems = group.get('grapeStems') as FormControl
            grapeStems.setValue(null, { emitEvent: false })
            group.get('nonWineGrapes').setValue(null, { emitEvent: false })

            this.calculateLPA(group, value, grapeStems.value)

            this.calculateNumberOfBoilers(group, value, grapeStems.value)
            //this.cf.formatDecimalDigits(group.get('lpa') as FormControl, 2)

        })

        this.wineGrapesSubs.push(wineGrapesSub)
    }

    subToNonWineGrapesValueChanges(group: FormGroup<TsipouraCalculationDataForm>) {

        const nonWineGrapesSub = group.get('nonWineGrapes').valueChanges.subscribe((value) => {

            console.log('TsipouraService subToNonWineGrapesValueChanges', value)
            const grapeStems = group.get('grapeStems') as FormControl
            const wineGrapes = group.get('wineGrapes') as FormControl

            wineGrapes.setValue(null, { emitEvent: false })
            grapeStems.setValue(this.cf.formatDecimalString(value * this.params.grapesToStems, 2), { emitEvent: false })

            this.calculateLPA(group, null, grapeStems.value)

            this.calculateNumberOfBoilers(group, null, grapeStems.value)

            //this.cf.formatDecimalDigits(group.get('lpa') as FormControl, 2)

        })

        this.nonWineGrapesSubs.push(nonWineGrapesSub)
    }

    subToWineStemsValueChanges(group: FormGroup<TsipouraCalculationDataForm>) {

        const grapeStemsSub = group.get('grapeStems').valueChanges.subscribe((value) => {
            const wineGrapes = group.get('wineGrapes') as FormControl
            const nonWineGrapes = group.get('nonWineGrapes') as FormControl

            if (nonWineGrapes.value) {
                nonWineGrapes.setValue(null, { emitEvent: false })
            }
            wineGrapes.setValue(null, { emitEvent: false })

            this.calculateLPA(group, null, value)

            this.calculateNumberOfBoilers(group, null, value)

        })

        this.grapeStemsSubs.push(grapeStemsSub)
    }

    subToLpaValueChanges(group: FormGroup<TsipouraCalculationDataForm>) {

        const lpaSub = group.get('lpa').valueChanges.subscribe((value) => {

            this.calculateEFK(group, value)
            this.calculateNetWeight(group)

            //  this.calculateTotals()

        })

        this.lpaSubs.push(lpaSub)




    }

    subToBoilerCapacityValueChanges(): Observable<number> {

        const form = this.tsipouraForm.getValue()
        const boilerCapacity = form.get('boilerCapacity') as FormControl

        return boilerCapacity.valueChanges.pipe(tap((value) => {

            const tsipouraCalculationData = form.get('tsipouraCalculationData') as FormArray

            tsipouraCalculationData.controls.forEach((group: FormGroup) => {
                const wineGrapes = group.get('wineGrapes').value
                const grapeStems = group.get('grapeStems').value

                this.calculateNumberOfBoilers(group, wineGrapes, grapeStems)
            })

        }))

    }
    subToWorkHoursPerDayValueChanges(): Observable<number> {

        const form = this.tsipouraForm.getValue()
        const workHoursPerDay = form.get('workHoursPerDay') as FormControl

        return workHoursPerDay.valueChanges.pipe(tap((value) => {

            const tsipouraCalculationData = form.get('tsipouraCalculationData') as FormArray

            tsipouraCalculationData.controls.forEach((group: FormGroup) => {
                const hours = group.get('hours').value

                this.calculateWorkDays(group, hours)
                this.calculateTotals()
            })

        }))

    }

    subToNumberOfBoilersValueChanges(group: FormGroup<TsipouraCalculationDataForm>) {

        const numberOfBoilersSub = group.get('numberOfBoilers').valueChanges.subscribe((value) => {


            this.calculateHours(group, value)

        })

        this.numberOfBoilersSubs.push(numberOfBoilersSub)



    }

    subToWorkHoursValueChanges(group: FormGroup<TsipouraCalculationDataForm>) {

        const hoursSub = group.get('hours').valueChanges.subscribe((value) => {

            this.calculateWorkDays(group, value)
            //setTimeout(() => {
            this.calculateTotals()
            //})

        })

        this.workHoursSubs.push(hoursSub)

    }

    subToObligeeValueChanges(): Observable<number> {

        const form = this.tsipouraForm.getValue()
        const obligee = form.get('obligee') as FormControl
        const boilerOwner = form.get('boilerOwner') as FormControl

        return obligee.valueChanges.pipe(tap((value) => {

            if (value && !boilerOwner.value) {
                boilerOwner.setValue(value)
            }

        }))
    }


    createContact() {
        return this.openDialogsService
            .createContactDialog('domestic', true)
            .pipe(
                concatMap(dialogResults => {
                    console.log('AFTER CONTACT CREATE value', dialogResults);
                    if (dialogResults) {
                        return this.contactService.createContact(dialogResults.contactForm, dialogResults.contactType);
                    }
                    else {
                        return of(null);
                    }
                })
            )

    }


    openApplication(index: number) {
        this.openDialogsService.openApplicationsDialog(DistillationLicenceAppComponent, 'Αίτηση - Δήλωση Διήμερου Μικρού Αποσταγματοποιού', 'distillersApplication', { tsipouraForm: this.tsipouraForm.getValue(), index })
    }

}
