import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'leadingZero3digits'
})
export class LeadingZero3digitsPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const stringValue = '' + value;

        if (stringValue.length === 2) {
            return '0' + value;
        } else if (stringValue.length === 1) {
            return '00' + value;
        } else {
            return stringValue;
        }
    }
}

@NgModule({
    declarations: [LeadingZero3digitsPipe],
    exports: [LeadingZero3digitsPipe],
})
export class LeadingZero3digitsPipeModule { }