import { SeaIdSidModel } from './seaidsid/seaidsid.model';

export class SeainfsilModel {
    SeaNumSLI2?: string;
    SEAIDSID?: SeaIdSidModel[];

    constructor(value?) {
        this.SeaNumSLI2 = value?.SeaNumSLI2 || null;
        this.SEAIDSID = value?.SEAIDSID;
    }
}
