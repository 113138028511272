import declarationData from 'assets/declarationData.json';
import { FormArray, FormGroup } from '@angular/forms';
import { Injectable } from "@angular/core";
import { TsipouraCalculationDataModel, TsipouraModel } from "app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura.model";
import { DEFKDeclarationModel } from "app/main/apps/excise/defk/_models/EF15A/declaration.model";
import { MainBroadcasterService } from "app/services/broadcaster.service";
import { lastValueFrom, map, take } from "rxjs";
import { DEFKDeclarationForm } from 'app/main/apps/excise/defk/_models/EF15A/declaration-form.model';
import { ComplementaryConsigneesModel } from 'app/main/apps/excise/defk/_models/complementary-consignees/complementary-consignees.model';
import { TaxesDeclarationModel } from 'app/main/apps/excise/defk/_models/taxesDeclaration/taxes-declaration.model';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { TaxesRowModel } from 'app/main/apps/excise/defk/_models/taxesRow/taxes-row.model';
import { NationalProductsModel } from 'app/main/apps/excise/defk/_models/taxesRow/national-products/national-products.model';
import { SupplementaryComputationInformationModel } from 'app/main/apps/excise/defk/_models/taxesRow/sup-computation/sup-computation.model';
import { TaxComputationModel } from 'app/main/apps/excise/defk/_models/taxesRow/tax-computation/tax-computation.model';
import { ReferenceDocumentsModel } from 'app/main/apps/excise/defk/_models/taxesRow/reference-docs/reference-docs.model';
import { StorageService } from 'app/core/services/storage/storage.service';
import { GoodsPrivateModel, PrivateControlsModel } from 'app/model/private-controls.model';
import { Declarant } from 'app/core/user/user.model';
import { Router } from '@angular/router';
import { PATHS } from 'app/navigation/paths';
import { OfficeService } from 'app/services/office.service';
import { errorMessages } from 'app/model/error-messages';
import { tsipouraFieldMappings } from 'app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura-field-mappings';
import { ToastrService } from 'ngx-toastr';

export interface TsipouraParameters {

    grapesToLpa: number;
    grapesToStems: number;
    stemsToLpa: number;
    boilerFullCap: number;
    hoursPerBoiler: number;
    workHoursPerDay: string;
    efkPerLpa: number;
    excemption: string;
    taricCode: string;
    addTaricCode: string;
    nationalProduct: string;

    //Limitations
    maxWineGrapes: number;
    maxGrapeStems: number;
    maxBoilerNumberGrapes: number;
    maxBoilerNumberStems: number;
    maxDays: number;
    maxHours: number;

}
@Injectable()
export class TsipouraDefkCreateService {

    constructor(private mainBroadcaster: MainBroadcasterService, private storageService: StorageService, private router: Router, private officeService: OfficeService, private toastrService: ToastrService) { }

    async createDefk(inputData: TsipouraModel, params: TsipouraParameters) {


        const defkForm = new FormGroup(new DEFKDeclarationForm(new DEFKDeclarationModel()))
        const defk = defkForm.value as DEFKDeclarationModel;

        const defaultDeclarant = await lastValueFrom(this.mainBroadcaster.declarants$.pipe(take(1), map(decl => decl.find(d => d.is_owner))));

        console.log('TsipouraDefkCreateService createDefk', inputData, params, defaultDeclarant)

        const privateCtrls = defk._PRIVATE;
        const exciseTaxesDeclaration = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclaration;

        this.setPrivateCtrls(inputData, privateCtrls, defaultDeclarant, params)

        this.setExciseTaxesDeclaration(inputData, exciseTaxesDeclaration)
        this.setDeclarant(defaultDeclarant, exciseTaxesDeclaration);
        this.setObligee(inputData, defk, privateCtrls)
        this.setConsignee(inputData, defk)
        this.setExciseTaxesRows(inputData, defk, params)


        //Set As LocalStorage
        this.storageService.setLocalObject(declarationData['DEFK'].localStorage, defk)
        this.storageService.removeLocalObject('twoDayDistillers');

        this.officeService.currentOffice$.pipe(take(1)).subscribe(office => {

            this.router.navigateByUrl('/blank', { skipLocationChange: true }).then(() => {
                this.router.navigate([PATHS.localDeclaration(office?.id, 'emcs', 'defk')]);
            });
        })


        console.log('TsipouraDefkCreateService createDefk defk', defk.Body.DeclarationOfExciseTaxesRegistration)

    }

    setPrivateCtrls(inputData: TsipouraModel, privateCtrls: PrivateControlsModel, defaultDeclarant: Declarant, params: TsipouraParameters) {
        privateCtrls.declarant = defaultDeclarant;
        privateCtrls.domain = 'EMCS';
        privateCtrls.messageOut = 'EF15A';
        privateCtrls.stateCode = 'Pre-Submitted';
        privateCtrls.subdomain = 'DEFK'
        privateCtrls.trader = inputData.obligee;
        privateCtrls.twoDayDistillers = inputData;

        inputData.tsipouraCalculationData.forEach((calcData, index) => {

            if (!privateCtrls.goodsPrivate[index]) {
                privateCtrls.goodsPrivate.push(new GoodsPrivateModel({
                    forceDescriptionforCode: params.taricCode
                }))
            } else {
                privateCtrls.goodsPrivate[index].forceDescriptionforCode = params.taricCode
            }
        })

    }
    setExciseTaxesDeclaration(inputData: TsipouraModel, exciseTaxesDeclaration: TaxesDeclarationModel) {

        const calculationData = inputData.tsipouraCalculationData
        const totalRows = calculationData.length
        const itemsPerPage = declarationData['DEFK'].itemsPerPage
        const totalDocuments = Math.ceil((totalRows - 1) / itemsPerPage) + 1;

        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so +1 is needed.
        const dd = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}-${mm}-${dd}`;

        this.mainBroadcaster.declarationSettings$.pipe(take(1)).subscribe((settings: DeclarationSettingsModel) => {

            exciseTaxesDeclaration.Fallbackflag = "0";
            exciseTaxesDeclaration.RegistrationOffice = settings.export_customs
            exciseTaxesDeclaration.DocumentState = "0"
            exciseTaxesDeclaration.DocumentNumber = "1"
            exciseTaxesDeclaration.TotalDocuments = totalDocuments.toString()
            exciseTaxesDeclaration.NumberOfRows = totalRows.toString()
            exciseTaxesDeclaration.DispatchCountry = "GR"
            exciseTaxesDeclaration.DestinationCountry = "GR"
            exciseTaxesDeclaration.ExistenceOfContainerFlag = "0"
            exciseTaxesDeclaration.ChemicalAnalysisPerformedFlag = "0"
            exciseTaxesDeclaration.ThroughSimplifiedProcedureFlag = "0"
            exciseTaxesDeclaration.PaymentMethodCode = "H"
            exciseTaxesDeclaration.CertificateIssuanceFlag = "0"
            exciseTaxesDeclaration.DeclarationTypeCode = "30"
            exciseTaxesDeclaration.CalculationOfTaxesDate = formattedDate;


        })
    }

    setDeclarant(defaultDeclarant: Declarant, exciseTaxesDeclaration: TaxesDeclarationModel) {


        exciseTaxesDeclaration.ClearingAgent.ClearingAgentEORI = defaultDeclarant.eori;

        exciseTaxesDeclaration.ClearingAgent.ClearingAgentType = "2";
        exciseTaxesDeclaration.ClearingAgent.DeclarantObject = defaultDeclarant

    }

    setObligee(inputData: TsipouraModel, defk: DEFKDeclarationModel, privateCtrls) {
        const obligee = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesObligee


        obligee.ObligeeType = "7";
        obligee.ObligeeIdentificationType = "1";
        obligee.ObligeeIdentification = inputData.obligee.afm;
        obligee.ObligeeObject = inputData.obligee


    }

    setConsignee(inputData: TsipouraModel, defk: DEFKDeclarationModel) {

        const consignee = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclarationConsignee
        const tsipouraCalculationData: TsipouraCalculationDataModel[] = inputData.tsipouraCalculationData

        const length = tsipouraCalculationData.length;
        if (length === 1 && inputData.obligee.afm === tsipouraCalculationData[0].producerAfm) {
            consignee.ConsigneeType = "0";
        }
        //Συμπληρωματικοι Παραληπτες
        else if (length > 1 || (length === 1 && inputData.obligee.afm !== tsipouraCalculationData[0].producerAfm)) {
            consignee.ConsigneeType = "3";
        }
        else {
            throw new Error('No producers found')
        }
        consignee.ConsigneeIdentificationType = "1";
        consignee.ConsigneeIdentification = inputData.obligee.afm;
        consignee.ConsigneeObject = inputData.obligee;


        if (length > 1 || (length === 1 && inputData.obligee.afm !== tsipouraCalculationData[0].producerAfm)) {

            const complementaryConsignees = consignee.ComplementaryConsignees

            //find in tsipouraCalculationData records with producerAfm different than the boiler owner
            const complementaryConsigneesData = tsipouraCalculationData.filter(data => data.producerAfm !== inputData.obligee.afm)

            console.log("complementaryConsigneesData", complementaryConsigneesData)
            console.log("complementaryConsigneesData length", complementaryConsigneesData.length)


            complementaryConsigneesData.forEach((data, index) => {
                if (complementaryConsignees[index]) {
                    complementaryConsignees[index].ComplementaryConsigneeIdentificationType = "1";
                    complementaryConsignees[index].ComplementaryConsigneeIdentification = data.producerAfm;
                }
                else {
                    consignee.ComplementaryConsignees.push(new ComplementaryConsigneesModel({
                        ComplementaryConsigneeIdentificationType: "1",
                        ComplementaryConsigneeIdentification: data.producerAfm
                    }));
                }
            })


        }

    }

    setExciseTaxesRows(inputData: TsipouraModel, defk: DEFKDeclarationModel, params: TsipouraParameters) {
        const exciseTaxesRow = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesRow
        const calculationData = inputData.tsipouraCalculationData


        calculationData.forEach((producer, i) => {


            const rowNumber = i + 1;
            const marksNumbers = this.setMarksNumbers(producer, inputData)
            const description = this.setDescription(producer, inputData)


            if (exciseTaxesRow[i]) {
                exciseTaxesRow[i].ExciseTaxesDeclarationRowNumber = rowNumber.toString();
                exciseTaxesRow[i].SymbolNumbers = marksNumbers;
                exciseTaxesRow[i].ProductDescription = description;
                exciseTaxesRow[i].TaricCode = params.taricCode;
                exciseTaxesRow[i].TaricAdditionCode = params.addTaricCode;
                exciseTaxesRow[i].TaxQuantity = producer.lpa.toString();
                exciseTaxesRow[i].AdditionalTaxQuantity = producer.lpa.toString();
                exciseTaxesRow[i].CountryOfOrigin = "GR";
                exciseTaxesRow[i].GrossWeight = producer.finalNetWeight.toString();
                exciseTaxesRow[i].NetWeight = producer.lpa.toString();
                exciseTaxesRow[i].ConditionIdentification = "48";
                exciseTaxesRow[i].PreviousConditionIdentification = "00";
                exciseTaxesRow[i].ExemptionCode = params.excemption;
                exciseTaxesRow[i].StatisticalValue = "0.00";
                exciseTaxesRow[i].NationalProducts = this.setNationalProducts(producer, params)
                exciseTaxesRow[i].SupplementaryComputationInformation = this.setSupplementaryComputation(producer)
                exciseTaxesRow[i].ReferenceDocuments = this.setReferenceDocuments(producer, inputData)
                exciseTaxesRow[i].TaxComputation = this.setTaxComputation(producer, params)

            } else {
                exciseTaxesRow.push(new TaxesRowModel({
                    ExciseTaxesDeclarationRowNumber: rowNumber.toString(),
                    SymbolNumbers: marksNumbers,
                    ProductDescription: description,
                    TaricCode: params.taricCode,
                    TaricAdditionCode: params.addTaricCode,
                    TaxQuantity: producer.lpa.toString(),
                    AdditionalTaxQuantity: producer.lpa.toString(),
                    CountryOfOrigin: "GR",
                    GrossWeight: producer.finalNetWeight.toString(),
                    NetWeight: producer.lpa.toString(),
                    ConditionIdentification: "48",
                    PreviousConditionIdentification: "00",
                    ExemptionCode: params.excemption,
                    StatisticalValue: "0.00",
                    NationalProducts: this.setNationalProducts(producer, params),
                    SupplementaryComputationInformation: this.setSupplementaryComputation(producer),
                    ReferenceDocuments: this.setReferenceDocuments(producer, inputData),
                    TaxComputation: this.setTaxComputation(producer, params)


                }));
            }









        })


    }

    setMarksNumbers(producer: TsipouraCalculationDataModel, inputData: TsipouraModel) {

        let description = ''

        description += producer.producerName + ', AΦΜ: ' + producer.producerAfm + '\n';

        if (producer.grapeStems) {

            description += 'Στέμφυλα: ' + producer.grapeStems + 'Kg \n'
        }
        else if (producer.wineGrapes) {
            description += 'Σταφύλια: ' + producer.wineGrapes + 'Kg \n'

        }

        description += 'Κάτοχος Αμβυκα: ' + inputData.boilerOwner.fullname + '\n'

        description += 'ΑΓΜ: ' + inputData.agm

        return description;
    }
    setDescription(producer: TsipouraCalculationDataModel, inputData: TsipouraModel) {

        let description = '';

        description = 'Προιόν Απόσταξης Διήμερου Μικρού Αποσταγματοποιού από '

        if (producer.grapeStems) {

            description += 'στέμφυλα '
        }
        else if (producer.wineGrapes) {
            description += 'σταφύλια '

        }
        description += 'με αλκοολικό βαθμό ' + (producer.alcoholVol * 100) + '%.'

        return description;

    }

    setNationalProducts(producer: TsipouraCalculationDataModel, params: TsipouraParameters) {

        const nationalProducts: NationalProductsModel[] = [{

            NationalProductId: params.nationalProduct,
            Quantity: producer.lpa.toString()

        }]

        return nationalProducts
    }

    setSupplementaryComputation(producer: TsipouraCalculationDataModel) {

        const supCompValues: SupplementaryComputationInformationModel[] = [{

            SupplementaryComputationInfo: 'LPA',
            SupplementaryComputationValue: producer.lpa.toString()
        }]

        return supCompValues
    }

    setTaxComputation(producer: TsipouraCalculationDataModel, params: TsipouraParameters) {

        const taxComputation: TaxComputationModel[] = [{
            _natTypeCode: 'ALC',
            _taxPaidCode: '208',

            NationalAccountingCode: '208',
            SuspensionFlag: "0",
            AmountOfTaxes: producer.efk.toString(),
            PaymentMethodCode: "H",
            TaxBase: producer.lpa.toString(),
            TaxRate: params.efkPerLpa.toString()
        }]

        return taxComputation;
    }
    setReferenceDocuments(producer: TsipouraCalculationDataModel, inputData: TsipouraModel) {

        const refDocs: ReferenceDocumentsModel[] = [

            {
                ReferenceDocumentId: "1E70",
                ReferenceDocumentNumber: null
            },
            {
                ReferenceDocumentId: "1E71",
                ReferenceDocumentNumber: producer.harvestDeclaration ?? null

            }

        ]

        if (producer.producerAfm !== inputData.obligee.afm) {
            refDocs.push({
                ReferenceDocumentId: "4",
                ReferenceDocumentNumber: null

            })
        }

        return refDocs;
    }
}