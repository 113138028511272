import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addWhiteSpace'
})
export class AddWhiteSpace implements PipeTransform {
    transform(value: any, args?: any): any {
        const ret = [];

        let i;

        let len;

        if (value !== undefined) {
            for (i = 0, len = value.length; i < len; i += 2) {
                ret.push(value.substr(i, 2));
            }

            value = ret.join(' ');

            return value;
        }
    }
}

@NgModule({
    declarations: [AddWhiteSpace],
    exports: [AddWhiteSpace],
})
export class AddWhiteSpaceModule { }