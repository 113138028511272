export class ArrAtExitHeaheaModel {
    DocNumHEA5?: string;
    CusSubPlaHEA66?: string;
    ArrNotPlaHEA60?: string;
    ArrNotPlaHEA60LNG?: string;
    ArrAgrLocCodHEA62?: string;
    ArrAgrLocOfGooHEA63?: string;
    ArrAgrLocOfGooHEA63LNG?: string;
    ArrNotDatHEA141?: string;
    DiaLanIndAtDesHEA255?: string;
    StoFlaHEA1?: string;
    AuthStorLocGoodHEA416?: string;

    constructor(value?) {
        if (value) {
            this.DocNumHEA5 = value.DocNumHEA5;
            this.CusSubPlaHEA66 = value.CusSubPlaHEA66;
            this.ArrNotPlaHEA60 = value.ArrNotPlaHEA60;
            this.ArrNotPlaHEA60LNG = value.ArrNotPlaHEA60LNG;
            this.ArrAgrLocCodHEA62 = value.ArrAgrLocCodHEA62;
            this.ArrAgrLocOfGooHEA63 = value.ArrAgrLocOfGooHEA63;
            this.ArrAgrLocOfGooHEA63LNG = value.ArrAgrLocOfGooHEA63LNG;
            this.ArrNotDatHEA141 = value.ArrNotDatHEA141;
            this.DiaLanIndAtDesHEA255 = value.DiaLanIndAtDesHEA255;
            this.StoFlaHEA1 = value.StoFlaHEA1;
            this.AuthStorLocGoodHEA416 = value.AuthStorLocGoodHEA416;
        }
    }
}
