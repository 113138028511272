import { Component, ElementRef, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { TsipouraForm } from 'app/main/apps/excise-helper-tables/tsipoura/_models/tsipoura-form.model';
import { TsipouraService } from 'app/main/apps/excise-helper-tables/tsipoura/tsipoura.service';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tsipoura-calculation-data',
    templateUrl: './tsipoura-calculation-data.component.html',
    styleUrls: ['./tsipoura-calculation-data.component.scss']
})
export class TsipouraCalculationDataComponent implements OnInit {
    @ViewChildren('firstInput') _first: QueryList<ElementRef>;
    tsipouraForm: FormGroup<TsipouraForm>

    alcoholVolOptions = [
        { value: 0.35, desc: "35.0%" },
        { value: 0.355, desc: "35.5%" },
        { value: 0.36, desc: "36.0%" },
        { value: 0.365, desc: "36.5%" },
        { value: 0.37, desc: "37.0%" },
        { value: 0.375, desc: "37.5%" },
        { value: 0.38, desc: "38.0%" },
        { value: 0.385, desc: "38.5%" },
        { value: 0.39, desc: "39.0%" },
        { value: 0.395, desc: "39.5%" },
        { value: 0.40, desc: "40.0%" },
        { value: 0.405, desc: "40.5%" },
        { value: 0.41, desc: "41.0%" },
        { value: 0.415, desc: "41.5%" },
        { value: 0.42, desc: "42.0%" },
        { value: 0.425, desc: "42.5%" },
        { value: 0.43, desc: "43.0%" }
    ]
    get tsipouraCalculationData(): FormArray {
        return this.tsipouraForm.get('tsipouraCalculationData') as FormArray;
    }
    get tsipouraTotals(): FormGroup {
        return this.tsipouraForm.get('tsipouraTotals') as FormGroup;
    }
    constructor(private tsipouraService: TsipouraService, private cf: ExtCustomFunctions) { }

    ngOnInit() {
        this.tsipouraService.tsipouraForm$.subscribe((form) => {

            this.tsipouraForm = form

            this.tsipouraCalculationData.controls.forEach((group, index) => {
                console.log('TsipouraCalculationDataComponent ngOnInit', index)
                console.log('TsipouraCalculationDataComponent ngOnInit', group.value)
                this.tsipouraService.subToAlcoholVolValueChanges(group as FormGroup)
                this.tsipouraService.subToWineGrapesValueChanges(group as FormGroup)
                this.tsipouraService.subToNonWineGrapesValueChanges(group as FormGroup)
                this.tsipouraService.subToWineStemsValueChanges(group as FormGroup)
                this.tsipouraService.subToLpaValueChanges(group as FormGroup)
                this.tsipouraService.subToNumberOfBoilersValueChanges(group as FormGroup)
                this.tsipouraService.subToWorkHoursValueChanges(group as FormGroup)

            })
        })
    }

    ngOnDestroy() {
        this.tsipouraService.alcoholVolSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
        this.tsipouraService.wineGrapesSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
        this.tsipouraService.nonWineGrapesSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
        this.tsipouraService.grapeStemsSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
        this.tsipouraService.lpaSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
        this.tsipouraService.numberOfBoilersSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
        this.tsipouraService.workHoursSubs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
    }

    addProducer() {
        this.tsipouraService.addProducer()
        setTimeout(() => {
            this._first.last.nativeElement.focus();
        })
    }
    deleteProducer(index: number) {
        this.tsipouraService.deleteProducer(index)
    }

    hideAdd() {
        return this.cf.hideAdd(this.tsipouraCalculationData, 99);
    }
    hideDelete() {
        return this.cf.hideDelete(this.tsipouraCalculationData);
    }

    formatDecimals(control: FormControl) {
        if (control.value) {
            this.cf.formatDecimalDigits(control, 2)
        }
    }

    openApplication(index:number){
        this.tsipouraService.openApplication(index)
    }

}
