<!-- <div fxLayout="column" style="width: 100%" fxLayoutAlign="space-between center">
    <div class="results-list-table" fxLayout="column">

   -->
<div class="ecl-container" *ngIf="!dataLoading; else loading">
    <div class="ecl-row">
        <div class="ecl-col-12">
            <mat-table #table [dataSource]="DATASOURCE" matSort matSortActive="country" matSortDirection="asc"
                matSortDisableClear class="wv-table--condensed" class="result-list">
                <!-- lrn Column -->
                <ng-container matColumnDef="resultColumn">
                    <mat-cell *matCellDef="let result; let i = index">
                        <ng-container *ngTemplateOutlet="
                                productRowTemplate;
                                context: { $implicit: result, index: i }
                            "></ng-container>
                    </mat-cell>
                </ng-container>

                <mat-row *matRowDef="let result; columns: displayedColumns"
                    [ngClass]="{ 'row-selectable': result.selectable }" matRipple>
                </mat-row>
            </mat-table>
        </div>

        <div *ngIf="(totalResults$ | async) === 0" fxLayout="row" fxLayoutAlign="center">
            <p class="text-truncate font-weight-600">
                Δεν βρέθηκαν εγγραφές με τα συγκεκριμένα κριτήρια
            </p>
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
            <mat-paginator class="mat-paginator-sticky" *ngIf="DATASOURCE.page$ | async as page"
                [length]="page.totalElements" [pageSize]="page.size" [pageIndex]="page.number" [hidePageSize]="false"
                [pageSizeOptions]="[10, 15, 20]" (page)="DATASOURCE.fetch($event.pageIndex, $event.pageSize)">
            </mat-paginator>
        </div>
    </div>
</div>
<ng-template #productRowTemplate let-result let-index="index">
    <!-- ngIf: isProductSelectable(result) -->
    <span *ngIf="result.selectable" (click)="selectProduct(result, index)"
        class="product-search-desc product-selectable">
        <span class="code treeleaf product-selectable-row">&nbsp;</span>&nbsp;&nbsp;<span
            [innerHTML]="result.description"></span>
    </span>
    <!-- ngIf: !isProductSelectable(result) -->
    <span *ngIf="!result.selectable" class="product-search-desc product-non-selectable">
        <span class="branch"></span>&nbsp;&nbsp;<span [innerHTML]="result.description"></span>
    </span>

    <div class="prod" *ngIf="result.selectable" (click)="selectProduct(result, index)">
        <span class="chapter">{{ result.chapter }}</span>
        <span *ngIf="result.heading !== '00'" class="heading">{{
            result.heading | addWhiteSpace
            }}</span>
        <span *ngIf="result.subheading !== '000000'" class="subheading">{{
            result.subheadingStyle
            }}</span>
    </div>
    <div class="prod code-non-selectable" *ngIf="!result.selectable">
        <span class="chapter">{{ result.chapter }}</span>
        <span *ngIf="result.heading !== '00'" class="heading">{{
            result.heading
            }}</span>
        <span *ngIf="result.subheading !== '000000'" class="subheading">{{
            result.subheadingStyle
            }}</span>
    </div>
    <div class="prod interval code-non-selectable" *ngIf="!result.selectable && !result.code && result.intervalMin">
        <span class="chapter">{{ result.intervalMinChapter }}</span>
        <span *ngIf="result.intervalMinHeading !== '00'" class="heading">{{
            result.intervalMinHeading
            }}</span>
        <span class="subheading">{{ result.intervalMinSubheadingStyle }}</span>
        -
        <span class="chapter" *ngIf="result.intervalMinChapter !== result.intervalMaxChapter">{{
            result.intervalMaxChapter }}</span>
        <span class="heading" *ngIf="result.intervalMinHeading !== result.intervalMaxHeading">{{
            result.intervalMaxHeading }}</span>
        <span class="subheading">{{ result.intervalMaxSubheadingStyle }}</span>
    </div>
    <!-- end ngIf: !isProductSelectable(result) -->
    <!-- ngIf: !isProductSelectable(result) && result.code && result.intervalMin -->

    <div class="prod-branch-desc">
        <ol class="prod-branch">
            <ng-container *ngFor="let ancestor of result.ancestors">
                <!-- ngRepeat: ancestor in result.ancestors --><!-- ngIf: result.id !== ancestor.id -->
                <li *ngIf="result.id !== ancestor.id">
                    {{ ancestor.description }}
                </li>
            </ng-container>
            <!-- end ngRepeat: ancestor in result.ancestors --><!-- ngIf: result.id !== ancestor.id -->
            <!-- end ngRepeat: ancestor in result.ancestors -->
        </ol>
    </div>

    <div class="showintree-showproduct-container">
        <a class="ecl-button ecl-button--secondary showintree" (click)="showInTree(result)">
            <span class="browseiconblue"></span>
            <span class="browse-text">Εμφάνιση στον κατάλογο εμπορευμάτων</span>
        </a>
        <!-- ngIf: isProductSelectable(result) -->
        <a class="ecl-button ecl-button--call showproduct" *ngIf="result.selectable"
            (click)="selectProduct(result, index)">
            <span *ngIf="!isLoading[index]" class="code treeleaf product-selectable-row margintopMenus2">&nbsp;</span>
            <mat-icon *ngIf="isLoading[index]"><mat-spinner style="
                        background: #ffe879;
                        border-radius: 50%;
                        border-color: #ffe879;
                    " color="#003776" diameter="20">
                </mat-spinner></mat-icon>
            <span class="browse-text">Εμφάνιση μέτρων</span>
        </a>
        <a class="ecl-button ecl-button--call showproduct useproduct" *ngIf="
                result.selectable && showActionButtons && !(formDisabled$ | async)" [matMenuTriggerFor]="actionsMenu"
            [matMenuTriggerData]="{
                product: result
            }">
            <mat-icon style="min-height: 22px; min-width: 28px">more_vert</mat-icon>
            <span class="browse-text">Χρήση</span>
        </a>
    </div>
</ng-template>

<!-- ACTIONS MENU -->
<mat-menu #actionsMenu="matMenu">
    <ng-template matMenuContent let-product="product">
        <taric-actions-menu [product]="product" (setCodeDescription)="setCodeDesc($event)"></taric-actions-menu>
    </ng-template> </mat-menu><!-- /ACTIONS MENU -->

<ng-template #loading>
    <div>LOADING...</div>
</ng-template>