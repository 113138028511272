import { ConsignorContactDetailsModel } from "../consignorContactDetails/consignorContactDetails.model";

export class VehicleArrivalConsignorModel {


    PersonIdentificationType: string;
    TaxIdNumber?: string;
    ConsignorContactDetails?: ConsignorContactDetailsModel;
    ConsignorObject?: {};



    constructor(value?) {

        this.PersonIdentificationType = value?.PersonIdentificationType || null;
        this.TaxIdNumber = value?.TaxIdNumber;
        this.ConsignorContactDetails = value?.ConsignorContactDetails;
        this.ConsignorObject = value?.ConsignorObject;

    }

}
