import { TirisiEggrafonContactDetailsModel, TirisiEggrafonCustomer, TirisiEggrafonPlaceModel, TirisiEggrafonDeclarant } from './topos-tirisis-eggrafon.model';
import { FormControl, AbstractControl, FormGroup } from '@angular/forms';
import { ToposTirisisEggafonModel } from 'app/main/apps/general-applications/topos-tirisis-eggrafon/_models/topos-tirisis-eggrafon.model';

export class ToposTirisisEggafonFormModel {
    [key: string]: AbstractControl;

    customsName: FormControl;
    customsCode: FormControl;
    customer: FormGroup;
    declarant: FormGroup;
    documentsPlace: FormGroup;
    contactDetails: FormGroup;
    date: FormControl;
    constructor(data: ToposTirisisEggafonModel) {

        this.customsName = new FormControl(data.customsName);
        this.customsCode = new FormControl(data.customsCode);
        this.customer = new FormGroup(new TirisiEggrafonCustomerForm(new TirisiEggrafonCustomer(data.customer)));
        this.declarant = new FormGroup(new TirisiEggrafonDeclarantForm(new TirisiEggrafonDeclarant(data.declarant)));
        this.documentsPlace = new FormGroup(new TirisiEggrafonPlaceFormModel(new TirisiEggrafonPlaceModel(data.documentsPlace)));
        this.contactDetails = new FormGroup(new TirisiEggrafonContactDetailsFormModel(new TirisiEggrafonContactDetailsModel(data.contactDetails)));
        this.date = new FormControl(data.date);
    }
}

export class TirisiEggrafonCustomerForm {
    [key: string]: AbstractControl;

    customerObject: FormControl;
    contactDetails: FormGroup;

    constructor(value?: TirisiEggrafonCustomer) {

        this.customerObject = new FormControl(value?.customerObject);
        this.contactDetails = new FormGroup(new TirisiEggrafonContactDetailsFormModel(new TirisiEggrafonContactDetailsModel(value?.contactDetails)));
    }

}

export class TirisiEggrafonDeclarantForm {

    [key: string]: AbstractControl;

    declarantObject: FormControl;
    contactDetails: FormGroup;

    constructor(value?: TirisiEggrafonDeclarant) {

        this.declarantObject = new FormControl(value?.declarantObject);
        this.contactDetails = new FormGroup(new TirisiEggrafonContactDetailsFormModel(new TirisiEggrafonContactDetailsModel(value?.contactDetails)));
    }
}

export class TirisiEggrafonPlaceFormModel {
    [key: string]: AbstractControl;

    place: FormControl;
    lastname: FormControl;
    firstname: FormControl;
    street: FormControl;
    streetNumber: FormControl;
    postalCode: FormControl;
    city: FormControl;
    county: FormControl;
    prefecture: FormControl;
    workingHours: FormControl;

    constructor(value?: TirisiEggrafonPlaceModel) {

        this.place = new FormControl(value?.place);
        this.lastname = new FormControl(value?.lastname);
        this.firstname = new FormControl(value?.firstname);
        this.street = new FormControl(value?.street);
        this.streetNumber = new FormControl(value?.streetNumber);
        this.postalCode = new FormControl(value?.postalCode);
        this.city = new FormControl(value?.city);
        this.county = new FormControl(value?.county);
        this.prefecture = new FormControl(value?.prefecture);
        this.workingHours = new FormControl(value?.workingHours);
    }
}

export class TirisiEggrafonContactDetailsFormModel {
    [key: string]: AbstractControl;


    lastname: FormControl; //This is the same as the customer's lastname
    firstname: FormControl; //This is the same as the customer's firstname
    phone: FormControl;
    fax: FormControl;
    email: FormControl;
    constructor(value?: TirisiEggrafonContactDetailsModel) {
        this.lastname = new FormControl(value?.lastname);
        this.firstname = new FormControl(value?.firstname);
        this.phone = new FormControl(value?.phone);
        this.fax = new FormControl(value?.fax);
        this.email = new FormControl(value?.email);
    }
}
