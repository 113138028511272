<div fxLayout="column" style="width: 100%; flex-grow: 1">

    <div mat-dialog-title class="{{ confirmClass }}" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <div>{{errorStatus}}</div>

            <div style="font-size:13px"> Η διασάφηση θα επιστρέψει στην προηγούμενη κατασταση: <b>{{stateCode |
                    declarationState}}</b>
            </div>
        </div>

        <div>
            <button mat-icon-button (click)="dialogRef.close()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content fxLayout="column" fxLayoutAlign="flex-start center" style="
    font-size:15px;
padding: 0 16px;">

        <ng-container [ngTemplateOutlet]="template === 'Edit' ? edit : template === 
   'Cancel' ? cancel : error" [ngTemplateOutletContext]="{ response, stateCode}"></ng-container>

    </mat-dialog-content>
    <div mat-dialog-actions>
        <button mat-raised-button class="{{ confirmClass }} mr-16" (click)="dialogRef.close()">
            {{ confirmButton }}
        </button>
    </div>
</div>

<ng-template #cancel let-response="response">
    <div class="group">
        <h2>Απόφαση</h2>
        <table class="error-dialog-table">
            <tbody>
                <tr>
                    <td>Παραστατικό / Αριθμός Αναφοράς</td>
                    <td>{{response.mrn}}</td>
                </tr>
                <tr *ngIf="response.data.cancelReqDate">
                    <td>Ημ/νία Αίτησης Ακύρωσης</td>
                    <td>{{response.data.cancelReqDate | dateTransform:"dd/mm/yyyy"}}</td>
                </tr>
                <tr *ngIf="response.data.cancelDecDate">
                    <td>Ημ/νία Απόφασης Ακύρωσης</td>
                    <td>{{response.data.cancelDecDate | dateTransform:"dd/mm/yyyy"}}</td>
                </tr>
                <tr *ngIf="response.data.cancelReason">
                    <td>Αιτιολογία Απόρριψης</td>
                    <td><b>{{response.data.cancelReason}}</b></td>
                </tr>
                <tr *ngIf="response.data.cancelInitByCustoms">
                    <td colspan="2">Η διασάφηση ακυρώθηκε με πρωτοβουλία του Τελωνείου</td>
                </tr>

            </tbody>

        </table>
    </div>

    <div class="group" *ngIf="errors">
        <h2>Σφάλματα</h2>
        <ng-container *ngFor="let error of errors; let last=last">

            <table class="error-dialog-table">
                <tbody>

                    <tr *ngIf="error.errorType">
                        <td>Τύπος Σφάλματος</td>
                        <td>{{error.errorType}}</td>
                    </tr>

                    <tr *ngIf="error.errorDesc">
                        <td>Περιγραφή Σφάλματος</td>
                        <td style="color:red">{{error.errorDesc}}</td>
                    </tr>
                    <tr *ngIf="!error.errorDesc">
                        <td>Περιγραφή Σφάλματος</td>
                        <td>{{error.explanation}}</td>
                    </tr>

                    <!--   <tr *ngIf="error.errorPointer">
                            <td>Θέση</td>
                            <td>{{error.errorPointer}}</td>
                        </tr> -->
                    <tr *ngIf="error.errorOriAttVal">
                        <td>Λάθος Τιμή</td>
                        <td>{{error.errorOriAttVal}}</td>
                    </tr>
                    <tr *ngIf="error.errorReason">
                        <td>Αιτιολογία Σφάλματος</td>
                        <td>{{error.errorReason}}</td>
                    </tr>


                    <tr *ngIf="!last">
                        <td colspan="2">
                            <mat-divider [inset]="true" style="margin:0 auto"></mat-divider>
                        </td>
                    </tr>

                </tbody>
            </table>



        </ng-container>
    </div>

</ng-template>


<ng-template #edit let-response="response">
    <div class="group">
        <h2>Απόφαση</h2>
        <table class="error-dialog-table">
            <tbody>
                <tr>
                    <td>Παραστατικό / Αριθμός Αναφοράς</td>
                    <td>{{response.mrn}}</td>
                </tr>
                <tr *ngIf="response.data.amendDate">
                    <td>Ημ/νία Τροποποίησης</td>
                    <td>{{response.data.amendDate | dateTransform:"dd/mm/yyyy"}}</td>
                </tr>
                <tr *ngIf="response.data.amendRejectionDate">
                    <td>Ημ/νία Απόρριψης Τροποποίησης</td>
                    <td>{{response.data.amendRejectionDate | dateTransform:"dd/mm/yyyy"}}</td>
                </tr>
                <tr *ngIf="response.data.amendRejectionMotivationText">
                    <td>Αιτιολογία Απόρριψης</td>
                    <td><b>{{response.data.amendRejectionMotivationText}}</b></td>
                </tr>

            </tbody>

        </table>

    </div>


    <div class="group">
        <h2>Σφάλματα</h2>
        <ng-container *ngFor="let error of errors; let last=last">

            <table class="error-dialog-table">
                <tbody>

                    <tr *ngIf="error.errorType">
                        <td>Τύπος Σφάλματος</td>
                        <td>{{error.errorType}}</td>
                    </tr>

                    <tr *ngIf="error.errorDesc">
                        <td>Περιγραφή Σφάλματος</td>
                        <td style="color:red">{{error.errorDesc}}</td>
                    </tr>
                    <tr *ngIf="!error.errorDesc">
                        <td>Περιγραφή Σφάλματος</td>
                        <td>{{error.explanation}}</td>
                    </tr>

                    <!--   <tr *ngIf="error.errorPointer">
                            <td>Θέση</td>
                            <td>{{error.errorPointer}}</td>
                        </tr> -->
                    <tr *ngIf="error.errorOriAttVal">
                        <td>Λάθος Τιμή</td>
                        <td>{{error.errorOriAttVal}}</td>
                    </tr>
                    <tr *ngIf="error.errorReason">
                        <td>Αιτιολογία Σφάλματος</td>
                        <td>{{error.errorReason}}</td>
                    </tr>


                    <tr *ngIf="!last">
                        <td colspan="2">
                            <mat-divider [inset]="true" style="margin:0 auto"></mat-divider>
                        </td>
                    </tr>

                </tbody>
            </table>



        </ng-container>
    </div>
</ng-template>


<ng-template #error let-response="response">
    <div class="group" *ngIf="errors">
        <h2>Σφάλματα</h2>
        <ng-container *ngFor="let error of errors; let last=last">

            <table class="error-dialog-table">
                <tbody>

                    <tr *ngIf="error.errorType">
                        <td>Τύπος Σφάλματος</td>
                        <td>{{error.errorType}}</td>
                    </tr>

                    <tr *ngIf="error.errorDesc">
                        <td>Περιγραφή Σφάλματος</td>
                        <td style="color:red">{{error.errorDesc}}</td>
                    </tr>
                    <tr *ngIf="!error.errorDesc">
                        <td>Περιγραφή Σφάλματος</td>
                        <td>{{error.explanation}}</td>
                    </tr>

                    <!--   <tr *ngIf="error.errorPointer">
                            <td>Θέση</td>
                            <td>{{error.errorPointer}}</td>
                        </tr> -->
                    <tr *ngIf="error.errorOriAttVal">
                        <td>Λάθος Τιμή</td>
                        <td>{{error.errorOriAttVal}}</td>
                    </tr>
                    <tr *ngIf="error.errorReason">
                        <td>Αιτιολογία Σφάλματος</td>
                        <td>{{error.errorReason}}</td>
                    </tr>


                    <tr *ngIf="!last">
                        <td colspan="2">
                            <mat-divider [inset]="true" style="margin:0 auto"></mat-divider>
                        </td>
                    </tr>

                </tbody>
            </table>



        </ng-container>
    </div>
</ng-template>