import { ChemistryDetailsType } from "../chemical-details/chemistry-details.model";
import { ClearingAgentType } from "../clearing-agent/clearing-agent.model";
import { DeliveryDetailsType } from "../delivery-details/delivery-details.model";
import { GuaranteeReferenceType } from "../guarantee-reference/guarantee-reference.model";

export class TaxesDeclarationModel {
    DeclarationReferenceNumber?: string;

    LocalReferenceNumber?: string;
    Fallbackflag?: string;
    FallbackDocumentReference?: string;
    FallbackOffice?: string;
    RegistrationDate?: string;
    RegistrationOffice?: string;
    DocumentState?: string;
    DocumentNumber?: string;
    TotalDocuments?: string;
    NumberOfRows?: string;
    TotalPackages?: string;
    FinancialResponsibleTaxId?: string;
    DispatchCountry?: string;
    DestinationCountry?: string;
    ExistenceOfContainerFlag?: string;
    DeliveryConditions?: string;
    Currency?: string;
    InvoiceAmount?: string;
    ExchangeFactor?: string;
    ChemicalAnalysisPerformedFlag?: string;
    ThroughSimplifiedProcedureFlag?: string;
    OfficeOfDispatch?: string;
    PaymentMethodCode?: string;
    CertificateIssuanceFlag?: string;
    DeclarationTypeCode?: string;
    CalculationOfTaxesDate?: string;
    GeneralChemistryDetails?: ChemistryDetailsType;
    DeliveryDetails?: DeliveryDetailsType;
    ClearingAgent?: ClearingAgentType;
    GuaranteeReference?: GuaranteeReferenceType;

    constructor(value?) {
        this.DeclarationReferenceNumber = value?.DeclarationReferenceNumber || null;
        this.LocalReferenceNumber = value?.LocalReferenceNumber || null;
        this.Fallbackflag = value?.Fallbackflag || null;
        this.FallbackDocumentReference = value?.FallbackDocumentReference || null;
        this.FallbackOffice = value?.FallbackOffice || null;
        this.RegistrationDate = value?.RegistrationDate || null;
        this.RegistrationOffice = value?.RegistrationOffice || null;
        this.DocumentState = value?.DocumentState;
        this.DocumentNumber = value?.DocumentNumber || null;
        this.TotalDocuments = value?.TotalDocuments || null;
        this.NumberOfRows = value?.NumberOfRows || null;
        this.TotalPackages = value?.TotalPackages || null;
        this.FinancialResponsibleTaxId = value?.FinancialResponsibleTaxId || null;
        this.DispatchCountry = value?.DispatchCountry || null;
        this.DestinationCountry = value?.DestinationCountry || null;
        this.ExistenceOfContainerFlag = value?.ExistenceOfContainerFlag || null;
        this.DeliveryConditions = value?.DeliveryConditions || null;
        this.Currency = value?.Currency || null;
        this.InvoiceAmount = value?.InvoiceAmount || null;
        this.ExchangeFactor = value?.ExchangeFactor || null;
        this.ChemicalAnalysisPerformedFlag = value?.ChemicalAnalysisPerformedFlag || null;
        this.ThroughSimplifiedProcedureFlag = value?.ThroughSimplifiedProcedureFlag || null;
        this.OfficeOfDispatch = value?.OfficeOfDispatch || null;
        this.PaymentMethodCode = value?.PaymentMethodCode || null;
        this.CertificateIssuanceFlag = value?.CertificateIssuanceFlag || null;
        this.DeclarationTypeCode = value?.DeclarationTypeCode || null;
        this.CalculationOfTaxesDate = value?.CalculationOfTaxesDate || null;
        this.GeneralChemistryDetails = value?.GeneralChemistryDetails || null;
        this.DeliveryDetails = value?.DeliveryDetails || null;
        this.ClearingAgent = value?.ClearingAgent || null;
        this.GuaranteeReference = value?.GuaranteeReference || null;
    }
}
