import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { ItiModel } from './iti.model';

export class ItiForm {
    [key: string]: FormControl;

    CouOfRouCodITI1 = new FormControl();

    constructor(value: ItiModel) {
        this.CouOfRouCodITI1.setValue(value.CouOfRouCodITI1);
    }
}
