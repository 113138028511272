import { FormControl, Validators } from '@angular/forms';
import { SeaIdSidModel } from './seaidsid.model';

export class SeaIdSidForm {
    [key: string]: FormControl;

    SeaIdeSID1 = new FormControl();
    SeaIdeSID1LNG = new FormControl();

    constructor(value: SeaIdSidModel) {
        this.SeaIdeSID1.setValue(value.SeaIdeSID1);
        this.SeaIdeSID1LNG.setValue(value.SeaIdeSID1LNG);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
