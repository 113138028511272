import { FormControl, Validators } from '@angular/forms';
import { TradecModel } from './tradec.model';

export class TradecForm {
    [key: string]: FormControl;

    NamTDE1 = new FormControl();
    StrAndNumTDE1 = new FormControl();
    PosCodTDE1 = new FormControl();
    CiTDE1 = new FormControl();
    CouCodTDE1 = new FormControl();
    TRADECLNG = new FormControl();
    TINTDE1 = new FormControl();
    SpeMenTDE1022 = new FormControl();
    TINTypDECTRA = new FormControl();
    DeclarantObject = new FormControl();

    constructor(value: TradecModel) {
        this.NamTDE1.setValue(value.NamTDE1);
        this.StrAndNumTDE1.setValue(value.StrAndNumTDE1);
        this.PosCodTDE1.setValue(value.PosCodTDE1);
        this.CiTDE1.setValue(value.CiTDE1);
        this.CouCodTDE1.setValue(value.CouCodTDE1);
        this.TRADECLNG.setValue(value.TRADECLNG);
        this.TINTDE1.setValue(value.TINTDE1);
        this.SpeMenTDE1022.setValue(value.SpeMenTDE1022);
        this.TINTypDECTRA.setValue(value.TINTypDECTRA);
        this.DeclarantObject.setValue(value.DeclarantObject);

        this.TINTDE1.setValidators([Validators.required]);
    }
}
