import { FormControl } from '@angular/forms';
import { TraautcontraModel } from './traautcontra.model';

export class TraautcontraForm {
    [key: string]: FormControl;

    TINTRA59 = new FormControl();
    TinTypTRA1009 = new FormControl();

    constructor(value: TraautcontraModel) {
        this.TINTRA59.setValue(value.TINTRA59);
        this.TinTypTRA1009.setValue(value.TinTypTRA1009);
    }
}
