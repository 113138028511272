export class DeliveryPlaceModel {

    StreetName: string;
    StreetNumber?: string;
    Municipality: string;
    PostCode: string;
    Prefecture: string;


    constructor(value?) {

        this.StreetName = value?.StreetName || null;
        this.StreetNumber = value?.StreetNumber;
        this.Municipality = value?.Municipality || null;
        this.PostCode = value?.PostCode || null;
        this.Prefecture = value?.Prefecture || null;
    }

}
