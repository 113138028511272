export class PreAdmRefModel {
    PreDocTypAR21?: string;
    PreDocRefAR26?: string;
    PreDocRefLNG?: string;
    PreDocCatPREADMREF21?: string;
    PreDocMrnAR1004?: string;
    PreDocIteAR1005?: string;
    PreDocQuaAR1006?: string;
    PreDocSupAR1007?: string;
    PreDocSumDecPacAR1035?: string;
    PreDocBilOfLadTyp10?: string;
    PreDocBilOfLadNum11?: string;

    constructor(value?) {
        this.PreDocTypAR21 = value?.PreDocTypAR21 || null;
        this.PreDocRefAR26 = value?.PreDocRefAR26 || null;
        this.PreDocRefLNG = value?.PreDocRefLNG || null;
        this.PreDocCatPREADMREF21 = value?.PreDocCatPREADMREF21 || null;
        this.PreDocMrnAR1004 = value?.PreDocMrnAR1004 || null;
        this.PreDocIteAR1005 = value?.PreDocIteAR1005 || null;
        this.PreDocQuaAR1006 = value?.PreDocQuaAR1006 || null;
        this.PreDocSupAR1007 = value?.PreDocSupAR1007 || null;
        this.PreDocSumDecPacAR1035 = value?.PreDocSumDecPacAR1035 || null;
        this.PreDocBilOfLadTyp10 = value?.PreDocBilOfLadTyp10 || null;
        this.PreDocBilOfLadNum11 = value?.PreDocBilOfLadNum11 || null;
    }
}
