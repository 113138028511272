import { genericItem } from 'app/model/api-model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { VehiclesForm } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles-form.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { Observable, map, switchMap, tap, startWith } from 'rxjs';
import { VehiclesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicles.service';
import { VehiclesQueriesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicle-queries.service';

@Component({
    selector: 'cars-header-inputs',
    templateUrl: './cars-header-inputs.component.html',
    styleUrls: ['./cars-header-inputs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarsHeaderInputsComponent implements OnInit {

    @Input() vehicleTypeOptions: genericItem[] = [];

    euroClassOptions$: Observable<any[]>

    vehiclesForm$: Observable<FormGroup<VehiclesForm>>;
    vehiclesForm: FormGroup<VehiclesForm>;

    chassisTypeOptions$: Observable<genericItem[]>;
    vehicleTypeGeneral$: Observable<string>;
    priceTitle = 'Λ.Τ. ΠΡΟ ΦΟΡΩΝ';

    constructor(private vehiclesService: VehiclesService, private vehiclesQueriesService: VehiclesQueriesService, private cf: ExtCustomFunctions, private cd: ChangeDetectorRef) {

        console.log("VehiclesComponentsRace CarsHeaderInputsComponent")

    }

    ngOnInit() {


        this.euroClassOptions$ = this.vehiclesQueriesService.euroClassOptions$

        this.chassisTypeOptions$ = this.vehiclesQueriesService.chassisTypeOptions$


        this.vehiclesForm$ = this.vehiclesService.vehiclesForm$.pipe(

            switchMap((form) => form.valueChanges.pipe(startWith(form.value), map(value => { return { form, value } }))),
            tap((value) => {

                const vehicleType = value.form.get('vehicleType').value;
                if (vehicleType === '2') {//Fortigo >3.5tn
                    this.priceTitle = 'ΤΙΜΗ ΑΓΟΡΑΣ (CIF)'
                }
                else {
                    this.priceTitle = 'Λ.Τ. ΠΡΟ ΦΟΡΩΝ'
                }
                console.log('CarsCalculationDataComponent valueChanges', value.value)
            }),
            map(value => value.form),

        )


        this.vehicleTypeGeneral$ = this.vehiclesService.vehicleTypeGeneral$
    }


    changeDateEvent(event: MatDatepickerInputEvent<Date>, controlName) {
        const momentDate = event.value as unknown as moment.Moment;
        const jsDate = momentDate.toDate();
        this.vehiclesForm.get(controlName).setValue(jsDate);
        console.log("changeDateTimeEvent", jsDate)
    }

    formatDecimals(controlName: string) {
        if (!this.vehiclesForm) return;
        if (!this.vehiclesForm.get(controlName)) return;

        const control = this.vehiclesForm.get(controlName) as FormControl
        if (control.value) {
            this.cf.formatDecimalDigits(control, 2)
        }
    }

}
