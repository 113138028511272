import { Injectable } from "@angular/core";
import { CanLoad, Router, Route, UrlSegment, NavigationEnd } from "@angular/router";
import { OfficeService } from "app/services/office.service";
import { Observable, filter, map, of, switchMap, take } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class OfficeGuard implements CanLoad {
    constructor(
        private officeService: OfficeService,
        private router: Router
    ) {

    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {

        const officeId = segments?.length > 0 ? segments?.[0]?.path : null;
        console.log("officeGuard officeId", officeId)

        return this.officeService.officesFetched.pipe(

            filter(fetched => fetched === true),
            take(1),
            switchMap(_ => {

                //Check that the office exists
                const office = this.officeService.getOfficeById(officeId);
                console.log("officeGuard office", office?.office_name)


                if (office) {
                    this.officeService.setCurrentOffice(office);
                    return of(true);

                } else {
                    this.router.navigate(['/']); // Navigate to an error page if the office does not exist
                    return of(false);
                }

            })
        )

    }
}
