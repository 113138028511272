export class AddCon789Model {
    NamAdTraAdCo1?: string;
    StrNumAdTraAdCo2?: string;
    PosCoAdTraAdCo3?: string;
    CitAdTraAdCo4?: string;
    NADLNGAdTraAdCo6?: string;
    VATAdTraAdCo7?: string;
    IdCarAdTraAdCo8?: string;
    OwnAdTraAdCo9?: string;
    OwnPerAdTraAdCo10?: string;
    PhoNumASCOASTR?: string;
    AddConsigneeObject?: {};

    constructor(value?) {
        this.NamAdTraAdCo1 = value?.NamAdTraAdCo1 || null;
        this.StrNumAdTraAdCo2 = value?.StrNumAdTraAdCo2 || null;
        this.PosCoAdTraAdCo3 = value?.PosCoAdTraAdCo3 || null;
        this.CitAdTraAdCo4 = value?.CitAdTraAdCo4 || null;
        this.NADLNGAdTraAdCo6 = value?.NADLNGAdTraAdCo6 || null;
        this.VATAdTraAdCo7 = value?.VATAdTraAdCo7 || null;
        this.IdCarAdTraAdCo8 = value?.IdCarAdTraAdCo8 || null;
        this.OwnAdTraAdCo9 = value?.OwnAdTraAdCo9 || null;
        this.OwnPerAdTraAdCo10 = value?.OwnPerAdTraAdCo10 || null;
        this.PhoNumASCOASTR = value?.PhoNumASCOASTR || null;
        this.AddConsigneeObject = value?.AddConsigneeObject || {};
    }
}
