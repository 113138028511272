<div *ngIf="inputField === 'textarea'" [formGroup]="form">
    <div>
        <mat-form-field [class]="classes" floatLabel="never">
            <textarea #firstInput #autosize="cdkTextareaAutosize" style="overflow: hidden; line-height: 1.5vw"
                cdkTextareaAutosize cdkAutosizeMinRows="1" [cdkAutosizeMaxRows]="maxRows" matInput (focus)="onFocus()"
                (blur)="onError(hasError(control))" [formControlName]="controlName" [class]="inputClass"
                (keyup)="onKeyUp($event)" [maxlength]="maxlength" [readonly]="readonly" [tabindex]="tabindex"
                [placeholder]="placeholder"></textarea>
        </mat-form-field>
    </div>
</div>

<div *ngIf="inputField === 'input' && !digitsOnly" [formGroup]="form">
    <div>
        <mat-form-field [class]="classes" floatLabel="never">
            <input #firstInput matInput (focus)="onFocus()" (blur)="formatDecimal();onError(hasError(control))"
                [formControlName]="controlName" [class]="inputClass" (keyup)="onKeyUp($event)" [maxlength]="maxlength"
                [required]="required" [readonly]="readonly" [tabindex]="tabindex" [placeholder]="placeholder" />
        </mat-form-field>
    </div>
</div>

<div *ngIf="inputField === 'input' && digitsOnly" [formGroup]="form">
    <div>
        <mat-form-field [class]="classes" floatLabel="never">
            <input #firstInput matInput digitOnly [decimal]="decimal" (focus)="onFocus()"
                (blur)="formatDecimal(nullOnEmpty);onError(hasError(control))" [formControlName]="controlName"
                [class]="inputClass" (keyup)="onKeyUp($event)" [maxlength]="maxlength" [required]="required"
                [readonly]="readonly" [tabindex]="tabindex" [placeholder]="placeholder" />
        </mat-form-field>
    </div>
</div>