import { FormControl, AbstractControl } from '@angular/forms';
import { MeaOfPro200Model } from './meaofpro200.model';

export class MeaOfPro200Form {
    [key: string]: AbstractControl;

    DIPRecIDMEAOFPRO1 = new FormControl();
    DesMeaofProMEAOFPRO2 = new FormControl();
    QuaMEAOFPRO3 = new FormControl();

    constructor(value: MeaOfPro200Model) {
        this.DIPRecIDMEAOFPRO1.setValue(value.DIPRecIDMEAOFPRO1);
        this.DesMeaofProMEAOFPRO2.setValue(value.DesMeaofProMEAOFPRO2);
        this.QuaMEAOFPRO3.setValue(value.QuaMEAOFPRO3);
    }
}
