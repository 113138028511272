export class CustomsFiltersModel {
    //This is the model of the object that will be passed on our form in case we have some initial values that need to be inserted

    searchQuery?: string;
    countryQuery?: string;

    constructor(value?) {
        if (value) {
            this.searchQuery = value.searchQuery;
            this.countryQuery = value.countryQuery;
        }
    }
}
