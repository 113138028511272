import { FormControl } from '@angular/forms';
import { eAitisiTrarepModel } from './trarep.model';

export class eAitisiTrarepForm {
    [key: string]: FormControl;

    NamTRE1 = new FormControl();
    VatNumTRE = new FormControl();
    DeclarantObject = new FormControl();

    constructor(value: eAitisiTrarepModel) {
        this.NamTRE1.setValue(value.NamTRE1);
        this.VatNumTRE.setValue(value.VatNumTRE);
        this.DeclarantObject.setValue(value.DeclarantObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
