import { FormControl } from '@angular/forms';
import { Traconsecgoo013Model } from './traconsecgoo013.model';

export class Traconsecgoo013Form {
    [key: string]: FormControl;

    NamTRACONSECGOO017 = new FormControl();
    StrNumTRACONSECGOO019 = new FormControl();
    PosCodTRACONSECGOO018 = new FormControl();
    CityTRACONSECGOO014 = new FormControl();
    CouCodTRACONSECGOO015 = new FormControl();
    TRACONSECGOO013LNG = new FormControl();
    TINTRACONSECGOO020 = new FormControl();
    TINTypTRACONSECGOO021 = new FormControl();

    ExporterObject = new FormControl();

    constructor(value: Traconsecgoo013Model) {
        this.NamTRACONSECGOO017.setValue(value.NamTRACONSECGOO017);
        this.StrNumTRACONSECGOO019.setValue(value.StrNumTRACONSECGOO019);
        this.PosCodTRACONSECGOO018.setValue(value.PosCodTRACONSECGOO018);
        this.CityTRACONSECGOO014.setValue(value.CityTRACONSECGOO014);
        this.CouCodTRACONSECGOO015.setValue(value.CouCodTRACONSECGOO015);
        this.TRACONSECGOO013LNG.setValue(value.TRACONSECGOO013LNG);
        this.TINTRACONSECGOO020.setValue(value.TINTRACONSECGOO020);
        this.TINTypTRACONSECGOO021.setValue(value.TINTypTRACONSECGOO021);
        this.ExporterObject.setValue(value.ExporterObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
