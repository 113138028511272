export class NctsPreAdmRefModel {
    PreDocTypAR21?: string;
    PreDocRefAR26?: string;
    PreDocRefLNG?: string;
    ComInfPREADMREFGI4?: string;
    ComInfLNGPREADMREFGI5?: string;

    constructor(value?) {
        this.PreDocTypAR21 = value?.PreDocTypAR21 || null;
        this.PreDocRefAR26 = value?.PreDocRefAR26 || null;
        this.PreDocRefLNG = value?.PreDocRefLNG || null;
        this.ComInfPREADMREFGI4 = value?.ComInfPREADMREFGI4 || null;
        this.ComInfLNGPREADMREFGI5 = value?.ComInfLNGPREADMREFGI5 || null;
    }
}
