export class T2LHeaheaTF013AModel {
    DocNumHEA5?: string;

    TypOfDecHEA24?: string;
    CouOfDesCodHEA30?: string;
    CouOfDisCodHEA55?: string;
    TotNumOfIteHEA305?: string;
    TotGroMasHEA307?: string;
    AmdPlaHEA598?: string;
    AmdPlaHEA598LNG?: string;
    RefT2LYeaHEA1012?: string;
    RefT2CouCodHEA1010?: string;
    RefT2LTypHEA1014?: string;
    RefT2LCusHEA1013?: string;
    RefT2LNumHEA1015?: string;
    AmdDatHEA599?: string;

    DecDatHEA383?: string;
    DecPlaHEA394?: string;
    DecPlaHEA394LNG?: string;

    constructor(value?) {

        console.log('TF013 value on contructor', value)
        this.DocNumHEA5 = value?.DocNumHEA5;

        this.TypOfDecHEA24 = value?.TypOfDecHEA24 || null;
        this.CouOfDesCodHEA30 = value?.CouOfDesCodHEA30 || null;
        this.CouOfDisCodHEA55 = value?.CouOfDisCodHEA55 || null;
        this.TotNumOfIteHEA305 = value?.TotNumOfIteHEA305 || null;
        this.TotGroMasHEA307 = value?.TotGroMasHEA307 || null;
        this.AmdPlaHEA598 = value?.AmdPlaHEA598 || value?.DecPlaHEA394 || null;
        this.AmdPlaHEA598LNG = value?.AmdPlaHEA598LNG || value?.DecPlaHEA394LNG || null;
        this.RefT2LYeaHEA1012 = value?.RefT2LYeaHEA1012 || null;
        this.RefT2CouCodHEA1010 = value?.RefT2CouCodHEA1010 || null;
        this.RefT2LTypHEA1014 = value?.RefT2LTypHEA1014 || null;
        this.RefT2LCusHEA1013 = value?.RefT2LCusHEA1013 || null;
        this.RefT2LNumHEA1015 = value?.RefT2LNumHEA1015 || null;
        this.AmdDatHEA599 = value?.AmdDatHEA599 || value?.DecDatHEA383 || null;
    }
}
