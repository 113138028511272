export class AccountingModel {
    AccCurrency?: string;
    AccValue?: number;
    AccExchangeRate?: number;
    AccResult?: number;
    changedManually?: boolean;

    constructor(value?) {
        if (value) {
            this.AccCurrency = value.AccCurrency || null;
            this.AccValue = value.AccValue || null;
            this.AccExchangeRate = value.AccExchangeRate || null;
            this.AccResult = value.AccResult || null;
            this.changedManually = value.changedManually || null;
        }
    }
}
