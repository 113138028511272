import { PrivateControlsService } from './../model/private-controls.service';
import { ToastrService } from 'ngx-toastr';
/* eslint-disable rxjs/no-subject-unsubscribe */
import { Observable, BehaviorSubject, of, Subject, takeUntil, take } from 'rxjs';
import { FormGroup, FormArray } from '@angular/forms';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { DeclarationStateService } from 'app/services/declaration-state.service';
import declarationData from 'assets/declarationData.json';
import { FormArrayService } from 'app/services/submitServices/formArray.service';
import { GooIteGdsModel } from 'app/main/apps/export/declaration/_models';
import { DeclarationForm } from 'app/main/apps/export/declaration/_models/declaration-form.model';
import { Declaration } from 'app/main/apps/export/declaration/_models/declaration.model';
import { ImportDeclarationForm } from 'app/main/apps/import/_models/declaration/declaration-form.model';
import { ImportDeclarationModel } from 'app/main/apps/import/_models/declaration/declaration.model';
import { ImpGooIteGdsModel } from 'app/main/apps/import/_models/gooitegds/gooitegds.model';
import { InjectionToken, PipeTransform } from '@angular/core';
import { T2LDeclarationForm } from 'app/main/apps/transit/t2l/_models/declaration/declaration-form.model';
import { T2LDeclarationModel } from 'app/main/apps/transit/t2l/_models/declaration/declaration.model';
import { T2LGooIteGdsModel } from 'app/main/apps/transit/_models/gooitegds/gooitegds.model';
import { TirDeclarationForm } from 'app/main/apps/transit/tir/_models/declaration/declaration-form.model';
import { TirDeclarationModel } from 'app/main/apps/transit/tir/_models/declaration/declaration.model';
import { TirGooIteGdsModel } from 'app/main/apps/transit/tir/_models/gooitegds-tir/gooitegds-tir.model';
import { DeclarationModels } from './submitServices/submitForm.service';
import { DaoDeclarationForm } from 'app/main/apps/excise/dao/_models/VA15A/declaration-form.model';
import { DaoDeclarationModel } from 'app/main/apps/excise/dao/_models/VA15A/declaration.model';
import { DEFKDeclarationForm } from 'app/main/apps/excise/defk/_models/EF15A/declaration-form.model';
import { DEFKDeclarationModel } from 'app/main/apps/excise/defk/_models/EF15A/declaration.model';
import { LrnService } from './lrn.service';
import { InvoiceParsingResultsModel } from 'app/main/apps/customer-invoice-parsing/_models';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { RouteStateService } from 'app/services/routeState.service';
import { StorageService } from 'app/core/services/storage/storage.service';
import { GoodsPrivateModel } from 'app/model/private-controls.model';
import { ProofRequestForm } from 'app/main/apps/transit/t2l-pous/_models/pous/pous-form.model';
import { ProofRequest } from 'app/main/apps/transit/t2l-pous/_models/pous/pous.model';


export const TEST_SERVICE = new InjectionToken<DeclarationFormService>('title');

export class DeclarationFormService {
    //subjects used to terminate following of valuechanges on main document
    //when value on bis is changed by user


    declarationForm$: Observable<FormArray>;
    totalTabs$: Observable<number>;
    newDeclaration$: Observable<number>;
    productDescription$: Observable<any>;

    bisArray: Object[] = [];

    allPackagesArray: number[] = [];
    grossWeightsArray: number[] = [];

    localDocs: string[];
    messageType: string;
    modifyMessageType: string;
    localStorageValue: string;
    declarationData: any;

    lang: string;

    protected totalTabs: BehaviorSubject<number | undefined>;
    protected declarationForm: BehaviorSubject<FormArray | undefined>;

    constructor(
        protected mainBroadcaster: MainBroadcasterService,
        protected cf: ExtCustomFunctions,
        protected lrnService: LrnService,
        protected declarationStateService: DeclarationStateService,
        protected formArrayService: FormArrayService,
        protected _fuseSplashScreenService: FuseSplashScreenService,
        protected toastrService: ToastrService,
        protected routeStateService: RouteStateService,
        protected storageService: StorageService,
        protected privateControlsService: PrivateControlsService,
        protected subdomain: string,

    ) {

        this.mainBroadcaster.declarationSettings$.pipe(take(1)).subscribe((settings: DeclarationSettingsModel) => {
            this.lang = settings.language;
        })

        this.declarationData = declarationData;

        this.localDocs = this.declarationData[subdomain].localDocs;
        this.messageType = this.declarationData[subdomain].messageType;
        this.modifyMessageType = this.declarationData[subdomain].modifyMessageType;
        this.localStorageValue = this.declarationData[subdomain].localStorage;

        this.declarationForm = new BehaviorSubject(this.createForm(subdomain));

        //initial value of tabs is 1(considering the main declaration page as number 1)
        this.totalTabs = new BehaviorSubject(1);

        this.updateTotalItems(1);

        if (!this.declarationForm$) {
            this.declarationForm$ = this.declarationForm.asObservable();
        }

        this.totalTabs$ = this.totalTabs.asObservable();

        this.updateFormDisabled(false);

    }



    private createForm(subdomain, data?: DeclarationModels): FormArray {
        switch (subdomain) {
            case 'EXP_DECL':
                return new FormArray([new FormGroup(new DeclarationForm(new Declaration(data), subdomain))]);

            case 'IMP_DECL':
                return new FormArray([new FormGroup(new ImportDeclarationForm(new ImportDeclarationModel(data), subdomain))]);

            case 'T2L':
                return new FormArray([new FormGroup(new T2LDeclarationForm(new T2LDeclarationModel(data), subdomain))]);

            case 'POUS':
                return new FormArray([new FormGroup(new ProofRequestForm(new ProofRequest(data), subdomain))]);

            case 'TIR': return new FormArray([new FormGroup(new TirDeclarationForm(new TirDeclarationModel(data), subdomain))]);

            case 'DAO': return new FormArray([new FormGroup(new DaoDeclarationForm(new DaoDeclarationModel(data), subdomain))]);
            case 'DEFK': return new FormArray([new FormGroup(new DEFKDeclarationForm(new DEFKDeclarationModel(data), subdomain))]);
        }
    }

    newForm() {
        console.log('newForm');

        this.deleteLocalStorage();

        this.mainBroadcaster.resetDocForms('all', true);

        this.updateFormDisabled(false);

        //we push a new form on the observble
        const newDecl = this.createForm(this.subdomain);
        console.log("newDecl on newForm", newDecl)

        newDecl.at(0).get('_PRIVATE').get('messageOut').setValue(declarationData[this.subdomain].messageType)
        newDecl.at(0).get('_PRIVATE').get('domain').setValue(declarationData[this.subdomain].domain)
        newDecl.at(0).get('_PRIVATE').get('subdomain').setValue(this.subdomain)

        console.log("newDecl on newForm after _PRIVATE", newDecl)


        this.broadcastCurrentForm(newDecl)

        //HARDCODED FORM VALUES  =======================================
        this.hardCodedValues(this.subdomain);

        // braodcast totalItems to export component to create new tabs
        //anKeyDown to Total Gross Weight to subscribe to the gross weight observables
        //  this.broadcaster.broadcast("totalItems", 1);
        this.updateTotalItems(1);

        this.mainBroadcaster.updateDeclarationStates({
            [this.subdomain]: { stateCode: 'Pre-Submitted' }
        });
        this.totalTabs.next(1);

        console.log("NEWFORM Declaration", this.declarationForm.value.value)
    }

    updateForm(data: DeclarationModels) {
        console.log('updateForm', data);

        const declForm = this.createForm(this.subdomain, data);

        this.updateCurrent(declForm, data)

    }
    private updateCurrent(declForm: FormArray, data: DeclarationModels) {

        //setting localStorage here because when setTotalItems is called
        //should read a preivous value
        this.storageService.setLocalObject(this.localStorageValue, (declForm.at(0) as FormGroup).value);

        this.broadcastCurrentForm(declForm)

        let items: number;

        //items every bis page has
        let itemsPerPage: number;

        //set initail values and subscribe to observables to track valuechanges

        //if declaration type other than EMCS type
        if ('HEAHEA' in data && 'TotNumOfIteHEA305' in data.HEAHEA) {
            items = data.HEAHEA && data.HEAHEA.TotNumOfIteHEA305 !== '' ? +data.HEAHEA.TotNumOfIteHEA305 : 1;
            itemsPerPage = 3;

            setTimeout(() => {
                this.cf.getGrossWeights(declForm.at(0) as FormGroup, items);

                if (this.subdomain !== 'T2L' && this.subdomain !== 'POUS') {
                    this.cf.getPackagesInitial(declForm.at(0) as FormGroup, items);
                }
                this._fuseSplashScreenService.hide();
            });
        }
        //if declaration type is DEFK (has DeclarationOfExciseTaxesRegistration in Body)
        else if ('Body' in data && 'DeclarationOfExciseTaxesRegistration' in data.Body) {
            const taxesDeclaration = data.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclaration

            items = taxesDeclaration && taxesDeclaration.NumberOfRows !== '' ? +taxesDeclaration.NumberOfRows : 1;
            itemsPerPage = 2;
        }
        else if ('Body' in data && 'VehicleArrivalDeclarationRegistration' in data.Body) {

            items = 2;  //Default 2 items to always show bis page
            itemsPerPage = 3; //Default number of items
        }
        else if ('ProofInformationT2LT2LF' in data && 'GoodsShipmentForT2LT2LF' in data.ProofInformationT2LT2LF) {

            items = data._PRIVATE && data._PRIVATE.pousNumberOfItems ? +data._PRIVATE.pousNumberOfItems : 1;  //Default 2 items to always show bis page
            itemsPerPage = 3; //Default number of items
        }
        //we broadcast total items to create BIS in export Component
        this.updateTotalItems(items);



        //total of new tabs (we add 1 which is the main declaration page plus the new created)
        const Tabs = Math.ceil((+items - 1) / itemsPerPage) + 1;
        this.totalTabs.next(Tabs);
        // console.log("afterPatch()", this.declarationForm.getValue());

        //Check statecode and enable/disable form
        this.declarationStateService.transmitStateCode(declForm.at(0) as FormGroup);

        const stateCode = declForm.at(0).get('_PRIVATE').get('stateCode').value;

        console.log("declFormService updataForm stateCode", stateCode)

        if (stateCode !== 'Pre-Submitted' && stateCode !== 'Edit') {

            this.storageService.removeLocalObject(declarationData[this.subdomain].localStorage)
        }
    }
    private broadcastCurrentForm(declarationForm: FormArray) {
        console.log("broadcastCurrentForm", declarationForm);
        this.mainBroadcaster.updateDeclarationForms({ [this.subdomain]: declarationForm.at(0) });
        this.declarationForm.next(declarationForm);
    }

    addFormArray(formArrayName: string, data?: any, formArrayParent?: FormGroup) {
        const currentDeclaration = this.declarationForm.getValue().at(0) as FormGroup;

        this.formArrayService.addFormArray(currentDeclaration, formArrayName, data, formArrayParent);
    }

    addGooFormArray(formArrayName: string, GoodsIndex: number, data?: any) {
        const currentDeclaration = this.declarationForm.getValue().at(0) as FormGroup;

        this.formArrayService.addGooFormArray(currentDeclaration, formArrayName, GoodsIndex, data);
    }

    deleteFormArray(formArrayName: string, FormArrayIndex: number, parentFormGroup?: FormGroup) {
        if (!parentFormGroup) {
            parentFormGroup = this.declarationForm.getValue().at(0) as FormGroup;
        }

        this.formArrayService.deleteFormArray(formArrayName, FormArrayIndex, parentFormGroup);
    }

    //=======================================================================================================
    //function used on totalItems Component and patchFromInvoiceData
    sendTotalItems(total: string, manualInput?: boolean) {
        console.trace('sendTotalItems');
        //console.log("sendTotalItems()");

        const items = total === '' || total === null ? 1 : +total;

        const currentDeclaration = this.declarationForm.getValue().at(0) as FormGroup;
        const currentGoods = currentDeclaration.get('GOOITEGDS') as FormArray;

        //privateCtrls
        const currentPrivateCtrls = currentDeclaration.get('_PRIVATE') as FormGroup;


        //get current size of goods array
        const length = currentGoods.length;

        //get previous inserted values from localstorage

        const previous = this.storageService.getLocalObject(this.localStorageValue);
        console.log('previous: ', previous);

        const prevPrivateGoods: GoodsPrivateModel[] = previous._PRIVATE.goodsPrivate;

        console.log("prevgoodsPrivate before sendItems", previous._PRIVATE.goodsPrivate)

        //get the GOOITEGDS array
        if (previous && manualInput) {
            const prevItems: GooIteGdsModel[] | ImpGooIteGdsModel[] | T2LGooIteGdsModel[] | TirGooIteGdsModel[] = previous.GOOITEGDS;



            //delete all items starting from last to first
            for (let i = length - 1; i >= 0; i--) {
                this.deleteFormArray('GOOITEGDS', i);
                this.privateControlsService.deletePrivateGroup(currentPrivateCtrls, i)
            }
            //add total items and patch previous values if the exist
            //else set Unique Item Number
            for (let i = 0; i < items; i++) {
                if (prevItems[i]) {
                    console.log('Previous Items for index' + i, prevItems[i]);
                    this.addFormArray('GOOITEGDS', prevItems[i]);
                    this.privateControlsService.addPrivateGroup(currentPrivateCtrls, prevPrivateGoods[i]);


                } else {
                    //add Goods Array and private goodsGroup
                    this.addFormArray('GOOITEGDS');
                    this.privateControlsService.addPrivateGroup(currentPrivateCtrls)
                    //add ACCOUNTING Array
                    if (this.subdomain !== 'T2L' && this.subdomain !== 'POUS') {
                        this.addGooFormArray('ACCOUNTING', i);
                    }

                    //set Item Number
                    currentGoods.at(i).patchValue({
                        IteNumGDS7: i + 1
                    });

                    //==============T2L ONLY===============
                    if (this.subdomain === 'T2L') {
                        const currentHEAHEA = currentDeclaration.get('HEAHEA') as FormGroup;
                        const declarationType = currentHEAHEA.get('TypOfDecHEA24').value;

                        currentGoods.at(i).patchValue({
                            DecTypGDS15: declarationType
                        });
                    }

                }
            }
        }

        console.log("goodsPrivate after sendItems", currentPrivateCtrls.get('goodsPrivate').value)
        //total of new tabs (we add 1 which is the main declaration page plus the new created)
        const Tabs = Math.ceil((+items - 1) / 3) + 1;
        this.totalTabs.next(Tabs);

        // braodcast totalItems to export component to create new tabs

        this.updateTotalItems(items);
    }

    //load Draft from localstorage else set initial values
    loadLocalStorage() {
        if (this.storageService.checkLocalObject(this.localStorageValue)) {
            const data = this.storageService.getLocalObject(this.localStorageValue);
            console.log('LocalStorage FOUND !!!', data);

            this.updateForm(data);
        } else {
            console.log('LocalStorage NOT FOUND !!! New Form');
            this.routeStateService.goto('new')
        }

        this.cf.addTabsLocalStorage(this.localDocs, this.localStorageValue);
    }

    private hardCodedValues(subdomain) {


        const currentDeclaration = this.declarationForm.getValue().at(0) as FormGroup;

        switch (subdomain) {
            case 'DAO': {
                break;
            }
            case 'DEFK': {

                this.cf.DefkHardCodedValues(currentDeclaration)
                break;
            }
            case 'POUS': {
                this.cf.PousHardCodedValues(currentDeclaration)
                break;
            }

            default: {
                this.cf.hardCodedValues(currentDeclaration);

            }
        }


        if (this.subdomain === 'TIR') {
            currentDeclaration.get('HEAHEA').get('TypOfDecHEA24').setValue('TIR');

        }
    }

    protected updateTotalItems(items: number) {
        this.mainBroadcaster.updateTotalItems({ [this.subdomain]: items });
    }

    private updateFormDisabled(disabled: boolean) {
        this.mainBroadcaster.updateFormDisabled({ [this.subdomain]: disabled });
    }


    setRefNumber(declarationForm: FormGroup): Observable<{ lrn: string }> {
        console.log('followSubscriptionChain setRefNumber ', declarationForm.value);
        return this.lrnService.setRefNumber(declarationForm);
    }

    deleteLocalStorage() {
        this.cf.deleteLocalStorage(this.localDocs);
    }


    checkBisValuesAreSame(controlName: string, arrayName?: string): boolean {

        const currentDeclaration = this.declarationForm.getValue();
        const currentGoods = currentDeclaration.at(0).get('GOOITEGDS') as FormArray;

        const totalItems = currentGoods.length;

        const arr = [];

        for (let i = 1; i < totalItems; i++) {

            let value: string

            if (arrayName) {

                value = (currentGoods.at(i).get(arrayName) as FormArray).at(0).get(controlName).value
            }
            else {
                value = currentGoods.at(i).get(controlName).value;
                console.log("checkEqual checkBisValuesAreSame, index " + i + " value: " + value)

            }


            arr.push(value);
        }
        console.log("checkEqual checkBisValuesAreSame, arr", arr)


        // Put all array elements in a HashSet
        const s = new Set(arr);

        console.log("checkEqual checkBisValuesAreSame, new Set", s)
        // If all elements are same, size of
        // HashSet should be 1. As HashSet contains only distinct values.
        return (s.size == 1);
    }

    setValueOnAllItems(controlName: string, value: string | Contact, arrayName?: string) {

        const currentDeclaration = this.declarationForm.getValue();
        const currentGoods = currentDeclaration.at(0).get('GOOITEGDS') as FormArray;
        const totalItems = currentGoods.length

        for (let i = 1; i < totalItems; i++) {
            if (arrayName) {
                (currentGoods.at(i)?.get(arrayName) as FormArray).at(0).get(controlName).setValue(value)
            }
            else {
                currentGoods.at(i)?.get(controlName).setValue(value);
            }
        }
    }



    checkEqual(totalItems: number, controlName: string, value: string | Contact | null, confirmMessage: string, successMessage: string, arrayName?: string) {
        console.log("checkEqual declFormService")
        if (totalItems > 1) {
            const sameValues = this.checkBisValuesAreSame(controlName, arrayName)

            console.log(" checkEqual PatchValuesOnBis Values On Bis Are The Same", sameValues)
            if (!sameValues) {
                setTimeout(() => {

                    if (confirm(confirmMessage)) {
                        this.setValueOnAllItems(controlName, value, arrayName)
                        this.toastrService.success(successMessage, null, { timeOut: 1500 })

                    }

                }, 200)
            }
            else {
                if (totalItems === 2) {

                    this.setValueOnAllItems(controlName, value, arrayName)
                    this.toastrService.success(successMessage, null, { timeOut: 1500 })

                }

            }
        }
    }











    //monitor Exporter,Consignne, Countries of Dispatch,Destination,Origin and Procedure and update Bis
    patchValuesOnBisValueChange(totalItems: number): Observable<any> {
        return of();
    }

    openAsTemplateGeneral(data) {

        console.log('DECLFORMSERVICE: openAsTemlate data: ', data);

        this.deleteLocalStorage();

        data._PRIVATE.stateCode = 'Pre-Submitted';

        this.updateForm(data);

        const currentDeclaration = this.declarationForm.getValue();

        const privateCtrls = currentDeclaration.at(0).get('_PRIVATE') as FormGroup;

        privateCtrls.get('stateDate').reset();
        privateCtrls.get('data').reset();
        privateCtrls.get('submissionDate').reset();
        privateCtrls.get('refNumber').reset();
        privateCtrls.get('lrn').reset();
        privateCtrls.get('mrn').reset();
        privateCtrls.get('taxAmount').reset();
        privateCtrls.get('cancellationDate').reset();
        privateCtrls.get('cancellationReason').reset();
        privateCtrls.get('messageIn').reset();
        privateCtrls.get('referrerDeclarationId').reset();


        privateCtrls.get('stateCode').setValue('Pre-Submitted');
        privateCtrls.get('messageOut').setValue(this.messageType)
        privateCtrls.get('lastValidState').setValue('Pre-Submitted')



        console.log("openAsTemplateGeneral currentDeclaration", currentDeclaration.value)
    }

    openAsTemplate(data: DeclarationModels): void { }
    patchTirFromExport(data: Declaration): void { }
    patchInvoiceData(data: InvoiceParsingResultsModel): Observable<boolean> {

        return of(true)
    }


    setConsignorOnBis(TRACONCO2: FormArray, consignorObject: Contact) {

        if (consignorObject) {

            const fullname = consignorObject.fullname.length > 35 ? consignorObject.fullname.substring(0, 34) : consignorObject.fullname;


            TRACONCO2?.at(0).patchValue(
                {
                    NamCO27: fullname,
                    StrAndNumCO222: consignorObject.street,
                    CitCO224: consignorObject.city,
                    CouCO225: consignorObject.country,
                    TINCO259: consignorObject.eori,
                    PosCodCO223: consignorObject.postal_code,
                    NADLNGGTCO: this.lang,
                    ConsignorObject: consignorObject
                },
                {
                    onlySelf: true,
                    emitEvent: false
                }

            );
        }
        else {
            TRACONCO2?.at(0).patchValue(
                {
                    NamCO27: null,
                    StrAndNumCO222: null,
                    CitCO224: null,
                    CouCO225: null,
                    TINCO259: null,
                    PosCodCO223: null,
                    NADLNGGTCO: null,
                    ConsignorObject: null
                },
                {
                    onlySelf: true,
                    emitEvent: false
                }
            );
        }
    }
    setConsigneeOnBis(TRACONCE2: FormArray, consigneeObject: Contact | null) {
        if (consigneeObject) {

            const fullname = consigneeObject.fullname.length > 35 ? consigneeObject.fullname.substring(0, 34) : consigneeObject.fullname;



            TRACONCE2?.at(0).patchValue(
                {
                    NamCE27: fullname,
                    StrAndNumCE222: consigneeObject.street,
                    PosCodCE223: consigneeObject.postal_code,
                    CitCE224: consigneeObject.city,
                    CouCE225: consigneeObject.country,
                    TINCE259: consigneeObject.eori,
                    NADLNGGICE: this.lang,
                    ConsigneeObject: consigneeObject
                },
                {
                    onlySelf: true,
                    emitEvent: false
                }
            );
        }
        else {
            TRACONCE2?.at(0).patchValue(
                {
                    NamCE27: null,
                    StrAndNumCE222: null,
                    PosCodCE223: null,
                    CitCE224: null,
                    CouCE225: null,
                    TINCE259: null,
                    NADLNGGICE: null,
                    ConsigneeObject: null
                },
                {
                    onlySelf: true,
                    emitEvent: false
                }
            );


        }
    }
    setExporterOnBis(TRACONEX2: FormArray, exporterObject: Contact | null) {

        console.log("EPXORTEROBJECT on setExporterOnBis", exporterObject)
        if (exporterObject) {
            console.log("EPXORTEROBJECT exists on setExporterOnBis", exporterObject)

            const fullname = exporterObject.fullname.length > 35 ? exporterObject.fullname.substring(0, 34) : exporterObject.fullname;

            TRACONEX2?.at(0).patchValue(
                {
                    NamEX27: fullname,
                    StrAndNumEX222: exporterObject.street,
                    CitEX224: exporterObject.city,
                    CouEX225: exporterObject.country,
                    TINEX259: exporterObject.eori,
                    PosCodEX223: exporterObject.postal_code,
                    NADLNGGTEX: this.lang,
                    ExporterObject: exporterObject
                },
                {
                    onlySelf: true,
                    emitEvent: false
                }
            );
        }
        else {
            TRACONEX2?.at(0).patchValue(
                {
                    NamEX27: null,
                    StrAndNumEX222: null,
                    CitEX224: null,
                    CouEX225: null,
                    TINEX259: null,
                    PosCodEX223: null,
                    NADLNGGTEX: null,
                    ExporterObject: null,
                },
                {
                    onlySelf: true,
                    emitEvent: false
                })
        }
    }
}
