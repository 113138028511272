import { FormControl, Validators } from '@angular/forms';
import { CalTaxModel } from './caltaxgod.model';

export class CalTaxForm {
    [key: string]: FormControl;

    TypOfTaxCTX1 = new FormControl();
    TaxBasCTX1 = new FormControl(null, { updateOn: 'blur' });
    RatOfTaxCTX1 = new FormControl(null, { updateOn: 'blur' });
    AmoOfTaxTCL1 = new FormControl(null, { updateOn: 'blur' });
    MetOfPayCTX1 = new FormControl();
    CalTypTAXCALGI = new FormControl();
    InRelTAXCALGI = new FormControl();
    SusDueDatTAXCALGI = new FormControl();
    QuaCTX1 = new FormControl();
    AmoCTX1 = new FormControl();

    _natTypeCode = new FormControl();
    _taxFreeCode = new FormControl();
    _taxPaidCode = new FormControl();
    _partialExemption = new FormControl();
    _wholeTaxAmount = new FormControl();

    constructor(value: CalTaxModel) {
        this.TypOfTaxCTX1.setValue(value.TypOfTaxCTX1);
        this.TaxBasCTX1.setValue(value.TaxBasCTX1);
        this.RatOfTaxCTX1.setValue(value.RatOfTaxCTX1);
        this.AmoOfTaxTCL1.setValue(value.AmoOfTaxTCL1);
        this.MetOfPayCTX1.setValue(value.MetOfPayCTX1);
        this.CalTypTAXCALGI.setValue(value.CalTypTAXCALGI);
        this.InRelTAXCALGI.setValue(value.InRelTAXCALGI);
        this.SusDueDatTAXCALGI.setValue(value.SusDueDatTAXCALGI);
        this.QuaCTX1.setValue(value.QuaCTX1);
        this.AmoCTX1.setValue(value.AmoCTX1);
        this._natTypeCode.setValue(value._natTypeCode);
        this._taxFreeCode.setValue(value._taxFreeCode);
        this._taxPaidCode.setValue(value._taxPaidCode);
        this._partialExemption.setValue(value._partialExemption);
        this._wholeTaxAmount.setValue(value._wholeTaxAmount);
    }
}
