import { ePaymentContainModel } from "./e-payment-contain.model";

export class ePaymentICISModel {
    wsTin?: string;
    wsUser?: string;
    wsPswd?: string;
    contain?: ePaymentContainModel[];


    //
    constructor(value?) {
        // console.log("___DeclarationModel constructor", value);
        this.wsTin = value?.wsTin || null;
        this.wsUser = value?.wsUser || null;
        this.wsPswd = value?.wsPswd || null;
        this.contain = value?.contain || null;
    }
}
