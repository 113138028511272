import { Component, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ExtCustomFunctions } from "app/main/main-building-functions/ext-custom-functions.service";
import { Subject } from "rxjs";

@Component({
    selector: 'app-base-component',
    template: '',
})
export class BaseBuildingComponent implements OnDestroy {
    @ViewChild('firstInput') _first;

    private _control: FormControl;

    public get control(): FormControl {
        return this._control;
    }


    protected _destroy: Subject<void> = new Subject<void>();

    constructor(
        protected hostElement: ElementRef, protected cf: ExtCustomFunctions,
    ) { }



    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._destroy.next();
        this._destroy.complete();
    }


    onFocus() {

        this.cf.onFocusEde(this.hostElement);

    }

    focus(event) {

        console.log("BASE DOC FOCUS", event)
        if (event.target.tagName === 'DIV') {
            if (this._first.inputRef) {
                this._first.inputRef.nativeElement.focus();
            } else {
                this._first.nativeElement.focus();
            }
        }
    }
    onError(hasError: boolean) {
        this.cf.onErrorEde(this.hostElement, hasError);

    }

    hasError(control: FormControl): boolean {
        return control.errors ? true : false;
    }
}
