import { EMCSVehicleInventoryModel } from "../vehicle-inventory/vehinv.model";

export class VehicleDetailsModel {

    FrameNumber: string;
    CarKind: string;
    ImporterCodeOrBrandName: string;
    FirstAllowanceDate?: string;
    VehicleFactoryType?: string;
    VehicleUsedFlag: number;
    VehicleModelName?: string;
    VehicleRegistrationPlate?: string;
    VehicleLicenceReference?: string;
    Mileage: string;
    VehicleInventory?: EMCSVehicleInventoryModel[];



    constructor(value?) {

        this.FrameNumber = value?.FrameNumber || null;
        this.CarKind = value?.CarKind || null;
        this.ImporterCodeOrBrandName = value?.ImporterCodeOrBrandName;
        this.FirstAllowanceDate = value?.FirstAllowanceDate;
        this.VehicleFactoryType = value?.VehicleFactoryType;
        this.VehicleUsedFlag = value?.VehicleUsedFlag;
        this.VehicleModelName = value?.VehicleModelName;
        this.VehicleRegistrationPlate = value?.VehicleRegistrationPlate;
        this.VehicleLicenceReference = value?.VehicleLicenceReference;
        this.Mileage = value?.Mileage;
        this.VehicleInventory = value?.VehicleInventory;


    }

}
