<ng-container *ngIf="vehiclesForm$| async as vehiclesForm">
    <table class="cars-header-inputs" style="width:100%" [formGroup]="vehiclesForm">
        <colgroup>
            <col style="width:35%">
            <col style="width:40%">

        </colgroup>
        <tbody>
            <tr>
                <td class="text-left">ΤΥΠΟΣ ΟΧΗΜΑΤΟΣ</td>
                <td class="noPrint">
                    <select style="width:250px" class="excel-input text-bold" formControlName="vehicleType">
                        <option *ngFor="let item of vehicleTypeOptions" [value]="item?.code">
                            {{item?.desc}} </option>
                    </select>
                </td>
                <td class="printOnly text-left">{{vehiclesForm.get('vehicleType').value | codeToDesc:
                    vehicleTypeOptions}}
                </td>

            </tr>
            <tr *ngIf="(vehicleTypeGeneral$|async)==='cars'">
                <td class="text-left">ΧΡΗΣΗ ΟΧΗΜΑΤΟΣ</td>
                <td class="noPrint">
                    <select style="width:250px" class="excel-input text-bold" formControlName="vehicleUse">
                        <option value="private">Ιδιωτική (Ι.Χ.)</option>
                        <option value="public">Δημόσια (Δ.Χ.)</option>
                    </select>
                </td>
                <td class="printOnly text-left" *ngIf="vehiclesForm.get('vehicleUse').value==='private'">Ιδιωτική
                    (Ι.Χ.)</td>
                <td class="printOnly text-left" *ngIf="vehiclesForm.get('vehicleUse').value==='public'">Δημόσια (Δ.Χ.)
                </td>

            </tr>
            <tr>
                <td class="text-left">ΤΥΠΟΣ AMAΞΩΜΑΤΟΣ</td>
                <td class="noPrint">
                    <select style="width:250px" class="excel-input text-bold"
                        [ngClass]="{'excel-disabled': vehiclesForm.get('chassisType').disabled}"
                        formControlName="chassisType">
                        <option *ngIf="!vehiclesForm.get('chassisType').value" [value]="null">Επιλέξτε Τύπο
                            Αμαξώματος
                        <option *ngFor="let item of chassisTypeOptions$| async" [value]="item?.code">
                            {{item?.desc}} </option>
                    </select>
                </td>
                <td class="printOnly text-left">{{vehiclesForm.get('chassisType').value | codeToDesc:
                    (chassisTypeOptions$ | async)}}
                </td>

            </tr>
            <tr>
                <td class="text-left">ΗΜ/ΝΙΑ 1ης ΚΥΚΛΟΦΟΡΙΑΣ</td>
                <td class="noPrint">
                    <input class="excel-input text-bold" style="width:120px" [matDatepicker]="startDatePicker"
                        name="firstAllowanceDate" formControlName="firstAllowanceDate" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>

                </td>
                <td class="printOnly text-left">{{vehiclesForm.get('firstAllowanceDate').value | date:'dd/MM/yyyy'}}
                </td>

            </tr>
            <tr>
                <td class="text-left">ΗΜ/ΝΙΑ ΑΙΤΗΣΗΣ</td>
                <td class="noPrint">

                    <input class="excel-input text-bold" style="width:120px" [matDatepicker]="endDatePicker"
                        name="calculationOfTaxesDate" formControlName="calculationOfTaxesDate" />
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>

                </td>
                <td class="printOnly text-left">{{vehiclesForm.get('calculationOfTaxesDate').value | date:'dd/MM/yyyy'}}
                </td>

            </tr>
            <tr>
                <td class="text-left">ΠΡΟΤΥΠΟ ΕΚΠ. ΡΥΠΩΝ</td>
                <td class="noPrint">
                    <select style="width:250px" class="excel-input text-bold"
                        [ngClass]="{'excel-disabled': vehiclesForm.get('carCertificateCategory').disabled}"
                        formControlName="carCertificateCategory">
                        <option *ngIf="!vehiclesForm.get('carCertificateCategory').value" [value]="null">Επιλέξτε
                            Κατηγορία
                        </option>

                        <option *ngFor="let item of euroClassOptions$ | async" [value]="item.code">
                            {{item.desc}} </option>
                    </select>
                </td>
                <td class="printOnly text-left">{{vehiclesForm.get('carCertificateCategory').value | codeToDesc:
                    (euroClassOptions$ | async)}}
                </td>

            </tr>
            <tr>
                <td class="text-left">ΚΑΥΣΑΕΡΙΑ</td>
                <td class="noPrint">
                    <input style="width:70px" class="excel-input text-bold"
                        [ngClass]="{'excel-disabled': vehiclesForm.get('carbonDioxideEmissions').disabled}" digitOnly
                        maxlength="3" formControlName="carbonDioxideEmissions" />
                </td>
                <td class="printOnly text-left">{{vehiclesForm.get('carbonDioxideEmissions').value}}</td>

            </tr>
            <tr>
                <td class="text-left">ΧΙΛΙΟΜΕΤΡΑ</td>
                <td class="noPrint">
                    <input style="width:100px" class="excel-input text-bold"
                        [ngClass]="{'excel-disabled': vehiclesForm.get('mileage').disabled}" digitOnly maxlength="10"
                        formControlName="mileage" />
                </td>
                <td class="printOnly text-left">{{vehiclesForm.get('mileage').value}}</td>

            </tr>
            <tr>
                <td class="text-left">ΚΥΒΙΣΜΟΣ</td>
                <td class="noPrint">
                    <input style="width:70px" class="excel-input text-bold"
                        [ngClass]="{'excel-disabled': vehiclesForm.get('engineCapacity').disabled}" digitOnly
                        maxlength="4" formControlName="engineCapacity" />
                </td>
                <td class="printOnly text-left">{{vehiclesForm.get('engineCapacity').value}}</td>

            </tr>
            <tr>
                <td class="text-left">{{priceTitle}}</td>
                <td class="noPrint">
                    <input style="width:100px" class="excel-input text-bold"
                        [ngClass]="{'excel-disabled': vehiclesForm.get('retailValueBeforeTax').disabled}" digitOnly
                        [decimal]="true" [numberOfDecimals]="2" maxlength="11"
                        (blur)="formatDecimals('retailValueBeforeTax')" formControlName="retailValueBeforeTax" />
                </td>
                <td class="printOnly text-left">{{vehiclesForm.get('retailValueBeforeTax').value | number:'1.2-2'}}</td>

            </tr>

        </tbody>
    </table>

</ng-container>