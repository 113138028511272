import { TransactionLineItemModel } from "app/main/businesses/transactions/_models/transaction-line-item/transaction-line-item.model";

export class TransactionModel {

    businessId: string;
    date: string;
    description: string;
    anchorLineItem: TransactionLineItemModel;
    lineItems: TransactionLineItemModel[];
    notes: string;


    constructor(value?: TransactionModel) {

        this.businessId = value?.businessId || null;
        this.date = value?.date || null;
        this.description = value?.description || null;
        this.anchorLineItem = value?.anchorLineItem || null;
        this.lineItems = value?.lineItems || [];
        this.notes = value?.notes || null;
    }
}