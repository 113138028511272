<div *ngIf="!fullCountry" [formGroup]="formGroup" fxFlex fxLayoutAlign="flex-start" style="width:100%">
    <div style="width: 25%;">
        <mat-form-field [class]="formFieldClasses">
            <country-select #firstInput [formControlName]="controlName" [required]="required" (focus)="onFocus()">
            </country-select>
        </mat-form-field>
    </div>


    <div *ngIf="longDesc && countryName" [ngClass]="{disabled: formDisabled}" style="width: 75%;padding-top: 2px;white-space: nowrap;
    overflow: hidden;
  ">
        <span>({{ countryName | countryPipe: "country_name" }})</span>


    </div>

</div>


<div *ngIf="fullCountry" [formGroup]="formGroup" fxFlex fxLayoutAlign="flex-start" style="width:100%">

    <div style="width: 80%;">
        <mat-form-field [class]="formFieldClasses">
            <country-select #firstInput [formControlName]="controlName" display="country_name" [required]="required"
                (focus)="onFocus()">
            </country-select>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field class="s-c s-2">
            <country-select [formControl]="slave_control" [readonly]="true" tabindex="-1" (focus)="onFocus()">
            </country-select>
        </mat-form-field>
    </div>
</div>