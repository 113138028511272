export class eAitisiCancelHeaheaModel {
    //Privates====================
    DocNumHEA5?: string; //<===NOT USED NORMALLY, TO BE REMOVED BEFORE SUBMIT
    DatOfCanReqHEA147?: string;
    CanReaHEA250?: string;
    //========================================

    //-----------------------

    constructor(value?) {
        if (value) {
            this.DocNumHEA5 = value.DocNumHEA5;
            this.DatOfCanReqHEA147 = value.DatOfCanReqHEA147;
            this.CanReaHEA250 = value.CanReaHEA250;
        }
    }
}
