import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { StateResponse } from 'app/services/declaration-state.service';
import { DEFKDeclarationModel } from 'app/main/apps/excise/defk/_models/EF15A/declaration.model';
import { ArrAtExitModel } from 'app/main/apps/export/arrival-at-exit/_models/arrivaAtExit.model';
import { eAitisiDeclarationModel } from 'app/main/apps/e-aitisi/_models/declaration/declaration.model';
import { DaoDeclarationModel } from 'app/main/apps/excise/dao/_models/VA15A/declaration.model';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CancelDeclarationComponent } from 'app/main/apps/export/cancel-declaration/cancel-declaration.component';
import { Declaration } from 'app/main/apps/export/declaration/_models/declaration.model';
import { ImportDeclarationModel } from 'app/main/apps/import/_models/declaration/declaration.model';
import { T2LDeclarationModel } from 'app/main/apps/transit/t2l/_models/declaration/declaration.model';
import { TirDeclarationModel } from 'app/main/apps/transit/tir/_models/declaration/declaration.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatest, lastValueFrom, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { MainBroadcasterService } from '../broadcaster.service';
import { SubToDomain } from '../com-code.service';
import { DeclarationStateService } from '../declaration-state.service';
import { CreateSubmitFormService } from './createSubmitForm.service';
import { MessageHeaderService } from './message-header.service';
import { RemovePrivateService } from './remove-private.service';
import { ExciseService } from 'app/main/apps/excise/excise.service';
import { Declarant } from 'app/core/user/user.model';
import { environment } from 'environments/environment';
import { StorageService } from 'app/core/services/storage/storage.service';
import { SaveFormParams, SaveFormService } from 'app/services/saveForm.service';
import { SaveDataModel } from 'app/main/apps/export/declaration/_models';
import { PollingRequest, PollingService } from 'app/services/polling.service';
import { FormGroup } from '@angular/forms';
import saveAs from 'file-saver';
import { ProofRequest } from 'app/main/apps/transit/t2l-pous/_models/pous/pous.model';

export interface GetStateParams {
    declarantId: string; //afm
    traderId: string; //EORI
    messageOut?: string; //CC515
    domain?: string; //ECS
    lrn: string;
    silent: boolean; //sets reportprogess on off
}

export type DeclarationModels = Declaration | ImportDeclarationModel | T2LDeclarationModel | TirDeclarationModel | eAitisiDeclarationModel | ArrAtExitModel | DaoDeclarationModel | DEFKDeclarationModel | ProofRequest;

export interface SubmitData extends Partial<SaveDataModel> {
    traderId?: string;
    declarantId?: string;
    messageOut?: string;
    submitForm?: any;
    lrn?: string;
    mrn?: string;
}

@Injectable({ providedIn: 'root' })
export class SubmitFormService {
    lrn: string;
    mrn: string;
    domain: string;
    subdomain: string;
    messageType: string;
    declarant: Declarant;
    getStateForEfkLrn: string;

    //SUBDOMAINS
    //ECS=======
    /**
     * SUBDOMAINS
     * ECS
     * -EXP_DECL
     * -ARR_AT_EXIT
     * -PRE_NOTIF
     * -EXP_NO_STAT
     * -EXIT_SUM_DECL
     * -MANIF_PRES
     *
     * IMP
     * -IMP_DECL
     * -ENTRY_REP
     * -EXIT_REP
     * -TRANS_REP
     * -PACKAGE_DECL
     * -ICS_DETE
     * 
     * NCTS
     * -T2L
     * -TIR
     *
     */

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CancelDeclarationComponent>,
        private cf: ExtCustomFunctions,
        private createSubmitFormService: CreateSubmitFormService,
        private messageHeaderService: MessageHeaderService,
        private removePrivateService: RemovePrivateService,
        private mainBroadcaster: MainBroadcasterService,
        private toastrService: ToastrService,
        private declarationStateService: DeclarationStateService,
        private dbQuery: ApiService,
        private exciseService: ExciseService,
        private storageService: StorageService,
        private saveFormService: SaveFormService,
        private pollingService: PollingService
    ) {
        console.log('SERVICE submitForm Started');
        if (environment.testing) {
            this.dbQuery.getTestSettings().subscribe(res => {
                this.getStateForEfkLrn = res.getStateForEfkLrn
            })
        }
    }

    //SUBMIT FORMS

    //Submit Form Order
    //      1. submitDeclaration() ->
    //      2, createSubmitForm() ->
    //      3. setMessageHeader() ->
    //      4. removePrivate() ->
    //      5. removeEmpty()
    //      6. createSubmitData()
    //      7. submit()

    //=============== CC515A (Pre-Submitted)===========================



    submitDeclaration(declaration: any, getXmlFile = false): Observable<any> {
        //create Clone of Declaration Form to be edited and submitted

        this.subdomain = declaration._PRIVATE.subdomain;
        this.messageType = declaration._PRIVATE.messageOut;
        this.declarant = declaration._PRIVATE.declarant;
        console.log('submitDeclaration subdomain', this.subdomain);
        console.log('submitDeclaration messageType', this.messageType);
        console.log('submitDeclaration declaration before', declaration);

        this.domain = SubToDomain[this.subdomain];

        this.lrn = declaration._PRIVATE.lrn;
        this.mrn = declaration._PRIVATE.mrn;

        /*
        We use a cloned version of the declaration Form
        because we need to change the declaration in order to submit it
        **/
        const submitForm = this.createSubmitFormService.createForm(declaration, this.messageType);
        console.log('submitDeclaration declaration after', submitForm);

        return this.submitForm(submitForm, getXmlFile);
    }

    submitForm(submitForm, getXmlFile = false): Observable<any> {
        const state = submitForm._PRIVATE.stateCode;

        if (state === 'Pre-Submitted' || state === 'Edit' || state === 'Submit VA18') {
            const declarantId = this.cf.getDeclarant(submitForm);
            const traderId = this.cf.getTraderId(submitForm);

            console.log('submitDeclaration beforeMessageHeader', submitForm);

            if (this.subdomain === 'DAO' || this.subdomain === 'DEFK') {
                submitForm = this.messageHeaderService.setEMCSHeader(submitForm, this.subdomain);
            }
            else if (this.subdomain === 'POUS') {
                //DO NOTHING
            }
            else {
                submitForm = this.messageHeaderService.setHeader(submitForm, this.subdomain);

            }

            console.log('submitDeclaration setMessageHeader', submitForm);

            submitForm = this.removePrivateService.removePrivate(submitForm, this.messageType);
            console.log('submitDeclaration removePrivate', submitForm);

            submitForm = this.cf.removeEmpty(submitForm);
            console.log('submitDeclaration removeEmpty', submitForm);

            const submitData = this.createSubmitData(submitForm, declarantId, traderId);
            console.log('submitData', submitData);

            //make request
            if (getXmlFile) {
                return this.getXML(submitData);

            }
            return this.submit(submitData);

        }
        else {
            return of(null)
        }
    }




    createSubmitData(submitForm: DeclarationModels, declarantId?: string, traderId?: string): SubmitData {
        console.log('submitForm on submit():', submitForm);

        return {
            traderId,
            declarantId,
            messageOut: this.messageType,
            submitForm,
            lrn: this.lrn,
            mrn: this.mrn
        };
    }

    /*  submit(submitData: SubmitData): Observable<any[]> {
         console.log('declaration END OF submit()');
 
         //COMPENSATE FOR DELAYS OF EFK
         const timeoutx = this.subdomain === 'DAO' || this.subdomain === 'DEFK' ? 120000 : 60000;
 
         return this.addSaveDataToSubmit(submitData)
             .pipe(
                 take(1),
                 switchMap(submitData => {
 
                     this.toastrService.clear()
                     this.mainBroadcaster.updateOtherFormsValue(false);
 
                     return this.dbQuery.submitForm(submitData).pipe(
                         timeout(timeoutx),
                         catchError((e: unknown) => {
                             console.log('Timeout on submit, Error:', e);
 
                             //wait 30 secs, then keep checking on the background, reset reportProgressResponse
                             this.mainBroadcaster.updateResponse(null);
                             this.toastrService.clear();
                             this.toastrService.warning('Το συστημα παρουσιαζει καθυστερησεις.');
                             this.declarationStateService.checkIncompleteStates();
                             return of(null);
                         }),
                         tap((response: any) => {
 
                             console.log("SUBMIT RESPONSE BEFORE FOREACH", response)
 
                             response.forEach((res: StateResponse) => {
                                 console.log('submit response', res);
                                 this.declarationStateService.updateStateServerResp(res)
 
                                 //get Declarants with updated LRN
                                 if (res.internalState === 'OK') {
 
                                     this.onInternalStateOk(res)
                                 }
                             })
 
 
                             console.log('SubmitFormData Result', response);
 
                         }),
                         finalize(() => {
                             //reset responseprogress observable
                             console.log('submit result setting responseProgress to null');
                             this.mainBroadcaster.updateResponse(null);
                             this.mainBroadcaster.updateOtherFormsValue(true);
 
 
                         })
                     );
 
                 }));
 
 
     } */

    submit(submitData: SubmitData): Observable<any[]> {

        return this.addSaveDataToSubmit(submitData).pipe(
            take(1),
            tap((modifiedSubmitData) => {
                console.log("MODIFIED SUBMIT DATA", modifiedSubmitData)
                this.toastrService.clear();
                this.mainBroadcaster.updateOtherFormsValue(false);
            }),
            switchMap(modifiedSubmitData => this.submitFormData(modifiedSubmitData)),
            tap((response) => this.handleResponse(submitData, response)),
            finalize(this.finalizeSubmission.bind(this))
        );
    }

    private submitFormData(submitData: SubmitData): Observable<any> {

        const timeoutDuration = this.subdomain === 'DAO' || this.subdomain === 'DEFK' ? 120000 : 60000;

        return this.dbQuery.submitForm(submitData).pipe(
            timeout(timeoutDuration),
            catchError((e: unknown) => {
                //console.log('Timeout on submit, Error:', e);
                //this.handleTimeoutError();
                return of(null);
            })
        );
    }

    private handleTimeoutError(): void {
        this.mainBroadcaster.updateResponse(null);
        this.toastrService.clear();
        this.toastrService.warning('Το συστημα παρουσιαζει καθυστερησεις.');
        this.declarationStateService.checkIncompleteStates();
    }

    private handleResponse(submitData: SubmitData, response: any[]): void {
        console.log("SUBMIT RESPONSE BEFORE FOREACH", response);

        if (!response) return;


        response.forEach((res: StateResponse) => {
            console.log('submit response', res);
            this.declarationStateService.updateStateServerResp(res);
            if (res.internalState === 'OK') {
                this.onInternalStateOk(res);
            }
            if (res.data) {
                this.pollRequest(submitData, res)

            }
            if (res.status === 'Error') {
                console.log("SUBMIT RESPONSE ERROR", res)
                // this.handleGsisMessageError([res])
            }

        });
        console.log('SubmitFormData Result', response);
    }

    private finalizeSubmission(): void {
        console.log('submit result setting responseProgress to null');
        this.mainBroadcaster.updateResponse(null);
        this.mainBroadcaster.updateOtherFormsValue(true);
    }

    getDeclarationXML(subdomain): Observable<string> {
        return this.mainBroadcaster.declarationForms$
            .pipe(
                switchMap(allForms => {
                    const declarationForm = allForms[subdomain] as FormGroup;
                    const trader = declarationForm.get('_PRIVATE').get('trader').value;

                    console.log("getXML trader", trader);
                    return combineLatest([this.submitDeclaration(declarationForm.getRawValue(), true), of(trader)]);
                }),
                take(1),
                map(([response, trader]) => {
                    console.log('getXML Response::', response);
                    const now = new Date();
                    const pad = num => String(num).padStart(2, '0'); // Utility function to ensure two digits

                    const datetimeLocal = [
                        now.getFullYear(),
                        pad(now.getMonth() + 1),
                        pad(now.getDate())
                    ].join('') + '_' + [
                        pad(now.getHours()),
                        pad(now.getMinutes()),
                        pad(now.getSeconds())
                    ].join('');

                    const filename = trader.afm + '_' + datetimeLocal + '.xml';

                    // Execute the saveAs logic here
                    saveAs(response, filename);

                    // Return a simple message indicating success
                    return 'File saved successfully.';
                }),
                // You might want to catch errors within the observable chain if you plan to handle them internally
                // catchError(error => {
                //     // Handle or log error, then rethrow or convert to observable to emit to subscribers
                //     console.error("Error saving file:", error);
                //     return throwError(() => new Error('Error saving file'));
                // })
            );
    }


    getXML(submitData: SubmitData) {

        return this.dbQuery.getXMLFile(submitData)

    }


    onInternalStateOk(res: StateResponse) {

        this.backupGetStateForEfk(res)

        this.dbQuery.getLrns().pipe(take(1)).subscribe((lrns) => this.mainBroadcaster.updateLRNs(lrns));

        //FOR VA18A delete temporary LocalStorage if everything is OK
        if (res.messageOut === 'VA18A') {
            this.storageService.removeLocalObject(res.mrn)
        }
    }

    pollRequest(submitData: SubmitData, res: StateResponse) {
        const NO_RESPONSE_CODES = ['CC507A', 'IB14A', 'CC514A', 'CC014A', 'ID14A', 'TF14A', 'VA10A', 'EF10A', 'VA18A']
        const NO_RESPONSE_STATES = ['Rejected', 'Refused', 'Cancelled', 'Withdrawn', 'Invalid', 'Invalid', 'Invalid', 'Invalid', 'Invalid', 'Invalid']

        if (!NO_RESPONSE_CODES.includes(res.messageOut) && !NO_RESPONSE_STATES.includes(res.stateCode)) {
            const requestObj: PollingRequest = {
                declarantId: submitData.declarantId,
                traderId: submitData.traderId,
                lrn: submitData.lrn,
                messageOut: submitData.messageOut,
                domain: submitData.domain,
                mrn: res.mrn,
                silent: true,
                state: res.stateCode
            }

            this.pollingService.enqueue(requestObj)
        }

    }

    backupGetStateForEfk(res: StateResponse) {
        //THIS IS A WORK AROUND FOR THE TIME WHILE EFK RETURNS ANSWERS AFTER 90 SECONDS
        if (res.subdomain === 'DAO' || res.subdomain === 'DEFK' || res.subdomain === 'DAO_RECEIVE') {

            console.log("ExciseService SUBMIT RESPONSE GETTING STATE FROM NODESERVER", res)

            const lrn = environment.testing ? this.getStateForEfkLrn : res.lrn

            this.exciseService.getStateEfk(res.subdomain, this.declarant, lrn).pipe(take(1)).subscribe((response) => {
                console.log(" ExciseService SUBMIT RESPONSE STATE FROM NODESERVER", response)

                if (response.results) {
                    this.declarationStateService.updateStateServerResp(response.results)
                }
            })

        }

    }


    addSaveDataToSubmit(submitData: SubmitData): Observable<SubmitData> {

        const args: SaveFormParams = {

            subdomain: this.subdomain,
            systemCall: true,
            noNotification: true,
            type: 'all'
        }

        return this.saveFormService.save(args, true).pipe(
            take(1),
            map((saveData: SaveDataModel) => {

                delete saveData.declarantId;
                delete saveData.lrn;
                delete saveData.messageType;
                delete saveData.traderId;

                // Merging saveData into submitData
                return { ...saveData, ...submitData };
            })
        );
    }


}
