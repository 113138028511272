import { FormControl } from '@angular/forms';
import { eAitisiTradecModel } from './tradec.model';

export class eAitisiTradecForm {
    [key: string]: FormControl;

    NamTDE1 = new FormControl();
    StrAndNumTDE1 = new FormControl();
    PosCodTDE1 = new FormControl();
    CiTDE1 = new FormControl();
    CouCodTDE1 = new FormControl();
    PhoneDECL = new FormControl();
    EmailDECL = new FormControl();
    IDTypDECL = new FormControl();
    IDNumDECL = new FormControl();
    DeclarantObject = new FormControl();

    constructor(value: eAitisiTradecModel) {
        this.NamTDE1.setValue(value.NamTDE1);
        this.StrAndNumTDE1.setValue(value.StrAndNumTDE1);
        this.PosCodTDE1.setValue(value.PosCodTDE1);
        this.CiTDE1.setValue(value.CiTDE1);
        this.CouCodTDE1.setValue(value.CouCodTDE1);
        this.PhoneDECL.setValue(value.PhoneDECL);
        this.EmailDECL.setValue(value.EmailDECL);
        this.IDTypDECL.setValue(value.IDTypDECL);
        this.IDNumDECL.setValue(value.IDNumDECL);
        this.DeclarantObject.setValue(value.DeclarantObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
