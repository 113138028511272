export class CancelHeaheaModel {

    DocNumHEA5?: string;
    DatOfCanReqHEA147?: string;
    CanReaHEA250?: string;
    CanReaHEA250LNG?: string;

    constructor(value?) {
        if (value) {

            this.DocNumHEA5 = value.DocNumHEA5;
            this.DatOfCanReqHEA147 = value.DatOfCanReqHEA147;
            this.CanReaHEA250 = value.CanReaHEA250;
            this.CanReaHEA250LNG = value.CanReaHEA250LNG;
        }
    }
}
