import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BeerForm } from 'app/main/apps/excise-helper-tables/beer/_models/beer-form.model';
import { BeerParameters } from 'app/main/apps/excise-helper-tables/beer/beer-defk-create.service';
import { BeerService } from 'app/main/apps/excise-helper-tables/beer/beer.service';
import { Observable, Subject, map, switchMap, startWith, tap } from 'rxjs';

@Component({
    selector: 'beer-parameters',
    templateUrl: './beer-parameters.component.html',
    styleUrls: ['./beer-parameters.component.scss']
})
export class BeerParametersComponent implements OnInit {

    @Input() params: BeerParameters;
    beerForm$: Observable<FormGroup<BeerForm>>
    vatRate: FormControl = new FormControl();


    private _destroy = new Subject<void>();
    constructor(private beerService: BeerService) { }

    ngOnInit(): void {
        this.beerForm$ = this.beerService.beerForm$.pipe(
            switchMap(form => form.get('vatRate').valueChanges.pipe(
                startWith(form.get('vatRate').value),
                tap(vatRate => {
                    console.log('beer-parameters vatRate', vatRate)
                    const vatRateStr = vatRate * 100 + '%'
                    this.vatRate.setValue(vatRateStr)
                }),
                map(() => form)))
        )

    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }
}
