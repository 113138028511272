import { Injectable } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { InvoiceAccountModel } from "app/main/businesses/invoices/_models/invoice-account/invoice-account.model";
import { TransactionLineItemForm } from "app/main/businesses/transactions/_models/transaction-line-item/transaction-line-item-form.model";
import { TransactionLineItemModel } from "app/main/businesses/transactions/_models/transaction-line-item/transaction-line-item.model";
import { TransactionDirection } from "app/main/businesses/transactions/_models/transaction-types.model";
import { TransactionForm } from "app/main/businesses/transactions/_models/transaction/transaction-form.model";
import { TransactionModel } from "app/main/businesses/transactions/_models/transaction/transaction.model";
import { BehaviorSubject, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TransactionsService {


    transactionForm: BehaviorSubject<FormGroup<TransactionForm>>
    constructor() {

        this.transactionForm = new BehaviorSubject(null);

    }

    createTransactionForm() {

        this.transactionForm.next(new FormGroup(new TransactionForm(new TransactionModel())));
    }

    addTransaction(transactionsForm) {

        return of(null)
    }

    addLineItem() {
        const currentTransactionForm = this.transactionForm.getValue();

        const lineItems = currentTransactionForm.get('lineItems') as FormArray;

        lineItems.push(new FormGroup(new TransactionLineItemForm(new TransactionLineItemModel())));
    }

    removeLineItem(index: number) {
        const currentTransactionForm = this.transactionForm.getValue();

        const lineItems = currentTransactionForm.get('lineItems') as FormArray;

        lineItems.removeAt(index);

    }

    setAnchorLineItem(anchorLineItem: FormGroup<TransactionLineItemForm>, direction: TransactionDirection, amount: number, account: InvoiceAccountModel) {

        const itemType = direction === 'DEPOSIT' ? 'DEBIT' : 'CREDIT'

        anchorLineItem.patchValue({
            amount,
            itemType,
            category: {
                accountId: account.id,
                type: 'ACCOUNT_ID'
            }

        })


    }

}