import { VehicleInventoryModel } from './vehicle-inventory/vehinv.model';

export class ImpVehDetModel {
    FraNumVD1014?: string;
    VehCC4006?: string;
    VehFue4007?: string;
    VehTyp4008?: string;
    VehEngTyp4009?: string;
    ProdYea4010?: string;
    VecTra4023?: string;
    VehFacTyp4001?: string;
    VehUseFl4015?: string;
    WarNumVEHDET40?: string;
    VecModDatVECDET02?: string;
    ArImpDatVECDET01?: string;
    ImpCodVECDETGI?: string;
    CouLasVECDETGI?: string;
    AppCodVehVECDETGI?: string;
    AppCodFacVECDETGI?: string;
    AppCodTypVECDETGI?: string;
    VehRebFlaVEDEGI?: string;
    RebVehRegPlaVEDEGI?: string;
    MilVECDET03?: string;
    CarDioEmiVECDET04?: string;
    VEHINVVECDETGI?: VehicleInventoryModel[];

    constructor(value?) {
        this.FraNumVD1014 = value?.FraNumVD1014 || null;
        this.VehCC4006 = value?.VehCC4006 || null;
        this.VehFue4007 = value?.VehFue4007 || null;
        this.VehTyp4008 = value?.VehTyp4008 || null;
        this.VehEngTyp4009 = value?.VehEngTyp4009 || null;
        this.ProdYea4010 = value?.ProdYea4010 || null;
        this.VecTra4023 = value?.VecTra4023 || null;
        this.VehFacTyp4001 = value?.VehFacTyp4001 || null;
        this.VehUseFl4015 = value?.VehUseFl4015 || null;
        this.WarNumVEHDET40 = value?.WarNumVEHDET40 || null;
        this.VecModDatVECDET02 = value?.VecModDatVECDET02 || null;
        this.ArImpDatVECDET01 = value?.ArImpDatVECDET01 || null;
        this.ImpCodVECDETGI = value?.ImpCodVECDETGI || null;
        this.CouLasVECDETGI = value?.CouLasVECDETGI || null;
        this.AppCodVehVECDETGI = value?.AppCodVehVECDETGI || null;
        this.AppCodFacVECDETGI = value?.AppCodFacVECDETGI || null;
        this.AppCodTypVECDETGI = value?.AppCodTypVECDETGI || null;
        this.VehRebFlaVEDEGI = value?.VehRebFlaVEDEGI || null;
        this.RebVehRegPlaVEDEGI = value?.RebVehRegPlaVEDEGI || null;
        this.MilVECDET03 = value?.MilVECDET03 || null;
        this.CarDioEmiVECDET04 = value?.CarDioEmiVECDET04 || null;
        this.VEHINVVECDETGI = value?.VEHINVVECDETGI;
    }
}
