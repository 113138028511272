import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { customsItem } from 'app/model/api-model';
import { ApiService, ApiEndPoints } from 'app/services/api.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import declarationData from 'assets/declarationData.json'
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { CancelDeclarationService } from 'app/services/submitServices/cancel-declaration.service';



@Component({
    selector: 'app-cancel-declaration',
    templateUrl: './cancel-declaration.component.html',
    styleUrls: ['./cancel-declaration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelDeclarationComponent implements OnInit {
    private _destroy: Subject<void> = new Subject<void>();


    declarationForm: FormGroup;
    subdomain: string;
    cancelForm: FormGroup;
    cancelMessageType: string;
    customsFormGroup: string;
    customsControl: string;
    traderFormGroup: string;
    traderControl: string;

    //choose dateType based on Angular DatePipe format
    dateType = 'yyyyMMdd';

    defaultDate: Date;
    mrn: string;

    customs: customsItem[] = [];

    config = {
        output: 'customs_int_code',
        menuOptions: {
            options: ['customs_name', 'customs_int_code']
        }
    };

    color1 = '';
    color2 = '';

    showCustoms: boolean;
    showTrader: boolean;



    constructor(
        private dbQuery: ApiService,
        public cancelDialogRef: MatDialogRef<CancelDeclarationComponent>,
        private openDialogsService: OpenDialogsService,
        private cancelDeclarationService: CancelDeclarationService,
        private cf: ExtCustomFunctions,
        public _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,

    ) {

        this.dbQuery
            .get_options(ApiEndPoints.customs)
            .pipe(takeUntil(this._destroy))
            .subscribe((data: customsItem[]) => {
                this.customs = data;
            });
    }

    ngOnInit() {
        this.defaultDate = new Date();

        this.showCustoms = true;
        this.showTrader = true;


        this.declarationForm = this.data.declarationForm as FormGroup;
        console.log("DECL FORM ON CANCEL", this.declarationForm.getRawValue())
        this.subdomain = this.declarationForm.get('_PRIVATE').get('subdomain').value;
        const messageType = this.declarationForm.get('_PRIVATE').get('messageOut').value


        this.setCustomsControl(messageType);

        this.cancelForm = this.cancelDeclarationService.createCancelForm(this.declarationForm)

        this.cancelMessageType = declarationData[this.subdomain].cancelMessageType

        this.cancelForm.get('_PRIVATE').get('messageOut').setValue(this.cancelMessageType)
        this.cancelForm.get('_PRIVATE').get('stateCode').setValue('Pre-Submitted')


        console.log("CANCEL FORM ON CANCEL", this.cancelForm.value)

        this.cancelForm.valueChanges.subscribe(value => {
            console.log("CANCEL FORM ", value)
            console.log("CANCEL FORM STATE", this.cancelForm.status)

        })


    }


    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    onCloseConfirm() {
        this.cancelDialogRef.close();
    }

    onSubmit() {
        this.openDialogsService.openSubmitDialog(this.cancelForm).subscribe(() => {
            this.cancelDialogRef.close()
        })
    }


    changeStyle1(event) {
        this.cf.changeStyle1(event)
    }
    changeStyle2(event) {
        this.cf.changeStyle2(this.cancelForm, event)
    }

    setCustomsControl(messageType) {

        switch (messageType) {
            case 'CC515A': case 'CC513A': {
                this.customsFormGroup = 'CUSOFFEXPERT';
                this.customsControl = 'RefNumERT1';
                this.traderFormGroup = 'TRAEXPEX1'
                this.traderControl = 'NamEX17'
                break;
            }
            case 'ID15A': case 'ID13A': {
                this.customsFormGroup = 'IMPCUSOFF';
                this.customsControl = 'RefNumIMPCUSOFF';
                this.traderFormGroup = 'TRACONCE1'
                this.traderControl = 'NamCE17'
                break;
            }
            case 'IB15A': case 'IB13A': {
                this.customsFormGroup = 'CUSOFF';
                this.customsControl = 'RefNumIMPCUSOFF';
                this.traderFormGroup = 'TRADEC'
                this.traderControl = 'NamTDE1'
                break;
            }

            case 'VA15A': case 'VA13A': case 'EF15A': case 'EF13A': {

                this.showCustoms = false;
                this.showTrader = false;
                break;
            }
            case 'TF015A': case 'TF013A': {
                this.customsFormGroup = 'CUSOFFDEPEPT';
                this.customsControl = 'RefNumEPT1';
                this.traderFormGroup = 'TRACONCO1'
                this.traderControl = 'NamCO17'
                break;
            }
            case 'CC015B': case 'CC013B': {
                this.customsFormGroup = 'CUSOFFDEPEPT';
                this.customsControl = 'RefNumEPT1';
                this.traderFormGroup = 'TRAPRIPC1'
                this.traderControl = 'NamPC17'
                break;
            }
        }


    }

}
