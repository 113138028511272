<table class="beer-parameters">
    <colgroup>
        <col style="width:50%">
        <col style="width:15%">
    </colgroup>
    <thead>
        <tr>
            <th colspan="2">ΠΑΡΑΜΕΤΡΟΙ ΥΠΟΛΟΓΙΣΜΟΥ</th>
        </tr>
    </thead>
    <tbody *ngIf="beerForm$ | async as beerForm">
        <tr [formGroup]="beerForm">
            <td>Συντελεστής Ε.Φ.Κ.</td>
            <td class="noPrint"><input tabindex="-1" readonly formControlName="productDuty" /></td>
            <td class="printOnly">{{beerForm.get('productDuty').value | number:'1.1-1'}}</td>
        </tr>
        <tr>
            <td>Εισφορά υπέρ ΕΤΕΠΠΑ</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.eteppaContribution | percent" /></td>
            <td class="printOnly">{{params.eteppaContribution | percent}}</td>

        </tr>
        <tr>
            <td>Εισφορά υπέρ ΟΓΑ</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.ogaContribution | percent" /></td>
            <td class="printOnly">{{params.ogaContribution | percent}}</td>

        </tr>
        <tr>
            <td>Χαρτόσημο ΕΤΕΠΠΑ</td>
            <td class="noPrint"><input tabindex="-1" readonly [value]="params.eteppaStampTax | percent" /></td>
            <td class="printOnly">{{params.eteppaStampTax | percent}}</td>

        </tr>
        <tr>
            <td>Φ.Π.Α.</td>
            <td class="noPrint"><input tabindex="-1" readonly [formControl]="vatRate" /></td>
            <td class="printOnly">{{vatRate.value}}</td>

        </tr>
    </tbody>
</table>