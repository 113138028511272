import { AbstractControl, FormControl } from "@angular/forms";
import { SupplementaryComputationInformationModel } from "./sup-computation.model";

export class SupplementaryComputationInformationForm {
    [key: string]: AbstractControl;

    SupplementaryComputationInfo: FormControl;
    SupplementaryComputationValue: FormControl;


    constructor(value: SupplementaryComputationInformationModel) {

        this.SupplementaryComputationInfo = new FormControl(value.SupplementaryComputationInfo);
        this.SupplementaryComputationValue = new FormControl(value.SupplementaryComputationValue, { updateOn: 'blur' });

    }
}
