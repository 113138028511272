import { InvoiceParsingResultsModel } from 'app/main/apps/customer-invoice-parsing/_models';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { DeclarationStateService } from 'app/services/declaration-state.service';
import { FormArrayService } from 'app/services/submitServices/formArray.service';
import { DeclarationFormService } from 'app/services/declaration-form.service';
import { LrnService } from 'app/services/lrn.service';
import { of, Observable, startWith, tap, combineLatest } from 'rxjs';
import { AccountingService } from 'app/main/main-building-blocks/_scams/accounting/accounting.service';
import { DeclarationModels } from 'app/services/submitServices/submitForm.service';
import { RouteStateService } from 'app/services/routeState.service';
import { StorageService } from 'app/core/services/storage/storage.service';
import { PrivateControlsService } from 'app/model/private-controls.service';
import { BroadcasterService } from 'app/main/apps/export/services/export-broadcaster.service';

@Injectable()
export class ExpDeclarationFormService extends DeclarationFormService {
    //subjects used to terminate following of valuechanges on main document
    //when value on bis is changed by user


    constructor(
        protected _fuseSplashScreenService: FuseSplashScreenService,
        protected mainBroadcaster: MainBroadcasterService,
        protected cf: ExtCustomFunctions,
        protected lrnService: LrnService,
        protected declarationStateService: DeclarationStateService,
        protected formArrayService: FormArrayService,
        protected toastrService: ToastrService,
        protected routeStateService: RouteStateService,
        protected storageService: StorageService,
        private accountingService: AccountingService,
        private broadcaster: BroadcasterService,
        protected privateControlsService: PrivateControlsService,


    ) {
        super(
            mainBroadcaster,
            cf,
            lrnService,
            declarationStateService,
            formArrayService,
            _fuseSplashScreenService,
            toastrService,
            routeStateService,
            storageService,
            privateControlsService,
            'EXP_DECL'


        );

        console.log('SERVICE ExpDeclarationFormService localDocs', this.localDocs);
        console.log(
            'SERVICE ExpDeclarationFormService modifyMessageType',
            this.modifyMessageType
        );

        console.trace('ExpDeclarationFormService');
    }


    openAsTemplate(data: DeclarationModels) {

        this.openAsTemplateGeneral(data);

        const currentDeclaration = this.declarationForm.getValue();
        const currentHeahea = currentDeclaration.at(0).get('HEAHEA') as FormGroup;
        const currentTradat = currentDeclaration.at(0).get('TRADAT') as FormGroup;
        const customsExit = currentDeclaration.at(0).get('CUSOFFEXIEXT') as FormGroup;

        const currentSeaInfSli = currentDeclaration
            .at(0)
            .get('SEAINFSLI') as FormGroup;

        const currentSeals = currentSeaInfSli.get('SEAIDSID') as FormArray;

        const currentGoods = currentDeclaration.at(0).get('GOOITEGDS') as FormArray;


        for (let i = 0; i < currentGoods.length; i++) {
            const currDocs = currentGoods.at(i).get('PRODOCDC2') as FormArray;
            const currPrevDocs = currentGoods.at(i).get('PREADMREFAR2') as FormArray;
            const currAccounting = currentGoods.at(i).get('ACCOUNTING') as FormArray;
            const currConnr = currentGoods.at(i).get('CONNR2') as FormArray;
            const currGRN = currentGoods.at(i).get('GRN') as FormArray;
            const currPackages = currentGoods.at(i).get('PACGS2') as FormArray;


            this.cf.resetFieldValue(currDocs, 'DocRefDC23');
            this.cf.resetFieldValue(currDocs, 'DocInfDC1008');

            this.cf.resetFieldValue(currPrevDocs, 'PreDocRefAR26');
            this.cf.resetFieldValue(currPrevDocs, 'PreDocQuaAR1006');

            this.cf.resetFieldValue(currAccounting, 'AccValue');
            this.cf.resetFieldValue(currAccounting, 'AccResult');
            this.cf.resetFieldValue(currConnr, 'ConNumNR21');


            this.cf.resetFieldValue(currPackages, 'NumOfPacGS24');
            this.cf.resetFieldValue(currPackages, 'NumOfPieGS25');
            this.cf.resetFieldValue(currPackages, 'MarNumOfPacGS21');
            this.cf.resetFieldValue(currGRN, 'GrnNum1010');

            //this.cf.resetFieldValue(currDocs, "DocInfDC1008");
            //this.cf.resetFieldValue(currDocs, "DocInfDC1008");

            this.cf.resetFieldValue(currentGoods, 'SupUniGDI1');
            this.cf.resetFieldValue(currentGoods, 'GroMasGDS46');
            this.cf.resetFieldValue(currentGoods, 'NetMasGDS48');
            this.cf.resetFieldValue(currentGoods, 'StaValAmoGDI1');
            this.cf.resetFieldValue(currentGoods, 'IdeMeaTraDepGDS1030');
            this.cf.resetFieldValue(currentGoods, 'IdeMeaTraCroBorGDS1031');
            this.cf.resetFieldValue(currentGoods, 'NatMeaTraCroBorGDS1032');
            this.cf.resetFieldValue(currentGoods, 'TypMeaTraCroBorGDS1033');
        }


        currentSeaInfSli.get('SeaNumSLI2').reset();
        for (let i = currentSeals.length - 1; i >= 0; i--) {
            this.deleteFormArray('SEAIDSID', i, currentSeaInfSli);
        }

        currentHeahea.get('DocNumHEA5').reset();
        currentHeahea.get('AccDatHEA158').reset();
        currentHeahea.get('AmeAccDatHEA602').reset();
        currentHeahea.get('ExiDatHEA1').reset();
        currentHeahea.get('IdeOfMeaOfTraAtDHEA78').reset();
        currentHeahea.get('IdeOfMeaOfTraCroHEA85').reset();
        currentHeahea.get('NatOfMeaOfTraCroHEA87').reset();
        currentHeahea.get('TypOfMeaOfTraCroHEA88').reset();

        currentTradat.get('TotAmoInvTRD1').reset();

        customsExit.get('RefNumEXT1').reset();
    }
    //monitor Exporter,Consignne, Countries of Dispatch,Destination,Origin and Procedure and update Bis
    patchValuesOnBisValueChange(totalItems: number) {
        console.log('patchValuesOnBis totalItems', totalItems);

        const currentDeclaration = this.declarationForm.getValue();

        const currentGoods = currentDeclaration.at(0).get('GOOITEGDS') as FormArray;
        const heahea = currentDeclaration.at(0).get('HEAHEA') as FormGroup;
        const TRAEXPEX1 = currentDeclaration.at(0).get('TRAEXPEX1') as FormGroup;
        const TRACONCE1 = currentDeclaration.at(0).get('TRACONCE1') as FormGroup;

        const exporterFirst = TRAEXPEX1.get('ExporterObject');
        const consigneeFirst = TRACONCE1.get('ConsigneeObject');
        const countryOfDispFirst = heahea.get('CouOfDisCodHEA55');
        const countryOfDestFirst = heahea.get('CouOfDesCodHEA30');
        const countryOfOriFirst = currentGoods.at(0).get('CouOfOriGDI1');
        const requestedProcFirst = currentGoods.at(0).get('ProReqGDI1');
        const previousProcFirst = currentGoods.at(0).get('PreProGDI1');
        const nationalCodeFirst = currentGoods.at(0).get('ComNatProGIM1');

        //======================================================================================================================

        const length = currentGoods.length;

        const exportersArray = [];
        const consigneesArray = [];
        const countryOfDispArray = [];
        const countryOfDestArray = [];
        const countryOfOriArray = [];
        const requestedProcArr = [];
        const previousProcArr = [];
        const nationalCodeArr = [];


        //We get all the item values except of the first item, because the first item at first is empty, the values or set on the main level for the first item
        for (let i = 1; i < length; i++) {

            const itemExporter = (currentGoods.at(i).get('TRACONEX2') as FormArray).at(0).get('ExporterObject').value;
            const itemConsignee = (currentGoods.at(i).get('TRACONCE2') as FormArray).at(0).get('ConsigneeObject').value;
            const countryOfDisp = currentGoods.at(i).get('CouOfDisGDS58').value;
            const countryOfDest = currentGoods.at(i).get('CouOfDesGDS59').value;
            const countryOfOri = currentGoods.at(i).get('CouOfOriGDI1').value;
            const requestedProc = currentGoods.at(i).get('ProReqGDI1').value;
            const previousProc = currentGoods.at(i).get('PreProGDI1').value;
            const nationalCode = currentGoods.at(i).get('ComNatProGIM1').value;


            exportersArray.push(itemExporter);
            consigneesArray.push(itemConsignee);
            countryOfDispArray.push(countryOfDisp);
            countryOfDestArray.push(countryOfDest);
            countryOfOriArray.push(countryOfOri);
            requestedProcArr.push(requestedProc)
            previousProcArr.push(previousProc)
            nationalCodeArr.push(nationalCode)


        }
        //==================================================================================================================

        const exporterEqual = this.cf.sameValuesInArray(exportersArray, exporterFirst.value);
        const consigneeEqual = this.cf.sameValuesInArray(consigneesArray, consigneeFirst.value);
        const countryOfDispEqual = this.cf.sameValuesInArray(countryOfDispArray, countryOfDispFirst.value);
        const countryOfDestEqual = this.cf.sameValuesInArray(countryOfDestArray, countryOfDestFirst.value);
        const countryOfOriEqual = this.cf.sameValuesInArray(countryOfOriArray, countryOfOriFirst.value);
        const requestedProcEqual = this.cf.sameValuesInArray(requestedProcArr, requestedProcFirst.value);
        const previousProcEqual = this.cf.sameValuesInArray(previousProcArr, previousProcFirst.value);
        const nationalCodeEqual = this.cf.sameValuesInArray(nationalCodeArr, nationalCodeFirst.value);

        const consignees$ = consigneeFirst.valueChanges
            .pipe(startWith(consigneeFirst.value), tap(consigneeObject => {

                if ((typeof (consigneeObject) === 'object' || !consigneeObject) && consigneeEqual) {

                    const ConsigneeSpecialMention = TRACONCE1.get('SpeMenCE1003') as FormControl;

                    //If Special Mention is not 00200
                    if (ConsigneeSpecialMention.value !== '00200') {
                        for (let i = 1; i < totalItems; i++) {
                            const TRACONCE2 = currentGoods.at(i)?.get('TRACONCE2') as FormArray;

                            this.setConsigneeOnBis(TRACONCE2, consigneeObject)

                        }
                    }
                }
            }));


        const exporters$ = exporterFirst.valueChanges
            .pipe(startWith(exporterFirst.value), tap(exporterObject => {

                if ((typeof (exporterObject) === 'object' || !exporterObject) && exporterEqual) {

                    const ExporterSpecialMention = TRAEXPEX1.get('SpeMenEX1011') as FormControl;

                    //If Special Mention is not 00200
                    if (ExporterSpecialMention.value !== '00200') {
                        for (let i = 1; i < totalItems; i++) {

                            const TRACONEX2 = currentGoods.at(i)?.get('TRACONEX2') as FormArray;

                            this.setExporterOnBis(TRACONEX2, exporterObject)
                        }
                    }
                }
            }));
        //country of dispatch
        const countryOfDisp$ = countryOfDispFirst.valueChanges
            .pipe(startWith(countryOfDispFirst.value), tap(value => {

                if ((value?.length === 2 || !value) && countryOfDispEqual) {

                    for (let i = 1; i < totalItems; i++) {
                        currentGoods.at(i)?.get('CouOfDisGDS58').setValue(value);
                    }
                }
            }));
        // country of dest
        const countryOfDest$ = countryOfDestFirst.valueChanges
            .pipe(startWith(countryOfDestFirst.value), tap(value => {

                if ((value?.length === 2 || !value) && countryOfDestEqual) {
                    for (let i = 1; i < totalItems; i++) {
                        currentGoods.at(i)?.get('CouOfDesGDS59').setValue(value);
                    }
                }
            }));
        //country of ori
        const countryOfOri$ = countryOfOriFirst.valueChanges
            .pipe(startWith(countryOfOriFirst.value), tap((value) => {

                if ((value?.length === 2 || !value) && countryOfOriEqual) {

                    for (let i = 1; i < totalItems; i++) {
                        currentGoods.at(i)?.get('CouOfOriGDI1').setValue(value);
                    }
                }

            }));
        //procedure
        const requestedProc$ = requestedProcFirst.valueChanges
            .pipe(startWith(requestedProcFirst.value), tap(value => {
                if ((value?.length === 2 || !value) && requestedProcEqual) {
                    for (let i = 1; i < totalItems; i++) {
                        currentGoods.at(i)?.get('ProReqGDI1').setValue(value);
                    }
                }
            }))

        const previousProc$ = previousProcFirst.valueChanges
            .pipe(startWith(previousProcFirst.value), tap(value => {
                if ((value?.length === 2 || !value) && previousProcEqual) {
                    for (let i = 1; i < totalItems; i++) {
                        currentGoods.at(i)?.get('PreProGDI1').setValue(value);
                    }
                }
            }))

        const nationalCode$ = nationalCodeFirst.valueChanges
            .pipe(startWith(nationalCodeFirst.value), tap(value => {
                if ((value?.length === 3 || !value) && nationalCodeEqual) {
                    for (let i = 1; i < totalItems; i++) {
                        currentGoods.at(i)?.get('ComNatProGIM1').setValue(value);
                    }
                }
            }))

        return combineLatest([exporters$, consignees$, countryOfDisp$, countryOfDest$, countryOfOri$, requestedProc$, previousProc$, nationalCode$])
    }



    patchInvoiceData(data: InvoiceParsingResultsModel): Observable<boolean> {
        console.log("DATA RECEIVED IN EXP DECLFORMSERVICE", data)

        try {
            const currentDeclaration = this.declarationForm.getValue();
            const currentHeahea = currentDeclaration.at(0).get('HEAHEA') as FormGroup;
            const TRAXEPEX1 = currentDeclaration.at(0).get('TRAEXPEX1') as FormGroup;
            const TRACONCE1 = currentDeclaration.at(0).get('TRACONCE1') as FormGroup;
            const DELTER = currentDeclaration.at(0).get('DELTER') as FormGroup;
            const TRADAT = currentDeclaration.at(0).get('TRADAT') as FormGroup;
            const currentGoods = currentDeclaration.at(0).get('GOOITEGDS') as FormArray;

            currentHeahea.get('CouOfDisCodHEA55').setValue('GR')
            currentHeahea.get('CouOfDesCodHEA30').setValue(data.destinationCountry)

            currentHeahea.get('IdeOfMeaOfTraAtDHEA78').setValue(data.transport)
            this.broadcaster.broadcast('transportAtDep', data.transport);

            ///Reset NAtionality to force user to check it
            currentHeahea.get('NatOfMeaOfTraCroHEA87').setValue(null)
            currentHeahea.get('NatOfMeaOfTraAtDHEA80').setValue(null)


            DELTER.get('IncCodTDL1').setValue(data.deliveryTerms)
            TRAXEPEX1.get('ExporterObject').setValue(data.trader)
            TRACONCE1.get('ConsigneeObject').setValue(data.consignee)
            TRADAT.get('CurTRD1').setValue('EUR')
            TRADAT.get('TotAmoInvTRD1').setValue(data.calculatedTotals.value)

            const declarationItems = +currentHeahea.get('TotNumOfIteHEA305').value
            const numberOfItems = +data.goods.length

            //If there are more items in the declaration than the parsed invoice, prompt user to delete them or not
            /*  if (declarationItems > numberOfItems) {
                   this.toastrService.warning('Η δήλωση έχει περισσότερα αντικείμενα από το τιμολόγιο. Παρακαλώ διαγράψτε τα περισσότερα αντικείμενα ή δημιουργήστε νέα δήλωση', 'Προσοχή')
                   return of(false)
             } */

            currentHeahea.get('TotNumOfIteHEA305').setValue(numberOfItems.toString())


            console.log("patchInvoiceData numberOfItems ", numberOfItems)

            this.sendTotalItems(numberOfItems.toString(), true);

            for (let i = 0; i < numberOfItems; i++) {

                const currentItem = currentGoods.at(i) as FormGroup

                currentItem.get('GroMasGDS46').setValue(data.goods[i].subtotal.gross)
                currentItem.get('NetMasGDS48').setValue(data.goods[i].subtotal.net)


                currentItem.get('StaValAmoGDI1').setValue(data.goods[i].subtotal.value)
                currentItem.get('StaValCurGDI1').setValue(data.currency)

                this.accountingService.calculateFromInvoice(currentItem, i, data.goods[i].subtotal.value, data.currency)

                console.log("patchInvoiceData currentItem after Patch", currentItem.value)

                //================================================================================================
                const PACGS2 = currentItem.get('PACGS2') as FormArray;

                if (!PACGS2) {
                    this.addGooFormArray('PACGS2', i)
                }

                PACGS2.at(0).get('KinOfPacGS23').setValue('PX')
                PACGS2.at(0).get('NumOfPacGS24').setValue(data.goods[i].subtotal.pal)
                PACGS2.at(0).get('MarNumOfPacGS21').setValue("CT:" + data.goods[i].subtotal.packs)
                //===============================================================================================

                const PRODOCDC2 = currentItem.get('PRODOCDC2') as FormArray;

                const invoiceIndex = PRODOCDC2.value.findIndex(e => e.DocTypDC21 === 'N380')

                console.log("INVOICEINDEX", invoiceIndex)
                //If N380 already exists, overwrite
                if (invoiceIndex > -1) {
                    PRODOCDC2.at(invoiceIndex).get('DocRefDC23').setValue(data.invoice)
                }
                //if Documents array is empty
                else if (!PRODOCDC2.at(0).get('DocTypDC21').value) {
                    PRODOCDC2.at(0).get('DocTypDC21').setValue('N380')
                    PRODOCDC2.at(0).get('DocRefDC23').setValue(data.invoice)
                    //if dosuments array is not empty, add an entry
                } else {
                    this.addGooFormArray('PRODOCDC2', i,
                        {
                            "DocTypDC21": "N380",
                            "DocRefDC23": data.invoice


                        })
                }

                this.cf.getGrossWeights(currentDeclaration.at(0) as FormGroup, numberOfItems)
                this.cf.getPackagesInitial(currentDeclaration.at(0) as FormGroup, numberOfItems)



            }

            TRAXEPEX1.get('ExporterObject').markAsTouched()
            return of(true)
        }
        catch (e) {
            console.log(e)
            return of(false);
        }




    }
}
