import { Declarant } from "app/core/user/user.model";
import { Contact } from "app/main/apps/contacts/contact.model";

export class ToposTirisisEggafonModel {
    //This is the model of the object that will be passed on our form in case we have some initial values that need to be inserted

    customsName?: string;
    customsCode?: string;
    customer?: TirisiEggrafonCustomer;
    declarant?: TirisiEggrafonDeclarant;
    documentsPlace?: TirisiEggrafonPlaceModel;
    contactDetails?: TirisiEggrafonContactDetailsModel;
    date?: string;

    constructor(value?: ToposTirisisEggafonModel) {

        this.customsName = value?.customsName || null;
        this.customsCode = value?.customsCode || null;
        this.customer = value?.customer || null;
        this.declarant = value?.declarant || null;
        this.documentsPlace = value?.documentsPlace || null;
        this.contactDetails = value?.contactDetails || null;
        this.date = value?.date || null;
    }
}


export class TirisiEggrafonCustomer {

    customerObject: Contact;
    contactDetails: TirisiEggrafonContactDetailsModel;

    constructor(value?: TirisiEggrafonCustomer) {

        this.customerObject = value?.customerObject || null;
        this.contactDetails = value?.contactDetails || null;

    }

}

export class TirisiEggrafonDeclarant {
    declarantObject: Declarant;
    contactDetails: TirisiEggrafonContactDetailsModel;
    constructor(value?: TirisiEggrafonDeclarant) {

        this.declarantObject = value?.declarantObject || null;
        this.contactDetails = value?.contactDetails || null;
    }
}
export class TirisiEggrafonPlaceModel {

    place?: number;
    lastname?: string;
    firstname?: string;
    street?: string;
    streetNumber?: string;
    postalCode?: string;
    city?: string;
    county?: string;
    prefecture?: string;
    workingHours?: string;


    constructor(
        value?: TirisiEggrafonPlaceModel
    ) {
        this.place = value?.place || null;
        this.lastname = value?.lastname || null;
        this.firstname = value?.firstname || null;
        this.street = value?.street || null;
        this.streetNumber = value?.streetNumber || null;
        this.postalCode = value?.postalCode || null;
        this.city = value?.city || null;
        this.county = value?.county || null;
        this.prefecture = value?.prefecture || null;
        this.workingHours = value?.workingHours || null;

    }

}

export class TirisiEggrafonContactDetailsModel {

    lastname?: string;
    firstname?: string;
    phone?: string;
    fax?: string;
    email?: string;

    constructor(
        value?: TirisiEggrafonContactDetailsModel
    ) {
        this.lastname = value?.lastname || null;
        this.firstname = value?.firstname || null;

        this.phone = value?.phone || null;
        this.fax = value?.fax || null;
        this.email = value?.email || null;
    }
}