import { Trapripc1Model } from 'app/main/apps/transit/tir/_models/trapripc1/trapripc1.model';
import { _pelatesItem } from 'app/model/api-model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { CusoffdepeptModel } from '../../../_models/cusoffdepept/cusoffdepept.model';
import { CancelHeaheaModel } from 'app/main/apps/export/cancel-declaration/_models/heahea/heahea.model';

export class TirCancelModel {
    _PRIVATE: PrivateControlsModel;

    SynIdeMES1?: string;
    SynVerNumMES2?: string;
    MesSenMES3?: string;
    SenIdeCodQuaMES4?: string;
    MesRecMES6?: string;
    RecIdeCodQuaMES7?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    IntConRefMES11?: string;
    RecRefMES12?: string;
    RecRefQuaMES13?: string;
    AppRefMES14?: string;
    PriMES15?: string;
    AckReqMES16?: string;
    ComAgrIdMES17?: string;
    TesIndMES18?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    ComAccRefMES21?: string;
    MesSeqNumMES22?: string;
    FirAndLasTraMES23?: string;
    //CorIdeMES25?: string;

    HEAHEA?: CancelHeaheaModel;
    TRAPRIPC1?: Trapripc1Model;
    CUSOFFDEPEPT?: CusoffdepeptModel;
    //
    constructor(value?) {
        this._PRIVATE = value?._PRIVATE || null;

        this.SynIdeMES1 = value?.SynIdeMES1 || null;
        this.SynVerNumMES2 = value?.SynVerNumMES2 || null;
        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.SenIdeCodQuaMES4 = value?.SenIdeCodQuaMES4 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.RecIdeCodQuaMES7 = value?.RecIdeCodQuaMES7 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.IntConRefMES11 = value?.IntConRefMES11 || null;
        this.RecRefMES12 = value?.RecRefMES12 || null;
        this.RecRefQuaMES13 = value?.RecRefQuaMES13 || null;
        this.AppRefMES14 = value?.AppRefMES14 || null;
        this.PriMES15 = value?.PriMES15 || null;
        this.AckReqMES16 = value?.AckReqMES16 || null;
        this.ComAgrIdMES17 = value?.ComAgrIdMES17 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = 'CC014A';
        this.ComAccRefMES21 = value?.ComAccRefMES21 || null;
        this.MesSeqNumMES22 = value?.MesSeqNumMES22 || null;
        this.FirAndLasTraMES23 = value?.FirAndLasTraMES23 || null;

        this.HEAHEA = value?.HEAHEA;
        this.TRAPRIPC1 = value?.TRAPRIPC1;
        this.CUSOFFDEPEPT = value?.CUSOFFDEPEPT;
    }
}
