import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'tariff-info-dialog',
    templateUrl: './tariff-info-dialog.component.html',
    styleUrls: ['./tariff-info-dialog.component.scss']
})
export class TariffInfoDialogComponent {
    measure: any;
    state: any;
    show: string; //conditions, regulations
    langCoutryCodeMap = new Map();

    constructor(public dialogRef: MatDialogRef<TariffInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.measure = this.data.measure;
        this.state = this.data.state;
        this.show = this.data.show;

        console.log('SHOW', this.show);

        this.langCoutryCodeMap.set('BG', 'BUL');
        this.langCoutryCodeMap.set('ES', 'SPA');
        this.langCoutryCodeMap.set('CS', 'CES');
        this.langCoutryCodeMap.set('DA', 'DAN');
        this.langCoutryCodeMap.set('DE', 'DEU');
        this.langCoutryCodeMap.set('ET', 'EST');
        this.langCoutryCodeMap.set('EL', 'ELL');
        this.langCoutryCodeMap.set('EN', 'ENG');
        this.langCoutryCodeMap.set('FR', 'FRA');
        this.langCoutryCodeMap.set('GA', 'GLE');
        this.langCoutryCodeMap.set('HR', 'HRV');
        this.langCoutryCodeMap.set('IT', 'ITA');
        this.langCoutryCodeMap.set('LV', 'LAV');
        this.langCoutryCodeMap.set('LT', 'LIT');
        this.langCoutryCodeMap.set('HU', 'HUN');
        this.langCoutryCodeMap.set('MT', 'MLT');
        this.langCoutryCodeMap.set('NL', 'NLD');
        this.langCoutryCodeMap.set('PL', 'POL');
        this.langCoutryCodeMap.set('PT', 'POR');
        this.langCoutryCodeMap.set('RO', 'RON');
        this.langCoutryCodeMap.set('SK', 'SLK');
        this.langCoutryCodeMap.set('SL', 'SLV');
        this.langCoutryCodeMap.set('FI', 'FIN');
        this.langCoutryCodeMap.set('SV', 'SWE');
    }

    onCloseConfirm() {
        this.dialogRef.close();
    }

    getDocumentURL(lang, year, journalNumber, journalPage) {
        const url = 'https://eur-lex.europa.eu/legal-content/EL/TXT/PDF/?uri=uriserv:OJ.L_.';

        let language = '';

        language = this.langCoutryCodeMap.get(lang);

        const jnumber = ('000' + journalNumber.substring(journalNumber.length - 3, journalNumber.length).trim()).slice(-3);

        let page = '0000' + journalPage;

        if (journalPage === '-') {
            page = '0001';
        }

        const document = url + year.substring(0, 4) + '.' + jnumber + '.01.' + page.slice(-4) + '.01.' + language;

        return document;
    }
}
