import { AbstractControl, FormControl, FormArray, FormGroup } from "@angular/forms";
import { EPaymentModel } from "./e-payment.model";

export class EPaymentForm {
    [key: string]: AbstractControl;

    blrn: FormControl;
    trader: FormControl;
    stateCode: FormControl;
    mrns: FormArray;
    bamnt: FormControl;
    date: FormControl;
    brmt: FormControl
    bmsv: FormControl;
    expired: FormControl;
    /*

   */

    constructor(declaration: EPaymentModel) {

        this.blrn = new FormControl(declaration.blrn);
        this.trader = new FormControl(declaration.trader);
        this.stateCode = new FormControl(declaration.stateCode);
        this.mrns = new FormArray([]);
        this.bamnt = new FormControl(declaration.bamnt);
        this.date = new FormControl(declaration.date);
        this.brmt = new FormControl(declaration.brmt);
        this.bmsv = new FormControl(declaration.bmsv);
        this.expired = new FormControl(declaration.expired);



        if (declaration.mrns) {
            declaration.mrns.forEach((mrn: string) => {
                this.mrns.push(new FormControl(mrn));
            });
        }
    }
}
