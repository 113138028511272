import { Injectable } from '@angular/core';
import { eAitisiDeclarationModel } from 'app/main/apps/e-aitisi/_models/declaration/declaration.model';
import { DaoDeclarationModel } from 'app/main/apps/excise/dao/_models/VA15A/declaration.model';
import { ArrAtExitModel } from 'app/main/apps/export/arrival-at-exit/_models/arrivaAtExit.model';
import { Declaration } from 'app/main/apps/export/declaration/_models/declaration.model';
import { ImportDeclarationModel } from 'app/main/apps/import/_models/declaration/declaration.model';
import { T2LDeclarationModel } from 'app/main/apps/transit/t2l/_models/declaration/declaration.model';
import { TirDeclarationModel } from 'app/main/apps/transit/tir/_models/declaration/declaration.model';

@Injectable({
    providedIn: 'root'
})
export class MessageHeaderService {
    subdomain: string;

    setHeader(
        submitForm: Declaration |
            ImportDeclarationModel |
            T2LDeclarationModel |
            TirDeclarationModel |
            ArrAtExitModel |
            eAitisiDeclarationModel,
        subdomain: string): any {

        this.subdomain = subdomain;


        const datetime = this.getDateTime();
        const date = datetime[0];
        const time = datetime[1];

        const refNumber = submitForm._PRIVATE.lrn.substring(10)

        const messageIdentity = this.getMessageIdentity(refNumber);

        //dismiss refNumber. No longer Needed

        if (subdomain === 'EXP_DECL' || subdomain === 'TIR' || subdomain === 'ARR_AT_EXIT') {

            this.setAdditional(submitForm, messageIdentity)
        }

        submitForm.MesSenMES3 = 'TRADER.GR';
        submitForm.MesRecMES6 = this.getRecipient(subdomain);
        submitForm.DatOfPreMES9 = date;
        submitForm.TimOfPreMES10 = time;
        submitForm.MesIdeMES19 = messageIdentity;
        submitForm.TesIndMES18 = '0';

        submitForm = this.setMessageType(submitForm);
        console.log('submit Form After messageHeader:', submitForm);

        return submitForm;
    }
    setAdditional(submitForm, messageIdentity) {
        submitForm.SynIdeMES1 = 'UNOC';
        submitForm.SynVerNumMES2 = '3';
        submitForm.IntConRefMES11 = messageIdentity;
    }

    setMessageType(submitForm): any {
        /*  if (submitForm.MesTypMES20 === null) {
             let message;
             if (this.subdomain === 'EXP_DECL') {
                 message = 'CC515A';
             } else if (this.subdomain === 'IMP_DECL') {
                 message = 'ID15A';
             } else if (this.subdomain === 'ARR_AT_EXIT') {
                 message = 'CC507A';
             } else if (this.subdomain === 'ICS_DETE') {
                 message = 'IB15A';
             } else if (this.subdomain === 'T2L') {
                 message = 'TF015A';
             }
 
             submitForm.MesTypMES20 = message;
         } */
        submitForm.MesTypMES20 = submitForm._PRIVATE.messageOut
        console.log("submitForm.MesTypMES20", submitForm.MesTypMES20)
        return submitForm;
    }

    setEMCSHeader(submitForm: DaoDeclarationModel,
        subdomain: string): any {

        const refNumber = submitForm._PRIVATE.lrn.substring(10)
        const datetime = this.getDateTime();
        const date = datetime[2];
        const time = datetime[3];


        console.log("setEMCSHeader refNumber", refNumber)

        submitForm.Header.MessageSender = 'TRADER.GR'
        submitForm.Header.MessageRecipient = 'NES.EL';
        submitForm.Header.DateOfPreparation = date;
        submitForm.Header.TimeOfPreparation = time;
        submitForm.Header.MessageIdentifier = this.getMessageIdentity(refNumber);

        return submitForm;

    }

    getDateTime() {
        const d = new Date();
        let HH = d.getHours().toString();
        let mm = d.getMinutes().toString();
        let ss = d.getSeconds().toString();
        let dd = d.getDate().toString();
        let MM = (+d.getMonth() + 1).toString();
        const yy = d.getFullYear().toString().substr(-2);
        const yyyy = d.getFullYear().toString();

        MM = MM.length < 2 ? '0' + MM : MM;

        dd = dd.length < 2 ? '0' + dd : dd;

        HH = HH.length < 2 ? '0' + HH : HH;

        mm = mm.length < 2 ? '0' + mm : mm;

        ss = ss.length < 2 ? '0' + ss : ss;

        const time = HH + mm;
        const time2 = HH + ":" + mm + ":" + ss;
        const date = yy + MM + dd;
        const date2 = yyyy + "-" + MM + "-" + dd;

        return [date, time, date2, time2];

    }

    getMessageIdentity(refNumber) {

        const timestamp = new Date().getTime().toString();

        refNumber = refNumber?.replace('/', '');

        const length = refNumber?.length;
        //we substract the length of refNumber from the total Message Identity Length which is 14
        //we get a negative number to use on the substr() function to get the remaining characters from end to start
        //messageIdentity is formed ny the refNumber plus the remaining characters from the timestamp(using microseconds)
        //if refNumber length is 5 the remaining 9 characters will be filled by the timestamp from end to start
        const remaining = length - 14;
        const part2 = timestamp.substring(remaining);

        let messageIdentity = refNumber + part2;
        console.log('messageIdentity', messageIdentity);

        messageIdentity = messageIdentity.substring(messageIdentity.length - 14);

        return messageIdentity;
    }

    getRecipient(subdomain) {

        switch (subdomain) {
            case 'EXP_DECL':
            case 'ARR_AT_EXIT':
                return 'NXA.GR';
            case 'IMP_DECL':
            case 'ICS_DETE':
                return 'NIA.GR';
            case 'T2L':
            case 'TIR':
                return 'NTA.GR';
            default:
                return 'NXA.GR';
        }
    }
}
