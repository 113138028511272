import { VallimecvleModel } from '../../../_models/vallimecvle/vallimecvle.model';
import { VallimnoneclimModel } from '../../../_models/vallimnoneclim/vallimnoneclim.model';

export class GuarefrefModel {
    OthGuaRefREF4?: string;
    AccCodREF6?: string;
    GuaRefNum400?: string;
    VALLIMECVLE?: VallimecvleModel;
    VALLIMNONECLIM?: VallimnoneclimModel[];

    constructor(value?) {
        this.OthGuaRefREF4 = value?.OthGuaRefREF4 || null;
        this.AccCodREF6 = value?.AccCodREF6 || null;
        this.GuaRefNum400 = value?.GuaRefNum400 || null;
        this.VALLIMECVLE = value?.VALLIMECVLE || null;
        this.VALLIMNONECLIM = value?.VALLIMNONECLIM;
    }
}
