import declarationData from 'assets/declarationData.json';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from 'app/core/services/storage/storage.service';
import { Declarant } from 'app/core/user/user.model';
import { DEFKDeclarationForm } from 'app/main/apps/excise/defk/_models/EF15A/declaration-form.model';
import { DEFKDeclarationModel } from 'app/main/apps/excise/defk/_models/EF15A/declaration.model';
import { PrivateControlsModel, GoodsPrivateModel } from 'app/model/private-controls.model';
import { PATHS } from 'app/navigation/paths';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { OfficeService } from 'app/services/office.service';
import { lastValueFrom, take, map } from 'rxjs';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';
import { TaxesDeclarationModel } from 'app/main/apps/excise/defk/_models/taxesDeclaration/taxes-declaration.model';
import { ReferenceDocumentsModel } from 'app/main/apps/excise/defk/_models/taxesRow/reference-docs/reference-docs.model';
import { SupplementaryComputationInformationModel } from 'app/main/apps/excise/defk/_models/taxesRow/sup-computation/sup-computation.model';
import { TaxComputationModel } from 'app/main/apps/excise/defk/_models/taxesRow/tax-computation/tax-computation.model';
import { TaxesRowModel } from 'app/main/apps/excise/defk/_models/taxesRow/taxes-row.model';
import { VehiclesModel, CarsCalculationDataModel } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.model';

export interface CarsParameters {
    id: number;
    name: string;
    description: string;
    price: number;
    quantity: number;
    total: number;
}
@Injectable({
    providedIn: 'root'
})
export class CarsDefkCreateService {

    constructor(
        private mainBroadcaster: MainBroadcasterService,
        private storageService: StorageService,
        private router: Router,
        private officeService: OfficeService
    ) { }


    async createDefk(inputData: VehiclesModel, params: CarsParameters) {


        const defkForm = new FormGroup(new DEFKDeclarationForm(new DEFKDeclarationModel()))
        const defk = defkForm.value as DEFKDeclarationModel;

        const defaultDeclarant = await lastValueFrom(this.mainBroadcaster.declarants$.pipe(take(1), map(decl => decl.find(d => d.is_owner))));

        console.log('CarsDefkCreateService createDefk', inputData, params, defaultDeclarant)

        const privateCtrls = defk._PRIVATE;
        const exciseTaxesDeclaration = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclaration;

        this.setPrivateCtrls(inputData, privateCtrls, defaultDeclarant, params)

        this.setExciseTaxesDeclaration(inputData, exciseTaxesDeclaration)
        this.setDeclarant(defaultDeclarant, exciseTaxesDeclaration);
        this.setExciseTaxesRows(inputData, defk, params)


        //Set As LocalStorage
        this.storageService.setLocalObject(declarationData['DEFK'].localStorage, defk)
        this.storageService.removeLocalObject('cars');

        this.officeService.currentOffice$.pipe(take(1)).subscribe(office => {

            this.router.navigateByUrl('/blank', { skipLocationChange: true }).then(() => {
                this.router.navigate([PATHS.localDeclaration(office?.id, 'emcs', 'defk')]);
            });
        })


        console.log('CarsDefkCreateService createDefk defk', defk.Body.DeclarationOfExciseTaxesRegistration)

    }

    setPrivateCtrls(inputData: VehiclesModel, privateCtrls: PrivateControlsModel, defaultDeclarant: Declarant, params: CarsParameters) {
        privateCtrls.declarant = defaultDeclarant;
        privateCtrls.domain = 'EMCS';
        privateCtrls.messageOut = 'EF15A';
        privateCtrls.stateCode = 'Pre-Submitted';
        privateCtrls.subdomain = 'DEFK'
        privateCtrls.cars = inputData;

        inputData.carsCalculationData.forEach((calcData, index) => {

            if (!privateCtrls.goodsPrivate[index]) {
                privateCtrls.goodsPrivate.push(new GoodsPrivateModel())
            }
        })

    }

    setExciseTaxesDeclaration(inputData: VehiclesModel, exciseTaxesDeclaration: TaxesDeclarationModel) {

        const calculationData = inputData.carsCalculationData
        const totalRows = calculationData.length
        const itemsPerPage = declarationData['DEFK'].itemsPerPage
        const totalDocuments = Math.ceil((totalRows - 1) / itemsPerPage) + 1;

        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so +1 is needed.
        const dd = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}-${mm}-${dd}`;

        this.mainBroadcaster.declarationSettings$.pipe(take(1)).subscribe((settings: DeclarationSettingsModel) => {

            exciseTaxesDeclaration.Fallbackflag = "0";
            exciseTaxesDeclaration.RegistrationOffice = settings.export_customs
            exciseTaxesDeclaration.DocumentState = "0"
            exciseTaxesDeclaration.DocumentNumber = "1"
            exciseTaxesDeclaration.TotalDocuments = totalDocuments.toString()
            exciseTaxesDeclaration.NumberOfRows = totalRows.toString()
            exciseTaxesDeclaration.DestinationCountry = "GR"
            exciseTaxesDeclaration.ExistenceOfContainerFlag = "0"
            exciseTaxesDeclaration.ChemicalAnalysisPerformedFlag = "0"
            exciseTaxesDeclaration.ThroughSimplifiedProcedureFlag = "0"
            exciseTaxesDeclaration.PaymentMethodCode = "H"
            exciseTaxesDeclaration.CertificateIssuanceFlag = "1"
            exciseTaxesDeclaration.DeclarationTypeCode = "30"
            exciseTaxesDeclaration.CalculationOfTaxesDate = formattedDate;


            exciseTaxesDeclaration.Currency = "EUR";
            exciseTaxesDeclaration.ExchangeFactor = "1.000";





        })
    }

    setDeclarant(defaultDeclarant: Declarant, exciseTaxesDeclaration: TaxesDeclarationModel) {


        exciseTaxesDeclaration.ClearingAgent.ClearingAgentEORI = defaultDeclarant.eori;

        exciseTaxesDeclaration.ClearingAgent.ClearingAgentType = "2";
        exciseTaxesDeclaration.ClearingAgent.DeclarantObject = defaultDeclarant

    }

    setExciseTaxesRows(inputData: VehiclesModel, defk: DEFKDeclarationModel, params: CarsParameters) {
        const exciseTaxesRow = defk.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesRow
        const calculationData = inputData.carsCalculationData
        const totals = inputData.carsTotals

        const rowNumber = 1;
        //const marksNumbers = this.setMarksNumbers(data)
        //const description = this.setDescription(data, inputData)


        if (exciseTaxesRow[0]) {
            exciseTaxesRow[0].ExciseTaxesDeclarationRowNumber = rowNumber.toString();
            //  exciseTaxesRow[0].SymbolNumbers = marksNumbers;
            //exciseTaxesRow[0].ProductDescription = description;
            exciseTaxesRow[0].TaricCode = this.getTaricCode(inputData);
            exciseTaxesRow[0].TaricAdditionCode = inputData.addTaricCode;
            exciseTaxesRow[0].TaxQuantity = totals.reducedValueTotal.toString();
            exciseTaxesRow[0].ConditionIdentification = "48";
            exciseTaxesRow[0].PreviousConditionIdentification = "00";
            // exciseTaxesRow[0].SupplementaryComputationInformation = this.setSupplementaryComputation(data)
            exciseTaxesRow[0].ReferenceDocuments = this.setReferenceDocuments()
            //exciseTaxesRow[0].TaxComputation = this.setTaxComputation(inputData, data, params)

        } else {
            exciseTaxesRow.push(new TaxesRowModel({
                ExciseTaxesDeclarationRowNumber: rowNumber.toString(),
                // SymbolNumbers: marksNumbers,
                //ProductDescription: description,
                TaricCode: this.getTaricCode(inputData),
                TaricAdditionCode: inputData.addTaricCode,
                TaxQuantity: totals.reducedValueTotal.toString(),
                CountryOfOrigin: "GR",
                ConditionIdentification: "48",
                PreviousConditionIdentification: "00",
                //SupplementaryComputationInformation: this.setSupplementaryComputation(data),
                //ReferenceDocuments: this.setReferenceDocuments(),
                //TaxComputation: this.setTaxComputation(inputData, data, params)


            }));

        }
    }

    setSupplementaryComputation(data: CarsCalculationDataModel) {

        const supCompValues: SupplementaryComputationInformationModel[] = [{

            SupplementaryComputationInfo: '057',
            //  SupplementaryComputationValue: data.platoPer100L.toString()
        }]

        return supCompValues
    }

    setTaxComputation(inputData: VehiclesModel, data: CarsCalculationDataModel, params: CarsParameters) {

        const taxComputation: TaxComputationModel[] = [
            {
                "_natTypeCode": "ALC",
                "_taxFreeCode": "205",
                "_taxPaidCode": "204",
                "NationalAccountingCode": "204",
                "SuspensionFlag": "0",
                // "AmountOfTaxes": data.efk.toString(),
                "PaymentMethodCode": "H",
                //"TaxBase": data.platoPer100L.toString(),
                //"TaxRate": inputData.productDuty.toString()
            },
            {
                "_natTypeCode": "ETP",
                "_taxFreeCode": "719",
                "_taxPaidCode": "718",
                "NationalAccountingCode": "718",
                "SuspensionFlag": "0",
                //"AmountOfTaxes": data.eteppaContrib.toString(),
                //"PaymentMethodCode": "H",
                //"TaxBase": data.efk.toString(),
                //"TaxRate": params.eteppaContribution.toString()
            },
            {
                "_natTypeCode": "XAR",
                "_taxFreeCode": "427",
                "_taxPaidCode": "426",
                "NationalAccountingCode": "426",
                "SuspensionFlag": "0",
                //"AmountOfTaxes": data.eteppaStampTax.toString(),
                //"PaymentMethodCode": "H",
                //"TaxBase": data.eteppaContrib.toString(),
                //"TaxRate": params.eteppaStampTax.toString()
            },
            {
                "_natTypeCode": "OGA",
                "_taxFreeCode": "429",
                "_taxPaidCode": "428",
                "NationalAccountingCode": "428",
                "SuspensionFlag": "0",
                //"AmountOfTaxes": data.ogaContrib.toString(),
                //"PaymentMethodCode": "H",
                //"TaxBase": data.eteppaStampTax.toString(),
                //"TaxRate": params.ogaContribution.toString()
            },

            {
                "_natTypeCode": "VAT",
                "_taxFreeCode": "331",
                "_taxPaidCode": "330",
                "NationalAccountingCode": "330",
                "SuspensionFlag": "0",
                //"AmountOfTaxes": data.vat.toString(),
                //"PaymentMethodCode": "H",
                //"TaxBase": data.vatTaxBase.toString(),
                //"TaxRate": inputData.vatRate.toString()
            }
        ]

        return taxComputation;
    }
    setReferenceDocuments() {

        const refDocs: ReferenceDocumentsModel[] = [

            {
                ReferenceDocumentId: "ΕΦ12",
                ReferenceDocumentNumber: null
            },
            {
                ReferenceDocumentId: "11",
                ReferenceDocumentNumber: null
            },
            {
                ReferenceDocumentId: "17",
                ReferenceDocumentNumber: null
            },
            {
                ReferenceDocumentId: "19",
                ReferenceDocumentNumber: null
            },
            {
                ReferenceDocumentId: "1",
                ReferenceDocumentNumber: null

            }

        ]



        return refDocs;
    }


    setMarksNumbers(data: CarsCalculationDataModel) {

        const description = ''
        //description += data.productName + '\n';
        //description += 'Plato: ' + data.platoExact + '\n';
        //description += 'Λίτρα: ' + data.quantity + '\n';

        return description;
    }
    setDescription(producer: CarsCalculationDataModel, inputData: VehiclesModel) {

        /*   let description = '';
  
          description = 'Προιόν Απόσταξης Διήμερου Μικρού Αποσταγματοποιού από '
  
          if (producer.grapeStems) {
  
              description += 'στέμφυλα '
          }
          else if (producer.wineGrapes) {
              description += 'σταφύλια '
  
          }
          description += 'με αλκοολικό βαθμό ' + (producer.alcoholVol * 100) + '%.'
  
          return description;
   */
    }

    getTaricCode(inputData: VehiclesModel) {
        const vehicleUsedFlag = inputData.vehicleUsedFlag

        let taricCode = null;

        const engineCapacity = inputData.engineCapacity

        if (engineCapacity === 0) {
            taricCode = ''
        }


        return taricCode;
    }
}
