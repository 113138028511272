import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';

if (environment.production) {
    enableProdMode();
}

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Mgo+DSMBaFt/QHRqVVhkXlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSH5Vd0JiUX1Zd3RWQw==;Mgo+DSMBPh8sVXJ0S0J+XE9AdFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0VlWHhfeHZSQWFbVA==;ORg4AjUWIQA/Gnt2VVhkQlFacl1JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjX35ZcXxXQWdeWEE=;ODkxODI3QDMyMzAyZTM0MmUzMGRuRjcwKzU1Tnp0RGY1VWQxSUhHejk2VzJlV1orMExBWG5xKzBUSzVEVE09;ODkxODI4QDMyMzAyZTM0MmUzMGVxYjBKRjI2WHY3QkxXYTN0bjNqN3dsUkFjanRnQTk1eDJ0Y2YyanROVUU9;NRAiBiAaIQQuGjN/V0Z+WE9EaFtAVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViWXlfd3VdRGdbWENz;ODkxODMwQDMyMzAyZTM0MmUzMFoyNmJmVDZyTWNCWk5qOTNvbzhhdk1EUUV0REZsYzNSK0JGOVd0a2R5UWM9;ODkxODMxQDMyMzAyZTM0MmUzMEpFaUVTQ0JOMXZ4dk8yVjcwVys2eVdoSEtXNjZ1cFhDN3ErUzNEZnBtMzA9;Mgo+DSMBMAY9C3t2VVhkQlFacl1JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjX35ZcXxXQWhaWUE=;ODkxODMzQDMyMzAyZTM0MmUzMGxYclpZbFlHc1YvSFBuanoyaEZURis5elhtazI1eTdVWkMyVThnL2xnSDg9;ODkxODM0QDMyMzAyZTM0MmUzMFlsSXpXS3g5am9vb2t1R2VPak9OQTdSUzh6SzArYXZJa3huM0tINndqTFk9;ODkxODM1QDMyMzAyZTM0MmUzMFoyNmJmVDZyTWNCWk5qOTNvbzhhdk1EUUV0REZsYzNSK0JGOVd0a2R5UWM9');

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap().catch(err => console.error(err));
}
