import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, combineLatest, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { generateNavigation } from 'app/navigation/navigation';
import { OfficeService } from 'app/services/office.service';
import { Office } from 'app/model/office.model';
import { BusinessModel } from 'app/main/businesses/invoices/_models/business/business.model';
import { BusinessService } from 'app/main/businesses/services/business.service';

@Component({
    selector: 'vertical-layout-3',
    templateUrl: './layout-3.component.html',
    styleUrls: ['./layout-3.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout3Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param _fuseConfigService
     */
    constructor(private _fuseConfigService: FuseConfigService, private officeService: OfficeService, private businessService: BusinessService) {

        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();
        // Set the defaults
        combineLatest([this.officeService.currentOffice$, this.businessService.currentBusiness$], (office, business) => <{ office: Office, business: BusinessModel }>{ office, business }).pipe(
            filter((value, index) => {
                console.log('Resolver Filter: ', value);
                return value.office && value.business ? true : false;
            }),
            takeUntil(this._unsubscribeAll)
        )
            .subscribe((response) => {
                const office = response.office
                const business = response.business

                this.navigation = generateNavigation(office, business);

            })
            ;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            console.log("fuseConfig  Layout3", config)
            this.fuseConfig = config;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
