import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { ApiService } from 'app/services/api.service';
import { switchMap, take } from 'rxjs/operators';
import { TariffInfoDialogComponent } from './tariff-info-dialog/tariff-info-dialog.component';

@Component({
    selector: 'tariffs',
    templateUrl: './tariffs.component.html',
    styleUrls: ['./tariffs.component.scss']
})
export class TariffsComponent {
    model: any;

    constructor(
        private dbQuery: ApiService,
        public dialog: MatDialog,
        public dialogConditionsRef: MatDialogRef<TariffInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private openDialogs: OpenDialogsService
    ) {
        this.model = this._data.duties;

        console.log('model', this.model);
        console.log('data', this.model.data);
    }

    getSupplyUnit(code) {
        return code;
    }

    selectMeasureType(measure) {
        if (measure.measureType === '109' || measure.measureType === '110') {
            return 'SUPPLEMENTARY_UNIT';
        } else if (measure.measureType === '122' || measure.measureType === '143' || measure.measureType === '552') {
            return 'TARIFF_QUOTA';
        } else {
            return 'DEFAULT';
        }
    }

    getTariffsMeasuresRegulations(id) {
        this.dbQuery
            .getRegulation(id)
            .pipe(switchMap(data => {
                return this.openDialogs.showTariffInfoDialog(this.model, data, 'regulations');
            }), take(1))
            .subscribe();
    }
    showTariffInfoDialog(measure, typeOfDialog) {
        this.openDialogs.showTariffInfoDialog(this.model, measure, typeOfDialog).subscribe();
    }
}
