<div fxLayout="column" style="width: 100%; flex-grow: 1">

    <!-- HEADER -->
    <div mat-dialog-title class="header primary p-6 p-sm-24" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- APP TITLE -->
        <div fxLayout="column" fxLayoutAlign="center flex-start">
            <!--            
-->
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <span class="logo-text h1"> {{ dialogTitle }} </span>
            </div>
        </div>
        <!-- / APP TITLE -->

        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button (click)="matDialogRef.close()">
                <mat-icon class="dialog-close-button">highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <!-- / HEADER -->

    <div mat-dialog-content style="
        background-color: #f5f5f5;
        min-height: 100px;
        height: 100px;
        overflow-y: auto;
    " class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <div [formGroup]="transactionForm" style="width: 100%">

            <div fxLayout="row" fxLayoutAlign="space-between start">

                <div fxLayout="row" style="width: 35%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Ημερομηνία</mat-label>
                        <date-select #firstInput id="date" formControlName="date" placeholder="" [unixTimestamp]="true"
                            [defaultDate]="transactionDate" required>
                        </date-select>
                    </mat-form-field>

                </div>

                <div fxLayout="row" style="width: 35%" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Περιγραφή</mat-label>

                        <input name="description" formControlName="description" matInput />
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>

                </div>

            </div>

            <div fxLayout="row" style="width: 100%" fxLayoutAlign="space-between start">

                <div style="width: 35%">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Λογαριασμός</mat-label>

                        <autocomplete-control [formControl]="account" [options]="accounts" [config]="accountConfig">
                        </autocomplete-control>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>
                </div>
                <div style="width: 35%">

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Κατηγορία</mat-label>

                        <select matNativeControl name="direction" [formControl]="direction">
                            <option value="DEPOSIT">Κατάθεση</option>
                            <option value="WITHDRAWAL">Ανάληψη</option>
                        </select>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" style="width: 100%" fxLayoutAlign="space-between start">

                <div style="width: 35%" [formGroup]="anchorLineItem">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Ποσό</mat-label>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <input name="amount" formControlName="amount" matInput required digitOnly [decimal]="true" />
                    </mat-form-field>
                </div>

                <div style="width: 35%" [formGroup]="anchorCategory">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Κατηγορία</mat-label>
                        <autocomplete-control formControlName="accountId" [options]="accounts" [config]="accountConfig">
                        </autocomplete-control>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <mat-divider></mat-divider>


            <div *ngIf="showCustomer || showVendor" fxLayout="row" style="width: 100%"
                fxLayoutAlign="space-between start">

                <div style="width: 35%" *ngIf="showVendor">

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Πωλητής</mat-label>

                        <autocomplete-control [formControl]="vendor" [options]="vendors" [config]="customerConfig">
                        </autocomplete-control>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>
                </div>
                <div *ngIf="showCustomer" style="width: 35%">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Πελάτης</mat-label>

                        <autocomplete-control [formControl]="customer" [options]="customers" [config]="customerConfig">
                        </autocomplete-control>
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="anchor-transaction__line-item--singleline-desktop__btn-wrapper"
                style="display:flex;margin:8px 0 16px;">
                <div class="transaction-tax-liability__container">
                    <div class="wv-popover__trigger"><span tabindex="0"><button
                                class="wv-button--small wv-button--link fs-unmask transaction-tax-liability__popover-toggle">Include
                                sales tax</button></span></div>
                </div>

                <span
                    class="anchor-transaction__line-item--singleline-desktop__btn-wrapper__bullet-separator">&nbsp;•&nbsp;</span>
                <button *ngIf="!showCustomer" class="wv-button--small wv-button--link fs-unmask"
                    (click)="addCustomer()">Add customer</button>
                <button *ngIf="showCustomer" class="wv-button--small wv-button--link fs-unmask"
                    (click)="removeCustomer()">Remove customer</button>


                <span *ngIf="direction.value==='WITHDRAWAL'"
                    class="anchor-transaction__line-item--singleline-desktop__btn-wrapper__bullet-separator">&nbsp; •
                    &nbsp;</span>
                <button *ngIf="direction.value==='WITHDRAWAL' && !showVendor"
                    class="wv-button--small wv-button--link fs-exclude" (click)="addVendor()">Add
                    vendor</button>
                <button *ngIf="direction.value==='WITHDRAWAL' && showVendor"
                    class="wv-button--small wv-button--link fs-exclude" (click)="removeVendor()">Remove
                    vendor</button>
            </div>

            <button class="wv-button--small wv-button--secondary fs-unmask">Split transaction</button>
        </div>
    </div>

    <div>{{transactionForm.value | json}}</div>
    <div mat-dialog-actions class="m-0 p-6" fxLayout="row" fxLayoutAlign="end center">
        <button *ngIf="action === 'edit'" mat-button class="mr-8" (click)="deleteTransaction()" aria-label="Delete"
            matTooltip="Delete">
            ΔΙΑΓΡΑΦΗ
        </button>

        <button *ngIf="action !== 'edit'" mat-button class="save-button" (click)="createTransaction()"
            [disabled]="transactionForm.invalid" aria-label="SAVE">
            ΠΡΟΣΘΗΚΗ
        </button>

        <button *ngIf="action === 'edit'" mat-button class="save-button" (click)="updateTransaction()"
            [disabled]="transactionForm.invalid" aria-label="SAVE">
            ΑΠΟΘΗΚΕΥΣΗ
        </button>
    </div>
</div>