import { environment } from 'environments/environment';
import { Component, Inject, OnDestroy, OnInit, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, of } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationGreek } from 'app/navigation/i18n/el';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { NavigationEnd, Router } from '@angular/router';
import { RouteStateService } from './services/routeState.service';
import { SwUpdate, VersionDetectedEvent, VersionReadyEvent } from '@angular/service-worker';
import { RollbarService } from './services/rollbar-errorhandler.service';
import Rollbar from 'rollbar';
import { OfficeService } from 'app/services/office.service';
import { generateNavigation } from 'app/navigation/navigation';
import { Office } from 'app/model/office.model';
import { BusinessService } from 'app/main/businesses/services/business.service';
import { BusinessModel } from 'app/main/businesses/invoices/_models/business/business.model';


@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    hiddenToolbar: boolean;
    version: string;
    // Private
    private _unsubscribeAll: Subject<void> = new Subject();

    /**
     * Constructor
     *
     * @param _document
     * @param _fuseConfigService
     * @param _fuseNavigationService
     * @param _fuseSidebarService
     * @param _fuseSplashScreenService
     * @param _fuseTranslationLoaderService
     * @param _platform
     * @param _translateService
     */
    constructor(
        //  @Inject(RollbarService) private rollbar: Rollbar,
        private ngZone: NgZone,
        @Inject(DOCUMENT) private _document: Document,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseSplashService: FuseSplashScreenService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private hotkeysService: HotkeysService,
        private _router: Router,
        private routeState: RouteStateService,
        private swUpdate: SwUpdate,
        private officeService: OfficeService,
        private businessService: BusinessService
    ) {

        combineLatest([
            this.officeService.currentOffice$,
            this.businessService.currentBusiness$
        ], (office, business) => <{ office: Office, business: BusinessModel }>{ office, business }).pipe(
            filter((value, index) => {
                console.log('AppComponent Resolver Filter: ', value);
                return value.office && value.business ? true : false;
            }),
            takeUntil(this._unsubscribeAll)
        )
            .subscribe((response) => {
                const office = response.office
                const business = response.business

                this.navigation = generateNavigation(office, business);

                this._fuseNavigationService.unregister('main');
                // Register the navigation to the service
                this._fuseNavigationService.register('main', this.navigation);

                // Set the main navigation as our current navigation
                this._fuseNavigationService.setCurrentNavigation('main');


            })
            ;




        // Add languages
        this._translateService.addLangs(['en', 'el']);

        // Set the default language
        this._translateService.setDefaultLang('el');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationGreek);

        // Use a language
        this._translateService.use('el');

        this.version = environment.version
        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this._document.body.classList.add('is-mobile');
        }

        this.hotkeysService.add(
            new Hotkey(
                'alt',
                (event: KeyboardEvent): boolean => {

                    const toolbar = {
                        layout: {
                            toolbar: {
                                hidden: !this.hiddenToolbar
                            }
                        }
                    }
                    this._fuseConfigService.setConfig(toolbar);


                    return false; // Prevent bubbling
                },
                ['INPUT', 'SELECT', 'TEXTBOX'],
                'Απόκρυψη μενόυ επικεφαλίδας'
            )
        );

        //update app service worker
        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates
                .pipe(
                    filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
                    map(evt => ({
                        type: 'UPDATE_AVAILABLE',
                        current: evt.currentVersion,
                        available: evt.latestVersion
                    })),

                    takeUntil(this._unsubscribeAll)
                )
                .subscribe((evt) => {
                    console.log('SWUPDATE EVENT', evt);
                    console.log('SWUPDATE EVENT current', evt.current);
                    console.log('SWUPDATE EVENT available', evt.available);
                    console.log('SWUPDATE EVENT current version', evt.current.appData['version']);
                    console.log('SWUPDATE EVENT available version', evt.available.appData['version']);

                    const text = 'Η νέα έκδοση ' + evt.available.appData['version'] + ' είναι διαθέσιμη. Να γινει εγκατάσταση; \n Τρέχουσα εκδοση: ' + evt.current.appData['version'];
                    if (confirm(text)) {
                        window.location.reload();
                    }
                });
        }



    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        //=================================================
        this.getDPI();
        console.log('screen devicePixelRatio', window.devicePixelRatio);

        const cssPR = this._document.body.style.getPropertyValue('--device_pixel_ratio');
        // console.log("cssPR", cssPR);
        this.computeDisplaySize();

        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((value: NavigationEnd) => {
                console.log('AppRouter NavigationEnd:', value.url);
                this._fuseSplashService.hide();
                this.routeState.updateAppUrl(value.urlAfterRedirects);
            });

        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.fuseConfig = config;

            // Boxed
            if (this.fuseConfig.layout.width === 'boxed') {
                this._document.body.classList.add('boxed');
            } else {
                this._document.body.classList.remove('boxed');
            }

            // Color theme - Use normal for loop for IE11 compatibility
            for (let i = 0; i < this._document.body.classList.length; i++) {
                const className = this._document.body.classList[i];

                if (className.startsWith('theme-')) {
                    this._document.body.classList.remove(className);
                }
            }

            this._document.body.classList.add(this.fuseConfig.colorTheme);

            //hidden Toolbar
            this.hiddenToolbar = this.fuseConfig.layout.toolbar.hidden;
        });



    }

    ngAfterViewChecked(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        const cssPR = this._document.body.style.getPropertyValue('--device_pixel_ratio');
        //console.log("cssPR", cssPR);

        if (environment.testing) {
            if (this._document.getElementById('container-2')) {
                this._document.getElementById('container-2').style.background = '#2b2b2b';
            }

            const toolbar = this._document.querySelector('#container-1 > toolbar > .mat-toolbar > div') as HTMLElement
            const content = this._document.querySelector('#container-2 > content') as HTMLElement

            //       console.log("CONTENT", content);
            if (toolbar) {
                toolbar.style.background = 'dimgray';
                toolbar.style.color = 'white';

                const icons = Array.from(toolbar.getElementsByClassName('mat-icon'));

                icons.forEach((icon: HTMLElement) => {
                    icon.style.color = 'white'
                })
            }
            if (content) {
                content.style.background = '#2d323e';

            }
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    getDPI() {
        /**
         * Binary search for a max value without knowing the exact value, only that it can be under or over
         * It dose not test every number but instead looks for 1,2,4,8,16,32,64,128,96,95 to figure out that
         * you thought about #96 from 0-infinity
         *
         * @example findFirstPositive(x => matchMedia(`(max-resolution: ${x}dpi)`).matches)
         * @author Jimmy Wärting
         * @see {@link https://stackoverflow.com/a/35941703/1008999}
         * @param {function} fn       The function to run the test on (should return truthy or falsy values)
         * @param {number}   start=1  Where to start looking from
         * @param {function} _        (private)
         * @returns {number}          Intenger
         */

        const dpi = this.findFirstPositive(x => matchMedia(`(max-resolution: ${x}dpi)`).matches);

        console.log('screen DPI:', dpi);
    }

    findFirstPositive(
        f,
        b = 1,
        d = (e, g, c?) => (g < e ? -1 : 0 < f((c = (e + g) >>> 1)) ? (c === e || 0 >= f(c - 1) ? c : d(e, c - 1)) : d(c + 1, g))
    ) {
        for (; 0 >= f(b); b <<= 1) {
            //
        }
        return d(b >>> 1, b) | 0;
    }

    computeDisplaySize() {
        const devicePixelRatio = window.devicePixelRatio || 1;
        const dpi_x = Math.round(document.getElementById('testdiv').offsetWidth * devicePixelRatio);
        const dpi_y = Math.round(document.getElementById('testdiv').offsetHeight * devicePixelRatio);
        const scale = 1;

        let width_in = screen.width / dpi_x / scale;
        let height_in = screen.height / dpi_y / scale;
        const diagonal_in = Math.round(10 * Math.sqrt(width_in * width_in + height_in * height_in)) / 10;
        const diagonal_cm = Math.round(10 * diagonal_in * 2.54) / 10;

        width_in = Math.round(10 * width_in) / 10;
        height_in = Math.round(10 * height_in) / 10;
        const width_cm = Math.round(10 * width_in * 2.54) / 10;
        const height_cm = Math.round(10 * height_in * 2.54) / 10;

        console.log('screen diagonal in', diagonal_in);
        console.log('screen width', screen);
    }



    /*  
         // Example log event using the rollbar object.
         rollbarInfo() {
             this.rollbar.info('angular test log');
         }
     
         // Example error, which will be reported to rollbar.
         throwError() {
             throw new Error('angular test error');
         }
     
         // Example log event with undefined arg.
         rollbarInfoWithUndefined() {
             this.rollbar.info('angular test log with undefined', undefined);
         }
         // Methods used by rollbar.js tests
     
         publicRollbarInfo() {
             this.ngZone.run(() => this.rollbarInfo());
         }
     
         publicThrowError() {
             this.ngZone.run(() => this.throwError());
         }
     
         publicRollbarInfoWithUndefined() {
             this.ngZone.run(() => this.rollbarInfoWithUndefined());
         }
     
         doCheckCount = 0;
     
         // Used to monitor change detection events
         ngDoCheck() {
             this.doCheckCount += 1;
         } */

}
