import { AbstractControl, FormControl } from "@angular/forms";
import { EMCSHeaderModel } from "./emcs-header.model";

export class EMCSHeaderForm {
    [key: string]: AbstractControl;

    MessageSender = new FormControl();
    MessageRecipient = new FormControl();
    DateOfPreparation = new FormControl();
    TimeOfPreparation = new FormControl();
    MessageIdentifier = new FormControl();
    CorrelationIdentifier = new FormControl();
    MessageRole = new FormControl();

    constructor(value: EMCSHeaderModel) {

        this.MessageSender.setValue(value.MessageSender);
        this.MessageRecipient.setValue(value.MessageRecipient);
        this.DateOfPreparation.setValue(value.DateOfPreparation);
        this.TimeOfPreparation.setValue(value.TimeOfPreparation);
        this.MessageIdentifier.setValue(value.MessageIdentifier);
        this.CorrelationIdentifier.setValue(value.CorrelationIdentifier);
        this.MessageRole.setValue(value.MessageRole);


    }
}
