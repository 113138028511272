export class PacgsModel {
    MarNumOfPacGS21?: string;
    MarNumOfPacGS21LNG?: string;
    KinOfPacGS23?: string;
    NumOfPacGS24?: string;
    NumOfPieGS25?: string;

    constructor(value?) {
        this.MarNumOfPacGS21 = value?.MarNumOfPacGS21 || null;
        this.MarNumOfPacGS21LNG = value?.MarNumOfPacGS21LNG || null;
        this.KinOfPacGS23 = value?.KinOfPacGS23 || null;
        this.NumOfPacGS24 = value?.NumOfPacGS24 || null;
        this.NumOfPieGS25 = value?.NumOfPieGS25 || null;
    }
}
