import { SubmittingOperatorModel } from "app/main/apps/excise/dao/_models/submittingOperator/submittingOperator.model";

export class EF10ABodyModel {

    DeclarationOfExciseTaxesCancellation: DeclarationOfExciseTaxesCancellationType;

    constructor(value?) {

        console.log("EF10ABodyModel value", value)

        this.DeclarationOfExciseTaxesCancellation = value?.DeclarationOfExciseTaxesCancellation || null
    }

}



export class DeclarationOfExciseTaxesCancellationType {



    SubmittingOperator?: SubmittingOperatorModel;
    ExciseTaxesDeclaration: CancelTaxesDeclarationModel;

    constructor(value?) {

        this.SubmittingOperator = value?.SubmittingOperator;
        this.ExciseTaxesDeclaration = value?.ExciseTaxesDeclaration;
    }



}

export class CancelTaxesDeclarationModel {



    DeclarationReferenceNumber?: string;
    CancellationReason: string;

    constructor(value?) {

        this.DeclarationReferenceNumber = value?.DeclarationReferenceNumber;
        this.CancellationReason = value?.CancellationReason;
    }



}
