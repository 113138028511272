import { Injectable } from "@angular/core";
import { FormGroup, FormArray } from "@angular/forms";
import { errorMessages } from "app/model/error-messages";
import { ToastrService } from "ngx-toastr";

/**
 * ErrorMessageConfig Interface
 * @path represents the path to a specific form control, group or array.
 * @formatMessage (optional) customizes how the error message is displayed.
 */
export interface ErrorMessageConfig {
    path: string;
    formatMessage?: (fieldLabel: string, rowIndex: number, errorMessage: string) => string;
}

@Injectable({
    providedIn: 'root',
})
export class AbstractControlErrorService {

    constructor(private toastrService: ToastrService) { }

    /**
     * Recursively navigates through a given FormGroup or FormArray, accumulating all validation errors present.
     * @param formGroup The FormGroup or FormArray to check for errors.
     * @return An object containing all validation errors from the given form group or array.
     */
    getAllErrors(formGroup: FormGroup | FormArray): { [key: string]: any } {
        const errors = {};

        Object.keys(formGroup.controls).forEach(key => {
            const control = formGroup.get(key);

            if (control instanceof FormGroup || control instanceof FormArray) {
                const childErrors = this.getAllErrors(control);
                if (Object.keys(childErrors).length > 0) {
                    errors[key] = childErrors;
                }
            } else if (control.errors) {
                errors[key] = control.errors;
            }
        });

        return errors;
    }

    /**
     * Converts raw error objects into readable messages suitable for end-users. 
     * Messages can be further customized using the provided configurations.
     * @param errors Raw error object typically obtained from getAllErrors.
     * @param path An array representing the path of the form control. Used primarily for nested controls.
     * @param fieldMappings An object mapping form control keys to their human-readable labels.
     * @param config Array of ErrorMessageConfig objects to customize error message formatting.
     * @return An array of user-friendly error messages.
     */
    convertErrorsToFriendlyMessages(
        errors: any,
        path: string[] = [],
        fieldMappings: { [key: string]: string },
        config: ErrorMessageConfig[] = []
    ): string[] {
        const messages: string[] = [];

        const defaultFormatMessage = (arrayName: string, rowIndex: number, errorMessage: string) => {
            return `${arrayName}: Στίχος ${rowIndex}: ${errorMessage}`;
        };

        Object.keys(errors).forEach(key => {
            const value = errors[key];
            const fieldLabel = fieldMappings[key] || key;

            const { required, minlength, max, maxWineGrapes, maxGrapeStems, maxNoOfBoilers, maxDistillHours, maxDistillDays } = value;

            if (value instanceof Object && ![required, minlength, max, maxWineGrapes, maxGrapeStems, maxNoOfBoilers, maxDistillHours, maxDistillDays].some(Boolean)) {
                const newPath = path.concat(key);
                messages.push(...this.convertErrorsToFriendlyMessages(value, newPath, fieldMappings, config));
            } else {
                const currentPath = path.concat(key).join(': ');
                let errorMessage = '';

                Object.keys(value).forEach(errorType => {
                    if (errorMessages[errorType]) {
                        errorMessage = errorMessages[errorType](fieldLabel, value[errorType]);
                    } else {
                        errorMessage = `${fieldLabel} has an error of type ${errorType}.`;
                    }
                });

                const customConfig = config.find(c => path.includes(c.path));
                const formatFunc = customConfig?.formatMessage || defaultFormatMessage;

                if (customConfig) {
                    const rowIndex = parseInt(currentPath.split(':')[1], 10) + 1;
                    const arrayName = fieldMappings[currentPath.split(':')[0]] || currentPath.split(':')[0];
                    errorMessage = formatFunc(arrayName, rowIndex, errorMessage);
                }

                messages.push(errorMessage);
            }
        });

        messages.forEach(message => this.toastrService.error(message, 'Σφάλμα', { timeOut: 0, disableTimeOut: true }));
        return messages;
    }
}
