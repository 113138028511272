import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Form, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DeclarationErrors } from './errors';

export interface AllValidationErrors {
    controlName: string;
    errorName: string;
    errorValue?: any;
    group?: any;
    array?: any;
    grandArray?: string;
    grandGroup?: string;
}

@Component({
    selector: 'app-form-errors',
    templateUrl: './form-errors.component.html',
    styleUrls: ['./form-errors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorsComponent implements OnInit {
    public chooseTitle = 'Έλεγχος Εγκυρότητας Διασάφησης';
    public confirmClass = 'fuse-navy-700';

    declForm: FormGroup;

    private errors: AllValidationErrors[];
    errorMessages: any[] = [];

    private _destroy: Subject<void> = new Subject<void>();

    getErrors: any;
    akisErrors: any[];

    grandParent: string;
    grandGroup: string;


    constructor(
        public dialogRef: MatDialogRef<FormErrorsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FormGroup
    ) {
        this.errors = [];
        this.declForm = data;
        console.log('DCL FORM ON ERRORDIALOG:', this.declForm.value);
    }

    ngOnInit() {
        this.errorMessages = this.calculateErrors(this.declForm);
        console.log('calculateErrors', this.errorMessages);
        //ALTERNATE GET ERROR METHOD
        //  console.log('getAllErrors', this.getAllErrors(this.declForm));

        // console.log("myErrors", this.myErrors());
        // console.log("getGroupError", this.getGroupError(this.declForm));

        //this.akisErrors = this.myErrors();
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._destroy.next();
        this._destroy.complete();
    }

    onCloseConfirm() {
        this.dialogRef.close();
    }

    calculateErrors(form: FormGroup | FormArray, formGroupName?: string, formArrayName?: string) {
        const parent = formGroupName || null;

        Object.keys(form.controls).forEach(controlName => {
            const control = form.get(controlName);
            /*         if(control instanceof FormArray && control.getRawValue().length){
          console.log("ERROR CHECKING CONTROL:", control)
        }
 */
            if (control instanceof FormArray) {
                // console.log("Control FormArray control", control);
                // console.log("Control FormArray controlName", controlName);
                // console.log("Control FormArray parent", parent);
                this.errors = this.errors.concat(this.calculateErrors(control, controlName, parent));

                return;
            } else if (control instanceof FormGroup) {
                // console.log("Control FormGroup control", control);
                // console.log("Control FormGroup controlName", controlName);
                // console.log("Control FormGroup parent", parent);

                if (parent === 'GOOITEGDS' || parent === 'VehicleDetails') {
                    this.grandParent = parent;
                    this.grandGroup = controlName;
                } else if (parent === null) {
                    this.grandParent = null;
                    this.grandGroup = null;
                }
                this.errors = this.errors.concat(this.calculateErrors(control, controlName, parent));

                return;
            }

            const controlErrors: ValidationErrors = control.errors;

            if (controlErrors !== null) {
                console.log("controlErrors", controlErrors)

                Object.keys(controlErrors).forEach(keyError => {
                    console.log("KeyError", keyError)
                    console.log(formArrayName);
                    this.errors.push({
                        controlName: controlName,
                        errorName: keyError,
                        errorValue: controlErrors[keyError],
                        group: formGroupName || null,
                        array: formArrayName || null,
                        grandArray: this.grandParent || null,
                        grandGroup: this.grandGroup || null
                    });
                });
            }
        });
        //console.log("ERRORS before filter", this.errors);
        // This removes duplicates
        this.errors = this.errors.filter(
            (error, index, self) =>
                self.findIndex(
                    t =>
                        t.controlName === error.controlName &&
                        t.controlName === error.controlName &&
                        t.grandArray === error.grandArray &&
                        t.grandGroup === error.grandGroup &&
                        t.group === error.group &&
                        t.array === error.array
                ) === index
        );
        // console.log("ERRORS after filter", this.errors);

        return this.errors;
    }

    myErrors(): { [key: string]: any }[] | null {
        const form = this.declForm;
        const allErrors: { [key: string]: any }[] = [];

        const errorFields: [] = [];

        Object.keys(form.controls).forEach((groupName: string) => {
            let groupError: AllValidationErrors[] = [];

            const group = form.get(groupName);

            if (group instanceof FormGroup) {
                console.log('GroupName', groupName);

                groupError = this.getGroupError(group);

                if (groupError.length) {
                    allErrors.push({ [groupName]: groupError });
                }
            } else if (group instanceof FormArray) {
                const arrayName = groupName;

                const arrayErrors: { [key: string]: any }[] = [];

                let groupError: AllValidationErrors[] = [];

                Object.keys(group.controls).forEach((groupName: string) => {
                    console.log('ArrayName: ' + arrayName + ', GroupName: ' + groupName);
                    const arrayGroup = group.get(groupName) as FormGroup;

                    groupError = this.getGroupError(arrayGroup);

                    console.log('ArrayName: ' + arrayName + ', GroupName: ' + groupName);
                    console.log('GroupError: ' + groupError);

                    if (groupError.length) {
                        //arrayErrors.push({groupError})
                        arrayErrors.push({ [groupName]: groupError });
                    }
                });
                if (arrayErrors.length) {
                    allErrors.push({ [arrayName]: arrayErrors });
                }
            }
        });

        console.log('allErrors:', allErrors);
        return allErrors;
    }

    getGroupError(group: FormGroup): AllValidationErrors[] {
        const errors: AllValidationErrors[] = [];

        Object.keys(group.controls).forEach(field => {
            const formControl = group.get(field) as FormControl;

            const controlErrors = formControl.errors;

            if (controlErrors !== null) {
                Object.keys(controlErrors).forEach(keyError => {
                    errors.push({
                        controlName: field,
                        errorName: keyError,
                        errorValue: controlErrors[keyError]
                    });
                });
            }
        });

        return errors;
    }

    getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
        this.errors = [];
        let hasError = false;

        const result = Object.keys(form.controls).reduce((acc, key) => {
            const control = form.get(key);

            let errors: ValidationErrors;

            if (control instanceof FormGroup || control instanceof FormArray) {
                errors = this.getAllErrors(control);
            } else {
                errors = control.errors;
            }

            if (errors) {
                //   console.log("ERROR acc",acc)
                //   console.log("ERROR key",key)
                //   console.log("ERROR errors",errors)

                if (control instanceof FormArray) {
                    acc[key] = [errors];
                } else {
                    acc[key] = [errors];
                }
                hasError = true;

                /*     {

              "HEAHEA":
              { "CouOfDesCodHEA30": { "required": true },
                "CouOfDisCodHEA55": { "required": true },
                "NatOfMeaOfTraCroHEA87": { "required": true }
              },

              "GOOITEGDS": [
                         { "0": { "StaValAmoGDI1": { "required": true } },
                           "1": { "StaValAmoGDI1": { "required": true } }
                         } ]
            }
            */
            }

            return acc;
        }, {} as { [key: string]: any });

        //console.log("Errors Result", result)
        this.getErrors = hasError ? result : null;

        // console.log("getErrors",this.getErrors)
        return hasError ? result : null;
    }
}
