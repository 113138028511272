export class TraconceModel {
    NamCE17?: string;
    StrAndNumCE122?: string;
    PosCodCE123?: string;
    CitCE124?: string;
    CouCE125?: string;
    NADLNGCE?: string;
    TINCE159?: string;
    SpeMenCE1003?: string; //On Export Only
    TinTypCE1009?: string; //On NCTTS Only
    ConsigneeObject?: {};

    constructor(value?) {
        if (value) {
            this.NamCE17 = value.NamCE17;
            this.StrAndNumCE122 = value.StrAndNumCE122;
            this.PosCodCE123 = value.PosCodCE123;
            this.CitCE124 = value.CitCE124;
            this.CouCE125 = value.CouCE125;
            this.NADLNGCE = value.NADLNGCE;
            this.TINCE159 = value.TINCE159;
            this.SpeMenCE1003 = value.SpeMenCE1003;
            this.TinTypCE1009 = value.TinTypCE1009;
            this.ConsigneeObject = value.ConsigneeObject;
        }
    }
}
