import { FormControl } from '@angular/forms';
import { CtlCl1Model } from './ctlcl1.model';

export class CtlCl1Form {
    [key: string]: FormControl;

    AmeTypFlaCL628 = new FormControl();

    constructor(value: CtlCl1Model) {
        this.AmeTypFlaCL628.setValue(value.AmeTypFlaCL628);
    }
}
