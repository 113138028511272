import { FormControl, Validators } from '@angular/forms';
import { Statrep385Model } from './statrep385.model';

export class Statrep385Form {
    [key: string]: FormControl;

    RepStatCodSTATREP386 = new FormControl();

    constructor(value: Statrep385Model) {
        this.RepStatCodSTATREP386.setValue(value.RepStatCodSTATREP386);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
