<div class="wv-business-switcher__panel__header">
    <div class="wv-business-switcher__toggle__logo">
        <span class="wv-logo--mark">TradeXΤ</span>
    </div>
    <div class="wv-business-switcher__panel__header__title fs-unmask">Ο λογαριασμός σας</div>
    <div class="wv-business-switcher__panel__header__close wv-close--large"></div>
</div>
<div class="wv-business-switcher__panel__body">
    <div class="wv-business-switcher__panel__body__section">
        <ul class="wv-business-switcher__business-menu">
            <li class="wv-business-switcher__business-menu__item" *ngFor="let office of offices"
                [class.is-current]="office?.id===currentOffice?.id">
                <a class="wv-business-switcher__business-menu__item__link fs-exclude" style="color:unset"
                    (click)="changeOffice(office)"><span
                        class="wv-business-switcher__business-menu__item__label">{{office.office_name}}</span>
                    <svg *ngIf="office?.id===currentOffice?.id" class="wv-svg-icon" aria-labelledby="check-title"
                        aria-hidden="false">
                        <title id="check-title">check icon</title>
                        <use xlink:href="#check"></use>
                    </svg>

                </a>
            </li>


        </ul>
        <!-- href="https://api.waveapps.com/go/create_business/583b4ca2-f729-4175-8fa7-c0a0c8857f77/" -->
        <div class="wv-business-switcher__business-menu__add fs-unmask"><svg
                class="wv-svg-icon--large wv-business-switcher__context-menu__item__icon"
                aria-labelledby="add--large-title" aria-hidden="false">
                <title id="add--large-title">add large icon</title>
                <use xlink:href="#add--large"></use>
            </svg><a class="wv-text wv-text--small wv-text--link">Δημιουργία νεόυ γραφείου</a></div>
    </div>
    <div class="wv-business-switcher__panel__body__section">
        <div class="wv-text wv-text--small fs-unmask my-6">Έχετε συνδεθεί ως <strong
                class="wv-business-switcher__user-email fs-exclude">{{(user$| async)?.emails[0].email}}</strong>.</div>
        <ul class="wv-business-switcher__context-menu fs-unmask">
            <li class="wv-business-switcher__context-menu__item"><svg
                    class="wv-svg-icon--large wv-business-switcher__context-menu__item__icon"
                    aria-labelledby="profile--large-title" aria-hidden="false">
                    <title id="profile--large-title">profile large icon</title>
                    <use xlink:href="#profile--large"></use>
                </svg><a class="wv-business-switcher__context-menu__item__link" (click)="myprofile()"><span
                        class="wv-business-switcher__context-menu__item__label">Διαχείρηση λογαριασμού</span></a>
            </li>
            <li class="wv-business-switcher__context-menu__item"><svg
                    class="wv-svg-icon--large wv-business-switcher__context-menu__item__icon"
                    aria-labelledby="logout--large-title" aria-hidden="false">
                    <title id="logout--large-title">logout large icon</title>
                    <use xlink:href="#logout--large"></use>
                </svg><a class="wv-business-switcher__context-menu__item__link" (click)="logout()"><span
                        class="wv-business-switcher__context-menu__item__label">Έξοδος</span></a></li>
        </ul>
    </div>
    <!-- href="https://www.waveapps.com/legal/terms-of-use" -->
    <!-- href="https://www.waveapps.com/legal/privacy-policy" -->
    <div class="wv-business-switcher__panel__body__section">
        <p class="wv-text wv-text--body fs-unmask fs-exclude"><a class="wv-business-switcher__legal__link"
                target="_blank" rel="noopener noreferrer">'Οροι</a><span
                class="wv-business-switcher__legal__separator">•</span><a class="wv-business-switcher__legal__link"
                target="_blank" rel="noopener noreferrer">Απόρρητο</a></p>
    </div>
</div>

<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <defs>
        <symbol viewBox="0 0 20 20" id="check" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 14.586L17.293 4.293a1 1 0 0 1 1.414 1.414l-11 11a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L7 14.586z">
            </path>
        </symbol>

        <symbol viewBox="0 0 26 26" id="add--large" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 24C6.925 24 2 19.075 2 13S6.925 2 13 2s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 1 0 0-18 9 9 0 0 0 0 18z">
            </path>
            <path d="M12 8a1 1 0 0 1 2 0v10a1 1 0 0 1-2 0V8z"></path>
            <path d="M8 14a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2H8z"></path>
        </symbol>

        <symbol viewBox="0 0 26 26" id="profile--large" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 24C6.925 24 2 19.075 2 13S6.925 2 13 2s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 1 0 0-18 9 9 0 0 0 0 18z">
            </path>
            <path
                d="M13 14c-2.122 0-4-1.878-4-4s1.878-4 4-4 4 1.878 4 4-1.878 4-4 4zm0-2c1.017 0 2-.983 2-2s-.983-2-2-2-2 .983-2 2 .983 2 2 2zM6.894 20.447l-1.788-.894C6.615 16.535 9.3 15 13 15s6.385 1.535 7.894 4.553l-1.788.894C17.948 18.132 15.967 17 13 17s-4.948 1.132-6.106 3.447z">
            </path>
        </symbol>
        <symbol viewBox="0 0 26 26" id="logout--large" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3 21a1 1 0 0 1 0 2H6.8A2.8 2.8 0 0 1 4 20.2V5.8A2.8 2.8 0 0 1 6.8 3h4.5a1 1 0 1 1 0 2H6.8a.8.8 0 0 0-.8.8v14.4a.8.8 0 0 0 .8.8h4.5zM17.35 10.76a1 1 0 1 1 1.3-1.52l3.6 3.086a1 1 0 0 1 0 1.519l-3.6 3.086a1 1 0 0 1-1.3-1.519l2.713-2.326-2.714-2.327z">
            </path>
            <path d="M20.8 12a1 1 0 0 1 0 2H10a1 1 0 1 1 0-2h10.8z"></path>
        </symbol>
    </defs>
</svg>