export class DEFKContactDetailsModel {

    LastNames: string;
    Forenames: string;
    NameOfFather: string;
    Address: string;
    Country: string;

    constructor(value?) {

        this.LastNames = value?.LastNames || null;
        this.Forenames = value?.Forenames || null;
        this.NameOfFather = value?.NameOfFather || null;
        this.Address = value?.Address || null;
        this.Country = value?.Country || null;
    }

}
