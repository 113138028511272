import { Injectable } from '@angular/core';
import { ApiNodeService } from 'app/services/api.node.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { environment } from 'environments/environment';
import { Observable, merge, of, fromEvent, Subject, BehaviorSubject } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

@Injectable({
    providedIn: 'root'
})
export class ActivityLedsService {
    // serverOnline: boolean;
    counter = 0;
    networkOnline$: Observable<boolean>;
    serverOnline$: Observable<boolean>;
    icisOnline$: Observable<boolean>;

    myWebSocket: WebSocketSubject<any> = webSocket({
        url: environment.WS_URL,
        deserializer: msg => msg
    });

    private _destroy: Subject<void> = new Subject<void>();

    private networkOnline: BehaviorSubject<boolean>;
    private serverOnline: BehaviorSubject<boolean>;
    private icisOnline: BehaviorSubject<boolean>;

    constructor(private apiNodeService: ApiNodeService, private mainBroadcaster: MainBroadcasterService) {
        this.networkOnline = new BehaviorSubject(false);
        this.serverOnline = new BehaviorSubject(false);
        this.icisOnline = new BehaviorSubject(false);

        this.networkOnline$ = this.networkOnline.asObservable();
        this.serverOnline$ = this.serverOnline.asObservable();
        this.icisOnline$ = this.icisOnline.asObservable();

        //Code from
        // https://www.freecodecamp.org/news/how-to-check-internet-connection-status-with-javascript/
        setInterval(async () => {
            const result = await this.checkOnlineStatus();
            this.networkOnline.next(result);
        }, 10000); // probably too often, try 30000 for every 30 seconds

        window.addEventListener('load', async event => {
            this.networkOnline.next(await this.checkOnlineStatus());
        });

        /*     this.networkOnline$ = merge(
            of(navigator.onLine),
            fromEvent(window, "online").pipe(mapTo(true)),
            fromEvent(window, "offline").pipe(mapTo(false))
        );
 */
    }
    webSocketConnect() {
        this.myWebSocket.pipe(takeUntil(this._destroy)).subscribe({
            next: (msg: MessageEvent) => {
                // this.message = msg.data[0].message;
                const data = JSON.parse(msg.data);
                this.serverOnline.next(true);

                if (data.serverStatus === 'online') {
                    this.icisOnline.next(true);

                    this.counter = 0;
                } else if (data.serverStatus === 'offline') {
                    this.icisOnline.next(false);

                }
            },
            // Called whenever there is a message from the server
            error: (err: unknown) => {
                this.serverOnline.next(false);

                this.mainBroadcaster.updateIcisUser({ isLoggedIn: false, user: { name: null, afm: null } })
                //This is a "kind of" auto reconnect Try 1000 times
                if (this.counter < 1000) {
                    setTimeout(() => {
                        this.counter++;

                        this.webSocketConnect();
                    }, 5000);
                } else {
                    console.log('WEBSOCKET FINAL ERROR', err);
                }
            },
            // Called if WebSocket API signals some kind of error
            complete: () => console.log('WEBSOCKET complete')
            // Called when connection is closed (for whatever reason)
        });
    }



    async checkOnlineStatus(): Promise<boolean> {
        if (!navigator.onLine) {
            return false;  // Fastest way to detect obvious offline status
        }

        // Check with an external service like Google first, as it's highly reliable
        /*    if (!(await this.fetchResource('https://api.ipify.org?format=json'))) {
               return false;  // User's internet is likely offline
           } */

        // If Google is reachable, check your own server's status
        return await this.fetchResource('assets/img/pixel.png');
    }

    async fetchResource(url: string): Promise<boolean> {
        try {
            const response = await fetch(url, {
                method: 'HEAD',
                cache: 'no-store',
                headers: new Headers({ 'X-Bypass-Service-Worker': 'true' })
            });
            return response.status >= 200 && response.status < 300;
        } catch (err) {
            return false;
        }
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }
}
