import { FormControl, Validators } from '@angular/forms';
import { Rel800Model } from './rel800.model';

export class Rel800Form {
    [key: string]: FormControl;
    RelRelCod02 = new FormControl();

    constructor(value: Rel800Model) {
        this.RelRelCod02.setValue(value.RelRelCod02);
    }
}
