export class FueCon300Model {
    RelCodFUECON2?: string;
    QuaFUECON3?: string;
    NatProCodFUECON4?: string;
    InvNumFUECON123?: string;

    constructor(value?) {
        this.RelCodFUECON2 = value?.RelCodFUECON2 || null;
        this.QuaFUECON3 = value?.QuaFUECON3 || null;
        this.NatProCodFUECON4 = value?.NatProCodFUECON4 || null;
        this.InvNumFUECON123 = value?.InvNumFUECON123 || null;
    }
}
