import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseBuildingComponent } from './base.component';



@NgModule({
    declarations: [BaseBuildingComponent],
    imports: [
        CommonModule
    ],
    exports: [BaseBuildingComponent]
})
export class BaseComponentModule { }
