<div id="printableApplication" class="app_Grid" [ngClass]="{app_Grid_chrome: browser.isChrome}">
    <ng-container *ngIf="dilosiForm$ | async as dilosiForm">

        <div class="app_img">
            <distillation-licence-app-svg></distillation-licence-app-svg>
        </div>

        <div id="period" class="declDiv">
            {{ dilosiForm.get('period').value}}
        </div>
        <div id="defkMRN" class="declDiv">
            {{ dilosiForm.get('defkMRN').value}}
        </div>

        <div id="customsName" class="declDiv">
            {{ dilosiForm.get('customsName').value}}
        </div>


        <div id="producerName" class="declDiv">
            {{ producerDetails.get('producerName').value }}
        </div>
        <div id="producerId" class="declDiv">
            {{ producerDetails.get('producerId').value }}
        </div>
        <div id="producerStreet" class="declDiv">
            {{ producerDetails.get('producerStreet').value }}
        </div>
        <div id="producerStreetNumber" class="declDiv">
            {{producerDetails.get('producerStreetNumber').value}}
        </div>
        <div id="producerPhone" class="declDiv">
            {{producerDetails.get('producerPhone').value}}
        </div>
        <div id="producerEmail" class="declDiv">
            {{producerDetails.get('producerEmail').value}}
        </div>


        <div id="startHours" class="declDiv">
            {{ dilosiForm.get('startHours').value}}
        </div>
        <div id="startDate" class="declDiv">
            {{ dilosiForm.get('startDate').value}}
        </div>

        <div id="endHours" class="declDiv">
            {{ dilosiForm.get('endHours').value}}
        </div>
        <div id="endDate" class="declDiv">
            {{ dilosiForm.get('endDate').value}}
        </div>


        <div id="ownStems-quantity" class="declDiv">
            {{ ownStems.get('quantity').value}}
        </div>
        <div id="ownStems-litresPureAlcohol" class="declDiv">
            {{ ownStems.get('litresPureAlcohol').value}}
        </div>
        <div id="ownStems-litresGross" class="declDiv">
            {{ ownStems.get('litresGross').value}}
        </div>
        <div id="ownStems-alcoholicTitle" class="declDiv">
            {{ ownStems.get('alcoholicTitle').value}}
        </div>



        <div id="wineryReturnedWith" class="declDiv">
            {{ winery.get('wineryReturnedWith').value}}
        </div>
        <div id="wineryName" class="declDiv">
            {{ winery.get('wineryName').value}}
        </div>

        <div id="wineryAfm" class="declDiv">
            {{ winery.get('wineryAfm').value}}
        </div>
        <div id="wineryDocumentNumber" class="declDiv">
            {{ winery.get('wineryDocumentNumber').value}}
        </div>

        <div id="returnedStems-quantity" class="declDiv">
            {{ returnedStems.get('quantity').value}}
        </div>
        <div id="returnedStems-litresPureAlcohol" class="declDiv">
            {{ returnedStems.get('litresPureAlcohol').value}}
        </div>
        <div id="returnedStems-litresGross" class="declDiv">
            {{ returnedStems.get('litresGross').value}}
        </div>
        <div id="returnedStems-alcoholicTitle" class="declDiv">
            {{ returnedStems.get('alcoholicTitle').value}}
        </div>


        <div id="otherRawMaterials" class="declDiv">
            {{ dilosiForm.get('otherRawMaterials').value}}
        </div>

        <div id="grapes-quantity" class="declDiv">
            {{ grapes.get('quantity').value}}
        </div>
        <div id="grapes-litresPureAlcohol" class="declDiv">
            {{ grapes.get('litresPureAlcohol').value}}
        </div>
        <div id="grapes-litresGross" class="declDiv">
            {{ grapes.get('litresGross').value}}
        </div>
        <div id="grapes-alcoholicTitle" class="declDiv">
            {{ grapes.get('alcoholicTitle').value}}
        </div>

        <div class="second-page" [ngClass]="{'second-page-firefox': browser.isFirefox}">

            <div id="harvestDeclaration" class="declDiv">
                {{ dilosiForm.get('harvestDeclaration').value}}
            </div>
            <div id="previousQuantityLpa" class="declDiv">
                {{ dilosiForm.get('previousQuantityLpa').value}}
            </div>
            <div id="previousQuantityGross" class="declDiv">
                {{ dilosiForm.get('previousQuantityGross').value}}
            </div>
            <div id="previousQuantityAlcoholTitle" class="declDiv">
                {{ dilosiForm.get('previousQuantityAlcoholTitle').value}}
            </div>
            <div id="previousQuantityLocation" class="declDiv">
                {{ dilosiForm.get('previousQuantityLocation').value}}
            </div>
            <div id="previousQuantityLpaRights" class="declDiv">
                {{ dilosiForm.get('previousQuantityLpaRights').value}}
            </div>
            <div id="previousQuantityGrossRights" class="declDiv">
                {{ dilosiForm.get('previousQuantityGrossRights').value}}
            </div>
            <div id="previousQuantityAlcoholTitleRights" class="declDiv">
                {{ dilosiForm.get('previousQuantityAlcoholTitleRights').value}}
            </div>
            <div id="previousQuantityLocationRights" class="declDiv">
                {{ dilosiForm.get('previousQuantityLocationRights').value}}
            </div>


            <div id="boilerNumber" class="declDiv">
                {{ boilerInfo.get('boilerNumber').value}}
            </div>
            <div id="boilerCapacity" class="declDiv">
                {{ boilerInfo.get('boilerCapacity').value}}
            </div>
            <div id="boilerOwnerName" class="declDiv">
                {{ boilerInfo.get('boilerOwnerName').value}}
            </div>
            <div id="boilerOwnerId" class="declDiv">
                {{ boilerInfo.get('boilerOwnerId').value}}
            </div>
            <div id="boilerRegistrationNumber" class="declDiv">
                {{ boilerInfo.get('boilerRegistrationNumber').value}}
            </div>
            <div id="boilerGeoLocation" class="declDiv">
                {{ boilerInfo.get('boilerGeoLocation').value}}
            </div>


            <div id="unsealDate" class="declDiv">
                {{ dilosiForm.get('unsealDate').value}}
            </div>
            <div id="date" class="declDiv">
                {{ dilosiForm.get('date').value}}
            </div>
        </div>

    </ng-container>

</div>