export class PosPayGI400Model {
    PosEndDatPOSPAYGI401?: string;
    PosEndFlaPOSPAYGI402?: string;
    PosPayCodPOSPAYGI404?: string;
    PosSusDatPOSPAYGI405?: string;

    constructor(value?) {
        this.PosEndDatPOSPAYGI401 = value?.PosEndDatPOSPAYGI401 || null;
        this.PosEndFlaPOSPAYGI402 = value?.PosEndFlaPOSPAYGI402 || null;
        this.PosPayCodPOSPAYGI404 = value?.PosPayCodPOSPAYGI404 || null;
        this.PosSusDatPOSPAYGI405 = value?.PosSusDatPOSPAYGI405 || null;
    }
}
