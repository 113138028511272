import { DEFKContactDetailsModel } from "../contactDetails/contactDetails.model";

export class ComplementaryConsigneesModel {
    ComplementaryConsigneeIdentificationType?: string;
    ComplementaryConsigneeIdentification?: string;
    ExemptionBeneficiaryFlag?: string;
    OwnershipPercentage?: string;
    ContactDetails?: DEFKContactDetailsModel;


    constructor(value?) {
        this.ComplementaryConsigneeIdentificationType = value?.ComplementaryConsigneeIdentificationType || null;
        this.ComplementaryConsigneeIdentification = value?.ComplementaryConsigneeIdentification || null;
        this.ExemptionBeneficiaryFlag = value?.ExemptionBeneficiaryFlag || null;
        this.OwnershipPercentage = value?.OwnershipPercentage || null;
        this.ContactDetails = value?.ContactDetails || null;

    }
}
