import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VehiclesForm } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles-form.model';
import { CarPriceRange } from 'app/main/apps/excise-helper-tables/vehicles/_models/vehicles.interfaces';
import { VehiclesQueriesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicle-queries.service';
import { VehiclesService } from 'app/main/apps/excise-helper-tables/vehicles/vehicles.service';
import { Observable, tap, switchMap, map } from 'rxjs';

@Component({
    selector: 'vehicles-general-calculation-data',
    templateUrl: './vehicles-general-calculation-data.component.html',
    styleUrls: ['./vehicles-general-calculation-data.component.scss']
})
export class VehiclesGeneralCalculationDataComponent implements OnInit {

    @Input() vehiclesForm: FormGroup;
    vehiclesForm$: Observable<FormGroup<VehiclesForm>>
    vehicleTypeGeneral$: Observable<string>;
    priceRanges$: Observable<CarPriceRange[]>
    priceCategories: string[] = []
    title1 = 'Λ.Τ.Π.Φ.'
    constructor(private vehiclesService: VehiclesService, private vehiclesQueriesService: VehiclesQueriesService) {



    }

    ngOnInit() {

        this.vehicleTypeGeneral$ = this.vehiclesService.vehicleTypeGeneral$;

        this.vehiclesForm$ = this.vehiclesService.vehiclesForm$.pipe(

            switchMap((form) => form.valueChanges.pipe(map(value => { return { form, value } }))),
            tap((value) => {

                const vehicleType = value.form.get('vehicleType').value;
                if (vehicleType === '2') {//Fortigo >3.5tn
                    this.title1 = 'Συνολική Τιμή Αγοράς (CIF)'
                }
                else {
                    this.title1 = 'Λ.Τ.Π.Φ.'
                }
                console.log('CarsCalculationDataComponent valueChanges', value.value)
            }),
            map(value => value.form),

        )

    }


}
