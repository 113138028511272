<div>
    <ul *ngIf="info?.length > 0" class="messagesListClass">
        <li *ngFor="let i of info" class="globalInfoClass">
            <img src="../assets/images/general/information.gif" alt="info" />&nbsp;{{ i }}
        </li>
    </ul>

    <ul *ngIf="errors?.length > 0" class="messagesListClass">
        <li *ngFor="let e of errors" class="globalErrorClass">
            <img src="../assets/images/general/error.gif" alt="error" />&nbsp;{{
            e
            }}
        </li>
    </ul>
</div>

<div class="tariff-results-container" *ngIf="duties">
    <app-tariff-results-table [tableRows]="6" [results]="duties.results" [totals]="duties.totals"
        tableTitle="Υπολογισμός δασμών"></app-tariff-results-table>
</div>
<div class="tariff-results-container" *ngIf="uvDuties">
    <app-tariff-results-table [tableRows]="4" [results]="uvDuties.results" [totals]="uvDuties.totals"
        tableTitle="Υπολογισμός τιμής μονάδας"></app-tariff-results-table>
</div>

<div class="tariff-results-container" *ngIf="uvSecurity">
    <app-tariff-results-table [tableRows]="6" [results]="uvSecurity.results" [totals]="uvSecurity.totals"
        tableTitle="Υπολογισμός εγγύησης /Δασμός τρίτης χώρας"></app-tariff-results-table>
</div>

<div style="font-weight: 600">
    <h4 style="font-weight: 600;text-decoration:underline;margin-bottom: 0;">Σημαντική Σημείωση</h4>

    <span>Σε περίπτωση διαφοράς μεταξύ μέτρων του TARIC και αυτών που προέρχονται από την Ε.Ε. ή την εθνική νομοθεσία,
        ισχύουν αυτά της νομοθεσίας.</span>
</div>