import { AbstractControl, FormControl } from "@angular/forms";
import { ClearingAgentType } from "./clearing-agent.model";

export class ClearingAgentForm {
    [key: string]: AbstractControl;

    ClearingAgentType: FormControl;
    ClearingAgentEORI: FormControl;
    ClearingAgentAfm: FormControl;
    SpecialReferenceCode: FormControl;
    DeclarantObject: FormControl;

    /*

   */

    constructor(value: ClearingAgentType) {

        this.ClearingAgentType = new FormControl(value.ClearingAgentType);
        this.ClearingAgentEORI = new FormControl(value.ClearingAgentEORI);
        this.ClearingAgentAfm = new FormControl(value.ClearingAgentAfm);
        this.SpecialReferenceCode = new FormControl(value.SpecialReferenceCode);
        this.DeclarantObject = new FormControl(value.DeclarantObject);

    }
}
