import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    OnDestroy,
    Optional,
    Self,
    ViewChild,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    HostListener
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ApiService, ApiEndPoints } from 'app/services/api.service';
import { genericItem } from 'app/model/api-model';
import { FormFieldFunctions } from '../form-field-functions.service';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';

export interface ConfigValues {
    output: string[];
    menuTitles: string[];
    menuOptions: string[];
    filterBy: string[];
}

@Component({
    selector: 'language-select',
    templateUrl: './language-select.component.html',
    providers: [{ provide: MatFormFieldControl, useExisting: LanguageSelectComponent }],
    host: {
        '(focusout)': 'onTouched()'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor, MatFormFieldControl<Object>, OnDestroy {
    @ViewChild(MatAutocompleteTrigger)
    trigger: MatAutocompleteTrigger;

    //  @ViewChild("focusMe", { static: false }) _focusMe: ElementRef;
    @ViewChild('focusMe', { read: ElementRef })
    languageRef: ElementRef<HTMLInputElement>;

    static nextId = 0;

    private _config: ConfigValues;
    private options: genericItem[] = [];
    private _disabled = false;
    private _focused = false;
    private _autofocus = false;
    private _tabindex = 0;
    private _placeholder = '';
    private _required = false;
    private _destroy: Subject<void> = new Subject<void>();
    private _timeout;

    lang: string;
    stateChanges: Subject<void> = new Subject<void>();
    replacedItems: any[] = [];
    filteredOptions: Observable<any[]>;
    selectedOption: Object = {};
    subscription: any;
    outputValue: string;
    menuTitle1: string;
    menuTitle2: string;
    menuOption1: string;
    menuOption2: string;

    @Input() _value;
    onChange: any = () => { };
    onTouched: any = () => { };

    get value() {
        return this._value;
    }
    set value(val: string | genericItem) {
        this._value = val;
        this.onChange(val);
        this.stateChanges.next();
    }

    /*     @Input()
    get options(): Object[] {
        return this._options;
    }
    set options(obj: Object[]) {
        this._options = obj;
        this.stateChanges.next();
    } */
    @Input()
    get config(): ConfigValues {
        return this._config;
    }
    set config(obj: ConfigValues) {
        this._config = obj;
        this.stateChanges.next();
    }
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    @Input()
    get tabindex(): number {
        return this._tabindex;
    }
    set tabindex(value: number) {
        this._tabindex = value;
        this.stateChanges.next();
    }

    @Input()
    get autofocus(): boolean {
        return this._autofocus;
    }
    set autofocus(value: boolean) {
        this._autofocus = value;
        this.stateChanges.next();
    }
    @HostBinding('attr.aria-describedby')
    describedBy = '';

    @HostBinding()
    id = `language-select-${++LanguageSelectComponent.nextId}`;

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    controlType = 'custom-language-select';

    get empty(): boolean {
        const n = this.value;
        return !n;
    }

    get errorState(): boolean {
        return this.ngControl.errors !== null && !!this.ngControl.touched;
    }
    get focused(): boolean {
        return this._focused;
    }
    set focused(value: boolean) {
        this._focused = value;
        this.stateChanges.next();
    }

    @Output() blur: EventEmitter<any> = new EventEmitter();

    @Output() focus: EventEmitter<any> = new EventEmitter(); //

    @HostListener('keydown', ['$event'])
    onKeyDown(e) {
        if (!this.trigger.autocompleteDisabled === false) {
            this.trigger.autocompleteDisabled = false;
            console.log('autocompleteDisabled: ', this.trigger.autocompleteDisabled);
        }

        if (e.key === 'ContextMenu') {
            e.stopImmediatePropagation();
            this.trigger._onChange('');
            this.trigger.openPanel();
        }
    }
    //..================================

    constructor(
        private focusMonitor: FocusMonitor,
        private elementRef: ElementRef<HTMLElement>,
        private formFieldFunctions: FormFieldFunctions,
        @Optional() @Self() public ngControl: NgControl,
        private dbQuery: ApiService,
        private cd: ChangeDetectorRef,
        private mainBroadcaster: MainBroadcasterService,
    ) {
        if (ngControl) {
            // Set the value accessor directly (instead of providing
            // NG_VALUE_ACCESSOR) to avoid running into a circular import
            this.ngControl.valueAccessor = this;
            ngControl.valueAccessor = this;
        }

        this.mainBroadcaster.declarationSettings$.pipe(takeUntil(this._destroy)).subscribe((settings: DeclarationSettingsModel) => {
            this.lang = settings.language;
        })
    }

    ngOnInit() {
        this.config = {
            output: ['code'],
            menuOptions: ['code', 'desc'],
            menuTitles: [],
            filterBy: ['code', 'desc']
        };

        // this.config = new AutoCompleteConfig(this.options);
        this.outputValue = this.config.output[0];
        this.menuTitle1 = this.config.menuTitles[0];
        this.menuTitle2 = this.config.menuTitles[1];
        this.menuOption1 = this.config.menuOptions[0];
        this.menuOption2 = this.config.menuOptions[1];

        /*         this.replacedItems = this.options.map(option => Object.fromEntries(

                                               Object.values(option).map((val, i) => ['key' + (i), val])
         ));

        */

        this.dbQuery
            .get_options(ApiEndPoints.languages)
            .pipe(takeUntil(this._destroy))
            .subscribe((data: genericItem[]) => {
                this.options = data;
            });

        this.filteredOptions = this.ngControl.valueChanges.pipe(
            startWith(''),
            map(option => (option ? this.filterOptions(option) : this.options.slice()))
        );
    }

    ngAfterViewInit(): void {
        this.focusMonitor.monitor(this.elementRef.nativeElement, true).subscribe(focusOrigin => {
            this.focused = !!focusOrigin;
        });
        //When the options panel closes...
        this.panelClosingSubscription();
        this.trigger.autocompleteDisabled = true;
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
        this.stateChanges.next();
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
        this.subscription.unsubscribe();
    }

    //================================
    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.focusMonitor.focusVia(this.languageRef.nativeElement, 'mouse');
        }
    }

    writeValue(value: Object): void {
        this._value = value ? value : null;
    }

    registerOnChange(fn: (_: string | null) => void): void {
        this.onChange = (value: string | null) => {
            fn(value === '' ? null : value);
        };
    }


    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cd.detectChanges();
    }

    private panelClosingSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.trigger.panelClosingActions.pipe(takeUntil(this._destroy)).subscribe({
            next: (closingActions: MatOptionSelectionChange) => {
                if (!closingActions) {
                    //onBlur (closingActions==null) user must type a value for the option to be selected    
                    if (this.value && this.value !== '' && this.filterOptions(this.value).length > 0) {
                        this.value = this.trigger.activeOption.value;
                        this.onChange(this.trigger.activeOption.value)
                        this.selectedOption = this.options.filter(e => e.code === this.trigger.activeOption.value)[0]
                    }
                    else {
                        this.value = null;
                        this.onChange(null);
                        this.selectedOption = null
                    }
                    //  console.log("ACMP 1. onBlur value", this.value);
                    this.panelClosingSubscription();

                }
                else {
                    //on user select (onEnter/onClick)
                    if (this.trigger.activeOption && closingActions.isUserInput) {
                        //   console.log("ACMP 2 on Panel Interaction isUserInput", closingActions.isUserInput);
                        this.value = closingActions.source.value;
                        this.onChange(closingActions.source.value)
                        this.selectedOption = this.options.filter(e => e.code === closingActions.source.value)[0]
                        //   console.log("ACMP 2. on Panel Interaction value", this.value);
                    }
                }
            },
            error: (e: unknown) => console.log('error')
        });
    }

    filterOptions(name: string | genericItem) {
        const key0 = this.config.filterBy[0];
        const key1 = this.config.filterBy[1];
        //filter by both keys
        const filtered = this.formFieldFunctions.autocompleteFilter(name, this.options, key0, key1);

        return filtered;
    }
    onEscape(event) {
        this.value = this.lang;
        if (event.target.nextElementSibling !== null) {
            event.target.nextElementSibling.focus();
        } else {
            event.target.blur();
        }
    }
    //open panel with delay
    onFocus(event) {
        this.focus.emit(event);
    }

    onBlur(event) {
        this.blur.emit(event);
        this.trigger.autocompleteDisabled = true;
    }

    stopTimeout() {
        clearTimeout(this._timeout);
    }
}
