import { FormControl } from '@angular/forms';
import { Cartra100Model } from './cartra100.model';

export class Cartra100Form {
    [key: string]: FormControl;

    NamCARTRA121 = new FormControl();
    StrAndNumCARTRA254 = new FormControl();
    PosCodCARTRA121 = new FormControl();
    CitCARTRA789 = new FormControl();
    CouCodCARTRA587 = new FormControl();
    NADCARTRA121 = new FormControl();
    TINCARTRA254 = new FormControl();
    TinTypCARTRA1009 = new FormControl();
    CarrierObject = new FormControl();

    constructor(value: Cartra100Model) {
        this.NamCARTRA121.setValue(value.NamCARTRA121);
        this.StrAndNumCARTRA254.setValue(value.StrAndNumCARTRA254);
        this.PosCodCARTRA121.setValue(value.PosCodCARTRA121);
        this.CitCARTRA789.setValue(value.CitCARTRA789);
        this.CouCodCARTRA587.setValue(value.CouCodCARTRA587);
        this.NADCARTRA121.setValue(value.NADCARTRA121);
        this.TINCARTRA254.setValue(value.TINCARTRA254);
        this.TinTypCARTRA1009.setValue(value.TinTypCARTRA1009);
        this.CarrierObject.setValue(value.CarrierObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
