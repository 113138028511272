import { PrivateControlsModel } from 'app/model/private-controls.model';
import { ImpcusoffModel } from '../impcusoff/impcusoff.model';
import { eAitisiTradecModel } from '../tradec/tradec.model';
import { eAitisiTrarepModel } from '../trarep/trarep.model';
import { eAitisiCancelHeaheaModel } from './heahea/heahea.model';
export class eAitisiCancelModel {

    _PRIVATE?: PrivateControlsModel;

    MesSenMES3?: string;
    MesRecMES6?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    // PriMES15?: string;
    TesIndMES18?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    // CorIdeMES25?: string;

    //
    HEAHEA?: eAitisiCancelHeaheaModel;
    TRADEC?: eAitisiTradecModel;
    TRAREP?: eAitisiTrarepModel;
    CUSOFF?: ImpcusoffModel;
    //
    constructor(value?) {
        this._PRIVATE = value?._PRIVATE || null;

        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = 'IB14A';
        this.HEAHEA = value?.HEAHEA || null;
        this.TRADEC = value?.TRADEC || null;
        this.TRAREP = value?.TRAREP || null;
        this.CUSOFF = value?.CUSOFF || null;
    }
}
