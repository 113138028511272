import { Injectable } from '@angular/core';
import { of, timer } from 'rxjs';
import { retryWhen, delayWhen, tap, concatMap, delay, take } from 'rxjs/operators';
import { ApiService } from 'app/services/api.service';
import { GetStateParams, SubmitData } from 'app/services/submitServices/submitForm.service';
import { DeclarationStateService } from 'app/services/declaration-state.service';

export interface PollingRequest extends GetStateParams {
    state: string;
    mrn: string

}
@Injectable({
    providedIn: 'root'
})
export class PollingService {
    private readonly MAX_RETRIES = 7;
    private activeRequests = new Map<string, any>();

    constructor(private dbQuery: ApiService, private declarationStateService: DeclarationStateService) { }

    // Add a request object to the activeRequests and start its polling
    enqueue(requestObj: PollingRequest): void {
        const fibonacciDelays = this.generateFibonacciDelays();

        this.activeRequests.set(requestObj.mrn, {
            data: requestObj,
            retries: 0,
            delays: [...fibonacciDelays]
        });

        console.log('Enqueued request:', requestObj);
        console.log('Active requests:', this.activeRequests);
        this.poll(requestObj);
    }

    private generateFibonacciDelays(): number[] {
        const delays = [1, 1];
        for (let i = 2; i < this.MAX_RETRIES; i++) {
            delays.push(delays[i - 1] + delays[i - 2]);
        }
        return delays.map(delay => delay * 30 * 1000);
    }

    private poll(requestObj: PollingRequest, currentRetry = 0): void {
        const requestInfo = this.activeRequests.get(requestObj.mrn);

        console.log('Polling requestInfo:', requestInfo);
        console.log('Polling requestObject:', requestInfo);
        console.log('Polling request data:', requestInfo.data);
        if (!requestInfo || currentRetry >= this.MAX_RETRIES) return;

        of(requestObj)
            .pipe(
                delay(requestInfo.delays[currentRetry]),
                concatMap(reqObj => this.dbQuery.getStateSilent(reqObj)),
                tap(response => {
                    console.log("POLLING response", response);
                    console.log("POLLING requestInfo.data.state", requestInfo.data.state);
                    console.log("POLLING response.state", response.stateCode)
                    if (requestInfo.data.state !== response.stateCode) {
                        // Handle successful response and remove from the queue
                        this.declarationStateService.updateStateServerResp(response);

                        if (response.stateCode !== 'Under Control') {
                            console.log("POLLING deleting request from QUEUE", requestObj.mrn);
                            this.activeRequests.delete(requestObj.mrn);
                        }
                    }
                })
            )
            .subscribe({
                next: _ => {
                    // Check if we need to poll again
                    if (this.activeRequests.has(requestObj.mrn) && currentRetry < this.MAX_RETRIES - 1) {
                        console.log("POLLING polling again", requestObj.mrn);
                        this.poll(requestObj, currentRetry + 1);
                    }
                },
                error: error => {
                    console.error('Error while polling:', error);
                }
            });
    }

}
