import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { T2LHeaheaTF013AModel } from './heaheaTF013A.model';
export class T2LHeaheaTF013AForm {
    [key: string]: AbstractControl;
    //DOESNT EXIST ON CC515A
    DocNumHEA5 = new FormControl();

    //HEADER FORM
    TypOfDecHEA24 = new FormControl();
    CouOfDesCodHEA30 = new FormControl();
    CouOfDisCodHEA55 = new FormControl();
    TotNumOfIteHEA305 = new FormControl();
    TotGroMasHEA307 = new FormControl();
    AmdPlaHEA598 = new FormControl();
    AmdPlaHEA598LNG = new FormControl();
    RefT2LYeaHEA1012 = new FormControl();
    RefT2CouCodHEA1010 = new FormControl();
    RefT2LTypHEA1014 = new FormControl();
    RefT2LCusHEA1013 = new FormControl();
    RefT2LNumHEA1015 = new FormControl();
    AmdDatHEA599 = new FormControl();

    constructor(value: T2LHeaheaTF013AModel) {
        this.DocNumHEA5.setValue(value.DocNumHEA5);

        this.TypOfDecHEA24.setValue(value.TypOfDecHEA24);
        this.CouOfDesCodHEA30.setValue(value.CouOfDesCodHEA30);
        this.CouOfDisCodHEA55.setValue(value.CouOfDisCodHEA55);
        this.TotNumOfIteHEA305.setValue(value.TotNumOfIteHEA305);
        this.TotGroMasHEA307.setValue(value.TotGroMasHEA307);
        this.AmdPlaHEA598.setValue(value.AmdPlaHEA598);
        this.AmdPlaHEA598LNG.setValue(value.AmdPlaHEA598LNG);
        this.RefT2LYeaHEA1012.setValue(value.RefT2LYeaHEA1012);
        this.RefT2CouCodHEA1010.setValue(value.RefT2CouCodHEA1010);
        this.RefT2LTypHEA1014.setValue(value.RefT2LTypHEA1014);
        this.RefT2LCusHEA1013.setValue(value.RefT2LCusHEA1013);
        this.RefT2LNumHEA1015.setValue(value.RefT2LNumHEA1015);
        this.AmdDatHEA599.setValue(value.AmdDatHEA599);

        this.TypOfDecHEA24.setValidators(Validators.required);
    }
}
