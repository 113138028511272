export class LoadingDtsModel {
    LoadingBegDateHEA?: string;
    LoadingEndDat?: string;

    constructor(value?) {
        if (value) {
            this.LoadingBegDateHEA = value.LoadingBegDateHEA;
            this.LoadingEndDat = value.LoadingEndDat;
        }
    }
}
