import { FormControl, Validators } from '@angular/forms';
import { GrnImpModel } from './grn-imp.model';

export class GrnImpForm {
    [key: string]: FormControl;

    GrnNum1010 = new FormControl();
    GRNAmouGRN2 = new FormControl();

    constructor(value: GrnImpModel) {
        this.GrnNum1010.setValue(value.GrnNum1010);
        this.GRNAmouGRN2.setValue(value.GRNAmouGRN2);
    }
}
