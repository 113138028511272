import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ApiService } from 'app/services/api.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PaginatedDataSource } from 'app/services/datasource/paginated-datasource';
import { CustomQuery } from './customs-list/customs-list.component';
import { Page, PageRequest, Sort } from 'app/services/datasource/page';
import { Custom } from './customs.model';
import { CustomsFiltersForm } from './customs-filters/_models/customs-filters-form.model';
import { CustomsFiltersModel } from './customs-filters/_models/customs-filters.model';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CustomsDialogComponent } from './customs-dialog/customs-dialog.component';
import { CustomsItem } from 'app/services/broadcaster.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class CustomsService implements OnDestroy {
    private _destroy: Subject<void>;

    customs: Custom[];
    selectedCustoms: string[] = [];

    private dataSource: BehaviorSubject<PaginatedDataSource<Custom, CustomQuery>>;

    public dataSource$: Observable<PaginatedDataSource<Custom, CustomQuery>>;

    private customFilters: BehaviorSubject<FormGroup | undefined>;

    public customFilters$: Observable<FormGroup>;

    public loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    initialSort: Sort<Custom> = {
        property: 'CountryCode',
        order: 'desc'
    };

    constructor(
        private dbQuery: ApiService,
        private fb: FormBuilder,
        private toastrService: ToastrService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<CustomsDialogComponent>
    ) {
        console.log('SERVICE CUSTMS service started');

        // Set the defaults
        this._destroy = new Subject<void>();

        this.customFilters = new BehaviorSubject(this.fb.group(new CustomsFiltersForm(new CustomsFiltersModel())));

        if (!this.customFilters$) {
            this.customFilters$ = this.customFilters.asObservable();
        }

        const dataSource = new PaginatedDataSource<Custom, CustomQuery>(
            (request, query) => this.getCustoms(request, query),
            this.initialSort,
            { search: '' },
            10
        );

        this.dataSource = new BehaviorSubject(dataSource);

        if (!this.dataSource$) {
            this.dataSource$ = this.dataSource.asObservable();
        }
    }

    ngOnDestroy() {
        console.log('CUSTMS service destroyed');

        this._destroy.next();
        this._destroy.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get customs
     *
     * @returns {Observable<Page<InvoiceModel>>}
     */

    getCustoms(request: PageRequest<Custom>, query: CustomQuery): Observable<Page<Custom>> {
        const postData = {
            pageNumber: request.page,
            pageSize: request.size,
            sortColumn: request.sort.property,
            sortOrder: request.sort.order,
            query
        };

        return this.dbQuery.get_AllCustoms(postData);
    }

    setCustoms(declarationForm: FormGroup, result: CustomsItem) {
        const customsRefNumber: string = result.customsRefNumber;
        const customsName: string = result.customsName;
        const type: string = result.type;
        const customsTitle = result.customsTitle;

        // console.log("SETCUSTOMS currentDecl", declarationForm.value);
        // console.log("SETCUSTOMS customsRefNumber", customsRefNumber);
        // console.log("SETCUSTOMS customsName", customsName);
        // console.log("SETCUSTOMS type", type);

        const country = customsRefNumber.substr(0, 2);

        if (type === 'exit') {
            const cusoffexiext = declarationForm.get('CUSOFFEXIEXT') as FormGroup;
            // console.log("SETCUSTOMS CUSOFFEXIEXT", cusoffexiext);
            cusoffexiext.get('RefNumEXT1').setValue(customsRefNumber);
            cusoffexiext.get('_countryCode').setValue(country);
        } else if (type === 'export') {
            const cusoffexpert = declarationForm.get('CUSOFFEXPERT') as FormGroup;

            // console.log("SETCUSTOMS CUSOFFEXPERT", cusoffexpert);

            cusoffexpert.get('RefNumERT1').setValue(customsRefNumber);
        } else if (type === 'entry') {
            const entcusoff = declarationForm.get('ENTCUSOFF') as FormGroup;
            console.log('DECLFORMSERVICE: SETCUSTOMS ENTCUSOFF', entcusoff);
            entcusoff.get('EnCusOffRefNum01').setValue(customsRefNumber);
            entcusoff.get('_countryCode').setValue(country);
        } else if (type === 'import') {
            const impcusoff = declarationForm.get('IMPCUSOFF') as FormGroup;

            console.log('DECLFORMSERVICE: SETCUSTOMS IMPCUSOFF', impcusoff);

            impcusoff.get('RefNumIMPCUSOFF').setValue(customsRefNumber);
        } else if (type === 'departure') {
            const cusoffdep = declarationForm.get('CUSOFFDEPEPT') as FormGroup;
            console.log('DECLFORMSERVICE: SETCUSTOMS cusoffdep', cusoffdep);
            cusoffdep.get('RefNumEPT1').setValue(customsRefNumber);
            cusoffdep.get('_countryCode').setValue(country);
        } else if (type === 'destination') {
            const cusoffdes = declarationForm.get('CUSOFFDESEST') as FormGroup;

            console.log('DECLFORMSERVICE: SETCUSTOMS cusoffdes', cusoffdes);
            cusoffdes.get('_countryCode').setValue(country);
            cusoffdes.get('RefNumEST1').setValue(customsRefNumber);
        }

        this.toastrService.success(`Oνομα: <b>${customsName}</b><br/> Κωδικός: <b>${customsRefNumber}</b>`, `Το Tελωνείο ${customsTitle} άλλαξε`, {
            timeOut: 2500
        });
    }

    openCustomsDialog(OfficeRefNumber) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.width = '70%';
        dialogConfig.height = '100%';
        dialogConfig.panelClass = ['mat-dialog-override', 'mat-dialog-white'];
        dialogConfig.data = { ReferenceNumber: OfficeRefNumber };

        let customsDialogRef = this.dialog.open(CustomsDialogComponent, dialogConfig);

        customsDialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape') {
                customsDialogRef.close();
            }
        });

        customsDialogRef.backdropClick().subscribe(() => {
            customsDialogRef.close();
        });

        customsDialogRef.afterClosed().subscribe(() => {
            customsDialogRef = null;
        });
    }
}
