import { CancelHeaheaModel } from 'app/main/apps/export/cancel-declaration/_models/heahea/heahea.model';
import { TraconceModel } from 'app/main/apps/export/declaration/_models';
import { ImpcusoffModel } from 'app/main/apps/import/_models/impcusoff/impcusoff.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { TrarepModel } from '../trarep/trarep.model';

export class ImportCancelDeclarationModel {
    _PRIVATE: PrivateControlsModel;

    MesSenMES3?: string;
    MesRecMES6?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    PriMES15?: string;
    TesIndMES18?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    CorIdeMES25?: string;
    //CorIdeMES25?: string;

    HEAHEA?: CancelHeaheaModel;
    TRACONCE1?: TraconceModel;
    TRAREP?: TrarepModel;
    IMPCUSOFF?: ImpcusoffModel;
    //
    constructor(value?) {
        this._PRIVATE = value?._PRIVATE || null;

        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.PriMES15 = value?.PriMES15 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = 'ID14A';
        this.CorIdeMES25 = value?.CorIdeMES25 || null;

        this.HEAHEA = value?.HEAHEA;
        this.TRACONCE1 = value?.TRACONCE1;
        this.TRAREP = value?.TRAREP;
        this.IMPCUSOFF = value?.IMPCUSOFF;
    }
}
