import { Component, Inject, Type, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralApplicationsService } from 'app/main/apps/general-applications/general-applications.service';
import { ContentContainerDirective } from 'app/main/apps/tab-factory/content-container.module';
import { SkeletonComponent } from 'app/main/apps/tab-factory/tab-content.component';
import { environment } from 'environments/environment';
import { Observable, switchMap } from 'rxjs';

@Component({
    selector: 'app-general-applications',
    templateUrl: './general-applications.component.html',
    styleUrls: ['./general-applications.component.scss'],
    providers: [GeneralApplicationsService]
})
export class GeneralApplicationsComponent {

    @ViewChild('searchInput')
    searchInputField;
    allApplications: any[] = [];
    searching = false;

    searchText = ''



    @ViewChild(ContentContainerDirective, { static: true }) contentContainer: ContentContainerDirective;

    dialogTitle: string;

    allApplications$: Observable<any[]>;

    constructor(
        public dialogRef: MatDialogRef<GeneralApplicationsComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: { component: Type<any>, dialogTitle: string, cssFilename: string, data?: any },
        private generalApplicationsService: GeneralApplicationsService,

    ) { }


    ngOnInit() {

        this.allApplications$ = this.generalApplicationsService.getAllApplications('distillersApp').pipe(switchMap(apps => {
            this.allApplications = apps;
            console.log("allApplications on GeneralApplicationsComponent", apps)

            return this.generalApplicationsService.allApplications$
        }))


        this.dialogTitle = this.dialogData.dialogTitle;

        const viewContainerRef = this.contentContainer.viewContainerRef;

        const componentRef = viewContainerRef.createComponent(this.dialogData.component);

        const instance = componentRef.instance as SkeletonComponent;

        if (this.dialogData.data) {
            instance._data = this.dialogData.data;
        }

    }

    onPrint() {

        const content = document.getElementById('printableApplication').outerHTML;
        const cssPath = environment.DOMAIN_NAME + '/assets/css/' + this.dialogData.cssFilename + '.css'; // Your logged css path
        const newWindow = window.open('', '_blank');

        if (newWindow && newWindow.document) {
            // Create new link element
            const linkEl = newWindow.document.createElement('link');
            linkEl.setAttribute('rel', 'stylesheet');
            linkEl.setAttribute('type', 'text/css');

            // Set href attribute to the cssPath
            linkEl.setAttribute('href', cssPath);

            // Append the link element to the head of the document
            newWindow.document.head.appendChild(linkEl);

            // Add content to the body of the new window
            newWindow.document.body.innerHTML = content;

            // Listen for the load event on the link element
            linkEl.onload = function () {
                newWindow.print();
                newWindow.close();
            };
        }
    }

    onSave() {
        this.generalApplicationsService.sendSaveSignal();
    }

    onLoad(event, application: any) {

        console.log("onLoad event: ", event)
        console.log("onLoad application: ", application)
        this.generalApplicationsService.sendLoadSignal(application.id, application.applicationForm);
    }

    delete(application) {

        this.generalApplicationsService.deleteApplication(application).subscribe()
    }
    onCloseConfirm() {
        this.dialogRef.close()
    }


    onMenuOpen(): void {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }


}
