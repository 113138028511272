import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ContactsContactFormDialogComponent } from 'app/main/apps/contacts/contact-form/contact-form.component';
import { Contact } from 'app/main/apps/contacts/contact.model';
import { ContactsService } from 'app/main/apps/contacts/contacts.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ConfirmDialogConfig, OpenDialogsService } from 'app/services/openDialogs.service';
import { environment } from 'environments/environment';
import { Observable, Subject, startWith, switchMap, take, takeUntil, tap } from 'rxjs';

interface UpdateContactsExternalInputLine {
    lastname: string;
    firstname: string;
    street: string;
    postal_code: string;
    city: string;
    occupation: string;
    afm: string;
    doy: string;
    phone_number: string;

}
interface UpdateContactsExternalWarn {
    lastname?: boolean;
    street?: boolean;
    postal_code?: boolean;
    city?: boolean;
    country?: boolean;
}
interface UpdateContactsExternal {


    contact: Contact;
    inputLine: UpdateContactsExternalInputLine;
    error: string;
    warn: UpdateContactsExternalWarn
}

@Component({
    selector: 'app-contacts-update-from',
    templateUrl: './contacts-update-from.component.html',
    styleUrls: ['./contacts-update-from.component.scss']
})
export class ContactsUpdateFromComponent {

    @ViewChild('scrollContainer', { read: ElementRef, static: true }) scrollContainer: ElementRef;
    @ViewChild('fileInput') _fileInput: ElementRef

    form: FormGroup;
    dialogTitle: string;
    contactType: 'domestic' | 'international';

    filename: string;

    responses: UpdateContactsExternal[];
    loading$: Observable<boolean>;
    loading: boolean;

    public file: File;

    info_domestic = `
                <ul>
                    <li class="li-contact-info">Αφου πατήσετε το κουμπί <strong>'Επιλογη Αρχείου Πελατών'</strong> περιηγηθειτε στον φακελο της εφαρμογής ΔΑΣΜΟΣ (συνηθως <strong>C:\\Dasmos</strong>) και επιλέξτε το αρχείο <strong>Custs.csv</strong>.</li>
                    <li class="li-contact-info">Τα στοιχεία για τις επιχειρήσεις, λαμβάνονται απευθείας απο την Α.Α.Δ.Ε. και γίνεται αυτόματα έλεγχος για την ύπαρξη EORI.</li>
                    <li class="li-contact-info">Τα στοιχεία για τους ιδιώτες, μεταφέρονται ως έχουν από την εφαρμογή ΔΑΣΜΟΣ.</li>
                    <li class="li-contact-info">Η σωστή μεταφορά των εγγραφών προϋποθέτει ότι τα πεδία στην εφαρμογη ΔΑΣΜΟΣ ειναι σωστά συμπληρωμένα.</li>
                    <li class="li-contact-info">Ως <strong>Χώρα</strong>, για ιδιώτες και επιχειρήσεις, λογίζεται η <strong>Ελλάδα</strong>.</li>
                </ul>`;

    info_international = `
                <ul>
                   <li class="li-contact-info">Αφου πατήσετε το κουμπί <strong>'Επιλογη Αρχείου Πελατών'</strong> περιηγηθειτε στον φακελο της εφαρμογής ΔΑΣΜΟΣ (συνηθως <strong>C:\\Dasmos</strong>) και επιλέξτε το αρχείο <strong>Cust_Ex.csv</strong>.</li>
                    <li class="li-contact-info">Τα στοιχεία για τους πελάτες εξωτερικού, μεταφέρονται ως έχουν από την εφαρμογή ΔΑΣΜΟΣ.</li>
                    <li class="li-contact-info">Η σωστή μεταφορά των εγγραφών προϋποθέτει ότι τα πεδία στην εφαρμογη ΔΑΣΜΟΣ ειναι σωστά συμπληρωμένα.</li>
                    <li class="li-contact-info"> Η εφαρμογή ΔΑΣΜΟΣ δε χρησιμοποιεί διαφορετικό πεδίο για τη 'Χώρα'. Θα γίνει προσπάθεια αυτόματης αναγνώρισης της χώρας του πελάτη. Παρακαλώ πολύ ελέγξτε προσεκτικά οτι το πεδίο <strong>Χώρα</strong> έχει συμπληρωθεί σωστά στις νέες εγγραφές.</li>
                </ul>`;

    info_results_international = `
            <ul>
                <li class="li-contact-info">Μπορείτε να διορθώσετε τους πελάτες, πατώντας πάνω σε κάθε γραμμή.</li>
                <li class="li-contact-info">Τα πεδία <strong>Επωνυμία</strong>, <strong>Διεύθυνση</strong>, <strong>Τ.Κ.</strong>, <strong>Πόλη</strong> και <strong>Χώρα</strong> συμπληρώνονται υποχρεωτικά στο σύστημα.</li>
                <li class="li-contact-info">Η εφαρμογή θα συμπληρώσει τα πεδία Διεύθυνση, Τ.Κ. και Πόλη με παύλες, εφόσον αύτα βρεθούν κενά.</li>
                <li class="li-contact-info">Παρακαλώ ελέγξτε ότι η <strong>Χώρα</strong> κάθε πελάτη έχει συμπληρωθεί σωστα.</li>
            </ul>
            `;

    info_results_domestic = `
            <ul>
                <li class="li-contact-info">Μπορείτε να διορθώσετε τους πελάτες, πατώντας πάνω σε κάθε γραμμή.</li>
                <li class="li-contact-info">Η εφαρμογή θα συμπληρώσει τα πεδία Διεύθυνση, Τ.Κ. και Πόλη με παύλες, εφόσον αύτα βρεθούν κενά.</li>
            </ul>
            `;
    get fileToUpload() {
        return this.form.get('fileToUpload') as FormControl
    }
    get contact() {
        return this.form.get('contactType') as FormControl
    }
    displayedColumns: string[];
    displayedColumnsError: string[];

    internationalColumns: string[] = ['index', 'lastname', 'firstname', 'street', 'city', 'postal_code', 'country', 'occupation', 'phone_number', 'actions'];
    domesticColumns: string[] = ['index', 'lastname', 'afm', 'customerType', 'eori', 'actions'];
    domesticErrorColumns: string[] = ['index', 'lastname', 'afm', 'error', 'empty', 'empty'];
    internationalErrorColumns: string[] = ['index', 'error', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty'];


    private _destroy = new Subject<void>();

    constructor(
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private matDialog: MatDialog,
        public dialogRef: MatDialogRef<ContactsUpdateFromComponent>,
        public editDialogRef: MatDialogRef<ContactsContactFormDialogComponent>,
        private mainBroadcaster: MainBroadcasterService,
        private contactsService: ContactsService,
        private http: HttpClient,
        private openDialogsService: OpenDialogsService
    ) {
        this.dialogTitle = _data.external === 'dasmos' ? "Μεταφορά πελατών από πρόγραμμα 'ΔΑΣΜΟΣ'" : "";

        this.contactType = _data.contactType;

        this.form = this.fb.group({
            contactType: _data.contactType,
            fileToUpload: null

        })

        this.loading$ = this.mainBroadcaster.loading$.pipe(tap(loading => this.loading = loading))

    }

    ngOnInit() {
        this.contact.valueChanges.pipe(startWith(this.contact.value), takeUntil(this._destroy)).subscribe(value => {

            this.filename = value === 'international' ? 'Cust_Ex.csv' : (value === 'domestic' ? 'Custs.csv' : null)

            this.displayedColumns = value === 'international' ? this.internationalColumns : this.domesticColumns;
            this.displayedColumnsError = value === 'international' ? this.internationalErrorColumns : this.domesticErrorColumns;

            if (this.fileToUpload.value?.name !== this.filename) {

                this.fileToUpload.setErrors({ incorrect: true });
                this.fileToUpload.setValue(null)
            }

        })
    }

    ngOnDestroy() {

        this._destroy.next()
        this._destroy.complete();
    }
    process() {

        const PHP_API_SERVER = environment.API_URL;

        if (this.form.valid && this.fileToUpload.value) {
            const formData = new FormData();
            formData.append('customerCsv', this.fileToUpload.value);
            formData.append('contactType', this.contact.value);

            if (this.contact.value === 'domestic') {
                this.responses = [];
                this.http.post(
                    `${PHP_API_SERVER}/contacts/updateFromDasmos`,
                    formData,
                    {
                        responseType: 'text',
                        reportProgress: true,
                        observe: 'events'
                    }
                )
                    .pipe(
                        tap((res: unknown) => {
                            console.log("contactResponse", res)
                            this.responses = [...this.responses, ...res as UpdateContactsExternal[]];
                            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;

                        }),
                    ).subscribe();
            }
            else {
                this.http.post<UpdateContactsExternal[]>(
                    `${PHP_API_SERVER}/contacts/updateFromDasmos`,
                    formData
                ).pipe(take(1)).subscribe(result => this.responses = result)

            }

        }
    }
    selectFile() {
        if (!this.loading) {
            this._fileInput.nativeElement.click()
        }
    }
    onFileSelected() {
        const $img: any = this._fileInput.nativeElement;
        const file: File = $img.files[0];
        if (file) {
            if (file.name === this.filename) {
                this.fileToUpload.setValue(file);
                console.log("FORM: ", this.form);
            } else {
                this.fileToUpload.setErrors({ incorrect: true });
                console.error('File name does not match the expected name.');
            }
        }


    }
    editContact(row: UpdateContactsExternal): void {

        const contact = row.contact;

        console.log('setContact editContact row', row)

        if (row.error) {
            return;
        }

        const dialogConfig = new MatDialogConfig();

        // dialogConfig.autoFocus = true;
        dialogConfig.width = '60%';
        dialogConfig.height = '85%';
        dialogConfig.panelClass = 'mat-dialog-override';
        dialogConfig.data = {
            contact: contact,
            action: 'edit',
            class: this.contact.value
        };

        this.editDialogRef = this.matDialog.open(ContactsContactFormDialogComponent, dialogConfig);

        this.editDialogRef.afterClosed().subscribe(response => {

            console.log("Dialog RES", response);
            if (!response) {
                return;
            }
            const actionType: string = response[0];
            const customerType: string = response[1];
            const formData: FormGroup = response[2];
            switch (actionType) {
                /**
                 * Save
                 */
                case 'save':
                    this.contactsService.updateContact(formData.getRawValue(), this.contact.value, false, true).pipe(take(1)).subscribe(response => {
                        this.updateResponses(contact, response)
                    });

                    break;
                /**
                 * Delete
                 */
                case 'delete':
                    this.deleteContact(contact);

                    break;
            }
        });
    }

    deleteContact(contact): void {
        console.trace("deleteCOntactDialog")

        const config: ConfirmDialogConfig = {
            message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το συγκεκριμένο πελάτη;',
            title: 'Διαγραφή',
            confirmButton: 'Διαγραφή',
            cancelButton: 'Άκυρο',
            confirmClass: 'warn',
            width: '35%',
            height: '45%'
        }


        this.openDialogsService.openConfirmDialog(config)
            .pipe(
                switchMap(result => {
                    if (result) { return this.contactsService.deleteContact(contact, this.contact.value); }
                }),
                take(1)
            )
            .subscribe({
                next: () => {
                    const index = this.responses.findIndex(e => {

                        if (e.contact) {
                            return e.contact.id === contact.id
                        }
                    });
                    this.responses.splice(index, 1)

                    // Re-assign responses array to trigger change detection
                    this.responses = [...this.responses];

                    console.log(this.responses)
                }

            })

    }

    getInactive(row) {
        if (!row.contact) {
            return false;
        }
        console.log("active", row.contact.active)
        return row.contact.active === 0;
    }


    getTooltip(row: any): string {


        const warningMessages = [];

        if (!row.contact?.active) {
            warningMessages.push('Ο Πελάτης δεν ασκεί πλέον επιχειρηματική δραστηριότητα.');
            return warningMessages.join('');
        }
        if (!row.contact?.lastname || row.contact.lastname === '-') {
            warningMessages.push('To πεδίο `Επωνυμία` λέιπει.');
        }
        if (!row.contact?.street || row.contact.street === '-') {
            warningMessages.push('To πεδίο `Διεύθυνση` λέιπει.');
        }
        if (!row.contact?.postal_code || row.contact.postal_code === '-') {
            warningMessages.push('To πεδίο `Ταχ.Κώδικας` λέιπει.');
        }
        if (!row.contact?.city || row.contact.city === '-') {
            warningMessages.push('To πεδίο `Πόλη` λέιπει.');
        }
        if (!row.contact?.country || row.contact.country === '-') {
            warningMessages.push('To πεδίο `Χώρα` λέιπει.');
        }


        // continue for other properties...

        return warningMessages.join(', ');
    }

    updateResponses(contact, response: any) {

        const savedContacts = response;

        const savedContact = savedContacts.filter(c => c.id == contact.id)[0]

        const index = this.responses.findIndex(e => {

            if (e.contact) {
                return e.contact.id === contact.id
            }

        });

        this.responses.at(index).contact = savedContact

        this.responses = [...this.responses];
    }


    normalRow(index, rowData) {
        // return true if this row is a normal row (i.e., no error)

        return !rowData.error;
    }

    errorRow(index, rowData) {
        // return true if this row is an error row

        return rowData.error;
    }

}


@Pipe({ name: 'inactive' })
export class ContactInactivePipe implements PipeTransform {

    transform(row: UpdateContactsExternal,): boolean {

        const inactive = !row.contact.active;

        console.log("ROw inactive", inactive)
        return inactive;
    }
}
