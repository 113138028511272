import { FormControl, Validators } from '@angular/forms';
import { CusoffexpertModel } from './cusoffexpert.model';

export class CusoffexpertForm {
    [key: string]: FormControl;

    _countryCode = new FormControl();
    RefNumERT1 = new FormControl();

    constructor(value: CusoffexpertModel) {
        this._countryCode.setValue(value._countryCode);
        this.RefNumERT1.setValue(value.RefNumERT1);

        // this.RefNumERT1.setValidators([Validators.required]);
    }
}
