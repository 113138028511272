<div
    mat-dialog-title
    class="{{ dialogClass }}"
    fxLayout="row"
    fxLayoutAlign="space-between center"
>
    <div>{{ dialogTitle }}</div>
    <div>
        <button tabindex="-1" mat-icon-button (click)="dialogRef.close(false)">
            <mat-icon> highlight_off</mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content fxLayout="column" fxLayoutAlign="space-around center">
    <div
        *ngIf="contentButtons"
        fxLayout="column"
        fxLayoutAlign="space-around center"
        style="width: 50%; align-self: center"
    >
        <div
            fxLayout="row"
            style="margin: 20px 0"
            *ngFor="let button of contentButtons"
        >
            <button
                mat-raised-button
                style="width: 100%"
                class="{{ button.class }}"
                (click)="dialogRef.close(button.action)"
            >
                {{ button.title }}
            </button>
        </div>
    </div>
    <div *ngIf="contentDescription" [innerHTML]="contentDescription"></div>

    <div *ngIf="inputTitle">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div>
                <mat-form-field class="sc-ul s-l s-34 reset">
                    <input
                        [placeholder]="inputTitle"
                        matInput
                        [(ngModel)]="inputValue"
                    />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button
        *ngFor="let button of confirmButtons"
        mat-raised-button
        class="{{ button.class }} mr-16"
        (click)="
            dialogRef.close({ action: button.action, inputValue: inputValue })
        "
    >
        {{ button.title }}
    </button>
</div>
