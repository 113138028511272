import { FormControl } from '@angular/forms';
import { eAitisiTasksModel } from './tasks.model';

export class eAitisiTasksForm {
    [key: string]: FormControl;

    FlatRate = new FormControl();
    CustomsTaskType = new FormControl();
    CstPerHour = new FormControl();
    CstPerKlmtr = new FormControl();
    NumHours = new FormControl();
    NumMnts = new FormControl();
    NumKlmtrs = new FormControl();
    Amount = new FormControl();

    constructor(value: eAitisiTasksModel) {
        this.FlatRate.setValue(value.FlatRate);
        this.CustomsTaskType.setValue(value.CustomsTaskType);
        this.CstPerHour.setValue(value.CstPerHour);
        this.CstPerKlmtr.setValue(value.CstPerKlmtr);
        this.NumHours.setValue(value.NumHours);
        this.NumMnts.setValue(value.NumMnts);
        this.NumKlmtrs.setValue(value.NumKlmtrs);
        this.Amount.setValue(value.Amount);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
