import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { ContactDetailsForm } from "../contactDetails/contactDetails-form.model";
import { ContactDetailsModel } from "../contactDetails/contactDetails.model";
import { DeliveryPlaceForm } from "../deliveryPlace/deliveryPlace-form.model";
import { DeliveryPlaceModel } from "../deliveryPlace/deliveryPlace.model";
import { VehicleArrivalConsigneeModel } from "./vehicleArrivalConsigee.model";

export class VehicleArrivalConsigneeForm {
    [key: string]: AbstractControl;

    PersonIdentificationType: FormControl;
    TaxIdNumber: FormControl;
    OfficeOfResponsibility: FormControl;
    VehicleTaxWarehouseReference: FormControl;
    ContactDetails: FormGroup;
    DeliveryPlace: FormGroup;
    ConsigneeObject: FormControl;

    constructor(value: VehicleArrivalConsigneeModel) {
        this.PersonIdentificationType = new FormControl(value.PersonIdentificationType, [Validators.required]);
        this.TaxIdNumber = new FormControl(value.TaxIdNumber, [Validators.required]);
        this.OfficeOfResponsibility = new FormControl(value.OfficeOfResponsibility, [Validators.required]);
        this.VehicleTaxWarehouseReference = new FormControl(value.VehicleTaxWarehouseReference, { updateOn: "blur" });
        this.ContactDetails = new FormGroup(new ContactDetailsForm(new ContactDetailsModel(value.ContactDetails)));
        this.DeliveryPlace = new FormGroup(new DeliveryPlaceForm(new DeliveryPlaceModel(value.DeliveryPlace)));
        this.ConsigneeObject = new FormControl(value.ConsigneeObject, [Validators.required]);


    }
}
