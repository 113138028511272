import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { Custom } from '../customs.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PaginatedDataSource } from 'app/services/datasource/paginated-datasource';
import { Sort } from 'app/services/datasource/page';
import { takeUntil } from 'rxjs/operators';
import { CustomsService } from '../customs.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';

export interface CustomQuery {
    search: string;
    country?: string; //template, draft, all
}

@Component({
    selector: 'customs-list',
    templateUrl: './customs-list.component.html',
    styleUrls: ['./customs-list.component.scss']
})
export class CustomsListComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Input() DATASOURCE: PaginatedDataSource<Custom, CustomQuery>;
    @Input() countries: any[];
    @Input() formDisabled: boolean;

    subdomain: string;
    dialogContent: TemplateRef<any>;

    customs: any;

    customs1 = { type: '', title: '' };
    customs2 = { type: '', title: '' };

    //DATASOURCE: FilesDataSource | null;
    displayedColumns: string[] = [];

    initialSortDraft: Sort<Custom> = {
        property: 'CountryCode',
        order: 'asc'
    };

    selectedCustoms: any[];
    checkboxes: {};
    dialogRef: any;
    totalCustoms$: Observable<number>;
    filters: FormGroup;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     */
    constructor(private mainBroadcaster: MainBroadcasterService, private customsService: CustomsService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();

        this.mainBroadcaster.activeSubdomain$.pipe(takeUntil(this._unsubscribeAll)).subscribe(subdomain => {
            this.subdomain = subdomain
            this.setCustomsOptions(subdomain);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        console.log('CUSTMS LIST DATASOURCE:', this.DATASOURCE);
        console.log('CUSTMS LIST countries:', this.countries);
        console.log('CUSTMS LIST formDisabled:', this.formDisabled);

        if (this.formDisabled) {
            this.displayedColumns = ['CountryCode', 'ReferenceNumber', 'CustomsOfficeName', 'City'];
        } else {
            this.displayedColumns = ['CountryCode', 'ReferenceNumber', 'CustomsOfficeName', 'City', 'usage'];
        }
    }

    ngAfterViewInit() {
        if (this.sort) {
            this.sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
                this.DATASOURCE.sortBy({
                    property: value.active,
                    order: value.direction
                });
            });
        }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    goToOffice(code) {
        this.customsService.openCustomsDialog(code);
    }
    getCountry(code) {
        if (code && this.countries) {
            const country = this.countries.find(e => e.CountryCode === code);

            return country.CountryName;
        }
    }
    setCustoms(customsRefNumber: string, customsName: string, type: string, customsTitle: string) {
        this.mainBroadcaster.updateCustoms({
            customsRefNumber,
            customsName,
            type,
            customsTitle
        });
    }

    setCustomsOptions(subdomain: string) {
        switch (subdomain) {
            case 'EXP_DECL': {
                this.customs1.type = 'export';
                this.customs1.title = 'Εξαγωγής';
                this.customs2.type = 'exit';
                this.customs2.title = 'Εξόδου';
                break;
            }
            case 'IMP_DECL': {
                this.customs1.type = 'import';
                this.customs1.title = 'Προορισμού';
                this.customs2.type = 'entry';
                this.customs2.title = 'Εισόδου';
                break;
            }
            case 'T2L':
            case 'POUS': {
                this.customs1.type = 'departure';
                this.customs1.title = 'Αναχώρησης';
                this.customs2.type = 'destination';
                this.customs2.title = 'Προορισμού';
                break;
            }
        }
    }
}
