import { FormControl, Validators } from '@angular/forms';
import { TradatModel } from './tradat.model';

export class TradatForm {
    [key: string]: FormControl;

    CurTRD1 = new FormControl();
    TotAmoInvTRD1 = new FormControl(null, { updateOn: 'blur' });
    ExcRatTRD1 = new FormControl(null, { updateOn: 'blur' });
    NatOfTraFirCodTRD1 = new FormControl();
    NatOfTraSecCodTRD1 = new FormControl();

    constructor(value: TradatModel) {
        this.CurTRD1.setValue(value.CurTRD1);
        this.TotAmoInvTRD1.setValue(value.TotAmoInvTRD1);
        this.ExcRatTRD1.setValue(value.ExcRatTRD1);
        this.NatOfTraFirCodTRD1.setValue(value.NatOfTraFirCodTRD1);
        this.NatOfTraSecCodTRD1.setValue(value.NatOfTraSecCodTRD1);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
