import { OfficeService } from './../../services/office.service';
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { User } from 'app/core/user/user.model';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { BusinessService } from 'app/main/businesses/services/business.service';
import { PATHS } from 'app/navigation/paths';
import { Office } from 'app/model/office.model';
import { BusinessModel } from 'app/main/businesses/invoices/_models/business/business.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
    PHP_API_SERVER = environment.API_URL;

    private isUserAuthenticated = new BehaviorSubject<boolean>(true);
    private isOfficeAuthenticated = new BehaviorSubject<boolean>(true);
    private isBusinessAuthenticated = new BehaviorSubject<boolean>(true);
    public isUserAuthenticated$ = this.isUserAuthenticated.asObservable();
    public isOfficeAuthenticated$ = this.isOfficeAuthenticated.asObservable();
    public isBusinessAuthenticated$ = this.isBusinessAuthenticated.asObservable();

    private redirectUrl: string | null = null;

    authenticationStateChanged = new Subject<boolean>();
    private _httpClient: HttpClient;
    /**
     * Constructor
     */
    constructor(
        private injector: Injector,
        private _userService: UserService,
        private cookieService: CookieService,
        private _router: Router,
        private _officeService: OfficeService,
        private _businessService: BusinessService,
        private fuseConfigService: FuseConfigService
    ) {
        //The setTimeout() function is used here to defer the execution of the code inside it. In this particular case, we are using setTimeout() with a delay of 0 milliseconds to allow Angular's Dependency Injection system to resolve and initialize all the dependencies before executing the code inside the function.
        setTimeout(() => {
            this._httpClient = this.injector.get(HttpClient);
            this.checkUserAuthenticationStatus();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    /*  signIn(credentials: { email: string; password: string }): Observable<any> {
 
         // Throw error, if the user is already logged in
         if (this.authenticated) {
             throw new Error(`User is already logged in.`);
         }
         const url = `${this.PHP_API_SERVER}/auth/login`;
         return this._httpClient.post(url, credentials).pipe(
             switchMap((response: User) => {
                 // Store the access token in the local storage
                 //this.accessToken = response.accessToken;
 
                 // Set the authenticated flag to true
                 this.authenticated = true;
 
                 // Store the user on the user service
                 this._userService.user = response;
 
                 // Return a new observable with the response
                 return of(response);
             })
         );
     } */

    signIn(credentials: { email: string; password: string }) {
        const url = `${this.PHP_API_SERVER}/auth/login`;

        // Throw error, if the user is already logged in
        if (this.isUserAuthenticated.value) {
            if (this.redirectUrl) {
                this._router.navigateByUrl(this.redirectUrl);
                this.redirectUrl = null;
            } else {
                this._router.navigate(['/']);
            }
            //throw new Error(`User is already logged in.`);
        }
        return this._httpClient
            .post(url, credentials, { withCredentials: true })
            .pipe(
                catchError(error => {
                    console.error('Error signing in:', error);
                    return throwError(error);
                }),
                switchMap((user: User) => {
                    this.updateUserAuthenticatedStatus(true);
                    this._userService.user = user;

                    return this.fuseConfigService.loadConfig().pipe(switchMap(() => {

                        return combineLatest([
                            this._officeService.fetchOffices(),
                            this._businessService.fetchBusinesses()
                        ])
                            .pipe(
                                tap(() => {

                                    if (this.redirectUrl) {
                                        this._router.navigateByUrl(this.redirectUrl);
                                        this.redirectUrl = null;
                                    } else {
                                        this._router.navigate(['/']);
                                    }
                                }));
                    }))


                })
            );
    }


    signOut() {

        const url = `${this.PHP_API_SERVER}/auth/logout`;

        return this._httpClient
            .get(url)
            .pipe(
                catchError(error => {
                    console.error('Error signing out:', error);
                    return throwError(error);
                }),
                tap(() => {
                    this.updateUserAuthenticatedStatus(false);
                    this.updateOfficeAuthenticatedStatus(false);
                    this.updateBusinessAuthenticatedStatus(false);
                    this._router.navigate([PATHS.signIn]);
                    this._officeService.setDefaultOffice(null)
                    this._officeService.setOffices(null)
                    this._businessService.setDefaultBusiness(null);
                    this._businessService.setBusinesses(null)

                    this._officeService.setCurrentOffice(null)
                    this._businessService.setCurrentBusiness(null);


                })
            );
    }


    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { first_name: string; last_name: string; email: string; password: string }): Observable<any> {
        console.log('AUTH signUp');

        const url = `${this.PHP_API_SERVER}/user/create`;

        return this._httpClient.post(url, user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        console.log('AUTH unlockSession');

        const url = `${this.PHP_API_SERVER}/auth/login`;

        return this._httpClient.post(url, credentials);

        //return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    checkUserAuthenticationStatus() {

        console.trace("checkUserAuthenticationStatus")
        const url = `${this.PHP_API_SERVER}/auth/checkAuthenticated/index.php/user`;

        this._httpClient.get(url)
            .pipe(
                catchError((e) => {
                    this.updateUserAuthenticatedStatus(false);
                    return throwError(new Error('Error checking authentication status: ' + e),);
                }),
                switchMap((user: User) => {
                    this._userService.user = user
                    this.updateUserAuthenticatedStatus(true);

                    return this.fuseConfigService.loadConfig().pipe(switchMap(() => {

                        return combineLatest([
                            this._officeService.fetchOffices(),
                            this._businessService.fetchBusinesses()
                        ]);

                    }));

                })
            )
            .subscribe();
    }


    updateUserAuthenticatedStatus(value: boolean) {
        this.isUserAuthenticated.next(value);


    }

    updateOfficeAuthenticatedStatus(value: boolean) {
        this.isOfficeAuthenticated.next(value);


    }

    updateBusinessAuthenticatedStatus(value: boolean) {
        this.isBusinessAuthenticated.next(value);


    }

    // Utility function to get a cookie by name
    getCookie(name: string): string | null {
        const cookieValue = this.cookieService.get(name);
        console.log("COOKIE", cookieValue)

        return cookieValue
    }

    getCookieValue(name: string) {
        const b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    setRedirectUrl(url: string) {
        this.redirectUrl = url;
    }

    // Get the CSRF token from the cookie

    officeAuthenticate(office): Observable<any> {
        console.trace("officeG officeAuthenticate", office.office_name)


        //  console.log("officeG officeAuthenticate", office.office_name)
        return this._httpClient.get(`${this.PHP_API_SERVER}/offices/index.php/${office.id}/entry`)
            .pipe(
                catchError((e) => {
                    this.updateOfficeAuthenticatedStatus(false);
                    return throwError(new Error('Error authenticating office: ' + e),);
                }),
                tap(() => {
                    this._officeService.setCurrentOffice(office);
                    this.updateOfficeAuthenticatedStatus(true);
                }));
    }

    businessAuthenticate(business): Observable<any> {
        console.trace("businessG businessAuthenticate", business.business_name)

        //  console.log("businessG businessAuthenticate", business.business_name)
        return this._httpClient.get(`${this.PHP_API_SERVER}/businesses/index.php/${business.business_id}/entry`)
            .pipe(
                catchError((e) => {
                    this.updateBusinessAuthenticatedStatus(false);
                    return throwError(new Error('Error authenticating business: ' + e),);
                }),
                tap(() => {
                    this._businessService.setCurrentBusiness(business);
                    this.updateBusinessAuthenticatedStatus(true);
                }));
    }



    checkOfficeAuthenticated(): Observable<Office> {
        const url = `${this.PHP_API_SERVER}/auth/checkAuthenticated/index.php/office`;

        return this._httpClient.get(url)
            .pipe(
                catchError((e) => {
                    this.updateOfficeAuthenticatedStatus(false);
                    return throwError(new Error('Error checking office authentication status: ' + e),);
                }),
                tap((office: Office) => {
                    this._officeService.setCurrentOffice(office)
                    this.updateOfficeAuthenticatedStatus(true);


                })
            )
    }
    checkBusinessAuthenticated(): Observable<BusinessModel> {
        const url = `${this.PHP_API_SERVER}/auth/checkAuthenticated/index.php/business`;

        return this._httpClient.get(url)
            .pipe(
                catchError((e) => {
                    this.updateBusinessAuthenticatedStatus(false);
                    return throwError(new Error('Error checking business authentication status: ' + e),);
                }),
                tap((business: BusinessModel) => {
                    this._businessService.setCurrentBusiness(business)
                    this.updateBusinessAuthenticatedStatus(true);


                })
            )
    }

}
