import { NgModule } from '@angular/core';
import { TrapFocusDirective } from './trap-focus.directive';
import { HideTabDirective } from './hideTab.directive';
import { UpperCaserDirective } from './uppercase.directive';
import { CenterMatmenuDirective } from './center-matmenu.directive';
import { BoldParenthesisDirective } from 'app/services/directives/boldParenthesis.directive';

export const DIRECTIVES = [TrapFocusDirective, HideTabDirective, CenterMatmenuDirective, BoldParenthesisDirective];

@NgModule({
    declarations: DIRECTIVES,
    exports: DIRECTIVES
})
export class DirectivesModule { }
