import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'topos-tirisis-eggrafon-svg',
    templateUrl: './topos-tirisis-eggrafon-svg.component.svg',
    styleUrls: ['./topos-tirisis-eggrafon-svg.component.scss']
})
export class ToposTirisisEggrafonSvgComponent {

    constructor() { }



}
