import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
    ItiForm,
    ItiModel,
    DefPosPayForm,
    DefPosPayModel,
    GooIteGdsForm,
    GooIteGdsModel,
    LoadingDtsForm,
    LoadingDtsModel,
    SeaIdSidForm,
    SeaIdSidModel
} from 'app/main/apps/export/declaration/_models';
import { AccountingForm } from 'app/main/apps/export/declaration/_models/gooitegds/accounting/accounting-form.model';
import { AccountingModel } from 'app/main/apps/export/declaration/_models/gooitegds/accounting/accounting.model';
import { BasProForm } from 'app/main/apps/export/declaration/_models/gooitegds/baspro/baspro-form.model';
import { BasProModel } from 'app/main/apps/export/declaration/_models/gooitegds/baspro/baspro.model';
import { CalTaxForm } from 'app/main/apps/export/declaration/_models/gooitegds/caltaxgod/caltaxgod-form.model';
import { CalTaxModel } from 'app/main/apps/export/declaration/_models/gooitegds/caltaxgod/caltaxgod.model';
import { ComCodForm } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm-form.model';
import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { Connr2Form } from 'app/main/apps/export/declaration/_models/gooitegds/connr2/connr2-form.model';
import { Connr2Model } from 'app/main/apps/export/declaration/_models/gooitegds/connr2/connr2.model';
import { GrnForm } from 'app/main/apps/export/declaration/_models/gooitegds/grn/grn-form.model';
import { GrnModel } from 'app/main/apps/export/declaration/_models/gooitegds/grn/grn.model';
import { NatProForm } from 'app/main/apps/export/declaration/_models/gooitegds/natpro/natpro-form.model';
import { NatProModel } from 'app/main/apps/export/declaration/_models/gooitegds/natpro/natpro.model';
import { PacgsForm } from 'app/main/apps/export/declaration/_models/gooitegds/pacgs2/pacgs2-form.model';
import { PacgsModel } from 'app/main/apps/export/declaration/_models/gooitegds/pacgs2/pacgs2.model';
import { PreAdmRefForm } from 'app/main/apps/export/declaration/_models/gooitegds/preadmrefar2/preadmrefar2-form.model';
import { PreAdmRefModel } from 'app/main/apps/export/declaration/_models/gooitegds/preadmrefar2/preadmrefar2.model';
import { ProDocForm } from 'app/main/apps/export/declaration/_models/gooitegds/prodocdc2/prodocdc2-form.model';
import { ProDocModel } from 'app/main/apps/export/declaration/_models/gooitegds/prodocdc2/prodocdc2.model';
import { SpeMenForm } from 'app/main/apps/export/declaration/_models/gooitegds/spemenmt2/spemenmt2-form.model';
import { SpeMenModel } from 'app/main/apps/export/declaration/_models/gooitegds/spemenmt2/spemenmt2.model';
import { TraConce2Form } from 'app/main/apps/export/declaration/_models/gooitegds/traconce2/traconce2-form.model';
import { TraConce2Model } from 'app/main/apps/export/declaration/_models/gooitegds/traconce2/traconce2.model';
import { TraConexForm } from 'app/main/apps/export/declaration/_models/gooitegds/traconex2/traconex2-form.model';
import { TraConexModel } from 'app/main/apps/export/declaration/_models/gooitegds/traconex2/traconex2.model';
import { VehDetForm } from 'app/main/apps/export/declaration/_models/gooitegds/vehdet/vehdet-form.model';
import { VehDetModel } from 'app/main/apps/export/declaration/_models/gooitegds/vehdet/vehdet.model';
import { ImpGooIteGdsForm } from 'app/main/apps/import/_models/gooitegds/gooitegds-form.model';
import { ImpGooIteGdsModel } from 'app/main/apps/import/_models/gooitegds/gooitegds.model';
import { TaxAddEle100Form } from 'app/main/apps/import/_models/gooitegds/taxaddele100/taxaddele100-form.model';
import { TaxAddEle100Model } from 'app/main/apps/import/_models/gooitegds/taxaddele100/taxaddele100.model';
import { Traconco2Form } from 'app/main/apps/import/_models/gooitegds/traconco2/traconco2-form.model';
import { Traconco2Model } from 'app/main/apps/import/_models/gooitegds/traconco2/traconco2.model';
import { TirGooIteGdsForm } from 'app/main/apps/transit/tir/_models/gooitegds-tir/gooitegds-tir-form.model';
import { TirGooIteGdsModel } from 'app/main/apps/transit/tir/_models/gooitegds-tir/gooitegds-tir.model';
import { GuaguaForm } from 'app/main/apps/transit/tir/_models/guagua/guagua-form.model';
import { GuaguaModel } from 'app/main/apps/transit/tir/_models/guagua/guagua.model';
import { GuarefrefForm } from 'app/main/apps/transit/tir/_models/guarefref/guarefref-form.model';
import { GuarefrefModel } from 'app/main/apps/transit/tir/_models/guarefref/guarefref.model';
import { TirPacgsForm } from 'app/main/apps/transit/tir/_models/pacgs2/pacgs2-form.model';
import { TirPacgsModel } from 'app/main/apps/transit/tir/_models/pacgs2/pacgs2.model';
import { TirPreAdmRefForm } from 'app/main/apps/transit/tir/_models/preadmrefar2/preadmrefar2-form.model';
import { TirPreAdmRefModel } from 'app/main/apps/transit/tir/_models/preadmrefar2/preadmrefar2.model';
import { TirProDocForm } from 'app/main/apps/transit/tir/_models/prodocdc2/prodocdc2-form.model';
import { TirProDocModel } from 'app/main/apps/transit/tir/_models/prodocdc2/prodocdc2.model';
import { Sgicodsd2Form } from 'app/main/apps/transit/tir/_models/sgicodsd2/sgicodsd2-form.model';
import { Sgicodsd2Model } from 'app/main/apps/transit/tir/_models/sgicodsd2/sgicodsd2.model';
import { Traconsecgoo013Form } from 'app/main/apps/transit/tir/_models/traconsecgoo013/traconsecgoo013-form.model';
import { Traconsecgoo013Model } from 'app/main/apps/transit/tir/_models/traconsecgoo013/traconsecgoo013.model';
import { Tracorsecgoo021Form } from 'app/main/apps/transit/tir/_models/tracorsecgoo021/tracorsecgoo021-form.model';
import { Tracorsecgoo021Model } from 'app/main/apps/transit/tir/_models/tracorsecgoo021/tracorsecgoo021.model';
import { CusofftrarnsForm } from 'app/main/apps/transit/_models/cusofftrarns/cusofftrarns-form.model';
import { CusofftrarnsModel } from 'app/main/apps/transit/_models/cusofftrarns/cusofftrarns.model';
import { T2LGooIteGdsForm } from 'app/main/apps/transit/_models/gooitegds/gooitegds-form.model';
import { T2LGooIteGdsModel } from 'app/main/apps/transit/_models/gooitegds/gooitegds.model';
import { NctsPreAdmRefForm } from 'app/main/apps/transit/_models/preadmrefar2/preadmrefar2-form.model';
import { NctsPreAdmRefModel } from 'app/main/apps/transit/_models/preadmrefar2/preadmrefar2.model';
import { NctsProDocForm } from 'app/main/apps/transit/_models/prodocdc2/prodocdc2-form.model';
import { NctsProDocModel } from 'app/main/apps/transit/_models/prodocdc2/prodocdc2.model';
import { NctsSpeMenForm } from 'app/main/apps/transit/_models/spemenmt2/spemenmt2-form.model';
import { NctsSpeMenModel } from 'app/main/apps/transit/_models/spemenmt2/spemenmt2.model';
import { VallimnoneclimForm } from 'app/main/apps/transit/_models/vallimnoneclim/vallimnoneclim-form.model';
import { VallimnoneclimModel } from 'app/main/apps/transit/_models/vallimnoneclim/vallimnoneclim.model';
import { TaxesRowModel } from 'app/main/apps/excise/defk/_models/taxesRow/taxes-row.model';
import { NationalProductsModel } from 'app/main/apps/excise/defk/_models/taxesRow/national-products/national-products.model';
import { NationalProductsForm } from 'app/main/apps/excise/defk/_models/taxesRow/national-products/national-products-form.model';
import { ReferenceDocumentsForm } from 'app/main/apps/excise/defk/_models/taxesRow/reference-docs/reference-docs-form.model';
import { ReferenceDocumentsModel } from 'app/main/apps/excise/defk/_models/taxesRow/reference-docs/reference-docs.model';
import { SupplementaryComputationInformationForm } from 'app/main/apps/excise/defk/_models/taxesRow/sup-computation/sup-computation-form.model';
import { SupplementaryComputationInformationModel } from 'app/main/apps/excise/defk/_models/taxesRow/sup-computation/sup-computation.model';
import { TaxesRowForm } from 'app/main/apps/excise/defk/_models/taxesRow/taxes-row-form.model';
import { DEFKVehicleDetailsModel } from 'app/main/apps/excise/defk/_models/taxesRow/vehicle-details/vehicle-details.model';
import { TaxComputationForm } from 'app/main/apps/excise/defk/_models/taxesRow/tax-computation/tax-computation-form.model';
import { TaxComputationModel } from 'app/main/apps/excise/defk/_models/taxesRow/tax-computation/tax-computation.model';
import { SpecialMentionsForm } from 'app/main/apps/excise/defk/_models/taxesRow/special-mentions/special-mentions-form.model';
import { SpecialMentionsModel } from 'app/main/apps/excise/defk/_models/taxesRow/special-mentions/special-mentions.model';
import { DEFKVehicleDetailsForm } from 'app/main/apps/excise/defk/_models/taxesRow/vehicle-details/vehicle-details-form.model';
import { ImpVehDetForm } from 'app/main/apps/import/_models/gooitegds/vehdet/vehdet-form.model';
import { ImpVehDetModel } from 'app/main/apps/import/_models/gooitegds/vehdet/vehdet.model';
import { GrnImpModel } from 'app/main/apps/import/_models/grn_imp/grn-imp.model';
import { GrnImpForm } from 'app/main/apps/import/_models/grn_imp/grn-imp-form.model';
import { PackagingForm, PreviousDocumentsForm } from 'app/main/apps/transit/t2l-pous/_models/pous/pous-form.model';
import { Packaging, PreviousDocuments } from 'app/main/apps/transit/t2l-pous/_models/pous/pous.model';

@Injectable({
    providedIn: 'root'
})
export class FormArrayService {
    constructor(private fb: FormBuilder) { }

    addFormArray(currentDeclaration: FormGroup, formArrayName: string, data?: any, formArrayParent?: FormGroup) {
        const subdomain = currentDeclaration.get('_PRIVATE').get('subdomain').value;

        let currentFormArray = currentDeclaration.get(formArrayName) as FormArray;

        let formGroup;

        switch (formArrayName) {
            case 'ITI': {
                formGroup = this.fb.group(new ItiForm(new ItiModel(data)));
                break;
            }
            case 'DEFPOSPAY': {
                formGroup = this.fb.group(new DefPosPayForm(new DefPosPayModel(data)));
                break;
            }
            case 'GOOITEGDS': {
                formGroup = this.GOOITEGDS(subdomain, data);

                break;
            }
            case 'LoadingDts': {
                currentFormArray = currentDeclaration.get('HEADER').get(formArrayName) as FormArray;

                formGroup = this.fb.group(new LoadingDtsForm(new LoadingDtsModel(data)));

                break;
            }

            case 'SEAIDSID': {
                currentFormArray = currentDeclaration.get('SEAINFSLI').get(formArrayName) as FormArray;

                formGroup = this.fb.group(new SeaIdSidForm(new SeaIdSidModel(data)));

                break;
            }

            case 'GUAGUA': {
                formGroup = this.fb.group(new GuaguaForm(new GuaguaModel(data)));
                break;
            }

            case 'GUAREFREF': {
                currentFormArray = formArrayParent.get(formArrayName) as FormArray;

                formGroup = this.fb.group(new GuarefrefForm(new GuarefrefModel(data)));

                break;
            }

            case 'VALLIMNONECLIM': {
                currentFormArray = formArrayParent.get(formArrayName) as FormArray;

                formGroup = this.fb.group(new VallimnoneclimForm(new VallimnoneclimModel(data)));

                break;
            }

            case 'CUSOFFTRARNS': {

                formGroup = this.fb.group(new CusofftrarnsForm(new CusofftrarnsModel(data)));

                break;
            }


            //DEFK=====================================================================

            case 'ExciseTaxesRow': {
                currentFormArray = currentDeclaration.get('Body').get('DeclarationOfExciseTaxesRegistration').get(formArrayName) as FormArray
                formGroup = this.fb.group(new TaxesRowForm(new TaxesRowModel(data)));
            }
        }
        console.log("ITI currentFormArray befor add", currentFormArray?.value)
        console.log("ITI formGroup befor add", formGroup?.value)

        currentFormArray.push(formGroup);
        console.log("ITI currentFormArray after add", currentFormArray?.value)

    }

    addTaxesRowArray(currentDeclaration: FormGroup, formArrayName: string, GoodsIndex: number, data?: any) {

        const currentGoods = currentDeclaration.get('Body').get('DeclarationOfExciseTaxesRegistration').get('ExciseTaxesRow') as FormArray;

        const currentFormArray = currentGoods.at(GoodsIndex).get(formArrayName) as FormArray;

        let formGroup;

        switch (formArrayName) {
            case 'SupplementaryComputationInformation': {
                formGroup = this.fb.group(new SupplementaryComputationInformationForm(new SupplementaryComputationInformationModel(data)));
                break;
            }
            case 'ReferenceDocuments': {
                formGroup = this.fb.group(new ReferenceDocumentsForm(new ReferenceDocumentsModel(data)));
                break;
            }
            case 'NationalProducts': {
                formGroup = this.fb.group(new NationalProductsForm(new NationalProductsModel(data)));
                break;
            }
            case 'VehicleDetails': {
                formGroup = this.fb.group(new DEFKVehicleDetailsForm(new DEFKVehicleDetailsModel(data)));
                break;
            }
            case 'TaxComputation': {
                formGroup = this.fb.group(new TaxComputationForm(new TaxComputationModel(data)));
                break;
            }
            case 'SpecialMentions': {
                formGroup = this.fb.group(new SpecialMentionsForm(new SpecialMentionsModel(data)));
                break;
            }
        }

        currentFormArray.push(formGroup);


    }
    addPousGooFormArray(currentDeclaration: FormGroup, formArrayName: string, GoodsIndex: number, data?: any) {
        const currentGoodsItems = currentDeclaration.get('ProofInformationT2LT2LF').get('GoodsShipmentForT2LT2LF').get('GoodsItemsForT2LT2LF') as FormArray;

        const currentFormArray = currentGoodsItems.at(GoodsIndex).get(formArrayName) as FormArray;

        let formGroup;

        switch (formArrayName) {
            case 'Packaging': {
                formGroup = this.fb.group(new PackagingForm(new Packaging(data)));
                break;
            }
            case 'PreviousDocuments': {
                formGroup = this.fb.group(new PreviousDocumentsForm(new PreviousDocuments(data)));
                break;
            }


        }

        currentFormArray.push(formGroup);


    }
    addGooFormArray(currentDeclaration: FormGroup, formArrayName: string, GoodsIndex: number, data?: any) {
        const subdomain = currentDeclaration.get('_PRIVATE').get('subdomain').value;

        const currentGoods = currentDeclaration.get('GOOITEGDS') as FormArray;

        const currentFormArray = currentGoods.at(GoodsIndex).get(formArrayName) as FormArray;
        //console.log('ADDFORMARRAY formArrayName', formArrayName);
        //console.log('ADDFORMARRAY CurrentFormArray', currentFormArray);

        let formGroup;

        switch (formArrayName) {
            case 'GRN': {
                if (subdomain === 'IMP_DECL') {
                    formGroup = this.fb.group(new GrnImpForm(new GrnImpModel(data)));
                }
                else {
                    formGroup = this.fb.group(new GrnForm(new GrnModel(data)));
                }

                console.log("GRN ON addGooFormArray", formGroup.value)
                break;
            }
            case 'CONNR2': {
                formGroup = this.fb.group(new Connr2Form(new Connr2Model(data)));
                break;
            }
            case 'VEHDET': {

                formGroup = this.fb.group(new ImpVehDetForm(new ImpVehDetModel(data)));

                if (subdomain === 'EXP_DECL') {
                    formGroup = this.fb.group(new VehDetForm(new VehDetModel(data)));
                }


                break;
            }

            case 'BASPRO': {
                formGroup = this.fb.group(new BasProForm(new BasProModel(data)));
                break;
            }
            case 'NATPRO': {
                formGroup = this.fb.group(new NatProForm(new NatProModel(data)));
                break;
            }
            case 'CALTAXGOD': {
                formGroup = this.fb.group(new CalTaxForm(new CalTaxModel(data)));
                break;
            }
            case 'PACGS2': {
                formGroup = this.PACGS2(subdomain, data);
                break;
            }
            case 'SPEMENMT2': {
                formGroup = this.SPEMENMT2(subdomain, data);
                break;
            }
            case 'PRODOCDC2': {
                formGroup = this.PRODOCDC2(subdomain, data);
                break;
            }
            case 'PREADMREFAR2': {
                formGroup = this.PREADMREFAR2(subdomain, data);
                break;
            }
            case 'TAXADDELE100': {
                formGroup = this.fb.group(new TaxAddEle100Form(new TaxAddEle100Model(data)));
                break;
            }
            case 'SGICODSD2': {
                formGroup = this.fb.group(new Sgicodsd2Form(new Sgicodsd2Model(data)));
                break;
            }

            //====================================This is used for calculating purposes only==============================
            //==============================it should be saved but NOT submitted to the ICISNet===========================

            case 'ACCOUNTING': {
                formGroup = this.fb.group(new AccountingForm(new AccountingModel(data)));
                break;
            }

            /// The below are not actual Arrays on the form,
            //we  transform them to arrays, just to maintain the order within the form

            case 'COMCODGODITM': {
                formGroup = this.fb.group(new ComCodForm(new ComCodModel(data)));
                break;
            }
            case 'TRACONEX2': {
                formGroup = this.fb.group(new TraConexForm(new TraConexModel(data)));
                break;
            }
            case 'TRACONCE2': {
                formGroup = this.fb.group(new TraConce2Form(new TraConce2Model(data)));
                break;
            }
            case 'TRACONCO2': {
                formGroup = this.fb.group(new Traconco2Form(new Traconco2Model(data)));
                break;
            }
            case 'TRACORSECGOO021': {
                formGroup = this.fb.group(new Tracorsecgoo021Form(new Tracorsecgoo021Model(data)));
                break;
            }
            case 'TRACONSECGOO013': {
                formGroup = this.fb.group(new Traconsecgoo013Form(new Traconsecgoo013Model(data)));
                break;
            }
            //=========================================================================
            //=========================================================================

        }
        currentFormArray.push(formGroup);
    }

    deleteFormArray(formArrayName: string, FormArrayIndex: number, parentFormGroup: FormGroup) {
        const currentFormArray = parentFormGroup.get(formArrayName) as FormArray;

        currentFormArray.removeAt(FormArrayIndex);

        /*  */
    }

    GOOITEGDS(subdomain, data) {
        if (subdomain === 'EXP_DECL') {
            return this.fb.group(new GooIteGdsForm(new GooIteGdsModel(data)));
        } else if (subdomain === 'IMP_DECL') {
            return this.fb.group(new ImpGooIteGdsForm(new ImpGooIteGdsModel(data)));
        } else if (subdomain === 'T2L') {
            return this.fb.group(new T2LGooIteGdsForm(new T2LGooIteGdsModel(data)));
        } else if (subdomain === 'TIR') {
            return this.fb.group(new TirGooIteGdsForm(new TirGooIteGdsModel(data)));
        }
    }

    PACGS2(subdomain, data) {
        if (subdomain === 'EXP_DECL' || subdomain === 'IMP_DECL') {
            return this.fb.group(new PacgsForm(new PacgsModel(data)));
        } else if (subdomain === 'TIR') {
            return this.fb.group(new TirPacgsForm(new TirPacgsModel(data)));
        }
    }
    SPEMENMT2(subdomain, data) {
        if (subdomain === 'EXP_DECL' || subdomain === 'IMP_DECL') {
            return this.fb.group(new SpeMenForm(new SpeMenModel(data)));
        } else if (subdomain === 'T2L' || subdomain === 'TIR') {
            return this.fb.group(new NctsSpeMenForm(new NctsSpeMenModel(data)));
        }
    }
    PRODOCDC2(subdomain, data) {
        if (subdomain === 'EXP_DECL' || subdomain === 'IMP_DECL') {
            return this.fb.group(new ProDocForm(new ProDocModel(data)));
        } else if (subdomain === 'T2L') {
            return this.fb.group(new NctsProDocForm(new NctsProDocModel(data)));
        } else if (subdomain === 'TIR') {
            return this.fb.group(new TirProDocForm(new TirProDocModel(data)));
        }
    }
    PREADMREFAR2(subdomain, data) {
        if (subdomain === 'EXP_DECL' || subdomain === 'IMP_DECL') {
            return this.fb.group(new PreAdmRefForm(new PreAdmRefModel(data)));
        } else if (subdomain === 'T2L') {
            return this.fb.group(new NctsPreAdmRefForm(new NctsPreAdmRefModel(data)));
        } else if (subdomain === 'TIR') {
            return this.fb.group(new TirPreAdmRefForm(new TirPreAdmRefModel(data)));
        }
    }
}
