import { TrarepForm } from './../trarep/trarep-form.model';
import { AbstractControl, FormGroup, FormControl } from "@angular/forms";
import { CancelHeaheaForm } from "app/main/apps/export/cancel-declaration/_models/heahea/heahea-form.model";
import { CancelHeaheaModel } from "app/main/apps/export/cancel-declaration/_models/heahea/heahea.model";
import { TraconceForm, TraconceModel } from "app/main/apps/export/declaration/_models";
import { PrivateControlsFormModel } from "app/model/private-controls-form.model";
import { PrivateControlsModel } from "app/model/private-controls.model";
import { ImpcusoffForm } from "../impcusoff/impcusoff-form.model";
import { ImpcusoffModel } from "../impcusoff/impcusoff.model";
import { TrarepModel } from "../trarep/trarep.model";
import { ImportCancelDeclarationModel } from "./declaration.model";

export class ImportCancelDeclarationForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    MesSenMES3: FormControl;
    MesRecMES6: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    PriMES15: FormControl;
    TesIndMES18: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    CorIdeMES25: FormControl;

    HEAHEA: FormGroup;
    TRACONCE1: FormGroup;
    TRAREP: FormGroup;
    IMPCUSOFF: FormGroup;

    /*

   */

    constructor(declaration: ImportCancelDeclarationModel, subdomain?: string, messageType?: string) {

        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));


        this.MesSenMES3 = new FormControl(declaration.MesSenMES3);
        this.MesRecMES6 = new FormControl(declaration.MesRecMES6);
        this.DatOfPreMES9 = new FormControl(declaration.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(declaration.TimOfPreMES10);
        this.PriMES15 = new FormControl(declaration.PriMES15);
        this.TesIndMES18 = new FormControl(declaration.TesIndMES18);
        this.MesIdeMES19 = new FormControl(declaration.MesIdeMES19);
        this.MesTypMES20 = new FormControl(declaration.MesTypMES20);
        this.CorIdeMES25 = new FormControl(declaration.CorIdeMES25);

        this.HEAHEA = new FormGroup(new CancelHeaheaForm(new CancelHeaheaModel(declaration.HEAHEA)));

        this.TRACONCE1 = new FormGroup(new TraconceForm(new TraconceModel(declaration.TRACONCE1)));

        this.TRAREP = new FormGroup(new TrarepForm(new TrarepModel(declaration.TRAREP)))

        this.IMPCUSOFF = new FormGroup(new ImpcusoffForm(new ImpcusoffModel(declaration.IMPCUSOFF)));


    }
}
