<div class="beer-header">
    <div [formGroup]="beerForm" class="noPrint" style="display:flex;justify-content:space-between;width:100%">
        <table style="width:50%">
            <colgroup>
                <col style="width:28%">
                <col style="width:50%">
                <col style="width:22%">

            </colgroup>
            <tbody>
                <tr>
                    <td>Ζυθοποιός</td>
                    <td style="line-height:2.3">
                        <mat-form-field class="excel-form-field sc-ul s-30">
                            <autocomplete-control formControlName="customer" class="autocomplete-excel-input"
                                [options]="pelates" [config]="pelConfig" (keyDown.Escape)="$event.stopPropagation();
                                $event.preventDefault();">
                            </autocomplete-control>

                        </mat-form-field>
                    </td>
                    <td>
                        <button mat-raised-button class=" accent" style="font-size:12px; line-height: 2.4;"
                            (click)="createContact()">
                            Νέος Πελάτης
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>Ανεξάρτ. Μικρός Ζυθοποιός</td>
                    <td class="noPrint">
                        <select class="text-bold" style="padding: 0px 15px; background: white;"
                            formControlName="smallProducer">
                            <option [value]="0">OXI</option>
                            <option [value]="1">NAI</option>
                        </select>

                    </td>
                <tr>
                    <td>Καθεστώς Μειωμένου ΦΠΑ</td>
                    <td class="noPrint">
                        <select class="text-bold" style="padding: 0px 15px; background: white;"
                            formControlName="reducedVat">
                            <option [value]="0">OXI</option>
                            <option [value]="1">NAI</option>
                        </select>

                    </td>
                </tr>

                <tr>
                    <td>Κόστος Παραγωγής (EUR/lt)</td>
                    <td colspan="2">
                        <input style="width:70px" class="excel-input text-bold" formControlName="masterProduceValue">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>


    <div class="printOnly" [formGroup]="beerForm">
        <div style="display:flex;justify-content:space-between;width:100%">
            <table style="width:45%">
                <colgroup>
                    <col style="width:45%">
                    <col style="width:55%">
                </colgroup>
                <tbody>
                    <tr>
                        <td class="text-left">Ζυθοποιός</td>
                        <td class="text-bold text-left">
                            {{beerForm.get('customer')?.value?.fullname}}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">Ανεξάρτ. Μικρός Ζυθοποιός</td>
                        <td class="text-bold text-left">
                            <span *ngIf="beerForm.get('smallProducer').value==='0'">OXI</span>
                            <span *ngIf="beerForm.get('smallProducer').value==='1'">NAI</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="text-left">Καθεστώς Μειωμένου ΦΠΑ</td>
                        <td class="text-bold text-left">
                            <span *ngIf="beerForm.get('reducedVat').value==='0'">OXI</span>
                            <span *ngIf="beerForm.get('reducedVat').value==='1'">NAI</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>