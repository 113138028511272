<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <a mat-button>TradeXT - Customs Brokers © 2022-{{currentYear}}</a>
        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button [routerLink]="PATHS.documentation">Documentation</a>
            <span>&bull;</span>
            <a mat-button [routerLink]="PATHS.changeLog">Changelog</a>
        </div>

    </div>

</mat-toolbar>