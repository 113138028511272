import { DatePipe } from '@angular/common';
import { FormArray, FormGroup } from '@angular/forms';
import { Custom } from 'app/main/apps/customs/customs.model';
import { ApiService } from 'app/services/api.service';
import { Observable, Subject, map, takeUntil, tap, switchMap, take } from 'rxjs';
import { DistillationLicenceAppService } from './../distillation-licence-app.service';
import { Component, Inject, OnInit } from '@angular/core';
import { GeneralApplicationsService } from 'app/main/apps/general-applications/general-applications.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-distillation-licence-app',
    templateUrl: './distillation-licence-app.component.html',
    styleUrls: ['./distillation-licence-app.component.scss'],
    providers: [DistillationLicenceAppService]

})
export class DistillationLicenceAppComponent implements OnInit {


    dilosiForm$: Observable<FormGroup>;
    dilosiForm: FormGroup;
    producerDetails: FormGroup;
    winery: FormGroup;
    ownStems: FormGroup;
    returnedStems: FormGroup;
    grapes: FormGroup;
    boilerInfo: FormGroup;


    customs: Custom[] = []


    private _destroy = new Subject<void>();
    constructor(
        private dilosiService: DistillationLicenceAppService,
        private dateTransform: DatePipe,
        private dbQuery: ApiService,
        private generalApplicationService: GeneralApplicationsService,
        @Inject(MAT_DIALOG_DATA) private _data: { tsipouraForm: FormGroup, index: number },

    ) {

        this.dbQuery.get_customs('GR').subscribe(customs => {

            this.customs = customs
        })
    }

    ngOnInit(): void {

        this.dilosiForm$ = this.dilosiService.dilosiForm$.pipe(map((form, index) => {


            this.dilosiForm = form
            this.producerDetails = this.dilosiForm.get('producerDetails') as FormGroup;
            this.ownStems = this.dilosiForm.get('ownStems') as FormGroup;
            this.returnedStems = this.dilosiForm.get('returnedStems') as FormGroup;
            this.grapes = this.dilosiForm.get('grapes') as FormGroup;
            this.winery = this.dilosiForm.get('winery') as FormGroup;
            this.boilerInfo = this.dilosiForm.get('boilerInfo') as FormGroup;

            this.dilosiForm.get('date').setValue(this.dateTransform.transform(new Date(), 'dd/MM/yyyy'))


            console.log("DistLice PASSED DATA: ", this._data)

            if (this._data && index === 0) {


                this.generalApplicationService.allApplications$.pipe(takeUntil(this._destroy)).subscribe(apps => {

                    const boilerOwner = this._data.tsipouraForm.get('boilerOwner').value || null;
                    const boilerOwnerAfm = boilerOwner ? boilerOwner.afm : null;
                    const producer = (this._data.tsipouraForm.get('tsipouraCalculationData') as FormArray).at(this._data.index) as FormGroup;
                    const producerAfm = producer.get('producerAfm').value || null;

                    console.log("DistLice apps: ", apps)
                    console.log("DistLice boilerOwner: ", boilerOwner)
                    console.log("DistLice boilerOwnerAfm: ", boilerOwnerAfm)

                    const savedProducerAfm = apps.find(app => app.applicationForm.producerDetails.producerId === producerAfm)
                    console.log("DistLice savedProducerAfm: ", savedProducerAfm)

                    //both producer and boiler owner match
                    const savedApplicationBoth = apps.find(app => app.customer?.afm === boilerOwnerAfm && app.applicationForm.producerDetails.producerId === producerAfm)

                    //only producer matches
                    const savedApplicationProducer = apps.find(app => app.applicationForm.producerDetails.producerId === producerAfm)

                    //only boiler owner matches
                    const savedApplicationBoiler = apps.find(app => app.customer?.afm === boilerOwnerAfm)

                    //console.log("DistLice savedApplicationBoth: ", savedApplicationBoth)
                    //console.log("DistLice savedApplicationProducer: ", savedApplicationProducer)
                    //console.log("DistLice savedApplicationBoiler: ", savedApplicationBoiler)


                    if (savedApplicationBoth) {
                        console.log("DistLice savedApplicationBoth: TRIGGERED ", savedApplicationBoth)

                        //If there is a saved application form with both producer and boiler owner, load it and patch the calculation data
                        this.dilosiService.loadSavedApplication(savedApplicationProducer.applicationForm)
                        this.dilosiService.patchRestOfDilosi(this._data.tsipouraForm, this._data.index)

                    }

                    else if (savedApplicationBoiler) {
                        console.log("DistLice savedApplicationBoiler: TRIGGERED", savedApplicationBoiler)

                        //If there is a saved application form with the boiler owner already saved,load it and patch only the producer and the calculation data
                        this.dilosiService.loadSavedApplication(savedApplicationProducer.applicationForm)
                        this.dilosiService.patchProducerOnDilosi(this._data.tsipouraForm, this._data.index)
                        this.dilosiService.patchRestOfDilosi(this._data.tsipouraForm, this._data.index)

                    }
                    else if (savedApplicationProducer) {
                        // //If there is a saved application form with the producer already saved but with a different boiler owner,load it and patch only the boiler Owner and the calculation data
                        console.log("DistLice savedApplicationProducer: TRIGGERED", savedApplicationProducer)

                        this.dilosiService.loadSavedApplication(savedApplicationProducer.applicationForm)
                        this.dilosiService.patchBoilerOwnerOnDilosi(this._data.tsipouraForm, this._data.index)
                        this.dilosiService.patchRestOfDilosi(this._data.tsipouraForm, this._data.index)


                    }
                    else {
                        console.log("TDistLice ELSE IS TRIGGERED!")
                        //If there is no saved application form with the producer or the boiler owner, patch the producer and the boiler owner and the calculation data
                        this.dilosiService.patchProducerOnDilosi(this._data.tsipouraForm, this._data.index)
                        this.dilosiService.patchBoilerOwnerOnDilosi(this._data.tsipouraForm, this._data.index)
                        this.dilosiService.patchRestOfDilosi(this._data.tsipouraForm, this._data.index)
                    }



                })
            }
            return form;

        }));


        this.generalApplicationService.save$.pipe(takeUntil(this._destroy)).pipe(switchMap(_ => {
            const postData = this.dilosiService.getPostData()

            return this.generalApplicationService.saveApplication(postData)
        })).subscribe()

        this.generalApplicationService.load$.pipe(takeUntil(this._destroy)).subscribe(dilosiModel => {

            this.dilosiService.loadSavedApplication(dilosiModel)
        })
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }



}
