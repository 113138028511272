export class DeliveryDetailsType {
    TransportVehicleIdentificationNumber?: string;
    TransportVehicleCountry?: string;
    NationalTransportMode?: string;
    DipennakNumber?: string;


    constructor(value?) {
        this.TransportVehicleIdentificationNumber = value?.TransportVehicleIdentificationNumber || null;
        this.TransportVehicleCountry = value?.TransportVehicleCountry || null;
        this.NationalTransportMode = value?.NationalTransportMode || null;
        this.DipennakNumber = value?.DipennakNumber || null;

    }
}
