import { _pelatesItem } from "app/model/api-model";
import { PrivateControlsModel } from "app/model/private-controls.model";
import { EMCSHeaderModel } from "../../../_models/emcs-header.model";
import { VA10ABodyModel } from "./Body/body.model";

export class VA10ADeclarationModel {
    _PRIVATE: PrivateControlsModel;

    Header: EMCSHeaderModel;
    Body: VA10ABodyModel;

    constructor(value?) {

        // console.log("___DeclarationModel constructor", value);
        this._PRIVATE = value?._PRIVATE || null;

        this.Header = value?.Header;

        if (value.Body.VehicleArrivalDeclarationCancellation) {
            this.Body = value?.Body;
        }
        else {
            this.Body = new VA10ABodyModel();
        }
    }
}