import { TradecModel } from 'app/main/apps/export/declaration/_models';
import { Traconco1Model } from 'app/main/apps/import/_models/traconco1/traconco1.model';
import { User, _pelatesItem, international_customersItem } from 'app/model/api-model';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { CusoffdepeptModel } from '../../../_models/cusoffdepept/cusoffdepept.model';
import { CusoffdesestModel } from '../../../_models/cusoffdesest/cusoffdesest.model';
import { T2LGooIteGdsModel } from '../../../_models/gooitegds/gooitegds.model';
import { T2LHeaheaTF013AModel } from '../../../_models/heahea-TF013A/heaheaTF013A.model';
import { T2LHeaheaModel } from '../../../_models/heahea/heahea.model';

export class T2LDeclarationModel {
    _PRIVATE: PrivateControlsModel;

    MesSenMES3?: string;
    MesRecMES6?: string;
    DatOfPreMES9?: string;
    TimOfPreMES10?: string;
    PriMES15?: string;
    TesIndMES18?: string;
    MesIdeMES19?: string;
    MesTypMES20?: string;
    MesSeqNumMES22?: string;
    FirAndLasTraMES23?: string;
    //CorIdeMES25?: string;

    HEAHEA?: T2LHeaheaTF013AModel | T2LHeaheaModel;
    TRACONCO1?: Traconco1Model;
    CUSOFFDEPEPT?: CusoffdepeptModel;
    CUSOFFDESEST?: CusoffdesestModel;
    GOOITEGDS?: T2LGooIteGdsModel[];
    TRADEC?: TradecModel;
    //
    constructor(value?) {
        this._PRIVATE = value?._PRIVATE || null;

        this.MesSenMES3 = value?.MesSenMES3 || null;
        this.MesRecMES6 = value?.MesRecMES6 || null;
        this.DatOfPreMES9 = value?.DatOfPreMES9 || null;
        this.TimOfPreMES10 = value?.TimOfPreMES10 || null;
        this.PriMES15 = value?.PriMES15 || null;
        this.TesIndMES18 = value?.TesIndMES18 || null;
        this.MesIdeMES19 = value?.MesIdeMES19 || null;
        this.MesTypMES20 = value?.MesTypMES20 || 'TF015A';
        this.MesSeqNumMES22 = value?.MesSeqNumMES22 || null;
        this.FirAndLasTraMES23 = value?.FirAndLasTraMES23 || null;

        this.HEAHEA = value?.HEAHEA;
        this.TRACONCO1 = value?.TRACONCO1;
        this.CUSOFFDEPEPT = value?.CUSOFFDEPEPT;
        this.CUSOFFDESEST = value?.CUSOFFDESEST;
        this.GOOITEGDS = value?.GOOITEGDS;
        this.TRADEC = value?.TRADEC;
    }
}
