<ng-container *ngIf="arrAtExitForm">
    <div id="arrAtExit-dialog-content" fxLayout="column" style="width: 100%; flex-grow: 1">
        <div mat-dialog-title class="primary">
            <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 20%">
                <!--   <button
                mat-icon-button
                [matMenuTriggerFor]="moreMenu"
                matTooltip="Επιλογές"
                class="sidebar-toggle mr-12"
            >
                <mat-icon>menu</mat-icon>
            </button>
 -->
                <span>Άφιξη στην έξοδο</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" style="margin-left: 70px">
                <h3>
                    <span *ngIf="stateCode">{{
                        stateCode | declarationState
                        }}</span>
                </h3>

                <div *ngIf="
                    (arrAtExitForm.valid ||
                        arrAtExitForm.status === 'DISABLED') &&
                    stateCode !== 'Cancelled' &&
                    stateCode !== 'Pre-Submitted' &&
                    stateCode !== 'Edit' &&
                    stateCode !=='Goods Ready To Be Released' &&
                    stateCode !== 'Goods Not Allowed to Exit'

                " class="m-0" id="b-circle">
                    <div class="outer-circle"></div>

                    <div class="circle" [ngClass]="{
                        warning:
                            stateCode === 'Sent' ||
                            stateCode === 'Submitted' ||
                            stateCode === 'Under Amendment' ||
                            stateCode === 'Cancellation Submitted',
                        danger: stateCode === 'Rejected',
                        success:
                            stateCode === 'Accepted' ||
                            stateCode === 'To be Accepted',
                        primary: stateCode === 'Cancelled',
                        accent:
                            stateCode === 'Pre-Submitted' ||
                            stateCode === 'Edit'
                    }"></div>

                    <!-- EDIT REJECTED FORM -->

                    <button *ngIf="stateCode === 'Rejected'" class="sendButton" style="top: -3px"
                        (click)="editDeclaration()" matTooltip="Διόρθωση & Επανάληψη Αποστολής" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                    <!-- //EDIT REJECTED FORM -->
                    <!-- FORM SUBMITTED CHECK STATE -->

                    <button *ngIf="
                        stateCode === 'Submitted' ||
                        stateCode === 'Sent' ||
                        stateCode === 'Under Amendment' ||
                        stateCode === 'Cancellation Submitted' ||
                        stateCode === 'Accepted' ||
                        stateCode === 'To be Accepted'
                    " class="sendButton" style="top: -3px" (click)="checkState()" mat-icon-button
                        matTooltip="Έλεγχος Κατάστασης">
                        <mat-icon>autorenew</mat-icon>
                    </button>
                    <!-- ///FORM SUBMITTED CHECK STATE -->
                </div>

                <div *ngIf="
                    stateCode === 'Cancelled' ||
                    stateCode === 'Pre-Submitted' ||
                    stateCode === 'Edit' ||
                    stateCode ==='Goods Ready To Be Released' ||
                    stateCode === 'Goods Not Allowed to Exit'
                " class="m-0" id="b-circle"></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">
                <button mat-icon-button (click)="onCloseConfirm()">
                    <mat-icon> highlight_off</mat-icon>
                </button>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" mat-dialog-content style="
            overflow-y: auto;
            min-height: 100px;
            height: 100px;
            padding: 0 16px;
        " [formGroup]="arrAtExitForm">
            <!-- LEFT SIDE -->
            <div fxLayout="column" class="group" style="width: 50%; padding-left: 1%">
                <h2>Βασικά Στοιχεία</h2>

                <!-- FIRST ROW -->
                <table class="arrAtExitHea fourColumns" [formGroup]="heahea">
                    <tbody>
                        <tr>
                            <td>Ημ/νία Ειδοποίησης Άφιξης</td>
                            <td>
                                <mat-form-field appearance="outline" class="s-l sc-ul s-12">
                                    <date-select formControlName="ArrNotDatHEA141" [dateType]="dateType"
                                        [defaultDate]="defaultDate" [tabindex]="-1" [readonly]="true">
                                    </date-select>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>Παραστατικό / Αριθμός Αναφοράς</td>
                            <td>
                                <mat-form-field class="s-l sc-ul s-20" appearance="outline">
                                    <input matInput formControlName="DocNumHEA5" maxlength="21" required />
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>Τελωνείο</td>
                            <td>
                                <mat-form-field class="s-l sc-ul s-21" appearance="outline"
                                    [ngClass]="{'disabled-field': customsPlace.disabled && !formDisabled}">
                                    <input matInput formControlName="CusSubPlaHEA66" maxlength="17" />
                                </mat-form-field>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                Κωδικός Συμφωνειθήσας
                                Τοποθεσίας Άφιξης
                            </td>
                            <td>
                                <mat-form-field appearance="outline" class="s-l sc-ul s-21"
                                    [ngClass]="{'disabled-field': agreedLocCode.disabled && !formDisabled}">

                                    <input #ArrAgrLocCode formControlName="ArrAgrLocCodHEA62" matInput maxlength="17" />
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Συμφωνειθήσα Τοποθεσία
                                Άφιξης
                            </td>
                            <td>
                                <mat-form-field class="s-l sc-ul s-30" appearance="outline"
                                    [ngClass]="{'disabled-field': agreedLoc.disabled && !formDisabled}">

                                    <input #ArrAgrLoc matInput formControlName="ArrAgrLocOfGooHEA63" maxlength="35" />
                                </mat-form-field>
                            </td>
                        </tr>

                        <tr>
                            <td>Τόπος Ειδοποίησης Άφιξης</td>
                            <td>
                                <mat-form-field class="s-l sc-ul s-30" appearance="outline">
                                    <input #ArrNotPlace matInput formControlName="ArrNotPlaHEA60" maxlength="35"
                                        required />
                                </mat-form-field>
                            </td>
                        </tr>

                        <tr>
                            <td>Αποθήκευση</td>
                            <td>
                                <mat-form-field class="s-l sc-ul s-14 dont-float" floatLabel="never"
                                    appearance="outline" required>
                                    <mat-label>Επιλογή</mat-label>
                                    <mat-select name="StoFlaHEA1" formControlName="StoFlaHEA1">
                                        <mat-option value="0"> Όχι </mat-option>
                                        <mat-option value="1"> Ναι </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <mat-expansion-panel #prevDocPanel>
                                    <mat-expansion-panel-header class="specific-class"
                                        (click)="expandPanel(prevDocPanel, $event)">
                                        <mat-panel-title style="margin: 0 0.25em">
                                            Προαιρετικά Πεδία
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <ng-template matExpansionPanelContent>
                                        <div id="pd-additional-fields" fxLayout="column">
                                            <div id="pd-add-fields-1" fxLayout="row" fxLayoutAlign="space-between">
                                                <table>
                                                    <tr>
                                                        <td>
                                                            Γλώσσα Τόπου Ειδοποίησης
                                                            Άφιξης
                                                        </td>
                                                        <td>
                                                            <mat-form-field class="sc-ul s-l" appearance="outline"
                                                                required>
                                                                <language-select formControlName="ArrNotPlaHEA60LNG">
                                                                </language-select>
                                                            </mat-form-field>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Γλώσσα Τόπου Άφιξης
                                                            Εμπορευμάτων
                                                        </td>
                                                        <td>
                                                            <mat-form-field class="sc-ul s-l" appearance="outline">
                                                                <language-select
                                                                    formControlName="ArrAgrLocOfGooHEA63LNG">
                                                                </language-select>
                                                            </mat-form-field>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Εγκεκριμένες Αποθήκες
                                                            Εμπ/των
                                                        </td>
                                                        <td>
                                                            <mat-form-field class="s-l sc-ul" appearance="outline">
                                                                <input matInput formControlName="AuthStorLocGoodHEA416"
                                                                    maxlength="21" />
                                                            </mat-form-field>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Ένδειξη Γλώσσας Διαλόγου
                                                            στον Προορισμό
                                                        </td>
                                                        <td>
                                                            <mat-form-field class="sc-ul s-l" appearance="outline">
                                                                <language-select formControlName="DiaLanIndAtDesHEA255">
                                                                </language-select>
                                                            </mat-form-field>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </ng-template>
                                </mat-expansion-panel>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- RIGHT SIDE -->
            <div fxLayout="column" fxLayoutAlign="center start"
                style="width: 45%; padding-left: 1%; margin-bottom: 16px">
                <div fxLayout="column" fxLayoutAlign="center start" class="group">
                    <h2>Πραγματικό Τελωνείο Εξόδου</h2>
                    <table class="arrAtExitHea fourColumns-right" [formGroup]="aceace">
                        <tbody>

                            <tr>
                                <td>Τελωνείο</td>
                                <td>
                                    <mat-form-field appearance="outline" class="s-c sc-ul s-4">
                                        <country-select formControlName="_countryCode" [customsCountries]="true">
                                        </country-select>
                                    </mat-form-field>
                                </td>

                                <td>
                                    <mat-form-field class="s-c sc-ul s-12" appearance="outline" required>
                                        <autocomplete-control formControlName="RefNumACE1" [options]="customs"
                                            [config]="config">
                                        </autocomplete-control>
                                    </mat-form-field>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div fxLayout="column" fxLayoutAlign="center start" class="group">
                    <h2>Μεταφορέας</h2>
                    <table class="arrAtExitHea fourColumns-right" [formGroup]="traexicar">
                        <tbody>
                            <tr>
                                <td>Επωνυμία</td>
                                <td>
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-40">
                                        <autocomplete-control formControlName="CarrierObject" [options]="carriers"
                                            [config]="consConfig">
                                        </autocomplete-control>
                                        <mat-error *ngIf="
                                            carrier.hasError('required') &&
                                            carrier.touched
                                        ">Required</mat-error>
                                    </mat-form-field>
                                </td>
                                <td colspan="2"></td>

                            </tr>
                            <tr>
                                <td>EORI/TIN</td>

                                <td colspan="3">
                                    <mat-form-field appearance="outline" class="s-l sc-ul s-18" required>
                                        <input matInput formControlName="TINTEC2" readonly tabindex="-1" />
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <mat-expansion-panel #carrierPanel>
                                        <mat-expansion-panel-header class="specific-class"
                                            (click)="expandPanel(carrierPanel, $event)">
                                            <mat-panel-title style="margin: 0 0.25em">
                                                <b>Διεύθυνση/Γλώσσα</b>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <ng-template matExpansionPanelContent>
                                            <div id="pd-additional-fields" fxLayout="column">
                                                <div id="pd-add-fields-1" fxLayout="row" fxLayoutAlign="space-between">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Διεύθυνση</td>
                                                                <td>
                                                                    <mat-form-field appearance="outline"
                                                                        class="s-l sc-ul">
                                                                        <input matInput
                                                                            formControlName="StrAndNumTEC2" />
                                                                    </mat-form-field>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Τ.Κ.</td>
                                                                <td>
                                                                    <mat-form-field appearance="outline"
                                                                        class="s-l sc-ul">
                                                                        <input matInput formControlName="PosCodTEC1" />
                                                                    </mat-form-field>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Πόλη</td>
                                                                <td>
                                                                    <mat-form-field appearance="outline"
                                                                        class="s-l sc-ul">
                                                                        <input matInput formControlName="CitTEC1" />
                                                                    </mat-form-field>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Χώρα</td>
                                                                <td>
                                                                    <mat-form-field appearance="outline"
                                                                        class="s-l sc-ul">
                                                                        <country-select formControlName="CouCodTEC1"
                                                                            placeholder="Χώρα">
                                                                        </country-select>
                                                                    </mat-form-field>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Γλώσσα</td>
                                                                <td>
                                                                    <mat-form-field appearance="outline"
                                                                        class="s-l sc-ul">
                                                                        <language-select formControlName="TRAEXICARLNG"
                                                                            placeholder="Γλώσσα"></language-select>
                                                                    </mat-form-field>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </mat-expansion-panel>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
                <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                    (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="onCloseConfirm()">
                    Ακυρο
                </button>
                <button style="width: auto !important" mat-raised-button
                    [disabled]="!arrAtExitForm.valid || arrAtExitForm.disabled" [ngClass]="color2"
                    (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onSubmit()">
                    Υποβολή
                </button>
            </mat-dialog-actions>
        </div>
    </div>
</ng-container>