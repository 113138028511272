import { Directive, NgModule, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[content-container]'
})
export class ContentContainerDirective {
    constructor(public viewContainerRef: ViewContainerRef) { }
}


@NgModule({
    declarations: [ContentContainerDirective],
    exports: [ContentContainerDirective]
})
export class ContentContainerDirectiveModule { }
