import { Office } from 'app/model/office.model';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { filter, map, first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { OfficeService } from 'app/services/office.service';
import { BusinessService } from 'app/main/businesses/services/business.service';
import { BusinessModel } from 'app/main/businesses/invoices/_models/business/business.model';

export interface AppResolverData {
    offices: Office[];
    businesses: BusinessModel[];
}


@Injectable()
export class AppResolver implements Resolve<any> {
    // declaration_id: number;

    constructor(
        private officeService: OfficeService,
        private businessService: BusinessService
    ) {
        console.log('App Resolver hit');
    }

    /**
     * Resolver
     *
     * @param route
     * @param state
     * @returns
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {


        console.log("APP RESOLVER HIT", route)
        const offices$ = this.officeService.fetchOffices();
        const businesses$ = this.businessService.fetchBusinesses();

        const combine$ = combineLatest([offices$, businesses$], (offices, businesses) => <AppResolverData>{ offices, businesses }).pipe(
            filter((value, index) => {
                return value.offices && value.businesses ? true : false;
            }),
            map(value => {
                console.log('App Resolver Map & Complete:', value);


                return value;
            }),
            first()
        );

        return combine$;
    }
}
