import { ePaymentResponseModel } from './_models/e-payment-response.model';
import { RemittanceInput } from './../../../services/openDialogs.service';
import { RemittanceForm } from './_models/remittance-form.model';
import { ApiNodeService, EPaymentsDocArgs } from 'app/services/api.node.service';
import { ApiService } from 'app/services/api.service';
import { ePaymentICISForm } from './_models/e-payment-icis-form.model';
import { OpenDialogsService } from 'app/services/openDialogs.service';
import { ePaymentContainForm } from './_models/e-payment-contain-form.model';
import { Injectable } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, switchMap, of, take } from 'rxjs';
import { ePaymentContainModel } from './_models/e-payment-contain.model';
import { ePaymentICISModel } from './_models/e-payment-icis.model';
import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { RemittanceModel } from './_models/remittance.model';
import { EPaymentForm } from './_models/e-payment-form.model';
import { EPaymentModel } from './_models/e-payment.model';
import { environment } from 'environments/environment';
import { PrivateControlsModel } from 'app/model/private-controls.model';
import { FuseUtils } from '@fuse/utils';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class EPaymentService {


    getPaymentIdDoc = false;
    remittanceForm$: Observable<FormGroup>;

    private remittanceForm: BehaviorSubject<FormGroup>;


    constructor(private openDialogsService: OpenDialogsService, private dbQuery: ApiService, private dateTransform: DateTransformPipe,
        private apiNodeService: ApiNodeService,
        private mainBroadcaster: MainBroadcasterService
    ) {

        this.remittanceForm = new BehaviorSubject(new FormGroup(new RemittanceForm(new RemittanceModel())));

        this.remittanceForm$ = this.remittanceForm.asObservable()
    }


    addMrn(index: number, mrn?: string) {
        const remittanceForm = this.remittanceForm.getValue();

        const ePayment = remittanceForm.get('ePayment') as FormArray;

        const mrnArray = ePayment.at(index).get('mrns') as FormArray;

        mrnArray.push(new FormControl(mrn))
    }
    deleteMrn(formIndex: number, mrnIndex: number) {
        const remittanceForm = this.remittanceForm.getValue();
        const ePayment = remittanceForm.get('ePayment') as FormArray;

        const mrnArray = ePayment.at(formIndex).get('mrns') as FormArray;

        mrnArray.removeAt(mrnIndex)
    }
    addFormArray(data?: EPaymentModel) {
        const remittanceForm = this.remittanceForm.getValue();


        const formGroup = new FormGroup(new EPaymentForm(new EPaymentModel(data)));

        const ePayment = remittanceForm.get('ePayment') as FormArray;

        ePayment.push(formGroup);

    }

    deleteFormArray(FormArrayIndex: number) {

        const currentDeclaration = this.remittanceForm.getValue();

        const currentFormArray = currentDeclaration.get('ePayment') as FormArray;

        currentFormArray.removeAt(FormArrayIndex);
    }

    submit(): Observable<HttpResponse<ArrayBuffer>> {
        console.log("SUBMIT remittance ENTERED")

        // return this.openDialogsService.openSubmitDialog(this.remittanceForm.getValue());
        const { remittanceForm, declarantId } = this.createSubmitForm();

        console.log("SUBMIT remittance ICISNet Form", remittanceForm.value)

        console.log("SUBMIT openSubmitDialog ")

        return this.openDialogsService.openSubmitRemittanceDialog().pipe(switchMap(result => {

            console.log("SUBMIT opendialog result", result)
            if (result.action === 'send') {


                return this.getPaymentId(remittanceForm, declarantId)
            }
            else {
                return of(null)
            }
        }), take(1))
    }


    createSubmitForm() {
        console.log("SUBMIT createSubmitForm ENTERED")

        const form = this.remittanceForm.getValue();

        console.log("SUBMIT remittance Form", form)

        const ePayment = form.get('ePayment').value;
        const declarantId = form.get('declarant').value.afm


        const remittanceForm = new FormGroup(new ePaymentICISForm(new ePaymentICISModel()))

        const contain = remittanceForm.get('contain') as FormArray;

        ePayment.forEach((rmt: EPaymentModel) => {

            const mrns = rmt.mrns;

            const mrnCount = mrns.length

            mrns.forEach(item => {

                contain.push(new FormGroup(new ePaymentContainForm(new ePaymentContainModel({

                    blrn: rmt.blrn,
                    bafm: declarantId, //to set the trader.afm, you need to be authorized from the trader
                    bmrn: item,
                    bcnt: mrnCount

                }))))
            })
        })

        return { remittanceForm, declarantId };
    }

    getPaymentId(remittanceForm: FormGroup, declarantId: string): Observable<HttpResponse<ArrayBuffer>> {
        console.log("SUBMIT getPaymentId FROM ICISNet");

        //get result from ICISNet
        return this.dbQuery.submitRemittance({ remittance: remittanceForm.value, declarantId })
            .pipe(switchMap(result => this.processRemittanceResult(result)));
    }

    private processRemittanceResult(result: ePaymentResponseModel[]): Observable<HttpResponse<ArrayBuffer>> {
        console.log("SUBMIT PaymentId Received", result);

        const remittanceForm = this.remittanceForm.getValue();
        const ePayment = remittanceForm.get('ePayment') as FormArray;

        console.log("Received Rem ePayment array", ePayment.value);

        result.forEach((rmt, i) => {
            this.updatePaymentArray(i, rmt, ePayment);
        });

        return this.handlePaymentDocumentRequest();
    }

    private updatePaymentArray(i: number, rmt: ePaymentResponseModel, ePayment: FormArray): void {
        console.log("Received Rem index" + i + " lrn:", rmt.blrn);

        const index = ePayment.value.findIndex(e => e.blrn == rmt.blrn);

        console.log("Received Rem found index of ePayment for above lrn", index);

        ePayment.at(index).patchValue({
            blrn: rmt.blrn,
            bamnt: rmt.bamnt,
            brmt: rmt.brmt,
            date: this.dateTransform.formatDate(rmt.brmt.substr(0, 6), 'dd/mm/yyyy'),
            bmsv: rmt.bmsv,
            stateCode: 'Under Payment',
            expired: this.checkExpired(rmt)
        });
        console.log("Rem REceived after patch", ePayment.at(index).value);

        if (this.isValidRmt(rmt)) {
            ePayment.disable();
        }
    }

    private handlePaymentDocumentRequest(): Observable<HttpResponse<ArrayBuffer> | null> {
        if (this.getPaymentIdDoc) {
            console.log("SUBMIT Get Remittance Document Requested");

            const ePaymentDocArgs: EPaymentsDocArgs = { declarant: null };

            return this.getDoc(ePaymentDocArgs);
        } else {
            console.log("SUBMIT Remittance Document  Not Requested");

            return of(null);
        }
    }
    getDoc(ePaymentDocArgs: EPaymentsDocArgs) {

        console.log("getDoc ePaymentDocArgs", ePaymentDocArgs)

        //==========================TESTING=======================
        if (environment.testing) {
            return this.dbQuery.getTestSettings().pipe(switchMap(testSettings => {
                return this.mainBroadcaster.currentDeclarant$.pipe(switchMap(currentDeclarant => {
                    ePaymentDocArgs = {
                        "declarant": currentDeclarant.declarant,
                        "paymentId": testSettings.epayment_paymentId,
                        "rmtType": "2",
                        "customs": testSettings.epayment_customs,
                        "deadlineDate": testSettings.epayment_deadlineDate,
                        "docType": "receipt"
                    }
                    console.log("getDoc ePaymentDocArgs", ePaymentDocArgs)
                    return this.apiNodeService.getEPaymentDocuments(ePaymentDocArgs).pipe(take(1))

                }))

            })
                , take(1))

        }

        return this.apiNodeService.getEPaymentDocuments(ePaymentDocArgs).pipe(take(1))
    }

    showSaved(dataToShow: RemittanceInput[]) {

        const remittanceForm = this.remittanceForm.getValue()
        const ePayment = remittanceForm.get('ePayment') as FormArray;
        const declarant = remittanceForm.get('declarant') as FormGroup;

        const reduced = dataToShow.reduce((allData, current) => {
            (allData[current.remittance.blrn]) ? allData[current.remittance.blrn].push(current) : allData[current.remittance.blrn] = [current];

            return allData;
        }, {});


        console.log("showSaved reduced", dataToShow)
        console.log("showSaved reduced", reduced)

        let i = 0;

        //for each individual lrn create ePayment group
        for (const [afm, data] of Object.entries(reduced)) {

            console.log("showSaved for loop index" + i + ", data", data)

            //patch values form the first item, since they have same lrn, same trader and same number
            this.addFormArray({
                blrn: data[0].remittance.blrn,
                brmt: data[0].remittance.brmt,
                bamnt: data[0].remittance.bamnt,
                bmsv: data[0].remittance.bmsv,
                date: this.dateTransform.formatDate(data[0].remittance.brmt.substring(0, 6), 'dd/mm/yyyy'),
                trader: data[0].private.trader,
                stateCode: data[0].private.stateCode,
                expired: this.checkExpired(data[0].remittance)
            })



            data.forEach(d => {

                this.addMrn(i, d.private.mrn);
            })

            const lastIndex = ePayment.length - 1;
            console.log("ePayment index " + lastIndex, ePayment.at(lastIndex).value)
            ePayment.at(lastIndex).disable()

            i++;
        }
    }

    dataToSubmit(dataToSubmit: RemittanceInput[]) {

        const privateArray: PrivateControlsModel[] = dataToSubmit.map(d => d.private)

        const reduced = privateArray.reduce((allData, current) => {
            (allData[current.trader.afm]) ? allData[current.trader.afm].push(current) : allData[current.trader.afm] = [current];

            return allData;
        }, {});


        console.log("showSaveddataToSubmit dataToSubmit", dataToSubmit)
        console.log("showSaveddataToSubmit reduced", reduced)


        for (const [afm, data] of Object.entries(reduced)) {

            this.addFormArray({
                trader: data.map(d => d.trader)[0],
                stateCode: data.map(d => d.stateCode)[0],
                mrns: data.map(d => d.mrn)
            })

        }
    }

    checkExpired(rmt: ePaymentResponseModel) {

        if (this.isValidRmt(rmt)) {

            //We add 24 hours to the expiration date, to indicate the end of the day
            //I don't know until what time during the day the rem is valid. Maybe until the banks close, maybe until 22:00. maybe until 15:00

            const date = this.dateTransform.getDate(rmt.brmt.substring(0, 6)).getTime() + (24 * 60 * 60 * 1000)

            const today = new Date().getTime();

            const difference = Math.floor(date - today);


            console.log("checkExpired date", new Date(date))
            console.log("checkExpired today", today)
            console.log("checkExpired difference", difference)

            if (difference < 0) {

                return true;
            }

            return false;
        }
        return false;


    }

    isValidRmt(rmt) {
        return /^\d+$/.test(rmt.brmt)

    }

    reSubmit(index) {

        const remittanceForm = this.remittanceForm.getValue() as FormGroup

        const ePaymentItem = (remittanceForm.get('ePayment') as FormArray).at(index);

        ePaymentItem.patchValue({

            blrn: (new Date().getTime() + FuseUtils.generateGUID()).substring(0, 22),
            bamnt: null,
            date: null,
            brmt: null,
            bmsv: null,
            expired: false


        })

        ePaymentItem.enable()


    }

}