export class BasProModel {
    ComCodBP1019?: string;
    AddCodBP1020?: string;
    QuaBP1021?: string;

    constructor(value?) {
        if (value) {
            this.ComCodBP1019 = value.ComCodBP1019;
            this.AddCodBP1020 = value.AddCodBP1020;
            this.QuaBP1021 = value.QuaBP1021;
        }
    }
}
