import { FormControl } from '@angular/forms';
import { TrarepModel } from './trarep.model';

export class TrarepForm {
    [key: string]: FormControl;

    NamTRE1 = new FormControl();
    StrAndNumTRE1 = new FormControl();
    PosCodTRE1 = new FormControl();
    CitTRE1 = new FormControl();
    CouCodTRE1 = new FormControl();
    TRAREPLNG = new FormControl();
    TINTRE1 = new FormControl();
    SpeMenTRE410 = new FormControl();
    DeclarantObject = new FormControl();

    constructor(value: TrarepModel) {
        this.NamTRE1.setValue(value.NamTRE1);
        this.StrAndNumTRE1.setValue(value.StrAndNumTRE1);
        this.PosCodTRE1.setValue(value.PosCodTRE1);
        this.CitTRE1.setValue(value.CitTRE1);
        this.CouCodTRE1.setValue(value.CouCodTRE1);
        this.TRAREPLNG.setValue(value.TRAREPLNG);
        this.TINTRE1.setValue(value.TINTRE1);
        this.SpeMenTRE410.setValue(value.SpeMenTRE410);
        this.DeclarantObject.setValue(value.DeclarantObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
