import { AccountGenericModel } from "app/main/businesses/invoices/_models/account-generic/account-generic.model";
import { AccountPermissionsModel } from "app/main/businesses/invoices/_models/account-permissions/account-permissions.model";

export interface AccountBalance {
    balance: number;
    credit: number;
    debit: number;
}
export class InvoiceAccountModel {
    //This is the model of the object that will be passed on our form in case we have some initial values that need to be inserted

    id?: string;
    active?: boolean;
    archived?: boolean;
    sequence?: number;
    business_id?: string;
    customer_id?: string;
    account_name?: string;
    account_display_id?: string;
    group?: AccountGenericModel;
    normal_balance_type?: AccountGenericModel;
    sub_group?: AccountGenericModel;
    accrual_anchor_tier?: AccountGenericModel;
    archivable?: boolean;
    system_created?: boolean;
    currency_code?: string;
    description?: string;
    metadata?: { meta_entity_id: number, meta_entity_type: string };
    external_usage_type?: string;
    permissions?: AccountPermissionsModel;
    account_balance?: AccountBalance;
    //
    constructor(value?) {
        this.id = value?.id || null;
        this.active = value?.active || null;
        this.archived = value?.archived || null;
        this.sequence = value?.sequence || null;
        this.business_id = value?.business_id || null;
        this.customer_id = value?.customer_id || null;
        this.account_name = value?.account_name || null;
        this.account_display_id = value?.account_display_id || null;
        this.group = value?.group || null;
        this.normal_balance_type = value?.normal_balance_type || null;
        this.sub_group = value?.sub_group || null;
        this.accrual_anchor_tier = value?.accrual_anchor_tier || null;
        this.archivable = value?.archivable || null;
        this.system_created = value?.system_created || null;
        this.currency_code = value?.currency_code || null;
        this.description = value?.description || null;
        this.metadata = value?.metadata || null;
        this.external_usage_type = value?.external_usage_type || null;
        this.permissions = value?.permissions || null;
        this.account_balance = value?.account_balance || null;
    }
}