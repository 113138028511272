<div
    id="header"
    fxLayout="row"
    fxLayoutAlign="space-between start"
    style="width: 90%; margin: 0 auto; margin-bottom: 20px"
>
    <div style="width: 30%">
        <div id="CountryCode" class="groupTitle" style="width: 100%">
            Χώρα: {{ co?.CountryCode | countryPipe: "country_name" }}
        </div>
    </div>
    <div style="width: 30%">
        <div
            id="RegionCode"
            class="groupTitle"
            style="width: 100%"
            *ngIf="region"
        >
            Περιφέρεια: {{ region.CountryRegionName }}
        </div>
    </div>
    <div style="width: 30%">
        <div id="City" class="groupTitle">Πόλη: {{ location?.City }}</div>
    </div>
</div>

<div
    id="location"
    fxLayout="row"
    fxLayoutAlign="space-between"
    style="width: 90%; margin: 0 auto"
>
    <div id="address" style="width: 40%">
        <div id="addressTitle" class="groupTitle" style="width: 100%">
            Διεύθυνση
        </div>
        <div id="StreetAndNumber">{{ location?.StreetAndNumber }}</div>
        <div id="PostalCode">
            <span>{{ co?.PostalCode }}</span>
            <span *ngIf="co.PostalCode && location.City">, </span
            ><span>{{ location?.City }}</span>
        </div>
    </div>

    <div id="contact" style="width: 30%">
        <div id="locationTitle" class="groupTitle" style="width: 100%">
            Επικοινωνία
        </div>

        <div id="PhoneNumber">Τηλέφωνο: {{ co?.PhoneNumber }}</div>
        <div id="FaxNumber">Fax: {{ co?.FaxNumber }}</div>
        <div id="TelexNumber">Telex: {{ co?.TelexNumber }}</div>
        <div id="EMailAddress">
            e-mail:
            <span
                ><a href="mailto: {{ co?.EMailAddress }}">{{
                    co?.EMailAddress
                }}</a></span
            >
        </div>
    </div>
</div>

<div id="relatedTitle" class="groupTitle">Σχετιζόμενα Γραφεία</div>

<div
    id="RelatedOffices"
    fxLayout="row"
    fxLayoutAlign="space-between start"
    style="width: 90%; margin: 0 auto"
>
    <div style="width: 50%">
        <div id="ReferenceNumberHigherAuthority">
            Ανώτερη Αρχή:
            <a
                *ngIf="co.ReferenceNumberHigherAuthority"
                (click)="goToOffice(co.ReferenceNumberHigherAuthority)"
                class="button-class"
            >
                {{ co?.ReferenceNumberHigherAuthority }}
            </a>
        </div>
        <div id="ReferenceNumberMainOffice">
            Κεντρική Υπηρεσία:
            <a
                *ngIf="co.ReferenceNumberMainOffice"
                (click)="goToOffice(co.ReferenceNumberMainOffice)"
                class="button-class"
            >
                {{ co?.ReferenceNumberMainOffice }}
            </a>
        </div>
        <div id="ReferenceNumberCompetentAuthorityOfEnquiry">
            Αρμόδια Aρχή Έρευνας:
            <a
                *ngIf="co.ReferenceNumberCompetentAuthorityOfEnquiry"
                (click)="
                    goToOffice(co.ReferenceNumberCompetentAuthorityOfEnquiry)
                "
                class="button-class"
            >
                {{ co?.ReferenceNumberCompetentAuthorityOfEnquiry }}
            </a>
        </div>
        <div id="ReferenceNumberCompetentAuthorityOfRecovery">
            Αρμόδια Aρχή Eίσπραξης Eπιβαρύνσεων:
            <a
                *ngIf="co.ReferenceNumberCompetentAuthorityOfRecovery"
                (click)="
                    goToOffice(co.ReferenceNumberCompetentAuthorityOfRecovery)
                "
                class="button-class"
            >
                {{ co?.ReferenceNumberCompetentAuthorityOfRecovery }}
            </a>
        </div>
    </div>
    <div style="width: 30%">
        <div id="NearestOffice">
            Πλησιέστερη Υπηρεσία:
            <a
                *ngIf="co.NearestOffice"
                (click)="goToOffice(co.NearestOffice)"
                class="button-class"
            >
                {{ co?.NearestOffice }}
            </a>
        </div>
        <div id="ReferenceNumberTakeOver">
            Υπηρεσία Ανάληψης:
            <a
                *ngIf="co.ReferenceNumberTakeOver"
                (click)="goToOffice(co.ReferenceNumberTakeOver)"
                class="button-class"
            >
                {{ co?.ReferenceNumberTakeOver }}
            </a>
        </div>
    </div>
</div>

<div id="specInfoTitle" class="groupTitle">Ειδικές Πληροφορίες</div>

<div
    id="SpecificInfo"
    fxLayout="row"
    fxLayoutAlign="space-between start"
    style="width: 90%; margin: 0 auto"
>
    <div style="width: 40%">
        <div id="status">
            Κατασταση: <span *ngIf="co.state === 'valid'">NCTS</span>
        </div>
        <div id="entryDate">Ημ/νία Ένταξης: {{ entryDate }}</div>
    </div>
    <div style="width: 30%">
        <div>UN/LOCODE: {{ co?.UnLocodeId }}</div>
        <div id="GeoInfoCode">Τοποθεσία: {{ co?.GeoInfoCode | geoInfo }}</div>
    </div>
</div>
<div
    *ngIf="co.CustomsOfficeSpecificNotes"
    id="specNotesTitle"
    class="groupTitle"
>
    Ειδικές Σημειώσεις
</div>

<div
    *ngIf="co.CustomsOfficeSpecificNotes"
    id="SpecificNotes"
    fxLayout="row"
    fxLayoutAlign="space-between start"
    style="width: 90%; margin: 0 auto"
>
    <div
        *ngFor="let item of co?.CustomsOfficeSpecificNotes"
        style="width: 100%"
    >
        <div
            style="width: 100%"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <div
                style="width: 10%"
                id="status"
                fxLayout="row"
                fxLayoutAlign="center center"
            >
                {{ item.SpecificNotesCode }}:
            </div>
            <div style="width: 90%">{{ item.SpecificNotesCode | note }}</div>
        </div>
    </div>
</div>

<div id="timetableTitle" class="groupTitle">Ωρολόγιο Πρόγραμμα</div>

<div id="timetable" style="width: 90%; margin: 15px auto">
    <div *ngFor="let table of co?.CustomsOfficeTimetable">
        <table style="width: 100%; font-size: 0.9em">
            <tr>
                <th id="seasonHeader" style="width: 25%">
                    <div>{{ table.SeasonName }}</div>
                    <div>
                        από {{ table.SeasonStartDate | ddmm }} ως
                        {{ table.SeasonEndDate | ddmm }}
                    </div>
                </th>
                <th id="timeHeader" style="width: 34%">Ωράριο Λειτουργίας</th>
                <th id="roleHeader" style="width: 41%">Ρόλοι</th>
            </tr>
            <tr>
                <td colspan="3"><mat-divider [inset]="true"></mat-divider></td>
            </tr>
            <tr *ngFor="let item of table.TrafficTypeTimeLine">
                <td colspan="3">
                    <table style="width: 100%">
                        <tr>
                            <th
                                id="trafficTypeHeader"
                                style="text-align: left; width: 25%"
                            >
                                {{ item.TrafficType | trafficType }} ({{
                                    item.TrafficType
                                }})
                            </th>
                            <th id="emptyHeader1" style="width: 34%"></th>
                            <th id="emptyHeader2" style="width: 41%"></th>
                        </tr>

                        <tr
                            *ngFor="
                                let el of sortTimetableLine(
                                    item.CustomsOfficeTimetableLine
                                )
                            "
                        >
                            <td
                                *ngIf="
                                    el.DayInTheWeekBeginDay ===
                                    el.DayInTheWeekEndDay
                                "
                            >
                                {{ el.DayInTheWeekBeginDay | greekDay }}
                            </td>
                            <td
                                *ngIf="
                                    el.DayInTheWeekBeginDay !==
                                    el.DayInTheWeekEndDay
                                "
                            >
                                {{ el.DayInTheWeekBeginDay | greekDay }} -
                                {{ el.DayInTheWeekEndDay | greekDay }}
                            </td>

                            <td style="text-align: center">
                                <span
                                    >{{ el.OpeningHoursTimeFirstPeriodFrom }} -
                                    {{ el.OpeningHoursTimeFirstPeriodTo }}</span
                                >
                                <span
                                    *ngIf="
                                        el.OpeningHoursTimeSecondPeriodFrom &&
                                        el.OpeningHoursTimeSecondPeriodFrom !==
                                            '0000' &&
                                        el.OpeningHoursTimeSecondPeriodTo !==
                                            '0000'
                                    "
                                >
                                    &
                                    {{ el.OpeningHoursTimeSecondPeriodFrom }} -
                                    {{ el.OpeningHoursTimeSecondPeriodTo }}
                                </span>
                            </td>
                            <td>
                                <ng-container
                                    *ngFor="let role of el.Roles; let i = index"
                                >
                                    <span
                                        matTooltip="{{ role | role }}"
                                        matTooltipClass="tooltip"
                                        *ngIf="i === 0"
                                    >
                                        {{ role }}</span
                                    >
                                    <span
                                        matTooltip="{{ role | role }}"
                                        matTooltipClass="tooltip"
                                        *ngIf="i > 0"
                                        >, {{ role }}</span
                                    >
                                </ng-container>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <mat-divider class="total-divider" [inset]="true">
                    </mat-divider>
                </td>
            </tr>
        </table>
    </div>
</div>
