import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { PrivateControlsFormModel } from "app/model/private-controls-form.model";
import { PrivateControlsModel } from "app/model/private-controls.model";
import { EMCSHeaderForm } from "../../../_models/emcs-header-form.model";
import { EMCSHeaderModel } from "../../../_models/emcs-header.model";
import { DaoBodyForm } from "./Body/body-form.model";
import { DaoBodyModel } from "./Body/body.model";
import { DaoDeclarationModel } from "./declaration.model";

export class DaoDeclarationForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    Header: FormGroup;
    Body: FormGroup;

    /*

   */

    constructor(declaration: DaoDeclarationModel, subdomain?: string, messageType?: string) {
        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));



        this.Header = new FormGroup(new EMCSHeaderForm(new EMCSHeaderModel(declaration.Header)));

        this.Body = new FormGroup(new DaoBodyForm(new DaoBodyModel(declaration.Body)));
    }
}
