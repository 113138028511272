import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { SubmittingOperatorForm } from "../../submittingOperator/submittingOperator-form.model";
import { SubmittingOperatorModel } from "../../submittingOperator/submittingOperator.model";
import { CancelVehicleArrival, VA10ABodyModel, VehicleArrivalDeclarationCancellationType } from "./body.model";

export class VA10ABodyForm {
    [key: string]: AbstractControl;
    VehicleArrivalDeclarationCancellation: FormGroup;

    constructor(value: VA10ABodyModel) {
        this.VehicleArrivalDeclarationCancellation = new FormGroup(new VehicleArrivalDeclarationCancellationForm(new VehicleArrivalDeclarationCancellationType(value.VehicleArrivalDeclarationCancellation)));

    }
}


export class VehicleArrivalDeclarationCancellationForm {
    [key: string]: AbstractControl;

    SubmittingOperator: FormGroup;
    VehicleArrival: FormGroup;


    constructor(value: VehicleArrivalDeclarationCancellationType) {

        this.SubmittingOperator = new FormGroup(new SubmittingOperatorForm(new SubmittingOperatorModel(value.SubmittingOperator)));
        this.VehicleArrival = new FormGroup(new CancelVehicleArrivalForm(new CancelVehicleArrival(value.VehicleArrival)));

    }
}


export class CancelVehicleArrivalForm {
    [key: string]: AbstractControl;

    DeclarationReferenceNumber: FormControl;
    CancellationReason: FormControl;


    constructor(value: CancelVehicleArrival) {

        this.DeclarationReferenceNumber = new FormControl(value.DeclarationReferenceNumber);
        this.CancellationReason = new FormControl(value.CancellationReason);

    }
}
