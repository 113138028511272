import { FormControl, Validators } from '@angular/forms';
import { NatProModel } from './natpro.model';

export class NatProForm {
    [key: string]: FormControl;

    WarNumNP1016 = new FormControl();
    ProIdNP1017 = new FormControl();
    ProQuaNP1018 = new FormControl();

    constructor(value: NatProModel) {
        this.WarNumNP1016.setValue(value.WarNumNP1016);
        this.ProIdNP1017.setValue(value.ProIdNP1017);
        this.ProQuaNP1018.setValue(value.ProQuaNP1018);
    }
}
