import { FormControl, Validators } from '@angular/forms';
import { TirProDocModel } from './prodocdc2.model';

export class TirProDocForm {
    [key: string]: FormControl;

    DocRefDCLNG = new FormControl();
    ComOfInfDC25 = new FormControl();
    ComOfInfDC25LNG = new FormControl();
    DocTypDC21 = new FormControl();
    DocRefDC23 = new FormControl();

    constructor(value: TirProDocModel) {
        this.DocRefDCLNG.setValue(value.DocRefDCLNG);
        this.ComOfInfDC25.setValue(value.ComOfInfDC25);
        this.ComOfInfDC25LNG.setValue(value.ComOfInfDC25LNG);
        this.DocTypDC21.setValue(value.DocTypDC21);
        this.DocRefDC23.setValue(value.DocRefDC23);
    }
}
