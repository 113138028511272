<div id="printableApplication" class="app_Grid" [ngClass]="{app_Grid_chrome: browser.isChrome}">
    <div class="app_img">
        <topos-tirisis-eggrafon-svg></topos-tirisis-eggrafon-svg>
    </div>


    <div id="customsName" class="declDiv">
        {{ dilosiForm.get('customsName').value}}
    </div>

    <div id="customsCode" class="declDiv">
        {{ dilosiForm.get('customsCode').value}}
    </div>


    <div id="customer-eori" class="declDiv">
        {{ customer.get('customerObject').value?.eori }}
    </div>
    <div id="customer-afm" class="declDiv">
        {{ customer.get('customerObject').value?.afm }}
    </div>
    <div id="customer-lastname" class="declDiv">
        {{ customer.get('contactDetails').get('lastname').value }}
    </div>
    <div id="customer-name" class="declDiv">
        {{customer.get('contactDetails').get('firstname').value}}
    </div>
    <div id="customer-phone" class="declDiv">
        {{customer.get('contactDetails').get('phone').value}}
    </div>
    <div id="customer-fax" class="declDiv">
        {{customer.get('contactDetails').get('fax').value}}
    </div>
    <div id="customer-email" class="declDiv">
        {{customer.get('contactDetails').get('email').value}}
    </div>






    <div id="declarant-eori" class="declDiv">
        {{ declarant.get('declarantObject').value?.eori }}
    </div>
    <div id="declarant-afm" class="declDiv">
        {{ declarant.get('declarantObject').value?.afm }}
    </div>
    <div id="declarant-lastname" class="declDiv">
        {{declarant.get('contactDetails').get('lastname').value}}
    </div>
    <div id="declarant-name" class="declDiv">
        {{declarant.get('contactDetails').get('firstname').value}}
    </div>
    <div id="declarant-phone" class="declDiv">
        {{declarant.get('contactDetails').get('phone').value}}
    </div>
    <div id="declarant-fax" class="declDiv">
        {{declarant.get('contactDetails').get('fax').value}}
    </div>
    <div id="declarant-email" class="declDiv">
        {{declarant.get('contactDetails').get('email').value}}
    </div>






    <div *ngIf="documentsPlace.get('place').value===0" id="PlaceOfTheCustomer" class="declDiv">X</div>

    <div *ngIf="documentsPlace.get('place').value===1" id="OtherPlace" class="declDiv">X</div>

    <div *ngIf="documentsPlace.get('place').value===2" id="PlaceOfTheDeclarant" class="declDiv">X</div>




    <div id="documentsPlace-street" class="declDiv">
        {{documentsPlace.get('street').value}}
    </div>
    <div id="documentsPlace-streetNumber" class="declDiv">
        {{documentsPlace.get('streetNumber').value}}
    </div>
    <div id="documentsPlace-postalCode" class="declDiv">
        {{documentsPlace.get('postalCode').value}}
    </div>
    <div id="documentsPlace-city" class="declDiv">
        {{documentsPlace.get('city').value}}
    </div>
    <div id="documentsPlace-county" class="declDiv">
        {{documentsPlace.get('county').value}}
    </div>
    <div id="documentsPlace-prefecture" class="declDiv">
        {{documentsPlace.get('prefecture').value}}
    </div>
    <div id="documentsPlace-workingHours" class="declDiv">
        {{documentsPlace.get('workingHours').value}}
    </div>




    <div id="contactDetails-lastname" class="declDiv">
        {{contactDetails.get('lastname').value}}
    </div>
    <div id="contactDetails-firstname" class="declDiv">
        {{contactDetails.get('firstname').value}}
    </div>
    <div id="contactDetails-phone" class="declDiv">
        {{contactDetails.get('phone').value}}
    </div>
    <div id="contactDetails-fax" class="declDiv">
        {{contactDetails.get('fax').value}}
    </div>
    <div id="contactDetails-email" class="declDiv">
        {{contactDetails.get('email').value}}
    </div>
    <div id="date" class="declDiv">
        {{dilosiForm.get('date').value}}
    </div>


</div>