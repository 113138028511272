import { DeclarationStatePipeModule } from 'app/services/pipes/declaration-state.pipe';
import { CodeToDescPipeModule } from 'app/services/pipes/codeToDesc.pipe';
import { FormFieldsModule } from './../../../main-building-blocks/form-fields/form-fields.module';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { ArrivalAtExitService } from './arrAtExit.service';
import { ArrivalAtExitComponent } from './arrival-at-exit.component';

@NgModule({
    declarations: [ArrivalAtExitComponent],
    imports: [
        FuseSharedModule,
        MaterialModule,
        FormFieldsModule,
        CodeToDescPipeModule,
        DeclarationStatePipeModule
    ],
    providers: [ArrivalAtExitService]
})
export class ArrivalAtExitModule { }
