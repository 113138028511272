export class VehicleArrivalModel {

    _arrivalDate?: string;
    DeclarationReferenceNumber?: string; //<===NOT USED NORMALLY, TO BE REMOVED BEFORE SUBMIT

    LocalReferenceNumber: string;
    Fallbackflag: string;
    FallbackDocumentReference?: string;
    FallbackOffice?: string;
    RegistrationDate?: string;
    VehicleMovementMeans: string;
    TransportVehicleRegistrationPlate?: string;
    TransportVehicleCountry?: string;
    DurationInDays?: string;
    OfficeOfOrigin?: string;
    CountryOfOrigin?: string;


    constructor(value?) {

        this._arrivalDate = value?._arrivalDate;
        this.DeclarationReferenceNumber = value?.DeclarationReferenceNumber;

        this.LocalReferenceNumber = value?.LocalReferenceNumber || null;
        //==========Fallback Details======================
        this.Fallbackflag = value?.Fallbackflag || "0";
        this.FallbackDocumentReference = value?.FallbackDocumentReference;
        this.FallbackOffice = value?.FallbackOffice;
        this.RegistrationDate = value?.RegistrationDate;
        //==============================================
        this.VehicleMovementMeans = value?.VehicleMovementMeans || null;
        this.TransportVehicleRegistrationPlate = value?.TransportVehicleRegistrationPlate;
        this.TransportVehicleCountry = value?.TransportVehicleCountry;
        this.DurationInDays = value?.DurationInDays;
        this.OfficeOfOrigin = value?.OfficeOfOrigin;
        this.CountryOfOrigin = value?.CountryOfOrigin;
    }

}
