import { PrivateControlsModel } from 'app/model/private-controls.model';
import { MaxSizeValidator } from '@angular-material-components/file-input';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { ApiNodeService } from 'app/services/api.node.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { SubToDomain } from 'app/services/com-code.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subject } from 'rxjs';
import { concatMap, map, take, takeUntil } from 'rxjs/operators';
import { ExpDeclarationFormService } from '../../export/declaration/exp-declaration-form.service';
import { ExportService } from '../../export/services/export.service';
import { AttachmentsService } from '../attachments.service';
import { Declarant, User } from 'app/core/user/user.model';

export enum Domain {
    ECS = 'EXPORT',
    IMP = 'IMPORT',
    EMCS = 'EMCS',
    AIS = 'AIS',
    NCTS = 'NCTS',
    LIS = 'LIS'
}

@Component({
    selector: 'app-attachments-dialog',
    templateUrl: './attachments-dialog.component.html',
    styleUrls: ['./attachments-dialog.component.scss'],
    providers: [ExpDeclarationFormService, ExportService, AttachmentsService]
})
export class AttachmentsDialogComponent implements OnInit {
    @ViewChild(ToastContainerDirective, { static: true })
    toastContainer: ToastContainerDirective;

    message: string;
    progressValue = 0;

    user: User;

    attachmentForm: FormGroup;
    icisUser: string;
    icisPass: string;
    key: string;
    key_type: string;
    domain: string;
    role: string;
    type: string;
    remarks: string;
    fileToUpload: File[];

    action: string;
    title: string;
    submitTitle: string;

    color1 = '';
    color2: string;
    processing = false;

    // PDF, DOC, XLS. JPEG, TXT, OTH

    color: ThemePalette = 'primary';
    disabled = false;
    multiple = true;
    accept: string;

    fileControl: FormControl;
    isLoggedIn$: Observable<boolean>;

    public options = [
        { value: true, label: 'True' },
        { value: false, label: 'False' }
    ];

    public listColors = ['primary', 'accent', 'warn'];

    public files: File[];
    maxSize = 8192; //8Mb

    results: any[] = [];

    declarationForm$: Observable<any>;
    declarationForm: FormGroup;

    loading$: Observable<boolean>;

    currentDeclarant: Declarant;

    private _destroy: Subject<void> = new Subject<void>();
    private _unsubscribe: Subject<void>;

    constructor(
        private attachmentsService: AttachmentsService,
        public dialogRef: MatDialogRef<AttachmentsDialogComponent>,
        private toastrService: ToastrService,
        private cf: ExtCustomFunctions,
        private mainBroadcaster: MainBroadcasterService,
        private apiLocalService: ApiNodeService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        console.log('attachement DialogData', data);

        this.mainBroadcaster.currentDeclarant$.pipe(take(1)).subscribe(currentDeclarant => {
            this.currentDeclarant = currentDeclarant.declarant
        });

        this.loading$ = this.mainBroadcaster.loading$

        this.attachmentsService.message.pipe(takeUntil(this._destroy)).subscribe(message => {
            this.message = message;
            console.log('WebSocket Message', this.message);
        });
        this.attachmentsService.progressValue.pipe(takeUntil(this._destroy)).subscribe(progressValue => {
            this.progressValue = progressValue;
            console.log('WebSocket progressValue', this.progressValue);
        });
        this.attachmentsService.color2.pipe(takeUntil(this._destroy)).subscribe(color2 => {
            this.color2 = color2;
        });
        this.attachmentsService.processing.pipe(takeUntil(this._destroy)).subscribe(processing => {
            this.processing = processing;
        });

        this.attachmentsService.createForm();

        this.attachmentsService.attachmentForm.pipe(takeUntil(this._destroy)).subscribe(attachmentForm => {
            this.attachmentForm = attachmentForm;
        });

        this.fileControl = new FormControl(this.files, [Validators.required, MaxSizeValidator(this.maxSize * 1024)]);

        this.results.push(['']);

        this.isLoggedIn$ = this.mainBroadcaster.icisUser$.pipe(map(icisUser => icisUser.isLoggedIn));

        const activeSubdomain$ = this.mainBroadcaster.activeSubdomain$;
        const declarationForms$ = this.mainBroadcaster.declarationForms$;

        combineLatest([activeSubdomain$, declarationForms$])
            .pipe(takeUntil(this._destroy))
            .subscribe(([subdomain, declarationForms]) => {
                this.declarationForm = declarationForms[subdomain];

                console.log('attachment subdomain', subdomain);
                console.log('attachment declarationForms', declarationForms);
                console.log('attachment declarationForm', this.declarationForm);
                this.domain = SubToDomain[subdomain];
            });
    }

    ngOnInit(): void {
        this.toastrService.overlayContainer = this.toastContainer;

        //  const heahea = this.declarationForm.get('HEAHEA') as FormGroup;

        const privateCtrl: PrivateControlsModel = this.declarationForm.get('_PRIVATE').value
        this.currentDeclarant = privateCtrl.declarant || this.currentDeclarant;
        const mrn = privateCtrl.mrn;
        const lrn = privateCtrl.lrn;




        console.log('attachement declarationForm', this.declarationForm);
        console.log('attachement declarant', this.currentDeclarant);

        console.log(this.declarationForm);


        if (mrn !== null && mrn !== '' && mrn !== undefined) {
            this.key = mrn;
            this.key_type = 'MRN';
        } else {
            this.key = lrn;
            this.key_type = 'LRN';
        }

        console.log('attachement domain', this.domain);
        console.log('attachement key', this.key);
        console.log('attachement key_type', this.key_type);
        console.log('attachement declarant', this.currentDeclarant);

        this.attachmentForm.get('domain').setValue(Domain[this.domain]);
        this.attachmentForm.get('key_type').setValue(this.key_type);
        this.attachmentForm.get('key').setValue(this.key);


        /*   this.apiLocalService.icisLogin(declarant, true).subscribe(value => {
              console.log('attachment icisLogin ', value);
          });
   */
        if (this.data.action === 'send') {
            this.title = 'Αποστολή Συνημμένων';
            this.action = 'send';
            this.submitTitle = 'Αποστολή Αρχείων';
        } else {
            this.title = 'Αναζήτηση Συνημμένων';
            this.action = 'check';
            this.submitTitle = 'Αναζήτηση';
        }

        this.fileControl.valueChanges.pipe(takeUntil(this._destroy)).subscribe((files: any) => {
            if (!Array.isArray(files)) {
                this.files = [files];
            } else {
                this.files = files;
            }
        });

        this.attachmentForm.statusChanges.pipe(takeUntil(this._destroy)).subscribe(status => {
            console.log('attachment', status);
            this.color2 = status === 'INVALID' ? '' : 'primary';
        });

        this.fileControl.statusChanges.pipe(takeUntil(this._destroy)).subscribe(status => {
            console.log('fileContorl', status);
            this.color2 = status === 'INVALID' ? '' : 'primary';
        });
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event);
    }
    changeStyle2(event) {
        if (this.attachmentForm.valid || !this.processing) {
            this.color2 = this.cf.changeStyle2(this.attachmentForm, event);
        }
    }
    onCloseConfirm() {
        this.dialogRef.close();
    }
    cancel() {
        console.log('cancel', this._unsubscribe);

        if (this.processing) {
            this._unsubscribe.next();
            this._unsubscribe.complete();
            console.log('cancel after completion', this._unsubscribe);
            this.processing = false;
            this.mainBroadcaster.updateLoading(false)

        } else {
            this.onCloseConfirm();
        }
    }
    onSend() {
        this._unsubscribe = new Subject<void>();

        this.attachmentsService.onSend(this.currentDeclarant, this.files).subscribe({
            next: result => {
                console.log('Attachment result', result);

                if (result[0].data) {
                    this.toastrService.success(result[0].data);
                } else {
                    this.toastrService.error(result[0].error, '', {
                        disableTimeOut: true
                    });
                }
            },
            error: error => {
                console.log('attachment error', error);

                this.cancel()

            },
            complete: () => {
                console.log('attachment complete');
                this.cancel()

                this.color2 = 'primary';

            }
        });;
    }

    onCheck() {
        this._unsubscribe = new Subject<void>();
        this.attachmentsService
            .onCheck(this.currentDeclarant)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe({
                next: result => {
                    console.log('attachment result', result);
                    if (typeof result.data === 'string' || result.data instanceof String) {
                        this.results = [];
                        this.results.push([result.data]);
                    } else {
                        this.results = result.data;
                    }
                },
                error: error => {
                    console.log('attachment error', error);
                    this.cancel()
                },
                complete: () => {
                    console.log('attachment complete');
                    this.cancel()
                }
            });
    }

    onSubmit(action: string) {
        console.log('Action', action);
        action === 'send' ? this.onSend() : this.onCheck();
    }


    onDisabledChanged(value: boolean) {
        if (!value) {
            this.fileControl.enable();
        } else {
            this.fileControl.disable();
        }
    }
}
