import { MainBroadcasterService } from 'app/services/broadcaster.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'app/services/api.service';
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { CalcTariffResponse } from 'app/main/apps/tariff-calculate/_models';
import { AttachmentItem } from 'app/model/api-model';
import { Observable, concatMap, catchError, of, tap, throwError, BehaviorSubject, take, switchMap, filter } from 'rxjs';
import { LoginResult } from 'app/main/apps/attachments/attachments.service';
import { Declarant } from 'app/core/user/user.model';
export interface EPaymentsDocArgs {

    declarant: Declarant;
    paymentId?: string;
    rmtType?: "1" | "2";  //Physical/Electronic
    customs?: string;
    deadlineDate?: string; //dd/MM/yyyy
    docType?: "receipt" | "remittance";
    mrn?: string;
}


@Injectable({
    providedIn: 'root'
})


export class ApiNodeService {

    NODE_API_SERVER = environment.NODE_API;


    loginCounter = 0;
    declarant: Declarant;



    constructor(private http: HttpClient, private dbQuery: ApiService, private mainBroadcaster: MainBroadcasterService, private toastrService: ToastrService) {

    }


    calculateTariff(postData): Observable<CalcTariffResponse> {
        postData.app = 'taric';
        const url = `${this.NODE_API_SERVER}/tariff/calculate`;
        return this.http.post<CalcTariffResponse>(url, postData);
    }
    submitTariffDocumentsResponse(postData) {
        const url = `${this.NODE_API_SERVER}/tariff/documents`;
        return this.http.post<CalcTariffResponse>(url, postData);
    }
    submitTariffCalcSupUnits(postData) {
        const url = `${this.NODE_API_SERVER}/tariff/supUnits`;
        return this.http.post<CalcTariffResponse>(url, postData);
    }

    //===============================================================================
    getIcisDocument(subdomain: string, mrn: string, declarant: Declarant): Observable<HttpResponse<ArrayBuffer>> {

        const url = `${this.NODE_API_SERVER}/document/get`;

        const postData = { app: 'itrader', subdomain, mrn, creds: null };

        // postData.mrn = mrn;

        return this.dbQuery.getIcisCreds(declarant).pipe(
            concatMap((creds: any) => {

                postData.creds = creds;

                return this.http.post<any>(url, postData, {
                    observe: 'response',
                    responseType: 'arraybuffer' as 'json',
                    headers: {
                        Accept: 'application/pdf',
                        'Content-Disposition': 'attachment;filename=' + mrn + '.pdf'
                    }
                });
            })
        )
    }

    gotoIcisSubdomain(subdomain: string, declarant: Declarant): Observable<boolean> {

        const url = `${this.NODE_API_SERVER}/goToIcisSubdomain`;

        const postData = { app: 'itrader', subdomain, creds: null };

        // postData.mrn = mrn;

        return this.dbQuery.getIcisCreds(declarant).pipe(
            concatMap((creds: any) => {

                postData.creds = creds;

                return this.http.post<any>(url, postData);
            })
        )
    }

    getStateEfk(subdomain: string, declarant: Declarant, lrn: string) {
        const url = `${this.NODE_API_SERVER}/getStateEFK`;

        const postData = { app: 'itrader', subdomain, lrn, creds: null };

        // postData.mrn = mrn;

        return this.dbQuery.getIcisCreds(declarant).pipe(
            concatMap((creds: any) => {

                postData.creds = creds;

                return this.http.post<any>(url, postData);
            })
        )
    }

    getEPaymentDocuments(epaymentData: EPaymentsDocArgs): Observable<HttpResponse<ArrayBuffer>> {

        const url = `${this.NODE_API_SERVER}/remittance/get`;

        const postData = { ...epaymentData, app: 'remittance', creds: null };

        // postData.mrn = mrn;

        return this.dbQuery.getIcisCreds(epaymentData.declarant).pipe(
            concatMap((creds: any) => {

                postData.creds = creds;

                return this.http.post<any>(url, postData, {
                    observe: 'response',
                    responseType: 'arraybuffer' as 'json',
                    headers: {
                        Accept: 'application/pdf',
                        'Content-Disposition': 'attachment;filename=' + epaymentData.paymentId + '.pdf'
                    }
                });
            })
        )
    }


    sendAttachment(declarant: Declarant, postData: FormData): Observable<any> {
        const url = `${this.NODE_API_SERVER}/attachment/send`;

        return this.dbQuery.getIcisCreds(declarant).pipe(
            concatMap((creds: { icisUser: string, icisPass: string }) => {

                // Convert creds object to a JSON string
                const credsString = JSON.stringify(creds);
                postData.append('creds', credsString);
                postData.append('app', 'itrader');

                return this.http.post(url, postData);
            }))
    }

    checkAttachment(declarant: Declarant, postData: any): Observable<any> {
        const url = `${this.NODE_API_SERVER}/attachment/check`;

        return this.dbQuery.getIcisCreds(declarant).pipe(
            concatMap((creds: any) => {

                postData.creds = creds;
                postData.app = 'itrader';


                return this.http.post(url, postData).pipe(
                    tap((result: LoginResult) => {
                        if (result.error) {
                            this.toastrService.error('Παρακαλώ δοκιμάστε ξανά.', 'Υπήρξε σφάλμα κατα τη διαδικασία', { disableTimeOut: true });
                        }
                    })
                );
            }))
    }

    getAttachmentFile(index): Observable<any> {
        const url = `${this.NODE_API_SERVER}/attachment/getFile`;
        return this.http.post(url, { index });
    }

    icisLogin(declarant: Declarant, silent?: boolean): Observable<any> {
        console.trace('ATTACH isLoggedIN icisLogin ');

        this.declarant = declarant

        return this.mainBroadcaster.isLoginInProgress$.pipe(
            take(1),
            filter(isInProgress => !isInProgress),  // Only proceed if IsLoginInProgress=false
            switchMap(() => this.mainBroadcaster.icisUser$.pipe(
                take(1),
                switchMap(icisUser => {

                    setTimeout(() => {
                        if (!icisUser.isLoggedIn) {
                            if (!silent) {
                                this.toastrService.info('Σύνδεση με ICISNet...', '', {
                                    progressAnimation: 'increasing',
                                    progressBar: true,
                                    positionClass: 'toast-top-center',
                                    timeOut: 45000
                                });
                            }

                            // this.mainBroadcaster.updateIcisUser({ isLoggedIn: false, user: { name: null, afm: null } })
                        }
                    }, 300);

                    const url = `${this.NODE_API_SERVER}/icisLogin`;

                    this.mainBroadcaster.updateIsLoginInProgress(true);

                    return this.dbQuery.getIcisCreds(declarant).pipe(
                        concatMap(creds => this.http.post<LoginResult>(url, { app: 'itrader', creds })),
                        tap(this.handleLoginResult(silent)),
                        catchError(error => this.handleLoginError(error))
                    );
                })
            ))
        );
    }

    icisLogout() {
        const url = `${this.NODE_API_SERVER}/icisLogout`;

        return this.http.get(url).pipe(take(1), tap(() => this.mainBroadcaster.updateIcisUser({ isLoggedIn: false, user: { name: null, afm: null } })));



    }



    //======================================
    invoiceParse(postData: any): Observable<any> {
        console.trace("Who calls invoiceParse")
        console.log("invoiceParse send form", postData)
        const url = `${this.NODE_API_SERVER}/invoiceParsing/parse`;
        return this.http.post(url, postData).pipe(take(1));
    }



    // Handling login result
    private handleLoginResult(silent: boolean) {
        return (result: LoginResult) => {
            this.mainBroadcaster.updateIsLoginInProgress(false);

            if (!result.error) {
                this.handleSuccessLogin(result, silent);
                this.mainBroadcaster.updateIcisUser(result)
                this.loginCounter = 0;


            } else {
                this.handleFailedLogin(silent);
                this.mainBroadcaster.updateIcisUser({ isLoggedIn: false, user: { name: null, afm: null } })
                if (this.loginCounter < 5) {
                    this.loginCounter++;
                    this.icisLogin(this.declarant, silent).subscribe();
                }

            }
        }
    }

    // Successful login 
    private handleSuccessLogin(result: LoginResult, silent: boolean) {
        if (!silent) {
            this.toastrService.clear();
            this.toastrService.success('Σύνδεση με ICISNet επιτυχής.', '', {
                positionClass: 'toast-top-center'
            });
        }

    }

    // Failed login 
    private handleFailedLogin(silent: boolean) {
        if (!silent) {
            this.toastrService.clear();
            this.toastrService.error('Η συνδεση με το ICISNet απέτυχε.', '', {
                positionClass: 'toast-top-center',
                disableTimeOut: true
            });
        }

    }

    // Handling HTTP errors
    private handleLoginError(error: HttpErrorResponse): Observable<null> {
        this.mainBroadcaster.updateIsLoginInProgress(false);
        this.toastrService.error(error.message, 'Η συνδεση με το ICISNet απέτυχε.', {
            disableTimeOut: true,
            positionClass: 'toast-top-center'
        });

        if (error.status === 404) {
            return of(null);
        }
        return throwError(() => new Error('icisLogin error'));
    }
}