<ng-container *ngIf="tsipouraForm">
    <div fxLayout="column" style="width: 100%; flex-grow: 1">
        <div mat-dialog-title class="primary">
            <div fxLayout="column">
                <span>Υπολογισμός Φόρου Διήμερου Αποσταγματοποιού</span>
                <!--             <span style="font-size: 14px"><small>{{ subheading }}</small></span>
 -->
            </div>
            <div>
                <button mat-icon-button (click)="onPrint()" matTooltip="Εκτύπωση" matTooltipClass="tooltip">
                    <mat-icon> print</mat-icon>
                </button>
                <button mat-icon-button (click)="onCloseConfirm()" matTooltip="Κλείσιμο" matTooltipClass="tooltip">
                    <mat-icon> highlight_off</mat-icon>
                </button>
            </div>
        </div>

        <div mat-dialog-content style="flex-grow:1">
            <div id="printable">
                <div style="margin:24px; margin-top:0">
                    <tsipoura-header></tsipoura-header>

                </div>
                <div style="margin:24px">
                    <tsipoura-calculation-data></tsipoura-calculation-data>

                </div>
                <div style="margin:24px; display:flex;">
                    <tsipoura-producer-times style="width:46%"></tsipoura-producer-times>
                    <tsipoura-parameters style="width:25%" [params]="params"></tsipoura-parameters>
                    <tsipoura-limitations style="width:25%" [params]="params"></tsipoura-limitations>
                </div>
            </div>
        </div>

        <div>
            <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
                <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                    (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="onCloseConfirm()">
                    Ακυρο
                </button>
                <button style="width: auto !important" mat-raised-button [ngClass]="color2"
                    (mouseenter)="changeStyle2($event)" (mouseleave)="changeStyle2($event)" (click)="onSubmit()">
                    Δημιουργία ΔΕΦΚ
                </button>
            </mat-dialog-actions>
        </div>
    </div>

</ng-container>