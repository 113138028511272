import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { CurrencyItem } from "app/model/api-model";

export function confirmPasswordValidator(checkControl: AbstractControl): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

        console.log("CustomVAlidator control", control)
        console.log("CustomVAlidator control.parent", control.parent)

        if (!control.parent || !control) {
            return null;
        }



        if (!control || !checkControl) {
            return null;
        }

        if (control.value === '') {
            return null;
        }

        if (control.value === checkControl.value) {
            return null;
        }

        return { passNotMatching: true };
    }
};



//Returns Erroe when two fields are the same
export function avoidSame(checkControl: AbstractControl): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

        //console.log("avoidSame control.value", control.value)
        //console.log("avoidSame checkControl.value", checkControl.value)

        if (!control.parent || !control) {
            return null;
        }

        if (!control || !checkControl) {
            //console.log("avoidSame one not exist")
            //console.log("avoidSame control.value", control.value)
            //console.log("avoidSame checkControl.value", checkControl.value)

            return null;
        }

        if (!control.value && !checkControl.value) {
            //console.log("avoidSame both not exist")
            //console.log("avoidSame control.value", control.value)
            //console.log("avoidSame checkControl.value", checkControl.value)
            return null;
        }

        if (control.value === '') {
            return null;
        }

        if (control.value !== checkControl.value) {
            return null;
        }

        if (control.value === checkControl.value) {

            const group = checkControl.parent;

            let checkControlName;

            Object.keys(group.controls).forEach(key => {

                const childControl = group.get(key);

                if (childControl !== checkControl) {
                    return;
                }

                checkControlName = key;
            });



            return { controlsAreSame: checkControlName };
        }
    }
};


//Returns error when two fields are different
export function checkSame(checkControl: AbstractControl): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

        //console.log("avoidSame control.value", control.value)
        //console.log("avoidSame checkControl.value", checkControl.value)

        if (!control.parent || !control) {
            return null;
        }

        if (!control || !checkControl) {
            //console.log("avoidSame one not exist")
            //console.log("avoidSame control.value", control.value)
            //console.log("avoidSame checkControl.value", checkControl.value)

            return null;
        }

        if (!control.value && !checkControl.value) {
            //console.log("avoidSame both not exist")
            //console.log("avoidSame control.value", control.value)
            //console.log("avoidSame checkControl.value", checkControl.value)
            return null;
        }

        if (control.value === '') {
            return null;
        }

        if (control.value === checkControl.value) {
            return null;
        }

        if (control.value !== checkControl.value) {

            const group = checkControl.parent;

            let checkControlName;

            Object.keys(group.controls).forEach(key => {

                const childControl = group.get(key);

                if (childControl !== checkControl) {
                    return;
                }

                checkControlName = key;
            });



            return { controlsAreDifferent: checkControlName };
        }
    }
};



export function exchangeRateValidator(currencyItem: CurrencyItem, changedManually: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const exchangeRate = +control.value;

        console.log("exchangeRateValidator currencyItem", currencyItem)
        console.log("exchangeRateValidator manuallyChanged", changedManually)
        console.log("exchangeRateValidator value", exchangeRate)


        if (!exchangeRate) {
            return null;  // or return an error if the value is required
        }

        if (!currencyItem.valid_until) {
            return null;
        }


        const currentDate = new Date();
        const validFrom = new Date(currencyItem.valid_from);
        const validUntil = new Date(currencyItem.valid_until);
        const isDateInRange = currentDate >= validFrom && currentDate <= validUntil;
        const doRatesMatch = exchangeRate === currencyItem.rate;

        // Handle the various cases:

        // Rates don't match, retrieved rates are invalid, rate is manually changed
        // inform user it is manually changed
        if (!doRatesMatch && !isDateInRange && changedManually) {
            console.log("exchangeRateValidator case 1")

            // return { invalidRate: true, type: 'info', currencyItem };
            return null;
        }

        // Rates don't match, retrieved rates are invalid, rate is not manually changed
        //
        if (!doRatesMatch && !isDateInRange && !changedManually) {
            console.log("exchangeRateValidator case 2")

            return { invalidRate: true, type: 'warn', currencyItem };
        }

        // Rates don't match, retrieved rates are valid (either manually changed or not)
        if (!doRatesMatch && isDateInRange) {
            console.log("exchangeRateValidator case 3")

            return { invalidRate: true, type: 'update', currencyItem };
        }

        // Rates match, retrieved rates are invalid, rate is manually changed
        // This could happen if the rate is set automatically and we mesh with the value,
        //thatr the control becomes dirty, but we don't change the value
        if (doRatesMatch && !isDateInRange && changedManually) {
            console.log("exchangeRateValidator case 4")

            return { invalidRate: true, type: 'warn', currencyItem };
        }

        // Rates match, retrieved rates are invalid, rate is not manually changed
        if (doRatesMatch && !isDateInRange && !changedManually) {
            console.log("exchangeRateValidator case 5")

            return { invalidRate: true, type: 'warn', currencyItem };
        }

        // Rates match, retrieved rates are valid (either manually changed or not)
        // Do nothing
        if (doRatesMatch && isDateInRange) {
            console.log("exchangeRateValidator case 6")

            return null;
        }

        // If none of the conditions are met, return null
        console.log("exchangeRateValidator case 7")

        return null;
    };
}
