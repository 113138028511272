import { FuseUtils } from '@fuse/utils';
import { DeclarationSettingsModel } from 'app/main/app-settings/declaration-settings/_models/delcaration-settings.model';

export class Contact {
    id?: string;
    type?: string;
    customerType?: number;
    lastname?: string;
    firstname?: string;
    fullname?: string;
    afm?: string;
    doy?: string;
    eori?: string;
    passport?: string;
    id_number?: string;
    occupation?: string;
    street?: string;
    address2?: string;
    city?: string;
    postal_code?: string;
    country?: string;
    email?: string;
    phone?: string;
    trademark?: string;
    trademarkEn?: string;
    registeredWarehouseOwnerId?: string;
    exporterEng?: string;
    notes?: string;
    icisCreds?: boolean;
    icisUser?: string;
    icisPass?: string;
    icisPassConfirm?: string;
    countryTitle?: string;
    active?: number;
    warehouseRefNumbers?: string[];

    /**
     * Constructor
     *
     * @param contact
     */
    constructor(settings: Partial<DeclarationSettingsModel>, contact?: Contact) {
        {

            const defaultCountry = settings?.country;


            console.log("defaultCountry", defaultCountry);
            this.id = contact?.id || FuseUtils.generateGUID();
            this.type = contact?.type || null;
            this.customerType = contact?.customerType || null;
            this.lastname = contact?.lastname || null;
            this.firstname = contact?.firstname || null;
            this.fullname = contact?.fullname || null;
            this.afm = contact?.afm || null;
            this.doy = contact?.doy || null;
            this.eori = contact?.eori || null;
            this.passport = contact?.passport || null;
            this.id_number = contact?.id_number || null;
            this.occupation = contact?.occupation || null;
            this.street = contact?.street || null;
            this.address2 = contact?.address2 || null;
            this.city = contact?.city || null;
            this.postal_code = contact?.postal_code || null;
            this.country = contact?.country || defaultCountry;
            this.countryTitle = contact?.countryTitle || null;
            this.email = contact?.email || null;
            this.phone = contact?.phone || null;
            this.trademark = contact?.trademark || null;
            this.trademarkEn = contact?.trademarkEn || null;
            this.registeredWarehouseOwnerId = contact?.registeredWarehouseOwnerId || null;
            this.exporterEng = contact?.exporterEng || null;
            this.icisCreds = contact?.icisCreds || null;
            this.icisUser = contact?.icisUser || null;
            this.icisPass = contact?.icisPass || null;
            this.icisPassConfirm = contact?.icisPassConfirm || null;
            this.active = contact?.active || null;
            this.warehouseRefNumbers = contact?.warehouseRefNumbers || null;


        }

    }
}
