import { FormControl, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { CancelHeaheaModel } from './heahea.model';

export class CancelHeaheaForm {
    [key: string]: AbstractControl;
    //HEADER FORM
    DocNumHEA5 = new FormControl();
    DatOfCanReqHEA147 = new FormControl();
    CanReaHEA250 = new FormControl();
    CanReaHEA250LNG = new FormControl();

    constructor(value: CancelHeaheaModel) {

        this.DocNumHEA5.setValue(value.DocNumHEA5);
        this.DatOfCanReqHEA147.setValue(value.DatOfCanReqHEA147);
        this.CanReaHEA250.setValue(value.CanReaHEA250);
        this.CanReaHEA250LNG.setValue(value.CanReaHEA250LNG);
    }
}
