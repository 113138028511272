import { FormControl } from '@angular/forms';
import { Tracorsec037Model } from './tracorsec037.model';

export class Tracorsec037Form {
    [key: string]: FormControl;

    NamTRACORSEC041 = new FormControl();
    StrNumTRACORSEC043 = new FormControl();
    PosCodTRACORSEC042 = new FormControl();
    CitTRACORSEC038 = new FormControl();
    CouCodTRACORSEC039 = new FormControl();
    TRACORSEC037LNG = new FormControl();
    TINTRACORSEC044 = new FormControl();
    TINTypTRACORSEC045 = new FormControl();

    ExporterObject = new FormControl();

    constructor(value: Tracorsec037Model) {
        this.NamTRACORSEC041.setValue(value.NamTRACORSEC041);
        this.StrNumTRACORSEC043.setValue(value.StrNumTRACORSEC043);
        this.PosCodTRACORSEC042.setValue(value.PosCodTRACORSEC042);
        this.CitTRACORSEC038.setValue(value.CitTRACORSEC038);
        this.CouCodTRACORSEC039.setValue(value.CouCodTRACORSEC039);
        this.TRACORSEC037LNG.setValue(value.TRACORSEC037LNG);
        this.TINTRACORSEC044.setValue(value.TINTRACORSEC044);
        this.TINTypTRACORSEC045.setValue(value.TINTypTRACORSEC045);
        this.ExporterObject.setValue(value.ExporterObject);

        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
