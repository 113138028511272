import { FormControl } from '@angular/forms';
import { PosPayGI400Model } from './pospaygi400.model';

export class PosPayGI400Form {
    [key: string]: FormControl;
    PosEndDatPOSPAYGI401 = new FormControl();
    PosEndFlaPOSPAYGI402 = new FormControl();
    PosPayCodPOSPAYGI404 = new FormControl();
    PosSusDatPOSPAYGI405 = new FormControl();

    constructor(value: PosPayGI400Model) {
        this.PosEndDatPOSPAYGI401.setValue(value.PosEndDatPOSPAYGI401);
        this.PosEndFlaPOSPAYGI402.setValue(value.PosEndFlaPOSPAYGI402);
        this.PosPayCodPOSPAYGI404.setValue(value.PosPayCodPOSPAYGI404);
        this.PosSusDatPOSPAYGI405.setValue(value.PosSusDatPOSPAYGI405);
    }
}
