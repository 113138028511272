import { Injectable } from '@angular/core';
import { packagingItem } from 'app/model/api-model';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

export interface AgrCertObjects {
    exporterMark?: string;
    packages?: packagingItem[];
    codes?: string[];
}
@Injectable()
export class ExportService {
    agrCertDescObjects$: Observable<AgrCertObjects>;

    public destroyService: Subject<void>;

    private agrCertDescObjects: BehaviorSubject<AgrCertObjects>;

    constructor(private dbQuery: ApiService) {
        console.log('SERVICE Export Started');

        this.agrCertDescObjects = new BehaviorSubject({
            exporterMark: null,
            packages: [],
            codes: []
        });
        this.agrCertDescObjects$ = this.agrCertDescObjects.asObservable();
    }

    updateAgrCertObjects(obj: AgrCertObjects) {
        this.agrCertDescObjects.next(obj);
    }

    newDestroy() {
        if (!this.destroyService || this.destroyService.isStopped) {
            this.destroyService = new Subject<void>();
            console.log('New Destory Subject', this.destroyService);
        }
    }
    completeDestroy() {
        if (this.destroyService) {
            this.destroyService.next();
            this.destroyService.complete();
            console.log('destroyService completed', this.destroyService);
        }
    }

    //packages are used in AgrCert Form and CMR Form
    //exporter Mark is updated on AgrCert Items component
    getPackagesForForms(): Observable<any> {
        return this.dbQuery.get_options(ApiEndPoints.packaging).pipe(
            tap((packagesData: packagingItem[]) => {
                const codes = packagesData.map(value => value.code);

                const agrcertObj: AgrCertObjects = {
                    exporterMark: null,
                    packages: packagesData,
                    codes: codes
                };

                this.updateAgrCertObjects(agrcertObj);
            }),
            take(1)
        );
    }
}
