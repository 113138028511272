import { NgModule, Pipe, PipeTransform } from '@angular/core';
import stateCodes from 'assets/stateCodes.json';

@Pipe({
    name: 'declarationState'
})
export class DeclarationStatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const states = stateCodes.all;

        if (value && states.find(s => s.code === value)) {
            return states.find(s => s.code === value).translate;
        }
        else {
            return value;
        }
    }
}

@NgModule({
    declarations: [DeclarationStatePipe],
    exports: [DeclarationStatePipe],
})
export class DeclarationStatePipeModule { }