import { Contact } from 'app/main/apps/contacts/contact.model';
export class Traexpex1Model {
    NamEX17?: string;
    StrAndNumEX122?: string;
    PosCodEX123?: string;
    CitEX124?: string;
    CouEX125?: string;
    NADLNGEX?: string;
    TINEX159?: string;
    SpeMenEX1011?: string;
    ExporterObject?: Contact;

    constructor(value?) {
        this.NamEX17 = value?.NamEX17 || null;
        this.StrAndNumEX122 = value?.StrAndNumEX122 || null;
        this.PosCodEX123 = value?.PosCodEX123 || null;
        this.CitEX124 = value?.CitEX124 || null;
        this.CouEX125 = value?.CouEX125 || null;
        this.NADLNGEX = value?.NADLNGEX || null;
        this.TINEX159 = value?.TINEX159 || null;
        this.SpeMenEX1011 = value?.SpeMenEX1011 || null;
        this.ExporterObject = value?.ExporterObject || null;
    }
}
