import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReportProgressInterceptor } from './report-progress-interceptor';
import { ErrorHandlerInterceptor } from './error-handler-interceptor';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';

export const HttpInterceptProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ReportProgressInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorHandlerInterceptor,
        multi: true
    }
];
