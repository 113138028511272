import { FormFieldsModule } from './../../../main-building-blocks/form-fields/form-fields.module';
import { NgModule } from '@angular/core';
import { CancelDeclarationComponent } from './cancel-declaration.component';
import { MaterialModule } from 'app/main/angular-material-elements/material.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
    declarations: [CancelDeclarationComponent],
    imports: [MaterialModule, FormFieldsModule, FuseSharedModule, FuseConfirmDialogModule],
    exports: [CancelDeclarationComponent],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} }
    ]
})
export class CancelDeclarationModule { }
