import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BrowserDetectService {
    isChrome: boolean;
    isFirefox: boolean;
    isSafari: boolean;
    isIE: boolean;

    constructor() {
        const userAgent = window.navigator.userAgent;
        this.isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
        this.isFirefox = /Firefox/.test(userAgent);
        this.isSafari = /Safari/.test(userAgent) && !this.isChrome; // Chrome's userAgent also includes "Safari"
        this.isIE = /MSIE|Trident/.test(userAgent);
    }
}