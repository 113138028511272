import { ExtCustomFunctions } from './../main/main-building-functions/ext-custom-functions.service';
import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ProductNode } from 'app/main/apps/taric/taric.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { DeclarationForms, MainBroadcasterService, TotalItems } from './broadcaster.service';
import { DeclarationProductDescriptionService } from './product-description.service';
import { SupUnitDesc } from 'app/model/api-model';
export enum SubToDomain {
    EXP_DECL = 'ECS',
    IMP_DECL = 'IMP',
    T2L = 'NCTS',
    POUS = 'NCTS',
    TIR = 'NCTS',
    DAO = 'EMCS',
    DAO_RECEIVE = 'EMCS',
    DEFK = 'EMCS',
    ICS_DETE = 'IMP',
    ARR_AT_EXIT = 'ECS'
}
export interface RetrievedDescription {
    id: number;
    officeId: string;
    comCode: string;
    taricCode: string;
    description: string;
    descriptionEn: string;
    botanicalName: string;
    lastUsed: number;
}
export class ComCodeItem {
    index?: number;
    code?: { comCode?: string; taricCode?: string };
    currentDescription?: RetrievedDescription;
    comCodeStatus?: 'valid' | 'invalid' | null;
    taricProduct?: ProductNode;
    supUnit?: SupUnitDesc;




    constructor(index) {
        this.index = index;
        this.code = { comCode: null, taricCode: null };
        this.supUnit = { short: null, long: null };
        this.currentDescription = null;
        this.comCodeStatus = null;
        // this.retrievedDescriptions = [];
        this.taricProduct = null;
        // this.documents = [];
    }
}
@Injectable({
    providedIn: 'root'
})
export class ComCodeService {
    domain: string;
    subdomain: string;
    product: ProductNode;
    ComCode$: Observable<ComCodeItem[]>;

    currentDescription: RetrievedDescription;

    comCodeStatus: 'valid' | 'invalid' | null;

    //retrievedDescriptions: RetrievedDescription[];
    supUnit: SupUnitDesc;

    private ComCode: BehaviorSubject<ComCodeItem[]>;

    private _destroy: Subject<void> = new Subject<void>();

    constructor(
        private dbQuery: ApiService,
        private toastrService: ToastrService,
        private mainBroadcaster: MainBroadcasterService,
        private productDescriptionService: DeclarationProductDescriptionService,
        private cf: ExtCustomFunctions
    ) {
        console.log('COMCODE SERVICE CONSTRUCTOR');
        this.ComCode = new BehaviorSubject([]);
        this.ComCode$ = this.ComCode.asObservable();

        /*    const totalItems$ = this.mainBroadcaster.totalItems$.pipe(
               tap((items: TotalItems) => {
                   const totalItems = items[this.subdomain];
   
                   const comCodeArray = [];
                   for (let i = 0; i < totalItems; i++) {
                       comCodeArray.push(new ComCodeItem(i));
                   }
   
                   this.ComCode.next(comCodeArray);
                   console.log('COMCODE OBSERVABLE VALUE Constructor', this.ComCode.value);
                   console.log('SWITCHMAP TOTALITEMS', totalItems);
               }),
               switchMap(()=> this.mainBroadcaster.declarationForms$.pipe(
                       map((declarationForms: DeclarationForms) => declarationForms[this.subdomain]),
                       first(declarationForm => declarationForm !== null),
                   )
               ),
               takeUntil(this._destroy)
           )
   
           this.mainBroadcaster.activeSubdomain$
               .pipe(
                   switchMap(subdomain => {
                       this.subdomain = subdomain;
   
                       if (subdomain !== 'EXP_DECL' && subdomain !== 'IMP_DECL' && subdomain !== 'T2L' && subdomain !== 'TIR' && subdomain !== 'DEFK') {
                           return of(null);
                       }
                       return totalItems$;
                   }),
                   takeUntil(this._destroy)
               )
               .subscribe(declarationForm => {
                   console.log('SWITCHMAP DECLARATION', declarationForm?.value);
                   if (declarationForm)
                       this.checkCodeExistsOnStart(declarationForm)
               }); */



        this.mainBroadcaster.activeSubdomain$
            .pipe(
                tap(subdomain => this.subdomain = subdomain),
                // filter out invalid subdomains early to avoid unnecessary operations
                filter(subdomain => this.isValidSubdomain(subdomain)),
                switchMap(subdomain => {
                    if (!this.isValidSubdomain(subdomain)) {
                        return of(null); // emit null for invalid subdomains
                    }

                    const totalItems$ = this.mainBroadcaster.totalItems$.pipe(
                        this.handleTotalItems(this.subdomain, this.ComCode)
                    );

                    const declarationForms$ = this.mainBroadcaster.declarationForms$.pipe(
                        this.filterValidDeclarationForms(this.subdomain)
                    );

                    // combineLatest will wait for both totalItems$ and declarationForms$ to emit before proceeding
                    return combineLatest([totalItems$, declarationForms$]);
                }),
                map(([totalItems, declarationForm]) => declarationForm),  // map to only pass the declarationForm downstream
                takeUntil(this._destroy)
            )
            .subscribe((declarationForm: FormGroup) => {
                console.log('SWITCHMAP DECLARATION', declarationForm?.value);
                if (declarationForm) this.checkCodeExistsOnStart(declarationForm);
            });
    }



    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }
    private checkCodeExistsOnStart(declarationForm: FormGroup) {

        const currentGoods = this.cf.getCurrentGoods(declarationForm);
        const goodsPrivate = declarationForm.get('_PRIVATE').get('goodsPrivate') as FormArray;

        for (let i = 0; i < currentGoods.length; i++) {
            const GoodsFormGroup = currentGoods.at(i) as FormGroup;
            const goodsPrivateGroup = goodsPrivate.at(i) as FormGroup;

            const comTaric = this.cf.getComTaric(GoodsFormGroup, this.subdomain);
            const comCode = comTaric.comCode
            const taricCode = comTaric.taricCode

            console.log('COMCODE GoodsFormGroup start', GoodsFormGroup);
            console.log('COMCODE comCode start', comCode);
            console.log('COMCODE taricCode start', taricCode);

            if (comCode && taricCode && comCode !== '') {

                let item: ComCodeItem = {
                    code: {
                        comCode: null,
                        taricCode: null
                    },
                    currentDescription: null,
                    supUnit: null,
                    //  retrievedDescriptions:
                    //      results.retrievedDescriptions,
                    taricProduct: null,
                    comCodeStatus: null
                };

                this.comCodeExists(comCode, taricCode).then(async product => {
                    if (product) {
                        console.log('COMCODE ON CHECK EXISTs');

                        //getProductDescriptio
                        const currentDescription = await this.productDescriptionService.currentDescription(
                            GoodsFormGroup,
                            goodsPrivateGroup,
                            comCode,
                            taricCode
                        );

                        await this.getSupUnits(
                            comCode,
                            taricCode
                        );

                        item = {
                            code: {
                                comCode,
                                taricCode
                            },
                            currentDescription,
                            supUnit: this.supUnit,
                            //  retrievedDescriptions:
                            //      results.retrievedDescriptions,
                            taricProduct: product,
                            comCodeStatus: 'valid'
                        };
                        console.log('COMCODE ITEM ON INIT', item);
                    } else {
                        console.log('COMCODE ON CHECK DOES NOT EXIST');
                        item = {
                            code: {
                                comCode,
                                taricCode
                            },
                            currentDescription: null,
                            supUnit: null,
                            //  retrievedDescriptions:
                            //      results.retrievedDescriptions,
                            taricProduct: null,
                            comCodeStatus: 'invalid'
                        };

                    }

                    this.updateComCodItem(i, goodsPrivateGroup, item);

                });
            }

        }
    }
    async request(goodsIndex, goodsFormGroup, goodsPrivateGroup, comCode, taricCode) {

        let item: ComCodeItem = {
            code: { comCode: null, taricCode: null },
            currentDescription: null,
            //retrievedDescriptions: this.retrievedDescriptions,
            supUnit: null,
            taricProduct: null,
            comCodeStatus: null
        };
        this.comCodeExists(comCode, taricCode).then(async product => {
            if (product) {
                console.log('COMCODE ON CHECK EXISTs');

                //getProductDescriptio
                const results = await this.productDescriptionService.getProductDescription(
                    goodsIndex,
                    goodsFormGroup,
                    goodsPrivateGroup,
                    comCode,
                    taricCode,
                    product
                );

                this.currentDescription = results?.currentDescription;
                // this.retrievedDescriptions = results.retrievedDescriptions;

                console.log('COMCODE GETPRODUCTDESCRIPTION');

                await this.getSupUnits(comCode, taricCode);
                console.log('COMCODE SUPUNITS');

                item = {
                    code: { comCode, taricCode },
                    currentDescription: this.currentDescription,
                    //retrievedDescriptions: this.retrievedDescriptions,
                    supUnit: this.supUnit,
                    taricProduct: product,
                    comCodeStatus: 'valid'
                };
            } else {
                console.log('COMCODE ON CHECK DOES NOT EXIST');
                item = {
                    code: { comCode, taricCode },
                    currentDescription: this.currentDescription,
                    //retrievedDescriptions: this.retrievedDescriptions,
                    supUnit: null,
                    taricProduct: null,
                    comCodeStatus: 'invalid'
                };
                //reset COmCodItem
            }

            this.updateComCodItem(goodsIndex, goodsPrivateGroup, item);

        });
    }
    private async comCodeExists(comCode, taricCode): Promise<ProductNode> {
        console.log('COMCODE REQUEST RECEIVED');
        const code = comCode + taricCode;

        const product = await this.getProductByCode(code);
        if (!product) return null;

        if (!this.isProductCodeValid(product, comCode, code)) return null;

        console.log('COMCODE codeExists taricProduct', product);
        return product;
    }

    private async getProductByCode(code): Promise<ProductNode> {
        const product = await lastValueFrom(this.dbQuery.getSingleProduct(code).pipe(take(1)));
        if (!product) this.notFound();
        return product;
    }

    private isProductCodeValid(product, comCode, fullCode): boolean {
        const productCodePrefix = product?.code?.substring(0, 8);
        const productFullCodePrefix = product?.code?.substring(0, 10);

        if ((this.subdomain === 'EXP_DECL' || this.subdomain === 'T2L' || this.subdomain === 'POUS') && productCodePrefix !== comCode) {
            this.notFound();
            return false;
        } else if ((this.subdomain === 'IMP_DECL' || this.subdomain === 'DEFK') && productFullCodePrefix !== fullCode) {
            this.notFound();
            return false;
        }

        return true;
    }


    async getSupUnits(comCode: string, taricCode: string) {
        // console.trace("supUnits trace");
        if (taricCode && comCode.length === 8 && taricCode.length === 2) {
            const code = comCode + taricCode;

            const supUnit = await lastValueFrom(this.dbQuery.getSupUnits(code, SubToDomain[this.subdomain]));

            console.log('ComCOdeService, getSupUnit ', supUnit);

            this.supUnit = supUnit;
        }
    }

    // The common method that handles the shared functionality
    private updateComCodeItemCommon(goodsIndex: number, goodsPrivateGroup: FormGroup, updateCallback: (comCodeItem: any) => void) {
        const comCodeArray = [...this.ComCode.value]; // create a new copy

        // Check if the item exists and is not null
        if (comCodeArray[goodsIndex] == null) {
            console.log(`No comCodeItem found at index ${goodsIndex}`);
            return; // Exit the method if the item does not exist
        }

        const comCodeItem = { ...comCodeArray[goodsIndex] }; // Safely create a new copy now

        // Call the callback to perform specific updates, safely inside the check
        updateCallback(comCodeItem);

        this.updateGoodsPrivateGroup(goodsPrivateGroup, comCodeItem.currentDescription);
        comCodeArray[goodsIndex] = comCodeItem; // replace the item in the new array
        this.ComCode.next(comCodeArray); // emit the new array
    }

    // Modified original method to use the common functionality
    private updateComCodItem(goodsIndex: number, goodsPrivateGroup: FormGroup, item: ComCodeItem) {
        this.updateComCodeItemCommon(goodsIndex, goodsPrivateGroup, (comCodeItem) => {
            comCodeItem.taricProduct = item.taricProduct;
            comCodeItem.code.comCode = item.code?.comCode;
            comCodeItem.code.taricCode = item.code?.taricCode;
            comCodeItem.currentDescription = item.currentDescription;
            comCodeItem.supUnit = item.supUnit;
            comCodeItem.comCodeStatus = item.comCodeStatus;
        });
    }

    // Modified original method to use the common functionality
    updateComCodeItemFromDescService(goodsIndex: number, goodsPrivateGroup: FormGroup, description: RetrievedDescription, product?: ProductNode) {
        this.updateComCodeItemCommon(goodsIndex, goodsPrivateGroup, (comCodeItem) => {
            if (description) {
                comCodeItem.currentDescription = description;
            }
            if (product) {
                comCodeItem.code.comCode = product.code.substring(0, 8);
                comCodeItem.code.taricCode = product.code.substring(8, 10);
                comCodeItem.taricProduct = product;
            }
        });
    }


    resetComCode() {
        this.ComCode.next([])
        console.log('COMCODE OBSERVABLE VALUE resetComCode', this.ComCode.value);

    }

    updateGoodsPrivateGroup(goodsPrivateGroup: FormGroup, description: RetrievedDescription) {
        goodsPrivateGroup.get('descriptionEn').setValue(description?.descriptionEn)
        goodsPrivateGroup.get('botanicalName').setValue(description?.botanicalName)

        console.log("comCode goodsPrivateGroup", goodsPrivateGroup.value)

    }


    private notFound() {
        this.toastrService.warning('Ο επιλεγμένος κωδικός δεν υπάρχει στο δασμολόγιο!');
    }

    // Custom operator to handle total items
    private handleTotalItems(subdomain: string, ComCode: any): OperatorFunction<TotalItems, TotalItems> {
        return source => source.pipe(
            tap(items => {
                const totalItems = items[subdomain];
                const comCodeArray = Array(totalItems).fill(null).map((_, index) => new ComCodeItem(index));
                ComCode.next(comCodeArray);
                console.log('COMCODE OBSERVABLE VALUE Constructor', ComCode.value);
                console.log('SWITCHMAP TOTALITEMS', totalItems);
            })
        );
    }

    // Custom operator to filter valid declaration forms
    private filterValidDeclarationForms(subdomain: string): OperatorFunction<DeclarationForms, FormGroup | null> {
        return source => source.pipe(
            map(declarationForms => declarationForms[subdomain]),
            first(declarationForm => declarationForm !== null)
        );
    }

    // Utility function
    private isValidSubdomain(subdomain: string): boolean {
        const validSubdomains = ['EXP_DECL', 'IMP_DECL', 'T2L', 'POUS', 'TIR', 'DEFK'];
        return validSubdomains.includes(subdomain);
    }

}
