<div fxLayout="column" style="width: 100%; padding: 16px" id="printable">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column" class="calculationData">
            <app-tariff-calculation-data [calculationData]="calculationData"></app-tariff-calculation-data>
        </div>
        <div fxLayout="column"></div>
    </div>
    <div fxLayout="row" class="resultsTable">
        <app-tariff-results-container style="width: 100%" [duties]="duties" [uvDuties]="uvDuties"
            [uvSecurity]="uvSecurity" [info]="info" [errors]="errors"></app-tariff-results-container>
    </div>
</div>