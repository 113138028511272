import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { NationalProduct } from 'app/model/api-model';

@Pipe({
    name: 'natProDesc'
})
export class NatProDescPipe implements PipeTransform {
    constructor() { }

    transform(natCode: string, args: NationalProduct[]) {

        return (args?.find(el => el.natCode === natCode))?.description ?? null;

    }
}

@NgModule({
    declarations: [NatProDescPipe],
    exports: [NatProDescPipe],
})
export class NatProDescPipeModule { }