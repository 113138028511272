import { FormArray, FormControl, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { ImportDeclarationModel } from './declaration.model';

import { Traconco1Form } from '../traconco1/traconco1-form.model';
import { Traconco1Model } from '../traconco1/traconco1.model';
import { TrarepForm } from '../trarep/trarep-form.model';
import { TrarepModel } from '../trarep/trarep.model';
import { EntcusoffForm } from '../entcusoff/entcusoff-form.model';
import { EntcusoffModel } from '../entcusoff/entcusoff.model';
import { Prov100Form } from '../prov100/prov100-form.model';
import { Prov100Model } from '../prov100/prov100.model';
import { ImpcusoffForm } from '../impcusoff/impcusoff-form.model';
import { ImpcusoffModel } from '../impcusoff/impcusoff.model';
import { AddCon789Model } from '../addcon789/addcon789.model';
import { AddCon789Form } from '../addcon789/addcon789-form.model';
import { ImpGooIteGdsForm } from '../gooitegds/gooitegds-form.model';
import { ImpGooIteGdsModel } from '../gooitegds/gooitegds.model';
import { ImpHeaheaForm } from '../heahea/heahea-form.model';
import { ImpHeaheaModel } from '../heahea/heahea.model';
import {
    TraconceForm,
    TraconceModel,
    ConresersForm,
    ConresersModel,
    SeainfsilForm,
    SeainfsilModel,
    DelterForm,
    DelterModel,
    TradatForm,
    TradatModel,
    Statrep385Form,
    Statrep385Model,
    ItiModel,
    ItiForm
} from 'app/main/apps/export/declaration/_models';
import { Cartra100Form } from '../cartra100/cartra-form.model';
import { Cartra100Model } from '../cartra100/cartra100.model';
import { PrivateControlsFormModel } from 'app/model/private-controls-form.model';
import { PrivateControlsModel } from 'app/model/private-controls.model';

export class ImportDeclarationForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    MesSenMES3: FormControl;
    MesRecMES6: FormControl;
    DatOfPreMES9: FormControl;
    TimOfPreMES10: FormControl;
    PriMES15: FormControl;
    TesIndMES18: FormControl;
    MesIdeMES19: FormControl;
    MesTypMES20: FormControl;
    CorIdeMES25: FormControl;

    HEAHEA: FormGroup;
    TRACONCO1: FormGroup;
    TRACONCE1: FormGroup;
    CONRESERS: FormGroup;
    SEAINFSLI: FormGroup;
    GOOITEGDS: FormArray;
    ITI: FormArray;
    DELTER: FormGroup;
    TRADAT: FormGroup;
    TRAREP: FormGroup;
    STATREP385: FormGroup;
    CARTRA100: FormGroup;
    ADDCON789: FormArray;
    ENTCUSOFF: FormGroup;
    PROV100: FormGroup;
    IMPCUSOFF: FormGroup;
    /*

   */

    constructor(declaration: ImportDeclarationModel, subdomain?: string, messageType?: string) {
        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));



        this.MesSenMES3 = new FormControl(declaration.MesSenMES3);
        this.MesRecMES6 = new FormControl(declaration.MesRecMES6);
        this.DatOfPreMES9 = new FormControl(declaration.DatOfPreMES9);
        this.TimOfPreMES10 = new FormControl(declaration.TimOfPreMES10);
        this.PriMES15 = new FormControl(declaration.PriMES15);
        this.TesIndMES18 = new FormControl(declaration.TesIndMES18);
        this.MesIdeMES19 = new FormControl(declaration.MesIdeMES19);
        this.MesTypMES20 = new FormControl(declaration.MesTypMES20);
        this.CorIdeMES25 = new FormControl(declaration.CorIdeMES25);

        this.HEAHEA = new FormGroup(new ImpHeaheaForm(new ImpHeaheaModel(declaration.HEAHEA)));

        this.TRACONCO1 = new FormGroup(new Traconco1Form(new Traconco1Model(declaration.TRACONCO1)));

        this.TRACONCE1 = new FormGroup(new TraconceForm(new TraconceModel(declaration.TRACONCE1)));

        this.CONRESERS = new FormGroup(new ConresersForm(new ConresersModel(declaration.CONRESERS)));

        this.SEAINFSLI = new FormGroup(new SeainfsilForm(new SeainfsilModel(declaration.SEAINFSLI)));

        this.GOOITEGDS = new FormArray([], Validators.required);

        this.ITI = new FormArray([]);

        this.DELTER = new FormGroup(new DelterForm(new DelterModel(declaration.DELTER)));

        this.TRADAT = new FormGroup(new TradatForm(new TradatModel(declaration.TRADAT)));

        this.TRAREP = new FormGroup(new TrarepForm(new TrarepModel(declaration.TRAREP)));

        this.STATREP385 = new FormGroup(new Statrep385Form(new Statrep385Model(declaration.STATREP385)));

        this.CARTRA100 = new FormGroup(new Cartra100Form(new Cartra100Model(declaration.CARTRA100)));

        this.ADDCON789 = new FormArray([]);

        this.ENTCUSOFF = new FormGroup(new EntcusoffForm(new EntcusoffModel(declaration.ENTCUSOFF)));
        this.PROV100 = new FormGroup(new Prov100Form(new Prov100Model(declaration.PROV100)));
        this.IMPCUSOFF = new FormGroup(new ImpcusoffForm(new ImpcusoffModel(declaration.IMPCUSOFF)));

        if (declaration.GOOITEGDS) {
            declaration.GOOITEGDS.forEach((goods: ImpGooIteGdsModel) => {
                this.GOOITEGDS.push(new FormGroup(new ImpGooIteGdsForm(new ImpGooIteGdsModel(goods))));
            });
        }

        if (declaration.ITI) {
            declaration.ITI.forEach((itinerary: ItiModel) => {
                this.ITI.push(new FormGroup(new ItiForm(new ItiModel(itinerary))));
            });
        }

        if (declaration.ADDCON789) {
            declaration.ADDCON789.forEach((addcon: AddCon789Model) => {
                this.ADDCON789.push(new FormGroup(new AddCon789Form(new AddCon789Model(addcon))));
            });
        }

        console.log('___DeclarationFormModel GOOITEGDS constructor', this.GOOITEGDS);
    }
}
