export class T2LHeaheaModel {
    DocNumHEA5?: string;

    RefNumHEA4?: string; //
    TypOfDecHEA24?: string;
    CouOfDesCodHEA30?: string;
    TotNumOfIteHEA305?: string;
    TotGroMasHEA307?: string;
    DecDatHEA383?: string;
    DecPlaHEA394?: string;
    DecPlaHEA394LNG?: string;
    CouOfDisCodHEA55?: string;
    AmdPlaHEA598?: string;
    AmdPlaHEA598LNG?: string;
    RefT2LYeaHEA1012?: string;
    RefT2CouCodHEA1010?: string;
    RefT2LTypHEA1014?: string;
    RefT2LCusHEA1013?: string;
    RefT2LNumHEA1015?: string;
    AmdDatHEA599?: string;

    //TypOfDecHEA24
    //CouOfDesCodHEA30
    //CouOfDisCodHEA55
    //TotNumOfIteHEA305
    //TotGroMasHEA307

    constructor(value?) {
        if (value) {
            this.DocNumHEA5 = value.DocNumHEA5;

            this.RefNumHEA4 = value.RefNumHEA4;
            this.TypOfDecHEA24 = value.TypOfDecHEA24;
            this.CouOfDesCodHEA30 = value.CouOfDesCodHEA30;
            this.TotNumOfIteHEA305 = value.TotNumOfIteHEA305;
            this.TotGroMasHEA307 = value.TotGroMasHEA307;
            this.DecDatHEA383 = value.DecDatHEA383;
            this.DecPlaHEA394 = value.DecPlaHEA394;
            this.DecPlaHEA394LNG = value.DecPlaHEA394LNG;
            this.CouOfDisCodHEA55 = value.CouOfDisCodHEA55;
            //===========================================================
            this.AmdPlaHEA598 = value.AmdPlaHEA598;
            this.AmdPlaHEA598LNG = value.AmdPlaHEA598LNG;
            this.RefT2LYeaHEA1012 = value.RefT2LYeaHEA1012;
            this.RefT2CouCodHEA1010 = value.RefT2CouCodHEA1010;
            this.RefT2LTypHEA1014 = value.RefT2LTypHEA1014;
            this.RefT2LCusHEA1013 = value.RefT2LCusHEA1013;
            this.RefT2LNumHEA1015 = value.RefT2LNumHEA1015;
            this.AmdDatHEA599 = value.AmdDatHEA599;
        }
    }
}
