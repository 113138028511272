import { FormControl, Validators } from '@angular/forms';
import { TraconceModel } from './traconce1.model';

export class TraconceForm {
    [key: string]: FormControl;

    NamCE17 = new FormControl();
    StrAndNumCE122 = new FormControl();
    PosCodCE123 = new FormControl();
    CitCE124 = new FormControl();
    CouCE125 = new FormControl();
    NADLNGCE = new FormControl();
    TINCE159 = new FormControl();
    SpeMenCE1003 = new FormControl();
    TinTypCE1009 = new FormControl();
    ConsigneeObject = new FormControl();

    constructor(value: TraconceModel) {
        this.NamCE17.setValue(value.NamCE17);
        this.StrAndNumCE122.setValue(value.StrAndNumCE122);
        this.PosCodCE123.setValue(value.PosCodCE123);
        this.CitCE124.setValue(value.CitCE124);
        this.CouCE125.setValue(value.CouCE125);
        this.NADLNGCE.setValue(value.NADLNGCE);
        this.TINCE159.setValue(value.TINCE159);
        this.SpeMenCE1003.setValue(value.SpeMenCE1003);
        this.TinTypCE1009.setValue(value.TinTypCE1009);
        this.ConsigneeObject.setValue(value.ConsigneeObject);
        // this.RepStatCodSTATREP386.setValidators([Validators.required]);
    }
}
