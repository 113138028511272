<div fxLayout="column" style="width: 100%; flex-grow: 1" class="excise-vehicle-details">
    <div mat-dialog-title class="primary">
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 20%">
            <span>Στοιχεία {{VehicleDetailsIndex+1}}ου Οχήματος</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="flex-end center" style="width: 20%">
            <button mat-icon-button (click)="onCloseConfirm()">
                <mat-icon> highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row" mat-dialog-content style="overflow-y: auto;padding: 0 16px;flex:1 1 auto;">

        <div style="width:50%; margin-bottom: 16px;margin-right:2%" fxLayout="column" fxLayoutAlign="flex-start center"
            class="group">
            <h2>Στοιχεία Οχήματος</h2>
            <table class="eAitisiHea threeColumns" [formGroup]="VehicleDetailsFormGroup">
                <tbody>

                    <tr>
                        <td style="width:37%">Είδος Οχήματος</td>

                        <td style="width:8%">
                            <mat-form-field appearance="outline" class="s-l sc-ul s-4">
                                <autocomplete-control formControlName="CarKind" [options]="carKindOptions"
                                    [config]="config">
                                </autocomplete-control>
                            </mat-form-field>
                        </td>
                        <td style="width:55%">
                            {{carKindControl.value | codeToDesc:carKindOptions}}

                        </td>
                    </tr>
                    <tr>
                        <td>Εργοστάσιο Κατασκευής / Μάρκα (D.1)</td>
                        <td>
                            <mat-form-field appearance="outline" class="s-l sc-ul s-4">
                                <autocomplete-control formControlName="ImporterCodeOrBrandName"
                                    [options]="carBrandOptions" [config]="config">
                                </autocomplete-control>
                            </mat-form-field>

                        </td>
                        <td> {{carBrandControl.value | codeToDesc:carBrandOptions}}
                        </td>
                    </tr>

                    <tr>
                        <td>Εργοστασιακός Τύπος (D.2)</td>

                        <td colspan="2">
                            <mat-form-field appearance="outline" class="s-l sc-ul s-22">
                                <input maxlength="25" matInput formControlName="VehicleFactoryType" />
                            </mat-form-field>
                        </td>
                    </tr>

                    <tr>
                        <td>Μοντέλο (D.3)</td>

                        <td colspan="2">
                            <mat-form-field appearance="outline" class="s-l sc-ul s-20">
                                <input matInput maxlength="20" formControlName="VehicleModelName" />
                            </mat-form-field>
                        </td>
                    </tr>

                    <tr>
                        <td>Αριθμός Πλαισίου (E)</td>
                        <td colspan="2">
                            <mat-form-field appearance="outline" class="s-l sc-ul s-21">
                                <input matInput maxlength="17" formControlName="FrameNumber" (keyup)="
                                VehicleDetailsFormGroup.patchValue({
                                    FrameNumber: $event.target.value.toUpperCase()
                                })
                            " />
                            </mat-form-field>
                        </td>
                    </tr>

                    <tr>
                        <td>Διανυθέντα Χιλιόμετρα</td>

                        <td colspan="2">
                            <mat-form-field appearance="outline" class="s-l sc-ul s-8">
                                <input maxlength="8" digitOnly matInput formControlName="Mileage" />
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Ημερομηνία 1ης Κυκλοφορίας (Β)</td>

                        <td colspan="2">
                            <mat-form-field appearance="outline" class="s-l sc-ul s-16">
                                <date-select formControlName="FirstAllowanceDate" [dateType]="dateType"
                                    [defaultDate]="defaultDate" [hideCal]='formDisabled' minlength="10">
                                </date-select>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Μεταχειρισμένο</td>

                        <td colspan="2">
                            <mat-checkbox (change)="onChange($event)" [(checked)]="checkboxChecked"
                                (click)="$event.stopPropagation()" [disabled]="formDisabled">
                            </mat-checkbox>
                        </td>
                    </tr>

                    <tr>
                        <td>Αριθμός Κυκλοφορίας</td>

                        <td colspan="2">
                            <mat-form-field appearance="outline" class="s-l sc-ul s-20">
                                <input maxlength="20" matInput formControlName="VehicleRegistrationPlate" />
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Αρ. Άδειας Κυκλοφορίας</td>

                        <td colspan="2">
                            <mat-form-field appearance="outline" class="s-l sc-ul s-22">
                                <input maxlength="25" matInput formControlName="VehicleLicenceReference" />
                            </mat-form-field>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div style="width:35%;margin-bottom: 16px;" fxLayout="column" class="group">
            <h2>Απογραφικό Οχήματος</h2>
            <div style="overflow: auto; ">
                <table class="eAitisiHea fourColumns" [formGroup]="VehicleDetailsFormGroup">
                    <tbody>
                        <tr formArrayName="VehicleInventory" *ngFor="
                        let item of vehicleInventory?.controls;
                        let i = index
                    ">
                            <td style="width:5%">{{i+1}}.</td>
                            <td style="width:15%" [formGroupName]="i">
                                <mat-form-field class="s-c sc-ul s-6" appearance="outline">
                                    <autocomplete-control formControlName="VehicleInventoryType"
                                        [options]="stoixeioApografikouOpt" [config]="config">
                                    </autocomplete-control>
                                </mat-form-field>
                            </td>
                            <td style="width:60%">
                                {{item.get('VehicleInventoryType').value | codeToDesc: stoixeioApografikouOpt}}</td>
                            <td style="width:35%" class="add_del_buttons">
                                <add-del-buttons *ngIf="showButtons" [show]="showButtons"
                                    [hideAdd]="hideAdd(vehicleInventory)" [hideDelete]="hideDelete(vehicleInventory)"
                                    (addButton)="add()" (deleteButton)="delete(i)">
                                </add-del-buttons>
                            </td>
                        </tr>

                    </tbody>

                </table>
            </div>
        </div>
    </div>


    <div>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around start">
            <button style="width: auto !important" mat-raised-button [ngClass]="color1"
                (mouseenter)="changeStyle1($event)" (mouseleave)="changeStyle1($event)" (click)="cancel()">
                Ακυρο
            </button>
            <button style="width: auto !important" mat-raised-button [ngClass]="color2"
                [disabled]="VehicleDetailsFormGroup.invalid" (mouseenter)="changeStyle2($event)"
                (mouseleave)="changeStyle2($event)" (click)="onCloseConfirm()">
                OK
            </button>
        </mat-dialog-actions>
    </div>
</div>