import { FormControl, Validators } from '@angular/forms';
import { BasProModel } from './baspro.model';

export class BasProForm {
    [key: string]: FormControl;

    ComCodBP1019 = new FormControl();
    AddCodBP1020 = new FormControl();
    QuaBP1021 = new FormControl();

    constructor(value: BasProModel) {
        this.ComCodBP1019.setValue(value.ComCodBP1019);
        this.AddCodBP1020.setValue(value.AddCodBP1020);
        this.QuaBP1021.setValue(value.QuaBP1021);

        this.ComCodBP1019.setValidators(Validators.pattern('\\d{10}'));
    }
}
