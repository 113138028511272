import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodeDescParams, MainBroadcasterService } from 'app/services/broadcaster.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProductNode } from '../taric.service';

export interface Description {
    id: string;
    description: string;
}

@Component({
    selector: 'app-code-desc-dialog',
    templateUrl: './code-desc-dialog.component.html',
    styleUrls: ['./code-desc-dialog.component.scss']
})
export class CodeDescDialogComponent implements OnInit {
    codeDescDialogRef: MatDialogRef<CodeDescDialogComponent>;
    @ViewChildren('itemButton') itemButton: QueryList<ElementRef<MatButton>>;

    type: string;
    code: string;
    product: ProductNode;
    formattedCode: string;
    title: string;
    descriptions: Description[];
    descTableDialogRef: MatDialogRef<any>;
    goodsIndex: number; //index of the GoodsItem asking for description
    totalItems: number[] = [];
    checkboxes: {};

    selectedDescriptions: string[] = [];
    selectedItems: number[] = [];

    finalDescription: string;

    onDescriptionsChanged: BehaviorSubject<any>;
    onSelectedDescriptionsChanged: BehaviorSubject<any>;
    declarationForm: FormGroup;

    private _destroy: Subject<void> = new Subject<void>();

    displayedColumns = ['checkbox'];

    constructor(
        public dialogRef: MatDialogRef<CodeDescDialogComponent>,
        public _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private mainBroadcaster: MainBroadcasterService
    ) {
        this.type = data.type;
        this.product = data.product;
        this.code = data.product.code;
        this.descriptions = data.descriptions;
        this.goodsIndex = data.goodsIndex;
        this.descTableDialogRef = data.descTableDialogRef;
        console.log('setCode CODE DESC DIalog data', data);
        this.onDescriptionsChanged = new BehaviorSubject([]);
        this.onSelectedDescriptionsChanged = new BehaviorSubject([]);
        this.checkboxes = {};
        this.formattedCode = this.formatCode(this.code);

        if (this.type === 'code') {
            this.title = 'ο κωδικός';
        } else if (this.type === 'description') {
            this.title = 'η περιγραφή';
        } else {
            this.title = 'ο κωδικός και η περιγραφή';
        }
    }

    ngOnInit(): void {
        console.log(this.data);
        combineLatest([this.mainBroadcaster.totalItems$, this.mainBroadcaster.activeSubdomain$])
            .pipe(take(1))
            .subscribe(([items, subdomain]) => {
                console.log('TOTAL ITEMS ON TARICCODEDESC', items);
                const totalItems = items[subdomain];
                console.log('TOTAL ITEMS ON TARICCODEDESC', totalItems);
                for (let i = 1; i <= totalItems; i++) {
                    this.totalItems.push(i);
                }
            });

        if (this.descriptions && this.descriptions.length > 0) {
            this.selectedDescriptions.push(this.descriptions[this.descriptions.length - 1].id);

            this.onSelectedDescriptionsChanged.next(this.selectedDescriptions);

            this.descriptions.forEach(description => {
                this.checkboxes[description.id] = false;
            });
        }

        this.onSelectedDescriptionsChanged.pipe(takeUntil(this._destroy)).subscribe(selectedDescriptions => {
            for (const id in this.checkboxes) {
                // eslint-disable-next-line no-prototype-builtins
                if (!this.checkboxes.hasOwnProperty(id)) {
                    continue;
                }

                console.log('selectedDescriptions', selectedDescriptions);

                console.log(selectedDescriptions.includes(id));
                this.checkboxes[id] = selectedDescriptions.includes(id);
            }

            this.selectedDescriptions = selectedDescriptions;
            this.constructFinalDesc();
        });

        console.log('descriptions after map', this.descriptions);
        console.log('checkboxes after map', this.checkboxes);
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }
    /**
     * On selected change
     *
     * @param descriptionId
     */
    onSelectedChange(descriptionId): void {
        this.toggleSelectedDescription(descriptionId);
    }

    /**
     * Toggle selected description by id
     *
     * @param id
     */
    toggleSelectedDescription(id): void {
        // First, check if we already have that description as selected...
        if (this.selectedDescriptions.length > 0) {
            const index = this.selectedDescriptions.indexOf(id);

            if (index !== -1) {
                this.selectedDescriptions.splice(index, 1);

                // Trigger the next event
                this.onSelectedDescriptionsChanged.next(this.selectedDescriptions);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedDescriptions.push(id);

        // Trigger the next event
        this.onSelectedDescriptionsChanged.next(this.selectedDescriptions);
        console.log('checkboxes', this.checkboxes);
        console.log('selectedDescriptions', this.selectedDescriptions);
    }

    constructFinalDesc() {
        const selectedArray = [];

        const selectedDescriptions = this.selectedDescriptions.sort();

        selectedDescriptions.forEach(item => {
            const ff = this.descriptions.filter(obj => obj.id === item).map(obj => obj.description);

            selectedArray.push(ff[0]);
        });

        this.finalDescription = selectedArray.join(', ');
    }

    onSelectItem(event, index) {
        console.log('Description Selected index', index);
        console.log('Description Selected event', event);
        const params: CodeDescParams = {
            type: this.type,
            index,
            product: this.product,
            code: this.code,
            description: this.finalDescription,
            showDialog: false
        };

        console.log('codeDesc params on seleCt ', params);
        this.mainBroadcaster.updateCodeDesc(params);
        this.dialogRef.close(params);
        //close local Descriptions Dialog
        // this.descTableDialogRef?.close();
        //close add Replace Dialog
    }
    /**
     * Toggle select all
    /*     toggleSelectAll(): void {
        if (this.selectedDescriptions.length > 0) {
            this.deselectDescriptions();
        } else {
            this.selectDescriptions();
        }
    }
     */
    /**
     * Select descriptions
     *
     * @param filterParameter
     * @param filterValue
     */
    /*     selectDescriptions(filterParameter?, filterValue?): void {
        this.selectedDescriptions = [];

        // If there is no filter, select all descriptions
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedDescriptions = [];
            this.descriptions.map((description) => {
                this.selectedDescriptions.push(description.id);
            });
        }

        // Trigger the next event
        this.onSelectedDescriptionsChanged.next(this.selectedDescriptions);
    }
 */
    /**
     * Deselect descriptions
     */
    deselectDescriptions(): void {
        this.selectedDescriptions = [];

        // Trigger the next event
        this.onSelectedDescriptionsChanged.next(this.selectedDescriptions);
    }

    onCloseConfirm() {
        this.dialogRef.close();
    }

    formatCode(code: string) {
        const chapter = code.substring(0, 2);

        const heading = code.substring(2, 4);

        const subheading = this.setSubheading(code);

        const subheadingStyle = this.chunk(subheading, 2).join(' ');

        const formattedCode = chapter + ' ' + heading + ' ' + subheadingStyle;

        return formattedCode;
    }

    chunk(str, n) {
        const ret = [];
        const len = str.length;

        for (let i = 0; i < len; i += n) {
            ret.push(str.substr(i, n));
        }

        return ret;
    }

    setSubheading(code) {
        let subheading = code.substring(4);

        if (subheading === '000000') {
            subheading = '';
        } else if (subheading.substring(2, 6) === '0000') {
            subheading = subheading.substring(0, 2);
        } else if (subheading.substring(4, 6) === '00') {
            subheading = subheading.substring(0, 4);
        }
        return subheading;
    }
}
