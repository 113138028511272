import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-tariffs-dialog',
    templateUrl: './tariffs-dialog.component.html',
    styleUrls: ['./tariffs-dialog.component.scss']
})
export class TariffsDialogComponent {
    action: string;
    code: string;
    prop: string;
    country: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogTariffRef: MatDialogRef<TariffsDialogComponent>) {
        if (this.data.action === 'IMP') {
            this.action = 'Εισαγωγής';
            this.prop = 'Χώρα Εισαγωγής:';
        } else if (this.data.action === 'EXP') {
            this.action = 'Εξαγωγής';
            this.prop = 'Χώρα Εξαγωγής:';
        } else {
            this.action = '';
            this.prop = '';
        }

        this.code = this.data.code;
        if (this.data.country !== '') {
            this.country = this.data.country;
        } else {
            this.country = 'Όλες οι χώρες';
        }
    }

    onCloseConfirm() {
        this.dialogTariffRef.close();
    }
}
