export class GuaranteeReferenceType {
    GuaranteeReferenceNumber?: string;
    GuaranteeAmount?: string;


    constructor(value?) {
        this.GuaranteeReferenceNumber = value?.GuaranteeReferenceNumber || null;
        this.GuaranteeAmount = value?.GuaranteeAmount || null;

    }
}
