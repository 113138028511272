import { FormControl, Validators } from '@angular/forms';
import { PacgsModel } from './pacgs2.model';

export class PacgsForm {
    [key: string]: FormControl;

    MarNumOfPacGS21 = new FormControl(null, { updateOn: 'blur' });
    MarNumOfPacGS21LNG = new FormControl();
    KinOfPacGS23 = new FormControl(null);
    NumOfPacGS24 = new FormControl(null, { updateOn: 'blur' });
    NumOfPieGS25 = new FormControl(null, { updateOn: 'blur' });

    constructor(value: PacgsModel) {
        this.MarNumOfPacGS21.setValue(value.MarNumOfPacGS21);
        this.MarNumOfPacGS21LNG.setValue(value.MarNumOfPacGS21LNG);
        this.KinOfPacGS23.setValue(value.KinOfPacGS23);
        this.NumOfPacGS24.setValue(value.NumOfPacGS24);
        this.NumOfPieGS25.setValue(value.NumOfPieGS25);
    }
}
