import { FormControl, Validators } from '@angular/forms';
import { VallimnoneclimModel } from './vallimnoneclim.model';

export class VallimnoneclimForm {
    [key: string]: FormControl;
    NotValForOthConPLIM2 = new FormControl();

    constructor(value: VallimnoneclimModel) {
        this.NotValForOthConPLIM2.setValue(value.NotValForOthConPLIM2);

        // this.RefNumEPT1.setValidators([Validators.required]);
    }
}
