import { AbstractControl, FormControl } from "@angular/forms";
import { SubmittingOperatorModel } from "./submittingOperator.model";

export class SubmittingOperatorForm {
    [key: string]: AbstractControl;

    SubmittingOperatorIdentification = new FormControl();
    SubmittingTraderIdentification = new FormControl();

    constructor(value: SubmittingOperatorModel) {
        this.SubmittingOperatorIdentification.setValue(value.SubmittingOperatorIdentification);
        this.SubmittingTraderIdentification.setValue(value.SubmittingTraderIdentification);

    }
}
