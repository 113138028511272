import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { Router } from '@angular/router';
import { DeclarationState, MainBroadcasterService } from 'app/services/broadcaster.service';
import { environment } from 'environments/environment';
import { OfficeService } from 'app/services/office.service';
import { generateNavigation } from 'app/navigation/navigation';
import { Office } from 'app/model/office.model';
import { BusinessService } from 'app/main/businesses/services/business.service';
import { BusinessModel } from 'app/main/businesses/invoices/_models/business/business.model';
import { PATHS } from 'app/navigation/paths';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];

    fullname: string;
    loggedin = true;

    currentURL: string;
    export: boolean;
    import: boolean;
    emcs: boolean;
    ncts: boolean;
    invoice: boolean;
    contacts: boolean;
    t2l: boolean;

    description = false;

    stateCode: string;
    testing: boolean;
    office: Office;
    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param _fuseConfigService
     * @param _fuseSidebarService
     * @param _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _mainBroadcaster: MainBroadcasterService,
        private _router: Router,
        private cd: ChangeDetectorRef,
        private officeService: OfficeService,
        private businessService: BusinessService
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'el',
                title: 'Ελληνικά',
                flag: 'gr'
            }
        ];


        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();

        this.testing = environment.testing;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Get default navigation
        combineLatest([this.officeService.currentOffice$, this.businessService.currentBusiness$], (office, business) => <{ office: Office, business: BusinessModel }>{ office, business }).pipe(
            filter((value, index) => {
                console.log('Resolver Filter: ', value);
                return value.office && value.business ? true : false;
            }),
            takeUntil(this._unsubscribeAll)
        )
            .subscribe((response) => {
                const office = response.office
                const business = response.business

                this.office = response.office;

                this.navigation = generateNavigation(office, business);
                console.log("Navigation Toolbar: ", this.navigation)

            })
            ;
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(settings => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang
        });


        const activeSubdomain$ = this._mainBroadcaster.activeSubdomain$.pipe(startWith(''));
        const declarationStates$ = this._mainBroadcaster.declarationStates$.pipe(startWith());

        combineLatest([activeSubdomain$, declarationStates$])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([subdomain, declarationStates]) => {
                const state: DeclarationState = declarationStates[subdomain];
                this.stateCode = state?.current.stateCode;

                console.log('toolbar subdomain', subdomain);
                console.log('toolbar declarationStates', declarationStates);
                console.log('toolbar stateCode', this.stateCode);
                this.cd.detectChanges();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    login() {
        this._router.navigate([PATHS.signIn]);
    }


}
