import { FormControl, Validators } from '@angular/forms';
import { VallimecvleModel } from './vallimecvle.model';

export class VallimecvleForm {
    [key: string]: FormControl;
    NotValForECVLE1 = new FormControl();

    constructor(value: VallimecvleModel) {
        this.NotValForECVLE1.setValue(value.NotValForECVLE1);

        // this.RefNumEPT1.setValidators([Validators.required]);
    }
}
