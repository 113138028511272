import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { ConsignorContactDetailsForm } from "../consignorContactDetails/consignorContactDetails-form.model";
import { ConsignorContactDetailsModel } from "../consignorContactDetails/consignorContactDetails.model";
import { VehicleArrivalConsignorModel } from "./vehicleArrivalConsignor.model";

export class VehicleArrivalConsignorForm {
    [key: string]: AbstractControl;

    PersonIdentificationType: FormControl;
    TaxIdNumber: FormControl;
    ConsignorContactDetails: FormGroup;
    ConsignorObject: FormControl;

    constructor(value: VehicleArrivalConsignorModel) {
        this.PersonIdentificationType = new FormControl(value.PersonIdentificationType, [Validators.required]);
        this.TaxIdNumber = new FormControl(value.TaxIdNumber, [Validators.required]);
        this.ConsignorContactDetails = new FormGroup(new ConsignorContactDetailsForm(new ConsignorContactDetailsModel(value.ConsignorContactDetails)));
        this.ConsignorObject = new FormControl(value.ConsignorObject, [Validators.required]);


    }
}
