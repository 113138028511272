import { AbstractControl, FormGroup } from "@angular/forms";
import { PrivateControlsFormModel } from "app/model/private-controls-form.model";
import { PrivateControlsModel } from "app/model/private-controls.model";
import { EMCSHeaderForm } from "../../../_models/emcs-header-form.model";
import { EMCSHeaderModel } from "../../../_models/emcs-header.model";
import { EF10ABodyForm } from "./Body/body-form.model";
import { EF10ABodyModel } from "./Body/body.model";
import { EF10ADeclarationModel } from "./defkCancel.model";

export class EF10ADeclarationForm {
    [key: string]: AbstractControl;

    _PRIVATE: FormGroup;

    Header: FormGroup;
    Body: FormGroup;

    /*

   */

    constructor(declaration: EF10ADeclarationModel, subdomain?: string, messageType?: string) {
        let privateControls: string | PrivateControlsModel = subdomain;

        if (declaration._PRIVATE) {
            privateControls = declaration._PRIVATE
        }
        this._PRIVATE = new FormGroup(new PrivateControlsFormModel(new PrivateControlsModel(privateControls)));


        this.Header = new FormGroup(new EMCSHeaderForm(new EMCSHeaderModel(declaration.Header)));

        this.Body = new FormGroup(new EF10ABodyForm(new EF10ABodyModel(declaration.Body)));
    }
}
