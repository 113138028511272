import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, filter, first } from 'rxjs';
import { MainBroadcasterService } from './broadcaster.service';
import { PATHS } from 'app/navigation/paths';

@Injectable({
    providedIn: 'root'
})
export class RouteStateService {
    private pathParam = new BehaviorSubject<Params>(null);
    private previousUrl = new BehaviorSubject<string>('');
    private appUrl = new BehaviorSubject<string>('');
    private history = new BehaviorSubject<string[]>([])
    private officeIdFromUrl = new BehaviorSubject<string>('');
    pathParam$: Observable<Params>;
    previousUrl$: Observable<string>;
    appUrl$: Observable<string>;

    officeIdFromUrl$: Observable<string>;

    history$: Observable<string[]>;

    constructor(private mainBroadcaster: MainBroadcasterService, private router: Router, private location: Location) {
        this.pathParam$ = this.pathParam.asObservable();
        this.previousUrl$ = this.previousUrl.asObservable();
        this.appUrl$ = this.appUrl.asObservable();
        this.history$ = this.history.asObservable();
        this.officeIdFromUrl$ = this.officeIdFromUrl.asObservable();
    }

    updatePathParamState(newPathParam: Params) {
        this.pathParam.next(newPathParam);
    }


    updateAppUrl(url: string) {


        this.history.value.push(url);
        const officeId = url.split('/')[1];
        if (officeId !== 'home') {
            this.officeIdFromUrl.next(officeId);
        }

        //set Disabled state to true
        //this I don't know why we need it like this...
        if (!url.includes('/ecs/export') && !url.includes('/imp/import')) {
            this.mainBroadcaster.updateFormDisabled({
                EXP_DECL: true,
                IMP_DECL: true
            });
        }

        //we use the declForm on checkStateService to verify that returned state belongs
        //to a specific declarationForm. If we are on export component and the LRNs match
        //we should patch the declarationForm with the values returned from the checkState()
        //since checkStateService checks the DeclarationForms$, we should empty the observable
        //tp avoid patching values when we are not on export component

        //we added the excdption of tir, since we need the EXP_DECL form to patch the TIR Form
        if (!url.includes('/ecs/export') && !url.includes('/ncts/tir')) {
            this.mainBroadcaster.updateDeclarationForms({ EXP_DECL: null });
        }
        if (!url.includes('/imp/import')) {
            this.mainBroadcaster.updateDeclarationForms({ IMP_DECL: null });
        }

        this.previousUrl.next(this.appUrl.value)

        this.appUrl.next(url);

        this.setPathSegments(url)
    }


    back(): void {
        console.log("navigation history", this.history)
        this.history.value.pop()
        if (this.history.value.length > 0) {
            this.location.back()
        } else {
            this.router.navigateByUrl('/')
        }
    }

    setPathSegments(url) {

        let domain;
        let subdomain;

        if (url.includes('/ecs/export')) {
            domain = 'ecs';
            subdomain = 'export'

        }
        else if (url.includes('/imp/import')) {
            domain = 'imp';
            subdomain = 'import';
        }
        else if (url.includes('/ncts')) {
            domain = 'ncts';

        }
        else if (url.includes('/emcs')) {
            domain = 'emcs';

        }

        if (url.includes('/t2l')) {
            subdomain = 't2l';

        }
        else if (url.includes('/tir')) {
            subdomain = 'tir';

        }
        else if (url.includes('/defk')) {
            subdomain = 'defk';

        }
        else if (url.includes('/dao')) {
            subdomain = 'dao';

        }


        this.updateRouteParams({ domain, subdomain })



    }


    updateRouteParams(value: { domain?: string, subdomain?: string, type?: string, id?: string, officeId?: string }) {

        const params = { ...this.pathParam.value, ...value }

        this.pathParam.next(params)

    }

    goto(path) {
        console.log("routeState goto", path)


        switch (path) {

            case 'new': {
                this.newDecl();
                break;
            }
            case 'local': {
                this.localDecl()
            }
        }
    }

    private newDecl() {
        console.log("routeState newDecl", this.pathParam)

        this.pathParam.pipe(filter(e => e?.domain), first()).subscribe(pathParams => {

            const officeId = pathParams?.officeId;
            const domain = pathParams?.domain;
            const subdomain = pathParams?.subdomain;

            console.log("routeState pathParams", pathParams)
            console.log("routeState officeId", officeId)
            console.log("routeState domain", domain)
            console.log("routeState subdomain", subdomain)
            console.log("routeState appUrl", this.appUrl)

            this.router.navigate([PATHS.newDeclaration(officeId, domain, subdomain)])

        })



    }

    private localDecl() {
        this.pathParam.pipe(filter(e => e?.domain), first()).subscribe(pathParams => {

            const officeId = pathParams?.officeId;
            const domain = pathParams?.domain;
            const subdomain = pathParams?.subdomain;

            console.log("routeState pathParams", pathParams)
            console.log("routeState officeId", officeId)
            console.log("routeState domain", domain)
            console.log("routeState subdomain", subdomain)
            console.log("routeState appUrl", this.appUrl)

            this.router.navigate([PATHS.draftDeclaration(officeId, domain, subdomain)])

        })
    }
}
