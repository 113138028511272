import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class HttpSuccessService {
    constructor(private toastrService: ToastrService) { }

    saveSuccess(response, documentType, templateDescription) {
        if (response.noNotification !== true) {
            let message = '';
            let title = '';
            if (documentType === 'draft') {
                message = '';
                title = 'Επιτυχής Αποθήκευση Προχείρου!';
            } else if (documentType === 'template') {
                if (templateDescription && templateDescription !== '') {
                    message = 'Περιγραφή: ' + templateDescription;
                }
                title = 'Επιτυχής Αποθήκευση Προτύπου!';
            }

            this.toastrService.success(message, title, { timeOut: 4000 });
        }
    }
}
