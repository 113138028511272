import { FunctionalErrorsType, StateResponseData } from './../services/declaration-state.service';

export class CustomsControlModel {
    controlDate?: string;
    controlledBy?: string;
    controlResult?: string;
    controlDateLimit?: string;
    seals?: string;
    sealNumber?: string[];

    constructor(value?: any) {

        this.controlDate = value?.controlDate;
        this.controlledBy = value?.controlledBy;
        this.controlResult = value?.controlResult;
        this.controlDateLimit = value?.controlDateLimit;
        this.seals = value?.seals;
        this.sealNumber = value?.sealNumber;

    }

}
export class StateResponseModel implements StateResponseData {
    FunctionalErrors?: FunctionalErrorsType[];
    acceptanceDate?: string; //EXP, DETE, IMP,, DETE as RegistrationDate
    issuingDate?: string; //EXP
    exitDate?: string; //EXP
    underControl?: string; //EXP
    underControlDesc?: string; //EXP
    underControlInfo?: string; //EXP
    controlResDesc?: string; // NOT YET HANDLED
    controlResInd?: string; // NOT YET HANDLED
    customsControl?: CustomsControlModel;
    nonExitExpRespDateLim?: string; //CC582A  NOT YET HANDLED  Προθεσμία για Απάντηση
    nonExitExpReqDate?: string; //CC582A  NOT YET HANDLED  Ημ/νία για Πληροφορίες Mη-Eξαχθέντων Eξαγωγών
    nonExitCustomsRef?: string; //CC582A  NOT YET HANDLED  Αρμόδια Τελωνειακή Αρχή Εξαγωγής
    nonExitInfoAvail?: string; //CC583A NOT YET HANDLED
    nonExitExitDate?: string; //CC583A NOT YET HANDLED
    nonExitDateOfInfoReceived?: string; //CC583A NOT YET HANDLED
    cancelDecision?: boolean;
    cancelReqDate?: string;
    cancelInitByCustoms?: boolean;
    cancelDecDate?: string;
    cancelReason?: string;
    //DETE
    deteAmountOfTax?: string; //DETE
    rejectionDate?: string; //ARR_AT_EXIT
    rejectionReason?: string; //ARR_AT_EXIT
    amendAcceptanceDate?: string;
    amendRejectionDate?: string;
    amendRejectionMotivationCode?: string;
    amendRejectionMotivationText?: string;
    //ARR_AT_EXIT
    actionToBeTaken?: string;
    dateOfRelease?: string;
    //IMP
    totalTaxesAmount?: string;
    additionalDeclDate?: string;
    info?: string;

    daoReceivedDate?: string;

    payOrderId?: string;
    payOrderAmount?: string;
    //EMCS ONLY
    paymentDueDate?: string;
    paymentOrderType?: string;




    //
    constructor(value?: any) {

        this.FunctionalErrors = value?.FunctionalErrors;
        this.acceptanceDate = value?.acceptanceDate;
        this.issuingDate = value?.issuingDate;
        this.exitDate = value?.exitDate;
        this.underControl = value?.underControl;
        this.underControlDesc = value?.underControlDesc;
        this.underControlInfo = value?.underControlInfo;
        this.controlResDesc = value?.controlResDesc;
        this.controlResInd = value?.controlResInd;
        this.customsControl = value?.customsControl;
        this.nonExitExpRespDateLim = value?.nonExitExpRespDateLim;
        this.nonExitExpReqDate = value?.nonExitExpReqDate;
        this.nonExitCustomsRef = value?.nonExitCustomsRef;
        this.nonExitInfoAvail = value?.nonExitInfoAvail;
        this.nonExitExitDate = value?.nonExitExitDate;
        this.nonExitDateOfInfoReceived = value?.nonExitDateOfInfoReceived;
        this.cancelDecision = value?.cancelDecision;
        this.cancelReqDate = value?.cancelReqDate;
        this.cancelInitByCustoms = value?.cancelInitByCustoms;
        this.cancelDecDate = value?.cancelDecDate;
        this.cancelReason = value?.cancelReason;

        this.deteAmountOfTax = value?.deteAmountOfTax;
        this.rejectionDate = value?.rejectionDate;
        this.rejectionReason = value?.rejectionReason;
        this.amendAcceptanceDate = value?.amendAcceptanceDate;
        this.amendRejectionDate = value?.amendRejectionDate;
        this.amendRejectionMotivationCode = value?.amendRejectionMotivationCode;
        this.amendRejectionMotivationText = value?.amendRejectionMotivationText;

        this.actionToBeTaken = value?.actionToBeTaken;
        this.dateOfRelease = value?.dateOfRelease;

        this.totalTaxesAmount = value?.totalTaxesAmount;
        this.additionalDeclDate = value?.additionalDeclDate;
        this.info = value?.info;
        this.daoReceivedDate = value?.daoReceivedDate;

        this.payOrderId = value?.payOrderId;
        this.payOrderAmount = value?.payOrderAmount;

        this.paymentDueDate = value?.paymentDueDate;
        this.paymentOrderType = value?.paymentOrderType;
    }
}
