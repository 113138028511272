<div>
    <input #date matInput [id]="id" [placeholder]="placeholder" [disabled]="disabled" [(ngModel)]="_inputValue"
        (keyup)="inputEvent($event)" [readonly]="readonly" [autofocus]="autofocus" [tabindex]="tabindex"
        class="customDate" maxlength="10" (focus)="focus.emit($event)" (blur)="blur.emit($event)"
        [ngClass]="{trapFocus: trapFocus}" />

    <input hidden [matDatepicker]="DatePicker" [(ngModel)]="_pickerValue" (dateChange)="pickerEvent($event)" [min]="min"
        [max]="max" />

    <mat-datepicker-toggle *ngIf="!hideCal" matSuffix [for]="DatePicker" tabindex="-1" class="ScreenMedia">
    </mat-datepicker-toggle>
    <mat-datepicker #DatePicker></mat-datepicker>
</div>